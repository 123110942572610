import { sortBy } from 'lodash-es';
import axiosBff from 'platform/axios/axiosBff';
import { TimeOffer } from './timeOffer.types';

export const fetchTimeOffers = ({ advertiserId }: { advertiserId: number }) =>
    axiosBff
        .get<TimeOffer[]>('/campaign/api/time-offers', { params: { advertiserId } })
        .then((r) => sortBy(r.data, ['status', 'name']));

export const fetchTimeOffer = (id: number) =>
    axiosBff.get<TimeOffer>(`/campaign/api/time-offers/${id}`).then((r) => r.data);

export const createTimeOffer = (timeOffer: TimeOffer) =>
    axiosBff.post<TimeOffer>('/campaign/api/time-offers', timeOffer).then((r) => r.data);

export const updateTimeOffer = (timeOffer: TimeOffer) =>
    axiosBff.put<TimeOffer>(`/campaign/api/time-offers/${timeOffer.id}`, timeOffer).then((r) => r.data);
