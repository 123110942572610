import { isEmpty, uniq } from 'lodash-es';
import {
    AggregatedSyncEntities,
    Sync,
    SyncEntityType,
    SyncRequestType,
    SyncStatus,
} from 'platform/adminPanel/components/SyncJournal/syncJournal.types';
import { getAdvertiserSummaries } from 'platform/advertisers/services/advertiser.service';
import axiosBff, { API_PATH } from 'platform/axios/axiosBff';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';

export type SyncFilter = {
    advertiserId?: number;
    dateFrom?: string;
    dateTo?: string;
    entityType?: SyncEntityType;
    requestType?: SyncRequestType[];
    externalSystem?: ExternalSystem;
    internalId?: number[];
    status?: SyncStatus;
    limit?: number;
    offset?: number;
};

type AggregatedSyncParams = {
    status?: SyncStatus[];
    dateFrom: string;
    dateTo: string;
    limit?: number;
};

export const fetchSyncs = (params: SyncFilter) =>
    axiosBff.get<Sync[]>('/sync/api/journal', { params }).then((response) => response.data);

const fetchAggregatedSyncEntities = (params: AggregatedSyncParams) =>
    axiosBff
        .get<AggregatedSyncEntities[]>('/sync/api/journal/summary/by-advertiser', { params })
        .then((response) => response.data);

export const fetchAggregatedSyncs = async (params: AggregatedSyncParams) => {
    const syncEntities = await fetchAggregatedSyncEntities(params);
    if (isEmpty(syncEntities)) {
        return [];
    }
    const advertisers = await getAdvertiserSummaries({ ids: uniq(syncEntities.map((e) => e.advertiserId)) });
    return syncEntities.map((entity) => {
        const advertiser = advertisers.find((a) => a.id === entity.advertiserId);
        return { ...entity, name: advertiser?.name };
    });
};

export const getSyncEventStreamUrl = (accessToken: string) =>
    `${API_PATH}/sync/api/event-stream?access_token=${accessToken}`;
