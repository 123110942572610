interface Props {
    width?: number;
    height?: number;
}

const DoughnutSvg = ({ width = 24, height = 24 }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="10.5" strokeWidth="1" />
        <circle cx="12" cy="12" r="4.5" strokeWidth="1" />
        <path d="M12 2V8" strokeWidth="1" />
        <path d="M16 13L22 15" strokeWidth="1" />
        <path d="M14 16L16 22" strokeWidth="1" />
    </svg>
);

export default DoughnutSvg;
