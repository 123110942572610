import { FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import { useField } from 'formik';
import DatePicker from 'platform/common/components/DatePicker/DatePicker';
import { formatDate } from 'platform/common/utils/date.util';
import { validator, Validator } from 'platform/common/utils/validators.util';

type Props = {
    name: string;
    validate?: Validator | Validator[];
    className?: string;
    placeholder?: string;
    isClearable?: boolean;
    format?: string;
};

const FormDatePicker = ({ format, className, name, validate, placeholder, isClearable }: Props) => {
    const [field, { touched, error }, helpers] = useField<string | undefined>({
        name,
        validate: validate && validator(validate),
    });
    const invalid = !!(touched && error);

    return (
        <>
            <DatePicker
                format={format}
                date={field.value}
                onDateChange={(date) => helpers.setValue(formatDate(date))}
                onDateClear={isClearable ? () => helpers.setValue(undefined) : undefined}
                className={classNames({ 'form-control is-invalid p-0': invalid }, className)}
                placeholder={placeholder}
            />
            {invalid && <FormFeedback className="d-block">{error}</FormFeedback>}
        </>
    );
};

export default FormDatePicker;
