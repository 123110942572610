import { FILE_UPLOAD_HEADERS, fileUploadData } from 'platform/common/utils/file.util';
import axiosBff from '../../axios/axiosBff';

export const uploadExternalAsset = (
    params: { advertiserId: number },
    content: Blob,
    onUploadProgress?: (progressEvent: ProgressEvent) => void
) =>
    axiosBff
        .post<{ externalId: string }>('/creative/api/external-assets', fileUploadData(content), {
            ...FILE_UPLOAD_HEADERS,
            params,
            onUploadProgress,
        })
        .then((response) => response.data);
