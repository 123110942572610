import { fetchActionStatsReports } from 'platform/actionStatsReport/actionStatsReport.service';
import { ActionStatsReport } from 'platform/actionStatsReport/actionStatsReport.types';
import { GcmAdvertiser, GcmParentAccount } from 'platform/advertisers/advertiser.types';
import {
    fetchGcmAdvertisers,
    fetchGcmParentAccounts,
} from 'platform/advertisers/services/advertiser.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';

interface Row extends ActionStatsReport {
    accountName: string;
    advertiserName: string;
}

const ActionStatsReportList = () => {
    const [{ data, loading }] = usePromise<Row[]>(
        [],
        async () => {
            const [accounts, advertisers, reports]: [
                GcmParentAccount[],
                GcmAdvertiser[],
                ActionStatsReport[]
            ] = await Promise.all([
                fetchGcmParentAccounts(),
                fetchGcmAdvertisers(),
                fetchActionStatsReports(),
            ]);
            return reports.map(
                (report): Row => ({
                    ...report,
                    accountName: accounts.find((a) => a.id === report.accountId)?.name ?? '',
                    advertiserName: advertisers
                        .filter((a) => a.floodlightConfigurationId === report.floodlightConfigurationId)
                        .map((a) => a.name)
                        .join(', '),
                })
            );
        },
        []
    );

    const columns: TableColumn<Row>[] = [
        {
            Header: 'Report ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 100,
        },
        {
            Header: 'Account',
            accessor: 'accountName',
            width: 150,
        },
        {
            Header: 'Advertiser',
            accessor: 'advertiserName',
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Name',
            accessor: 'name',
            maxWidth: 400,
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Last modified',
            accessor: 'lastModified',
            width: 130,
            Cell: ({ value }) => <UpdatedOn date={value} />,
        },
    ];

    return (
        <>
            <HeaderContainer>
                <PageHeader title="CM360 Reports" />
            </HeaderContainer>
            <BodyContainer helpKey="action_stats_report_list">
                <FormattedTable
                    columns={columns}
                    data={data}
                    loading={loading}
                    defaultSorted={[
                        { orderBy: 'accountName', direction: 'ASC' },
                        { orderBy: 'advertiserName', direction: 'ASC' },
                        { orderBy: 'name', direction: 'ASC' },
                    ]}
                    pageSize={200}
                />
            </BodyContainer>
        </>
    );
};

export default ActionStatsReportList;
