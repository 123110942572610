import { syncRequestTypeLabel } from 'platform/adminPanel/components/SyncJournal/syncJournal.constant';
import { SyncEntityType } from 'platform/adminPanel/components/SyncJournal/syncJournal.types';
import { SynchronizationState } from 'platform/campaign/campaign/campaign.types';
import { isDefined } from 'platform/common/common.types';
import { formatDateTime } from 'platform/common/utils/date.util';
import { ExternalSystem } from '../../constants/externalIntegration.constant';
import DspIntegrationIcon from '../DspIntegrationIcon/DspIntegrationIcon';

export type IntegrationSummary = {
    externalSystem: ExternalSystem;
    externalId?: string;
    lastImported?: string;
    synchronizationState: SynchronizationState;
};

type Props = {
    syncEntityType?: SyncEntityType;
    internalId?: number;
    integration: IntegrationSummary;
    onImport?: () => void;
    onResync?: (internalId: number, system: ExternalSystem) => void;
};

export const IntegrationDetailsIcon = (props: Props) => {
    const { syncEntityType, internalId, integration, onImport, onResync } = props;
    const { lastImported, externalSystem, externalId, synchronizationState } = integration;
    const { message, warnings, status, lastSucceeded, lastFailed, failedRequestTypes } = synchronizationState;

    const detailedStats = [
        lastImported ? `Last import: ${formatDateTime(lastImported)}` : undefined,
        lastSucceeded ? `Last successful sync: ${formatDateTime(lastSucceeded)}` : undefined,
        lastFailed ? `Last failed sync: ${formatDateTime(lastFailed)}` : undefined,
        failedRequestTypes?.length
            ? `What failed: ${failedRequestTypes.map(syncRequestTypeLabel).join(', ')}`
            : undefined,
    ];

    return (
        <DspIntegrationIcon
            systemName={externalSystem}
            status={status}
            enabled={status !== 'NEVER' || !!externalId}
            internalId={internalId}
            externalId={externalId}
            syncEntityType={syncEntityType}
            onImport={onImport}
            onResync={onResync}
            integrationMessage={message}
            subLabel={detailedStats.filter(isDefined).join('\n')}
            warning={warnings?.length ? warnings.join('\n\n') : undefined}
        />
    );
};
