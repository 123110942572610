import { Dispatch } from 'redux';
import { Feature } from 'platform/app/app.types';
import { IntegrationAttributes } from 'platform/campaign/campaign/campaign.types';
import { CampaignState } from 'platform/campaign/campaign/constants/state.constant';
import { IntegrationType } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import { UnitState } from 'platform/campaign/unit/constants/state.constant';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';

export type Context = {
    campaign?: {
        id: number;
        state: CampaignState;
        type: IntegrationType;
        budget?: number;
        dailyBudget?: number;
        attributes?: IntegrationAttributes;
        externalSystem?: ExternalSystem;
    };
    strategy?: {
        id: number;
        state: string;
    };
    units?: {
        id: number;
        state?: UnitState;
        stateLocked?: boolean;
        bidCpm?: number;
        budget?: number;
        dailyBudget?: number;
    }[];
    column?: string;
};

export enum ActionType {
    INLINE = 'INLINE',
    REDIRECT = 'REDIRECT',
}

export type InlineActionConnect = {
    action: string;
    type: ActionType.INLINE;
    requiresFeature?: Feature;
};

export type RedirectActionConnect = {
    action: string;
    type: ActionType.REDIRECT;
    label: string;
    requiresFeature?: Feature;
    operation: (row: { [key: string]: any }, dispatch: Dispatch, context?: Context) => void;
};

export type ActionConnect = InlineActionConnect | RedirectActionConnect;

export type ReportInlineActions = {
    actions: string[];
    context: Context;
};
