import { PeriodDimension } from 'platform/analytics/analytics.types';
import InlineSelect from 'platform/common/components/InlineSelect/InlineSelect';

interface Props {
    onChange: (option: { label: string; value: PeriodDimension }) => void;
    value: string;
}

const pivotOptions: { label: string; value: PeriodDimension }[] = [
    { label: 'By Day', value: 'date' },
    { label: 'By Week', value: 'week' },
    { label: 'By Month', value: 'month' },
];

const ReportPivotController = ({ onChange, value }: Props) => {
    const getOptionLabel = () => pivotOptions.find((item) => item.value === value)?.label;

    return (
        <div className="d-flex align-items-center text-muted ReportPivotController">
            <span>{getOptionLabel()}</span>
            <InlineSelect options={pivotOptions} value={value} onChange={(option) => onChange(option)} />
        </div>
    );
};

export default ReportPivotController;
