import { useParams } from 'react-router-dom';
import { toTemplatesApi, toTemplatesForm } from 'platform/algorithms/algorithm.mapper';
import { fetchAlgorithmTemplate, updateAlgorithmTemplate } from 'platform/algorithms/algorithms.service';
import { AlgorithmTemplateFormModel } from 'platform/algorithms/algorithms.types';
import AlgorithmTemplateForm from 'platform/algorithms/components/AlgorithmTemplateForm/AlgorithmTemplateForm';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';

const AlgorithmTemplateEdit = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const onOpen = () => fetchAlgorithmTemplate(id).then(toTemplatesForm);
    const onSubmit = (data: AlgorithmTemplateFormModel) => updateAlgorithmTemplate(id, toTemplatesApi(data));

    return (
        <FormContainer
            helpKey="algorithm_template_form"
            onSubmit={onSubmit}
            onOpen={onOpen}
            canEdit={canEdit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            sidePanel
            isEdit
        >
            {(props) => <AlgorithmTemplateForm {...props} />}
        </FormContainer>
    );
};

export default AlgorithmTemplateEdit;
