import SearchNotification from 'platform/common/components/SearchNotification/SearchNotification';

const LegacyNotification = () => (
    <div className="h-100 bg-white">
        <SearchNotification
            header="This report is outdated"
            text="This report uses an old version of Analytics which is no longer available. Please reach out to your client service contact to receive an updated version of this report. Thank you very much in advance!"
        >
            <i className="fa fa-info-circle text-info font-5xl" />
        </SearchNotification>
    </div>
);

export default LegacyNotification;
