import { makeOptions } from 'platform/common/utils/option.util';
import { WikiSpace } from './wiki.types';

const SPACE_LABELS: Record<WikiSpace, string> = {
    ACADEMY: 'Academy',
    MEDIA: 'Media',
    PLATFORM: 'Platform',
};

export const WIKI_SPACE_OPTIONS = makeOptions(SPACE_LABELS);
