import { useParams } from 'react-router-dom';
import {
    createPredefinedPrompt,
    getPredefinedPrompt,
    updatePredefinedPrompt,
} from 'platform/chat/chat.service';
import { PredefinedPromptFormModel } from 'platform/chat/chat.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import PredefinedPromptForm from './PredefinedPromptForm';

const PredefinedPromptFormContainer = ({ canEdit, afterSubmit, redirectTo }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = !!params.id && Number(params.id);

    const handleOpen = (): Promise<PredefinedPromptFormModel> => {
        if (id) {
            return getPredefinedPrompt(id).then((res) => res);
        }

        return Promise.resolve({
            name: '',
            order: 0,
            agentId: null,
            prompt: '',
            status: 'ACTIVE',
            description: '',
            forceLanguage: false,
            adminOnly: false,
            forceFileUpload: false,
            splitFilePrompt: false,
        });
    };

    const handleSubmit = (formValues: PredefinedPromptFormModel) => {
        if (id) {
            return updatePredefinedPrompt(id, formValues);
        }

        return createPredefinedPrompt(formValues);
    };

    return (
        <FormContainer
            helpKey="predefined_prompt_form"
            showEditWarning={false}
            isEdit={!!id}
            sidePanel
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            canEdit={canEdit}
            redirectTo={redirectTo}
            onOpen={handleOpen}
        >
            {(props) => <PredefinedPromptForm {...props} />}
        </FormContainer>
    );
};

export default PredefinedPromptFormContainer;
