import ControlledCardBorderless from 'platform/common/components/ControlledCard/ControlledCardBorderless';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';

interface Props {
    isCentral: boolean;
}

const ReportSaveAdvanced = ({ isCentral }: Props) => (
    <ControlledCardBorderless title="Advanced" className="mt-3 mb-3" bodyClassName="my-4" isExpanded={false}>
        <FormHelp className="align-items-center mb-3">
            <FormSwitch name="shouldSaveCampaigns" className="me-2" />
            <span>Save selected campaigns</span>
        </FormHelp>
        <FormHelp className="align-items-center mb-3">
            <FormSwitch name="shouldSaveStrategies" className="me-2" disabled={isCentral} />
            <span>Save selected strategies</span>
        </FormHelp>
        <FormHelp className="align-items-center mb-3">
            <FormSwitch name="shouldSaveDateRange" className="me-2" />
            <span>Save selected date period</span>
        </FormHelp>
    </ControlledCardBorderless>
);

export default ReportSaveAdvanced;
