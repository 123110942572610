import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { AxiosResponse } from 'axios';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import { isTaskBlocked, isTaskFinished } from 'platform/task/task.util';
import { User } from 'platform/userManagement/types/user.type';
import {
    Task,
    TaskBoardChange,
    TaskBoardState,
    TaskCreationData,
    TaskData,
    TaskLane,
    TaskLaneData,
    TaskLocation,
    TaskStatus,
} from '../task.types';
import KanbanCard from './KanbanCard';
import KanbanCardAdder from './KanbanCardAdder';
import KanbanColumnHeader from './KanbanColumnHeader';
import { KanbanFilterValues } from './KanbanFilters';
import {
    matchesAssigneeFilter,
    matchesDateFilter,
    matchesLabelFilter,
    matchesSearchFilter,
} from './KanbanPage';
import KanbanSubCard from './KanbanSubCard';

type Props = {
    board: TaskBoardState;
    lane: TaskLane;
    laneIndex: number;
    userOptions: User[];
    canEditColumn: boolean;
    filters: KanbanFilterValues;
    onStatusFilterChange: (newFilter: TaskStatus[]) => void;
    onLaneUpdate: (laneId: number, data: TaskLaneData) => Promise<void>;
    onLaneDelete: (laneId: number) => Promise<void>;
    onTaskAdd: (location: TaskLocation, data: TaskCreationData) => Promise<void>;
    onTaskUpdate: (taskId: number, data: Partial<TaskData>) => void;
    onTaskMove: (taskId: number, location: TaskLocation) => void;
    onTaskDelete: (taskId: number) => void;
    onTaskOpen: (taskId: number) => void;
    onTaskCopy: (task: Task) => void;
    onAttachmentDownload: (taskId: number, attachmentId: number) => Promise<AxiosResponse<Blob>>;
    onTaskWatchChange: (taskId: number, watch: boolean) => Promise<TaskBoardChange[]>;
};

const KanbanColumn = ({
    board,
    lane,
    laneIndex,
    userOptions,
    canEditColumn,
    filters,
    onStatusFilterChange,
    onLaneUpdate,
    onLaneDelete,
    onTaskAdd,
    onTaskUpdate,
    onTaskMove,
    onTaskDelete,
    onTaskOpen,
    onTaskCopy,
    onAttachmentDownload,
    onTaskWatchChange,
}: Props) => {
    const [addingTask, setAddingTask] = useState(false);
    const [expandedCardIds, setExpandedCardIds] = useState<number[]>([]);

    const handleTaskAdd = async (data: TaskCreationData) => {
        await onTaskAdd({ laneId: lane.id, position: 0 }, data);
        setAddingTask(false);
    };

    const matchesFilters = (task: Task) =>
        matchesSearchFilter(filters.search, task) &&
        matchesAssigneeFilter(filters.assignees.values, filters.assignees.operator, task) &&
        matchesLabelFilter(filters.labels.values, filters.labels.operator, task) &&
        matchesDateFilter(filters.date, task);

    const getFilterAdjustedCount = (status: TaskStatus) =>
        board.lanes
            .find((l) => l.id === lane.id)
            ?.tasks.filter((t) => t.status === status && matchesFilters(t)).length ?? 0;

    const archivedTaskCount = getFilterAdjustedCount('ARCHIVED');
    const doneTaskCount = getFilterAdjustedCount('DONE');

    const showTaskCount = (taskCount: number, taskStatus: TaskStatus) =>
        taskCount > 0 && !!filters.status.length && !filters.status.includes(taskStatus);

    return (
        <Draggable draggableId={`${lane.id}`} index={laneIndex} isDragDisabled={!canEditColumn}>
            {({ dragHandleProps, draggableProps, innerRef }) => (
                <div className="Kanban-column" ref={innerRef} {...draggableProps}>
                    <div {...dragHandleProps} className="Kanban-column-header-container">
                        <KanbanColumnHeader
                            boardId={board.id}
                            lane={lane}
                            userOptions={userOptions}
                            canEdit={canEditColumn}
                            onLaneUpdate={onLaneUpdate}
                            onLaneDelete={onLaneDelete}
                            onTaskAdd={() => setAddingTask(true)}
                        />
                    </div>
                    {addingTask && (
                        <KanbanCardAdder
                            className="me-3"
                            onSubmit={handleTaskAdd}
                            onCancel={() => setAddingTask(false)}
                        />
                    )}
                    <Droppable droppableId={`${lane.id}`} type="DRAGGABLE_TASK" direction="vertical">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="Kanban-column-cards-container"
                            >
                                <div className="Kanban-column-cards">
                                    {lane.tasks.map((task, taskIndex) => (
                                        <>
                                            <KanbanCard
                                                key={task.id}
                                                boardId={board.id}
                                                task={task}
                                                lane={lane}
                                                isBlocked={
                                                    !isTaskFinished(task, lane) &&
                                                    isTaskBlocked(task, board.lanes)
                                                }
                                                taskIndex={taskIndex}
                                                totalTasksInLane={lane.tasks.length}
                                                labelNames={board.labelNames ?? {}}
                                                userOptions={userOptions}
                                                expanded={expandedCardIds.includes(task.id)}
                                                onOpen={onTaskOpen}
                                                onAttachmentDownload={onAttachmentDownload}
                                                onUpdate={onTaskUpdate}
                                                onMove={onTaskMove}
                                                onDelete={onTaskDelete}
                                                onTaskCopy={onTaskCopy}
                                                onTaskWatchChange={onTaskWatchChange}
                                                onExpand={() =>
                                                    expandedCardIds.includes(task.id)
                                                        ? setExpandedCardIds(
                                                              expandedCardIds.filter((id) => id !== task.id)
                                                          )
                                                        : setExpandedCardIds([...expandedCardIds, task.id])
                                                }
                                            />
                                            {expandedCardIds.includes(task.id) &&
                                                task.subtasks?.map((subtask, index) => (
                                                    <div className="d-flex align-items-center ps-2">
                                                        <i className="fas fa-diagram-subtask text-muted ms-1 me-4" />
                                                        <KanbanSubCard
                                                            boardId={board.id}
                                                            subtask={subtask}
                                                            position={index}
                                                            totalSubtasks={task.subtasks?.length ?? 0}
                                                            userOptions={userOptions}
                                                            onClick={() => onTaskOpen(subtask.id)}
                                                            onMove={(position) =>
                                                                onTaskMove(subtask.id, {
                                                                    parentTaskId: task.id,
                                                                    position,
                                                                })
                                                            }
                                                            onUpdate={(data) =>
                                                                onTaskUpdate(subtask.id, data)
                                                            }
                                                            onDelete={() => onTaskDelete(subtask.id)}
                                                            onTaskWatchChange={onTaskWatchChange}
                                                        />
                                                    </div>
                                                ))}
                                        </>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            </div>
                        )}
                    </Droppable>
                    {showTaskCount(archivedTaskCount, 'ARCHIVED') && (
                        <LabelLink
                            className="Kanban-column-bottom-label mt-2 text-start text-muted"
                            onClick={() => onStatusFilterChange([...filters.status, 'ARCHIVED'])}
                        >
                            <b>{archivedTaskCount}</b> archived task
                            {archivedTaskCount !== 1 && 's'}
                        </LabelLink>
                    )}
                    {showTaskCount(doneTaskCount, 'DONE') && (
                        <LabelLink
                            className="Kanban-column-bottom-label mt-2 text-start text-muted"
                            onClick={() => onStatusFilterChange([...filters.status, 'DONE'])}
                        >
                            <b>{doneTaskCount}</b> done task
                            {doneTaskCount !== 1 && 's'}
                        </LabelLink>
                    )}
                </div>
            )}
        </Draggable>
    );
};

export default KanbanColumn;
