import { useContext } from 'react';
import { ClassificationOptionsContext } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableForm/ClassificationOptionsContext';
import FormSelect, { FormSelectProps } from 'platform/formik/FormSelect/FormSelect';

type Props = Omit<FormSelectProps, 'options'>;

const FormCountryLabelsSelect = ({ ...rest }: Props) => {
    const [{ countryLabels }, loading] = useContext(ClassificationOptionsContext);

    return (
        <FormSelect
            {...rest}
            isMulti
            containerClassName="flex-grow-1"
            returnOnlyValues={false}
            options={countryLabels}
            placeholder="Select..."
            isLoading={loading}
        />
    );
};

export default FormCountryLabelsSelect;
