import { Col } from 'reactstrap';
import classNames from 'classnames';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { CURRENCY_SYMBOL } from 'platform/common/constants/common.constant';
import { makeOptions } from 'platform/common/utils/option.util';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import MediaInsertionPopoverButton from 'platform/mediaplan/components/MediaplanTreeForm/MediaInsertionPopoverButton';
import { VOLUME_TYPE_LABELS } from 'platform/mediaplan/mediaplan.types';
import { PricingType } from 'platform/units/units.type';
import './MediaNode.scss';

interface Props {
    field: string;
    pricing: number | undefined;
    volume: number | undefined;
}

const MediaInsertionPricingAndVolume = ({ field, pricing, volume }: Props) => (
    <MediaInsertionPopoverButton
        iconClassName={classNames('fa-credit-card', { 'text-success': !!pricing || !!volume })}
    >
        {() => (
            <>
                <div className="MediaNode-popoverTitle">Pricing and Volume</div>
                <FormRow label="Pricing" labelWidth={3} rowClassName="mx-0" childrenColClassName="d-flex">
                    <FormSelect
                        name={`${field}.pricingType`}
                        options={makeOptions(PricingType)}
                        className="flex-grow-1"
                    />
                    <Col xs={6} className="pe-0 ps-3">
                        <FormNumberInput name={`${field}.pricing`} label={CURRENCY_SYMBOL} />
                    </Col>
                </FormRow>
                <FormRow
                    label="Booked volume"
                    rowClassName="m-0"
                    labelWidth={3}
                    childrenColClassName="d-flex"
                >
                    <FormSelect
                        name={`${field}.volumeType`}
                        options={makeOptions(VOLUME_TYPE_LABELS)}
                        className="flex-grow-1"
                    />
                    <Col xs={6} className="pe-0 ps-3">
                        <FormNumberInput name={`${field}.volume`} />
                    </Col>
                </FormRow>
            </>
        )}
    </MediaInsertionPopoverButton>
);

export default MediaInsertionPricingAndVolume;
