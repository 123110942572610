import { CSSProperties, MouseEvent } from 'react';
import classNames from 'classnames';
import { MetricDefinition } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import VatIcon from 'platform/analytics/reportComponents/VatIcon/VatIcon';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { useAdvancedSetting } from 'platform/common/hooks/useAdvancedSetting';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';

interface Props {
    metric?: MetricDefinition;
    className?: string;
    style?: CSSProperties;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const ReportMetricIcons = ({ metric, className, style, onClick }: Props) => {
    const { includeVatRate, modelOptIn } = useTypedSelector(analyticsSelectors.settings);
    const hasVat = !!useAdvancedSetting('analytics.vat-rate') && !!includeVatRate;
    const hasOptIn = !!useAdvancedSetting('analytics.opt-in-rate') && !!modelOptIn;
    if ((!hasVat && !hasOptIn) || !metric) {
        return null;
    }

    const showVat = hasVat && !!metric.vat;
    const showOptIn = hasOptIn && !!metric.optInEffect;

    return (
        <div role="presentation" className={classNames('d-flex', className)} style={style} onClick={onClick}>
            {showOptIn && (
                <Tooltip renderTooltip={() => 'This metric is using Opt-In Modelling'}>
                    <i
                        className={classNames('fa fa-arrow-up-right-dots text-primary', { 'me-3': showVat })}
                    />
                </Tooltip>
            )}
            {showVat && <VatIcon />}
        </div>
    );
};

export default ReportMetricIcons;
