import { useParams } from 'react-router-dom';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { createModelEndpoint, getModelEndpoint, updateModelEndpoint } from '../../chat.service';
import { ModelEndpointFormModel } from '../../chat.types';
import ModelEndpointForm from './ModelEndpointForm';

interface Props {
    canEdit: boolean;
    redirectTo: string;
    afterSubmit?: () => void;
}

const initialModel: ModelEndpointFormModel = {
    status: 'ACTIVE',
    name: '',
    description: '',
    endpoint: '',
    apiKey: '',
    modelType: 'GPT_35',
};

const ModelEndpointFormContainer = ({ canEdit, afterSubmit, redirectTo }: Props) => {
    const params = useParams<{ id: string }>();
    const id = !!params.id && Number(params.id);

    return (
        <FormContainer<ModelEndpointFormModel>
            helpKey="model_endpoint_form"
            showEditWarning={false}
            isEdit={!!id}
            sidePanel
            onOpen={async () => (id ? getModelEndpoint(id) : initialModel)}
            onSubmit={(model) => (id ? updateModelEndpoint(id, model) : createModelEndpoint(model))}
            onSubmitFinish={afterSubmit}
            canEdit={canEdit}
            redirectTo={redirectTo}
        >
            {(props) => <ModelEndpointForm {...props} />}
        </FormContainer>
    );
};

export default ModelEndpointFormContainer;
