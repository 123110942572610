import { Children, ReactElement, ReactNode } from 'react';
import { useFormikContext } from 'formik';

const getChildName = (children: ReactNode) =>
    (Children.toArray(children).find((child: ReactElement) => child?.props?.name) as ReactElement)?.props
        ?.name;

export const useFormHelp = (children: ReactNode, helpKey?: string) => {
    const formikContext = useFormikContext<{ helpKey: string }>();
    const name = getChildName(children);

    return (
        helpKey ?? (name && formikContext?.values?.helpKey ? `${formikContext.values.helpKey}_${name}` : '')
    );
};
