import { CampaignSearchType } from 'platform/campaign/campaign/services/campaign.service';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { OptionType } from 'platform/common/components/Select/select.types';

export interface GlobalSearchFilter {
    key: 'mediaplanStatuses' | 'campaignStatuses' | 'unitStatuses' | 'vendorSystems';
    name: string;
    type: CampaignSearchType;
    values: string[];
    options: OptionType[];
}

interface Props {
    filters: GlobalSearchFilter[];
    className: string;
    onFiltersChange: (filters: GlobalSearchFilter[]) => void;
}

const GlobalSearchFilters = ({ filters, className, onFiltersChange }: Props) => (
    <>
        <h5 className="mb-3">Filters</h5>
        <div className="h-100">
            <ListFilters className={className} childrenClassName="w-100">
                {filters.map((filter) => (
                    <SelectWithAddon
                        key={filter.key}
                        name={filter.name}
                        value={filter.values}
                        options={filter.options}
                        selectClassname="w-100"
                        isClearable
                        isMulti
                        onChange={(values) =>
                            onFiltersChange(filters.map((f) => (f.key === filter.key ? { ...f, values } : f)))
                        }
                    />
                ))}
            </ListFilters>
        </div>
    </>
);

export default GlobalSearchFilters;
