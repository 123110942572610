import classNames from 'classnames';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const InfoIconAlert = ({ children, className }: Props) => (
    <div className={classNames('border-primary rounded d-flex align-items-center p-2 bg-white', className)}>
        <i className="text-primary fal fa-info-circle me-2" />
        {children}
    </div>
);

export default InfoIconAlert;
