import { useSelector } from 'react-redux';
import { SEARCH_TERM_STRATEGY_LABELS, SearchTermStrategy } from 'platform/campaign/campaign/campaign.types';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { fromSystemCode } from 'platform/common/utils/formatters.util';
import ChannelIcon from './ChannelIcon';

interface Props {
    withIcon?: boolean;
    channel: string | undefined;
    searchTermStrategy?: SearchTermStrategy;
}

const ChannelLabel = ({ withIcon, channel: code, searchTermStrategy: sts }: Props) => {
    const channel = useSelector(classifierSelectors.channels).find((c) => c.code === code);

    if (!code) return null;

    const name = channel?.name ?? fromSystemCode(code);
    const fullName = code === 'SEARCH' && sts ? `${name} / ${SEARCH_TERM_STRATEGY_LABELS[sts] ?? sts}` : name;

    return withIcon ? (
        <span className="d-flex align-items-center text-nowrap">
            <ChannelIcon channel={channel} className="me-1" />
            {fullName}
        </span>
    ) : (
        <>{fullName}</>
    );
};

export default ChannelLabel;
