import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { InputGroup, InputGroupText } from 'reactstrap';
import classnames from 'classnames';
import SelectTree from 'platform/common/components/SelectTree/SelectTree';
import TextWithEllipsis from 'platform/common/components/TextWithEllipsis/TextWithEllipsis';
import {
    activeAdvertiserSelectors,
    AdvertiserOrAgentOption,
    AdvertiserTreeOption,
} from 'platform/common/ducks/activeAdvertiser.duck';

export type ValueType = string | number | Object | string[] | number[] | Object[];

type AdvertiserSelectProps = {
    onChange: (value: ValueType) => void;
    onBlur?: () => void;
    value?: ValueType;
    isMulti?: boolean;
    disabled?: boolean;
    invalid?: boolean;
    isClearable?: boolean;
    closeMenuOnSelect?: boolean;
    placeholder?: string;
    filterOption?: (option: AdvertiserOrAgentOption, query: string) => boolean;
    className?: string;
    inputClassName?: string;
    endAddon?: string;
    selectStyle?: any;
    firstAddon?: string;
};

const AdvertiserSelect = ({
    firstAddon,
    endAddon,
    onChange,
    onBlur,
    value,
    isMulti,
    invalid,
    isClearable = false,
    closeMenuOnSelect = true,
    disabled,
    placeholder,
    filterOption,
    className,
    inputClassName,
    selectStyle,
}: AdvertiserSelectProps) => {
    const advertisers = useSelector(activeAdvertiserSelectors.optionsTree);

    const leftBorderRadius: CSSProperties = {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
    };

    const selectRemoveStyle: CSSProperties = {
        borderRight: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    };

    const getSelectStyle = (removeSelectStyle: boolean, style?: CSSProperties) => {
        if (removeSelectStyle) {
            return { ...style, ...leftBorderRadius, ...selectRemoveStyle };
        }

        if (!firstAddon) {
            return style;
        }

        return { ...style, ...leftBorderRadius };
    };

    const inputComponent = (
        <SelectTree<AdvertiserTreeOption>
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            getOptionParentLabel={(option) => option.name}
            childrenKey="children"
            options={advertisers}
            value={value}
            isMulti={isMulti}
            invalid={invalid}
            onChange={onChange}
            onBlur={onBlur}
            className={inputClassName}
            selectStyle={getSelectStyle(!!endAddon && !disabled, selectStyle)}
            closeMenuOnSelect={closeMenuOnSelect}
            isClearable={isClearable}
            isDisabled={disabled}
            placeholder={placeholder}
            filterOption={filterOption}
        />
    );
    if (firstAddon)
        return (
            <InputGroup
                className={classnames('flex-nowrap', className, {
                    'form-control is-invalid m-0 p-0': invalid,
                })}
            >
                <InputGroupText className="rounded-start">
                    <TextWithEllipsis>{firstAddon}</TextWithEllipsis>
                </InputGroupText>
                {inputComponent}
            </InputGroup>
        );

    return <>{inputComponent}</>;
};

export default AdvertiserSelect;
