import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';
import { MediaInsertionStepType, MediaplanStepType } from 'platform/mediaplan/mediaplan.types';
import ControlledStep from './ControlledStep';
import StageCardHeader from './StageCardHeader';
import SummaryStep from './SummaryStep';
import { WorkflowStagesProps } from './WorkflowStages';
import useSummarySteps from './useSummarySteps';

const summarySteps: MediaInsertionStepType[] = ['CAMPAIGN_SETUP', 'IO_SCRIPTS_SENT', 'IO_PREVIEW_APPROVED'];
const controlledSteps: MediaplanStepType[] = ['ADDITIONAL_TRACKING_REQUIREMENT'];

const TechnicalSetupStage = ({ lanes, ...rest }: WorkflowStagesProps) => {
    const { stepPropsByType } = useSummarySteps(summarySteps, lanes);

    return (
        <SimpleCard
            headerContent={
                <StageCardHeader
                    icon={<i className="fa-solid fa-wrench text-white font-3xl" />}
                    text="Technical setup"
                />
            }
        >
            {summarySteps.map((step, i) => (
                <SummaryStep key={i} {...stepPropsByType[step]} />
            ))}
            {controlledSteps.map((stepType, i) => (
                <ControlledStep type={stepType} key={i} {...rest} />
            ))}
        </SimpleCard>
    );
};

export default TechnicalSetupStage;
