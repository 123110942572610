import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import ColumnDefinitionFormContainer from 'platform/adminPanel/components/ColumnDefinitions/ColumnDefinitionFormContainer';
import { fetchAnalyticsColumns } from 'platform/analytics/analytics.service';
import {
    COLUMN_TYPE_LABELS,
    COLUMN_TYPE_OPTIONS,
    ColumnDefinition,
    ColumnGroup,
    ColumnType,
    DEFAULT_COLUMN_GROUP_KEY,
} from 'platform/analytics/analytics.types';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import DMSwitch from 'platform/common/components/Switch/Switch';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterBySearchQuery } from 'platform/common/utils/search.util';

export type ColumnWithType = ColumnDefinition & { columnType: ColumnType };

const searchQueryFields: (keyof ColumnDefinition)[] = ['key', 'name', 'altName', 'description'];

const toRow = (columnType: ColumnType, definition: ColumnDefinition): ColumnWithType => {
    const {
        group = DEFAULT_COLUMN_GROUP_KEY,
        key,
        name,
        altName,
        description,
        source,
        includeInDeeply,
    } = definition;
    return { columnType, group, key, name, altName, description, source, includeInDeeply };
};

const PATH = '/admin-panel/dimensions-and-metrics';

type QueryParams = {
    type?: ColumnType;
    group: string;
    searchQuery: string;
};

const ColumnDefinitionList = () => {
    const dispatch = useDispatch();
    const canEdit = useFeature('ADMIN_EDIT');
    const { columnGroups } = useAnalyticsMetadata();

    const {
        queryParams: { type, searchQuery, group },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>(
        {
            type: undefined,
            searchQuery: '',
            group: '',
        },
        PATH
    );

    const [
        {
            data: { dimensions, metrics },
            loading,
        },
        refetch,
    ] = usePromise({ dimensions: [], metrics: [] }, fetchAnalyticsColumns, []);

    const analyticsColumns = [
        ...dimensions.map((d) => toRow('DIMENSION', d)),
        ...metrics.map((m) => toRow('METRIC', m)),
    ];
    const filteredRows = filterBySearchQuery(
        analyticsColumns.filter((r) => (!type || type === r.columnType) && (!group || group === r.group)),
        searchQueryFields,
        searchQuery
    );

    const tableColumns: TableColumn<ColumnWithType>[] = [
        {
            Header: 'Type',
            accessor: 'type',
            width: 90,
            Cell: ({ original: row }) => COLUMN_TYPE_LABELS[row.columnType],
        },
        {
            Header: 'Section',
            accessor: 'group',
            width: 120,
            Cell: ({ original: row }) => columnGroups.find((g) => g.key === row.group)?.name ?? row.group,
        },
        {
            Header: 'Key',
            accessor: 'key',
            maxWidth: 350,
        },
        {
            Header: 'Name',
            accessor: 'name',
            maxWidth: 350,
        },
        {
            Header: 'Alternative name',
            accessor: 'altName',
            maxWidth: 350,
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Data source',
            accessor: 'source',
            width: 120,
        },
        {
            id: 'includeInDeeply',
            Header: 'Use in Deeply',
            width: 50,
            Cell: ({ original: row }) => (
                <DMSwitch input={{ value: row.includeInDeeply || false }} disabled />
            ),
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original: row }) => (
                <InlineDropdown
                    items={[
                        {
                            label: canEdit ? 'Edit' : 'View',
                            action: () => dispatch(push(`${PATH}/${row.key}`)),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="column_definition_list">
                {!canEdit && <CanNotEditWarning />}
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Type"
                        value={type}
                        options={COLUMN_TYPE_OPTIONS}
                        onChange={(value) => setQueryParams({ type: value })}
                    />
                    <SelectWithAddon
                        name="Section"
                        value={group}
                        options={columnGroups}
                        getOptionValue={(o: ColumnGroup) => o.key}
                        getOptionLabel={(o: ColumnGroup) => o.name}
                        onChange={(value) => setQueryParams({ group: value })}
                    />
                </ListFilters>
                <FormattedTable
                    topToolbar={<ListToolbar onSearch={(value) => setQueryParams({ searchQuery: value })} />}
                    columns={tableColumns}
                    loading={loading}
                    data={filteredRows}
                    defaultSorted={[
                        { orderBy: 'Type', direction: 'ASC' },
                        { orderBy: 'Section', direction: 'ASC' },
                        { orderBy: 'Key', direction: 'ASC' },
                    ]}
                    pageSize={200}
                />
            </BodyContainer>

            <Routes>
                <Route
                    path=":key"
                    element={
                        <ColumnDefinitionFormContainer
                            columns={analyticsColumns}
                            columnGroups={columnGroups}
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default ColumnDefinitionList;
