import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { AnalyticsProps } from 'platform/analytics/modes/Analytics';
import CampaignToolBar from 'platform/analytics/modes/CampaignAnalytics/CampaignToolBar';
import RedirectScriptsModal from 'platform/analytics/modes/CampaignAnalytics/RedirectScriptsModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import useActiveAdvertisersComponent from 'platform/common/hooks/useActiveAdvertisers';

const CampaignAnalytics = ({ children }: { children: (props: AnalyticsProps) => ReactNode }) => {
    const { showModal } = useModal();
    useActiveAdvertisersComponent();
    const singleAdvertiserMode = useSelector(analyticsSelectors.singleAdvertiserMode);
    const filters = useSelector(analyticsSelectors.filtersWithAdvertiser);

    return (
        <>
            {children({
                componentsProps: {
                    TABLE: {
                        Toolbar: ({ afterUpdate }) => (
                            <CampaignToolBar filters={filters} afterUpdate={afterUpdate} />
                        ),
                        exportActions: singleAdvertiserMode
                            ? [
                                  {
                                      label: 'IO Scripts',
                                      action: () =>
                                          showModal((toggle) => (
                                              <RedirectScriptsModal toggle={toggle} filters={filters} />
                                          )),
                                  },
                              ]
                            : undefined,
                    },
                },
            })}
        </>
    );
};

export default CampaignAnalytics;
