import { useSelector } from 'react-redux';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { entitiesToOptions } from 'platform/common/utils/option.util';
import TimeToActionChart from 'platform/segments/components/SegmentCharts/TimeToActionChart';
import { fetchSegmentList } from 'platform/segments/services/segments.service';

type Props = {
    advertiserId: number;
    segmentId: number;
};

const SegmentAnalysisTimeToAction = ({ advertiserId, segmentId }: Props) => {
    const isDemoMode = useSelector(authSelectors.isDemoModeEnabled);
    const [{ data: segmentOptions }] = usePromise(
        [],
        () => fetchSegmentList({ advertiserId }, isDemoMode).then(entitiesToOptions),
        [advertiserId]
    );

    return <TimeToActionChart advertiserId={advertiserId} segments={segmentOptions} segmentId={segmentId} />;
};

export default SegmentAnalysisTimeToAction;
