import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { TASK_STATUS_LABELS } from '../task.types';

const TaskStatusIcon = ({ status }: { status: 'DONE' | 'ARCHIVED' }) => (
    <Tooltip renderTooltip={() => TASK_STATUS_LABELS[status]}>
        <i className={`far fa-lg ${status === 'DONE' ? 'fa-circle-check' : 'fa-box-archive'} me-2`} />
    </Tooltip>
);

export default TaskStatusIcon;
