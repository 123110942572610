import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { AdvertiserTag } from 'platform/dashboard/dashboard.service';
import { isVendorActiveInAdvertiserTag } from 'platform/segments/utils/segmentSyncPanel.util';
import VendorLabel from 'platform/vendors/components/VendorLabel';
import './SegmentVendorList.scss';

type Props = {
    advertiserTag: AdvertiserTag | undefined;
    style?: CSSProperties;
};

const SegmentVendorList = ({ advertiserTag, style }: Props) => {
    const vendors = useSelector(classifierSelectors.vendors).filter((v) => v.useInCDP);

    return (
        <div className="SegmentVendorList" style={style}>
            {vendors.map((vendor) => (
                <div key={vendor.id} className="SegmentVendorList-item">
                    <i
                        className={`fa fa-circle me-1 ${
                            isVendorActiveInAdvertiserTag(vendor.id!, advertiserTag)
                                ? 'indicator--active'
                                : 'indicator--inactive'
                        }`}
                    />
                    <VendorLabel vendorCode={vendor.externalSystem} />
                </div>
            ))}
        </div>
    );
};

export default SegmentVendorList;
