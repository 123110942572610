import moment from 'moment';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { findById } from 'platform/common/utils/array.util';
import { User } from 'platform/userManagement/types/user.type';
import {
    ParentLaneLocation,
    ParentTaskLocation,
    Task,
    TaskBoardChange,
    TaskBoardState,
    TaskLane,
    TaskLocation,
} from './task.types';

export const isParentLane = (loc: TaskLocation): loc is ParentLaneLocation => 'laneId' in loc;

export const isParentTask = (loc: TaskLocation): loc is ParentTaskLocation => 'parentTaskId' in loc;

export const isTaskFinished = (task: Task, lane?: TaskLane) => {
    if (task.status === 'DONE' || task.status === 'ARCHIVED') return true;
    return lane?.type === 'WORK_FINISHED' || lane?.type === 'DONE';
};

export const isTaskBlocked = (task: Task, lanes: TaskLane[]) =>
    !!task.blockedBy?.some((otherTaskId) => {
        const upstream = findTask(lanes, otherTaskId);
        return upstream && !isTaskFinished(upstream.task, upstream.lane);
    });

export const findTask = (
    lanes: TaskLane[],
    taskId: number
): { task: Task; lane: TaskLane; parentTaskId?: number } | undefined =>
    lanes
        .flatMap((lane) => lane.tasks.map((task) => ({ lane, task })))
        .reduce((found, { lane, task }) => {
            if (found) return found;
            if (task.id === taskId) return { task, lane };
            const subtask = findById(task.subtasks, taskId);
            if (subtask) return { task: subtask, lane, parentTaskId: task.id };
            return undefined;
        }, undefined);

interface LaneOptions {
    id: number;
    name: string;
}

export const formatTaskBoardHistoryData = (
    board: TaskBoardState,
    userOptions: User[],
    filteredChanges: TaskBoardChange[],
    lanesData: LaneOptions[],
    tasksData: LaneOptions[]
) => {
    const getLaneName = (laneId: number) => lanesData.find((lane) => lane.id === laneId)?.name || laneId;

    const getTaskName = (taskId: number) => tasksData.find((task) => task.id === taskId)?.name || taskId;

    const getUserNames = (userIds: number[]) =>
        userIds.map((userId) => userOptions.find((op) => op.id === userId)?.name ?? userId);

    const getLabelNames = (labelKeys: number[], labelNames: Record<number, string>) =>
        labelKeys.map((labelKey) => labelNames[labelKey]);

    return filteredChanges.map((item: any) => {
        const laneName = getLaneName(item.laneId);
        const taskName = getTaskName(item.taskId);

        const detailsData: any = {};

        if (item?.details?.data?.description) {
            detailsData.description = 'changed';
        }

        if (item?.details?.data?.notifiedUserIds) {
            detailsData.notifiedusernames = getUserNames(item.details.data.notifiedUserIds);
        }

        if (item?.details?.data?.assigneeIds) {
            detailsData.assigneenames = getUserNames(item.details.data.assigneeIds);
        }

        if (item?.details?.data?.labelKeys && board?.labelNames) {
            detailsData.labels = getLabelNames(item.details.data.labelKeys, board.labelNames);
        }

        return {
            by: item?.updatedBy,
            on: moment(item?.updatedOn).format(ISO_DATE_FORMAT),
            lane: laneName,
            task: taskName,
            type: item?.type,
            changes: {
                ...item.details.data,
                ...detailsData,
            },
        };
    });
};
