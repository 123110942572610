import classNames from 'classnames';
import './Modal.scss';

interface Props {
    className?: string;
    onClick: () => void;
}

const ModalHeaderCloseButton = ({ className, onClick }: Props) => (
    <button type="button" className={classNames('ModalHeader-close', className)} onClick={onClick}>
        <i className="far fa-circle-xmark" />
    </button>
);

export default ModalHeaderCloseButton;
