import { useSelector } from 'react-redux';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import CreativeLabelForm from 'platform/creatives/components/CreativeLabelForm/CreativeLabelForm';
import { createCreativeLabel } from 'platform/creatives/services/creativeLabel.service';
import { CreativeLabelGroup } from 'platform/creatives/types/creativeLabel.types';

const CreativeLabelCreate = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const handleOpen = async (): Promise<CreativeLabelGroup> => ({ name: '', labels: [], advertiserId });

    const handleSubmit = (data: CreativeLabelGroup) => createCreativeLabel(data);

    return (
        <FormContainer
            helpKey="creative_label_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={false}
            sidePanel
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <CreativeLabelForm {...props} />}
        </FormContainer>
    );
};

export default CreativeLabelCreate;
