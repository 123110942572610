import { groupBy, isEmpty, orderBy } from 'lodash-es';
import { Advertiser } from 'platform/advertisers/advertiser.types';
import { Campaign } from 'platform/campaign/campaign/campaign.types';
import { campaignMappingLink } from 'platform/campaign/campaign/components/CampaignForm/CampaignFormVendor';
import { getInsertion, getMediaInsertionLinks } from 'platform/campaign/campaign/services/mediaplan.service';
import { Strategy } from 'platform/campaign/strategy/strategy.types';
import { Unit } from 'platform/campaign/unit/unit.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { CURRENCY_SYMBOL } from 'platform/common/constants/common.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { makeOptions } from 'platform/common/utils/option.util';
import { positiveNumber, required } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { MediaInsertionLink } from 'platform/mediaplan/mediaplan.types';
import { PricingType, SpendCalculationType, UnitIOSpends } from 'platform/units/units.type';
import { Vendor } from 'platform/vendors/vendors.types';
import SpendTable from './SpendTable';

export interface UnitFormType {
    ioSpends: UnitIOSpends;
    advertiser: Advertiser;
    campaign: Campaign;
    strategy: Strategy;
    unit: Unit;
    vendor: Vendor;
    dates: {
        from?: string;
        to?: string;
    };
    bookedPricing: boolean;
}

type MediaplanTreeOption = {
    mediaplanName: string;
    nodes?: MediaInsertionLink[];
};

const UnitForm = ({
    onCancel,
    canEdit,
    labels,
    formikProps: {
        initialValues,
        submitForm,
        setFieldValue,
        setFieldError,
        errors,
        values: { ioSpends, unit, vendor, dates, bookedPricing, campaign },
    },
}: FormProps<UnitFormType>) => {
    const { calculationType, defaultPricing, vendorExternalId } = ioSpends;
    const storedData: UnitIOSpends = initialValues.ioSpends;
    const setBookedPricing = async (mediaInsertionLink?: MediaInsertionLink) => {
        const mediaInsertion =
            mediaInsertionLink &&
            (await getInsertion(mediaInsertionLink.mediaplanId, mediaInsertionLink.mediaInsertionId));
        setFieldValue('mediaInsertionLink', mediaInsertionLink);
        setFieldValue('ioSpends.bookedPricing.type', mediaInsertion && mediaInsertion.pricingType);
        setFieldValue('ioSpends.bookedPricing.multiplier', mediaInsertion && mediaInsertion.pricing);
    };

    const [{ data: mediaPlanTreeOptions, loading: mediaPlanTreeOptionsLoading }] = usePromise<
        MediaplanTreeOption[]
    >(
        [],
        async () => {
            if (!campaign.id || !bookedPricing) return Promise.resolve([]);
            const response = await getMediaInsertionLinks({ campaignId: campaign.id });
            if (response.length === 1) await setBookedPricing(response[0]);
            return orderBy(
                Object.values(groupBy(response, (i) => i.mediaplanId)).map(
                    (insertions): MediaplanTreeOption => ({
                        mediaplanName: insertions[0].mediaplanName,
                        nodes: orderBy(insertions, (i) => i.mediaInsertionName),
                    })
                ),
                (option) => option.mediaplanName
            );
        },
        [campaign.id, bookedPricing]
    );

    return (
        <CardForm
            title={`${labels.prefix} IO Spend setup`}
            subtitle={unit.id ? `ID: ${unit.id}` : null}
            submitLabel={labels.submit}
            animationDelay={0}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info">
                <FormRow label="Advertiser">
                    <FormInput name="advertiser.name" type="text" disabled />
                </FormRow>
                <FormRow label="Campaign">
                    <FormInput name="campaign.name" type="text" disabled />
                </FormRow>
                <FormRow label="Vendor">
                    <FormInput name="vendor.name" type="text" disabled />
                </FormRow>
                <FormRow label="Strategy">
                    <FormInput name="strategy.name" type="text" disabled />
                </FormRow>
                <FormRow label="Unit">
                    <FormInput name="unit.name" type="text" disabled />
                </FormRow>
                <FormRow label="Notes">
                    <FormInput name="ioSpends.notes" type="textarea" />
                </FormRow>
            </ControlledCard>
            <ControlledCard title="Spend calculation">
                <FormRow label="Flight dates">
                    <FormDateRangePicker name="dates" disabled />
                </FormRow>
                <FormRow label="Spend calculation type">
                    <FormButtonArray
                        name="ioSpends.calculationType"
                        buttons={[
                            {
                                value: SpendCalculationType.MANUAL,
                                label: 'Manual',
                                disabled: !!vendorExternalId,
                            },
                            {
                                value: SpendCalculationType.AUTOMATED,
                                label: 'Automated',
                                disabled: !vendor.costIntegrationEnabled,
                            },
                        ]}
                    />
                </FormRow>
                {calculationType === SpendCalculationType.AUTOMATED && (
                    <FormRow label={`${vendor.name} ID`}>
                        <div className="d-flex align-items-center">
                            <FormInput name="ioSpends.vendorExternalId" validate={required} type="text" />
                            <a
                                target="_blank"
                                title="External IDs"
                                className="ms-1"
                                rel="noopener noreferrer"
                                href={campaignMappingLink}
                            >
                                <i className="fa fa-external-link-alt text-muted" />
                            </a>
                        </div>
                    </FormRow>
                )}
                {(calculationType === SpendCalculationType.MANUAL ||
                    storedData.defaultPricing?.multiplier) && (
                    <>
                        <FormRow label="Default pricing">
                            <FormSelect
                                name="ioSpends.defaultPricing.type"
                                options={makeOptions(PricingType)}
                                disabled={calculationType === SpendCalculationType.AUTOMATED}
                                onChange={(value: string | null) => {
                                    if (!value) {
                                        setFieldValue('ioSpends.defaultPricing.multiplier', null);
                                    }
                                }}
                                isClearable
                                placeholder="Not set"
                                id="defaultPricing.type"
                            />
                            {defaultPricing && defaultPricing.type && (
                                <FormInput
                                    name="ioSpends.defaultPricing.multiplier"
                                    className="mt-3"
                                    rightAddOn={{ title: CURRENCY_SYMBOL }}
                                    disabled={calculationType === SpendCalculationType.AUTOMATED}
                                    validate={[required, positiveNumber]}
                                    type="number"
                                />
                            )}
                        </FormRow>
                        <FormRow label="Booked pricing">
                            <FormSwitch name="bookedPricing" />
                        </FormRow>
                        {bookedPricing && (
                            <>
                                <FormRow label="Media insertion">
                                    <FormSelectTree
                                        name="mediaInsertionLink"
                                        options={mediaPlanTreeOptions}
                                        isLoading={mediaPlanTreeOptionsLoading}
                                        onChange={(mediaInsertionLink: MediaInsertionLink) => {
                                            setBookedPricing(
                                                isEmpty(mediaInsertionLink) ? undefined : mediaInsertionLink
                                            );
                                        }}
                                        getOptionValue={(i: MediaInsertionLink) => i.mediaInsertionId}
                                        getOptionLabel={(i: MediaInsertionLink) =>
                                            `${i.mediaInsertionName} (${i.mediaInsertionDateFrom} - ${i.mediaInsertionDateTo})`
                                        }
                                        getOptionParentLabel={(o: MediaplanTreeOption) => o.mediaplanName}
                                        isBreadcrumbsShown={false}
                                        returnOnlyValues={false}
                                    />
                                </FormRow>
                                <FormRow label="Pricing" childrenColClassName="d-flex">
                                    <FormSelect
                                        name="ioSpends.bookedPricing.type"
                                        options={makeOptions(PricingType)}
                                        className="flex-grow-1 w-100"
                                        disabled
                                    />
                                    <FormNumberInput
                                        containerClassName="ms-3 w-100"
                                        name="ioSpends.bookedPricing.multiplier"
                                        label={CURRENCY_SYMBOL}
                                        disabled
                                    />
                                </FormRow>
                            </>
                        )}
                    </>
                )}
                {(calculationType === SpendCalculationType.MANUAL || storedData.datedSpends?.length) && (
                    <SpendTable
                        value={ioSpends.datedSpends ?? []}
                        datesRange={dates}
                        bookedPricing={ioSpends.bookedPricing}
                        error={errors.ioSpends?.datedSpends}
                        disabled={calculationType === SpendCalculationType.AUTOMATED}
                        onChange={(datedSpends, error) => {
                            setFieldValue('ioSpends.datedSpends', datedSpends, false);
                            if (error) {
                                setFieldError('ioSpends.datedSpends', error);
                            }
                        }}
                    />
                )}
            </ControlledCard>
        </CardForm>
    );
};

export default UnitForm;
