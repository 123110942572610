import { useEffect, useState } from 'react';
import classNames from 'classnames';
import DeeplyAiLogo from 'platform/assets/images/deeply-ai-logo.svg';
import IconButton from 'platform/common/components/IconButton/IconButton';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Unauthorized from 'platform/common/components/Unauthorized/Unauthorized';
import { useFeature } from 'platform/common/hooks/useFeature';
import { useIsMobile } from 'platform/common/hooks/useIsMobile';
import ChatAgentBtn from '../ChatAgents/ChatAgentBtn';
import ChatClearButton from '../ChatClearButton';
import ChatFooter from '../ChatFooter/ChatFooter';
import ChatInput from '../ChatInput/ChatInput';
import ChatAgentsSelect from '../ChatLanding/ChatAgentsSelect';
import ChatMessage from '../ChatMessage/ChatMessage';
import ChatAdvertiserSelectMessage from '../ChatMessage/customMessages/ChatAdvertiserSelectMessage';
import ChatWelcomeMessage from '../ChatMessage/customMessages/ChatWelcomeMessage';
import useChat from '../useChat';
import './FullScreenChat.scss';

interface Props {
    onBackClick: () => void;
    className?: string;
}

const FullScreenChat = ({ onBackClick, className }: Props) => {
    const isMobile = useIsMobile();
    const [messagesRef, setMessagesRef] = useState<HTMLFormElement | null>(null);
    const isAuthorized = useFeature('CHATBOT_DEFAULT_VIEW');

    const { agentsLoading, messages, selectedAgent, contextPrompts, setSelectedAgent } = useChat();

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    useEffect(() => {
        document.title = 'DeeplyAI';
    }, []);

    if (!isAuthorized) return <Unauthorized />;

    if (agentsLoading) return <OverlayLoader />;

    return (
        <div
            className={classNames('FullScreenChat', className)}
            onDrop={(e) => {
                e.preventDefault();
                setUploadedFiles((prevFiles) => [...prevFiles, ...Array.from(e.dataTransfer.files)]);
            }}
        >
            <div className="FullScreenChat-backgroundImg">
                <div className="FullScreenChat-header">
                    <IconButton
                        onClick={() => {
                            onBackClick();
                            setSelectedAgent(undefined);
                        }}
                        className="position-absolute"
                        style={{ left: 32 }}
                    >
                        <i className="far fa-chevron-left fs-5 text-white" />
                    </IconButton>
                    <img src={DeeplyAiLogo} className="mx-auto" alt="DeeplyAI Logo" />
                    <ChatClearButton style={{ right: 16 }} className="ms-auto position-absolute" />
                </div>
                <div className="w-100 d-flex">
                    {isMobile ? (
                        <ChatAgentBtn className="mx-auto" size="medium" agent={selectedAgent!} disabled />
                    ) : (
                        <ChatAgentsSelect />
                    )}
                </div>
            </div>

            <form ref={setMessagesRef}>
                <div className="FullScreenChat-messages">
                    <ChatWelcomeMessage containerClassName="FullScreenChat-message" />

                    {(selectedAgent?.requiresAdvertiser ||
                        (selectedAgent?.advertiserIds && selectedAgent?.advertiserIds?.length > 0)) && (
                        <ChatAdvertiserSelectMessage containerClassName="FullScreenChat-message" />
                    )}
                    {messages.map((msg, index) => (
                        <ChatMessage
                            key={`chat_message_${index}`}
                            index={index}
                            messageData={msg}
                            contextPrompts={contextPrompts}
                            titleMessageClassName="bg-body"
                            iconUrl={selectedAgent?.iconUrl}
                            containerClassName="FullScreenChat-message"
                        />
                    ))}
                </div>
                <div className="px-3 bg-body">
                    <div className="FullScreenChat-inputContainer">
                        <ChatInput
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            chatBoundary={messagesRef}
                            className="FullScreenChat-input"
                            iconClassName="FullScreenChat-inputIcon"
                        />
                    </div>
                </div>
            </form>
            <ChatFooter className="p-2" />
        </div>
    );
};

export default FullScreenChat;
