import { CellInfo, RowInfo } from 'react-table';
import { isEmpty } from 'lodash-es';
import { Status } from 'platform/common/constants/status.constant';
import { DateRangePreset } from './utils/date.util';

export type FileFormat = 'EXCEL' | 'CSV' | 'JSON';

export interface RangeObj<T = string | number | Date> {
    from: T;
    to: T;
}

export interface SelectOption {
    value: any;
    label: string;
}

export interface Action {
    type: string;
    payload?: any;
    meta?: any;
    takeLatest?: boolean;
}

export interface TableRow<T = any> extends RowInfo {
    original: T;
}

export interface TableCell<T = any> extends CellInfo {
    original: T;
    formattedValue: any;
}

export interface Period {
    to?: string;
    from?: string;
    preset?: DateRangePreset;
}

export interface TypeDefinition {
    typeId: string;
    parse: (val: any, options?: any) => any;
    parseForExport: (val: any) => any;
    format: (value: any, options?: any) => string;
    formatTitle: (val: any, options?: any) => any;
    isNumeric?: boolean;
}

export interface Series {
    labels: string[];
    values: number[];
}

export interface StatisticWithSeries {
    value: number;
    series: Series;
}

export interface SelectItem<T = any> {
    value: T;
    label: string;
    icon?: string;
    status?: Status;
    __isNew__?: boolean;
    nodes?: SelectItem<T>[];
}

export type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R;

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const isDefined = <T>(item: T | null | undefined): item is T => item !== null && item !== undefined;

export const isNotEmpty = <T>(item: T | null | undefined): item is T =>
    !!item && (typeof item !== 'object' || !isEmpty(item));

export interface IdResponse {
    id: number;
}

export type Paging = {
    pageNumber: number;
    pageSize: number;
};

export type SortDirection = 'ASC' | 'DESC';

export interface Sort {
    orderBy: string;
    direction: SortDirection;
}

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

type Join<K, P> = K extends string | number
    ? P extends string | number
        ? `${K}${'' extends P ? '' : '.'}${P}`
        : never
    : never;

export type Paths<T, D extends number = 10> = [D] extends [never]
    ? never
    : T extends object
    ? {
          [K in keyof T]-?: K extends string | number
              ? `${K}` | (Paths<T[K], Prev[D]> extends infer R ? Join<K, R> : never)
              : never;
      }[keyof T]
    : '';
