import { Button } from 'reactstrap';
import { FormikProps } from 'formik';
import TemplateUsagePopover from 'platform/analyticsReports/TemplateUsagePopover';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { makeOptions } from 'platform/common/utils/option.util';
import { required } from 'platform/common/utils/validators.util';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import InsightQueryVersionsPopover from 'platform/insight/InsightForm/InsightQueryVersionsPopover';
import { normalizeColumnMetadata } from 'platform/insight/insight.service';
import { INSIGHT_SOURCE_LABELS, InsightQuery, InsightReport } from 'platform/insight/insight.types';
import InsightColumns from './InsightColumns';

const INSIGHT_QUERY_HELP_KEY = 'insight_form_query';

const SOURCE_OPTIONS = makeOptions(INSIGHT_SOURCE_LABELS);

type Props = {
    field: string;
    query: InsightQuery;
    setFieldValue: FormikProps<InsightReport>['setFieldValue'];
    onAnalyse: (opts: { suggestColumns: boolean }) => void;
    onRemove: () => void;
    onRestore: (timestamp: string) => void;
};

const InsightQueryCard = ({ field, query, setFieldValue, onAnalyse, onRemove, onRestore }: Props) => {
    const showConfirmationModal = useConfirmationModal();
    const { metadata } = query;

    const confirmRemoval = (remove: () => void) =>
        showConfirmationModal(remove, {
            title: 'Delete query?',
            text: 'Make sure that no analytics reports are using this query before deleting it.',
            okLabel: 'Delete',
        });

    return (
        <ControlledCard
            title={query.name}
            subtitle={query.id ? `Query ID: ${query.id}` : ''}
            onRemove={() => (query.id ? confirmRemoval(onRemove) : onRemove())}
        >
            <FormRow label="Name" helpKey={`${INSIGHT_QUERY_HELP_KEY}_name`}>
                <FormInput name={`${field}.name`} type="text" validate={required} />
            </FormRow>
            {query.id && (
                <FormRow>
                    <div className="d-flex">
                        <TemplateUsagePopover templateId={String(query.id)} />
                        <InsightQueryVersionsPopover
                            className="ms-4"
                            queryId={query.id}
                            onRestore={onRestore}
                        />
                    </div>
                </FormRow>
            )}
            <FormRow label="Data source" helpKey={`${INSIGHT_QUERY_HELP_KEY}_source`}>
                <FormSelect name={`${field}.source`} options={SOURCE_OPTIONS} validate={required} />
            </FormRow>
            <FormRow label="Code" labelWidth={1} helpKey={`${INSIGHT_QUERY_HELP_KEY}_code`}>
                <FormCodeEditor
                    name={`${field}.code`}
                    options={{
                        mode: 'text/x-sql',
                        lineWrapping: true,
                        readOnly: false,
                        lineNumbers: true,
                    }}
                    validate={required}
                />
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                        <Button onClick={() => onAnalyse({ suggestColumns: false })}>Test query</Button>
                        <Button onClick={() => onAnalyse({ suggestColumns: true })}>
                            Test query & suggest columns
                        </Button>
                    </div>
                    {metadata.error && (
                        <div className="text-danger ms-3">
                            <i className="fa fa-warning" /> {metadata.error}
                        </div>
                    )}
                </div>
            </FormRow>
            {!!metadata.parameters?.length && (
                <FormRow label="Used filters" labelWidth={1} childrenColClassName="text-muted">
                    {metadata.parameters?.join(', ')}
                </FormRow>
            )}
            <FormRow label="Columns" labelWidth={1} helpKey={`${INSIGHT_QUERY_HELP_KEY}_columns`}>
                <InsightColumns
                    columns={metadata.columns ?? []}
                    onChange={(columns) =>
                        setFieldValue(`${field}.metadata.columns`, normalizeColumnMetadata(columns))
                    }
                />
            </FormRow>
        </ControlledCard>
    );
};

export default InsightQueryCard;
