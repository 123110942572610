import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Form, Label } from 'reactstrap';
import { push } from 'redux-first-history';
import { Formik } from 'formik';
import { updatePassword } from 'platform/app/services/auth.service';
import { minLength, required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

interface FormModel {
    password: string;
    confirmPassword: string;
}

interface Props {
    formType: 'set' | 'reset';
}

const matchPassword = (password: string) => (confirmPassword: string) =>
    confirmPassword !== password ? 'Passwords do not match' : undefined;

const SetPasswordForm = ({ formType }: Props) => {
    const { activationKey } = useParams<{ activationKey: string }>();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const submit = async (formData: FormModel) => {
        if (!activationKey) return;

        setLoading(true);

        try {
            await updatePassword(activationKey, formData.password, formType === 'reset');
            dispatch(push('/login'));
        } catch (error) {
            setErrorMessage(
                error.response.data.errorCode === 'ACTION_KEY_INVALID'
                    ? 'Password reset token has expired.'
                    : 'Oops! Something went wrong. Try again later.'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik<FormModel> initialValues={{ password: '', confirmPassword: '' }} onSubmit={submit}>
            {({ values, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    <h4 className="mb-4 fw-bold">Please enter your new password</h4>
                    <div className="mb-3">
                        <Label for="username" className="fw-bold">
                            Password
                        </Label>
                        <FormInput
                            id="password"
                            name="password"
                            type="password"
                            label="New password"
                            validate={[required, minLength(8)]}
                        />
                    </div>
                    <div className="mb-3">
                        <Label for="password" className="fw-bold">
                            Confirm password
                        </Label>
                        <FormInput
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            label="Confirm new password"
                            validate={[required, matchPassword(values.password)]}
                        />
                    </div>
                    <Button type="submit" color="primary" className="px-3 py-2 w-100" disabled={loading}>
                        Set password
                    </Button>
                    <Link className="mt-2 float-end" to="/login">
                        Return to login page
                    </Link>
                </Form>
            )}
        </Formik>
    );
};

export default SetPasswordForm;
