import { useLocation } from 'react-router';
import qs from 'qs';
import LogBookForm from 'platform/campaign/logBook/components/LogBookForm/LogBookForm';
import { LogBookAssignmentType } from 'platform/campaign/logBook/logBook.constant';
import { toNotesApiModel } from 'platform/campaign/logBook/logBook.mapper';
import { LogBookNoteFormModel } from 'platform/campaign/logBook/logBook.types';
import { createNote } from 'platform/campaign/logBook/logbook.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';

type Props = {
    advertiserId: number;
};

const LogBookCreate = ({ canEdit, redirectTo, afterSubmit, advertiserId }: FormContainerProps & Props) => {
    const location = useLocation();

    const onOpen = (): Promise<LogBookNoteFormModel> => {
        const { from, to, mediaplanIds, mediaInsertionIds, assignmentType } = qs.parse(location.search, {
            ignoreQueryPrefix: true,
            strictNullHandling: true,
        });
        return Promise.resolve({
            version: 0,
            advertiserId,
            status: 'ACTIVE',
            subject: '',
            category: undefined,
            period: {
                from: from as string,
                to: to as string,
            },
            mediaplanIds: (Array.isArray(mediaplanIds) && mediaplanIds.map(Number)) || [],
            mediaInsertionIds: (Array.isArray(mediaInsertionIds) && mediaInsertionIds.map(Number)) || [],
            text: '',
            assignmentType: assignmentType as LogBookAssignmentType,
        });
    };

    const onSubmit = (model: LogBookNoteFormModel) => createNote(toNotesApiModel(model));

    return (
        <FormContainer
            helpKey="logbook_form"
            onSubmit={onSubmit}
            onOpen={onOpen}
            canEdit={canEdit}
            formikConfig={{ enableReinitialize: true }}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            isEdit={false}
            sidePanel
        >
            {(props) => <LogBookForm {...props} />}
        </FormContainer>
    );
};

export default LogBookCreate;
