import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Optional } from 'platform/common/common.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import ProductFeedForm from './ProductFeedForm';
import { createProductFeed, fetchProductFeed, updateProductFeed } from './productFeed.service';
import { ProductFeed } from './productFeed.types';

const ProductFeedFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = params.id ? Number(params.id) : undefined;
    const advertiserId = useSelector(activeAdvertiserSelectors.id);

    return (
        <FormContainer<Optional<ProductFeed, 'id'>>
            helpKey="product_feed_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            onOpen={async () => (id ? fetchProductFeed(id) : { advertiserId, name: '', url: '' })}
            onSubmit={(data: ProductFeed) => (id ? updateProductFeed(data) : createProductFeed(data))}
            onSubmitFinish={afterSubmit}
            isEdit={!!id}
            sidePanel
        >
            {(props) => <ProductFeedForm {...props} />}
        </FormContainer>
    );
};

export default ProductFeedFormContainer;
