import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportComponentState } from 'platform/analytics/analytics.types';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { ComponentsProps } from 'platform/analytics/modes/Analytics';
import PopoverButton from 'platform/common/components/PopoverButton/PopoverButton';
import ReportComponentSettings from './ReportComponentSettings';
import './ReportComponentEdit.scss';

interface Props {
    initialState: ReportComponentState;
    componentsProps?: ComponentsProps;
    canEdit: boolean;
    toggleHighlight: () => void;
}

const ReportComponentEdit = ({ initialState, componentsProps, canEdit, toggleHighlight }: Props) => {
    const [componentState, setComponentState] = useState(initialState);
    const dispatch = useDispatch();

    useEffect(() => {
        setComponentState(initialState);
    }, [initialState]);

    const pristine = initialState === componentState;

    const handleApply = (onApplied: () => void) => {
        dispatch(analyticsSettingsActions.changeComponentState(componentState));
        onApplied();
    };

    return (
        <PopoverButton
            popoverclassName="ReportComponentEdit"
            icon={<i className="fal fa-edit" />}
            buttonClassName="p-1"
            boundariesElement={document.querySelector('.main-container') ?? undefined}
            toggleOnOutsideClick={false}
            onClick={toggleHighlight}
            onClose={() => {
                setComponentState(initialState);
                toggleHighlight();
            }}
        >
            {({ togglePopover }) => (
                <ReportComponentSettings
                    canEdit={canEdit}
                    disabled={pristine}
                    componentState={componentState}
                    componentsProps={componentsProps}
                    height={570}
                    onCancel={togglePopover}
                    onApply={() => handleApply(togglePopover)}
                    onChange={setComponentState}
                />
            )}
        </PopoverButton>
    );
};

export default ReportComponentEdit;
