import { ComponentType } from 'react';
import { EDITABLE_DIMENSIONS } from 'platform/analytics/analytics.constants';
import { NoteType } from 'platform/analytics/reportComponents/ReportNote';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { NavigationStyle } from 'platform/analyticsReports/analyticsReports.constant';
import { Section } from 'platform/app/app.types';
import { ReportDefaultMetrics } from 'platform/campaign/advertiserManagement/DefaultMetrics/defaultMetrics.types';
import { LogCategory } from 'platform/campaign/logBook/logBook.constant';
import { Period, Sort, TableCell } from 'platform/common/common.types';
import { Status } from 'platform/common/constants/status.constant';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import { SecondaryPreset } from 'platform/common/utils/date.util';
import { makeOptions } from 'platform/common/utils/option.util';
import { MediaInsertionSearchResult, Mediaplan } from 'platform/mediaplan/mediaplan.types';
import { SpacerType } from './reportComponents/ReportSpacer';

export interface InteractionFilter {
    key: string;
    value: number | string[] | string;
}

type ComponentGroup = 'DATA' | 'GENERAL' | 'CHARTS';

export type PeriodDimension = 'date' | 'week' | 'month';

export type ColouringType = 'TEXT' | 'CELL';

export type ReportComponentType =
    | 'CHART'
    | 'SPACER'
    | 'TABLE'
    | 'SUMMARY_BAR'
    | 'LOG_BOOK'
    | 'NOTE'
    | 'INTERACTION';

export type ChartType = 'LINE' | 'STACK_LINE' | 'DOUGHNUT' | 'BAR' | 'STACK_BAR' | 'WORD_CLOUD' | 'SUNBURST';

export type VatSetting = 'ALWAYS' | 'INCLUDE' | 'EXCLUDE';

export type OptInEffect = 'INCREASE' | 'DECREASE';

export const DEFAULT_COLUMN_GROUP_KEY = 'DMS_OTHER';

export type ReportType = 'DEFAULT' | 'PIVOT' | 'INSIGHT';

export type TemplateType = 'DEFAULT' | 'INSIGHT';

export type InteractionType = 'SELECT' | 'SLIDER' | 'INPUT' | 'FILTER' | 'DATE_PICKER';

export type EditableDimension = typeof EDITABLE_DIMENSIONS[number];

export const COLUMN_TYPE_LABELS = { DIMENSION: 'Dimension', METRIC: 'Metric' };

export const COLUMN_TYPE_OPTIONS = makeOptions(COLUMN_TYPE_LABELS);

export type ColumnType = keyof typeof COLUMN_TYPE_LABELS;

export type ColumnDefinitionOption = ColumnDefinition & { nodes: ColumnDefinition[] };

export interface ColumnDefinition {
    key: string;
    name: string;
    altName?: string;
    type?: { ui: string };
    group?: string;
    includeInDeeply?: boolean;
    description?: string;
    source?: string;
    isSystem?: boolean;
}

export interface ColumnGroup {
    key: string;
    name: string;
}

export type DimensionDefinition = ColumnDefinition & {
    dependsOn?: string[];
};

export type MetricDefinition = ColumnDefinition & {
    invertedPerception?: boolean;
    isDerivative?: boolean;
    vat?: VatSetting;
    optInEffect?: OptInEffect;
};

export type OlapFilterOperator =
    | 'IN'
    | 'NOT_IN'
    | 'BTE'
    | 'STE'
    | 'LTE'
    | 'GTE'
    | 'LT'
    | 'GT'
    | 'EQUALS'
    | 'ARRAY_CONTAINS'
    | 'LIKE'
    | 'BETWEEN'
    | 'CASE_AGNOSTIC_EQUALS'
    | 'CASE_AGNOSTIC_IN';

export interface OlapFilter {
    columnId: string;
    compareOperator: OlapFilterOperator;
    compareValue: any;
}

export type ReportFilterValue = string | number | null | boolean;

export type ReportFilterOperator =
    | 'IS'
    | 'IS_NOT'
    | 'CONTAINS'
    | 'CONTAINS_NOT'
    | 'GT'
    | 'GTE'
    | 'LT'
    | 'LTE';

export interface ReportFilter {
    key: string;
    operator?: ReportFilterOperator;
    values: ReportFilterValue[];
}

export interface FilterDefinition {
    type: FilterType;
    source: string;
    target: string;
    name: string;
    isMulti?: boolean;
    isSensitive?: boolean;
    canBeSaved?: boolean;
}

export interface CustomFilter {
    column: string;
    operator: ReportFilterOperator;
    value: any;
}

export interface PivotOptions {
    column: string;
    dimsFirst?: boolean;
}

type FilterType = 'LIST' | 'NUMBER' | 'TEXT';

interface Placement {
    width: number;
}
interface CommonComponentType {
    id: number;
    placement: Placement;
    name: string;
    group: ComponentGroup;
    icon: string | ComponentType<any>;
    height?: number;
    compatibleFilters?: FilterDefinition[];
    compatibleFiltersLoaded?: boolean;
    note?: NoteType;
    spacer?: SpacerType;
    defaultMetrics?: string[];
}

export type CommonChartType = CommonComponentType & {
    type: 'CHART';
    chartType: ChartType;
    title?: string;
    templateId: string;
    metrics: string[];
    dimensions: string[];
    customFilters?: CustomFilter[];
    customDateRange?: Period;
    entriesCount: number;
};

export type LineState = CommonChartType & {
    chartType: 'LINE';
    periodDimension: PeriodDimension;
    sortBy: ColumnType;
    showValues: boolean;
};
export type StackLineState = CommonChartType & {
    chartType: 'STACK_LINE';
    periodDimension: PeriodDimension;
    sortBy: ColumnType;
    showValues: boolean;
};
export type DoughnutState = CommonChartType & {
    chartType: 'DOUGHNUT';
    showOtherValues: boolean;
};

export type BarState = CommonChartType & {
    chartType: 'BAR';
    showOtherValues: boolean;
    sortBy: ColumnType;
};

export type StackBarState = CommonChartType & {
    chartType: 'STACK_BAR';
    showOtherValues: boolean;
    sortBy: ColumnType;
};

export type WordCloudState = CommonChartType & {
    chartType: 'WORD_CLOUD';
    colorDimension?: string;
};

export type SunburstState = CommonChartType & {
    chartType: 'SUNBURST';
};

export type SpacerState = CommonComponentType & { type: 'SPACER' };
export type NoteState = CommonComponentType & { type: 'NOTE' };

export interface TablePreset {
    name: string;
    metrics: string[];
    dimensions: string[];
    editableDimensions?: EditableDimension[];
    pivot?: PivotOptions;
    customFilters?: CustomFilter[];
    customDateRange?: Period;
    pageSize?: number;
    sort?: Sort[];
    hideEmptyColumns: boolean;
    templateId: string;
}

export interface ColumnColouringType {
    min: number;
    max: number;
    type: ColouringType;
    maxColour: string;
    minColour: string;
}

export type ColumnsColouring = Record<string, ColumnColouringType>;

export type TableState = CommonComponentType & {
    type: 'TABLE';
    activePresetIndex: number;
    presets: TablePreset[];
    columnsColouring?: ColumnsColouring;
    sort: Sort[];
    pageSize: number;
    pageNumber: number;
    summaryTable?: boolean;
    collapseView?: boolean;
};

export type SummaryBarState = CommonComponentType & {
    type: 'SUMMARY_BAR';
    templateId: string;
    rows: string[][];
    isChartVisible: boolean;
    showChartValues: boolean;
    customFilters?: CustomFilter[];
    customDateRange?: Period;
    activeColumn?: string;
    activeDimension: PeriodDimension;
};

export type LogBookState = CommonComponentType & {
    type: 'LOG_BOOK';
    productCategory?: string[];
    productLabel?: string[];
    productSubLabel?: string[];
    flightLabel?: string[];
    countryLabel?: string[];
    category?: LogCategory;
    logNoteExpanded?: boolean;
    customDateRange?: Period;
};

export type CommonInteractionComponent = CommonComponentType & {
    type: 'INTERACTION';
    interactionType: InteractionType;
    key: string;
    required: boolean;
    title?: string;
};

export type SelectInteractionState = CommonInteractionComponent & {
    interactionType: 'SELECT';
    selectOptions: { label: string; value?: number }[];
    value?: number;
    defaultValue?: number;
};

export type FilterInteractionState = CommonInteractionComponent & {
    interactionType: 'FILTER';
    filter: string;
    value?: string[];
    defaultValue?: string[];
};

export type SliderInteractionState = CommonInteractionComponent & {
    interactionType: 'SLIDER';
    minValue?: number;
    maxValue?: number;
    step?: number;
    value?: number;
    defaultValue?: number;
};

export type InputInteractionState = CommonInteractionComponent & {
    interactionType: 'INPUT';
    value?: number;
    defaultValue?: number;
};

export type DatePickerInteractionState = CommonInteractionComponent & {
    interactionType: 'DATE_PICKER';
    fromKey: string;
    toKey: string;
    value?: Period;
    defaultValue?: Period;
    onlyPast?: boolean;
    onlyFuture?: boolean;
};

export type InteractionComponentState =
    | SelectInteractionState
    | SliderInteractionState
    | InputInteractionState
    | FilterInteractionState
    | DatePickerInteractionState;

export type ChartState =
    | LineState
    | StackLineState
    | BarState
    | StackBarState
    | DoughnutState
    | WordCloudState
    | SunburstState;

export interface ComponentSettings<T> {
    componentState: T;
    template: Template;
    compatibleFilters: FilterDefinition[];
    metricOptions: ColumnDefinitionOption[];
    dimensionOptions: ColumnDefinitionOption[];
    loading: boolean;
    canEdit: boolean;
    onChange: (state: T) => void;
}

export type ReportComponentState =
    | ChartState
    | TableState
    | SpacerState
    | SummaryBarState
    | LogBookState
    | InteractionComponentState
    | NoteState;

export interface ReportHeaderColumn {
    id: string;
    key: string;
    name: string;
    altName?: string;
    type: { ui: string };
    vat?: VatSetting;
    optInEffect?: OptInEffect;
    group?: string;
    hasActions: boolean;
    description?: string;
    invertedPerception?: boolean;
    isDerivative?: boolean;
    value?: string;
}

interface DrillOperand {
    key: string;
    values?: (string | number)[];
    target?: string;
}

export interface DrillInstruction {
    add?: DrillOperand[];
    remove?: DrillOperand[];
}

export interface ReportDrill {
    target: string;
    name?: string;
    filter?: DrillInstruction;
    dimensions?: DrillInstruction;
    metrics?: string[];
    rowValues?: { [key: string]: any };
    rowValuesExcept?: { [key: string]: any };
    sorts?: Sort[];
    icon?: string;
    isPrimary?: boolean;
    systemReportKey?: string;
}

export interface OlapReport<RowType = any> extends BaseReport<RowType> {
    type: 'DEFAULT';
    drill?: ReportDrill[];
    paging: {
        totalPages: number;
        pageNumber: number;
    };
}

export interface PivotValue {
    value: number;
    type: {
        ui: string;
        gql: string;
    };
    originalKey: string;
}

export type PivotRow = {
    level: number;
} & {
    [key: string]: PivotValue;
};

export interface PivotReport<RowType = any> extends BaseReport<RowType> {
    type: 'PIVOT';
    drill: ReportDrill[];
}

interface InsightReport<RowType = any> extends BaseReport<RowType> {
    type: 'INSIGHT';
}

export type ReportTableCell<T = any> = {
    drills?: ReportDrill[];
    componentId?: number;
    renderFirstDependant?: boolean;
} & TableCell<T>;

export interface AnalyticsMetadataDefinitions {
    dimensions: DimensionDefinition[];
    metrics: MetricDefinition[];
    filters: FilterDefinition[];
    columnsWithData: string[];
}

export interface AnalyticsMetadata {
    templates: Template[];
    definitions: AnalyticsMetadataDefinitions;
    columnGroups: ColumnGroup[];
    defaultMetrics: ReportDefaultMetrics | null;
    hasDefaultMetrics: boolean;
    mediaPlans: Mediaplan[];
    mediaInsertions: MediaInsertionSearchResult[];
}

type OpenIn = 'NEW_TAB' | 'NEW_WINDOW' | 'EMBEDDED';

export type LinkDisplay = {
    openIn?: OpenIn;
    width?: number;
    height?: number;
    labelStyles?: NavigationStyle[];
};

export const analyticsModes = ['DEFAULT', 'BUDGET', 'CAMPAIGN', 'INSIGHT', 'INTERACTIVE'] as const;
export type AnalyticsMode = typeof analyticsModes[number];

type CompareWith = 'PREVIOUS_ROW' | 'AVERAGE';

export type AnalyticsCompareType = CompareWith | SecondaryPreset;

export interface CompareOption {
    value: AnalyticsCompareType;
    label: string;
}

export const datePickerModes = [
    'DEFAULT',
    'CALENDAR_WEEK',
    'CALENDAR_WEEK_RANGE',
    'MONTH',
    'MONTH_RANGE',
    'YEAR',
] as const;
export type DatePickerMode = typeof datePickerModes[number];

export interface InteractionPreset {
    id: number;
    name: string;
    values: InteractionFilter[];
    submitType?: 'UPDATE' | 'CREATE';
}

export interface AnalyticsSettings {
    analyticsReportId?: number;
    gridColumns: number;
    filters: ReportFilter[];
    filtersLoading?: boolean;
    compareWith: CompareWith | undefined;
    components: ReportComponentState[];
    mode: AnalyticsMode;
    datePickerMode: DatePickerMode;
    section?: Section;
    modelOptIn?: boolean;
    debugMode: boolean;
    includeVatRate?: boolean;
    inlineEditing?: boolean;
    includeDefaultMetrics?: boolean;
    showCompareValues: boolean;
    originalReportId?: number;
    maintenanceMode?: boolean;
    showLogBookLink?: boolean;
}

export interface NavigationParams {
    settings?: string;
    reportId?: number;
    internalLinkId?: number;
    advertiserId?: number;
    algorithmId?: number;
    [key: string]: any;
}

export interface AnalyticsUrlSettings {
    filters?: ReportFilter[];
    components?: ReportComponentState[];
    dimensions?: string[];
    periods?: Periods;
}

export interface AnalyticsState {
    meta?: AnalyticsMetadata;
    settings: AnalyticsSettings;
    analyticsReports: AnalyticsReport[];
}

export type Report<T = any> = OlapReport<T> | PivotReport<T> | InsightReport<T>;

interface BaseReport<RowType = any> {
    type: ReportType;
    header: ReportHeaderColumn[];
    rows: RowType[];
    traceId: string;
}

type Preset = {
    name: string;
    dimensions?: string[];
    metrics?: string[];
};

type TemplateFilter = {
    target: string;
};

export type Template = {
    id: string;
    name: string;
    dimensions: DimensionDefinition[];
    metrics: MetricDefinition[];
    presets: Preset[];
    filters: TemplateFilter[];
    hideTemplateFromSelector: boolean;
    type: TemplateType;
};

type DimensionFilterValue = (number | string | boolean)[];

export type DimensionFilterValues = {
    [key: string]: DimensionFilterValue | undefined;
};
export interface DimensionFilterOption {
    label: string;
    value: string | number;
    disabled?: boolean;
    status?: Status;
}

export type ContainerComponent = {
    isContainer: boolean;
    items: ReportComponentState[];
};

export type GroupedComponents = ContainerComponent | ReportComponentState;
