import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import NumberShift from 'platform/campaign/logBook/components/BudgetShiftingDetails/NumberShift';
import LogBookEntryDivider from 'platform/campaign/logBook/components/LogBookListTable/LogBookEntryDivider';
import LogEntryInfo from 'platform/campaign/logBook/components/LogBookListTable/LogEntryInfo';
import LogEntryShortNote from 'platform/campaign/logBook/components/LogBookListTable/LogEntryShortNote';
import ViewNoteButton from 'platform/campaign/logBook/components/LogBookListTable/ViewNoteButton';
import { LogBookEntry } from 'platform/campaign/logBook/logBook.types';
import { getCategoryLabel } from 'platform/campaign/logBook/logBook.utils';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import useToggle from 'platform/common/hooks/useToggle';
import './ReportLogBook.scss';

interface Props {
    log: LogBookEntry;
    className?: string;
    noteDefaultExpanded?: boolean;
}

const ReportLogBook = ({ log, className, noteDefaultExpanded = false }: Props) => {
    const [noteExpanded, toggleNoteExpanded] = useToggle(noteDefaultExpanded);
    const hasData = log.eventDetails?.oldData && log.eventDetails?.newData;
    const hasNote = !!log.text;

    return (
        <div className={classNames('ReportLogBook', className)}>
            <div className="d-flex align-items-center p-3">
                {log.periodFrom && log.periodTo && (
                    <div className="pe-5">
                        {log.periodFrom} <span className="text-light-slate-gray">to</span> {log.periodTo}
                    </div>
                )}
                <span className="text-light-slate-gray text-nowrap">{getCategoryLabel(log)}</span>
                <NumberShift
                    className="ms-2"
                    newNumber={log.eventDetails?.newData?.budget}
                    oldNumber={log.eventDetails?.oldData?.budget}
                />
                {hasData && (
                    <>
                        <LogBookEntryDivider />
                        <LogEntryInfo
                            oldData={log.eventDetails?.oldData}
                            newData={log.eventDetails?.newData}
                        />
                    </>
                )}
                {log.subject && (
                    <>
                        {!hasData && <LogBookEntryDivider />}
                        <LogEntryShortNote text={log.subject ?? ''} />
                    </>
                )}
            </div>
            <Collapse isOpen={hasNote && noteExpanded}>
                <RawHtml className="ReportLogBook-borderTop p-3">{log.text}</RawHtml>
            </Collapse>
            {hasNote && <ViewNoteButton expanded={noteExpanded} onClick={toggleNoteExpanded} />}
        </div>
    );
};

export default ReportLogBook;
