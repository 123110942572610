import { useRefetchAnalyticsReports } from 'platform/analyticsReports/ReportManagement/useRefetchAnalyticsReports';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { arrayItemMove } from 'platform/common/utils/array.util';
import InsetBox from '../../common/components/InsetBox/InsetBox';
import { ActiveOrArchived } from '../../common/constants/status.constant';
import { usePromise } from '../../common/hooks/usePromise';
import { ADMIN_SEAT_ID } from '../../userManagement/mappers/user.mapper';
import SectionsCard from '../SectionsCard';
import { assignAnalyticsReports, fetchAnalyticsReports } from '../analyticsReport.service';
import { AnalyticsReport } from '../analyticsReport.types';
import { useReportHandlers } from './useReportHandlers';

interface Props {
    status: ActiveOrArchived[];
    canEdit: boolean;
    onReportChange: (report: AnalyticsReport) => Promise<void>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const CentralReports = ({ status, canEdit, onReportChange, onStatusChange }: Props) => {
    const [{ data: adminSeatReports, loading }, refetchReports] = usePromise<AnalyticsReport[]>(
        [],
        () =>
            fetchAnalyticsReports({
                status,
                hasSection: true,
                seatId: ADMIN_SEAT_ID,
            }),
        [status]
    );

    const { refetch } = useRefetchAnalyticsReports(refetchReports);

    const onReportMove = async (
        oldReport: AnalyticsReport,
        newReport: AnalyticsReport,
        reports: AnalyticsReport[]
    ) => {
        const oldIndex = reports.findIndex((r) => r.id === oldReport.id);
        const newIndex = reports.findIndex((r) => r.id === newReport.id);

        if (oldIndex === -1 || oldIndex === newIndex) {
            return;
        }

        await assignAnalyticsReports(
            { seatId: ADMIN_SEAT_ID },
            arrayItemMove(reports, oldIndex, newIndex).map((r) => r.id!)
        );
    };

    const { handleReportChange, handleReportMove } = useReportHandlers({
        seatId: ADMIN_SEAT_ID,
        refetchReports: refetch,
        onReportChange,
        onReportMove,
        onReportsCreate: undefined,
    });

    return (
        <BodyContainer helpKey="central_reports">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
            </ListFilters>
            {loading && <OverlayLoader />}
            <InsetBox className="mb-3">
                <SectionsCard
                    seatId={ADMIN_SEAT_ID}
                    section="CENTRAL_ANALYTICS"
                    reports={adminSeatReports.filter((r) => r.section === 'CENTRAL_ANALYTICS')}
                    loading={loading}
                    canEdit={canEdit}
                    refetchReports={refetchReports}
                    onReportMove={(oldReport, newReport) =>
                        handleReportMove(oldReport, newReport, adminSeatReports)
                    }
                    onReportChange={handleReportChange}
                />
            </InsetBox>
        </BodyContainer>
    );
};

export default CentralReports;
