import { Collapse, Row } from 'reactstrap';
import classNames from 'classnames';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useToggle from 'platform/common/hooks/useToggle';
import { CustomerJourneyReportCM360 } from 'platform/dashboard/dashboard.types';
import JourneyRowBlockDivider from '../../JourneyRowBlockDivider';
import JourneyRowBlock from './JourneyRowBlock';
import JourneyRowCustomVariables from './JourneyRowCustomVariables';
import JourneyRowMainBlock from './JourneyRowMainBlock';

interface Props {
    row: CustomerJourneyReportCM360;
    last?: boolean;
    defaultExpanded?: boolean;
}

const PageViewJourneyRow = ({ row, last = false, defaultExpanded = false }: Props) => {
    const [expanded, toggleExpanded] = useToggle(defaultExpanded);

    return (
        <>
            <Row className={classNames('align-items-center px-3 pt-3', !expanded && 'pb-3')}>
                <JourneyRowMainBlock
                    text={row.segment_name}
                    subtitle={row.journey_event_date_time}
                    expanded={expanded}
                    eventType={row.journey_event_type}
                    toggleExpanded={toggleExpanded}
                    showChevron={!!row.journey_custom_variables}
                />
                <JourneyRowBlockDivider />
                <JourneyRowBlock
                    label="Action Probability"
                    value={
                        row.adh_segment_purchase_probability_30d
                            ? DATA_TYPES.P100.format(row.adh_segment_purchase_probability_30d)
                            : undefined
                    }
                />
                <JourneyRowBlockDivider />
                <JourneyRowBlock label="Category" value={row.journey_product_category_ids?.join(', ')} />
                <JourneyRowBlockDivider />
                <JourneyRowBlock label="Products" value={row.journey_product_ids?.join(', ')} />
            </Row>
            <Collapse isOpen={expanded}>
                <div className={classNames(!last && 'px-3 pb-3')}>
                    <JourneyRowCustomVariables
                        className={last ? 'rounded-bottom' : undefined}
                        customVariableJson={row.journey_custom_variables}
                    />
                </div>
            </Collapse>
        </>
    );
};

export default PageViewJourneyRow;
