import { CustomerJourneyReportCM360, JourneyEventTypeCM360 } from 'platform/dashboard/dashboard.types';
import ActionJourneyRow from './ActionJourneyRow';
import MediaJourneyRow from './MediaJourneyRow';
import PageViewJourneyRow from './PageViewJourneyRow';

interface Props {
    row: CustomerJourneyReportCM360;
    last?: boolean;
    defaultExpanded?: boolean;
    isExpanded?: boolean;
}

const JourneyRow = ({ row, last, defaultExpanded, isExpanded }: Props) => {
    const Component = JOURNEY_EVENT_TYPE_NODE_MAP[row.journey_event_type];

    return <Component row={row} last={last} defaultExpanded={defaultExpanded} isExpanded={isExpanded} />;
};

const JOURNEY_EVENT_TYPE_NODE_MAP: Record<JourneyEventTypeCM360, ({ row }: Props) => JSX.Element> = {
    click: MediaJourneyRow,
    view: MediaJourneyRow,
    action: ActionJourneyRow,
    pageview: PageViewJourneyRow,
};

export default JourneyRow;
