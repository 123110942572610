import { Category } from 'platform/category/category.types';
import InsetBox from '../../../common/components/InsetBox/InsetBox';
import SegmentDetailsFunnel from '../SegmentFunnel/SegmentDetailsFunnel';
import SegmentSimilarityChart from '../SegmentSimilarityChart/SegmentSimilarityChart';
import SegmentSummary from '../SegmentSummary/SegmentSummary';

interface Props {
    segmentId: number;
    categories: Category[];
}

const SegmentSimilarity = ({ segmentId, categories }: Props) => (
    <>
        <InsetBox className="mb-4">
            <SegmentSummary id={segmentId} categories={categories} />
        </InsetBox>
        <InsetBox className="mb-4 p-3">
            <SegmentDetailsFunnel segmentId={segmentId} />
        </InsetBox>
        <SegmentSimilarityChart segmentId={segmentId} />
    </>
);

export default SegmentSimilarity;
