export const deleteTypename = (target: any): any => {
    if (!target || typeof target !== 'object') {
        return target;
    }

    if (Array.isArray(target)) {
        return target.map(deleteTypename);
    }

    return Object.keys(target).reduce((cleaned, key) => {
        if (key !== '__typename') {
            return { ...cleaned, [key]: deleteTypename(target[key]) };
        }
        return cleaned;
    }, {});
};
