import moment from 'moment';
import { INITIAL_CRON_EXPRESSION } from 'platform/observer/observer.constants';
import {
    CreateUpdateObserverReportPayload,
    ObserverFormModel,
    ObserverReportBase,
    ObserverSchedulingFormModel,
} from './observer.types';

export const toFormModel = (res: ObserverReportBase): ObserverFormModel => {
    if (res.reportType === 'ON_DEMAND') {
        return {
            ...res,
            dateRange: {
                from: '',
                to: '',
            },
            scheduling: { ...parseCron(INITIAL_CRON_EXPRESSION) },
        };
    }
    return {
        ...res,
        dateRange: {
            from: res.startDate,
            to: res.endDate,
        },
        scheduling: { ...parseCron(res.cron ?? '') },
    };
};

export const toCreateUpdatePayload = ({
    scheduling,
    dateRange,
    ...formValues
}: ObserverFormModel): CreateUpdateObserverReportPayload => {
    if (formValues.reportType === 'ON_DEMAND') {
        return { ...formValues, status: 'ACTIVE' };
    }

    return {
        ...formValues,
        cron: scheduling?.expression,
        startDate: moment(dateRange?.from).utc(true).startOf('day').format(),
        endDate: dateRange?.to ? moment(dateRange.to).utc(true).startOf('day').format() : undefined,
        status: 'ACTIVE',
    };
};

export const parseCron = (expression: string): ObserverSchedulingFormModel => {
    const scheduling: ObserverSchedulingFormModel = {
        day: undefined,
        hours: undefined,
        minutes: undefined,
        expression,
        selectedTab: undefined,
        weekDays: undefined,
        dayOfMonth: undefined,
        recurring: false,
        recurring_every: '',
        recurring_custom_hours: false,
        recurring_from: '',
        recurring_to: '',
    };

    const segments = expression.split(' ');
    if (segments.length === 6 || segments.length === 7) {
        const [, minutes, hours, dayOfMonth, , dayOfWeek] = segments;

        if (expression.match(/\d+ 0\/\d+ \* 1\/1 \* \?/)) {
            scheduling.selectedTab = 'MINUTES';
            scheduling.minutes = minutes.substring(2);
        } else if (expression.match(/\d+ \d+ 0\/\d+ 1\/1 \* \?/)) {
            scheduling.selectedTab = 'HOURLY';
            scheduling.hours = hours.substring(2);
            scheduling.minutes = minutes;
        } else if (expression.match(/\d+ \d+ \d+ 1\/\d+ \* \?/)) {
            scheduling.selectedTab = 'DAILY';
            scheduling.day = dayOfMonth.substring(2);
            scheduling.hours = hours;
            scheduling.minutes = minutes;
        } else if (
            expression.match(
                /\d+ \d+ \d+ \? \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))*/
            )
        ) {
            scheduling.weekDays = dayOfWeek.split(',');
            scheduling.selectedTab = 'WEEKLY';
            scheduling.hours = hours;
            scheduling.minutes = minutes;
        } else if (
            expression.match(
                /\d+ \d+ \d+-\d+\/\d+ \* \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))*/
            )
        ) {
            scheduling.selectedTab = 'WEEKLY';
            scheduling.weekDays = dayOfWeek.split(',');
            scheduling.recurring_from = hours.split('-')[0];
            scheduling.recurring_to = hours.split('-')[1].split('/')[0];
            scheduling.recurring_every = hours.split('/')[1];
            scheduling.recurring = true;
            scheduling.recurring_custom_hours = true;
        } else if (
            expression.match(
                /\d+ \d+ \*\/\d+ \* \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))*/
            )
        ) {
            scheduling.selectedTab = 'WEEKLY';
            scheduling.weekDays = dayOfWeek.split(',');
            scheduling.recurring_from = '';
            scheduling.recurring_to = '';
            scheduling.recurring_every = hours.split('/')[1];
            scheduling.recurring = true;
            scheduling.recurring_custom_hours = false;
        } else if (expression.match(/0 \d+ \d+ \b([1-9]|[12][0-9]|3[01])\b 1\/1 \?/)) {
            scheduling.dayOfMonth = dayOfMonth;
            scheduling.hours = hours;
            scheduling.minutes = minutes;
            scheduling.selectedTab = 'MONTHLY';
        }
    }

    return scheduling;
};
