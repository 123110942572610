import { Channel } from 'platform/channels/channel.types';
import { Action } from 'platform/common/common.types';
import { RootState } from 'platform/rootState.type';
import { Vendor } from 'platform/vendors/vendors.types';

/**
 * A cache of classifier objects, common to all advertisers, infrequently changed by admin users.
 */
export interface CommonClassifiers {
    channels: Channel[];
    vendors: Vendor[];
}

const SET_COMMON_CLASSIFIERS = 'common/classifiers/SET_COMMON_CLASSIFIERS';
const SET_CHANNELS = 'common/classifiers/SET_CHANNELS';
const SET_VENDORS = 'common/classifiers/SET_VENDORS';

const initialState: CommonClassifiers = {
    channels: [],
    vendors: [],
};

const reducer = (state = initialState, action: Action): CommonClassifiers => {
    switch (action.type) {
        case SET_COMMON_CLASSIFIERS:
            return action.payload;
        case SET_CHANNELS:
            return { ...state, channels: action.payload };
        case SET_VENDORS:
            return { ...state, vendors: action.payload };
        default:
            return state;
    }
};

export const classifierActions = {
    setCommonClassifiers: (commonClassifiers: CommonClassifiers) => ({
        type: SET_COMMON_CLASSIFIERS,
        payload: commonClassifiers,
    }),
    setChannels: (channels: Channel[]) => ({
        type: SET_CHANNELS,
        payload: channels,
    }),
    setVendors: (vendors: Vendor[]) => ({
        type: SET_VENDORS,
        payload: vendors,
    }),
};

export const classifierSelectors = {
    commonClassifiers: (state: RootState) => state.common.classifiers,
    channels: (state: RootState) => state.common.classifiers.channels,
    vendors: (state: RootState) => state.common.classifiers.vendors,
};

export default reducer;
