import { Button } from 'reactstrap';
import FormRow from 'platform/common/components/FormRow/FormRow';
import SortableList from 'platform/common/components/SortableList/SortableList';
import { arrayItemMove, arrayItemRemove } from 'platform/common/utils/array.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import KanbanColumnSelect from 'platform/task/Kanban/KanbanColumnSelect';
import KanbanLabelSelect from 'platform/task/Kanban/KanbanLabelSelect';
import { LabelNames, TaskBoardView, TaskBoardViewFilters, TaskLane } from 'platform/task/task.types';

interface Props {
    views: TaskBoardView[];
    labelNames: LabelNames;
    laneOptions: TaskLane[];
    onChange: (views: TaskBoardView[]) => void;
}

const NEW_VIEW: TaskBoardView = {
    name: '',
    filters: { labels: [], lanes: [] },
};

const TaskBoardViewList = ({ views, labelNames, laneOptions, onChange }: Props) => {
    const handleAdd = () => onChange([...views, NEW_VIEW]);

    const handleFilterChange = (index: number, changes: TaskBoardViewFilters) =>
        onChange(
            views.map((view, i) =>
                i === index ? { ...view, filters: { ...view.filters, ...changes } } : view
            )
        );

    const handleMove = (from: TaskBoardView, to: TaskBoardView) =>
        onChange(arrayItemMove(views, views.indexOf(from), views.indexOf(to)));

    const handleRemove = (view: TaskBoardView) => onChange(arrayItemRemove(views, views.indexOf(view)));

    return (
        <>
            <SortableList
                items={views}
                dragKey="views"
                itemClassName="ps-3 mb-3 border bg-secondary"
                onDrop={handleMove}
                onRemove={handleRemove}
            >
                {(view, index) => (
                    <div className="w-100 my-2 pt-3 pe-2">
                        <FormRow label="View name">
                            <FormInput name={`views[${index}].name`} />
                        </FormRow>
                        <FormRow label="Labels">
                            <KanbanLabelSelect
                                placeholder="Any..."
                                labelKeys={view.filters.labels ?? []}
                                labelNames={labelNames}
                                onChange={(labels) => handleFilterChange(index, { labels })}
                            />
                        </FormRow>
                        <FormRow label="Columns">
                            <KanbanColumnSelect
                                placeholder="All columns..."
                                value={view.filters.lanes ?? []}
                                options={laneOptions}
                                onChange={(ids) => handleFilterChange(index, { lanes: ids })}
                            />
                        </FormRow>
                    </div>
                )}
            </SortableList>
            <Button color="secondary" onClick={handleAdd}>
                Add view
            </Button>
        </>
    );
};

export default TaskBoardViewList;
