import { ChangeEvent } from 'react';
import { useImageUpload } from 'platform/adminPanel/hooks/useImageUpload';
import { MEDIA_TYPE_OPTIONS } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Separator from 'platform/common/components/Separator/Separator';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { toSystemCode } from 'platform/common/utils/formatters.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormInputWithUrlPreview from 'platform/formik/FormInputWithUrlPreview/FormInputWithUrlPreview';
import FormRadioList from 'platform/formik/FormRadioList/FormRadioList';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';
import { Channel } from '../channel.types';

const ChannelForm = ({
    labels,
    formikProps: { submitForm, values, setFieldValue },
    canEdit,
    isEdit,
    onCancel,
}: FormProps<Channel>) => {
    const { uploadingImage, handleImageUpload } = useImageUpload();

    return (
        <CardForm
            title={`${labels.prefix} Channel`}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info">
                <FormRow label="Name">
                    <FormInput
                        name="name"
                        type="text"
                        validate={required}
                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('name', value);
                            if (value && !isEdit) {
                                setFieldValue('code', toSystemCode(value));
                            }
                        }}
                    />
                </FormRow>
                <FormRow label="System Code">
                    <FormInput name="code" type="text" disabled />
                </FormRow>
                <FormRow label="Media type">
                    <FormRadioList
                        name="mediaType"
                        options={MEDIA_TYPE_OPTIONS}
                        optionsClassName="d-flex"
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Status">
                    <FormStateSelect name="status" options={ACTIVE_OR_ARCHIVED} validate={required} />
                </FormRow>

                <Separator label="Optional" />

                <FormRow label="Icon url">
                    <FormInputWithUrlPreview name="iconUrl" value={values.iconUrl} />
                </FormRow>
                <FormRow>
                    <UploadInput
                        acceptableMimeTypes="image/jpeg, image/png, image/svg+xml"
                        onFileUpload={(file, uploadProgressProps) =>
                            handleImageUpload({
                                file,
                                uploadProgressProps,
                                onUpload: (fileName) => setFieldValue('iconUrl', fileName),
                            })
                        }
                        loading={uploadingImage}
                        subtitle="Upload jpeg/png/svg"
                    />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default ChannelForm;
