import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { MEDIA_TYPE_LABELS } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import ChannelIcon from 'platform/channels/components/ChannelIcon';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { CHANNELS } from '../channel.navigation';
import { fetchChannels } from '../channel.service';
import { Channel } from '../channel.types';
import ChannelFormContainer from './ChannelFormContainer';

type QueryParams = {
    status: ActiveOrArchived[];
    searchQuery: string;
};

const ChannelList = () => {
    const {
        queryParams: { status, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>(
        {
            status: ['ACTIVE'],
            searchQuery: '',
        },
        CHANNELS.path
    );
    const dispatch = useDispatch();
    const canEdit = useFeature('ADMIN_EDIT');
    const [{ data, loading }, refetch] = usePromise([], fetchChannels, []);

    const channels = data.filter(
        (c) =>
            (!status.length || status.includes(c.status)) &&
            (!searchQuery || `${c.name} ${c.code}`.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const columns: TableColumn<Channel>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            maxWidth: 80,
            Cell: ({ value }) => <StatusBadge status={value} />,
        },
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ original }) => (
                <div className="d-flex align-items-center text-nowrap">
                    <ChannelIcon channel={original} className="me-1" />
                    {original.name}
                </div>
            ),
        },
        {
            Header: 'System Code',
            accessor: 'code',
        },
        {
            Header: 'Media type',
            accessor: 'mediaType',
            maxWidth: 120,
            Cell: ({ original }) => MEDIA_TYPE_LABELS[original.mediaType],
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            maxWidth: 160,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
        },
        {
            maxWidth: 50,
            sortable: false,
            Cell: ({ original }) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => dispatch(push(`${CHANNELS.path}/${original.code}`)),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Channels" />
            </HeaderContainer>
            <BodyContainer>
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    onChange={(value) => setQueryParams({ status: value })}
                    options={ACTIVE_OR_ARCHIVED}
                    isMulti
                />
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            onCreate={canEdit ? () => dispatch(push(`${CHANNELS.path}/new`)) : undefined}
                            value={searchQuery}
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    columns={columns}
                    data={channels}
                    loading={loading}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <ChannelFormContainer
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":code"
                    element={
                        <ChannelFormContainer
                            redirectTo={returnUrl}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default ChannelList;
