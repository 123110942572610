import { AdvertiserAccountKey } from 'platform/advertisers/advertiser.types';
import { fetchExternalAdvertiserAccount } from 'platform/advertisers/services/advertiser.service';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';

interface Props {
    accountKey: AdvertiserAccountKey;
}

const AdvertiserAccountDetails = ({ accountKey }: Props) => {
    const [{ data: account }] = usePromise(undefined, () => fetchExternalAdvertiserAccount(accountKey), [
        accountKey,
    ]);

    if (!account) {
        return (
            <div className="position-relative py-4">
                <OverlayLoader />
            </div>
        );
    }

    return (
        <>
            <FormRow
                label={`${getIntegrationLabel(accountKey.externalSystem)} account:`}
                labelWidth={2}
                rowClassName="mb-1"
            >
                <div>
                    {account.name !== accountKey.externalAdvertiserId
                        ? `${account.name} (ID: ${accountKey.externalAdvertiserId})`
                        : accountKey.externalAdvertiserId}
                </div>
            </FormRow>
            <FormRow label="Tracking template:" labelWidth={2} rowClassName="mb-1">
                <div>{account.trackingUrlTemplate || '-'}</div>
            </FormRow>
            <FormRow label="Final URL suffix:" labelWidth={2} rowClassName="mb-1">
                <div>{account.clickThroughUrlSuffix || '-'}</div>
            </FormRow>
        </>
    );
};

export default AdvertiserAccountDetails;
