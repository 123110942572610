import { AdvertiserExternalIntegration } from 'platform/advertisers/advertiser.types';
import AdvertiserIntegration from 'platform/advertisers/components/AdvertiserFormIntegrations/AdvertiserIntegration';

interface Props {
    integrations: AdvertiserExternalIntegration[];
    name: string;
    advertiserId?: number;
    advertiserName?: string;
    onChange: (field: string, value: any) => void;
}

const Integrations = ({ integrations, name, advertiserId, advertiserName, onChange }: Props) => (
    <>
        {integrations.map((integration, index) => {
            const field = `${name}.${index}`;
            return (
                <AdvertiserIntegration
                    key={field}
                    field={field}
                    integration={integration}
                    advertiserId={advertiserId}
                    advertiserName={advertiserName}
                    onChange={onChange}
                />
            );
        })}
    </>
);

export default Integrations;
