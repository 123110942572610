import { Optional } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required, urlHttps } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import { ProductFeed } from './productFeed.types';

const ProductFeedForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: { initialValues, submitForm },
}: FormProps<Optional<ProductFeed, 'id'>>) => (
    <CardForm
        title={`${labels.prefix} product feed`}
        subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
        submitLabel={labels.submit}
        onCancel={onCancel}
        disabled={!canEdit}
        onSubmit={submitForm}
    >
        <ControlledCard title="General info" subtitle="required">
            <FormRow label="Name">
                <FormInput name="name" type="text" validate={required} />
            </FormRow>
            <FormRow label="Feed URL">
                <FormInput name="url" type="text" validate={[required, urlHttps]} />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default ProductFeedForm;
