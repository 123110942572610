import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SECTION_LABELS } from 'platform/app/app.types';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { useActiveTaskBoards } from 'platform/task/hooks/useActiveTaskBoards';
import TaskBoardForm from './TaskBoardForm';
import { createTaskBoard, getTaskBoard, getTaskBoardState, updateTaskBoard } from './task.service';
import { TaskBoardFormModel } from './task.types';

type Props = {
    redirectTo: string;
    afterSubmit: () => void;
};

const NEW_TASK_BOARD: TaskBoardFormModel = {
    name: '',
    status: 'ACTIVE',
    labelNames: {},
    laneOptions: [],
};

const TaskBoardFormContainer = ({ redirectTo, afterSubmit }: Props) => {
    const params = useParams<{ id: string }>();
    const id = params.id ? Number(params.id) : undefined;
    const { id: advertiserId } = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const { reloadActiveTaskBoards } = useActiveTaskBoards();

    const handleOpen = async (): Promise<TaskBoardFormModel> => {
        if (!id) return NEW_TASK_BOARD;
        const [board, state] = await Promise.all([getTaskBoard(id), getTaskBoardState(id)]);
        return {
            ...board,
            labelNames: state.labelNames ?? {},
            laneOptions: state.lanes,
        };
    };

    const handleSubmit = async (board: TaskBoardFormModel) => {
        if (!id) {
            await createTaskBoard(advertiserId, board);
            toastSuccess(`The board can now be accessed under ${SECTION_LABELS.COLLABORATION} section`);
        } else {
            await updateTaskBoard(id, board);
        }
        return reloadActiveTaskBoards(advertiserId);
    };

    return (
        <FormContainer
            helpKey="task_board_form"
            isEdit={!!id}
            sidePanel
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            redirectTo={redirectTo}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <TaskBoardForm {...props} />}
        </FormContainer>
    );
};

export default TaskBoardFormContainer;
