import { publicImageUrl } from 'platform/common/utils/environment.util';
import { Channel } from '../channel.types';
import other from './other.svg';

interface Props {
    channel: Channel | undefined;
    className?: string;
}

const ChannelIcon = ({ channel, className }: Props) => (
    <img
        src={publicImageUrl(channel?.iconUrl) || other}
        alt={channel?.name}
        className={className}
        width="20"
        height="20"
    />
);

export default ChannelIcon;
