import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { LinkDisplay } from 'platform/analytics/analytics.types';
import { getEmbeddedReportUrl } from 'platform/app/components/Sidebar/navigation.util';

type Props = {
    name: string;
    url?: string;
    display?: LinkDisplay;
    className?: string;
    icon?: ReactNode;
};

const ExternalReportLink = ({ name, url, display, className, icon }: Props) => {
    const dispatch = useDispatch();

    switch (display?.openIn) {
        case 'NEW_WINDOW':
            return (
                <a
                    href={url}
                    className={className}
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        e.preventDefault();
                        window.open(url, '_blank', `width=${display?.width}, height=${display?.height}`);
                        return false;
                    }}
                >
                    {icon}
                    {name}
                </a>
            );
        case 'EMBEDDED':
            return (
                <a
                    role="button"
                    className={className}
                    rel="noopener noreferrer"
                    tabIndex={0}
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(push(getEmbeddedReportUrl(url!, name)));
                    }}
                >
                    {icon}
                    {name}
                </a>
            );
        default:
            return (
                <a href={url} className={className} target="_blank" rel="noopener noreferrer">
                    {icon}
                    {name}
                </a>
            );
    }
};

export default ExternalReportLink;
