import { useEffect, useRef } from 'react';
import { Tree, MoveHandler, TreeApi } from 'react-arborist';
import { useDragDropManager } from 'react-dnd';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';
import { useModal } from 'platform/common/components/Modal/Modal';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { useLoading } from 'platform/common/hooks/useLoading';
import WikiSpaceSelect from 'platform/wiki/WikiSpaceSelect';
import { WikiPageData } from 'platform/wiki/wiki.types';
import WikiPageCreateModal from '../WikiPageCreateModal/WikiPageCreateModal';
import useWikiPermissions from '../useWikiPermissions';
import { updatePagePosition } from '../wiki.service';
import WikiDropCursor from './WikiDropCursor';
import WikiNavItem from './WikiNavItem';
import './WikiNavigation.scss';

interface Props {
    wikiPages: WikiPageData[];
    loading: boolean;
    reloadPages: () => void;
}

export type TreePage = {
    name: string;
    id: string;
    parentId?: string;
    children?: TreePage[];
};

const toTreePage = (page: WikiPageData): TreePage => ({
    id: `${page.id}`, // react-arborist requires ids to be strings
    name: page.title,
    parentId: `${page.parentId}`,
});

const getPageChildren = (page: TreePage, wikiPages: WikiPageData[]): TreePage => {
    const children = wikiPages
        .filter((p) => `${p.parentId}` === page.id)
        .map((p) => getPageChildren(toTreePage(p), wikiPages));

    return { ...page, children };
};

const isRootPage = (page: WikiPageData) => !page.parentId;

const WikiNavigation = ({ wikiPages, loading, reloadPages }: Props) => {
    const dragDropManager = useDragDropManager();
    const params = useParams<{ '*': string }>();
    const id = params['*']?.split('/').pop() ?? '';
    const treeRef = useRef<TreeApi<TreePage>>();
    const { canEdit } = useWikiPermissions();
    const { showModal } = useModal();

    const [loadingWikiUpdate, withLoading] = useLoading();

    const treePages = wikiPages
        .filter(isRootPage)
        .map(toTreePage)
        .map((page) => getPageChildren(page, wikiPages));

    const handleMove: MoveHandler<TreePage> = ({ dragIds, parentId, index }) => {
        if (dragIds.length) {
            withLoading(() => updatePagePosition(dragIds[0], index, parentId).then(reloadPages));
        }
    };

    useEffect(() => {
        treeRef.current?.closeAll();
        treeRef.current?.open(id);
        treeRef.current?.openParents(id);
    }, [id, treeRef.current, wikiPages]);

    return (
        <div className="WikiNavigation">
            <WikiSpaceSelect />
            {(loading || loadingWikiUpdate) && <OverlayLoader />}
            <Tree
                ref={treeRef}
                className="WikiNavigation-tree"
                width="100%"
                data={treePages}
                rowHeight={32}
                disableDrag={!canEdit}
                disableDrop={!canEdit}
                disableEdit={!canEdit}
                onMove={handleMove}
                renderCursor={WikiDropCursor}
                dndManager={dragDropManager}
            >
                {(treeProps) => <WikiNavItem {...treeProps} refetch={reloadPages} />}
            </Tree>
            {canEdit && (
                <Button
                    className="WikiNavigation-addBtn"
                    onClick={() =>
                        showModal((toggle) => <WikiPageCreateModal toggle={toggle} refetch={reloadPages} />)
                    }
                >
                    <i className="fa-solid fa-circle-plus" />
                    Add Page
                </Button>
            )}
        </div>
    );
};

export default WikiNavigation;
