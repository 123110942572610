import className from 'classnames';
import { FieldMetaProps } from 'formik';
import ColourBox from 'platform/analytics/reportComponents/ColumnColouring/ColourBox';
import { required } from 'platform/common/utils/validators.util';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import './NumberInputWithColour.scss';

interface Props {
    name: string;
    color: string;
    fieldMeta: FieldMetaProps<number>;
}

const NumberInputWithColour = ({ name, color, fieldMeta: { touched, error } }: Props) => {
    const invalid = touched && !!error;
    return (
        <div className={className('NumberInputWithColour', { invalid })}>
            <ColourBox className="m-1" colour={color} />
            <FormNumberInput
                name={name}
                inputClassName="border-0 shadow-none"
                validate={[required]}
                isValidationMessageVisible={false}
            />
        </div>
    );
};

export default NumberInputWithColour;
