import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { useAnalyticsReports } from 'platform/analytics/hooks/useAnalyticsReports';
import {
    AdvertiserOption,
    activeAdvertiserActions,
    activeAdvertiserSelectors,
} from 'platform/common/ducks/activeAdvertiser.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { useLoading } from 'platform/common/hooks/useLoading';
import { useActiveTaskBoards } from 'platform/task/hooks/useActiveTaskBoards';

export const useAdvertiserContext = () => {
    const activeAdvertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const { reloadAnalyticsMetadata } = useAnalyticsMetadata();
    const { reloadAnalyticsReports } = useAnalyticsReports();
    const { reloadActiveTaskBoards } = useActiveTaskBoards();
    const [advertiserContextLoading, withLoading] = useLoading(true);
    const dispatch = useDispatch();
    const canViewAnalytics = useFeature('ANALYTICS_VIEW');

    const changeAdvertiserContext = (advertiser: AdvertiserOption) =>
        withLoading(async () => {
            if (!canViewAnalytics) return undefined;

            return Promise.all([
                reloadAnalyticsMetadata(advertiser.id),
                reloadAnalyticsReports(advertiser),
                reloadActiveTaskBoards(advertiser.id),
            ]);
        });

    useEffect(() => {
        changeAdvertiserContext(activeAdvertiser);
    }, []);

    const changeAdvertiser = (selectedAdvertisers: AdvertiserOption[]) => {
        dispatch(activeAdvertiserActions.changeAdvertisers(selectedAdvertisers.map((a) => a.id)));
        changeAdvertiserContext(selectedAdvertisers[0]);
    };

    return {
        advertiserContextLoading,
        changeAdvertiser,
    };
};
