import AdvancedSettingList from '../components/AdvancedSettings/AdvancedSettingList';
import AdvertiserTagUpdateList from '../components/AdvertiserTagUpdateList/AdvertiserTagUpdateList';
import ColumnDefinitionList from '../components/ColumnDefinitions/ColumnDefinitionList';
import DataIngestionJobList from '../components/DataIngestion/DataIngestionJobList';
import SegmentSharingTemplateList from '../components/SegmentSharingTemplateList/SegmentSharingTemplateList';
import { SyncJournal } from '../components/SyncJournal/SyncJournal';
import SystemSettingList from '../components/SystemSettings/SystemSettingList';
import TwoFactorAuthentication from '../components/TwoFactorAuthentication/TwoFactorAuthentication';

export const ADMIN_PANEL_TAB_LIST = [
    {
        url: 'segment-sharing-templates',
        name: 'segmentSharingTemplates',
        label: 'Data sharing templates',
        component: SegmentSharingTemplateList,
    },
    {
        url: '2fa',
        name: 'twoFactorAuthentication',
        label: '2FA authentication',
        component: TwoFactorAuthentication,
    },
    {
        url: 'sync-journal',
        name: 'syncJournal',
        label: 'Sync Journal',
        component: SyncJournal,
    },
    {
        url: 'data-ingestion',
        name: 'dataIngestion',
        label: 'Data Ingestion',
        component: DataIngestionJobList,
    },
    {
        url: 'advertiser-tags-update',
        name: 'advertiserTagsUpdate',
        label: 'AdvertiserTag Update',
        component: AdvertiserTagUpdateList,
    },
    {
        url: 'dimensions-and-metrics',
        name: 'dimensionsAndMetrics',
        label: 'Dimensions and Metrics',
        component: ColumnDefinitionList,
    },
    {
        url: 'advanced-settings',
        name: 'advancedSettings',
        label: 'Advanced Settings',
        component: AdvancedSettingList,
    },
    {
        url: 'system-settings',
        name: 'systemSettings',
        label: 'System Settings',
        component: SystemSettingList,
    },
];
