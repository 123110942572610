import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, uniqueId } from 'lodash-es';
import './FunnelChart.scss';

type FunnelChartProps = {
    data: number[];
    chartHeight: number;
    barWidthInPercent?: number;
};

const FunnelChart = ({ data, chartHeight, barWidthInPercent = 40 }: FunnelChartProps) => {
    const [shadowWidth, setShadowWidth] = useState(0);
    const chartElem = useRef<HTMLDivElement>(null);

    const chartData = useMemo(() => {
        const getHeightInPx = (number: number) => (number / Math.max(...data)) * chartHeight;

        return data.map((number, index) => ({
            id: uniqueId('FunnelChart'),
            barHeight: getHeightInPx(number),
            nextBarHeight: getHeightInPx(data[index + 1] || 0),
        }));
    }, [chartHeight, data]);

    const getShadowSkew = (barHeight: number, nextBarHeight: number) =>
        90 - (Math.atan(shadowWidth / (barHeight - nextBarHeight)) * 180) / Math.PI;

    const onResize = useCallback(() => {
        if (chartElem.current) {
            const { offsetWidth } = chartElem.current;
            const shadowWidthInPercent = 100 - barWidthInPercent;

            setShadowWidth((offsetWidth * shadowWidthInPercent) / barWidthInPercent);
        }
    }, [barWidthInPercent, chartElem]);

    useEffect(() => {
        const debouncedOnResize = debounce(onResize, 250);

        window.addEventListener('resize', debouncedOnResize);
        setTimeout(onResize, 0);

        return () => window.removeEventListener('resize', debouncedOnResize);
    }, [onResize]);

    return (
        <div className="FunnelChart overflow-hidden d-flex mt-2" style={{ height: `${chartHeight}px` }}>
            {chartData.map(({ id, barHeight, nextBarHeight }) => (
                <div key={id} className="d-flex h-100" style={{ width: `calc(100% / ${chartData.length})` }}>
                    <div className="d-flex w-100 align-self-end" style={{ height: `${barHeight}px` }}>
                        <div
                            ref={chartElem}
                            className="FunnelChart-bar align-self-end h-100"
                            style={{ width: `${barWidthInPercent}%` }}
                        />
                        <div
                            className="FunnelChart-shadow align-self-end gray-200 flex-grow-1 h-100"
                            style={{
                                transform: `skewY(${getShadowSkew(barHeight, nextBarHeight)}deg)`,
                            }}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FunnelChart;
