import { useSelector } from 'react-redux';
import { OlapReport } from 'platform/analytics/analytics.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { produceRowsForSummary } from 'platform/chat/ChatMessage/ChatAssistantMessage/ChatAssistantOlapMessage/ChatAssistantOlapMessage';
import { CHAT_SUMMARY_AGENT_ID } from 'platform/chat/chat.constants';
import { ChatMessage, ChatMessagePayload } from 'platform/chat/chat.types';
import { chatMessagePollAsyncResult } from 'platform/chat/chat.utis';
import IconButton from 'platform/common/components/IconButton/IconButton';
import Spinner from 'platform/common/components/Spinner/Spinner';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { useFeature } from 'platform/common/hooks/useFeature';
import { ReportAiSummaryContainerProps } from './ReportAiSummaryContainer/ReportAiSummaryContainer';

interface Props {
    report: OlapReport;
    compareReport?: OlapReport;
    summaryProps?: ReportAiSummaryContainerProps;
}

const ReportTableAiSummaryBtn = ({ report, compareReport, summaryProps }: Props) => {
    const isAdmin = useSelector(authSelectors.isAdmin);
    const canAccessChat = useFeature('CHATBOT_DEFAULT_VIEW');
    const canBetaTestChat = useFeature('CHATBOT_BETA_TESTING');

    if (!isAdmin || !canAccessChat || !canBetaTestChat) return null;

    const handleClick = async () => {
        if (summaryProps?.loading) return;

        const rows = produceRowsForSummary(report);
        const compareRows = compareReport ? produceRowsForSummary(compareReport) : [];

        const payload: ChatMessagePayload = {
            agentId: CHAT_SUMMARY_AGENT_ID,
            messages: [
                ...(compareReport
                    ? [
                          {
                              role: 'user',
                              contentParts: [{ type: 'TEXT', content: JSON.stringify(compareRows) }],
                          } as ChatMessage,
                      ]
                    : []),
                {
                    role: 'user',
                    contentParts: [{ type: 'TEXT', content: JSON.stringify(rows) }],
                },
            ],
            placeholders: {},
        };
        summaryProps?.setSummaryOpen(true);

        const res = await summaryProps?.withLoading(() => chatMessagePollAsyncResult(payload, true));

        if (res?.message) {
            summaryProps?.setTextSummary(res.message);
        }
    };

    return (
        <Tooltip
            placement="top-start"
            renderTooltip={() =>
                summaryProps?.loading
                    ? 'Your table summary is loading and will appear below the table itself shortly'
                    : 'DeeplyAI Summary'
            }
        >
            <IconButton onClick={handleClick}>
                {summaryProps?.loading ? (
                    <Spinner style={{ width: 13, height: 13 }} />
                ) : (
                    <i className="fa-solid fa-wand-magic-sparkles text-primary" />
                )}
            </IconButton>
        </Tooltip>
    );
};

export default ReportTableAiSummaryBtn;
