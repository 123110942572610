import { ChangeEvent, ReactNode, createContext, useContext, useMemo, useRef, useState } from 'react';
import { FileInfo } from 'platform/common/utils/file.util';
import { ChatLanguage, PredefinedPrompt } from '../chat.types';
import { useChatFileUpload } from '../useChatFileUpload';
import { useChatMessageContext } from './ChatMessageProvider';
import { useChatPromptContext } from './ChatPromptProvider';

interface ContextType {
    language: ChatLanguage;
    isLanguageMenuOpen: true | undefined;
    handleLanguageSelect: (lng: ChatLanguage) => void;
    handlePredefinedPromptClick: (prompt: PredefinedPrompt) => void;
}

const ChatLanguageContext = createContext<ContextType>({
    language: 'language_auto',
    isLanguageMenuOpen: undefined,
    handleLanguageSelect: () => {},
    handlePredefinedPromptClick: () => {},
});

export const ChatLanguageProvider = ({ children }: { children: ReactNode }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { uploadFiles } = useChatFileUpload();
    const { systemPrompts } = useChatPromptContext();
    const { submit, updateMessagesForCurrentAgent, messages } = useChatMessageContext();

    const [language, setLanguage] = useState<ChatLanguage>('language_auto');
    const [selectedPredefinedPrompt, setSelectedPredefinedPrompt] = useState<PredefinedPrompt | null>(null);
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState<true | undefined>();

    const handleLanguageSelect = (lng: ChatLanguage) => {
        setLanguage(lng);
        setIsLanguageMenuOpen(undefined);

        if (selectedPredefinedPrompt) {
            submit([
                {
                    text: selectedPredefinedPrompt.prompt,
                    prompt: {
                        name: selectedPredefinedPrompt.name,
                        id: selectedPredefinedPrompt.id!,
                        type: 'PREDEFINED',
                    },
                    languageKey: lng,
                },
            ]);

            setSelectedPredefinedPrompt(null);
        }
    };

    const handleUpload = (e: ChangeEvent<HTMLInputElement>, prompt: PredefinedPrompt) => {
        const submitCallback = (fileInfo: FileInfo) => {
            submit([
                {
                    text: prompt.prompt,
                    prompt: {
                        name: prompt.name,
                        id: prompt.id,
                        type: 'PREDEFINED',
                        splitFilePrompt: prompt.splitFilePrompt,
                        forceFileUpload: prompt.forceFileUpload,
                    },
                },
                {
                    text: fileInfo.content as string,
                    fileName: fileInfo.name,
                },
            ]);
        };
        uploadFiles(e.target.files as unknown as File[], submitCallback, prompt.splitFilePrompt);
        fileInputRef.current = null;
    };

    const handlePredefinedPromptClick = (prompt: PredefinedPrompt) => {
        setSelectedPredefinedPrompt(prompt);

        if (prompt.forceFileUpload) {
            fileInputRef.current = document.createElement('input');
            fileInputRef.current.type = 'file';
            fileInputRef.current.style.display = 'none';
            fileInputRef.current.addEventListener(
                'change',
                (e) =>
                    handleUpload(
                        e as unknown as ChangeEvent<HTMLInputElement>,
                        prompt
                    ) as unknown as EventListener
            );
            document.body.appendChild(fileInputRef.current);
            fileInputRef.current.click();
        } else if (prompt.forceLanguage && language === 'language_auto') {
            const forceLanguagePrompt = systemPrompts.find((p) => p.key === 'force_language')?.prompt ?? '';
            setIsLanguageMenuOpen(true);
            updateMessagesForCurrentAgent([
                ...messages,
                { role: 'assistant', contentParts: [{ type: 'TEXT', content: forceLanguagePrompt }] },
            ]);
        } else {
            submit([
                {
                    text: prompt.prompt,
                    prompt: {
                        name: prompt.name,
                        id: prompt.id!,
                        type: 'PREDEFINED',
                    },
                    languageKey: language,
                },
            ]);
        }
    };

    const values = useMemo(
        () => ({ language, isLanguageMenuOpen, handleLanguageSelect, handlePredefinedPromptClick }),
        [language, isLanguageMenuOpen, handleLanguageSelect, handlePredefinedPromptClick]
    );

    return <ChatLanguageContext.Provider value={values}>{children}</ChatLanguageContext.Provider>;
};

export const useChatLanguageContext = () => {
    const context = useContext(ChatLanguageContext);
    if (!context) {
        throw new Error('ChatAgentContext must be used within a ChatLanguageProvider');
    }

    return context;
};
