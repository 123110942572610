import { Alert } from 'reactstrap';
import { sortBy } from 'lodash-es';
import AdvertiserIntegrations from 'platform/advertisers/components/AdvertiserIntegrations/AdvertiserIntegrations';
import { SelectOption } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Separator from 'platform/common/components/Separator/Separator';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import {
    AdvertiserTagFormModel,
    FIRE_LOGGING_PIXEL_OPTIONS,
    INTERNAL_LOG_DATA_ID_TYPE_OPTIONS,
} from '../../advertiserTag.types';
import {
    URL_DETECTION_TYPE_OPTIONS,
    URL_SEGMENT_TRACKING_OPTIONS,
} from '../../constants/advertiserTag.constant';
import EventTrackingList from '../EventTrackingList/EventTrackingList';
import NewCustomEventPanel from './NewCustomEventPanel';
import './AdvertiserTagForm.scss';

export const ADSERVER_CLICK_ID: SelectOption = { label: 'CM360', value: 'GCM' };

const AdvertiserTagForm = ({
    onCancel,
    labels,
    canEdit,
    isEdit,
    formikProps: {
        initialValues,
        submitForm,
        setFieldValue,
        values: { events, advertiserTag, clickIdVendors },
    },
}: FormProps<AdvertiserTagFormModel>) => {
    const clickIdOptions: SelectOption[] = [
        ADSERVER_CLICK_ID,
        ...clickIdVendors.map((v) => ({ label: v.name, value: v.externalSystem })),
    ];

    const onCustomEventCreate = ({ name, variable }: { name: string; variable: string }) => {
        const customEvent = {
            name,
            type: 'CUSTOM_EVENT',
            availability: 'PRIVATE',
            advertiserTagVarName: variable,
            selected: true,
            disabled: false,
            editable: {
                convertible: true,
                productData: true,
                purchaseData: true,
            },
            convertible: false,
            productData: false,
            purchaseData: false,
        };

        setFieldValue('events', [customEvent, ...events]);
    };

    return (
        <CardForm
            title={`${isEdit ? 'Edit' : 'New'} AdvertiserTag`}
            subtitle={isEdit && `ID: ${initialValues.advertiserTag.id}`}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info">
                <FormRow label="Domain">
                    <FormInput name="advertiserTag.domain" type="text" validate={required} />
                    <span style={{ fontSize: '11px', fontWeight: 300 }}>
                        Can contain multiple domains separated by semicolons
                    </span>
                </FormRow>
                {initialValues.advertiser.id && (
                    <FormRow>
                        <AdvertiserIntegrations advertiser={initialValues.advertiser} />
                    </FormRow>
                )}
                <FormRow label="URL detection">
                    <FormSelect
                        name="advertiserTag.urlDetection"
                        options={URL_DETECTION_TYPE_OPTIONS}
                        validate={required}
                        isClearable={false}
                    />
                </FormRow>
                <FormRow label="URL segment tracking">
                    <FormSelect
                        name="advertiserTag.urlSegmentTracking"
                        options={URL_SEGMENT_TRACKING_OPTIONS}
                        validate={required}
                        isClearable={false}
                    />
                </FormRow>
                <Separator label="Optional" />

                <FormRow label="Description">
                    <FormInput name="advertiserTag.description" type="textarea" />
                </FormRow>

                <FormRow label="Debug panel">
                    <FormSwitch name="advertiserTag.debugPanelEnabled" />
                </FormRow>

                {advertiserTag.debugPanelEnabled && (
                    <Alert color="info">
                        In order to access debug panel in the website where AdvertiserTag is implemented,
                        please add this parameter to the URL ?debugadvtag=1 and click enter.
                    </Alert>
                )}
            </ControlledCard>

            <ControlledCard title="Data collection settings">
                <FormRow
                    label="Internal log data"
                    childrenColClassName="d-flex align-items-center"
                    rowClassName="align-items-center"
                >
                    <FormSwitch
                        name="advertiserTag.internalLogDataEnabled"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                setFieldValue('advertiserTag.internalLogDataIdTypes', []);
                            }
                        }}
                    />
                </FormRow>
                {advertiserTag.internalLogDataEnabled && (
                    <div className="mb-3 CreativeForm-internalLogRow">
                        <FormRow
                            label="User ID logging"
                            childrenColClassName="d-flex align-items-center"
                            rowClassName="align-items-center"
                        >
                            <div style={{ width: '290px', marginLeft: '55px' }}>
                                <FormSelect
                                    name="advertiserTag.internalLogDataIdTypes"
                                    options={INTERNAL_LOG_DATA_ID_TYPE_OPTIONS}
                                    isMulti
                                    validate={required}
                                />
                            </div>
                        </FormRow>
                        <FormRow
                            label="Fire logging pixel"
                            childrenColClassName="d-flex align-items-center"
                            rowClassName="align-items-center"
                        >
                            <div style={{ width: '290px', marginLeft: '55px' }}>
                                <FormSelect
                                    name="advertiserTag.fireLoggingPixel"
                                    className="mt-2"
                                    options={FIRE_LOGGING_PIXEL_OPTIONS}
                                    validate={required}
                                />
                            </div>
                        </FormRow>
                    </div>
                )}
                <FormRow label="Click IDs">
                    <FormSwitch
                        name="advertiserTag.clickIdsToggle"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                setFieldValue('advertiserTag.clickIdSystems', []);
                            }
                        }}
                    />
                </FormRow>
                {advertiserTag.clickIdsToggle && (
                    <div className="mb-3 px-4 pb-3 CreativeForm-internalLogRow">
                        <FormSelect
                            name="advertiserTag.clickIdSystems"
                            options={clickIdOptions}
                            isMulti
                            validate={required}
                        />
                    </div>
                )}
                <FormRow label="ABC segments">
                    <FormSwitch name="advertiserTag.trackers.toggles.ABC" />
                </FormRow>
                <FormRow label="Product segments">
                    <FormSwitch name="advertiserTag.trackers.toggles.PRODUCT" />
                </FormRow>
                <FormRow label="Visit frequency">
                    <FormSwitch name="advertiserTag.trackers.toggles.VISIT_FREQUENCY" />
                </FormRow>
                <FormRow label="Visited pages">
                    <FormSwitch name="advertiserTag.trackers.toggles.VISITED_PAGES" />
                </FormRow>
                <FormRow
                    label="Visit time tracking"
                    childrenColClassName="d-flex align-items-center"
                    rowClassName="align-items-center"
                >
                    <FormSwitch
                        name="advertiserTag.trackers.toggles.VISIT_TIME"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                setFieldValue('advertiserTag.trackers.visitTimeIntervals', []);
                            }
                        }}
                    />
                </FormRow>
                {advertiserTag.trackers?.toggles.VISIT_TIME && (
                    <div className="mb-3 px-4 pb-3 CreativeForm-internalLogRow">
                        <FormSelect
                            name="advertiserTag.trackers.visitTimeIntervals"
                            options={sortBy(
                                Object.keys(initialValues.trackersInfo.VISIT_TIME),
                                (key) => initialValues.trackersInfo.VISIT_TIME[key].minTimeOnPage
                            ).map((key) => ({
                                value: key,
                                label: initialValues.trackersInfo.VISIT_TIME[key].segmentName,
                            }))}
                            isMulti
                            validate={required}
                        />
                    </div>
                )}
                <FormRow
                    label="Count one Action per OrderID"
                    labelClassName="pe-0"
                    childrenColClassName="d-flex align-items-center"
                >
                    <FormSwitch name="advertiserTag.oneActionPerOrderIdEnabled" />
                    {advertiserTag.oneActionPerOrderIdEnabled && (
                        <div className="ms-3 d-flex align-items-center">
                            Reset counter every
                            <FormInput
                                name="advertiserTag.oneActionPerOrderIdExpirationDays"
                                type="number"
                                className="mx-2"
                                style={{ width: '75px' }}
                                validate={required}
                            />
                            days
                        </div>
                    )}
                </FormRow>
            </ControlledCard>
            {events && (
                <ControlledCard title="Event tracking">
                    <NewCustomEventPanel onCustomEventCreate={onCustomEventCreate} />
                    <EventTrackingList events={events} onChange={setFieldValue} />
                </ControlledCard>
            )}
        </CardForm>
    );
};

export default AdvertiserTagForm;
