export type LegacyErrorPayload = {
    errorCode: string;
    errorMsg?: string;
    stacktrace?: string;
};

type ErrorType = {
    code?: string;
    message: string;
    field?: string;
};

export type ErrorPayload = {
    errors: ErrorType[];
};

export const parseErrorMessages = (payload: unknown): { message: string; field?: string }[] => {
    if (typeof payload === 'string') {
        return [{ message: payload.length > 100 ? `${payload.slice(0, 100)}...` : payload }];
    }
    if (payload && isErrorPayload(payload)) {
        return payload.errors.map(({ field, message }) => (field ? { field, message } : { message }));
    }
    if (payload && isLegacyErrorPayload(payload)) {
        return [{ message: payload.errorMsg || payload.errorCode }];
    }
    return [{ message: 'Oops! Something went wrong.' }];
};

const isErrorPayload = (payload: unknown): payload is ErrorPayload =>
    (payload as ErrorPayload).errors !== undefined;

const isLegacyErrorPayload = (payload: unknown): payload is LegacyErrorPayload =>
    (payload as LegacyErrorPayload).errorCode !== undefined ||
    (payload as LegacyErrorPayload).errorMsg !== undefined;
