import { useState } from 'react';
import DefaultLogo from 'assets/images/deepmedia.svg';
import './LoginLogo.scss';

const LOGO_BASE_URL = 'https://www.deepmedia.de/logos';

const LoginLogo = () => {
    const subDomain = window.location.hostname.split('.')[0];
    const name = subDomain === 'localhost' ? 'app' : subDomain;

    const [svgFailed, setSvgFailed] = useState(false);
    const [pngFailed, setPngFailed] = useState(false);

    const handleError = () => {
        if (!svgFailed) {
            setSvgFailed(true);
            return;
        }

        setPngFailed(true);
    };

    if (name === 'app' || (svgFailed && pngFailed)) {
        return <img className="LoginLogo mb-5" src={DefaultLogo} alt="Logo" />;
    }

    const extension = svgFailed ? 'png' : 'svg';
    const src = `${LOGO_BASE_URL}/${name}.${extension}`;

    return <img className="LoginLogo mb-5" src={src} alt="Logo" onError={handleError} />;
};

export default LoginLogo;
