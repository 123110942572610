import { useSelector } from 'react-redux';
import { DropdownMenuProps, DropdownProps } from 'reactstrap';
import { ActiveAdvertiserProps } from 'platform/app/AppContext';
import ActiveAdvertiserSelect from 'platform/common/containers/ActiveAdvertiser/ActiveAdvertiserSelect';
import { AdvertiserOption, activeAdvertiserSelectors } from '../../ducks/activeAdvertiser.duck';

type Props = ActiveAdvertiserProps & {
    menuProps?: DropdownMenuProps;
    dropdownProps?: DropdownProps;
    optionsOverride?: AdvertiserOption[];
};

const ActiveAdvertiserAppHeader = ({
    onAdvertisersChange,
    menuProps,
    dropdownProps,
    optionsOverride,
}: Props) => {
    const options = useSelector(activeAdvertiserSelectors.options);
    const activeAdvertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const loading = useSelector(activeAdvertiserSelectors.loading);

    return (
        <ActiveAdvertiserSelect
            value={activeAdvertiser.id}
            options={optionsOverride || options}
            isLoading={loading}
            menuProps={menuProps}
            dropdownProps={dropdownProps}
            onChange={onAdvertisersChange}
        >
            {activeAdvertiser.name}
        </ActiveAdvertiserSelect>
    );
};
export default ActiveAdvertiserAppHeader;
