import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash-es';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Separator from 'platform/common/components/Separator/Separator';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import { getCreativeIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import GcmHostedCreativeFields from 'platform/creatives/components/CreativeBlocks/GcmHostedCreativeFields';
import GcmTrackerCreativeFields from 'platform/creatives/components/CreativeBlocks/GcmTrackerCreativeFields';
import GcmVideoCreativeFields from 'platform/creatives/components/CreativeBlocks/GcmVideoCreativeFields';
import CreativeIntegrationIcon from 'platform/creatives/components/CreativeIntegrations/CreativeIntegrationIcon';
import { conceptExternalSystem } from 'platform/creatives/constants/conceptTypes';
import { ConceptModel, CreativeModel } from 'platform/creatives/types/creative.types';
import CreativeCard from '../CreativeCard/CreativeCard';
import { CREATIVE_FORM_HELP_KEY } from '../CreativeForm/CreativeFormContainer';
import ImportedCreativeFields from './ImportedCreativeFields';

interface Props {
    onCreativeRemove: (index: number) => void;
}

const CreativeBlocks = ({ onCreativeRemove }: Props) => {
    const {
        values: { creatives, advertiserId, type },
        errors,
    } = useFormikContext<ConceptModel>();

    const renderCreativeFields = (field: string, creative: CreativeModel) => {
        switch (creative.type) {
            case 'GCM_TRACKER': {
                return <GcmTrackerCreativeFields field={field} advertiserId={advertiserId} />;
            }
            case 'GCM_HOSTED': {
                return <GcmHostedCreativeFields field={field} advertiserId={advertiserId} />;
            }
            case 'GCM_VIDEO': {
                return <GcmVideoCreativeFields field={field} advertiserId={advertiserId} />;
            }
            default: {
                return (
                    <ImportedCreativeFields
                        creative={creative}
                        externalSystem={conceptExternalSystem(type)}
                        field={field}
                    />
                );
            }
        }
    };

    return (
        <>
            {creatives.map((creative, index) => {
                const { integration } = creative;
                const field = `creatives[${index}]`;
                const creativeErrors = get(errors, field);

                return (
                    <CreativeCard
                        key={creative.id || creative.tempId}
                        creative={creative}
                        hasErrors={!isEmpty(creativeErrors)}
                        isExpanded={creative.isNew}
                        onRemove={() => onCreativeRemove(index)}
                    >
                        {renderCreativeFields(field, creative)}
                        {integration && (
                            <>
                                <Separator label="Integration" />
                                <FormRow
                                    label={getCreativeIntegrationLabel(integration.externalSystem)}
                                    helpKey={`${CREATIVE_FORM_HELP_KEY}_externalSystem`}
                                >
                                    <CreativeIntegrationIcon id={creative.id} integration={integration} />
                                    <span className="ms-1">{integration.externalId}</span>
                                    <span className="ms-4">
                                        <StatusBadge
                                            status={
                                                integration.externalId ? creative.deliveryStatus : 'PENDING'
                                            }
                                        />
                                    </span>
                                </FormRow>
                            </>
                        )}
                    </CreativeCard>
                );
            })}
        </>
    );
};

export default CreativeBlocks;
