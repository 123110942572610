import { isArray, isEqual, keys, mapValues, pick } from 'lodash-es';
import { RoutingItem } from 'platform/app/app.types';
import TaskBoardList from 'platform/task/TaskBoardList';
import { TaskBoardView } from 'platform/task/task.types';
import BoardChangesSidePanel from './ChangeLog/BoardChangesSidePanel';
import TaskBoardContainer from './TaskBoardContainer';

export const TASK_BOARDS_PATH = '/task-boards';
export const TASK_BOARD_CHANGE_LOG_PATH = `${TASK_BOARDS_PATH}/:id/change-log`;
export const TASK_BOARD_TIMELINE_PATH = `${TASK_BOARDS_PATH}/:id/timeline`;
export const TASK_BOARD_EDIT_PATH = `${TASK_BOARDS_PATH}/:id/edit`;
export const TASK_BOARD_MANAGEMENT_PATH = '/task-board-management';

export const matchesTaskBoardView = (params: Record<string, any>, view: TaskBoardView) => {
    // make sure all param values are arrays, so that `isEqual` works correctly:
    const comparedParams = mapValues(pick(params, keys(view.filters)), (v) => (isArray(v) ? v : [v]));
    return isEqual(comparedParams, view.filters);
};

export const taskBoardRoutes: RoutingItem[] = [
    {
        name: 'Task boards',
        path: TASK_BOARD_MANAGEMENT_PATH,
        requiresFeature: 'TASK_BOARD_EDIT',
        component: TaskBoardList,
    },
    {
        name: 'Task board',
        path: `${TASK_BOARDS_PATH}/:id`,
        requiresFeature: 'TASK_EDIT',
        component: TaskBoardContainer,
    },
    {
        name: 'Task board change log',
        path: TASK_BOARD_CHANGE_LOG_PATH,
        requiresFeature: 'TASK_EDIT',
        component: BoardChangesSidePanel,
    },
];
