import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import { isString } from 'lodash-es';
import RadioList from 'platform/common/components/RadioList/RadioList';
import { Validator, validator } from 'platform/common/utils/validators.util';

type Value = number | string | boolean;

interface Props {
    id?: string;
    options: { label?: string; value: Value; children?: Function; icon?: string; disabled?: boolean }[];
    className?: string;
    optionsClassName?: string;
    tag?: string;
    radioListGroupClassName?: string;
    name: string;
    validate?: Validator | Validator[];
    disabled?: boolean;
    onChange?: (value: Value) => void;
    label?: string;
}

const FormRadioList = ({
    name,
    options,
    tag,
    radioListGroupClassName,
    validate,
    className = '',
    optionsClassName = '',
    disabled = false,
    onChange,
    label,
}: Props) => {
    const [field, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const { error, touched } = meta;

    return (
        <>
            <RadioList
                label={label}
                className={className}
                optionsClassName={optionsClassName}
                options={options}
                onRadioChange={(value) => {
                    helpers.setValue(value);
                    if (onChange) {
                        onChange(value);
                    }
                }}
                value={field.value}
                name={name}
                disabled={disabled}
                tag={tag}
                radioListGroupClassName={radioListGroupClassName}
            />
            {touched && error && isString(error) ? (
                <FormFeedback className="d-block">{error}</FormFeedback>
            ) : null}
        </>
    );
};

export default FormRadioList;
