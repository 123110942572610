import { useState } from 'react';
import {
    Product,
    ProductCategory,
    ProductSubLabel,
} from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import DynamicList from 'platform/common/components/DynamicList/DynamicList';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { AddOn } from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { namesToOptions } from 'platform/common/utils/option.util';
import { required, uriComponent } from 'platform/common/utils/validators.util';
import FormCreatableSelect from 'platform/formik/FormCreatableSelect/FormCreatableSelect';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

export interface ProductLabelFormModel {
    original: Product;
    product: Product;
    categories: ProductCategory[];
    isRenaming?: boolean;
}

const ProductLabelForm = ({
    onCancel,
    labels,
    canEdit,
    isEdit,
    formikProps: {
        initialValues: { categories },
        values: { isRenaming, product },
        setFieldValue,
        submitForm,
        resetForm,
    },
}: FormProps<ProductLabelFormModel>) => {
    const [newCategory, setNewCategory] = useState<string>();
    const showConfirmationModal = useConfirmationModal();

    const switchToRenaming = () =>
        showConfirmationModal(
            () => {
                resetForm();
                setFieldValue('isRenaming', true);
            },
            {
                title: 'Switching to rename mode',
                text: 'This will turn on the ability to rename this product and sub-products. If you have made any changes since opening the form, they will be lost. Continue?',
            }
        );

    const renamingAddOn: AddOn | undefined =
        isEdit && !isRenaming ? { className: 'fa fa-pen', onClick: switchToRenaming } : undefined;

    return (
        <CardForm
            title={`${isRenaming ? 'Renaming' : labels.prefix} product`}
            submitLabel={isRenaming ? 'Rename' : labels.submit}
            disabled={!canEdit}
            onCancel={onCancel}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Product category">
                    <FormCreatableSelect
                        name="product.category"
                        placeholder="Select or enter..."
                        options={namesToOptions(
                            newCategory ? [...categories, { name: newCategory }] : categories
                        )}
                        onNewOption={setNewCategory}
                        formatCreateLabel={(value) => (
                            <span>
                                <b>Create new category</b>
                                {` "${value}"`}
                            </span>
                        )}
                        validate={uriComponent}
                        disabled={isRenaming}
                    />
                </FormRow>
                <FormRow label="Product name">
                    <FormInput
                        name="product.name"
                        type="text"
                        disabled={isEdit && !isRenaming}
                        validate={[required, uriComponent]}
                        rightAddOn={renamingAddOn}
                    />
                </FormRow>
                <FormRow label="Status">
                    <FormSelect
                        name="product.status"
                        options={ACTIVE_OR_ARCHIVED}
                        validate={required}
                        isClearable={false}
                        disabled={isRenaming}
                    />
                </FormRow>
            </ControlledCard>
            <ControlledCard title="Sub-products" subtitle="optional">
                <DynamicList
                    items={product.subLabels}
                    newItem={{ name: '', status: 'ACTIVE' } as ProductSubLabel}
                    onChange={(subLabels) => setFieldValue('product.subLabels', subLabels)}
                    disabled={isRenaming}
                >
                    {(subLabel, index) => (
                        <div className="d-flex gap-2">
                            <FormInput
                                name={`product.subLabels.${index}.name`}
                                placeholder="e.g. Apple > iPhone > iPhone 11 Pro"
                                disabled={isEdit && !isRenaming && !subLabel.__isNew__}
                                validate={required}
                                className="flex-grow-1"
                                rightAddOn={subLabel.__isNew__ ? undefined : renamingAddOn}
                            />
                            <FormSelect
                                name={`product.subLabels.${index}.status`}
                                options={ACTIVE_OR_ARCHIVED}
                                isClearable={false}
                                selectStyle={{ minWidth: 120 }}
                                disabled={isRenaming}
                            />
                        </div>
                    )}
                </DynamicList>
            </ControlledCard>
        </CardForm>
    );
};

export default ProductLabelForm;
