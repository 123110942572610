interface Props {
    fill?: string;
}

const IoIcon = ({ fill = '#5D6A79' }: Props) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1457 2.40571L10 0L6.85434 2.40571L2.92893 2.92893L2.40571 6.85434L0 10L2.40571 13.1457L2.92893 17.0711L6.85434 17.5943L10 20L13.1457 17.5943L17.0711 17.0711L17.5943 13.1457L20 10L17.5943 6.85434L17.0711 2.92893L13.1457 2.40571ZM8.33265 13.889C8.62779 13.889 8.8882 13.6459 8.8882 13.3334C8.8882 13.0383 8.62779 12.7779 8.33265 12.7779H6.66598V7.22234H8.33265C8.62779 7.22234 8.8882 6.97928 8.8882 6.66678C8.8882 6.37164 8.62779 6.11123 8.33265 6.11123H3.8882C3.5757 6.11123 3.33265 6.37164 3.33265 6.66678C3.33265 6.97928 3.5757 7.22234 3.8882 7.22234H5.55487V12.7779H3.8882C3.5757 12.7779 3.33265 13.0383 3.33265 13.3334C3.33265 13.6459 3.5757 13.889 3.8882 13.889H8.33265ZM8.8882 10.0175C8.8882 7.8647 10.6243 6.12859 12.7771 6.12859C14.9125 6.12859 16.666 7.8647 16.666 10.0175C16.666 12.1529 14.9125 13.9064 12.7771 13.9064C10.6243 13.9064 8.8882 12.1529 8.8882 10.0175ZM9.99932 10.0001C9.99932 11.5453 11.232 12.7779 12.7771 12.7779C14.3049 12.7779 15.5549 11.5279 15.5549 10.0001C15.5549 8.4897 14.3049 7.2397 12.7771 7.2397C11.232 7.2397 9.99932 8.47234 9.99932 10.0001Z"
            fill={fill}
        />
    </svg>
);

export default IoIcon;
