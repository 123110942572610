import { MouseEvent } from 'react';
import { MultiValueProps, components } from 'react-select';
import { OptionType, SelectTreeComponentProps } from '../Select/select.types';
import { isAnyChildNodeSelected } from './SelectTree.utils';

const formLabel = (
    data: OptionType,
    options: OptionType[] = [],
    getOptionNodes: (value: OptionType) => any[],
    getOptionLabel: (value: OptionType) => string,
    getOptionValue: (value: OptionType) => any,
    isBreadcrumbsShown: boolean
) => {
    const rootParentOption = options.find((option) =>
        isAnyChildNodeSelected(option, [data], getOptionNodes, getOptionValue)
    );

    const label = getOptionLabel(data);
    return rootParentOption && isBreadcrumbsShown && getOptionValue(rootParentOption) !== getOptionValue(data)
        ? `${getOptionLabel(rootParentOption)} > ${label}`
        : label;
};

const SelectTreeValue = (props: MultiValueProps<OptionType>) => {
    const { data } = props;

    const {
        options,
        isBreadcrumbsShown,
        getOptionNodes,
        getOptionValue,
        getOptionLabel,
        setHighlightedValues,
        highlightedValues,
    } = props.selectProps as SelectTreeComponentProps<OptionType>;

    const label = formLabel(
        data,
        options,
        getOptionNodes,
        getOptionLabel,
        getOptionValue,
        isBreadcrumbsShown
    );

    const value = getOptionValue(data);
    const optionId = `select-tree-value-${value}`;

    const highlightValue = (e: MouseEvent<HTMLDivElement>) => {
        if (e.metaKey) {
            e.stopPropagation();
            if (!highlightedValues.includes(value)) {
                setHighlightedValues((prev) => [...prev, value]);
            } else {
                setHighlightedValues((prev) => prev.filter((val) => val !== value));
            }
        }
    };

    return (
        <div role="button" tabIndex={-1} id={optionId} onClick={highlightValue}>
            <components.MultiValueLabel {...props}>{label}</components.MultiValueLabel>
        </div>
    );
};

export default SelectTreeValue;
