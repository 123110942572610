import { useParams } from 'react-router-dom';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import CreativeLabelForm from 'platform/creatives/components/CreativeLabelForm/CreativeLabelForm';
import { fetchCreativeLabel, updateCreativeLabel } from 'platform/creatives/services/creativeLabel.service';
import { CreativeLabelGroup } from 'platform/creatives/types/creativeLabel.types';

const CreativeLabelEdit = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const handleOpen = (): Promise<CreativeLabelGroup> => fetchCreativeLabel(id);
    const handleSubmit = (data: CreativeLabelGroup) => updateCreativeLabel(data);

    return (
        <FormContainer
            helpKey="creative_label_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            sidePanel
            isEdit
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <CreativeLabelForm {...props} />}
        </FormContainer>
    );
};

export default CreativeLabelEdit;
