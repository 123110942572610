import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { useAdvertiserExternalAccountOptions } from 'platform/common/utils/advertiser.util';
import { CREATIVE_FORM_HELP_KEY } from 'platform/creatives/components/CreativeForm/CreativeFormContainer';

type Props = {
    advertiserId: number;
    externalSystem: ExternalSystem;
    externalAdvertiserId: string;
};

const AdvertiserAccountFormLabel = ({ advertiserId, externalSystem, externalAdvertiserId }: Props) => {
    const accounts = useAdvertiserExternalAccountOptions(advertiserId)[externalSystem];
    const accountName = accounts?.find((a) => a.value === externalAdvertiserId)?.label;

    return (
        <FormHelp helpKey={`${CREATIVE_FORM_HELP_KEY}_externalAdvertiserId`}>
            <InputWithAddOn
                leftAddOn={{ title: 'Account' }}
                disabled
                value={
                    accountName && accountName !== externalAdvertiserId
                        ? `${accountName} (ID: ${externalAdvertiserId})`
                        : externalAdvertiserId
                }
            />
        </FormHelp>
    );
};

export default AdvertiserAccountFormLabel;
