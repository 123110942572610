import { ReactNode, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { FormikProps } from 'formik';
import CodeMirrorSrc from 'platform/common/components/CodeMirror/CodeMirrorSource';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';

type Props = {
    title: string;
    snippet: string;
    onSave?: () => Promise<any>;
    onChange: FormikProps<any>['setFieldValue'];
    path: string;
    children?: ReactNode;
};

const EditableSnippet = (props: Props) => {
    const { snippet, title, onSave, onChange, path, children } = props;

    const [disabled, setDisabled] = useState(true);
    const [initialSnippet, setInitialSnippet] = useState(snippet);
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    return (
        <span>
            <ControlledCard title={title}>
                <CodeMirrorSrc
                    name="advertiserTagLite.actionMap"
                    className="form-control"
                    value={snippet}
                    onChange={(value: string) => onChange(path, value)}
                    options={{ readOnly: disabled }}
                />
                {children}
                {isErrorVisible && <Alert color="danger">Failed to save {title}</Alert>}
                <div className="pt-4">
                    <Button
                        className="me-2"
                        color={'secondary'}
                        onClick={() => {
                            setDisabled(!disabled);
                            if (!disabled) {
                                onChange(path, initialSnippet);
                                setIsErrorVisible(false);
                            }
                        }}
                    >
                        {disabled ? 'Edit' : 'Cancel'}
                    </Button>
                    {onSave && (
                        <Button
                            disabled={disabled}
                            color={'primary'}
                            onClick={() => {
                                setIsErrorVisible(false);
                                onSave()
                                    .then(() => {
                                        setDisabled(true);
                                        setInitialSnippet(snippet);
                                        setIsErrorVisible(false);
                                    })
                                    .catch(() => setIsErrorVisible(true));
                            }}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </ControlledCard>
        </span>
    );
};

export default EditableSnippet;
