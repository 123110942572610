import classNames from 'classnames';
import { JourneyEventTypeCM360 } from 'platform/dashboard/dashboard.types';

const getJourneyIconClass = (event_type: JourneyEventTypeCM360) => {
    switch (event_type) {
        case 'click':
            return 'far fa-hand-pointer text-dark';
        case 'view':
            return 'far fa-eye text-dark';
        case 'action':
        case 'pageview':
            return 'fa-regular fa-window-restore text-primary';
        default:
            return '';
    }
};

interface Props {
    eventType: JourneyEventTypeCM360;
}

const JourneyEventTypeIcon = ({ eventType }: Props) => (
    <i
        className={classNames(
            'd-flex align-items-center justify-content-center font-xl',
            getJourneyIconClass(eventType)
        )}
        style={{
            borderRadius: '50%',
            width: 45,
            height: 45,
            backgroundColor: TYPE_BG_COLOR_MAP[eventType],
        }}
    />
);

const TYPE_BG_COLOR_MAP: Record<JourneyEventTypeCM360, string> = {
    click: '#F9EDBB',
    view: '#F9EDBB',
    action: '#DCF5E7',
    pageview: '#e7f3fb',
};

export default JourneyEventTypeIcon;
