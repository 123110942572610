import { isNil, trim } from 'lodash-es';
import moment from 'moment';
import {
    campaignClassificationToApi,
    campaignClassificationToForm,
} from 'platform/campaign/campaign/campaignClassification.mapper';
import { CampaignClassificationModel } from 'platform/campaign/campaign/campaignClassification.types';
import { isDefined } from 'platform/common/common.types';
import { Adserver } from 'platform/common/constants/externalIntegration.constant';
import { formatDate } from 'platform/common/utils/date.util';
import { precisionRound } from 'platform/common/utils/formatters.util';
import { MediaInsertionLink } from 'platform/mediaplan/mediaplan.types';
import { Campaign, CampaignModel, Monetization } from '../campaign.types';

const getFlightDates = (dateFrom: any, dateTo: any) => {
    const from = dateFrom || formatDate(moment());
    return {
        from,
        to: dateTo || formatDate(moment(dateFrom).add(30, 'days')),
    };
};

const removeHtmlElements = (string: string) => string.replace(/<[^>]+>/g, '');

export const initCampaign = ({
    advertiserId,
    adserver,
}: {
    advertiserId: number;
    adserver: Adserver;
}): CampaignModel => ({
    name: '',
    mediaType: 'DIGITAL',
    type: 'REDIRECT',
    advertiserId,
    notes: undefined,
    adserver,
    classification: {
        countryLabels: [],
        channel: undefined,
        searchTermStrategy: undefined,
        targetAudience: undefined,
        marketingGoal: undefined,
    },
    flightDates: getFlightDates(undefined, undefined),
    monetization: {
        costPerSpendPercentage: 0,
    },
    mediaInsertionLinks: [],
    redirectVendorId: undefined,
});

export const monetizationToApi = ({
    productFeeTargetCPM,
    productFeeFloor,
    costPerSpendPercentage,
    productFeeSpendX,
}: Monetization): Monetization => ({
    productFeeTargetCPM,
    productFeeFloor,
    costPerSpendPercentage: !isNil(costPerSpendPercentage)
        ? precisionRound(costPerSpendPercentage / 100, 4)
        : undefined,
    productFeeSpendX: !isNil(productFeeSpendX) ? precisionRound(productFeeSpendX / 100, 4) : undefined,
});

export const monetizationToForm = ({
    costPerSpendPercentage,
    productFeeSpendX,
    productFeeTargetCPM,
    productFeeFloor,
}: Monetization): Monetization => ({
    productFeeTargetCPM,
    productFeeFloor,
    costPerSpendPercentage: !isNil(costPerSpendPercentage)
        ? precisionRound(costPerSpendPercentage * 100, 2)
        : undefined,
    productFeeSpendX: !isNil(productFeeSpendX) ? precisionRound(productFeeSpendX * 100, 2) : undefined,
});

export const apiToFormModel = (
    {
        id,
        name,
        clientBudget,
        advertiserId,
        vendorSystem,
        type,
        channel,
        targetAudience,
        marketingGoal,
        searchTermStrategy,
        dateFrom,
        dateTo,
        importedOn,
        creativeConceptId,
        productLabel,
        productSubLabel,
        flightLabel,
        countryLabels,
        monetization,
        integration,
        redirectVendorId,
        redirectExternalId,
        redirectExternalSystem,
        clickThroughUrlSuffix,
        notes,
        adserver,
        timeOfferIds,
    }: Campaign,
    mediaInsertionLinks: MediaInsertionLink[]
): CampaignModel => ({
    id,
    name,
    clientBudget,
    advertiserId,
    adserver,
    notes: notes ? removeHtmlElements(notes) : '',
    classification: campaignClassificationToForm({
        channel,
        targetAudience,
        marketingGoal,
        searchTermStrategy,
        productLabel,
        productSubLabel,
        flightLabel,
        countryLabels,
        timeOfferIds,
    }),
    vendorSystem,
    mediaType: type === 'TRADITIONAL' ? 'TRADITIONAL' : 'DIGITAL',
    type,
    flightDates: getFlightDates(dateFrom, dateTo),
    importedOn,
    creativeConceptId,
    monetization: monetizationToForm(monetization || {}),
    integration,
    redirectVendorId,
    mediaInsertionLinks,
    redirectExternalId,
    redirectExternalSystem,
    clickThroughUrlSuffix,
});

export const searchTermStrategyAvailable = ({ channel }: CampaignClassificationModel) =>
    channel === 'SEARCH' || channel === 'PRICE_COMPARISON';

export const toApiModel = (campaign: CampaignModel): Omit<Campaign, 'id' | 'adserver'> => ({
    advertiserId: campaign.advertiserId,
    clientBudget: campaign.clientBudget,
    name: trim(campaign.name),
    // for each integration there should be a Vendor with the same externalSystem:
    vendorSystem: campaign.vendorSystem ?? campaign.integration?.externalSystem,
    type: campaign.type,
    ...campaignClassificationToApi(campaign.classification),
    mediaInsertionIds: campaign.mediaInsertionLinks
        .filter((link) => link.matchType === 'EXPLICIT')
        .map((link) => link.mediaInsertionId)
        .filter(isDefined),
    notes: campaign.notes,
    dateFrom: campaign.flightDates.from,
    dateTo: campaign.flightDates.to,
    monetization: monetizationToApi(campaign.monetization ?? {}),
    redirectVendorId: campaign.redirectVendorId,
    redirectExternalSystem: campaign.redirectExternalSystem,
    redirectExternalId: campaign.redirectExternalSystem ? trim(campaign.redirectExternalId) : undefined,
    clickThroughUrlSuffix: campaign.clickThroughUrlSuffix,
});
