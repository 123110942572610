import { useDispatch } from 'react-redux';
import { helpActions } from 'platform/common/ducks/help.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { fetchHelp } from '../services/help.service';

export const useHelp = () => {
    const dispatch = useDispatch();
    const canEdit = useFeature('HELP_EDIT');
    const canView = useFeature('HELP_VIEW');

    const updateHelpState = async () => {
        const help = await (canEdit || canView ? fetchHelp() : []);
        dispatch(helpActions.setHelp(help));
    };

    return {
        updateHelpState,
    };
};
