import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import { User } from 'platform/userManagement/types/user.type';

interface Props {
    users: User[];
}

const AssignedUsers = ({ users }: Props) => {
    if (!users.length) {
        return null;
    }

    const usersWithAccess = users.filter((user) => ['ACTIVE', 'PENDING'].includes(user.status));
    const usersWithNoAccess = users.filter((user) =>
        ['INACTIVE', 'ARCHIVED', 'PENDING_INACTIVE'].includes(user.status)
    );

    return (
        <ControlledCard title="Usage">
            <p className="fw-bold">Users with access (Status: Active, Pending)</p>
            {!usersWithAccess.length && 'No users assigned.'}
            {usersWithAccess.map((user) => (
                <p key={user.login}>
                    {user.name} - {user.login}
                </p>
            ))}
            <hr />
            <p className="fw-bold">Users with no access (Status: Inactive, Archived, Pending Inactive)</p>
            {!usersWithNoAccess.length && 'No users assigned.'}
            {usersWithNoAccess.map((user) => (
                <p key={user.login}>
                    {user.name} - {user.login}
                </p>
            ))}
        </ControlledCard>
    );
};

export default AssignedUsers;
