import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import useToggle from 'platform/common/hooks/useToggle';
import { formatDateTime } from 'platform/common/utils/date.util';
import { inTextLinkFormat } from 'platform/common/utils/url.util';
import { ObserverMessage } from 'platform/observerMessageCenter/observerMessageCenter.types';
import { User } from 'platform/userManagement/types/user.type';
import MessageCenterAttachment from './MessageCenterAttachment';
import MessageCenterItemAssignees from './MessageCenterItemAssignees';
import MessageCenterItemHighlight from './MessageCenterItemHighlight';
import MessageCenterItemNote from './MessageCenterItemNote';
import MessageCenterItemPriority from './MessageCenterItemPriority';
import MessageCenterStatusSelect from './MessageCenterStatusSelect/MessageCenterStatusSelect';

interface Props {
    messageData: ObserverMessage;
    assigneeOptions: User[];
    onHistoryClick: (id: number) => void;
    onChange: () => void;
}

const MessageCenterListItem = ({ messageData, assigneeOptions, onHistoryClick, onChange }: Props) => {
    const [expanded, toggleExpanded] = useToggle(false);
    const processedText = inTextLinkFormat(messageData.text, /https:\/\/app\.deepmedia\.de\/?\S*/g);

    return (
        <li className="ObserverMessageCenterList-itemContainer">
            <MessageCenterItemHighlight type={messageData.type} />
            <div
                className={classNames('ObserverMessageCenterList-item', {
                    'ObserverMessageCenterList-item--expanded': expanded,
                })}
                tabIndex={0}
                role="button"
                onClick={() => toggleExpanded()}
            >
                <div className="d-flex align-items-center col-4">
                    <div style={{ width: 150 }} className="mx-2">
                        <MessageCenterStatusSelect messageData={messageData} onChange={onChange} />
                    </div>
                    <div style={{ width: 40 }} className="mx-2">
                        <MessageCenterItemPriority priority={messageData.priority} />
                    </div>
                    <div className={classNames('mx-2 fw-bold', !expanded && 'text-truncate')}>
                        {messageData.title}
                    </div>
                </div>
                <div
                    className="d-flex align-items-center text-truncate flex-grow-1"
                    role="button"
                    tabIndex={0}
                    onClick={(e) => e && expanded && e.stopPropagation()}
                >
                    <RawHtml
                        className={classNames(
                            'me-2 mb-0 h-100',
                            !expanded && 'text-truncate',
                            expanded && 'display-linebreak'
                        )}
                    >
                        {processedText}
                    </RawHtml>
                </div>
                <div className="mx-2 ObserverMessageCenterList-actions col-2">
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onHistoryClick(messageData.id);
                        }}
                    >
                        <i className="fa-solid fa-clock-rotate-left text-light-slate-gray fs-5" />
                    </IconButton>
                </div>
                <div className="mx-2 ObserverMessageCenterList-date col-2">
                    {formatDateTime(messageData.createdOn)} - ID {messageData.reportId}
                </div>
            </div>
            <div className={classNames('pb-2 pe-2', !expanded && 'd-none')}>
                <MessageCenterItemNote note={messageData.note} />
                <hr className="m-2" style={{ borderTop: '1px dashed #98a2b3' }} />
                <div className="d-flex mt-2">
                    <div className="col-4 pe-3">
                        <MessageCenterItemAssignees
                            assigneeOptions={assigneeOptions}
                            messageData={messageData}
                            className="ms-2"
                        />
                    </div>
                    <MessageCenterAttachment messageData={messageData} />
                </div>
            </div>
        </li>
    );
};

export default MessageCenterListItem;
