import classNames from 'classnames';
import FileIcon from 'platform/common/components/FileLink/FileIcon';
import FileLabel from 'platform/common/components/FileLink/FileLabel';
import RoundIconButton from 'platform/common/components/RoundIconButton/RoundIconButton';
import './ChatAttachmentsEdit.scss';

interface Props {
    attachment: File;
    onDelete?: () => void;
}

const ChatAttachment = ({ attachment, onDelete }: Props) => {
    const isImage = attachment.type.startsWith('image/');
    const imageSrc = isImage ? URL.createObjectURL(attachment) : undefined;

    return (
        <div className="ChatAttachmentsEdit-attachment">
            <div
                className={classNames(
                    isImage ? 'p-0' : 'p-2',
                    'd-flex position-relative cursor-pointer no-text-decoration outline-0 align-items-center',
                    'flex-column w-100 flex-grow-1 overflow-hidden rounded'
                )}
            >
                <div
                    className="ChatAttachmentsEdit-attachment-image-container w-100"
                    style={{ maxHeight: isImage ? '100%' : '60px' }}
                >
                    {isImage ? (
                        <img src={imageSrc} alt={attachment.name} className="w-100 h-100" />
                    ) : (
                        <FileIcon filename={attachment.name} />
                    )}
                </div>
                {!isImage && <FileLabel className="fw-bold mt-1 w-100" filename={attachment.name} />}
            </div>

            {onDelete && (
                <RoundIconButton
                    className="ChatAttachmentsEdit-attachment-remove"
                    icon="fa fa-xmark"
                    onClick={onDelete}
                />
            )}
        </div>
    );
};

export default ChatAttachment;
