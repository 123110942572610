import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { ACTIVE_OR_INACTIVE } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { makeOptions } from 'platform/common/utils/option.util';
import { noWhiteSpace, required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import {
    ADVANCED_SETTING_GROUP_LABELS,
    ADVANCED_SETTING_VALUE_TYPE_LABELS,
    AdvancedSetting,
} from './advanced.setting.types';

const AdvancedSettingForm = ({
    labels,
    formikProps: { submitForm },
    isEdit,
    onCancel,
}: FormProps<AdvancedSetting>) => (
    <CardForm
        title={`${labels.prefix} advanced setting`}
        submitLabel={labels.submit}
        onSubmit={submitForm}
        onCancel={onCancel}
    >
        <ControlledCard title="General info">
            <FormRow label="Key">
                <FormInput name="key" type="text" disabled={isEdit} validate={[required, noWhiteSpace]} />
            </FormRow>
            <FormRow label="Name">
                <FormInput name="name" type="text" validate={required} />
            </FormRow>
            <FormRow label="Status">
                <FormSelect
                    name="status"
                    options={ACTIVE_OR_INACTIVE}
                    isSearchable={false}
                    isClearable={false}
                />
            </FormRow>
            <FormRow label="Group">
                <FormSelect
                    name="group"
                    options={makeOptions(ADVANCED_SETTING_GROUP_LABELS)}
                    isSearchable={false}
                    isClearable={false}
                />
            </FormRow>
            <FormRow label="Value type">
                <FormSelect
                    name="valueType"
                    options={makeOptions(ADVANCED_SETTING_VALUE_TYPE_LABELS)}
                    isSearchable={false}
                    isClearable={false}
                />
            </FormRow>
            <FormRow label="Description">
                <FormInput name="description" type="textarea" rows={4} />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default AdvancedSettingForm;
