import { ReactNode } from 'react';
import { AnalyticsProps } from 'platform/analytics/modes/Analytics';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { COMPARE_OPTIONS } from '../analytics.constants';

interface Props {
    children: (props: AnalyticsProps) => ReactNode;
}

const BudgetReporting = ({ children }: Props) => {
    useActiveAdvertiserComponent();

    return (
        <>
            {children({
                datePickerDisabled: true,
                compareOptions: COMPARE_OPTIONS.filter((option) => option.value === 'AVERAGE'),
                componentsProps: {
                    SUMMARY_BAR: {
                        canChangeColumn: false,
                    },
                },
                filterInputProps: { media_plan_id: { isMulti: false } },
            })}
        </>
    );
};

export default BudgetReporting;
