import { Form } from 'reactstrap';
import { AdvertiserExternalIntegration } from 'platform/advertisers/advertiser.types';
import Integrations from 'platform/advertisers/components/AdvertiserFormIntegrations/Integrations';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FloatingButton from 'platform/common/components/FloatingButton/FloatingButton';
import FormRow from 'platform/common/components/FormRow/FormRow';
import {
    Adserver,
    ExternalSystem,
    getIntegrationLabel,
} from 'platform/common/constants/externalIntegration.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';

export interface AdvertiserIntegrationsFormModel {
    id: number;
    name: string;
    activeAdserver: Adserver;
    externalSystemIntegrations: AdvertiserExternalIntegration[];
}

const AdvertiserIntegrationsForm = ({
    formikProps: {
        setFieldValue,
        submitForm,
        values: { id, name, activeAdserver, externalSystemIntegrations },
    },
    loading,
}: FormProps<AdvertiserIntegrationsFormModel> & { loading: boolean }) => (
    <Form>
        <ControlledCard title="Integrations" id="integrations">
            <Integrations
                name="externalSystemIntegrations"
                onChange={setFieldValue}
                integrations={externalSystemIntegrations}
                advertiserId={id}
                advertiserName={name}
            />
            <FormRow label="Ad server">{getIntegrationLabel(activeAdserver as ExternalSystem)}</FormRow>
        </ControlledCard>
        <FloatingButton icon="far fa-pen-to-square" disabled={loading} onClick={submitForm}>
            Update
        </FloatingButton>
    </Form>
);

export default AdvertiserIntegrationsForm;
