import { removeById } from 'platform/common/utils/array.util';
import { TaskAttachment, TaskAttachmentChange } from '../task.types';

export const attachmentReducer = (
    attachments: TaskAttachment[],
    c: TaskAttachmentChange
): TaskAttachment[] => {
    switch (c.type) {
        case 'ATTACHMENT_ADDED':
            return [
                ...attachments,
                {
                    ...c.details.attachment,
                    name: decodeURIComponent(c.details.attachment.name),
                },
            ];
        case 'ATTACHMENT_DELETED':
            return removeById(attachments, c.details.attachment.id);
        default:
            return attachments;
    }
};
