import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClassificationOptionsProvider } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableForm/ClassificationOptionsContext';
import { GlobalCostSourcePreset } from 'platform/campaign/globalPreset/globalPreset.types';
import { toApiModel, toPreset } from 'platform/campaign/globalPreset/mappers/globalPreset.mapper';
import globalCostSourceService from 'platform/campaign/globalPreset/services/globalCostSourcePreset.service';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import GlobalCostSourceForm from './GlobalCostSourceForm';

const GlobalCostSourceFormContainer = ({ redirectTo, canEdit, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = params.id && Number(params.id);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const channels = useSelector(classifierSelectors.channels);

    const handleOnOpen = async () =>
        toPreset(
            id
                ? await globalCostSourceService.fetchGlobalCostSource(id)
                : { name: '', advertiserId, monetization: {} }
        );

    const handleSubmit = async (data: GlobalCostSourcePreset) =>
        id
            ? globalCostSourceService.updateGlobalCostSource(id, toApiModel(data))
            : globalCostSourceService.createGlobalCostSource(toApiModel(data));

    return (
        <FormContainer
            helpKey="global_cost_source_form"
            size={SIDE_PANEL_SIZES.WIDE}
            redirectTo={redirectTo}
            canEdit={canEdit}
            sidePanel
            isEdit={!!id}
            onOpen={handleOnOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => (
                <ClassificationOptionsProvider>
                    <GlobalCostSourceForm {...props} channels={channels} />
                </ClassificationOptionsProvider>
            )}
        </FormContainer>
    );
};

export default GlobalCostSourceFormContainer;
