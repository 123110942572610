interface Props {
    width?: number;
    height?: number;
}

const StackBarSvg = ({ width = 24, height = 24 }: Props) => (
    <svg width={width} height={height} viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0.5C0 0.223858 0.223858 0 0.5 0H23.5C23.7761 0 24 0.223858 24 0.5C24 0.776142 23.7761 1 23.5 1H0.5C0.223857 1 0 0.776142 0 0.5Z"
            fill="#1083D6"
        />
        <path
            d="M0 4C0 3.44772 0.447715 3 1 3H23C23.5523 3 24 3.44772 24 4C24 4.55228 23.5523 5 23 5H1C0.447716 5 0 4.55228 0 4Z"
            fill="#1083D6"
        />
        <path
            d="M0 8.5C0 7.67157 0.671573 7 1.5 7H22.5C23.3284 7 24 7.67157 24 8.5C24 9.32843 23.3284 10 22.5 10H1.5C0.671573 10 0 9.32843 0 8.5Z"
            fill="#1083D6"
        />
        <path
            d="M0 15C0 13.3431 1.34315 12 3 12H21C22.6569 12 24 13.3431 24 15C24 16.6569 22.6569 18 21 18H3C1.34315 18 0 16.6569 0 15Z"
            fill="#1083D6"
        />
    </svg>
);

export default StackBarSvg;
