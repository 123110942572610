import { useState } from 'react';
import classNames from 'classnames';
import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import { isDefined } from 'platform/common/common.types';
import ControlledButtonDropdown from 'platform/common/components/ButtonDropdown/ControlledButtonDropdown';
import { useModal } from 'platform/common/components/Modal/Modal';
import useToggle from 'platform/common/hooks/useToggle';
import CreativeMapper from 'platform/creatives/mappers/creative.mapper';
import { fetchConcepts } from 'platform/creatives/services/creative.service';
import { Concept, CreativeModel, GcmCreativeModel } from 'platform/creatives/types/creative.types';
import CreativeImportModal from '../CreativeImportExport/CreativeImportModal';
import creativeImportExport from '../CreativeImportExport/creativeImportExport';

const fetchCreatives = async (advertiserId: number, conceptIds: number[]): Promise<GcmCreativeModel[]> => {
    const [advertiser, concepts] = await Promise.all([
        fetchAdvertiser(advertiserId),
        fetchConcepts({ advertiserId, id: conceptIds, state: ['ACTIVE'] }),
    ]);
    const flatCreatives: CreativeModel[] = concepts
        .map((concept) =>
            CreativeMapper.toFormModel({ concept, timezone: advertiser.regionalSettings.timeZoneId })
        )
        .flatMap(({ name: conceptName, type: conceptType, creatives }) =>
            creatives.filter(isDefined).map((c) => ({ ...c, conceptName, conceptType }))
        );
    return flatCreatives.filter(creativeImportExport.isSupportedType);
};

const ImportButton = ({
    className,
    updateCreatives,
    advertiserId,
    disabled,
    conceptIds,
}: {
    updateCreatives: (creatives: GcmCreativeModel[]) => void;
    className?: string;
    advertiserId: number;
    disabled: boolean;
    conceptIds: number[];
}) => {
    const [isOpen, toggleDropdown] = useToggle(false);
    const [isLoading, setIsLoading] = useState(false);
    const { showModal } = useModal();
    const items = [
        {
            label: 'Landing pages',
            action: async () => {
                setIsLoading(true);
                toggleDropdown();
                const creatives = await fetchCreatives(advertiserId, conceptIds);
                setIsLoading(false);
                showModal((toggle) => (
                    <CreativeImportModal
                        toggle={toggle}
                        creatives={creatives}
                        updateCreatives={updateCreatives}
                    />
                ));
            },
        },
    ];
    return (
        <ControlledButtonDropdown
            color="secondary"
            className={classNames(className)}
            items={items}
            dropdownMenuRight
            isOpen={isOpen}
            toggle={toggleDropdown}
            disabled={disabled || isLoading}
        >
            <i className="fa fa-upload" /> {isLoading ? 'Loading...' : 'Import'}
        </ControlledButtonDropdown>
    );
};

const ExportButton = ({
    className,
    disabled,
    advertiserId,
    conceptIds,
}: {
    className?: string;
    disabled?: boolean;
    advertiserId: number;
    conceptIds: number[];
}) => {
    const [isOpen, toggle] = useToggle(false);
    const [isLoading, setIsLoading] = useState(false);

    const items = [
        {
            label: 'Landing pages',
            action: async () => {
                setIsLoading(true);
                toggle();
                const creatives = await fetchCreatives(advertiserId, conceptIds);
                creativeImportExport.toExcel(creatives);
                setIsLoading(false);
            },
        },
    ];

    return (
        <ControlledButtonDropdown
            color="secondary"
            className={classNames(className)}
            items={items}
            dropdownMenuRight
            disabled={disabled || isLoading}
            isOpen={isOpen}
            toggle={toggle}
        >
            <i className="fa fa-download" /> {isLoading ? 'Loading...' : 'Export'}
        </ControlledButtonDropdown>
    );
};

type Props = {
    advertiserId: number;
    concepts: Concept[];
    updateCreatives: (creatives: GcmCreativeModel[]) => void;
};

const CreativeListActions = ({ advertiserId, concepts, updateCreatives }: Props) => {
    const conceptIds = concepts.map((c) => c.id!);
    const enabled = concepts.some((c) => c.type === 'STANDARD' && c.adserver === 'GCM');
    return (
        <>
            <ExportButton
                className="ms-2"
                disabled={!enabled}
                advertiserId={advertiserId}
                conceptIds={conceptIds}
            />
            <ImportButton
                className="ms-2"
                advertiserId={advertiserId}
                disabled={!enabled}
                conceptIds={conceptIds}
                updateCreatives={updateCreatives}
            />
        </>
    );
};

export default CreativeListActions;
