import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import UserIcons from 'platform/common/components/UserSelect/UserIcons';
import { User } from 'platform/userManagement/types/user.type';
import { TASK_LANE_TYPE_OPTIONS, TaskLaneData, TaskLaneTimeout, TaskLaneType } from '../task.types';

interface Props {
    lane: TaskLaneData;
    userOptions: User[];
}

const laneTypeIcon = (type: TaskLaneType) => {
    const option = TASK_LANE_TYPE_OPTIONS.find((o) => o.value === type);
    return (
        <Tooltip
            className="d-flex align-items-center"
            renderTooltip={() => `Treat tasks as: ${option?.label}`}
        >
            <i className={`Kanban-green font-xs me-2 fa ${option?.icon}`} />
        </Tooltip>
    );
};

const describeTimeout = (timeout: TaskLaneTimeout) => (
    <div>
        Tasks should not stay in this column for longer than {timeout.days} days
        {(timeout.notifyLaneOwners || timeout.notifyTaskUsers) && (
            <ul className="m-0">
                {timeout.notifyLaneOwners && <li>Notify column owners</li>}
                {timeout.notifyTaskUsers && <li>Notify assignees & watchers</li>}
            </ul>
        )}
    </div>
);

const LaneTitle = ({ lane, userOptions }: Props) => (
    <div className="d-flex align-items-center">
        {lane.type && laneTypeIcon(lane.type)}

        {lane.timeout && (
            <Tooltip
                className="d-flex align-items-center"
                renderTooltip={() => describeTimeout(lane.timeout!)}
            >
                <i className="fa fa-alarm-clock Kanban-red font-xs me-2" />
            </Tooltip>
        )}

        {!!lane.ownerIds?.length && (
            <div className="me-1">
                <UserIcons
                    userIds={lane.ownerIds}
                    options={userOptions}
                    tooltip={(user) => `${user.name} is column owner`}
                />
            </div>
        )}

        {lane.name}
    </div>
);

export default LaneTitle;
