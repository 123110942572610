import { useState } from 'react';
import ButtonArray from 'platform/common/components/ButtonArray/ButtonArray';
import VisitFrequencyChart from 'platform/segments/components/SegmentCharts/VisitFrequency/VisitFrequencyChart';
import VisitFrequencyTable from 'platform/segments/components/SegmentCharts/VisitFrequency/VisitFrequencyTable';
import { VISIT_TIME_BUTTON_OPTIONS, VISIT_TIME_BUTTONS } from 'platform/segments/constants/chart.constants';
import { SegmentChartStats } from 'platform/segments/segments.types';

interface Props {
    data: SegmentChartStats[];
    loading: boolean;
}

const VisitFrequency = ({ data, loading }: Props) => {
    const [activeButton, setActiveButton] = useState(VISIT_TIME_BUTTONS.CHART);
    return (
        <>
            <div className="SegmentCharts-navigation mb-1 d-flex">
                <ButtonArray
                    className="SegmentCharts-buttons"
                    buttons={VISIT_TIME_BUTTON_OPTIONS}
                    selected={activeButton}
                    onClick={setActiveButton}
                />
            </div>
            <div className="SegmentCharts-charts">
                {activeButton === VISIT_TIME_BUTTONS.CHART && (
                    <VisitFrequencyChart data={data} loading={loading} />
                )}
                {activeButton === VISIT_TIME_BUTTONS.TABLE && (
                    <VisitFrequencyTable data={data} loading={loading} />
                )}
            </div>
        </>
    );
};

export default VisitFrequency;
