import MultiSwitch, { ButtonParams } from '../MultiSwitch/MultiSwitch';

export type Settings = 'edit' | 'view';

const SETTINGS_OPTIONS: ButtonParams<Settings>[] = [
    { text: 'View', iconClassName: 'far fa-eye', value: 'view' },
    { text: 'Edit', iconClassName: 'far fa-pen-to-square', value: 'edit' },
];

interface Props {
    className?: string;
    toggled: boolean;
    onClick: (val: Settings) => void;
}

const ShowSettingsButton = ({ className = '', toggled, onClick }: Props) => (
    <MultiSwitch
        options={SETTINGS_OPTIONS}
        value={toggled ? 'edit' : 'view'}
        className={className}
        onChange={onClick}
    />
);

export default ShowSettingsButton;
