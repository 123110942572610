import { useState } from 'react';
import { useSelector } from 'react-redux';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Separator from 'platform/common/components/Separator/Separator';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useLoading } from 'platform/common/hooks/useLoading';
import { FileInfo, formatFileSize } from 'platform/common/utils/file.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';
import { getSharedFile, updateSharedFileContent } from '../fileSharing.service';
import { SharedFile, MAX_SHARED_FILE_SIZE } from '../fileSharing.types';

const FileSharingForm = ({ formikProps: { values, submitForm }, onCancel }: FormProps<SharedFile>) => {
    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);
    const [size, setSize] = useState(values.size);
    const [uploading, withUploading] = useLoading();

    const handleUpload = (file: FileInfo) =>
        withUploading(async () => {
            await updateSharedFileContent(values.id, file.content as Blob, file.name);
            const metadata = await getSharedFile(values.id);
            setSize(metadata.size);
        });

    return (
        <CardForm title="Edit file" submitLabel="Save" onCancel={onCancel} onSubmit={submitForm}>
            <ControlledCard title="File info">
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Status">
                    <FormStateSelect name="status" options={ACTIVE_OR_ARCHIVED} validate={required} />
                </FormRow>
                <FormRow label="Content" childrenColClassName="position-relative">
                    {uploading && <OverlayLoader />}
                    <UploadInput
                        title="Update file"
                        subtitle={`Size: ${formatFileSize(size)}`}
                        readFileAs="blob"
                        maxSizeInMb={MAX_SHARED_FILE_SIZE}
                        loading={uploading}
                        onFileUpload={handleUpload}
                    />
                </FormRow>
                <Separator label="Optional" />
                <FormRow label="Advertiser">
                    <FormSelect
                        name="advertiserId"
                        options={advertiserOptions}
                        getOptionValue={(a) => a.id}
                        getOptionLabel={(a) => a.name}
                    />
                </FormRow>
                <FormRow label="Description">
                    <FormInput name="description" type="textarea" rows={5} />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default FileSharingForm;
