import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { fetchAnalyticsColumns } from 'platform/analytics/analytics.service';
import { ColumnDefinition } from 'platform/analytics/analytics.types';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import LabelOverridesCreate from './LabelOverridesCreate';
import LabelOverridesEdit from './LabelOverridesEdit';
import { deleteLabelOverride, fetchLabelOverrides } from './labelOverrides.service';
import { LabelOverride } from './labelOverrides.types';

export const PATH = '/advertiser-settings/analytics-naming';

const resolveName = (definition: ColumnDefinition): string => definition.altName || definition.name;

type QueryParams = {
    searchQuery: string;
};

const AnalyticsNaming = () => {
    const {
        queryParams: { searchQuery },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        searchQuery: '',
    });

    const dispatch = useDispatch();
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const [{ data: overrides, loading: loadingOverrides }, refetch] = usePromise(
        [],
        () => fetchLabelOverrides(advertiserId),
        [advertiserId]
    );

    const refetchAndClearSearch = () => {
        refetch();
        setQueryParams({ searchQuery: '' });
    };

    const showConfirmationModal = useConfirmationModal();

    const canEdit = useFeature('ADVERTISER_SETTINGS_EDIT');

    const [{ data: olap, loading: loadingOlap }] = usePromise(
        {
            metrics: [],
            dimensions: [],
            keyToMetricName: {},
            keyToDimensionName: {},
        },
        async () => {
            const { dimensions, metrics } = await fetchAnalyticsColumns();

            const keyToMetricName: { [key: string]: string } = {};
            metrics.forEach((m) => {
                keyToMetricName[m.key] = resolveName(m);
            });

            const keyToDimensionName: { [key: string]: string } = {};
            dimensions.forEach((d) => {
                keyToDimensionName[d.key] = resolveName(d);
            });
            return {
                metrics,
                dimensions,
                keyToMetricName,
                keyToDimensionName,
            };
        },
        []
    );

    const mappedOverrides = overrides.map((override) => {
        const { type, systemName } = override;
        const name =
            type === 'METRIC' ? olap.keyToMetricName[systemName] : olap.keyToDimensionName[systemName];
        return {
            ...override,
            name,
        };
    });

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            maxWidth: 80,
            type: DATA_TYPES.ID,
        },
        {
            Header: 'Type',
            accessor: 'type',
            maxWidth: 160,
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Default name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Key',
            accessor: 'systemName',
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Advertiser-specific name',
            accessor: 'alias',
            type: DATA_TYPES.TEXT,
        },
        {
            Header: '',
            className: 'pull-right cell-align-right',
            maxWidth: 80,
            sortable: false,
            Cell: ({ original }: TableCell<LabelOverride>) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => dispatch(push(`${PATH}/${original.id}`)),
                        },
                        {
                            label: 'Remove',
                            action: () =>
                                showConfirmationModal(
                                    () => deleteLabelOverride(original.id!!).then(refetchAndClearSearch),
                                    {
                                        title: 'Are you sure?',
                                        text: `"${original.alias}" will be reset to its original name "${original.systemName}"`,
                                    }
                                ),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="label_overrides_list">
                <FormattedTable
                    topToolbar={
                        <ListToolbar
                            value={searchQuery}
                            onCreate={() => dispatch(push(`${PATH}/new`))}
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    columns={columns}
                    data={filterBySearchQuery(
                        mappedOverrides,
                        columns.map((c) => c.accessor),
                        searchQuery
                    )}
                    loading={loadingOverrides || loadingOlap}
                />
            </BodyContainer>

            <Routes>
                <Route
                    path="new"
                    element={
                        <LabelOverridesCreate
                            redirectTo={PATH}
                            canEdit
                            afterSubmit={refetchAndClearSearch}
                            advertiserId={advertiserId}
                            metrics={olap.metrics}
                            dimensions={olap.dimensions}
                            keyTyMetricName={olap.keyToMetricName}
                            keyToDimensionName={olap.keyToDimensionName}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <LabelOverridesEdit
                            redirectTo={PATH}
                            canEdit={canEdit}
                            afterSubmit={refetchAndClearSearch}
                            metrics={olap.metrics}
                            dimensions={olap.dimensions}
                            keyTyMetricName={olap.keyToMetricName}
                            keyToDimensionName={olap.keyToDimensionName}
                            advertiserId={advertiserId}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default AnalyticsNaming;
