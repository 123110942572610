import { usePromise } from 'platform/common/hooks/usePromise';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import Switch from 'platform/formik/Switch/Switch';
import { TaskLaneTimeout } from 'platform/task/task.types';
import { fetchRoles } from 'platform/userManagement/services/userManagement.service';

interface Props {
    values: TaskLaneTimeout;
    setValues: (newValues: TaskLaneTimeout) => void;
}

const KanbanColumnTimeoutSettings = ({ values, setValues }: Props) => {
    const [{ data: roles }] = usePromise([], fetchRoles, []);

    return (
        <>
            <div className="d-flex align-items-center">
                <FormInput
                    name="timeout.days"
                    type="number"
                    className="me-2"
                    inputStyle={{ maxWidth: 40, height: 24 }}
                    autoFocus
                    validate={required}
                />
                or more days
            </div>
            <div className="d-flex align-items-center mt-2">
                <FormSwitch className="me-2" name="timeout.notifyLaneOwners" />
                Notify column owners
            </div>
            <div className="d-flex align-items-center mt-2">
                <FormSwitch className="me-2" name="timeout.notifyTaskUsers" />
                Notify assignees &amp; watchers
            </div>
            {values.notifyTaskUsers && (
                <div className="d-flex align-items-start mt-2 ms-5">
                    <div className="d-flex align-items-center text-nowrap mt-1">
                        <Switch
                            className="me-2"
                            value={!!values.notifyExcludeUserRoleIds}
                            onChange={({ target }) =>
                                setValues({
                                    ...values,
                                    notifyExcludeUserRoleIds: target.checked ? [] : undefined,
                                })
                            }
                        />
                        Exclude roles
                    </div>
                    {values.notifyExcludeUserRoleIds && (
                        <FormSelect
                            containerClassName="ms-2 flex-fill"
                            name="timeout.notifyExcludeUserRoleIds"
                            options={roles}
                            getOptionValue={(r) => r.id}
                            getOptionLabel={(r) => r.title}
                            isMulti
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default KanbanColumnTimeoutSettings;
