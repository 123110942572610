import IconButton from 'platform/common/components/IconButton/IconButton';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import { useModal } from 'platform/common/components/Modal/Modal';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import Spinner from 'platform/common/components/Spinner/Spinner';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import LaneChangesModal from 'platform/task/ChangeLog/LaneChangesModal';
import KanbanColumnModal from 'platform/task/Kanban/KanbanColumnModal';
import { User } from 'platform/userManagement/types/user.type';
import LaneTitle from '../Common/LaneTitle';
import { TaskLane, TaskLaneData } from '../task.types';

type Props = {
    boardId: number;
    lane: TaskLane;
    canEdit: boolean;
    userOptions: User[];
    onLaneUpdate: (laneId: number, data: TaskLaneData) => Promise<void>;
    onLaneDelete: (laneId: number) => Promise<void>;
    onTaskAdd: () => void;
};

const KanbanColumnHeader = ({
    boardId,
    lane,
    canEdit,
    userOptions,
    onLaneUpdate,
    onLaneDelete,
    onTaskAdd,
}: Props) => {
    const { showModal } = useModal();

    const getOptionItems = (): PopoverDropdownItem[] => [
        {
            icon: 'fa fa-edit',
            label: 'Edit',
            action: () =>
                showModal((toggle) => (
                    <KanbanColumnModal
                        lane={lane}
                        userOptions={userOptions}
                        onSubmit={(data: TaskLaneData) => onLaneUpdate(lane.id, data)}
                        onClose={toggle}
                    />
                )),
        },
        {
            icon: 'fa fa-history',
            label: 'Change history',
            action: () =>
                showModal((toggle) => (
                    <LaneChangesModal
                        boardId={boardId}
                        lane={lane}
                        userOptions={userOptions}
                        onClose={toggle}
                    />
                )),
        },
        ...(!lane.tasks.length
            ? [
                  {
                      icon: 'fa fa-trash',
                      label: 'Delete',
                      action: () => onLaneDelete(lane.id),
                      confirmation: 'Permanently delete column?',
                  },
              ]
            : []),
    ];

    return (
        <div className="Kanban-column-header">
            <div className="d-flex align-items-center">
                <span className="Kanban-column-name" style={{ cursor: canEdit ? 'grab' : 'default' }}>
                    <LaneTitle lane={lane} userOptions={userOptions} />
                </span>
                {canEdit && (
                    <>
                        {lane.syncing ? (
                            <Spinner size="sm" className="mt-1 me-2" />
                        ) : (
                            <InlineDropdown iconClassName="mt-2 fa fa-ellipsis-h" items={getOptionItems()} />
                        )}
                    </>
                )}
                <IconButton className="ms-auto" onClick={onTaskAdd}>
                    <Tooltip renderTooltip={() => 'New task'}>
                        <div className="d-flex text-muted">
                            <i className="Kanban-add-icon ms-2 fal fa-plus" />
                        </div>
                    </Tooltip>
                </IconButton>
            </div>
        </div>
    );
};

export default KanbanColumnHeader;
