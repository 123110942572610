import { Badge } from 'reactstrap';
import { MetricDefinition } from 'platform/analytics/analytics.types';
import { tokenizeFormula } from './customMetric.util';

type Props = {
    expression: string;
    metrics: MetricDefinition[];
    className?: string;
};

const Formula = ({ expression, metrics, className }: Props) => (
    <div className={`d-flex flex-wrap align-items-center gap-1 ${className}`}>
        {tokenizeFormula(expression, { metrics }).map(({ text, type, metric }, index) => (
            <span key={index}>
                {type === 'METRIC' && (
                    <Badge color="success" className="badge-outline font-xs">
                        {metric?.name ?? 'UNKNOWN METRIC'}
                    </Badge>
                )}
                {type === 'NUMBER' && <span className="text-dark font-lg">{text}</span>}
                {type === 'SYMBOL' && (
                    <span className="text-dark font-lg fw-bold">{text === '*' ? '×' : text}</span>
                )}
            </span>
        ))}
    </div>
);

export default Formula;
