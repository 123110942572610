import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { FileUploadProgressProps } from 'platform/common/hooks/useProgress';
import { FileInfo, ReadFileAs } from 'platform/common/utils/file.util';
import { validator, Validator } from 'platform/common/utils/validators.util';

type Props = {
    name: string;
    acceptableMimeTypes: string;
    subtitle?: string;
    readFileAs?: ReadFileAs;
    disabled?: boolean;
    validate?: Validator | Validator[];
    title?: string;
    onFileUpload?: (file: FileInfo | FileInfo[], uploadProgressProps?: FileUploadProgressProps) => void;
    loading?: boolean;
    uploadProgress?: number;
    onRemoveItem?: (index?: number) => void;
    multiple?: boolean;
};

const FormUploadInput = ({
    name,
    subtitle = '',
    disabled = false,
    acceptableMimeTypes,
    readFileAs,
    validate,
    title,
    onFileUpload,
    loading = false,
    uploadProgress,
    multiple = false,
    onRemoveItem,
}: Props) => {
    const [{ value }, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const invalid = Boolean(meta.touched && meta.error);

    return (
        <div>
            <UploadInput
                title={title}
                subtitle={subtitle}
                acceptableMimeTypes={acceptableMimeTypes}
                disabled={disabled}
                invalid={invalid}
                readFileAs={readFileAs}
                onFileUpload={async (
                    file: FileInfo | FileInfo[],
                    uploadProgressProps: FileUploadProgressProps | undefined
                ) => {
                    helpers.setValue(undefined);
                    if (onFileUpload) {
                        const response: any = await onFileUpload?.(file, uploadProgressProps);
                        if (response !== 'failed') {
                            helpers.setValue(file);
                        }
                    } else {
                        helpers.setValue(file);
                    }
                }}
                value={value}
                setValue={helpers.setValue}
                loading={loading}
                uploadProgress={uploadProgress}
                onRemoveItem={onRemoveItem}
                multiple={multiple}
            />
            {invalid && <FormFeedback className={'d-block'}>{meta.error} </FormFeedback>}
        </div>
    );
};

// eslint-disable-next-line import/no-unused-modules
export default FormUploadInput;
