import ReactSelect, { GroupBase, Props } from 'react-select';
// This import is necessary for module augmentation.
// It allows us to extend the 'Props' interface in the 'react-select/base' module
// and add our custom properties.
// eslint-disable-next-line prettier/prettier
import type {} from 'react-select/base';
import { SelectTreeInnerComponentProps } from '../SelectTree/SelectTree';

declare module 'react-select/base' {
    interface Props<
        Option,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        IsMulti extends boolean,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Group extends GroupBase<Option>
    >  extends Partial<SelectTreeInnerComponentProps<Option>>{
        selectAllEnabled?: boolean;
        clearValuesOnAddAllFound?: boolean;
        returnOnlyValues?: boolean; 
    }
}

const ReactSelectWrapper = <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: Props<Option, IsMulti, Group>
) => <ReactSelect {...props} />;

export default ReactSelectWrapper;

