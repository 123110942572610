import { useParams } from 'react-router-dom';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import CategoryForm from './CategoryForm';
import { fetchCategory, updateCategory } from './category.service';

const CategoryEdit = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const handleOpen = () => fetchCategory(Number(params.id));

    return (
        <FormContainer
            helpKey="category_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            sidePanel
            isEdit
            onOpen={handleOpen}
            onSubmit={updateCategory}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <CategoryForm {...props} />}
        </FormContainer>
    );
};

export default CategoryEdit;
