import { CSSProperties, ReactNode } from 'react';
import searchImage from 'platform/assets/images/search.png';
import './SearchNotification.scss';

interface Props {
    header: ReactNode;
    text: string;
    style?: CSSProperties;
    children?: ReactNode;
}

const SearchNotification = ({ text, header, style, children }: Props) => (
    <div className="SearchNotification" style={style}>
        <div className="d-flex align-items-center">
            {children || (
                <img
                    className="SearchNotification-image"
                    height={100}
                    src={searchImage}
                    alt="search notification"
                />
            )}
            <div className="SearchNotification-textContainer">
                <h3 className="mb-0 text-center">{header}</h3>
                {text && <span className="mt-2 text-muted text-center">{text}</span>}
            </div>
        </div>
    </div>
);

export default SearchNotification;
