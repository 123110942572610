import { createContext, ReactNode, useMemo } from 'react';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import { fetchTimeOffers } from 'platform/campaign/advertiserManagement/TimeOffers/timeOffer.service';
import { getCountryOptions } from 'platform/category/category.service';
import { SelectItem } from 'platform/common/common.types';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { entitiesToOptions, namesToOptions } from 'platform/common/utils/option.util';

type ClassificationOptionsState = [ClassificationOptions, boolean, () => Promise<void>];

interface ClassificationOptions {
    countryLabels: SelectItem<string>[];
    flightLabels: SelectItem<string>[];
    productLabels: SelectItem<string>[];
    timeOffers: SelectItem<number>[];
}

const defaultOptions: ClassificationOptions = {
    countryLabels: [],
    flightLabels: [],
    productLabels: [],
    timeOffers: [],
};

export const ClassificationOptionsContext = createContext<ClassificationOptionsState>([
    defaultOptions,
    true,
    async () => {},
]);

interface Props {
    children: ReactNode;
    selectedProductLabel?: string;
}

export const ClassificationOptionsProvider = ({ children }: Props) => {
    const advertiserId = useTypedSelector(activeAdvertiserSelectors.id);

    const [{ data, loading }, refetch] = usePromise<ClassificationOptions>(defaultOptions, async () => {
        const [countryLabels, flightLabels, productLabels, timeOffers] = await Promise.all([
            getCountryOptions(),
            fetchFlightLabels(advertiserId).then(namesToOptions),
            fetchProducts(advertiserId).then((products) =>
                products.map((p) => ({ value: p.name, label: p.name, nodes: namesToOptions(p.subLabels) }))
            ),
            fetchTimeOffers({ advertiserId }).then(entitiesToOptions),
        ]);
        return { countryLabels, flightLabels, productLabels, timeOffers };
    });

    const state = useMemo<ClassificationOptionsState>(
        () => [data, loading, refetch],
        [data, loading, refetch]
    );

    return (
        <ClassificationOptionsContext.Provider value={state}>
            {children}
        </ClassificationOptionsContext.Provider>
    );
};
