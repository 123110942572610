import { useParams } from 'react-router-dom';
import { toAlgorithmApi, toAlgorithmForm } from 'platform/algorithms/algorithm.mapper';
import { createAlgorithm, fetchAlgorithmTemplate } from 'platform/algorithms/algorithms.service';
import { AlgorithmFormModel, Algorithm } from 'platform/algorithms/algorithms.types';
import { Optional } from 'platform/common/common.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import AlgorithmForm from './AlgorithmForm';

const AlgorithmCreate = ({
    canEdit,
    redirectTo,
    advertiserId,
    afterSubmit,
}: FormContainerProps & { advertiserId: number }) => {
    const params = useParams<{ templateId: string }>();
    const templateId = Number(params.templateId);

    const handleOpen = async (): Promise<AlgorithmFormModel> => {
        const template = await fetchAlgorithmTemplate(templateId);

        const algorithm: Optional<Algorithm, 'id'> = {
            advertiserId,
            algorithmTemplateId: templateId,
            status: 'INACTIVE',
            settings: template.settings ?? '',
            code: template.code ?? '',
            format: template.format,
            version: template.version,
            adminNotes: template.adminNotes,
            display: {
                codeLanguage: template.display.codeLanguage,
                settingsLanguage: template.display.settingsLanguage,
            },
        };

        return toAlgorithmForm(algorithm, template);
    };

    const handleSubmit = (data: AlgorithmFormModel) =>
        createAlgorithm(toAlgorithmApi({ ...data, advertiserId }));

    return (
        <FormContainer
            helpKey="algorithm_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={false}
            sidePanel
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <AlgorithmForm {...props} />}
        </FormContainer>
    );
};

export default AlgorithmCreate;
