import classNames from 'classnames';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { getFileExtension } from 'platform/common/utils/file.util';

interface Props {
    filename: string;
    className?: string;
}

const FileLabel = ({ filename, className }: Props) => {
    const extension = getFileExtension(filename) ?? '';
    const namePart = extension ? filename.slice(0, -extension.length - 1) : filename;

    return (
        <Tooltip renderTooltip={() => filename}>
            <span className={classNames('d-flex', className)}>
                <span className="text-truncate">{namePart}</span>
                <span>{extension ? `.${extension}` : ''}</span>
            </span>
        </Tooltip>
    );
};

export default FileLabel;
