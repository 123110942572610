import { sortByName } from 'platform/common/utils/array.util';
import {
    toApiGcmHostedCreative,
    toFormModelGcmHostedCreative,
} from 'platform/creatives/mappers/creative/gcmHostedCreative.mapper';
import {
    toApiGcmTrackerCreative,
    toFormModelGcmTrackerCreative,
} from 'platform/creatives/mappers/creative/gcmTrackerCreative.mapper';
import {
    toApiGcmVideoCreative,
    toFormModelGcmVideoCreative,
} from 'platform/creatives/mappers/creative/gcmVideoCreative.mapper';
import { Concept, ConceptModel, Creative, CreativeModel as CreativeModelType } from '../types/creative.types';
import { toApiImportedCreative, toFormModelImportedCreative } from './creative/commonCreative.mapper';

export const base64ToDataUrl = (base64: string, mimeType?: string) => {
    const safeMimeType = mimeType && mimeType !== 'UNKNOWN' ? mimeType : 'image/jpeg';

    return `data:${safeMimeType};base64,${base64}`;
};

const toFormModelCreative = (creative: Creative, timezone: string): CreativeModelType => {
    switch (creative.type) {
        case 'GCM_HOSTED':
            return toFormModelGcmHostedCreative(creative, timezone);
        case 'GCM_VIDEO':
            return toFormModelGcmVideoCreative(creative, timezone);
        case 'GCM_TRACKER':
            return toFormModelGcmTrackerCreative(creative);
        default:
            return toFormModelImportedCreative(creative);
    }
};

const toFormModel = ({
    concept,
    isDemoMode,
    seatName,
    timezone,
}: {
    concept: Concept;
    isDemoMode?: boolean;
    seatName?: string;
    timezone: string;
}): ConceptModel => {
    const {
        id,
        name,
        type,
        clickThroughUrl,
        advertiserId,
        campaignId,
        creatives,
        state,
        createdOn,
        updatedOn,
        updatedBy,
        productLabel,
        flightLabel,
        externalAdvertiserId,
        adserver,
    } = concept;

    return {
        id: id!,
        type: type!,
        clickThroughUrl: isDemoMode ? 'https://www.example-landing-page.com' : clickThroughUrl,
        campaignId,
        name: isDemoMode ? `Creative_concept_${id}` : name || '',
        advertiserId,
        adserver,
        externalAdvertiserId,
        creatives: sortByName(
            (creatives || [])
                .map((c) => toFormModelCreative(c, timezone))
                .map((creative) => ({
                    ...creative,
                    name: isDemoMode ? `Creative_${creative.id}` : creative.name,
                }))
        ),
        state: state!,
        createdOn,
        updatedOn,
        updatedBy,
        productLabel: productLabel ? { label: productLabel, value: productLabel } : undefined,
        flightLabel: flightLabel ? { label: flightLabel, value: flightLabel } : undefined,
        seatName,
        isDemoMode,
    };
};

const toApiCreative = (creative: CreativeModelType, timezone: string) => {
    switch (creative.type) {
        case 'GCM_HOSTED':
            return toApiGcmHostedCreative(creative, timezone);
        case 'GCM_VIDEO':
            return toApiGcmVideoCreative(creative, timezone);
        case 'GCM_TRACKER':
            return toApiGcmTrackerCreative(creative);
        default:
            return toApiImportedCreative(creative);
    }
};

const toApi = (
    {
        id,
        name,
        type,
        state,
        clickThroughUrl,
        advertiserId,
        externalAdvertiserId,
        campaignId,
        creatives,
        flightLabel,
        productLabel,
        adserver,
    }: ConceptModel,
    timezone: string
): Concept => ({
    id,
    name,
    type,
    state,
    clickThroughUrl,
    advertiserId,
    externalAdvertiserId,
    campaignId,
    productLabel: productLabel?.value,
    flightLabel: flightLabel?.value,
    adserver,
    creatives: creatives.map((c) => toApiCreative(c, timezone)),
});

const CreativeMapper = {
    toFormModel,
    toApi,
    toApiCreative,
    toFormModelCreative,
};

export default CreativeMapper;
