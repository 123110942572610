import { useParams } from 'react-router-dom';
import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import campaignService from 'platform/campaign/campaign/services/campaign.service';
import { getStrategy } from 'platform/campaign/strategy/services/strategy.service';
import unitService from 'platform/campaign/unit/services/units.service';
import { assertIsDefined } from 'platform/common/common.assert';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import UnitForm, { UnitFormType } from 'platform/units/components/UnitForm/UnitForm';
import { fetchVendor } from 'platform/vendors/vendors.service';

const UnitFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const handleOpen = async (): Promise<UnitFormType> => {
        const [unit, ioSpends] = await Promise.all([
            unitService.getUnit(id),
            unitService.getUnitIOSpends(id),
        ]);

        assertIsDefined(unit.advertiserId, 'unit.advertiserId');
        assertIsDefined(unit.campaignId, 'unit.campaignId');
        assertIsDefined(unit.strategyId, 'unit.strategyId');
        const [advertiser, campaign, strategy] = await Promise.all([
            fetchAdvertiser(unit.advertiserId),
            campaignService.getCampaign(unit.campaignId),
            getStrategy(unit.strategyId),
        ]);

        assertIsDefined(campaign.redirectVendorId, 'campaign.redirectVendorId');
        const vendor = await fetchVendor(campaign.redirectVendorId);

        return {
            ioSpends,
            advertiser,
            campaign,
            strategy,
            unit,
            vendor,
            dates: {
                from: campaign.dateFrom,
                to: campaign.dateTo,
            },
            bookedPricing: !!ioSpends.bookedPricing?.multiplier,
        };
    };

    const handleSubmit = ({ ioSpends, bookedPricing }: UnitFormType) =>
        unitService.updateUnitIOSpends(Number(id), {
            ...ioSpends,
            bookedPricing:
                bookedPricing && ioSpends.bookedPricing
                    ? {
                          ...ioSpends.bookedPricing,
                          multiplier: Number(ioSpends.bookedPricing.multiplier),
                      }
                    : undefined,
            vendorExternalId: ioSpends.vendorExternalId?.trim(),
            defaultPricing: ioSpends.defaultPricing?.type
                ? {
                      ...ioSpends.defaultPricing,
                      multiplier: Number(ioSpends.defaultPricing.multiplier),
                  }
                : undefined,
        });

    return (
        <FormContainer
            helpKey="unit_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            isEdit
            sidePanel
        >
            {(props) => <UnitForm {...props} />}
        </FormContainer>
    );
};

export default UnitFormContainer;
