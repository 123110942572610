import { ReactNode } from 'react';
import classNames from 'classnames';
import PageDocumentation from '../PageHelp/PageHelp';

interface Props {
    children: ReactNode;
    helpKey?: string;
    className?: string;
    childClassName?: string;
}

const BodyContainer = ({ children, className, helpKey, childClassName }: Props) => (
    <div className={classNames('mt-0 pb-3 mx-3', className)}>
        {!!helpKey && <PageDocumentation helpKey={helpKey} />}
        <div className={childClassName}>{children}</div>
    </div>
);

export default BodyContainer;
