import moment from 'moment-timezone';
import { formatDate, formatHours } from 'platform/common/utils/date.util';

export type RuntimeFormModel = {
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
};

export type RuntimeFields = {
    runtimeFrom?: string;
    runtimeTo?: string;
};

export type RuntimeFormFields = {
    scheduling?: boolean;
    runtime: RuntimeFormModel;
};

const toRuntimeApiModel = (r: RuntimeFormModel, timezone: string): [string, string?] => {
    const [startHours, startMinutes] = r.startTime ? r.startTime.split(':') : [];
    const [endHours, endMinutes] = r.endTime ? r.endTime.split(':') : [];

    const start = moment
        .tz(r.startDate, timezone)
        .hours(Number(startHours))
        .minutes(Number(startMinutes))
        .utc()
        .format();

    const end =
        r.endDate &&
        moment.tz(r.endDate, timezone).hours(Number(endHours)).minutes(Number(endMinutes)).utc().format();

    return [start, end];
};

export const toRuntimeApiFields = (
    { scheduling, runtime }: RuntimeFormFields,
    timezone: string
): RuntimeFields => {
    if (scheduling && runtime.startDate && runtime.startTime) {
        const [runtimeFrom, runtimeTo] = toRuntimeApiModel(runtime, timezone);
        return { runtimeFrom, runtimeTo };
    }
    return {};
};

const toRuntimeFormModel = (timezone: string, start: string, end?: string): RuntimeFormModel => ({
    startDate: formatDate(start, timezone),
    startTime: formatHours(start, timezone),
    endDate: formatDate(end, timezone),
    endTime: formatHours(end, timezone),
});

export const toRuntimeFormFields = (
    { runtimeFrom, runtimeTo }: RuntimeFields,
    timezone: string
): RuntimeFormFields => ({
    scheduling: !!runtimeFrom,
    runtime: runtimeFrom
        ? toRuntimeFormModel(timezone, runtimeFrom, runtimeTo)
        : { startDate: '', startTime: '' },
});
