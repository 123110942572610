import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import InfoIconTooltip from 'platform/common/components/InfoIconTooltip/InfoIconTooltip';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import TaskBoardViewList from './TaskBoardViewList';
import { TaskBoardFormModel } from './task.types';

const TaskBoardForm = ({
    formikProps: { values, setFieldValue, submitForm },
    isEdit,
    onCancel,
}: FormProps<TaskBoardFormModel>) => (
    <CardForm
        title={isEdit ? 'Update task board' : 'New task board'}
        submitLabel="Save"
        onSubmit={submitForm}
        onCancel={onCancel}
    >
        <ControlledCard title="General info">
            <FormRow label="Name">
                <FormInput name="name" validate={required} />
            </FormRow>
            <FormRow label="Status">
                <FormStateSelect name="status" options={ACTIVE_OR_ARCHIVED} validate={required} />
            </FormRow>
            <FormRow label="Internal only">
                <FormSwitch name="internal" />
            </FormRow>
        </ControlledCard>

        <ControlledCard
            title={
                <>
                    Filtered views
                    <InfoIconTooltip className="ms-2">
                        These are displayed as additional navigation links under Collaboration section.
                    </InfoIconTooltip>
                </>
            }
        >
            <TaskBoardViewList
                views={values.views ?? []}
                laneOptions={values.laneOptions}
                labelNames={values.labelNames}
                onChange={(views) => setFieldValue('views', views)}
            />
        </ControlledCard>
    </CardForm>
);

export default TaskBoardForm;
