import { FormEvent, useState } from 'react';
import { Button, Form, Input } from 'reactstrap';

interface Props {
    labelKey: string;
    labelName: string;
    onSubmit: (key: string, newName: string) => void;
    onClose: () => void;
}

const KanbanLabelRenameForm = ({ labelKey, labelName, onSubmit, onClose }: Props) => {
    const [newName, setNewName] = useState(labelName);
    const nameChanged = newName.trim() && newName.trim() !== labelName;

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (nameChanged) {
            onSubmit(labelKey, newName.trim());
            onClose();
        }
    };

    return (
        <Form className="Kanban-label-rename-form" onSubmit={handleSubmit}>
            <Input
                value={newName}
                autoFocus
                className={`Task-label-color-${labelKey}`}
                onChange={({ target }) => setNewName(target.value)}
            />
            <div className="Kanban-label-rename-form-buttons">
                <Button type="submit" disabled={!nameChanged}>
                    Rename label
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </div>
        </Form>
    );
};

export default KanbanLabelRenameForm;
