import { Template } from 'platform/analytics/analytics.types';
import { isOlapReport } from 'platform/analytics/analytics.util';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { isDefined } from 'platform/common/common.types';

interface Props {
    compatibleColumns: string[];
    template: Template;
}

export const useAvailableFilters = ({ compatibleColumns, template }: Props) => {
    const { definitions } = useAnalyticsMetadata();
    return definitions.filters
        .filter((filter) =>
            isOlapReport(template)
                ? compatibleColumns.includes(filter.target)
                : template.filters.find((f) => f.target === filter.target)
        )
        .filter(isDefined);
};
