import LabelIcon from '../LabelIcon/LabelIcon';
import './IconAction.scss';

interface Props {
    icon: string;
    label: string;
    iconClassName?: string;
    onClick: () => void;
}

const IconAction = ({ icon, label, iconClassName = '', onClick }: Props) => (
    <div role="menuitem" tabIndex={0} className="IconAction px-1" onClick={onClick}>
        <LabelIcon iconClassName={iconClassName} icon={icon} label={label} labelPosition="right" />
    </div>
);

export default IconAction;
