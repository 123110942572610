import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import qs from 'qs';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterTreeByQuery } from 'platform/common/utils/search.util';
import CreativeLabelCreate from 'platform/creatives/components/CreativeLabelForm/CreativeLabelCreate';
import CreativeLabelEdit from 'platform/creatives/components/CreativeLabelForm/CreativeLabelEdit';
import { CREATIVE_LABELS } from 'platform/creatives/creatives.navigation';
import { fetchCreativeLabels } from 'platform/creatives/services/creativeLabel.service';
import { CreativeLabelGroup } from 'platform/creatives/types/creativeLabel.types';

type QueryParams = {
    searchQuery: string;
};

const CreativeLabelList = () => {
    const {
        queryParams: { searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>(
        {
            searchQuery: '',
        },
        CREATIVE_LABELS.path
    );

    const dispatch = useDispatch();
    const canEdit = useFeature('CREATIVES_EDIT');
    const { id: advertiserId } = useActiveAdvertiserComponent();

    const [{ data, loading }, refetch] = usePromise([], () => fetchCreativeLabels({ advertiserId }), [
        advertiserId,
    ]);

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            width: 90,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 200,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            maxWidth: 160,
            Cell: (props: TableCell<CreativeLabelGroup>) =>
                props.original.labels ? (
                    <UpdatedOn date={props.original.updatedOn} updatedBy={props.original.updatedBy} />
                ) : null,
        },
        {
            className: 'pull-right cell-align-right',
            maxWidth: 50,
            sortable: false,
            Cell: (props: TableCell<CreativeLabelGroup>) =>
                props.original.labels ? (
                    <InlineDropdown
                        items={[
                            {
                                label: canEdit ? 'Edit' : 'View',
                                action: () => dispatch(push(`${CREATIVE_LABELS.path}/${props.original.id}`)),
                            },
                        ]}
                    />
                ) : null,
        },
    ];

    const filteredData = filterTreeByQuery(data, searchQuery, 'labels', [
        ...columns.map((column) => column.accessor),
        'updatedBy',
    ]);

    return (
        <div>
            <HeaderContainer>
                <PageHeader title="Creative Settings" />
            </HeaderContainer>
            <BodyContainer helpKey="creative_label_list">
                {!canEdit && <CanNotEditWarning />}
                <FormattedTable
                    isTree
                    columns={columns}
                    topToolbar={
                        <ListToolbar
                            value={searchQuery}
                            onCreate={
                                canEdit
                                    ? () => {
                                          dispatch(
                                              push({
                                                  pathname: `${CREATIVE_LABELS.path}/new`,
                                                  search: qs.stringify({ advertiserId }),
                                              })
                                          );
                                      }
                                    : undefined
                            }
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    loading={loading}
                    data={filteredData}
                    childrenLoaders={[{ field: 'labels' }]}
                    childrenOffset={0}
                    nodesExpanded={!!searchQuery}
                    defaultSorted={[
                        {
                            orderBy: 'updatedOn',
                            direction: 'DESC',
                        },
                    ]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <CreativeLabelCreate canEdit={canEdit} redirectTo={returnUrl} afterSubmit={refetch} />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <CreativeLabelEdit canEdit={canEdit} redirectTo={returnUrl} afterSubmit={refetch} />
                    }
                />
            </Routes>
        </div>
    );
};

export default CreativeLabelList;
