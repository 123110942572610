import { ActiveOrArchived } from 'platform/common/constants/status.constant';

export type SharedFile = {
    id: number;
    name: string;
    size: number;
    status: ActiveOrArchived;
    stableName: string;
    advertiserId?: number;
    description?: string;
    updatedOn?: string;
    updatedBy?: string;
};

export type SharedFileUpdate = {
    name: string;
    status: ActiveOrArchived;
    advertiserId?: number;
    description?: string;
};

export const MAX_SHARED_FILE_SIZE = 50; // MB
