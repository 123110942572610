import { render } from 'react-dom';
import Root from './app/components/Root/Root';
import { setAxiosAccessToken } from './axios/axiosBff';
import { setStoreToApolloClient } from './common/utils/createApolloClient.util';
import './globalSettings.ts';
import { handleStateToStorageSync } from './storageAndStoreSync';
import globalStore, { history } from './store';
import './scss/style.scss';

handleStateToStorageSync(globalStore);
setAxiosAccessToken(globalStore);
setStoreToApolloClient(globalStore);

const appElement = document.getElementById('app');
if (!appElement) {
    throw new Error('Element with ID "app" not found on page');
}

render(<Root store={globalStore} history={history} />, appElement);
