import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Col, ListGroup, Row } from 'reactstrap';
import { push } from 'redux-first-history';
import SidePanelExpandButton from 'platform/campaign/common/SidePanelExpandButton';
import ListGroupItem from 'platform/common/components/ListGroupItem/ListGroupItem';
import SidePanel from 'platform/common/components/SidePanel/SidePanel';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import useToggle from 'platform/common/hooks/useToggle';

export interface FormStep {
    id: string;
    label: string;
    getUrl?: (id: number | undefined) => string;
    getDisableStepIds?: (id: number | undefined) => string[];
}

interface Props {
    entryId?: number;
    children: ({ navigateToNextStep }: { navigateToNextStep: (id: number | undefined) => void }) => ReactNode;
    steps: FormStep[];
    isExpandable: boolean;
    onStepClick?: (callback: () => void) => void;
}

const FormNavigation = ({ children, steps, isExpandable, onStepClick, entryId }: Props) => {
    const [isPanelExpanded, togglePanelExpand] = useToggle(!isExpandable);
    const [activeStep, setActiveStep] = useState<FormStep>(steps[0]);
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        const newStep = steps.find((step) => step.getUrl && step.getUrl(entryId) === location.pathname);
        if (newStep) {
            setActiveStep(newStep);
        }
    }, [location]);

    const navigateToNextStep = (newId: number | undefined) => {
        const currentStepIndex = steps.indexOf(activeStep);
        if (currentStepIndex >= 0 && currentStepIndex < steps.length - 1) {
            const nextStep = steps[currentStepIndex + 1];
            setActiveStep(nextStep);
            if (nextStep.getUrl) {
                dispatch(push(nextStep.getUrl(newId ?? entryId)));
            }
        }
    };

    const isStepDisabled = (step: FormStep) => !!activeStep.getDisableStepIds?.(entryId).includes(step.id);

    const handleStepClick = (step: FormStep) => {
        setActiveStep(step);
        if (step.getUrl) {
            dispatch(push(step.getUrl(entryId)));
        }
    };

    return (
        <SidePanel size={isPanelExpanded ? SIDE_PANEL_SIZES.FULL : SIDE_PANEL_SIZES.WIDE}>
            <Row className="h-100 g-0">
                <Col md={2} className="bg-white border-right">
                    <ListGroup className="mx-3">
                        {steps.map((step, index) => (
                            <ListGroupItem
                                id={step.id}
                                label={step.label}
                                key={step.id}
                                index={index}
                                active={step.id === activeStep.id}
                                disabled={isStepDisabled(step)}
                                onClick={() => {
                                    if (onStepClick) {
                                        onStepClick(() => handleStepClick(step));
                                    } else {
                                        handleStepClick(step);
                                    }
                                }}
                            />
                        ))}
                    </ListGroup>
                    {isExpandable && (
                        <SidePanelExpandButton isExpanded={isPanelExpanded} onClick={togglePanelExpand} />
                    )}
                </Col>
                <Col className="StrategyWizardWrapper-content h-100" md={10}>
                    {children({ navigateToNextStep })}
                </Col>
            </Row>
        </SidePanel>
    );
};

export default FormNavigation;
