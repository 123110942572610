import axios from 'platform/axios/axiosBff';
import { AuditedChange, AuditObjectType } from './audit.types';

interface AuditedChangesFilter {
    objectTypes: AuditObjectType[];
    advertiserId: number;
    campaignId?: number;
    unitId?: number;
    author?: string;
    dateFrom?: string;
    dateTo?: string;
}

export const getAuditedChanges = (params: AuditedChangesFilter) =>
    axios.get<AuditedChange[]>('/campaign/api/auditing', { params }).then((r) => r.data);
