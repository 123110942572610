import { usePromise } from 'platform/common/hooks/usePromise';
import { User } from 'platform/userManagement/types/user.type';
import { getTaskChanges } from '../task.service';
import { TaskBoardChange, TaskBoardState } from '../task.types';
import BoardChangesTable from './BoardChangesTable';

interface Props {
    className?: string;
    board: TaskBoardState;
    userOptions: User[];
    taskId: number;
}

const TaskChangesContainer = ({ className, board, userOptions, taskId }: Props) => {
    const [{ data: changes, loading }] = usePromise<TaskBoardChange[]>(
        [],
        () => getTaskChanges(board.id, taskId),
        [board.id, taskId]
    );

    return (
        <BoardChangesTable
            className={className}
            board={board}
            userOptions={userOptions}
            changes={changes}
            loading={loading}
            topToolbar={<div className="fw-bold text-muted">Change history</div>}
        />
    );
};

export default TaskChangesContainer;
