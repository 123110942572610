import { CSSProperties, FC, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { Popover as ReactstrapPopover, PopoverProps } from 'reactstrap';
import { Modifier, flip, hide, preventOverflow as preventOverflowModifier } from '@popperjs/core';
import { FlipModifier } from '@popperjs/core/lib/modifiers/flip';
import { HideModifier } from '@popperjs/core/lib/modifiers/hide';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import classNames from 'classnames';
import useToggle from '../../hooks/useToggle';
import './Popover.scss';

export type PopoverContainerType = string | HTMLElement | React.RefObject<HTMLElement>;

interface Props {
    className?: string;
    popoverClassName?: string;
    placement?: PopoverPlacement;
    boundariesElement?: PopoverBoundaries;
    trigger?: 'legacy' | 'hover' | 'click';
    style?: CSSProperties;
    preventOverflow?: boolean;
    preventToggle?: boolean;
    hideArrow?: boolean;
    isOpen?: boolean;
    modifiers?: Modifier<string, any>[];
    onToggle?: (open: boolean) => void;
    renderPopover: ({ toggle }: { toggle: () => void }) => ReactElement;
    container?: PopoverContainerType;
}

export type PopoverPlacement = PopoverProps['placement'];
export type PopoverBoundaries = PopoverProps['boundariesElement'];

const Popover: FC<Props> = ({
    children,
    className,
    popoverClassName,
    placement = 'top',
    boundariesElement = 'viewport',
    trigger = 'legacy',
    style,
    preventOverflow = false,
    preventToggle = false,
    hideArrow = true,
    isOpen = false,
    modifiers,
    renderPopover,
    onToggle,
    container,
}) => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [open, toggleOpen] = useToggle(isOpen);

    useEffect(() => {
        toggleOpen(isOpen);
    }, [isOpen]);

    const handleToggle = () => {
        if (open && preventToggle) {
            return;
        }
        if (onToggle) {
            onToggle(!open);
            return;
        }
        toggleOpen();
    };

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };

    return (
        <div
            ref={setRef}
            style={style}
            className={classNames('cursor-pointer', className)}
            role="button"
            tabIndex={0}
            onClick={handleClick}
        >
            {children}
            {ref && (
                <ReactstrapPopover
                    className={classNames('Popover', popoverClassName)}
                    trigger={trigger}
                    placement={placement}
                    target={ref}
                    container={container}
                    delay={{ show: 0, hide: 200 }}
                    modifiers={
                        modifiers ?? [
                            {
                                ...preventOverflowModifier,
                                enabled: preventOverflow,
                                options: { rootBoundary: boundariesElement, altAxis: true },
                            } as PreventOverflowModifier,
                            {
                                ...flip,
                                options: {
                                    rootBoundary: boundariesElement,
                                    flipVariations: true,
                                    behavior: boundariesElement ? undefined : ['top', 'bottom'],
                                },
                            } as FlipModifier,
                            { ...hide, enabled: preventOverflow } as HideModifier,
                        ]
                    }
                    isOpen={open}
                    hideArrow={hideArrow}
                    flip
                    fade={false}
                    toggle={handleToggle}
                >
                    {renderPopover({ toggle: handleToggle })}
                </ReactstrapPopover>
            )}
        </div>
    );
};

export default Popover;
