import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { isEqual, uniq } from 'lodash-es';
import { CHART_COLORS } from 'platform/common/utils/color.util';

interface ColorAssignment {
    dimensions: string[];
    valueColorMap: { [key: string]: string };
}

interface ColorMap {
    [key: number]: ColorAssignment[];
}

interface ColorContextType {
    colorAssignments: ColorMap;
    registerChartLabels: (key: number, values: string[], dimensions: string[]) => void;
    getChartColors: (key: number, values: string[], dimensions: string[]) => string[];
}

const ColorContext = createContext<ColorContextType | undefined>(undefined);

interface Props {
    children: ReactNode;
}

// Makes sure that colors are consistent between charts (with same dimensions) that use same labels
export const ChartColorProvider = ({ children }: Props) => {
    const [colorAssignments, setColorAssignments] = useState<ColorMap>([]);

    const registerChartLabels = (key: number, values: string[], dimensions: string[]): void => {
        if (!values.length) {
            return;
        }

        const uniqueValues = uniq(values);
        const existingColorAssignment = colorAssignments[key]?.find((a) => isEqual(a.dimensions, dimensions));
        const valuesWithoutColors = uniqueValues.filter(
            (value) => !existingColorAssignment?.valueColorMap[value]
        );

        if (!existingColorAssignment) {
            const newAssignment: ColorAssignment = {
                dimensions,
                valueColorMap: uniqueValues.reduce(
                    (acc, value, index) => ({ ...acc, [value]: CHART_COLORS[index % CHART_COLORS.length] }),
                    {}
                ),
            };
            setColorAssignments((prev) => ({ ...prev, [key]: [...(prev[key] || []), newAssignment] }));
        } else if (valuesWithoutColors.length) {
            const newAssignment = {
                ...existingColorAssignment,
                valueColorMap: valuesWithoutColors.reduce((acc, value, i) => {
                    const color =
                        CHART_COLORS[
                            (Object.keys(existingColorAssignment.valueColorMap).length + (i + 1)) %
                                CHART_COLORS.length
                        ];
                    return { ...acc, [value]: color };
                }, existingColorAssignment.valueColorMap),
            };
            setColorAssignments((prev) => ({
                ...prev,
                [key]: prev[key].map((a) => {
                    if (isEqual(a.dimensions, dimensions)) {
                        return newAssignment;
                    }
                    return a;
                }),
            }));
        }
    };

    const getChartColors = (key: number, values: string[], dimensions: string[]): string[] => {
        const assignment = colorAssignments[key]?.find((a) => isEqual(a.dimensions, dimensions));

        if (!assignment) {
            return values.map((_, index) => CHART_COLORS[index % CHART_COLORS.length]);
        }

        return values.map((value) => assignment.valueColorMap[value]);
    };

    const value = useMemo(
        () => ({ colorAssignments, registerChartLabels, getChartColors }),
        [colorAssignments, registerChartLabels, getChartColors]
    );

    return <ColorContext.Provider value={value}>{children}</ColorContext.Provider>;
};

export const useChartColor = (key: number, labels: string[], dimensions: string[]) => {
    const context = useContext(ColorContext);

    if (!context) {
        throw new Error('useChartColor must be used within a ChartColorProvider');
    }

    useEffect(() => {
        context.registerChartLabels(key, labels, dimensions);
    }, [key, labels, dimensions]);

    return {
        ...context,
        getChartColors: (values: string[], dims: string[]) => context.getChartColors(key, values, dims),
    };
};
