import { useCallback, useState } from 'react';
import { isBoolean } from 'lodash-es';

const useToggle = (state: boolean): [boolean, (newState?: boolean) => void] => {
    const [value, setValue] = useState<boolean>(state);
    const toggle = useCallback(
        (newState?: any) => {
            setValue((v) => (isBoolean(newState) ? newState : !v));
        },
        [setValue]
    );
    return [value, toggle];
};

export default useToggle;
