import { ReactNode } from 'react';
import { Button } from 'reactstrap';
import SortableItem from 'platform/common/components/SortableItem/SortableItem';

type Props<T> = {
    items: T[];
    getKey?: (value: T) => string | number;
    dragKey: string;
    disabled?: boolean;
    className?: string;
    itemClassName?: string;
    children: (value: T, index: number) => ReactNode;
    onDrop: (moveFrom: T, moveTo: T) => void;
    onRemove: (item: T) => void;
};

const SortableList = <T,>({
    items,
    getKey,
    dragKey,
    disabled,
    className,
    itemClassName,
    children,
    onRemove,
    onDrop,
}: Props<T>) => (
    <div className={className}>
        {items.map((item, index) => (
            <SortableItem
                key={getKey?.(item) ?? index}
                sortId={dragKey}
                item={item}
                className={itemClassName}
                canDrop={!disabled}
                canDrag={!disabled}
                useHandle
                onDrop={onDrop}
            >
                {children(item, index)}
                <Button onClick={() => onRemove(item)} color="link" className="ms-auto">
                    <i className="fal fa-trash text-muted" />
                </Button>
            </SortableItem>
        ))}
    </div>
);

export default SortableList;
