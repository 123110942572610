import { LogBookNoteFormModel } from 'platform/campaign/logBook/logBook.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import GeneralInfoCard from './GeneralInfoCard';
import LogBookClassificationCard from './LogBookClassificationCard';
import NoteCard from './NoteCard';
import './LogBookForm.scss';

const LogBookForm = ({ onCancel, labels, canEdit, formikProps }: FormProps<LogBookNoteFormModel>) => (
    <CardForm
        formClassname="LogBookForm"
        title={`${labels.prefix} Log entry`}
        subtitle={null}
        submitLabel={labels.submit}
        animationDelay={0}
        onCancel={onCancel}
        disabled={!canEdit}
        onSubmit={formikProps.submitForm}
    >
        <GeneralInfoCard formikProps={formikProps} />
        <LogBookClassificationCard
            field="classification"
            values={formikProps.values.classification}
            onChange={formikProps.setFieldValue}
        />
        <NoteCard />
    </CardForm>
);

export default LogBookForm;
