import { isEmpty } from 'lodash-es';
import { isNotEmpty } from 'platform/common/common.types';
import { OptionFilter, OptionType } from './CheckboxTree.type';

export const isAnyChildNodeSelected = (
    node: OptionType,
    value: number[],
    valueKey: string,
    nodesKey: string
): boolean => {
    const childNodes: OptionType[] = node[nodesKey] || [];
    if (childNodes && childNodes.length) {
        return childNodes.some((child) => isAnyChildNodeSelected(child, value, valueKey, nodesKey));
    }

    return value.includes(node[valueKey]);
};

export const flattenNodes = (nodes: OptionType[], nodesKey: string): OptionType[] =>
    nodes?.reduce<OptionType[]>((result, node) => {
        const childNodes = node[nodesKey] || [];
        if (childNodes && childNodes.length) {
            return [...result, ...flattenNodes(childNodes, nodesKey)];
        }
        return [...result, node];
    }, []);

export const flattenNodesWithParents = (
    nodes: OptionType[],
    nodesKey: string,
    flattenedNodesWithParents: OptionType[] = [],
    parents: OptionType[] = []
) => {
    if (!nodes) {
        return flattenedNodesWithParents;
    }
    nodes.forEach((node) => {
        if (node[nodesKey]) {
            flattenNodesWithParents(node[nodesKey], nodesKey, flattenedNodesWithParents, [...parents, node]);
        }
        flattenedNodesWithParents.push({
            ...node,
            parents,
        });
    });

    return flattenedNodesWithParents;
};

export const filterOptions = (
    options: OptionType[],
    selectedValue: number[],
    query: string,
    filter: OptionFilter,
    valueKey: string,
    labelKey: string,
    nodesKey: string,
    filterChildren: boolean = true,
    additionalFilters: { attribute: string; value: string }[] = []
): OptionType[] => {
    const validFilters = additionalFilters.filter((f) => isNotEmpty(f.value));

    const filteredOptions = validFilters.length
        ? options.filter((option) =>
              validFilters.every(({ attribute, value }) => option[attribute] === value)
          )
        : options;

    return filteredOptions.reduce<OptionType[]>((acc, option) => {
        const nodes = option[nodesKey];
        const matchesQuery = option[labelKey].toUpperCase().includes(query.toUpperCase());
        const isSelected =
            filter === OptionFilter.SHOW_SELECTED ? selectedValue.includes(option[valueKey]) : true;
        if (!filterChildren && matchesQuery && isSelected) {
            acc.push(option);
            return acc;
        }
        if (isEmpty(nodes)) {
            if (matchesQuery && isSelected) {
                acc.push(option);
            }

            return acc;
        }

        const childOptions = filterOptions(nodes, selectedValue, query, filter, valueKey, labelKey, nodesKey);

        if (childOptions.length) {
            acc.push({
                ...option,
                [nodesKey]: childOptions,
            });
        } else if (matchesQuery && isSelected) {
            acc.push({
                ...option,
                [nodesKey]: [],
            });
        }

        return acc;
    }, []);
};
