import {
    ALGORITHM_LANGUAGE_LABELS,
    ALGORITHM_LANGUAGE_VALUE,
    AlgorithmLanguage,
} from 'platform/algorithms/algorithmManager.constant';
import AlgorithmSettingTabs from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettingTabs';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { makeOptions } from 'platform/common/utils/option.util';
import { required } from 'platform/common/utils/validators.util';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import './AlgorithmSettings.scss';

export interface AlgorithmSettingsProps {
    settings?: string;
    settingsLanguage: AlgorithmLanguage;
    hasMultipleSettings: boolean;
    onChange: (settings: string) => void;
}

const AlgorithmSettings = ({
    settingsLanguage,
    settings,
    hasMultipleSettings,
    onChange,
}: AlgorithmSettingsProps) => (
    <div className="AlgorithmSettings">
        {settingsLanguage === 'JSON' && (
            <AlgorithmSettingTabs
                settings={settings}
                settingsLanguage={settingsLanguage}
                hasMultipleSettings={hasMultipleSettings}
                onChange={onChange}
            />
        )}
        {settingsLanguage !== 'JSON' && (
            <>
                <FormCodeEditor
                    name="settings"
                    options={{
                        mode: ALGORITHM_LANGUAGE_VALUE[settingsLanguage],
                        lineWrapping: true,
                        readOnly: false,
                        lineNumbers: true,
                    }}
                />
                <FormRow label="Language">
                    <FormSelect
                        name="display.settingsLanguage"
                        options={makeOptions(ALGORITHM_LANGUAGE_LABELS)}
                        validate={required}
                        isClearable={false}
                    />
                </FormRow>
            </>
        )}
    </div>
);

export default AlgorithmSettings;
