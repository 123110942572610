import 'react-day-picker/dist/style.css';
import useToggle from 'platform/common/hooks/useToggle';
import UncontrolledDatePicker from './UncontrolledDatePicker';

interface Props {
    date: string | undefined;
    placeholder?: string;
    className?: string;
    format?: string;
    isOpen?: boolean;
    children?: React.ReactNode;
    onDateChange: (value: Date) => void;
    onDateClear?: () => void;
}

const DatePicker = ({ date, placeholder, className, format, children, onDateChange, onDateClear }: Props) => {
    const [isOpen, toggleIsOpen] = useToggle(false);

    return (
        <UncontrolledDatePicker
            date={date}
            format={format}
            placeholder={placeholder}
            className={className}
            isOpen={isOpen}
            onToggle={() => toggleIsOpen()}
            onDateChange={onDateChange}
            onDateClear={onDateClear}
        >
            {children}
        </UncontrolledDatePicker>
    );
};

export default DatePicker;
