import { ChartOptions } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import { StatisticWithSeries } from 'platform/common/common.types';
import LineChart from 'platform/common/components/LineChart/LineChart';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';

interface Props {
    data?: StatisticWithSeries;
    loading: boolean;
    chartOptions: ChartOptions<any>;
    datasetOptions: ChartOptions<any>;
}

const LoadingState = () => <Placeholder height="300px" />;

const KPIOverviewChart = ({ data, chartOptions, datasetOptions, loading }: Props) => {
    if (loading) {
        return <LoadingState />;
    }

    if (!data) {
        return null;
    }

    return (
        <div style={{ height: 300 }}>
            <LineChart
                values={data.series.values}
                labels={data.series.labels}
                height={300}
                customizeChartOptions={(defaultOptions) => ({
                    ...defaultOptions,
                    ...cloneDeep(chartOptions),
                })}
                customizeDatasetOptions={(defaultOptions) => ({
                    ...defaultOptions,
                    ...cloneDeep(datasetOptions),
                })}
            />
        </div>
    );
};

export default KPIOverviewChart;
