import { useState } from 'react';
import { Input, InputGroupText } from 'reactstrap';
import DatePicker from 'platform/common/components/DatePicker/DatePicker';
import UncontrolledDatePicker from 'platform/common/components/DatePicker/UncontrolledDatePicker';
import { formatDate } from 'platform/common/utils/date.util';
import CardLabel from '../../common/components/CardLabel/CardLabel';
import { TaskData } from '../task.types';
import KanbanToggleButton from './KanbanToggleButton';

interface Props {
    startDate: string | undefined;
    dueDate: string | undefined;
    tentativeDates: boolean | undefined;
    onChange: (data: Partial<TaskData>) => Promise<void>;
}

const KanbanDates = ({ startDate, dueDate, tentativeDates, onChange }: Props) => {
    const [isDatesVisible, setIsDatesVisible] = useState(!!startDate || !!dueDate);
    const [dueDateOpen, setDueDateOpen] = useState(false);

    return (
        <>
            <div className="d-flex">
                <CardLabel icon="fa fa-calendar-days">Dates</CardLabel>
                <KanbanToggleButton
                    onClick={async () => {
                        setIsDatesVisible(true);
                        // needed to wait for DatePicker to be mounted
                        setTimeout(() => {
                            setDueDateOpen((isOpen) => !isOpen);
                        }, 0);
                    }}
                />
            </div>
            {isDatesVisible && (
                <>
                    <div className="d-flex align-items-center mb-1 mt-3">
                        <div className="text-muted fw-bold me-2">Timing to be decided</div>
                        <Input
                            className="m-0 border"
                            type="checkbox"
                            checked={tentativeDates}
                            onChange={(e) => onChange({ tentativeDates: e.target.checked })}
                        />
                    </div>

                    <div className="d-flex gap-3">
                        <div className="flex-grow-1">
                            <div className="mb-1 text-muted fw-bold">Start Date</div>
                            <DatePicker
                                className="Kanban-card-modal-date-picker"
                                date={startDate}
                                onDateChange={(date) => onChange({ startDate: formatDate(date) })}
                                onDateClear={() => onChange({ startDate: '' })}
                            >
                                <InputGroupText>
                                    <i className="fa fa-circle Kanban-green font-xs" />
                                </InputGroupText>
                            </DatePicker>
                        </div>
                        <div className="flex-grow-1">
                            <div className="mb-1 text-muted fw-bold"> Due Date</div>
                            <UncontrolledDatePicker
                                className="Kanban-card-modal-date-picker"
                                date={dueDate}
                                isOpen={dueDateOpen}
                                onToggle={() => setDueDateOpen((isOpen) => !isOpen)}
                                onDateChange={(date) => onChange({ dueDate: formatDate(date) })}
                                onDateClear={() => onChange({ dueDate: '' })}
                            >
                                <InputGroupText>
                                    <i className="fa fa-circle Kanban-red font-xs" />
                                </InputGroupText>
                            </UncontrolledDatePicker>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default KanbanDates;
