import { ReactNode, useEffect, useState } from 'react';
import { uniqueId } from 'lodash-es';
import InputButton from '../InputButton/InputButton';
import RemovableLineItem from '../RemovableLineItem/RemovableLineItem';

interface Props {
    addOnTitle?: string;
    buttonTitle: string;
    placeholder?: string;
    onAdd: (value: string[], newValue: string) => string;
    onRemove: (value: string[]) => string;
    values: string[];
    className?: string;
    inputProps: {
        onFocus: () => void;
        onBlur: (value: string[]) => void;
    };
    valueDecorator?: (value: string) => string;
    id?: string;
    children?: ReactNode;
    canRemove?: boolean;
}

const UNIQUE_ID_KEY = 'DynamicOptionListEntry';

const toValue = <T extends { value: string }>(item: T) => item.value;

const DynamicOptionList = ({
    buttonTitle,
    className,
    inputProps,
    onAdd,
    onRemove,
    values,
    valueDecorator,
    children,
    addOnTitle = '',
    placeholder = '',
    canRemove = true,
    id,
}: Props) => {
    const [entries, setEntries] = useState<{ id: string; value: string }[]>([]);

    useEffect(() => {
        setEntries((values || []).map((item) => ({ id: uniqueId(UNIQUE_ID_KEY), value: item })));
    }, [values]);

    const onAddClick = (value: string): string => {
        if (!value) {
            return '';
        }
        const previewValue = valueDecorator ? valueDecorator(value.trim()) : value;
        const newEntries = [...entries, { id: uniqueId(UNIQUE_ID_KEY), value: previewValue }];
        return onAdd(newEntries.map(toValue), value);
    };

    const onRemoveClick = (key: string) => {
        const newEntries = entries.filter((item) => item.id !== key);
        setEntries(newEntries);
        onRemove(newEntries.map(toValue));
    };

    return (
        <div className="DynamicOptionList" id={id}>
            <InputButton
                className={className}
                inputProps={{
                    onFocus: inputProps.onFocus,
                    onBlur: () => inputProps.onBlur(entries.map(toValue)),
                }}
                addOnTitle={addOnTitle}
                placeholder={placeholder}
                buttonTitle={buttonTitle}
                onClick={onAddClick}
            />
            {children}
            {entries.map((entry, index) => (
                <RemovableLineItem
                    canRemove={canRemove}
                    key={entry.id}
                    className={entries.length - 1 === index ? 'mt-2' : 'my-2'}
                    id={entry.id}
                    onRemove={() => onRemoveClick(entry.id)}
                >
                    {entry.value}
                </RemovableLineItem>
            ))}
        </div>
    );
};

export default DynamicOptionList;
