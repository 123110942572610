import ObserverList from './ObserverList';
import { OBSERVER } from './observer.navigation';

const routes = [
    {
        ...OBSERVER,
        component: ObserverList,
    },
];

export default routes;
