import { useFormikContext } from 'formik';
import ChatAgentPlaceholdersList from 'platform/chat/admin/AdminChatAgentList/ChatAgentPlaceholdersList';
import { ChatAgentFormModel } from 'platform/chat/chat.types';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormInput from 'platform/formik/FormInput/FormInput';

type Props = {
    showAvailablePlaceholders?: boolean;
};
const ChatAgentSystemMessageField = ({ showAvailablePlaceholders }: Props) => {
    const formikContext = useFormikContext<ChatAgentFormModel>();
    const systemMessage = formikContext.values.systemMessage;
    const messages = systemMessage ? [systemMessage] : [];

    return (
        <FormRow label="System message">
            <FormInput name="systemMessage" type="textarea" rows={5} />
            <ChatAgentPlaceholdersList messages={messages} showAvailable={showAvailablePlaceholders} />
        </FormRow>
    );
};

export default ChatAgentSystemMessageField;
