import { useState } from 'react';

export type withLoadingType = <T = any>(action: () => Promise<T>) => Promise<T>;

export const useLoading = (
    initialState = false
): [boolean, <T = any>(action: () => Promise<T>) => Promise<T>] => {
    const [loading, setLoading] = useState(initialState);

    const withLoading = async <T = any>(action: () => Promise<T>): Promise<T> => {
        try {
            setLoading(true);
            return await action();
        } finally {
            setLoading(false);
        }
    };

    return [loading, withLoading];
};
