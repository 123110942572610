import { LabelNames } from '../task.types';
import './TaskLabels.scss';

const TaskLabels = ({ keys, names }: { keys: string[]; names: LabelNames }) => (
    <>
        {keys.map((key) => (
            <div key={key} className={`Task-label Task-label-color-${key}`}>
                {names[key] || `Label ${key}`}
            </div>
        ))}
    </>
);

export default TaskLabels;
