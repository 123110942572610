import { useSelector } from 'react-redux';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { isDefined } from 'platform/common/common.types';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import Spinner from 'platform/common/components/Spinner/Spinner';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { TASK_BOARDS_PATH } from 'platform/task/taskRoutes';
import { Task, TaskData, TaskStatus } from '../task.types';

type Props = {
    boardId: number;
    task: Task;
    currentPosition: number;
    bottomPosition: number;
    className?: string;
    onMove: (position: number) => void;
    onUpdate: (data: Partial<TaskData>) => void;
    onDelete: () => void;
    onTaskCopy?: (task: Task) => void;
};

const KanbanCardActionsDropdown = ({
    boardId,
    task,
    currentPosition,
    bottomPosition,
    className,
    onMove,
    onUpdate,
    onDelete,
    onTaskCopy,
}: Props) => {
    const profile = useSelector(authSelectors.ready.profile);

    const statusItem = (icon: string, label: string, status: TaskStatus): PopoverDropdownItem | undefined =>
        task.status !== status ? { icon, label, action: () => onUpdate({ status }) } : undefined;

    const copyLink = () => {
        const link = `${location.origin}${TASK_BOARDS_PATH}/${boardId}?openTaskId=${task.id}`;
        navigator.clipboard.writeText(link);
        toastSuccess('Task link copied to clipboard');
    };

    const getOptionItems = (): PopoverDropdownItem[] =>
        [
            {
                icon: 'fa-link',
                label: 'Copy link',
                action: copyLink,
            },
            onTaskCopy
                ? {
                      icon: 'fa-copy',
                      label: 'Copy task',
                      action: () => !!onTaskCopy && onTaskCopy(task),
                  }
                : undefined,
            !task.assigneeIds?.includes(profile.id)
                ? {
                      icon: 'fa-user',
                      label: 'Assign to me',
                      action: () => onUpdate({ assigneeIds: [...(task.assigneeIds ?? []), profile.id] }),
                  }
                : undefined,
            currentPosition > 0
                ? {
                      icon: 'fa-arrow-up',
                      label: 'Move to top',
                      action: () => onMove(0),
                  }
                : undefined,
            currentPosition < bottomPosition - 1
                ? {
                      icon: 'fa-arrow-down',
                      label: 'Move to bottom',
                      action: () => onMove(bottomPosition),
                  }
                : undefined,
            statusItem('fa-list', 'Mark as active', 'ACTIVE'),
            statusItem('fa-circle-check', 'Mark as done', 'DONE'),
            statusItem('fa-box-archive', 'Archive', 'ARCHIVED'),
            {
                icon: 'fa-minus',
                label: 'Delete',
                action: onDelete,
                confirmation: `Permanently delete "${task.name}"?`,
            },
        ].filter(isDefined);

    return (
        <span className={className}>
            {task.syncing ? (
                <Spinner size="sm" className="mt-1 me-2" />
            ) : (
                <InlineDropdown items={getOptionItems()} />
            )}
        </span>
    );
};

export default KanbanCardActionsDropdown;
