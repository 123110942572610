import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';

const implementationTestUrl = 'https://implementation-test.deepmedia.de/';

const ImplementationTest = () => {
    window.location.href = implementationTestUrl;
    return <OverlayLoader />;
};

export default ImplementationTest;
