/* eslint-disable import/no-import-module-exports */
import { applyMiddleware, compose, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { withTakeLatest } from 'platform/withTakeLatest';
import { isDefined } from './common/common.types';
import createRootReducer from './root.reducer';
import rootSaga from './root.saga';
import { loadStateFromStorage } from './storageAndStoreSync';

export const urlBasename = window.basename?.endsWith('/') ? window.basename.slice(0, -1) : window.basename; // removing trailing slash

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
    basename: urlBasename, // required for preview branch
    reduxTravelling: true,
});

const prodMiddlewares = (sagaMiddleware: SagaMiddleware) => [
    routerMiddleware,
    withTakeLatest(promise),
    thunk,
    sagaMiddleware,
];

export function configureStoreProd(initialState: any) {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        createRootReducer(routerReducer),
        initialState,
        compose(applyMiddleware(...prodMiddlewares(sagaMiddleware)))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}

function configureStoreDev(initialState: any) {
    const sagaMiddleware = createSagaMiddleware({ sagaMonitor: (window as any).__SAGA_MONITOR_EXTENSION__ });
    const suppressReduxLogger = ['error', 'crit', 'warn'].includes(envConfig.LOGGING_LEVEL);

    const middlewares = [
        ...prodMiddlewares(sagaMiddleware),
        envConfig.STATE_MUTATION_CHECKS ? reduxImmutableStateInvariant() : undefined,
        suppressReduxLogger
            ? undefined
            : createLogger({
                  level: 'log',
                  collapsed: envConfig.REDUX_LOGGER_COLLAPSED,
              }),
    ].filter(isDefined);

    // eslint-disable-next-line no-underscore-dangle
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        createRootReducer(routerReducer),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    sagaMiddleware.run(rootSaga);
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./root.reducer', () => {
            store.replaceReducer(createRootReducer(routerReducer));
        });
    }

    return store;
}

const initialState = loadStateFromStorage();
const globalStore = envConfig.IS_PRODUCTION_BUILD
    ? configureStoreProd(initialState)
    : configureStoreDev(initialState);

export const history = createReduxHistory(globalStore);

export default globalStore;
