import classNames from 'classnames';
import { formatBudget } from 'platform/common/utils/number.util';

interface Props {
    oldNumber?: number;
    newNumber?: number;
    className?: string;
}

const NumberShift = ({ oldNumber = 0, newNumber = 0, className }: Props) => {
    const numberDelta = newNumber - oldNumber;

    if (numberDelta === 0) {
        return null;
    }

    return (
        <span
            className={classNames(
                'me-2 fw-bold',
                {
                    'text-success': numberDelta > 0,
                    'text-danger': numberDelta < 0,
                },
                className
            )}
        >
            <i
                className={classNames('me-1 fas', {
                    'fa-arrow-trend-up': numberDelta > 0,
                    'fa-arrow-trend-down': numberDelta < 0,
                })}
            />
            {numberDelta > 0 && '+'}
            {formatBudget(numberDelta)}
        </span>
    );
};

export default NumberShift;
