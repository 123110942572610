import { Button } from 'reactstrap';
import classNames from 'classnames';
import { useField } from 'formik';

type Props = {
    name: string;
    icon: string;
    onChange: (value: boolean) => void;
};

const FormToggle = ({ name, icon, onChange }: Props) => {
    const [{ value }, , { setValue }] = useField({ name });
    const handleClick = () => {
        setValue(!value);
        onChange(!value);
    };

    return (
        <Button color="link" className="text-muted" onClick={handleClick}>
            <i className={classNames('fa', 'fa-lg', icon)} />
        </Button>
    );
};

export default FormToggle;
