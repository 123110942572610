import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import ButtonArray, { ButtonType } from 'platform/common/components/ButtonArray/ButtonArray';
import { validator, Validator } from 'platform/common/utils/validators.util';

interface Props<T> {
    buttons: ButtonType<T>[];
    disabled?: boolean;
    className?: string;
    validate?: Validator | Validator[];
    name: string;
    onChange?: (value: T) => void;
}

const FormButtonArray = <T extends string | number>({
    disabled = false,
    buttons,
    name,
    className,
    validate,
    onChange,
}: Props<T>) => {
    const [field, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const invalid = Boolean(meta.touched && meta.error);

    return (
        <>
            <ButtonArray
                buttons={buttons}
                className={className}
                disabled={disabled}
                selected={field.value}
                onClick={(value) => {
                    helpers.setValue(value);
                    if (onChange) {
                        onChange(value);
                    }
                }}
            />
            {invalid && <FormFeedback className="w-100 d-block">{meta.error}</FormFeedback>}
        </>
    );
};

export default FormButtonArray;
