import { Card, CardBody, Button, Collapse } from 'reactstrap';
import chevronsCollapse from 'platform/assets/icons/journey/chevrons2.svg';
import useToggle from 'platform/common/hooks/useToggle';
import { CustomerJourneyReportCM360 } from 'platform/dashboard/dashboard.types';
import JourneyItemSeparator from '../JourneyEventListHeader/JourneyTimeline/JourneyItemSeparator';
import JourneyRow from './JourneyRow/JourneyRow';
import './JourneyEventListGroup.scss';

interface Props {
    rows: CustomerJourneyReportCM360[];
}

const JourneyEventListGroup = ({ rows }: Props) => {
    const [isOpen, toggle] = useToggle(false);
    const [isFirstRowOpen, toggleFirstRowOpen] = useToggle(false);

    const first = rows[0];
    const last = rows.at(-1)!;
    const rest = rows.slice(1, -1);

    if (!first || !last) return null;

    return (
        <Card className="mb-0 JourneyEventListGroup">
            <CardBody className="p-0">
                {rows.length > 1 && (
                    <JourneyRow
                        row={first}
                        defaultExpanded={false}
                        isExpanded={
                            first.journey_event_type === 'click' || first.journey_event_type === 'view'
                                ? isFirstRowOpen
                                : undefined
                        }
                    />
                )}
                {rows.length > 2 && (
                    <>
                        <Collapse isOpen={isOpen}>
                            <Button
                                className="JourneyEventListGroup-showLess"
                                onClick={() => {
                                    toggle();
                                    toggleFirstRowOpen(false);
                                }}
                            >
                                <img src={chevronsCollapse} width={16} height={32} alt="collapse" />
                            </Button>
                            {rest.map((row, index) => (
                                <div key={row.journey_event_date_time}>
                                    <JourneyItemSeparator
                                        startTime={rows[index].journey_event_date_time}
                                        endTime={row.journey_event_date_time}
                                    />
                                    <JourneyRow row={row} />
                                </div>
                            ))}
                            <JourneyItemSeparator
                                startTime={rest.at(-1)!.journey_event_date_time}
                                endTime={last.journey_event_date_time}
                            />
                        </Collapse>
                        {!isOpen && (
                            <div className="JourneyEventListGroup-showMoreContainer">
                                <div className="JourneyEventListGroup-showMoreLine" />
                                <Button
                                    className="JourneyEventListGroup-showMore"
                                    color="primary"
                                    onClick={() => {
                                        toggle();
                                        toggleFirstRowOpen(true);
                                    }}
                                >
                                    {rest.length} more visit
                                    {rest.length > 1 && 's'}
                                    <i className="ms-2 fa-solid fa-arrows-up-down" />
                                </Button>
                            </div>
                        )}
                    </>
                )}
                <JourneyRow last row={last} />
            </CardBody>
        </Card>
    );
};

export default JourneyEventListGroup;
