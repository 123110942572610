import FormFlightLabelSelect from 'platform/campaign/common/FormFlightLabelSelect';
import FormProductLabelSelect from 'platform/campaign/common/FormProductLabelSelect';
import FormProductSubLabelSelect from 'platform/campaign/common/FormProductSubLabelSelect';
import FormTimeOffersSelect from 'platform/campaign/common/FormTimeOffersSelect';
import { StrategyModel } from 'platform/campaign/strategy/strategy.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

const StrategyForm = ({
    onCancel,
    formikProps: { values, setFieldValue, submitForm },
}: FormProps<StrategyModel>) => (
    <CardForm
        title="Strategy"
        subtitle={`ID: ${values.id}`}
        submitLabel="Save & continue"
        onSubmit={submitForm}
        onCancel={onCancel}
    >
        <ControlledCard title="General information">
            <FormRow label="Advertiser">{values.advertiser.name}</FormRow>
            <FormRow label="Campaign">{values.campaign.name}</FormRow>
            <FormRow label="Name">
                <FormInput name="name" validate={required} disabled={values.type === 'RTB'} />
            </FormRow>
            <FormRow label="Product">
                <FormProductLabelSelect
                    name="productLabel"
                    onChange={() => setFieldValue('productSubLabel', null)}
                />
            </FormRow>
            {!!values.productLabel?.value && (
                <FormRow label="Sub-product">
                    <FormProductSubLabelSelect
                        name="productSubLabel"
                        parentProductLabel={values.productLabel}
                    />
                </FormRow>
            )}
            <FormRow label="Flight">
                <FormFlightLabelSelect name="flightLabel" />
            </FormRow>
            <FormRow label="Time offer">
                <FormTimeOffersSelect name="timeOfferIds" inputClassName="w-100" />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default StrategyForm;
