import { Button, UncontrolledTooltip } from 'reactstrap';
import Search from '../Search/Search';

type Props = {
    onCreate?: (fn: any) => any | undefined;
    createInactiveReason?: string;
    onSearch?: (name: string) => any;
    value?: string;
    addNewButtonTitle?: string;
};

const ListToolbar = ({
    onCreate,
    createInactiveReason,
    addNewButtonTitle = 'Add new',
    onSearch,
    value,
}: Props) => {
    const addNewButtonWrapperId = 'ListToolbar-addNewWrapper';
    return (
        <>
            {(onCreate || createInactiveReason) && (
                <Button
                    id={addNewButtonWrapperId}
                    size="md"
                    color="primary"
                    className="me-3"
                    onClick={onCreate}
                    title={!onCreate ? createInactiveReason : undefined}
                    disabled={!onCreate}
                >
                    {addNewButtonTitle}
                </Button>
            )}
            {!onCreate && createInactiveReason && (
                <UncontrolledTooltip placement="right" target={addNewButtonWrapperId}>
                    {createInactiveReason}
                </UncontrolledTooltip>
            )}
            {onSearch && (
                <div>
                    <Search onSearch={onSearch} value={value} />{' '}
                </div>
            )}
        </>
    );
};

export default ListToolbar;
