import classNames from 'classnames';
import Tooltip from '../Tooltip/Tooltip';
import { Delta, Direction, PERCEPTION_COLOR_MAP } from './deltas.util';

export interface DeltaTextProps {
    delta: Delta | undefined;
    className?: string;
    showCompareValue?: boolean;
}

const Arrow = ({ direction }: { direction: Direction }) => {
    if (direction === 'NONE') {
        return null;
    }

    return <i className={`fal fa-arrow-trend-${direction.toLowerCase()} me-1`} />;
};

const DeltaText = ({ delta, className, showCompareValue }: DeltaTextProps) => (
    <div className={classNames('text-nowrap', className, delta && PERCEPTION_COLOR_MAP[delta.perception])}>
        {delta && (
            <div className="d-flex flex-column">
                {showCompareValue && <div className="text-dark">({delta.compareValue})</div>}
                <Tooltip placement="bottom" renderTooltip={() => delta.compareValue}>
                    <Arrow direction={delta.direction} />
                    {delta.value}
                </Tooltip>
            </div>
        )}
    </div>
);

export default DeltaText;
