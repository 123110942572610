import { TableCell } from '../../../../common/common.types';
import FormattedTable from '../../../../common/components/FormattedTable/FormattedTable';
import { DATA_TYPES } from '../../../../common/dataTypes';
import { SegmentChartStats } from '../../../segments.types';
import './VisitTimeTable.scss';

type StateProps = {
    data: SegmentChartStats[];
    loading: boolean;
};

const columns = [
    {
        Header: 'Visit time',
        accessor: 'name',
        Cell: (line: TableCell<SegmentChartStats>) => {
            const time = line.value && line.value.match(/\d+/)?.[0];
            return time ? `${time} s` : null;
        },
    },
    {
        Header: 'Users 30 d.',
        accessor: 'uniqueUsers',
    },
    {
        Header: 'All actions',
        accessor: 'allActions',
    },
    {
        Header: 'Change in action prob., %',
        accessor: 'penetrationDelta',
        Cell: (line: TableCell<SegmentChartStats>) => DATA_TYPES.P100.parse(Math.max(0, line.value)),
    },
];

const VisitTimeTable = ({ data, loading }: StateProps) => {
    if (!data) {
        return null;
    }

    return (
        <div className="VisitTimeTable pt-3">
            <FormattedTable columns={columns} data={data} loading={loading} />
        </div>
    );
};

export default VisitTimeTable;
