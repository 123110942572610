import { SelectItem } from 'platform/common/common.types';
import SortableList from 'platform/common/components/SortableList/SortableList';
import Select from '../../../common/components/Select/Select';

export interface Item {
    id: number;
}

type Props = {
    options: (SelectItem<number> & { hidden?: boolean })[];
    className?: string;
    items: Item[];
    dragKey: string;
    disabled?: boolean;
    loading?: boolean;
    onDrop: (moveFrom: Item, moveTo: Item) => void;
    onRemove: (item: Item) => void;
    onAdd: (item: number) => void;
};

const SortableSelect = ({
    options,
    items,
    dragKey,
    className,
    loading = false,
    disabled = false,
    onDrop,
    onAdd,
    onRemove,
}: Props) => (
    <div className={className}>
        <Select
            onChange={onAdd}
            value={undefined}
            options={options
                .map((o) => ({
                    ...o,
                    disabled: items.some((i) => i.id === o.value),
                }))
                .filter((o) => !o?.hidden)}
            isLoading={loading}
            isDisabled={disabled}
        />
        {!loading && items && (
            <SortableList
                items={items}
                dragKey={dragKey}
                disabled={disabled}
                itemClassName="ps-3 border-bottom bg-secondary"
                className="mt-3 border-top"
                onDrop={onDrop}
                onRemove={onRemove}
            >
                {(value) => options.find((o) => o.value === value.id)?.label ?? value.id}
            </SortableList>
        )}
    </div>
);

export default SortableSelect;
