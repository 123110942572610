interface Props {
    note: string;
}

const MessageCenterItemNote = ({ note }: Props) => {
    if (!note) return null;

    return (
        <div className="ObserverMessageCenterList-note">
            <i className="fa-solid fa-message-lines me-2 text-light-slate-gray" />
            {note}
        </div>
    );
};

export default MessageCenterItemNote;
