import { CSSProperties, ReactNode } from 'react';
import { InputGroup, InputGroupText } from 'reactstrap';
import classnames from 'classnames';
import { OptionType } from 'platform/common/components/Select/select.types';
import TextWithEllipsis from '../TextWithEllipsis/TextWithEllipsis';
import Select, { SelectProps } from './Select';

const selectRemoveStyle: CSSProperties = {
    borderRight: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
};

const leftBorderRadius: CSSProperties = {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
};

const getSelectStyle = (removeSelectStyle: boolean, style?: CSSProperties) => {
    if (removeSelectStyle) {
        return { ...style, ...leftBorderRadius, ...selectRemoveStyle };
    }

    return { ...style, ...leftBorderRadius };
};

interface SelectWithAddonProps {
    name?: ReactNode;
    selectClassname?: string;
    secondAddon?: ReactNode;
    endAddon?: ReactNode;
}

const SelectWithAddon = <T extends OptionType = OptionType>({
    className,
    name,
    secondAddon,
    endAddon,
    selectStyle,
    selectClassname,
    isDisabled,
    isMulti,
    invalid,
    ...rest
}: SelectProps<T> & SelectWithAddonProps) => (
    <InputGroup
        className={classnames('flex-nowrap', className, { 'form-control is-invalid m-0 p-0': invalid })}
    >
        <InputGroupText className="rounded-start">
            <TextWithEllipsis>{name}</TextWithEllipsis>
        </InputGroupText>
        {secondAddon && <InputGroupText className="rounded-0">{secondAddon}</InputGroupText>}
        <Select
            selectStyle={getSelectStyle(!!endAddon && !isDisabled, selectStyle)}
            className={selectClassname}
            closeMenuOnSelect={!isMulti}
            isDisabled={isDisabled}
            isMulti={isMulti}
            {...rest}
        />
        {endAddon}
    </InputGroup>
);

export default SelectWithAddon;
