import axiosBff from 'platform/axios/axiosBff';
import { RequestDetails, ResponseDetails } from 'platform/common/error.types';

interface ErrorReportingModel {
    userAgent: string;
    url: string;
    advertisers: { id: number; name: string }[];
    error: string;
    traceId?: string;
    request?: RequestDetails;
    response?: ResponseDetails;
}

export const sendErrorReport = (data: ErrorReportingModel) =>
    axiosBff.post('/users/api/error/reporting/send', data);
