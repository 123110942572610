import { Key, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SortableTree, { addNodeUnderParent, map, removeNodeAtPath, walk } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { Alert } from 'reactstrap';
import classNames from 'classnames';
import { FormikErrors } from 'formik';
import { isEmpty, omit, sumBy } from 'lodash-es';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import { getCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { assertIsDefined } from 'platform/common/common.assert';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import CardForm from 'platform/common/components/CardForm/CardForm';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { randomHash } from 'platform/common/utils/number.util';
import { MEDIAPLAN_NODE_OPTIONS } from 'platform/mediaplan/mediaplan.constant';
import {
    MediaGroupFormModel,
    MediaInsertionFormModel,
    MediaNodeFormModel,
    MediaNodeType,
    MediaplanTreeFormModel,
} from 'platform/mediaplan/mediaplan.types';
import MediaNodeToolbar from '../MediaNodeToolbar/MediaNodeToolbar';
import MediaplanBudgetBar from '../MediaplanBudgetBar/MediaplanBudgetBar';
import MediaGroupNode from './MediaGroupNode';
import MediaInsertionNode from './MediaInsertionNode';
import './MediaplanTreeForm.scss';

type Props = FormProps<MediaplanTreeFormModel> & {
    id: number;
    canEdit: boolean;
    onInitialValuesChange: (isDirty: boolean) => void;
};

const MediaplanTreeForm = ({
    id,
    labels,
    canEdit,
    onCancel,
    onInitialValuesChange,
    formikProps: {
        submitCount,
        dirty,
        setFieldValue,
        values: { insertions, mediaplan, treeData, groups },
        initialValues,
        errors,
        submitForm,
    },
}: Props) => {
    const [copiedNode, setCopiedNode] = useState<MediaNodeFormModel | undefined>(undefined);
    const showConfirmationModal = useConfirmationModal();
    const vendors = useSelector(classifierSelectors.vendors);

    const [
        {
            data: [products, flightLabels, campaigns],
            loading,
        },
    ] = usePromise(
        [[], [], []],
        () =>
            Promise.all([
                fetchProducts(mediaplan.advertiserId),
                fetchFlightLabels(mediaplan.advertiserId),
                getCampaigns({ advertiserIds: [mediaplan.advertiserId] }),
            ]),
        [id]
    );

    useEffect(() => {
        onInitialValuesChange(dirty);
    }, [dirty]);

    const createNewNode = (type: MediaNodeType): MediaNodeFormModel => {
        const key = getUniqueNodeId();
        if (type === 'INSERTION') {
            const newInsertion: MediaInsertionFormModel = {
                key,
                name: '',
                vendorSystems: [],
                workflowType: 'SIMPLIFIED',
                runtime: {
                    from: mediaplan.dateFrom,
                    to: mediaplan.dateTo,
                },
                budget: 0,
                campaignLinks: [],
            };
            setFieldValue('insertions', { ...insertions, [key]: newInsertion });
        } else {
            const newGroup: MediaGroupFormModel = { key, name: '' };
            setFieldValue('groups', { ...groups, [key]: newGroup });
        }

        return { key, type };
    };

    const changeTreeData = (newData: MediaNodeFormModel[]) => setFieldValue(`treeData`, newData);

    const addTreeNode = (path: Key[], node: MediaNodeFormModel) => {
        changeTreeData(
            addNodeUnderParent({
                treeData,
                parentKey: path[path.length - 1],
                addAsFirstChild: true,
                expandParent: true,
                getNodeKey: getTreeNodeKey,
                newNode: node,
            }).treeData
        );
    };

    const pasteToGroup = (path: Key[]) => {
        assertIsDefined(copiedNode, 'copiedNode');
        const newGroups: Record<string, MediaGroupFormModel> = {};
        const newInsertions: Record<string, MediaInsertionFormModel> = {};

        const treeCopy = map({
            treeData: [copiedNode],
            callback: ({ node }: { node: MediaNodeFormModel }) => {
                const newNode = {
                    ...node,
                    key: getUniqueNodeId(),
                };
                if (groups[node.key]) {
                    newGroups[newNode.key] = {
                        ...groups[node.key],
                        key: newNode.key,
                    };
                }
                if (insertions[node.key]) {
                    const { id: insertionId, ...rest } = insertions[node.key];
                    newInsertions[newNode.key] = {
                        ...rest,
                        key: newNode.key,
                    };
                }
                return newNode;
            },
            getNodeKey: getTreeNodeKey,
            ignoreCollapsed: false,
        });

        setFieldValue('groups', { ...groups, ...newGroups });
        setFieldValue('insertions', { ...insertions, ...newInsertions });

        addTreeNode(path, treeCopy[0]);
        setCopiedNode(undefined);
    };

    const removeNode = (node: MediaNodeFormModel, path: Key[]) => {
        const nodeIdsToRemove: string[] = [];
        walk({
            treeData: [node],
            callback: (tree: { node: MediaNodeFormModel }) => nodeIdsToRemove.push(tree.node.key),
            getNodeKey: getTreeNodeKey,
            ignoreCollapsed: false,
        });
        changeTreeData(
            removeNodeAtPath({
                treeData,
                path,
                getNodeKey: getTreeNodeKey,
            })
        );
        setFieldValue('groups', omit(groups, nodeIdsToRemove));
        setFieldValue('insertions', omit(insertions, nodeIdsToRemove));
    };

    const removeNodeWithConfirmation = (node: MediaNodeFormModel, path: Key[]) => {
        const insertion: MediaInsertionFormModel = initialValues.insertions[node.key];
        const explicitLinks = insertion?.campaignLinks?.filter((l) => l.matchType === 'EXPLICIT');

        if (!explicitLinks?.length) {
            removeNode(node, path);
            return;
        }

        showConfirmationModal(() => removeNode(node, path), {
            title: 'Delete media insertion?',
            text:
                `Please be informed that this media insertion is linked to ${explicitLinks.length} ` +
                `campaign(s) and can affect data in budget controlling report. Do you want to proceed?`,
            okLabel: 'Delete',
        });
    };

    const totalBudget = ({ key, type, children }: MediaNodeFormModel): number =>
        type === 'INSERTION' ? Number(insertions[key]?.budget) || 0 : sumBy(children, totalBudget);

    const nodeControls = (node: MediaNodeFormModel) =>
        isGroupNode(node) ? (
            <MediaGroupNode field={`groups.${node.key}`} groupBudget={totalBudget(node)} />
        ) : (
            <MediaInsertionNode
                products={products}
                flightLabels={flightLabels}
                campaigns={campaigns}
                field={`insertions.${node.key}`}
                vendors={vendors}
                insertion={insertions[node.key]}
                initial={initialValues.insertions[node.key]}
                onChange={setFieldValue}
            />
        );

    const nodeToolbar = (node: MediaNodeFormModel, path: Key[]) => (
        <MediaNodeToolbar
            nodeOptions={MEDIAPLAN_NODE_OPTIONS}
            onAdd={isGroupNode(node) ? (type) => addTreeNode(path, createNewNode(type)) : undefined}
            onClear={copiedNode ? () => setCopiedNode(undefined) : undefined}
            onCopy={() => setCopiedNode(node)}
            onPaste={copiedNode && isGroupNode(node) ? () => pasteToGroup(path) : undefined}
            onRemove={
                node.type === 'INSERTION' || isEmpty(node.children)
                    ? () => removeNodeWithConfirmation(node, path)
                    : undefined
            }
        />
    );

    const formErrors = errors as FormikErrors<MediaplanTreeFormModel & { changeTrackingError?: string }>;

    const alert =
        !!submitCount && formErrors.changeTrackingError ? (
            <Alert color="danger" className="m-0 px-3 py-2">
                {formErrors.changeTrackingError}
            </Alert>
        ) : (
            dirty && (
                <Alert color="warning" className="m-0 px-3 py-2">
                    {`Please save the changes to the media insertions by clicking on the "Update" button`}
                </Alert>
            )
        );

    return (
        <CardForm
            title={`${labels.prefix} Mediaplan`}
            subtitle={`ID: ${id}`}
            headerContent={alert}
            cardBodyClassname="h-100"
            submitLabel={labels.submit}
            disabled={!canEdit}
            onCancel={onCancel}
            onSubmit={submitForm}
        >
            <div className="MediaplanTreeForm">
                <div className="MediaplanTreeForm-header pb-md-3">
                    <ButtonDropdown
                        color="secondary"
                        className="ms-2"
                        buttonStyle={{ borderRadius: 8 }}
                        items={MEDIAPLAN_NODE_OPTIONS.map(({ label, type }) => ({
                            label,
                            action: () => changeTreeData([...treeData, createNewNode(type)]),
                        }))}
                    >
                        Add new
                    </ButtonDropdown>
                    <div className="mx-3 flex-grow-1">
                        <MediaplanBudgetBar
                            total={mediaplan.budget}
                            allocated={sumBy(Object.values(insertions), (i) => Number(i.budget) ?? 0)}
                        />
                    </div>
                </div>

                <div className="MediaplanTreeForm-body">
                    {loading ? (
                        <OverlayLoader />
                    ) : (
                        <SortableTree
                            treeData={treeData}
                            className="MediaplanTreeForm-tree"
                            innerStyle={{ paddingBottom: '22rem' }}
                            rowHeight={getRowHeight}
                            canNodeHaveChildren={isGroupNode}
                            isVirtualized={false}
                            getNodeKey={getTreeNodeKey}
                            generateNodeProps={({ node, treeIndex, path }) => ({
                                id: `nodeRow-${treeIndex}`,
                                className: classNames('MediaplanTreeForm-tree-row', {
                                    'MediaplanTreeForm-tree-row--copyTarget': copiedNode && isGroupNode(node),
                                    'MediaplanTreeForm-tree-row-group': isGroupNode(node),
                                }),
                                title: nodeControls(node),
                                buttons: [nodeToolbar(node, path)],
                            })}
                            onChange={(changedTree: MediaNodeFormModel[]) => changeTreeData(changedTree)}
                        />
                    )}
                </div>
            </div>
        </CardForm>
    );
};

const getTreeNodeKey = ({ node }: { node: MediaNodeFormModel }) => node.key;

const getUniqueNodeId = () => `nodeId_${randomHash()}`;

export const isGroupNode = ({ type }: MediaNodeFormModel) => type === 'GROUP';

const getRowHeight = ({ node }: { node: MediaNodeFormModel }) => (isGroupNode(node) ? 80 : 140);

export default MediaplanTreeForm;
