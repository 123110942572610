import styles from 'platform/scss/combined-variables.scss';

enum DeviceTypes {
    DESKTOP = 'Desktop',
    PHONE = 'Phone',
    TABLET = 'Tablet',
}

const DEVICES = [DeviceTypes.DESKTOP, DeviceTypes.PHONE, DeviceTypes.TABLET];

export const DEVICE_COLORS = {
    [DeviceTypes.DESKTOP]: styles.success,
    [DeviceTypes.PHONE]: styles.primary,
    [DeviceTypes.TABLET]: styles.gray100,
};

export const filterDevices = (rows: { device_type: string | null }[]) =>
    DEVICES.filter((device) => rows.some((row) => row.device_type?.toLowerCase() === device.toLowerCase()));
