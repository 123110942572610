import { gql, useQuery } from '@apollo/client';
import { ChartOptions } from 'chart.js';
import moment from 'moment';
import { TypeDefinition } from 'platform/common/common.types';
import LinePure from 'platform/common/components/LinePure/LinePure';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { shouldShowFraction, shouldShowMetricPrefix } from 'platform/common/utils/chart.utils';
import { CHART_COLORS } from 'platform/common/utils/color.util';

type DeviceStatsRow = {
    date: string;
    device_type: string;
    adh_segment_unique_users_yesterday: number;
};

type Props = {
    segmentId: number;
};

const generateChartOptions = (
    type: TypeDefinition,
    useMetricPrefix: boolean,
    showFraction: boolean
): ChartOptions<'line'> => ({
    animation: undefined,
    hover: { intersect: false },
    maintainAspectRatio: false,
    elements: {
        point: { radius: 0, hoverRadius: 4 },
    },
    plugins: {
        tooltip: {
            intersect: false,
            mode: 'index',
            callbacks: {
                title: (tooltipItems: any) =>
                    tooltipItems?.[0].dataset?.labels?.[tooltipItems[0].dataIndex] || '',
            },
        },
    },
    layout: {
        padding: {
            left: 16,
            right: 24,
            top: 24,
            bottom: 24,
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                displayFormats: {
                    hour: 'MMM D',
                },
            },
        },

        y: {
            min: 0,
            ticks: {
                callback: (label: any) => type.format(label, { useMetricPrefix, showFraction }),
            },
        },
    },
});

const uniqueDevices = (rows: DeviceStatsRow[]) => [...new Set(rows.map((row) => row.device_type))].sort();

const createDatasets = (rows: DeviceStatsRow[]) => {
    const deviceTypes = uniqueDevices(rows);
    const makeSeries = <T,>(deviceType: string, extractFn: (row: DeviceStatsRow) => T) => {
        const deviceRows = rows.filter((row) => row.device_type === deviceType);
        const sorted = deviceRows.sort((a, b) => (a.date < b.date ? -1 : 1));
        return sorted.map(extractFn);
    };

    return deviceTypes.map((dt, i) => ({
        data: makeSeries(dt, (row) => row.adh_segment_unique_users_yesterday),
        label: dt,
        labels: makeSeries(dt, (row) => row.date),
        fill: false,
        backgroundColor: CHART_COLORS[i],
        borderColor: CHART_COLORS[i],
    }));
};

const SegmentOverviewDeviceChart = ({ segmentId }: Props) => {
    const { data, loading } = useQuery<{ segment: { deviceStats: DeviceStatsRow[] } }>(
        gql`
            query SegmentOverviewDeviceChart($id: Int!, $period: Period) {
                segment(id: $id) {
                    id
                    deviceStats(period: $period) {
                        date
                        device_type
                        adh_segment_unique_users_yesterday
                    }
                }
            }
        `,
        {
            variables: {
                id: segmentId,
                period: {
                    from: moment()
                        .subtract(30 - 1, 'd')
                        .format(ISO_DATE_FORMAT),
                },
            },
        }
    );

    if (loading) {
        return (
            <div>
                <div className="mb-3 fw-bold">Daily device stats</div>
                <Placeholder height="300px" />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const datasets = createDatasets(data.segment.deviceStats);

    if (!datasets || datasets.length === 0) {
        return null;
    }

    const chartData = {
        labels: datasets[0].labels,
        datasets,
    };

    return (
        <div>
            <div className="mb-3 fw-bold">Daily device stats</div>
            <div>
                <LinePure
                    data={chartData}
                    height={300}
                    options={generateChartOptions(
                        DATA_TYPES.INT,
                        shouldShowMetricPrefix(chartData.datasets),
                        shouldShowFraction(chartData.datasets)
                    )}
                />
            </div>
        </div>
    );
};

export default SegmentOverviewDeviceChart;
