import {
    isArray,
    isBoolean,
    isEmpty,
    isFunction,
    isNil,
    isNumber,
    isObjectLike,
    isString,
    mapValues,
    pickBy,
} from 'lodash-es';
import { isDefined } from 'platform/common/common.types';
import { required } from 'platform/common/utils/validators.util';
import { gcmHostedCreativeValidator } from 'platform/creatives/components/CreativeBlocks/GcmHostedCreativeFields';
import { gcmTrackerValidator } from 'platform/creatives/components/CreativeBlocks/GcmTrackerCreativeFields';
import { gcmVideoCreativeValidator } from 'platform/creatives/components/CreativeBlocks/GcmVideoCreativeFields';
import { ConceptModel, CreativeModel } from 'platform/creatives/types/creative.types';

function toPaths(val: any, prefix: string): string[] {
    if (typeof val === 'string') return [prefix];
    if (isArray(val)) {
        // eslint-disable-next-line no-use-before-define
        return listArrayPathsForErr(val).map((path) => `${prefix}${path}`);
    }
    if (isObjectLike(val)) {
        // eslint-disable-next-line no-use-before-define
        return listObjectPathsForErr(val).map((path) => `${prefix}.${path}`);
    }
    return [];
}

function listArrayPathsForErr(vals: any[]): string[] {
    return vals.flatMap((val, i) => toPaths(val, `[${i}]`));
}

export function listObjectPathsForErr(errors: Record<string, any>): string[] {
    return Object.keys(errors).flatMap((key) => toPaths(errors[key], key));
}

export type ValidatorResult<T = any> = Partial<Record<keyof T, any>>;
type Validator<T = any> = (
    objToValidate: T
) =>
    | undefined
    | string
    | Validator<T>
    | ValidatorResult<T>
    | (undefined | string | Validator | ValidatorResult<T>)[];

const joinValidators =
    <T>(validationFns: Validator<T>[]): Validator<T> =>
    (val: T) =>
        validationFns.map((validationFn) => validationFn(val)).find(Boolean);

// From validator for type T makes validator for type T[] which returns its errors in corresponding array
export const arrayItemValidator =
    (validators: Validator | Validator[]): Validator =>
    (vals) => {
        const itemValidator = isArray(validators) ? joinValidators(validators) : validators;
        const itemErrors = vals && isArray(vals) ? vals.map(itemValidator) : [];
        return itemErrors.filter(Boolean).length ? itemErrors : undefined;
    };

// Function which enables graphql style validator resolving - validator can return other validator functions
// in object array and they will be recursively resolved
export function withValidatorResolve<T>(validator: Validator<T> | Validator<T>[]): Validator<T> {
    return (value) => {
        // To be considered: next line allows auto joining of validators given in array, which saves some
        // typing in most cases, but can become a problem when you just want specific function per array
        // value.
        const validationResult = (isArray(validator) ? joinValidators(validator) : validator)(value);
        // eslint-disable-next-line no-use-before-define
        return resolveResult(value, validationResult);
    };
}

function resolveResult(value: any, validationResult: any) {
    if (isNil(validationResult)) {
        return undefined;
    }
    if (isString(validationResult)) {
        return validationResult;
    }
    if (isBoolean(validationResult) || isNumber(validationResult)) {
        return String(validationResult);
    }
    if (isArray(validationResult)) {
        if (validationResult.every(isFunction)) {
            return withValidatorResolve(validationResult)(value);
        }
        const resolved: any[] = validationResult.map((r, i) =>
            resolveResult(isArray(value) ? value[i] : undefined, r)
        );
        if (resolved.every(isNil)) return undefined;
        return resolved;
    }
    if (isFunction(validationResult)) {
        return withValidatorResolve(validationResult)(value);
    }
    if (isObjectLike) {
        const resolved: any = pickBy(
            mapValues(validationResult, (resultFieldValue, resultFieldKey) =>
                resolveResult(isObjectLike(value) ? value[resultFieldKey] : undefined, resultFieldValue)
            ),
            isDefined
        );

        return isEmpty(resolved) ? undefined : resolved;
    }

    throw new Error('What the hell did we get??!');
}

const creativeValidator = () => (creative: CreativeModel) => {
    switch (creative.type) {
        case 'GCM_TRACKER':
            return gcmTrackerValidator();
        case 'GCM_HOSTED':
            return gcmHostedCreativeValidator();
        case 'GCM_VIDEO':
            return gcmVideoCreativeValidator();
        default:
            return undefined;
    }
};

export const conceptValidator = (): ValidatorResult<ConceptModel> => ({
    name: required,
    creatives: arrayItemValidator(creativeValidator()),
});
