import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomActionRuleForm from 'platform/campaign/globalPreset/components/CustomActionRule/CustomActionRuleForm';
import { CustomActionRuleApiModel } from 'platform/campaign/globalPreset/globalPreset.types';
import {
    createCustomActionRule,
    fetchCustomActionRule,
    updateCustomActionRule,
} from 'platform/campaign/globalPreset/services/globaCustomActionRule.service';
import { Optional } from 'platform/common/common.types';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';

const CustomActionRuleFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);

    const handleOpen = (): Promise<Optional<CustomActionRuleApiModel, 'id'>> => {
        if (id) {
            return fetchCustomActionRule(id);
        }

        return Promise.resolve({
            name: '',
            advertiserId,
            ruleSet: {
                default: undefined,
                rules: [
                    {
                        dimension: undefined,
                        expression: { condition: undefined, value: '' },
                    },
                ],
            },
        });
    };

    const handleSubmit = (data: CustomActionRuleApiModel) =>
        id ? updateCustomActionRule(id, data) : createCustomActionRule(data);

    return (
        <FormContainer
            helpKey="custom_action_rule_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            isEdit={!!id}
            sidePanel
            size={SIDE_PANEL_SIZES.WIDE}
        >
            {(props) => <CustomActionRuleForm {...props} />}
        </FormContainer>
    );
};

export default CustomActionRuleFormContainer;
