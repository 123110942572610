import './CounterBadge.scss';

interface Props {
    count: number;
    className?: string;
}

const CounterBadge = ({ count, className }: Props) => (
    <div className={`CounterBadge bg-primary ${className}`}>{count <= 999 ? count : '∞'}</div>
);

export default CounterBadge;
