import classNames from 'classnames';
import PopoverDropdown, { PopoverDropdownItem } from '../PopoverDropdown/PopoverDropdown';
import './InlineDropDown.scss';

type Props = {
    items: PopoverDropdownItem[];
    iconClassName?: string;
    togglerClassName?: string;
};

const InlineDropdown = ({ items, iconClassName, togglerClassName }: Props) => (
    <PopoverDropdown
        toggler={<i className={iconClassName || 'fa fa-ellipsis-h'} />}
        togglerClassName={classNames('InlineDropdown', togglerClassName)}
        items={items}
    />
);

export default InlineDropdown;
