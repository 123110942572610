import { Button } from 'reactstrap';
import { Boundary, preventOverflow } from '@popperjs/core';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import { useChatAgentContext } from 'platform/chat/ChatProviders/ChatAgentProvider';
import { useChatLanguageContext } from 'platform/chat/ChatProviders/ChatLanguageProvider';
import { useChatMessageContext } from 'platform/chat/ChatProviders/ChatMessageProvider';
import { useChatPromptContext } from 'platform/chat/ChatProviders/ChatPromptProvider';
import { PersonalPrompt } from 'platform/chat/chat.types';
import PopoverDropdown from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { useFeature } from 'platform/common/hooks/useFeature';
import ChatPersonalPrompts from './ChatPersonalPrompts';
import ChatPromptCard from './ChatPromptCard';
import './ChatPromptsPopover.scss';

interface Props {
    chatBoundary: Boundary | null;
}

const ChatPromptsButton = ({ chatBoundary }: Props) => {
    const { handlePredefinedPromptClick } = useChatLanguageContext();
    const canViewPrompts = useFeature('CHATBOT_PROMPTS_VIEW');
    const { selectedAgent } = useChatAgentContext();
    const { prompts } = useChatPromptContext();
    const { submit } = useChatMessageContext();

    if (!canViewPrompts || (!prompts.length && !selectedAgent?.personalPrompts)) return null;

    return (
        <Tooltip renderTooltip={() => 'Prompts'} className="d-flex align-items-center">
            <PopoverDropdown
                className="d-flex align-items-center"
                modifiers={[
                    {
                        ...preventOverflow,
                        enabled: true,
                        options: {
                            boundary: chatBoundary,
                            altAxis: true,
                        },
                    } as PreventOverflowModifier,
                ]}
                popoverClassName="ChatPromptsPopover"
                togglerClassName="p-0 ms-2 d-flex align-items-center"
                placement="top"
                toggler={<i className="far fa-book-open-cover fs-5 text-light-slate-gray" />}
            >
                {({ toggle }) => (
                    <>
                        <ul className="m-0 p-2">
                            {prompts.map((p) => (
                                <li key={p.id} className="mb-2">
                                    <Button
                                        className="w-100 border-0 p-0 rounded"
                                        onClick={() => {
                                            handlePredefinedPromptClick(p);
                                            toggle();
                                        }}
                                    >
                                        <ChatPromptCard name={p.name} descriptionHtml={p.description} />
                                    </Button>
                                </li>
                            ))}

                            {selectedAgent?.personalPrompts && (
                                <ChatPersonalPrompts
                                    agentId={selectedAgent.id}
                                    onPromptClick={(prompt: PersonalPrompt) => {
                                        submit([
                                            {
                                                text: prompt.prompt,
                                                prompt: {
                                                    name: prompt.name,
                                                    id: prompt.id!,
                                                    type: 'PERSONAL',
                                                },
                                            },
                                        ]);
                                        toggle();
                                    }}
                                />
                            )}
                        </ul>
                    </>
                )}
            </PopoverDropdown>
        </Tooltip>
    );
};

export default ChatPromptsButton;
