import { useSelector } from 'react-redux';
import { groupBy } from 'lodash-es';
import { ReportComponentState } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import ReportComponentItem from 'platform/analytics/reportComponents/ReportComponentActions/ReportComponentItem';
import { REPORT_COMPONENTS } from 'platform/analytics/reportComponents/ReportComponentActions/reportComponents.constant';

interface Props {
    componentState?: Omit<ReportComponentState, 'id'>;
    onClick: (component: ReportComponentState) => void;
}

const ReportComponents = ({ componentState, onClick }: Props) => {
    const settings = useSelector(analyticsSelectors.settings);
    const componentsByGroup = groupBy(REPORT_COMPONENTS, (i) => i.group);

    const shouldDisplayComponent = (component: ReportComponentState) =>
        !(component.type === 'INTERACTION' && settings.mode !== 'INTERACTIVE');

    return (
        <div className="ReportComponents p-3">
            {Object.entries(componentsByGroup).map(([group, components]) => (
                <div key={group}>
                    <div className="text-muted mb-2">{group}</div>
                    <div>
                        {components.filter(shouldDisplayComponent).map((component) => (
                            <ReportComponentItem
                                key={component.name}
                                active={component.name === componentState?.name}
                                component={component}
                                onClick={onClick}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ReportComponents;
