import { useChatAgentContext } from './ChatProviders/ChatAgentProvider';
import { useChatMessageContext } from './ChatProviders/ChatMessageProvider';
import { useChatPromptContext } from './ChatProviders/ChatPromptProvider';

const useChat = () => {
    const { agentsLoading, selectedAgent, setSelectedAgent } = useChatAgentContext();
    const { messages } = useChatMessageContext();
    const { contextPrompts } = useChatPromptContext();

    return {
        agentsLoading,
        selectedAgent,
        messages,
        contextPrompts,
        setSelectedAgent,
    };
};

export default useChat;
