import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Button } from 'reactstrap';
import { push } from 'redux-first-history';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { isDefined } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import DefaultMetricsFormContainer from './DefaultMetricsFormContainer';
import { getDefaultMetrics } from './defaultMetrics.service';
import { DefaultMetrics } from './defaultMetrics.types';

export const PATH = '/advertiser-settings/default-metrics';

const DefaultMetricsList = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const dispatch = useDispatch();
    const canEdit = useFeature('ADVERTISER_SETTINGS_EDIT');
    const { definitions, reloadAnalyticsMetadata } = useAnalyticsMetadata();

    const [{ data: defaultMetrics, loading }, refetch] = usePromise(
        undefined,
        () => getDefaultMetrics(advertiserId),
        [advertiserId]
    );

    const handleEdit = () => dispatch(push(`${PATH}/edit?advertiserId=${advertiserId}`));

    const namesOf = (metrics: string[] | undefined) =>
        metrics?.map((key) => definitions.metrics.find((m) => m.key === key)?.name ?? key).join(', ');

    const columns: TableColumn<DefaultMetrics>[] = [
        {
            Header: 'Tables (all presets)',
            minWidth: 200,
            sortable: false,
            Cell: ({ original }) => <CellWithEllipsis value={namesOf(original.tableMetrics)} />,
        },
        {
            Header: 'Summary bars (first row)',
            minWidth: 200,
            sortable: false,
            Cell: ({ original }) => <CellWithEllipsis value={namesOf(original.summaryBarFirstRowMetrics)} />,
        },
        {
            Header: 'Summary bars (second row)',
            minWidth: 200,
            sortable: false,
            Cell: ({ original }) => <CellWithEllipsis value={namesOf(original.summaryBarSecondRowMetrics)} />,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            minWidth: 130,
            maxWidth: 200,
            sortable: false,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            maxWidth: 50,
            sortable: false,
            Cell: () => canEdit && <InlineDropdown items={[{ label: 'Edit', action: handleEdit }]} />,
        },
    ];

    return (
        <>
            <BodyContainer helpKey="default_metrics_list">
                {!canEdit && <CanNotEditWarning />}
                {!loading && !defaultMetrics && canEdit && (
                    <Button color="primary" size="md" className="me-3" onClick={handleEdit}>
                        Add default metrics
                    </Button>
                )}
                {(defaultMetrics || loading) && (
                    <FormattedTable
                        columns={columns}
                        data={[defaultMetrics].filter(isDefined)}
                        loading={loading}
                    />
                )}
            </BodyContainer>
            <Routes>
                <Route
                    path="edit"
                    element={
                        <DefaultMetricsFormContainer
                            redirectTo={PATH}
                            afterSubmit={() => {
                                refetch();
                                reloadAnalyticsMetadata(advertiserId);
                            }}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default DefaultMetricsList;
