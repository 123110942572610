import { List } from 'reactstrap';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SectionSeparator from 'platform/common/components/SectionSeparator/SectionSeparator';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatDateTime } from 'platform/common/utils/date.util';
import { User } from 'platform/userManagement/types/user.type';
import { MESSAGE_CENTER_STATUS_LABELS } from './observerMessageCenter.constants';
import { getObserverMessageChanges } from './observerMessageCenter.service';
import { ObserverMessageChangeDetails } from './observerMessageCenter.types';

const getDetailsText = (
    { assignees, status, note }: ObserverMessageChangeDetails,
    assigneeOptions: User[]
) => {
    if (assignees) {
        return `Assignees changed to ${assignees
            .map((a) => assigneeOptions.find((o) => o.id === Number(a))?.name)
            .join(', ')}`;
    }

    if (status) {
        return `Status changed to ${MESSAGE_CENTER_STATUS_LABELS[status]}`;
    }

    if (note) {
        return `Note added: ${note}`;
    }

    return '';
};

interface Props {
    id?: number;
    assigneeOptions: User[];
}

const MessageHistory = ({ id, assigneeOptions }: Props) => {
    const [{ loading, data }] = usePromise(
        [],
        () => (id ? getObserverMessageChanges(id) : Promise.resolve([])),
        [id]
    );

    if (loading) return <OverlayLoader />;

    return (
        <List type="inline">
            {data.map((item, i) => (
                <li key={i} className="mb-4 d-flex">
                    <SectionSeparator iconClassName="fa-solid fa-clock-rotate-left fs-5 mt-1" />
                    <div>
                        <div className="mb-1 fs-5">{getDetailsText(item.details, assigneeOptions)}</div>
                        <div className="text-light-slate-gray">by {item.updatedBy}</div>
                        <div>{formatDateTime(item.updatedOn)}</div>
                    </div>
                </li>
            ))}
        </List>
    );
};

export default MessageHistory;
