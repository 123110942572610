import VendorList from './components/VendorList';
import { VENDORS } from './vendors.navigation';

const vendorsRoutes = [
    {
        ...VENDORS,
        component: VendorList,
    },
];

export default vendorsRoutes;
