import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompatibleColumns } from 'platform/analytics/analytics.service';
import {
    AnalyticsMetadata,
    ChartState,
    ColumnDefinitionOption,
    Template,
} from 'platform/analytics/analytics.types';
import { isOlapReport } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { getCompatibleOptions } from 'platform/analytics/hooks/useCompatibleColumns';
import CustomFilterIndicator from 'platform/analytics/reportComponents/CustomFilterIndicator';
import { isDefined } from 'platform/common/common.types';
import InlineSelectTree from 'platform/common/components/InlineSelectTree/InlineSelectTree';

const getTitleOverride = (
    metric: String,
    dimension: String,
    { definitions: { metrics, dimensions } }: AnalyticsMetadata
) => {
    const metricName = metrics.find((m) => m.key === metric)?.name;
    const dimensionName = dimensions.find((d) => d.key === dimension)?.name;

    return metricName && dimensionName && `${metricName} by ${dimensionName}`;
};

interface Props {
    title?: string;
    template: Template;
    componentState: ChartState;
    children: ReactNode;
}

const ReportChartTitle = ({ title, componentState, template, children }: Props) => {
    const [state, setState] = useState<{ loading: boolean; options: ColumnDefinitionOption[] }>({
        loading: false,
        options: [],
    });
    const { customFilters, metrics, dimensions } = componentState;
    const meta = useSelector(analyticsSelectors.meta);
    const filters = useSelector(analyticsSelectors.filtersWithAdvertiser);
    const columns = [...metrics, ...dimensions].filter(isDefined);
    const dispatch = useDispatch();
    const changeComponentState = (newState: ChartState) =>
        dispatch(analyticsSettingsActions.changeComponentState(newState));

    const metric = metrics.length === 1 ? metrics[0] : undefined;

    const loadOptions = async () => {
        setState({ ...state, loading: true });
        const compatibleColumns = isOlapReport(template)
            ? await getCompatibleColumns(template.id, columns, filters)
            : [];

        setState({
            loading: false,
            options: getCompatibleOptions(
                template.metrics,
                meta.columnGroups,
                compatibleColumns,
                columns,
                isOlapReport(template)
            ),
        });
    };

    return (
        <div className="d-flex px-3 pt-3 align-items-center">
            <div className="d-flex flex-grow-1">
                {!!customFilters?.length && <CustomFilterIndicator filters={customFilters} />}
                <div className="text-break fw-bold font-sm ms-2">{title}</div>
                {metric && (
                    <InlineSelectTree
                        value={metric}
                        options={state.options}
                        loading={state.loading}
                        onClick={loadOptions}
                        renderTooltip={() => 'Change metric'}
                        onChange={(value) => {
                            changeComponentState({
                                ...componentState,
                                metrics: [value],
                                title: getTitleOverride(value, componentState.dimensions[0], meta),
                            });
                        }}
                    />
                )}
            </div>
            {children}
        </div>
    );
};

export default ReportChartTitle;
