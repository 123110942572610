import LineChart from '../LineChart/LineChart';
import Statistic from '../Statistic/Statistic';

enum ValueTypes {
    NUMBER = 'NUMBER',
    PERCENT = 'PERCENT',
}

interface Props {
    name: string;
    totalValue?: string;
    type?: ValueTypes;
    labels: string[];
    values: number[];
    height?: number;
    className?: string;
}

const formatTotalValue = (totalValue: string | undefined, type: ValueTypes) => {
    let val = totalValue;
    if (type === ValueTypes.PERCENT && val) {
        val = `${val}%`;
    }
    return val;
};

const StatisticChart = ({
    className,
    name = '',
    labels = [],
    values = [],
    height = 70,
    totalValue,
    type = ValueTypes.NUMBER,
}: Props) => (
    <div className={className}>
        <Statistic name={name} value={formatTotalValue(totalValue, type)} />
        {values && values.length ? <LineChart labels={labels} values={values} height={height} /> : null}
    </div>
);

export default StatisticChart;
