import { MailJobConfiguration } from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

const DataIngestionConfigurationForm = ({
    labels,
    formikProps: { submitForm, initialValues },
    onCancel,
}: FormProps<MailJobConfiguration>) => (
    <CardForm
        title={'Edit Configuration'}
        submitLabel={labels.submit}
        animationDelay={0}
        onCancel={onCancel}
        onSubmit={submitForm}
    >
        <ControlledCard title="General info">
            {!initialValues.jobName && (
                <FormRow label="Job name">
                    <FormInput name="jobName" type="text" validate={required} />
                </FormRow>
            )}
            <FormRow label="Date column">
                <FormInput name="dateColumn" type="text" />
            </FormRow>
            <FormRow label="Move date partition to column">
                <FormInput name="datePartitionToColumn" type="text" />
            </FormRow>
            <FormRow label="Partition column">
                <FormInput name="partitionColumn" type="text" />
            </FormRow>
            <FormRow label="Header lines to skip">
                <FormInput name="headerLinesToSkip" type="number" />
            </FormRow>
            <FormRow label="Footer lines to skip">
                <FormInput name="footerLinesToSkip" type="number" />
            </FormRow>
            <FormRow label="Leading columns to skip">
                <FormInput name="leadingColumnsToSkip" type="number" />
            </FormRow>
            <FormRow label="Trailing columns to skip">
                <FormInput name="trailingColumnsToSkip" type="number" />
            </FormRow>
            <FormRow label="Date format">
                <FormInput name="dateFormat" type="text" />
            </FormRow>
            <FormRow label="Column delimiter">
                <FormInput name="columnDelimiter" type="text" />
            </FormRow>
            <FormRow label="Url regex">
                <FormInput name="urlRegex" type="text" />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default DataIngestionConfigurationForm;
