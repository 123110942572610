import { useQuery } from '@apollo/client';
import { ChartOptions } from 'chart.js';
import moment from 'moment';
import { StatisticWithSeries, TypeDefinition } from 'platform/common/common.types';
import LinePure from 'platform/common/components/LinePure/LinePure';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { shouldShowFraction, shouldShowMetricPrefix } from 'platform/common/utils/chart.utils';
import { CHART_COLORS } from 'platform/common/utils/color.util';
import segmentOverviewChart from 'platform/segments/queries/segmentOverviewChart.graphql';

type QueryResult = {
    segment: {
        primaryStats: {
            adh_segment_unique_users_yesterday: StatisticWithSeries;
        };
        secondaryStats: {
            adh_segment_unique_users_yesterday: StatisticWithSeries;
        };
    };
};

type Props = {
    segmentId: number;
};

const generateChartOptions = (
    type: TypeDefinition,
    useMetricPrefix: boolean,
    showFraction: boolean
): ChartOptions<'line'> => ({
    animation: undefined,
    hover: { intersect: false },
    maintainAspectRatio: false,
    elements: {
        point: { radius: 0, hoverRadius: 4 },
    },
    plugins: {
        tooltip: {
            intersect: false,
            mode: 'index',
            callbacks: {
                title: (tooltipItems: any) =>
                    tooltipItems?.[0].dataset?.labels?.[tooltipItems[0].dataIndex] || '',
            },
        },
    },
    layout: {
        padding: {
            left: 16,
            right: 24,
            top: 24,
            bottom: 24,
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                displayFormats: {
                    hour: 'MMM D',
                },
            },
        },

        y: {
            min: 0,
            ticks: {
                callback: (label: any) => type.format(label, { useMetricPrefix, showFraction }),
            },
        },
    },
});

const SegmentOverviewChart = ({ segmentId }: Props) => {
    const { data, loading } = useQuery<QueryResult>(segmentOverviewChart, {
        variables: {
            id: segmentId,
            primaryPeriod: {
                from: moment()
                    .subtract(30 - 1, 'd')
                    .format(ISO_DATE_FORMAT),
            },
            secondaryPeriod: {
                from: moment()
                    .subtract(60 - 1, 'd')
                    .format(ISO_DATE_FORMAT),
                to: moment().subtract(30, 'd').format(ISO_DATE_FORMAT),
            },
        },
    });

    if (loading) {
        return (
            <div>
                <div className="mb-3 fw-bold">Users by day</div>
                <Placeholder height="300px" />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const primarySeries = data.segment.primaryStats.adh_segment_unique_users_yesterday.series;
    const secondarySeries = data.segment.secondaryStats.adh_segment_unique_users_yesterday.series;

    const chartData = {
        labels: primarySeries.labels,
        datasets: [
            {
                data: primarySeries.values,
                label: 'Users',
                labels: primarySeries.labels,
                fill: false,
                backgroundColor: CHART_COLORS[0],
                borderColor: CHART_COLORS[0],
                borderWidth: 2,
            },
            {
                data: secondarySeries.values,
                label: 'Previous period',
                labels: secondarySeries.labels,
                fill: false,
                borderColor: CHART_COLORS[0],
                backgroundColor: 'transparent',
                borderWidth: 1,
                borderDash: [10, 10],
            },
        ],
    };

    return (
        <div>
            <div className="mb-3 fw-bold">Trend of Users</div>
            <div>
                <LinePure
                    data={chartData}
                    height={300}
                    options={generateChartOptions(
                        DATA_TYPES.INT,
                        shouldShowMetricPrefix(chartData.datasets),
                        shouldShowFraction(chartData.datasets)
                    )}
                />
            </div>
        </div>
    );
};

export default SegmentOverviewChart;
