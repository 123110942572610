import { useQuery } from '@apollo/client';
import { Category } from 'platform/category/category.types';
import { getCategoryNames } from 'platform/category/category.util';
import Pie from 'platform/common/components/Charts/Pie';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import Statistic from 'platform/common/components/Statistic/Statistic';
import Row from 'platform/common/components/SummaryBar/Row';
import SummaryBar from 'platform/common/components/SummaryBar/SummaryBar';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { getPeriodForLast7Days } from 'platform/common/utils/date.util';
import ChartWithSeries from 'platform/segments/components/SegmentSummary/ChartWithSeries';
import SegmentSummaryQuery from 'platform/segments/components/SegmentSummary/SegmentSummary.graphql';
import SegmentSummaryMeta from 'platform/segments/components/SegmentSummary/SegmentSummaryMeta';
import SegmentAvailabilityIndicator from '../SegmentAvailabilityIndicator/SegmentAvailabilityIndicator';
import SegmentTypeBadge from '../SegmentTypeBadge/SegmentTypeBadge';
import styles from './SegmentSummary.scss';

interface Props {
    id: number;
    categories: Category[];
}

const SegmentSummary = ({ id, categories }: Props) => {
    const { data, loading } = useQuery(SegmentSummaryQuery, {
        variables: { id, statsPeriod: getPeriodForLast7Days() },
    });

    if (loading) {
        return <Placeholder height="190px" />;
    }

    if (!data) {
        return null;
    }

    const { availability, type, classificationCodes, advertiser, stats } = data.segment;

    return (
        <SummaryBar columns={5}>
            <Row>
                <>
                    <div className="text-muted mb-2">
                        <SegmentAvailabilityIndicator
                            availability={availability}
                            className="SegmentSummary-availabilityIcon me-2"
                        />
                        <SegmentTypeBadge type={type} />
                    </div>
                    <SegmentSummaryMeta label="Advertiser" value={advertiser.name} />
                    <SegmentSummaryMeta label="ID" value={id} />
                    <SegmentSummaryMeta
                        label="Section"
                        value={getCategoryNames(classificationCodes, categories, 'SS')}
                    />
                </>
                <ChartWithSeries
                    label="Users, yesterday"
                    type={DATA_TYPES.INT}
                    stats={stats.adh_segment_unique_users_yesterday}
                />
                <ChartWithSeries
                    label="All actions, 30d"
                    type={DATA_TYPES.INT}
                    stats={stats.adh_segment_unique_purchases_30d}
                />
                <ChartWithSeries
                    label="Action probability, 30d"
                    type={DATA_TYPES.P100}
                    stats={stats.adh_segment_purchase_probability_30d}
                />
                <div>
                    <Statistic
                        name="Share in actions, 30d"
                        value={DATA_TYPES.P100.format(stats.adh_segment_share_in_purchase_30d.value)}
                    />
                    <Pie
                        height={70}
                        data={{
                            datasets: [
                                {
                                    backgroundColor: [styles.highlight, styles.inactive],
                                    data: [
                                        DATA_TYPES.P100.parse(stats.adh_segment_share_in_purchase_30d.value),
                                        DATA_TYPES.P100.parse(
                                            100 - stats.adh_segment_share_in_purchase_30d.value
                                        ),
                                    ],
                                },
                            ],
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                tooltip: {
                                    enabled: false,
                                },
                            },
                            elements: {
                                arc: {
                                    borderWidth: 0,
                                },
                            },
                        }}
                    />
                </div>
            </Row>
        </SummaryBar>
    );
};

export default SegmentSummary;
