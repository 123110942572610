import { Store } from 'redux';
import { ApolloClient, ApolloQueryResult, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'cross-fetch';
import { debounce, get } from 'lodash-es';
import { Action } from 'platform/common/common.types';
import { ErrorDetails, RequestDetails } from 'platform/common/error.types';
import { deleteTypename } from 'platform/common/utils/graphql.util';
import { retryDelay, isTransientError } from 'platform/common/utils/http.util';
import { authActions } from '../../app/ducks/auth.duck';
import localStorage from './localStorage.util';
import { toastError } from './toast.util';
import { generateTraceHeaders } from './trace.util';

let store: Store<any, any>;

type GraphqlError = {
    message: string;
    status: number;
    details?: {
        error: string;
        failedRequest?: RequestDetails;
    };
    traceId?: string;
};

export const setStoreToApolloClient = (globalStore: Store<any, Action>) => {
    store = globalStore;
};

const debounceUserUnauthorized = debounce(() => {
    store.dispatch(authActions.userUnauthorized('Graphql returned 401'));
}, 0);

const authMiddleware = setContext((request, context) => {
    const token = get(localStorage.get('session'), 'token');
    return {
        ...context,
        headers: {
            ...context.headers,
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
    };
});

const traceMiddleware = setContext((request, context) => ({
    ...context,
    headers: {
        ...context.headers,
        ...generateTraceHeaders(),
    },
}));

const pageUrlMiddleware = setContext((request, context) => ({
    ...context,
    pageUrl: window.location.href,
}));

const errorHandlerLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { pageUrl, response, errorHandledByComponent } = operation.getContext();
    const traceId = response?.headers.get('X-B3-TraceId');

    if (graphQLErrors) {
        // eslint-disable-next-line no-console
        console.error('[GraphQL error]', graphQLErrors);
        const userUnauthorized = graphQLErrors.some((error) => (error as any).status === 401);
        if (userUnauthorized) {
            debounceUserUnauthorized();
            return;
        }
    }

    const graphQLError = graphQLErrors?.[0] as unknown as GraphqlError | undefined;

    if (!errorHandledByComponent) {
        toastError(
            {
                message: graphQLError?.message || 'Unexpected error',
                traceId,
                detailedMessage: graphQLError?.details?.error,
                request: graphQLError?.details?.failedRequest,
                response,
            },
            pageUrl
        );
    } else {
        // There is no way to pass traceId to component not mutating error
        graphQLErrors?.forEach((e) => {
            // @ts-ignore
            e.traceId = traceId;
        });
    }

    if (networkError) {
        // TODO: Move error logging to GraphQL
        // eslint-disable-next-line no-console
        console.error(`[GraphQL network error]: ${networkError}`);
    }
});

const retryLink = new RetryLink({
    attempts: {
        retryIf: (error, { operationName }) => {
            const willRetry = !!error?.statusCode && isTransientError(error.statusCode);
            if (willRetry) {
                // eslint-disable-next-line no-console
                console.warn(`Will retry GraphQL operation ${operationName} because "${error?.message}"...`);
            }
            return willRetry;
        },
        max: 5, // give up after fifth failed request
    },
    delay: retryDelay,
});

const httpLink = new HttpLink({ uri: '/bff/graphql/api/graphql', fetch });

const client = new ApolloClient({
    link: authMiddleware
        .concat(traceMiddleware)
        .concat(pageUrlMiddleware)
        .concat(errorHandlerLink)
        .concat(retryLink)
        .concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
        query: {
            errorPolicy: 'all',
        },
    },
});

const originalQuery = client.query;
client.query = (params) => originalQuery(params).then((res) => deleteTypename(res));

const graphQLErrorToErrorDetails = (graphQLErrors: GraphqlError[]): ErrorDetails => {
    const message = graphQLErrors?.[0]?.message || 'Unknown error';
    const graphqlError = graphQLErrors?.[0];
    return {
        message,
        traceId: graphqlError?.traceId,
        detailedMessage: graphqlError?.details?.error,
        request: graphqlError?.details?.failedRequest,
        response: { status: graphqlError?.status },
    };
};

// By default client.query doesn't reject on error, it just returns response with error,
// but sometimes we need to expose graphql errors as exception for component to handle them,
// otherwise it comes along with partial data and promise is not rejected
export const throwOnError = <T>(response: ApolloQueryResult<T>): ApolloQueryResult<T> => {
    if (response.errors) {
        throw graphQLErrorToErrorDetails(response.errors as unknown as GraphqlError[]);
    }
    return response;
};

export default client;
