import RemovableActionRuleConditions from 'platform/campaign/globalPreset/components/CustomActionRule/RemovableActionRuleConditions';
import { customDimensionOptions } from 'platform/campaign/globalPreset/constants/customActionRule.constant';
import { CustomActionRule } from 'platform/campaign/globalPreset/globalPreset.types';
import { required } from 'platform/common/utils/validators.util';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

interface Props {
    rule: CustomActionRule;
    field: string;
    parentIndex: number;
    onRuleConditionRemove: (parentIndex: number) => void;
}

const RemovableActionRule = ({ rule, field, parentIndex, onRuleConditionRemove }: Props) => (
    <div className="row">
        <div className="col-md-3 p-2">
            <FormSelect
                name={`${field}.dimension`}
                options={customDimensionOptions}
                isClearable={false}
                validate={required}
            />
        </div>
        <div className="col-md-9">
            <RemovableActionRuleConditions
                field={`${field}.expression.and`}
                expressions={rule.expression.and}
                parentIndex={parentIndex}
                onRuleConditionRemove={onRuleConditionRemove}
            />
        </div>
    </div>
);

export default RemovableActionRule;
