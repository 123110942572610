import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';

interface LinePureProps {
    data: ChartData<'line'>;
    options?: ChartOptions<'line'>;
    height?: number;
}

const LinePure = ({ data, options, height }: LinePureProps) => (
    <Line data={data} options={options} height={height} />
);

export default LinePure;
