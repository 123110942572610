import { useParams } from 'react-router-dom';
import { Badge } from 'reactstrap';
import {
    ALGORITHM_AREA_LABELS,
    ALGORITHM_SECTION_LABELS,
} from 'platform/algorithms/algorithmManager.constant';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import Statistic from 'platform/common/components/Statistic/Statistic';
import Row from 'platform/common/components/SummaryBar/Row';
import SummaryBar from 'platform/common/components/SummaryBar/SummaryBar';
import { formatPreciseDateTime } from 'platform/common/utils/date.util';
import { useAlgorithmLogDetails } from './useAlgorithmLogDetails';

const AlgorithmLogDetails = () => {
    const { id } = useParams<{ id: string }>();

    const { section, area, algorithmTemplateName, timestamp, status, loading, output } =
        useAlgorithmLogDetails(id!);

    return (
        <>
            {loading && <OverlayLoader />}
            <HeaderContainer>
                <PageHeader title="Algorithm - Log Details" />
            </HeaderContainer>
            <BodyContainer helpKey="algorithm_log_details">
                <InsetBox className="mb-2">
                    <SummaryBar columns={5}>
                        <Row>
                            <Statistic
                                name="Section"
                                value={section ? ALGORITHM_SECTION_LABELS[section] : undefined}
                                isLoading={loading}
                            />
                            <Statistic
                                name="Area"
                                value={area ? ALGORITHM_AREA_LABELS[area] : ''}
                                isLoading={loading}
                            />
                            <Statistic
                                name="Algorithm Template Name"
                                value={algorithmTemplateName}
                                isLoading={loading}
                            />
                            <Statistic name="Status" isLoading={loading}>
                                {status === 'SUCCESS' && <Badge color="success">SUCCESSFUL</Badge>}
                                {status === 'ERROR' && <Badge color="danger">FAILED</Badge>}
                            </Statistic>
                            <Statistic
                                name="Time"
                                value={formatPreciseDateTime(timestamp, 'Europe/Berlin')}
                                isLoading={loading}
                            />
                        </Row>
                    </SummaryBar>
                </InsetBox>
                {output && (
                    <InsetBox>
                        <pre className="p-3 mb-0">{output}</pre>
                    </InsetBox>
                )}
            </BodyContainer>
        </>
    );
};

export default AlgorithmLogDetails;
