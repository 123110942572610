import { isNumber, sortBy } from 'lodash-es';
import { Periods } from '../ducks/dateFilter.duck';
import { getDuration } from './date.util';

export const shouldShowMetricPrefix = (datasets: { data: number[] }[], THRESHOLD = 5000) => {
    const dataValues = datasets.flatMap((d) => d.data);
    const maxValue = Math.max(...dataValues);
    const minValue = Math.min(...dataValues);
    if (!isNumber(maxValue) || !isNumber(minValue)) return false;

    return maxValue - minValue > THRESHOLD;
};

export const shouldShowFraction = (datasets: { data: number[] }[], THRESHOLD = 5) => {
    const dataValues = datasets.flatMap((d) => d.data);
    const maxValue = Math.max(...dataValues);
    const minValue = Math.min(...dataValues);
    if (!isNumber(maxValue) || !isNumber(minValue)) return false;

    return maxValue - minValue < THRESHOLD;
};

export const sortByOrder = <T extends { order: number }>(prompts: T[]) => sortBy(prompts, 'order');

export const showLineDataLabels = (showValues: boolean = false, periods: Periods) => {
    if (showValues && getDuration(periods.primaryFrom, periods.primaryTo).asDays() > 30) {
        return 'auto';
    }
    return showValues;
};
