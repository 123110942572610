import { ComponentType, useMemo } from 'react';
import ChatAssistantUserFriendlyErrorMessage from 'platform/chat/ChatMessage/ChatAssistantMessage/ChatAssistantUserFriendlyErrorMessage/ChatAssistantUserFriendlyErrorMessage';
import resolveMessageType from 'platform/chat/ChatMessage/ChatAssistantMessage/messageTypeResolver';
import { ChatOlapRequest } from 'platform/chat/olap/chat.olap.types';
import { ChatAssistantMessageType, DeeplyChatError } from '../../chat.types';
import ChatAssistantDefaultMessage from './ChatAssistantDefaultMessage/ChatAssistantDefaultMessage';
import ChatAssistantError from './ChatAssistantError';
import ChatAssistantOlapMessage from './ChatAssistantOlapMessage/ChatAssistantOlapMessage';
import ChatAssistantQuestionsMessage, {
    Question,
} from './ChatAssistantQuestionsMessage/ChatAssistantQuestionsMessage';

interface ChatComponentProps {
    content: string | Question[] | ChatOlapRequest;
}

const TYPE_COMPONENTS: Record<ChatAssistantMessageType, ComponentType<ChatComponentProps>> = {
    DEFAULT: ChatAssistantDefaultMessage,
    QUESTIONS: ChatAssistantQuestionsMessage,
    OLAP_REQUEST: ChatAssistantOlapMessage,
    DISPLAY_ISSUE: ChatAssistantUserFriendlyErrorMessage,
};

interface Props {
    content: string;
    error?: DeeplyChatError;
}

const ChatAssistantMessage = ({ content, error }: Props) => {
    const resolvedData = useMemo(() => resolveMessageType(content), [content]);

    if (error) {
        return <ChatAssistantError error={error} />;
    }

    const MessageComponent = TYPE_COMPONENTS[resolvedData.type];

    return <MessageComponent content={resolvedData.content} />;
};

export default ChatAssistantMessage;
