import { Col, Input, Row } from 'reactstrap';
import { AdvertiserTagFormEvent } from '../../advertiserTag.types';

interface Props {
    event: AdvertiserTagFormEvent;
    onEventPropertyChange: (advertiserTagVarName: string, property: string) => any;
}

const EventTrackingListRow = ({ event, onEventPropertyChange }: Props) => {
    const toggleSelected = () => {
        onEventPropertyChange(event.advertiserTagVarName, 'selected');
    };

    const toggleConvertible = () => {
        onEventPropertyChange(event.advertiserTagVarName, 'convertible');
    };

    const toggleProductData = () => {
        onEventPropertyChange(event.advertiserTagVarName, 'productData');
    };

    const togglePurchaseData = () => {
        onEventPropertyChange(event.advertiserTagVarName, 'purchaseData');
    };

    return (
        <Row className="EventTrackingList-row">
            <Col md={1}>
                <Input
                    type="checkbox"
                    checked={event.selected}
                    disabled={event.disabled}
                    onChange={toggleSelected}
                />
            </Col>
            <Col md={5}>
                <span>{event.name}</span>{' '}
                {event.type === 'CUSTOM_EVENT' && <span>{`(${event.advertiserTagVarName})`}</span>}
            </Col>
            <Col md={2} className="text-center">
                {event.editable.convertible && (
                    <Input
                        type="checkbox"
                        checked={event.convertible}
                        disabled={!event.selected}
                        onChange={toggleConvertible}
                    />
                )}
            </Col>
            <Col md={2} className="text-center">
                {event.editable.productData && (
                    <Input
                        type="checkbox"
                        checked={event.productData}
                        disabled={!event.selected}
                        onChange={toggleProductData}
                    />
                )}
            </Col>
            <Col md={2} className="text-center">
                {event.editable.purchaseData && (
                    <Input
                        type="checkbox"
                        checked={event.purchaseData}
                        disabled={!event.selected}
                        onChange={togglePurchaseData}
                    />
                )}
            </Col>
        </Row>
    );
};

export default EventTrackingListRow;
