import { capitalize } from 'lodash-es';

const IMAGE = 'IMAGE';
const HTML5 = 'HTML5';

export type CreativeType = 'GCM_TRACKER' | 'GCM_HOSTED' | 'GCM_VIDEO' | 'FACEBOOK' | 'GOOGLE' | 'BING';

export const CREATIVE_TYPES_LABELS: { [key in CreativeType]: string } = Object.freeze({
    GCM_TRACKER: 'Tracker',
    GCM_HOSTED: 'Hosted',
    GCM_VIDEO: 'In-stream video',
    FACEBOOK: 'Facebook',
    GOOGLE: 'Google',
    BING: 'Bing',
});

/**
 * Overridden labels for some of external ad types, for which auto-formatting isn't good enough.
 */
const EXTERNAL_TYPE_LABEL_OVERRIDES: { [key: string]: string } = Object.freeze({
    AppInstall: 'App install ad',
    DynamicSearch: 'Dynamic search ad',
    ExpandedText: 'Expanded text ad',
    Image: 'Image ad',
    Product: 'Product ad',
    ResponsiveAd: 'Responsive ad',
    ResponsiveSearch: 'Responsive search ad',
    Text: 'Text ad',
    HTML5_UPLOAD_AD: 'HTML5 display upload ad',
    DYNAMIC_HTML5_AD: 'Dynamic display upload ad',
    VIDEO_NON_SKIPPABLE_IN_STREAM_AD: 'Video non-skippable in-stream ad',
    VIDEO_OUTSTREAM_AD: 'Video out-stream ad',
    VIDEO_TRUEVIEW_DISCOVERY_AD: 'Video TrueView in-display ad',
    VIDEO_TRUEVIEW_IN_STREAM_AD: 'Video TrueView in-stream ad',
});

export const getCreativeExternalTypeName = (externalType?: string) => {
    if (!externalType) {
        return '(unknown)';
    }
    return EXTERNAL_TYPE_LABEL_OVERRIDES[externalType] ?? capitalize(externalType.replace(/_/g, ' '));
};

export const getCreativeTypeName = ({ type, externalType }: { type: CreativeType; externalType?: string }) =>
    externalType ? getCreativeExternalTypeName(externalType) : CREATIVE_TYPES_LABELS[type] ?? type;

export const HOSTED_TYPES = Object.freeze({
    IMAGE,
    HTML5,
});

export const TRACKER_CREATIVE_FORMAT = '1x1';

export const LANDING_PAGE_MACROS = [
    { key: '{WIDTH}', label: 'width of banner' },
    { key: '{HEIGHT}', label: 'height of banner' },
    { key: '{VENDOR_NAME}', label: 'name of Vendor as set in Vendor management' },
];
