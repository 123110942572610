import AlgorithmSettingsValueEdit from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettingsValueEdit';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { DataTypes } from 'platform/common/components/InlineEditInput/InlineEditInput';
import { DATA_TYPES } from 'platform/common/dataTypes';

export type AlgorithmSettingsValue = string | number | boolean | string[];

interface Setting {
    key: string;
    value: AlgorithmSettingsValue;
}

const settingsToColumns = (settings: Record<string, AlgorithmSettingsValue>) =>
    Object.entries(settings).map((entry) => ({
        key: entry[0],
        value: entry[1],
    }));

const parseValue = (value: string, dataType?: DataTypes) => {
    if (!dataType) {
        return value;
    }

    switch (dataType) {
        case DATA_TYPES.FLOAT:
        case DATA_TYPES.INT:
            return Number(value);
        case DATA_TYPES.BOOLEAN:
            return value === 'true';
        case DATA_TYPES.ARRAY_OF_STRINGS:
            return value.split(',').map((v) => v.trim());
        default:
            return value;
    }
};

interface Props {
    settings: Record<string, AlgorithmSettingsValue>;
    onChange: (settings: Record<string, string>) => void;
}

const AlgorithmSettingsTable = ({ settings, onChange }: Props) => {
    const data = settingsToColumns(settings);

    const handleChange = (key: string, value: string, dataType?: DataTypes) => {
        onChange(
            data.reduce(
                (acc, item) =>
                    item.key === key
                        ? { ...acc, [key]: parseValue(value, dataType) }
                        : {
                              ...acc,
                              [item.key]: item.value,
                          },
                {}
            )
        );
    };

    const columns: TableColumn<Setting>[] = [
        {
            Header: 'Key',
            accessor: 'key',
        },
        {
            Header: 'Value',
            accessor: 'value',
            Cell: ({ original: { value, key } }) => (
                <AlgorithmSettingsValueEdit valueKey={key} value={value} onChange={handleChange} />
            ),
        },
    ];

    return (
        <FormattedTable
            containerClass="overFlowVisible"
            data={data}
            columns={columns}
            showPagination={false}
            stickyHeader={false}
            NoDataComponent={() => (
                <div className="p-3 d-flex align-items-center justify-content-center">No columns</div>
            )}
        />
    );
};

export default AlgorithmSettingsTable;
