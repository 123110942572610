import FolderAccess from 'platform/analyticsReports/FolderAccess/FolderAccess';
import { CUSTOM_FOLDER_ACCESS, DASHBOARD_FOLDER_ACCESS } from './folderAccess.navigation';

const folderAccessRoutes = [
    {
        ...DASHBOARD_FOLDER_ACCESS,
        component: () => <FolderAccess section="CUSTOM_DASHBOARD" />,
    },
    {
        ...CUSTOM_FOLDER_ACCESS,
        component: () => <FolderAccess section="CUSTOM_ANALYTICS" />,
    },
];

export default folderAccessRoutes;
