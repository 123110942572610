import { Badge } from 'reactstrap';
import classNames from 'classnames';
import './IconBadge.scss';

interface Props {
    icon: React.ReactNode;
    text: string;
    className?: string;
}

const IconBadge = ({ text, icon, className }: Props) => (
    <Badge className={classNames('IconBadge', className)}>
        {icon}
        <span className="ms-1">{text}</span>
    </Badge>
);

export default IconBadge;
