import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { defaultTo } from 'lodash-es';
import Placeholder from '../Placeholder/Placeholder';

type Props = {
    loading?: boolean;
    data: ChartData<'bar'>;
    options?: ChartOptions<'bar'>;
    height?: number;
};

const BarWrapper = (props: Props) => {
    if (props.loading) {
        const height = `${defaultTo(props.height, 256)}px`;
        return <Placeholder height={height} />;
    }

    return <Bar {...props} />;
};

export default BarWrapper;
