import { NavigationItem } from 'platform/app/app.types';
import { ReactComponent as DASHBOARD } from 'platform/assets/icons/DASHBOARD.svg';

export const CUSTOMER_JOURNEY_CM360: NavigationItem = {
    name: 'Customer journey for CM360',
    path: '/customer-journey-pro',
    requiresFeature: 'CUSTOMER_JOURNEY_VIEW',
};

export const AUDIENCES: NavigationItem = {
    name: 'Audiences',
    path: '/audiences',
    icon: DASHBOARD,
    requiresFeature: 'SEGMENTS_VIEW',
};
