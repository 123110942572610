import { ReactNode, useEffect, useState } from 'react';
import { Button, Input, Label, Modal, ModalBody } from 'reactstrap';
import classNames from 'classnames';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { NavigationItem, Section, SECTION_LABELS } from 'platform/app/app.types';
import { ADMIN_ITEMS, getDefaultItems } from 'platform/app/components/Sidebar/useNavigationItems';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import ListGroupHeader from 'platform/common/components/ListGroupHeader/ListGroupHeader';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { fetchAnalyticsReport } from './analyticsReport.service';

interface Props {
    reports: AnalyticsReport[];
    title: string;
    children?: ReactNode;
    section?: Section;
    seatId: number;
    disabled?: boolean;
    loading?: boolean;
    toggle: () => void;
    onOk?: (reports: AnalyticsReport[]) => Promise<void>;
}

type ReportCopy = AnalyticsReport & { selected: boolean };

const mapReports = (reports: AnalyticsReport[]): ReportCopy[] =>
    reports.map((r) => ({ ...r, selected: false }));

const groupReports = (reports: ReportCopy[], navigationItems: NavigationItem[]) =>
    navigationItems
        .map(({ section, icon }) => ({
            label: SECTION_LABELS[section!],
            icon,
            reports: reports.filter((r) => r.section === section),
        }))
        .filter((r) => !!r.reports.length);

const ReportCopyModal = ({
    reports,
    title,
    children,
    section,
    seatId,
    disabled,
    loading,
    toggle,
    onOk,
}: Props) => {
    const [reportCopies, setReportCopies] = useState<ReportCopy[]>([]);

    const navigationItems = [...getDefaultItems(reports), ...ADMIN_ITEMS].filter((i) => !!i.section);

    useEffect(() => {
        setReportCopies(mapReports(reports));
    }, [reports]);

    const selectedCopies = reportCopies.filter((t) => t.selected);

    const handleCopySelect = (template: ReportCopy, selected: boolean) => {
        setReportCopies(
            reportCopies.map((t) =>
                template.id === t.id
                    ? {
                          ...t,
                          selected,
                      }
                    : t
            )
        );
    };

    const handleOkClick = async () => {
        const copies = await Promise.all(selectedCopies.map((c) => fetchAnalyticsReport(c.id!)));
        await onOk?.(
            copies.map((report) => ({
                ...report,
                name: `Copy of "${report.name}"`,
                section: section ?? report.section,
                seatIds: seatId ? [seatId] : report.seatIds,
            }))
        );
        toggle();
    };

    const reportsBySection = groupReports(reportCopies, navigationItems);

    return (
        <Modal style={{ maxWidth: 500 }} isOpen toggle={toggle}>
            <ModalHeader onClose={toggle}>{title}</ModalHeader>
            <ModalBody>
                {loading && <OverlayLoader />}
                {children}
                {!!children && !!reportCopies.length && <hr className="mb-0" />}
                <div
                    className="d-flex flex-column align-items-start overflow-y-auto"
                    style={{ maxHeight: 600 }}
                >
                    {reportsBySection.map((group, index) => (
                        <div
                            key={group.label}
                            className={classNames('w-100', {
                                'border-bottom': index !== reportsBySection.length - 1,
                            })}
                        >
                            <ListGroupHeader className="mb-2">
                                <i className={classNames('fa-lg me-2', group.icon)} />
                                {group.label}
                            </ListGroupHeader>
                            {group.reports.map((report) => (
                                <Label key={report.id} check className="d-flex align-items-center mb-2">
                                    <Input
                                        type="checkbox"
                                        className="position-static my-0 ms-0 me-2"
                                        checked={report.selected}
                                        onChange={(e) => handleCopySelect(report, e.target.checked)}
                                    />
                                    {report.name}
                                </Label>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-end">
                    <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <AnimatedButton disabled={disabled || !selectedCopies.length} onClick={handleOkClick}>
                        Apply
                    </AnimatedButton>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ReportCopyModal;
