import { ChartData, ChartOptions } from 'chart.js';
import Bar from 'platform/common/components/Charts/Bar';
import { CHART_COLORS } from 'platform/common/utils/color.util';
import { precisionRound } from 'platform/common/utils/formatters.util';

const getChartOptions = (statName: string): ChartOptions<'bar'> => ({
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: true,
            position: 'bottom' as const,
        },
    },
    scales: {
        y: {
            type: 'linear',
            position: 'left',
            grid: { display: true },
            beginAtZero: true,
            ticks: {
                callback: (value: number) => {
                    const roundedValue = precisionRound(value, 5);
                    if (statName === 'adh_segment_purchase_probability_30d') {
                        return `${roundedValue} %`;
                    }
                    return roundedValue;
                },
            },
        },

        x: {
            type: 'time',
            time: {
                unit: 'day' as const,
            },
            grid: { display: false },
            offset: true,
            ticks: {
                autoSkip: true,
                maxRotation: 45,
                minRotation: 45,
            },
        },
    },
});

const getChartData = (
    labels: string[],
    primaryLabel: string,
    primaryValues: number[],
    secondaryLabel: string | undefined,
    secondaryValues: number[] | undefined
): ChartData<'bar'> => {
    const chartData = {
        labels,
        datasets: [
            {
                label: primaryLabel,
                yAxisID: 'y',
                backgroundColor: CHART_COLORS[0],
                data: [...primaryValues], // https://github.com/reactchartjs/react-chartjs-2/issues/250
            },
        ],
    };

    if (secondaryLabel && secondaryValues) {
        chartData.datasets = [
            ...chartData.datasets,
            {
                label: secondaryLabel,
                yAxisID: 'y',
                backgroundColor: CHART_COLORS[1],
                data: [...secondaryValues], // https://github.com/reactchartjs/react-chartjs-2/issues/250
            },
        ];
    }

    return chartData;
};

type Props = {
    metric: string;
    labels: string[];
    primaryLabel: string;
    primaryValues: number[];
    secondaryLabel: string | undefined;
    secondaryValues: number[] | undefined;
};

const SegmentAnalysisChart = ({
    metric,
    labels,
    primaryLabel,
    primaryValues,
    secondaryLabel,
    secondaryValues,
}: Props) => (
    <Bar
        data={getChartData(labels, primaryLabel, primaryValues, secondaryLabel, secondaryValues)}
        options={getChartOptions(metric)}
        height={300}
    />
);

export default SegmentAnalysisChart;
