import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { useLoading } from 'platform/common/hooks/useLoading';
import { TaskAttachment } from '../../../task/task.types';
import CardLabel from '../CardLabel/CardLabel';
import Attachment from './Attachment';
import './AttachmentsEdit.scss';

interface Props {
    attachments: TaskAttachment[];
    className?: string;
    labelClassName?: string;
    canUpload?: boolean;
    onUpload: (name: string, content: Blob) => Promise<void>;
    onDelete: (attachmentId: number) => Promise<void>;
    onDownload: (attachmentId: number) => Promise<AxiosResponse<Blob>>;
}

const AttachmentsEdit = ({
    attachments,
    className,
    labelClassName,
    canUpload = true,
    onUpload,
    onDelete,
    onDownload,
}: Props) => {
    const [uploading, toggleUploading] = useLoading();

    return (
        <div className={classNames('AttachmentsEdit', className)}>
            <div className="d-flex">
                <CardLabel className={labelClassName} icon="fa fa-paperclip">
                    Attachments
                </CardLabel>
                {canUpload && (
                    <UploadInput
                        multiple={false}
                        readFileAs="blob"
                        onFileUpload={(file) =>
                            toggleUploading(() => onUpload(file.name, file.content as Blob))
                        }
                    >
                        <i className="AttachmentsEdit-add-icon fal fa-plus" />
                    </UploadInput>
                )}
            </div>
            {!!attachments.length && (
                <div className="d-flex justify-content-start align-items-center flex-wrap gap-2 position-relative mt-3">
                    {uploading && <OverlayLoader />}
                    {attachments.map((attachment) => (
                        <Attachment
                            key={attachment.id}
                            attachment={attachment}
                            onDownload={onDownload}
                            onDelete={() => onDelete(attachment.id)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default AttachmentsEdit;
