import { groupBy, mapValues } from 'lodash-es';
import { searchTermStrategyAvailable } from 'platform/campaign/campaign/mappers/campaign.mapper';
import { SelectItem } from 'platform/common/common.types';
import {
    CampaignClassification,
    CampaignClassificationModel,
    CampaignClassifications,
    LabelClassification,
    LabelClassificationModel,
} from './campaignClassification.types';

export const labelClassificationToApi = (c: LabelClassificationModel): LabelClassification => ({
    productLabel: c.productLabel?.value || undefined,
    productSubLabel: (c.productLabel?.value && c.productSubLabel?.value) || undefined,
    flightLabel: c.flightLabel?.value || undefined,
    countryLabels: c.countryLabels?.length ? c.countryLabels.map((l) => l.value) : undefined,
    timeOfferIds: c.timeOfferIds?.length ? c.timeOfferIds : undefined,
});

export const labelClassificationToForm = (c: LabelClassification): LabelClassificationModel => ({
    productLabel: c.productLabel ? { label: c.productLabel, value: c.productLabel } : undefined,
    productSubLabel: c.productSubLabel ? { label: c.productSubLabel, value: c.productSubLabel } : undefined,
    flightLabel: c.flightLabel ? { label: c.flightLabel, value: c.flightLabel } : undefined,
    countryLabels: c.countryLabels?.map((l) => ({ value: l, label: l })) ?? [],
    timeOfferIds: c.timeOfferIds ?? [],
});

export const campaignClassificationToApi = (c: CampaignClassificationModel): CampaignClassification => ({
    channel: c.channel,
    targetAudience: c.targetAudience,
    marketingGoal: c.marketingGoal,
    searchTermStrategy: searchTermStrategyAvailable(c) ? c.searchTermStrategy : undefined,
    ...labelClassificationToApi(c),
});

export const campaignClassificationToForm = (c: CampaignClassification): CampaignClassificationModel => ({
    channel: c.channel,
    targetAudience: c.targetAudience,
    marketingGoal: c.marketingGoal,
    searchTermStrategy: c.searchTermStrategy,
    ...labelClassificationToForm(c),
});

export const campaignClassificationsToApi = (classification: string[] = []): CampaignClassifications => {
    const pairs = classification.map((a) => a.split('#', 2) as [string, string]);
    const groupedClassificationPairs = groupBy(pairs, (p) => p[0]);
    return mapValues(groupedClassificationPairs, (ps) => ps.map((p) => p[1]));
};

export const campaignClassificationsToForm = (classification: CampaignClassifications = {}): string[] =>
    Object.entries(classification).flatMap(([key, values]) =>
        (values ?? []).map((value) => `${key}#${value}`)
    );

export const campaignClassificationsToDisplayNames = (
    classification: string[],
    options: SelectItem[]
): string =>
    options
        .map((o) => o.nodes ?? [])
        .map((nodes) => {
            const labels = nodes.filter((n) => classification.includes(n.value)).map((n) => n.label);
            if (!labels.length) return undefined;
            return labels.length === 1 ? labels[0] : `(${labels.join(', ')})`;
        })
        .filter(Boolean)
        .join(' AND ');
