import { useFeature } from 'platform/common/hooks/useFeature';

export type LogBookAccess = {
    canEdit: boolean;
    canViewAll: boolean;
    canViewBudgetShifting: boolean;
    canViewOptimization: boolean;
    canView: boolean;
};

export const useLogBookAccess = (): LogBookAccess => {
    const canEdit = useFeature('LOGBOOK_EDIT');
    const canViewAll = useFeature('LOGBOOK_VIEW');
    const canViewBudgetShifting = useFeature('LOGBOOK_BUDGET_SHIFTING_VIEW');
    const canViewOptimization = useFeature('LOGBOOK_OPTIMIZATION_VIEW');

    return {
        canEdit,
        canViewAll,
        canViewBudgetShifting,
        canViewOptimization,
        canView: canViewAll || canViewBudgetShifting || canViewOptimization,
    };
};
