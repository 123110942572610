import { getChatAgents } from 'platform/chat/chat.service';
import { PredefinedPromptFormModel } from 'platform/chat/chat.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { FormTextEditor } from 'platform/formik/FormTextEditor/FormTextEditor';

type Props = FormProps<PredefinedPromptFormModel> & { id?: number };

const PredefinedPromptForm = ({
    id,
    labels,
    onCancel,
    formikProps: {
        values: { forceFileUpload },
        submitForm,
    },
    canEdit,
}: Props) => {
    const [{ data: agents, loading }] = usePromise([], () => getChatAgents(), []);

    return (
        <CardForm
            title={`${labels.prefix} DeeplyAI Prompt`}
            subtitle={id ? `ID: ${id}` : null}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General">
                {loading && <OverlayLoader />}
                <FormRow label="Name">
                    <FormInput name="name" validate={required} />
                </FormRow>
                <FormRow label="Prompt">
                    <FormInput name="prompt" type="textarea" rows={15} validate={required} />
                </FormRow>
                <FormRow label="Agent">
                    <FormSelect
                        name="agentId"
                        options={agents}
                        valueKey="id"
                        labelKey="name"
                        isClearable={false}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Order">
                    <FormNumberInput name="order" validate={required} isClearable={false} />
                </FormRow>
                <FormRow label="Internal only">
                    <FormSwitch name="adminOnly" />
                </FormRow>
            </ControlledCard>
            <ControlledCard title="File">
                <FormRow label="Force file select">
                    <FormSwitch name="forceFileUpload" />
                </FormRow>
                {forceFileUpload && (
                    <FormRow label="Send file as multiple prompts">
                        <FormSwitch name="splitFilePrompt" />
                    </FormRow>
                )}
            </ControlledCard>
            <ControlledCard title="Additional settings">
                <FormRow label="Force language select">
                    <FormSwitch name="forceLanguage" />
                </FormRow>
            </ControlledCard>
            <ControlledCard bodyClassName="p-0" title="Description">
                <FormTextEditor name="description" />
            </ControlledCard>
        </CardForm>
    );
};

export default PredefinedPromptForm;
