import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { groupBy, isEmpty, isNil, uniq } from 'lodash-es';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { Report, ReportFilter } from 'platform/analytics/analytics.types';
import { getFilterNumberValue } from 'platform/analytics/analytics.util';
import { IOScriptExportHeader } from 'platform/analytics/ioExportScript.constant';
import { generateIOScripts } from 'platform/campaign/campaign/services/campaign.service';
import { assertIsDefined } from 'platform/common/common.assert';
import { isDefined, isNotEmpty, TableCell } from 'platform/common/common.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { toCsvFile } from 'platform/common/components/DataExportButton/dataExport';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { DATE_RANGES } from 'platform/common/utils/date.util';
import { UnitIOScript } from 'platform/units/units.type';
import VendorLabel from 'platform/vendors/components/VendorLabel';

type Props = {
    toggle: () => void;
    filters: ReportFilter[];
};

type Unit = {
    unitName: string;
    unitId: number;
};

type VendorUnit = {
    vendorCode: string;
    units: Unit[];
};

type DataRow = {
    campaign_id: number;
    campaign_name: string;
    vendor_code: string;
    unit_id: number;
    unit_marker_targeting: string;
    unit_creative_targeting_name: string;
    strategy_name: string;
};

const selectAllUnits = (
    units: Unit[],
    checked: boolean,
    dispatchState: Dispatch<SetStateAction<number[]>>
) => {
    const ids = units.map((u) => u.unitId);
    if (checked) {
        dispatchState((allScripts) => uniq([...allScripts, ...ids]));
    } else {
        dispatchState((allScripts) => allScripts.filter((s) => !ids.includes(s)));
    }
};

const selectUnit = (unitId: number, checked: boolean, dispatch: Dispatch<SetStateAction<number[]>>) => {
    if (checked) {
        dispatch((allScripts) => [...allScripts, unitId]);
    } else {
        dispatch((allScripts) => allScripts.filter((s) => s !== unitId));
    }
};

const isChecked = (units: Unit[], vendorCode: string, unitId: number, selectedScripts: number[]) => {
    if (vendorCode) {
        const selectedV = selectedScripts.map((s) => units.find((u) => u.unitId === s)).filter(isDefined);
        return !!selectedV.length && selectedV.length === units.length;
    }
    return selectedScripts.includes(unitId);
};

const isIndeterminate = (units: Unit[], vendorCode: string, selectedScripts: number[]) => {
    if (vendorCode) {
        const selectedV = selectedScripts.map((s) => units.find((u) => u.unitId === s)).filter(isDefined);
        return !!selectedV.length && selectedV.length < units.length;
    }
    return false;
};

const makeUnitName = (campaignName?: string, creativeTargetingName?: string, markerTargetingName?: string) =>
    `${campaignName} | ${creativeTargetingName || ''} | ${markerTargetingName || ''}`;

const RedirectScriptsModal = ({ toggle, filters }: Props) => {
    const [selectedJSScripts, selectJSScripts] = useState<number[]>([]);
    const [selectedIframeScripts, selectIframeScripts] = useState<number[]>([]);
    const [selectedViewScripts, selectViewScripts] = useState<number[]>([]);
    const [selectedClickScripts, selectClickScripts] = useState<number[]>([]);
    const [selectedVastScripts, selectVastScripts] = useState<number[]>([]);

    const vendors = useSelector(classifierSelectors.vendors);

    const { from, to } = DATE_RANGES.THIS_MONTH.getRange();

    const advertiserId = getFilterNumberValue(filters, 'advertiser_id');
    assertIsDefined(advertiserId, 'filter with key advertiser_id');

    const [{ loading, data, error }] = usePromise<Report<DataRow> | undefined>(
        undefined,
        () =>
            generateOlapReport({
                templateId: 'all_columns',
                dimensions: [
                    'campaign_id',
                    'campaign_name',
                    'strategy_name',
                    'vendor_code',
                    'unit_id',
                    'unit_marker_targeting',
                    'unit_creative_targeting_name',
                ],
                metrics: [],
                dimensionFilters: [
                    ...filters,
                    { key: 'integration_type', values: ['REDIRECT'] },
                    { key: 'full_set', values: [true] },
                ],
                from: from.toISOString(),
                to: to.toISOString(),
            }),
        [filters]
    );

    const reportByVendor = groupBy(data?.rows, (r) => r.vendor_code); // sort here

    const vendorUnitTree: VendorUnit[] = Object.keys(reportByVendor)
        .filter((v) => v !== 'null')
        .map((vendorCode) => ({
            vendorCode,
            units: reportByVendor[vendorCode]
                .filter((v) => v.unit_id)
                .map((v) => ({
                    unitName: makeUnitName(
                        v.campaign_name,
                        v.unit_creative_targeting_name,
                        v.unit_marker_targeting
                    ),
                    unitId: v.unit_id,
                })),
        }))
        .filter((v) => !isEmpty(v.units));

    const additionalOlapData = (ioScript: UnitIOScript) => {
        const {
            vendor_code,
            campaign_name,
            strategy_name,
            unit_marker_targeting,
            unit_creative_targeting_name,
        } = data?.rows.find((row) => row.unit_id === ioScript.unitId) || {};
        return {
            vendorName: vendors.find((v) => v.externalSystem === vendor_code)?.name ?? '',
            campaignName: campaign_name,
            strategyName: strategy_name,
            unitName: makeUnitName(campaign_name, unit_creative_targeting_name, unit_marker_targeting),
        };
    };

    const onApply = async () => {
        const zip = new JSZip();
        const [selectedScripts, allScripts] = await Promise.all([
            generateIOScripts({
                unitIds: uniq(
                    [
                        selectedJSScripts,
                        selectedIframeScripts,
                        selectedViewScripts,
                        selectedClickScripts,
                        selectedVastScripts,
                    ]
                        .flat()
                        .filter(isDefined)
                ),
                advertiserId,
            }),
            generateIOScripts({
                unitIds: uniq(data?.rows.map((r) => r.unit_id)).filter(isNotEmpty) || [],
                advertiserId,
            }),
        ]);

        const scriptsByVendor = groupBy(
            selectedScripts.map((s) => ({
                ...additionalOlapData(s),
                creativeName: s.creativeName,
                creativeSize: s.creativeSize,
                javascript: selectedJSScripts.includes(s.unitId) ? s.javascript : undefined,
                iframe: selectedIframeScripts.includes(s.unitId) ? s.iframe : undefined,
                clickTracker: selectedClickScripts.includes(s.unitId) ? s.clickTracker : undefined,
                viewTracker: selectedViewScripts.includes(s.unitId) ? s.viewTracker : undefined,
                vast2: selectedVastScripts.includes(s.unitId) ? s.vast2 : undefined,
                vast3: selectedVastScripts.includes(s.unitId) ? s.vast3 : undefined,
                vast4: selectedVastScripts.includes(s.unitId) ? s.vast4 : undefined,
            })),
            (s) => s.vendorName
        );

        if (Object.keys(allScripts).length > 1) {
            const allScriptsWithOlapData = allScripts.map((s) => ({
                ...additionalOlapData(s),
                creativeName: s.creativeName,
                creativeSize: s.creativeSize,
                javascript: s.javascript,
                iframe: s.iframe,
                clickTracker: s.clickTracker,
                viewTracker: s.viewTracker,
                vast2: s.vast2,
                vast3: s.vast3,
                vast4: s.vast4,
            }));

            const rows = [IOScriptExportHeader, ...allScriptsWithOlapData].map(Object.values);
            zip.file('overview.csv', toCsvFile(rows));
        }

        Object.entries(scriptsByVendor).forEach(([vendorName, vendorScripts]) => {
            const emptyColumnKeys = Object.entries(vendorScripts[0])
                .filter(([, value]) => isNil(value))
                .map(([key]) => key);

            const rows = [IOScriptExportHeader, ...vendorScripts].map((obj) =>
                Object.entries(obj)
                    .filter(([key]) => !emptyColumnKeys.includes(key))
                    .map(([, value]) => value!)
            );

            zip.file(`${vendorName}_scripts.csv`, toCsvFile(rows));
        });

        return zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
            FileSaver.saveAs(content, 'scripts.zip');
        });
    };

    const checkboxColumn = (
        header: string,
        selectedScripts: number[],
        dispatch: Dispatch<SetStateAction<number[]>>
    ) => ({
        Header: header,
        headerClassName: 'cell-align-center',
        className: 'cell-align-center',
        Cell: ({ original: { unitId, vendorCode, units } }: TableCell<VendorUnit & Unit>) => (
            <input
                type="checkbox"
                className="position-static m-0"
                checked={isChecked(units, vendorCode, unitId, selectedScripts)}
                onChange={({ target: { checked } }) => {
                    if (vendorCode) {
                        selectAllUnits(units, checked, dispatch);
                    } else {
                        selectUnit(unitId, checked, dispatch);
                    }
                }}
                ref={(ref) => {
                    if (ref) {
                        // eslint-disable-next-line no-param-reassign
                        ref.indeterminate = isIndeterminate(units, vendorCode, selectedScripts);
                    }
                }}
            />
        ),
    });

    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '95vw' }}>
            <ModalHeader toggle={toggle}>
                <div className="d-flex align-items-center">
                    Please choose items to export:
                    <div className="d-flex align-items-center ms-2" />
                </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '80vh', overflow: 'scroll', paddingTop: '4vh' }}>
                <FormattedTable
                    isTree
                    NoDataComponent={error ? () => <ErrorMessage error={error} /> : undefined}
                    data={vendorUnitTree || []}
                    loading={loading}
                    childrenOffset={0}
                    childrenLoaders={[{ field: 'units' }]}
                    columns={[
                        {
                            Header: 'Vendor',
                            Cell: ({ original: { vendorCode } }: TableCell<VendorUnit & Unit>) =>
                                vendorCode ? <VendorLabel vendorCode={vendorCode} /> : null,
                        },
                        {
                            Header: 'Unit',
                            Cell: ({ original }: TableCell<VendorUnit & Unit>) => {
                                const { unitName, vendorCode, unitId } = original;
                                return !vendorCode ? (
                                    <LabelWithSubtext
                                        label={unitName}
                                        subtext={DATA_TYPES.ID.format(unitId)}
                                    />
                                ) : null;
                            },
                            id: 'name',
                            minWidth: 400,
                        },
                        checkboxColumn('Javascript', selectedJSScripts, selectJSScripts),
                        checkboxColumn('iFrame', selectedIframeScripts, selectIframeScripts),
                        checkboxColumn('Click Tracker', selectedClickScripts, selectClickScripts),
                        checkboxColumn('View Tracker', selectedViewScripts, selectViewScripts),
                        checkboxColumn('VAST', selectedVastScripts, selectVastScripts),
                    ]}
                />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between align-items-center">
                <div>
                    {!loading &&
                        `Export ${
                            uniq([
                                selectedJSScripts,
                                selectedIframeScripts,
                                selectedViewScripts,
                                selectedClickScripts,
                                selectedVastScripts,
                            ]).flat().length
                        } script(s) for the selected unit(s)`}
                </div>
                <div>
                    <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <AnimatedButton disabled={loading} onClick={onApply}>
                        Export
                    </AnimatedButton>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default RedirectScriptsModal;
