import { ReactComponent as AI_BRAIN } from 'platform/assets/icons/AI_BRAIN.svg';
import IconButton from 'platform/common/components/IconButton/IconButton';
import './ChatBtn.scss';

interface Props {
    onClick: () => void;
    style?: React.CSSProperties;
}

const ChatBtn = ({ onClick, style }: Props) => (
    <IconButton style={style} className="ChatBtn" onClick={onClick} type="button">
        <AI_BRAIN />
    </IconButton>
);

export default ChatBtn;
