import axios from 'platform/axios/axiosBff';
import {
    CustomActionRuleApiModel,
    CustomActionRuleListModel,
} from 'platform/campaign/globalPreset/globalPreset.types';

export const fetchCustomActionRules = (advertiserId: number) =>
    axios
        .get<CustomActionRuleListModel[]>('/advertiser/api/custom-action-rules', { params: { advertiserId } })
        .then((response) => response.data);

export const fetchCustomActionRule = (id: number) =>
    axios
        .get<CustomActionRuleApiModel>(`/advertiser/api/custom-action-rules/${id}`)
        .then((response) => response.data);

export const createCustomActionRule = (model: CustomActionRuleApiModel) =>
    axios
        .post<CustomActionRuleApiModel>(`/advertiser/api/custom-action-rules`, model)
        .then((response) => response.data);

export const updateCustomActionRule = (id: string | number, model: CustomActionRuleApiModel) =>
    axios
        .put<CustomActionRuleApiModel>(`/advertiser/api/custom-action-rules/${id}`, model)
        .then((response) => response.data);
