import { CSSProperties } from 'react';
import classNames from 'classnames';
import InlineEditContainer from 'platform/common/components/InlineEditContainer/InlineEditContainer';
import Select from 'platform/common/components/Select/Select';

type OptionType<T> = { value: T; label?: string };

interface Props<T, O extends OptionType<T>> {
    value: T;
    options: O[];
    getLabel?: (value: O) => any;
    placeholder?: string;
    className?: string;
    selectStyle?: CSSProperties;
    iconClassName?: string;
    isLoading?: boolean;
    isClearable?: boolean;
    onChange: (value: T) => void;
    menuPosition?: 'fixed' | 'absolute';
}

const InlineEditSelect = <T, O extends OptionType<T>>({
    value,
    options,
    getLabel,
    placeholder,
    className,
    selectStyle,
    iconClassName,
    isLoading = false,
    isClearable = true,
    onChange,
    menuPosition,
}: Props<T, O>) => {
    const option = options.find((o) => o.value === value);
    return (
        <InlineEditContainer
            placeholder={placeholder}
            iconClassName={iconClassName}
            value={option?.label || `${value ?? ''}`}
            renderValue={getLabel && option ? () => getLabel(option) : undefined}
        >
            {(props) => (
                <Select
                    value={value}
                    className={classNames('me-1', className)}
                    selectStyle={selectStyle}
                    options={options}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    menuIsOpen
                    autoFocus
                    getOptionLabel={getLabel}
                    onBlur={() => {
                        props.toggleEditMode();
                    }}
                    onChange={(newValue) => {
                        onChange(newValue);
                        props.toggleEditMode();
                    }}
                    menuPosition={menuPosition}
                />
            )}
        </InlineEditContainer>
    );
};

export default InlineEditSelect;
