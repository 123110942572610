import StrategyFormNavigation from './components/StrategyFormNavigation';
import { STRATEGIES } from './strategy.navigation';

const strategyRoutes = [
    {
        ...STRATEGIES,
        component: StrategyFormNavigation,
    },
];

export default strategyRoutes;
