import { useState } from 'react';
import classNames from 'classnames';
import { publicImageUrl } from 'platform/common/utils/environment.util';
import { getInitials } from 'platform/common/utils/formatters.util';
import './Avatar.scss';

interface Props {
    name: string;
    imageUrl: string | undefined;
    size?: 'tiny' | 'small' | 'medium';
    impersonating?: boolean;
    framed?: boolean;
    className?: string;
}

const Avatar = ({ name, imageUrl, size = 'small', impersonating, framed = false, className }: Props) => {
    const [loaded, setLoaded] = useState(false);

    const imageVisible = imageUrl && loaded;

    const getAvatarText = () => {
        if (impersonating) {
            return <div className="Avatar-image d-flex align-items-center justify-content-center">IMP</div>;
        }

        if (imageVisible) {
            return '';
        }

        return getInitials(name);
    };

    return (
        <span
            className={classNames(
                'Avatar',
                size,
                {
                    framed,
                    'bg-transparent': imageVisible && !framed,
                },
                className
            )}
        >
            {getAvatarText()}
            {imageUrl && !impersonating && (
                <img
                    src={publicImageUrl(imageUrl)}
                    alt="avatar"
                    className={classNames('Avatar-image', !imageVisible && 'd-none')}
                    onLoad={() => setLoaded(true)}
                />
            )}
        </span>
    );
};

export default Avatar;
