import { lazy } from 'react';
import { NavigationItem, RoutingItem } from 'platform/app/app.types';

const DataExplorer = lazy(() => import('platform/dataExplorer/DataExplorer'));

export const DATA_EXPLORER: NavigationItem = {
    name: 'Data Explorer',
    path: '/dataExplorer',
    requiresFeature: 'ANALYTICS_VIEW',
    lazy: true,
};

export const dataExplorerRoute: RoutingItem = {
    ...DATA_EXPLORER,
    component: DataExplorer,
};
