import { Button } from 'reactstrap';
import ErrorReporting from 'platform/common/components/Errors/ErrorReporting';
import { ErrorDetails } from 'platform/common/error.types';
import InformationModal from '../InformationModal/InformationModal';

const copyToClipboard = (str: string): void => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const TraceIdButton = ({ traceId }: { traceId: string }) => (
    <span role="button" tabIndex={0} className="cursor-pointer" onClick={() => copyToClipboard(traceId)}>
        Trace ID: {traceId}
    </span>
);

interface Props {
    error: ErrorDetails;
    toggle: () => void;
}

const ErrorModal = ({ error, toggle }: Props) => (
    <InformationModal
        title="Error details"
        style={{ maxWidth: '30vw' }}
        isOpen
        renderFooter={() => (
            <>
                <Button color="primary" onClick={toggle}>
                    Close
                </Button>
            </>
        )}
        toggle={toggle}
    >
        <h5>{error.message}</h5>
        {error.traceId && <TraceIdButton traceId={error.traceId} />}
        {error.request?.url && <div>Request url: {error.request.url}</div>}
        {error.detailedMessage && <div className="overflow-auto mt-3">{error.detailedMessage}</div>}
        <ErrorReporting error={error} />
    </InformationModal>
);

export default ErrorModal;
