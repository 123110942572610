import classNames from 'classnames';

interface Props {
    text: string;
    className?: string;
}

const SimpleCardTitle = ({ text, className }: Props) => (
    <span className={classNames('SimpleCard-title fw-bold', className)}>{text}</span>
);

export default SimpleCardTitle;
