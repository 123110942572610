import { useParams } from 'react-router';
import { saveAnalyticsColumn } from 'platform/analytics/analytics.service';
import { ColumnGroup } from 'platform/analytics/analytics.types';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import ColumnDefinitionForm from './ColumnDefinitionForm';
import { ColumnWithType } from './ColumnDefinitionList';

interface Props {
    columns: ColumnWithType[];
    columnGroups: ColumnGroup[];
    canEdit: boolean;
    redirectTo: string;
    afterSubmit: () => void;
}

const ColumnDefinitionFormContainer = ({
    columns,
    columnGroups,
    redirectTo,
    canEdit,
    afterSubmit,
}: Props) => {
    const { key } = useParams<{ key: string | undefined }>();
    const column = columns.find((c) => c.key === key);

    if (!column) return null;

    return (
        <FormContainer
            sidePanel
            isEdit
            canEdit={canEdit}
            redirectTo={redirectTo}
            helpKey="column_definition_form"
            onOpen={async () => column}
            onSubmit={saveAnalyticsColumn}
            onSubmitFinish={afterSubmit}
        >
            {(props) => (
                <ColumnDefinitionForm {...props} columnType={column.columnType} columnGroups={columnGroups} />
            )}
        </FormContainer>
    );
};

export default ColumnDefinitionFormContainer;
