import { orderBy } from 'lodash-es';
import BudgetShiftingDetails from 'platform/campaign/logBook/components/BudgetShiftingDetails/BudgetShiftingDetails';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { formatDateRange, formatDateTime } from 'platform/common/utils/date.util';
import { formatBudget } from 'platform/common/utils/number.util';
import {
    CHANGE_REASON_LABELS,
    MediaplanChange,
    MediaplanChangeData,
} from 'platform/mediaplan/mediaplan.types';

type Row = {
    timestamp?: string;
    changedBy?: string;
    label?: string;
    comment?: string;
    oldData?: MediaplanChangeData;
    newData?: MediaplanChangeData;
};

const columnDefinitions: TableColumn<Row>[] = [
    {
        Header: 'Budget',
        type: DATA_TYPES.EUR,
        width: 120,
        sortable: false,
        Cell: ({ original: { newData } }) => formatBudget(newData?.budget),
    },
    {
        Header: 'Runtime',
        width: 200,
        sortable: false,
        Cell: ({ original: { newData } }) => formatDateRange(newData?.dateFrom, newData?.dateTo),
    },
    {
        Header: 'Details',
        sortable: false,
        Cell: ({ original: { label, comment, oldData, newData, changedBy } }) => (
            <div className="text-truncate">
                <div className="mb-1">{label}</div>
                {changedBy && <BudgetShiftingDetails oldData={oldData} newData={newData} comment={comment} />}
            </div>
        ),
    },
    {
        Header: 'Changed',
        sortable: false,
        width: 200,
        Cell: ({ original: { timestamp, changedBy } }) =>
            changedBy ? <UpdatedOn date={formatDateTime(timestamp)} updatedBy={changedBy} /> : null,
    },
];

export const LogBookTable = (props: { changes: MediaplanChange[] }) => {
    const rows = orderBy(props.changes, 'timestamp').map((change) => ({
        ...change,
        label: change.reason && CHANGE_REASON_LABELS[change.reason],
    }));
    const initialRow = {
        label: 'Initial',
        oldData: rows[0].oldData,
        newData: rows[0].oldData,
    };
    return <FormattedTable columns={columnDefinitions} data={[initialRow, ...rows]} />;
};
