import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import {
    createInteractionPreset,
    deleteInteractionPreset,
    updateInteractionPreset,
} from 'platform/analytics/analytics.service';
import { InteractionPreset } from 'platform/analytics/analytics.types';
import { Optional } from 'platform/common/common.types';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { useLoading } from 'platform/common/hooks/useLoading';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormRadioList from 'platform/formik/FormRadioList/FormRadioList';

interface Props {
    reportId: number;
    preset: Optional<InteractionPreset, 'id'>;
    onSave: (presetName: string) => void;
    onDelete: () => void;
    onClose: () => void;
}

const InteractionPresetModal = ({ reportId, preset, onSave, onDelete, onClose }: Props) => {
    const [loading, withLoading] = useLoading();
    const showConfirmationModal = useConfirmationModal();
    const handleSave = async ({ id, name, values, submitType }: InteractionPreset) => {
        await withLoading(() =>
            id && submitType === 'UPDATE'
                ? updateInteractionPreset(reportId, id, { name, values })
                : createInteractionPreset(reportId, { name, values })
        );
        onSave(name);
    };

    const handleDelete = () =>
        showConfirmationModal(
            async () => {
                await withLoading(() => deleteInteractionPreset(reportId, preset.id!!));
                onDelete();
                onClose();
            },
            { title: `Delete preset "${preset.name}"?` }
        );

    return (
        <Modal isOpen toggle={onClose}>
            <FormContainer
                helpKey="interaction_preset_form"
                onOpen={async () => preset}
                onSubmit={handleSave}
                onSubmitFinish={onClose}
            >
                {({ formikProps: { submitForm } }) => (
                    <>
                        <ModalHeader onClose={onClose}>Preset settings</ModalHeader>
                        <ModalBody className="p-4">
                            {!!preset.id && (
                                <FormRadioList
                                    name="submitType"
                                    options={[
                                        { label: 'Update an existing preset', value: 'UPDATE' },
                                        { label: 'Create a new preset', value: 'CREATE' },
                                    ]}
                                    className="d-flex flex-fill mb-3"
                                />
                            )}
                            <FormRow label="Preset name">
                                <FormInput name="name" validate={required} />
                            </FormRow>
                        </ModalBody>
                        <ModalFooter>
                            {loading && <OverlayLoader />}
                            {!!preset.id && (
                                <Button color="secondary" onClick={handleDelete}>
                                    Delete preset
                                </Button>
                            )}
                            <Button color="primary" onClick={submitForm}>
                                {preset.id ? 'Update' : 'Save'}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </FormContainer>
        </Modal>
    );
};

export default InteractionPresetModal;
