import { Help } from 'platform/adminPanel/adminPanel.types';
import { RootState } from '../../rootState.type';
import { Action } from '../common.types';

const SET_HELP = 'common/help/SET_HELP';

const reducer = (state = [], action: Action): Help[] => {
    switch (action.type) {
        case SET_HELP: {
            return action.payload;
        }
        default:
            return state;
    }
};

export default reducer;

export const helpActions = {
    setHelp: (help: Help[]) => ({
        type: SET_HELP,
        payload: help,
    }),
};

export const helpSelectors = {
    getHelpValue: (helpKey?: string) => (state: RootState) =>
        state.common.help.find((help) => help.key === helpKey)?.value ?? '',
};
