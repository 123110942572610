import { ReactNode } from 'react';
import { Col } from 'reactstrap';
import { EMPTY_SYMBOL } from 'platform/common/constants/common.constant';

interface Props {
    label: string;
    value?: ReactNode;
}

const JourneyHeaderRowBlock = ({ label, value }: Props) => (
    <Col className="d-flex flex-column">
        <span className="text-muted mb-3">{label}</span>
        <div className="font-xl fw-bold d-flex align-items-center">{value ?? EMPTY_SYMBOL}</div>
    </Col>
);

export default JourneyHeaderRowBlock;
