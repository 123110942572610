import { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import HeaderCell from 'platform/dashboard/components/CdpDashboard/HeaderCell';
import SegmentChart from 'platform/dashboard/components/CdpDashboard/SegmentChart';
import { fetchCdpAdvertiserData } from 'platform/dashboard/dashboard.service';

interface Props {
    advertiserId: number;
    periods: Periods;
}

const SegmentInfoCard = ({ advertiserId, periods }: Props) => {
    const [selectedMetric, setSelectedMetric] = useState('unique_users_30d');

    const [{ data, loading }] = usePromise(undefined, () => fetchCdpAdvertiserData(advertiserId, periods), [
        advertiserId,
        periods,
    ]);

    if (loading) {
        return <Placeholder className="pb-4" height="350px" />;
    }

    if (!data) {
        return null;
    }

    return (
        <Card>
            <CardBody className="p-0">
                <div className="d-flex flex-column flex-md-row p-3">
                    {(() => {
                        const metric = 'unique_users_30d';
                        return (
                            <HeaderCell
                                name={'Users, 30d'}
                                type={DATA_TYPES.INT}
                                metric={metric}
                                value={data.primaryStats[metric].value}
                                compareValue={data.secondaryStats[metric].value}
                                isSelected={selectedMetric === metric}
                                onSelect={() => {
                                    setSelectedMetric(metric);
                                }}
                            />
                        );
                    })()}

                    {(() => {
                        const metric = 'all_actions_30d';
                        return (
                            <HeaderCell
                                name={'All actions, 30d'}
                                type={DATA_TYPES.INT}
                                metric={metric}
                                value={data.primaryStats[metric].value}
                                compareValue={data.secondaryStats[metric].value}
                                isSelected={selectedMetric === metric}
                                onSelect={() => {
                                    setSelectedMetric(metric);
                                }}
                            />
                        );
                    })()}

                    {(() => {
                        const metric = 'action_probability_30d';
                        return (
                            <HeaderCell
                                name={'Action probability, 30d'}
                                type={DATA_TYPES.P100}
                                metric={metric}
                                value={data.primaryStats[metric].value}
                                compareValue={data.secondaryStats[metric].value}
                                isSelected={selectedMetric === metric}
                                onSelect={() => {
                                    setSelectedMetric(metric);
                                }}
                            />
                        );
                    })()}

                    {(() => {
                        const metric = 'bounce_rate_30d';
                        const value = data.primaryStats[metric].value;
                        return (
                            value && (
                                <HeaderCell
                                    name="Bounce rate, 30d"
                                    type={DATA_TYPES.P100}
                                    metric={metric}
                                    value={value}
                                    compareValue={data.secondaryStats[metric].value}
                                    isSelected={selectedMetric === metric}
                                    onSelect={() => {
                                        setSelectedMetric(metric);
                                    }}
                                />
                            )
                        );
                    })()}

                    <HeaderCell
                        name="Number of Segments"
                        type={DATA_TYPES.INT}
                        value={data.segments.length}
                        isSelected={false}
                    />
                </div>

                <SegmentChart data={data} loading={loading} selectedMetric={selectedMetric} />
            </CardBody>
        </Card>
    );
};

export default SegmentInfoCard;
