import { CSSProperties } from 'react';
import classNames from 'classnames';
import AlgorithmActionButton from './AlgorithmActionButton';

interface AlgorithmActionBtn {
    title: string;
    onClick: () => void;
}

interface Props {
    color: string;
    expanded: boolean;
    actionBtns: AlgorithmActionBtn[];
}

const AlgorithmActions = ({ actionBtns, color, expanded }: Props) => {
    const style: CSSProperties | undefined = expanded
        ? { background: color, color: '#fff', borderColor: '#fff' }
        : undefined;

    return (
        <div className={classNames('hoverable-actions', { expanded })}>
            {actionBtns.map(({ title, onClick }, i) => (
                <AlgorithmActionButton key={i} style={style} onClick={onClick}>
                    {title}
                </AlgorithmActionButton>
            ))}
        </div>
    );
};

export default AlgorithmActions;
