import { List } from 'reactstrap';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';
import SectionSeparator from 'platform/common/components/SectionSeparator/SectionSeparator';
import { OBSERVER_REPORT_MESSAGE_CATEGORIES } from 'platform/observer/observer.constants';
import { ObserverMessageCategory } from 'platform/observer/observer.types';
import { User } from 'platform/userManagement/types/user.type';
import { ObserverMessage } from '../observerMessageCenter.types';
import MessageCenterListItem from './MessageCenterListItem/MessageCenterListItem';
import './ObserverMessageCenterList.scss';

const CATEGORY_ICONS: Record<ObserverMessageCategory, string> = {
    BIDDING: 'fa-solid fa-gavel',
    BUDGET: 'fa-solid fa-location-crosshairs',
    TRACKING: 'fa-solid fa-euro-sign',
};

interface Props {
    rows: ObserverMessage[];
    assigneeOptions: User[];
    onHistoryClick: (id: number) => void;
    onChange: () => void;
}

const ObserverMessageCenterList = ({ rows, assigneeOptions, onHistoryClick, onChange }: Props) => {
    const sectionsByType = groupBy(rows, 'category');

    return (
        <>
            {Object.entries(sectionsByType).map(([category, messages]) => (
                <div key={category} className="d-flex mb-3">
                    <SectionSeparator
                        iconClassName={classNames(
                            CATEGORY_ICONS[category as ObserverMessageCategory],
                            'fs-4'
                        )}
                    />
                    <div className="flex-grow-1" style={{ minWidth: 0 }}>
                        <div className="fs-5 fw-semibold mb-1">
                            {OBSERVER_REPORT_MESSAGE_CATEGORIES[category as ObserverMessageCategory]}
                        </div>
                        <List type="inline" className="ObserverMessageCenterList">
                            {messages.map((messageData) => (
                                <MessageCenterListItem
                                    key={messageData.id}
                                    messageData={messageData}
                                    assigneeOptions={assigneeOptions}
                                    onHistoryClick={onHistoryClick}
                                    onChange={onChange}
                                />
                            ))}
                        </List>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ObserverMessageCenterList;
