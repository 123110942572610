import { Status } from 'platform/common/constants/status.constant';

export interface AdvancedSetting {
    key: string;
    status: Status;
    name: string;
    group: AdvancedSettingGroup;
    valueType: AdvancedSettingValueType;
    description?: string;
    updatedOn?: string;
    updatedBy?: string;
}

export type AdvancedSettingGroup =
    | 'INTEGRATION_SPECIFIC'
    | 'FUNNEL_LABELS'
    | 'INTEGRATION_IDS'
    | 'REACH'
    | 'OTHER';

export const ADVANCED_SETTING_GROUP_LABELS: Record<AdvancedSettingGroup, string> = {
    INTEGRATION_SPECIFIC: 'Integration-specific',
    FUNNEL_LABELS: 'Audiences',
    INTEGRATION_IDS: 'Integration IDs',
    REACH: 'Reach',
    OTHER: 'Other',
};

export type AdvancedSettingValueType = 'STRING' | 'NUMBER' | 'SWITCH';

export const ADVANCED_SETTING_VALUE_TYPE_LABELS: Record<AdvancedSettingValueType, string> = {
    STRING: 'Text',
    NUMBER: 'Number',
    SWITCH: 'On/Off',
};

export const defaultAdvancedSetting = (key: string): AdvancedSetting => ({
    key,
    status: 'ACTIVE',
    name: key,
    group: 'OTHER',
    valueType: 'STRING',
});
