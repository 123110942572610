import Tooltip from 'platform/common/components/Tooltip/Tooltip';

const HEALTH_ICON_CLASSES: { [key: string]: string } = {
    WARNING: 'text-warning',
    CRITICAL: 'text-danger',
};

const TOOLTIP_MESSAGES: { [key: string]: string } = {
    WARNING: `This Segment generated less than 50 impressions yesterday.
              If it should have more impressions, please check implementation.`,
    CRITICAL: 'This Segment is not generating any statistics. Please check implementation.',
};

interface Props {
    health: string;
    idForTooltip: string;
}

const SegmentHealthIndicator = ({ health, idForTooltip, ...rest }: Props) => {
    const iconClass = HEALTH_ICON_CLASSES[health];

    if (!iconClass) {
        return null;
    }

    return (
        <Tooltip renderTooltip={() => <> {TOOLTIP_MESSAGES[health]}</>}>
            <span id={idForTooltip} className={`fa fa-exclamation-circle ${iconClass}`} {...rest} />
        </Tooltip>
    );
};

export default SegmentHealthIndicator;
