import { useState } from 'react';
import { groupBy } from 'lodash-es';
import { FloodlightActivity } from 'platform/advertisers/advertiser.types';
import { fetchFloodlightActivities } from 'platform/advertisers/services/advertiser.service';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { ExternalSystem, getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { positiveWholeNumber, required } from 'platform/common/utils/validators.util';
import FormDynamicOptionList from 'platform/formik/FormDynamicOptionList/FormDynamicOptionList';
import FormCreatableSelectTree from 'platform/formik/FormSelectTree/FormCreatableSelectTree';
import { SegmentFormAdvertiser } from 'platform/segments/components/SegmentForm/SegmentForm.types';

interface Props {
    advertiser: SegmentFormAdvertiser;
    floodlightActivityIds?: string[];
}

interface FloodlightActivityTreeOption {
    value?: string;
    label: string;
    nodes?: FloodlightActivityTreeOption[];
}

const Convertible = ({ advertiser, floodlightActivityIds }: Props) => {
    const [customFloodlightActivities, setCustomFloodlightActivities] = useState<
        FloodlightActivityTreeOption[]
    >([]);

    const integration = (system: ExternalSystem) =>
        advertiser.externalSystemIntegrations.find((i) => i.externalSystem === system);

    const [{ data: options, loading }] = usePromise<FloodlightActivity[]>(
        [],
        async () => {
            const gcmAdvertiserId = integration('GCM')?.accounts[0]?.externalId;
            const floodlightActivities = gcmAdvertiserId
                ? await fetchFloodlightActivities(gcmAdvertiserId)
                : [];

            const customFloodlightActivityIds = floodlightActivityIds?.filter(
                (id) => !floodlightActivities.find((o) => String(o.id) === id)
            );
            if (customFloodlightActivityIds?.length) {
                setCustomFloodlightActivities(
                    customFloodlightActivityIds.map((id) => ({ value: id, label: id }))
                );
            }

            return floodlightActivities;
        },
        []
    );

    const groupedActivities = Object.values(groupBy(options, (o) => o.floodlightActivityGroupId)).map(
        (activities) => ({
            value: String(activities[0].floodlightActivityGroupId),
            label: activities[0].floodlightActivityGroupName,
            nodes: activities.map((a) => ({
                value: String(a.id),
                label: a.name,
            })),
        })
    );

    return (
        <>
            {integration('GCM')?.enabled && (
                <FormRow label={getIntegrationLabel('GCM')}>
                    <FormCreatableSelectTree<FloodlightActivityTreeOption>
                        name="floodlightActivityIds"
                        options={
                            customFloodlightActivities.length
                                ? [
                                      ...groupedActivities,
                                      {
                                          label: 'custom',
                                          nodes: customFloodlightActivities,
                                      },
                                  ]
                                : groupedActivities
                        }
                        onNewOption={(newOption) =>
                            setCustomFloodlightActivities((prev) => [...prev, newOption])
                        }
                        formatCreateLabel={(value) => (
                            <span>
                                <b>Add Activity ID</b>
                                {` "${value}"`}
                            </span>
                        )}
                        isLoading={loading}
                        isMulti
                    />
                </FormRow>
            )}
            {integration('GOOGLE')?.enabled && (
                <FormRow label={getIntegrationLabel('GOOGLE')}>
                    <FormDynamicOptionList
                        name="googleAdsConversionIds"
                        placeholder="GoogleAds conversion ID"
                        buttonTitle="Add"
                        validateInput={positiveWholeNumber}
                    />
                </FormRow>
            )}
            {integration('GOOGLE_ANALYTICS')?.enabled && (
                <FormRow label={getIntegrationLabel('GOOGLE_ANALYTICS')}>
                    <FormDynamicOptionList
                        name="googleAnalyticsEventNames"
                        placeholder="Event name"
                        buttonTitle="Add"
                        validateInput={required}
                    />
                </FormRow>
            )}
            {integration('BING')?.enabled && (
                <FormRow label={getIntegrationLabel('BING')}>
                    <FormDynamicOptionList
                        name="bingGoalNames"
                        placeholder="Goal name"
                        buttonTitle="Add"
                        validateInput={required}
                    />
                </FormRow>
            )}
        </>
    );
};

export default Convertible;
