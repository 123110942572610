import { Algorithm, AlgorithmExecution, AlgorithmTemplate } from 'platform/algorithms/algorithms.types';
import { ReportSettings, generateOlapReport } from 'platform/analytics/analytics.service';
import axiosBff from 'platform/axios/axiosBff';
import { Optional } from 'platform/common/common.types';

export const listAdvertiserAlgorithms = (advertiserId: number) =>
    axiosBff
        .get<Algorithm[]>(`/optimus/api/algorithms`, { params: { advertiserId } })
        .then((res) => res.data);

export const fetchAlgorithm = (id: number) =>
    axiosBff.get<Algorithm>(`/optimus/api/algorithms/${id}`).then((res) => res.data);

export const updateAlgorithm = (id: number, model: Optional<Algorithm, 'id'>) =>
    axiosBff.put(`/optimus/api/algorithms/${id}`, model).then((res) => res.data);

export const createAlgorithm = (model: Omit<Algorithm, 'id'>) =>
    axiosBff.post(`/optimus/api/algorithms`, model).then((res) => res.data);

export const listAlgorithmTemplates = () =>
    axiosBff.get<AlgorithmTemplate[]>(`/optimus/api/algorithms/templates`).then((res) => res.data);

export const fetchAlgorithmTemplate = (id: number) =>
    axiosBff.get<AlgorithmTemplate>(`/optimus/api/algorithms/templates/${id}`).then((res) => res.data);

export const updateAlgorithmTemplate = (id: number, model: Optional<AlgorithmTemplate, 'id'>) =>
    axiosBff.put(`/optimus/api/algorithms/templates/${id}`, model).then((res) => res.data);

export const createAlgorithmTemplate = (model: Optional<AlgorithmTemplate, 'id'>) =>
    axiosBff.post(`/optimus/api/algorithms/templates`, model).then((res) => res.data);

export const fetchAlgorithmExecution = (id: string) =>
    axiosBff.get<AlgorithmExecution>(`/optimus/api/algorithms/log/${id}`).then((res) => res.data);

export const executeAlgorithm = (id: number) => axiosBff.post(`/optimus/api/algorithms/execute/${id}`);

export const fetchAlgorithmListSummary = (
    advertiserId: number,
    from: string | undefined,
    to: string | undefined
) => {
    const reportSettings: ReportSettings = {
        dimensions: ['algorithm_executions', 'algorithm_processed_entries'],
        metrics: [],
        from,
        to,
        dimensionFilters: [{ key: 'advertiser_id', values: [advertiserId] }],
    };

    return generateOlapReport(reportSettings);
};
