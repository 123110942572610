import classNames from 'classnames';
import FailedIcon from 'platform/assets/icons/FAILED.svg';
import SyncIcon from 'platform/assets/icons/SYNC.svg';
import WarningIcon from 'platform/assets/icons/WARNING.svg';
import {
    ExternalSystem,
    getIntegrationIcon,
    getIntegrationLabel,
    IntegrationStatus,
} from 'platform/common/constants/externalIntegration.constant';
import './IntegrationIcon.scss';

type Props = {
    system: ExternalSystem;
    customLabel?: string;
    customIcon?: string;
    enabled?: boolean;
    status?: IntegrationStatus;
    hasImgTitle?: boolean;
    integrationMessage?: string;
};

const getStatusIcon = (status: IntegrationStatus, integrationMessage?: string) => {
    if (status === 'ERROR') {
        return FailedIcon;
    }
    if (status === 'IN_PROGRESS') {
        return SyncIcon;
    }
    if (integrationMessage) {
        return WarningIcon;
    }
    return undefined;
};

const IntegrationIcon = ({
    status = 'NEVER',
    integrationMessage,
    system,
    customLabel,
    customIcon,
    enabled = true,
    hasImgTitle = true,
}: Props) => {
    const integrationLabel = getIntegrationLabel(system);
    const integrationIcon = getIntegrationIcon(system);
    if (!integrationIcon) {
        return null;
    }

    const statusIcon = getStatusIcon(status, integrationMessage);
    const label = customLabel || integrationLabel;
    const icon = customIcon || integrationIcon;

    return (
        <div
            tabIndex={0}
            role="button"
            className={classNames('IntegrationIcon', {
                'IntegrationIcon--inactive': !enabled,
            })}
        >
            <div className="IntegrationIcon-mainIcon">
                <img
                    src={icon}
                    alt={label}
                    title={hasImgTitle ? label : undefined}
                    style={{ opacity: statusIcon ? 0.6 : 1 }}
                />
            </div>
            {statusIcon && (
                <div className="IntegrationIcon-stateIcon">
                    <img
                        src={statusIcon}
                        alt={status}
                        title={hasImgTitle ? `${label}: ${status}` : undefined}
                    />
                </div>
            )}
        </div>
    );
};

export default IntegrationIcon;
