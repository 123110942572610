import { Col, Container, Row } from 'reactstrap';
import { FormikProps } from 'formik';
import { AdvertiserTagFormEvent } from '../../advertiserTag.types';
import {
    CHECKOUT_EVENTS,
    DEFAULT_EVENTS,
    ORDER_EVENTS,
    OrderEvent,
    PRODUCT_TRACKING_EVENTS,
    SITE_REGISTRATION_EVENTS,
} from '../../constants/advertiserTag.constant';
import EventTrackingListRow from './EventTrackingListRow';
import EventTrackingListSection from './EventTrackingListSection';
import './EventTrackingList.scss';

interface Props {
    events: AdvertiserTagFormEvent[];
    onChange: FormikProps<AdvertiserTagFormEvent>['setFieldValue'];
}

const filterEvents = (events: AdvertiserTagFormEvent[], names: string[]) =>
    events.filter((event) => names.includes(event.advertiserTagVarName));

const EventTrackingList = ({ events, onChange }: Props) => {
    const updateEventProperty = (
        advertiserTagVarName: string,
        property: 'selected' | 'convertible' | 'productData' | 'purchaseData'
    ) => {
        const eventIndex = events.findIndex((item) => item.advertiserTagVarName === advertiserTagVarName);
        const event = { ...events[eventIndex] };
        event[property] = !event[property];

        if (property === 'selected') {
            if (event.selected) {
                if (ORDER_EVENTS.includes(event.advertiserTagVarName as OrderEvent)) {
                    event.convertible = true;
                    event.purchaseData = true;
                }
            } else {
                event.convertible = false;
                event.productData = false;
                event.purchaseData = false;
            }
        }

        onChange(`events[${eventIndex}]`, event);
    };

    const renderSection = (sectionName: string, formEvents: AdvertiserTagFormEvent[]) => {
        if (!formEvents.length) {
            return null;
        }
        return (
            <EventTrackingListSection name={sectionName}>
                {formEvents.map((event) => (
                    <EventTrackingListRow
                        key={event.name}
                        event={event}
                        onEventPropertyChange={updateEventProperty}
                    />
                ))}
            </EventTrackingListSection>
        );
    };

    const customEvents = events.filter((event) => event.type === 'CUSTOM_EVENT');
    const defaultEvents = filterEvents(events, DEFAULT_EVENTS);
    const productTrackingEvents = filterEvents(events, PRODUCT_TRACKING_EVENTS);
    const siteRegistrationEvents = filterEvents(events, SITE_REGISTRATION_EVENTS);
    const checkoutEvents = filterEvents(events, CHECKOUT_EVENTS);

    return (
        <Container>
            <Row className="EventTrackingList-headerRow text-center">
                <Col md={6} />
                <Col md={2}>Action</Col>
                <Col md={2}>Product data</Col>
                <Col md={2}>Purchase data</Col>
            </Row>
            {renderSection('Custom events', customEvents)}
            {renderSection('Default events', defaultEvents)}
            {renderSection('Product tracking', productTrackingEvents)}
            {renderSection('Site registration', siteRegistrationEvents)}
            {renderSection('Checkout', checkoutEvents)}
        </Container>
    );
};

export default EventTrackingList;
