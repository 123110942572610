import { MouseEvent, ReactNode, useState } from 'react';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import ImagePreviewModal from 'platform/common/components/ImagePreviewModal/ImagePreviewModal';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { useLoading } from 'platform/common/hooks/useLoading';
import { saveDownloadedFile } from 'platform/common/utils/file.util';
import { isImageFilename } from 'platform/creatives/utils/asset.utils';
import FileIcon from './FileIcon';
import FileLabel from './FileLabel';

interface Props {
    filename: string;
    className?: string;
    iconClassName?: string;
    labelClassName?: string;
    onDownload: () => Promise<AxiosResponse<Blob>>;
}

const FileLink = ({
    filename,
    className = 'align-items-center',
    iconClassName = 'me-2',
    labelClassName = 'text-primary',
    onDownload,
}: Props) => {
    const [previewModal, setPreviewModal] = useState<ReactNode | null>(null);
    const [downloading, whileDownloading] = useLoading();

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        if (isImageFilename(filename)) {
            setPreviewModal(
                <ImagePreviewModal title={filename} load={onDownload} toggle={() => setPreviewModal(null)} />
            );
        } else {
            whileDownloading(() => onDownload().then(saveDownloadedFile));
        }
    };

    return (
        <a
            href="#"
            className={classNames(
                'd-flex position-relative cursor-pointer no-text-decoration outline-0',
                className
            )}
            onClick={handleClick}
        >
            {downloading && <OverlayLoader />}
            <div className={iconClassName}>
                <FileIcon filename={filename} />
            </div>
            <FileLabel className={labelClassName} filename={filename} />
            {previewModal}
        </a>
    );
};

export default FileLink;
