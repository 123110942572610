import { ColumnDefinition } from 'platform/analytics/analytics.types';
import { SelectItem, Sort, SortDirection } from 'platform/common/common.types';
import AddItemButton from 'platform/common/components/AddItemButton/AddItemButton';
import { ButtonDropdownOption } from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { arrayItemRemove, arrayItemUpdate } from 'platform/common/utils/array.util';

interface Props {
    sorts: Sort[];
    columns: ColumnDefinition[];
    onChange: (sorts: Sort[]) => void;
}

const DIRECTION_OPTIONS: SelectItem<SortDirection>[] = [
    { value: 'ASC', label: '↓' },
    { value: 'DESC', label: '↑' },
];

const ReportSorting = ({ sorts, columns, onChange }: Props) => {
    const addSort = (key: string) => onChange([...sorts, { orderBy: key, direction: 'DESC' }]);

    const options: ButtonDropdownOption[] = columns
        .filter((c) => !sorts.some((sort) => sort.orderBy === c.key))
        .map(({ key, name }) => ({ key, label: name, action: () => addSort(key) }));

    return (
        <>
            {!!sorts.length && <p className="mt-0 mb-3 text-muted fw-bold">Sort by:</p>}
            <div className="d-flex flex-wrap" style={{ gap: '1rem' }}>
                {sorts.map((sort, index) => (
                    <SelectWithAddon
                        className="w-auto"
                        key={index}
                        name={columns.find((c) => c.key === sort.orderBy)?.name ?? sort.orderBy}
                        value={sort.direction}
                        options={DIRECTION_OPTIONS}
                        selectStyle={{ minWidth: 90 }}
                        onChange={(direction: SortDirection) =>
                            onChange(
                                direction
                                    ? arrayItemUpdate(sorts, index, { ...sort, direction })
                                    : arrayItemRemove(sorts, index)
                            )
                        }
                    />
                ))}
                <AddItemButton label="Add sorting" options={options} />
            </div>
        </>
    );
};

export default ReportSorting;
