import { SystemPromptFormModel } from 'platform/chat/chat.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

type Props = FormProps<SystemPromptFormModel> & { id?: number };

const SystemPromptForm = ({ id, labels, onCancel, formikProps: { submitForm }, canEdit }: Props) => (
    <CardForm
        title={`${labels.prefix} DeeplyAI System Prompt`}
        subtitle={id ? `ID: ${id}` : null}
        submitLabel={labels.submit}
        onCancel={onCancel}
        disabled={!canEdit}
        onSubmit={submitForm}
    >
        <ControlledCard title="General">
            <FormRow label="Name">
                <FormInput name="name" validate={required} />
            </FormRow>
            <FormRow label="Key">
                <FormInput name="key" validate={required} />
            </FormRow>
            <FormRow label="Prompt">
                <FormInput name="prompt" type="textarea" rows={15} validate={required} />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default SystemPromptForm;
