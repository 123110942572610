import { RootState } from 'platform/rootState.type';
import { TaskBoard } from '../task.types';

export interface TaskReducerState {
    activeBoards: TaskBoard[];
}

const initialState: TaskReducerState = {
    activeBoards: [],
};

const SET_ACTIVE_BOARDS = 'task-actions/SET_ACTIVE_BOARDS';

type Action = {
    type: typeof SET_ACTIVE_BOARDS;
    payload: TaskBoard[];
};

export const taskReducer = (state: TaskReducerState = initialState, action: Action): TaskReducerState => {
    switch (action.type) {
        case SET_ACTIVE_BOARDS:
            return { activeBoards: action.payload };
        default:
            return state;
    }
};

export const taskActions = {
    setActiveBoards: (payload: TaskBoard[]): Action => ({ type: SET_ACTIVE_BOARDS, payload }),
};

export const taskSelectors = {
    activeBoards: (state: RootState): TaskBoard[] => state.taskReducer.activeBoards,
};
