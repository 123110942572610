import { useParams } from 'react-router-dom';
import { DataSharingTemplate } from 'platform/adminPanel/adminPanel.types';
import {
    fetchDataSharingTemplate,
    updateDataSharingTemplate,
} from 'platform/adminPanel/services/segmentSharingTemplate.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import SegmentSharingTemplateForm from './SegmentSharingTemplateForm';

const SegmentSharingTemplateEdit = ({ redirectTo, canEdit, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const handleOpen = async (): Promise<DataSharingTemplate> => fetchDataSharingTemplate(id);

    const handleSubmit = (model: DataSharingTemplate) => updateDataSharingTemplate(id, model);

    return (
        <FormContainer
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit
            sidePanel
            helpKey="segment_sharing_template_form"
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <SegmentSharingTemplateForm {...props} />}
        </FormContainer>
    );
};

export default SegmentSharingTemplateEdit;
