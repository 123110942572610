import axiosBff from 'platform/axios/axiosBff';
import { saveDownloadedFile } from 'platform/common/utils/file.util';
import { catchNonTransientErrors } from 'platform/common/utils/http.util';
import {
    ObserverMessage,
    ObserverMessageChange,
    ObserverMessageChangeDetails,
    ObserverMessageFilter,
} from './observerMessageCenter.types';

export const getObserverMessages = (params: ObserverMessageFilter) =>
    axiosBff.get<ObserverMessage[]>('/observer/api/messages', { params }).then((r) => r.data);

export const countObserverMessagesByAdvertiser = (params: ObserverMessageFilter) =>
    axiosBff
        .get<{ advertiserId: number; count: number }[]>('/observer/api/messages/count/by-advertiser', {
            params,
        })
        .then((r) => r.data);

export const countObserverMessages = (params: ObserverMessageFilter) =>
    axiosBff
        .get<{ count: number }>('/observer/api/messages/count/total', {
            params,
            errorHandledByComponent: true,
        })
        .then((r) => r.data.count)
        .catch(catchNonTransientErrors);

export const updateObserverMessage = (id: number, payload: ObserverMessageChangeDetails) =>
    axiosBff.patch(`/observer/api/messages/${id}`, payload);

export const getObserverMessageChanges = (id: number) =>
    axiosBff.get<ObserverMessageChange[]>(`/observer/api/messages/${id}/changes`).then((r) => r.data);

export const downloadAttachment = async (id: number) =>
    axiosBff
        .get<Blob>(`/observer/api/messages/${id}/attachment`, { responseType: 'blob' })
        .then(saveDownloadedFile);
