import { Button } from 'reactstrap';
import { FieldArray, FormikProps } from 'formik';
import { partition, uniqueId } from 'lodash-es';
import { DataSharingTemplate } from 'platform/adminPanel/adminPanel.types';
import VendorBadges from 'platform/advertiserTag/components/ThirdPartyRules/VendorBadges';
import { Advertiser } from 'platform/advertisers/advertiser.types';
import 'platform/common/components/CodeMirror/CodeMirror.scss';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import { entitiesToOptions } from 'platform/common/utils/option.util';
import { nonNegativeNumber, required } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { Vendor } from 'platform/vendors/vendors.types';
import {
    DataSharingRuleSnippetModel,
    Segment,
    SnippetType,
    ThirdPartyCodesFormValues,
    ThirdPartyTrackingRuleModel,
} from '../../advertiserTag.types';
import { FIRE_PIXEL_OPTIONS, FirePixelType } from '../../constants/advertiserTag.constant';
import DataSharingRuleCodeSnippets from './DataSharingRuleCodeSnippets';

type Props = {
    rule: ThirdPartyTrackingRuleModel;
    index: number;
    templates: DataSharingTemplate[];
    vendors: Vendor[];
    advertiser: Advertiser;
    segments: Segment[];
    onRemove?: (index: number) => void;
    setFieldValue: FormikProps<ThirdPartyCodesFormValues>['setFieldValue'];
};

const DataSharingRule = (props: Props) => {
    const { rule, index, templates, vendors, advertiser, onRemove, setFieldValue, segments = [] } = props;
    const fieldBase = `rules.${index}`;
    const segmentOptions = entitiesToOptions(segments);
    const includedSegmentsFieldName = `${fieldBase}.includedSegments`;
    const excludedSegmentsFieldName = `${fieldBase}.excludedSegments`;

    const addAllIncludedEvents = () => {
        setFieldValue(
            includedSegmentsFieldName,
            segments.map((item) => item.id)
        );
    };

    const addAllExcludedEvents = () => {
        setFieldValue(
            excludedSegmentsFieldName,
            segments.map((item) => item.id)
        );
    };

    const [activeSnippets, inactiveSnippets] = partition(
        rule.codeSnippets,
        (s) => (s as DataSharingRuleSnippetModel).active
    );

    const addCodeSnippet = (push: (obj: any) => void) => {
        push({
            tempId: uniqueId('DataSharingRule-CodeSnippet'),
            snippetType: SnippetType.DATA_SHARING,
            active: true,
            globalVariables: [],
            userVariables: [],
        });
    };

    return (
        <ControlledCard
            useCollapse={false}
            key={rule.tempId}
            title={
                <>
                    <div>{rule.ruleName}</div>
                    <VendorBadges
                        label="Active"
                        color={rule.active ? 'primary' : 'secondary'}
                        snippets={activeSnippets}
                        vendors={vendors}
                    />
                    <VendorBadges
                        label="Inactive"
                        color="secondary"
                        snippets={inactiveSnippets}
                        vendors={vendors}
                    />
                </>
            }
            isExpanded={rule.isNew}
            onRemove={onRemove ? () => onRemove(index) : undefined}
        >
            <FormRow label="Name">
                <FormInput name={`${fieldBase}.ruleName`} validate={required} />
            </FormRow>
            <FormRow label="Description">
                <FormInput name={`${fieldBase}.description`} />
            </FormRow>
            <FormRow label="Active">
                <FormSwitch name={`${fieldBase}.active`} />
            </FormRow>
            <FormRow label="Priority">
                <FormNumberInput name={`${fieldBase}.priority`} validate={nonNegativeNumber} />
            </FormRow>
            <FormRow label="Fire">
                <FormButtonArray name={`${fieldBase}.firePixels`} buttons={FIRE_PIXEL_OPTIONS} />
            </FormRow>
            <FormRow label="Unique per User / Day">
                <FormSwitch name={`${fieldBase}.fireOncePerDay`} />
            </FormRow>
            {rule.firePixels === FirePixelType.CUSTOM && (
                <>
                    <FormRow label="Include">
                        <FormSelect
                            name={includedSegmentsFieldName}
                            options={segmentOptions}
                            validate={required}
                            isMulti
                        />
                        <LabelLink onClick={addAllIncludedEvents}>Select all available events</LabelLink>
                    </FormRow>
                    <FormRow label="Exclude">
                        <FormSelect name={excludedSegmentsFieldName} options={segmentOptions} isMulti />
                        <LabelLink onClick={addAllExcludedEvents}>Select all available events</LabelLink>
                    </FormRow>
                </>
            )}
            <FieldArray name={`${fieldBase}.codeSnippets`}>
                {({ push, remove, name, form }) => (
                    <>
                        <DataSharingRuleCodeSnippets
                            vendors={vendors}
                            fieldName={name}
                            onCodeSnippetRemove={(snippetIndex: number) => remove(snippetIndex)}
                            advertiser={advertiser}
                            templates={templates}
                            setFieldValue={setFieldValue}
                            ruleIsActive={rule.active}
                            codeSnippets={form.values.rules[index].codeSnippets}
                        />
                        <Button color="primary" onClick={() => addCodeSnippet(push)}>
                            Add code
                        </Button>
                    </>
                )}
            </FieldArray>
        </ControlledCard>
    );
};

export default DataSharingRule;
