import { Monetization } from 'platform/campaign/campaign/campaign.types';
import { CampaignClassifications } from 'platform/campaign/campaign/campaignClassification.types';
import { SelectItem } from 'platform/common/common.types';

export type GlobalCostSourcePreset = {
    id?: number;
    name: string;
    advertiserId: number;
    monetization: Monetization;
    channel?: string;
    productLabel?: SelectItem<string>;
};

export type DefaultCostSourceType = {
    advertiserId: number;
    monetizationId: number;
};

export type GlobalCostSourceListModel = {
    id: number;
    name: string;
    agencyFee: number;
    channel?: string;
    channelDefault: boolean;
};

export type GlobalCostSourceApiModel = {
    id?: number;
    name: string;
    advertiserId: number;
    monetization: Monetization;
    channel?: string;
    channelDefault?: boolean;
    productLabel?: string;
};

export type CustomActionRule = {
    dimension?: number;
    expression: CustomActionRuleExpression;
};

export type CustomActionRuleExpression = {
    and?: CustomActionRuleExpression[];
    or?: CustomActionRuleExpression[];
    not?: CustomActionRuleExpression[];
    condition?: Record<string, any>;
};

export type CustomActionRuleListModel = {
    id: number;
    name: string;
    advertiserId?: number;
    ruleSet?: {
        default?: number;
        rules?: CustomActionRule[];
    };
};

export type CustomActionRuleApiModel = {
    id: number;
    name: string;
    advertiserId: number;
    ruleSet: {
        default?: number;
        rules: CustomActionRule[];
    };
};

export enum Expression {
    CONTAINS = 'CONTAINS',
    EQUALS = 'EQUALS',
    REGEX = 'REGEX',
}

type ScoringMetric = {
    metric: string;
    weight: number;
};

type ScoringRule = {
    classification: CampaignClassifications | undefined;
    metrics: ScoringMetric[];
};

export type Scoring = {
    id?: number;
    name: string;
    rules: ScoringRule[];
    createdOn?: string;
    updatedOn?: string;
    updatedBy?: string;
};

export type ScoringFormRule = {
    classification?: string[];
    metrics: ScoringMetric[];
};

export type ScoringFormModel = {
    id?: number;
    name: string;
    rules: ScoringFormRule[];
};
