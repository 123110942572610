import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { range } from 'lodash-es';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { required } from 'platform/common/utils/validators.util';
import FormDatePicker from 'platform/formik/FormDatePicker/FormDatePicker';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { CREATIVE_FORM_HELP_KEY } from '../CreativeForm/CreativeFormContainer';

const getTimeOptions = (minutes: number) =>
    range(0, 24).map((hours) => {
        const label = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        return { label, value: label };
    });

const startTimeOptions = getTimeOptions(0);
const endTimeOptions = getTimeOptions(59);

const CreativeRuntimeFields = ({ field }: { field: string }) => {
    const advertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    return (
        <>
            <FormRow label="Start" helpKey={`${CREATIVE_FORM_HELP_KEY}_runtime_start`}>
                <div className="d-flex justify-content-between">
                    <div>
                        <FormDatePicker
                            name={`${field}.startDate`}
                            placeholder="Date..."
                            validate={required}
                        />
                    </div>
                    <div>
                        <FormSelect
                            name={`${field}.startTime`}
                            options={startTimeOptions}
                            placeholder="Time..."
                            validate={required}
                        />
                    </div>
                </div>
            </FormRow>
            <FormRow label="End" helpKey={`${CREATIVE_FORM_HELP_KEY}_runtime_end`}>
                <div className="d-flex justify-content-between">
                    <div>
                        <FormDatePicker name={`${field}.endDate`} placeholder="No end date..." isClearable />
                    </div>
                    <div>
                        <FormSelect
                            name={`${field}.endTime`}
                            options={endTimeOptions}
                            placeholder="Time..."
                        />
                    </div>
                </div>
            </FormRow>
            <FormRow>
                <Alert color="warning">
                    Time is set in {advertiser.regionalSettings.timeZoneId} timezone
                </Alert>
            </FormRow>
        </>
    );
};

export default CreativeRuntimeFields;
