import { AxiosResponse } from 'axios';
import contentDisposition from 'content-disposition';
import FileSaver from 'file-saver';
import { get } from 'lodash-es';
import axiosBff from '../../axios/axiosBff';
import { Status } from '../../common/constants/status.constant';
import {
    AdvertiserTag,
    AdvertiserTagLite,
    AdvertiserTagTrackersInfo,
    Segment,
    TagLiteTrackingCodeRequest,
    ThirdPartyTrackingRule,
} from '../advertiserTag.types';

export const fetchAdvertiserTags = (params: { advertiserId: number; state?: Status }) =>
    axiosBff
        .get<AdvertiserTag[]>('/segmentation/api/advertiser-tags', { params })
        .then((response) => response.data);

export const fetchAdvertiserTag = (id: number) =>
    axiosBff.get<AdvertiserTag>(`/segmentation/api/advertiser-tags/${id}`).then((response) => response.data);

export const fetchAdvertiserTagTrackersInfo = () =>
    axiosBff
        .get<AdvertiserTagTrackersInfo>(`/segmentation/api/advertiser-tags/trackers-info`)
        .then((response) => response.data);

export const createAdvertiserTag = (advertiserTag: Partial<AdvertiserTag>) =>
    axiosBff.post('/segmentation/api/advertiser-tags', advertiserTag).then((response) => response.data);

export const updateAdvertiserTag = (advertiserTag: Partial<AdvertiserTag>) =>
    axiosBff.put('/segmentation/api/advertiser-tags', advertiserTag).then((response) => response.data);

export const updateAdvertiserTagState = (id: number, advertiserTag: { state: Status }) =>
    axiosBff
        .patch(`/segmentation/api/advertiser-tags/${id}/state`, advertiserTag)
        .then((response) => response.data);

export const updateBatchedSegments = (advertiserId: number, segments: Partial<Segment>[]) =>
    axiosBff
        .post(`/segmentation/api/batched-segments/${advertiserId}`, segments)
        .then((response) => response.data);

export const fetchStandardEvents = () =>
    axiosBff.get('/segmentation/api/standard-events').then((response) => response.data);

export const fetchSegments = (params: { advertiserTagId: number }) =>
    axiosBff.get<Segment[]>('/segmentation/api/segments', { params }).then((response) => response.data);

export const fetchAdvertiserTagLite = (advertiserTagId: number) =>
    axiosBff
        .get<AdvertiserTagLite>(`/segmentation/api/advertiser-tags/${advertiserTagId}/lite`)
        .then((response) => response.data);

export const updateActionMap = (advertiserTagId: number, data: any) =>
    axiosBff.patch(`/segmentation/api/advertiser-tags/${advertiserTagId}/lite/action-map`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const updateIntegrationMap = (advertiserTagId: number, data: any) =>
    axiosBff.patch(`/segmentation/api/advertiser-tags/${advertiserTagId}/lite/integration-map`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const updateCustomVar = (advertiserTagId: number, data: any) =>
    axiosBff.patch(`/segmentation/api/advertiser-tags/${advertiserTagId}/lite/custom-var-config`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const fetchThirdPartyTrackingRules = (tagId: number) =>
    axiosBff
        .get<{ rules: ThirdPartyTrackingRule[] }>(
            `/segmentation/api/advertiser-tags/${tagId}/third-party-tracking-rules`
        )
        .then((response) => response.data.rules);

export const saveThirdPartyTrackingRules = (tagId: string, rules: ThirdPartyTrackingRule[]) =>
    axiosBff.post(`/segmentation/api/advertiser-tags/${tagId}/third-party-tracking-rules`, { rules });

const suggestedFilename = (fileResponse: AxiosResponse<string>) => {
    const header: string = fileResponse.headers['content-disposition'];
    // if filename contains () symbols, parsing throws error
    const normalizedHeader = header.replace(/[()]/g, '_');
    const cd = contentDisposition.parse(normalizedHeader);
    return get(cd, 'parameters.filename');
};

export const downloadJsonLoader = async (advertiserTagId: string, model: TagLiteTrackingCodeRequest) => {
    const res = await axiosBff.post<string>(
        `/segmentation/api/advertiser-tags/${advertiserTagId}/lite/tracking-code`,
        model,
        {
            responseType: 'blob',
        }
    );

    const filename = suggestedFilename(res) || 'loader.zip';

    setTimeout(() => {
        FileSaver.saveAs(res.data, filename);
    }, 0);
};

export const downloadJavascriptLoader = async ({
    advertiserTagId,
    segmentIds = [],
}: {
    advertiserTagId: number;
    segmentIds?: number[];
}) => {
    const query = {
        segmentId: segmentIds.length ? segmentIds : undefined,
    };
    const res = await axiosBff.get<string>(
        `/segmentation/api/advertiser-tags/${advertiserTagId}/tracking-code`,
        {
            params: query,
            responseType: 'blob',
        }
    );

    const filename = suggestedFilename(res) || 'loader.txt';

    setTimeout(() => {
        // Need to add setTimeout, for Safari.
        // For some reason save does not work if during that time transition to other route happens
        // In this case transition to list page after submit
        FileSaver.saveAs(res.data, filename);
    }, 0);
};

export const downloadIframeLoader = async ({
    advertiserTagId,
    segmentIds = [],
}: {
    advertiserTagId: number;
    segmentIds?: number[];
}) => {
    const query = {
        segmentId: segmentIds.length ? segmentIds : undefined,
    };
    const res = await axiosBff.get<string>(
        `/segmentation/api/advertiser-tags/${advertiserTagId}/iframe-loader-code`,
        {
            params: query,
            responseType: 'blob',
        }
    );

    const filename = suggestedFilename(res) || 'loader.txt';

    setTimeout(() => {
        // Need to add setTimeout, for Safari.
        // For some reason save does not work if during that time transition to other route happens
        // In this case transition to list page after submit
        FileSaver.saveAs(res.data, filename);
    }, 0);
};

export const reuploadAdvertiserTags = (ids: number[]) =>
    axiosBff
        .patch(`/segmentation/api/advertiser-tags/reupload`, {}, { params: { advertiserTagIds: ids } })
        .then((response) => response.data);
