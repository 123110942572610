import { CampaignModel } from 'platform/campaign/campaign/campaign.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';

type Props = {
    campaign: CampaignModel;
    onDelete: () => Promise<void>;
};

const CampaignAdvancedSettings = ({ campaign, onDelete }: Props) => {
    const showConfirmationModal = useConfirmationModal();

    return (
        <ControlledCard title="Advanced" isExpanded={false} bodyClassName="form-content">
            <AnimatedButton
                onClick={() =>
                    showConfirmationModal(onDelete, {
                        title: 'Delete this campaign?',
                        text: `This will delete the campaign, together with its strategies,
                            units, creative set, and all ${campaign.vendorSystem} mappings.
                            This action cannot be undone. Do you want to proceed?`,
                    })
                }
            >
                Delete campaign
            </AnimatedButton>
        </ControlledCard>
    );
};

export default CampaignAdvancedSettings;
