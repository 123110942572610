import { useState } from 'react';

export type TableSortDirection = 'asc' | 'desc';

export interface TableSort<T> {
    column: keyof T;
    direction: TableSortDirection;
}

const useTableSort = <T>(initialSort: TableSort<T>) => {
    const [sort, setSort] = useState<TableSort<T>>(initialSort);

    const handleSort = (key: keyof T) => {
        setSort((prev) => ({
            column: key,
            direction: prev.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    return { sort, handleSort };
};

export default useTableSort;
