import { LabelClassification } from 'platform/campaign/campaign/campaignClassification.types';
import { isNotEmpty } from 'platform/common/common.types';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import useToggle from 'platform/common/hooks/useToggle';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { useLabelClassification } from '../../useLabelClassification';

interface Props {
    field: string;
    values: LabelClassification | undefined;
    onChange: (key: string, value: any) => void;
    labelWidth?: number;
    labelClassName?: string;
    bodyClassName?: string;
    disabled?: boolean;
}

const LogBookClassificationCard = ({
    field,
    values,
    onChange,
    labelWidth,
    labelClassName,
    bodyClassName,
    disabled = false,
}: Props) => {
    const initiallyExpanded = !!(
        values?.productCategory ||
        values?.productLabel ||
        values?.productSubLabel ||
        values?.flightLabel ||
        values?.countryLabels?.length
    );
    const [expanded, toggleExpanded] = useToggle(initiallyExpanded);
    const { productCategories, products, subLabels, flightLabels, countryOptions, labelsLoading } =
        useLabelClassification({
            selectedProductCategories: [values?.productCategory].filter(isNotEmpty),
            selectedProductLabels: [values?.productLabel].filter(isNotEmpty),
        });

    return (
        <ControlledCard
            isExpanded={expanded}
            onToggle={toggleExpanded}
            bodyClassName={bodyClassName}
            title="Classification"
            subtitle={'(Optional)'}
        >
            {labelsLoading && <OverlayLoader />}
            <FormRow labelWidth={labelWidth} labelClassName={labelClassName} label="Product category">
                <FormSelect
                    labelKey="name"
                    valueKey="name"
                    name={`${field}.productCategory`}
                    options={productCategories}
                    disabled={disabled}
                />
            </FormRow>
            <FormRow labelWidth={labelWidth} labelClassName={labelClassName} label="Product">
                <FormSelect
                    labelKey="name"
                    valueKey="name"
                    name={`${field}.productLabel`}
                    options={products}
                    disabled={disabled}
                    onChange={() => onChange(`${field}.productSubLabel`, null)}
                />
            </FormRow>
            {!!subLabels.length && (
                <FormRow labelWidth={labelWidth} labelClassName={labelClassName} label="Sub-product">
                    <FormSelect
                        labelKey="name"
                        valueKey="name"
                        name={`${field}.productSubLabel`}
                        options={subLabels}
                        disabled={disabled}
                    />
                </FormRow>
            )}
            <FormRow labelWidth={labelWidth} labelClassName={labelClassName} label="Flight">
                <FormSelect
                    labelKey="name"
                    valueKey="name"
                    name={`${field}.flightLabel`}
                    options={flightLabels}
                    disabled={disabled}
                />
            </FormRow>
            <FormRow labelWidth={labelWidth} labelClassName={labelClassName} label="Country">
                <FormSelect
                    isMulti
                    name={`${field}.countryLabels`}
                    options={countryOptions}
                    disabled={disabled}
                />
            </FormRow>
        </ControlledCard>
    );
};

export default LogBookClassificationCard;
