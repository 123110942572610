import { Feature } from 'platform/app/app.types';
import { isDefined } from 'platform/common/common.types';

export type Module =
    | 'IMPORTER'
    | 'MEDIAPLAN'
    | 'LOGBOOK_MANAGEMENT'
    | 'LOGBOOK_BUDGET_SHIFTING'
    | 'LOGBOOK_OPTIMIZATION'
    | 'ALGORITHM'
    | 'ALGORITHM_HISTORY'
    | 'ALGORITHM_PREVIEW'
    | 'SUPERUSER'
    | 'ADVERTISER_SETTINGS'
    | 'ADVERTISERS'
    | 'CAMPAIGNS'
    | 'CHANGELOG'
    | 'CREATIVES'
    | 'ANALYTICS'
    | 'CENTRAL_ANALYTICS'
    | 'CUSTOM_ANALYTICS'
    | 'CUSTOM_DASHBOARDS'
    | 'BUDGET'
    | 'OPTIMIZATION'
    | 'FINANCE_ANALYTICS'
    | 'MANAGEMENT_ANALYTICS'
    | 'TESTING_ANALYTICS'
    | 'MONITORING_ANALYTICS'
    | 'ANALYTICS_PERSONAL_SECTION'
    | 'ANALYTICS_CUSTOM_FOLDERS'
    | 'ANALYTICS_FILTERS_EDIT'
    | 'ANALYTICS_INLINE_ACTIONS'
    | 'CUSTOMER_JOURNEY'
    | 'SEGMENTS'
    | 'ADVERTISER_TAG'
    | 'ADMIN_FEATURES'
    | 'SENSITIVE_DATA'
    | 'SPEND_DATA'
    | 'USER_MANAGEMENT'
    | 'TASK_MANAGEMENT'
    | 'TASK_BOARD_MANAGEMENT'
    | 'DEFAULT_CHAT'
    | 'CHAT_BETA_TESTING'
    | 'CHAT_AGENTS'
    | 'CHAT_PROMPTS'
    | 'CHAT_MANAGEMENT'
    | 'PRODUCT_ACTION_PROBABILITY'
    | 'OBSERVER_MESSAGE_CENTER'
    | 'WIKI'
    | 'WIKI_SPACE_ACADEMY'
    | 'WIKI_SPACE_MEDIA_MANAGEMENT'
    | 'WIKI_SPACE_PLATFORM'
    | 'HELP';

type ModuleConfig = {
    features: { view?: Feature; edit?: Feature };
    dependencies?: { view?: Feature[]; edit?: Feature[] };
    title: string;
    description?: { header: string; content?: string[] };
};

export const MODULE_CONFIG: Record<Module, ModuleConfig> = {
    IMPORTER: {
        features: { view: 'IMPORTER' },
        dependencies: {
            view: ['CAMPAIGNS_EDIT'],
        },
        title: 'Importer',
    },
    MEDIAPLAN: {
        features: { view: 'MEDIAPLAN_VIEW', edit: 'MEDIAPLAN_EDIT' },
        title: 'Mediaplan',
    },
    LOGBOOK_MANAGEMENT: {
        features: { view: 'LOGBOOK_VIEW', edit: 'LOGBOOK_EDIT' },
        dependencies: {
            view: ['MEDIAPLAN_VIEW'],
        },
        title: 'All Log Book entries',
    },
    LOGBOOK_BUDGET_SHIFTING: {
        features: { view: 'LOGBOOK_BUDGET_SHIFTING_VIEW' },
        dependencies: {
            view: ['MEDIAPLAN_VIEW'],
        },
        title: 'Budget shifting Log Book entries',
    },
    LOGBOOK_OPTIMIZATION: {
        features: { view: 'LOGBOOK_OPTIMIZATION_VIEW' },
        dependencies: {
            view: ['MEDIAPLAN_VIEW'],
        },
        title: 'Optimization Log Book entries',
    },
    ALGORITHM: {
        features: { view: 'ALGORITHM_VIEW', edit: 'ALGORITHM_EDIT' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
            edit: ['ALGORITHM_HISTORY', 'ALGORITHM_PREVIEW'],
        },
        title: 'Algorithm list',
        description: {
            header: 'View & Edit grants permission to:',
            content: [
                'Setup and modify algorithms',
                'See inactive algorithms',
                'Run algorithms',
                'View execution log',
            ],
        },
    },
    ALGORITHM_HISTORY: {
        features: { view: 'ALGORITHM_HISTORY' },
        dependencies: { view: ['ANALYTICS_VIEW'] },
        title: 'Algorithm history',
    },
    ALGORITHM_PREVIEW: {
        features: { view: 'ALGORITHM_PREVIEW' },
        dependencies: { view: ['ANALYTICS_VIEW'] },
        title: 'Algorithm preview',
    },
    SUPERUSER: {
        features: { edit: 'SUPERUSER' },
        title: 'Superuser features',
        description: {
            header: 'Allows to:',
            content: [
                'Save system reports in Analytics',
                'Add system dimensions and filters to reports',
                'Enable/disable Automated IO spend in Vendor',
                'Delete campaigns',
            ],
        },
    },
    ADVERTISER_SETTINGS: {
        features: { view: 'ADVERTISER_SETTINGS', edit: 'ADVERTISER_SETTINGS_EDIT' },
        title: 'Advertiser settings',
        description: {
            header: 'Allows access to:',
            content: [
                'Integrations',
                'Cost sources',
                'Product labels',
                'Product feeds',
                'Time offers',
                'Custom metrics',
                'Default metrics',
                'Analytics naming',
                'Advanced',
                'Custom action rules',
                'Scoring rules',
            ],
        },
    },
    ADVERTISERS: {
        features: { view: 'ADVERTISERS_VIEW', edit: 'ADVERTISERS_EDIT' },
        title: 'Advertisers',
    },
    CAMPAIGNS: {
        features: { view: 'CAMPAIGNS_VIEW', edit: 'CAMPAIGNS_EDIT' },
        title: 'Campaigns',
    },
    OBSERVER_MESSAGE_CENTER: {
        features: { edit: 'MESSAGE_CENTER_EDIT' },
        title: 'DeeplyAI Cockpit',
    },
    CHANGELOG: {
        features: { view: 'CHANGELOG_VIEW' },
        title: 'Change Log',
    },
    CREATIVES: {
        features: { view: 'CREATIVES_VIEW', edit: 'CREATIVES_EDIT' },
        title: 'Creatives',
    },
    ANALYTICS: {
        features: { view: 'ANALYTICS_VIEW', edit: 'ANALYTICS_EDIT' },
        dependencies: {
            edit: ['ANALYTICS_FILTERS_EDIT'],
        },
        title: 'Analytics',
    },
    CENTRAL_ANALYTICS: {
        features: { view: 'CENTRAL_ANALYTICS' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Central analytics',
    },
    CUSTOM_ANALYTICS: {
        features: { view: 'CUSTOM_ANALYTICS' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Custom analytics',
    },
    CUSTOM_DASHBOARDS: {
        features: { view: 'CUSTOM_DASHBOARDS' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Custom dashboards',
    },
    BUDGET: {
        features: { view: 'BUDGET' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Budget',
    },
    OPTIMIZATION: {
        features: { view: 'OPTIMIZATION' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Optimization',
    },
    FINANCE_ANALYTICS: {
        features: { view: 'FINANCE_ANALYTICS' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Finance',
    },
    MANAGEMENT_ANALYTICS: {
        features: { view: 'MANAGEMENT_ANALYTICS' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Management',
    },
    TESTING_ANALYTICS: {
        features: { view: 'TESTING_ANALYTICS' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Testing',
    },
    MONITORING_ANALYTICS: {
        features: { view: 'MONITORING_ANALYTICS' },
        dependencies: {
            view: ['ANALYTICS_VIEW'],
        },
        title: 'Monitoring',
    },
    ANALYTICS_PERSONAL_SECTION: {
        features: { edit: 'ANALYTICS_PERSONAL_SECTION_EDIT' },
        dependencies: {
            edit: ['ANALYTICS_VIEW'],
        },
        title: 'Personal section',
    },
    ANALYTICS_CUSTOM_FOLDERS: {
        features: { edit: 'CUSTOM_FOLDER_EDIT' },
        dependencies: {
            edit: ['ANALYTICS_VIEW'],
        },
        title: 'Custom folders',
    },
    ANALYTICS_FILTERS_EDIT: {
        features: { edit: 'ANALYTICS_FILTERS_EDIT' },
        dependencies: {
            edit: ['ANALYTICS_VIEW'],
        },
        title: 'Analytics filters',
    },
    ANALYTICS_INLINE_ACTIONS: {
        features: { edit: 'ANALYTICS_INLINE_ACTIONS' },
        dependencies: {
            edit: ['ANALYTICS_VIEW'],
        },
        title: 'Analytics inline actions',
        description: {
            header: 'Allows to edit campaigns directly from Analytics.',
        },
    },
    CUSTOMER_JOURNEY: {
        features: { view: 'CUSTOMER_JOURNEY_VIEW' },
        title: 'Customer Journey',
    },
    SEGMENTS: {
        features: { view: 'SEGMENTS_VIEW', edit: 'SEGMENTS_EDIT' },
        title: 'Dashboard / Segments',
    },
    ADVERTISER_TAG: {
        features: { view: 'ADVERTISER_TAG_VIEW', edit: 'ADVERTISER_TAG_EDIT' },
        title: 'AdvertiserTag',
    },
    ADMIN_FEATURES: {
        features: { view: 'ADMIN_VIEW', edit: 'ADMIN_EDIT' },
        title: 'Common admin features',
        description: {
            header: 'Allows access to Admin section:',
            content: [
                'Admin panel',
                'Algorithm templates',
                'Categorization',
                'Channel management',
                'Creative settings',
                'DeeplyAI manager',
                'DeeplyAI internal agents',
                'Insights',
                'Observer',
                'Report management',
                'File sharing',
                'Vendor management',
            ],
        },
    },
    SENSITIVE_DATA: {
        features: { view: 'SENSITIVE_DATA' },
        title: 'Sensitive data',
        description: {
            header: 'Allows to view metrics:',
            content: ['Profit', 'Margin'],
        },
    },
    SPEND_DATA: {
        features: { view: 'SPEND_DATA' },
        title: 'Spend data',
        description: {
            header: 'Allows to view metrics:',
            content: ['Spend', 'Spend vattable', 'Search ads fee', 'Tracking fee'],
        },
    },
    USER_MANAGEMENT: {
        features: { edit: 'USER_MANAGEMENT' },
        title: 'User Management',
    },
    TASK_MANAGEMENT: {
        features: { edit: 'TASK_EDIT' },
        title: 'Task management',
        description: {
            header: 'Allows to create, edit and move tasks in a board',
        },
    },
    TASK_BOARD_MANAGEMENT: {
        features: { edit: 'TASK_BOARD_EDIT' },
        dependencies: { edit: ['TASK_EDIT'] },
        title: 'Board management',
        description: {
            header: 'Allows to create task boards and manage columns inside a board',
        },
    },
    DEFAULT_CHAT: {
        title: 'Default chat',
        features: { view: 'CHATBOT_DEFAULT_VIEW' },
    },
    CHAT_BETA_TESTING: {
        title: 'Chat beta testing',
        features: {
            view: 'CHATBOT_BETA_TESTING',
        },
    },
    CHAT_AGENTS: {
        features: {
            view: 'CHATBOT_AGENTS_VIEW',
        },
        title: 'Agents',
    },
    CHAT_PROMPTS: {
        features: {
            view: 'CHATBOT_PROMPTS_VIEW',
        },
        title: 'Prompts',
    },
    CHAT_MANAGEMENT: {
        features: {
            view: 'CHATBOT_MANAGEMENT_VIEW',
            edit: 'CHATBOT_MANAGEMENT_EDIT',
        },
        title: 'Management',
        description: {
            header: 'Allows to view and manage different chat features',
            content: ['Agents', 'Prompts', 'Context prompts'],
        },
    },
    PRODUCT_ACTION_PROBABILITY: {
        features: {
            view: 'PRODUCT_ACTION_PROBABILITY',
        },
        title: 'Products',
    },
    WIKI: {
        features: {
            view: 'WIKI_VIEW',
            edit: 'WIKI_EDIT',
        },
        title: 'Wiki',
        description: {
            header: 'Grants view or edit access to one or more Wiki spaces below',
        },
    },
    WIKI_SPACE_ACADEMY: {
        features: { view: 'WIKI_SPACE_ACADEMY' },
        dependencies: { view: ['WIKI_VIEW'] },
        title: 'Academy',
    },
    WIKI_SPACE_MEDIA_MANAGEMENT: {
        features: { view: 'WIKI_SPACE_MEDIA_MANAGEMENT' },
        dependencies: { view: ['WIKI_VIEW'] },
        title: 'Media Management',
    },
    WIKI_SPACE_PLATFORM: {
        features: { view: 'WIKI_SPACE_PLATFORM' },
        dependencies: { view: ['WIKI_VIEW'] },
        title: 'Platform',
    },
    HELP: {
        features: {
            view: 'HELP_VIEW',
            edit: 'HELP_EDIT',
        },
        title: 'Help',
    },
};

export const MODULE_GROUPS: Record<string, Module[]> = {
    'Ad Operations': [
        'ADVERTISERS',
        'CAMPAIGNS',
        'IMPORTER',
        'CREATIVES',
        'CHANGELOG',
        'ADVERTISER_SETTINGS',
    ],
    'DeeplyAI Cockpit': ['OBSERVER_MESSAGE_CENTER'],
    'Mediaplan & Log Book': [
        'MEDIAPLAN',
        'LOGBOOK_MANAGEMENT',
        'LOGBOOK_BUDGET_SHIFTING',
        'LOGBOOK_OPTIMIZATION',
    ],
    Algorithms: ['ALGORITHM', 'ALGORITHM_HISTORY', 'ALGORITHM_PREVIEW'],
    Audiences: ['SEGMENTS', 'PRODUCT_ACTION_PROBABILITY', 'ADVERTISER_TAG'],
    'Customer Journey': ['CUSTOMER_JOURNEY'],
    Analytics: [
        'ANALYTICS',
        'CENTRAL_ANALYTICS',
        'CUSTOM_ANALYTICS',
        'CUSTOM_DASHBOARDS',
        'BUDGET',
        'OPTIMIZATION',
        'FINANCE_ANALYTICS',
        'MANAGEMENT_ANALYTICS',
        'TESTING_ANALYTICS',
        'MONITORING_ANALYTICS',
        'ANALYTICS_CUSTOM_FOLDERS',
        'ANALYTICS_PERSONAL_SECTION',
        'ANALYTICS_FILTERS_EDIT',
        'ANALYTICS_INLINE_ACTIONS',
    ],
    'Task Boards': ['TASK_MANAGEMENT', 'TASK_BOARD_MANAGEMENT'],
    Admin: ['ADMIN_FEATURES', 'USER_MANAGEMENT', 'SUPERUSER'],
    Data: ['SENSITIVE_DATA', 'SPEND_DATA'],
    'Chat Bot': ['DEFAULT_CHAT', 'CHAT_AGENTS', 'CHAT_BETA_TESTING', 'CHAT_PROMPTS', 'CHAT_MANAGEMENT'],
    Wiki: ['WIKI', 'WIKI_SPACE_ACADEMY', 'WIKI_SPACE_MEDIA_MANAGEMENT', 'WIKI_SPACE_PLATFORM'],
    Other: ['HELP'],
};

export const MODULE_CONFIG_BY_FEATURE: { [key in Feature]?: ModuleConfig } = Object.fromEntries(
    Object.values(MODULE_CONFIG).flatMap((config): [Feature, ModuleConfig][] =>
        [config.features.view, config.features.edit].filter(isDefined).map((key) => [key, config])
    )
);

export const featureName = (feature: Feature) => {
    const module = MODULE_CONFIG_BY_FEATURE[feature];
    if (module) {
        return `${module.title}${feature === module.features.edit ? ' editing' : ''}`;
    }
    return feature;
};
