import { Chart as ChartJS, registerables, defaults } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { merge } from 'lodash-es';

ChartJS.register(...registerables, ChartDataLabels);

merge(defaults, {
    locale: 'en-US',
    plugins: {
        legend: {
            labels: {
                boxWidth: 6, // Smaller width for the color box
                boxHeight: 6, // Smaller height for the color box (optional, same as boxWidth if omitted)
                usePointStyle: true,
            },
        },
        datalabels: {
            display: false,
            align: 'top',
            color: '#767676',
        },
    },
    elements: {
        line: {
            tension: 0.4, // Set default tension for line charts
        },
    },
});
