import InsightList from 'platform/insight/InsightList';
import { INSIGHTS } from 'platform/insight/insight.navigation';

const routes = [
    {
        ...INSIGHTS,
        component: InsightList,
    },
];

export default routes;
