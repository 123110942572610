import { Alert } from 'reactstrap';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';

interface Props {
    report?: AnalyticsReport;
}

const ArchivedWarning = ({ report }: Props) => {
    if (report?.status !== 'ARCHIVED') {
        return null;
    }

    return (
        <Alert className="w-100" color="warning">
            Warning - Please be advised that this report requires your attention as it is currently using an
            archived version. It will soon be deactivated. We kindly request you to contact your designated
            Client Service representative for further assistance
        </Alert>
    );
};

export default ArchivedWarning;
