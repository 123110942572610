import { FormEvent, useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { useLoading } from 'platform/common/hooks/useLoading';
import { TaskCreationData } from '../task.types';

type Props = {
    onSubmit: (task: TaskCreationData) => Promise<void>;
    onCancel: () => void;
};

const KanbanSubCardAdder = ({ onSubmit, onCancel }: Props) => {
    const [name, setName] = useState('');
    const [saving, withSaving] = useLoading();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (name) {
            await withSaving(() => onSubmit({ name, status: 'ACTIVE' }));
        }
    };

    return (
        <Form className="position-relative d-flex align-items-center gap-2 mt-3 mb-0" onSubmit={handleSubmit}>
            {saving && <OverlayLoader />}
            <Input
                placeholder="Name"
                value={name}
                onChange={({ target }) => setName(target.value)}
                autoFocus
            />
            <Button color="primary" type="submit">
                Add
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
        </Form>
    );
};

export default KanbanSubCardAdder;
