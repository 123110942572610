import { useCallback, useMemo, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { OlapReport, ReportFilter } from 'platform/analytics/analytics.types';
import { getFilterNumberValue } from 'platform/analytics/analytics.util';
import CampaignStateIndicator from 'platform/campaign/campaign/components/CampaignStateIndicator';
import { CampaignState } from 'platform/campaign/campaign/constants/state.constant';
import { bulkUpdateCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { GlobalCostSourcePreset } from 'platform/campaign/globalPreset/globalPreset.types';
import { toPreset } from 'platform/campaign/globalPreset/mappers/globalPreset.mapper';
import { fetchAdvertiserCostSources } from 'platform/campaign/globalPreset/services/globalCostSourcePreset.service';
import ChannelLabel from 'platform/channels/components/ChannelLabel';
import { assertIsDefined } from 'platform/common/common.assert';
import { Period, TableCell } from 'platform/common/common.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { precisionRound } from 'platform/common/utils/formatters.util';
import { entitiesToOptions } from 'platform/common/utils/option.util';

interface Props {
    toggle: () => void;
    afterUpdate: () => void;
    filters: ReportFilter[];
    date: Period;
}

interface DataRow {
    advertiser_id: number;
    advertiser_name: string;
    campaign_id: number;
    campaign_name: string;
    campaign_runtime_from: string;
    campaign_runtime_to: string;
    campaign_product_label: string;
    campaign_state: CampaignState;
    cost_per_spend: string;
    channel: string;
}

const isCampaignSelected = (campaignId: number, selectedCampaigns: number[]) =>
    selectedCampaigns.includes(campaignId);

const BulkCampaignCostSourceModal = ({ toggle, afterUpdate, filters, date }: Props) => {
    const [selectedCampaigns, selectCampaigns] = useState<number[]>([]);
    const [monetizationId, setMonetizationId] = useState<number | undefined>(undefined);

    const [{ loading, data, error }] = usePromise<OlapReport<DataRow> | undefined>(
        undefined,
        () =>
            generateOlapReport({
                templateId: 'all_columns',
                dimensions: [
                    'advertiser_id',
                    'advertiser_name',
                    'campaign_id',
                    'campaign_name',
                    'campaign_runtime_from',
                    'campaign_runtime_to',
                    'campaign_product_label',
                    'campaign_state',
                    'cost_per_spend',
                    'channel',
                ],
                metrics: [],
                from: date.from,
                to: date.to,
                dimensionFilters: [...filters, { key: 'full_set', values: [true] }],
            }),
        [filters, date]
    );

    const onRowClick = useCallback(
        (campaignId: number) => {
            selectCampaigns(
                selectedCampaigns.includes(campaignId)
                    ? selectedCampaigns.filter((id) => id !== campaignId)
                    : [...selectedCampaigns, campaignId]
            );
        },
        [selectedCampaigns]
    );

    const onAllCampaignsClick = useCallback(() => {
        selectCampaigns(selectedCampaigns.length ? [] : data?.rows.map((row) => row.campaign_id) || []);
    }, [data, selectedCampaigns.length]);

    const ready = Boolean(!loading && selectedCampaigns.length);
    const advertiserId = getFilterNumberValue(filters, 'advertiser_id');
    assertIsDefined(advertiserId, 'filter with key advertiser_id');

    const onApply = async () => {
        await bulkUpdateCampaigns({
            campaigns: selectedCampaigns.map((id) => ({ id, monetizationId })),
        });
        afterUpdate();
        toggle();
    };

    const [{ data: globalCostSources = [], loading: monetizationLoading }] = usePromise(
        [],
        () =>
            fetchAdvertiserCostSources({
                advertiserId,
            }).then((loaded): GlobalCostSourcePreset[] => loaded.map((row) => toPreset(row))),
        [advertiserId]
    );
    const selectedCostSource = globalCostSources.find((m) => m.id === monetizationId);

    const columns = useMemo<TableColumn<DataRow>[]>(
        () => [
            {
                Header: () => (
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={!!selectedCampaigns.length}
                        onChange={onAllCampaignsClick}
                    />
                ),
                width: 70,
                headerClassName: 'cell-align-center',
                className: 'cell-align-center',
                Cell: ({ original: { campaign_id } }) => (
                    <Input
                        type="checkbox"
                        className="position-static m-0"
                        checked={isCampaignSelected(campaign_id, selectedCampaigns)}
                        readOnly
                        onChange={() => onRowClick(campaign_id)}
                    />
                ),
                sortable: false,
            },
            {
                Header: 'Advertiser name',
                autoWidth: true,
                Cell: ({ original: { advertiser_id, advertiser_name } }) => (
                    <LabelWithSubtext label={advertiser_name} subtext={DATA_TYPES.ID.format(advertiser_id)} />
                ),
            },
            {
                Header: 'Campaign name',
                accessor: (row: DataRow) => row.campaign_name,
                Cell: ({ original: { campaign_id, campaign_name, campaign_state } }) => (
                    <>
                        <CampaignStateIndicator className="me-2" state={campaign_state} />
                        <LabelWithSubtext label={campaign_name} subtext={DATA_TYPES.ID.format(campaign_id)} />
                    </>
                ),
                id: 'name',
                autoWidth: true,
            },
            {
                Header: 'Runtime from',
                accessor: (row: DataRow) => row.campaign_runtime_from,
                type: DATA_TYPES.TEXT,
                autoWidth: true,
                id: 'runtimeFrom',
            },
            {
                Header: 'Runtime to',
                accessor: (row: DataRow) => row.campaign_runtime_to,
                type: DATA_TYPES.TEXT,
                autoWidth: true,
                id: 'runtimeTo',
            },
            {
                Header: 'Product',
                accessor: (row: DataRow) => row.campaign_product_label,
                id: 'campaign_product_label',
            },
            {
                Header: 'Channel',
                accessor: 'channel',
                Cell: ({ original: { channel } }) => <ChannelLabel channel={channel} />,
                autoWidth: true,
                id: 'channel',
            },
            {
                Header: 'Agency Fee',
                autoWidth: true,
                Cell: ({ original: { cost_per_spend, campaign_id } }) =>
                    cost_per_spend && campaign_id ? (
                        <span>{`${precisionRound(Number(cost_per_spend) * 100, 2)}%`}</span>
                    ) : null,
            },
        ],
        [onAllCampaignsClick, onRowClick, selectedCampaigns]
    );

    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '95vw' }}>
            <ModalHeader onClose={toggle}>
                <div className="d-flex flex-row align-items-center">
                    <span className="ModalHeader-title">Select campaigns and cost sources</span>
                    <div className="d-flex ms-3">
                        <SelectWithAddon
                            name="Cost sources"
                            value={monetizationId}
                            options={entitiesToOptions(globalCostSources)}
                            selectStyle={{ width: '10rem' }}
                            onChange={setMonetizationId}
                            className="ms-3 pe-3"
                        />
                    </div>
                    {monetizationId && !monetizationLoading && (
                        <div className="ms-2 pe-3">{`Agency Fee: ${
                            selectedCostSource?.monetization.costPerSpendPercentage || 0
                        }%`}</div>
                    )}
                </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                <FormattedTable
                    NoDataComponent={error ? () => <ErrorMessage error={error} /> : undefined}
                    data={data?.rows || []}
                    loading={loading || monetizationLoading}
                    stickyHeader={false}
                    getTrProps={(_: any, rowInfo?: TableCell<DataRow>) => ({
                        className: classNames({
                            selected:
                                rowInfo &&
                                isCampaignSelected(rowInfo.original.campaign_id, selectedCampaigns),
                        }),
                    })}
                    columns={columns}
                />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between align-items-center">
                <div>{ready && `Update will affect ${selectedCampaigns.length} campaign(s)`}</div>
                <div>
                    <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <AnimatedButton disabled={!ready} onClick={onApply}>
                        Update
                    </AnimatedButton>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default BulkCampaignCostSourceModal;
