import { API_PATH } from 'platform/axios/axiosBff';

const MEDIA_ID_PREFIX = 'wiki-media-id-';

const getFileSrc = (fileId: string, authToken: string) =>
    `${API_PATH}/wiki/api/files/${fileId}/content?access_token=${authToken}`;

export const loadMedia = async (html: string, authToken: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const mediaElements = doc.querySelectorAll(
        `img[id^="${MEDIA_ID_PREFIX}"], video[id^="${MEDIA_ID_PREFIX}"]`
    );

    mediaElements.forEach((element: HTMLImageElement | HTMLVideoElement) => {
        const fileId = element.getAttribute('id')!.replace(`${MEDIA_ID_PREFIX}`, '');
        element.setAttribute('src', getFileSrc(fileId, authToken));
    });

    return doc.body.innerHTML;
};

// Tiny mce editor has internal blob cache
// On insert to editor we're assigning the cache id to the image/video element
// And pulling blob from cache on save
export const replaceEmbeddedMedia = async (
    html: string,
    uploadBlob: (blobId: string) => Promise<number | undefined>
) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const mediaElements = doc.querySelectorAll('img[id*="blobid"], video[id*="blobid"]');

    const uploadPromises = Array.from(mediaElements).map(
        async (element: HTMLImageElement | HTMLVideoElement) => {
            const blobId = element.getAttribute('id');

            if (!blobId) return;

            const fileId = await uploadBlob(blobId);

            if (!fileId) return;

            element.setAttribute('id', `${MEDIA_ID_PREFIX}${fileId}`);
            element.removeAttribute('src');
        }
    );

    await Promise.all(uploadPromises);

    return doc.body.innerHTML;
};
