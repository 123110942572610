import React, { useRef, useState, useEffect, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { NavigationItem } from 'platform/app/app.types';
import { useNavigationItems } from 'platform/app/components/Sidebar/useNavigationItems';
import './PageHeader.scss';

interface Props {
    title: ReactNode;
    parentTitle?: string;
    parentLink?: string;
    className?: string;
    collapsedContent?: ReactNode;
    breadcrumbs?: { name: string; icon?: string }[];
}

const findNavigationItem = (path: string, item: NavigationItem, breadcrumbs: NavigationItem[]) => {
    if (item.path && decodeURIComponent(path).includes(item.path)) {
        breadcrumbs?.unshift(item);
        return true;
    }

    if (item?.children?.length) {
        const foundInChildren = item.children.find((child) => findNavigationItem(path, child, breadcrumbs));
        if (foundInChildren) {
            breadcrumbs?.unshift(item);
            return true;
        }
    }

    return false;
};

const generateBreadcrumbsFromPath = (path: string, navigationItems: NavigationItem[]) => {
    const breadcrumbs: NavigationItem[] = [];
    navigationItems.forEach((item) => findNavigationItem(path, item, breadcrumbs));
    return breadcrumbs?.map((item) => ({ name: item?.name, icon: item?.icon })) || [];
};

const PageHeader = ({ title, parentTitle, parentLink, className, collapsedContent, breadcrumbs }: Props) => {
    const { navigationItems } = useNavigationItems();
    const location = useLocation();
    const currentLocationUrl = location.pathname + location.search;

    const pageBreadcrumbs = breadcrumbs ?? generateBreadcrumbsFromPath(currentLocationUrl, navigationItems);

    const PageIcon =
        pageBreadcrumbs
            ?.map((item) => item?.icon)
            .filter(Boolean)
            .pop() || undefined;

    const divRef = useRef<HTMLHeadingElement>(null);
    const [showMinimalHeading, setShowMinimalHeading] = useState(false);
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        };

        const callback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    setShowMinimalHeading(true);
                } else setShowMinimalHeading(false);
            });
        };

        const observer = new IntersectionObserver(callback, options);

        const headerContainer = divRef.current?.closest('.HeaderContainer');
        if (headerContainer) {
            observer.observe(headerContainer);
        }

        return () => {
            if (headerContainer) {
                observer.unobserve(headerContainer);
            }
        };
    }, []);

    const parent =
        parentTitle && parentLink ? (
            <>
                <Link to={parentLink}>{parentTitle}</Link>
                &nbsp;
                <i className="fa fa-angle-right" />
                &nbsp;
            </>
        ) : (
            parentTitle
        );

    return (
        <>
            <div
                className={classNames(
                    'PageHeader-heading d-flex justify-content-between w-100 align-items-center',
                    showMinimalHeading && 'active'
                )}
            >
                <div>
                    {pageBreadcrumbs.length > 0 ? (
                        pageBreadcrumbs.map((item, index) => (
                            <React.Fragment key={`${item.name}${index}`}>
                                {index > 0 && <span>{` > `}</span>}
                                {index === pageBreadcrumbs.length - 1 ? (
                                    <strong>{title || item.name}</strong>
                                ) : (
                                    item.name
                                )}
                            </React.Fragment>
                        ))
                    ) : (
                        <strong>{title}</strong>
                    )}
                </div>
                <div>{collapsedContent}</div>
            </div>
            <h4 className={classNames('PageHeader-title d-flex align-items-center', className)} ref={divRef}>
                {PageIcon && (
                    <div className="PageHeader-heading-icon">
                        {typeof PageIcon === 'string' ? <i className={PageIcon} /> : <PageIcon />}
                    </div>
                )}
                {parent}
                <span>{title}</span>
            </h4>
        </>
    );
};

export default PageHeader;
