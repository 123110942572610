import { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import './InsetBox.scss';

interface Props {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
}

const InsetBox = ({ children, className, style }: Props) => (
    <div style={style ?? {}} className={classNames('InsetBox', className)}>
        {children}
    </div>
);

export default InsetBox;
