import { Card } from 'reactstrap';
import SortableItem from 'platform/common/components/SortableItem/SortableItem';
import TaskTitle from 'platform/task/Common/TaskTitle';
import { Task, TaskData } from '../task.types';
import KanbanCardActionsDropdown from './KanbanCardActionsDropdown';
import KanbanCardFooter from './KanbanCardFooter';
import { KanbanSubCardProps } from './KanbanSubCard';

interface Props {
    onDrop: (moveFrom: TaskData, moveTo: TaskData) => void;
}

const KanbanSortableSubCard = ({
    boardId,
    subtask,
    position,
    totalSubtasks,
    userOptions,
    onClick,
    onMove,
    onUpdate,
    onDelete,
    onTaskWatchChange,
    onDrop,
}: Props & KanbanSubCardProps) => (
    <SortableItem<Task> className="mt-2" item={subtask} key={subtask.id} sortId="subtasks" onDrop={onDrop}>
        <Card className="Kanban-sub-card mt-1 mb-0" onClick={onClick}>
            <div className="Kanban-sortable-sub-card d-flex align-items-center">
                <i className="fal fa-grip-vertical me-3 SortableItem-dragIcon" />
                <TaskTitle task={subtask} />
                <div className="flex-fill d-flex justify-content-end">
                    <KanbanCardFooter
                        task={subtask}
                        userOptions={userOptions}
                        onTaskWatchChange={onTaskWatchChange}
                        onTaskUpdate={(_, changes) => onUpdate(changes)}
                    />
                </div>
                <KanbanCardActionsDropdown
                    className="ms-2"
                    boardId={boardId}
                    task={subtask}
                    currentPosition={position}
                    bottomPosition={totalSubtasks}
                    onMove={onMove}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                />
            </div>
        </Card>
    </SortableItem>
);

export default KanbanSortableSubCard;
