import { useParams } from 'react-router-dom';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import InsightForm from 'platform/insight/InsightForm/InsightForm';
import { fetchInsightReport, updateInsightReport } from 'platform/insight/insight.service';
import { InsightReport } from 'platform/insight/insight.types';

type Props = {
    advertiserOptions: { value: number; label: string }[];
} & FormContainerProps;

const InsightEdit = ({ canEdit, redirectTo, afterSubmit, advertiserOptions }: Props) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const handleOpen = () => fetchInsightReport(id);
    const handleSubmit = (data: InsightReport) => updateInsightReport(id, data);

    return (
        <FormContainer
            helpKey="insight_form"
            canEdit={canEdit}
            size={SIDE_PANEL_SIZES.WIDE}
            redirectTo={redirectTo}
            isEdit
            sidePanel
            onSubmit={handleSubmit}
            onOpen={handleOpen}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <InsightForm {...props} advertiserOptions={advertiserOptions} />}
        </FormContainer>
    );
};

export default InsightEdit;
