import { useState } from 'react';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash-es';
import AnalyticsReportFormRoutes from 'platform/analyticsReports/ReportManagement/AnalyticsReportFormRoutes';
import { useRefetchAnalyticsReports } from 'platform/analyticsReports/ReportManagement/useRefetchAnalyticsReports';
import ReportsTable from 'platform/analyticsReports/ReportsTable';
import { fetchAnalyticsReports } from 'platform/analyticsReports/analyticsReport.service';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { useReportTableColumns } from 'platform/analyticsReports/useReportTableColumns';
import { isDefined } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { activeAdvertiserSelectors, AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { entitiesToOptions } from 'platform/common/utils/option.util';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';

const getReportAdvertisersLabel = (
    seatIds: number[],
    savedAdvertiserIds: number[],
    advertisers: AdvertiserOption[]
) => {
    if (!seatIds.length && !savedAdvertiserIds.length) {
        return 'All';
    }

    const seatAdvertisers = seatIds
        .filter((id) => id !== ADMIN_SEAT_ID)
        .flatMap((id) => advertisers.filter((a) => a.seatId === id))
        .filter(isDefined);

    const savedAdvertisers = advertisers.filter((a) => savedAdvertiserIds.includes(a.id));

    if (seatAdvertisers.length || savedAdvertisers.length) {
        return uniq([...seatAdvertisers, ...savedAdvertisers].map((a) => a.name)).join(', ');
    }

    return seatIds.includes(ADMIN_SEAT_ID) ? 'Admin seat' : '-';
};

const ADVERTISERS_COLUMN_INDEX = 2;

interface Props {
    status: ActiveOrArchived[];
    canEdit: boolean;
    onReportChange: (report: AnalyticsReport) => Promise<void>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const SharedLinks = ({ status, canEdit, onReportChange, onStatusChange }: Props) => {
    const activeAdvertiserId = useSelector(activeAdvertiserSelectors.id);
    const [advertiserId, setAdvertiserId] = useState<number | undefined>(activeAdvertiserId);
    const advertisers = useSelector(activeAdvertiserSelectors.options);
    const columns = useReportTableColumns({ canEdit, onReportChange });

    const [{ data, loading }, refetchReports] = usePromise(
        [],
        () => fetchAnalyticsReports({ status, hasSection: false }),
        [advertiserId, status]
    );

    const { refetch } = useRefetchAnalyticsReports(refetchReports);

    const handleReportChange = async (report: AnalyticsReport) => {
        await onReportChange(report);
        refetch();
    };

    const links = advertiserId
        ? data.filter((report) => {
              const advertiserSeatId = advertisers.find((a) => a.id === advertiserId)?.seatId;
              return (
                  (advertiserSeatId && report.seatIds.includes(advertiserSeatId)) ||
                  report.advertiserIds?.includes(advertiserId)
              );
          })
        : data;

    const advertisersColumn: TableColumn<AnalyticsReport> = {
        Header: 'Advertisers',
        accessor: (report) =>
            getReportAdvertisersLabel(report.seatIds, report.advertiserIds ?? [], advertisers),
        type: DATA_TYPES.ID,
        width: 200,
        Cell: ({ original: report }) => (
            <CellWithEllipsis
                value={getReportAdvertisersLabel(report.seatIds, report.advertiserIds ?? [], advertisers)}
            />
        ),
    };

    return (
        <BodyContainer helpKey="shared_links">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
                <SelectWithAddon
                    name="Advertiser"
                    selectStyle={{ minWidth: 200 }}
                    value={advertiserId}
                    options={entitiesToOptions(advertisers)}
                    onChange={setAdvertiserId}
                />
            </ListFilters>
            <ReportsTable
                columns={[
                    ...columns.slice(0, ADVERTISERS_COLUMN_INDEX),
                    advertisersColumn,
                    ...columns.slice(ADVERTISERS_COLUMN_INDEX),
                ]}
                reports={links}
                loading={loading}
                canEdit={canEdit}
                onReportChange={handleReportChange}
            />
            <AnalyticsReportFormRoutes canEdit={canEdit} afterSubmit={refetch} />
        </BodyContainer>
    );
};

export default SharedLinks;
