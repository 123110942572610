import { Col } from 'reactstrap';
import classNames from 'classnames';
import { JourneyEventTypeCM360 } from 'platform/dashboard/dashboard.types';
import JourneyEventTypeIcon from '../JourneyEventTypeIcon';

interface Props {
    text?: string;
    subtitle: string;
    expanded: boolean;
    showChevron?: boolean;
    eventType: JourneyEventTypeCM360;
    toggleExpanded: () => void;
}

const JourneyRowMainBlock = ({
    text,
    subtitle,
    expanded,
    eventType,
    showChevron = true,
    toggleExpanded,
}: Props) => (
    <Col xs={3} className="d-flex align-items-center flex-wrap">
        <JourneyEventTypeIcon eventType={eventType} />

        <div className="ms-2 flex-grow-1">
            <div className="fw-bold mb-0 font-lg w-100 d-flex">
                {text}
                {showChevron && (
                    <i
                        tabIndex={0}
                        role="button"
                        className={classNames(
                            'text-muted ms-auto',
                            expanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
                        )}
                        onClick={() => toggleExpanded()}
                    />
                )}
            </div>
            <div className="text-muted text-nowrap">{subtitle}</div>
        </div>
    </Col>
);

export default JourneyRowMainBlock;
