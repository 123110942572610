import { push } from 'redux-first-history';
import { createAndEditStrategy } from 'platform/campaign/strategy/utils/strategy.util';
import { activeAdvertiserActions } from '../../../../common/ducks/activeAdvertiser.duck';
import { ActionConnect, ActionType, InlineActionConnect, RedirectActionConnect } from './InlineActions.types';

const registeredActions: ActionConnect[] = [
    { action: 'change_unit_state', type: ActionType.INLINE },
    { action: 'change_unit_bid', type: ActionType.INLINE },
    { action: 'change_unit_budget', type: ActionType.INLINE },
    { action: 'change_unit_daily_budget', type: ActionType.INLINE },
    { action: 'change_strategy_state', type: ActionType.INLINE },
    { action: 'change_campaign_state', type: ActionType.INLINE },
    { action: 'change_campaign_budget', type: ActionType.INLINE },
    {
        action: 'edit_advertiser',
        label: 'Edit advertiser',
        operation: (row, dispatch) => {
            const { advertiser_id } = row;
            if (advertiser_id) {
                dispatch(push(`/analyticsPro/advertisers/edit/${advertiser_id}${window.location.search}`));
            }
        },
        requiresFeature: 'ADVERTISERS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        action: 'add_campaign',
        label: 'Add campaign',
        operation: (row, dispatch) => {
            const { advertiser_id } = row;
            if (advertiser_id) {
                dispatch(activeAdvertiserActions.changeAdvertisers([advertiser_id]));
            }
            dispatch(push('/campaigns/new'));
        },
        requiresFeature: 'CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        action: 'edit_campaign',
        label: 'Edit campaign',
        operation: (row, dispatch) => {
            const { campaign_id } = row;
            if (campaign_id) {
                dispatch(push(`/campaigns/${campaign_id}`));
            }
        },
        requiresFeature: 'CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Add strategy',
        action: 'add_strategy',
        operation: (row, dispatch, { campaign } = {}) => {
            if (campaign?.id) {
                createAndEditStrategy(dispatch, campaign.id, campaign.type);
            }
        },
        requiresFeature: 'CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Edit strategy',
        action: 'edit_strategy',
        operation: ({ strategy_id }, dispatch) => dispatch(push(`/strategies/${strategy_id}`)),
        requiresFeature: 'CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Show creatives',
        action: 'show_creatives',
        operation: ({ strategy_id }, dispatch) => dispatch(push(`/strategies/${strategy_id}/creatives`)),
        requiresFeature: 'CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Edit units',
        action: 'edit_units',
        operation: ({ strategy_id }, dispatch) => dispatch(push(`/strategies/${strategy_id}/units`)),
        requiresFeature: 'CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
    {
        label: 'Edit IO Spend',
        action: 'edit_io_spend_setup',
        operation: ({ unit_id }, dispatch) => {
            dispatch(push(`/analyticsPro/unit-spend/${unit_id}${window.location.search}`));
        },
        requiresFeature: 'CAMPAIGNS_EDIT',
        type: ActionType.REDIRECT,
    },
];

export const isRedirectAction = (action: ActionConnect): action is RedirectActionConnect =>
    action.type === ActionType.REDIRECT;

export const isInlineAction = (action: ActionConnect): action is InlineActionConnect =>
    action.type === ActionType.INLINE;

export const findRegisteredActionById = (actionId: string) =>
    registeredActions.find((action) => action.action === actionId);
