import { useState } from 'react';
import AdminReports from 'platform/analyticsReports/ReportManagement/AdminReports';
import AdvertiserReports from 'platform/analyticsReports/ReportManagement/AdvertiserReports';
import CentralReports from 'platform/analyticsReports/ReportManagement/CentralReports';
import PersonalReports from 'platform/analyticsReports/ReportManagement/PersonalReports';
import ReportManagementHeader, {
    ReportManagementTab,
} from 'platform/analyticsReports/ReportManagement/ReportManagementHeader';
import SharedLinks from 'platform/analyticsReports/ReportManagement/SharedLinks';
import SystemReports from 'platform/analyticsReports/ReportManagement/SystemReports';
import Templates from 'platform/analyticsReports/ReportManagement/Templates';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { arrayItemMove } from 'platform/common/utils/array.util';
import {
    assignAnalyticsReports,
    createAnalyticsReport,
    fetchAnalyticsReports,
    updateAnalyticsReport,
} from '../analyticsReport.service';
import { AnalyticsReport, AnalyticsReportWithSettings } from '../analyticsReport.types';

export const resolveFolderId = (oldReport: AnalyticsReport, newReport: Partial<AnalyticsReport>) => {
    if (oldReport.type === 'FOLDER' && newReport.type === 'FOLDER') {
        return undefined;
    }

    return newReport.type === 'FOLDER' ? newReport.id : newReport.folderId;
};

const ReportManagement = () => {
    const canEdit = useFeature('ADMIN_EDIT');
    const [status, setStatus] = useState<ActiveOrArchived[]>(['ACTIVE']);
    const [activeTab, setActiveTab] = useState<ReportManagementTab>('Advertiser reports');

    const [{ data: templates }] = usePromise<AnalyticsReport[]>(
        [],
        () => fetchAnalyticsReports({ status: ['ACTIVE'], usedAsTemplate: true }),
        [activeTab]
    );

    const handleReportMove = async (
        oldReport: AnalyticsReportWithSettings,
        newReport: Partial<AnalyticsReportWithSettings>,
        reports: AnalyticsReport[],
        seatId: number
    ) => {
        const oldIndex = reports.findIndex((r) => r.id === oldReport.id);
        const newIndex = reports.findIndex((r) => r.id === newReport.id);

        if (
            oldIndex === -1 ||
            oldIndex === newIndex ||
            (oldReport.type === 'FOLDER' && !!newReport.folderId)
        ) {
            return;
        }

        const promises = [
            updateAnalyticsReport({
                ...oldReport,
                section: newReport.section,
                folderId: resolveFolderId(oldReport, newReport),
            }),
            newIndex === -1
                ? Promise.resolve()
                : assignAnalyticsReports(
                      { seatId },
                      arrayItemMove(reports, oldIndex, newIndex).map((r) => r.id!)
                  ),
        ];

        await Promise.all(promises);
    };

    const handleReportChange = (report: AnalyticsReportWithSettings) => updateAnalyticsReport(report);

    const handleReportsCreate = async (newReports: AnalyticsReport[]) =>
        Promise.all(newReports.map(createAnalyticsReport));

    return (
        <>
            <ReportManagementHeader
                status={status}
                tab={activeTab}
                canEdit={canEdit}
                onTabChange={setActiveTab}
            />

            {activeTab === 'Advertiser reports' && (
                <AdvertiserReports
                    templates={templates}
                    status={status}
                    canEdit={canEdit}
                    onReportMove={handleReportMove}
                    onReportChange={handleReportChange}
                    onReportsCreate={handleReportsCreate}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Central reports' && (
                <CentralReports
                    status={status}
                    canEdit={canEdit}
                    onReportChange={handleReportChange}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Shared links' && (
                <SharedLinks
                    status={status}
                    canEdit={canEdit}
                    onReportChange={handleReportChange}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Personal reports' && (
                <PersonalReports
                    status={status}
                    canEdit={canEdit}
                    onReportChange={handleReportChange}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'System reports' && (
                <SystemReports
                    status={status}
                    canEdit={canEdit}
                    onReportChange={handleReportChange}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Admin reports' && (
                <AdminReports
                    status={status}
                    templates={templates}
                    canEdit={canEdit}
                    onReportMove={handleReportMove}
                    onReportChange={handleReportChange}
                    onReportsCreate={handleReportsCreate}
                    onStatusChange={setStatus}
                />
            )}
            {activeTab === 'Templates' && (
                <Templates
                    status={status}
                    canEdit={canEdit}
                    onReportMove={handleReportMove}
                    onReportChange={handleReportChange}
                    onReportsCreate={handleReportsCreate}
                    onStatusChange={setStatus}
                />
            )}
        </>
    );
};

export default ReportManagement;
