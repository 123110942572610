import { ReactNode } from 'react';
import { useLabelClassification } from 'platform/campaign/logBook/useLabelClassification';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { CURRENCY_SYMBOL } from 'platform/common/constants/common.constant';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import {
    nonNegativeNumber,
    positiveNumber,
    required,
    requiredDatepickerValues,
} from 'platform/common/utils/validators.util';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import LogBookPopover from 'platform/mediaplan/components/LogBookPopover';
import { MediaplanFormModel } from 'platform/mediaplan/mediaplan.types';
import { getTrackedChanges } from 'platform/mediaplan/mediaplan.utils';

type Props = FormProps<MediaplanFormModel> & { headerContent?: ReactNode };

const MediaplanForm = ({
    labels,
    canEdit,
    headerContent,
    formikProps: { initialValues, values: mediaplan, setFieldValue, submitForm },
    onCancel,
}: Props) => {
    const { needsLogging } = getTrackedChanges(mediaplan, initialValues);

    const { products, subLabels, flightLabels, countryOptions, labelsLoading } = useLabelClassification({
        selectedProductLabels: mediaplan.classification?.productLabel,
    });

    return (
        <CardForm
            title={`${labels.prefix} Mediaplan`}
            subtitle={mediaplan.id ? `ID: ${mediaplan.id}` : null}
            headerContent={headerContent}
            submitLabel={labels.submit}
            onCancel={onCancel}
            cardBodyClassname="MediaplanForm"
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required" className="form-max-width">
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Status">
                    <FormSelect
                        name="status"
                        options={STATUS_OPTIONS}
                        isClearable={false}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Period">
                    <FormDateRangePicker name="runtime" validate={requiredDatepickerValues} />
                </FormRow>
                <FormRow label="Budget (net/net)" childrenColClassName="d-flex align-items-center">
                    <FormNumberInput
                        name="budget"
                        label={CURRENCY_SYMBOL}
                        validate={[required, positiveNumber]}
                    />
                    {mediaplan.id && (
                        <div className="ms-2">
                            <LogBookPopover
                                field="newChange"
                                onChange={setFieldValue}
                                mandatory={needsLogging}
                                title={mediaplan.name ?? ''}
                                newChange={mediaplan.newChange}
                                previousChanges={mediaplan.previousChanges}
                            />
                        </div>
                    )}
                </FormRow>
                <FormRow label="Project number">
                    <FormInput name="projectNumber" validate={required} />
                </FormRow>
                <FormRow label="PO number">
                    <FormInput name="poNumber" />
                </FormRow>
                <FormRow label="Adserving fee">
                    <FormNumberInput
                        name="adservingFee"
                        label={CURRENCY_SYMBOL}
                        validate={nonNegativeNumber}
                    />
                </FormRow>
                <FormRow label="Agency fee">
                    <FormNumberInput name="agencyFee" validate={positiveNumber} />
                </FormRow>
                <FormRow label="Notes">
                    <FormInput name="notes" type="textarea" />
                </FormRow>
            </ControlledCard>

            <ControlledCard title="Classification" subtitle="(Optional)" className="form-max-width">
                {labelsLoading && <OverlayLoader />}
                <FormRow label="Product label">
                    <FormSelect
                        isMulti
                        labelKey="name"
                        valueKey="name"
                        name="classification.productLabel"
                        options={products}
                        onChange={(value) => {
                            setFieldValue('classification.productSubLabel', null);
                            setFieldValue('newChange.classification.productSubLabel', null);
                            if (value.length === 1) {
                                setFieldValue('newChange.classification.productLabel', value[0]);
                            }
                        }}
                    />
                </FormRow>

                {!!subLabels.length && (
                    <FormRow label="Product sub-label">
                        <FormSelect
                            isMulti
                            labelKey="name"
                            valueKey="name"
                            name="classification.productSubLabel"
                            options={subLabels}
                            onChange={(value) => {
                                if (value.length === 1) {
                                    setFieldValue('newChange.classification.productSubLabel', value[0]);
                                }
                            }}
                        />
                    </FormRow>
                )}
                <FormRow label="Flight label">
                    <FormSelect
                        isMulti
                        labelKey="name"
                        valueKey="name"
                        name="classification.flightLabel"
                        options={flightLabels}
                        onChange={(value) => {
                            if (value.length === 1) {
                                setFieldValue('newChange.classification.flightLabel', value[0]);
                            }
                        }}
                    />
                </FormRow>
                <FormRow label="Country labels">
                    <FormSelect
                        isMulti
                        name="classification.countryLabels"
                        options={countryOptions}
                        onChange={(value) => setFieldValue('newChange.classification.countryLabels', value)}
                    />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default MediaplanForm;
