import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormikErrors } from 'formik';
import { Segment } from 'platform/advertiserTag/advertiserTag.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { getActiveAdvertiserId } from 'platform/common/utils/advertiser.util';
import { SegmentFormModel } from 'platform/segments/components/SegmentForm/SegmentForm.types';
import {
    getClassificationCodesBySegmentType,
    isSegmentAlertingEnabled,
    toApiModel,
    toFormModel,
} from 'platform/segments/mappers/segment.mapper';
import {
    createSegment,
    fetchSegmentAdvertiser,
    getSegment,
    updateSegment,
} from 'platform/segments/services/segments.service';
import SegmentForm from './SegmentForm';

const initialSegment: Partial<Segment> = {
    classificationCodes: getClassificationCodesBySegmentType('STANDARD_EVENT', ''),
};

const validateForm = (s: SegmentFormModel): FormikErrors<SegmentFormModel> =>
    s.isAlertingOn && !isSegmentAlertingEnabled(s)
        ? { isAlertingOn: 'Please enter the value of at least one alerting rule' }
        : {};

const SegmentFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = params.id ? Number(params.id) : undefined;
    const isDemoMode = useSelector(authSelectors.isDemoModeEnabled);

    const handleOpen = async () => {
        const segment = id ? await getSegment(id, isDemoMode) : initialSegment;
        const advertiser = await fetchSegmentAdvertiser(segment.advertiserId ?? getActiveAdvertiserId());
        return toFormModel({
            segment,
            advertiser,
        });
    };

    const handleSubmit = (model: SegmentFormModel) => (id ? updateSegment : createSegment)(toApiModel(model));

    return (
        <FormContainer
            helpKey="segment_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            isEdit={!!id}
            sidePanel
            validate={validateForm}
        >
            {(props) => <SegmentForm {...props} />}
        </FormContainer>
    );
};

export default SegmentFormContainer;
