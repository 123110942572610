import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';
import { MediaInsertionStepType } from 'platform/mediaplan/mediaplan.types';
import StageCardHeader from './StageCardHeader';
import SummaryStep from './SummaryStep';
import { WorkflowStagesProps } from './WorkflowStages';
import useSummarySteps from './useSummarySteps';

interface Props {
    lanes: WorkflowStagesProps['lanes'];
}

const steps: MediaInsertionStepType[] = ['IO_VENDOR_FINAL_STATS', 'VENDOR_INVOICE_CHECKED'];

const AccountingStage = ({ lanes }: Props) => {
    const { stepPropsByType } = useSummarySteps(steps, lanes);

    return (
        <SimpleCard
            headerContent={
                <StageCardHeader
                    icon={<i className="fa-solid fa-receipt text-white font-3xl" />}
                    text="Accounting"
                />
            }
        >
            {steps.map((type, i) => (
                <SummaryStep key={i} {...stepPropsByType[type]} />
            ))}
        </SimpleCard>
    );
};

export default AccountingStage;
