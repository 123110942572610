import { SunburstState, ColumnDefinitionOption } from 'platform/analytics/analytics.types';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';

const MAX_DIMENSIONS = 4;

interface Props {
    componentState: SunburstState;
    metrics: ColumnDefinitionOption[];
    dimensions: ColumnDefinitionOption[];
    isLoading: boolean;
    onChange: (state: SunburstState) => void;
}

const ReportSunburstChartSettings = ({ componentState, metrics, dimensions, isLoading, onChange }: Props) => {
    const handleDimensionsChange = (values: string[]) => {
        if (values.length > MAX_DIMENSIONS) {
            return;
        }

        onChange({ ...componentState, dimensions: values });
    };

    return (
        <>
            <ReportSelectTree
                className="mb-3"
                name="Dimensions"
                placeholder="Select up to 4 dimensions"
                options={dimensions}
                value={componentState.dimensions}
                isLoading={isLoading}
                isMulti
                onChange={handleDimensionsChange}
            />
            <ReportSelectTree
                name="Metric"
                options={metrics}
                value={componentState.metrics[0]}
                isLoading={isLoading}
                isMulti={false}
                onChange={(value) => onChange({ ...componentState, metrics: value ? [value] : [] })}
            />
        </>
    );
};

export default ReportSunburstChartSettings;
