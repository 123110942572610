import { isEmpty, startCase } from 'lodash-es';
import { isDefined } from 'platform/common/common.types';

const isEmptyValue = (value: any) => !isDefined(value) || (typeof value === 'object' && isEmpty(value));

const stripIndent = (str: string) => {
    const lines = str.split('\n');
    const commonIndent = lines.reduce(
        (indent, line) => Math.min(indent, line.match(/^\s*/)?.[0].length ?? 0),
        1000
    );
    return lines.map((line) => line.substring(commonIndent)).join('\n');
};

const humanReadable = (value: any): any => {
    if (isEmptyValue(value)) {
        return '';
    }
    if (Array.isArray(value)) {
        return value.map(humanReadable);
    }
    if (typeof value === 'object') {
        return Object.fromEntries(
            Object.entries(value)
                .filter(([key]) => !['id', 'sync'].includes(key))
                .filter(([, val]) => !isEmptyValue(val))
                .map(([key, val]) => [startCase(key), humanReadable(val)])
        );
    }
    return value;
};

export const formatObjectTree = (value: any) =>
    stripIndent(
        JSON.stringify(humanReadable(value), null, '    ')
            .replace(/[{}[\]",]/g, '')
            .replace(/^\s*\n/, '')
            .replace(/\n\s+\n/g, '\n')
            .trimEnd()
    );
