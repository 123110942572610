import { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { uniqueId } from 'lodash-es';
import html5Icon from 'platform/assets/images/html5.png';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { FileInfo, formatFileSize, stripFileExtension } from 'platform/common/utils/file.util';
import {
    ACCEPTABLE_HTML5_IMAGE_LABEL,
    ACCEPTABLE_HTML5_IMAGE_TYPES,
} from 'platform/creatives/constants/asset.constant';
import { CreativeSize } from 'platform/creatives/constants/creativeDimensions';
import { GcmHostedCreativeModel } from 'platform/creatives/mappers/creative/gcmHostedCreative.mapper';
import { AssetMetadata, getAssetMetadata, isImage } from 'platform/creatives/utils/asset.utils';
import './CreativeBulkUploadModal.scss';

type Props = {
    toggle: () => void;
    advertiserId: number;
    onUpload: (creatives: GcmHostedCreativeModel[]) => void;
};

const columns: TableColumn<GcmHostedCreativeModel>[] = [
    {
        Header: 'Creative name',
        accessor: 'name',
    },
    {
        Header: 'Creative size',
        width: 150,
        accessor: ({ width, height }) => width * 100 + height,
        Cell: ({ original: { width, height } }) => `${width} x ${height}`,
    },
    {
        Header: 'File type',
        width: 100,
        accessor: ({ file }) => file.type?.split('/').pop(),
    },
    {
        Header: 'File size',
        width: 100,
        accessor: ({ file }) => Number(file.size),
        Cell: ({ value }) => formatFileSize(value),
    },
    {
        Header: 'Preview',
        sortable: false,
        width: 200,
        Cell: ({ original: { tempId, file } }) => (
            <img
                src={isImage(file) ? file.content : html5Icon}
                alt="Preview"
                id={`${tempId}-assetPreview`}
                className="CreativeBulkUploadModal-assetPreview"
            />
        ),
    },
];

const CreativeBulkUploadModal = ({ toggle, advertiserId, onUpload }: Props) => {
    const [includeSize, setIncludeSize] = useState(false);
    const [includeFileName, setIncludeFileName] = useState(true);
    const [suffix, setSuffix] = useState('');
    const [creatives, setCreatives] = useState<GcmHostedCreativeModel[]>([]);

    const buildName = (file: { name?: string }, { width, height }: CreativeSize) =>
        [includeSize && `${width}x${height}`, suffix, includeFileName && stripFileExtension(file.name)]
            .filter(Boolean)
            .join(' ');

    useEffect(() => {
        if (!creatives.length) {
            return;
        }
        // only after rendering preview images can we use DOM to get the width/height of original assets:
        const assetMetadata: Promise<AssetMetadata[]> = creatives[0].width
            ? Promise.resolve(creatives)
            : Promise.all(creatives.map((c) => getAssetMetadata(c.file, `${c.tempId}-assetPreview`)));

        assetMetadata.then((metadata) => {
            const adjusted = creatives.map((creative, i) => ({
                ...creative,
                ...metadata[i],
                name: buildName(creative.file, metadata[i]),
            }));
            if (creatives[0].width !== adjusted[0].width || creatives[0].name !== adjusted[0].name) {
                setCreatives(adjusted);
            }
        });
    }, [creatives, includeSize, includeFileName, suffix]);

    const toCreative = (file: FileInfo): GcmHostedCreativeModel => ({
        tempId: uniqueId('creative'),
        isNew: true,
        advertiserId,
        type: 'GCM_HOSTED',
        name: '',
        clickThroughUrl: '',
        width: 0,
        height: 0,
        file: { ...file, content: file.content as string },
        runtime: { startDate: '', startTime: '' },
    });

    return (
        <Modal isOpen toggle={toggle} className="CreativeBulkUploadModal">
            <ModalHeader onClose={toggle}>Upload hosted creatives</ModalHeader>
            <ModalBody>
                <div className="d-flex align-items-start mb-2">
                    <UploadInput
                        className="CreativeBulkUploadModal-uploadButton"
                        title="Upload or drag & drop here"
                        acceptableMimeTypes={ACCEPTABLE_HTML5_IMAGE_TYPES}
                        subtitle={ACCEPTABLE_HTML5_IMAGE_LABEL}
                        multiple
                        onFileUpload={(files) => setCreatives(files.map(toCreative))}
                    />
                    <div className="CreativeBulkUploadModal-namingControls">
                        <span className="text-muted">Creative name builder:</span>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={includeSize}
                                onChange={({ target }) => setIncludeSize(target.checked)}
                            />
                            Include size
                        </Label>
                        <InputGroup>
                            <InputGroupText>Suffix</InputGroupText>
                            <Input
                                value={suffix}
                                placeholder="(Optional)"
                                onChange={({ target }) => setSuffix(target.value)}
                            />
                        </InputGroup>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={includeFileName}
                                onChange={({ target }) => setIncludeFileName(target.checked)}
                            />
                            Include file name
                        </Label>
                    </div>
                </div>
                {!!creatives.length && (
                    <FormattedTable
                        data={creatives}
                        className="-striped"
                        columns={columns}
                        defaultSorted={[{ orderBy: 'Creative size', direction: 'ASC' }]}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={!creatives?.length}
                    onClick={() => {
                        onUpload(creatives);
                        toggle();
                    }}
                >
                    OK
                </Button>
                <Button className="ms-2" color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreativeBulkUploadModal;
