import { FormGroup, Input, Label } from 'reactstrap';
import {
    COLUMN_TYPE_OPTIONS,
    ColumnDefinitionOption,
    StackBarState,
} from 'platform/analytics/analytics.types';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import ReportChartEntriesCount from '../ReportChartEntriesCount';

interface Props {
    componentState: StackBarState;
    metrics: ColumnDefinitionOption[];
    dimensions: ColumnDefinitionOption[];
    isLoading: boolean;
    onChange: (state: StackBarState) => void;
}

const ReportStackBarChartSettings = ({ componentState, metrics, dimensions, isLoading, onChange }: Props) => (
    <>
        <ReportSelectTree
            className="mb-3"
            name="Dimension"
            options={dimensions}
            value={componentState.dimensions}
            isLoading={isLoading}
            isMulti={componentState.metrics.length < 2}
            menuIsOpen={componentState.dimensions.length >= 2 ? false : undefined}
            onChange={(dimension) =>
                onChange({
                    ...componentState,
                    dimensions: Array.isArray(dimension) ? dimension : [dimension],
                })
            }
        />

        <ReportSelectTree
            className="mb-3"
            name="Metric"
            options={metrics}
            value={componentState.metrics}
            isLoading={isLoading}
            isMulti={componentState.dimensions.length < 2}
            onChange={(values) => {
                onChange({
                    ...componentState,
                    metrics: Array.isArray(values) ? values : [values as string],
                });
            }}
        />

        <SelectWithAddon
            selectClassname="flex-fill"
            className="mb-3"
            value={componentState.sortBy}
            name="Sort By"
            options={COLUMN_TYPE_OPTIONS}
            isClearable={false}
            onChange={(sortBy) => onChange({ ...componentState, sortBy })}
        />
        <ReportChartEntriesCount
            entriesCount={componentState.entriesCount}
            onChange={(entriesCount) => onChange({ ...componentState, entriesCount })}
        />
        <FormGroup check className="mt-2">
            <Label check>
                <Input
                    type="checkbox"
                    checked={componentState.showOtherValues ?? true}
                    onChange={(e) => onChange({ ...componentState, showOtherValues: e.target.checked })}
                />
                Show other values
            </Label>
        </FormGroup>
    </>
);

export default ReportStackBarChartSettings;
