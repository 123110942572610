import CdpDashboard from './components/CdpDashboard/CdpDashboard';
import CustomerJourneyCM360 from './components/CustomerJourneyCM360/CustomerJourneyCM360';
import { AUDIENCES, CUSTOMER_JOURNEY_CM360 } from './dashboard.navigation';

const dashboardRoutes = [
    {
        ...CUSTOMER_JOURNEY_CM360,
        component: CustomerJourneyCM360,
    },
    {
        ...AUDIENCES,
        component: CdpDashboard,
    },
];

export default dashboardRoutes;
