import { CSSProperties, MouseEventHandler, ReactNode, useRef } from 'react';
import { Popover, PopoverProps } from 'reactstrap';
import { preventOverflow } from '@popperjs/core';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';
import useToggle from 'platform/common/hooks/useToggle';
import { PopoverBoundaries, PopoverPlacement } from '../Popover/Popover';

interface Props {
    icon: ReactNode;
    buttonClassName?: string;
    className?: string;
    popoverclassName?: string;
    style?: CSSProperties;
    boundariesElement?: PopoverBoundaries;
    placement?: PopoverPlacement;
    modifiers?: PopoverProps['modifiers'];
    toggleOnOutsideClick?: boolean;
    children: ({ togglePopover }: { togglePopover: () => void }) => ReactNode;
    onClose?: () => void;
    onClick?: () => void;
}

const PopoverButton = ({
    icon,
    buttonClassName,
    className,
    popoverclassName,
    style,
    boundariesElement,
    placement = 'bottom-end',
    toggleOnOutsideClick = true,
    modifiers,
    children,
    onClose,
    onClick,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, toggleIsOpen] = useToggle(false);
    const togglePopover = () => {
        if (isOpen && onClose) {
            onClose();
        }

        toggleIsOpen();
    };

    const handleToggleClick: MouseEventHandler = (e) => {
        if (!toggleOnOutsideClick) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        const clickedOutside = e.target instanceof Element && !ref.current?.contains(e.target);
        if (clickedOutside) {
            togglePopover();
        }
    };

    return (
        <div
            role="button"
            tabIndex={0}
            ref={ref}
            className={className}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <IconButton
                className={buttonClassName}
                onClick={() => {
                    togglePopover();
                    onClick?.();
                }}
            >
                {icon}
            </IconButton>
            {ref.current && (
                <Popover
                    className={classNames('Popover', popoverclassName)}
                    placement={placement}
                    style={style}
                    modifiers={
                        modifiers || [
                            {
                                ...preventOverflow,
                                options: {
                                    altAxis: true,
                                },
                                fn: (popper) => {
                                    preventOverflow.fn(popper); // prevents overflow on resize
                                },
                            } as PreventOverflowModifier,
                        ]
                    }
                    boundariesElement={boundariesElement}
                    trigger="legacy"
                    target={ref.current}
                    isOpen={isOpen}
                    hideArrow
                    toggle={handleToggleClick}
                >
                    {children({ togglePopover })}
                </Popover>
            )}
        </div>
    );
};

export default PopoverButton;
