import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { MediaNodeType } from 'platform/mediaplan/mediaplan.types';
import './MediaNodeToolbar.scss';

interface Props {
    nodeOptions: { label: string; type: MediaNodeType }[];
    onAdd?: (type: MediaNodeType) => void;
    onClear?: () => void;
    onCopy?: () => void;
    onPaste?: () => void;
    onRemove?: () => void;
}

const MediaNodeToolbar = ({ nodeOptions, onAdd, onClear, onCopy, onPaste, onRemove }: Props) => (
    <div className="MediaNodeToolbar">
        <div className="MediaNodeToolbar-buttonsContainer">
            <div className="MediaNodeToolbar-buttons">
                {onAdd && (
                    <UncontrolledDropdown>
                        <DropdownToggle color="link">
                            <i className="fal fa-lg fa-circle-plus" />
                        </DropdownToggle>
                        <DropdownMenu>
                            {nodeOptions.map((option) => (
                                <DropdownItem
                                    key={option.label}
                                    onClick={() => onAdd(option.type)}
                                    tabIndex={-1}
                                >
                                    {option.label}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )}
                {(onCopy || onPaste) && (
                    <UncontrolledDropdown>
                        <DropdownToggle color="link">
                            <i className="far fa-lg fa-copy" />
                        </DropdownToggle>
                        <DropdownMenu>
                            {onCopy && (
                                <DropdownItem key="copy" onClick={onCopy} tabIndex={-1}>
                                    Copy
                                </DropdownItem>
                            )}
                            {onPaste && (
                                <DropdownItem key="paste" onClick={onPaste} tabIndex={-1}>
                                    Paste
                                </DropdownItem>
                            )}
                            {onClear && (
                                <DropdownItem key="clear" onClick={onClear}>
                                    Clear
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )}
                {onRemove && (
                    <Button onClick={onRemove} tabIndex={-1} color="link">
                        <i className="far fa-lg fa-trash-alt" />
                    </Button>
                )}
            </div>
        </div>
    </div>
);

export default MediaNodeToolbar;
