import { ReactNode } from 'react';
import { isDefined } from 'platform/common/common.types';
import Popover from 'platform/common/components/Popover/Popover';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UserIcons from 'platform/common/components/UserSelect/UserIcons';
import { formatNaturalDate } from 'platform/common/utils/date.util';
import { formatFileSize } from 'platform/common/utils/file.util';
import TaskBlockedBadge from 'platform/task/Common/TaskBlockedBadge';
import { User } from 'platform/userManagement/types/user.type';
import LaneTitle from '../Common/LaneTitle';
import TaskLabels from '../Common/TaskLabels';
import {
    LabelNames,
    TASK_STATUS_LABELS,
    TaskAttachment,
    TaskBoardChange,
    TaskBoardStateData,
    TaskData,
    TaskLaneData,
} from '../task.types';

type Props = {
    change: TaskBoardChange;
    userOptions: User[];
    labelNames: LabelNames;
};

const boardUpdated = (data: TaskBoardStateData): ReactNode => {
    if (data.labelNames) {
        return (
            <>
                Renamed labels to
                <TaskLabels keys={Object.keys(data.labelNames)} names={data.labelNames} />
            </>
        );
    }
    return (
        <>
            Updated. Name: <i>{data.name}</i>, Status:
            <StatusBadge status={data.status} />
        </>
    );
};

const laneDetails = (event: string, data: TaskLaneData, userOptions: User[]): ReactNode => (
    <>
        {event}
        <i>
            <LaneTitle lane={data} userOptions={userOptions} />
        </i>
    </>
);

const taskAdded = (data: TaskData, containerName: string, isSubtask: boolean): ReactNode => (
    <>
        Created {isSubtask ? 'sub-task' : 'task'} <i>{data.name}</i>
        {isSubtask ? 'under parent' : 'in column'} <i>{containerName}</i>
    </>
);

const taskUpdated = (data: Partial<TaskData>, userOptions: User[], labelNames: LabelNames): ReactNode => (
    <>
        {isDefined(data.name) && (
            <>
                Renamed to <i>{data.name}</i>
            </>
        )}
        {isDefined(data.status) && (
            <>
                Marked as
                <b>{TASK_STATUS_LABELS[data.status]}</b>
            </>
        )}
        {isDefined(data.description) && (
            <>
                Updated description to
                <Popover renderPopover={() => <RawHtml>{data.description}</RawHtml>}>
                    <i className="fa fa-align-left" />
                </Popover>
            </>
        )}
        {isDefined(data.startDate) && (
            <>
                Moved start date to <i className="far fa-calendar" />
                <i>{formatNaturalDate(data.startDate) ?? 'unspecified'}</i>
            </>
        )}
        {isDefined(data.dueDate) && (
            <>
                Moved due date to <i className="far fa-calendar" />
                <i>{formatNaturalDate(data.dueDate) ?? 'unspecified'}</i>
            </>
        )}
        {isDefined(data.tentativeDates) && (
            <>
                Timing to be decided:
                <i>{data.tentativeDates ? 'Yes' : 'No'}</i>
            </>
        )}
        {isDefined(data.assigneeIds) && (
            <>
                Changed assignees to
                <UserIcons userIds={data.assigneeIds} options={userOptions} />
            </>
        )}
        {isDefined(data.notifiedUserIds) && (
            <>
                Changed watchers to
                <UserIcons userIds={data.notifiedUserIds} options={userOptions} />
            </>
        )}
        {isDefined(data.labelKeys) && (
            <>
                Changed labels to <TaskLabels keys={data.labelKeys} names={labelNames} />
            </>
        )}
        {isDefined(data.display) && <>Changed what is shown on card</>}
        {isDefined(data.blockedBy) && (
            <>
                <TaskBlockedBadge />
                Changed upstream dependencies
            </>
        )}
    </>
);

const taskMoved = (containerName: string, isSubtask: boolean): ReactNode => (
    <>
        Moved <i className="fa fa-right" /> {isSubtask ? 'parent task' : 'column'} <i>{containerName}</i>
    </>
);

const taskReordered = (isSubtask: boolean): ReactNode => (
    <>
        Changed order <i className="fa fa-sort-alt" /> within {isSubtask ? 'parent task' : 'column'}
    </>
);

const attachmentAction = (action: string, { name, size }: TaskAttachment): ReactNode => (
    <>
        {action} attachment <i className="fa fa-paperclip" /> <i>{name}</i> ({formatFileSize(size)})
    </>
);

const commentAction = (action: string, text: string): ReactNode => (
    <>
        {action} comment <i className="fa fa-comment" /> <i>{text}</i>
    </>
);

const getDetails = (c: TaskBoardChange, userOptions: User[], labelNames: LabelNames): ReactNode => {
    switch (c.type) {
        case 'BOARD_UPDATED':
            return boardUpdated(c.details.data);
        case 'LANE_ADDED':
            return laneDetails('Created column', c.details.data, userOptions);
        case 'LANE_UPDATED':
            return laneDetails('Updated to', c.details.data, userOptions);
        case 'LANE_MOVED':
            return 'Moved';
        case 'LANE_DELETED':
            return 'Deleted';
        case 'TASK_ADDED':
            return taskAdded(c.details.data, c.details.containerName, !!c.parentTaskId);
        case 'TASK_UPDATED':
            return taskUpdated(c.details.data, userOptions, labelNames);
        case 'TASK_MOVED':
            return taskMoved(c.details.containerName, !!c.parentTaskId);
        case 'TASK_REORDERED':
            return taskReordered(!!c.parentTaskId);
        case 'TASK_DELETED':
            return 'Deleted';
        case 'ATTACHMENT_ADDED':
            return attachmentAction('Added', c.details.attachment);
        case 'ATTACHMENT_DELETED':
            return attachmentAction('Removed', c.details.attachment);
        case 'COMMENT_ADDED':
            return commentAction('Added', c.details.text);
        case 'COMMENT_REMOVED':
            return commentAction('Removed', c.details.text);
        default:
            return JSON.stringify(c);
    }
};

const BoardChangeDetails = ({ change, userOptions, labelNames }: Props) => (
    <div className="d-flex align-items-center gap-1">{getDetails(change, userOptions, labelNames)}</div>
);

export default BoardChangeDetails;
