import { Badge, Card, CardBody, CardHeader } from 'reactstrap';
import RemovableActionRules from 'platform/campaign/globalPreset/components/CustomActionRule/RemovableActionRules';
import { customDimensionOptions } from 'platform/campaign/globalPreset/constants/customActionRule.constant';
import { CustomActionRuleApiModel } from 'platform/campaign/globalPreset/globalPreset.types';
import { Optional } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

const CustomActionRuleForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: {
        initialValues,
        submitForm,
        values: { ruleSet },
    },
}: FormProps<Optional<CustomActionRuleApiModel, 'id'>>) => (
    <CardForm
        title={`${labels.prefix} custom action rule`}
        subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
        submitLabel={labels.submit}
        onCancel={onCancel}
        disabled={!canEdit}
        onSubmit={submitForm}
    >
        <ControlledCard title="General info" subtitle="required">
            <FormRow label="Name">
                <FormInput name="name" type="text" validate={required} className="col-md-6" />
            </FormRow>
        </ControlledCard>
        <ControlledCard
            title="Rules"
            subtitle="Populate actions based on the following rules"
            className="px-2"
        >
            <RemovableActionRules rules={ruleSet.rules} field="ruleSet.rules" />
            <h4>
                <Badge color="secondary" className="py-2 px-4 mb-2">
                    OR
                </Badge>
            </h4>
            <div className="row d-flex">
                <Card className="flex-column d-flex flex-fit bg-light">
                    <CardHeader>
                        <b>This dimension</b> should be populated if nothing matches
                    </CardHeader>
                    <CardBody>
                        <FormSelect
                            name="ruleSet.default"
                            options={customDimensionOptions}
                            className="w-25"
                            isClearable={false}
                            validate={required}
                        />
                    </CardBody>
                </Card>
            </div>
        </ControlledCard>
    </CardForm>
);

export default CustomActionRuleForm;
