import { ColumnType, OptInEffect, VatSetting } from 'platform/analytics/analytics.types';
import { Status } from 'platform/common/constants/status.constant';

export type InsightSource = 'BIG_QUERY' | 'POSTGRES';

export const INSIGHT_SOURCE_LABELS: Record<InsightSource, string> = {
    BIG_QUERY: 'Big Query',
    POSTGRES: 'PostgreSQL',
};

export const INSIGHT_COLUMN_FORMATS = ['ID', 'TEXT', 'INT', 'FLOAT', 'P100', 'EUR'] as const;
type InsightColumnFormat = typeof INSIGHT_COLUMN_FORMATS[number];

export const NEW_INSIGHT_QUERY: InsightQuery = {
    name: 'New query',
    source: 'BIG_QUERY',
    code: '',
    metadata: {
        columns: [],
    },
};

export interface InsightColumn {
    key: string;
    name: string;
    type: ColumnType;
    format: InsightColumnFormat;
    vat?: VatSetting;
    optInEffect?: OptInEffect;
    aggregateFunction?: string;
    derivedFrom?: string;
}

export interface InsightColumnFormModel extends InsightColumn {
    derivedChildren?: InsightColumnFormModel[];
}

export interface InsightQuery {
    id?: number;
    name: string;
    source: InsightSource;
    code: string;
    metadata: InsightQueryMetadata;
}

export interface InsightQuerySummary {
    reportId: number;
    reportName: string;
    queryId: number;
    queryName: string;
}

export interface InsightQueryVersionSummary {
    timestamp: string;
    updatedBy: string;
}

export interface InsightQueryVersion extends InsightQueryVersionSummary {
    code: string;
    metadata: InsightQueryMetadata;
}

export type InsightQueryMetadata = {
    columns?: InsightColumn[];
    parameters?: string[];
    error?: string;
};

export interface InsightReport {
    id: number;
    name: string;
    status: Status;
    advertiserId: number | undefined;
    shortDescription: string;
    longDescription: string;
    queries: InsightQuery[];
    updatedOn?: string;
    updatedBy?: string;
}
