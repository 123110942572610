import { Button } from 'reactstrap';
import { AdvertiserAccount } from 'platform/advertisers/advertiser.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditInput from 'platform/common/components/InlineEditInput/InlineEditInput';
import Switch from 'platform/common/components/Switch/Switch';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { arrayItemUpdate } from 'platform/common/utils/array.util';

type AdvertiserAccountTableModel = AdvertiserAccount & { error?: string };

interface Props {
    accounts: AdvertiserAccountTableModel[];
    externalSystem: ExternalSystem;
    onChange: (value: AdvertiserAccountTableModel[]) => void;
}

const ExternalAccountsTable = ({ accounts, onChange, externalSystem }: Props) => {
    const toggledColumn = (
        label: string,
        flag: keyof AdvertiserAccount
    ): TableColumn<AdvertiserAccountTableModel> => ({
        Header: label,
        accessor: flag,
        Cell: ({ original: account, index }) => (
            <Switch
                input={{
                    value: !!account[flag],
                    onChange: ({ target: { checked } }) =>
                        onChange(arrayItemUpdate(accounts, index, { ...accounts[index], [flag]: checked })),
                }}
            />
        ),
    });

    const getColumns = (): TableColumn<AdvertiserAccountTableModel>[] => {
        const nameColumn: TableColumn<AdvertiserAccountTableModel> = {
            Header: 'Name',
            accessor: 'name',
            Cell: (row) => (
                <InlineEditInput
                    onChange={(name) =>
                        onChange(arrayItemUpdate(accounts, row.index, { ...accounts[row.index], name }))
                    }
                    inputType="text"
                    value={row.original.name}
                    placeholder="Name"
                />
            ),
        };
        const externalIdColumn: TableColumn<AdvertiserAccountTableModel> = {
            Header: 'ID',
            accessor: 'externalId',
            Cell: (row) => (
                <InlineEditInput
                    onChange={(externalId) =>
                        onChange(arrayItemUpdate(accounts, row.index, { ...accounts[row.index], externalId }))
                    }
                    inputType="text"
                    value={row.original.externalId}
                    placeholder="ID"
                />
            ),
            Footer: () => (
                <InlineEditInput
                    onChange={(externalId) => {
                        onChange([...accounts, { externalId } as AdvertiserAccountTableModel]);
                    }}
                    inputType="text"
                    placeholder="Add ID"
                />
            ),
        };

        const classificationSyncColumn = toggledColumn('Label sync', 'campaignClassificationSyncEnabled');
        const segmentSyncColumn = toggledColumn('Segment sync', 'segmentSyncEnabled');
        const statusSyncColumn = toggledColumn('Status sync', 'statusSyncEnabled');
        const runtimeSyncColumn = toggledColumn('Runtime sync', 'runtimeSyncEnabled');
        const budgetSyncColumn = toggledColumn('Budget sync', 'budgetSyncEnabled');
        const bidSyncColumn = toggledColumn('Bid sync', 'bidSyncEnabled');
        const trackingSyncColumn = toggledColumn('Tracking sync', 'trackingSyncEnabled');

        const removeColumn: TableColumn<AdvertiserAccountTableModel> = {
            maxWidth: 50,
            Cell: (row) => (
                <Button
                    color="link"
                    className="text-muted"
                    onClick={() => onChange(accounts.filter((account, index) => index !== row.index))}
                >
                    <i className="fa fa-times" />
                </Button>
            ),
        };

        const restColumns = [
            budgetSyncColumn,
            bidSyncColumn,
            runtimeSyncColumn,
            statusSyncColumn,
            removeColumn,
        ];

        switch (externalSystem) {
            case 'GOOGLE': {
                return [
                    externalIdColumn,
                    nameColumn,
                    trackingSyncColumn,
                    classificationSyncColumn,
                    segmentSyncColumn,
                    ...restColumns,
                ];
            }
            case 'FACEBOOK': {
                return [externalIdColumn, nameColumn, trackingSyncColumn, segmentSyncColumn, ...restColumns];
            }
            default: {
                return [externalIdColumn, nameColumn, ...restColumns];
            }
        }
    };
    return (
        <FormattedTable
            stickyHeader={false}
            data={accounts}
            columns={getColumns()}
            getTrProps={(_: any, rowInfo: any) =>
                rowInfo.original.error ? { style: { border: '1px solid #f86c6b' } } : {}
            }
            NoDataComponent={() => null}
        />
    );
};

export default ExternalAccountsTable;
