import { useSelector } from 'react-redux';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { isProduction } from 'platform/common/utils/environment.util';
import './ReportTracingTips.scss';

const KIBANA_URL = `http://kibana.${isProduction() ? 'dap-prod.p-l.it' : 'dap-dev.p-l.it'}`;

const getLink = (traceId: string): string =>
    `${KIBANA_URL}/_plugin/kibana/app/kibana#/discover?_g=()&_a=(columns:!('@log_group',level,message),index:'*',interval:auto,query:(query_string:(analyze_wildcard:!t,query:'${traceId}')),sort:!('@timestamp',desc))`;

interface Props {
    report?: any;
    compareReport?: any;
}

const ReportTracingTips = ({ report, compareReport }: Props) => {
    const debugMode = useSelector(analyticsSelectors.debugMode);
    if (!report?.traceId || !debugMode) {
        return null;
    }

    return (
        <div className="ReportTracingTips">
            <a
                className="ReportTracingTips-link"
                rel="noopener noreferrer"
                target="_blank"
                href={getLink(report.traceId)}
            >
                Trace log: {report.traceId}
            </a>
            {compareReport?.traceId && (
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={getLink(compareReport.traceId)}
                    className="ReportTracingTips-link ms-1"
                >
                    Trace log: {compareReport.traceId}
                </a>
            )}
        </div>
    );
};

export default ReportTracingTips;
