import { useRef } from 'react';
import { components, OptionProps } from 'react-select';
import StatisticTooltip, { Tooltip } from 'platform/common/components/StatisticTooltip/StatisticTooltip';

const SelectOptionWithTooltip = (props: OptionProps<Tooltip, true>) => {
    const ref = useRef<HTMLDivElement>(null);
    return (
        <div ref={ref} style={{ overflow: 'hidden' }}>
            <components.Option {...props} />
            <StatisticTooltip target={ref.current} tooltip={props.data} />
        </div>
    );
};

export default SelectOptionWithTooltip;
