import { LogData } from 'platform/campaign/logBook/logBook.types';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import BudgetShiftTooltip from './BudgetShiftTooltip';
import NumberShift from './NumberShift';

type Props = {
    newData?: LogData;
    oldData?: LogData;
    comment?: string;
};

const BudgetShiftingDetails = ({ oldData, newData, comment }: Props) => (
    <Tooltip
        renderTooltip={() => <BudgetShiftTooltip oldData={oldData} newData={newData} comment={comment} />}
    >
        <NumberShift oldNumber={oldData?.budget} newNumber={newData?.budget} />
        <i className="fa fa-info-circle text-info me-2" />
        {comment && <span>{comment}</span>}
    </Tooltip>
);

export default BudgetShiftingDetails;
