import { ReactNode } from 'react';
import classNames from 'classnames';
import './ListGroupHeader.scss';

interface Props {
    className?: string;
    children: ReactNode;
}

const ListGroupHeader = ({ className, children }: Props) => (
    <div className={classNames('ListGroupHeader', className)}>{children}</div>
);

export default ListGroupHeader;
