import { CSSProperties, ReactNode } from 'react';
import { Button } from 'reactstrap';

interface Props {
    onClick: () => void;
    children: ReactNode;
    style?: CSSProperties;
}

const AlgorithmActionButton = ({ onClick, children, style }: Props) => (
    <Button
        style={style}
        color="secondary"
        size="sm"
        className="rounded-pill py-2 lh-1 ms-1"
        onClick={(e) => {
            e.stopPropagation();
            onClick();
        }}
    >
        {children}
    </Button>
);

export default AlgorithmActionButton;
