import { ColumnColouringType } from 'platform/analytics/analytics.types';
import ColumnColouringForm from 'platform/analytics/reportComponents/ColumnColouring/ColumnColouringForm';
import Popover from 'platform/common/components/Popover/Popover';

interface Props {
    colouring?: ColumnColouringType;
    onColouringChange: (colouring: ColumnColouringType | undefined) => void;
    onToggle: (open: boolean) => void;
}

const ColumnColouring = ({ colouring, onToggle, onColouringChange }: Props) => {
    const handleColouringChange = (value: ColumnColouringType | undefined) => {
        onColouringChange(value);
        onToggle(false);
    };

    return (
        <Popover
            placement="bottom-end"
            isOpen
            preventOverflow
            onToggle={onToggle}
            renderPopover={() => (
                <ColumnColouringForm
                    initialValues={colouring}
                    onSubmit={(value) => {
                        handleColouringChange(value);
                    }}
                    onReset={() => {
                        handleColouringChange(undefined);
                    }}
                />
            )}
        />
    );
};

export default ColumnColouring;
