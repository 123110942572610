import { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import PopoverDropdown, { PopoverDropdownItem } from '../PopoverDropdown/PopoverDropdown';

interface Option<T> {
    value: T;
    label: string;
    disabled?: boolean;
}

interface Props<T extends string | number> {
    options: Option<T>[];
    iconClassName?: string;
    value: any;
    togglerClassName?: string;
    popoverClassName?: string;
    toggler?: ReactNode;
    buttonColorProp?: string;
    children?: ({ toggle }: { toggle: (event?: MouseEvent<HTMLButtonElement>) => void }) => ReactNode;
    onChange: (newValue: Option<T>) => void;
}

const optionToItem =
    <T extends {}>(onChange: (newValue: Option<T>) => void, selectedValue: T) =>
    (option: Option<T>): PopoverDropdownItem => {
        if (selectedValue === option.value) {
            return {
                label: <b>{option.label}</b>,
                action: noop,
                key: String(option.value),
            };
        }

        return {
            label: option.label,
            action: () => onChange(option),
            key: String(option.value),
            disabled: option.disabled,
        };
    };

const InlineSelect = <T extends string | number>({
    options,
    iconClassName,
    value,
    togglerClassName,
    popoverClassName,
    toggler,
    buttonColorProp,
    children,
    onChange,
}: Props<T>) => (
    <PopoverDropdown
        toggler={toggler || <i className={classNames('fal fa-angle-down text-muted', iconClassName)} />}
        items={options.map(optionToItem(onChange, value))}
        togglerClassName={classNames('px-1 py-0', togglerClassName)}
        popoverClassName={popoverClassName}
        placement="bottom-start"
        buttonColorProp={buttonColorProp}
    >
        {children}
    </PopoverDropdown>
);

export default InlineSelect;
