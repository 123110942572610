import { useState } from 'react';
import { Label } from 'reactstrap';
import { useFormikContext } from 'formik';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import Switch from 'platform/common/components/Switch/Switch';
import FormInput from 'platform/formik/FormInput/FormInput';
import { CampaignModel } from '../../campaign.types';

type Props = {
    campaign: CampaignModel;
};
export const CampaignFormLandingPageSuffix = ({ campaign: { clickThroughUrlSuffix } }: Props) => {
    const [suffixEnabled, setSuffixEnabled] = useState(!!clickThroughUrlSuffix);
    const { setFieldValue } = useFormikContext<CampaignModel>();
    return (
        <div className="mb-3">
            <FormHelp className="mb-1" helpKey="campaign_form_suffix_enabled">
                <Label className="m-0">Landing page suffix</Label>

                <Switch
                    className="ms-2"
                    input={{
                        value: suffixEnabled,
                        onChange: ({ target }) => {
                            setSuffixEnabled(target.checked);
                            if (!target.checked) {
                                setFieldValue('clickThroughUrlSuffix', null);
                            }
                        },
                    }}
                />
            </FormHelp>
            {suffixEnabled && (
                <FormHelp className="mt-2">
                    <FormInput
                        leftAddOn={{ title: 'Suffix' }}
                        name="clickThroughUrlSuffix"
                        placeholder="Example: utm_campaign=Campaign"
                    />
                </FormHelp>
            )}
        </div>
    );
};
