import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApolloError } from '@apollo/client';
import { isEqual } from 'lodash-es';
import { getDimensionFilterOptions } from 'platform/analytics/analytics.service';
import {
    DimensionFilterOption,
    FilterDefinition,
    ReportFilter,
    ReportFilterOperator,
    ReportFilterValue,
} from 'platform/analytics/analytics.types';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';

// we need to clear values that don't exist in latest options
const removeDeprecatedValues = (values: ReportFilterValue[], options: DimensionFilterOption[] = []) =>
    values.filter((value) => options.some((option) => option.value === value));

export const resolveFilterValues = (
    values: ReportFilterValue[],
    options: DimensionFilterOption[],
    operator: ReportFilterOperator
): ReportFilterValue[] => {
    switch (operator) {
        case 'IS_NOT': {
            const resolved = options
                .filter((option) => !values.includes(option.value))
                .map((option) => option.value);
            return resolved.length ? resolved : [null];
        }
        case 'CONTAINS': {
            const resolved = options
                .filter((option) =>
                    values.some((value) =>
                        option.label.toLowerCase().includes(String(value).toLocaleLowerCase())
                    )
                )
                .map((option) => option.value);
            return resolved.length ? resolved : values;
        }
        case 'CONTAINS_NOT': {
            const resolved = options
                .filter(
                    (option) =>
                        !values.some((value) =>
                            option.label.toLowerCase().includes(String(value).toLocaleLowerCase())
                        )
                )
                .map((option) => option.value);
            return resolved.length ? resolved : values;
        }
        default:
            return removeDeprecatedValues(values, options);
    }
};

// In case we have a filter with an operator different from 'IS', we need to refetch options with resolved values
// cause BE service doesn't support operators
const updateFilterOptionsMap = (
    filters: ReportFilter[],
    filterDefinitions: FilterDefinition[],
    advertiserIds: number[],
    optionsMap?: FilterOptionsMap
): Promise<FilterOptionsMap> => {
    const resolvedFilters = filters.map((filter) => {
        const options = optionsMap?.[filter.key]?.options ?? [];
        const filterDefinition = filterDefinitions.find((definition) => definition.target === filter.key);

        return {
            ...filter,
            values:
                filterDefinition?.type === 'LIST' && filter.values.length
                    ? resolveFilterValues(filter.values, options, filter.operator ?? 'IS')
                    : filter.values,
        };
    });

    return fetchFilterOptionsMap(resolvedFilters, filterDefinitions, advertiserIds);
};

const fetchFilterOptionsMap = async (
    filters: ReportFilter[],
    filterDefinitions: FilterDefinition[],
    advertiserIds: number[]
): Promise<FilterOptionsMap> => {
    const filterOptionsPromises = filters
        .filter((filter) => filterDefinitions.find((f) => f.target === filter.key)?.type === 'LIST')
        .map(async (filter) => {
            const filterDefinition = filterDefinitions.find(
                (definition) => definition.target === filter.key
            )!;

            try {
                const options = await getDimensionFilterOptions(filterDefinition?.source, filter.values, [
                    ...filters.filter((f) => f.key !== filter.key),
                    { key: 'advertiser_id', values: advertiserIds },
                ]);
                return {
                    ...filter,
                    error: undefined,
                    options: options ?? [],
                };
            } catch (error) {
                return {
                    ...filter,
                    error,
                    options: [],
                };
            }
        });

    return (await Promise.all(filterOptionsPromises)).reduce(
        (acc, filter) => ({
            ...acc,
            [filter.key]: {
                options: filter.options,
                error: filter.error,
            },
        }),
        {}
    );
};

const shouldUpdateOptions = (filters: ReportFilter[]) =>
    filters.some(({ operator }) => operator && operator !== 'IS');

export type FilterOptionsMap = Record<
    string,
    {
        options: DimensionFilterOption[];
        error?: ApolloError;
    }
>;

interface Props {
    reportFilters: ReportFilter[];
    onFiltersChange: (filters: ReportFilter[]) => void;
    onLoadingChange?: (loading: boolean) => void;
}

export const useReportFilterOptions = ({ reportFilters, onFiltersChange, onLoadingChange }: Props) => {
    const prevFilters = useRef<ReportFilter[] | null>(null);
    const { definitions } = useAnalyticsMetadata();
    const filterDefinitions = definitions.filters;
    const advertiserIds = useSelector(activeAdvertiserSelectors.ids);
    const [state, setState] = useState<{ filtersLoading: boolean; filterOptionsMap: FilterOptionsMap }>({
        filtersLoading: false,
        filterOptionsMap: {},
    });

    const updateFilters = async (filters: ReportFilter[]) => {
        setState((prev) => ({ ...prev, filtersLoading: true }));
        onLoadingChange?.(true);
        const optionsMap = await fetchFilterOptionsMap(filters, filterDefinitions, advertiserIds);
        const filterOptionsMap = await (shouldUpdateOptions(filters)
            ? updateFilterOptionsMap(filters, filterDefinitions, advertiserIds, optionsMap)
            : optionsMap);

        const newFilters = filters.map((f) => {
            const options = filterOptionsMap[f.key]?.options;
            return {
                ...f,
                values:
                    options && (f.operator === 'IS' || f.operator === 'IS_NOT')
                        ? removeDeprecatedValues(f.values, options)
                        : f.values,
            };
        });
        if (!isEqual(reportFilters, newFilters)) {
            onFiltersChange(newFilters);
        }
        setState({ filtersLoading: false, filterOptionsMap });
        onLoadingChange?.(false);
        return newFilters;
    };

    useEffect(() => {
        // Need prev state here to avoid infinite effect calls
        if (!isEqual(reportFilters, prevFilters.current)) {
            prevFilters.current = reportFilters;
            updateFilters(reportFilters);
        }
    }, [reportFilters]);

    return {
        ...state,
        updateFilters,
    };
};
