import AlgorithmLogDetails from 'platform/algorithms/components/AlgorithmLogDetails';
import AlgorithmTemplatesList from 'platform/algorithms/components/AlgorithmTemplateList';
import { ALGORITHM_LOG_DETAILS, ALGORITHM_TEMPLATES, ALGORITHMS } from './algorithms.navigation';
import AlgorithmList from './components/AlgorithmList/AlgorithmList';

const algorithmsRoutes = [
    {
        ...ALGORITHMS,
        component: AlgorithmList,
    },
    {
        ...ALGORITHM_TEMPLATES,
        component: AlgorithmTemplatesList,
    },
    {
        ...ALGORITHM_LOG_DETAILS,
        component: AlgorithmLogDetails,
    },
];

export default algorithmsRoutes;
