import { range } from 'lodash-es';
import { OptInEffect } from 'platform/analytics/analytics.types';
import { CampaignClassifications } from 'platform/campaign/campaign/campaignClassification.types';
import { SelectOption } from 'platform/common/common.types';

export const CUSTOM_METRIC_OPTIONS: SelectOption[] = range(1, 51).map((n) => ({
    value: `custom_metric_${n}`,
    label: `Custom metric ${n}`,
}));

export const CUSTOM_METRIC_KEYS = CUSTOM_METRIC_OPTIONS.map((o) => o.value);

export const CUSTOM_METRIC_FORMAT_LABELS = {
    FLOAT: 'Number',
    INT: 'Integer',
    P100: 'Percent',
    EUR: 'Currency',
};

type CustomMetricFormat = keyof typeof CUSTOM_METRIC_FORMAT_LABELS;

export type CustomMetricRule = {
    condition?: CampaignClassifications;
    expression: string;
};

export type CustomMetric = {
    key: string;
    name: string;
    description?: string;
    format: CustomMetricFormat;
    optInEffect?: OptInEffect;
    rules: CustomMetricRule[];
    createdOn?: string;
    updatedOn?: string;
    updatedBy?: string;
};

export type CustomMetricFormRule = {
    key: string;
    conditions: string[];
    expression: string;
};

export type CustomMetricFormModel = {
    key: string;
    name: string;
    description?: string;
    format: CustomMetricFormat;
    optInEffect?: OptInEffect;
    rules: CustomMetricFormRule[];
};
