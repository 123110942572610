import { useEffect, useState } from 'react';
import { components, OptionProps } from 'react-select';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import useToggle from 'platform/common/hooks/useToggle';
import TaskLabels from '../Common/TaskLabels';
import { LabelNames, TaskLabelOption } from '../task.types';
import KanbanLabelSelect from './KanbanLabelSelect';
import './KanbanCardLabelEdit.scss';

type Props = {
    keys: string[];
    names: LabelNames;
    onUpdate: (data: any) => void;
};

const KanbanCardLabelEdit = ({ keys, names, onUpdate }: Props) => {
    const [labelKeys, setLabelKeys] = useState<string[]>(keys);
    const [toggle, onToggle] = useToggle(false);

    useEffect(() => {
        if (!isEqual(keys, labelKeys)) {
            setLabelKeys(keys);
        }
    }, [keys]);

    const Option = (props: OptionProps<TaskLabelOption, true>) => (
        <div className="d-flex justify-content-between align-items-center">
            <components.Option {...props} />
        </div>
    );
    return (
        <div role="button" tabIndex={-1} onClick={(e) => e.stopPropagation()}>
            {toggle ? (
                <KanbanLabelSelect
                    className="mb-1"
                    labelKeys={labelKeys}
                    labelNames={names}
                    Option={Option}
                    menuIsOpen={toggle}
                    onBlur={() => {
                        if (toggle && !isEqual(keys, labelKeys) && onUpdate) onUpdate({ labelKeys });
                        onToggle();
                    }}
                    autoFocus
                    onKeyDown={(e) => e.key === 'Escape' && (setLabelKeys(keys), onToggle())}
                    onChange={(op) => setLabelKeys(op)}
                />
            ) : (
                <div
                    role="button"
                    className="Kanban-card-labels mb-3 pe-2"
                    tabIndex={-1}
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggle();
                    }}
                >
                    <TaskLabels keys={keys} names={names} />
                    <div
                        className={classNames('KanbanCardLabelEdit-Add', {
                            'Kanban--show-on-hover': keys.length > 0,
                        })}
                    >
                        <i className="fa fa-plus text-muted" />
                        {!keys?.length && <span className="ms-1 me-1 bold">Add label</span>}
                    </div>
                </div>
            )}
        </div>
    );
};
export default KanbanCardLabelEdit;
