import { FormFeedback } from 'reactstrap';
import classnames from 'classnames';
import { useField } from 'formik';
import InputButton, { Props as InputButtonProps } from 'platform/common/components/InputButton/InputButton';
import { Validator, validator } from 'platform/common/utils/validators.util';

type Props = {
    name: string;
    validate?: Validator | Validator[];
} & InputButtonProps;

const FormInputButton = ({ name, className, validate, ...rest }: Props) => {
    const [field, meta] = useField({
        name,
        validate: validate && validator(validate),
    });
    const invalid = Boolean(meta.touched && meta.error);

    return (
        <>
            <InputButton
                {...rest}
                className={classnames(className, { 'form-control is-invalid m-0 p-0': invalid })}
                value={field.value}
            />
            {invalid && <FormFeedback>{meta.error}</FormFeedback>}
        </>
    );
};

export default FormInputButton;
