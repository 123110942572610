import { Help } from 'platform/adminPanel/adminPanel.types';
import { ContactInformation } from 'platform/advertisers/advertiser.types';
import { combineReducersWithRoot } from './combineReducersWithRoot';
import activeAdvertiser, { ActiveAdvertiserState } from './ducks/activeAdvertiser.duck';
import classifiers, { CommonClassifiers } from './ducks/commonClassifiers.duck';
import contacts from './ducks/contacts.duck';
import dateFilter, { Periods } from './ducks/dateFilter.duck';
import help from './ducks/help.duck';
import layout, { Layout } from './ducks/layout.duck';

export interface CommonState {
    activeAdvertiser: ActiveAdvertiserState;
    dateFilter: Periods;
    layout: Layout;
    classifiers: CommonClassifiers;
    help: Help[];
    contacts: ContactInformation;
}

const reducer = combineReducersWithRoot<CommonState>({
    activeAdvertiser,
    dateFilter,
    layout,
    classifiers,
    help,
    contacts,
});

export default reducer;
