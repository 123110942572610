import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'platform/common/components/Select/Select';
import useWikiPermissions from './useWikiPermissions';
import { WIKI_SPACE_OPTIONS } from './wiki.constant';
import { wikiActions, wikiSelectors } from './wiki.duck';

const WikiSpaceSelect = () => {
    const { canEdit, canView, allowedSpaces } = useWikiPermissions();
    const dispatch = useDispatch();
    const wikiSpace = useSelector(wikiSelectors.activeWikiSpace);

    useEffect(() => {
        if (!allowedSpaces.includes(wikiSpace)) {
            dispatch(wikiActions.setActiveWikiSpace(allowedSpaces[0]));
        }
    }, [allowedSpaces, wikiSpace]);

    if (!canEdit && !canView) {
        return null;
    }

    return (
        <Select
            className="mt-2 mb-3"
            value={wikiSpace}
            options={WIKI_SPACE_OPTIONS.filter((o) => allowedSpaces.includes(o.value))}
            onChange={(v) => dispatch(wikiActions.setActiveWikiSpace(v))}
        />
    );
};

export default WikiSpaceSelect;
