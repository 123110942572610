import { Formik } from 'formik';
import { useChatMessageContext } from 'platform/chat/ChatProviders/ChatMessageProvider';
import ChatAssistantQuestionList from './ChatAssistantQuestionList';

export interface Question {
    label: string;
    fieldType: FieldType;
}

export type FieldType = 'INPUT' | 'TEXTAREA';

interface Props {
    content: Question[];
}

const ChatAssistantQuestionsMessage = ({ content }: Props) => {
    const { submit } = useChatMessageContext();

    const handleSubmit = (values: { questions: string[] }) => {
        const prompt = values.questions.map((v, i) => `Answer ${i + 1}: ${v}`).join('\n\n');
        submit([{ text: prompt }]);
    };

    return (
        <Formik initialValues={{ questions: content.map(() => '') }} onSubmit={handleSubmit}>
            <ChatAssistantQuestionList questions={content} />
        </Formik>
    );
};

export default ChatAssistantQuestionsMessage;
