import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { goBack } from 'redux-first-history';
import './NotFound.scss';

const NotFoundPage = () => {
    const dispatch = useDispatch();
    return (
        <div className="NotFound">
            <div className="NotFound-logo">404</div>
            <div className="NotFound-title">Page Not Found</div>
            <Button color="link" onClick={() => dispatch(goBack())}>
                <i className="fal fa-arrow-left" /> Get back
            </Button>
        </div>
    );
};

export default NotFoundPage;
