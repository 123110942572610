import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import ChatAgentBtn from '../ChatAgents/ChatAgentBtn';
import { useChatAgentContext } from '../ChatProviders/ChatAgentProvider';

interface Props {
    onAgentSelect?: () => void;
    handleAdvertiserReset?: () => void;
}

const ChatAgentsSelect = ({ onAgentSelect, handleAdvertiserReset }: Props) => {
    const { agents, selectedAgent } = useChatAgentContext();
    const isAdmin = useSelector(authSelectors.isAdmin);
    const profile = useTypedSelector(authSelectors.ready.profile);
    const activeAdvertiserIds = useSelector(activeAdvertiserSelectors.ids);
    const [showAllAgents, setShowAllAgents] = useState(!isAdmin);

    const availableAgents = agents.filter((agent) =>
        agent?.advertiserIds?.length
            ? agent.advertiserIds.some((id) => activeAdvertiserIds.includes(id))
            : true
    );

    useEffect(() => {
        if (
            selectedAgent?.id &&
            availableAgents.find((agent) => agent.id === selectedAgent.id) === undefined &&
            handleAdvertiserReset
        ) {
            handleAdvertiserReset();
        }
    }, [activeAdvertiserIds]);

    const filteredAgents = availableAgents.filter(
        (agent) =>
            showAllAgents ||
            !profile.adminUser ||
            (!agent.betaTesting &&
                !agent.adminOnly &&
                (!agent.advertiserIds || agent.advertiserIds.length === 0))
    );

    return (
        <ul className="ChatLanding-agents">
            {filteredAgents.map((agent) => (
                <li key={agent.id} style={agent.id === selectedAgent?.id ? { marginTop: -15 } : undefined}>
                    <Tooltip renderTooltip={() => agent.name}>
                        <ChatAgentBtn
                            agent={agent}
                            onAgentSelect={onAgentSelect}
                            size={agent.id === selectedAgent?.id ? 'medium' : 'small'}
                        />
                    </Tooltip>
                </li>
            ))}
            {!showAllAgents && filteredAgents.length !== agents.length && (
                <li>
                    <ChatAgentBtn
                        placeholderName="... more"
                        placeholderIcon="fa-ellipsis"
                        onAgentSelect={() => setShowAllAgents(true)}
                    />
                </li>
            )}
        </ul>
    );
};

export default ChatAgentsSelect;
