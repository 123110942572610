import { ReactNode, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import ReportMetricIcons from 'platform/analytics/reportComponents/ReportMetricIcons';
import DeltaText from 'platform/common/components/DeltaText/DeltaText';
import { Delta } from 'platform/common/components/DeltaText/deltas.util';
import StatisticTooltip, { Tooltip } from 'platform/common/components/StatisticTooltip/StatisticTooltip';
import './StatisticWithDelta.scss';

interface Props {
    name: string;
    columnKey?: string;
    children?: ReactNode;
    value?: string | number;
    delta?: Delta;
    tooltip?: Tooltip;
    link?: string;
    className?: string;
    valueClassName?: string;
    compact?: boolean;
    showCompareValue?: boolean;
}

const StatisticWithDelta = ({
    name,
    columnKey,
    children,
    value,
    delta,
    tooltip,
    link,
    className,
    valueClassName,
    compact,
    showCompareValue = false,
}: Props) => {
    const { metrics } = useAnalyticsMetadata().definitions;
    const childrenRef = useRef<HTMLSpanElement>(null);

    return (
        <div
            className={classNames('StatisticWithDelta', className, {
                'StatisticWithDelta--compact': compact,
                'StatisticWithDelta--standard': !compact,
            })}
        >
            <div className="StatisticWithDelta-name d-flex align-items-center mb-3">
                <span ref={childrenRef} className="text-muted text-truncate">
                    {name}
                </span>
                {tooltip && <StatisticTooltip target={childrenRef.current} tooltip={tooltip} />}
                {children}
                <ReportMetricIcons className="ms-auto" metric={metrics.find((m) => m.key === columnKey)} />
            </div>
            <div className="mb-auto">
                <div className="StatisticWithDelta-value">
                    <h4 className={classNames('mb-0 text-truncate', valueClassName)}>
                        {link ? <Link to={link}>{value}</Link> : value}
                    </h4>
                </div>
                {delta && (
                    <DeltaText
                        className="StatisticWithDelta-delta"
                        delta={delta}
                        showCompareValue={showCompareValue}
                    />
                )}
            </div>
        </div>
    );
};

export default StatisticWithDelta;
