import { useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import './SegmentKPIOverview.scss';

interface Props {
    options: {
        tab: string;
        label: string;
        value?: string;
        Component: () => JSX.Element;
    }[];
    loading: boolean;
}

const KPIOverviewNavigation = ({ options, loading }: Props) => {
    const [currentTab, setTab] = useState(options[0].tab);
    const selectedTab = options.find(({ tab }) => tab === currentTab);

    return (
        <InsetBox className="custom-tabs p-3 mb-3 mb-md-0 h-100">
            <div className="ChartNavbar-navigation mb-3">
                <Nav tabs className="ChartNavbar-tabs">
                    {options.map(({ tab, label, value }) => (
                        <NavItem key={tab} className="ChartNavbar-item col-sm-3 p-0">
                            <NavLink onClick={() => setTab(tab)} active={currentTab === tab}>
                                {loading ? (
                                    <Placeholder className="pb-1" height="20px" />
                                ) : (
                                    <Tooltip renderTooltip={() => label}>
                                        <div className="ChartNavbar-label text-truncate">{label}</div>
                                    </Tooltip>
                                )}
                                <h5 className="ChartNavbar-value text-truncate mb-0">
                                    {loading ? (
                                        <Placeholder className="pt-1 pe-5" height="20px" />
                                    ) : (
                                        value || '-'
                                    )}
                                </h5>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
            {selectedTab && (
                <div className="SegmentCharts-charts">
                    <selectedTab.Component />
                </div>
            )}
        </InsetBox>
    );
};

export default KPIOverviewNavigation;
