import { SelectItem } from 'platform/common/common.types';
import { Status } from 'platform/common/constants/status.constant';

export const makeOptions = <T extends string>(labels: { [key in T]: string }): SelectItem<T>[] =>
    Object.keys(labels).map((key: T) => ({ value: key, label: labels[key] }));

export const namesToOptions = (
    items: { name: string; status?: Status }[] | undefined
): SelectItem<string>[] => (items ?? []).map((i) => ({ value: i.name, label: i.name, status: i.status }));

export const entitiesToOptions = (
    entities: { id?: number; name: string; status?: Status }[] | undefined
): SelectItem<number>[] => (entities ?? []).map((e) => ({ value: e.id!!, label: e.name, status: e.status }));
