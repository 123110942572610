import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { positiveNumber, required } from 'platform/common/utils/validators.util';
import FormDatePicker from 'platform/formik/FormDatePicker/FormDatePicker';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import { OptInRateFormModel } from './optInRates.types';

const OptInRateForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: { submitForm },
}: FormProps<OptInRateFormModel>) => (
    <CardForm
        title={`${labels.prefix} Opt-In Rate`}
        submitLabel={labels.submit}
        disabled={!canEdit}
        onCancel={onCancel}
        onSubmit={submitForm}
    >
        <ControlledCard title="General info" subtitle="required">
            <FormRow label="Opt-In Rate name">
                <FormInput name="name" type="text" />
            </FormRow>
            <FormRow label="Opt-In Rate">
                <FormNumberInput name="value" type="text" validate={[required, positiveNumber]} />
            </FormRow>
            <FormRow label="Opt-In Rate start date">
                <FormDatePicker name="dateFrom" validate={required} />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default OptInRateForm;
