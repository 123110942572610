import { hot } from 'react-hot-loader/root';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Button } from 'reactstrap';
import { Store } from 'redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { ApolloProvider } from '@apollo/client';
import { History } from 'history';
import GoogleCredentials from 'platform/advertisers/components/GoogleCredentials/GoogleCredentials';
import GoogleTokenRetrieval from 'platform/advertisers/components/GoogleCredentials/GoogleTokenRetrieval';
import { ChartColorProvider } from 'platform/analytics/reportComponents/ChartColorProvider';
import { AppProvider } from 'platform/app/AppContext';
import { GOOGLE_TOKEN_RETRIEVAL_PATH, SET_GOOGLE_CREDENTIALS_PATH } from 'platform/app/app.navigation';
import { authActions } from 'platform/app/ducks/auth.duck';
import { Action } from 'platform/common/common.types';
import ErrorIndicator from 'platform/common/components/Errors/ErrorIndicator';
import { ModalProvider } from 'platform/common/components/Modal/Modal';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import apolloClient from 'platform/common/utils/createApolloClient.util';
import { RootState } from 'platform/rootState.type';
import { urlBasename } from 'platform/store';
import { chat, login } from '../../app.routes';
import { AppState, appStateSelectors } from '../../ducks/appStateSelectors';
import App from '../App/App';

type Props = {
    store: Store<RootState, Action>;
    history: History;
};

const AppError = ({ message, error }: { message: string; error: any }) => {
    const dispatch = useDispatch();
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: 200 }}>
            <ErrorIndicator error={error} position="static" />
            <h6 className="mt-1">{message}</h6>
            <Button onClick={() => dispatch(authActions.logout())}>Retry loging in</Button>
        </div>
    );
};

const getAppComponentForState = (appState: AppState) => {
    switch (appState.type) {
        case 'READY':
            return <App />;
        case 'PENDING':
            return <OverlayLoader />;
        case 'ERROR':
            return <AppError message={appState.message} error={appState.originalError} />;
        default:
            return null;
    }
};

const RootRoutes = () => {
    const appState = useSelector(appStateSelectors.getState);

    return (
        <Routes>
            <Route path={chat.path} element={<chat.component />} />
            <Route path={`${login.path}/*`} element={<login.component />} />
            <Route path={SET_GOOGLE_CREDENTIALS_PATH} element={<GoogleCredentials />} />
            <Route path={GOOGLE_TOKEN_RETRIEVAL_PATH} element={<GoogleTokenRetrieval />} />
            <Route path="/*" element={getAppComponentForState(appState)} />
        </Routes>
    );
};

const Root = ({ store, history }: Props) => (
    <ChartColorProvider>
        <DndProvider backend={HTML5Backend}>
            <ApolloProvider client={apolloClient}>
                <ReduxProvider store={store}>
                    <Router history={history} basename={urlBasename}>
                        <ModalProvider>
                            <AppProvider>
                                <ToastContainer autoClose={8000} closeOnClick={false} />
                                <RootRoutes />
                            </AppProvider>
                        </ModalProvider>
                    </Router>
                </ReduxProvider>
            </ApolloProvider>
        </DndProvider>
    </ChartColorProvider>
);

export default hot(Root);
