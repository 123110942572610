import {
    BarState,
    CommonChartType,
    CommonInteractionComponent,
    DoughnutState,
    DatePickerInteractionState,
    FilterInteractionState,
    InputInteractionState,
    SelectInteractionState,
    SliderInteractionState,
    LineState,
    LogBookState,
    NoteState,
    SpacerState,
    StackBarState,
    StackLineState,
    SummaryBarState,
    SunburstState,
    TableState,
    WordCloudState,
} from 'platform/analytics/analytics.types';
import DoughnutSvg from 'platform/analytics/reportComponents/ReportComponentActions/DoughnutSvg';
import StackBarSvg from 'platform/analytics/reportComponents/ReportComponentActions/StackBarSvg';
import WordcloudSvg from 'platform/analytics/reportComponents/ReportComponentActions/WordcloudSvg';

type OmitId<T> = Omit<T, 'id'>;

const TABLE_DEFAULT_STATE: OmitId<TableState> = {
    type: 'TABLE',
    name: 'Table',
    activePresetIndex: 0,
    presets: [
        {
            name: 'Default',
            templateId: 'all_columns',
            metrics: [],
            dimensions: [],
            hideEmptyColumns: false,
        },
    ],
    compatibleFilters: [],
    sort: [],
    pageSize: 100,
    pageNumber: 0,
    placement: {
        width: 12,
    },
    group: 'DATA',
    icon: 'fal fa-table',
};

const SUMMARY_TABLE_DEFAULT_STATE: OmitId<TableState> = {
    ...TABLE_DEFAULT_STATE,
    name: 'Summary Table',
    summaryTable: true,
    icon: 'fal fa-table-list',
};

const SUMMARY_BAR_DEFAULT_STATE: OmitId<SummaryBarState> = {
    name: 'Summary Bar',
    compatibleFilters: [],
    type: 'SUMMARY_BAR',
    templateId: 'all_columns',
    placement: {
        width: 12,
    },
    rows: [[]],
    isChartVisible: false,
    showChartValues: false,
    activeDimension: 'date',
    group: 'DATA',
    icon: 'fal fa-table-cells-large',
};

const CHART_BASE_STATE: Omit<CommonChartType, 'id' | 'name' | 'icon' | 'chartType'> = {
    type: 'CHART',
    templateId: 'all_columns',
    dimensions: [],
    metrics: [],
    compatibleFilters: [],
    group: 'CHARTS',
    placement: {
        width: 6,
    },
    entriesCount: 10,
};

const LINE_CHART_DEFAULT_STATE: OmitId<LineState> = {
    ...CHART_BASE_STATE,
    name: 'Line',
    chartType: 'LINE',
    periodDimension: 'date',
    icon: 'fal fa-chart-line',
    sortBy: 'METRIC',
    showValues: false,
};

const STACK_LINE_CHART_DEFAULT_STATE: OmitId<StackLineState> = {
    ...CHART_BASE_STATE,
    name: 'Stacked Line',
    chartType: 'STACK_LINE',
    periodDimension: 'date',
    icon: 'fal fa-chart-area',
    sortBy: 'METRIC',
    showValues: false,
};

const DOUGHNUT_CHART_DEFAULT_STATE: OmitId<DoughnutState> = {
    ...CHART_BASE_STATE,
    name: 'Doughnut',
    chartType: 'DOUGHNUT',
    icon: DoughnutSvg,
    showOtherValues: true,
};

const BAR_CHART_DEFAULT_STATE: OmitId<BarState> = {
    ...CHART_BASE_STATE,
    chartType: 'BAR',
    name: 'Bar',
    icon: 'fal fa-chart-column',
    sortBy: 'METRIC',
    showOtherValues: true,
};
const STACK_BAR_CHART_DEFAULT_STATE: OmitId<StackBarState> = {
    ...CHART_BASE_STATE,
    chartType: 'STACK_BAR',
    name: 'Stacked Bar',
    icon: StackBarSvg,
    sortBy: 'METRIC',
    showOtherValues: true,
};

const WORD_CLOUD_CHART_DEFAULT_STATE: OmitId<WordCloudState> = {
    ...CHART_BASE_STATE,
    chartType: 'WORD_CLOUD',
    icon: WordcloudSvg,
    name: 'Wordcloud',
};

const SUNBURST_CHART_DEFAULT_STATE: OmitId<SunburstState> = {
    ...CHART_BASE_STATE,
    chartType: 'SUNBURST',
    name: 'Sunburst',
    icon: 'fal fa-chart-pie',
};

const SPACER_DEFAULT_STATE: OmitId<SpacerState> = {
    type: 'SPACER',
    placement: {
        width: 12,
    },
    group: 'GENERAL',
    icon: 'fal fa-folder-closed',
    name: 'Section',
};

const NOTE_DEFAULT_STATE: OmitId<NoteState> = {
    placement: {
        width: 12,
    },
    type: 'NOTE',
    note: {
        display: 'AlWAYS',
        text: '',
    },
    group: 'GENERAL',
    icon: 'fal fa-note-sticky',
    name: 'Note',
};

const LOG_BOOK_DEFAULT_STATE: OmitId<LogBookState> = {
    type: 'LOG_BOOK',
    placement: {
        width: 12,
    },
    group: 'GENERAL',
    icon: 'fal fa-book',
    name: 'Log Book',
};

const INTERACTION_DEFAULT_STATE: OmitId<CommonInteractionComponent> = {
    type: 'INTERACTION',
    interactionType: 'INPUT',
    key: '',
    placement: {
        width: 12,
    },
    group: 'GENERAL',
    icon: 'fal fa-user-pen',
    name: 'Interaction',
    required: false,
};

const INTERACTION_SELECT_DEFAULT_STATE: OmitId<SelectInteractionState> = {
    ...INTERACTION_DEFAULT_STATE,
    interactionType: 'SELECT',
    icon: 'fal fa-square-check',
    name: 'Select',
    selectOptions: [],
};

const INTERACTION_FILTER_DEFAULT_STATE: OmitId<FilterInteractionState> = {
    ...INTERACTION_DEFAULT_STATE,
    interactionType: 'FILTER',
    icon: 'fal fa-filter',
    name: 'Filter',
    filter: '',
};

const INTERACTION_SLIDER_DEFAULT_STATE: OmitId<SliderInteractionState> = {
    ...INTERACTION_DEFAULT_STATE,
    interactionType: 'SLIDER',
    icon: 'fal fa-slider',
    name: 'Slider',
    minValue: 1,
    maxValue: 10,
    step: 1,
};

const INTERACTION_INPUT_DEFAULT_STATE: OmitId<InputInteractionState> = {
    ...INTERACTION_DEFAULT_STATE,
    interactionType: 'INPUT',
    icon: 'fal fa-input-numeric',
    name: 'Input',
};

const INTERACTION_DATE_PICKER_DEFAULT_STATE: OmitId<DatePickerInteractionState> = {
    ...INTERACTION_DEFAULT_STATE,
    interactionType: 'DATE_PICKER',
    icon: 'fal fa-calendar-days',
    name: 'Date picker',
    fromKey: '',
    toKey: '',
    onlyFuture: false,
    onlyPast: false,
};

export const INTERACTION_COMPONENT_DEFAULTS = [
    INTERACTION_INPUT_DEFAULT_STATE,
    INTERACTION_SLIDER_DEFAULT_STATE,
    INTERACTION_SELECT_DEFAULT_STATE,
    INTERACTION_FILTER_DEFAULT_STATE,
    INTERACTION_DATE_PICKER_DEFAULT_STATE,
];

export const CHARTS = [
    LINE_CHART_DEFAULT_STATE,
    STACK_LINE_CHART_DEFAULT_STATE,
    BAR_CHART_DEFAULT_STATE,
    STACK_BAR_CHART_DEFAULT_STATE,
    DOUGHNUT_CHART_DEFAULT_STATE,
    SUNBURST_CHART_DEFAULT_STATE,
    WORD_CLOUD_CHART_DEFAULT_STATE,
];

export const REPORT_COMPONENTS = [
    SUMMARY_BAR_DEFAULT_STATE,
    SUMMARY_TABLE_DEFAULT_STATE,
    TABLE_DEFAULT_STATE,
    SPACER_DEFAULT_STATE,
    NOTE_DEFAULT_STATE,
    LOG_BOOK_DEFAULT_STATE,
    INTERACTION_DEFAULT_STATE,
    ...CHARTS,
];
