import { ADVERTISERS } from './advertisers.navigation';
import AdvertiserList from './components/AdvertiserList/AdvertiserList';

const advertiserRoutes = [
    {
        ...ADVERTISERS,
        component: AdvertiserList,
    },
];

export default advertiserRoutes;
