import { Store } from 'redux';
import { isEqual } from 'lodash-es';
import { Action } from 'platform/common/common.types';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import { parseQuery } from 'platform/common/utils/url.util';
import { AuthState, defaultAuthState, tokenExpired } from './app/ducks/auth.duck';
import {
    activeAdvertiserSelectors,
    ActiveAdvertiserState,
    getActiveAdvertiserDefaultState,
} from './common/ducks/activeAdvertiser.duck';
import localStorage from './common/utils/localStorage.util';
import { RootState } from './rootState.type';

type CommonRootState = {
    session: AuthState;
    common: {
        activeAdvertiser: ActiveAdvertiserState;
        dateFilter: Periods;
    };
};

export const loadStateFromStorage = (): CommonRootState => {
    const { hash } = window.location;
    const queryStartsAt = hash.indexOf('?');
    if (queryStartsAt > -1) {
        const { advertiserIds } = parseQuery(hash.substring(queryStartsAt));
        if (advertiserIds) {
            localStorage.set('activeAdvertisers', [advertiserIds]);
        }
    }

    const session: AuthState = localStorage.get('session');
    const dateFilter: Periods = localStorage.get('dateFilter');

    const isSessionValid = session && session.type === 'READY' && !tokenExpired(session.tokenExpires);

    return {
        session: isSessionValid ? session : defaultAuthState,
        common: {
            activeAdvertiser: {
                ...getActiveAdvertiserDefaultState(),
                ids: localStorage.get('activeAdvertisers') || [],
            },
            dateFilter,
        },
    };
};

export const handleStateToStorageSync = (globalStore: Store<RootState, Action>) => {
    let prevState: RootState | null = null;

    globalStore.subscribe(() => {
        const state = globalStore.getState();

        const session = state.session;
        const prevSession = prevState && prevState.session;
        if (session !== prevSession) {
            localStorage.set('session', session);
        }

        const activeAdvertiserIds = activeAdvertiserSelectors.uncheckedIds(state);
        const prevActiveAdvertiserIds = prevState && activeAdvertiserSelectors.uncheckedIds(prevState);

        const advertisersChanged =
            activeAdvertiserIds.length &&
            prevActiveAdvertiserIds?.length &&
            !isEqual(activeAdvertiserIds, prevActiveAdvertiserIds);

        if (advertisersChanged) {
            localStorage.set('activeAdvertisers', activeAdvertiserIds);
        }

        const dateFilter = state.common.dateFilter;
        const prevDateFilter = prevState && prevState.common.dateFilter;

        if (!isEqual(prevDateFilter, dateFilter)) {
            localStorage.set('dateFilter', dateFilter);
        }

        prevState = state;
    });
};
