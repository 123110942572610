import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'redux-first-history';
import CardForm from 'platform/common/components/CardForm/CardForm';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import WorkflowStages from './WorkflowStages/WorkflowStages';
import WorkflowTable from './WorkflowTable/WorkflowTable';
import { useMediaplanWorkflow } from './useMediaplanWorkflow';
import './MediaplanWorkflow.scss';

interface Props {
    canEdit: boolean;
    redirectTo: string;
}

const MediaplanWorkflow = ({ canEdit, redirectTo }: Props) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const dispatch = useDispatch();
    const {
        lanes,
        workflowEvents,
        mediaplanSteps,
        loading,
        changeMediaplanStep,
        changeLaneStep,
        changeMultiLaneSteps,
        changeLaneAssignee,
    } = useMediaplanWorkflow(id);

    return (
        <CardForm
            title={`${canEdit ? 'Edit' : 'View'} mediaplan workflow`}
            subtitle={`ID: ${id}`}
            cardBodyClassname="h-100"
            cancelLabel="Close"
            disabled={!canEdit}
            onCancel={() => dispatch(push(redirectTo))}
        >
            <div className="MediaplanWorkflow">
                <WorkflowStages
                    lanes={lanes}
                    workflowEvents={workflowEvents}
                    mediaplanSteps={mediaplanSteps}
                    canEdit={canEdit}
                    onChange={changeMediaplanStep}
                />
                <div className="MediaplanWorkflow-lanes">
                    <WorkflowTable
                        lanes={lanes}
                        workflowEvents={workflowEvents}
                        canEdit={canEdit}
                        onAssigneeChange={changeLaneAssignee}
                        onBatchChange={changeMultiLaneSteps}
                        onChange={changeLaneStep}
                    />
                </div>
                {loading && <OverlayLoader />}
            </div>
        </CardForm>
    );
};

export default MediaplanWorkflow;
