import { useEffect, useRef } from 'react';

const useAutoFocus = <T extends HTMLElement>() => {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [ref]);

    return ref;
};

export default useAutoFocus;
