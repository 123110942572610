import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import OptInRateForm from './OptInRateForm';
import { createOptInRate, fetchOptInRate, updateOptInRate } from './optInRates.service';
import { OptInRate, OptInRateFormModel } from './optInRates.types';

const toPayload = (model: OptInRateFormModel, advertiserId: number, id: number): OptInRate => ({
    id,
    advertiserId,
    name: model.name!,
    value: model.value!,
    dateFrom: model.dateFrom!,
});

const OptInRateFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);

    const handleOpen = async (): Promise<OptInRateFormModel> => {
        if (id) {
            const optInRate = await fetchOptInRate(id);

            return {
                name: optInRate.name,
                value: optInRate.value,
                dateFrom: optInRate.dateFrom,
            };
        }

        return { name: '', value: undefined, dateFrom: '' };
    };

    const handleSubmit = (model: OptInRateFormModel) => {
        const payload = toPayload(model, advertiserId, id);

        if (id) {
            return updateOptInRate(payload);
        }

        return createOptInRate(payload);
    };

    return (
        <FormContainer
            helpKey="opt_in_rate_form"
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={!!id}
            sidePanel
            onSubmitFinish={afterSubmit}
        >
            {(props) => <OptInRateForm {...props} />}
        </FormContainer>
    );
};

export default OptInRateFormContainer;
