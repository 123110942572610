import { Button, Form } from 'reactstrap';
import { Formik } from 'formik';
import { ColouringType, ColumnColouringType } from 'platform/analytics/analytics.types';
import ColourBox from 'platform/analytics/reportComponents/ColumnColouring/ColourBox';
import NumberInputWithColour from 'platform/analytics/reportComponents/ColumnColouring/NumberInputWithColour';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { required } from 'platform/common/utils/validators.util';
import FormRadioList from 'platform/formik/FormRadioList/FormRadioList';

const colouringTypeOptions: { value: ColouringType; label: string }[] = [
    { value: 'TEXT', label: 'Text' },
    { value: 'CELL', label: 'Cell' },
];

const red = '#f6515b';
const green = '#17d390';

const colourOptions = [
    { label: 'Red for highest values', value: red },
    { label: 'Green for highest values', value: green },
];

interface Props {
    initialValues?: ColumnColouringType;
    onSubmit: (model: ColumnColouringType) => void;
    onReset: () => void;
}

const defaultValues: ColumnColouringType = { type: 'TEXT', maxColour: red, minColour: green, min: 0, max: 0 };

const ColumnColouringForm = ({ initialValues, onSubmit, onReset }: Props) => (
    <Formik<ColumnColouringType> initialValues={initialValues ?? defaultValues} onSubmit={onSubmit}>
        {({ values, handleSubmit, setFieldValue, getFieldMeta, dirty }) => (
            <>
                <Form onSubmit={handleSubmit}>
                    <FormRow labelWidth={0}>
                        <FormRadioList
                            options={colouringTypeOptions}
                            name="type"
                            className="d-flex flex-fill"
                            radioListGroupClassName="me-2"
                            validate={[required]}
                        />
                    </FormRow>
                    <FormRow labelWidth={0}>
                        <FormRadioList
                            options={colourOptions}
                            name="maxColour"
                            className="d-flex flex-fill"
                            radioListGroupClassName="me-2"
                            validate={[required]}
                            onChange={(maxColour) => {
                                setFieldValue('minColour', maxColour === red ? green : red);
                            }}
                        />
                    </FormRow>
                    <div
                        style={{
                            borderTop: '1px dashed #cbd0d9',
                        }}
                        className="mb-3"
                    />
                    <FormRow inline labelWidth={0} childrenColClassName="justify-content-between">
                        <NumberInputWithColour
                            name="max"
                            color={values.maxColour}
                            fieldMeta={getFieldMeta('max')}
                        />
                        <span className="fw-bold mx-2">{'>='}</span>
                        <ColourBox />
                        <span className="fw-bold mx-2">{'>='}</span>
                        <NumberInputWithColour
                            name="min"
                            color={values.minColour}
                            fieldMeta={getFieldMeta('min')}
                        />
                    </FormRow>
                    <div className="d-flex justify-content-end">
                        <Button color="secondary" className="me-2" onClick={onReset}>
                            Reset
                        </Button>
                        <Button color="primary" type="submit" disabled={!dirty}>
                            Apply
                        </Button>
                    </div>
                </Form>
            </>
        )}
    </Formik>
);

export default ColumnColouringForm;
