import { Button } from 'reactstrap';
import ErrorModal from 'platform/common/components/Errors/ErrorModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import { ErrorDetails } from 'platform/common/error.types';

interface Props {
    error: ErrorDetails | undefined;
    loading?: boolean;
    position?: 'static' | 'absolute';
    className?: string;
    onRetry?: () => void;
}

const ErrorIndicator = ({ error, loading = false, position = 'absolute', className, onRetry }: Props) => {
    const { showModal } = useModal();
    if (!error || loading) {
        return null;
    }

    const handleOpenModal = () => {
        showModal((toggle) => <ErrorModal error={error} toggle={toggle} />);
    };

    return (
        <div
            style={{
                ...(position === 'absolute' && { position: 'absolute', right: 1, top: 1 }),
                backgroundColor: 'rgba(247,225,226,.7)',
                borderRadius: '3px',
                paddingTop: '1px',
            }}
            className={className}
        >
            {onRetry && (
                <Button color="link" size="sm" onClick={() => onRetry()}>
                    <i style={{ color: 'rgba(100,0,0,.7)' }} className="fal fa-redo cursor-pointer me-1" />
                </Button>
            )}
            <Button color="link" size="sm" onClick={handleOpenModal}>
                <i
                    style={{ color: 'rgba(100,0,0,.7)' }}
                    className="fal fa-exclamation-circle cursor-pointer"
                />
            </Button>
        </div>
    );
};

export default ErrorIndicator;
