import { uniq } from 'lodash-es';
import { LOG_CATEGORY_LABELS } from 'platform/campaign/logBook/logBook.constant';
import { Channel } from 'platform/channels/channel.types';
import { getChannelName } from 'platform/channels/channel.util';
import { isDefined } from 'platform/common/common.types';
import {
    CHANGE_REASON_LABELS,
    MEDIA_INSERTION_STEPS,
    MediaInsertionStepType,
    MEDIAPLAN_STEPS,
    MediaplanStepType,
} from 'platform/mediaplan/mediaplan.types';
import { LogBookEntry } from './logBook.types';

export const getChannelLabels = (log: LogBookEntry, channels: Channel[]) => {
    const codes = log.mediaInsertions.flatMap((mi) => mi.channels).filter(isDefined);
    const names = uniq(codes).map((code) => getChannelName(code, channels));
    return names.sort().join(', ');
};

export const getCategoryLabel = (log: LogBookEntry) => {
    if (!log.eventDetails) return LOG_CATEGORY_LABELS[log.category];

    const { reason, stepType } = log.eventDetails;
    if (log.category === 'BUDGET_SHIFTING' && reason) {
        return CHANGE_REASON_LABELS[reason];
    }
    if (log.category === 'WORKFLOW' && stepType) {
        return (
            MEDIAPLAN_STEPS[stepType as MediaplanStepType]?.label ??
            MEDIA_INSERTION_STEPS[stepType as MediaInsertionStepType]?.label
        );
    }

    return LOG_CATEGORY_LABELS[log.category];
};
