import imageIcon from 'platform/assets/images/file-image.png';
import otherIcon from 'platform/assets/images/file-other.png';
import pdfIcon from 'platform/assets/images/file-pdf.png';
import tableIcon from 'platform/assets/images/file-table.png';
import { getFileExtension } from 'platform/common/utils/file.util';

const ICON_MAP: Record<string, any> = {
    pdf: pdfIcon,
    xls: tableIcon,
    xlsx: tableIcon,
    csv: tableIcon,
    tsv: tableIcon,
    jpg: imageIcon,
    jpeg: imageIcon,
    png: imageIcon,
    gif: imageIcon,
};

const FileIcon = ({ filename, className }: { filename: string; className?: string }) => (
    <img src={ICON_MAP[getFileExtension(filename) ?? ''] ?? otherIcon} alt={filename} className={className} />
);

export default FileIcon;
