import { forwardRef } from 'react';
import { Input, InputGroup, InputGroupText, InputProps } from 'reactstrap';
import classNames from 'classnames';

export interface AddOn {
    className?: string;
    containerClassName?: string;
    title?: string | JSX.Element;
    onClick?: () => void;
}

interface Props {
    leftAddOn?: AddOn;
    rightAddOn?: AddOn;
    invalid?: boolean;
    hideInput?: boolean;
    inputClassName?: string;
}

const InputWithAddOn = forwardRef<HTMLInputElement, Props & InputProps>(
    ({ leftAddOn, rightAddOn, className, invalid, hideInput, inputClassName, ...rest }, ref) => (
        <InputGroup className={classNames(className, { 'form-control is-invalid m-0 p-0': invalid })}>
            {leftAddOn && (
                <InputGroupText
                    className={classNames(leftAddOn?.containerClassName, {
                        'cursor-pointer': leftAddOn.onClick,
                    })}
                    onClick={leftAddOn.onClick}
                >
                    <i className={leftAddOn.className} />
                    {leftAddOn.title}
                </InputGroupText>
            )}
            {!hideInput && (
                <Input
                    {...rest}
                    innerRef={ref}
                    className={classNames(inputClassName, { 'border-right-0': rightAddOn })}
                />
            )}
            {rightAddOn && (
                <InputGroupText
                    className={classNames('bg-white', rightAddOn?.containerClassName, {
                        'cursor-pointer': rightAddOn.onClick,
                    })}
                    onClick={rightAddOn.onClick}
                >
                    <i className={rightAddOn.className} />
                    {rightAddOn.title}
                </InputGroupText>
            )}
        </InputGroup>
    )
);

export default InputWithAddOn;
