import classNames from 'classnames';

interface Props {
    customVariableJson?: string;
    className?: string;
}

const JourneyRowCustomVariables = ({ customVariableJson, className }: Props) => {
    if (!customVariableJson) return null;

    const customVariables = Object.entries<string>(JSON.parse(customVariableJson));

    return (
        <div className={classNames('p-2 mt-3 bg-light font-sm', className)}>
            <i className="text-success text-center me-2 fal fa-cube" style={{ width: 30 }} />
            Additional variables:
            {customVariables.map(([key, value], index) => (
                <div key={index} style={{ marginLeft: 38 }}>
                    {key}: <span className="fw-bold">{value}</span>
                </div>
            ))}
        </div>
    );
};

export default JourneyRowCustomVariables;
