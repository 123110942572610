import { formatDateTime } from '../../utils/date.util';
import LabelWithSubtext from '../LabelWithSubtext/LabelWithSubtext';

interface Props {
    date?: string | null | undefined;
    updatedBy?: string | null | undefined;
    className?: string;
}

const UpdatedOn = ({ date, updatedBy, className }: Props) => (
    <LabelWithSubtext className={className} label={formatDateTime(date)} subtext={updatedBy || ''} />
);

export default UpdatedOn;
