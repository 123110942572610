import axiosBff from 'platform/axios/axiosBff';

export type JobStatus = 'RUNNING' | 'COMPLETED' | 'FAILED';

type DataIngestionJob = {
    name: string;
    vendor: string;
};

interface DataIngestionAccount {
    id: number;
    name: string;
}

export type DataIngestionJobParams = {
    periodFrom?: string;
    periodTo?: string;
    periodChunkSize?: number;
    accounts?: number[];
    forceFetchFiles: boolean;
};

export type DataIngestionJobExecution = {
    name: string;
    vendor: string;
    status: JobStatus;
    params?: DataIngestionJobParams;
    result?: { totalAccounts?: number; totalRows: number };
    message?: string;
    startedOn: string;
    startedBy: string;
    endedOn?: string;
};

export type MailJobConfiguration = {
    jobName?: string;
    dateColumn?: string;
    datePartitionToColumn?: string;
    partitionColumn?: string;
    headerLinesToSkip?: number;
    footerLinesToSkip?: number;
    leadingColumnsToSkip?: number;
    trailingColumnsToSkip?: number;
    dateFormat?: String;
    columnDelimiter?: String;
    urlRegex?: String;
};

export const fetchDataIngestionJobs = () =>
    axiosBff.get<DataIngestionJob[]>('/ingestion/api/jobs').then((r) => r.data);

export const fetchDataIngestionJobAccounts = (jobName: string) =>
    axiosBff.get<DataIngestionAccount[]>(`/ingestion/api/jobs/${jobName}/accounts`).then((r) => r.data);

export const runDataIngestionJob = (name: string, vendor: string, params?: DataIngestionJobParams) => {
    if (vendor === 'mail') {
        return axiosBff.post(`/ingestion/api/mails/${name}/import`, params).then((r) => r.data);
    }
    return axiosBff.post(`/ingestion/api/jobs/${name}/executions`, params).then((r) => r.data);
};

export const fetchDataIngestionJobExecutions = (params: { name?: string; since?: string; until?: string }) =>
    axiosBff
        .get<DataIngestionJobExecution[]>('/ingestion/api/jobs/executions', { params })
        .then((r) => r.data);

export const fetchMailJobConfiguration = (jobName: string) =>
    axiosBff
        .get<MailJobConfiguration>(`/ingestion/api/mails/${jobName}/configuration`)
        .then((res) => ({ ...res.data, jobName }));

export const updateMailJobConfiguration = (model: MailJobConfiguration) =>
    axiosBff.put<void>(`/ingestion/api/mails/${model.jobName}/configuration`, model).then((r) => r.data);
