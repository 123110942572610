import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';
import { MediaplanStepType } from 'platform/mediaplan/mediaplan.types';
import ControlledStep from './ControlledStep';
import StageCardHeader from './StageCardHeader';
import { WorkflowStagesProps } from './WorkflowStages';

type Props = Omit<WorkflowStagesProps, 'lanes'>;

const steps: MediaplanStepType[] = ['CUSTOMER_APPROVAL', 'INVOICE_SENT'];

const ApprovalStage = (props: Props) => (
    <SimpleCard
        headerContent={
            <StageCardHeader
                icon={<i className="fa-solid fa-thumbs-up text-white font-3xl" />}
                text="Approval"
            />
        }
    >
        {steps.map((stepType, i) => (
            <ControlledStep key={i} type={stepType} {...props} />
        ))}
    </SimpleCard>
);

export default ApprovalStage;
