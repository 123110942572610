import classNames from 'classnames';
import moment from 'moment';
import { formatNaturalMonthDay } from 'platform/common/utils/date.util';
import { Task } from '../task.types';

const dueDateClass = ({ status, dueDate }: Task) => {
    if (status !== 'ACTIVE') return 'text-muted';
    const today = moment().startOf('day');
    const remainingDays = moment(dueDate).diff(today, 'days');
    if (remainingDays <= 0) return 'text-danger';
    if (remainingDays <= 3) return 'text-warning';
    return 'text-muted';
};

interface Props {
    className?: string;
    task: Task;
}

const KanbanDateTag = ({ className, task }: Props) =>
    task.startDate || task.dueDate ? (
        <div className={classNames('d-flex', className)}>
            {task.startDate && (
                <div className="text-nowrap text-muted" title="Start date">
                    <i className="far fa-calendar me-2" />
                    {formatNaturalMonthDay(task.startDate)}
                </div>
            )}
            <div className="mx-1 text-muted">&mdash;</div>
            {task.dueDate && (
                <div className={classNames('text-nowrap', dueDateClass(task))} title="Due date">
                    <i className="far fa-calendar me-2" />
                    {formatNaturalMonthDay(task.dueDate)}
                </div>
            )}
        </div>
    ) : null;

export default KanbanDateTag;
