import { ReactNode, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import chatBotIcon from 'platform/assets/icons/CHAT_BOT.svg';
import Avatar from 'platform/common/components/Avatar/Avatar';
import Spinner from 'platform/common/components/Spinner/Spinner';
import { publicImageUrl } from 'platform/common/utils/environment.util';
import { useChatMessageContext } from '../ChatProviders/ChatMessageProvider';
import { useChatPromptContext } from '../ChatProviders/ChatPromptProvider';
import { ChatMessage as ChatMessageType, ContextPrompt } from '../chat.types';
import ChatAssistantMessage from './ChatAssistantMessage/ChatAssistantMessage';
import ChatMessageActions from './ChatMessageActions/ChatMessageActions';
import ChatUserMessage from './ChatUserMessage/ChatUserMessage';
import './ChatMessage.scss';

const cutLanguageSnippetsFromContent = (content: string, snippets: string[]) =>
    snippets.reduce((acc, curr) => acc.replace(curr, ''), content);

interface Props {
    index?: number;
    messageData: ChatMessageType;
    containerClassName?: string;
    className?: string;
    msgContainerClassName?: string;
    iconUrl?: string;
    titleMessageClassName?: string;
    contextPrompts: ContextPrompt[];
    children?: ReactNode;
}

const ChatMessage = ({
    index,
    messageData,
    containerClassName,
    className,
    msgContainerClassName = 'flex-grow-1 mb-0 d-flex flex-column minWidth ',
    iconUrl,
    titleMessageClassName,
    contextPrompts,
    children,
}: Props) => {
    const { role, prompt, fileName, error, contentParts } = messageData;
    const content = contentParts.length > 0;
    const msgEndRef = useRef<HTMLDivElement | null>(null);
    const { messages } = useChatMessageContext();
    const { systemPrompts } = useChatPromptContext();
    const accountName = useSelector(authSelectors.accountName);
    const profile = useSelector(authSelectors.ready.profile);
    const impersonating = useSelector(authSelectors.impersonating);
    const assistant = role === 'assistant';
    const isLast = index === messages.length - 1;
    const isLatestAssistantMessage = assistant && messages && isLast;
    const languageSnippets =
        systemPrompts
            ?.filter((p) => p.key === 'language_de' || p.key === 'language_en')
            .map((p) => p.prompt) ?? [];

    useEffect(() => {
        if (msgEndRef.current) {
            // scrollIntroView not working properly in Chrome without setTimeout
            // https://github.com/facebook/react/issues/23396
            setTimeout(() => {
                msgEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }, [msgEndRef, contentParts]);
    return (
        <>
            <div
                className={classNames(
                    'ChatMessage d-flex text-break',
                    containerClassName,
                    assistant ? 'rightOffset' : 'leftOffset'
                )}
            >
                <div className="w-100">
                    <div className="d-flex mb-2">
                        {assistant ? (
                            <div className="ChatMessage-assistantIcon">
                                <img
                                    width={26}
                                    height={26}
                                    alt="Chat Bot"
                                    src={publicImageUrl(iconUrl) || chatBotIcon}
                                />
                            </div>
                        ) : (
                            <Avatar
                                className="me-2"
                                name={accountName}
                                imageUrl={profile.imageUrl}
                                impersonating={impersonating}
                            />
                        )}
                        {assistant && !content && !error && <Spinner />}
                    </div>
                    <div className={classNames('rounded flex-grow-1', content && 'p-2 bg-white')}>
                        <div className={classNames('mb-2', msgContainerClassName)}>
                            {role === 'user' && (
                                <ChatUserMessage
                                    messageData={{
                                        role,
                                        prompt,
                                        fileName,
                                        contentParts: contentParts.map((cp) => ({
                                            ...cp,
                                            content: cutLanguageSnippetsFromContent(
                                                cp.content,
                                                languageSnippets
                                            ),
                                        })),
                                    }}
                                    className={className}
                                    titleMessageClassName={titleMessageClassName}
                                />
                            )}
                            {assistant && (
                                <div
                                    className={classNames(
                                        'ChatMessage-assistantMessage minWidth',
                                        !!content && 'loaded'
                                    )}
                                >
                                    <ChatAssistantMessage content={contentParts[0]?.content} error={error} />
                                </div>
                            )}
                        </div>
                        {children}
                        {content && (
                            <ChatMessageActions
                                isLatestAssistantMessage={isLatestAssistantMessage}
                                messageData={messageData}
                                contextPrompts={contextPrompts}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isLast && <div ref={msgEndRef} />}
        </>
    );
};

export default ChatMessage;
