import { useParams } from 'react-router-dom';
import { fetchAdvertiser, moveAdvertiser } from 'platform/advertisers/services/advertiser.service';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import AdvertiserMoveForm from './AdvertiserMoveForm';

interface Props {
    redirectTo: string;
    afterSubmit?: () => void;
}

const AdvertiserMoveFormContainer = ({ redirectTo, afterSubmit }: Props) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    return (
        <FormContainer
            sidePanel
            helpKey="advertiser_move_form"
            onOpen={async () => fetchAdvertiser(id)}
            onSubmit={({ parentId }) => moveAdvertiser(id, parentId)}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
        >
            {(props) => <AdvertiserMoveForm {...props} />}
        </FormContainer>
    );
};

export default AdvertiserMoveFormContainer;
