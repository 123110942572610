import { ModalProps } from 'reactstrap';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import IconButton from 'platform/common/components/IconButton/IconButton';

interface Props {
    title: string;
    className?: string;
    onCopy: () => void;
    onClick?: () => void;
    modalProps?: ModalProps;
}

const CopyButton = ({ title, className, modalProps, onCopy, onClick }: Props) => {
    const showConfirmationModal = useConfirmationModal(modalProps);

    const handleCopy = () => {
        onClick?.();
        showConfirmationModal(onCopy, { title });
    };

    return (
        <IconButton title={title} className={className} onClick={handleCopy}>
            <i className="fal fa-copy" />
        </IconButton>
    );
};

export default CopyButton;
