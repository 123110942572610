import { ChangeEvent, ChangeEventHandler } from 'react';
import { useField } from 'formik';
import FieldError from 'platform/formik/FieldError/FieldError';
import Switch from 'platform/formik/Switch/Switch';

type Props = {
    name: string;
    className?: string;
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    id?: string;
};

const FormSwitch = (props: Props) => {
    const { name, onChange, ...rest } = props;
    const [field, , helpers] = useField(name);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(e.target.checked);

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <>
            <Switch id={props.id} name={name} onChange={handleChange} value={field.value} {...rest} />
            <FieldError name={name} />
        </>
    );
};

export default FormSwitch;
