export enum PartOfSpeech {
    ADV = 'ADV',
    ADJX = 'ADJX',
    VVINF = 'VVINF',
    NN = 'NN',
}

export const PART_OF_SPEECH_LABELS = {
    [PartOfSpeech.ADV]: 'ADVERB',
    [PartOfSpeech.ADJX]: 'ADJECTIVE',
    [PartOfSpeech.VVINF]: 'VERB',
    [PartOfSpeech.NN]: 'NOUN',
};

// Temporary mapping until backend is corrected
export type LanguageCode = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'gb' | 'jp';

export const LANGUAGE_CODES: Record<LanguageCode, string> = {
    de: 'German',
    gb: 'English',
    es: 'Spanish',
    fr: 'French',
    it: 'Italian',
    jp: 'Japanese',
    en: 'English',
    ja: 'Japanese',
};
