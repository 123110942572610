import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { useChatAgentContext } from '../ChatProviders/ChatAgentProvider';
import { ChatAgent } from '../chat.types';
import ChatAgentIcon from './ChatAgentIcon';
import './ChatAgentBtn.scss';

type Size = 'small' | 'medium';

interface Props {
    agent?: ChatAgent;
    onAgentSelect?: () => void;
    disabled?: boolean;
    size?: Size;
    className?: string;
    icon?: string;
    placeholderName?: string;
    placeholderIcon?: string;
}

const ChatAgentBtn = ({
    agent,
    disabled = false,
    size = 'small',
    className,
    onAgentSelect,
    placeholderIcon,
    placeholderName,
}: Props) => {
    const { setSelectedAgent } = useChatAgentContext();

    return (
        <div className={classNames(`ChatAgentBtn ${size}`, className)}>
            <IconButton
                className={size}
                disabled={disabled}
                onClick={() => {
                    if (agent?.id) setSelectedAgent(agent);
                    onAgentSelect?.();
                }}
            >
                <ChatAgentIcon
                    iconUrl={agent?.iconUrl}
                    className={`ChatAgentBtn-icon-${size}`}
                    placeholderIcon={placeholderIcon}
                />
            </IconButton>
            <div className={`ChatAgentBtn-text ${size}`}>{agent?.name || placeholderName}</div>
        </div>
    );
};

export default ChatAgentBtn;
