import { useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash-es';
import AnalyticsReportFormRoutes from 'platform/analyticsReports/ReportManagement/AnalyticsReportFormRoutes';
import { useRefetchAnalyticsReports } from 'platform/analyticsReports/ReportManagement/useRefetchAnalyticsReports';
import ReportsTable from 'platform/analyticsReports/ReportsTable';
import { fetchAnalyticsReports } from 'platform/analyticsReports/analyticsReport.service';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { useReportTableColumns } from 'platform/analyticsReports/useReportTableColumns';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { fetchUsers } from 'platform/userManagement/services/userManagement.service';
import { User } from 'platform/userManagement/types/user.type';

const getUserOptions = (users: User[]) =>
    orderBy(
        users.map((u) => ({ value: u.id, label: u.name })),
        (u) => u.label
    );

const getUserName = (users: User[], userId?: number) => users.find((u) => u.id === userId)?.name;

const USER_COLUMN_INDEX = 2;

interface Props {
    status: ActiveOrArchived[];
    canEdit: boolean;
    onReportChange: (report: AnalyticsReport) => Promise<void>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const PersonalReports = ({ status, canEdit, onReportChange, onStatusChange }: Props) => {
    const profile = useSelector(authSelectors.ready.profile);
    const [userIds, setUserIds] = useState([profile.id]);
    const columns = useReportTableColumns({ canEdit, onReportChange });

    const [{ data: users, loading: usersLoading }] = usePromise(
        [],
        () => fetchUsers({ states: ['ACTIVE'] }),
        []
    );

    const [{ data: personalReports, loading }, refetchReports] = usePromise<AnalyticsReport[]>(
        [],
        () =>
            userIds.length
                ? fetchAnalyticsReports({
                      status,
                      ownerUserId: userIds,
                      section: ['PERSONAL'],
                  })
                : Promise.resolve([]),
        [status, userIds]
    );

    const { refetch } = useRefetchAnalyticsReports(refetchReports);

    const handleReportChange = async (report: AnalyticsReport) => {
        await onReportChange(report);
        refetch();
    };

    const userColumn: TableColumn<AnalyticsReport> = {
        Header: 'User',
        accessor: (report) => getUserName(users, report.ownerUserId),
        width: 200,
        Cell: CellWithEllipsis,
    };

    return (
        <BodyContainer helpKey="personal_reports">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
                <SelectWithAddon
                    name="User"
                    value={userIds}
                    selectStyle={{ minWidth: 200 }}
                    options={getUserOptions(users)}
                    isLoading={usersLoading}
                    isMulti
                    onChange={setUserIds}
                />
            </ListFilters>
            <ReportsTable
                columns={[
                    ...columns.slice(0, USER_COLUMN_INDEX),
                    userColumn,
                    ...columns.slice(USER_COLUMN_INDEX),
                ]}
                reports={personalReports}
                loading={usersLoading || loading}
                canEdit={canEdit}
                onReportChange={handleReportChange}
            />
            <AnalyticsReportFormRoutes canEdit={canEdit} afterSubmit={refetch} />
        </BodyContainer>
    );
};

export default PersonalReports;
