import { useParams } from 'react-router-dom';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import LabelOverridesForm, { LabelOverrideModel, LabelOverrideProps } from './LabelOverridesForm';
import { fetchLabelOverride, updateLabelOverride } from './labelOverrides.service';

const LabelOverridesEdit = ({
    canEdit,
    redirectTo,
    afterSubmit,
    metrics,
    dimensions,
    keyTyMetricName,
    keyToDimensionName,
}: FormContainerProps & LabelOverrideProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const onOpen = async (): Promise<LabelOverrideModel> => {
        const labelOverride = await fetchLabelOverride(id);
        return {
            ...labelOverride,
            metrics,
            dimensions,
            keyTyMetricName,
            keyToDimensionName,
        };
    };

    const onSubmit = (model: LabelOverrideModel) => updateLabelOverride(id, model);

    return (
        <FormContainer
            helpKey="label_overrides_form"
            onSubmit={onSubmit}
            onOpen={onOpen}
            onSubmitFinish={afterSubmit}
            canEdit={canEdit}
            redirectTo={redirectTo}
            isEdit
            sidePanel
        >
            {(props) => <LabelOverridesForm {...props} />}
        </FormContainer>
    );
};

export default LabelOverridesEdit;
