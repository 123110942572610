import { SelectItem } from 'platform/common/common.types';
import {
    Adserver,
    ExternalSystem,
    IntegrationStatus,
} from 'platform/common/constants/externalIntegration.constant';
import { Status } from 'platform/common/constants/status.constant';
import { ConceptType } from 'platform/creatives/constants/conceptTypes';
import { CreativeType } from 'platform/creatives/constants/creativeTypes';
import {
    ImportedCreative,
    ImportedCreativeModel,
} from 'platform/creatives/mappers/creative/commonCreative.mapper';
import {
    GcmHostedCreative,
    GcmHostedCreativeModel,
} from 'platform/creatives/mappers/creative/gcmHostedCreative.mapper';
import {
    GcmTrackerCreative,
    GcmTrackerCreativeModel,
} from 'platform/creatives/mappers/creative/gcmTrackerCreative.mapper';
import {
    GcmVideoCreative,
    GcmVideoCreativeModel,
} from 'platform/creatives/mappers/creative/gcmVideoCreative.mapper';

export enum DeliveryStatus {
    RUNNING = 'RUNNING',
    PAUSED = 'PAUSED',
    REMOVED = 'REMOVED',
}

export interface CreativeIntegration {
    externalId: string;
    externalSystem: ExternalSystem;
    integrationStatus: IntegrationStatus;
    integrationMessage: string;
    previewUrl?: string;
}

export type Creative = ImportedCreative | GcmTrackerCreative | GcmHostedCreative | GcmVideoCreative;

export interface Concept<T = Creative> {
    id?: number;
    name: string;
    type: ConceptType;
    clickThroughUrl?: string;
    advertiserId: number;
    externalAdvertiserId?: string;
    creatives: T[];
    campaignId?: number;
    state: Status;
    createdOn?: string;
    updatedOn?: string;
    updatedBy?: string;
    creativeCount?: number;
    creativeSyncSummaryStatus?: IntegrationStatus;
    usedCreativeTypes?: CreativeType[];
    productLabel?: string;
    flightLabel?: string;
    adserver?: Adserver;
}

export type CreativeModel = ImportedCreativeModel | GcmCreativeModel;

export type GcmCreativeModel = GcmTrackerCreativeModel | GcmHostedCreativeModel | GcmVideoCreativeModel;

export interface ConceptModel {
    id?: number;
    name: string;
    type: ConceptType;
    clickThroughUrl?: string;
    advertiserId: number;
    adserver?: Adserver;
    externalAdvertiserId?: string;
    campaignId?: number;
    creatives: CreativeModel[];
    productLabel?: SelectItem<string>;
    flightLabel?: SelectItem<string>;
    state: Status;
    createdOn?: string;
    updatedOn?: string;
    updatedBy?: string;
    seatName?: string;
    isDemoMode?: boolean;
}

export interface CreativePatch {
    id?: number;
    name?: string;
    clickThroughUrl?: string;
}

export interface GcmHostedCreativePreview {
    previewUrl: string;
    computedClickThroughUrl?: string;
    width: number;
    height: number;
}
