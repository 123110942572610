import { AlgorithmLanguage } from 'platform/algorithms/algorithmManager.constant';
import {
    Algorithm,
    AlgorithmFormModel,
    AlgorithmTemplate,
    AlgorithmTemplateFormModel,
} from 'platform/algorithms/algorithms.types';
import { Optional } from 'platform/common/common.types';

const stringifyJson = (json: Record<string, string>) => JSON.stringify(json, null, 4);

const parseJStoJSON = (settings: string): Record<string, string> | undefined => {
    try {
        // eslint-disable-next-line no-new-func
        return new Function(settings)();
    } catch (e) {
        return undefined;
    }
};

const algorithmSettingsToApi = (
    settings: string,
    googleAdScript: boolean,
    settingsLanguage: AlgorithmLanguage
): { settings: string; settingsLanguage: AlgorithmLanguage } => {
    if (googleAdScript && settingsLanguage === 'JSON' && settings) {
        const settingsJson = JSON.parse(settings);
        // Google Ad script settings are stored as javascript
        // we need to keep them as javascript
        // in order to rewrite to JSON we would need to update every script on Google manually
        return {
            settings: `return ${stringifyJson(settingsJson)}`,
            settingsLanguage: 'JAVASCRIPT',
        };
    }

    if (settingsLanguage === 'JSON' && settings) {
        const settingsJson = JSON.parse(settings);
        return {
            settings: stringifyJson(settingsJson),
            settingsLanguage,
        };
    }

    return {
        settings,
        settingsLanguage,
    };
};

const algorithmSettingsToForm = (
    googleAdScript: boolean,
    settingsLanguage: AlgorithmLanguage,
    settings: string
): { settings: string; settingsLanguage: AlgorithmLanguage } => {
    if (googleAdScript && settingsLanguage === 'JAVASCRIPT' && settings) {
        const settingsJson = parseJStoJSON(settings);
        return settingsJson
            ? {
                  settings: stringifyJson(settingsJson),
                  settingsLanguage: 'JSON',
              }
            : {
                  settings,
                  settingsLanguage,
              };
    }

    if (settingsLanguage === 'JSON' && settings) {
        return {
            settings: stringifyJson(JSON.parse(settings)),
            settingsLanguage,
        };
    }

    return {
        settings,
        settingsLanguage,
    };
};

const hasMultipleSettings = (settingsLanguage: AlgorithmLanguage, settings?: string) => {
    if (settingsLanguage !== 'JSON' || !settings) {
        return false;
    }
    const jsonSettings = JSON.parse(settings);

    return Array.isArray(jsonSettings);
};

export const toTemplatesApi = (props: AlgorithmTemplateFormModel): Optional<AlgorithmTemplate, 'id'> => {
    const { settings, settingsLanguage } = algorithmSettingsToApi(
        props.hasSettings && props.settings ? props.settings : '',
        false,
        props.display.settingsLanguage
    );

    return {
        ...props,
        externalRuntime: props.googleAdScript ? 'GAS' : undefined,
        code: props.hasCode ? props.code : '',
        settings,
        display: {
            ...props.display,
            settingsLanguage,
        },
    };
};

export const toTemplatesForm = (props: AlgorithmTemplate): AlgorithmTemplateFormModel => {
    const googleAdScript = props.externalRuntime === 'GAS';
    const { settings, settingsLanguage } = algorithmSettingsToForm(
        googleAdScript,
        props.display.settingsLanguage,
        props.settings
    );
    return {
        ...props,
        googleAdScript,
        hasCode: !!props.code,
        hasSettings: !!props.settings,
        hasMultipleSettings: hasMultipleSettings(settingsLanguage, settings),
        settings,
        display: {
            ...props.display,
            settingsLanguage,
        },
    };
};

export const toAlgorithmApi = (data: AlgorithmFormModel): Optional<Algorithm, 'id'> => {
    const { settings, settingsLanguage } = algorithmSettingsToApi(
        data.settings,
        data.externalRuntime === 'GAS',
        data.display.settingsLanguage
    );
    return {
        ...data,
        settings,
        display: {
            ...data.display,
            settingsLanguage,
        },
    };
};

export const toAlgorithmForm = (
    algorithm: Optional<Algorithm, 'id'>,
    template: AlgorithmTemplate
): AlgorithmFormModel => {
    const googleAdScript = template.externalRuntime === 'GAS';
    const { settings, settingsLanguage } = algorithmSettingsToForm(
        googleAdScript,
        algorithm.display.settingsLanguage,
        algorithm.settings
    );

    return {
        ...algorithm,
        settings,
        display: {
            ...algorithm.display,
            settingsLanguage,
        },
        adminNotes: template.adminNotes,
        externallyAvailable: template.externallyAvailable,
        externalRuntime: template.externalRuntime,
        algorithmTemplateName: template.name,
        templateCode: template.code,
        templateSettings: template.settings,
        templateVersion: template.version,
        templateCodeLanguage: template.display.codeLanguage,
        templateSettingsLanguage: template.display.settingsLanguage || algorithm.display.settingsLanguage,
        hasMultipleSettings: hasMultipleSettings(settingsLanguage, settings),
    };
};
