import moment, { Moment } from 'moment';
import DateButton from '../DateButton/DateButton';

const NUMBER_OF_OPTIONS = 50;

const generateYearOptionsFromPresent = (fromDate: Date, toDate: Date): Date[] => {
    const diffInYears = moment(toDate).diff(moment(fromDate), 'years');
    const yearOptions: Date[] = [];

    // eslint-disable-next-line no-plusplus
    for (let year = 0; year <= diffInYears; year++) {
        yearOptions.push(moment(fromDate).add(year, 'years').toDate());
    }

    return yearOptions;
};

const isSameYear = (date1: Date | undefined | null, date2: Date | undefined | null) => {
    if (!date1 || !date2) {
        return false;
    }

    return moment(date1).isSame(date2, 'year');
};

interface Props {
    from: Date | undefined | null;
    to: Date | undefined | null;
    secondaryFrom?: Date | null;
    secondaryTo?: Date | null;
    fromDate?: Moment;
    toDate?: Moment;
    width?: number;
    onDateClick: (date: Date) => void;
}

const YearPicker = ({
    from,
    to,
    secondaryFrom,
    secondaryTo,
    fromDate = moment().subtract(NUMBER_OF_OPTIONS, 'years'),
    toDate = moment().add(NUMBER_OF_OPTIONS, 'years'),
    width = 260,
    onDateClick,
}: Props) => {
    const yearOptions = generateYearOptionsFromPresent(fromDate.toDate(), toDate.toDate());

    return (
        <div style={{ width, maxHeight: 350, overflowY: 'auto' }} className="d-flex px-3 pt-3">
            <div className="d-flex flex-wrap">
                {yearOptions.map((date, i) => (
                    <DateButton
                        key={i}
                        secondarySelected={isSameYear(secondaryFrom, date) || isSameYear(secondaryTo, date)}
                        secondaryBetweenSelected={
                            secondaryFrom && secondaryTo
                                ? moment(date).isBetween(secondaryFrom, secondaryTo, 'year')
                                : undefined
                        }
                        selected={isSameYear(from, date) || isSameYear(to, date)}
                        betweenSelected={from && to ? moment(date).isBetween(from, to, 'year') : undefined}
                        onClick={() => onDateClick(date)}
                    >
                        {moment(date).format('YYYY')}
                    </DateButton>
                ))}
            </div>
        </div>
    );
};

export default YearPicker;
