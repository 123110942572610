import classNames from 'classnames';
import './Placeholder.scss';

interface Props {
    width?: string;
    height?: string;
    minHeight?: string;
    maxWidth?: string;
    className?: string;
    borderRadius?: string;
}

const Placeholder = ({ width = '100%', height, minHeight, maxWidth, className, borderRadius }: Props) => (
    <div
        style={{ width, height, minHeight, maxWidth }}
        className={classNames('Placeholder-container d-flex flex-grow-1', className)}
    >
        <div className="Placeholder" style={{ width: '100%', borderRadius }} />
    </div>
);

export default Placeholder;
