import classNames from 'classnames';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import './ChatPromptCard.scss';

interface Props {
    name: string;
    descriptionHtml?: string;
    additionalIcons?: React.ReactNode;
}

const ChatPromptCard = ({ name, descriptionHtml, additionalIcons }: Props) => {
    const descriptionEl = <RawHtml className="ChatPromptCard-description">{descriptionHtml}</RawHtml>;

    return (
        <div className="ChatPromptCard">
            <div className="ChatPromptCard-highlight" />
            <div className="text-start me-2 text-truncate">
                <strong className="ChatPromptCard-title">{name}</strong>
                {descriptionHtml && <Tooltip renderTooltip={() => descriptionEl}>{descriptionEl}</Tooltip>}
            </div>
            {additionalIcons}
            <i
                className={classNames(
                    'far fa-paper-plane-top align-self-center text-light-slate-gray fs-5',
                    !additionalIcons && 'ms-auto'
                )}
            />
        </div>
    );
};

export default ChatPromptCard;
