import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import { CampaignModel, Monetization } from 'platform/campaign/campaign/campaign.types';
import CampaignAdvancedSettings from 'platform/campaign/campaign/components/CampaignForm/CampaignAdvancedSettings';
import CampaignClassificationSettings from 'platform/campaign/campaign/components/CampaignForm/CampaignClassificationSettings';
import CampaignGeneralInfo from 'platform/campaign/campaign/components/CampaignForm/CampaignGeneralInfo';
import CampaignMediaplanSettings from 'platform/campaign/campaign/components/CampaignForm/CampaignMediaplanSettings';
import CostSources from 'platform/campaign/campaign/components/CampaignForm/CostSources';
import { monetizationToForm } from 'platform/campaign/campaign/mappers/campaign.mapper';
import { deleteCampaign } from 'platform/campaign/campaign/services/campaign.service';
import { fetchAdvertiserCostSourceDefaults } from 'platform/campaign/globalPreset/services/globalCostSourcePreset.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import FormNavigation, { FormStep } from 'platform/common/components/FormNavigation/FormNavigation';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import './CampaignFormSettings.scss';

type GlobalCostSourceForm = {
    channel?: string;
    productLabel?: string;
    monetization: Monetization;
};

/** Cost re-calculations will not be performed for data older than this year */
const COST_CALCULATION_CUTOFF_YEAR = 2023;

const CAMPAIGN_FORM_STEPS: FormStep[] = [
    {
        id: 'generalInfo',
        label: 'General info',
    },
    {
        id: 'classificationSettings',
        label: 'Classification',
    },
    {
        id: 'mediaplanSettings',
        label: 'Media insertion',
    },
    {
        id: 'costSources',
        label: 'Cost sources',
    },
];

const getMonetization = (
    campaign: CampaignModel,
    globalCostSources: GlobalCostSourceForm[]
): Monetization | undefined => {
    const result: (GlobalCostSourceForm | undefined)[] = [];
    const costSource = globalCostSources.find((c) => isEmpty(c.channel) && isEmpty(c.productLabel));
    const matchingLabelCostSource = globalCostSources.find(
        (c) =>
            isEmpty(c.channel) &&
            !isEmpty(c.productLabel) &&
            campaign.classification.productLabel?.label === c.productLabel
    );
    const matchingChannelCostSource = globalCostSources.find(
        (c) => !isEmpty(c.channel) && isEmpty(c.productLabel) && campaign.classification.channel === c.channel
    );
    const matchingLabelAndChannelCostSource = globalCostSources.find(
        (c) =>
            !isEmpty(c.channel) &&
            !isEmpty(c.productLabel) &&
            campaign.classification.productLabel?.label === c.productLabel &&
            campaign.classification.channel === c.channel
    );

    if (isEmpty(campaign.classification.channel) && isEmpty(campaign.classification.productLabel)) {
        result.push(costSource);
    } else if (isEmpty(campaign.classification.channel) && !isEmpty(campaign.classification.productLabel)) {
        result.concat([matchingLabelCostSource, costSource]);
    } else if (!isEmpty(campaign.classification.channel) && isEmpty(campaign.classification.productLabel)) {
        result.concat([matchingChannelCostSource, costSource]);
    } else if (!isEmpty(campaign.classification.channel) && !isEmpty(campaign.classification.productLabel)) {
        result.concat([
            matchingLabelAndChannelCostSource,
            matchingLabelCostSource,
            matchingChannelCostSource,
            costSource,
        ]);
    }

    const globalSource = result.find((c) => !isEmpty(c));
    return globalSource?.monetization;
};

const CampaignForm = ({
    labels,
    isEdit,
    formikProps: { initialValues, values, submitForm, dirty, setFieldValue },
    onCancel,
    missingCMIntegration,
}: FormProps<CampaignModel> & { missingCMIntegration: boolean }) => {
    const isSuperuser = useFeature('SUPERUSER');

    usePromise(
        undefined,
        async () => {
            if (isEdit) {
                return;
            }

            const [advertiser, costSourceDefaults] = await Promise.all([
                fetchAdvertiser(values.advertiserId),
                fetchAdvertiserCostSourceDefaults({
                    advertiserId: values.advertiserId,
                }),
            ]);

            if (advertiser) {
                setFieldValue('adserver', advertiser.activeAdserver);
            }

            const formCostSourceDefaults: GlobalCostSourceForm[] = costSourceDefaults.map(
                ({ channel, productLabel, monetization }) => ({
                    channel,
                    productLabel,
                    monetization: monetizationToForm(monetization),
                })
            );
            const monetization = getMonetization(values, formCostSourceDefaults);

            if (monetization) {
                setFieldValue('monetization', monetization);
            }
        },
        [values.advertiserId, isEdit]
    );

    const startYear = moment(values.flightDates.from).year();
    const endYear = moment(values.flightDates.to).year();

    const costSourceEditWarning = () => {
        if (startYear < COST_CALCULATION_CUTOFF_YEAR) {
            return endYear < COST_CALCULATION_CUTOFF_YEAR
                ? `Cost Sources cannot be modified, since this campaign is in an archived period before ${COST_CALCULATION_CUTOFF_YEAR}.`
                : `This campaign is partly in an archived period, so changes to Cost Sources will only affect data from the beginning of ${COST_CALCULATION_CUTOFF_YEAR}.`;
        }
        return undefined;
    };

    const handleDelete = async () => {
        if (initialValues.id) {
            await deleteCampaign(initialValues.id);
        }
        onCancel();
    };

    return (
        <FormNavigation entryId={initialValues.id} steps={CAMPAIGN_FORM_STEPS} isExpandable>
            {() => (
                <CardForm
                    title={`${labels.prefix} Campaign`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    submitLabel={labels.submit}
                    onCancel={onCancel}
                    disabled={!dirty}
                    onSubmit={submitForm}
                    cardBodyClassname={'CampaignFormComponent'}
                >
                    <div id="generalInfo" className="CampaignForm--GeneralInfo">
                        <CampaignGeneralInfo
                            campaign={values}
                            editMode={isEdit}
                            creativeConceptId={initialValues.creativeConceptId}
                        />
                    </div>

                    <div id="classificationSettings">
                        <CampaignClassificationSettings
                            campaign={values}
                            setFieldValue={setFieldValue}
                            missingCMIntegration={missingCMIntegration}
                        />
                    </div>

                    <div id="mediaplanSettings" className="mediaplanSettings">
                        <CampaignMediaplanSettings campaign={values} />
                    </div>
                    <div id="costSources">
                        {!!values.advertiserId && values.monetization && (
                            <CostSources
                                disabled={endYear < COST_CALCULATION_CUTOFF_YEAR}
                                warning={costSourceEditWarning()}
                            />
                        )}
                    </div>
                    {isSuperuser && !!initialValues.id && (
                        <div id="advancedSettings">
                            <CampaignAdvancedSettings campaign={values} onDelete={handleDelete} />
                        </div>
                    )}
                </CardForm>
            )}
        </FormNavigation>
    );
};

export default CampaignForm;
