import { useEffect, useState } from 'react';
import classNames from 'classnames';
import './WordsFadeInAnimation.scss';

interface Props {
    words: string[];
    delay?: number;
}

const WordsFadeInAnimation = ({ words, delay = 366 }: Props) => {
    const [cutoffIndex, setCutoffIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => setCutoffIndex((prev) => prev + 1), delay);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {words.map((word, index) => (
                <span
                    key={word}
                    className={classNames('WordsFadeInAnimation', {
                        'WordsFadeInAnimation-initial': index >= cutoffIndex,
                        'WordsFadeInAnimation-finish': index < cutoffIndex,
                    })}
                >
                    {word}
                </span>
            ))}
        </>
    );
};

export default WordsFadeInAnimation;
