import { isNil } from 'lodash-es';
import { isDefined } from 'platform/common/common.types';
import { Accessor } from 'platform/common/components/FormattedTable/FormattedTable';

const optionalChildren = <T extends { [key: string]: any }>(node: T, childrenKey: string): T[] => {
    const children = node[childrenKey];
    return children && children.length ? children : [];
};

const contains = (node: { [key: string]: any }, accessors: Accessor[], fragment = '') =>
    accessors.some((accessor) => {
        if (!accessor) return false;
        const value = typeof accessor === 'function' ? accessor(node) : node[accessor];
        return isNil(value) ? false : String(value).toLowerCase().includes(fragment.toLowerCase());
    });

const filterNode = <T extends { [key: string]: any }>(
    node: T,
    childrenKey: string,
    predicate: (node: T) => boolean
): T | undefined => {
    let matches = predicate(node);
    let children = optionalChildren(node, childrenKey);

    if (!matches) {
        const filteredChildren = optionalChildren(node, childrenKey)
            .map((childNode) => filterNode(childNode, childrenKey, predicate))
            .filter(isDefined);
        matches = !!filteredChildren.length;
        children = filteredChildren;
    }

    return matches
        ? {
              ...node,
              [childrenKey]: children,
          }
        : undefined;
};

const filterHierarchy = <T extends { [key: string]: any }>(
    hierarchicalList: T[],
    childrenKey: string,
    predicate: (node: T) => boolean
): T[] => {
    if (!hierarchicalList) {
        return hierarchicalList;
    }

    return hierarchicalList.map((node) => filterNode(node, childrenKey, predicate)).filter(isDefined);
};

export const filterTreeByQuery = <T extends Object>(
    hierarchicalList: T[],
    query: string,
    childrenKey: string,
    accessors: Accessor[]
) => {
    const predicate = (node: T) => contains(node, accessors, query);
    return filterHierarchy(hierarchicalList, childrenKey, predicate);
};

export const filterBySearchQuery = <T extends { [key: string]: any }>(
    data: T[],
    accessors: Accessor[],
    searchQuery: string
): T[] => {
    if (!searchQuery || !accessors.length) return data;
    return data.filter((item) => contains(item, accessors, searchQuery));
};
