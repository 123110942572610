import { isFinite } from 'lodash-es';
import moment from 'moment';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { OlapFilter, OlapFilterOperator } from 'platform/analytics/analytics.types';
import { SEARCH_TERM_STRATEGY_LABELS } from 'platform/campaign/campaign/campaign.types';
import { Sort } from 'platform/common/common.types';
import { CommonClassifiers } from 'platform/common/ducks/commonClassifiers.duck';
import { formatDate } from 'platform/common/utils/date.util';
import { ChatOlapRequest, Filter } from './chat.olap.types';

const COST_METRIC = 'buyside_cost';

export const mapChatOlapRequestToReportSettings = (
    olapRequest: ChatOlapRequest,
    commonClassifiers: CommonClassifiers
): ReportSettings => {
    const { dimensions, metrics, filters, orderBy, limit } = olapRequest;
    const fixedDimensions = fixColumnList(dimensions);
    const fixedMetrics = fixColumnList(metrics);

    const filtersWithDate = ensureDateFilter(filters, fixedDimensions);

    const baseRequest = {
        dimensions: fixedDimensions,
        metrics: fixedMetrics,
        olapFilters: filtersWithDate
            .map((f) => handleSpecialKeys(f, commonClassifiers))
            .map(fixValueType)
            .filter((f) => f.columnId !== 'advertiser_id'),
        sort: ensureSorting(orderBy, fixedDimensions, fixedMetrics),
    };

    if (limit) {
        return { ...baseRequest, paging: { pageNumber: 0, pageSize: limit } };
    }
    return baseRequest;
};

const ensureSorting = (
    sort: Record<string, 'ASC' | 'DESC'>,
    dimensions: string[],
    metrics: string[]
): Sort[] => {
    const _sort = sort || {};
    const entries = Object.entries(_sort);
    if (entries.length === 0) {
        if (metrics && metrics.includes(COST_METRIC)) {
            return [{ orderBy: COST_METRIC, direction: 'DESC' }];
        }
        if (dimensions && dimensions.length > 0) {
            return [{ orderBy: dimensions[0], direction: 'ASC' }];
        }
    }
    return entries.reduce((acc, [key, direction]) => [...acc, { orderBy: key, direction }], []);
};

// We only need to specify keys that have numeric values that need to be treated as strings
const STRING_VALUE_KEYS = ['year'];
const fixValueType = (filter: Filter): OlapFilter => {
    const numberValues = filter.values.map(Number).filter(isFinite);
    const onlyNumbers = numberValues.length === filter.values.length;
    const stringValueKey = STRING_VALUE_KEYS.includes(filter.key);
    if (!onlyNumbers || stringValueKey) {
        return {
            columnId: filter.key,
            compareValue: filter.values,
            compareOperator: ['IN', 'EQUALS'].includes(filter.operator)
                ? (`CASE_AGNOSTIC_${filter.operator}` as OlapFilterOperator)
                : filter.operator,
        };
    }
    return {
        columnId: filter.key,
        compareValue: numberValues,
        compareOperator: filter.operator,
    };
};

const SEARCH_TERM_STRATEGY_KEYS: { [p: string]: string } = Object.freeze(
    Object.entries(SEARCH_TERM_STRATEGY_LABELS).reduce<Record<string, string>>((acc, cur) => {
        acc[cur[1].toLowerCase()] = cur[0];
        return acc;
    }, {})
);

const handleSpecialKeys = (filter: Filter, { channels }: CommonClassifiers): Filter => {
    if (filter.key === 'channel') {
        const newValues = filter.values.map(
            (value) => channels.find((c) => c.name.toLowerCase() === value.toLowerCase())?.code ?? value
        );
        return { ...filter, values: newValues };
    }
    if (filter.key === 'search_term_strategy') {
        const newValues = filter.values.map(
            (value) => SEARCH_TERM_STRATEGY_KEYS[value.toLowerCase()] || value
        );
        return { ...filter, values: newValues };
    }
    return filter;
};

export const DATE_COLUMNS = ['date', 'month', 'year', 'quarter', 'week'];

const ensureDateFilter = (filters: Filter[], dimensions: string[]): Filter[] => {
    const _filters: Filter[] = filters || [];
    const dateFilters = _filters.filter((filter) => DATE_COLUMNS.includes(filter.key));
    const hasDateFilter = dateFilters.length > 0;
    if (!hasDateFilter) {
        const today = formatDate(moment().subtract(1, 'day'))!;
        const _90DaysAgo = formatDate(moment().subtract(90, 'days'))!;
        const defaultDateFilter: Filter = {
            key: 'date',
            operator: 'BETWEEN',
            values: [_90DaysAgo, today],
        };
        return [..._filters, defaultDateFilter];
    }
    // It can happen when we compare 2 periods. In that case we'll have a date dimension to group by
    // and multiple date filters
    if (dateFilters.length > 1) {
        const _dimensions = dimensions || [];
        const betweenFilters = dateFilters.filter((filter) => filter.operator === 'BETWEEN');
        const allColumnsSame = dateFilters.every((filter) => filter.key === dateFilters[0].key);
        const onlyBetweenOps = betweenFilters.length === dateFilters.length;
        const hasDateDimension = _dimensions.some((d) => DATE_COLUMNS.includes(d));

        if (onlyBetweenOps && allColumnsSame && hasDateDimension) {
            const nonDateFilters = _filters.filter((filter) => !DATE_COLUMNS.includes(filter.key));
            const dateValues = dateFilters.flatMap((filter) => filter.values).sort();
            const combinedDateFilter: Filter = {
                key: dateFilters[0].key,
                operator: 'BETWEEN',
                values: [dateValues[0], dateValues[dateValues.length - 1]],
            };

            return [...nonDateFilters, combinedDateFilter];
        }
    }
    return _filters;
};

const fixColumnList = (columns: string[]) => {
    if (!columns) return [];

    // @ts-ignore
    return columns.map((c) => c.key || c);
};
