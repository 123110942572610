import { Fragment } from 'react';
import classNames from 'classnames';
import {
    FUNNEL_SETTINGS,
    FunnelSetting,
    FunnelTitle,
} from 'platform/segments/constants/segmentFunnel.constant';
import './SegmentFunnel.scss';

type Props = {
    activeSteps: FunnelTitle[];
};

const SegmentFunnel = ({ activeSteps }: Props) => {
    const getIconClasses = (funnel: FunnelSetting) => {
        const isActive = activeSteps.includes(funnel.title);
        return classNames('fa', `fa-${funnel.icon}`, 'fa-lg', 'SegmentFunnel-icon', {
            'SegmentFunnel-icon--active': isActive,
            'SegmentFunnel-icon--inactive': !isActive,
        });
    };

    const renderDivider = () => (
        <div className="SegmentFunnel-divider">
            <i className="fa fa-arrow-circle-right fa-lg SegmentFunnel-dividerIcon SegmentFunnel-icon--inactive" />
        </div>
    );

    const list = FUNNEL_SETTINGS.map((funnel, i) => (
        <Fragment key={funnel.title}>
            <div className="SegmentFunnel">
                <i className={getIconClasses(funnel)} />
                <div className="SegmentFunnel-title">{funnel.title}</div>
            </div>
            {i < FUNNEL_SETTINGS.length - 1 && renderDivider()}
        </Fragment>
    ));

    return <div className="d-flex flex-row justify-content-around align-items-stretch">{list}</div>;
};

export default SegmentFunnel;
