import axios from '../../../axios/axiosBff';
import { DefaultCostSourceType, GlobalCostSourceApiModel } from '../globalPreset.types';

export const fetchAdvertiserCostSources = (params: { advertiserId: number }) =>
    axios
        .get<GlobalCostSourceApiModel[]>('/campaign/api/advertiser-monetizations', {
            params,
        })
        .then((response) => response.data);

export const fetchAdvertiserCostSourceDefaults = (params: { advertiserId: number }) =>
    axios
        .get<GlobalCostSourceApiModel[]>('/campaign/api/advertiser-monetizations/defaults', {
            params,
        })
        .then((response) => response.data);

const createGlobalCostSource = (globalCostSource: Partial<GlobalCostSourceApiModel>) =>
    axios
        .post<GlobalCostSourceApiModel[]>('/campaign/api/advertiser-monetizations', globalCostSource)
        .then((response) => response.data);

const updateGlobalCostSource = (id: number, globalCostSource: GlobalCostSourceApiModel) =>
    axios
        .put<GlobalCostSourceApiModel[]>(`/campaign/api/advertiser-monetizations/${id}`, globalCostSource)
        .then((response) => response.data);

const fetchGlobalCostSource = (id: number) =>
    axios
        .get<GlobalCostSourceApiModel>(`/campaign/api/advertiser-monetizations/${id}`)
        .then((response) => response.data);

export const setDefaultCostSource = (monetizationId: number, advertiserId: number) =>
    axios
        .post<DefaultCostSourceType>(`/campaign/api/advertiser-monetizations/defaults`, {
            advertiserId,
            monetizationId,
        })
        .then((response) => response.data);

export default {
    createGlobalCostSource,
    updateGlobalCostSource,
    fetchAdvertiserCostSources,
    fetchGlobalCostSource,
};
