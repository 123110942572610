import classNames from 'classnames';
import './CardLabel.scss';

interface Props {
    className?: string;
    icon: string;
    children: React.ReactNode;
}

const CardLabel = ({ className, icon, children }: Props) => (
    <div className={classNames('CardLabel', className)}>
        <i className={classNames(icon, 'me-1')} />
        {children}
    </div>
);

export default CardLabel;
