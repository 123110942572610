import axiosBff from '../../axios/axiosBff';
import { IdResponse } from '../../common/common.types';
import { Concept, Creative, CreativePatch, GcmHostedCreativePreview } from '../types/creative.types';

export const fetchConcepts = (params: {
    advertiserId?: number;
    id?: number[];
    creativeId?: number[];
    type?: string[];
    state?: string[];
    query?: string;
    view?: 'full' | 'summary' | 'without-creatives';
    creativeCount?: boolean;
    usedCreativeTypes?: boolean;
}) => axiosBff.get<Concept[]>('/creative/api/v2/concepts', { params }).then((response) => response.data);

export const fetchConcept = (id: number | string) =>
    axiosBff.get<Concept>(`/creative/api/v2/concepts/${id}`).then((response) => response.data);

export const fetchCreatives = (params: { advertiserId?: number; conceptId?: number }) =>
    axiosBff.get<Creative[]>(`/creative/api/v2/creatives`, { params }).then((response) => response.data);

export const createConcept = (concept: Concept) =>
    axiosBff.post<void>('/creative/api/v2/concepts', concept).then((response) => response.data);

export const updateConcept = (concept: Concept) =>
    axiosBff.put<void>(`/creative/api/v2/concepts/${concept.id}`, concept).then((response) => response.data);

export const patchCreatives = (conceptId: number, patches: CreativePatch[]) =>
    axiosBff.patch<void>(`/creative/api/v2/concepts/${conceptId}/creatives`, patches);

export const resyncCreative = (creativeId: number) =>
    axiosBff.post<void>(`/creative/api/v2/creatives/${creativeId}/resync`).then((response) => response.data);

export const fetchGcmHostedCreativePreview = (creativeId: number) =>
    axiosBff
        .get<GcmHostedCreativePreview>(`/creative/api/v2/creatives/${creativeId}/preview`)
        .then((response) => response.data);

export const resyncConceptCreatives = (conceptId: number) =>
    axiosBff
        .post<void>(`/creative/api/v2/concepts/${conceptId}/creatives/resync`)
        .then((response) => response.data);

export const updateConceptState = (conceptId: number, state: string) =>
    axiosBff
        .put<void>(`/creative/api/v2/concepts/${conceptId}/state`, { state })
        .then((response) => response.data);

export const duplicateConcept = (conceptId: number) =>
    axiosBff
        .post<IdResponse>(`/creative/api/v2/concepts/${conceptId}/copy`)
        .then((response) => response.data);
