import classNames from 'classnames';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import creativeImportExport from 'platform/creatives/components/CreativeImportExport/creativeImportExport';
import { GcmCreativeModel } from '../../types/creative.types';

type Props = {
    creatives: GcmCreativeModel[];
    className?: string;
};

const CreativeExportButton = ({ creatives, className }: Props) => {
    const items = [
        {
            label: 'Landing pages',
            action: () => creativeImportExport.toExcel(creatives),
        },
    ];
    return (
        <ButtonDropdown color="secondary" className={classNames(className)} items={items} dropdownMenuRight>
            <i className="fa fa-download" /> Export
        </ButtonDropdown>
    );
};

export default CreativeExportButton;
