import { ReactNode } from 'react';
import { Col } from 'reactstrap';
import { EMPTY_SYMBOL } from 'platform/common/constants/common.constant';

interface Props {
    label: string;
    value: ReactNode;
}

const JourneyRowBlock = ({ label, value }: Props) => (
    <Col>
        <div className="fw-bold font-lg">{value ?? EMPTY_SYMBOL}</div>
        <div className="text-muted">{label}</div>
    </Col>
);

export default JourneyRowBlock;
