import { CSSProperties, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import classnames from 'classnames';
import Search from 'platform/common/components/Search/Search';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';

export interface ButtonDropdownOption {
    key?: string;
    label: string;
    disabled?: boolean;
    action?: () => void;
}

interface Props {
    items: ButtonDropdownOption[];
    children: ReactNode;
    container?: string | HTMLElement | React.RefObject<HTMLElement>;
    className?: string;
    color?: string;
    buttonClassName?: string;
    buttonStyle?: CSSProperties;
    dropdownMenuRight?: boolean;
    disabled?: boolean;
    caret?: boolean;
    isSearchable?: boolean;
    persist?: boolean;
    onItemClick?: (option: ButtonDropdownOption) => void;
}

const ButtonDropdown = ({
    items,
    children,
    container,
    className = '',
    color = 'primary',
    buttonClassName,
    buttonStyle,
    dropdownMenuRight,
    disabled,
    caret = true,
    isSearchable = false,
    persist = false,
    onItemClick,
}: Props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const isMobile = useSelector(layoutSelectors.isMobile);
    const onDropdownItemClick = (option: ButtonDropdownOption) => {
        if (option.action) option.action();
        if (onItemClick) onItemClick(option);
        setSearchQuery('');
    };

    const filteredItems = searchQuery
        ? items.filter((option) => option.label.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
        : items;

    return (
        <UncontrolledButtonDropdown className={className} disabled={disabled}>
            <DropdownToggle
                className={buttonClassName}
                caret={caret}
                style={buttonStyle}
                color={color}
                disabled={disabled}
            >
                {children}
            </DropdownToggle>
            <DropdownMenu
                container={container}
                style={{ zIndex: 2000, ...(isMobile ? { width: '400px' } : {}) }}
                end={Boolean(dropdownMenuRight)}
                persist={persist}
            >
                <>
                    {isSearchable && (
                        <Search
                            value={searchQuery}
                            className="py-2 px-3"
                            onSearch={setSearchQuery}
                            style={{ minWidth: `${isMobile ? 400 : 500}px` }}
                        />
                    )}
                    <div className="overflow-y-auto" style={{ maxHeight: '300px' }}>
                        {!filteredItems.length && (
                            <div className="d-flex justify-content-center py-2 px-3">
                                <span>No items found</span>
                            </div>
                        )}
                        {filteredItems.map((option) => (
                            <DropdownItem
                                className={classnames({ disabled: option.disabled })}
                                onClick={option.disabled ? undefined : () => onDropdownItemClick(option)}
                                key={option.key || option.label}
                            >
                                {option.label}
                            </DropdownItem>
                        ))}
                    </div>
                </>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
};

export default ButtonDropdown;
