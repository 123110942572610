import { Badge } from 'reactstrap';
import classNames from 'classnames';
import { ObserverMessagePriority } from 'platform/observer/observer.types';

const PRIORITY_COLORS: Record<ObserverMessagePriority, string> = {
    LOW: 'ObserverMessageCenterList-badge--low',
    MID: 'ObserverMessageCenterList-badge--mid',
    HIGH: 'ObserverMessageCenterList-badge--high',
};

interface Props {
    priority: ObserverMessagePriority;
}

const MessageCenterItemPriority = ({ priority }: Props) => (
    <Badge className={classNames('ObserverMessageCenterList-badge', PRIORITY_COLORS[priority])}>
        {priority}
    </Badge>
);

export default MessageCenterItemPriority;
