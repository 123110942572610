import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Placeholder from '../Placeholder/Placeholder';

const CardRowPlaceholder = ({ labelWidth }: { labelWidth: string }) => (
    <Row className="form-group d-flex align-items-center">
        <Col md="4">
            <Placeholder className="ms-auto" height="17px" width={labelWidth} />
        </Col>
        <Col md="8">
            <Placeholder height="33px" width="100%" />
        </Col>
    </Row>
);

const CardFormPlaceholder = () => (
    <Card className="CardFormPlaceholder h-100 CardForm">
        <CardHeader className="d-flex align-items-center">
            <Placeholder height="18px" width="50%" />
            <div className="d-flex ms-auto">
                <Placeholder className="ms-2" height="33px" width="60px" borderRadius="1.5rem" />
                <Placeholder className="ms-2" height="33px" width="60px" borderRadius="1.5rem" />
            </div>
        </CardHeader>
        <CardBody>
            <Card>
                <CardHeader className="bg-white">
                    <Placeholder className="mt-1" height="17px" width="30%" />
                </CardHeader>
                <CardBody>
                    <CardRowPlaceholder labelWidth="90%" />
                    <CardRowPlaceholder labelWidth="70%" />
                    <CardRowPlaceholder labelWidth="90%" />
                    <CardRowPlaceholder labelWidth="70%" />
                </CardBody>
            </Card>
        </CardBody>
    </Card>
);

export default CardFormPlaceholder;
