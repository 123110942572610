import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Alert, Card, CardBody } from 'reactstrap';
import qs from 'qs';
import { useAnalyticsReports } from 'platform/analytics/hooks/useAnalyticsReports';
import { AnalyticsReportWithSettings } from 'platform/analyticsReports/analyticsReport.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';

interface Props {
    onRedirect: () => void;
}

const EmbeddedReport = ({ onRedirect }: Props) => {
    const location = useLocation();
    const { url, title } = qs.parse(location.search, { ignoreQueryPrefix: true });

    useActiveAdvertiserComponent();
    const { accessibleReports } = useAnalyticsReports();

    useEffect(() => {
        if (!accessibleReports.some((report: AnalyticsReportWithSettings) => report.name === title)) {
            onRedirect();
        }
    }, []);

    return (
        <>
            <HeaderContainer>
                <PageHeader title={title as string} />
            </HeaderContainer>
            <BodyContainer>
                <Card className="h-100">
                    <CardBody className="py-1 px-1 h-100">
                        {url ? (
                            <iframe
                                src={url as string}
                                title={url as string}
                                width="100%"
                                height="100%"
                                style={{ border: 'none', height: '100vh' }}
                            />
                        ) : (
                            <Alert color="danger" className="align-self-center">
                                Embedded url not found within the report
                            </Alert>
                        )}
                    </CardBody>
                </Card>
            </BodyContainer>
        </>
    );
};

export default EmbeddedReport;
