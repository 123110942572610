import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FieldArray } from 'formik';
import { uniqueId } from 'lodash-es';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { fetchDataSharingTemplates } from '../../../adminPanel/services/segmentSharingTemplate.service';
import { fetchAdvertiser } from '../../../advertisers/services/advertiser.service';
import ButtonDropdown from '../../../common/components/ButtonDropdown/ButtonDropdown';
import CardForm from '../../../common/components/CardForm/CardForm';
import { Segment, SnippetType } from '../../advertiserTag.types';
import { FirePixelType } from '../../constants/advertiserTag.constant';
import { RuleSource } from '../../constants/ruleSource.constant';
import ThirdPartyCodesMapper from '../../mappers/thirdPartyCodes.mapper';
import * as AdvertiserTagService from '../../services/advertiserTag.service';
import ThirdPartyRules from '../ThirdPartyRules/ThirdPartyRules';

const THIRD_PARTY_CODES_FORM_HELP_KEY = 'third_party_codes_form';
export const THIRD_PARTY_CODES_FORM_RULE_HELP_KEY = `${THIRD_PARTY_CODES_FORM_HELP_KEY}_rule`;
export const THIRD_PARTY_CODES_FORM_CODE_SNIPPET_HELP_KEY = `${THIRD_PARTY_CODES_FORM_HELP_KEY}_codeSnippets`;

const ThirdPartyCodesForm = ({ isEdit, canEdit, redirectTo }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const vendors = useSelector(classifierSelectors.vendors);

    const handleOpen = async () => {
        const [rules, segments, templates, advertiserTag] = await Promise.all([
            AdvertiserTagService.fetchThirdPartyTrackingRules(id),
            AdvertiserTagService.fetchSegments({ advertiserTagId: id }).then((list) =>
                list.filter((s: Segment) => s.state === 'ACTIVE')
            ),
            fetchDataSharingTemplates({ states: ['ACTIVE'] }),
            AdvertiserTagService.fetchAdvertiserTag(id),
        ]);
        const advertiser = await fetchAdvertiser(advertiserTag.advertiserId);
        return ThirdPartyCodesMapper.toFormModel({
            advertiserTagId: id,
            rulesData: {
                rules: (rules || []).map((rule) => ({
                    ...rule,
                    tempId: uniqueId('ThirdPartyRule'),
                    codeSnippets: rule.codeSnippets.map((snippet) => ({
                        ...snippet,
                        tempId: uniqueId('ThirdPartyRule-CodeSnippet'),
                    })),
                })),
            },
            segments,
            templates,
            advertiser,
        });
    };

    const handleSubmit = (formValues: any) => {
        const { advertiserTagId, rules } = ThirdPartyCodesMapper.toApi(formValues);
        return AdvertiserTagService.saveThirdPartyTrackingRules(advertiserTagId, rules);
    };

    const addRule = (push: (obj: any) => void) => {
        push({
            tempId: uniqueId('ThirdPartyStandardRule'),
            isNew: true,
            active: true,
            ruleName: 'New rule',
            description: '',
            includedSegments: [],
            excludedSegments: [],
            ruleSource: 'RULE_BUILDER',
            firePixels: FirePixelType.CUSTOM,
            fireOncePerDay: false,
            codeSnippets: [
                {
                    tempId: uniqueId('temp-CodeSnippet'),
                    type: 'JAVASCRIPT_HTML',
                    codeIntegralPart: '',
                    snippetType: SnippetType.STANDARD,
                    active: true,
                },
            ],
        });
    };

    const addDataSharing = (push: (obj: any) => void) => {
        push({
            tempId: uniqueId('ThirdPartyRule'),
            isNew: true,
            active: true,
            ruleName: 'New template rule',
            description: '',
            codeSnippets: [
                {
                    tempId: uniqueId('DataSharingRule-CodeSnippet'),
                    snippetType: SnippetType.DATA_SHARING,
                    active: true,
                    globalVariables: [],
                    userVariables: [],
                },
            ],
            includedSegments: [],
            excludedSegments: [],
            ruleSource: RuleSource.DATA_SHARING_TEMPLATE,
            firePixels: FirePixelType.CUSTOM,
            fireOncePerDay: false,
        });
    };

    return (
        <FormContainer
            helpKey={THIRD_PARTY_CODES_FORM_HELP_KEY}
            sidePanel
            onSubmit={handleSubmit}
            onOpen={handleOpen}
            redirectTo={redirectTo}
        >
            {({ formikProps: { setFieldValue, initialValues, values, submitForm }, labels, onCancel }) => (
                <CardForm
                    title={`${labels.prefix} AdvertiserTag Data Sharing`}
                    subtitle={isEdit && `ID: ${initialValues.advertiserTagId}`}
                    submitLabel={labels.submit}
                    onCancel={onCancel}
                    disabled={!canEdit}
                    onSubmit={submitForm}
                >
                    <FieldArray name="rules">
                        {({ push, remove }) => (
                            <>
                                <ThirdPartyRules
                                    rules={values.rules}
                                    segments={values.segments}
                                    templates={values.templates}
                                    vendors={vendors}
                                    advertiser={values.advertiser}
                                    setFieldValue={setFieldValue}
                                    onRuleRemove={(index: number) => remove(index)}
                                />
                                <ButtonDropdown
                                    items={[
                                        { label: 'Custom', action: () => addRule(push) },
                                        { label: 'Use template', action: () => addDataSharing(push) },
                                    ]}
                                    color="primary"
                                >
                                    Add rule
                                </ButtonDropdown>
                            </>
                        )}
                    </FieldArray>
                </CardForm>
            )}
        </FormContainer>
    );
};

export default ThirdPartyCodesForm;
