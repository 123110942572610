import { useState } from 'react';
import { DragDropContext, DraggableLocation, Droppable, DropResult } from 'react-beautiful-dnd';
import { AxiosResponse } from 'axios';
import FloatingButton from 'platform/common/components/FloatingButton/FloatingButton';
import { User } from 'platform/userManagement/types/user.type';
import {
    Task,
    TaskBoardChange,
    TaskBoardState,
    TaskCreationData,
    TaskData,
    TaskLane,
    TaskLaneData,
    TaskLocation,
    TaskStatus,
} from '../task.types';
import KanbanColumn from './KanbanColumn';
import KanbanColumnAdder from './KanbanColumnAdder';
import { KanbanFilterValues } from './KanbanFilters';

type Props = {
    board: TaskBoardState;
    filteredLanes: TaskLane[];
    userOptions: User[];
    canEditBoard: boolean;
    filters: KanbanFilterValues;
    onStatusFilterChange: (newFilter: TaskStatus[]) => void;
    onLaneAdd: (position: number, data: TaskLaneData) => Promise<void>;
    onLaneUpdate: (laneId: number, data: TaskLaneData) => Promise<void>;
    onLaneMove: (laneId: number, position: number) => void;
    onLaneDelete: (laneId: number) => Promise<void>;
    onTaskAdd: (location: TaskLocation, data: TaskCreationData) => Promise<any>;
    onTaskMove: (taskId: number, location: TaskLocation) => void;
    onTaskUpdate: (taskId: number, data: Partial<TaskData>) => void;
    onTaskDelete: (taskId: number) => void;
    onTaskOpen: (taskId: number) => void;
    onTaskCopy: (task: Task) => void;
    onAttachmentDownload: (taskId: number, attachmentId: number) => Promise<AxiosResponse<Blob>>;
    onTaskWatchChange: (taskId: number, watch: boolean) => Promise<TaskBoardChange[]>;
};

const hasMoved = (source: DraggableLocation, destination: DraggableLocation) =>
    source.droppableId !== destination.droppableId || source.index !== destination.index;

const KanbanBoard = ({
    board,
    filteredLanes,
    userOptions,
    canEditBoard,
    filters,
    onStatusFilterChange,
    onLaneAdd,
    onLaneUpdate,
    onLaneMove,
    onLaneDelete,
    onTaskAdd,
    onTaskUpdate,
    onTaskMove,
    onTaskDelete,
    onTaskOpen,
    onTaskCopy,
    onAttachmentDownload,
    onTaskWatchChange,
}: Props) => {
    const [isAddingLane, setAddingLane] = useState(false);

    const handleAddLane = async (data: TaskLaneData) => {
        await onLaneAdd(board.lanes.length, data);
        setAddingLane(false);
    };

    const handleDragEnd = ({ type, draggableId, source, destination }: DropResult) => {
        if (!destination || !hasMoved(source, destination)) return;
        const { droppableId, index } = destination;
        if (type === 'DRAGGABLE_LANE') {
            onLaneMove(Number(draggableId), index);
        }
        if (type === 'DRAGGABLE_TASK') {
            onTaskMove(Number(draggableId), { laneId: Number(droppableId), position: index });
        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="Kanban-board">
                <Droppable droppableId="board-droppable" type="DRAGGABLE_LANE" direction="horizontal">
                    {({ droppableProps, innerRef, placeholder }) => (
                        <div ref={innerRef} {...droppableProps} style={{ whiteSpace: 'nowrap' }}>
                            {filteredLanes.map((lane, index) => (
                                <KanbanColumn
                                    key={lane.id}
                                    board={board}
                                    lane={lane}
                                    laneIndex={index}
                                    userOptions={userOptions}
                                    canEditColumn={canEditBoard}
                                    filters={filters}
                                    onStatusFilterChange={onStatusFilterChange}
                                    onLaneUpdate={onLaneUpdate}
                                    onLaneDelete={onLaneDelete}
                                    onTaskAdd={onTaskAdd}
                                    onTaskUpdate={onTaskUpdate}
                                    onTaskMove={onTaskMove}
                                    onTaskDelete={onTaskDelete}
                                    onTaskOpen={onTaskOpen}
                                    onTaskCopy={onTaskCopy}
                                    onAttachmentDownload={onAttachmentDownload}
                                    onTaskWatchChange={onTaskWatchChange}
                                />
                            ))}
                            {placeholder}
                        </div>
                    )}
                </Droppable>
                {canEditBoard &&
                    filteredLanes.length === board.lanes.length &&
                    (isAddingLane ? (
                        <div className="Kanban-column">
                            <div className="Kanban-column-header">
                                <KanbanColumnAdder
                                    onSubmit={handleAddLane}
                                    onCancel={() => setAddingLane(false)}
                                />
                            </div>
                        </div>
                    ) : (
                        <FloatingButton icon="fa fa-plus" onClick={async () => setAddingLane(true)}>
                            Add column
                        </FloatingButton>
                    ))}
            </div>
        </DragDropContext>
    );
};

export default KanbanBoard;
