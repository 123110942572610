import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import { isString } from 'lodash-es';
import TextEditor from 'platform/common/components/TextEditor/TextEditor';
import { Validator, validator } from 'platform/common/utils/validators.util';

export const FormTextEditor = ({ validate, name }: { name: string; validate?: Validator | Validator[] }) => {
    const [field, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const invalid = Boolean(meta.touched && meta.error);

    return (
        <>
            <TextEditor
                height={500}
                value={field.value}
                onChange={(value) => {
                    helpers.setValue(value);
                }}
            />

            {Boolean(meta.touched && invalid) && isString(meta.error) ? (
                <FormFeedback className="d-block">{meta.error}</FormFeedback>
            ) : null}
        </>
    );
};
