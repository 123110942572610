import { fmtDate } from 'platform/common/utils/date.util';
import { ItemGroupCM360 } from '../../CustomerJourneyCM360';
import JourneyEventTypeIcon from '../../JourneyEventList/JourneyEventTypeIcon';
import { JOURNEY_EVENT_TYPE_TEXT } from '../../customerJourney.constants';
import { JOURNEY_EVENT_WIDTH } from './JourneyTimeline';
import './JourneyEvent.scss';

const DATE_FORMAT = 'MM.DD';

const scrollToJourneyListGroup = (groupIndex: number) => {
    const elem = document.getElementById(`JourneyListGroup-${groupIndex}`);
    if (elem) {
        elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
};

interface Props {
    group: ItemGroupCM360;
    groupIndex: number;
    leftDashVisible: boolean;
    rightDashVisible: boolean;
    circleEmpty: boolean;
}

const LineSpacer = ({ visible }: { visible: boolean }) => (
    <div
        style={{
            width: `${(JOURNEY_EVENT_WIDTH - 20) / 2}px`,
            height: 2,
            backgroundColor: visible ? '#1B90D7' : 'transparent',
        }}
    />
);

const JourneyEvent = ({ group, groupIndex, leftDashVisible, rightDashVisible, circleEmpty }: Props) => (
    <div
        className="JourneyEvent d-flex flex-column align-items-center"
        style={{ width: JOURNEY_EVENT_WIDTH }}
        role="button"
        tabIndex={0}
        onClick={() => scrollToJourneyListGroup(groupIndex)}
    >
        <div className="mt-2" style={{ height: 45 }}>
            <JourneyEventTypeIcon eventType={group.rows[0].journey_event_type} />
        </div>

        <div className="my-2 fw-bold font-lg text-nowrap" style={{ height: 20 }}>
            {JOURNEY_EVENT_TYPE_TEXT[group.eventType]} {group.rows.length > 1 ? `x${group.rows.length}` : ''}
        </div>
        <div className="JourneyEvent-connector" />
        <div className="my-2 d-flex align-items-center" style={{ height: 10 }}>
            <LineSpacer visible={leftDashVisible} />
            <div
                style={{
                    width: 10,
                    height: 10,
                    margin: '0 5px',
                    backgroundColor: circleEmpty ? '#FFF' : '#1B90D7',
                    border: '2px solid #1B90D7',
                    borderRadius: '50%',
                }}
            />
            <LineSpacer visible={rightDashVisible} />
        </div>
        <div style={{ height: 20 }}>
            {group.startTime === group.endTime
                ? fmtDate(DATE_FORMAT)(group.startTime)
                : `${fmtDate(DATE_FORMAT)(group.startTime)} - ${fmtDate(DATE_FORMAT)(group.endTime)}`}
        </div>
    </div>
);

export default JourneyEvent;
