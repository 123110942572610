import AdvertiserAccountFormLabel from 'platform/advertisers/components/AdvertiserAccountLabel/AdvertiserAccountFormLabel';
import { CampaignIntegration } from 'platform/campaign/campaign/campaign.types';
import FacebookIntegration from './FacebookIntegration';

interface Props {
    advertiserId: number;
    integration: CampaignIntegration;
}

const CampaignProgrammaticIntegrationSettings = ({ advertiserId, integration }: Props) => {
    const { externalSystem, externalAdvertiserId } = integration;

    return (
        <>
            {externalAdvertiserId && (
                <AdvertiserAccountFormLabel
                    advertiserId={advertiserId}
                    externalSystem={externalSystem}
                    externalAdvertiserId={externalAdvertiserId}
                />
            )}
            {externalSystem === 'FACEBOOK' && <FacebookIntegration integration={integration} />}
        </>
    );
};

export default CampaignProgrammaticIntegrationSettings;
