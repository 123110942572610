import { useSelector } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { stringify } from 'qs';
import {
    ALGORITHM_LANGUAGE_LABELS,
    ALGORITHM_LANGUAGE_VALUE,
} from 'platform/algorithms/algorithmManager.constant';
import { AlgorithmFormModel } from 'platform/algorithms/algorithms.types';
import AlgorithmSettings from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettings';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import CardForm from 'platform/common/components/CardForm/CardForm';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Separator from 'platform/common/components/Separator/Separator';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { makeOptions } from 'platform/common/utils/option.util';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { required } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';
import generateGAS from 'platform/generateGAS';

const AlgorithmForm = ({
    labels,
    formikProps: {
        initialValues,
        submitForm,
        values: {
            id,
            version,
            code,
            algorithmTemplateName,
            templateVersion,
            templateSettings,
            templateCode,
            templateCodeLanguage,
            templateSettingsLanguage,
            settings,
            display,
            externalRuntime,
            externalToken,
            externallyAvailable,
            hasMultipleSettings,
        },
        setFieldValue,
    },
    canEdit,
    onCancel,
}: FormProps<AlgorithmFormModel>) => {
    const isAdmin = useSelector(authSelectors.isAdmin);
    const advertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const showConfirmationModal = useConfirmationModal();

    const canGenerateAlgorithmCode = !!initialValues.id && !!initialValues.externalToken;
    const subtitle = `${initialValues.algorithmTemplateName}${
        initialValues.id ? `, ID: ${initialValues.id}` : ''
    }`;

    return (
        <CardForm
            title="Setup algorithm"
            subtitle={subtitle}
            submitLabel={labels.submit}
            animationDelay={0}
            disabled={!canEdit}
            onCancel={onCancel}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info">
                <FormRow label="Status">
                    <FormStateSelect name="status" options={STATUS_OPTIONS} validate={required} />
                </FormRow>
                <FormRow label="Code version">
                    <FormInput name="version" type="text" validate={required} />
                </FormRow>
                {Number(version?.replace(/\./g, '')) < Number(templateVersion?.replace(/\./g, '')) && (
                    <FormRow>
                        <Alert color="success" className="mb-0">
                            Newest code version template: <strong>{templateVersion}</strong>
                            <Button
                                color="primary"
                                className="ms-3"
                                onClick={() =>
                                    showConfirmationModal(
                                        () => {
                                            setFieldValue('version', templateVersion);
                                            setFieldValue('code', templateCode);
                                            setFieldValue('settings', templateSettings);
                                            setFieldValue(
                                                'display.settingsLanguage',
                                                templateSettingsLanguage
                                            );
                                            setFieldValue('display.codeLanguage', templateCodeLanguage);
                                        },
                                        {
                                            title: `Update version to ${templateVersion}`,
                                            text: `Apply v${templateVersion} changes to this setup?`,
                                            okLabel: 'Update',
                                        }
                                    )
                                }
                            >
                                Update
                            </Button>
                        </Alert>
                    </FormRow>
                )}
                <Separator label="Optional" />
                <FormRow label="Notes">
                    <FormInput name="notes" type="textarea" />
                </FormRow>
            </ControlledCard>
            {templateSettings && (
                <ControlledCard title="Settings">
                    <AlgorithmSettings
                        settings={settings}
                        settingsLanguage={display.settingsLanguage}
                        hasMultipleSettings={hasMultipleSettings}
                        onChange={(value) => setFieldValue('settings', value)}
                    />
                </ControlledCard>
            )}
            {templateCode && (
                <div>
                    <ControlledCard title="Code">
                        <FormCodeEditor
                            name="code"
                            options={{
                                mode: display.codeLanguage
                                    ? ALGORITHM_LANGUAGE_VALUE[display.codeLanguage]
                                    : ALGORITHM_LANGUAGE_VALUE.JAVASCRIPT,
                                lineWrapping: true,
                                readOnly: false,
                                lineNumbers: true,
                            }}
                            validate={required}
                        />
                        <FormRow label="Language">
                            <FormSelect
                                name="display.codeLanguage"
                                options={makeOptions(ALGORITHM_LANGUAGE_LABELS)}
                                validate={required}
                                isClearable={false}
                            />
                        </FormRow>
                    </ControlledCard>
                    {display.codeLanguage === 'BIG_QUERY' && externallyAvailable && (
                        <FormRow label="External File Format">
                            <FormButtonArray
                                buttons={[
                                    { label: 'Excel', value: 'EXCEL' },
                                    { label: 'Csv', value: 'CSV' },
                                    { label: 'Json', value: 'JSON' },
                                ]}
                                name="format"
                            />
                        </FormRow>
                    )}
                </div>
            )}
            <ControlledCard title="Actions">
                {!canGenerateAlgorithmCode && (
                    <Alert color="info">
                        Algorithm name and code will be available after setup is finished
                    </Alert>
                )}
                <div className="d-flex flex-column">
                    {canGenerateAlgorithmCode && (
                        <>
                            <Button
                                className="mb-3"
                                onClick={() => {
                                    const name = `${algorithmTemplateName}_${advertiser.name}_${id}`;
                                    window.navigator.clipboard.writeText(name);
                                    toastSuccess('Algorithm name copied to clipboard');
                                }}
                            >
                                Copy algorithm name
                            </Button>
                            {externalRuntime === 'GAS' && (
                                <Button
                                    className="mb-3"
                                    onClick={() => {
                                        if (!id || !externalToken) {
                                            return;
                                        }

                                        const generatedGAS = generateGAS({
                                            algorithmId: id,
                                            code,
                                            accessToken: externalToken,
                                        });

                                        window.navigator.clipboard.writeText(generatedGAS);
                                        toastSuccess('Algorithm code copied to clipboard');
                                    }}
                                >
                                    Copy algorithm code
                                </Button>
                            )}
                            {externallyAvailable && (
                                <Button
                                    className="w-100"
                                    onClick={() => {
                                        const query = stringify({ accessToken: externalToken });
                                        const url = `${window.location.origin}/algorithms/${id}/execute?${query}`;
                                        window.navigator.clipboard.writeText(url);
                                        toastSuccess('Algorithm URL copied to clipboard');
                                    }}
                                >
                                    Copy algorithm URL
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </ControlledCard>
            {isAdmin && (
                <ControlledCard title="Admin notes" isExpanded={false}>
                    <FormRow label="Admin notes">
                        <FormInput name="adminNotes" type="textarea" disabled />
                    </FormRow>
                </ControlledCard>
            )}
        </CardForm>
    );
};

export default AlgorithmForm;
