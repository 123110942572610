import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { sortBy } from 'lodash-es';
import moment from 'moment';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import LogBookClassificationCard from 'platform/campaign/logBook/components/LogBookForm/LogBookClassificationCard';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { formatDateTime } from 'platform/common/utils/date.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import { WorkflowEvent, WorkflowNotesFormModel, WorkflowStep } from 'platform/mediaplan/mediaplan.types';
import '../../../Mediaplan.scss';

interface Props {
    step: WorkflowStep;
    stepEvents: WorkflowEvent[];
    canEdit: boolean;
    onChange: (step: WorkflowStep, event: WorkflowEvent) => void;
    togglePopover: () => void;
}

const columns: TableColumn<WorkflowEvent>[] = [
    {
        Header: 'Notes',
        sortable: false,
        accessor: 'notes',
        Cell: CellWithEllipsis,
    },
    {
        Header: 'Saved',
        accessor: 'timestamp',
        width: 200,
        Cell: ({ original: { timestamp, changedBy } }) =>
            changedBy ? <UpdatedOn date={formatDateTime(timestamp)} updatedBy={changedBy} /> : null,
    },
];

const WORKFLOW_NOTES_INITIAL_FORM: WorkflowNotesFormModel = {
    notes: '',
};

const WorkflowNotesPopover = ({ step, stepEvents, canEdit, togglePopover, onChange }: Props) => {
    const userName = useSelector(authSelectors.accountName);

    return (
        <FormContainer
            helpKey="mediaplan_workflow_form"
            formikConfig={{ enableReinitialize: true }}
            onOpen={async () => WORKFLOW_NOTES_INITIAL_FORM}
        >
            {({ formikProps }) => (
                <div className="Mediaplan-logBookPopover">
                    <FormRow label="Notes" labelWidth={12} labelClassName="">
                        <FormInput name="notes" validate={required} type="textarea" />
                    </FormRow>
                    <LogBookClassificationCard
                        field="classification"
                        values={formikProps.values.classification}
                        onChange={formikProps.setFieldValue}
                        labelWidth={12}
                        labelClassName="text-start"
                        bodyClassName="d-flex gap-4"
                    />
                    <div className="d-flex justify-content-end">
                        <Button onClick={togglePopover}>Cancel</Button>
                        {canEdit && (
                            <Button
                                className="ms-3"
                                type="submit"
                                color="primary"
                                disabled={
                                    !formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty
                                }
                                onClick={() => {
                                    onChange(
                                        {
                                            ...step,
                                            notes: formikProps.values.notes,
                                            classification: formikProps.values.classification,
                                        },
                                        {
                                            timestamp: moment().toISOString(),
                                            changedBy: userName,
                                            stepType: step.type,
                                            notes: formikProps.values.notes,
                                        }
                                    );
                                    togglePopover();
                                }}
                            >
                                Save
                            </Button>
                        )}
                    </div>
                    {stepEvents.length > 0 && (
                        <ControlledCard
                            className="mt-3 Mediaplan-logBookPopover-changeHistory dark mt-3 mb-0"
                            bodyClassName="p-0"
                            title={`History (${stepEvents.length} changes)`}
                        >
                            <FormattedTable columns={columns} data={sortBy(stepEvents, (e) => e.timestamp)} />
                        </ControlledCard>
                    )}
                </div>
            )}
        </FormContainer>
    );
};

export default WorkflowNotesPopover;
