import { Link } from 'react-router-dom';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import ControlledCardBorderless from 'platform/common/components/ControlledCard/ControlledCardBorderless';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { isPast } from 'platform/common/utils/date.util';
import { required } from 'platform/common/utils/validators.util';
import FormAdvertiserSelect from 'platform/formik/FormAdvertiserSelect/FormAdvertiserSelect';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormInput from 'platform/formik/FormInput/FormInput';
import { CampaignModel } from '../../campaign.types';
import { CampaignFormLandingPageSuffix } from './CampaignFormLandingPageSuffix';
import CampaignFormType from './CampaignFormType';
import CampaignFormVendor from './CampaignFormVendor';

const noDateToInPast = (dateRange: { to?: string } = {}) =>
    isPast(dateRange.to) ? 'Cannot create campaign that is ended' : undefined;

type Props = {
    editMode: boolean;
    creativeConceptId?: number;
    campaign: CampaignModel;
};

const CampaignGeneralInfo = ({ editMode, creativeConceptId, campaign }: Props) => (
    <ControlledCard title="General information" subtitle="required" bodyClassName="form-content">
        <FormHelp iconClassName="mt-2">
            <FormAdvertiserSelect
                firstAddon="Advertiser"
                name="advertiserId"
                validate={required}
                disabled
                inputClassName="w-100"
            />
        </FormHelp>
        <FormHelp iconClassName="mt-2">
            <FormInput
                name="name"
                type="text"
                validate={required}
                leftAddOn={{ title: 'Campaign name' }}
                className="flex-grow-1"
            />
        </FormHelp>
        {editMode && <CampaignFormType campaign={campaign} />}
        {campaign.type !== 'RTB' && <CampaignFormVendor campaign={campaign} />}
        <FormHelp iconClassName="mt-2">
            <FormDateRangePicker
                leftAddOn={{ title: 'Flight dates' }}
                name="flightDates"
                validate={editMode ? undefined : noDateToInPast}
                className="flex-grow-1"
            />
        </FormHelp>
        {creativeConceptId && (
            <FormHelp iconClassName="mt-2" helpKey="creativeSet">
                <InputWithAddOn
                    disabled
                    className="mb-3"
                    leftAddOn={{ title: 'Creative set' }}
                    hideInput
                    rightAddOn={{
                        className: 'd-none',
                        containerClassName: 'flex-fill text-start',
                        title: <Link to={`/creatives/${creativeConceptId}`}>{creativeConceptId}</Link>,
                    }}
                />
            </FormHelp>
        )}

        <ControlledCardBorderless
            title="Optional"
            className="mb-3 ControlledCardBorderless"
            bodyClassName="mt-3"
            isExpanded
        >
            {campaign?.type === 'REDIRECT' && campaign?.adserver === 'GCM' && (
                <CampaignFormLandingPageSuffix campaign={campaign} />
            )}
            <FormHelp className="mb-0">
                <FormInput
                    leftAddOn={{ title: 'Campaign notes' }}
                    name="notes"
                    type="textarea"
                    className="flex-grow-1"
                />
            </FormHelp>
        </ControlledCardBorderless>
    </ControlledCard>
);

export default CampaignGeneralInfo;
