import DefaultMetricsSelect from 'platform/campaign/advertiserManagement/DefaultMetrics/DefaultMetricsSelect';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { maxArraySize } from 'platform/common/utils/validators.util';
import { DefaultMetrics } from './defaultMetrics.types';

const DefaultMetricsForm = ({ canEdit, onCancel, formikProps }: FormProps<DefaultMetrics>) => (
    <CardForm
        title="Default metrics"
        submitLabel="Save"
        disabled={!canEdit}
        onCancel={onCancel}
        onSubmit={formikProps.submitForm}
    >
        <ControlledCard title="In analytics sections">
            <FormRow label="Tables (all presets)">
                <DefaultMetricsSelect name="tableMetrics" values={formikProps.values.tableMetrics} />
            </FormRow>
            <FormRow label="Summary bars (first row)">
                <DefaultMetricsSelect
                    name="summaryBarFirstRowMetrics"
                    values={formikProps.values.summaryBarFirstRowMetrics}
                    validate={maxArraySize(6)}
                />
            </FormRow>
            <FormRow label="Summary bars (second row)">
                <DefaultMetricsSelect
                    name="summaryBarSecondRowMetrics"
                    values={formikProps.values.summaryBarSecondRowMetrics}
                    validate={maxArraySize(6)}
                />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default DefaultMetricsForm;
