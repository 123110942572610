import { NavigationItem } from 'platform/app/app.types';
import { ReactComponent as PRODUCTS } from 'platform/assets/icons/PRODUCTS.svg';

export const PRODUCT_ACTION_PROBABILITY: NavigationItem = {
    name: 'Products',
    path: '/productActionProbability',
    redirectTo: '/productActionProbability/category',
    icon: PRODUCTS,
    requiresFeature: 'PRODUCT_ACTION_PROBABILITY',
};

export const PRODUCT_ACTION_PROBABILITY_CATEGORY: NavigationItem = {
    name: 'Product category',
    path: '/productActionProbability/category',
    requiresFeature: 'PRODUCT_ACTION_PROBABILITY',
};

export const PRODUCT_ACTION_PROBABILITY_PRODUCT: NavigationItem = {
    name: 'Product',
    path: '/productActionProbability/product',
    requiresFeature: 'PRODUCT_ACTION_PROBABILITY',
};

export const PRODUCT_OVERLAP_PRODUCTS: NavigationItem = {
    name: 'Product Overlap',
    path: '/productActionProbability/productOverlap',
    requiresFeature: 'PRODUCT_ACTION_PROBABILITY',
};
