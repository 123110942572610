import { sortBy } from 'lodash-es';
import { StatisticWithSeries } from 'platform/common/common.types';
import { SegmentChartStats } from '../segments.types';

const regexAlphabet = /[^a-zA-Z]/g;
const regexNumeric = /[^0-9]/g;

const sortAlphaNum = (a: any, b: any) => {
    const aA = a.name.replace(regexAlphabet, '');
    const bA = b.name.replace(regexAlphabet, '');
    if (aA === bA) {
        const aN = parseInt(a.name.replace(regexNumeric, ''), 10);
        const bN = parseInt(b.name.replace(regexNumeric, ''), 10);
        if (aN === bN) {
            return 0;
        }
        return aN > bN ? 1 : -1;
    }
    return aA > bA ? 1 : -1;
};

const extractIntervalFromName = (name: string) => Number(name.split(' ').pop());

export const calculateAverage = (data: any[], allUniqueUsers: number) => {
    const uniqueUsersDimension = 'adh_segment_unique_users_30d';
    const sortedAscending = sortBy(data, (val) => extractIntervalFromName(val.name));

    const totalAmount = sortedAscending.reduce((accumulator, current, index) => {
        const nextIndex = index + 1;

        const usersInInterval =
            nextIndex < data.length
                ? current.stats[uniqueUsersDimension].value -
                  sortedAscending[nextIndex].stats[uniqueUsersDimension].value
                : current.stats[uniqueUsersDimension].value;
        return usersInInterval * extractIntervalFromName(current.name) + accumulator;
    }, 0);
    return totalAmount / allUniqueUsers;
};

export const calculateChartData = (
    data: {
        name: string;
        stats: {
            adh_segment_unique_users_30d: StatisticWithSeries;
            adh_available_reach_30d: StatisticWithSeries;
            adh_segment_unique_purchases_30d: StatisticWithSeries;
        };
    }[] = []
): SegmentChartStats[] => {
    const sorted = [...data].sort(sortAlphaNum);
    const mapped = sorted.map((item) => ({
        name: item.name,
        availableReach: item.stats.adh_available_reach_30d.value,
        allActions: item.stats.adh_segment_unique_purchases_30d.value,
        uniqueUsers: item.stats.adh_segment_unique_users_30d.value,
        penetrationDelta: 0,
    }));

    const calcPenetrationDelta = (prev: SegmentChartStats, current: SegmentChartStats) => {
        const actionProbability = current.allActions / current.uniqueUsers || 0;
        const prevActionProbability = prev.allActions / prev.uniqueUsers || 0;
        return Math.max(0, actionProbability - prevActionProbability) * 100;
    };

    return mapped.map((item, index) => ({
        ...item,
        penetrationDelta: index > 0 ? calcPenetrationDelta(mapped[index - 1], item) : item.penetrationDelta,
    }));
};
