import {
    GlobalCostSourceApiModel,
    GlobalCostSourceListModel,
} from 'platform/campaign/globalPreset/globalPreset.types';

export const costSourcesToList = (
    globalCostSources: GlobalCostSourceApiModel
): GlobalCostSourceListModel => ({
    id: globalCostSources.id!,
    name: globalCostSources.name,
    channel: globalCostSources.channel,
    agencyFee: globalCostSources.monetization?.costPerSpendPercentage || 0,
    channelDefault: !!globalCostSources.channelDefault,
});
