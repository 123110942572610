import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { classifierActions } from 'platform/common/ducks/commonClassifiers.duck';
import { createVendor, fetchVendor, fetchVendors, updateVendor } from 'platform/vendors/vendors.service';
import { VendorFormModel } from 'platform/vendors/vendors.types';
import { vendorFormToApi } from 'platform/vendors/vendors.util';
import VendorForm from './VendorForm';

const NEW_VENDOR: VendorFormModel = {
    name: '',
    externalSystem: '',
    status: 'ACTIVE',
    integrations: {
        GCM: {},
    },
    adservers: {
        GCM: {
            enabled: false,
        },
    },
    iconUrl: '',
    tokenParameters: [],
    advertiserLevelTracking: false,
    costIntegrationEnabled: false,
    useInCDP: true,
    privateNetwork: false,
    clickIdEnabled: false,
};

const VendorFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const id = Number(useParams().id);
    const dispatch = useDispatch();

    const loadVendor = (): Promise<VendorFormModel> =>
        fetchVendor(id).then(
            (vendor): VendorFormModel => ({
                ...vendor,
                adservers: {
                    GCM: {
                        enabled: !!vendor.integrations.GCM,
                    },
                },
                tokenParameters: Object.entries(vendor.tokenParameters || {}).map(
                    ([key, value]) => `${key} = ${value}`
                ),
            })
        );

    const handleSubmit = async (data: VendorFormModel) => {
        const vendor = vendorFormToApi(data);
        await (id ? updateVendor(id, vendor) : createVendor(vendor));
        dispatch(classifierActions.setVendors(await fetchVendors()));
    };

    return (
        <FormContainer
            helpKey="vendor_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={false}
            sidePanel
            onOpen={async () => (id ? loadVendor() : NEW_VENDOR)}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <VendorForm {...props} />}
        </FormContainer>
    );
};

export default VendorFormContainer;
