import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';

interface Props {
    value: string;
    onChange: (filterKey: string) => void;
}

const ReportInteractionFilterSelect = ({ value, onChange }: Props) => {
    const { definitions } = useAnalyticsMetadata();

    return (
        <SelectWithAddon
            value={value}
            menuPosition="fixed"
            className="mb-3"
            selectClassname="flex-fill"
            options={definitions.filters}
            getOptionLabel={(f) => `${f.name} [${f.target}]`}
            getOptionValue={(f) => f.target}
            name="Filter"
            onChange={onChange}
        />
    );
};

export default ReportInteractionFilterSelect;
