import { useState } from 'react';
import { components, MenuProps } from 'react-select';
import { difference } from 'lodash-es';

const MenuWithSelectAll = (props: MenuProps<any>) => {
    const [isDataFilterEnabled, setDataFilterEnabled] = useState(false);
    const selectedValues = props.getValue();
    const allValues = props.selectProps.getOptionNodes
        ? props.options.flatMap(props.selectProps.getOptionNodes)
        : props.options;

    const valuesInOptions = (
        props.selectProps.clearValuesOnAddAllFound ? allValues : difference(allValues, selectedValues)
    ).filter((option) =>
        props.selectProps.filterOption
            ? props.selectProps.filterOption(
                  {
                      label: props.selectProps.getOptionLabel?.(option) ?? option.label,
                      value: props.selectProps.getOptionValue?.(option) ?? option.value,
                      data: option,
                  },
                  props.selectProps.inputValue || ''
              )
            : true
    );

    const showAddButton =
        props.selectProps.selectAllEnabled &&
        !!valuesInOptions.length &&
        valuesInOptions.length !== selectedValues.length;

    const onHasDataFilterChange = props.selectProps.onHasDataFilterChange;
    const isHasDataFilterEnabled = !!onHasDataFilterChange;

    const onSelectAll = () =>
        props.setValue(
            [
                ...(props.selectProps.clearValuesOnAddAllFound && props.selectProps.inputValue
                    ? []
                    : selectedValues),
                ...valuesInOptions,
            ],
            'select-option'
        );
    const onHasDataToggle = () => {
        const value = !isDataFilterEnabled;
        setDataFilterEnabled(value);
        onHasDataFilterChange?.(value);
    };

    return (
        <components.Menu {...props}>
            <>
                {props.children}
                {showAddButton && (
                    <button
                        type="button"
                        className=" btn-secondary w-100 border-0 font-xs py-0 my-0 "
                        onClick={onSelectAll}
                    >
                        {props.selectProps.inputValue ? 'Add all found' : 'Add all'}
                    </button>
                )}
                {isHasDataFilterEnabled && (
                    <button
                        type="button"
                        className=" btn-secondary w-100 border-0 font-xs py-0 my-0 "
                        onClick={onHasDataToggle}
                    >
                        Show only columns with data for last 90 days: {isDataFilterEnabled ? 'ON' : 'OFF'}
                    </button>
                )}
            </>
        </components.Menu>
    );
};

export default MenuWithSelectAll;
