import { FocusEvent } from 'react';
import { FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import { useField } from 'formik';
import { isString } from 'lodash-es';
import CreatableSelect, { CreatableSelectProps } from 'platform/common/components/Select/CreatableSelect';
import { OptionType } from 'platform/common/components/Select/select.types';
import { validator, Validator } from 'platform/common/utils/validators.util';

export type FormCreatableSelectProps<T = OptionType> = {
    name: string;
    isValidationMessageVisible?: boolean;
    validate?: Validator | Validator[];
    disabled?: boolean;
} & CreatableSelectProps<T>;

const FormCreatableSelect = <T extends OptionType>({
    className,
    options = [],
    name,
    validate,
    onBlur,
    onChange,
    isValidationMessageVisible = true,
    returnOnlyValues = true,
    createOptionPosition = 'first',
    ...rest
}: FormCreatableSelectProps<T>) => {
    const [field, meta, helpers] = useField({ name, validate: validate && validator(validate) });
    const invalid = Boolean(meta.touched && meta.error);

    const feedback = invalid && isString(meta.error) && isValidationMessageVisible && (
        <FormFeedback className="d-block">{meta.error}</FormFeedback>
    );

    return (
        <>
            <CreatableSelect
                className={classNames('flex-nowrap', className)}
                value={field.value || options.find((option) => !!option.default)}
                options={options}
                returnOnlyValues={returnOnlyValues}
                createOptionPosition={createOptionPosition}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    helpers.setTouched(true);
                    onBlur?.(e);
                }}
                onChange={(value) => {
                    helpers.setValue(value);
                    if (onChange) onChange(value);
                }}
                {...rest}
            />
            {feedback}
        </>
    );
};

export default FormCreatableSelect;
