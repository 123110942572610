import { useState } from 'react';
import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import ChatAgentPlaceholdersList from 'platform/chat/admin/AdminChatAgentList/ChatAgentPlaceholdersList';
import CodeMirrorSrc from 'platform/common/components/CodeMirror/CodeMirrorSource';
import FormRow from 'platform/common/components/FormRow/FormRow';

const tryParseJSON = (val: string) => {
    try {
        return JSON.parse(val);
    } catch {
        return undefined;
    }
};

const validate = (val: any) =>
    val && typeof val === 'object' && !Array.isArray(val) ? undefined : 'must be valid JSON object';

const settingsToMessages = (settings: string): string[] => {
    try {
        return JSON.parse(settings).messages.map((msg: any) => msg.content);
    } catch {
        return [];
    }
};

const ChatValueField = () => {
    const [{ value }, { error }, { setValue }] = useField<object>({ name: 'settings', validate });
    const [input, setInput] = useState(JSON.stringify(value, null, 4));
    const messages = settingsToMessages(input);

    return (
        <FormRow label="JSON Value">
            <CodeMirrorSrc
                value={input}
                onChange={setInput}
                onFocusChange={() => setValue(tryParseJSON(input))}
                options={{ mode: 'javascript', lineWrapping: true }}
                className="form-control"
            />
            <FormFeedback className="d-block">{error}</FormFeedback>
            <ChatAgentPlaceholdersList messages={messages} />
        </FormRow>
    );
};

export default ChatValueField;
