import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';

export type Props = {
    addOnTitle?: string;
    buttonTitle: string;
    placeholder?: string;
    onClick: (value: string) => string | void;
    inputProps?: {
        onFocus?: () => any;
        onBlur?: () => any;
        disabled?: boolean;
    };
    value?: string;
    isButtonDisabled?: boolean;
    className?: string;
    invalid?: boolean;
};

const InputButton = ({
    addOnTitle,
    placeholder,
    buttonTitle,
    inputProps,
    value = '',
    onClick,
    isButtonDisabled,
    className,
}: Props) => {
    const [inputValue, setInputValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const onChange = () => {
        if (inputRef && inputRef.current) {
            if (!onClick(inputValue)) setInputValue(value);
            inputRef.current.focus();
        }
    };

    return (
        <InputGroup className={className}>
            {addOnTitle && <InputGroupText className="pb-0 pt-0">{addOnTitle}</InputGroupText>}
            <Input
                {...inputProps}
                type="text"
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key !== 'Enter') {
                        return;
                    }

                    e.stopPropagation();
                    e.preventDefault();
                    onChange();
                }}
                value={inputValue}
                placeholder={placeholder}
                onChange={(e) => setInputValue(e.target.value)}
                innerRef={inputRef}
            />
            <Button size="md" color="primary" onClick={onChange} disabled={isButtonDisabled}>
                {buttonTitle}
            </Button>
        </InputGroup>
    );
};

export default InputButton;
