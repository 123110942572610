import { useParams } from 'react-router';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import ObserverForm from './ObserverForm/ObserverForm';
import { toCreateUpdatePayload, toFormModel } from './observer.mapper';
import { getObserverReport, updateObserverReport } from './observer.service';
import { ObserverFormModel } from './observer.types';

const ObserverEdit = ({ canEdit, afterSubmit, redirectTo }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const onOpen = (): Promise<ObserverFormModel> => getObserverReport(id).then((res) => toFormModel(res));

    const onSubmit = (formValues: ObserverFormModel) =>
        updateObserverReport(id, toCreateUpdatePayload(formValues));

    return (
        <FormContainer
            helpKey="observer_form"
            onSubmit={onSubmit}
            onOpen={onOpen}
            canEdit={canEdit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            isEdit
            sidePanel
        >
            {(props) => <ObserverForm {...props} />}
        </FormContainer>
    );
};

export default ObserverEdit;
