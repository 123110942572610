import { pickBy } from 'lodash-es';
import { Adserver, INTEGRATIONS_INFO } from 'platform/common/constants/externalIntegration.constant';
import { Vendor, VendorFormModel } from 'platform/vendors/vendors.types';

export const getVendorName = (externalSystem: string, vendors: Vendor[]) =>
    INTEGRATIONS_INFO[externalSystem]?.label ??
    vendors.find((c) => c.externalSystem === externalSystem)?.name ??
    externalSystem;

const tokenParametersToApi = (tokenParameters: string[]) =>
    tokenParameters.reduce((acc, param) => {
        const [key, value] = param.split('=');
        return {
            ...acc,
            [key.trim()]: value.trim(),
        };
    }, {});

export const vendorFormToApi = (model: VendorFormModel): Vendor => {
    const { adservers, integrations, ...vendor } = model;
    return {
        ...vendor,
        integrations: pickBy(integrations, (val, key) => adservers[key as Adserver].enabled),
        tokenParameters: tokenParametersToApi(model.tokenParameters),
    };
};
