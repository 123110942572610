import { AnalyticsMetadata } from 'platform/analytics/analytics.types';

const SET_META = 'SET_META';

type SetMetaAction = {
    type: typeof SET_META;
    payload: AnalyticsMetadata;
};

type AnalyticsMetaActions = SetMetaAction;

const defaultState: AnalyticsMetadata = {
    templates: [],
    definitions: {
        dimensions: [],
        metrics: [],
        filters: [],
        columnsWithData: [],
    },
    columnGroups: [],
    defaultMetrics: null,
    hasDefaultMetrics: false,
    mediaPlans: [],
    mediaInsertions: [],
};

const analyticsMetadataReducer = (state = defaultState, action: AnalyticsMetaActions): AnalyticsMetadata => {
    switch (action.type) {
        case SET_META:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const analyticsMetadataActions = {
    setMeta: (payload: AnalyticsMetadata): SetMetaAction => ({ type: SET_META, payload }),
};

export default analyticsMetadataReducer;
