import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { times } from 'lodash-es';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { fetchCdpIntegrationsData } from 'platform/dashboard/dashboard.service';
import { isVendorActiveInAdvertiserTag } from 'platform/segments/utils/segmentSyncPanel.util';
import VendorLabel from 'platform/vendors/components/VendorLabel';
import { Vendor } from 'platform/vendors/vendors.types';

interface Props {
    advertiserId: number;
}

const IntegrationsCard = ({ advertiserId }: Props) => {
    const [{ data, loading }] = usePromise(undefined, () => fetchCdpIntegrationsData(advertiserId), [
        advertiserId,
    ]);
    const vendors = useSelector(classifierSelectors.vendors);

    const isVendorActive = (vendor: Vendor) =>
        data?.advertiser.externalSystemIntegrations.find((i) => i.externalSystem === vendor.externalSystem)
            ?.enabled || isVendorActiveInAdvertiserTag(vendor.id!, data?.advertiser.activeAdvertiserTag);

    return (
        <Card>
            <CardBody className="p-3">
                <h5 className="mb-3">Integrations</h5>
                {loading && (
                    <Row>
                        {times(3).map((index) => (
                            <Col key={index} md={6} lg={4}>
                                <Placeholder height="80px" />
                            </Col>
                        ))}
                    </Row>
                )}
                {!loading && (
                    <Row>
                        {vendors
                            .filter((vendor) => vendor.useInCDP)
                            .map((vendor) => {
                                const active = isVendorActive(vendor);
                                return (
                                    <Col key={vendor.externalSystem} md={6} lg={4}>
                                        <div
                                            className={classNames(
                                                'border p-3 mb-3 d-flex align-items-center',
                                                {
                                                    'bg-secondary': !active,
                                                }
                                            )}
                                        >
                                            <b>
                                                <VendorLabel vendorCode={vendor.externalSystem} />
                                            </b>
                                            <div className="ms-auto">
                                                {active ? (
                                                    <div className="d-flex align-items-center text-success">
                                                        <div>Active</div>
                                                        <i className="fal fa-lg fa-check-circle ms-2" />
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center text-muted">
                                                        <div>Inactive</div>
                                                        <i className="fal fa-lg fa-ban ms-2" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                    </Row>
                )}
            </CardBody>
        </Card>
    );
};

export default IntegrationsCard;
