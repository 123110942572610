import { useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { Formik } from 'formik';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import {
    getCustomMetric,
    storeCustomMetric,
} from 'platform/campaign/advertiserManagement/CustomMetrics/customMetric.service';
import { CUSTOM_METRIC_KEYS } from 'platform/campaign/advertiserManagement/CustomMetrics/customMetric.types';
import {
    createLabelOverride,
    fetchLabelOverrides,
    updateLabelOverride,
} from 'platform/campaign/advertiserManagement/LabelOverrides/labelOverrides.service';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Popover from 'platform/common/components/Popover/Popover';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useLoading } from 'platform/common/hooks/useLoading';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

interface Props {
    columnKey: string;
    onToggle: (open: boolean) => void;
}

const LabelRenamePopover = ({ columnKey, onToggle }: Props) => {
    const [loading, withLoading] = useLoading();
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const { definitions, reloadAnalyticsMetadata } = useAnalyticsMetadata();
    const { dimensions, metrics } = definitions;

    const currentName = [...dimensions, ...metrics].find((c) => c.key === columnKey)?.name ?? '';

    const handleLabelRename = async (newName: string) => {
        if (CUSTOM_METRIC_KEYS.includes(columnKey)) {
            const customMetric = await getCustomMetric(advertiserId, columnKey);
            await storeCustomMetric(advertiserId, { ...customMetric, name: newName });
        } else {
            const labelOverride = (await fetchLabelOverrides(advertiserId)).find(
                (o) => o.systemName === columnKey
            );
            await (labelOverride?.id
                ? updateLabelOverride(labelOverride.id, { ...labelOverride, alias: newName })
                : createLabelOverride({
                      advertiserId,
                      type: dimensions.find((d) => d.key === columnKey) ? 'DIMENSION' : 'METRIC',
                      systemName: columnKey,
                      alias: newName,
                  }));
        }
        await reloadAnalyticsMetadata(advertiserId);
        onToggle(false);
    };

    return (
        <Popover
            placement="bottom-end"
            isOpen
            preventOverflow
            onToggle={onToggle}
            renderPopover={() => (
                <Formik
                    initialValues={{ name: currentName }}
                    onSubmit={({ name }) => withLoading(() => handleLabelRename(name))}
                >
                    {({ handleSubmit, dirty }) => (
                        <Form style={{ width: 400 }} onSubmit={handleSubmit}>
                            {loading && <OverlayLoader />}
                            <FormRow label="Label">
                                <FormInput name="name" validate={required} />
                            </FormRow>
                            <div className="d-flex justify-content-end">
                                <Button color="secondary" className="me-2" onClick={() => onToggle(false)}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={!dirty}>
                                    Apply
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        />
    );
};

export default LabelRenamePopover;
