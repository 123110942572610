import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import LabelOverridesForm, { LabelOverrideModel, LabelOverrideProps } from './LabelOverridesForm';
import { createLabelOverride } from './labelOverrides.service';

const LabelOverridesCreate = ({
    canEdit,
    redirectTo,
    afterSubmit,
    advertiserId,
    metrics,
    dimensions,
    keyTyMetricName,
    keyToDimensionName,
}: FormContainerProps & LabelOverrideProps) => {
    const onOpen = (): Promise<LabelOverrideModel> =>
        Promise.resolve({
            advertiserId,
            metrics,
            dimensions,
            keyTyMetricName,
            keyToDimensionName,
            systemName: '',
            alias: '',
            type: 'METRIC',
        });

    const onSubmit = (model: LabelOverrideModel) => createLabelOverride(model);

    return (
        <FormContainer
            helpKey="label_overrides_form"
            onSubmit={onSubmit}
            onOpen={onOpen}
            onSubmitFinish={afterSubmit}
            canEdit={canEdit}
            redirectTo={redirectTo}
            isEdit={false}
            sidePanel
        >
            {(props) => <LabelOverridesForm {...props} />}
        </FormContainer>
    );
};

export default LabelOverridesCreate;
