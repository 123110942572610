import { Form } from 'reactstrap';
import AdditionalAttributes from 'platform/advertisers/components/AdditionalAttributes/AdditionalAttributes';
import FloatingButton from 'platform/common/components/FloatingButton/FloatingButton';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';

interface FormModel {
    additionalAttributes: Record<string, string> | null;
}

const AdvertiserAdvancedSettingsForm = ({
    labels,
    formikProps: { setFieldValue, submitForm, values },
    loading,
}: FormProps<FormModel> & { loading: boolean }) => (
    <Form>
        <AdditionalAttributes
            field="additionalAttributes"
            onChange={setFieldValue}
            additionalAttributes={values.additionalAttributes ?? {}}
        />
        <FloatingButton disabled={loading} onClick={submitForm} icon="far fa-pen-to-square">
            {labels.submit}
        </FloatingButton>
    </Form>
);

export default AdvertiserAdvancedSettingsForm;
