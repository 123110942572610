import { ObserverMessageCategory, ObserverMessageType } from 'platform/observer/observer.types';
import { ObserverMessageStatus } from './observerMessageCenter.types';

export const MESSAGE_CENTER_STATUS_LABELS: Record<ObserverMessageStatus, string> = {
    NEW: 'New',
    IN_PROGRESS: 'In Progress',
    RESOLVED: 'Resolved',
    EXPIRED: 'Expired',
};

export const MESSAGE_CENTER_STATUS_ICONS: Record<ObserverMessageStatus, string> = {
    NEW: 'fal fa-circle-dot',
    IN_PROGRESS: 'fal fa-clock',
    RESOLVED: 'fal fa-circle-check',
    EXPIRED: 'fal fa-hourglass-end',
};

export const MESSAGE_CENTER_TYPE_LABELS: Record<ObserverMessageType, string> = {
    INFO: 'Info',
    WARNING: 'Warning',
    SUGGESTION: 'Suggestion',
};

export const MESSAGE_CENTER_CATEGORY_LABELS: Record<ObserverMessageCategory, string> = {
    BUDGET: 'Budget',
    BIDDING: 'Bidding',
    TRACKING: 'Tracking',
};
