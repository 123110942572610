import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { ErrorDetails } from 'platform/common/error.types';
import { isLocalhost, isProduction } from 'platform/common/utils/environment.util';
import AdBlockerWarning from './AdBlockerWarning';
import { sendErrorReport } from './errorReporting.service';
import { useAdBlockerDetection } from './useAdBlockerDetection';

interface Props {
    error: ErrorDetails;
    pageUrl?: string;
    autoSend?: boolean;
}

const ErrorReporting = ({ error, pageUrl, autoSend }: Props) => {
    const activeAdvertisers = useSelector(activeAdvertiserSelectors.activeAdvertisers);
    const adBlockerStatus = useAdBlockerDetection();
    const [wasSent, setWasSent] = useState(false);
    const isAdmin = useSelector(authSelectors.isAdmin);
    const isAutoSend = autoSend && !isAdmin;

    const canSend = useCallback(
        () => error.message && adBlockerStatus === 'ok' && !(isLocalhost() && isProduction()),
        [adBlockerStatus]
    );

    const send = async () => {
        await sendErrorReport({
            error: [error.message, error.detailedMessage].filter(Boolean).join('; '),
            url: pageUrl || window.location.href,
            userAgent: window.navigator.userAgent,
            advertisers: activeAdvertisers.map((a) => ({ id: a.id, name: a.name })),
            traceId: error.traceId,
            request: error.request,
            response: error.response,
        });
        setWasSent(true);
    };

    useEffect(() => {
        if (isAutoSend && canSend()) {
            send();
        }
    }, [canSend]);

    return (
        <>
            {adBlockerStatus === 'detected' && <AdBlockerWarning />}
            {!isAutoSend && canSend() && !wasSent && (
                <AnimatedButton className="btn-secondary my-2 me-2" onClick={send}>
                    Report this error
                </AnimatedButton>
            )}
            {wasSent && <div className="my-2">Issue was reported to our developers.</div>}
        </>
    );
};

export default ErrorReporting;
