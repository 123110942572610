import { NavigationItem } from 'platform/app/app.types';
import { ReactComponent as AI_BRAIN } from 'platform/assets/icons/AI_BRAIN.svg';

export const CHAT_ADMIN_PANEL: NavigationItem = {
    name: 'DeeplyAI',
    path: '/deeply-panel',
    redirectTo: '/deeply-panel/agents',
    icon: AI_BRAIN,
    requiresFeature: 'CHATBOT_MANAGEMENT_VIEW',
    admin: true,
};
