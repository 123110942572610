import { ReactNode } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import classNames from 'classnames';
import useToggle from 'platform/common/hooks/useToggle';

interface Props {
    name: string;
    children: ReactNode;
}

const EventTrackingListSection = ({ name, children }: Props) => {
    const [expanded, toggleExpanded] = useToggle(true);

    return (
        <div>
            <Row className="EventTrackingList-groupRow">
                <Col md={1}>
                    <i
                        tabIndex={0}
                        role="button"
                        onClick={() => toggleExpanded()}
                        className={classNames(
                            'EventTrackingList-expandArrow',
                            expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
                        )}
                    />
                </Col>
                <Col md={11} className="EventTrackingList-headerTitle">
                    {name}
                </Col>
            </Row>
            <Collapse isOpen={expanded}>{children}</Collapse>
        </div>
    );
};

export default EventTrackingListSection;
