import { CSSProperties } from 'react';
import IconButton from 'platform/common/components/IconButton/IconButton';
import ChatBtn from './ChatBtn';

export const CHAT_BTN_STYLE: CSSProperties = {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 1050,
    width: 52,
};

interface Props {
    isChatOpen: boolean;
    positionX: number;
    onClick: () => void;
}

const GlobalChatBtn = ({ isChatOpen, positionX, onClick }: Props) => {
    if (isChatOpen) {
        return (
            <IconButton
                color="primary"
                onClick={onClick}
                style={{
                    ...CHAT_BTN_STYLE,
                    height: 52,
                    left: positionX || undefined,
                    right: positionX === 0 ? CHAT_BTN_STYLE.right : undefined,
                }}
                className="bg-primary rounded-circle"
            >
                <i className="fa-solid fa-xmark text-white fs-4" />
            </IconButton>
        );
    }

    return (
        <ChatBtn
            onClick={onClick}
            style={{
                ...CHAT_BTN_STYLE,
                left: positionX || undefined,
                right: positionX === 0 ? CHAT_BTN_STYLE.right : undefined,
            }}
        />
    );
};

export default GlobalChatBtn;
