import classNames from 'classnames';
import './LabelIcon.scss';

interface Props {
    label: string;
    icon: string;
    labelPosition: string;
    className?: string;
    iconClassName?: string;
    onIconClick?: () => void;
}

const LabelIcon = ({ label, icon, labelPosition = 'left', iconClassName, className, onIconClick }: Props) => (
    <div className={classNames('LabelIcon', className)}>
        {labelPosition === 'left' && <span className="LabelIcon-label--left">{label}</span>}
        <img
            role="presentation"
            className={classNames('LabelIcon-icon', iconClassName)}
            src={icon}
            alt={label}
            onClick={onIconClick}
        />
        {labelPosition === 'right' && <span className="LabelIcon-label--right">{label}</span>}
    </div>
);

export default LabelIcon;
