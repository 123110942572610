import { Label } from 'reactstrap';
import { useFormikContext } from 'formik';
import { IntegrationType } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import Switch from 'platform/common/components/Switch/Switch';
import { Adserver } from 'platform/common/constants/externalIntegration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByName } from 'platform/common/utils/array.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { fetchVendors } from 'platform/vendors/vendors.service';
import { Vendor } from 'platform/vendors/vendors.types';
import { CampaignModel } from '../../campaign.types';

export const campaignMappingLink =
    'https://deepmedia.atlassian.net/wiki/spaces/PT/pages/3343482894/Mapping+campaigns+with+DAP+new';

type Props = {
    campaign: CampaignModel;
};

const findSuitableVendors = (adserver: Adserver, type?: IntegrationType) =>
    fetchVendors().then((vendors) =>
        vendors.filter(
            (vendor) =>
                vendor.status === 'ACTIVE' &&
                (type === 'TRADITIONAL' || Object.keys(vendor.integrations ?? {}).includes(adserver))
        )
    );

const CampaignFormVendor = ({
    campaign: { id, type, redirectVendorId, redirectExternalSystem, adserver },
}: Props) => {
    const [{ data: vendorOptions }] = usePromise<Vendor[]>(
        [],
        () => findSuitableVendors(adserver, type).then(sortByName),
        [adserver, type]
    );
    const { setFieldValue } = useFormikContext<CampaignModel>();

    const vendor = redirectVendorId ? vendorOptions.find((v) => v.id === redirectVendorId) : undefined;

    const setExternalSystem = (system?: string) => {
        setFieldValue('redirectExternalSystem', system || null);
        if (!system) {
            setFieldValue('redirectExternalId', null);
        }
    };

    const supportsIdMapping = (system?: string) =>
        system === 'BING' || system === 'TABOOLA' || system === 'TIKTOK' || system === 'GOOGLE';

    return (
        <>
            <FormHelp iconClassName="mt-2">
                <FormSelect
                    firstAddon="Vendor"
                    className="w-100"
                    containerClassName="flex-grow-1"
                    name="redirectVendorId"
                    isClearable={false}
                    valueKey="id"
                    labelKey="name"
                    options={vendorOptions}
                    validate={required}
                    disabled={!!id}
                    onChange={(newVendorId) => {
                        const newVendor = vendorOptions.find((v) => v.id === Number(newVendorId)) as Vendor;
                        setFieldValue('redirectVendorId', newVendorId);
                        setFieldValue('vendorSystem', newVendor.externalSystem);
                    }}
                />
            </FormHelp>
            {type === 'REDIRECT' && vendor && supportsIdMapping(vendor?.externalSystem) && (
                <div className="mb-3 mt-3">
                    <FormHelp className="mb-2" helpKey="redirectIntegrationEnabled">
                        <Label>Map with external campaign</Label>
                        <Switch
                            id="redirectIntegrationEnabled"
                            className="ms-2"
                            input={{
                                value: !!redirectExternalSystem,
                                onChange: ({ target }) =>
                                    setExternalSystem(target.checked ? vendor?.externalSystem : undefined),
                            }}
                        />
                    </FormHelp>

                    {redirectExternalSystem && (
                        <FormHelp className="d-flex align-items-center">
                            <FormInput
                                leftAddOn={{ title: `${vendor?.name} ID` }}
                                rightAddOn={{
                                    className: 'fa fa-external-link-alt text-muted',
                                    onClick: () =>
                                        window.open(campaignMappingLink, '_blank', 'noopener,noreferrer'),
                                }}
                                name="redirectExternalId"
                                validate={required}
                            />
                        </FormHelp>
                    )}
                </div>
            )}
        </>
    );
};

export default CampaignFormVendor;
