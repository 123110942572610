import { useState } from 'react';
import { Alert, Button, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { at, capitalize, isEmpty } from 'lodash-es';
import CardForm from 'platform/common/components/CardForm/CardForm';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { ErrorPayload, LegacyErrorPayload, parseErrorMessages } from 'platform/common/utils/errorMessages';
import ConceptGeneralTab from 'platform/creatives/components/CreativeForm/ConceptGeneralTab';
import { ConceptModel, CreativeModel } from '../../types/creative.types';
import ConceptCreativesTab from './ConceptCreativesTab';
import './CreativeForm.scss';

const CREATIVE_FORM_TABS = ['Creative concept', 'Creatives'];

const CreativeErrorMessage = ({
    field,
    requestPayload,
    message,
    traceId,
}: {
    field?: string;
    requestPayload: any;
    message: string;
    traceId?: string;
}) => {
    if (field) {
        const [creativePath, fieldName] = field.split('.');
        const [creative] = at(requestPayload, creativePath);
        return (
            <Alert color="danger">
                {creative ? (
                    <>
                        <div>
                            {creative.name
                                ? `Creative: ${creative.name}`
                                : `Creative index: ${requestPayload.creatives.indexOf(creative) + 1}`}
                        </div>
                        {fieldName && <div>Field: {fieldName}</div>}
                        <div>Message: {message}</div>
                        {traceId && <div>TraceId: {traceId}</div>}
                    </>
                ) : (
                    traceId &&
                    message && (
                        <>
                            <div>Message: {message}</div>
                            <div>TraceId: {traceId}</div>
                        </>
                    )
                )}
            </Alert>
        );
    }
    return (
        <Alert color="danger">
            <>
                {message}
                {traceId && <div>TraceId: {traceId}</div>}
            </>
        </Alert>
    );
};

type Props = FormProps<ConceptModel> & {
    backendErrors: string | ErrorPayload | LegacyErrorPayload | undefined;
    requestPayload: any;
    traceId: string | undefined;
    missingCMIntegration?: boolean;
};

const CreativeForm = ({
    missingCMIntegration,
    requestPayload,
    traceId,
    backendErrors,
    onCancel,
    labels,
    isEdit,
    canEdit,
    formikProps: { initialValues, submitForm, errors, submitCount },
}: Props) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    return (
        <CardForm
            title={`${isEdit ? 'Edit' : 'New'} ${capitalize(initialValues.type)} Creative`}
            subtitle={isEdit && `ID: ${initialValues.id}`}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit || Boolean(initialValues.isDemoMode)}
            onSubmit={submitForm}
        >
            {missingCMIntegration && (
                <Alert color="warning">Please set up CM360 integration for this advertiser.</Alert>
            )}

            {!isEmpty(errors) && submitCount > 0 && (
                <Alert color="danger">
                    <ul className="mb-0">
                        {errors.name && <li>Concept details are invalid</li>}
                        {!!(errors.creatives as CreativeModel[] | undefined)?.filter(
                            (creative) => !isEmpty(creative)
                        ).length && <li>Creatives are invalid</li>}
                    </ul>
                </Alert>
            )}

            {backendErrors &&
                parseErrorMessages(backendErrors).map((e) => (
                    <CreativeErrorMessage
                        key={e.field}
                        message={e.message}
                        field={e.field}
                        requestPayload={requestPayload}
                        traceId={traceId}
                    />
                ))}

            <div className="mb-3 custom-tabs">
                <Nav tabs>
                    {CREATIVE_FORM_TABS.map((label, tabIndex) => (
                        <NavItem key={label}>
                            <NavLink
                                onClick={() => setActiveTab(tabIndex)}
                                className={classNames({ active: tabIndex === activeTab })}
                            >
                                {label}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>

            {activeTab === 0 && (
                <>
                    <ConceptGeneralTab concept={initialValues} />
                    <div className="d-flex justify-content-end">
                        <Button color="primary" onClick={() => setActiveTab(1)}>
                            Next
                        </Button>
                    </div>
                </>
            )}
            {activeTab === 1 && <ConceptCreativesTab />}
        </CardForm>
    );
};

export default CreativeForm;
