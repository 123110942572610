import { STATUS_LABELS } from 'platform/common/constants/status.constant';
import CreativeIntegrationIcon from 'platform/creatives/components/CreativeIntegrations/CreativeIntegrationIcon';
import { Concept, Creative } from 'platform/creatives/types/creative.types';

type Props = {
    creative: Creative;
    concept?: Concept;
};

const CreativeDisplayOption = ({ creative, concept }: Props) => {
    let displayText = creative.name;
    if (concept && concept.state !== 'ACTIVE') {
        displayText += ` [${STATUS_LABELS[concept.state]}]`;
    }
    return (
        <div className="d-flex w-100">
            <div className="me-auto">{displayText}</div>
            <div className="ms-auto">
                {creative.integration && (
                    <CreativeIntegrationIcon id={creative.id} integration={creative.integration} />
                )}
            </div>
        </div>
    );
};

export default CreativeDisplayOption;
