import { Form } from 'reactstrap';
import { Formik } from 'formik';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { useLoading } from 'platform/common/hooks/useLoading';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import { TaskLaneData } from '../task.types';
import KanbanColumnFormButtons from './KanbanColumnFormButtons';

type Props = {
    onSubmit: (data: TaskLaneData) => Promise<void>;
    onCancel: () => void;
};

const KanbanColumnAdder = ({ onSubmit, onCancel }: Props) => {
    const [saving, withSaving] = useLoading();

    return (
        <Formik<TaskLaneData>
            initialValues={{ name: '' }}
            onSubmit={(data) => withSaving(() => onSubmit(data))}
        >
            {({ handleSubmit }) => (
                <Form className="Kanban-column-adder" onSubmit={handleSubmit}>
                    {saving && <OverlayLoader />}
                    <FormInput placeholder="Column name" name="name" autoFocus validate={required} />
                    <div className="mt-4">
                        <KanbanColumnFormButtons onCancel={onCancel} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default KanbanColumnAdder;
