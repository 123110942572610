import { CSSProperties } from 'react';
import TripleChevronIcon from 'platform/assets/icons/journey/TripleChevronIcon';
import { DurationInterval } from '../../customerJourney.utils';

interface Props {
    durationText: string;
    durationInterval: DurationInterval;
    durationTextStyle?: CSSProperties;
}

const JourneyDurationInfo = ({ durationInterval, durationText, durationTextStyle }: Props) => {
    if (['DAYS', 'MINUTES', 'HOURS'].includes(durationInterval)) {
        return (
            <div style={durationTextStyle} className="text-light-slate-gray fw-semibold">
                {durationText}
            </div>
        );
    }

    return (
        <TripleChevronIcon
            className="text-light-slate-gray"
            style={{
                transform: 'rotate(270deg)',
            }}
        />
    );
};

export default JourneyDurationInfo;
