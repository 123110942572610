import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { push } from 'redux-first-history';
import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import { AnalyticsUrlSettings } from 'platform/analytics/analytics.types';
import { getSystemReportUrl } from 'platform/analytics/analytics.util';
import { useAnalyticsReports } from 'platform/analytics/hooks/useAnalyticsReports';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import campaignService from 'platform/campaign/campaign/services/campaign.service';
import { toFormModel } from 'platform/campaign/strategy/mappers/strategy.mapper';
import { deleteStrategy, getStrategy } from 'platform/campaign/strategy/services/strategy.service';
import { StrategyModel } from 'platform/campaign/strategy/strategy.types';
import UnitEditorForm from 'platform/campaign/unit/containers/UnitEditorForm/UnitEditorForm';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormNavigation from 'platform/common/components/FormNavigation/FormNavigation';
import { usePromise } from 'platform/common/hooks/usePromise';
import { getWizardSteps } from '../constants/strategyWizard.constant';
import StrategyCreativesForm from './CreativePresets/StrategyCreativesForm';
import StrategyFormContainer from './StrategyFormContainer';

const StrategyFormNavigation = () => {
    const location = useLocation();
    const params = useParams<{ id: string }>();
    const strategyId = Number(params.id);
    const returnLocationQuery = useSelector(authSelectors.returnLocationQuery);
    const { accessibleReports } = useAnalyticsReports();
    const dispatch = useDispatch();
    const showConfirmationModal = useConfirmationModal();

    const [{ data: strategy }, refetch] = usePromise<StrategyModel | undefined>(
        undefined,
        async () => {
            const strategyApiModel = await getStrategy(strategyId);
            const [advertiser, campaign] = await Promise.all([
                fetchAdvertiser(strategyApiModel.advertiserId!!),
                campaignService.getCampaign(strategyApiModel.campaignId!!),
            ]);
            return toFormModel(advertiser, campaign, strategyApiModel);
        },
        []
    );

    if (!strategy) {
        return null;
    }

    const getCampaignReportUrl = (settings?: AnalyticsUrlSettings, advertiserId?: number) =>
        getSystemReportUrl(accessibleReports, 'campaign_list', settings, advertiserId);

    const returnToAnalytics = () =>
        dispatch(
            push(
                returnLocationQuery && returnLocationQuery !== location.pathname
                    ? returnLocationQuery
                    : getCampaignReportUrl(
                          {
                              filters: [{ key: 'campaign_id', values: [strategy.campaign.id] }],
                              dimensions: ['campaign_name', 'vendor_code', 'strategy_name'],
                          },
                          strategy.advertiser.id
                      )
            )
        );

    const cancel = () => {
        if (strategy.state === 'DRAFT') {
            return showConfirmationModal(returnToAnalytics, {
                title: 'Discard progress',
                text: 'If you will choose to save as draft, changes made in this page will be lost.',
                okLabel: 'Save as draft',
                cancelLabel: 'Delete',
                onCancel: () => deleteStrategy(strategy.id).then(returnToAnalytics),
            });
        }
        return returnToAnalytics();
    };

    return (
        <FormNavigation entryId={strategyId} steps={getWizardSteps(strategy.campaign)} isExpandable>
            {({ navigateToNextStep }) => {
                const afterSubmit = async () => {
                    if (strategy.type === 'RTB') {
                        cancel();
                        return;
                    }
                    navigateToNextStep(strategyId);
                    await refetch();
                };
                return (
                    <Routes>
                        <Route
                            path="creatives"
                            element={
                                <StrategyCreativesForm
                                    strategy={strategy}
                                    afterSubmit={afterSubmit}
                                    onCancel={cancel}
                                />
                            }
                        />
                        <Route
                            path="units"
                            element={
                                <UnitEditorForm
                                    strategy={strategy}
                                    submitRedirectTo={getCampaignReportUrl({
                                        filters: [{ key: 'campaign_id', values: [strategy.campaign.id] }],
                                    })}
                                    onCancel={cancel}
                                />
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <StrategyFormContainer
                                    strategy={strategy}
                                    afterSubmit={afterSubmit}
                                    onCancel={cancel}
                                />
                            }
                        />
                    </Routes>
                );
            }}
        </FormNavigation>
    );
};

export default StrategyFormNavigation;
