import { useParams } from 'react-router-dom';
import { FormikErrors } from 'formik';
import { Feature } from 'platform/app/app.types';
import { isDefined } from 'platform/common/common.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import RoleForm from 'platform/userManagement/components/Roles/RoleForm';
import { featureName, MODULE_CONFIG_BY_FEATURE } from 'platform/userManagement/constants/roles.constant';
import { Role, RoleFormModel, RoleRequest } from 'platform/userManagement/types/role.type';
import { createRole, deleteRole, fetchRole, updateRole } from '../../services/userManagement.service';

export const NEW_ROLE: Role = {
    title: '',
    assignedUsers: [],
    features: [
        'CUSTOM_DASHBOARDS',
        'MEDIAPLAN_VIEW',
        'LOGBOOK_BUDGET_SHIFTING_VIEW',
        'LOGBOOK_OPTIMIZATION_VIEW',
        'SEGMENTS_VIEW',
        'ADVERTISER_TAG_VIEW',
        'ANALYTICS_VIEW',
        'ANALYTICS_EDIT',
        'CUSTOM_ANALYTICS',
        'CENTRAL_ANALYTICS',
        'ANALYTICS_FILTERS_EDIT',
        'TASK_EDIT',
        'SPEND_DATA',
    ],
    status: 'ACTIVE',
};

const toFormModel = (role: Role): RoleFormModel => ({
    ...role,
    channelAccess: role.accessibleChannels?.length ? 'CUSTOM' : 'ALL',
});

const toRequest = ({
    title,
    features,
    channelAccess,
    accessibleChannels,
    status,
}: RoleFormModel): RoleRequest => ({
    title,
    features,
    accessibleChannels: channelAccess === 'CUSTOM' ? accessibleChannels : [],
    status,
});

const missingDependencyError = (viewOrEdit: 'view' | 'edit', feature: Feature, features: Feature[]) => {
    const config = MODULE_CONFIG_BY_FEATURE[feature];
    if (config?.features[viewOrEdit] === feature) {
        const missingFeatures = config.dependencies?.[viewOrEdit]?.filter((dep) => !features.includes(dep));
        if (missingFeatures?.length) {
            return `${featureName(feature)} also requires ${missingFeatures.map(featureName).join(' and ')}`;
        }
    }
    return undefined;
};

const validateForm = ({ features }: Role): FormikErrors<Role> => {
    const dependencyErrors = features.flatMap((feature) =>
        [
            missingDependencyError('view', feature, features),
            missingDependencyError('edit', feature, features),
        ].filter(isDefined)
    );
    return dependencyErrors.length ? { features: `Missing permissions: ${dependencyErrors.join('. ')}` } : {};
};

const RoleFormContainer = ({ redirectTo, canEdit, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = params.id ? Number(params.id) : undefined;

    return (
        <FormContainer<RoleFormModel>
            helpKey="role_form"
            sidePanel
            canEdit={canEdit}
            isEdit={!!id}
            onOpen={async () => toFormModel(id ? await fetchRole(id) : NEW_ROLE)}
            onSubmit={(role) => (id ? updateRole(id, toRequest(role)) : createRole(toRequest(role)))}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            validate={validateForm}
        >
            {(props) => (
                <RoleForm
                    {...props}
                    onDelete={() => id && deleteRole(id).then(props.onCancel).then(afterSubmit)}
                />
            )}
        </FormContainer>
    );
};

export default RoleFormContainer;
