import { ChartProps, Doughnut } from 'react-chartjs-2';
import { defaultTo } from 'lodash-es';
import Placeholder from '../Placeholder/Placeholder';

type Props = {
    loading?: boolean;
};

const DoughnutWrapper = (props: Props & ChartProps<'doughnut'>) => {
    if (props.loading) {
        const height = `${defaultTo(props.height, 256)}px`;
        return <Placeholder height={height} />;
    }

    return <Doughnut {...props} />;
};

export default DoughnutWrapper;
