import { CSSProperties, useEffect, useRef } from 'react';
import { uniqueId } from 'lodash-es';
import ReactTableWithStickyColumns, { ReactTableProps } from './ReactTableWithStickyColumns';
import './ReactTableWithStickyHeader.scss';

const STUCK_CLASSNAME = 'stuck';

const getTransform = (translate: number | undefined) => (translate ? `translateY(${translate}px)` : 'none');

const updateHeadElement = (element: HTMLElement | null, translate: number | undefined, stuck: boolean) => {
    if (!element) {
        return;
    }

    if (stuck !== element.classList.contains(STUCK_CLASSNAME)) {
        element.classList.toggle(STUCK_CLASSNAME, stuck);
    }

    // eslint-disable-next-line no-param-reassign
    element.style.transform = getTransform(translate);
};

const ReactTableWithStickyHeader = (props: ReactTableProps) => {
    const tableId = uniqueId('FormattedTable');
    const theadId = uniqueId('FormattedTable-thead');
    const theadGroupId = uniqueId('FormattedTable-theadGroup');

    const navbarElement = useRef<HTMLElement | null>(null);
    const tableElement = useRef<HTMLElement | null>(null);
    const theadElement = useRef<HTMLElement | null>(null);
    const theadGroupElement = useRef<HTMLElement | null>(null);

    const isChildOfMain = () => tableElement?.current?.parentElement?.closest('.main') !== null;
    const isChildOfSidepanel = () => tableElement?.current?.parentElement?.closest('.SidePanel') !== null;
    const stuck = useRef(false);
    const translate = useRef<number>();

    const theadStyles: CSSProperties = {
        position: 'sticky',
        transform: getTransform(translate.current),
    };

    useEffect(() => {
        navbarElement.current = document.querySelector<HTMLElement>('.rt-sticky-thead-push');
        tableElement.current = document.getElementById(tableId);
        theadElement.current = document.getElementById(theadId);
        theadGroupElement.current = document.getElementById(theadGroupId);

        handleScroll();
        window.addEventListener('scroll', handleScroll, true);

        return () => window.removeEventListener('scroll', handleScroll, true);
    }, []);

    const getTableProps = () => ({ id: tableId });

    const getTheadProps = () => ({
        id: theadId,
        className: stuck.current ? STUCK_CLASSNAME : '',
        style: theadStyles,
    });

    const getTheadGroupProps = () => ({
        id: theadGroupId,
        className: stuck.current ? STUCK_CLASSNAME : '',
        style: theadStyles,
    });

    const handleScroll = (e?: Event) => {
        if (
            !theadElement.current ||
            !tableElement.current ||
            (e?.target instanceof Element && !e.target.contains(tableElement.current.parentElement))
        ) {
            return;
        }

        const navbarHeight = navbarElement.current?.offsetHeight || 0;
        const tablePositionToViewport = tableElement.current.getBoundingClientRect().top || 0;
        const scrollContainerDistanceToTop =
            e?.target instanceof Element ? e.target.getBoundingClientRect().top : navbarHeight;

        const isStuck = scrollContainerDistanceToTop > tablePositionToViewport;

        stuck.current = isStuck;
        const adjustToParent = isChildOfMain() && !isChildOfSidepanel() ? 30 : 0;
        translate.current = isStuck
            ? scrollContainerDistanceToTop - tablePositionToViewport - 1 + adjustToParent
            : 0;

        updateHeadElement(theadElement.current, translate.current, isStuck);
        updateHeadElement(theadGroupElement.current, translate.current, isStuck);
    };

    return (
        <ReactTableWithStickyColumns
            {...props}
            getTableProps={getTableProps}
            getTheadGroupProps={getTheadGroupProps}
            getTheadProps={getTheadProps}
        />
    );
};

export default ReactTableWithStickyHeader;
