import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import classNames from 'classnames';
import { ControlledCardChevron } from './ControlledCardChevron';
import './ControlledCard.scss';

export interface ControlledCardProps {
    title: ReactNode;
    children: ReactNode;
    headerChildren?: ReactNode;
    subtitle?: string | string[];
    className?: string;
    headerClassName?: string;
    bodyClassName?: string;
    headerIcon?: ReactNode;
    color?: string;
    id?: string;
    isExpanded?: boolean;
    toggleable?: boolean;
    renderCollapsedView?: () => ReactNode;
    style?: CSSProperties;
    onRemove?: () => void;
    onToggle?: (isExpanded: boolean) => void;
    useCollapse?: boolean;
    reverseChevron?: boolean;
    hideControl?: boolean;
}

const ControlledCard = ({
    title,
    children,
    headerChildren,
    subtitle,
    className = '',
    headerClassName = '',
    bodyClassName,
    headerIcon,
    color,
    id,
    style,
    isExpanded = true,
    toggleable = true,
    useCollapse = true,
    renderCollapsedView,
    onRemove,
    onToggle,
    reverseChevron,
    hideControl = false,
}: ControlledCardProps) => {
    const [isExpandedInternal, setIsExpandedInternal] = useState(isExpanded);

    useEffect(() => {
        setIsExpandedInternal(isExpanded);
    }, [isExpanded]);

    const toggle = () => {
        const newValue = !isExpandedInternal;
        setIsExpandedInternal(newValue);
        if (onToggle) {
            onToggle(newValue);
        }
    };

    const cardBodyNode = <CardBody className={bodyClassName}>{children}</CardBody>;

    return (
        <Card className={classNames('ControlledCard', className)} color={color} id={id} style={style}>
            <CardHeader className={headerClassName}>
                {headerIcon}
                {!hideControl && reverseChevron && (
                    <div className="card-actions-reverse">
                        <ControlledCardChevron
                            toggleable={toggleable}
                            toggle={toggle}
                            isExpandedInternal={isExpandedInternal}
                        />
                    </div>
                )}
                <span
                    role="button"
                    tabIndex={0}
                    onClick={toggleable ? toggle : () => {}}
                    className="ControlledCard-title"
                >
                    {title}
                </span>
                {subtitle && <span className="ControlledCard-subtitle">{subtitle}</span>}
                {headerChildren}
                <div className="card-actions">
                    {onRemove && (
                        <button type="button" onClick={onRemove}>
                            <i className="fa fa-times" />
                        </button>
                    )}
                    {!hideControl && !reverseChevron && (
                        <ControlledCardChevron
                            toggleable={toggleable}
                            toggle={toggle}
                            isExpandedInternal={isExpandedInternal}
                        />
                    )}
                </div>
            </CardHeader>
            {useCollapse ? (
                <Collapse isOpen={isExpandedInternal}>{cardBodyNode}</Collapse>
            ) : (
                isExpandedInternal && cardBodyNode
            )}
            {!isExpandedInternal && renderCollapsedView && (
                <CardBody className={bodyClassName}>{renderCollapsedView()}</CardBody>
            )}
        </Card>
    );
};

export default ControlledCard;
