import DspIntegrationIcon from '../../../common/components/DspIntegrationIcon/DspIntegrationIcon';
import { Advertiser } from '../../advertiser.types';

interface Props {
    advertiser: Advertiser;
    onResync?: (advertiserId: number, externalSystem: string) => any;
}

const AdvertiserIntegrations = ({ advertiser, onResync }: Props) => (
    <>
        {advertiser.externalSystemIntegrations
            .filter((integration) => integration.enabled || integration.accounts?.length)
            .slice(0)
            .sort((a, b) => a.externalSystem.localeCompare(b.externalSystem))
            .map((integration) => (
                <DspIntegrationIcon
                    key={integration.externalSystem}
                    systemName={integration.externalSystem}
                    enabled={integration.enabled}
                    internalId={advertiser.id}
                    externalId={integration.accounts?.[0]?.externalId}
                    syncEntityType={'ADVERTISER'}
                    onResync={onResync}
                />
            ))}
    </>
);

export default AdvertiserIntegrations;
