import ButtonDropdown, {
    ButtonDropdownOption,
} from 'platform/common/components/ButtonDropdown/ButtonDropdown';

interface Props {
    label: string;
    options: ButtonDropdownOption[];
    container?: string | HTMLElement | React.RefObject<HTMLElement>;
}

const AddItemButton = ({ label, options, container }: Props) => (
    <ButtonDropdown
        items={options}
        container={container || document.body}
        buttonClassName="p-0 bg-transparent shadow-none border-0 text-decoration-none"
        color="link"
        caret={false}
        isSearchable
    >
        <div className="d-flex align-items-center">
            <i className="fa fa-plus-circle me-2" style={{ fontSize: '2rem' }} />
            <span className="text-nowrap">{label}</span>
        </div>
    </ButtonDropdown>
);

export default AddItemButton;
