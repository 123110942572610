export const LOGIN = {
    name: 'Login Page',
    path: '/login',
};

export const PROFILE = {
    name: 'Profile',
    path: '/profile',
};

export const UNDER_CONSTRUCTION = {
    name: 'Under construction',
    path: '/under-construction',
};

export const CHAT = {
    name: 'DeeplyAI',
    path: '/deeply',
};

export const SET_GOOGLE_CREDENTIALS_PATH = '/set-google-credentials';

export const GOOGLE_TOKEN_RETRIEVAL_PATH = '/google-token-retrieval';
