import { FormEvent } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { pick, sortBy } from 'lodash-es';
import { FlightLabel } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.types';
import { Product } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.types';
import { Campaign } from 'platform/campaign/campaign/campaign.types';
import { CampaignClassifications } from 'platform/campaign/campaign/campaignClassification.types';
import { CAMPAIGN_CLASSIFICATION_ORDER } from 'platform/campaign/campaign/constants/campaignClassification.constant';
import { isDefined, SelectItem } from 'platform/common/common.types';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { DATE_RANGES, DateRangePreset } from 'platform/common/utils/date.util';
import { required } from 'platform/common/utils/validators.util';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';
import LogBookPopover from 'platform/mediaplan/components/LogBookPopover';
import IoIcon from 'platform/mediaplan/components/MediaplanTreeForm/IoIcon';
import MediaInsertionBudget from 'platform/mediaplan/components/MediaplanTreeForm/MediaInsertionBudget';
import { MediaInsertionFormModel } from 'platform/mediaplan/mediaplan.types';
import { getTrackedChanges, toClassificationOptions } from 'platform/mediaplan/mediaplan.utils';
import { Vendor } from 'platform/vendors/vendors.types';
import MediaInsertionCampaignLinks from './MediaInsertionCampaignLinks';
import MediaInsertionNotes from './MediaInsertionNotes';
import MediaInsertionPricingAndVolume from './MediaInsertionPricingAndVolume';
import MediaInsertionTargetKpi from './MediaInsertionTargetKpi';
import '../Mediaplan.scss';
import './MediaNode.scss';

interface Props {
    field: string;
    products: Product[];
    flightLabels: FlightLabel[];
    campaigns: Campaign[];
    insertion: MediaInsertionFormModel;
    initial?: MediaInsertionFormModel;
    vendors: Vendor[];
    onChange: (key: string, value: any) => void;
}

const sortClassifications = (options: SelectItem[]) =>
    sortBy(options, (c) => CAMPAIGN_CLASSIFICATION_ORDER.indexOf(c.value));

const toVendorSystemValue = (vendor: string) => `vendorSystem#${vendor}`;

const promoteSelectedVendorOptions = (options: SelectItem[], selectedVendorSystems: string[]) => {
    const vendorGroup = options.find((x) => x.value === 'vendorSystem');
    if (!vendorGroup) {
        return options;
    }

    const sortedVendorGroup: SelectItem = {
        ...vendorGroup,
        nodes: sortBy(vendorGroup.nodes, (x) => (selectedVendorSystems.includes(x.value) ? -1 : 1)),
    };

    return options.map((option) => (option.value === sortedVendorGroup.value ? sortedVendorGroup : option));
};

const mediaInsertionDatePresets: DateRangePreset[] = [
    'THIS_MONTH',
    'NEXT_MONTH',
    'THIS_QUARTER',
    'NEXT_QUARTER',
];

const MULTI_VALUED_CAMPAIGN_CLASSIFICATIONS: (keyof CampaignClassifications)[] = [
    'productLabel',
    'flightLabel',
    'productSubLabel',
    'productCategory',
    'vendorSystem',
    'searchTermStrategy',
];

const MediaInsertionNode = ({
    field,
    products,
    flightLabels,
    campaigns,
    insertion,
    initial,
    vendors,
    onChange,
}: Props) => {
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);

    const { runtimeChanged, needsLogging } = getTrackedChanges(insertion, initial);
    const selectedVendorSystems = insertion.vendorSystems.map(toVendorSystemValue);
    const selectedProductLabels =
        insertion.classificationEntries
            ?.filter((x) => x.startsWith('productLabel#'))
            .map((x) => x.split('#')[1]) || [];
    const selectedProductCategories =
        insertion.classificationEntries
            ?.filter((x) => x.startsWith('productCategory#'))
            .map((x) => x.split('#')[1]) || [];

    const filterClassificationOption = (option: SelectItem, input: string, parent?: SelectItem) => {
        const matchesQuery = option.value.toLowerCase().includes(input.toLowerCase());
        const canSelectMoreInCategory =
            MULTI_VALUED_CAMPAIGN_CLASSIFICATIONS.includes((parent || option).value) ||
            !(parent || option).nodes?.some((node) => insertion.classificationEntries?.includes(node.value));
        return matchesQuery && canSelectMoreInCategory;
    };

    const classificationOptions = toClassificationOptions(
        commonClassifiers,
        products,
        flightLabels,
        selectedProductLabels,
        selectedProductCategories
    );

    const filteredAndSortedClassificationOptions = sortClassifications(
        promoteSelectedVendorOptions(classificationOptions.filter(isDefined), selectedVendorSystems)
    );

    const getClassificationOptionDisplay = (option: SelectItem) =>
        selectedVendorSystems.includes(option.value) ? <b>{option.label}</b> : option.label;

    return (
        <div className="MediaNode">
            <div className="MediaNode-nameAndVendors">
                <div className="MediaNode-name">
                    <FormInput
                        name={`${field}.name`}
                        id={field}
                        type="text"
                        placeholder="Name"
                        isValidationMessageVisible={false}
                        validate={required}
                        onDrop={(e: FormEvent<HTMLInputElement>) => e.preventDefault()}
                    />
                </div>
                <div className="MediaNode-vendors">
                    <FormSelect
                        name={`${field}.vendorSystems`}
                        placeholder="Vendor"
                        options={vendors}
                        valueKey="externalSystem"
                        labelKey="name"
                        isClearable={false}
                        isMulti
                    />
                </div>
            </div>
            <IconButton
                className="mx-3 pt-0"
                onClick={() =>
                    onChange(`${field}.workflowType`, insertion.workflowType === 'IO' ? 'SIMPLIFIED' : 'IO')
                }
            >
                <IoIcon fill={insertion.workflowType === 'IO' ? '#1083D6' : undefined} />
            </IconButton>
            <MediaInsertionBudget
                field={`${field}.budget`}
                newValue={insertion.budget}
                oldValue={initial?.budget}
                className="me-3"
                onChange={onChange}
            />
            <MediaInsertionTargetKpi
                field={field}
                targetKpiValue={insertion.targetKpiValue}
                targetKpiMetric={insertion.targetKpiMetric}
            />
            {insertion.id && (
                <LogBookPopover
                    field={`${field}.newChange`}
                    onChange={onChange}
                    mandatory={needsLogging}
                    title={insertion.name ?? ''}
                    newChange={insertion.newChange}
                    previousChanges={insertion.previousChanges}
                />
            )}

            <div className="d-flex align-items-center mt-2">
                <div className="MediaNode-classification">
                    <FormSelectTree
                        name={`${field}.classificationEntries`}
                        placeholder="Assign"
                        options={filteredAndSortedClassificationOptions}
                        closeMenuOnSelect={false}
                        isMulti
                        filterOption={filterClassificationOption}
                        optionDisplay={getClassificationOptionDisplay}
                        sortValue={sortClassifications}
                        onChange={(value: string[]) => {
                            const productLabel =
                                value
                                    .filter((x) => x.startsWith('productLabel#'))
                                    .map((x) => x.split('#')[1]) || [];
                            if (productLabel.length === 1) {
                                onChange(`${field}.newChange.classification.productLabel`, productLabel[0]);
                            }
                            const productSubLabel =
                                value
                                    .filter((x) => x.startsWith('productSubLabel#'))
                                    .map((x) => x.split('#')[1]) || [];
                            if (productSubLabel.length === 1) {
                                onChange(
                                    `${field}.newChange.classification.productSubLabel`,
                                    productSubLabel[0]
                                );
                            }
                            const flightLabel =
                                value
                                    .filter((x) => x.startsWith('flightLabel#'))
                                    .map((x) => x.split('#')[1]) || [];
                            if (flightLabel.length === 1) {
                                onChange(`${field}.newChange.classification.flightLabel`, flightLabel[0]);
                            }
                        }}
                    />
                </div>
                <MediaInsertionCampaignLinks
                    title={insertion.name ?? ''}
                    links={insertion.campaignLinks}
                    campaigns={campaigns}
                    vendors={vendors}
                    dateFrom={insertion.runtime?.from}
                    dateTo={insertion.runtime?.to}
                    onChange={(links) => onChange(`${field}.campaignLinks`, links)}
                />
                <FormDateRangePicker
                    name={`${field}.runtime`}
                    ranges={pick(DATE_RANGES, mediaInsertionDatePresets)}
                    className="me-3"
                    inputClassName={classNames({ 'Mediaplan-changed': runtimeChanged })}
                    placeholder="Runtime"
                    isValidationMessageVisible={false}
                />
                <MediaInsertionPricingAndVolume
                    field={field}
                    pricing={insertion.pricing}
                    volume={insertion.volume}
                />
                <MediaInsertionNotes field={field} notes={insertion.notes} />
            </div>
        </div>
    );
};

export default MediaInsertionNode;
