import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { stringify } from 'qs';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import TimeOfferFormContainer from './TimeOfferFormContainer';
import { fetchTimeOffers } from './timeOffer.service';
import { TimeOffer } from './timeOffer.types';

export const PATH = '/advertiser-settings/time-offers';

type QueryParams = {
    status: ActiveOrArchived[];
    searchQuery: string;
};

const TimeOfferList = () => {
    const {
        queryParams: { status, searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>(
        {
            status: ['ACTIVE'],
            searchQuery: '',
        },
        PATH
    );

    const dispatch = useDispatch();
    const canEdit = useFeature('ADVERTISER_SETTINGS_EDIT');
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const [{ data, loading }, refetch] = usePromise([], () => fetchTimeOffers({ advertiserId }), [
        advertiserId,
    ]);

    const columnDefinitions: TableColumn<TimeOffer>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            type: DATA_TYPES.TEXT,
            width: 80,
            Cell: ({ original }) => <StatusBadge status={original.status} />,
        },
        {
            Header: 'Label',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 300,
        },
        {
            Header: 'Date from',
            accessor: 'dateFrom',
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Date to',
            accessor: 'dateTo',
            type: DATA_TYPES.TEXT,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 160,
            sortable: false,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original }) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () =>
                                dispatch(
                                    push({
                                        pathname: `${PATH}/${original.id}`,
                                        search: stringify({ advertiserId }),
                                    })
                                ),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="time_offers_list">
                {!canEdit && <CanNotEditWarning />}
                <SelectWithAddon
                    className="mb-3"
                    name="Status"
                    value={status}
                    options={ACTIVE_OR_ARCHIVED}
                    onChange={(value) => setQueryParams({ status: value })}
                    isMulti
                />
                <FormattedTable
                    isTree
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () =>
                                          dispatch(
                                              push({
                                                  pathname: `${PATH}/new`,
                                                  search: stringify({ advertiserId }),
                                              })
                                          )
                                    : undefined
                            }
                            value={searchQuery}
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    columns={columnDefinitions}
                    data={filterBySearchQuery(
                        data,
                        [...columnDefinitions.map((column) => column.accessor), 'updatedBy'],
                        searchQuery
                    )?.filter((o) => (status.length ? status?.includes(o.status) : true))}
                    loading={loading}
                    nodesExpanded={!!searchQuery}
                    defaultSorted={[{ orderBy: 'name', direction: 'ASC' }]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <TimeOfferFormContainer
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <TimeOfferFormContainer
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default TimeOfferList;
