import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Form, Label } from 'reactstrap';
import { Formik } from 'formik';
import { login } from 'platform/app/app.routes';
import { sendPasswordResetEmail } from 'platform/app/services/auth.service';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

interface FormModel {
    username: string;
}

const ForgotPasswordForm = () => {
    const [recoveryLinkSent, setRecoveryLinkSent] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async (formData: FormModel) => {
        setLoading(true);

        try {
            await sendPasswordResetEmail(formData.username);
            setRecoveryLinkSent(true);
            setSubmitError(false);
        } catch {
            setSubmitError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik<FormModel> initialValues={{ username: '' }} onSubmit={submit}>
            {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    {submitError && (
                        <Alert color="danger">
                            Password reminder cannot be used for this user. Please contact system
                            administrator.
                        </Alert>
                    )}
                    <h4 className="mb-4 fw-bold">{recoveryLinkSent ? 'Email sent' : 'Reset password'}</h4>
                    <p className="text-muted">
                        {recoveryLinkSent
                            ? "If such user exists, you will soon receive an email with steps to reset your password. Please give it a few minutes to arrive. If you don't see the email in your inbox, be sure to check your spam folder. If you cannot find it there, please contact support."
                            : 'Enter your email address and we will send you a link to reset your password.'}
                    </p>
                    <div className="mb-3">
                        <Label for="email" className="fw-bold">
                            Email
                        </Label>
                        <FormInput id="email" name="username" type="text" label="Email" validate={required} />
                    </div>
                    <Button type="submit" color="primary" className="px-3 py-2 w-100" disabled={loading}>
                        {recoveryLinkSent ? 'Resend' : 'Send'}
                    </Button>
                    <Link className="mt-2 float-end" to={login.path}>
                        Return to login page
                    </Link>
                </Form>
            )}
        </Formik>
    );
};

export default ForgotPasswordForm;
