import { sortByName } from 'platform/common/utils/array.util';
import { makeOptions } from 'platform/common/utils/option.util';
import { Vendor } from 'platform/vendors/vendors.types';

export type EventType = 'STANDARD_EVENT' | 'CUSTOM_EVENT' | 'URL_VISIT';

export const EventName = Object.freeze({
    PAGE_VIEW: 'PageView',
    PAGE_VIEW_NO_SCRIPT: 'PageViewNoScript',
    VIEW_PRODUCT: 'ViewProduct',
    VIEW_LIST: 'ViewList',
    ADD_TO_CART: 'AddToCart',
    INITIATE_CHECKOUT: 'InitiateCheckout',
    USER_DETAILS: 'UserDetails',
    ADD_PAYMENT: 'AddPayment',
    REVIEW_ORDER: 'ReviewOrder',
    PURCHASE: 'Purchase',
    PURCHASE_UNATTRIBUTED: 'PurchaseUnattributed',
    COMPLETE_REGISTRATION: 'CompleteRegistration',
});

export enum EventParam {
    PRODUCT_DATA = 'PRODUCT_DATA',
    PURCHASE_DATA = 'PURCHASE_DATA',
}

export enum FirePixelType {
    ALWAYS = 'ALWAYS',
    CUSTOM = 'CUSTOM',
}

export const FIRE_PIXEL_OPTIONS = makeOptions({
    [FirePixelType.ALWAYS]: 'Always',
    [FirePixelType.CUSTOM]: 'Custom',
});

export type CodeType = 'JAVASCRIPT_HTML' | 'IMAGE_HTML' | 'IMAGE_URL' | 'PARAMETERIZED_URL_HTML';

export const SCRIPT_TYPE_LABELS: Record<CodeType, string> = Object.freeze({
    JAVASCRIPT_HTML: 'Javascript HTML',
    IMAGE_HTML: 'Image HTML',
    IMAGE_URL: 'Image URL',
    PARAMETERIZED_URL_HTML: 'Iframe with Segment ID',
});

export const SCRIPT_TYPE_OPTIONS = makeOptions(SCRIPT_TYPE_LABELS);

export const DEFAULT_EVENTS = [EventName.PAGE_VIEW, EventName.PAGE_VIEW_NO_SCRIPT];
export const DEFAULT_DOWNLOAD_EVENTS = [EventName.PAGE_VIEW];
export const PRODUCT_TRACKING_EVENTS = [EventName.VIEW_PRODUCT, EventName.VIEW_LIST, EventName.ADD_TO_CART];
export const SITE_REGISTRATION_EVENTS = [EventName.COMPLETE_REGISTRATION];
export const CHECKOUT_EVENTS = [
    EventName.INITIATE_CHECKOUT,
    EventName.USER_DETAILS,
    EventName.ADD_PAYMENT,
    EventName.REVIEW_ORDER,
    EventName.PURCHASE,
    EventName.PURCHASE_UNATTRIBUTED,
];

export const ORDER_EVENTS = [EventName.PURCHASE, EventName.PURCHASE_UNATTRIBUTED];
export type OrderEvent = typeof ORDER_EVENTS[number];

export enum UrlDetectionType {
    EXTERNAL = 'EXTERNAL',
    DATAG = 'DATAG',
}

const URL_DETECTION_TYPE_LABELS = {
    [UrlDetectionType.EXTERNAL]: 'Use externally passed URL',
    [UrlDetectionType.DATAG]: 'Use URL detected by AdvertiserTag',
};

export const URL_DETECTION_TYPE_OPTIONS = makeOptions(URL_DETECTION_TYPE_LABELS);

export enum UrlSegmentTracking {
    ON_PAGE_LOAD = 'ON_PAGE_LOAD',
    ON_EVERY_ADVERTISER_TAG_LOAD = 'ON_EVERY_ADVERTISER_TAG_LOAD',
}

const URL_SEGMENT_TRACKING_LABELS = {
    [UrlSegmentTracking.ON_PAGE_LOAD]: 'Track URL once per page load',
    [UrlSegmentTracking.ON_EVERY_ADVERTISER_TAG_LOAD]: 'Track URL changes continuously',
};

export const URL_SEGMENT_TRACKING_OPTIONS = makeOptions(URL_SEGMENT_TRACKING_LABELS);

const CONSTANT_DATA_SHARING_MACROS = [
    { key: '${ORDER_ID}', label: 'Order ID' },
    { key: '${ORDER_ID2}', label: ' Order ID without timestamp' },
    { key: '${VALUE}', label: 'Order value' },
    { key: '${SEGMENT_ID}', label: 'Triggered segment ID(s)' },
    { key: '${SEGMENT_ID2}', label: 'Triggered segment ID(s) (in seg={ID} format)' },
    { key: '${PRODUCT_ID}', label: "Product ID (in 'id1','id2' format)" },
    { key: '${PRODUCT_ID_PL}', label: 'Product ID (in id1,id2 format)' },
    { key: '${PRODUCT_CATEGORY_ID}', label: "Product Category ID (in 'id1','id2' format)" },
    { key: '${PRODUCT_CATEGORY_ID_PL}', label: 'Product Category ID (in id1,id2 format)' },
    { key: '${TIMESTAMP}', label: 'Timestamp' },
    { key: '${URL}', label: 'Url' },
    { key: '${URL2}', label: 'Url without parameters' },
    { key: '${CUSTOM_VAR_1}', label: 'Custom var 1' },
    { key: '${CUSTOM_VAR_2}', label: 'Custom var 2' },
    { key: '${CUSTOM_VAR_3}', label: 'Custom var 3' },
    { key: '${CUSTOM_VAR_...}', label: '... up to 20 custom variables are supported' },
    { key: '${SESSION}', label: 'Session ID' },
    { key: '${CLID}', label: 'Click ID' },
    { key: '${ADITION_CLICKID}', label: 'Adition Click ID' },
    { key: '${DCLID}', label: 'CM360 Click ID' },
    {
        key: '${ENGAGERY_RECOMMENDED_PRODUCT_IDS}',
        label: 'Comma-separated list of product ids that have been shown to end-user on engagery overlay',
    },
];

export const toClickIdMacro = (clickIdParameter: string | undefined): string =>
    clickIdParameter ? `\${${clickIdParameter.toUpperCase()}}` : '';

export const getDataSharingMacros = (vendors: Vendor[]): { key: string; label: string }[] => [
    ...CONSTANT_DATA_SHARING_MACROS,
    ...sortByName(vendors)
        .filter((v) => v.clickIdEnabled)
        .map((v) => ({
            key: toClickIdMacro(v.clickIdParameter),
            label: `${v.name} Click ID`,
        })),
];
