import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { required, url } from 'platform/common/utils/validators.util';
import LandingPageFields from 'platform/creatives/components/CreativeBlocks/LandingPageFields';
import { GcmTrackerCreativeModel } from 'platform/creatives/mappers/creative/gcmTrackerCreative.mapper';
import { ConceptModel } from 'platform/creatives/types/creative.types';
import { ValidatorResult } from 'platform/creatives/utils/creativeValidate.utils';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormRow from '../../../common/components/FormRow/FormRow';
import Separator from '../../../common/components/Separator/Separator';
import { CREATIVE_FORM_HELP_KEY } from '../CreativeForm/CreativeFormContainer';
import CreativeLabelSelect from './CreativeLabelSelect';

type Props = {
    field: string;
    advertiserId: number;
};

export const gcmTrackerValidator = (): ValidatorResult<GcmTrackerCreativeModel> => ({
    name: required,
    clickThroughUrl: [required, url],
});

const GcmTrackerCreativeFields = ({ field, advertiserId }: Props) => {
    const { values } = useFormikContext<ConceptModel>();
    const creative = get(values, field);

    const uniqueContentHtmlId = (forField: string) =>
        `creative${creative.id || creative.tempId || ''}${forField}`;

    return (
        <>
            <FormRow label="Name" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_name`}>
                <FormInput name={`${field}.name`} type="text" validate={required} />
            </FormRow>
            {creative.id && (
                <FormRow label="Internal ID" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_id`}>
                    <FormInput name={`${field}.id`} type="text" disabled />
                </FormRow>
            )}
            <LandingPageFields field={field} />
            <Separator label="Optional" />
            <FormRow
                label="Creative label"
                rowHtmlId={uniqueContentHtmlId('creativeLabel')}
                helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_labelId`}
            >
                <CreativeLabelSelect name={`${field}.creativeLabelId`} advertiserId={advertiserId} />
            </FormRow>
        </>
    );
};

export default GcmTrackerCreativeFields;
