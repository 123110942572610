import { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import IconButton from '../../IconButton/IconButton';
import DateButton from '../DateButton/DateButton';

const NUMBER_OF_MONTHS = 12;

const generateMonthOptionsForYear = (year: number): Date[] => {
    const startOfYear = moment().year(year).startOf('year');

    return Array(NUMBER_OF_MONTHS)
        .fill(null)
        .map((_, index) => startOfYear.clone().add(index, 'month').toDate());
};

const isSameMonth = (date1: Date | undefined | null, date2: Date | undefined | null) => {
    if (!date1 || !date2) {
        return false;
    }

    return moment(date1).isSame(date2, 'month');
};

interface Props {
    year?: number;
    from: Date | undefined | null;
    to: Date | undefined | null;
    secondaryFrom?: Date | null;
    secondaryTo?: Date | null;
    className?: string;
    previousDisabled?: boolean;
    nextDisabled?: boolean;
    style?: React.CSSProperties;
    fromDate?: Moment;
    toDate?: Moment;
    onPreviousYear?: () => void;
    onNextYear?: () => void;
    onDateClick: (date: Date) => void;
}

const MonthPicker = ({
    year,
    from,
    to,
    secondaryFrom,
    secondaryTo,
    className,
    previousDisabled,
    nextDisabled,
    style = { width: 320 },
    fromDate,
    toDate,
    onDateClick,
    onPreviousYear,
    onNextYear,
}: Props) => {
    const [internalYear, setInternalYear] = useState(year ?? moment(from).year() ?? moment().year());
    const monthOptions = generateMonthOptionsForYear(internalYear);

    useEffect(() => {
        if (year) {
            setInternalYear(year);
        }
    }, [year]);

    return (
        <div style={style} className={classNames(className)}>
            <div className="d-flex align-items-center justify-content-between mb-2 w-100 font-lg">
                <IconButton
                    onClick={() => (onPreviousYear ? onPreviousYear() : setInternalYear((prev) => prev - 1))}
                    disabled={
                        previousDisabled || fromDate?.isSameOrAfter(moment().year(internalYear), 'year')
                    }
                >
                    <i className="fa fa-chevron-left" />
                </IconButton>
                <div>{internalYear}</div>
                <IconButton
                    onClick={() => (onNextYear ? onNextYear() : setInternalYear((prev) => prev + 1))}
                    disabled={nextDisabled || toDate?.isSameOrBefore(moment().year(internalYear), 'year')}
                >
                    <i className="fa fa-chevron-right" />
                </IconButton>
            </div>

            <div className="d-flex flex-wrap">
                {monthOptions.map((date, i) => (
                    <DateButton
                        key={i}
                        secondarySelected={isSameMonth(secondaryFrom, date) || isSameMonth(secondaryTo, date)}
                        secondaryBetweenSelected={
                            secondaryFrom && secondaryTo
                                ? moment(date).isBetween(secondaryFrom, secondaryTo, 'month')
                                : undefined
                        }
                        betweenSelected={from && to ? moment(date).isBetween(from, to, 'month') : undefined}
                        selected={isSameMonth(from, date) || isSameMonth(to, date)}
                        onClick={() => onDateClick(date)}
                        style={
                            (!!from && !!to && !isSameMonth(from, to)) ||
                            (!!secondaryFrom && !!secondaryTo && !isSameMonth(secondaryFrom, secondaryTo))
                                ? {
                                      borderTopLeftRadius:
                                          isSameMonth(to, date) || isSameMonth(secondaryTo, date)
                                              ? 0
                                              : undefined,
                                      borderTopRightRadius:
                                          isSameMonth(from, date) || isSameMonth(secondaryFrom, date)
                                              ? 0
                                              : undefined,
                                      borderBottomLeftRadius:
                                          isSameMonth(to, date) || isSameMonth(secondaryTo, date)
                                              ? 0
                                              : undefined,
                                      borderBottomRightRadius:
                                          isSameMonth(from, date) || isSameMonth(secondaryFrom, date)
                                              ? 0
                                              : undefined,
                                  }
                                : undefined
                        }
                    >
                        {moment(date).format('MMM')}
                    </DateButton>
                ))}
            </div>
        </div>
    );
};

export default MonthPicker;
