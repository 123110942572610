import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActiveAdvertiserAppHeader from 'platform/common/containers/ActiveAdvertiser/ActiveAdvertiserAppHeader';
import { useSetAppHeader } from '../../app/hooks/useSetAppHeader';
import {
    activeAdvertiserActions,
    AdvertiserOption,
    activeAdvertiserSelectors,
} from '../ducks/activeAdvertiser.duck';

const useActiveAdvertiserComponent = (): AdvertiserOption => {
    useSetAppHeader({ AppHeaderComponent: ActiveAdvertiserAppHeader });
    const dispatch = useDispatch();
    const advertisers = useSelector(activeAdvertiserSelectors.activeAdvertisers);
    const advertiserId = advertisers[0]?.id;

    useEffect(() => {
        if (advertisers.length > 1 && advertiserId) {
            dispatch(activeAdvertiserActions.changeAdvertisers([advertiserId]));
        }
    }, []);

    return useSelector(activeAdvertiserSelectors.activeAdvertiser);
};

export default useActiveAdvertiserComponent;
