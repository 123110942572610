import DspIntegrationIcon from '../../../common/components/DspIntegrationIcon/DspIntegrationIcon';
import { CreativeIntegration } from '../../types/creative.types';

const CreativeIntegrationIcon = ({ id, integration }: { id?: number; integration: CreativeIntegration }) => (
    <DspIntegrationIcon
        systemName={integration.externalSystem}
        status={integration.integrationStatus}
        enabled={integration.integrationStatus !== 'NEVER'}
        internalId={id}
        externalId={integration.externalId}
        syncEntityType={'CREATIVE'}
        integrationMessage={integration.integrationMessage}
    />
);

export default CreativeIntegrationIcon;
