import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import Search from 'platform/common/components/Search/Search';
import { DATA_TYPES } from 'platform/common/dataTypes';

interface ProductOverlapType {
    adh_overlap_source_product: string;
    adh_overlap_target_product: string;
    adh_overlap_users_30d: number;
    adh_product_unique_purchases_30d: number;
    adh_product_purchase_probability_30d: number;
}

interface Props {
    advertiserId: number;
}

const ProductOverlap = ({ advertiserId }: Props) => {
    const [searchQuery, setSearchQuery] = useState('');

    const { data, loading, error } = useQuery<{
        productOverlap: ProductOverlapType[];
    }>(
        gql`
            query ProductOverlap($advertiserId: Int!, $query: String) {
                productOverlap(advertiserId: $advertiserId, query: $query) {
                    adh_overlap_source_product
                    adh_overlap_target_product
                    adh_overlap_users_30d
                    adh_product_unique_purchases_30d
                    adh_product_purchase_probability_30d
                }
            }
        `,
        {
            variables: {
                advertiserId,
                query: searchQuery,
            },
            fetchPolicy: 'network-only',
        }
    );

    const columns: TableColumn<ProductOverlapType>[] = [
        {
            Header: 'Source Product',
            type: DATA_TYPES.TEXT,
            accessor: 'adh_overlap_source_product',
        },
        {
            Header: 'Target Product',
            type: DATA_TYPES.TEXT,
            accessor: 'adh_overlap_target_product',
        },
        {
            Header: 'Overlap, 30d',
            accessor: 'adh_overlap_users_30d',
            type: DATA_TYPES.INT,
        },
        {
            Header: 'Actions, 30d',
            accessor: 'adh_product_unique_purchases_30d',
            type: DATA_TYPES.INT,
        },
        {
            Header: 'Purchase Probability, 30d',
            accessor: 'adh_product_purchase_probability_30d',
            type: DATA_TYPES.P100,
        },
    ];

    if (error) {
        return <ErrorMessage error={error} style={{}} />;
    }

    if (loading || !data) {
        return <FormattedTable containerClass="shadow-none" manual columns={[]} data={[]} loading />;
    }

    return (
        <BodyContainer helpKey="product_overlap">
            <FormattedTable
                exportData={{ filename: 'productOverlap' }}
                columns={columns}
                data={data.productOverlap}
                loading={loading}
                topToolbar={
                    <div>
                        <Search minLength={3} value={searchQuery} onSearch={setSearchQuery} />
                    </div>
                }
            />
        </BodyContainer>
    );
};

export default ProductOverlap;
