import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { gql } from '@apollo/client';
import {
    activeAdvertiserSelectors,
    ActiveAdvertiserState,
    AdvertiserOrAgentOption,
    RELOAD_ADVERTISER_OPTIONS,
} from 'platform/common/ducks/activeAdvertiser.duck';
import { fulfilled, pending, rejected } from '../../common/utils/actionSuffixes.util';
import client from '../../common/utils/createApolloClient.util';
import { authSelectors } from '../ducks/auth.duck';

const fetchAdvertisers = () =>
    client
        .query<{ advertisers: AdvertiserOrAgentOption[] }>({
            query: gql`
                query ActiveAdvertiser_Options {
                    advertisers(state: ["ACTIVE"]) {
                        id
                        parentId
                        type
                        name
                        iconUrl
                        activeAdserver
                        additionalAttributes
                        seatId
                        regionalSettings {
                            timeFormat
                            currencyId
                            timeZoneId
                        }
                        parentId
                    }
                }
            `,
            fetchPolicy: 'network-only',
        })
        .then((res) => res.data.advertisers);

function* reloadOptions() {
    yield put({ type: pending(RELOAD_ADVERTISER_OPTIONS) });
    try {
        // @ts-ignore
        const options = yield call(fetchAdvertisers);
        // @ts-ignore
        const profile = yield select(authSelectors.ready.profile);
        yield put({ type: fulfilled(RELOAD_ADVERTISER_OPTIONS), payload: { options, profile } });
    } catch (error) {
        yield put({ type: rejected(RELOAD_ADVERTISER_OPTIONS), payload: error });
    }
}

function* monitorTransitiveStates() {
    while (true) {
        yield take('*');
        const activeAdvertiserState: ActiveAdvertiserState = yield select(activeAdvertiserSelectors.root);
        if (activeAdvertiserState.type !== 'IDLE') {
            // eslint-disable-next-line no-continue
            continue;
        }
        // @ts-ignore
        if (!(yield select(authSelectors.isLoggedIn))) {
            // eslint-disable-next-line no-continue
            continue;
        }

        yield call(reloadOptions);
    }
}

export default [call(monitorTransitiveStates), takeLatest(RELOAD_ADVERTISER_OPTIONS, reloadOptions)];
