import { useState } from 'react';
import { useSelector } from 'react-redux';
import { components, MenuListProps } from 'react-select';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import Select from 'platform/common/components/Select/Select';
import { User } from 'platform/userManagement/types/user.type';

interface Props {
    assignee: string | undefined;
    users: User[];
    loading: boolean;
    onChange: (assignee?: string) => void;
}

const MenuWithAssignMe = (props: MenuListProps<any>) => {
    const login = useSelector(authSelectors.ready.profile).login;
    const currentUserInOptions = props.options.some((x) => x.value === login);

    return (
        <components.MenuList {...props}>
            <>
                {currentUserInOptions && (
                    <button
                        type="button"
                        className="btn-secondary w-100 border-0 font-xs py-2 my-0"
                        onClick={() => props.setValue({ value: login }, 'select-option')}
                    >
                        Assign to me
                    </button>
                )}
                {props.children}
            </>
        </components.MenuList>
    );
};

const LaneAssignee = ({ assignee, users, loading, onChange }: Props) => {
    const [editMode, setEditMode] = useState(false);

    if (!editMode) {
        const assigneeName = users.find((x) => x.login === assignee)?.name || '-';

        return (
            <div className="d-flex align-items-center w-100" style={{ height: 34 }}>
                <span className="text-truncate" title={assigneeName}>
                    {assigneeName}
                </span>
                <i
                    role="button"
                    tabIndex={0}
                    className="fal fa-pencil text-muted ms-auto"
                    onClick={() => setEditMode(true)}
                />
            </div>
        );
    }

    const userOptions = users.map((u) => ({ value: u.login, label: u.name }));

    return (
        <Select
            value={assignee}
            isLoading={loading}
            options={userOptions}
            className="w-100"
            selectStyle={{ height: 34, minWidth: 'unset' }}
            components={{
                DropdownIndicator: null,
                MenuList: MenuWithAssignMe,
            }}
            menuIsOpen
            autoFocus
            onBlur={() => setEditMode(false)}
            onChange={(value) => {
                setEditMode(false);
                onChange(value);
            }}
        />
    );
};

export default LaneAssignee;
