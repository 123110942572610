export type FunnelTitle = 'All users' | 'Product page' | 'Shopping cart' | 'Order process' | 'Action';

export interface FunnelSetting {
    groupId: number;
    title: FunnelTitle;
    icon: string;
    labelKey: string;
    segmentSections: string[];
}

export const FUNNEL_SETTINGS: FunnelSetting[] = [
    {
        groupId: 0,
        title: 'All users',
        icon: 'eye',
        labelKey: 'segment-funnel-labels-step1',
        segmentSections: [],
    },
    {
        groupId: 1,
        title: 'Product page',
        icon: 'tag',
        labelKey: 'segment-funnel-labels-step2',
        segmentSections: [
            'SS_PRODUCT_1',
            'SS_PRODUCT_2',
            'SS_PRODUCT_3',
            'SS_PRODUCT_4',
            'SS_PRODUCT_5',
            'SS_PRODUCT_6',
            'SS_PRODUCT_7',
            'SS_PRODUCT_8',
            'SS_PRODUCT_9',
            'SS_PRODUCT_10',
        ],
    },
    {
        groupId: 2,
        title: 'Shopping cart',
        icon: 'shopping-cart',
        labelKey: 'segment-funnel-labels-step3',
        segmentSections: ['SS_FUNNEL_1'],
    },
    {
        groupId: 3,
        title: 'Order process',
        icon: 'align-left',
        labelKey: 'segment-funnel-labels-step4',
        segmentSections: [
            'SS_FUNNEL_2',
            'SS_FUNNEL_3',
            'SS_FUNNEL_4',
            'SS_FUNNEL_5',
            'SS_FUNNEL_6',
            'SS_FUNNEL_7',
            'SS_FUNNEL_8',
            'SS_FUNNEL_9',
            'SS_FUNNEL_10',
        ],
    },
    {
        groupId: 4,
        title: 'Action',
        icon: 'credit-card',
        labelKey: 'segment-funnel-labels-step5',
        segmentSections: ['SS_PURCHASE_UNATT'],
    },
];

export const classificationCodesToFunnelSteps = (classificationCodes: string[]): FunnelTitle[] => {
    const activeSteps = FUNNEL_SETTINGS.filter((step) =>
        step.segmentSections.some((s) => classificationCodes.includes(s))
    );
    return activeSteps.length ? activeSteps.map((s) => s.title) : [FUNNEL_SETTINGS[0].title];
};
