import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import qs from 'qs';
import { NavigationParams, ReportFilter } from 'platform/analytics/analytics.types';
import { getCompatibleFilters } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { FilterInputProps } from 'platform/analytics/modes/Analytics';
import AnalyticsFilter from 'platform/analytics/reportComponents/ReportFilters/AnalyticsFilter';
import { ButtonDropdownOption } from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import FiltersContainer from 'platform/common/components/FiltersContainer/FiltersContainer';
import { useFeature } from 'platform/common/hooks/useFeature';
import { sortByName } from 'platform/common/utils/array.util';
import { parseQuery } from 'platform/common/utils/url.util';
import { useReportFilterOptions } from './useReportFilterOptions';

interface Props {
    filterInputProps: { [key: string]: FilterInputProps };
    showSystemFilters: boolean;
}

const ReportFilters = ({ filterInputProps, showSystemFilters }: Props) => {
    const isSuperuser = useFeature('SUPERUSER');
    const reportFilters = useSelector(analyticsSelectors.filters);
    const settings = useSelector(analyticsSelectors.settings);
    const { definitions } = useAnalyticsMetadata();
    const dispatch = useDispatch();
    const canEditFilters = useFeature('ANALYTICS_FILTERS_EDIT');
    const { filterOptionsMap, updateFilters, filtersLoading } = useReportFilterOptions({
        reportFilters,
        onFiltersChange: (filters) => {
            dispatch(analyticsSettingsActions.changeFilters(filters));
            addFiltersToUrl(filters);
        },
        onLoadingChange: (loading) => dispatch(analyticsSettingsActions.changeFiltersLoading(loading)),
    });

    const queryParams: NavigationParams = parseQuery(location.search);
    // adding filters to url to support drills and linked reports
    const addFiltersToUrl = (filters: ReportFilter[]) => {
        const nonEmptyFilters = filters.filter((f) => !!f.values.length);

        dispatch(
            push({
                search: qs.stringify({
                    ...queryParams,
                    settings: JSON.stringify({
                        filters: nonEmptyFilters,
                    }),
                }),
            })
        );
    };
    const handleFilterRemove = (filter: ReportFilter) => {
        dispatch(analyticsSettingsActions.removeFilter(filter));
        addFiltersToUrl(reportFilters.filter((fil) => fil.key !== filter.key));
    };

    const getAvailableFilters = (): ButtonDropdownOption[] => {
        const compatibleFilters = getCompatibleFilters(settings.components);

        const availableFilters = sortByName(
            definitions.filters
                // remove advertiser filter cause it comes from active advertiser filter
                .filter((f) => f.target !== 'advertiser_id' && !reportFilters.find((v) => v.key === f.target))
                .filter((f) => {
                    const dimension = definitions.dimensions.find((d) => d.key === f.target);
                    return !dimension?.isSystem || (showSystemFilters && isSuperuser);
                })
                .filter((f) => !compatibleFilters || compatibleFilters.some((cf) => cf.target === f.target))
        );

        return availableFilters.map((f) => ({
            label: `${f.name} [${f.target}]`,
            key: f.target,
            action: () =>
                updateFilters([
                    ...reportFilters,
                    {
                        key: f.target,
                        operator: 'IS',
                        values: [],
                    },
                ]),
        }));
    };

    const filters = reportFilters.filter((f) => {
        const filterDefinition = definitions.filters.find((fd) => fd.target === f.key);
        return !!filterDefinition && f.key !== 'advertiser_id';
    });

    return (
        <FiltersContainer filterOptions={getAvailableFilters()} canEdit={canEditFilters}>
            {({ filtersContainerRef, isFilterHidden }) =>
                filters.map((filter, index) => {
                    const filterDefinition = definitions.filters.find((fd) => fd.target === filter.key)!;
                    const filterOptions = filterOptionsMap?.[filter.key];
                    return (
                        <div
                            key={filter.key}
                            className={classNames({
                                'FiltersContainer-hidden': isFilterHidden(index),
                            })}
                        >
                            <AnalyticsFilter
                                container={filtersContainerRef}
                                filter={filter}
                                filterDefinition={filterDefinition}
                                options={filterOptions?.options ?? []}
                                error={filterOptions?.error}
                                disabled={!canEditFilters}
                                loading={filtersLoading}
                                onChange={(newFilter) => {
                                    if (isEqual(newFilter, filter)) {
                                        return;
                                    }
                                    updateFilters(
                                        reportFilters.map((f) => (f.key === newFilter.key ? newFilter : f))
                                    );
                                }}
                                supportedOperators={['IS', 'IS_NOT', 'CONTAINS', 'CONTAINS_NOT']}
                                filterInputProps={filterInputProps[filter?.key]}
                                refetch={() => updateFilters(reportFilters)}
                                onRemove={() => handleFilterRemove(filter)}
                            />
                        </div>
                    );
                })
            }
        </FiltersContainer>
    );
};

export default ReportFilters;
