import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { round } from 'lodash-es';
import { isDefined } from 'platform/common/common.types';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { CHART_COLORS } from 'platform/common/utils/color.util';
import { SegmentChartStats } from '../../../segments.types';

type StateProps = {
    data: SegmentChartStats[];
    loading: boolean;
};

const VisitTimeChart = ({ data }: StateProps) => {
    if (!data) {
        return null;
    }

    const chartOptions: ChartOptions<'line' | 'bar'> = {
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Visit time in seconds, Last 30 days',
                position: 'bottom',
                padding: 0,
            },
            legend: {
                reverse: true,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                position: 'right',
                grid: {
                    display: false,
                },
                ticks: {
                    callback: (value: number) => `${round(value, 2)}%`,
                    padding: 10,
                },
            },
            y1: {
                beginAtZero: true,
                position: 'left',
                grid: {
                    drawTicks: false,
                },
            },
            x: {
                grid: { display: false },
            },
        },
    };

    const chartData: ChartData<any> = {
        labels: data.map((line) => line.name.match(/\d+/)?.[0]).filter(isDefined),
        datasets: [
            {
                label: 'Change in Action prob.',
                type: 'line',
                backgroundColor: CHART_COLORS[2],
                pointRadius: 0,
                borderJoinStyle: 'round',
                fill: false,
                tension: 0,
                yAxisID: 'y',
                data: data.map((line) => DATA_TYPES.P100.parse(Math.max(0, line.penetrationDelta))),
            },
            {
                label: 'Unique users',
                yAxisID: 'y1',
                type: 'bar',
                backgroundColor: CHART_COLORS[0],
                data: data.map((line) => line.uniqueUsers),
            },
        ],
    };

    return <Bar data={chartData} options={chartOptions} />;
};

export default VisitTimeChart;
