import { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { listMediaplans, searchMediaInsertions } from 'platform/campaign/campaign/services/mediaplan.service';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { dateFilterSelectors, dateFilterActions } from 'platform/common/ducks/dateFilter.duck';
import { formatDate } from 'platform/common/utils/date.util';
import { Mediaplan, MediaInsertionSearchResult } from 'platform/mediaplan/mediaplan.types';
import { ReportFilter, ReportFilterValue } from '../analytics.types';
import { findFilter } from '../analytics.util';
import { analyticsSelectors } from '../ducks/analytics.duck';
import { analyticsMetadataActions } from '../ducks/analyticsMeta.duck';
import { analyticsSettingsActions } from '../ducks/analyticsSettings.duck';
import { useAnalyticsMetadata } from './useAnalyticsMetadata';

export const MEDIA_PLAN_ID = 'media_plan_id';
export const MEDIA_INSERTION_ID = 'media_insertion_id';

const resolveMediaPlan = (mediaPlans: Mediaplan[], mediaPlanIds: ReportFilterValue[]): Mediaplan[] => {
    const filter: Mediaplan[] =
        mediaPlanIds && mediaPlanIds.length > 1
            ? mediaPlans.filter((mp: Mediaplan) => !!mediaPlanIds.find((value) => value === mp.id))
            : mediaPlans.filter((mp) => mediaPlanIds[0] === mp.id);
    if (filter.length) {
        return filter;
    }

    return mediaPlans.filter((mp) => moment().isBetween(mp.dateFrom, mp.dateTo, undefined, '[]'));
};

const addMediaPlanFilterValues = (mediaPlans: Mediaplan[], mediaPlanFilter?: ReportFilter): ReportFilter => {
    if (mediaPlanFilter?.values.length) {
        return mediaPlanFilter;
    }

    const mediaPlanIds = resolveMediaPlan(mediaPlans, mediaPlanFilter?.values ?? []).map((mp) => mp.id);
    return {
        key: MEDIA_PLAN_ID,
        values: mediaPlanIds.length > 1 ? [mediaPlanIds[0]] : mediaPlanIds,
    };
};

export const getBudgetReportingPeriod = ({
    mediaInsertion,
    mediaPlanIds,
    mediaPlans,
}: {
    mediaInsertion?: MediaInsertionSearchResult;
    mediaPlans: Mediaplan[];
    mediaPlanIds: ReportFilterValue[];
}) => {
    if (!mediaPlanIds.length && !mediaInsertion) {
        return undefined;
    }

    if (mediaInsertion) {
        const today = moment();
        return {
            from: mediaInsertion.dateFrom,
            to: today.isAfter(moment(mediaInsertion.dateTo))
                ? mediaInsertion.dateTo
                : formatDate(today.subtract(1, 'days')),
        };
    }

    const resolvedPlans = resolveMediaPlan(mediaPlans, mediaPlanIds);
    return {
        from: resolvedPlans.map((mp) => mp?.dateFrom).sort()[0] || '',
        to:
            resolvedPlans
                .map((mp) => mp?.dateTo)
                .sort()
                .reverse()[0] || '',
    };
};

interface Props {
    filter: ReportFilter;
}

const useMediaplanFilter = ({ filter }: Props) => {
    const dispatch = useDispatch();
    const settings = useSelector(analyticsSelectors.settings);
    const filters = useSelector(analyticsSelectors.filters);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const metadata = useAnalyticsMetadata();
    const showConfirmModal = useConfirmationModal();

    const showDateChangeDialog = (mediaPlanIds: ReportFilterValue[], mediaPlans: Mediaplan[]) => {
        const budgetPeriod = getBudgetReportingPeriod({
            mediaInsertion: metadata.mediaInsertions.find(
                (insertion) => insertion.id === findFilter(filters, MEDIA_INSERTION_ID)?.values[0]
            ),
            mediaPlanIds,
            mediaPlans,
        });

        if (
            budgetPeriod &&
            (budgetPeriod.from !== periods.primaryFrom || budgetPeriod.to !== periods.primaryTo)
        ) {
            showConfirmModal(
                () =>
                    dispatch(
                        dateFilterActions.changePeriods({
                            primaryFrom: budgetPeriod.from,
                            primaryTo: budgetPeriod.to,
                            preset: undefined,
                        })
                    ),
                {
                    title: 'Change date picker',
                    text: 'Would you like the date picker to update to the date range of the selected media plan?',
                }
            );
        }
    };

    const loadMediaPlans = async () => {
        const [plans, insertions] =
            !metadata.mediaPlans.length && !metadata.mediaInsertions.length
                ? await Promise.all([
                      listMediaplans({ advertiserId, status: ['ACTIVE', 'INACTIVE'] }),
                      searchMediaInsertions({ advertiserId }),
                  ])
                : [metadata.mediaPlans, metadata.mediaInsertions];
        const mediaPlans = plans.filter((mp) => mp.status === 'ACTIVE');
        const newFilter = settings.mode === 'BUDGET' ? addMediaPlanFilterValues(mediaPlans, filter) : filter;
        if (settings.mode === 'BUDGET' && !isEqual(filter, newFilter)) {
            dispatch(analyticsSettingsActions.saveFilter(newFilter));
        }

        if (newFilter.values.length) {
            showDateChangeDialog(newFilter.values, mediaPlans);
        }

        dispatch(
            analyticsMetadataActions.setMeta({
                ...metadata,
                mediaInsertions: insertions,
                mediaPlans: plans,
            })
        );
    };

    useEffect(() => {
        if (filter.key !== MEDIA_PLAN_ID) {
            return;
        }

        loadMediaPlans();
    }, []);

    return {
        onMediaPlanFilterChange: (mediaPlanIds: ReportFilterValue[]) =>
            showDateChangeDialog(
                mediaPlanIds,
                metadata.mediaPlans.filter((mp) => mp.status === 'ACTIVE')
            ),
    };
};

export default useMediaplanFilter;
