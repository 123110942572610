import { MouseEventHandler } from 'react';
import IconButton from '../IconButton/IconButton';

interface Props {
    expanded: boolean;
    className?: string;
    onClick: MouseEventHandler;
}

const ExpandCollapseButton = ({ expanded, className, onClick }: Props) => (
    <IconButton type="button" className={className} onClick={onClick}>
        <i className={expanded ? 'fa fa-angle-down' : 'fa fa-angle-right'} />
    </IconButton>
);

export default ExpandCollapseButton;
