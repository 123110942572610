import classNames from 'classnames';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { precisionRound } from 'platform/common/utils/formatters.util';
import './MediaplanBudgetBar.scss';

interface Props {
    total: number;
    allocated: number;
}

const budget = (money: number) => DATA_TYPES.EUR.format(money);

const MediaplanBudgetBar = ({ total, allocated }: Props) => {
    const remaining = precisionRound(total, 2) - (allocated > 0 ? precisionRound(allocated, 2) : 0);
    const isOverrun = remaining < 0;
    const allocatedPercent = (allocated / total) * 100;

    const progress = (() => {
        if (allocatedPercent < 0) {
            return 0;
        }

        if (allocatedPercent > 100) {
            return 100;
        }

        return allocatedPercent;
    })();

    return (
        <div className="MediaplanBudgetBar">
            <div className="d-flex mb-1">
                <div className="d-flex text-nowrap" style={{ width: `${progress}%` }}>
                    <div className="me-3">
                        <span className="text-muted">Total budget: </span>
                        <b>{budget(total)}</b>
                    </div>
                    <div className="ms-auto">
                        <span className="text-muted">Allocated: </span>
                        <b>{budget(allocated)}</b>
                    </div>
                </div>
                <div className="ms-auto text-end" style={{ minWidth: 200 }}>
                    <div>
                        <span className="text-muted">{isOverrun ? 'Overrun: ' : 'Remaining: '}</span>
                        <b>{budget(remaining)}</b>
                    </div>
                </div>
            </div>
            <div className="MediaplanBudgetBar-container">
                <div
                    className={classNames('MediaplanBudgetBar-container-progress', {
                        'bg-danger': isOverrun,
                    })}
                    style={{ width: `${progress}%` }}
                />
            </div>
        </div>
    );
};

export default MediaplanBudgetBar;
