import { getDuration } from 'platform/common/utils/date.util';

export type DurationInterval = 'DAYS' | 'HOURS' | 'MINUTES' | 'SECONDS';

export const getDurationText = (startDate: string, endDate: string) => {
    const duration = getDuration(startDate, endDate);

    if (duration.asDays() > 1) {
        return `${duration.asDays().toFixed(0)} d`;
    }

    if (duration.asHours() > 1) {
        return `${duration.asHours().toFixed(0)} h`;
    }

    if (duration.asMinutes() > 1) {
        return `${duration.asMinutes().toFixed(0)} min`;
    }

    return `${duration.asSeconds().toFixed(0)} s`;
};

export const getDurationInterval = (startDate: string, endDate: string) => {
    const duration = getDuration(startDate, endDate);

    if (duration.asDays() > 1) {
        return 'DAYS';
    }

    if (duration.asHours() > 1) {
        return 'HOURS';
    }

    if (duration.asMinutes() > 1) {
        return 'MINUTES';
    }

    return 'SECONDS';
};
