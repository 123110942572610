import { Button } from 'reactstrap';
import { Section } from 'platform/app/app.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { toastSuccess } from 'platform/common/utils/toast.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { fetchUsersWithSeats } from 'platform/userManagement/services/userManagement.service';
import { User } from 'platform/userManagement/types/user.type';
import { fetchAnalyticsReport, patchAnalyticsReport } from '../analyticsReport.service';
import { AnalyticsReport } from '../analyticsReport.types';

type Props = {
    reportId?: number;
    seatId: number;
    section: Section;
    toggle: () => void;
    onCreate: (report: AnalyticsReport) => Promise<void>;
};

const FolderForm = ({ reportId, seatId, section, toggle, onCreate }: Props) => {
    const [{ data: users, loading: usersLoading }] = usePromise(
        [],
        () => fetchUsersWithSeats({ seatId, states: ['ACTIVE', 'PENDING'] }),
        [seatId]
    );

    const handleOpen = async (): Promise<{ exclusiveUserIds: number[]; name: string }> => {
        if (!reportId) {
            return { exclusiveUserIds: [], name: 'New folder' };
        }

        const report = await fetchAnalyticsReport(reportId);
        return {
            exclusiveUserIds: report.exclusiveUserIds ?? [],
            name: report.name,
        };
    };

    const handleSubmit = async ({ exclusiveUserIds, name }: { exclusiveUserIds: number[]; name: string }) => {
        if (reportId) {
            await patchAnalyticsReport(reportId, { exclusiveUserIds });
            toastSuccess('Assignees updated successfully');
        } else {
            await onCreate({
                name,
                section,
                type: 'FOLDER',
                status: 'ACTIVE',
                seatIds: [seatId],
                exclusiveUserIds,
            });
        }
        toggle();
    };

    return (
        <FormContainer
            helpKey="folder_form"
            sidePanel={false}
            isEdit={!!reportId}
            onOpen={handleOpen}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <>
                    {!reportId && (
                        <FormRow label="Name">
                            <FormInput name="name" />
                        </FormRow>
                    )}
                    <FormRow label="Assignees">
                        <FormSelect
                            isLoading={usersLoading}
                            isMulti
                            name="exclusiveUserIds"
                            options={users}
                            getOptionLabel={(user: User) => user.name}
                            getOptionValue={(user: User) => user.id}
                        />
                    </FormRow>
                    <div className="d-flex justify-content-end">
                        <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <AnimatedButton onClick={props.formikProps.submitForm}>
                            {reportId ? 'Update' : 'Create'}
                        </AnimatedButton>
                    </div>
                </>
            )}
        </FormContainer>
    );
};

export default FolderForm;
