import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { TaskLaneType, TaskStatus } from '../task.types';

interface Props {
    status: TaskStatus;
    laneType: TaskLaneType | undefined;
}

const TaskFinishedIcon = ({ status, laneType }: Props) =>
    status === 'ACTIVE' && laneType === 'WORK_FINISHED' ? (
        <Tooltip renderTooltip={() => 'Work finished'}>
            <i className="text-success fa fa-lg fa-flag" />
        </Tooltip>
    ) : null;

export default TaskFinishedIcon;
