import { ReactNode, createContext, useContext, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useLoading } from 'platform/common/hooks/useLoading';
import { CHAT_EXCLUDED_FILE_TYPES } from '../chat.constants';
import {
    ChatAgent,
    ChatContentParts,
    ChatLanguage,
    ChatMessage,
    ChatMessagePayload,
    MessagePrompt,
    SubmitFunc,
} from '../chat.types';
import { chatMessagePollAsyncResult } from '../chat.utis';
import { useAgentPlaceholders } from '../useAgentPlaceholders';
import { useChatAgentContext } from './ChatAgentProvider';
import { useChatPromptContext } from './ChatPromptProvider';

type MessageToSubmit = {
    text: string;
    prompt?: MessagePrompt;
    fileName?: string;
    languageKey?: ChatLanguage;
    fileType?: string;
    withContent?: boolean;
    content?: MessageToSubmit[];
};

const EMPTY_AGENT_ID = 21;
const MAX_CHARS_PER_CSV = 30000;

function splitCsvIntoMultiple(csvString: string): string[] {
    const rows = csvString.split('\n');
    const headerRow = rows[0];

    const csvChunks: string[] = [];
    let currentChunk = '';

    rows.forEach((row) => {
        if (!currentChunk.length) {
            currentChunk = `${headerRow}\n`;
        }

        if (currentChunk.length + row.length > MAX_CHARS_PER_CSV) {
            csvChunks.push(currentChunk);
            currentChunk = '';
        } else {
            currentChunk += `${row}\n`;
        }
    });

    return csvChunks;
}

interface ContextType {
    messages: ChatMessage[];
    placeholdersLoading: boolean;
    submitLoading: boolean;
    submit: SubmitFunc;
    clearChat: () => void;
    updateMessagesForCurrentAgent: (newMessages: ChatMessage[]) => void;
}

const ChatMessageContext = createContext<ContextType>({
    messages: [],
    placeholdersLoading: true,
    submitLoading: false,
    submit: () => {},
    clearChat: () => {},
    updateMessagesForCurrentAgent: () => {},
});

const isFileType = (m: MessageToSubmit) =>
    !!m.fileName && !!m.fileType && !CHAT_EXCLUDED_FILE_TYPES.includes(m.fileType);

export const ChatMessageProvider = ({ children }: { children: ReactNode }) => {
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const { selectedAgent } = useChatAgentContext();
    const { systemPrompts } = useChatPromptContext();
    const [placeholders, placeholdersLoading] = useAgentPlaceholders(selectedAgent?.id, advertiserId);
    const [submitLoading, withSubmitLoading] = useLoading();
    const [messages, setMessages] = useState<Record<ChatAgent['id'], ChatMessage[] | undefined>>({});
    const messagesByAgent = messages?.[selectedAgent?.id ?? 0] ?? [];
    const excludedMessagesRef = useRef<Record<number, number>>({});

    const updateMessagesForCurrentAgent = (newMessages: ChatMessage[]) => {
        if (!selectedAgent) return;

        setMessages((prev) => ({ ...prev, [selectedAgent.id]: newMessages }));
    };

    const excludeMessages = (excludedIndexes: number | undefined | null) => {
        if (!selectedAgent || excludedIndexes === undefined || excludedIndexes === null) return;

        const currentMessages = excludedMessagesRef.current;
        const agentExclude = currentMessages[selectedAgent.id];
        const newId = agentExclude !== undefined ? agentExclude + excludedIndexes + 1 : excludedIndexes;

        excludedMessagesRef.current = {
            ...currentMessages,
            [selectedAgent.id]: newId,
        };
    };

    const messagesToSend = (_messages: ChatMessage[]): ChatMessage[] => {
        if (!selectedAgent) return _messages;
        const agentExclude = excludedMessagesRef.current[selectedAgent.id];
        const takeSince = agentExclude !== undefined ? agentExclude + 1 : 0;
        return _messages.slice(takeSince);
    };

    const submit = (messagesToSubmit: MessageToSubmit[]) => {
        if (submitLoading || placeholdersLoading || !selectedAgent?.id) return;

        // This is a band-aid solution to the current limitation of OpenAI
        // Not being able to handle large prompts
        // When forceFileUpload is activated the second message will be a CSV.
        // We are splitting the CSV into multiple messages,
        // Concatenate with the prompt from firstMsg
        // And send them as separate chat instances to an empty agent
        const [firstMsg, secondMsg] = messagesToSubmit;
        const shouldSplitCsv =
            firstMsg.prompt?.forceFileUpload &&
            firstMsg.prompt?.splitFilePrompt &&
            secondMsg?.fileName &&
            secondMsg?.text &&
            secondMsg.text.length > MAX_CHARS_PER_CSV;

        if (shouldSplitCsv) {
            const csvChunks = splitCsvIntoMultiple(secondMsg.text);
            const payloads: ChatMessagePayload[] = csvChunks.map((chunk) => ({
                agentId: EMPTY_AGENT_ID,
                messages: [
                    {
                        role: 'user',
                        contentParts: [{ type: 'TEXT', content: `${firstMsg.text}` }],
                        prompt: firstMsg.prompt,
                    },
                    {
                        role: 'user',
                        contentParts: [{ type: 'TEXT', content: chunk }],
                    },
                ],
                placeholders,
            }));
            const requests = payloads.map((payload) => chatMessagePollAsyncResult(payload));
            updateMessagesForCurrentAgent([
                ...messagesByAgent,
                {
                    role: 'user',
                    contentParts: [{ type: 'TEXT', content: firstMsg.text }],
                    prompt: firstMsg.prompt,
                },
                {
                    role: 'user',
                    fileName: secondMsg.fileName,
                    contentParts: [{ type: secondMsg.fileName ? 'FILE' : 'TEXT', content: secondMsg.text }],
                },
                { role: 'assistant', contentParts: [{ type: 'TEXT', content: '' }] },
            ]);
            withSubmitLoading(() =>
                Promise.all(requests).then((res) => {
                    updateMessagesForCurrentAgent([
                        ...messagesByAgent,
                        {
                            role: 'user',
                            contentParts: [{ type: 'TEXT', content: `${firstMsg.text}` }],
                            prompt: firstMsg.prompt,
                        },
                        {
                            role: 'user',
                            fileName: secondMsg.fileName,
                            contentParts: [{ type: 'TEXT', content: `${secondMsg.text}` }],
                        },
                        {
                            role: 'assistant',
                            contentParts: [
                                {
                                    type: 'TEXT',
                                    content: res.reduce((acc, r, i) => {
                                        if (i === 0) return r.message;

                                        const msgWithoutHeader = r.message.split('\n').slice(1).join('\n');

                                        return `${acc}\n${msgWithoutHeader}`;
                                    }, ''),
                                },
                            ],
                        },
                    ]);
                })
            );
        } else {
            const filePrompt = systemPrompts.find((p) => p.key === 'file_prompt');
            const mappedMessages: ChatMessage[] = messagesToSubmit.map((m) => {
                const languageSnippet = systemPrompts.find((p) => p.key === m.languageKey)?.prompt;

                const processContent = (procContent: MessageToSubmit): ChatContentParts => ({
                    ...(procContent.fileType || procContent.fileName
                        ? { fileName: procContent.fileName }
                        : {}),
                    type: isFileType(procContent) ? 'FILE' : 'TEXT',
                    mimeType: procContent.fileType,
                    content: isFileType(procContent)
                        ? `${procContent.text?.split(';base64,')?.[1]}`
                        : `${procContent.fileName && filePrompt ? filePrompt.prompt : ''}${procContent.text}${
                              languageSnippet || ''
                          }`,
                });

                const parts: ChatContentParts[] = [
                    !!m.text || !!m.fileName ? processContent(m) : undefined,
                    ...(m.withContent && m.content?.length
                        ? m.content.map((mContent) => processContent(mContent))
                        : []),
                ].filter((p): p is ChatContentParts => Boolean(p));

                return {
                    role: 'user',
                    contentParts: parts,
                    prompt: m.prompt,
                    fileName: m.fileName,
                };
            });
            const newMessages: ChatMessage[] = [...messagesByAgent, ...mappedMessages];
            updateMessagesForCurrentAgent([...newMessages, { role: 'assistant', contentParts: [] }]);

            const payload: ChatMessagePayload = {
                agentId: selectedAgent.id,
                messages: messagesToSend(newMessages.filter((m) => !m.error && m.contentParts.length)),
                placeholders,
            };
            withSubmitLoading(() =>
                chatMessagePollAsyncResult(payload)
                    .then((res) => {
                        excludeMessages(res.latestDeletedMessageIdx);
                        updateMessagesForCurrentAgent([
                            ...newMessages,
                            {
                                role: 'assistant',
                                contentParts: [{ type: 'TEXT', content: res.message }],
                            },
                        ]);
                    })
                    .catch((e) => {
                        updateMessagesForCurrentAgent([
                            ...newMessages,
                            {
                                role: 'assistant',
                                contentParts: [{ type: 'TEXT', content: '' }],
                                error: { message: e.message },
                            },
                        ]);
                    })
            );
        }
    };

    const values = useMemo(
        () => ({
            messages: messagesByAgent,
            placeholdersLoading,
            submitLoading,
            submit,
            clearChat: () => updateMessagesForCurrentAgent([]),
            updateMessagesForCurrentAgent,
        }),
        [messagesByAgent, placeholdersLoading, submitLoading, submit, updateMessagesForCurrentAgent]
    );

    return <ChatMessageContext.Provider value={values}>{children}</ChatMessageContext.Provider>;
};

export const useChatMessageContext = () => {
    const context = useContext(ChatMessageContext);

    if (!context) {
        throw new Error('ChatMessageContext must be used within an ChatMessageProvider');
    }

    return context;
};
