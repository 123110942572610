import DspIntegrationIcon from 'platform/common/components/DspIntegrationIcon/DspIntegrationIcon';
import { resyncUser } from 'platform/userManagement/services/userManagement.service';
import { UserIntegration } from 'platform/userManagement/types/user.type';

type Props = {
    userId: number | undefined;
    integrations: UserIntegration[] | undefined;
};

const UserIntegrationIcons = ({ userId, integrations }: Props) => {
    if (!userId || !integrations?.length) {
        return null;
    }
    return (
        <div className="gap-1">
            {integrations?.map((integration) => (
                <DspIntegrationIcon
                    key={integration.externalSystem}
                    systemName={integration.externalSystem}
                    status={integration.status}
                    enabled
                    internalId={userId}
                    syncEntityType={'USER'}
                    integrationMessage={integration.errorMessage}
                    onResync={() => userId && resyncUser(userId, integration.externalSystem)}
                />
            ))}
        </div>
    );
};

export default UserIntegrationIcons;
