import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import classNames from 'classnames';
import { camelCase } from 'lodash-es';
import { useFormHelp } from 'platform/common/hooks/useFormHelp';
import HelpIcon from '../HelpIcon/HelpIcon';

type Props = {
    rowHtmlId?: string;
    label?: string;
    children?: ReactNode;
    inline?: boolean;
    rowClassName?: string;
    childrenColClassName?: string;
    labelClassName?: string;
    labelWidth?: number;
    contentWidth?: number;
    additionalText?: string;
    helpKey?: string;
};

const childrenWithIds = (baseId: string, children: ReactNode) =>
    Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
            return child;
        }
        if ((child as ReactElement).props.id) {
            return child;
        }
        const id = baseId + (index > 0 ? index : '');
        return cloneElement(child as ReactElement, { id });
    });

const FormRow = ({
    rowHtmlId,
    label,
    children,
    inline = false,
    rowClassName,
    childrenColClassName,
    labelClassName = 'text-end',
    labelWidth = 4,
    contentWidth,
    additionalText,
    helpKey,
}: Props) => {
    const baseId = rowHtmlId || camelCase(label);
    return (
        <FormGroup row className={rowClassName}>
            <Label md={labelWidth} className={labelClassName} for={baseId}>
                {label}
                <HelpIcon className="ms-1" helpKey={useFormHelp(children, helpKey)} />
            </Label>
            <Col
                md={contentWidth ?? 12 - labelWidth}
                className={classNames('align-self-center', childrenColClassName, {
                    'form-inline d-flex align-items-center': inline,
                })}
            >
                {childrenWithIds(baseId, children)}
                {additionalText && <small className="pt-1 pb-2">{additionalText}</small>}
            </Col>
        </FormGroup>
    );
};

export default FormRow;
