import classNames from 'classnames';
import { Period } from 'platform/common/common.types';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import { DATE_RANGES, DateRanges, formatDate } from 'platform/common/utils/date.util';
import ReportInteractionContainer from './ReportInteractionContainer';

interface Props {
    value?: Period;
    onlyFuture?: boolean;
    onlyPast?: boolean;
    required?: boolean;
    onChange: (value: Period) => void;
}

const today = formatDate(new Date());

const pastRanges: Partial<DateRanges> = {
    LAST_WEEK: DATE_RANGES.LAST_WEEK,
    LAST_MONTH: DATE_RANGES.LAST_MONTH,
    LAST_90_DAYS: DATE_RANGES.LAST_90_DAYS,
    THIS_YEAR: DATE_RANGES.THIS_YEAR,
    LAST_YEAR: DATE_RANGES.LAST_YEAR,
};

const futureRanges: Partial<DateRanges> = {
    NEXT_WEEK: { ...DATE_RANGES.NEXT_WEEK, label: 'Upcoming week' },
    NEXT_MONTH: { ...DATE_RANGES.NEXT_MONTH, label: 'Upcoming month' },
    NEXT_90_DAYS: { ...DATE_RANGES.NEXT_90_DAYS, label: 'Upcoming 90 days' },
    NEXT_YEAR: { ...DATE_RANGES.NEXT_YEAR, label: 'Upcoming year' },
};

const getRangeSet = (onlyFuture?: boolean, onlyPast?: boolean) => {
    if (onlyFuture) return futureRanges;
    if (onlyPast) return pastRanges;
    return {};
};

const ReportInteractionDatePicker = ({ value, onlyFuture, onlyPast, onChange, required }: Props) => (
    <ReportInteractionContainer
        required={required}
        isValueEmpty={!value?.from && !value?.to && !value?.preset}
    >
        {(setTouched, invalid) => (
            <DateRangePicker
                className={classNames({ 'form-control is-invalid p-0': invalid })}
                onChange={(from, to, preset) => onChange({ preset, from, to })}
                from={value?.from}
                to={value?.to}
                preset={value?.preset}
                ranges={getRangeSet(onlyFuture, onlyPast)}
                allowApplyEmptyValues
                minDate={onlyFuture ? today : undefined}
                maxDate={onlyPast ? today : undefined}
                onBlur={() => setTouched(true)}
            />
        )}
    </ReportInteractionContainer>
);

export default ReportInteractionDatePicker;
