import { useQuery } from '@apollo/client';
import { StatisticWithSeries } from 'platform/common/common.types';
import VisitTimeChart from 'platform/segments/components/SegmentCharts/VisitTime/VisitTimeChart';
import SegmentVisitTimeQuery from 'platform/segments/queries/segmentVisitTimeQuery.graphql';
import { calculateChartData } from 'platform/segments/utils/cdpChart.util';
import './SegmentAnalysisVisitTime.scss';

interface Props {
    advertiserId: number;
}

interface VisitTime {
    visitTime: {
        name: string;
        stats: {
            adh_segment_unique_users_30d: StatisticWithSeries;
            adh_available_reach_30d: StatisticWithSeries;
            adh_segment_unique_purchases_30d: StatisticWithSeries;
        };
    }[];
}

const SegmentAnalysisVisitTime = ({ advertiserId }: Props) => {
    const { data, loading } = useQuery<VisitTime>(SegmentVisitTimeQuery, {
        variables: {
            advertiserId,
        },
        fetchPolicy: 'network-only',
    });

    const chartData = calculateChartData(data?.visitTime);

    return (
        <div className="SegmentAnalysisVisitTime">
            <VisitTimeChart data={chartData} loading={loading} />
        </div>
    );
};

export default SegmentAnalysisVisitTime;
