import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { useFeature } from 'platform/common/hooks/useFeature';
import SegmentPageBodyContainer from './SegmentPageBodyContainer';

const SegmentPage = () => {
    const canEdit = useFeature('SEGMENTS_EDIT');

    return (
        <div className="SegmentPage">
            <HeaderContainer>
                <PageHeader title="Segments" />
            </HeaderContainer>

            {!canEdit && <CanNotEditWarning className="mt-3" />}
            <SegmentPageBodyContainer />
        </div>
    );
};

export default SegmentPage;
