import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { isEqual } from 'lodash-es';
import { fetchInteractionPresets } from 'platform/analytics/analytics.service';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import InteractionPresetModal from 'platform/analytics/reportComponents/InteractionPresets/InteractionPresetModal';
import { applyInteractionValues, getInteractionValues } from 'platform/analytics/reportComponents/useReport';
import { useModal } from 'platform/common/components/Modal/Modal';
import Select from 'platform/common/components/Select/Select';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { usePromise } from 'platform/common/hooks/usePromise';
import SaveIcon from '../../../assets/icons/SAVEGEAR.svg';
import './InteractionPresets.scss';

interface Props {
    reportId: number;
}

const InteractionPresets = ({ reportId }: Props) => {
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState<number>();
    const [newPresetName, setNewPresetName] = useState<string>();
    const interactionComponents = useSelector(analyticsSelectors.interactionComponents, isEqual);
    const { showModal } = useModal();

    const initialInteractionValues = useMemo(() => getInteractionValues(interactionComponents), [reportId]);

    const [{ data: presets }, refetch] = usePromise([], () => fetchInteractionPresets(reportId), [reportId]);

    useEffect(() => {
        if (presets.length && newPresetName) {
            const preset = presets.find((p) => p.name === newPresetName);
            if (preset?.id !== selectedId) {
                setSelectedId(preset?.id);
            }
            setNewPresetName(undefined);
        }
    }, [presets]);

    const openPresetModal = (presetId: number | undefined) => {
        const preset = presetId ? presets.find((p) => p.id === presetId) : undefined;
        showModal((toggle) => (
            <InteractionPresetModal
                reportId={reportId}
                preset={{
                    id: preset?.id,
                    name: preset?.name ?? '',
                    values: getInteractionValues(interactionComponents),
                    submitType: selectedId ? 'UPDATE' : 'CREATE',
                }}
                onSave={async (presetName) => {
                    setNewPresetName(presetName);
                    refetch();
                }}
                onDelete={() => {
                    handleChange(undefined);
                    refetch();
                }}
                onClose={toggle}
            />
        ));
    };

    const handleChange = (presetId: number | undefined) => {
        setSelectedId(presetId);
        const values = presets.find((p) => p.id === presetId)?.values ?? initialInteractionValues;
        const interactionStates = applyInteractionValues(interactionComponents, values);
        dispatch(analyticsSettingsActions.changeComponentStates(interactionStates));
    };

    return (
        <>
            <Tooltip renderTooltip={() => 'Create a preset'}>
                <Button className="p-0 ms-2 mb-1" color="link" onClick={() => openPresetModal(selectedId)}>
                    <img src={SaveIcon} alt="Save preset" />
                </Button>
            </Tooltip>

            {!!presets.length && (
                <div className="InteractionPresets ms-3">
                    <Select
                        placeholder="Select preset..."
                        value={selectedId}
                        options={presets}
                        onChange={handleChange}
                        getOptionValue={(preset) => preset.id}
                        getOptionLabel={(preset) => preset.name}
                        components={{ IndicatorSeparator: null }}
                    />
                </div>
            )}
        </>
    );
};

export default InteractionPresets;
