import { publicImageUrl } from 'platform/common/utils/environment.util';
import { ContactData } from '../useContacts';

interface Props {
    userContacts: ContactData[];
}

const imgPlaceholder = (
    <div
        className="p-lg-1 align-items-center d-flex justify-content-center me-2"
        style={{ width: 100, height: 100 }}
    >
        <i className="fa fa-lg fa-user" style={{ fontSize: '3.3em' }} />
    </div>
);

const ContactList = ({ userContacts }: Props) => (
    <>
        {userContacts.map(({ name, imageUrl, title, login, phone }) => (
            <div
                key={name}
                className="d-flex flex-row pe-2 mb-3 align-items-center flex-grow-1"
                style={{ flexBasis: '50%' }}
            >
                {!imageUrl && imgPlaceholder}

                {imageUrl && (
                    <img
                        alt="Contact"
                        style={{ width: 100, height: 100, borderRadius: '50%', objectFit: 'cover' }}
                        src={publicImageUrl(imageUrl)}
                    />
                )}
                <div className="flex-column flex-wrap ms-3">
                    <div className="pb-1 font-lg">
                        <b>{name}</b>
                    </div>
                    <div className="pb-1">{title}</div>
                    <div className="text-muted">{login}</div>
                    <div className="pb-1 text-muted">{phone}</div>
                </div>
            </div>
        ))}
    </>
);

export default ContactList;
