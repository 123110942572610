import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import InlineActionsFormContainer from 'platform/analytics/reportComponents/ReportTableContainer/InlineActions/InlineActionFormContainer';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import useToggle from 'platform/common/hooks/useToggle';
import unitService from '../../../../campaign/unit/services/units.service';
import InlineActionHeader from './InlineActionHeader';
import { ActionConnect, Context } from './InlineActions.types';
import { isInlineAction, isRedirectAction } from './inlineActionConnect';

type Props = {
    actions: ActionConnect[];
    context: Context;
    columnId: string;
    row: { [key: string]: any };
    closePopover: () => void;
    onSubmitFinished: () => void;
};

const CellInlineActionsPopoverBody = ({
    actions,
    context,
    columnId,
    row,
    closePopover,
    onSubmitFinished,
}: Props) => {
    const dispatch = useDispatch();
    const redirectActions = actions.filter(isRedirectAction);
    const inlineActions = actions.filter(isInlineAction);

    const [loading, toggleLoading] = useToggle(false);
    return (
        <>
            {loading && <OverlayLoader />}

            {columnId && <InlineActionHeader column={columnId} row={row} />}

            {(redirectActions.length > 0 || ['strategy_name', 'campaign_name'].includes(columnId)) && (
                <div className="CellInlineActions-buttonRow">
                    {redirectActions.map((action) => (
                        <Button
                            className="me-2"
                            key={action.action}
                            disabled={!action.operation}
                            onClick={() => {
                                closePopover();
                                if (action.operation) {
                                    action.operation(row, dispatch, context);
                                }
                            }}
                        >
                            {action.label}
                        </Button>
                    ))}

                    {['strategy_name', 'campaign_name'].includes(columnId) && (
                        <Button
                            className="d-flex align-items-center ms-auto"
                            onClick={async () => {
                                if (row.strategy_id && columnId === 'strategy_name') {
                                    await unitService.syncStrategyUnits(row.strategy_id);
                                } else if (row.campaign_id && columnId === 'campaign_name') {
                                    await unitService.syncCampaignUnits(row.campaign_id);
                                }

                                closePopover();
                            }}
                        >
                            <i className="fal fa-sync-alt gray-400 me-1" />
                            {(context.units || []).length === 1
                                ? 'Resync 1 unit'
                                : `Resync ${context.units?.length || 0} units`}
                        </Button>
                    )}
                </div>
            )}

            {inlineActions.length > 0 && (
                <div className="pt-2">
                    <InlineActionsFormContainer
                        context={context}
                        inlineActions={inlineActions.map((action) => action.action)}
                        onCancel={closePopover}
                        onSubmitStart={toggleLoading}
                        onSubmitFinished={() => {
                            onSubmitFinished();
                            closePopover();
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default CellInlineActionsPopoverBody;
