import { useDispatch } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { push } from 'redux-first-history';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_ARCHIVED, ActiveOrArchived } from 'platform/common/constants/status.constant';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import TaskBoardFormContainer from './TaskBoardFormContainer';
import { getTaskBoards } from './task.service';
import { TaskBoard } from './task.types';
import { TASK_BOARD_MANAGEMENT_PATH, TASK_BOARDS_PATH } from './taskRoutes';

type QueryParams = {
    status: ActiveOrArchived[];
};

const TaskBoardList = () => {
    const {
        queryParams: { status },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>(
        {
            status: ['ACTIVE'],
        },
        TASK_BOARD_MANAGEMENT_PATH
    );

    const dispatch = useDispatch();
    const { id: advertiserId } = useActiveAdvertiserComponent();

    const [{ data: boards, loading }, refetch] = usePromise(
        [],
        () => getTaskBoards({ advertiserId, status }),
        [advertiserId, status]
    );

    const columns: TableColumn<TaskBoard>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            width: 80,
            Cell: ({ original }) => <StatusBadge status={original.status} />,
        },
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ original }) => (
                <>
                    <Link to={`${TASK_BOARDS_PATH}/${original.id}`}>{original.name}</Link>
                    {original.internal && (
                        <Badge className="badge-outline-secondary ms-3" color="secondary">
                            Internal
                        </Badge>
                    )}
                </>
            ),
        },
        {
            Header: 'Edited',
            sortable: false,
            width: 200,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            maxWidth: 50,
            sortable: false,
            Cell: ({ original }) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () => dispatch(push(`${TASK_BOARD_MANAGEMENT_PATH}/${original.id}`)),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Task Boards" />
            </HeaderContainer>
            <BodyContainer helpKey="task_board_list">
                <SelectWithAddon
                    name="Status"
                    className="mb-3"
                    value={status}
                    options={ACTIVE_OR_ARCHIVED}
                    onChange={(value) => setQueryParams({ status: value })}
                    isMulti
                />
                <FormattedTable
                    topToolbar={
                        <ListToolbar onCreate={() => dispatch(push(`${TASK_BOARD_MANAGEMENT_PATH}/new`))} />
                    }
                    columns={columns}
                    loading={loading}
                    data={boards}
                    defaultSorted={[{ orderBy: 'name', direction: 'ASC' }]}
                    showPagination={false}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={<TaskBoardFormContainer redirectTo={returnUrl} afterSubmit={refetch} />}
                />
                <Route
                    path=":id"
                    element={<TaskBoardFormContainer redirectTo={returnUrl} afterSubmit={refetch} />}
                />
            </Routes>
        </>
    );
};

export default TaskBoardList;
