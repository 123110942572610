import { Badge } from 'reactstrap';
import { useFormikContext } from 'formik';
import {
    parsePlaceholders,
    uniquePlaceholders,
} from 'platform/chat/admin/AdminChatAgentList/placeholder.utils';
import { ChatAgentFormModel } from 'platform/chat/chat.types';
import { allowedPlaceholders } from 'platform/chat/useAgentPlaceholders';

interface Props {
    messages: string[];
    showAvailable?: boolean;
}
const ChatAgentPlaceholdersList = ({ messages, showAvailable }: Props) => {
    const formikContext = useFormikContext<ChatAgentFormModel>();
    const requiresAdvertiser = formikContext.values.requiresAdvertiser;
    const definedPlaceholders = allowedPlaceholders(requiresAdvertiser);

    const placeholders = messagesToPlaceholders(messages);

    const badgeColor = (p: string): string => {
        if (showAvailable) {
            return placeholders.includes(p) && definedPlaceholders.includes(p) ? 'success' : 'danger';
        }
        return definedPlaceholders.includes(p) ? 'success' : 'danger';
    };

    const placeholderElements = (
        showAvailable ? uniquePlaceholders([...definedPlaceholders, ...placeholders]) : placeholders
    ).map((p, index) => (
        <Badge key={`placeholder-${index}`} color={badgeColor(p)}>
            {p}
        </Badge>
    ));

    return <>{placeholderElements.length > 0 && <>Placeholders: {placeholderElements}</>}</>;
};

const messagesToPlaceholders = (messages: string[]): string[] => {
    try {
        return uniquePlaceholders(messages.map((msg) => parsePlaceholders(msg)).flat());
    } catch {
        return [];
    }
};

export default ChatAgentPlaceholdersList;
