import { makeOptions } from 'platform/common/utils/option.util';

export enum CampaignState {
    NEW = 'NEW',
    RUNNING = 'RUNNING',
    PAUSED = 'PAUSED',
    STOPPED = 'STOPPED',
    CANCELLED = 'CANCELLED',
    ARCHIVED = 'ARCHIVED',
}

export const CAMPAIGN_STATE_LABEL: {
    [key in CampaignState]: string;
} = Object.freeze({
    [CampaignState.NEW]: 'New',
    [CampaignState.RUNNING]: 'Running',
    [CampaignState.PAUSED]: 'Paused',
    [CampaignState.STOPPED]: 'Stopped',
    [CampaignState.CANCELLED]: 'Cancelled',
    [CampaignState.ARCHIVED]: 'Archived',
});

export const CAMPAIGN_STATE_OPTIONS = makeOptions(CAMPAIGN_STATE_LABEL);
