import classNames from 'classnames';
import { ColumnDefinitionOption } from 'platform/analytics/analytics.types';
import { getColumnDefinitionOptions } from 'platform/analytics/analytics.util';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import { useCompatibleColumns } from 'platform/analytics/hooks/useCompatibleColumns';
import { useMetricsWithData } from 'platform/analytics/hooks/useMetricsWithData';
import { filterOptionAdvanced } from 'platform/analytics/reportComponents/listFilter';
import SelectTreeOptionWithTooltip from 'platform/common/components/SelectTree/SelectTreeOptionWithTooltip';
import { CURRENCY_SYMBOL } from 'platform/common/constants/common.constant';
import { sortByName } from 'platform/common/utils/array.util';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';
import MediaInsertionPopoverButton from 'platform/mediaplan/components/MediaplanTreeForm/MediaInsertionPopoverButton';
import { MEDIA_INSERTION_KPI_METRICS } from 'platform/mediaplan/mediaplan.constant';
import './MediaNode.scss';

interface Props {
    field: string;
    targetKpiValue: number | undefined;
    targetKpiMetric: string | undefined;
}

const MediaInsertionTargetKpi = ({ field, targetKpiValue, targetKpiMetric }: Props) => {
    const { showMetricsWithData, setShowOnlyMetricsWithData } = useMetricsWithData();
    const template = useAnalyticsTemplate('all_columns');
    const { columnGroups } = useAnalyticsMetadata();
    const { metricOptions, compatibleColumnsLoading } = useCompatibleColumns({ template, columns: [] });
    const compatibleMetrics = getColumnDefinitionOptions(
        metricOptions.flatMap((o) => o.nodes).filter((o) => MEDIA_INSERTION_KPI_METRICS.includes(o.key)),
        columnGroups
    );

    return (
        <MediaInsertionPopoverButton
            iconClassName={classNames('fa-bullseye', {
                'text-success': !!targetKpiMetric && !!targetKpiValue,
            })}
            style={{ width: 400 }}
        >
            {() => (
                <>
                    <div className="MediaNode-popoverTitle">Target types</div>
                    <div className="mb-2">Target KPI metric</div>
                    <FormSelectTree
                        name={`${field}.targetKpiMetric`}
                        className="mb-3"
                        options={compatibleMetrics.map((option) => ({
                            ...option,
                            nodes: sortByName(option.nodes ?? []).map((o) => ({
                                ...o,
                                disabled: compatibleColumnsLoading,
                            })),
                        }))}
                        filterOption={(option: ColumnDefinitionOption, input) =>
                            filterOptionAdvanced(option.key, option.name, input) &&
                            showMetricsWithData(option.key)
                        }
                        onHasDataFilterChange={setShowOnlyMetricsWithData}
                        components={{
                            Option: SelectTreeOptionWithTooltip,
                        }}
                        getOptionParentLabel={(option: ColumnDefinitionOption) => option.name}
                        getOptionLabel={(option: ColumnDefinitionOption) => option.name}
                        getOptionValue={(option: ColumnDefinitionOption) => option.key}
                        isBreadcrumbsShown={false}
                        isLoading={compatibleColumnsLoading}
                    />
                    <div className="mb-2">Target KPI value (Including AE fee)</div>
                    <FormNumberInput name={`${field}.targetKpiValue`} type="number" label={CURRENCY_SYMBOL} />
                </>
            )}
        </MediaInsertionPopoverButton>
    );
};

export default MediaInsertionTargetKpi;
