const generateGASWithoutExternalFeatures = ({ settings, code }: { settings?: string; code?: string }) => {
    const containerCode = `
${settings?.replace('return accounts;', '')}
${code}

function executeInParallel() {
    executeScript(accounts)
}

function main() {
  var accountSelector = AdsManagerApp.accounts().withIds(accounts.map(function (account) { return account.Account_Id }));
  accountSelector.executeInParallel("executeInParallel");
}
`;
    return containerCode;
};

export default generateGASWithoutExternalFeatures;
