import { COLUMN_TYPE_LABELS, ColumnType } from 'platform/analytics/analytics.types';
import AnalyticsReportUsageTable from 'platform/analyticsReports/AnalyticsReportUsageTable';
import Popover from 'platform/common/components/Popover/Popover';

interface Props {
    columnKey: string;
    columnType: ColumnType;
}

const ColumnUsagePopover = ({ columnKey, columnType }: Props) => (
    <Popover
        placement="bottom"
        preventOverflow
        renderPopover={() => (
            <AnalyticsReportUsageTable
                title={`Analytics reports that use this ${COLUMN_TYPE_LABELS[columnType]}`}
                subtitle="We consider only pinned or system reports that are active"
                filter={{ olapColumn: columnKey, hasSectionOrSystemKey: true, status: ['ACTIVE'] }}
            />
        )}
    >
        <div role="button" tabIndex={-1} className="text-info">
            Show usage in analytics
        </div>
    </Popover>
);

export default ColumnUsagePopover;
