import { Feature } from 'platform/app/app.types';
import { isDefined } from 'platform/common/common.types';
import Popover from 'platform/common/components/Popover/Popover';
import { featureName, Module, MODULE_CONFIG } from 'platform/userManagement/constants/roles.constant';
import './RoleEditList.scss';

const dependencyInfoBlock = (header: string, dependencies?: Feature[]) =>
    dependencies?.length ? { header, content: dependencies.map(featureName) } : undefined;

const RoleInfoPopover = ({ module }: { module: Module }) => {
    const { description, dependencies } = MODULE_CONFIG[module];

    const infoBlocks = [
        description,
        dependencyInfoBlock('View permission also requires:', dependencies?.view),
        dependencyInfoBlock('View & Edit permission also requires:', dependencies?.edit),
    ].filter(isDefined);

    if (!infoBlocks.length) {
        return null;
    }

    return (
        <Popover
            className="d-inline-block outline-0 text-primary ms-1"
            preventOverflow
            renderPopover={() => (
                <>
                    {infoBlocks.map(({ header, content }, blockIndex) => (
                        <div className="px-3 py-2" style={{ fontSize: '0.825rem' }} key={blockIndex}>
                            <strong>{header}</strong>
                            {content && (
                                <ul className="m-0 ps-3">
                                    {content.map((line, lineIndex) => (
                                        <li className="mb-0" key={lineIndex}>
                                            {line}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </>
            )}
        >
            <i className="fa fa-info-circle" />
        </Popover>
    );
};

export default RoleInfoPopover;
