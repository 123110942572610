import { ComponentType } from 'react';
import MaintenanceModeForm from './MaintenanceModeForm';

export type SystemSettingKey = 'MAINTENANCE_MODE';

export type SystemSetting = {
    key: SystemSettingKey;
    value: { [key: string]: any };
    createdOn: string;
    updatedOn: string;
    updatedBy: string;
};

export const SYSTEM_SETTING_TYPES: Record<SystemSettingKey, { name: string; FormComponent: ComponentType }> =
    {
        MAINTENANCE_MODE: { name: 'System Notice', FormComponent: MaintenanceModeForm },
    };
