import { Col } from 'reactstrap';
import { useFormikContext } from 'formik';
import { CampaignModel } from 'platform/campaign/campaign/campaign.types';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import MediaInsertionLinksTable from './MediaInsertionLinksTable';

type Props = {
    campaign: CampaignModel;
};

const CampaignMediaplanSettings = ({ campaign }: Props) => {
    const { setFieldValue } = useFormikContext<CampaignModel>();

    return (
        <ControlledCard title="Media insertions" bodyClassName="form-content">
            <Col xl="12">
                <div>
                    Media insertions are automatically linked by matching runtime and Classification{' '}
                    <i>after</i> saving the campaign.
                </div>
                <div className="mb-3">
                    You can also manually assign media insertions that do not match by Classification. Manual
                    assignments override automatic ones for specific flight dates.
                </div>
                <MediaInsertionLinksTable
                    advertiserId={campaign.advertiserId}
                    mediaInsertionLinks={campaign.mediaInsertionLinks}
                    onChange={(links) => setFieldValue('mediaInsertionLinks', links)}
                />
            </Col>
        </ControlledCard>
    );
};

export default CampaignMediaplanSettings;
