export type AlgorithmArea =
    | 'BUDGET'
    | 'REMARKETING'
    | 'HEALTH_CHECKS'
    | 'AUDIENCE'
    | 'VIEWABILITY'
    | 'KEYWORD'
    | 'BIDDING'
    | 'BRAND_PROTECTION'
    | 'ADVERTISER'
    | 'SILO'
    | 'AFFILIATE'
    | 'BOT';

export type AlgorithmSection =
    | 'ALL'
    | 'DISPLAY_VIDEO'
    | 'BIDDING'
    | 'SEARCH'
    | 'DATA_PIPELINE'
    | 'CROSS_CHANNEL'
    | 'QUALITY_ASSURANCE';

export type AlgorithmLanguage =
    | 'JAVASCRIPT'
    | 'PYTHON'
    | 'SQL'
    | 'HIVE'
    | 'JAVA'
    | 'KOTLIN'
    | 'MYSQL'
    | 'PLAIN_TEXT'
    | 'R'
    | 'SAS'
    | 'SCALA'
    | 'XML'
    | 'JSON'
    | 'BIG_QUERY'
    | 'GOOGLE_AD_SCRIPT'
    | 'YAML';

export const ALGORITHM_LANGUAGE_VALUE: Record<AlgorithmLanguage, string> = {
    JAVASCRIPT: 'javascript',
    GOOGLE_AD_SCRIPT: 'javascript',
    JSON: 'javascript',
    PYTHON: 'python',
    SQL: 'text/x-sql',
    HIVE: 'text/x-sql',
    JAVA: 'text/x-java',
    KOTLIN: 'text/x-java',
    MYSQL: 'text/x-mysql',
    PLAIN_TEXT: 'text',
    R: 'text/x-rsrc',
    SAS: 'text/x-sas',
    SCALA: 'text/x-scala',
    XML: 'application/xml',
    BIG_QUERY: 'text/x-sql',
    YAML: 'text/x-yaml',
};

export const ALGORITHM_AREA_LABELS: Record<AlgorithmArea, string> = {
    BUDGET: 'Budget',
    AUDIENCE: 'Audience',
    REMARKETING: 'Remarketing',
    HEALTH_CHECKS: 'Health Checks',
    VIEWABILITY: 'Viewability',
    KEYWORD: 'Keyword',
    BIDDING: 'Bidding',
    BRAND_PROTECTION: 'Brand Protection',
    ADVERTISER: 'Advertiser',
    SILO: 'Silo',
    AFFILIATE: 'Affiliate',
    BOT: 'Bot',
};

export const ALGORITHM_SECTION_LABELS: Record<AlgorithmSection, string> = {
    ALL: 'All',
    BIDDING: 'Bidding',
    DISPLAY_VIDEO: 'Display / Video',
    SEARCH: 'Search',
    CROSS_CHANNEL: 'Cross-Channel and Shopping',
    QUALITY_ASSURANCE: 'Quality Assurance',
    DATA_PIPELINE: 'Data Pipeline',
};

export const ALGORITHM_LANGUAGE_LABELS: Record<AlgorithmLanguage, string> = {
    JAVASCRIPT: 'Javascript',
    GOOGLE_AD_SCRIPT: 'Google ad script',
    JSON: 'JSON',
    PYTHON: 'Python',
    SQL: 'SQL',
    HIVE: 'Hive',
    JAVA: 'Java',
    KOTLIN: 'Kotlin',
    MYSQL: 'MySQL',
    PLAIN_TEXT: 'Plain text',
    R: 'R',
    SAS: 'SAS',
    SCALA: 'Scala',
    XML: 'XML',
    BIG_QUERY: 'Big Query',
    YAML: 'YAML',
};

export const ALGORITHM_BADGE_TYPE: Record<AlgorithmArea, string> = {
    BUDGET: 'primary',
    AUDIENCE: 'success',
    REMARKETING: 'indigo',
    HEALTH_CHECKS: 'info',
    VIEWABILITY: 'cyan',
    KEYWORD: 'danger',
    BIDDING: 'teal',
    BRAND_PROTECTION: 'yellow',
    ADVERTISER: 'warning',
    SILO: 'light-blue',
    AFFILIATE: 'cadet-blue',
    BOT: 'light-slate-gray',
};

export const ALGORITHM_SECTION_ICON: Record<AlgorithmSection, string> = {
    ALL: 'fal fa-layer-group',
    DISPLAY_VIDEO: 'fal fa-display',
    BIDDING: 'fal fa-gavel',
    SEARCH: 'fal fa-magnifying-glass-arrow-right',
    DATA_PIPELINE: 'fal fa-arrow-down-short-wide',
    CROSS_CHANNEL: 'fal fa-circle',
    QUALITY_ASSURANCE: 'fal fa-award',
};

export const ALGORITHM_SECTION_COLOR: Record<AlgorithmSection, string> = {
    ALL: '#1083D6',
    DISPLAY_VIDEO: '#F0A209',
    BIDDING: '#17D390',
    SEARCH: '#A564F8',
    DATA_PIPELINE: '#E075BC',
    CROSS_CHANNEL: '#FF6F61',
    QUALITY_ASSURANCE: '#46CAE7',
};
