import { getDuration } from 'platform/common/utils/date.util';
import './JourneyItemSeparator.scss';

interface Props {
    startTime: string;
    endTime: string;
}

const JourneyItemSeparator = ({ startTime, endTime }: Props) => (
    <div className="JourneyItemSeparator">
        <div className="border-top" />
        <div className="JourneyItemSeparator-text text-muted">
            {getDuration(startTime, endTime).humanize()}
        </div>
    </div>
);

export default JourneyItemSeparator;
