import { FormEvent, useState } from 'react';
import { Button, Form, Input, InputGroup, InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Popover from 'platform/common/components/Popover/Popover';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import {
    ExternalSystem,
    getIntegrationOptions,
    SYNCABLE_INTEGRATIONS,
} from 'platform/common/constants/externalIntegration.constant';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { formatDuration, formatPreciseDateTime } from 'platform/common/utils/date.util';
import SyncStatusIcon from './SyncStatusIcon';
import SyncSystemIcon from './SyncSystemIcon';
import {
    SYNC_ENTITY_TYPE_OPTIONS,
    SYNC_REQUEST_TYPE_OPTIONS,
    SYNC_STATUS_OPTIONS,
    syncKey,
    syncLabel,
} from './syncJournal.constant';
import { fetchSyncs } from './syncJournal.service';
import { Sync, SyncEntityType, SyncRequestType, SyncStatus } from './syncJournal.types';

export const SyncJournal = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const [dateFrom, setDateFrom] = useState<string | undefined>(moment().format(ISO_DATE_FORMAT));
    const [dateTo, setDateTo] = useState<string | undefined>(moment().format(ISO_DATE_FORMAT));
    const [entityType, setEntityType] = useState<SyncEntityType>();
    const [requestTypes, setRequestTypes] = useState<SyncRequestType[]>();
    const [internalId, setInternalId] = useState<number>();
    const [externalSystem, setExternalSystem] = useState<ExternalSystem>();
    const [status, setStatus] = useState<SyncStatus>('ERROR');
    const [loading, setLoading] = useState(false);
    const [syncs, setSyncs] = useState<Sync[]>([]);

    const columnDefinitions: TableColumn<Sync>[] = [
        {
            Header: 'Request id',
            accessor: 'requestId',
            width: 110,
            Cell: ({ original: sync }: TableCell<Sync>) => `${sync.requestId}`,
        },
        {
            Header: 'Direction',
            accessor: 'direction',
            width: 80,
            Cell: ({ original: sync }: TableCell<Sync>) =>
                `${sync.direction === 'IMPORT' ? 'Import' : 'Export'}`,
        },
        {
            Header: 'Entity',
            sortable: false,
            width: 160,
            Cell: ({ original: sync }: TableCell<Sync>) => (
                <>
                    <div>{syncLabel(sync)}</div>
                    <SyncStatusIcon
                        sync={sync}
                        targetId={`SyncJournalList-${syncKey(sync)}`}
                        placement="auto"
                    />
                </>
            ),
        },
        {
            Header: 'DAP id',
            accessor: 'internalId',
            width: 110,
        },
        {
            Header: 'External id',
            accessor: 'externalId',
            maxWidth: 190,
            Cell: ({ original: sync }: TableCell<Sync>) => (
                <>
                    <SyncSystemIcon system={sync.externalSystem} />
                    &nbsp;&nbsp;
                    {sync.externalId}
                </>
            ),
        },
        {
            Header: 'Trigger',
            accessor: 'requestReason',
            maxWidth: 300,
            Cell: ({ original: sync }: TableCell<Sync>) =>
                sync.requestReason ? (
                    <Popover
                        className="text-truncate"
                        preventOverflow
                        renderPopover={() => (
                            <div className="p-2">
                                <b>Sync trigger: </b>
                                {sync.requestReason}
                            </div>
                        )}
                    >
                        <span className="text-truncate">{sync.requestReason}</span>
                    </Popover>
                ) : null,
        },
        {
            Header: 'Errors / warnings',
            accessor: 'errorMessage',
            Cell: ({ original: sync }: TableCell<Sync>) =>
                sync.errorMessage || sync.warnings ? (
                    <Popover
                        className={classNames('text-truncate', { 'text-danger': !!sync.errorMessage })}
                        preventOverflow
                        renderPopover={() => (
                            <div className="p-2">
                                <b>{sync.errorMessage ? 'Error: ' : 'Warning: '}</b>
                                {sync.errorMessage || sync.warnings}
                            </div>
                        )}
                    >
                        <i className="fa fa-eye pe-2" />
                        {sync.errorMessage || sync.warnings}
                    </Popover>
                ) : null,
        },
        {
            Header: 'Started',
            accessor: 'requestTimestampUtc',
            width: 170,
            Cell: ({ original: sync }: TableCell<Sync>) => formatPreciseDateTime(sync.requestTimestampUtc),
        },
        {
            Header: 'Completed',
            accessor: 'lastUpdateTimestampUtc',
            width: 170,
            Cell: ({ original: sync }: TableCell<Sync>) =>
                sync.status !== 'IN_PROGRESS' ? formatPreciseDateTime(sync.lastUpdateTimestampUtc) : null,
        },
        {
            Header: 'Duration',
            width: 80,
            Cell: ({ original: sync }: TableCell<Sync>) =>
                sync.status !== 'IN_PROGRESS'
                    ? formatDuration(sync.requestTimestampUtc, sync.lastUpdateTimestampUtc)
                    : null,
        },
    ];

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            setSyncs(
                await fetchSyncs({
                    advertiserId,
                    dateFrom: dateFrom ? `${dateFrom}T00:00:00Z` : undefined,
                    dateTo: dateTo ? `${dateTo}T23:59:59Z` : undefined,
                    externalSystem: externalSystem || undefined,
                    status: status || undefined,
                    entityType: entityType || undefined,
                    requestType: requestTypes?.length ? requestTypes : undefined,
                    internalId: internalId ? [internalId] : undefined,
                    limit: 1000,
                })
            );
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <BodyContainer helpKey="sync_journal">
                <Form onSubmit={onSubmit} className="mb-3">
                    <div className="d-flex">
                        <DateRangePicker
                            className="mb-2 filter"
                            from={dateFrom || undefined}
                            to={dateTo || undefined}
                            onChange={(from, to) => {
                                setDateFrom(from);
                                setDateTo(to);
                            }}
                        />
                    </div>
                    <ListFilters className="mb-2">
                        <SelectWithAddon
                            name="Integration"
                            value={externalSystem}
                            onChange={setExternalSystem}
                            options={getIntegrationOptions(SYNCABLE_INTEGRATIONS)}
                            isClearable
                        />
                        <SelectWithAddon
                            name="Status"
                            value={status}
                            onChange={setStatus}
                            options={SYNC_STATUS_OPTIONS}
                            isClearable
                        />
                        <SelectWithAddon
                            name="DAP entity type"
                            value={entityType}
                            onChange={setEntityType}
                            options={SYNC_ENTITY_TYPE_OPTIONS}
                            selectStyle={{ minWidth: 200 }}
                            isClearable
                        />
                        <SelectWithAddon
                            name="Synced attributes"
                            value={requestTypes}
                            onChange={setRequestTypes}
                            options={SYNC_REQUEST_TYPE_OPTIONS}
                            selectStyle={{ minWidth: 200 }}
                            isMulti
                            isClearable
                        />
                        <InputGroup>
                            <InputGroupText>DAP ID</InputGroupText>
                            <Input
                                type="text"
                                value={internalId || ''}
                                onChange={(e) => setInternalId(Number(e.target.value) || undefined)}
                            />
                        </InputGroup>
                    </ListFilters>
                    <Button type="submit">
                        <i className="fa fa-search me-2" />
                        Search
                    </Button>
                </Form>

                <FormattedTable columns={columnDefinitions} data={syncs} className="m-3" />
                {loading && <OverlayLoader />}
            </BodyContainer>
        </>
    );
};
