import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import './TableLoadingIndicator.scss';

interface Props {
    loading: boolean;
}

const rowPlaceholder = (width: string) => (
    <div className="rt-tr-group">
        <div className="rt-tr">
            <div className="rt-td" style={{ flex: '0 0 auto', width: 100 }}>
                <Placeholder height="13px" width="50px" />
            </div>
            <div className="rt-td">
                <Placeholder height="13px" width={width} />
            </div>
        </div>
    </div>
);

const TablePlaceholder = ({ loading }: Props) =>
    loading ? (
        <div className="ReactTable-placeholder">
            <div className="ReactTable-placeholder-inner">
                <div className="rt-tbody">
                    {rowPlaceholder('200px')}
                    {rowPlaceholder('250px')}
                    {rowPlaceholder('300px')}
                    {rowPlaceholder('200px')}
                    {rowPlaceholder('250px')}
                    {rowPlaceholder('300px')}
                    {rowPlaceholder('200px')}
                    {rowPlaceholder('250px')}
                    {rowPlaceholder('300px')}
                    {rowPlaceholder('200px')}
                    {rowPlaceholder('250px')}
                    {rowPlaceholder('300px')}
                </div>
            </div>
        </div>
    ) : null;

export default TablePlaceholder;
