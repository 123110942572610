import { Context } from 'platform/analytics/reportComponents/ReportTableContainer/InlineActions/InlineActions.types';
import InlineActionsForm from 'platform/analytics/reportComponents/ReportTableContainer/InlineActions/InlineActionsForm';
import { IntegrationAttributes } from 'platform/campaign/campaign/campaign.types';
import { CampaignState } from 'platform/campaign/campaign/constants/state.constant';
import campaignService, { bulkUpdateCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { changeStrategyState } from 'platform/campaign/strategy/services/strategy.service';
import { UnitState } from 'platform/campaign/unit/constants/state.constant';
import unitService from 'platform/campaign/unit/services/units.service';
import { isDefined } from 'platform/common/common.types';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { precisionRound } from 'platform/common/utils/formatters.util';

enum BidChangeType {
    SET = 'SET',
    DECREASE = 'DECREASE',
    INCREASE = 'INCREASE',
}

const incBid =
    (percentage: number, limit = 10000) =>
    (bid: number) => {
        if (bid >= limit) return bid;
        const newBid = bid * (percentage / 100 + 1);
        if (newBid >= limit) return limit;
        return precisionRound(newBid, 2);
    };

const decBid =
    (percentage: number, limit = 0.01) =>
    (bid: number) => {
        if (bid <= limit) return bid;
        const newBid = bid * (1 - percentage / 100);
        if (newBid <= limit) return limit;
        return precisionRound(newBid, 2);
    };

const makeBidUpdater =
    (updater: (value: number) => number) =>
    ({ id, bidCpm }: { id: number; bidCpm: number }) => ({ id, bidCpm: updater(bidCpm ?? 0) });

const getMaxBid = (units: Context['units'] = []) => {
    if (!units.length) {
        return undefined;
    }

    return Math.max(...units.map((u) => u.bidCpm).filter(isDefined));
};

const getMinBid = (units: Context['units'] = []) => {
    if (!units.length) {
        return undefined;
    }

    return Math.min(...units.map((u) => u.bidCpm).filter(isDefined));
};

export type FormModel = {
    strategyState?: string;
    campaignState?: CampaignState;
    campaignBudget?: number;
    campaignDailyBudget?: number;
    campaignAttributes?: IntegrationAttributes;
    externalSystem?: ExternalSystem;
    unitState?: UnitState;
    unitBid: {
        typeOfChange: BidChangeType;
        bidCpm?: string;
        limit?: string;
        changeByPercent?: string;
    };
    unitBudget?: string;
    unitDailyBudget?: string;
};

type Props = {
    context: Context;
    inlineActions: string[];
    onCancel: () => void;
    onSubmitFinished: () => void;
    onSubmitStart: () => void;
};

const InlineActionsFormContainer = ({
    context,
    inlineActions,
    onCancel,
    onSubmitStart,
    onSubmitFinished,
}: Props) => {
    if (!context) {
        return null;
    }

    const { units = [], campaign, strategy } = context;
    const minBid = getMinBid(units);
    const maxBid = getMaxBid(units);
    const initialBid = minBid && maxBid && minBid === maxBid ? String(minBid) : undefined;

    const initialValues: FormModel = {
        campaignState: campaign?.state,
        campaignBudget: campaign?.budget,
        campaignDailyBudget: campaign?.dailyBudget,
        campaignAttributes: campaign?.attributes,
        externalSystem: campaign?.externalSystem,
        strategyState: strategy?.state,
        unitState:
            units.length > 0 && units.every((unit) => unit.state === units[0].state)
                ? units[0].state
                : undefined,
        unitBid: {
            typeOfChange: BidChangeType.SET,
            bidCpm: initialBid,
            limit: undefined,
            changeByPercent: undefined,
        },
        unitBudget: units.length === 1 ? String(units[0].budget) : undefined,
        unitDailyBudget: units.length === 1 ? String(units[0].dailyBudget) : undefined,
    };

    const handleSubmit = async (formData: FormModel) => {
        onSubmitStart();

        if (
            inlineActions.includes('change_campaign_state') &&
            campaign &&
            formData.campaignState &&
            formData.campaignState !== initialValues.campaignState
        ) {
            await campaignService.changeCampaignState(campaign.id, formData.campaignState);
        }

        if (inlineActions.includes('change_campaign_budget') && campaign) {
            const { campaignBudget, campaignDailyBudget } = formData;
            if (
                (campaignBudget && campaignBudget !== initialValues.campaignBudget) ||
                (campaignDailyBudget && campaignDailyBudget !== initialValues.campaignDailyBudget)
            ) {
                await bulkUpdateCampaigns({
                    campaigns: [
                        {
                            id: campaign.id,
                            budget: campaignBudget ? parseFloat(String(campaignBudget)) : undefined,
                            dailyBudget: campaignDailyBudget
                                ? parseFloat(String(campaignDailyBudget))
                                : undefined,
                        },
                    ],
                });
            }
        }

        if (
            inlineActions.includes('change_strategy_state') &&
            strategy &&
            formData.strategyState &&
            formData.strategyState !== initialValues.strategyState
        ) {
            await changeStrategyState(strategy.id, formData.strategyState);
        }

        if (inlineActions.includes('change_unit_bid')) {
            if (formData.unitBid.typeOfChange === BidChangeType.SET) {
                if (formData.unitBid.bidCpm && formData.unitBid.bidCpm !== initialValues.unitBid.bidCpm) {
                    const bidCpm = parseFloat(formData.unitBid.bidCpm);
                    await unitService.bulkChangeUnits(units.map(makeBidUpdater(() => bidCpm)));
                }
            } else if (formData.unitBid.typeOfChange === BidChangeType.INCREASE) {
                if (formData.unitBid.changeByPercent) {
                    const changeByPercent = parseFloat(formData.unitBid.changeByPercent);
                    const limit = formData.unitBid.limit ? parseFloat(formData.unitBid.limit) : undefined;
                    await unitService.bulkChangeUnits(
                        units.map(makeBidUpdater(incBid(changeByPercent, limit)))
                    );
                }
            } else if (formData.unitBid.typeOfChange === BidChangeType.DECREASE) {
                if (formData.unitBid.changeByPercent) {
                    const changeByPercent = parseFloat(formData.unitBid.changeByPercent);
                    const limit = formData.unitBid.limit ? parseFloat(formData.unitBid.limit) : undefined;

                    await unitService.bulkChangeUnits(
                        units.map(makeBidUpdater(decBid(changeByPercent, limit)))
                    );
                }
            }
        }

        if (
            inlineActions.includes('change_unit_budget') ||
            inlineActions.includes('change_unit_daily_budget')
        ) {
            const { unitBudget, unitDailyBudget } = formData;
            if (
                (unitBudget && unitBudget !== initialValues.unitBudget) ||
                (unitDailyBudget && unitDailyBudget !== initialValues.unitDailyBudget)
            ) {
                await unitService.bulkChangeUnits(
                    units.map((u) => ({
                        id: u.id,
                        budget: unitBudget ? parseFloat(unitBudget) : undefined,
                        dailyBudget: unitDailyBudget ? parseFloat(unitDailyBudget) : undefined,
                    }))
                );
            }
        }

        if (
            inlineActions.includes('change_unit_state') &&
            units.length &&
            !units.some((unit) => unit.stateLocked) &&
            formData.unitState &&
            formData.unitState !== initialValues.unitState
        ) {
            await unitService.changeUnitStates(
                units.map((u) => u.id),
                formData.unitState
            );
        }

        onSubmitFinished();
    };

    return (
        <InlineActionsForm
            inlineActions={inlineActions}
            initialValues={initialValues}
            units={units}
            minBid={minBid}
            maxBid={maxBid}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    );
};

export default InlineActionsFormContainer;
