import classNames from 'classnames';

interface Props {
    iconClassName: string;
    label: string;
    value: string;
    className?: string;
}

const JourneyRowClassification = ({ className, iconClassName, label, value }: Props) => (
    <div className={classNames('font-sm', className)}>
        <i className={classNames('text-success text-center', iconClassName)} style={{ width: 30 }} />
        {label}:<span className="ms-1">{value}</span>
    </div>
);

export default JourneyRowClassification;
