import axiosBff from 'platform/axios/axiosBff';
import { sortByName } from 'platform/common/utils/array.util';
import { Channel } from './channel.types';

export const saveChannel = (channel: Channel) =>
    axiosBff.put<void>(`/admin/api/channels/${channel.code}`, channel);

export const fetchChannel = (code: string) =>
    axiosBff.get<Channel>(`/admin/api/channels/${code}`).then((r) => r.data);

export const fetchChannels = () =>
    axiosBff.get<Channel[]>('/admin/api/channels').then((r) => sortByName(r.data));
