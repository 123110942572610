import { Row, Col } from 'reactstrap';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InfoIconAlert from 'platform/common/components/InfoIconAlert/InfoIconAlert';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import useMasterAdvertiser from 'platform/common/hooks/useMasterAdvertiser';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { getPeriodForLast30Days, getCompareRange } from 'platform/common/utils/date.util';
import { AUDIENCES } from 'platform/dashboard/dashboard.navigation';
import IntegrationsCard from './IntegrationsCard';
import SegmentInfoCard from './SegmentInfoCard';
import UserFlowCard from './UserFlowCard';
import UsersByDevicesCard from './UsersByDevicesCard';
import UsersByHourCard from './UsersByHourCard';
import UsersByWeekdaysCard from './UsersByWeekdaysCard';

const period = getPeriodForLast30Days();

const getDefaultPeriods = (): Periods => {
    const { from, to } = period;
    const { from: secondaryFrom, to: secondaryTo } = getCompareRange({
        primaryFrom: from,
        primaryTo: to,
        secondaryPreset: 'PREVIOUS_PERIOD',
    });

    return {
        primaryFrom: from,
        primaryTo: to,
        secondaryTo,
        secondaryFrom,
        preset: undefined,
    };
};

const CdpDashboard = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const isAdmin = useTypedSelector(authSelectors.isAdmin);
    const { masterAdvertiser, resolvedAdvertiserId } = useMasterAdvertiser(advertiserId);

    return (
        <>
            <HeaderContainer>
                <PageHeader title={AUDIENCES.name} />
            </HeaderContainer>

            <BodyContainer helpKey="cdp_dashboard">
                {masterAdvertiser && isAdmin && (
                    <InfoIconAlert className="mb-3">
                        This data is retrieved from {masterAdvertiser?.name}
                    </InfoIconAlert>
                )}
                <SegmentInfoCard advertiserId={resolvedAdvertiserId} periods={getDefaultPeriods()} />
                <UserFlowCard advertiserId={resolvedAdvertiserId} />
                <Row>
                    <Col xl={4} className="mb-4">
                        <UsersByDevicesCard advertiserId={resolvedAdvertiserId} />
                    </Col>
                    <Col xl={4} className="mb-4">
                        <UsersByWeekdaysCard advertiserId={resolvedAdvertiserId} />
                    </Col>
                    <Col xl={4} className="mb-4">
                        <UsersByHourCard advertiserId={resolvedAdvertiserId} />
                    </Col>
                </Row>
                <IntegrationsCard advertiserId={resolvedAdvertiserId} />
            </BodyContainer>
        </>
    );
};

export default CdpDashboard;
