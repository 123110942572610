import { makeOptions } from 'platform/common/utils/option.util';

export type IntegrationType = 'RTB' | 'REDIRECT' | 'TRADITIONAL';

export const INTEGRATION_TYPE_LABELS: { [key in IntegrationType]: string } = Object.freeze({
    RTB: 'Programmatic',
    REDIRECT: 'Insertion order',
    TRADITIONAL: 'Traditional',
});

export type MediaType = 'DIGITAL' | 'TRADITIONAL';

export const MEDIA_TYPE_LABELS: { [key in MediaType]: string } = Object.freeze({
    DIGITAL: 'Digital',
    TRADITIONAL: 'Traditional',
});

export const MEDIA_TYPE_OPTIONS = makeOptions(MEDIA_TYPE_LABELS);
