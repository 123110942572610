import { makeOptions } from 'platform/common/utils/option.util';

export type UserStatus = 'ACTIVE' | 'INACTIVE' | 'ARCHIVED' | 'PENDING' | 'PENDING_INACTIVE';

const USER_STATUS_LABELS: Record<UserStatus, string> = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    ARCHIVED: 'Archived',
    PENDING: 'Pending',
    PENDING_INACTIVE: 'Pending Inactive',
};

export const USER_STATUS_OPTIONS = makeOptions(USER_STATUS_LABELS);
