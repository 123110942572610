import { Children, ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    children: ReactNode;
    className?: string;
    childrenClassName?: string;
}

const ListFilters = ({ children, className, childrenClassName }: Props) => (
    <div className={classNames('d-flex flex-wrap', className)} style={{ gap: '1rem' }}>
        {Children.map(
            children,
            (child) => !!child && <div className={classNames('d-flex', childrenClassName)}>{child}</div>
        )}
    </div>
);

export default ListFilters;
