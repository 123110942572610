import { STATUS_OPTIONS, STATUS_TRANSITIONS } from 'platform/common/constants/status.constant';
import { Validator } from 'platform/common/utils/validators.util';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

type Props = {
    initialValue?: string;
    options?: { label: string; value: string }[];
    transitions?: { [key: string]: string[] };
    id?: string;
    disabled?: boolean;
    name: string;
    validate?: Validator | Validator[];
};

const FormStateSelect = ({
    options = STATUS_OPTIONS,
    transitions = STATUS_TRANSITIONS,
    initialValue,
    id,
    disabled = false,
    name,
    validate,
}: Props) => {
    const getOptions = (currentValue?: string) => {
        if (!currentValue) {
            return options;
        }

        const availableTransitions = transitions[currentValue];
        return options.map((option) => ({
            ...option,
            disabled: !availableTransitions.includes(option.value) && option.value !== initialValue,
        }));
    };

    return (
        <FormSelect
            id={id || name}
            name={name}
            options={getOptions(initialValue)}
            isSearchable={false}
            isClearable={false}
            disabled={disabled}
            validate={validate}
        />
    );
};

export default FormStateSelect;
