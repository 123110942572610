import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { isDefined } from 'platform/common/common.types';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { FileInfo } from 'platform/common/utils/file.util';
import creativeImportExport, {
    CreativeImportExportModel,
} from 'platform/creatives/components/CreativeImportExport/creativeImportExport';
import { GcmCreativeModel } from 'platform/creatives/types/creative.types';
import UploadInput from '../../../common/components/UploadInput/UploadInput';
import './CreativeImportModal.scss';

interface Props {
    toggle: () => void;
    creatives: GcmCreativeModel[];
    updateCreatives: (creatives: GcmCreativeModel[]) => void;
}

const CreativeImportModal = ({ toggle, creatives, updateCreatives }: Props) => {
    const [state, setState] = useState<{
        importedCreatives?: CreativeImportExportModel[];
        updatedCreatives?: GcmCreativeModel[];
        loading: boolean;
    }>({ loading: false });

    const parseExcel = async (file: FileInfo) => {
        setState({ loading: true });
        const importedCreatives = await creativeImportExport.fromExcel(file.content as string);
        const updatedCreatives = importedCreatives
            .map(({ id, clickThroughUrl, clickThroughUrlSuffix }) => {
                if (!id || !clickThroughUrl?.trim()) return undefined;
                const original = creatives.find((c) => c.id === id);
                if (!original) return undefined;
                if (
                    (clickThroughUrl?.trim() ?? '') !== (original.clickThroughUrl ?? '') ||
                    (clickThroughUrlSuffix?.trim() ?? '') !== (original.clickThroughUrlSuffix ?? '')
                ) {
                    return {
                        ...original,
                        clickThroughUrl: clickThroughUrl.trim(),
                        clickThroughUrlSuffix: clickThroughUrlSuffix?.trim() ?? undefined,
                    };
                }
                return undefined;
            })
            .filter(isDefined);
        setState({ importedCreatives, updatedCreatives, loading: false });
    };

    return (
        <Modal isOpen toggle={toggle} className="CreativeImportModal">
            <ModalHeader onClose={toggle}>Import landing pages</ModalHeader>
            <ModalBody>
                <UploadInput
                    className="CreativeImportModal-uploadButton"
                    acceptableMimeTypes="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    title="Upload Excel"
                    subtitle={'.xls and .xlsx files are supported'}
                    onFileUpload={parseExcel}
                />
                {(state.updatedCreatives?.length || state.loading) && (
                    <>
                        <div className="mb-2">Landing pages to be changed:</div>
                        <FormattedTable
                            data={state.updatedCreatives}
                            loading={state.loading}
                            className="-striped"
                            columns={creativeImportExport.importedColumns}
                        />
                    </>
                )}
                {!!state.importedCreatives?.length && !state.updatedCreatives?.length && (
                    <div>No landing pages have changed in the uploaded file!</div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={!state.updatedCreatives?.length}
                    onClick={async () => {
                        setState({ loading: true });
                        updateCreatives(state.updatedCreatives ?? []);
                        toggle();
                    }}
                >
                    OK
                </Button>
                <Button className="ms-2" color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreativeImportModal;
