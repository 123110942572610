import { ReactNode, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'reactstrap';
import { preventOverflow } from '@popperjs/core';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import classNames from 'classnames';
import useToggle from 'platform/common/hooks/useToggle';
import './LinkPopover.scss';

export interface PopoverLinkItem {
    label: string;
    link: string;
    icon?: string;
    onClick: () => void;
}

interface Props {
    items: PopoverLinkItem[];
    children: ReactNode;
}

const LinkPopover = ({ items, children }: Props) => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    const targetRef = useRef<HTMLDivElement | null>(null);

    const handleLinkClick = (item: PopoverLinkItem) => {
        if (item.onClick) {
            item.onClick();
        }

        toggleIsOpen();
    };

    return (
        <>
            {children}
            <div
                role="button"
                tabIndex={0}
                className={classNames(
                    'd-flex LinkPopover__icon',
                    isOpen ? 'text-primary' : 'LinkPopover__icon--gray'
                )}
                onClick={() => toggleIsOpen()}
            >
                <span ref={targetRef} data-icon="l" className="d-flex icon me-1 ms-1" />
            </div>
            {targetRef.current && (
                <Popover
                    target={targetRef.current}
                    trigger="legacy"
                    placement="bottom"
                    isOpen={isOpen}
                    flip={false}
                    modifiers={[
                        { ...preventOverflow, options: { altAxis: true } } as PreventOverflowModifier,
                    ]}
                    toggle={toggleIsOpen}
                >
                    {items.map((item) => (
                        <Link
                            key={item.label}
                            className="dropdown-item d-flex align-items-center cursor-pointer"
                            to={item.link}
                            onClick={() => handleLinkClick(item)}
                        >
                            {item.icon && (
                                <span className={classNames('me-2 text-muted d-flex', item.icon)} />
                            )}
                            {item.label}
                        </Link>
                    ))}
                </Popover>
            )}
        </>
    );
};

export default LinkPopover;
