import { isEmpty } from 'lodash-es';
import { OptionType } from '../Select/select.types';

export const isAnyChildNodeSelected = <T extends OptionType>(
    node: T,
    selectedOptions: T[],
    getOptionNodes: (value: T) => any[],
    getOptionValue: (value: T) => any
): boolean => {
    const childNodes = getOptionNodes(node);
    if (childNodes && childNodes.length) {
        return childNodes.some((child) =>
            isAnyChildNodeSelected(child, selectedOptions, getOptionNodes, getOptionValue)
        );
    }

    return selectedOptions.some((value) => getOptionValue(value) === getOptionValue(node));
};

export const flattenNodes = <T extends OptionType>(
    nodes: T[],
    getChildNodes: (value: T) => T[] | undefined
): T[] =>
    nodes.reduce<T[]>((result, node) => {
        const childNodes = getChildNodes(node);
        if (childNodes?.length) {
            return [...result, ...flattenNodes(childNodes, getChildNodes)];
        }
        return [...result, node];
    }, []);

export const filterSelectOptions = <T extends OptionType>(
    options: T[],
    query: string,
    childrenKey: keyof T,
    parent: T | undefined,
    getOptionNodes: (option: T) => any[],
    getOptionLabel: (option: T) => string,
    predicate?: ((option: T, input: string, parent?: T) => boolean) | null
): T[] =>
    options.reduce<T[]>((acc, option) => {
        const label = getOptionLabel(option);
        const nodes = getOptionNodes(option);
        const matchesQuery = !query || label.toLowerCase().includes(query.toLowerCase());
        const matches = predicate ? predicate(option as any, query, parent) : matchesQuery;

        if (isEmpty(nodes)) {
            if (matches) {
                acc.push(option);
            }

            return acc;
        }

        const childOptions = filterSelectOptions(
            nodes,
            query,
            childrenKey,
            option,
            getOptionNodes,
            getOptionLabel,
            predicate
        );

        if (childOptions.length) {
            acc.push({
                ...option,
                [childrenKey]: childOptions,
            });
        } else if (matches) {
            acc.push({
                ...option,
                [childrenKey]: [],
            });
        }

        return acc;
    }, []);

const getPathToNode = <T extends OptionType>(
    options: readonly T[],
    node: T,
    getOptionNodes: (value: T) => any[],
    getOptionValue: (value: T) => any
): T[] => {
    for (let i = 0; i < options.length; i += 1) {
        const option = options[i];
        if (getOptionValue(option) === getOptionValue(node)) {
            return [option];
        }

        const childOptions = getOptionNodes(option);

        if (childOptions && childOptions.length) {
            const pathToNode = getPathToNode(childOptions, node, getOptionNodes, getOptionValue);
            if (pathToNode && pathToNode.length) {
                return [option, ...pathToNode];
            }
        }
    }

    return [];
};

export const getNodeParents = <T extends OptionType>(
    options: readonly T[],
    node: T,
    getOptionNodes: (value: T) => any[],
    getOptionValue: (value: T) => any
): T[] => {
    const pathToNode = getPathToNode(options, node, getOptionNodes, getOptionValue);
    pathToNode.pop();
    return pathToNode;
};
