import { useField } from 'formik';
import { SelectItem } from 'platform/common/common.types';
import { arrayItemMove, arrayItemRemove } from 'platform/common/utils/array.util';
import SortableSelect, { Item } from './SortableSelect';

type Props = {
    name: string;
    options: SelectItem<number>[];
    isLoading?: boolean;
    dragKey: string;
    isDisabled?: boolean;
    className?: string;
    items: Item[];
};

const FormSortableSelect = ({ name, options, isLoading, dragKey, isDisabled, className, items }: Props) => {
    const [{ value }, , { setValue }] = useField({ name });
    const handleRemove = (item: Item) => {
        const index = value.indexOf(item.id);
        setValue(arrayItemRemove(value, index));
    };

    const handleDrop = (moveFrom: Item, moveTo: Item) => {
        const fromIndex = value.indexOf(moveFrom.id);
        const toIndex = value.indexOf(moveTo.id);
        setValue(arrayItemMove(value, fromIndex, toIndex));
    };

    return (
        <SortableSelect
            items={items}
            options={options}
            dragKey={dragKey}
            loading={isLoading}
            disabled={isDisabled}
            className={className}
            onDrop={handleDrop}
            onRemove={handleRemove}
            onAdd={(item) => setValue([...value, item])}
        />
    );
};

export default FormSortableSelect;
