import { ReactNode } from 'react';
import { NavLink as RouterNavLink, Route, Routes } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { CHAT_ADMIN_TABS } from 'platform/chat/chat.constants';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';

const ChatAdminPanelPage = () => {
    const tabs = CHAT_ADMIN_TABS.map((tab) => (
        <NavItem key={tab.url}>
            <NavLink to={tab.url} tag={RouterNavLink}>
                {tab.label}
            </NavLink>
        </NavItem>
    ));

    const routes = CHAT_ADMIN_TABS.reduce<ReactNode[]>((acc, tab) => {
        if (tab.component) {
            acc.push(<Route key={tab.url} path={`${tab.url}/*`} element={<tab.component />} />);
        }
        return acc;
    }, []);

    return (
        <div>
            <HeaderContainer>
                <PageHeader title="DeeplyAI" />
            </HeaderContainer>
            <BodyContainer>
                <div className="custom-tabs">
                    <Nav tabs>{tabs}</Nav>
                </div>
            </BodyContainer>
            <Routes>{routes}</Routes>
        </div>
    );
};

export default ChatAdminPanelPage;
