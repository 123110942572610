import { useSelector } from 'react-redux';
import LoginForm from 'platform/app/components/Login/LoginForm';
import TwoFactorAuthForm from 'platform/app/components/Login/TwoFactorAuthForm';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';

const LoginFormContainer = () => {
    const authState = useSelector(authSelectors.root);
    const loading = authState.type !== 'LOGGED_OUT' && authState.type !== 'VERIFYING_2FA';

    const showTwoFactorForm =
        authState.type === 'VERIFYING_2FA' || authState.type === 'VERIFYING_2FA_PENDING';

    return (
        <>
            {loading && <OverlayLoader />}
            {!showTwoFactorForm && <LoginForm errorMessage={authState.errorMessage} />}
            {showTwoFactorForm && (
                <TwoFactorAuthForm
                    account={authState.account}
                    codeSent={authState.codeSent}
                    errorMessage={authState.errorMessage}
                />
            )}
        </>
    );
};

export default LoginFormContainer;
