import { useState } from 'react';
import { Chart, ChartData, ChartOptions, TooltipModel } from 'chart.js';
import { defaults, every, isEqual, uniqueId } from 'lodash-es';
import { LineChartProps } from 'platform/common/components/LineChart/LineChart';
import styles from 'platform/common/components/LineChart/LineChart.scss';

interface State {
    tooltip: {
        open: boolean;
        bottom: string;
        left: string;
        title: string;
        body: string;
    };
    tooltipTargetId: string;
}

const buildChartJsDataFunc = (
    labels: string[],
    values: number[],
    customizeDatasetOptions?: (options: ChartOptions<'line'>) => ChartData<'line'>
) => {
    const defaultDatasetOptions = {
        label: '',
        backgroundColor: (context: any) => {
            if (!context.chart.chartArea) {
                return;
            }
            const {
                ctx,
                chartArea: { top, bottom },
            } = context.chart;
            const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
            gradientBg.addColorStop(0, styles.chartGradientColorFrom);
            gradientBg.addColorStop(1, styles.chartGradientColorTo);
            // eslint-disable-next-line consistent-return
            return gradientBg;
        },
        borderColor: styles.chartBorderColor,
    };

    const dataset = customizeDatasetOptions
        ? customizeDatasetOptions(defaultDatasetOptions)
        : defaultDatasetOptions;

    return {
        labels,
        datasets: [
            {
                ...dataset,
                data: values,
                fill: true,
                tension: 0.3,
            },
        ],
    };
};

const buildChartOptions = (
    updateTooltip: (args: { chart: Chart<'line'>; tooltip: TooltipModel<'line'> }) => void
): ChartOptions<'line'> => ({
    animation: false,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            external: updateTooltip,
        },
    },
    scales: {
        x: { display: false },
        y: { display: false, beginAtZero: true, grace: 1 },
    },
    elements: {
        line: { borderWidth: styles.chartBorderWidth },
        point: { radius: 0, hitRadius: 10, hoverRadius: 4 },
    },
});

const determineOptimalYMin = (values: any[]) => {
    // Draws the line right in the middle if all values are zeros
    if (every(values, (val) => Number(val) === 0)) {
        return 0;
    }
    // If minimum is zero, make some space for the bottom line
    // so that it is drawn fully
    if (Math.min(...values) === 0) {
        return -1;
    }
    return 0;
};

const determineOptimalYMax = (values: any[]) => {
    const topPaddingFactor = 1.2;
    return Math.max(...values.map(Number)) * topPaddingFactor;
};

const updateScale = (options: any, values: any[]) => {
    const newOptions = { ...options };
    if (newOptions.scales && newOptions.scales.yAxes) {
        const yAxis = newOptions.scales.yAxes[0];
        yAxis.ticks = defaults(yAxis.ticks, {
            suggestedMin: determineOptimalYMin(values),
            suggestedMax: determineOptimalYMax(values),
        });
    }
    return newOptions;
};

export const useLineChart = ({
    values,
    labels,
    height,
    customizeDatasetOptions,
    customizeChartOptions,
}: LineChartProps) => {
    const [{ tooltip, tooltipTargetId }, setState] = useState<State>({
        tooltip: {
            open: false,
            bottom: '',
            left: '',
            title: '',
            body: '',
        },
        tooltipTargetId: uniqueId('tooltipTarget'),
    });

    const updateTooltip = ({ tooltip: chartToolTip }: { chart: Chart; tooltip: TooltipModel<any> }) => {
        const { caretX, caretY, title, body } = chartToolTip;

        if (!title || !body) {
            return;
        }

        const newTooltip = {
            open: !!title[0],
            bottom: `${height - caretY - 10}px`,
            left: `${caretX}px`,
            title: title[0],
            body: body[0].lines[0],
        };

        if (isEqual(chartToolTip, newTooltip)) {
            return;
        }

        setState((s) => ({ ...s, tooltip: newTooltip }));
    };

    const chartOptions = buildChartOptions(updateTooltip);

    return {
        tooltip,
        tooltipTargetId,
        data: buildChartJsDataFunc(labels, values, customizeDatasetOptions),
        options: updateScale(
            customizeChartOptions ? customizeChartOptions(chartOptions) : chartOptions,
            values
        ),
        onMouseOut: () => setState((s) => ({ ...s, tooltip: { ...s.tooltip, open: false } })),
    };
};
