import { ContactInformation, EMPTY_CONTACTS } from 'platform/advertisers/advertiser.types';
import { RootState } from '../../rootState.type';
import { Action } from '../common.types';

const SET_CONTACTS = 'common/contacts/SET_CONTACTS';

const reducer = (state: ContactInformation = EMPTY_CONTACTS, action: Action): ContactInformation => {
    switch (action.type) {
        case SET_CONTACTS: {
            return action.payload;
        }
        default:
            return state;
    }
};

export default reducer;
export const contactActions = {
    setContacts: (contacts: ContactInformation) => ({
        type: SET_CONTACTS,
        payload: contacts,
    }),
};

export const contactSelectors = {
    getContacts: () => (state: RootState) => state.common.contacts,
};
