import { IdToName, TaskBoardChange } from '../task.types';

type Props = {
    change: TaskBoardChange;
    laneNames: IdToName;
    taskNames: IdToName;
};

const nameTag = (map: IdToName, id: number) => {
    const name = map[id];
    return name ? <i>{name}</i> : <span className="text-muted">[DELETED]</span>;
};

const BoardChangeContext = ({ change: c, laneNames, taskNames }: Props) => {
    if ('taskId' in c) {
        return c.parentTaskId ? (
            <>
                <div>Sub-task {nameTag(taskNames, c.taskId)}</div>
                <div className="text-muted font-xs">under parent {nameTag(taskNames, c.parentTaskId)}</div>
            </>
        ) : (
            <>Task {nameTag(taskNames, c.taskId)}</>
        );
    }
    if ('laneId' in c) {
        return <>Column {nameTag(laneNames, c.laneId)}</>;
    }
    return <>Board</>;
};

export default BoardChangeContext;
