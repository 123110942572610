import { FormGroup, Input, Label } from 'reactstrap';
import { LogBookState } from 'platform/analytics/analytics.types';
import { LOG_CATEGORY_OPTIONS } from 'platform/campaign/logBook/logBook.constant';
import { useLabelClassification } from 'platform/campaign/logBook/useLabelClassification';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';

interface Props {
    componentState: LogBookState;
    onChange: (state: LogBookState) => void;
}

const nameGetter = (item: { name: string }) => item.name;

const ReportLogBookSettings = ({ componentState, onChange }: Props) => {
    const { productCategories, products, subLabels, flightLabels, countryOptions, labelsLoading } =
        useLabelClassification({
            selectedProductCategories: componentState.productCategory,
            selectedProductLabels: componentState.productLabel,
        });

    return (
        <div>
            <hr />
            <SelectWithAddon
                className="mb-3"
                selectClassname="flex-fill"
                name="Category"
                options={LOG_CATEGORY_OPTIONS}
                value={componentState.category}
                onChange={(category) => onChange({ ...componentState, category })}
            />
            <SelectWithAddon
                className="mb-3"
                selectClassname="flex-fill"
                name="Product category"
                options={productCategories}
                getOptionLabel={nameGetter}
                getOptionValue={nameGetter}
                value={componentState.productCategory}
                isMulti
                isLoading={labelsLoading}
                onChange={(productCategory) => onChange({ ...componentState, productCategory })}
            />
            <SelectWithAddon
                className="mb-3"
                selectClassname="flex-fill"
                name="Product"
                options={products}
                getOptionLabel={nameGetter}
                getOptionValue={nameGetter}
                value={componentState.productLabel}
                isMulti
                isLoading={labelsLoading}
                onChange={(productLabel) =>
                    onChange({ ...componentState, productLabel, productSubLabel: [] })
                }
            />
            {!!subLabels.length && (
                <SelectWithAddon
                    className="mb-3"
                    selectClassname="flex-fill"
                    name="Sub-product"
                    options={subLabels}
                    getOptionLabel={nameGetter}
                    getOptionValue={nameGetter}
                    value={componentState.productSubLabel}
                    isMulti
                    isLoading={labelsLoading}
                    onChange={(productSubLabel) => onChange({ ...componentState, productSubLabel })}
                />
            )}
            <SelectWithAddon
                className="mb-3"
                selectClassname="flex-fill"
                name="Flight"
                options={flightLabels}
                getOptionLabel={nameGetter}
                getOptionValue={nameGetter}
                value={componentState.flightLabel}
                isMulti
                isLoading={labelsLoading}
                onChange={(flightLabel) => onChange({ ...componentState, flightLabel })}
            />
            <SelectWithAddon
                className="mb-3"
                selectClassname="flex-fill"
                name="Country"
                options={countryOptions}
                value={componentState.countryLabel}
                isMulti
                isLoading={labelsLoading}
                onChange={(countryLabel) => onChange({ ...componentState, countryLabel })}
            />
            <FormGroup check className="mt-2">
                <Label check>
                    <Input
                        type="checkbox"
                        checked={componentState.logNoteExpanded}
                        style={{ marginTop: '0.2rem' }}
                        onChange={(e) => onChange({ ...componentState, logNoteExpanded: e.target.checked })}
                    />
                    Log note expanded
                </Label>
            </FormGroup>
            <hr />
        </div>
    );
};

export default ReportLogBookSettings;
