import { User } from 'platform/userManagement/types/user.type';
import AttachmentsIcon from '../../common/components/AttachmentEdit/AttachmentsIcon';
import { Task, TaskBoardChange, TaskData, TaskLane } from '../task.types';
import KanbanCardUserEdit from './KanbanCardUserEdit';
import KanbanDateTag from './KanbanDateTag';
import KanbanNotificationIconButton from './KanbanNotificationIconButton';

interface Props {
    task: Task;
    parentLane?: TaskLane;
    userOptions: User[];
    onTaskWatchChange: (taskId: number, watch: boolean) => Promise<TaskBoardChange[]>;
    onTaskUpdate: ((taskId: number, data: Partial<TaskData>) => void) | ((data: Partial<TaskData>) => void);
}

const isOwnerMissing = (task: Task, lane: TaskLane) =>
    !!lane.ownerMustBeAssignee &&
    !!lane.ownerIds?.length &&
    !lane.ownerIds.some((id) => task.assigneeIds?.includes(id));

const KanbanCardFooter = ({ task, parentLane, userOptions, onTaskWatchChange, onTaskUpdate }: Props) => (
    <div className="d-flex align-items-center gap-3">
        <KanbanNotificationIconButton
            className="Kanban-card-notification-icon"
            notifiedUserIds={task.notifiedUserIds}
            onChange={(watch) => onTaskWatchChange(task.id, watch)}
        />
        <AttachmentsIcon attachments={task.attachments} />
        <KanbanDateTag className="px-1" task={task} />
        <KanbanCardUserEdit
            className="ms-auto"
            showOwnerMissing={parentLane && isOwnerMissing(task, parentLane)}
            userIds={task.assigneeIds ?? []}
            userOptions={userOptions}
            onChange={(assigneeIds) => onTaskUpdate(task.id, { assigneeIds })}
        />
    </div>
);

export default KanbanCardFooter;
