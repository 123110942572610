import NaturalLanguageSentiments from 'platform/naturalLanguageSentiments/NaturalLanguageSentiments';
import { NATURAL_LANGUAGE_SENTIMENTS } from 'platform/naturalLanguageSentiments/naturalLanguage.navigation';

const naturalLanguageRoutes = [
    {
        ...NATURAL_LANGUAGE_SENTIMENTS,
        component: NaturalLanguageSentiments,
    },
];

export default naturalLanguageRoutes;
