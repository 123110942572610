import { Category } from 'platform/category/category.types';
import { Optional } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { minLength, required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';

const CategoryForm = ({
    labels,
    formikProps: { initialValues, submitForm },
    canEdit,
    onCancel,
    isEdit,
}: FormProps<Optional<Category, 'id'>>) => (
    <CardForm
        title={`${isEdit ? 'Edit' : 'New'} category`}
        subtitle={isEdit && `ID: ${initialValues.id}`}
        submitLabel={labels.submit}
        onCancel={onCancel}
        disabled={!canEdit}
        onSubmit={submitForm}
    >
        <ControlledCard title="General info" subtitle="required">
            <FormRow label="Name">
                <FormInput name="name" type="text" validate={[required, minLength(2)]} maxLength={200} />
            </FormRow>
            <FormRow label="Status">
                <FormStateSelect name="status" initialValue={initialValues.status} validate={required} />
            </FormRow>
            <FormRow label="Code">
                <FormInput name="code" type="text" validate={[required, minLength(2)]} maxLength={50} />
            </FormRow>
            <FormRow label="Description">
                <FormInput name="description" type="textarea" />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default CategoryForm;
