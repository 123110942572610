import { WordCloudState, ColumnDefinitionOption } from 'platform/analytics/analytics.types';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import ReportChartEntriesCount from '../ReportChartEntriesCount';

interface Props {
    componentState: WordCloudState;
    metrics: ColumnDefinitionOption[];
    dimensions: ColumnDefinitionOption[];
    isLoading: boolean;
    onChange: (state: WordCloudState) => void;
}

const ReportWordCloudChartSettings = ({
    componentState,
    metrics,
    dimensions,
    isLoading,
    onChange,
}: Props) => (
    <>
        <ReportSelectTree
            className="mb-3"
            name="Dimension"
            options={dimensions}
            value={componentState.dimensions[0]}
            isLoading={isLoading}
            isMulti={false}
            onChange={(dimension) =>
                onChange({ ...componentState, dimensions: dimension ? [dimension] : [] })
            }
        />
        <ReportSelectTree
            className="mb-3"
            name="Metric"
            options={metrics}
            value={componentState.metrics}
            isLoading={isLoading}
            isMulti
            onChange={(values) => onChange({ ...componentState, metrics: values })}
        />
        <ReportSelectTree
            name="Color dimension"
            className="mb-3"
            options={dimensions}
            value={componentState.colorDimension}
            isLoading={isLoading}
            isMulti={false}
            onChange={(colorDimension: string) => onChange({ ...componentState, colorDimension })}
        />
        <ReportChartEntriesCount
            entriesCount={componentState.entriesCount}
            onChange={(entriesCount) => onChange({ ...componentState, entriesCount })}
        />
    </>
);

export default ReportWordCloudChartSettings;
