import Popover from 'platform/common/components/Popover/Popover';
import ZoomText from './ZoomText';

const ZoomButton = () => (
    <Popover placement="bottom-end" renderPopover={() => <ZoomText />}>
        <i className="fa-solid fa-magnifying-glass-plus font-xl text-light-slate-gray" />
    </Popover>
);

export default ZoomButton;
