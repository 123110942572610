import { ReactNode } from 'react';
import { ModalProps } from 'reactstrap';
import ConfirmationModal from 'platform/common/components/ConfirmationModal/ConfirmationModal';
import { useModal } from 'platform/common/components/Modal/Modal';

export const useConfirmationModal = (props?: ModalProps) => {
    const { showModal } = useModal();

    return (
        action: () => any,
        {
            title,
            text = 'Are you sure?',
            okLabel = 'Yes',
            cancelLabel = 'Cancel',
            onCancel,
        }: { title: string; text?: ReactNode; okLabel?: string; cancelLabel?: string; onCancel?: () => void }
    ): Promise<void> =>
        new Promise((resolve, reject) => {
            showModal((toggle) => (
                <ConfirmationModal
                    {...props}
                    isOpen
                    toggle={toggle}
                    onOk={async () => {
                        await action();
                        resolve();
                        toggle();
                    }}
                    onCancel={() => {
                        onCancel?.();
                        reject();
                    }}
                    title={title}
                    okLabel={okLabel}
                    cancelLabel={cancelLabel}
                >
                    {text}
                </ConfirmationModal>
            ));
        });
};
