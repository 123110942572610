import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { UserStatus } from 'platform/userManagement/constants/users.constant';
import axiosBff from '../../axios/axiosBff';
import { IdResponse } from '../../common/common.types';
import { Role, RoleRequest } from '../types/role.type';
import { Seat } from '../types/seat.type';
import { User } from '../types/user.type';

export const fetchUsers = (
    params: {
        name?: string;
        states?: UserStatus[];
        seatId?: number;
        accessibleAdvertiserId?: number;
    } = {}
) => axiosBff.get<User[]>('/users/api/users', { params }).then((response) => response.data);

export const fetchUser = (id: number): Promise<User> =>
    axiosBff.get<User>(`/users/api/users/${id}`).then((response) => response.data);

export const createUser = (user: User) =>
    axiosBff.post<IdResponse>('/users/api/users', user).then((response) => response.data);

export const updateUser = (user: User) =>
    axiosBff.put<IdResponse>('/users/api/users', user).then((response) => response.data);

export const deleteUser = (id: number) =>
    axiosBff.delete<IdResponse>(`/users/api/users/${id}`).then((response) => response.data);

export const resyncUser = (id: number, externalSystem: ExternalSystem) =>
    axiosBff
        .post<void>(`/users/api/users/${id}/resync`, { externalSystem })
        .then((response) => response.data);

export const fetchRoles = (params?: { status?: ActiveOrArchived[] }) =>
    axiosBff.get<Role[]>('/users/api/roles', { params }).then((response) => response.data);

export const fetchRole = (id: number) =>
    axiosBff.get<Role>(`/users/api/roles/${id}`).then((response) => response.data);

export const createRole = (data: RoleRequest) =>
    axiosBff.post<IdResponse>('/users/api/roles', data).then((response) => response.data);

export const updateRole = (id: number, data: RoleRequest) =>
    axiosBff.put<IdResponse>('/users/api/roles', { ...data, id }).then((response) => response.data);

export const deleteRole = (id: number) =>
    axiosBff.delete<IdResponse>(`/users/api/roles/${id}`).then((response) => response.data);

export const fetchSeats = (params: { states?: string[] } = {}) =>
    axiosBff.get<Seat[]>('/users/api/seats', { params }).then((response) => response.data);

export const fetchSeat = (id: number) =>
    axiosBff.get<Seat>(`/users/api/seats/${id}`).then((response) => response.data);

export const createSeat = (model: Partial<Seat>) =>
    axiosBff.post<IdResponse>('/users/api/seats', model).then((response) => response.data);

export const updateSeat = (model: Partial<Seat>) =>
    axiosBff.put<IdResponse>(`/users/api/seats/${model.id || ''}`, model).then((response) => response.data);

export const fetchUsersWithSeats = (params: {
    name?: string;
    states?: UserStatus[];
    seatId?: number;
}): Promise<(User & { seatTitle: string })[]> =>
    Promise.all([fetchUsers(params), fetchSeats({})]).then(([userList, seatList]) =>
        userList.map((user) => {
            const seat = seatList.find((item) => item.id === user.seatId);
            return {
                ...user,
                seatTitle: (seat && seat.name) || 'Unknown',
            };
        })
    );
