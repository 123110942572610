import classNames from 'classnames';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { useChatMessageContext } from './ChatProviders/ChatMessageProvider';

interface Props {
    className?: string;
    iconClassName?: string;
    style?: React.CSSProperties;
}

const ChatClearButton = ({ className, iconClassName, style }: Props) => {
    const { clearChat } = useChatMessageContext();
    const showConfirmationModal = useConfirmationModal();

    const handleButtonClick = () =>
        showConfirmationModal(clearChat, {
            title: 'Reset Chat',
            text: 'All current chat messages will be deleted. Do you want to continue?',
        });

    return (
        <IconButton style={style} className={className} onClick={handleButtonClick}>
            <i className={classNames('fa-solid fa-arrows-rotate text-white fs-4', iconClassName)} />
        </IconButton>
    );
};

export default ChatClearButton;
