import React from 'react';

interface Props {
    children: React.ReactElement | (React.ReactElement | undefined)[];
    columns?: number;
    loading?: boolean;
}

const Row = ({ columns = 6, loading = false, children }: Props) => {
    const style = {
        width: `calc(100% / ${String(columns)})`,
    };
    return (
        <div className="SummaryBar-row">
            {React.Children.map(children, (child, index) => {
                if (!child) {
                    return null;
                }
                const childProps = {
                    loading: loading ? 'true' : undefined,
                };
                return (
                    <div
                        key={index}
                        className="SummaryBar-row-item py-md-4 ps-md-4 pe-md-2 p-3"
                        style={style}
                    >
                        {React.cloneElement(child, childProps)}
                    </div>
                );
            })}
        </div>
    );
};

export default Row;
