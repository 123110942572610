import MediaplanList from 'platform/mediaplan/components/MediaplanList';
import { MEDIAPLAN } from 'platform/mediaplan/mediaplan.navigation';

const mediaplanPageRoutes = [
    {
        ...MEDIAPLAN,
        component: MediaplanList,
    },
];

export default mediaplanPageRoutes;
