import { useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import Switch from 'platform/common/components/Switch/Switch';

type Props = {
    toggle: () => void;
    onGenerateClick: (saveAdvertiser: boolean, saveDate: boolean) => Promise<string>;
};

const LinkGenerationModal = ({ toggle, onGenerateClick }: Props) => {
    const [state, setState] = useState<{ saveAdvertisers: boolean; saveDate: boolean; link: string }>({
        saveAdvertisers: false,
        saveDate: false,
        link: '',
    });
    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '50em' }} className="UserReportSaveModal">
            <ModalHeader toggle={toggle}>Link to share report</ModalHeader>
            <ModalBody style={{ maxHeight: '45em' }} className="pb-0">
                <div className="pb-3">
                    With this link you can share the current report with other users. The user still needs to
                    have a login and access to our platform and to the corresponding advertiser.
                </div>
                <div className="d-flex align-items-center mb-1">
                    <Label className="mb-0 me-3 col-3">Save selected advertisers</Label>
                    <Switch
                        input={{
                            value: state.saveAdvertisers,
                            onChange: ({ target: { checked } }) =>
                                setState((s) => ({ ...s, saveAdvertisers: checked })),
                        }}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <Label className="mb-0 me-3 col-3">Save date</Label>
                    <Switch
                        input={{
                            value: state.saveDate,
                            onChange: ({ target: { checked } }) =>
                                setState((s) => ({ ...s, saveDate: checked })),
                        }}
                    />
                </div>
                {state.link && <Input className="my-3" value={state.link} disabled />}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-right align-items-center">
                <Button
                    onClick={async () => {
                        const link = await onGenerateClick(state.saveAdvertisers, state.saveDate);
                        setState((s) => ({ ...s, link }));
                    }}
                >
                    Generate link
                </Button>
                <AnimatedButton
                    onClick={async () => {
                        await navigator?.clipboard.writeText(state.link);
                        toggle();
                    }}
                    disabled={!state.link}
                >
                    Copy link to clipboard
                </AnimatedButton>
            </ModalFooter>
        </Modal>
    );
};
export default LinkGenerationModal;
