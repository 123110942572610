export const subscribe = (
    url: string,
    config: {
        onConnect: () => any;
        onReceive: (event: {}) => any;
    }
): { unsubscribe: () => any } => {
    const eventSource = new EventSource(url);
    const closeEventSource = () => eventSource.close();

    eventSource.onopen = () => config.onConnect();

    eventSource.onmessage = ({ data }: MessageEvent) => {
        if (data) {
            config.onReceive(JSON.parse(data));
        }
    };

    eventSource.onerror = () => {
        closeEventSource();
        // give the disconnected server some time before attempting reconnect

        // TODO: Handle expired session before uncommenting
        // setTimeout(() => {
        //     const { unsubscribe } = subscribe(url, config);
        //     closeEventSource = unsubscribe;
        // }, 5000);
    };

    return { unsubscribe: () => closeEventSource() };
};
