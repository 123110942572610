import { Button, Modal, ModalBody } from 'reactstrap';
import { Formik } from 'formik';
import { CampaignClassificationModel } from 'platform/campaign/campaign/campaignClassification.types';
import { ImportedCampaign } from 'platform/campaign/campaign/campaignImport.types';
import CampaignClassifications from 'platform/campaign/campaign/components/CampaignClassifications';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';

interface Props {
    campaigns: ImportedCampaign[];
    toggle: () => void;
    onImport: (classification: CampaignClassificationModel) => void;
}

const CampaignBatchImportModal = ({ campaigns, toggle, onImport }: Props) => (
    <Modal isOpen style={{ maxWidth: 640 }}>
        <ModalHeader onClose={toggle}>Set classification for campaigns</ModalHeader>
        <ModalBody>
            <Formik<{ classification: CampaignClassificationModel }>
                initialValues={{
                    classification: {
                        channel: undefined,
                        marketingGoal: 'PERFORMANCE',
                        targetAudience: 'PROSPECTING',
                    },
                }}
                onSubmit={(data) => {
                    onImport(data.classification);
                    toggle();
                }}
            >
                {({ values, setFieldValue, submitForm }) => (
                    <>
                        <CampaignClassifications
                            className="py-4"
                            mediaType={'DIGITAL'}
                            rowProps={{
                                rowClassName: 'm-0',
                                labelWidth: 1,
                                labelClassName: 'me-3',
                                childrenColClassName: 'flex-grow-1 pe-0',
                                contentWidth: 10,
                            }}
                            classification={values.classification}
                            setFieldValue={setFieldValue}
                        />
                        <div className="d-flex justify-content-end">
                            <Button color="primary" onClick={submitForm}>
                                Import ({campaigns.length}) campaigns
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
        </ModalBody>
    </Modal>
);

export default CampaignBatchImportModal;
