export type NavigationStyle = 'TOP_SEPARATOR' | 'BOTTOM_SEPARATOR' | 'SMALL_TEXT';

export const NAVIGATION_STYLE_LABELS: Record<NavigationStyle, string> = {
    TOP_SEPARATOR: 'Top Separator',
    BOTTOM_SEPARATOR: 'Bottom Separator',
    SMALL_TEXT: 'Small Text',
};

export const NAVIGATION_STYLE_CLASSES: Record<NavigationStyle, string> = {
    TOP_SEPARATOR: 'top-separator',
    BOTTOM_SEPARATOR: 'bottom-separator',
    SMALL_TEXT: 'small-text',
};

type NavigationOpenAction = 'NEW_TAB' | 'NEW_WINDOW' | 'EMBEDDED';

export const NAVIGATION_OPEN_ACTION_LABELS: Record<NavigationOpenAction, string> = {
    NEW_TAB: 'New tab',
    NEW_WINDOW: 'New window',
    EMBEDDED: 'Embedded',
};
