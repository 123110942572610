import { useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { uniq } from 'lodash-es';
import { DataSharingTemplate } from 'platform/adminPanel/adminPanel.types';
import {
    getDataSharingMacros,
    SCRIPT_TYPE_OPTIONS,
} from 'platform/advertiserTag/constants/advertiserTag.constant';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import SupportedMacrosPopover from 'platform/common/components/SupportedMacrosPopover/SupportedMacrosPopover';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import {
    containsImageTag,
    containsScriptOrIframeTag,
    required,
    secureUrls,
    urlHttps,
} from 'platform/common/utils/validators.util';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';

const validateCodeTemplate = (value: string, allValues: DataSharingTemplate) => {
    const validators = (() => {
        if (allValues.codeType === 'IMAGE_URL') {
            return [required, urlHttps];
        }
        if (allValues.codeType === 'IMAGE_HTML') {
            return [required, secureUrls, containsImageTag];
        }
        return [required, secureUrls, containsScriptOrIframeTag];
    })();

    return validators.map((f) => f(value)).find((error) => error !== undefined);
};

const extractVariables = (template: string) => {
    const global = (template.match(/\$\$([\w.-]+?)\$\$/g) || []).map((placeholder) =>
        placeholder.substring(2, placeholder.length - 2)
    );

    const local = (template.match(/\$([\w.-]+?)\$/g) || [])
        .map((placeholder) => placeholder.substring(1, placeholder.length - 1))
        .filter((placeholder) => !global.includes(placeholder));

    return { local, global };
};

const SegmentSharingTemplateForm = ({
    labels,
    canEdit,
    isEdit,
    formikProps: { initialValues, values, submitForm },
    onCancel,
}: FormProps<DataSharingTemplate>) => {
    const { local: variablesInTemplate, global: globalVariablesInTemplate } = extractVariables(
        values.codeTemplate
    );
    const vendors = useSelector(classifierSelectors.vendors);

    return (
        <CardForm
            title={isEdit ? 'Edit Template' : 'New Template'}
            subtitle={isEdit && `ID: ${initialValues.id}`}
            submitLabel={labels.submit}
            disabled={!canEdit}
            animationDelay={0}
            onCancel={onCancel}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Vendor">
                    <FormSelect name="vendorId" options={vendors} valueKey="id" labelKey="name" />
                </FormRow>
                <FormRow label="Name">
                    <FormInput name="systemName" type="text" validate={required} />
                </FormRow>
                <FormRow label="Status">
                    <FormStateSelect name="status" validate={required} />
                </FormRow>
                <FormRow label="Code type">
                    <FormSelect
                        name="codeType"
                        options={SCRIPT_TYPE_OPTIONS}
                        isClearable={false}
                        validate={required}
                    />
                </FormRow>
                {values.codeType !== 'IMAGE_URL' && (
                    <FormRow label="Code">
                        <FormCodeEditor
                            name="codeTemplate"
                            options={{
                                mode: 'htmlmixed',
                                lineWrapping: true,
                                readOnly: false,
                            }}
                            caption={
                                <span>
                                    User defined macros: use $parameter$ to define dynamic parameters, e.g.
                                    $advertiser_id$.
                                    <br />
                                    Global advertiser macros: use $$parameter$$ to define dynamic parameters,
                                    e.g. $$external_id$$.
                                </span>
                            }
                            validate={(val) => validateCodeTemplate(val, values)}
                        />
                    </FormRow>
                )}
                {values.codeType === 'IMAGE_URL' && (
                    <FormGroup>
                        <FormInput
                            name="codeTemplate"
                            type="text"
                            validate={(val) => validateCodeTemplate(val, values)}
                        />
                    </FormGroup>
                )}
                <FormRow
                    label="Used defined macros"
                    rowClassName="my-0"
                    helpKey="segment_sharing_template_form_used_macros"
                >
                    {uniq(variablesInTemplate).map((variable) => (
                        <div key={variable}>{variable}</div>
                    ))}
                </FormRow>
                <FormRow
                    label="Global macros"
                    rowClassName="my-0"
                    helpKey="segment_sharing_template_form_global_macros"
                >
                    {uniq(globalVariablesInTemplate).map((variable) => (
                        <div key={variable}>{variable}</div>
                    ))}
                </FormRow>
                <FormRow
                    label="Data sharing macros"
                    helpKey="segment_sharing_template_form_data_sharing_macros"
                >
                    <SupportedMacrosPopover list={getDataSharingMacros(vendors)} />
                </FormRow>
                <FormRow label="Notes">
                    <FormInput name="notes" type="textarea" />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default SegmentSharingTemplateForm;
