import axiosBff from 'platform/axios/axiosBff';
import { FILE_UPLOAD_HEADERS, fileUploadData } from 'platform/common/utils/file.util';
import { SharedFile, SharedFileUpdate } from './fileSharing.types';

export const createSharedFile = (content: Blob, name: string) =>
    axiosBff
        .post('/admin/api/shared-files', fileUploadData(content, name), FILE_UPLOAD_HEADERS)
        .then((r) => r.data);

export const updateSharedFileContent = (id: number, content: Blob, name: string) =>
    axiosBff
        .put(`/admin/api/shared-files/${id}/content`, fileUploadData(content, name), FILE_UPLOAD_HEADERS)
        .then((r) => r.data);

export const updateSharedFileMetadata = (id: number, file: SharedFileUpdate) =>
    axiosBff.put<void>(`/admin/api/shared-files/${id}`, file);

export const getSharedFiles = () => axiosBff.get<SharedFile[]>('/admin/api/shared-files').then((r) => r.data);

export const getSharedFile = (id: number) =>
    axiosBff.get<SharedFile>(`/admin/api/shared-files/${id}`).then((r) => r.data);

export const deleteSharedFile = (id: number) =>
    axiosBff.delete(`/admin/api/shared-files/${id}`).then((r) => r.data);
