import { Children, cloneElement, CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';
import './SummaryBar.scss';

interface Props {
    children: ReactElement;
    columns?: number;
    loading?: boolean;
    topBorder?: boolean;
    bottomBorder?: boolean;
    className?: string;
    style?: CSSProperties;
}

const SummaryBar = ({
    children,
    columns = 6,
    loading = false,
    topBorder,
    bottomBorder,
    className,
    style,
}: Props) => (
    <div
        style={style}
        className={classNames(
            'SummaryBar',
            className,
            { 'SummaryBar-withTopBorder': topBorder },
            { 'SummaryBar-withBottomBorder': bottomBorder }
        )}
    >
        {Children.map(children, (child) => {
            if (!child) {
                return null;
            }
            return cloneElement(child, { columns, loading });
        })}
    </div>
);

export default SummaryBar;
