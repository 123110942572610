import { useImageUpload } from 'platform/adminPanel/hooks/useImageUpload';
import { fetchAnalyticsReports } from 'platform/analyticsReports/analyticsReport.service';
import { SECTION_OPTIONS } from 'platform/app/app.types';
import ImgCropModal from 'platform/app/components/Profile/ImgCropModal';
import { REGULAR_USER_REPORT_SECTIONS } from 'platform/app/components/Sidebar/navigation.util';
import { assertIsDefined } from 'platform/common/common.assert';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { useModal } from 'platform/common/components/Modal/Modal';
import Separator from 'platform/common/components/Separator/Separator';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { TwoFactorAuthentication } from 'platform/common/constants/twoFactorAuthentication.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { email, required, urlHttps } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormInputWithUrlPreview from 'platform/formik/FormInputWithUrlPreview/FormInputWithUrlPreview';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import FormToggle from 'platform/formik/FormToggle/FormToggle';
import CustomAccess, {
    validateCustomAccessLevel,
} from 'platform/userManagement/components/CustomAccess/CustomAccess';
import FormSortableSelect from 'platform/userManagement/components/SortableSelect/FormSortableSelect';
import UserIntegrationIcons from 'platform/userManagement/components/UserIntegrationIcons';
import {
    BING_ACCESS_OPTIONS,
    GOOGLE_ACCESS_OPTIONS,
} from 'platform/userManagement/constants/access.constant';
import { USER_STATUS_OPTIONS, UserStatus } from 'platform/userManagement/constants/users.constant';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';
import { Seat } from 'platform/userManagement/types/seat.type';
import { UserFormModel } from 'platform/userManagement/types/user.type';
import { getSectionReportSortItems } from 'platform/userManagement/utils/users.util';

const UserForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: { initialValues, setFieldValue, submitForm, values },
}: FormProps<UserFormModel>) => {
    const {
        useSeatAdvertiser,
        useSeatPlatformAccessUrl,
        useSeatCompanyLogoUrl,
        useSeatPlatformLogoUrl,
        useSeatReports,
        demoModeEnabled,
        seatId,
        userReportIds,
        imageUrl,
    } = values;
    const { showModal } = useModal();

    const { uploadingImage, handleImageUpload } = useImageUpload();

    const [{ data: seatReports, loading: loadingReports }] = usePromise(
        [],
        async () => fetchAnalyticsReports({ section: REGULAR_USER_REPORT_SECTIONS, seatId }),
        [seatId]
    );

    const activeSeat = initialValues.seats.find((s) => s.id === seatId);
    assertIsDefined(activeSeat, `seat with id ${seatId}`);

    const isAvailable = (next: UserStatus) => {
        const pendingStatuses: UserStatus[] = ['PENDING', 'PENDING_INACTIVE'];
        return pendingStatuses.includes(initialValues.status ?? 'ACTIVE') === pendingStatuses.includes(next);
    };

    const handleUrlChange = (field: keyof Seat, value: boolean) => {
        setFieldValue(field, value ? activeSeat[field] ?? '' : '');
    };

    return (
        <CardForm
            title={`${labels.prefix} User`}
            subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info">
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Status">
                    <FormSelect
                        name="status"
                        options={USER_STATUS_OPTIONS.map((o) => ({ ...o, disabled: !isAvailable(o.value) }))}
                        className="w-50"
                        isSearchable={false}
                        isClearable={false}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Email">
                    <FormInput name="login" type="text" validate={[required, email]} />
                </FormRow>
                <FormRow label="Mobile phone No.">
                    <FormInput name="phone" type="text" />
                </FormRow>
                <FormRow label="Seat">
                    <FormSelect
                        name="seatId"
                        options={initialValues.seats
                            .filter((seat) => seat.status === 'ACTIVE' || initialValues.seatId === seat.id)
                            .map((seat) => ({ value: seat.id, label: seat.name }))}
                        isClearable={false}
                        onChange={(value) => {
                            if (value) {
                                const seat = initialValues.seats.find((s) => s.id === value);
                                if (seat) {
                                    setFieldValue('advertiserAccess', {
                                        mode: seat.advertiserAccess,
                                        entityIds: seat.advertiserIds,
                                    });
                                }
                            }
                        }}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Role">
                    <FormSelect
                        name="roleId"
                        options={initialValues.roles
                            ?.filter((role) => role.status === 'ACTIVE' || role.id === initialValues?.roleId)
                            ?.map((role) => ({
                                value: role.id,
                                label: role.title,
                                disabled: role.status === 'ARCHIVED',
                            }))}
                        validate={required}
                    />
                </FormRow>
                <Separator label="Optional" />
                <FormRow label="Google access" childrenColClassName="d-flex align-items-center gap-2">
                    <FormSelect
                        className="flex-grow-1"
                        name="googleAccessRole"
                        options={GOOGLE_ACCESS_OPTIONS}
                        isSearchable={false}
                        isClearable={false}
                    />
                    <UserIntegrationIcons
                        userId={initialValues.id}
                        integrations={initialValues.externalSystemIntegrations?.filter(
                            (integration) => integration.externalSystem === 'GOOGLE'
                        )}
                    />
                </FormRow>
                <FormRow label="Bing access" childrenColClassName="d-flex align-items-center gap-2">
                    <FormSelect
                        className="flex-grow-1"
                        name="bingAccessRole"
                        options={BING_ACCESS_OPTIONS}
                        isSearchable={false}
                        isClearable={false}
                    />
                    <UserIntegrationIcons
                        userId={initialValues.id}
                        integrations={initialValues.externalSystemIntegrations?.filter(
                            (integration) => integration.externalSystem === 'BING'
                        )}
                    />
                </FormRow>
                <FormRow label="Title">
                    <FormInput name="title" type="text" />
                </FormRow>
                <FormRow label="Image url">
                    <FormInputWithUrlPreview value={imageUrl} name="imageUrl" />
                </FormRow>
                <FormRow>
                    <UploadInput
                        acceptableMimeTypes="image/jpeg, image/png, image/svg+xml"
                        subtitle="Upload jpeg/png/svg. Recommended format 100x100 px"
                        onFileUpload={(file, uploadProgressProps) =>
                            showModal((toggle) => (
                                <ImgCropModal
                                    file={file}
                                    toggle={toggle}
                                    onSave={(savedFile) => {
                                        handleImageUpload({
                                            file: savedFile,
                                            rescaleToSize: {
                                                width: 150,
                                                height: 150,
                                            },
                                            uploadProgressProps,
                                            onUpload: (fileName) => setFieldValue('imageUrl', fileName),
                                        });
                                    }}
                                />
                            ))
                        }
                        loading={uploadingImage}
                    />
                </FormRow>
                <FormRow label="2 factor authentication">
                    <FormButtonArray
                        name="twoFactorAuthentication"
                        buttons={[
                            { value: TwoFactorAuthentication.NONE, label: 'None' },
                            { value: TwoFactorAuthentication.EMAIL, label: 'Email' },
                        ]}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Demo mode">
                    <FormSwitch name="demoModeEnabled" />
                </FormRow>
                {demoModeEnabled && (
                    <FormRow label="Metrics multiplier">
                        <FormNumberInput name="demoModeMultiplier" />
                    </FormRow>
                )}
            </ControlledCard>
            <ControlledCard title="Seat settings">
                <p>These settings are inherited from seat assigned to the user.</p>

                {seatId !== ADMIN_SEAT_ID && (
                    <ControlledCard title="Reports" isExpanded={false}>
                        <FormRow label="Use seat reports">
                            <FormSwitch
                                className="ms-1"
                                name="useSeatReports"
                                onChange={(value) => {
                                    if (value) {
                                        setFieldValue(
                                            'userReportIds',
                                            seatReports.filter((r) => r.createdByAdmin).map((r) => r.id)
                                        );
                                    }
                                }}
                            />
                        </FormRow>
                        {SECTION_OPTIONS.map((section) => (
                            <FormRow label={section.label}>
                                <FormSortableSelect
                                    name="userReportIds"
                                    options={seatReports
                                        .filter((r) => r.section === section.value && r.createdByAdmin)
                                        .map((r) => ({
                                            value: r.id!,
                                            label: r.status === 'ARCHIVED' ? `(Archived) ${r.name}` : r.name,
                                            hidden: r?.status === 'ARCHIVED',
                                        }))}
                                    isLoading={loadingReports}
                                    items={getSectionReportSortItems(
                                        section.value,
                                        userReportIds,
                                        seatReports
                                    )}
                                    dragKey={section.value}
                                    isDisabled={useSeatReports}
                                />
                            </FormRow>
                        ))}
                    </ControlledCard>
                )}

                <FormRow label="Advertiser access" childrenColClassName="d-flex justify-content-between">
                    <CustomAccess
                        name="advertiserAccess"
                        buttonAllText="User has access to all advertisers."
                        validate={validateCustomAccessLevel}
                        disabled={useSeatAdvertiser}
                    />
                    <FormToggle
                        name="useSeatAdvertiser"
                        icon={useSeatAdvertiser ? 'fa-pencil-alt' : 'fa-redo'}
                        onChange={(value) => {
                            if (value) {
                                setFieldValue('advertiserAccess', {
                                    mode: activeSeat.advertiserAccess,
                                    entityIds: activeSeat.advertiserIds,
                                });
                            }
                        }}
                    />
                </FormRow>

                <FormRow label="Platform access URL" childrenColClassName="d-flex justify-content-between">
                    <FormInput
                        name="platformAccessUrl"
                        type="text"
                        validate={[required, urlHttps]}
                        disabled={useSeatPlatformAccessUrl}
                    />
                    <FormToggle
                        name="useSeatPlatformAccessUrl"
                        icon={useSeatPlatformAccessUrl ? 'fa-pencil-alt' : 'fa-redo'}
                        onChange={(value) => handleUrlChange('platformAccessUrl', value)}
                    />
                </FormRow>

                <FormRow label="Company logo URL" childrenColClassName="d-flex justify-content-between">
                    <FormInput
                        name="companyLogoUrl"
                        type="text"
                        validate={urlHttps}
                        disabled={useSeatCompanyLogoUrl}
                    />
                    <FormToggle
                        name="useSeatCompanyLogoUrl"
                        icon={useSeatCompanyLogoUrl ? 'fa-pencil-alt' : 'fa-redo'}
                        onChange={(value) => handleUrlChange('companyLogoUrl', value)}
                    />
                </FormRow>

                <FormRow label="Platform logo URL" childrenColClassName="d-flex justify-content-between">
                    <FormInput
                        name="platformLogoUrl"
                        type="text"
                        validate={urlHttps}
                        disabled={useSeatPlatformLogoUrl}
                    />
                    <FormToggle
                        name="useSeatPlatformLogoUrl"
                        icon={useSeatPlatformLogoUrl ? 'fa-pencil-alt' : 'fa-redo'}
                        onChange={(value) => handleUrlChange('platformLogoUrl', value)}
                    />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default UserForm;
