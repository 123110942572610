const labelFor = (column: string, row = {}) => {
    const map: { [key: string]: (value: any) => any } = {
        creative_name: ({ creative_name }) => creative_name && `Creative: ${creative_name}`,
        advertiser_name: ({ advertiser_name }) => advertiser_name && `Advertiser: ${advertiser_name}`,
        campaign_name: ({ campaign_name }) => campaign_name && `Campaign: ${campaign_name}`,
        strategy_name: ({ strategy_name }) => strategy_name && `Strategy: ${strategy_name}`,
        unit_id: ({ unit_id }) => unit_id && `Unit: ${unit_id}`,
        unit_bid: ({ unit_id }) => unit_id && `Bid for unit ${unit_id}`,
        classification_channel: ({ classification_channel }) =>
            classification_channel && `Subchannel: ${classification_channel}`,
    };
    const fn = map[column] || (() => undefined);
    return fn(row) || '';
};

const subtextFor = (column: string, row = {}) => {
    const map: { [key: string]: (value: any) => any } = {
        advertiser_name: ({ advertiser_id }) => advertiser_id && `ID: ${advertiser_id}`,
        campaign_name: ({ campaign_id }) => campaign_id && `ID: ${campaign_id}`,
        strategy_name: ({ strategy_id }) => strategy_id && `ID: ${strategy_id}`,
        creative_name: ({ creative_id }) => creative_id && `ID: ${creative_id}`,
    };
    const fn = map[column] || (() => undefined);
    return fn(row);
};

const InlineActionHeader = ({ column, row }: { column: string; row: {} }) => {
    const subtext = subtextFor(column, row);
    return (
        <div>
            <span className="fw-bold">{labelFor(column, row)}</span>
            {subtext && (
                <>
                    <br />
                    <span className="text-muted">{subtext}</span>
                </>
            )}
        </div>
    );
};

export default InlineActionHeader;
