import { Expression } from 'platform/campaign/globalPreset/globalPreset.types';

const DIMENSION_LENGTH = {
    customAction: 20,
    customVars: 10,
};

const placeholderArray = (count: number) => Array.from(Array(count), (e, i) => i + 1);

export const customDimensionOptions = placeholderArray(DIMENSION_LENGTH.customAction).map((i) => ({
    value: i,
    label: `Custom dimension ${i}`,
}));

export const customRuleExpressionOptions = [
    {
        label: 'Contains',
        value: Expression.CONTAINS,
    },
    {
        label: 'Equals',
        value: Expression.EQUALS,
    },
    {
        label: 'Regex',
        value: Expression.REGEX,
    },
];

export const customColumnOptions = placeholderArray(DIMENSION_LENGTH.customVars).map((i) => ({
    value: `custom_var_${i}`,
    label: `Custom var ${i}`,
}));
// not supported yet
// {value: 'product_category_id', label: 'Product Category ID'},
// {value: 'product_id', label: 'Product ID'}
