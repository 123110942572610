import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { usePromise } from 'platform/common/hooks/usePromise';
import { fetchInsightQueryVersions } from 'platform/insight/insight.service';
import { InsightQueryVersionSummary } from 'platform/insight/insight.types';

interface Props {
    queryId: number;
    onRestore: (timestamp: string) => void;
}

const InsightQueryVersionsTable = ({ queryId, onRestore }: Props) => {
    const [{ data: versions, loading }] = usePromise([], () => fetchInsightQueryVersions(queryId), [queryId]);

    const columns: TableColumn<InsightQueryVersionSummary>[] = [
        {
            Header: 'Changed',
            width: 200,
            Cell: ({ original: { timestamp, updatedBy } }) => (
                <UpdatedOn date={timestamp} updatedBy={updatedBy} />
            ),
        },
        {
            width: 100,
            Cell: ({ original: { timestamp } }) => (
                <div role="button" tabIndex={-1} className="text-info" onClick={() => onRestore(timestamp)}>
                    <i className="fa fa-undo me-1" /> Restore
                </div>
            ),
        },
    ];

    return (
        <div style={{ minWidth: 300 }}>
            {loading && (
                <div className="position-relative m-3">
                    <OverlayLoader />
                </div>
            )}
            {!loading && versions.length === 0 && (
                <div className="text-center m-3">No record of previous versions</div>
            )}
            {!loading && versions.length > 0 && (
                <FormattedTable
                    data={versions}
                    columns={columns}
                    stickyHeader={false}
                    defaultSorted={[{ orderBy: 'timestamp', direction: 'DESC' }]}
                    defaultPageSize={10}
                />
            )}
        </div>
    );
};

export default InsightQueryVersionsTable;
