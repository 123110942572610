const HEALTH_ICON_CLASSES: { [key: string]: string } = {
    SUCCESS: 'text-success fa fa-check-circle',
    FAILED: 'text-danger fa fa-exclamation-circle',
    IN_PROGRESS: 'text-muted fa fa-spinner',
};

type Props = {
    status: string | undefined;
};

const ObserverStatusIndicator = ({ status }: Props) => (
    <>{status && <span className={HEALTH_ICON_CLASSES[status]} />}</>
);

export default ObserverStatusIndicator;
