import { KeyboardEvent, useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { FilterInputProps } from 'platform/analytics/modes/Analytics';
import FilterOperatorDropdown from 'platform/common/components/FilterOperatorDropdown/FilterOperatorDropdown';
import FilterRemoveIcon from 'platform/common/components/FilterRemoveIcon/FilterRemoveIcon';
import TextWithEllipsis from 'platform/common/components/TextWithEllipsis/TextWithEllipsis';
import { FilterDefinition, ReportFilterOperator, ReportFilter } from '../../analytics.types';

interface Props {
    filter: ReportFilter;
    filterDefinition: FilterDefinition;
    supportedOperators?: ReportFilterOperator[];
    disabled?: boolean;
    onChange: (filter: ReportFilter) => void;
    onRemove: () => void;
    filterInputProps?: FilterInputProps;
}

const VALUE_SEPARATOR = /\s*[,;]\s*/;

const AnalyticsInputFilter = ({
    filter,
    filterDefinition,
    supportedOperators,
    disabled,
    onChange,
    onRemove,
    filterInputProps,
}: Props) => {
    const [filterValues, setFilterValues] = useState(filter.values);

    const toValues = (input: string): string[] | number[] => {
        if (!input) {
            return [];
        }
        if (filterDefinition.type === 'NUMBER') {
            return [Number(input)];
        }
        return filterDefinition.isMulti ? input.split(VALUE_SEPARATOR) : [input];
    };

    const value = (filterDefinition.isMulti ? filterValues.join(', ') : filterValues[0]) ?? undefined;

    const onInputFinish = () =>
        onChange({
            ...filter,
            values: filterValues,
        });

    return (
        <InputGroup>
            <InputGroupText>
                <TextWithEllipsis>{filterDefinition.name}</TextWithEllipsis>
            </InputGroupText>
            {!!filter.operator && !!supportedOperators?.length && (
                <InputGroupText>
                    <FilterOperatorDropdown
                        operator={filter.operator}
                        supportedOperators={supportedOperators}
                        onChange={(operator) => onChange({ ...filter, operator })}
                    />
                </InputGroupText>
            )}
            <Input
                type={filterDefinition.type === 'NUMBER' ? 'number' : 'text'}
                placeholder="Enter..."
                style={{ height: 'auto' }}
                value={typeof value === 'boolean' ? String(value) : value}
                readOnly={disabled}
                onChange={(e) => setFilterValues(toValues(e.target.value))}
                onBlur={onInputFinish}
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                        onInputFinish();
                    }
                }}
                {...filterInputProps}
            />
            {!disabled && <FilterRemoveIcon onClick={onRemove} />}
        </InputGroup>
    );
};

export default AnalyticsInputFilter;
