import { FormGroup, Input, Label } from 'reactstrap';
import { DoughnutState, ColumnDefinitionOption } from 'platform/analytics/analytics.types';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import ReportChartEntriesCount from '../ReportChartEntriesCount';

interface Props {
    componentState: DoughnutState;
    metrics: ColumnDefinitionOption[];
    dimensions: ColumnDefinitionOption[];
    isLoading: boolean;
    onChange: (state: DoughnutState) => void;
}

const ReportDoughnutChartSettings = ({ componentState, metrics, dimensions, isLoading, onChange }: Props) => (
    <>
        <ReportSelectTree
            className="mb-3"
            name="Dimension"
            options={dimensions}
            value={componentState.dimensions[0]}
            isLoading={isLoading}
            isMulti={false}
            onChange={(dimension) =>
                onChange({ ...componentState, dimensions: dimension ? [dimension] : [] })
            }
        />
        <ReportSelectTree
            className="mb-3"
            name="Metric"
            options={metrics}
            value={componentState.metrics[0]}
            isLoading={isLoading}
            isMulti={false}
            onChange={(metric) => onChange({ ...componentState, metrics: metric ? [metric] : [] })}
        />
        <ReportChartEntriesCount
            entriesCount={componentState.entriesCount}
            onChange={(entriesCount) => onChange({ ...componentState, entriesCount })}
        />
        <FormGroup check className="mt-2">
            <Label check>
                <Input
                    type="checkbox"
                    checked={componentState.showOtherValues ?? true}
                    onChange={(e) => onChange({ ...componentState, showOtherValues: e.target.checked })}
                />
                Show other values
            </Label>
        </FormGroup>
    </>
);

export default ReportDoughnutChartSettings;
