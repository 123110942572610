import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Modal, ModalBody } from 'reactstrap';
import { ReportComponentState } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { ComponentsProps } from 'platform/analytics/modes/Analytics';
import ReportComponentSettings from 'platform/analytics/reportComponents/ReportComponentActions/ReportComponentSettings';
import ReportComponents from 'platform/analytics/reportComponents/ReportComponentActions/ReportComponents';
import ModalHeaderCloseButton from 'platform/common/components/Modal/ModalHeaderCloseButton';
import './ReportComponentWizard.scss';

interface Props {
    componentsProps?: ComponentsProps;
    canEdit: boolean;
    onClose: () => void;
}

interface StepMeta {
    component: ReactNode;
    breadcrumb?: string;
}

const ReportComponentWizard = ({ componentsProps, canEdit, onClose }: Props) => {
    const [step, setStep] = useState(0);
    const [componentState, setComponentState] = useState<ReportComponentState>();
    const componentId = useSelector(analyticsSelectors.nextId);
    const dispatch = useDispatch();

    const handleComponentSelect = (value: ReportComponentState) => {
        setComponentState(value);
        setStep(step + 1);
    };

    const handleComponentCreate = () => {
        if (!componentState) {
            return;
        }

        dispatch(
            analyticsSettingsActions.addComponent({
                ...componentState,
                id: componentId,
                ...(componentState.type === 'TABLE' && {
                    activePresetIndex: 0, // always save new reports with active index 0
                }),
            })
        );
        onClose();
    };

    const handleBreadcrumbClick = (stepNumber: number) => {
        if (stepNumber === step) {
            return;
        }
        setComponentState(undefined);
        setStep(stepNumber);
    };

    const STEPS: Record<number, StepMeta> = {
        0: {
            component: <ReportComponents componentState={componentState} onClick={handleComponentSelect} />,
            breadcrumb: 'Add new component',
        },
        1: {
            component: componentState ? (
                <ReportComponentSettings
                    componentState={componentState}
                    componentsProps={componentsProps}
                    height={620}
                    canEdit={canEdit}
                    onApply={handleComponentCreate}
                    onChange={setComponentState}
                />
            ) : null,
            breadcrumb: componentState?.name,
        },
    };

    const STEP_META = STEPS[step];

    return (
        <Modal className="ReportComponentWizard" isOpen style={{ maxWidth: '760px' }}>
            <div className="ReportComponentWizard-header">
                <Breadcrumb>
                    {[...Array(step + 1)].map((x, i) => (
                        <BreadcrumbItem key={i} active={step === i} onClick={() => handleBreadcrumbClick(i)}>
                            {STEPS[i].breadcrumb}
                        </BreadcrumbItem>
                    ))}
                </Breadcrumb>
                <ModalHeaderCloseButton className="ms-auto" onClick={onClose} />
            </div>
            <ModalBody className="p-0">{STEP_META.component}</ModalBody>
        </Modal>
    );
};

export default ReportComponentWizard;
