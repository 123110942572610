import { Alert } from 'reactstrap';
import { AdvertiserFormModel } from 'platform/advertisers/advertiser.types';
import AdvertiserWizardForm from 'platform/advertisers/components/AdvertiserWizard/AdvertiserWizardForm';
import { createAdvertiser, updateAdvertiser } from 'platform/advertisers/services/advertiser.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { toastError, toastSuccess } from 'platform/common/utils/toast.util';
import { NEW_ROLE } from 'platform/userManagement/components/Roles/RoleFormContainer';
import { NEW_SEAT } from 'platform/userManagement/components/SeatForm/SeatFormContainer';
import { createRole, createSeat } from 'platform/userManagement/services/userManagement.service';
import { newAdvertiser, getParentId } from '../AdvertiserForm/AdvertiserFormContainer';

const AdvertiserWizard = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const parentId = getParentId(location.search);

    const handleSubmit = async (advertiser: Omit<AdvertiserFormModel, 'id'>) => {
        try {
            const { id: advertiserId } = await createAdvertiser(advertiser);
            toastSuccess(`Advertiser ${advertiser.name} created`);
            const { id: seatId } = await createSeat({
                ...NEW_SEAT,
                name: advertiser.name,
                allowedDomains: [advertiser.domain],
                advertiserIds: [advertiserId],
            });
            await updateAdvertiser({ id: advertiserId, ...advertiser, seatId });
            toastSuccess(`Seat ${advertiser.name} created`);
            await createRole({
                ...NEW_ROLE,
                title: advertiser.name,
            });
            toastSuccess(`Role ${advertiser.name} created`);
        } catch (error) {
            toastError({
                message: `Error occurred while creating advertiser`,
            });
        }
    };

    return (
        <FormContainer
            helpKey="advertiser_wizard_form"
            redirectTo={redirectTo}
            canCreate={canEdit}
            onOpen={async () => newAdvertiser('ADVERTISER', parentId)}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            isEdit={false}
            sidePanel
        >
            {(props) => (
                <>
                    <Alert color="primary" className="m-3">
                        This wizard will create an advertiser account, corresponding seat and role in one set.
                        If you only want to create an advertiser, please go back and select &quot;Advertiser
                        (Expert)&quot;.
                    </Alert>
                    <AdvertiserWizardForm {...props} />
                </>
            )}
        </FormContainer>
    );
};

export default AdvertiserWizard;
