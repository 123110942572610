import axiosBff from '../../axios/axiosBff';
import { IdResponse } from '../../common/common.types';
import { CreativeLabelGroup } from '../types/creativeLabel.types';

export const fetchCreativeLabels = (params: { advertiserId?: number }) =>
    axiosBff
        .get<CreativeLabelGroup[]>('/creative/api/v2/creative-label-groups', { params })
        .then((response) => response.data);

export const fetchCreativeLabel = (id: number) =>
    axiosBff
        .get<CreativeLabelGroup>(`/creative/api/v2/creative-label-groups/${id}`)
        .then((response) => response.data);

export const createCreativeLabel = (model: CreativeLabelGroup) =>
    axiosBff
        .post<IdResponse>('/creative/api/v2/creative-label-groups', model)
        .then((response) => response.data);

export const updateCreativeLabel = (model: CreativeLabelGroup) =>
    axiosBff
        .put<CreativeLabelGroup>(`/creative/api/v2/creative-label-groups/${model.id}`, model)
        .then((response) => response.data);
