import { Card } from 'reactstrap';
import TaskTitle from 'platform/task/Common/TaskTitle';
import { User } from 'platform/userManagement/types/user.type';
import { Task, TaskBoardChange, TaskData } from '../task.types';
import KanbanCardActionsDropdown from './KanbanCardActionsDropdown';
import KanbanCardFooter from './KanbanCardFooter';

export interface KanbanSubCardProps {
    boardId: number;
    subtask: Task;
    position: number;
    totalSubtasks: number;
    userOptions: User[];
    onClick: () => void;
    onMove: (position: number) => void;
    onUpdate: (data: Partial<TaskData>) => void;
    onDelete: () => void;
    onTaskWatchChange: (taskId: number, watch: boolean) => Promise<TaskBoardChange[]>;
}

const KanbanSubCard = ({
    boardId,
    subtask,
    position,
    totalSubtasks,
    userOptions,
    onClick,
    onMove,
    onUpdate,
    onDelete,
    onTaskWatchChange,
}: KanbanSubCardProps) => (
    <Card className="Kanban-sub-card flex-grow-1" onClick={onClick}>
        <div className="d-flex align-items-center">
            <TaskTitle task={subtask} />
            <KanbanCardActionsDropdown
                className="ms-auto"
                boardId={boardId}
                task={subtask}
                currentPosition={position}
                bottomPosition={totalSubtasks}
                onMove={onMove}
                onUpdate={onUpdate}
                onDelete={onDelete}
            />
        </div>
        <hr className="dashed-line my-2" />
        <KanbanCardFooter
            task={subtask}
            userOptions={userOptions}
            onTaskWatchChange={onTaskWatchChange}
            onTaskUpdate={(_, changes) => onUpdate(changes)}
        />
    </Card>
);
export default KanbanSubCard;
