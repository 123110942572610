import { INTEGRATION_TYPE_LABELS } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { CampaignModel } from '../../campaign.types';
import { CampaignIntegrationIcon } from '../CampaignIntegrationIcon';
import CampaignProgrammaticIntegrationSettings from './Integrations/CampaignProgrammaticIntegrationSettings';

type Props = {
    campaign: CampaignModel;
};

const CampaignFormType = ({ campaign }: Props) => {
    const integration = campaign.integration;
    return (
        <>
            <FormHelp iconClassName="mt-2">
                <InputWithAddOn
                    name="type"
                    disabled
                    leftAddOn={{ title: 'Type' }}
                    className="mb-3"
                    value={INTEGRATION_TYPE_LABELS[campaign.type]}
                />
            </FormHelp>

            {integration && campaign.id && campaign.advertiserId && campaign.classification.channel && (
                <FormHelp iconClassName="mt-2">
                    <InputWithAddOn
                        name="integration"
                        disabled
                        className="mb-3"
                        leftAddOn={{ title: campaign.type === 'REDIRECT' ? 'Adserver' : 'Integration' }}
                        hideInput
                        rightAddOn={{
                            className: 'd-none',
                            containerClassName: 'flex-fill text-start',
                            title: (
                                <>
                                    <CampaignIntegrationIcon
                                        integration={{
                                            ...integration,
                                            campaignId: campaign.id,
                                            advertiserId: campaign.advertiserId,
                                            type: campaign.type,
                                            lastImported: campaign.importedOn,
                                        }}
                                    />
                                    {getIntegrationLabel(integration.externalSystem)}
                                </>
                            ),
                        }}
                    />
                </FormHelp>
            )}
            {integration && campaign.type === 'RTB' && campaign.advertiserId && (
                <CampaignProgrammaticIntegrationSettings
                    advertiserId={campaign.advertiserId}
                    integration={integration}
                />
            )}
        </>
    );
};

export default CampaignFormType;
