import { Route, Routes } from 'react-router-dom';
import { Card } from 'reactstrap';
import {
    ALGORITHM_SECTION_COLOR,
    ALGORITHM_SECTION_ICON,
    ALGORITHM_SECTION_LABELS,
} from 'platform/algorithms/algorithmManager.constant';
import { ALGORITHMS } from 'platform/algorithms/algorithms.navigation';
import { useAlgorithmList } from 'platform/algorithms/hooks/useAlgorithmList';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import DateRangePicker, {
    DateRangePickerProps,
} from 'platform/common/components/DateRangePicker/DateRangePicker';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { useFeature } from 'platform/common/hooks/useFeature';
import { decimalSpacing } from 'platform/common/utils/number.util';
import StatisticWithDelta from 'platform/dashboard/components/StatisticWithDelta/StatisticWithDelta';
import AlgorithmCreate from '../AlgorithmForm/AlgorithmCreate';
import AlgorithmEdit from '../AlgorithmForm/AlgorithmEdit';
import AlgorithmSection from '../AlgorithmSection/AlgorithmSection';
import './AlgorithmList.scss';

const AlgorithmList = () => {
    const canEdit = useFeature('ALGORITHM_EDIT');

    const {
        algosSummary,
        algorithmsCount,
        pipelineCount,
        sortedAlgorithmSections,
        filteredAlgorithms,
        observerReportsBySection,
        advertiserId,
        status,
        setQueryParams,
        returnUrl,
        loading,
        refetch,
        dateFrom,
        dateTo,
        algosSummaryLoading,
    } = useAlgorithmList();

    if (loading) {
        return <OverlayLoader />;
    }

    const datepickerRender = (params?: Partial<DateRangePickerProps>) => (
        <DateRangePicker
            className="ms-auto"
            from={dateFrom || undefined}
            to={dateTo || undefined}
            onChange={(from, to) => setQueryParams({ dateFrom: from, dateTo: to })}
            {...params}
        />
    );

    return (
        <>
            <HeaderContainer>
                <div className="d-flex flex-wrap align-items-center">
                    <PageHeader
                        title={ALGORITHMS.name}
                        collapsedContent={datepickerRender({ className: 'borderless' })}
                    />
                    {datepickerRender()}
                </div>
            </HeaderContainer>
            <BodyContainer helpKey="algorithm_list">
                <div className="InsetBox d-flex p-3 mb-3 position-relative">
                    {algosSummaryLoading && <OverlayLoader />}
                    <StatisticWithDelta
                        valueClassName="fw-bold"
                        className="flex-grow-1 border-end p-2"
                        name="Algorithms"
                        value={algorithmsCount - pipelineCount}
                    />
                    <StatisticWithDelta
                        valueClassName="fw-bold"
                        className="flex-grow-1 border-end p-2"
                        name="Data pipelines"
                        value={pipelineCount}
                    />
                    <StatisticWithDelta
                        valueClassName="fw-bold"
                        className="flex-grow-1 p-2 border-end"
                        name="Executions"
                        value={decimalSpacing(algosSummary?.algorithm_executions) ?? '-'}
                    />
                    <StatisticWithDelta
                        valueClassName="fw-bold"
                        className="flex-grow-1 p-2"
                        name="Processed Entries"
                        value={decimalSpacing(algosSummary?.algorithm_processed_entries) ?? '-'}
                    />
                </div>
                {canEdit && (
                    <SelectWithAddon
                        name="Status"
                        className="mb-3"
                        value={status}
                        options={STATUS_OPTIONS}
                        isMulti
                        onChange={(value) => setQueryParams({ status: value })}
                    />
                )}
                {!sortedAlgorithmSections.length && (
                    <Card className="px-4 py-2 d-flex">
                        <div className="font-xl">No algorithm templates found</div>
                    </Card>
                )}
                {sortedAlgorithmSections.map((section, index) => (
                    <AlgorithmSection
                        key={index}
                        iconClassName={ALGORITHM_SECTION_ICON[section]}
                        label={ALGORITHM_SECTION_LABELS[section]}
                        color={ALGORITHM_SECTION_COLOR[section]}
                        algorithms={filteredAlgorithms[section]}
                        observerReports={observerReportsBySection?.[section] ?? []}
                        advertiserId={advertiserId}
                    />
                ))}
                <Routes>
                    <Route
                        path=":templateId/new"
                        element={
                            <AlgorithmCreate
                                canEdit
                                redirectTo={returnUrl}
                                afterSubmit={refetch}
                                advertiserId={advertiserId}
                            />
                        }
                    />
                    <Route
                        path=":templateId/:id"
                        element={<AlgorithmEdit canEdit redirectTo={returnUrl} afterSubmit={refetch} />}
                    />
                </Routes>
            </BodyContainer>
        </>
    );
};

export default AlgorithmList;
