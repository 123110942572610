import { ReactNode, RefObject, useState } from 'react';
import { DayModifiers, DayPicker, Matcher } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Label, Popover } from 'reactstrap';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { COMPARE_OPTIONS } from 'platform/analytics/analytics.constants';
import { AnalyticsCompareType, CompareOption, DatePickerMode } from 'platform/analytics/analytics.types';
import { updateTablesSort } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import DateRangePickerSidebar from 'platform/common/components/DateRangePicker/DateRangePickerSidebar';
import DateRangerPickerActions from 'platform/common/components/DateRangePicker/DateRangerPickerActions';
import { PopoverPlacement } from 'platform/common/components/Popover/Popover';
import Select from 'platform/common/components/Select/Select';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import useToggle from 'platform/common/hooks/useToggle';
import Switch from 'platform/formik/Switch/Switch';
import {
    ComparePeriods,
    DateRangePreset,
    DateRanges,
    formatDate,
    getCompareRange,
    getDatePeriod,
    isSecondaryPreset,
    replaceRangeBasedOnDay,
} from '../../utils/date.util';
import DateInput from '../DateRangePicker/DateInput/DateInput';
import MonthPicker from '../DateRangePicker/MonthPicker/MonthPicker';
import MonthRangePicker from '../DateRangePicker/MonthPicker/MonthRangePicker';
import YearPicker from '../DateRangePicker/YearPicker/YearPicker';
import './DoubleDateRangePickerPopover.scss';

type Props = Periods & {
    target: string | HTMLElement | RefObject<HTMLElement>;
    placement: PopoverPlacement;
    mode: DatePickerMode;
    toggle: () => void;
    minDate?: string;
    maxDate?: string;
    compareType?: AnalyticsCompareType;
    ranges?: DateRanges;
    compareOptions?: CompareOption[];
    showCompareValues: boolean;
    fromDate?: Moment;
    toDate?: Moment;
    onChange: (periods: Periods) => void;
    onCancel: () => void;
    onShowCompareValuesChange: (showCompareValues: boolean) => void;
};

interface State {
    preset: DateRangePreset | undefined;
    primaryFrom: Date | undefined;
    primaryTo: Date | undefined;
    secondaryFrom: Date | undefined;
    secondaryTo: Date | undefined;
    minDate: Date | undefined;
    maxDate: Date | undefined;
    compareType?: AnalyticsCompareType;
    focusedMonth: Date | undefined;
}

const stringToDate = (date?: string | undefined): Date | undefined =>
    date ? moment(date).toDate() : undefined;

const getSecondaryPeriod = ({
    primaryFrom,
    primaryTo,
    secondaryFrom,
    secondaryTo,
    secondaryPreset,
}: ComparePeriods) => {
    const { from, to } = getCompareRange({
        primaryFrom,
        primaryTo,
        secondaryFrom,
        secondaryTo,
        secondaryPreset,
    });
    return {
        secondaryFrom: stringToDate(from),
        secondaryTo: stringToDate(to),
    };
};

const DoubleDateRangePickerPopover = ({
    ranges,
    preset,
    primaryFrom,
    primaryTo,
    secondaryFrom,
    secondaryTo,
    minDate,
    maxDate,
    compareType,
    target,
    placement,
    mode,
    compareOptions,
    showCompareValues,
    fromDate,
    toDate,
    toggle,
    onCancel,
    onChange,
    onShowCompareValuesChange,
}: Props) => {
    const dispatch = useDispatch();
    const analyticsSettings = useSelector(analyticsSelectors.settings);
    const [editSecondaryRange, toggleEditSecondaryRange] = useToggle(false);
    const [state, setState] = useState<State>({
        preset,
        primaryFrom: stringToDate(primaryFrom),
        primaryTo: stringToDate(primaryTo),
        secondaryFrom: stringToDate(secondaryFrom),
        secondaryTo: stringToDate(secondaryTo),
        minDate: stringToDate(minDate),
        maxDate: stringToDate(maxDate),
        focusedMonth: stringToDate(primaryTo)
            ? stringToDate(primaryTo)
            : stringToDate(primaryFrom) || new Date(),
        compareType,
    });

    const isCustomCompare = state.compareType === 'CUSTOM_PERIOD';
    const showSecondaryValues = (!!state.secondaryFrom && !!state.secondaryTo) || isCustomCompare;
    const isCalendarWeek = mode === 'CALENDAR_WEEK';
    const resolveSecondaryPreset = (analyticsCompareTypeType?: AnalyticsCompareType) =>
        isSecondaryPreset(analyticsCompareTypeType) ? analyticsCompareTypeType : undefined;

    const datePickerModifiers: DayModifiers = {
        primaryStart: state.primaryFrom ?? false,
        primaryRange: (date) =>
            !!state.primaryFrom &&
            !!state.primaryTo &&
            moment(date).isBetween(state.primaryFrom, state.primaryTo, 'days'),
        primaryEnd: (state.primaryTo || (!editSecondaryRange && state.primaryTo)) ?? false,
        secondaryStart: state.secondaryFrom ?? false,
        secondaryRange: (date) =>
            !!state.secondaryFrom &&
            !!state.secondaryTo &&
            moment(date).isBetween(state.secondaryFrom, state.secondaryTo, 'days'),
        secondaryEnd: (state.secondaryTo || (editSecondaryRange && state.primaryTo)) ?? false,
        disabled: [
            minDate && { before: minDate ? moment(minDate).toDate() : undefined },
            maxDate && { after: maxDate ? moment(maxDate).toDate() : undefined },
        ].filter(Boolean) as Matcher[],
        today: false,
        outside: false,
    };

    const applyChanges = (currentState: State) => {
        const secondaryPreset = resolveSecondaryPreset(currentState.compareType);
        const settings = {
            ...(currentState.compareType === 'PREVIOUS_ROW'
                ? updateTablesSort(analyticsSettings)
                : analyticsSettings),
            compareWith: isSecondaryPreset(currentState.compareType) ? undefined : currentState.compareType,
        };

        dispatch(analyticsSettingsActions.changeSettings(settings));

        onChange({
            primaryFrom: formatDate(currentState.primaryFrom),
            primaryTo: formatDate(currentState.primaryTo),
            secondaryFrom: formatDate(currentState.secondaryFrom),
            secondaryTo: formatDate(currentState.secondaryTo),
            preset: currentState.preset,
            secondaryPreset,
        });
    };

    const handleCalendarWeekDayClick = (day: Date) => {
        const { from, to } = getDatePeriod(day, 'isoWeek');
        if (editSecondaryRange) {
            setState({
                ...state,
                preset: undefined,
                secondaryFrom: from,
                secondaryTo: to,
            });
            toggleEditSecondaryRange(false);
            return;
        }

        setState({
            ...state,
            preset: undefined,
            primaryFrom: from,
            primaryTo: to,
            ...getSecondaryPeriod({
                primaryFrom: from,
                primaryTo: to,
                secondaryFrom: state.secondaryFrom,
                secondaryTo: state.secondaryTo,
                secondaryPreset: resolveSecondaryPreset(state.compareType),
            }),
        });

        toggleEditSecondaryRange(state.compareType === 'CUSTOM_PERIOD');
    };

    const handleCalendarWeekDayRangeClick = (day: Date) => {
        const range = getDatePeriod(day, 'isoWeek');

        if (editSecondaryRange) {
            const newRange = replaceRangeBasedOnDay(
                { from: state.secondaryFrom, to: state.secondaryTo },
                range,
                day,
                'isoWeek'
            );

            setState({
                ...state,
                preset: undefined,
                secondaryFrom: newRange.from,
                secondaryTo: newRange.to,
            });
            toggleEditSecondaryRange(!state.secondaryFrom && !state.secondaryTo);
            return;
        }

        const newRange = replaceRangeBasedOnDay(
            { from: state.primaryFrom, to: state.primaryTo },
            range,
            day,
            'isoWeek'
        );

        setState({
            ...state,
            preset: undefined,
            primaryFrom: newRange.from,
            primaryTo: newRange.to,
            ...getSecondaryPeriod({
                primaryFrom: newRange.from,
                primaryTo: newRange.to,
                secondaryFrom: state.secondaryFrom,
                secondaryTo: state.secondaryTo,
                secondaryPreset: resolveSecondaryPreset(state.compareType),
            }),
        });

        toggleEditSecondaryRange(
            !!state.primaryFrom && !!state.primaryTo && state.compareType === 'CUSTOM_PERIOD'
        );
    };

    const handleMonthClick = (date: Date) => {
        const range = getDatePeriod(date, 'month');

        if (editSecondaryRange) {
            setState({
                ...state,
                preset: undefined,
                secondaryFrom: range.from,
                secondaryTo: range.to,
            });
            toggleEditSecondaryRange(false);
            return;
        }
        setState({
            ...state,
            preset: undefined,
            primaryFrom: range.from,
            primaryTo: range.to,
            ...getSecondaryPeriod({
                primaryFrom: range.from,
                primaryTo: range.to,
                secondaryFrom: state.secondaryFrom,
                secondaryTo: state.secondaryTo,
                secondaryPreset: resolveSecondaryPreset(state.compareType),
            }),
        });

        toggleEditSecondaryRange(state.compareType === 'CUSTOM_PERIOD');
    };

    const handleMonthRangeClick = (date: Date) => {
        const range = getDatePeriod(date, 'month');

        if (editSecondaryRange) {
            const newRange = replaceRangeBasedOnDay(
                { from: state.secondaryFrom, to: state.secondaryTo },
                range,
                date,
                'month'
            );
            setState({
                ...state,
                preset: undefined,
                secondaryFrom: newRange.from,
                secondaryTo: newRange.to,
            });
            toggleEditSecondaryRange(!state.secondaryFrom && !state.secondaryTo);
            return;
        }

        const newRange = replaceRangeBasedOnDay(
            { from: state.primaryFrom, to: state.primaryTo },
            range,
            date,
            'month'
        );
        setState({
            ...state,
            preset: undefined,
            primaryFrom: newRange.from,
            primaryTo: newRange.to,
            ...getSecondaryPeriod({
                primaryFrom: newRange.from,
                primaryTo: newRange.to,
                secondaryFrom: state.secondaryFrom,
                secondaryTo: state.secondaryTo,
                secondaryPreset: resolveSecondaryPreset(state.compareType),
            }),
        });

        toggleEditSecondaryRange(
            !!state.primaryFrom && !!state.primaryTo && state.compareType === 'CUSTOM_PERIOD'
        );
    };

    const handleYearClick = (date: Date) => {
        const range = getDatePeriod(date, 'year');

        if (editSecondaryRange) {
            setState({
                ...state,
                preset: undefined,
                secondaryFrom: range.from,
                secondaryTo: range.to,
            });
            toggleEditSecondaryRange(false);
            return;
        }

        setState({
            ...state,
            preset: undefined,
            primaryFrom: range.from,
            primaryTo: range.to,
            ...getSecondaryPeriod({
                primaryFrom: range.from,
                primaryTo: range.to,
                secondaryFrom: state.secondaryFrom,
                secondaryTo: state.secondaryTo,
                secondaryPreset: resolveSecondaryPreset(state.compareType),
            }),
        });

        toggleEditSecondaryRange(state.compareType === 'CUSTOM_PERIOD');
    };

    const handlers: Record<DatePickerMode, ((date: Date) => void) | undefined> = {
        CALENDAR_WEEK: handleCalendarWeekDayClick,
        CALENDAR_WEEK_RANGE: handleCalendarWeekDayRangeClick,
        DEFAULT: undefined,
        MONTH: handleMonthClick,
        MONTH_RANGE: handleMonthRangeClick,
        YEAR: handleYearClick,
    };

    const handleDayClick = (day: Date, modifiers: DayModifiers) => {
        if (modifiers.disabled) {
            return;
        }

        if (mode !== 'DEFAULT') {
            handlers[mode]?.(day);
            return;
        }

        if (editSecondaryRange) {
            if (!state.secondaryFrom || (state.secondaryFrom && state.secondaryTo)) {
                setState({
                    ...state,
                    preset: undefined,
                    secondaryFrom: day,
                    secondaryTo: undefined,
                });
                return;
            }

            setState({
                ...state,
                preset: undefined,
                secondaryFrom: day < state.secondaryFrom ? day : state.secondaryFrom,
                secondaryTo: day < state.secondaryFrom ? state.secondaryFrom : day,
            });
            toggleEditSecondaryRange(false);

            return;
        }

        if (!state.primaryFrom || (state.primaryFrom && state.primaryTo)) {
            setState({
                ...state,
                preset: undefined,
                primaryFrom: day,
                primaryTo: undefined,
            });
            return;
        }

        const newPrimaryFrom = day < state.primaryFrom ? day : state.primaryFrom;
        const newPrimaryTo = day < state.primaryFrom ? state.primaryFrom : day;

        setState({
            ...state,
            preset: undefined,
            primaryFrom: newPrimaryFrom,
            primaryTo: newPrimaryTo,
            ...getSecondaryPeriod({
                primaryFrom: newPrimaryFrom,
                primaryTo: newPrimaryTo,
                secondaryFrom: state.secondaryFrom,
                secondaryTo: state.secondaryTo,
                secondaryPreset: resolveSecondaryPreset(state.compareType),
            }),
        });
        toggleEditSecondaryRange(state.compareType === 'CUSTOM_PERIOD');
    };

    const handlePresetSelect = (rangePreset: DateRangePreset) => {
        const dateRange = ranges?.[rangePreset]?.getRange();
        if (!dateRange) {
            return;
        }
        const from = dateRange.from.toDate();
        const to = dateRange.to.toDate();
        setState({
            ...state,
            preset: rangePreset,
            primaryFrom: from,
            primaryTo: to,
            focusedMonth:
                state?.focusedMonth?.toDateString() === dateRange.to.toDate().toDateString()
                    ? dateRange.from.toDate()
                    : dateRange.to.toDate(),
            ...getSecondaryPeriod({
                primaryFrom: from,
                primaryTo: to,
                secondaryFrom: undefined,
                secondaryTo: undefined,
                secondaryPreset: resolveSecondaryPreset(state.compareType),
            }),
        });
    };

    const handlePrimaryFromBlur = (value: string) => {
        const momentValue = moment(value);
        const dateValue = momentValue.toDate();

        if (momentValue.isAfter(moment(state.primaryTo), 'day')) {
            setState({
                ...state,
                preset: undefined,
                primaryFrom: dateValue,
                primaryTo: dateValue,
            });
            return;
        }

        setState({ ...state, preset: undefined, primaryFrom: dateValue });
    };

    const handlePrimaryToBlur = (value: string) => {
        const momentValue = moment(value);
        const dateValue = momentValue.toDate();

        if (momentValue.isBefore(moment(state.primaryFrom), 'day')) {
            setState({ ...state, preset: undefined, primaryFrom: dateValue, primaryTo: dateValue });
            return;
        }

        setState({ ...state, preset: undefined, primaryTo: dateValue });
    };

    const handleSecondaryFromBlur = (value: string) => {
        const momentValue = moment(value);
        const dateValue = momentValue.toDate();

        if (momentValue.isAfter(moment(state.secondaryTo), 'day')) {
            setState({
                ...state,
                preset: undefined,
                secondaryFrom: dateValue,
                secondaryTo: dateValue,
            });
            return;
        }

        setState({ ...state, preset: undefined, secondaryFrom: dateValue });
    };

    const handleSecondaryToBlur = (value: string) => {
        const momentValue = moment(value);
        const dateValue = momentValue.toDate();

        if (momentValue.isBefore(moment(state.secondaryFrom), 'day')) {
            setState({ ...state, preset: undefined, secondaryFrom: dateValue, secondaryTo: dateValue });
            return;
        }

        setState({ ...state, preset: undefined, secondaryTo: dateValue });
    };

    const handleCustomCompareChange = (analyticsCompareTypeType: AnalyticsCompareType | undefined) => {
        if (analyticsCompareTypeType === 'CUSTOM_PERIOD') {
            setState({ ...state, compareType: analyticsCompareTypeType });
            toggleEditSecondaryRange(true);
        } else {
            setState({
                ...state,
                compareType: analyticsCompareTypeType,
                ...(isSecondaryPreset(analyticsCompareTypeType)
                    ? getSecondaryPeriod({
                          primaryFrom: state.primaryFrom,
                          primaryTo: state.primaryTo,
                          secondaryFrom: undefined,
                          secondaryTo: undefined,
                          secondaryPreset: analyticsCompareTypeType,
                      })
                    : {
                          secondaryFrom: undefined,
                          secondaryTo: undefined,
                      }),
            });

            toggleEditSecondaryRange(false);
        }
    };

    const handelReset = () => {
        setState({
            ...state,
            preset: undefined,
            primaryFrom: undefined,
            primaryTo: undefined,
            secondaryFrom: undefined,
            secondaryTo: undefined,
        });
    };

    const dayPicker = (
        <DayPicker
            ISOWeek
            numberOfMonths={2}
            modifiers={datePickerModifiers}
            showOutsideDays
            showWeekNumber
            onDayClick={handleDayClick}
            weekStartsOn={1}
            modifiersClassNames={{
                primaryStart: 'DoubleDateRangePickerPopover-day-primaryStart',
                primaryRange: 'DoubleDateRangePickerPopover-day-primaryRange',
                primaryEnd: 'DoubleDateRangePickerPopover-day-primaryEnd',
                secondaryStart: 'DoubleDateRangePickerPopover-day-secondaryStart',
                secondaryRange: 'DoubleDateRangePickerPopover-day-secondaryRange',
                secondaryEnd: 'DoubleDateRangePickerPopover-day-secondaryEnd',
            }}
            fromDate={fromDate?.toDate()}
            month={state.focusedMonth}
            onMonthChange={(newMonth) => setState({ ...state, focusedMonth: newMonth })}
            toDate={toDate?.toDate()}
        />
    );

    const pickerElementMap: Record<DatePickerMode, ReactNode> = {
        DEFAULT: dayPicker,
        CALENDAR_WEEK: dayPicker,
        CALENDAR_WEEK_RANGE: dayPicker,
        MONTH: (
            <MonthPicker
                style={{ width: 600 }}
                className="px-3 pt-3"
                from={state.primaryFrom}
                to={state.primaryTo}
                secondaryFrom={state.secondaryFrom}
                secondaryTo={state.secondaryTo}
                onDateClick={handlers.MONTH!}
                fromDate={fromDate}
                toDate={toDate}
            />
        ),
        MONTH_RANGE: (
            <MonthRangePicker
                pickerWidth={300}
                from={state.primaryFrom}
                to={state.primaryTo}
                secondaryFrom={state.secondaryFrom}
                secondaryTo={state.secondaryTo}
                onDateClick={handlers.MONTH_RANGE!}
                fromDate={fromDate}
                toDate={toDate}
            />
        ),
        YEAR: (
            <YearPicker
                width={600}
                from={state.primaryFrom}
                to={state.primaryTo}
                secondaryFrom={state.secondaryFrom}
                secondaryTo={state.secondaryTo}
                onDateClick={handlers.YEAR!}
                fromDate={fromDate}
                toDate={toDate}
            />
        ),
    };

    return (
        <Popover
            placement={placement}
            target={target}
            isOpen
            toggle={toggle}
            className="DateRangePickerPopover"
            boundariesElement={document.querySelector('.main-container') ?? undefined}
            trigger="legacy"
            flip={false}
            hideArrow
        >
            <div className="DateRangePickerPopover--content">
                {!!ranges && (
                    <DateRangePickerSidebar
                        ranges={ranges}
                        range={state.preset}
                        onRangeClick={handlePresetSelect}
                    />
                )}
                <div className="d-flex flex-column">
                    <div
                        className={classNames('d-flex mt-3 mx-3 align-items-center', {
                            active: isCustomCompare && !editSecondaryRange,
                        })}
                    >
                        <div className="DateRangePickerPopover-activityButtonContainer">
                            <Button
                                onClick={() => toggleEditSecondaryRange(false)}
                                className={classNames('DateRangePickerPopover-activityButton', {
                                    active: isCustomCompare && !editSecondaryRange,
                                })}
                                disabled={!isCustomCompare}
                            />
                        </div>
                        <DateInput
                            style={{ width: 0 }}
                            className="flex-grow-1"
                            value={state.primaryFrom ? formatDate(moment(state.primaryFrom)) : ''}
                            onBlur={handlePrimaryFromBlur}
                            onFocus={() => toggleEditSecondaryRange(false)}
                            disabled={isCalendarWeek}
                        />
                        <div className="mx-3">—</div>
                        <DateInput
                            style={{ width: 0 }}
                            className="flex-grow-1"
                            value={state.primaryTo ? formatDate(moment(state.primaryTo)) : ''}
                            onBlur={handlePrimaryToBlur}
                            onFocus={() => toggleEditSecondaryRange(false)}
                            disabled={isCalendarWeek}
                        />
                    </div>

                    {showSecondaryValues && (
                        <div
                            className={classNames('d-flex mt-3 mx-3 align-items-center', {
                                active: isCustomCompare && editSecondaryRange,
                            })}
                        >
                            <div className="DateRangePickerPopover-activityButtonContainer">
                                <Button
                                    className={classNames('DateRangePickerPopover-activityButton--compare', {
                                        active: isCustomCompare && editSecondaryRange,
                                    })}
                                    onClick={() => toggleEditSecondaryRange(true)}
                                    disabled={!isCustomCompare || isCalendarWeek}
                                />
                            </div>
                            <DateInput
                                style={{ width: 0 }}
                                className="flex-grow-1"
                                value={state.secondaryFrom ? formatDate(moment(state.secondaryFrom)) : ''}
                                onBlur={handleSecondaryFromBlur}
                                onFocus={() => toggleEditSecondaryRange(true)}
                                disabled={!isCustomCompare || isCalendarWeek}
                            />
                            <div className="mx-3">—</div>
                            <DateInput
                                style={{ width: 0 }}
                                className="flex-grow-1"
                                value={state.secondaryTo ? formatDate(moment(state.secondaryTo)) : ''}
                                onBlur={handleSecondaryToBlur}
                                onFocus={() => toggleEditSecondaryRange(true)}
                                disabled={!isCustomCompare || isCalendarWeek}
                            />
                        </div>
                    )}

                    <div className="d-flex align-items-center flex-grow m-auto">{pickerElementMap[mode]}</div>
                    <DateRangerPickerActions
                        disabled={
                            !showSecondaryValues
                                ? !(state.primaryFrom && state.primaryTo)
                                : !(
                                      state.primaryFrom &&
                                      state.primaryTo &&
                                      state.secondaryFrom &&
                                      state.secondaryTo
                                  )
                        }
                        onCancel={onCancel}
                        onReset={handelReset}
                        onApply={async () => applyChanges(state)}
                    >
                        <div>
                            <div>
                                <Label className="d-flex align-items-center mb-0 me-3 cursor-pointer">
                                    <Input
                                        name="compareWith"
                                        type="checkbox"
                                        className="position-static m-0"
                                        checked={!!state.compareType}
                                        onChange={(e) => {
                                            handleCustomCompareChange(
                                                e.target.checked ? 'AVERAGE' : undefined
                                            );
                                        }}
                                    />
                                    <span className="ms-1 h-1">Compare</span>
                                </Label>
                            </div>
                            {state.compareType && (
                                <div className="d-flex align-items-center">
                                    <div className="me-2">Comparing with</div>
                                    <Select
                                        options={compareOptions ?? COMPARE_OPTIONS}
                                        onChange={handleCustomCompareChange}
                                        value={state.compareType}
                                        isClearable={false}
                                    />
                                    {state.compareType !== 'AVERAGE' && (
                                        <>
                                            <div className="m-2">Show compare values</div>
                                            <Switch
                                                value={showCompareValues}
                                                onChange={(e) => onShowCompareValuesChange(e.target.checked)}
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </DateRangerPickerActions>
                </div>
            </div>
        </Popover>
    );
};

export default DoubleDateRangePickerPopover;
