import { CSSProperties, forwardRef, useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { isNil } from 'lodash-es';
import { useDebounce } from '../../hooks/useDebounce';

interface Props {
    value?: string;
    minLength?: number;
    debounce?: number;
    placeholder?: string;
    keyboardShortcut?: string;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
    onEnter?: (newValue: string) => void;
    onSearch?: (newValue: string) => void;
}

const Search = forwardRef<HTMLInputElement, Props>(
    (
        {
            value,
            minLength = 0,
            debounce = 400,
            placeholder = 'Search',
            keyboardShortcut,
            className,
            style,
            onClick,
            onEnter,
            onSearch,
        }: Props,
        ref
    ) => {
        const [searchText, setSearchText] = useState(value || '');

        const debouncedSearchText = useDebounce(searchText, debounce);

        useEffect(() => {
            // Sync passed value to internal state
            if ((value && value.length > minLength) || !isNil(value)) {
                setSearchText(value);
            }
        }, [minLength, value]);

        useEffect(() => {
            const valueToSet = debouncedSearchText.length > minLength ? debouncedSearchText : '';
            if (value !== valueToSet && onSearch) {
                onSearch(valueToSet);
            }
        }, [debouncedSearchText]);

        return (
            <InputGroup onClick={onClick} className={className} style={style}>
                <InputGroupText>
                    <i className="fa fa-search" />
                </InputGroupText>
                <Input
                    innerRef={ref}
                    type="text"
                    value={searchText}
                    placeholder={placeholder}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && onEnter) {
                            onEnter(searchText);
                        }
                    }}
                />
                {keyboardShortcut && (
                    <InputGroupText>
                        <kbd>{keyboardShortcut}</kbd>
                    </InputGroupText>
                )}
            </InputGroup>
        );
    }
);

export default Search;
