import UserManagementPage from './components/UserManagementPage/UserManagementPage';
import { USER_MANAGEMENT } from './userManagement.navigation';

const userManagementRoutes = [
    {
        ...USER_MANAGEMENT,
        component: UserManagementPage,
    },
];

export default userManagementRoutes;
