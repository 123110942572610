import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { OptionProps } from 'react-select';
import classNames from 'classnames';
import { intersection, snakeCase } from 'lodash-es';
import { ColumnDefinition } from 'platform/analytics/analytics.types';
import { OptionType, SelectTreeComponentProps } from '../Select/select.types';
import { flattenNodes, getNodeParents, isAnyChildNodeSelected } from './SelectTree.utils';

interface Props {
    depth?: number;
    children?: (({ node }: { node: ColumnDefinition }) => ReactNode) | ReactNode;
}

const SelectTreeOption = <T extends OptionType>(props: Omit<OptionProps<T, boolean>, 'children'> & Props) => {
    const { depth = 0, data, options, getValue, setValue, selectOption, isMulti, children } = props;
    const {
        inputValue,
        isRootNodeSelectable,
        getOptionNodes,
        getOptionValue,
        getOptionLabel,
        getOptionParentLabel,
        optionDisplay,
    } = props.selectProps as SelectTreeComponentProps<T>;

    const [isExpanded, setIsExpanded] = useState(!!inputValue);

    useEffect(() => {
        setIsExpanded(!!inputValue);
    }, [inputValue]);

    const selectedOptions = getValue();

    const nodeParents = getNodeParents(options, data, getOptionNodes, getOptionValue);
    const nodeParentsValues = nodeParents.map(getOptionValue);

    const isParentSelected = Array.isArray(selectedOptions)
        ? selectedOptions.map(getOptionValue).some((value) => nodeParentsValues.includes(value))
        : false;

    const handleExpand = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setIsExpanded((value) => !value);
    };

    const handleNodeClick = (node: T) => {
        if (isParentSelected) {
            return;
        }

        const nodes = getOptionNodes(node) || [];

        if ((depth === 0 && !isRootNodeSelectable) || (!isMulti && nodes.length > 0)) {
            setIsExpanded((value) => !value);
            return;
        }

        if (!isMulti && nodes.length === 0) {
            selectOption(node);
            return;
        }

        if (!Array.isArray(selectedOptions)) {
            throw new Error('Invalid case - selectOptions must be an array with isMulti config');
        }

        const flattenedOptions = flattenNodes([node], getOptionNodes);
        const flattenedValues = flattenedOptions.map(getOptionValue);
        const selectedValues = selectedOptions.map(getOptionValue);

        const valueWithoutSubtree = selectedOptions.filter(
            (option) => !flattenedValues.includes(getOptionValue(option))
        );

        const isPartOfTreeSelected = intersection(flattenedValues, selectedValues).length > 0;

        if (isPartOfTreeSelected) {
            setValue(valueWithoutSubtree, 'deselect-option');
        } else {
            // Select all subtree nodes
            setValue([...valueWithoutSubtree, ...flattenedOptions], 'select-option');
        }
    };

    const nodes = getOptionNodes(data) || [];

    const isNodeSelected = (Array.isArray(selectedOptions) ? selectedOptions : []).some(
        (item) => getOptionValue(item) === getOptionValue(data)
    );

    const isNodeDisabled = isParentSelected;

    return (
        <>
            <div
                className={classNames('d-flex align-items-center SelectTreeOption', {
                    'SelectTreeOption--disabled': isNodeDisabled,
                })}
                style={{ paddingLeft: `${depth * 1.25}rem` }}
                role="button"
                tabIndex={0}
                onClick={() => handleNodeClick(data)}
            >
                {nodes.length > 0 ? (
                    <>
                        <div role="button" tabIndex={0} className="p-2" onClick={handleExpand}>
                            <span
                                className={classNames('SelectTreeOption-arrow', {
                                    'SelectTreeOption-arrow--expanded': isExpanded,
                                })}
                            />
                        </div>
                        {isMulti && (isRootNodeSelectable || depth !== 0) && (
                            <input
                                type="checkbox"
                                className="me-2"
                                readOnly
                                disabled={isNodeDisabled}
                                ref={(ref) => {
                                    if (ref) {
                                        // eslint-disable-next-line no-param-reassign
                                        ref.indeterminate = isAnyChildNodeSelected(
                                            data,
                                            Array.isArray(selectedOptions) ? selectedOptions : [],
                                            getOptionNodes,
                                            getOptionValue
                                        );
                                    }
                                }}
                                checked={isNodeSelected}
                            />
                        )}
                        <div>{getOptionParentLabel(data)}</div>
                    </>
                ) : (
                    <>
                        <div style={{ height: '35px', minWidth: '24px' }} />
                        {isMulti && (isRootNodeSelectable || depth !== 0) && (
                            <input
                                type="checkbox"
                                className="me-2"
                                readOnly
                                disabled={isNodeDisabled}
                                checked={isNodeSelected}
                            />
                        )}
                        {optionDisplay ? optionDisplay(data) : getOptionLabel(data)}
                    </>
                )}
            </div>
            {isExpanded && (
                <div>
                    {nodes.map((node) => (
                        <div key={getOptionValue(node)} id={snakeCase(node.key)}>
                            <SelectTreeOption {...props} data={node} depth={depth + 1} />
                            {children && typeof children === 'function' && children({ node })}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default SelectTreeOption;
