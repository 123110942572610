import { isEmpty, isNil } from 'lodash-es';
import { parseExcelFile } from 'platform/adminPanel/services/excel.service';
import { downloadXlsx } from 'platform/common/components/DataExportButton/dataExport';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { CreativeModel, GcmCreativeModel } from 'platform/creatives/types/creative.types';

export type CreativeImportExportModel = {
    id?: number;
    conceptId?: number;
    name?: string;
    conceptName?: string;
    clickThroughUrl?: string;
    clickThroughUrlSuffix?: string;
};

type CreativeImportExportRow = {
    'Concept name'?: string;
    'Concept Id'?: string;
    'Creative name'?: string;
    'Creative Id'?: string;
    'Landing page'?: string;
    'Landing page suffix'?: string;
};

const toFormModel = (creative: CreativeImportExportRow): CreativeImportExportModel => ({
    id: Number(creative['Creative Id']) || undefined,
    conceptId: Number(creative['Concept Id']) || undefined,
    name: creative['Creative name'],
    conceptName: creative['Concept name'],
    clickThroughUrl: creative['Landing page'],
    clickThroughUrlSuffix: creative['Landing page suffix'],
});

const SUPPORTED_TYPES = ['GCM_TRACKER', 'GCM_HOSTED', 'GCM_VIDEO'];
const isSupportedType = (c: CreativeModel): c is GcmCreativeModel => SUPPORTED_TYPES.includes(c.type);

const importedColumns: TableColumn<CreativeImportExportModel>[] = [
    { Header: 'Concept name', accessor: (c) => c.conceptName, Cell: CellWithEllipsis },
    { Header: 'Concept Id', accessor: (c) => c.conceptId },
    { Header: 'Creative name', accessor: (c) => c.name },
    { Header: 'Creative Id', accessor: (c) => c.id },
    {
        Header: 'Landing page',
        accessor: (c) => c.clickThroughUrl,
        style: { whiteSpace: 'unset' },
        width: 600,
    },
    { Header: 'Landing page suffix', accessor: (c) => c.clickThroughUrlSuffix, Cell: CellWithEllipsis },
];

const exportedColumns: {
    Header: keyof CreativeImportExportRow;
    exportCell: (creative: CreativeImportExportModel) => string | number | undefined;
}[] = [
    { Header: 'Concept name', exportCell: (c) => c.conceptName },
    { Header: 'Concept Id', exportCell: (c) => c.conceptId },
    { Header: 'Creative name', exportCell: (c) => c.name },
    { Header: 'Creative Id', exportCell: (c) => c.id },
    { Header: 'Landing page', exportCell: (c) => c.clickThroughUrl },
    { Header: 'Landing page suffix', exportCell: (c) => c.clickThroughUrlSuffix },
];

const toExcel = (creatives: GcmCreativeModel[]) =>
    downloadXlsx(
        exportedColumns,
        creatives,
        `Landing pages_${creatives
            .map((c) => c.id)
            .filter(Boolean)
            .join('_')
            .slice(0, 50)}`
    );

const fromExcel = async (fileContent: string): Promise<CreativeImportExportModel[]> => {
    const columns = exportedColumns.map((c) => c.Header);
    const rows = await parseExcelFile<CreativeImportExportRow>(fileContent, columns);
    return rows
        .filter((row) => !isEmpty(Object.values(row).filter((val) => !isNil(val) && val !== '')))
        .map(toFormModel);
};

export default {
    isSupportedType,
    importedColumns,
    toExcel,
    fromExcel,
};
