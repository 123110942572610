import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';

const SET_ANALYTICS_REPORTS = 'SET_ANALYTICS_REPORTS';

type SetAnalyticsReports = {
    type: typeof SET_ANALYTICS_REPORTS;
    payload: AnalyticsReport[];
};

const analyticsReportsReducer = (state = [], action: SetAnalyticsReports): AnalyticsReport[] => {
    switch (action.type) {
        case SET_ANALYTICS_REPORTS: {
            return action.payload;
        }
        default:
            return state;
    }
};

export const analyticsReportsActions = {
    setAnalyticsReports: (payload: AnalyticsReport[]): SetAnalyticsReports => ({
        type: SET_ANALYTICS_REPORTS,
        payload,
    }),
};

export default analyticsReportsReducer;
