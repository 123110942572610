import { Button } from 'reactstrap';
import { DurationInterval } from '../../customerJourney.utils';
import JourneyDurationInfo from './JourneyDurationInfo';
import { TOGGLEABLE_COLUMN_WIDTH } from './JourneyTimeline';

interface Props {
    buttonText: string;
    durationText: string;
    durationInterval: DurationInterval;
    onClick: () => void;
}

const LineSpacer = () => (
    <div
        style={{
            width: `${(TOGGLEABLE_COLUMN_WIDTH - 60) / 2}px`,
            height: 2,
            backgroundColor: '#1B90D7',
        }}
    />
);

const JourneyToggleableColumn = ({ buttonText, durationText, durationInterval, onClick }: Props) => (
    <div
        className="d-flex flex-column align-items-center justify-content-end"
        style={{ width: TOGGLEABLE_COLUMN_WIDTH }}
    >
        <JourneyDurationInfo durationText={durationText} durationInterval={durationInterval} />
        <div
            className="mt-2 d-flex align-items-center"
            style={{ width: '100%', height: 10, marginBottom: 28 }}
        >
            <LineSpacer />
            <Button
                color="primary"
                className="d-flex align-items-center justify-content-center font-xs p-0"
                style={{
                    width: 50,
                    height: 20,
                    margin: '0 5px',
                    borderRadius: 8,
                }}
                onClick={onClick}
            >
                {buttonText}
            </Button>
            <LineSpacer />
        </div>
    </div>
);

export default JourneyToggleableColumn;
