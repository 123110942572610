import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavigationParams } from 'platform/analytics/analytics.types';
import { NavigationItem, NavigationMode } from 'platform/app/app.types';
import { parseQuery } from 'platform/common/utils/url.util';
import { isItemActive } from './navigation.util';

const getNavMode = (
    items: NavigationItem[],
    adminItems: NavigationItem[] | null,
    pathname: string,
    params: NavigationParams
): NavigationMode => {
    if (pathname.includes('wiki')) {
        return 'wiki';
    }

    let activeRootName: string | null = null;

    const recursivelyFindActiveItem = (item: NavigationItem, root: NavigationItem) => {
        if (activeRootName) {
            return;
        }

        if (isItemActive(item, pathname, params)) {
            activeRootName = root.name;
            return;
        }

        if (item.children) {
            item.children.forEach((child) => recursivelyFindActiveItem(child, root));
        }
    };

    items.forEach((root) => recursivelyFindActiveItem(root, root));

    return adminItems?.find((x) => x.name === activeRootName) ? 'admin' : 'default';
};

export const useNavMode = (allItems: NavigationItem[], adminItems: NavigationItem[] | null) => {
    const location = useLocation();

    const params: NavigationParams = parseQuery(location.search);

    const [navMode, setNavMode] = useState<NavigationMode>('default');

    const syncNavMode = useCallback(() => {
        const mode = getNavMode(allItems, adminItems, location.pathname ?? '', params);

        setNavMode(mode);
    }, [allItems, adminItems, location.pathname, params.reportId]);

    useEffect(() => {
        syncNavMode();
    }, [syncNavMode]);

    const changeNavMode = (newMode: NavigationMode) => setNavMode(newMode);

    return {
        navMode,
        changeNavMode,
    };
};
