import { AlgorithmListItem } from 'platform/algorithms/algorithms.types';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import SectionSeparator from 'platform/common/components/SectionSeparator/SectionSeparator';
import { ObserverReportRow } from 'platform/observer/observer.types';
import AlgorithmCard from '../AlgorithmCard/AlgorithmCard';

interface Props {
    iconClassName: string;
    label: string;
    color: string;
    algorithms: AlgorithmListItem[];
    observerReports: ObserverReportRow[];
    advertiserId: number;
}

const AlgorithmSection = ({
    iconClassName,
    label,
    color,
    algorithms,
    observerReports,
    advertiserId,
}: Props) => (
    <div className="mb-3 d-flex">
        <SectionSeparator iconClassName={iconClassName} />

        <div className="flex-grow-1">
            <div className="fw-bold mb-2">{label}</div>
            {algorithms.map(({ algorithm, template }) => (
                <AlgorithmCard
                    key={template.id}
                    advertiserId={advertiserId}
                    color={color}
                    algorithmId={algorithm?.id}
                    templateId={template.id}
                    area={template.display.area}
                    algorithmDisplay={template.display}
                    name={template.name}
                    shortDescription={template.display.shortDescription}
                >
                    {template.display.htmlDescription && (
                        <RawHtml>{template.display.htmlDescription}</RawHtml>
                    )}
                </AlgorithmCard>
            ))}
            {observerReports.map((report) => (
                <AlgorithmCard
                    key={report.id}
                    advertiserId={advertiserId}
                    color={color}
                    name={report.name}
                    shortDescription={report.description}
                    observerReportId={report.id}
                    observerReportDestinations={report.destinations}
                    area={report.reportType === 'ALERT' ? 'HEALTH_CHECKS' : 'ADVERTISER'}
                >
                    {report.mailText && <div>{report.mailText}</div>}
                </AlgorithmCard>
            ))}
        </div>
    </div>
);

export default AlgorithmSection;
