import { usePromise } from 'platform/common/hooks/usePromise';

export const useAdBlockerDetection = () => {
    const [{ data: adBlockerStatus }] = usePromise<'unknown' | 'detected' | 'ok'>(
        'unknown',
        async () => {
            try {
                // first we fetch a known resource to check network connectivity, to avoid false positives
                if (!(await fetch('/index.html', { method: 'HEAD' })).ok) {
                    return 'unknown';
                }
            } catch {
                return 'unknown';
            }
            try {
                // since "adhelper.js" is blacklisted by many ad-blockers, it will throw an error here
                await fetch('/adhelper.js', { cache: 'no-cache' });
                return 'ok';
            } catch {
                return 'detected';
            }
        },
        []
    );
    return adBlockerStatus;
};
