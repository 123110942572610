import { useRef } from 'react';
import { Button } from 'reactstrap';
import Popover from 'platform/common/components/Popover/Popover';
import { PopoverDropdownItem } from './PopoverDropdown';
import './PopoverDropdown.scss';

interface Props {
    item: PopoverDropdownItem;
    toggle: () => void;
}

function PopoverDropdownOption({ item, toggle }: Props) {
    const targetRef = useRef<HTMLButtonElement | null>(null);

    const handleClick = () => {
        toggle();
        item.action();
    };

    const button = (
        <button
            ref={targetRef}
            className="dropdown-item"
            disabled={item.disabled}
            type="button"
            onClick={item.confirmation ? undefined : handleClick}
        >
            {item.icon && <i className={`me-2 far fa-lg ${item.icon}`} />}
            {item.label}
        </button>
    );

    if (item.confirmation && !item.disabled) {
        return (
            <Popover
                preventOverflow
                placement="bottom"
                popoverClassName="shadow-lg"
                renderPopover={({ toggle: toggleConfirmation }) => (
                    <div className="text-center">
                        <div className="font-lg">
                            <i className="far fa-lg fa-warning text-warning me-2" />
                            {item.confirmation}
                        </div>
                        <div className="mt-3">
                            <Button
                                color="primary"
                                onClick={() => {
                                    toggleConfirmation();
                                    handleClick();
                                }}
                            >
                                Yes
                            </Button>
                            <Button className="ms-3" onClick={toggleConfirmation}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            >
                {button}
            </Popover>
        );
    }

    return button;
}

export default PopoverDropdownOption;
