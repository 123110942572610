import { FormatOptionLabelMeta } from 'react-select';
import { SelectItem } from 'platform/common/common.types';
import { OptionType } from './select.types';

const SelectOptionWithIcon = (
    { label, icon }: SelectItem<string>,
    { context }: FormatOptionLabelMeta<OptionType>
) => {
    if (!icon || context === 'value') {
        return label;
    }
    return (
        <div className="d-flex align-items-center">
            <i className={`fa ${icon} me-2`} />
            {label}
        </div>
    );
};

export default SelectOptionWithIcon;
