import { Modal, ModalBody, Button, ModalFooter } from 'reactstrap';
import { Formik } from 'formik';
import { Help } from 'platform/adminPanel/adminPanel.types';
import { useHelp } from 'platform/adminPanel/hooks/useHelp';
import { saveHelp } from 'platform/adminPanel/services/help.service';
import FormInput from 'platform/formik/FormInput/FormInput';
import AnimatedButton from '../AnimatedButton/AnimatedButton';
import ModalHeader from '../Modal/ModalHeader';

interface Props {
    help: Help;
    toggle: () => void;
}

const HelpEdit = ({ help: { key, value }, toggle }: Props) => {
    const { updateHelpState } = useHelp();

    return (
        <Formik
            initialValues={{
                help: value,
            }}
            onSubmit={async ({ help }) => {
                await saveHelp({ key, value: help });
                await updateHelpState();
                toggle();
            }}
        >
            {({ submitForm }) => (
                <Modal isOpen toggle={toggle} style={{ maxWidth: '900px' }}>
                    <ModalHeader onClose={toggle}>Add/Edit explanation</ModalHeader>
                    <ModalBody>
                        <FormInput
                            inputStyle={{
                                backgroundColor: '#f5f6f9',
                            }}
                            name="help"
                            type="textarea"
                            rows={5}
                            autoFocus
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                        <AnimatedButton onClick={submitForm}>Save</AnimatedButton>
                    </ModalFooter>
                </Modal>
            )}
        </Formik>
    );
};
export default HelpEdit;
