import { Route, Routes, useMatch } from 'react-router-dom';
import { IdResponse } from 'platform/common/common.types';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormNavigation, { FormStep } from 'platform/common/components/FormNavigation/FormNavigation';
import useToggle from 'platform/common/hooks/useToggle';
import MediaplanFileList from 'platform/mediaplan/components/MediaplanFileList/MediaplanFileList';
import MediaplanWorkflow from 'platform/mediaplan/components/MediaplanWorkflow/MediaplanWorkflow';
import MediaPlanCreateFormContainer from './MediaplanForm/MediaplanCreateFormContainer';
import MediaplanEditFormContainer from './MediaplanForm/MediaplanEditFormContainer';
import MediaplanTreeFormContainer from './MediaplanTreeForm/MediaplanTreeFormContainer';

interface Props {
    canEdit: boolean;
    redirectTo: string;
    advertiserId: number;
    afterSubmit: () => void;
}

const MEDIAPLAN_FORM_STEPS: FormStep[] = [
    {
        id: 'generalInfo',
        label: 'General info',
        getUrl: (id) => (id ? `/mediaplan/wizard/${id}` : '/mediaplan/wizard/new'),
        getDisableStepIds: (id) => (id ? [] : ['mediaInsertions', 'workflow', 'files']),
    },
    {
        id: 'mediaInsertions',
        label: 'Media insertions',
        getUrl: (id) => `/mediaplan/wizard/${id}/media-insertions`,
    },
    {
        id: 'workflow',
        label: 'Workflow',
        getUrl: (id) => `/mediaplan/wizard/${id}/workflow`,
    },
    {
        id: 'files',
        label: 'Files',
        getUrl: (id) => `/mediaplan/wizard/${id}/files`,
    },
];

const MediaplanWizard = ({ redirectTo, canEdit, advertiserId, afterSubmit }: Props) => {
    // Not using useParams here because it's not returning id param
    // Due to wildcard route in parent component
    const match = useMatch('/mediaplan/wizard/:id/*');

    const [useConfirmation, toggleUseConfirmation] = useToggle(false);
    const showConfirmationModal = useConfirmationModal();
    const id = match?.params.id ? Number(match.params.id) : undefined;

    return (
        <FormNavigation
            entryId={id}
            steps={MEDIAPLAN_FORM_STEPS}
            isExpandable={false}
            onStepClick={
                useConfirmation
                    ? (callback) =>
                          showConfirmationModal(
                              () => {
                                  callback();
                                  toggleUseConfirmation();
                              },
                              {
                                  title: 'Do you really want to leave?',
                                  text: 'Your changes will be lost.',
                              }
                          )
                    : undefined
            }
        >
            {({ navigateToNextStep }) => (
                <Routes>
                    <Route
                        path="new"
                        element={
                            <MediaPlanCreateFormContainer
                                redirectTo={redirectTo}
                                advertiserId={advertiserId}
                                canEdit={canEdit}
                                onSubmitFinish={({ id: planId }: IdResponse) => {
                                    navigateToNextStep(planId);
                                    afterSubmit();
                                }}
                            />
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <MediaplanEditFormContainer
                                canEdit={canEdit}
                                redirectTo={redirectTo}
                                onSubmitFinish={() => {
                                    navigateToNextStep(id);
                                    afterSubmit();
                                }}
                            />
                        }
                    />
                    <Route
                        path=":id/media-insertions"
                        element={
                            <MediaplanTreeFormContainer
                                canEdit={canEdit}
                                redirectTo={redirectTo}
                                onInitialValuesChange={(isDirty: boolean) => {
                                    toggleUseConfirmation(isDirty);
                                }}
                                onSubmitFinish={() => {
                                    navigateToNextStep(id);
                                    afterSubmit();
                                }}
                            />
                        }
                    />
                    <Route
                        path=":id/workflow"
                        element={<MediaplanWorkflow canEdit={canEdit} redirectTo={redirectTo} />}
                    />
                    <Route
                        path=":id/files"
                        element={<MediaplanFileList canEdit={canEdit} redirectTo={redirectTo} />}
                    />
                </Routes>
            )}
        </FormNavigation>
    );
};

export default MediaplanWizard;
