import axios from '../../../axios/axiosBff';
import { ProductFeed } from './productFeed.types';

export const fetchProductFeeds = (params: { advertiserId: number }) =>
    axios.get<ProductFeed[]>('/advertiser/api/product-feeds', { params }).then((r) => r.data);

export const fetchProductFeed = (id: number) =>
    axios.get<ProductFeed>(`/advertiser/api/product-feeds/${id}`).then((r) => r.data);

export const createProductFeed = (model: Omit<ProductFeed, 'id'>) =>
    axios.post(`/advertiser/api/product-feeds`, model).then((r) => r.data);

export const updateProductFeed = (model: ProductFeed) =>
    axios.put(`/advertiser/api/product-feeds/${model.id}`, model).then((r) => r.data);

export const deleteProductFeed = (id: number) =>
    axios.delete(`/advertiser/api/product-feeds/${id}`).then((r) => r.data);
