import IconBadge from 'platform/common/components/IconBadge/IconBadge';

interface Props {
    text: string;
}

const ChatPromptBadge = ({ text }: Props) => (
    <IconBadge text={text} icon={<i className="far fa-book-open-cover text-primary fs-6" />} />
);

export default ChatPromptBadge;
