import { Col, Row } from 'reactstrap';
import { FormikProps } from 'formik';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { AdvertiserTagFormEvent } from '../../advertiserTag.types';
import {
    CHECKOUT_EVENTS,
    DEFAULT_DOWNLOAD_EVENTS,
    ORDER_EVENTS,
    OrderEvent,
    PRODUCT_TRACKING_EVENTS,
    SITE_REGISTRATION_EVENTS,
} from '../../constants/advertiserTag.constant';
import EventsDownloadSelectionListRow from './EventsDownloadSelectionListRow';
import './EventsDownloadSelectionList.scss';

interface Props {
    events: AdvertiserTagFormEvent[];
    onChange: FormikProps<AdvertiserTagFormEvent[]>['setFieldValue'];
    loaderType: string;
}

const filterEvents = (events: AdvertiserTagFormEvent[], names: string[]) =>
    events.filter(
        (event) => names.includes(event.advertiserTagVarName) && (event.selected || !event.disabled)
    );

const EventsDownloadSelectionList = ({ events, onChange, loaderType }: Props) => {
    const updateEventProperty = (
        advertiserTagVarName: string,
        property: 'selected' | 'productData' | 'purchaseData'
    ) => {
        const eventIndex = events.findIndex((item) => item.advertiserTagVarName === advertiserTagVarName);
        const event = { ...events[eventIndex] };
        const updatedEvent = {
            ...event,
            [property]: !event[property],
        };

        if (property === 'selected') {
            if (updatedEvent.selected) {
                if (ORDER_EVENTS.includes(updatedEvent.advertiserTagVarName as OrderEvent)) {
                    updatedEvent.purchaseData = true;
                }
            } else {
                updatedEvent.productData = false;
                updatedEvent.purchaseData = false;
            }
        }

        onChange(`events[${eventIndex}]`, updatedEvent);
    };

    const setNumberOfCustomVars = (advertiserTagVarName: string, customVars: number) => {
        const eventIndex = events.findIndex((item) => item.advertiserTagVarName === advertiserTagVarName);
        const event = { ...events[eventIndex] };

        const updatedEvent = {
            ...event,
            customVarsCount: customVars,
        };

        onChange(`events[${eventIndex}]`, updatedEvent);
    };

    const renderSection = (sectionName: string, formEvents: AdvertiserTagFormEvent[]) => {
        if (!formEvents.length) {
            return null;
        }

        return (
            <div>
                <div className="EventsDownloadSelectionList-groupRow">{sectionName}</div>
                {sectionName === 'Order' && loaderType !== 'iframe' && (
                    <div className="my-1 d-flex align-items-center">
                        <div className="me-3">Cookie switch</div>
                        <FormSwitch name="advertiserTag.cookieSwitch" />
                    </div>
                )}
                {formEvents.map((event) => (
                    <EventsDownloadSelectionListRow
                        key={event.name}
                        event={event}
                        onSelectionChange={updateEventProperty}
                        setNumberOfCustomVars={setNumberOfCustomVars}
                    />
                ))}
            </div>
        );
    };

    const customEvents = events.filter((event) => event.type === 'CUSTOM_EVENT');
    const defaultEvents = filterEvents(events, DEFAULT_DOWNLOAD_EVENTS);
    const productTrackingEvents = filterEvents(events, PRODUCT_TRACKING_EVENTS);
    const siteRegistrationEvents = filterEvents(events, SITE_REGISTRATION_EVENTS);
    const checkoutEvents = filterEvents(events, CHECKOUT_EVENTS).filter(
        (e) => !ORDER_EVENTS.includes(e.advertiserTagVarName as OrderEvent)
    );
    const orderEvents = filterEvents(events, ORDER_EVENTS);

    return (
        <>
            <Row className="EventTrackingList-headerRow text-center g-0">
                <Col md={5} className="px-1" />
                <Col md={2} className="px-1">
                    Product data
                </Col>
                <Col md={2} className="px-1">
                    Purchase data
                </Col>
                <Col md={3} className="px-1">
                    Custom vars
                </Col>
            </Row>
            {renderSection('Default events', defaultEvents)}
            {renderSection('Product tracking', productTrackingEvents)}
            {renderSection('Site registration', siteRegistrationEvents)}
            {renderSection('Checkout', checkoutEvents)}
            {renderSection('Order', orderEvents)}
            {renderSection('Custom events', customEvents)}
        </>
    );
};

export default EventsDownloadSelectionList;
