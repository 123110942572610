import { Bubble } from 'react-chartjs-2';
import { BubbleDataPoint, ChartOptions, TooltipItem } from 'chart.js';
import { isNil, maxBy } from 'lodash-es';
import { DATA_TYPES } from 'platform/common/dataTypes';

type ChartItem<T> = T & BubbleDataPoint;

const getChartOptions = <T extends {}>(
    xLabel: string,
    yLabel: string,
    getTooltipLabel: (item: ChartItem<T>) => string | string[]
): ChartOptions<'bubble'> => ({
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#fff',
            borderColor: '#828a97',
            borderWidth: 1,
            bodyColor: '#52575f',
            displayColors: false,
            callbacks: {
                label: (tooltipItem: TooltipItem<'bubble'>) => {
                    if (isNil(tooltipItem.dataIndex) || isNil(tooltipItem.datasetIndex)) return '-';
                    const item = tooltipItem.dataset.data?.[tooltipItem.dataIndex];
                    return getTooltipLabel(item as ChartItem<T>);
                },
            },
        },
    },
    scales: {
        y: {
            grid: { display: true },
            beginAtZero: true,
            suggestedMin: 0,
            title: {
                display: true,
                text: yLabel,
            },
        },

        x: {
            grid: { display: true },
            beginAtZero: true,
            suggestedMin: 0,
            ticks: {
                callback: (value) => `${DATA_TYPES.FLOAT.format(value)}%`,
            },
            title: {
                display: true,
                text: xLabel,
            },
        },
    },
});

const getMiddleThreshold = <T extends {}>(data: T[], key: keyof T) => {
    const max = maxBy<T>(data, (i) => i[key]);
    return Number(max?.[key]) / 2 || 0;
};

const getCoordinate = <T extends {}>(original: T, x: number, y: number) => {
    const DOT_RADIUS = 5;
    return { ...original, x, y, r: DOT_RADIUS };
};

const mapDataToColoredGroups = <T extends {}>(data: T[] = [], xKey: keyof T, yKey: keyof T) => {
    if (!data.length) {
        return { datasets: [] };
    }

    const HIGH_X_HIGH_Y = 'rgb(110,223,139)';
    const HIGH_X_LOW_Y = 'rgb(51,187,255)';
    const LOW_X_HIGH_Y = 'rgb(231,208,72)';
    const LOW_X_LOW_Y = 'rgb(224,108,106)';

    const xMiddleThreshold = getMiddleThreshold(data, xKey);
    const yMiddleThreshold = getMiddleThreshold(data, yKey);

    const chartData = [
        { backgroundColor: LOW_X_LOW_Y, data: [] },
        { backgroundColor: LOW_X_HIGH_Y, data: [] },
        { backgroundColor: HIGH_X_LOW_Y, data: [] },
        { backgroundColor: HIGH_X_HIGH_Y, data: [] },
    ];

    data.forEach((item) => {
        let quadrant: ChartItem<T>[];
        const x = Number(item[xKey]) || 0;
        const y = Number(item[yKey]) || 0;
        if (x < xMiddleThreshold) {
            quadrant = y < yMiddleThreshold ? chartData[0].data : chartData[1].data;
        } else {
            quadrant = y < yMiddleThreshold ? chartData[2].data : chartData[3].data;
        }
        quadrant.push(getCoordinate(item, x, y));
    });

    return { datasets: chartData };
};

type Props<T> = {
    xKey: keyof T;
    xLabel: string;
    yKey: keyof T;
    yLabel: string;
    data: T[];
    getTooltipLabel: (item: ChartItem<T>) => string | string[];
};

const BubbleGroupChart = <T extends {}>({ xKey, xLabel, yKey, yLabel, data, getTooltipLabel }: Props<T>) => (
    <Bubble
        data={mapDataToColoredGroups(data, xKey, yKey)}
        options={getChartOptions(xLabel, yLabel, getTooltipLabel)}
        redraw
        height={300}
    />
);

export default BubbleGroupChart;
