import { ColumnDefinitionOption, MetricDefinition } from 'platform/analytics/analytics.types';
import { useMetricsWithData } from 'platform/analytics/hooks/useMetricsWithData';
import { filterOptionAdvanced } from 'platform/analytics/reportComponents/listFilter';
import { SelectItem } from 'platform/common/common.types';
import SelectTree from 'platform/common/components/SelectTree/SelectTree';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';
import Formula from './Formula';
import { CustomMetricFormRule } from './customMetric.types';
import { validateFormula } from './customMetric.util';

type Props = {
    field: string;
    rule: CustomMetricFormRule;
    conditionOptions: SelectItem<string>[];
    metrics: MetricDefinition[];
    metricOptions: ColumnDefinitionOption[];
    onChange: (rule: CustomMetricFormRule) => void;
};

const CustomMetricRuleEditor = ({
    field,
    rule,
    conditionOptions,
    metrics,
    metricOptions,
    onChange,
}: Props) => {
    const { showMetricsWithData, setShowOnlyMetricsWithData } = useMetricsWithData();

    return (
        <div className="d-flex align-items-center w-100 my-2">
            <div className="me-2">When</div>
            <div style={{ flexBasis: 350 }}>
                <FormSelectTree
                    name={`${field}.conditions`}
                    placeholder="default case"
                    options={conditionOptions}
                    isMulti
                    isRootNodeSelectable={false}
                    closeMenuOnSelect={false}
                    isBreadcrumbsShown={false}
                />
            </div>
            <div className="mx-2">then</div>
            <div style={{ flexBasis: 640 }}>
                <div className="d-flex align-items-center mb-1">
                    <SelectTree
                        className="w-50"
                        placeholder="Insert metric..."
                        value={undefined}
                        options={metricOptions}
                        getOptionValue={(o: ColumnDefinitionOption) => o.key}
                        getOptionLabel={(o: ColumnDefinitionOption) => `${o.name} [${o.key}]`}
                        getOptionParentLabel={(o: ColumnDefinitionOption) => o.name}
                        onChange={(key) => onChange({ ...rule, expression: `${rule.expression} ${key}` })}
                        isClearable={false}
                        isBreadcrumbsShown={false}
                        isRootNodeSelectable={false}
                        filterOption={(option: ColumnDefinitionOption, input) =>
                            filterOptionAdvanced(option.key, option.name, input) &&
                            showMetricsWithData(option.key)
                        }
                        onHasDataFilterChange={setShowOnlyMetricsWithData}
                    />
                    <div className="ms-auto text-muted">
                        Available operators:
                        <span className="text-monospace fw-bold ms-3">( ) + - * /</span>
                    </div>
                </div>
                <FormInput
                    name={`${field}.expression`}
                    type="textarea"
                    rows={2}
                    placeholder="formula..."
                    value={rule.expression}
                    validate={[
                        required,
                        (value: string) =>
                            validateFormula(value, {
                                metrics: metrics.map((m) => m.key),
                                conditional: !!rule.conditions.length,
                            }),
                    ]}
                />
                <Formula expression={rule.expression} metrics={metrics} className="mt-2" />
            </div>
        </div>
    );
};

export default CustomMetricRuleEditor;
