import { Button } from 'reactstrap';
import { deletePersonalPrompt } from 'platform/chat/chat.service';
import { PersonalPrompt } from 'platform/chat/chat.types';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { useModal } from 'platform/common/components/Modal/Modal';
import ChatPersonalPromptModal from './ChatPersonalPromptModal';
import ChatPromptCard from './ChatPromptCard';

interface Props {
    prompt: PersonalPrompt;
    isCreate?: boolean;
    onPromptClick: (prompt: PersonalPrompt) => void;
    onSubmit: (formValues: PersonalPrompt) => Promise<void>;
    fetchData: () => void;
}

const ChatPersonalPrompt = ({ prompt, isCreate = false, onPromptClick, onSubmit, fetchData }: Props) => {
    const { showModal } = useModal();

    return (
        <Button
            className="w-100 border-0 p-0 rounded"
            onClick={() => {
                onPromptClick(prompt);
            }}
        >
            <ChatPromptCard
                name={prompt.name}
                additionalIcons={
                    <>
                        <IconButton
                            className="ms-auto me-1 px-2"
                            onClick={(e) => {
                                e.stopPropagation();

                                if (prompt.id) deletePersonalPrompt(prompt.id).then(fetchData);
                            }}
                        >
                            <i className="fa-solid fa-trash text-light-slate-gray" />
                        </IconButton>
                        <IconButton
                            className="me-1 px-2"
                            onClick={(e) => {
                                e.stopPropagation();
                                showModal((toggle) => (
                                    <ChatPersonalPromptModal
                                        onSubmit={onSubmit}
                                        onOpen={async () => prompt}
                                        toggle={toggle}
                                        isCreate={isCreate}
                                    />
                                ));
                            }}
                        >
                            <i className="fa-solid fa-pencil text-light-slate-gray" />
                        </IconButton>
                    </>
                }
            />
        </Button>
    );
};

export default ChatPersonalPrompt;
