import { useParams } from 'react-router-dom';
import {
    assignAnalyticsReports,
    fetchAssignedAnalyticsReports,
} from 'platform/analyticsReports/analyticsReport.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import SeatForm from 'platform/userManagement/components/SeatForm/SeatForm';
import {
    createSeat,
    fetchSeat,
    fetchUsers,
    updateSeat,
} from 'platform/userManagement/services/userManagement.service';
import { Seat, SeatFormModel } from 'platform/userManagement/types/seat.type';

export const NEW_SEAT: Omit<Seat, 'id'> = {
    name: '',
    status: 'ACTIVE',
    platformName: 'Deep Media',
    companyLogoUrl: 'https://www.deepmedia.de/logos/deepmedia.svg',
    platformAccessUrl: 'https://app.deepmedia.de',
    platformLogoUrl: undefined,
    advertiserAccess: 'CUSTOM',
    advertiserIds: [],
    allowedDomains: [],
    allowedIpRanges: [],
    // We need to keep this for legacy creaetives https://deepmedia.atlassian.net/browse/DA-5341
    obaIconTitle: 'Deep Media',
    obaIconText: 'nutzt u.a. die GCM Adserving-Technologie. Mehr',
    obaIconUrl: 'https://www.deepmedia.de/opt-out/',
    obaIconUrlText: 'Informationen zum Datenschutz / Opt-Out',
};

const SeatFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = params.id ? Number(params.id) : undefined;

    const handleSubmit = async ({ advertiserAccess, ...model }: SeatFormModel) => {
        const response = await (model.id ? updateSeat : createSeat)({
            ...model,
            advertiserAccess: advertiserAccess.mode,
            advertiserIds: advertiserAccess.entityIds,
        });
        await assignAnalyticsReports({ seatId: model.id ?? response.id }, model.seatReportIds);
        return response;
    };

    const handleCreateOpen = async (): Promise<SeatFormModel> => {
        const { advertiserAccess, advertiserIds, ...rest } = NEW_SEAT;
        return {
            ...rest,
            advertiserAccess: { mode: advertiserAccess, entityIds: advertiserIds },
            assignedUsers: [],
            seatReportIds: [],
        };
    };

    const handleEditOpen = async (): Promise<SeatFormModel> => {
        const [seat, seatReportIds, assignedUsers] = await Promise.all([
            fetchSeat(id!),
            fetchAssignedAnalyticsReports({ seatId: id! }),
            fetchUsers({ seatId: id }),
        ]);
        const { advertiserAccess, advertiserIds, ...rest } = seat;
        return {
            ...rest,
            seatReportIds,
            assignedUsers,
            advertiserAccess: { mode: advertiserAccess, entityIds: advertiserIds },
        };
    };

    return (
        <FormContainer
            helpKey="seat_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            onOpen={() => (id ? handleEditOpen() : handleCreateOpen())}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            isEdit={!!id}
            sidePanel
        >
            {(props) => <SeatForm {...props} />}
        </FormContainer>
    );
};

export default SeatFormContainer;
