import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import cm360SegmentKpis from 'platform/segments/queries/cm360SegmentKpis.graphql';
import { SegmentKpiStats } from 'platform/segments/segments.types';
import './KpisTable.scss';

type SegmentKpi =
    | 'adh_segment_unique_users_30d'
    | 'adh_segment_total_30d'
    | 'adh_segment_unique_users_yesterday'
    | 'adh_segment_total_yesterday';

const KPI_LABELS: Record<SegmentKpi, string> = {
    adh_segment_unique_users_30d: 'Users, 30 d.',
    adh_segment_total_30d: 'Total, 30 d.',
    adh_segment_unique_users_yesterday: 'Users, Yesterday',
    adh_segment_total_yesterday: 'Total, Yesterday',
};

type Props = {
    segmentId: number;
    kpis: SegmentKpi[];
};

const KpisTable = ({ segmentId, kpis }: Props) => {
    const advertiserId = useSelector(activeAdvertiserSelectors.id);

    const { data, loading } = useQuery<{ cm360Kpis: SegmentKpiStats }>(cm360SegmentKpis, {
        variables: {
            segmentId,
            advertiserId,
        },
    });

    return (
        <div className="tooltip-inner-body position-relative">
            {loading && (
                <div style={{ height: '20px', width: '20px' }}>
                    <OverlayLoader />
                </div>
            )}
            {
                <table className="KpisTable">
                    <thead>
                        <tr>
                            <th />
                            <th>Google</th>
                        </tr>
                    </thead>
                    <tbody>
                        {kpis.map((kpi) => (
                            <tr key={kpi}>
                                <td>{KPI_LABELS[kpi]}</td>
                                <td>{DATA_TYPES.INT.format(data?.cm360Kpis?.[kpi]?.value)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    );
};

export default KpisTable;
