import { isEmpty } from 'lodash-es';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import { CAMPAIGN_CLASSIFICATION_STATIC_GROUPS } from 'platform/campaign/campaign/constants/campaignClassification.constant';
import { SelectItem } from 'platform/common/common.types';
import { CommonClassifiers } from 'platform/common/ducks/commonClassifiers.duck';
import { sortByName } from 'platform/common/utils/array.util';

export const getCampaignClassificationOptions = async (
    advertiserId: number,
    { channels, vendors }: CommonClassifiers
): Promise<SelectItem<string>[]> => {
    const [products, flightLabels] = await Promise.all([
        fetchProducts(advertiserId),
        fetchFlightLabels(advertiserId),
    ]);

    const productGroup: SelectItem = {
        label: 'Product',
        value: 'productLabel',
        nodes: products.map(({ name }) => ({ label: name, value: `productLabel#${name}` })),
    };
    const flightLabelGroup: SelectItem = {
        label: 'Flight',
        value: 'flightLabel',
        nodes: flightLabels.map(({ name }) => ({ label: name, value: `flightLabel#${name}` })),
    };
    const channelGroup: SelectItem = {
        label: 'Channel',
        value: 'channel',
        nodes: channels.map((c) => ({ label: c.name, value: `channel#${c.code}` })),
    };
    const vendorGroup: SelectItem = {
        label: 'Vendor',
        value: 'vendorSystem',
        nodes: sortByName(vendors).map((v) => ({ label: v.name, value: `vendorSystem#${v.externalSystem}` })),
    };

    return [
        ...CAMPAIGN_CLASSIFICATION_STATIC_GROUPS,
        productGroup,
        flightLabelGroup,
        channelGroup,
        vendorGroup,
    ].filter((group) => !isEmpty(group.nodes));
};
