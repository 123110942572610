import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';

interface Props {
    text: string;
}

const LogEntryShortNote = ({ text }: Props) => (
    <CellWithEllipsis value={text} className="m-0 fw-bold text-info" />
);

export default LogEntryShortNote;
