import { CSSProperties } from 'react';
import { Tooltip } from 'reactstrap';
import { ChartOptions } from 'chart.js';
import { useLineChart } from 'platform/common/components/LineChart/useLineChart';
import LinePure from '../LinePure/LinePure';

export interface LineChartProps {
    values: number[];
    labels: string[];
    height: number;
    customizeChartOptions?: (options: ChartOptions) => ChartOptions;
    customizeDatasetOptions?: (options: ChartOptions<'line'>) => ChartOptions<any>;
}

const tooltipTargetCSS: CSSProperties = {
    display: 'block',
    height: '1px',
    width: '1px',
    visibility: 'hidden',
    position: 'absolute',
};

const LineChart = ({
    values,
    labels,
    height = 70,
    customizeChartOptions,
    customizeDatasetOptions,
}: LineChartProps) => {
    const { tooltip, tooltipTargetId, options, data, onMouseOut } = useLineChart({
        values,
        labels,
        height,
        customizeChartOptions,
        customizeDatasetOptions,
    });

    const tooltipTargetStyle: CSSProperties = {
        ...tooltipTargetCSS,
        bottom: tooltip.bottom,
        left: tooltip.left,
    };

    return (
        <div className="position-relative" onMouseOut={onMouseOut}>
            <Tooltip key={tooltip.title} isOpen={tooltip.open} placement="bottom" target={tooltipTargetId}>
                {tooltip.title}
                <br />
                {tooltip.body}
            </Tooltip>
            <a id={tooltipTargetId} style={tooltipTargetStyle}>
                I am hidden
            </a>
            <div className="px-0">
                <LinePure data={data} options={options} height={height} />
            </div>
        </div>
    );
};

export default LineChart;
