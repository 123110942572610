import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { isString } from 'lodash-es';
import { ReportDrill } from 'platform/analytics/analytics.types';
import LinkPopover from 'platform/analytics/drilling/LinkPopover';
import { useDrillCell } from './useDrillCell';

interface Props {
    row: { [key: string]: any };
    drills: ReportDrill[];
    value: ReactNode;
    componentId?: number;
}

const DrillCell = ({ drills, value, componentId, row }: Props) => {
    const { drillLinks, items } = useDrillCell(drills, row, componentId);

    if (drillLinks.length === 0) {
        return <>{value}</>;
    }

    if (drills.length === 1) {
        return (
            <Link className="d-block text-truncate" to={drillLinks[0]} style={{ lineHeight: '1rem' }}>
                {value}
            </Link>
        );
    }

    const primaryIndex = drills.findIndex((drill) => drill.isPrimary);
    const title = isString(value) ? value : undefined;

    return (
        <div className="d-flex align-items-center w-100">
            <LinkPopover items={items}>
                {primaryIndex !== -1 ? (
                    <Link className="text-truncate" to={drillLinks[primaryIndex]} title={title}>
                        {value}
                    </Link>
                ) : (
                    <div className="text-truncate" title={title}>
                        {value}
                    </div>
                )}
            </LinkPopover>
        </div>
    );
};

export default DrillCell;
