import momentTz from 'moment-timezone';
import { useImageUpload } from 'platform/adminPanel/hooks/useImageUpload';
import { AdvertiserFormModel } from 'platform/advertisers/advertiser.types';
import { Optional } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { entitiesToOptions } from 'platform/common/utils/option.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormInputWithUrlPreview from 'platform/formik/FormInputWithUrlPreview/FormInputWithUrlPreview';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { fetchSeats } from 'platform/userManagement/services/userManagement.service';

const TIME_ZONE_OPTIONS = momentTz.tz.names().map((n) => ({ label: n, value: n }));

const AdvertiserForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: { initialValues, setFieldValue, submitForm, values },
    isEdit,
}: FormProps<Optional<AdvertiserFormModel, 'id'>>) => {
    const { uploadingImage, handleImageUpload } = useImageUpload();
    const [{ data: seats, loading }] = usePromise(
        [],
        () =>
            fetchSeats({}).then((list) => entitiesToOptions(list.filter((seat) => seat.status === 'ACTIVE'))),
        []
    );
    const { type, iconUrl } = values;
    const isSuperuser = useFeature('SUPERUSER');

    return (
        <CardForm
            title={isEdit ? 'Edit Advertiser' : 'New Advertiser'}
            subtitle={isEdit && `ID: ${initialValues.id}`}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
            animationDelay={0}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Name">
                    <FormInput name="name" validate={required} />
                </FormRow>
                <FormRow label="Status">
                    <FormStateSelect name="state" validate={required} />
                </FormRow>
                {type === 'ADVERTISER' && (
                    <FormRow label="Domain">
                        <FormInput name="domain" validate={required} />
                    </FormRow>
                )}
                <FormRow label="Timezone">
                    <FormSelect
                        options={TIME_ZONE_OPTIONS}
                        name="regionalSettings.timeZoneId"
                        validate={required}
                    />
                </FormRow>
                {type === 'ADVERTISER' && (
                    <>
                        <FormRow label="Icon url">
                            <FormInputWithUrlPreview name="iconUrl" value={iconUrl} validate={required} />
                        </FormRow>
                        <FormRow>
                            <UploadInput
                                acceptableMimeTypes="image/jpeg, image/png, image/svg+xml"
                                onFileUpload={(file, uploadProgressProps) =>
                                    handleImageUpload({
                                        file,
                                        uploadProgressProps,
                                        onUpload: (fileName) => setFieldValue('iconUrl', fileName),
                                    })
                                }
                                loading={uploadingImage}
                                subtitle="Upload jpeg/png/svg"
                            />
                        </FormRow>
                        <FormRow label="Default seat">
                            <FormSelect
                                name="seatId"
                                options={seats}
                                isLoading={loading}
                                validate={required}
                            />
                        </FormRow>
                        <FormRow label="Notes">
                            <FormInput name="notes" type="textarea" />
                        </FormRow>
                        {isSuperuser && (
                            <FormRow label="Superuser only">
                                <FormSwitch name="superuserOnly" />
                            </FormRow>
                        )}
                    </>
                )}
            </ControlledCard>
        </CardForm>
    );
};

export default AdvertiserForm;
