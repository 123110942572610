import { makeOptions } from 'platform/common/utils/option.util';

const CHART = 'CHART';
const TABLE = 'TABLE';

export const VISIT_TIME_BUTTONS = {
    CHART,
    TABLE,
};

const VISIT_TIME_BUTTON_LABELS = {
    [CHART]: 'Chart',
    [TABLE]: 'Table',
};

export const VISIT_TIME_BUTTON_OPTIONS = makeOptions(VISIT_TIME_BUTTON_LABELS);

export enum TtaButton {
    HOURS = 'HOURS',
    DAYS = 'DAYS',
}

export enum ChartScale {
    HOURS = 'HOURS',
    DAYS = 'DAYS',
}

const TTA_BUTTON_LABELS = {
    [TtaButton.HOURS]: 'Hours',
    [TtaButton.DAYS]: 'Days',
};

export const TTA_BUTTON_OPTIONS = makeOptions(TTA_BUTTON_LABELS);

export const CHART_TYPES = {
    VISIT_TIME: 'VISIT_TIME',
    TIME_TO_ACTION: 'TIME_TO_ACTION',
    VISIT_FREQUENCY: 'VISIT_FREQUENCY',
    VISITED_PAGES: 'VISITED_PAGES',
};

export enum KpiOverviewChartType {
    USERS = 'USERS',
    ALL_ACTIONS = 'ALL_ACTIONS',
    ATTRIBUTED_ACTIONS = 'ATTRIBUTED_ACTIONS',
    ACTION_PROBABILITY = 'ACTION_PROBABILITY',
}

export const SCALE_TEXT = {
    [ChartScale.HOURS]: 'Time to convert in hours',
    [ChartScale.DAYS]: 'Time to convert in days',
};
