import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { FUNNEL_SETTINGS } from 'platform/segments/constants/segmentFunnel.constant';

interface Props {
    chartHeight: number;
}

const LoadingState = ({ chartHeight }: Props) => (
    <>
        <div className="FunnelOverviewStats d-flex flex-wrap border-top border-bottom">
            {FUNNEL_SETTINGS.map(({ title }) => (
                <div key={title} className="p-3" style={{ width: `calc(100% / ${FUNNEL_SETTINGS.length})` }}>
                    <Placeholder className="mb-1" width="80%" height="15px" />
                    <Placeholder className="mb-1" width="50%" height="26px" />
                    <Placeholder width="35%" height="19px" />
                </div>
            ))}
        </div>
        <Placeholder height={`${chartHeight}px`} className="mt-2" />
        <div className="FunnelActionProbability d-flex flex-wrap border-top">
            {FUNNEL_SETTINGS.map(({ title }) => (
                <div key={title} className="p-3">
                    <Placeholder className="mb-1" width="80%" height="15px" />
                    <Placeholder width="25%" height="24px" />
                </div>
            ))}
        </div>
    </>
);

export default LoadingState;
