import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { ClassificationOptionsProvider } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableForm/ClassificationOptionsContext';
import {
    CampaignModel,
    MARKETING_GOAL_OPTIONS,
    SEARCH_TERM_STRATEGY_OPTIONS,
    TARGET_AUDIENCE_OPTIONS,
} from 'platform/campaign/campaign/campaign.types';
import { CampaignClassificationModel } from 'platform/campaign/campaign/campaignClassification.types';
import { searchTermStrategyAvailable } from 'platform/campaign/campaign/mappers/campaign.mapper';
import FormCountryLabelsSelect from 'platform/campaign/common/FormCountryLabelsSelect';
import FormFlightLabelSelect from 'platform/campaign/common/FormFlightLabelSelect';
import FormProductLabelSelect from 'platform/campaign/common/FormProductLabelSelect';
import FormProductSubLabelSelect from 'platform/campaign/common/FormProductSubLabelSelect';
import FormTimeOffersSelect from 'platform/campaign/common/FormTimeOffersSelect';
import { MediaType } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import ControlledCardBorderless from 'platform/common/components/ControlledCard/ControlledCardBorderless';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { required } from 'platform/common/utils/validators.util';
import FormRadioList from 'platform/formik/FormRadioList/FormRadioList';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

// CampaignClassifications are used in multiples places, so we want to use same help
const CLASSIFICATION_HELP_KEY = 'classification_form';

interface Props {
    mediaType: MediaType;
    classification: CampaignClassificationModel;
    setFieldValue: FormikProps<CampaignModel>['setFieldValue'];
    className?: string;
    rowProps?: {
        labelWidth?: number;
        contentWidth?: number;
        rowClassName?: string;
        labelClassName?: string;
        childrenColClassName?: string;
    };
}

const CampaignClassifications = ({ mediaType, classification, setFieldValue, className }: Props) => {
    const channels = useSelector(classifierSelectors.channels);

    return (
        <ClassificationOptionsProvider>
            <div className={classNames('CampaignClassifications-fields', className)}>
                <FormHelp helpKey={`${CLASSIFICATION_HELP_KEY}_channel`} iconClassName="mt-2">
                    <FormSelect
                        firstAddon="Channel"
                        className="flex-fill"
                        name="classification.channel"
                        options={channels.filter((c) => c.mediaType === mediaType)}
                        getOptionValue={(c) => c.code}
                        getOptionLabel={(c) => c.name}
                        isClearable={false}
                        validate={required}
                    />
                </FormHelp>
                {searchTermStrategyAvailable(classification) && (
                    <FormHelp helpKey={`${CLASSIFICATION_HELP_KEY}searchTermStrategy`} iconClassName="mt-2">
                        <FormSelect
                            firstAddon="Search term strategy"
                            className="flex-fill"
                            containerClassName="flex-grow-1"
                            name="classification.searchTermStrategy"
                            options={SEARCH_TERM_STRATEGY_OPTIONS}
                            validate={required}
                        />
                    </FormHelp>
                )}
                <FormHelp helpKey={`${CLASSIFICATION_HELP_KEY}_targetAudience`}>
                    <FormRadioList
                        label={'Audience'}
                        options={TARGET_AUDIENCE_OPTIONS}
                        optionsClassName="d-flex"
                        name={'classification.targetAudience'}
                        validate={required}
                    />
                </FormHelp>
                <FormHelp helpKey={`${CLASSIFICATION_HELP_KEY}_marketingGoal`}>
                    <FormRadioList
                        label={'Marketing goal'}
                        options={MARKETING_GOAL_OPTIONS}
                        optionsClassName="d-flex"
                        name={'classification.marketingGoal'}
                        validate={required}
                    />
                </FormHelp>
                <ControlledCardBorderless
                    title="Optional"
                    className="ControlledCardBorderless"
                    bodyClassName="mt-3"
                    isExpanded
                >
                    <FormHelp helpKey={`${CLASSIFICATION_HELP_KEY}_productLabel`} iconClassName="mt-2">
                        <FormProductLabelSelect
                            firstAddon="Product"
                            inputClassName="w-100"
                            name="classification.productLabel"
                            onChange={() => setFieldValue('classification.productSubLabel', null)}
                        />
                    </FormHelp>
                    {!!classification.productLabel?.value && (
                        <FormHelp helpKey={`${CLASSIFICATION_HELP_KEY}_productSubLabel`} iconClassName="mt-2">
                            <FormProductSubLabelSelect
                                firstAddon="Sub-product"
                                inputClassName="w-100"
                                name="classification.productSubLabel"
                                parentProductLabel={classification.productLabel}
                            />
                        </FormHelp>
                    )}
                    <FormHelp helpKey={`${CLASSIFICATION_HELP_KEY}_flightLabel`} iconClassName="mt-2">
                        <FormFlightLabelSelect
                            firstAddon="Flight"
                            inputClassName="w-100"
                            name="classification.flightLabel"
                        />
                    </FormHelp>
                    <FormHelp helpKey={`${CLASSIFICATION_HELP_KEY}_timeOfferIds`} iconClassName="mt-2">
                        <FormTimeOffersSelect
                            firstAddon="Time offer"
                            name="classification.timeOfferIds"
                            inputClassName="w-100"
                        />
                    </FormHelp>
                    <FormHelp
                        helpKey={`${CLASSIFICATION_HELP_KEY}_countryLabels`}
                        className="mb-0"
                        iconClassName="mt-2"
                    >
                        <FormCountryLabelsSelect
                            firstAddon="Country"
                            className="w-100"
                            name="classification.countryLabels"
                            returnOnlyValues={false}
                        />
                    </FormHelp>
                </ControlledCardBorderless>
            </div>
        </ClassificationOptionsProvider>
    );
};

export default CampaignClassifications;
