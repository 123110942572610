import { useParams } from 'react-router';
import LogBookForm from 'platform/campaign/logBook/components/LogBookForm/LogBookForm';
import { toNotesApiModel, toNotesFormModel } from 'platform/campaign/logBook/logBook.mapper';
import { LogBookNoteFormModel } from 'platform/campaign/logBook/logBook.types';
import { getLog, updateNote } from 'platform/campaign/logBook/logbook.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';

const LogBookEdit = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const onOpen = () => getLog(id).then(toNotesFormModel);
    const onSubmit = (data: LogBookNoteFormModel) => updateNote(id, toNotesApiModel(data));

    return (
        <FormContainer
            helpKey="logbook_form"
            onSubmit={onSubmit}
            onOpen={onOpen}
            canEdit={canEdit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            isEdit
            sidePanel
        >
            {(props) => <LogBookForm {...props} />}
        </FormContainer>
    );
};

export default LogBookEdit;
