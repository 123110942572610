import { Alert } from 'reactstrap';
import { ErrorMessage } from 'formik';

type Props = {
    name: string;
    className?: string;
    alert?: boolean;
};

const FieldError = ({ name, className, alert }: Props) => (
    <ErrorMessage name={name}>
        {(message: any) => {
            if (typeof message !== 'string') {
                // a parent field (e.g. a FieldArray) may aggregate the errors of its children
                // into an array/object like [{name: 'required', nestedName: 'Nested error'}],
                // so we need to check for msg type as it's not always a 'string'
                return null;
            }
            return (
                <div className={`text-danger ${className}`}>
                    {alert ? <Alert color="danger">{message}</Alert> : message}
                </div>
            );
        }}
    </ErrorMessage>
);

export default FieldError;
