import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { CHART_PREVIEW_HEIGHT } from 'platform/analytics/analytics.constants';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { LineState, StackLineState, Template } from 'platform/analytics/analytics.types';
import {
    exportChartData,
    exportedFilename,
    findColumn,
    getChartAlignment,
} from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import ReportChartTitle from 'platform/analytics/reportComponents/ReportChartContainer/ReportChartTitle';
import LineChart from 'platform/analytics/reportComponents/ReportChartContainer/ReportLineChart/LineChart';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import ChartEmptyView from 'platform/common/components/ChartEmptyView/ChartEmptyView';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import InformationModal from 'platform/common/components/InformationModal/InformationModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import ReportPivotController from '../../ReportPivotController/ReportPivotController';
import { useReport } from '../../useReport';
import '../ReportChart.scss';

interface Props {
    template: Template;
    reportName: string;
    componentState: LineState | StackLineState;
    isStacked?: boolean;
}

const ReportLineChart = ({ template, reportName, componentState, isStacked }: Props) => {
    const dispatch = useDispatch();

    const changeComponentState = (newState: any) => {
        dispatch(analyticsSettingsActions.changeComponentState(newState));
    };
    const { includeVatRate, modelOptIn, components } = useSelector(analyticsSelectors.settings);
    const isMobile = useSelector(layoutSelectors.isMobile);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const {
        metrics,
        customFilters,
        customDateRange,
        dimensions,
        periodDimension,
        sortBy,
        title,
        showValues,
        entriesCount,
    } = componentState;
    const dimension = dimensions[0];

    const settings = useMemo<ReportSettings>(
        () => ({
            dimensions: dimension ? [dimension, periodDimension] : [periodDimension],
            metrics,
            customFilters,
            customDateRange,
            templateId: template.id,
            sort: [{ orderBy: periodDimension, direction: 'ASC' }],
            includeVatRate,
            modelOptIn,
        }),
        [
            periodDimension,
            metrics,
            customFilters,
            customDateRange,
            template.id,
            includeVatRate,
            modelOptIn,
            dimension,
        ]
    );
    const { loading, report, error, refetchReport } = useReport({
        settings,
        type: template.type,
        supportsComparison: false,
        componentState,
    });

    const { showModal } = useModal();

    if (error) {
        return <ErrorMessage error={error} onRetry={refetchReport} />;
    }

    if (loading) {
        return <Placeholder minHeight="290px" />;
    }

    const metricCells = report?.header.filter((item) => metrics.includes(item.key));
    const dimensionCell = findColumn(report, periodDimension);

    if (!report || !metricCells?.length || !dimensionCell) {
        return <ChartEmptyView />;
    }

    const titleText = title || `${metricCells.map((m) => m.name).join(', ')}`;

    return (
        <>
            <ReportChartTitle template={template} title={titleText} componentState={componentState}>
                <div className="ReportChart-buttonContainer ms-1">
                    <div className="ReportPivotController-wrapper">
                        <ReportPivotController
                            onChange={(option) =>
                                changeComponentState({
                                    ...componentState,
                                    periodDimension: option.value,
                                })
                            }
                            value={componentState.periodDimension ?? 'date'}
                        />
                    </div>

                    <Button
                        className="ReportChart-button"
                        title="View in modal"
                        onClick={() =>
                            showModal((toggle) => (
                                <InformationModal
                                    isOpen
                                    toggle={toggle}
                                    title={titleText}
                                    style={{ maxWidth: '80%' }}
                                >
                                    <LineChart
                                        dimension={dimension}
                                        metricCells={metricCells}
                                        periodDimension={periodDimension}
                                        sortBy={sortBy}
                                        report={report}
                                        height={CHART_PREVIEW_HEIGHT}
                                        showValues={showValues}
                                        entriesCount={entriesCount}
                                    />
                                </InformationModal>
                            ))
                        }
                    >
                        <i className="fal fa-expand" />
                    </Button>
                    {!isMobile && (
                        <Button
                            className="ReportChart-button"
                            title="Download chart data"
                            onClick={exportChartData(
                                metrics,
                                periodDimension,
                                report,
                                exportedFilename(reportName, titleText, periods)
                            )}
                        >
                            <i className="fa fa-download" />
                        </Button>
                    )}
                </div>
            </ReportChartTitle>

            <div className={classNames('d-flex  h-100 p-3', getChartAlignment(componentState, components))}>
                <div className="w-100">
                    <LineChart
                        dimension={dimension}
                        metricCells={metricCells}
                        periodDimension={periodDimension}
                        sortBy={sortBy}
                        report={report}
                        height={285}
                        stacked={isStacked}
                        showValues={showValues}
                        entriesCount={entriesCount}
                    />
                </div>
            </div>
            <ReportTracingTips report={report} />
        </>
    );
};

export default ReportLineChart;
