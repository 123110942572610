import SegmentDetails from './components/SegmentDetails/SegmentDetails';
import SegmentPage from './components/SegmentPage/SegmentPage';
import { SEGMENT_DETAILS, SEGMENTS } from './segments.navigation';

const segmentRoutes = [
    {
        ...SEGMENTS,
        component: SegmentPage,
    },
    {
        ...SEGMENT_DETAILS,
        component: SegmentDetails,
    },
];

export default segmentRoutes;
