import { ChangeEvent, ElementType } from 'react';
import { Input, Label } from 'reactstrap';
import classNames from 'classnames';
import { InteractionComponentState, InteractionType } from 'platform/analytics/analytics.types';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { INTERACTION_COMPONENT_DEFAULTS } from '../../ReportComponentActions/reportComponents.constant';
import ReportInteractionDatePickerFields from './ReportInteractionDatePickerFields';
import ReportInteractionFilterFields from './ReportInteractionFilterFields';
import ReportInteractionInputFields from './ReportInteractionInputFields';
import ReportInteractionSelectFields from './ReportInteractionSelectFields';
import ReportInteractionSliderFields from './ReportInteractionSliderFields';

const FIELD_COMPONENTS: Record<InteractionType, ElementType<any>> = {
    INPUT: ReportInteractionInputFields,
    SLIDER: ReportInteractionSliderFields,
    SELECT: ReportInteractionSelectFields,
    FILTER: ReportInteractionFilterFields,
    DATE_PICKER: ReportInteractionDatePickerFields,
};

interface Props {
    componentState: InteractionComponentState;
    onChange: (state: InteractionComponentState) => void;
}

const formatOptionLabel = (interaction: InteractionComponentState) => (
    <div className="d-flex align-items-center">
        <i className={classNames('text-primary', interaction.icon)} />
        <span className="ms-1">{interaction.name}</span>
    </div>
);

const ReportInteractionSettings = ({ componentState, onChange }: Props) => {
    const handleTypeChange = (interactionType: string) => {
        const newState = INTERACTION_COMPONENT_DEFAULTS.find(
            (interaction) => interaction.interactionType === interactionType
        );
        if (newState) {
            onChange({
                ...newState,
                title: componentState.title,
                key: componentState.key,
                defaultValue: componentState.defaultValue as any,
                id: componentState.id,
            });
        }
    };

    const FieldsComponent = FIELD_COMPONENTS[componentState.interactionType];

    return (
        <>
            <SelectWithAddon
                className="mb-3"
                selectClassname="flex-fill"
                value={componentState.interactionType}
                name="Type"
                options={INTERACTION_COMPONENT_DEFAULTS}
                isClearable={false}
                getOptionValue={(option: InteractionComponentState) => option.interactionType}
                getOptionLabel={(option: InteractionComponentState) => option.name}
                formatOptionLabel={formatOptionLabel}
                onChange={handleTypeChange}
            />
            <InputWithAddOn
                className="mb-3"
                value={componentState.title || ''}
                placeholder="Optional title"
                type="text"
                leftAddOn={{ title: 'Name' }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChange({ ...componentState, title: e.target.value })
                }
            />
            <FieldsComponent componentState={componentState} onChange={onChange} />
            <Label check className="mb-3 d-block">
                <Input
                    type="checkbox"
                    checked={componentState.required}
                    onChange={(e) => onChange({ ...componentState, required: e.target.checked })}
                />
                Required
            </Label>
        </>
    );
};

export default ReportInteractionSettings;
