import { useEffect } from 'react';
import { AdvertiserAccountKey } from 'platform/advertisers/advertiser.types';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import {
    ExternalSystem,
    getIntegrationOptions,
    IMPORTED_INTEGRATIONS,
} from 'platform/common/constants/externalIntegration.constant';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { useAdvertiserExternalAccountOptions } from 'platform/common/utils/advertiser.util';

interface Props {
    advertiserId: number;
    onChange: (accountKey: AdvertiserAccountKey | undefined) => void;
}

type QueryParams = {
    externalSystem?: ExternalSystem;
    externalAdvertiserId?: string;
};

const CampaignImportListFilters = ({ advertiserId, onChange }: Props) => {
    const {
        queryParams: { externalSystem, externalAdvertiserId },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        externalAdvertiserId: undefined,
        externalSystem: undefined,
    });

    useEffect(
        () =>
            onChange(
                externalSystem && externalAdvertiserId
                    ? { advertiserId, externalSystem, externalAdvertiserId }
                    : undefined
            ),
        [advertiserId, externalSystem, externalAdvertiserId]
    );

    const advertiserAccounts = useAdvertiserExternalAccountOptions(advertiserId);

    const getAccountOptions = (system: ExternalSystem | undefined) =>
        (system ? advertiserAccounts[system] : undefined) ?? [];

    const systemOptions = getIntegrationOptions(
        IMPORTED_INTEGRATIONS.filter((system) => getAccountOptions(system).length)
    );

    return (
        <div className="d-flex align-items-center mb-3 position-relative">
            <ListFilters>
                <SelectWithAddon
                    name="Vendor"
                    value={externalSystem}
                    onChange={(value) => {
                        const systemAccounts = getAccountOptions(value);
                        setQueryParams({
                            externalSystem: value,
                            externalAdvertiserId:
                                systemAccounts.length === 1 ? systemAccounts[0].value : undefined,
                        });
                    }}
                    options={systemOptions}
                    isClearable={false}
                />
                <SelectWithAddon
                    name={`Account`}
                    value={externalAdvertiserId}
                    onChange={(value) => setQueryParams({ externalAdvertiserId: value })}
                    options={getAccountOptions(externalSystem)}
                    isClearable={false}
                />
            </ListFilters>
        </div>
    );
};

export default CampaignImportListFilters;
