import classNames from 'classnames';
import { publicImageUrl } from 'platform/common/utils/environment.util';
import { Validator } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

interface Props {
    value: string | undefined;
    name: string;
    validate?: Validator | Validator[];
}

const FormInputWithUrlPreview = ({ value, name, validate }: Props) => (
    <FormInput
        name={name}
        rightAddOn={{
            className: classNames('fa fa-external-link-alt', 'cursor-pointer'),
            title: '',
            onClick: () => window.open(publicImageUrl(value), '_blank'),
        }}
        validate={validate}
    />
);
export default FormInputWithUrlPreview;
