import { OptionProps } from 'react-select';
import { snakeCase } from 'lodash-es';
import SelectTreeOption from 'platform/common/components/SelectTree/SelectTreeOption';
import StatisticTooltip from 'platform/common/components/StatisticTooltip/StatisticTooltip';
import { OptionType } from '../Select/select.types';

const SelectTreeOptionWithTooltip = <T extends OptionType>(props: OptionProps<T, true>) => (
    <SelectTreeOption<T> {...props}>
        {({ node }) => <StatisticTooltip target={snakeCase(node.key)} tooltip={node} />}
    </SelectTreeOption>
);

export default SelectTreeOptionWithTooltip;
