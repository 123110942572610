import { Alert } from 'reactstrap';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import ControlledCardBorderless from 'platform/common/components/ControlledCard/ControlledCardBorderless';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import { limitFloatTo, required } from 'platform/common/utils/validators.util';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';

type Props = {
    disabled?: boolean;
    warning?: string;
};

const CostSources = ({ warning, disabled }: Props) => (
    <ControlledCard title="Cost sources" bodyClassName="form-content">
        {!!warning && <Alert color="warning">{warning}</Alert>}
        <FormHelp iconClassName="mt-2">
            <FormNumberInput
                leftAddOn={{ title: 'Agency fee' }}
                rightAddOn={{ title: '%' }}
                name="monetization.costPerSpendPercentage"
                validate={[required, limitFloatTo(2)]}
                disabled={disabled}
            />
        </FormHelp>
        <ControlledCardBorderless
            title="Optional"
            className="ControlledCardBorderless"
            bodyClassName="mt-3"
            isExpanded={false}
        >
            <FormHelp iconClassName="mt-2">
                <FormNumberInput
                    leftAddOn={{ title: 'Product Fee - Spend + X' }}
                    rightAddOn={{ title: '%' }}
                    name="monetization.productFeeSpendX"
                    validate={[limitFloatTo(2)]}
                    disabled={disabled}
                />
            </FormHelp>
            <FormHelp iconClassName="mt-2">
                <FormNumberInput
                    leftAddOn={{ title: 'Product Fee - Target CPM' }}
                    rightAddOn={{ title: 'EUR' }}
                    name="monetization.productFeeTargetCPM"
                    validate={[limitFloatTo(2)]}
                    disabled={disabled}
                />
            </FormHelp>
            <FormHelp className="mb-0" iconClassName="mt-2">
                <FormNumberInput
                    leftAddOn={{ title: 'Product Fee - Floor' }}
                    rightAddOn={{ title: 'EUR' }}
                    name="monetization.productFeeFloor"
                    validate={[limitFloatTo(2)]}
                    disabled={disabled}
                />
            </FormHelp>
        </ControlledCardBorderless>
    </ControlledCard>
);

export default CostSources;
