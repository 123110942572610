import { ReactNode } from 'react';
import SimpleCardTitle from 'platform/common/components/SimpleCard/SimpleCardTitle';

interface Props {
    icon: ReactNode;
    text: string;
}

const StageCardHeader = ({ icon, text }: Props) => (
    <div className="d-flex align-items-center w-100">
        {icon}
        <SimpleCardTitle text={text} className="text-white ms-2" />
    </div>
);

export default StageCardHeader;
