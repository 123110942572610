import Select from 'platform/common/components/Select/Select';
import { TaskLane } from 'platform/task/task.types';

type Props = {
    placeholder?: string;
    value: number[];
    options: TaskLane[];
    onChange: (laneIds: number[]) => void;
};

const KanbanColumnSelect = ({ placeholder, value, options, onChange }: Props) => (
    <Select<TaskLane>
        placeholder={placeholder}
        value={value}
        options={options}
        getOptionValue={(o) => o.id}
        getOptionLabel={(o) => o.name}
        isMulti
        closeMenuOnSelect={false}
        onChange={onChange}
    />
);

export default KanbanColumnSelect;
