import { ChangeEvent } from 'react';
import { InteractionComponentState, FilterInteractionState } from 'platform/analytics/analytics.types';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import ReportInteractionFilterSelect from './ReportInteractionFilterSelect';

interface Props {
    componentState: FilterInteractionState;
    onChange: (state: InteractionComponentState) => void;
}

const ReportInteractionFilterFields = ({ componentState, onChange }: Props) => (
    <>
        <InputWithAddOn
            className="mb-3"
            value={componentState.key}
            type="text"
            leftAddOn={{ title: 'Macro' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange({ ...componentState, key: e.target.value })
            }
        />
        <ReportInteractionFilterSelect
            value={componentState.filter}
            onChange={(f) => onChange({ ...componentState, filter: f })}
        />
    </>
);

export default ReportInteractionFilterFields;
