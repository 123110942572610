import { sampleSize } from 'lodash-es';
import { DATA_TYPES } from 'platform/common/dataTypes';

export const isPositiveNumber = (value: any) => !(!value || isNaN(value) || Number(value) <= 0);

export const isPositiveWholeNumber = (value: any) =>
    isPositiveNumber(value) && Number.isInteger(Number(value));

export const randomHash = (length: number = 10) => {
    const chars = 'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ';
    const selected = sampleSize(chars, length);
    return selected.join('');
};

export const formatBudget = (budget?: number) => DATA_TYPES.EUR.format(budget ?? 0);

export const decimalSpacing = (number?: string | number) =>
    number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
