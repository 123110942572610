import { useSelector } from 'react-redux';
import { random } from 'lodash-es';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { AlgorithmArea, AlgorithmSection } from '../algorithmManager.constant';
import { fetchAlgorithmExecution, fetchAlgorithmTemplate } from '../algorithms.service';
import { AlgorithmExecutionStatus } from '../algorithms.types';

interface AlgorithmLogData {
    section: AlgorithmSection;
    area: AlgorithmArea;
    algorithmTemplateName: string;
    status: AlgorithmExecutionStatus;
    timestamp: string;
    output: string;
}

interface ReplacementConfig {
    regex: RegExp;
    replacer?: (token: string) => string;
}

const replacementConfig: ReplacementConfig[] = [
    { regex: /(^| )campaign:? (?<textToReplace>.*?)(,|$)/gim },
    { regex: /campaign ?name: (?<textToReplace>.*?)(,|$)/gim },
    { regex: /Pause the following keywords in campaign (?<textToReplace>.*)$/gim },
    { regex: /(^| )query: (?<textToReplace>.*?)(,|$)/gim },
    { regex: /(^| )added keywords: (?<textToReplace>.*)$/gim },
    { regex: /(^| )keyword: (?<textToReplace>.*?),/gim },
    { regex: /(^| )negative: "(?<textToReplace>.*?)",/gim },
    { regex: /(^| )blocked positives: (?<textToReplace>.*)(]|")/gim },
    { regex: /(^| )ad ?group: (?<textToReplace>.*?)(,|$)/gim },
    { regex: /(^| )feed "(?<textToReplace>.*?)("|$)/gim },
    {
        regex: /https?:\/\/(?<textToReplace>.*?)(,| |$)/gim,
        replacer: () => `www.deepmedia.de?id=${random(100000)}`,
    },
];

const defaultReplacer = (token: string) =>
    token.replace(/[\w\u00C0-\u00ff]/gi, (match) => '*'.repeat(match.length));

const maskOutput = (initialText: string) =>
    replacementConfig.reduce(
        (output, config) =>
            output.replace(config.regex, (match, ...groups: (string | { textToReplace: string })[]) => {
                const captureGroup = groups.find((x) => typeof x !== 'string' && x.textToReplace);

                if (!captureGroup || typeof captureGroup === 'string') {
                    return match;
                }

                return match.replace(captureGroup.textToReplace, config.replacer || defaultReplacer);
            }),
        initialText
    );

export const useAlgorithmLogDetails = (id: string) => {
    const demoMode = useSelector(authSelectors.isDemoModeEnabled);

    const [{ data, loading }] = usePromise<AlgorithmLogData | undefined>(
        undefined,
        async () => {
            const execution = await fetchAlgorithmExecution(id);
            const algorithmTemplate = await fetchAlgorithmTemplate(execution.algorithmTemplateId);

            return {
                section: algorithmTemplate.display.section,
                area: algorithmTemplate.display.area,
                algorithmTemplateName: algorithmTemplate.name,
                status: execution.status,
                timestamp: execution.timestamp,
                output: demoMode ? maskOutput(execution.output) : execution.output,
            };
        },
        []
    );

    return {
        ...data,
        loading,
    };
};
