import { useDispatch } from 'react-redux';
import { generatePath, useLocation, useMatch } from 'react-router-dom';
import { push } from 'redux-first-history';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import {
    TASK_BOARD_CHANGE_LOG_PATH,
    TASK_BOARD_EDIT_PATH,
    TASK_BOARD_TIMELINE_PATH,
    TASK_BOARDS_PATH,
} from '../taskRoutes';

interface Props {
    boardId: number;
    canEdit: boolean;
    className?: string;
}

const KanbanBoardActionsDropdown = ({ boardId, canEdit, className }: Props) => {
    const location = useLocation();
    const isInTimeline = !!useMatch(TASK_BOARD_TIMELINE_PATH);
    const dispatch = useDispatch();

    return (
        <InlineDropdown
            togglerClassName={className}
            items={[
                ...(canEdit
                    ? [
                          {
                              icon: 'fa-edit',
                              label: 'Edit',
                              action: () =>
                                  dispatch(push(generatePath(TASK_BOARD_EDIT_PATH, { id: `${boardId}` }))),
                          },
                      ]
                    : []),
                {
                    icon: 'fa-history',
                    label: 'Change log',
                    action: () =>
                        dispatch(push(generatePath(TASK_BOARD_CHANGE_LOG_PATH, { id: `${boardId}` }))),
                },
                isInTimeline
                    ? {
                          icon: 'fa-square-kanban',
                          label: 'Show board',
                          action: () => dispatch(push(`${TASK_BOARDS_PATH}/${boardId}${location.search}`)),
                      }
                    : {
                          icon: 'fa-list-timeline',
                          label: 'Show timeline',
                          action: () =>
                              dispatch(
                                  push(
                                      generatePath(`${TASK_BOARD_TIMELINE_PATH}${location.search}`, {
                                          id: `${boardId}`,
                                      })
                                  )
                              ),
                      },
            ]}
        />
    );
};

export default KanbanBoardActionsDropdown;
