import { ReactNode } from 'react';
import Dropzone from 'react-dropzone';
import { Col, Progress, Row } from 'reactstrap';
import classNames from 'classnames';
import { FileUploadProgressProps, useFileUploadProgress } from 'platform/common/hooks/useProgress';
import { toastError } from 'platform/common/utils/toast.util';
import { FileInfo, formatFileSize, ReadFileAs, readFileData, readFileListData } from '../../utils/file.util';
import IconButton from '../IconButton/IconButton';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import './UploadInput.scss';

type PropsCommon = {
    acceptableMimeTypes?: string;
    title?: string;
    subtitle?: string;
    readFileAs?: ReadFileAs;
    maxSizeInMb?: number;
    disabled?: boolean;
    invalid?: boolean;
    className?: string;
    loading?: boolean;
    uploadProgress?: number;
    allowRemove?: boolean;
    children?: ReactNode;
};

type PropsSingle = {
    multiple?: false;
    value?: FileInfo;
    setValue?: (file: FileInfo | undefined) => void;
    onFileUpload: (file: FileInfo, uploadProgressProps?: FileUploadProgressProps) => void;
    onRemoveItem?: () => void;
};

type PropsMultiple = {
    multiple: true;
    value?: FileInfo[];
    setValue?: (file: FileInfo[] | undefined) => void;
    onFileUpload: (files: FileInfo[], uploadProgressProps?: FileUploadProgressProps) => void;
    onRemoveItem?: (index: number) => void;
};

type Props = PropsCommon & (PropsSingle | PropsMultiple);

const FileDescription = ({
    file,
    onRemoveItem,
}: {
    file: FileInfo;
    onRemoveItem?: (index?: number) => void;
}) => (
    <>
        {onRemoveItem && (
            <Col xs={1}>
                <IconButton className="mt-1" onClick={() => onRemoveItem && onRemoveItem()}>
                    <i className="fal fa-trash-can" />
                </IconButton>
            </Col>
        )}
        <Col xs={onRemoveItem ? 8 : 9}>{file.name || 'File'}</Col>
        {file.size && (
            <Col xs={3} className="text-end">
                {formatFileSize(Number(file.size))}
            </Col>
        )}
    </>
);

const UploadInput = (props: Props) => {
    const progressProps = useFileUploadProgress();
    const readFileAs = props.readFileAs ?? 'dataURL';
    const maxSize = props.maxSizeInMb;

    const validateFileSize = (files: FileInfo[]) => {
        if (maxSize && files.find((file) => (file.content as Blob)?.size > maxSize * 1024 * 1024)) {
            toastError({ message: `File size should not exceed ${maxSize} MB` });
        }
    };

    return (
        <div className={classNames('UploadInput', props.className)}>
            <Dropzone
                accept={props.acceptableMimeTypes}
                multiple={props.multiple}
                onDrop={(acceptedFiles, rejectedFiles) =>
                    props.multiple
                        ? readFileListData({
                              acceptedFiles,
                              rejectedFiles,
                              readFileAs,
                              callback: (files) => {
                                  validateFileSize(files);
                                  props.onFileUpload([...(props.value ?? []), ...files], progressProps);
                              },
                          })
                        : readFileData({
                              acceptedFiles,
                              rejectedFiles,
                              readFileAs,
                              callback: (file) => {
                                  validateFileSize([file]);
                                  props.onFileUpload(file, progressProps);
                              },
                          })
                }
                disabled={props.disabled}
            >
                {({ getRootProps, getInputProps, isDragActive }) =>
                    props.children ? (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {props.children}
                            {props?.loading && <OverlayLoader />}
                        </div>
                    ) : (
                        <div
                            {...getRootProps()}
                            className={classNames('UploadInput-dropzone form-control text-center', {
                                'UploadInput-dropzone--active': isDragActive,
                                'is-invalid': props.invalid,
                            })}
                        >
                            <input {...getInputProps()} />
                            <div>{props.title ?? (props.value ? 'Re-upload' : 'Upload')}</div>
                            {props?.loading && <OverlayLoader />}
                        </div>
                    )
                }
            </Dropzone>
            {!!progressProps?.uploadProgress && (
                <Progress className="mt-1" style={{ height: 4 }} value={progressProps.uploadProgress} />
            )}
            {props.subtitle && <small className="text-muted">{props.subtitle}</small>}
            {!props.multiple && props.value && (
                <Row className="g-1">
                    <FileDescription file={props.value} onRemoveItem={props?.onRemoveItem} />
                </Row>
            )}
            {props.multiple && props?.value && (
                <Row className="g-1">
                    {props.value?.map((file, index) => (
                        <FileDescription
                            key={`file-${index}`}
                            file={file}
                            onRemoveItem={() => props.onRemoveItem && props?.onRemoveItem(index)}
                        />
                    ))}
                </Row>
            )}
        </div>
    );
};

export default UploadInput;
