import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { required } from 'platform/common/utils/validators.util';
import { CreativeLabel } from 'platform/creatives/types/creativeLabel.types';
import FormInput from 'platform/formik/FormInput/FormInput';

interface Props {
    field: string;
    label: CreativeLabel;
    onRemove: () => void;
}

const LabelFields = ({ field, label, onRemove }: Props) => (
    <ControlledCard
        isExpanded={!label.id}
        key={field}
        title={label.id ? label.name : 'New label'}
        onRemove={onRemove}
    >
        {label.id && (
            <FormRow label="ID" helpKey="creative_label_form_label_id">
                <FormInput name={`${field}.id`} type="text" disabled />
            </FormRow>
        )}
        <FormRow label="Name" helpKey="creative_label_form_label_name">
            <FormInput name={`${field}.name`} type="text" validate={required} />
        </FormRow>
    </ControlledCard>
);

export default LabelFields;
