import { useState } from 'react';
import { Button } from 'reactstrap';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Select from 'platform/common/components/Select/Select';
import { useLoading } from 'platform/common/hooks/useLoading';
import { sortByName } from 'platform/common/utils/array.util';
import { Task } from 'platform/task/task.types';

type Props = {
    options: Task[];
    onSubmit: (selectedTaskIds: number[]) => Promise<void>;
    onCancel: () => void;
};

const KanbanBlockedByAdder = ({ options, onSubmit, onCancel }: Props) => {
    const [selectedTaskIds, setSelectedTaskIds] = useState([]);
    const [saving, withSaving] = useLoading();

    const handleSubmit = async () => {
        if (selectedTaskIds.length) {
            await withSaving(() => onSubmit(selectedTaskIds));
        }
    };

    return (
        <div className="position-relative d-flex align-items-center gap-2 mt-3 mb-0">
            {saving && <OverlayLoader />}
            <Select
                className="flex-fill"
                placeholder="Select upstream tasks that must be completed before this one..."
                value={selectedTaskIds}
                options={sortByName(options)}
                onChange={setSelectedTaskIds}
                getOptionValue={(t) => t.id}
                getOptionLabel={(t) => t.name}
                isMulti
                autoFocus
                openMenuOnFocus
                canSelectAll={false}
            />
            <Button color="primary" onClick={handleSubmit}>
                Add
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
        </div>
    );
};

export default KanbanBlockedByAdder;
