import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { push } from 'redux-first-history';
import moment from 'moment/moment';
import SidePanelExpandButton from 'platform/campaign/common/SidePanelExpandButton';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import FormHeader from 'platform/common/components/FormHeader/FormHeader';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import SidePanel from 'platform/common/components/SidePanel/SidePanel';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import { useAdvertiserUsers } from 'platform/common/hooks/useAdvertiserUsers';
import { usePromise } from 'platform/common/hooks/usePromise';
import useToggle from 'platform/common/hooks/useToggle';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { makeOptions } from 'platform/common/utils/option.util';
import { getTaskBoardChangesByPeriod, getTaskBoardState } from '../task.service';
import { TaskBoardChange, TaskBoardChangeInclude, TaskBoardState } from '../task.types';
import { TASK_BOARDS_PATH } from '../taskRoutes';
import BoardChangesTable from './BoardChangesTable';

const INCLUDE_LABELS: Record<TaskBoardChangeInclude, string> = {
    ALL: 'Tasks + sub-tasks + board changes',
    TASKS_AND_SUBTASKS: 'Tasks + sub-tasks',
    TASKS_ONLY: 'Tasks only',
};

type QueryParams = {
    date: string[];
    include: TaskBoardChangeInclude;
};

const BoardChangesSidePanel = () => {
    const {
        queryParams: {
            date: [dateFrom = '', dateTo = ''],
            include,
        },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        date: [
            moment().startOf('isoWeek').format(ISO_DATE_FORMAT),
            moment().endOf('isoWeek').format(ISO_DATE_FORMAT),
        ],
        include: 'ALL',
    });

    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const [isPanelExpanded, togglePanelExpanded] = useToggle(false);

    const [{ data: board }] = usePromise<TaskBoardState | undefined>(
        undefined,
        () => getTaskBoardState(Number(id)),
        [id]
    );

    const userOptions = useAdvertiserUsers(board?.advertiserId);

    const [{ data: changes, loading }] = usePromise<TaskBoardChange[]>(
        [],
        () => getTaskBoardChangesByPeriod(Number(id), { dateFrom, dateTo, include }),
        [id, dateFrom, dateTo, include]
    );

    return (
        <SidePanel size={isPanelExpanded ? SIDE_PANEL_SIZES.FULL : SIDE_PANEL_SIZES.WIDE}>
            <SidePanelExpandButton
                isExpanded={isPanelExpanded}
                onClick={togglePanelExpanded}
                style={{ right: 'auto' }}
            />
            <Card className="h-100">
                {board ? (
                    <>
                        <CardHeader>
                            <FormHeader title="Task board change log" subtitle={board.name}>
                                <div className="py-2 align-items-end">
                                    <Button
                                        color="secondary"
                                        onClick={() => dispatch(push(`${TASK_BOARDS_PATH}/${id}`))}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </FormHeader>
                        </CardHeader>
                        <CardBody className="overflow-auto">
                            <div className="d-flex align-items-center mb-3">
                                <DateRangePicker
                                    label="Date range"
                                    from={dateFrom}
                                    to={dateTo}
                                    onChange={(from, to) => setQueryParams({ date: [from ?? '', to ?? ''] })}
                                />
                                <SelectWithAddon
                                    className="ms-3"
                                    selectStyle={{ width: 260 }}
                                    name="Include"
                                    options={makeOptions(INCLUDE_LABELS)}
                                    value={include}
                                    onChange={(value) => setQueryParams({ include: value })}
                                    isClearable={false}
                                />
                            </div>
                            <BoardChangesTable
                                board={board}
                                userOptions={userOptions}
                                changes={changes}
                                loading={loading}
                                showContext
                            />
                        </CardBody>
                    </>
                ) : (
                    <OverlayLoader />
                )}
            </Card>
        </SidePanel>
    );
};

export default BoardChangesSidePanel;
