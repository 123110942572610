import { useState } from 'react';

export interface FileUploadProgressProps {
    uploadProgress: number;
    setUploadProgress: React.Dispatch<React.SetStateAction<number>>;
    resetProgress: () => void;
    onUploadProgress: (progressEvent: ProgressEvent) => void;
}

export const useFileUploadProgress = (): FileUploadProgressProps => {
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    const onUploadProgress = (progressEvent: ProgressEvent) => {
        if (progressEvent) {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
    };

    const resetProgress = () => {
        setUploadProgress(0);
    };

    return { uploadProgress, setUploadProgress, resetProgress, onUploadProgress };
};
