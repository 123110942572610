import { useState } from 'react';
import moment, { Moment } from 'moment';
import MonthPicker from './MonthPicker';

const increase = (prev: number) => prev + 1;
const decrease = (prev: number) => prev - 1;
const PICKER_WIDTH = 200;

interface Props {
    from: Date | undefined | null;
    to: Date | undefined | null;
    secondaryFrom?: Date | null;
    secondaryTo?: Date | null;
    fromDate?: Moment;
    toDate?: Moment;
    pickerWidth?: number;
    onDateClick: (date: Date) => void;
}

const MonthRangePicker = ({
    from,
    to,
    secondaryFrom,
    secondaryTo,
    fromDate,
    toDate,
    pickerWidth = PICKER_WIDTH,
    onDateClick,
}: Props) => {
    const [fromYear, setFromYear] = useState(from ? moment(from)?.year() : moment().year());
    const [toYear, setToYear] = useState(to ? moment(to)?.year() : moment().add(1, 'year').year());

    return (
        <div className="d-flex px-3 pt-3">
            <MonthPicker
                style={{ width: pickerWidth }}
                fromDate={fromDate}
                from={from}
                to={to}
                secondaryFrom={secondaryFrom}
                secondaryTo={secondaryTo}
                className="pe-3"
                year={fromYear}
                nextDisabled={fromYear >= toYear}
                onDateClick={onDateClick}
                onPreviousYear={() => setFromYear(decrease)}
                onNextYear={() => setFromYear(increase)}
            />
            <MonthPicker
                style={{ width: pickerWidth }}
                toDate={toDate}
                from={from}
                to={to}
                secondaryFrom={secondaryFrom}
                secondaryTo={secondaryTo}
                year={toYear}
                className="ps-3"
                previousDisabled={toYear <= fromYear}
                onDateClick={onDateClick}
                onPreviousYear={() => setToYear(decrease)}
                onNextYear={() => setToYear(increase)}
            />
        </div>
    );
};

export default MonthRangePicker;
