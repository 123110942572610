import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button, Form, Label } from 'reactstrap';
import { Formik } from 'formik';
import { authActions } from 'platform/app/ducks/auth.duck';
import { send2FaCode } from 'platform/app/services/auth.service';
import { TwoFactorAuthentication } from 'platform/common/constants/twoFactorAuthentication.constant';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import { Account } from '../../app.types';

interface FormModel {
    verificationCode: string;
}

interface Props {
    errorMessage?: string;
    account: Account;
    codeSent: boolean;
}

const TwoFactorAuthForm = ({ errorMessage, account, codeSent }: Props) => {
    const [sendingCode, setSendingCode] = useState(false);
    const dispatch = useDispatch();

    const sendCode = useCallback(async () => {
        setSendingCode(true);
        await send2FaCode();
        dispatch(authActions.codeFor2faSent());
        setSendingCode(false);
    }, [dispatch]);

    useEffect(() => {
        if (!codeSent) {
            sendCode();
        }
    }, [codeSent, sendCode]);

    const handleVerification = (formData: FormModel) => {
        dispatch(authActions.verify2FaCode(formData));
    };

    return (
        <Formik<FormModel> initialValues={{ verificationCode: '' }} onSubmit={handleVerification}>
            {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    {account.twoFactorAuthentication === TwoFactorAuthentication.EMAIL && (
                        <p className="text-muted">
                            We have sent a verification code to your email ({account.login}). Please enter
                            code below to continue.
                        </p>
                    )}
                    <div className="mb-3">
                        <Label for="verificationCode" className="fw-bold">
                            Verification code
                        </Label>
                        <FormInput
                            id="verificationCode"
                            name="verificationCode"
                            label="Verification code"
                            validate={required}
                        />
                    </div>
                    <Button type="submit" color="primary" className="px-3 py-2 w-100">
                        Verify
                    </Button>
                    <Button
                        color="link"
                        className="mt-2 p-0 float-end"
                        disabled={sendingCode}
                        onClick={sendCode}
                    >
                        Resend code
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default TwoFactorAuthForm;
