import { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';
import { uniq } from 'lodash-es';
import { SourceMediumMapping } from 'platform/advertisers/advertiser.types';
import SourceMediumMappingsTable from 'platform/advertisers/components/AdvertiserFormIntegrations/SourceMediumModal/SourceMediumMappingsTable';
import { isDefined } from 'platform/common/common.types';
import FormHeader from 'platform/common/components/FormHeader/FormHeader';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import './SourceMediumModal.scss';

type Props = {
    toggle: () => any;
    sourceMedium?: SourceMediumMapping[];
    onChange: (value: SourceMediumMapping[]) => void;
};

const getMediumMappingError = (currentMapping: SourceMediumMapping): string | undefined => {
    if (!currentMapping.unitId) {
        return 'Unit ID is required';
    }
    if (!currentMapping.pattern) {
        return 'Source-medium field is required';
    }
    return undefined;
};

const SourceMediumModal = ({ toggle, onChange, sourceMedium = [] }: Props) => {
    const [sourceMediumState, setSourceMedium] = useState(sourceMedium);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    useEffect(() => {
        const errors = uniq(sourceMediumState.map(getMediumMappingError).filter(isDefined));
        if (errors) {
            setErrorMessages(errors);
        }
    }, [sourceMediumState]);

    return (
        <Modal isOpen toggle={toggle} className="SourceMediumModal">
            <Card className="CardForm h-100">
                <CardHeader>
                    <FormHeader title={`${getIntegrationLabel('GOOGLE_ANALYTICS')} Source-medium mappings`}>
                        <Button
                            color="primary"
                            onClick={() => {
                                if (sourceMediumState.map(getMediumMappingError).filter(isDefined).length) {
                                    return;
                                }
                                onChange(sourceMediumState);
                                toggle();
                            }}
                        >
                            Submit
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Dismiss
                        </Button>
                    </FormHeader>
                </CardHeader>
                <CardBody>
                    <Card className="mb-0">
                        <CardBody>
                            {!!errorMessages.length && (
                                <Alert color="danger">
                                    {errorMessages.map((error) => (
                                        <div key={error}>{error}</div>
                                    ))}
                                </Alert>
                            )}
                            <SourceMediumMappingsTable
                                onChange={setSourceMedium}
                                sourceMedium={sourceMediumState}
                            />
                        </CardBody>
                    </Card>
                </CardBody>
            </Card>
        </Modal>
    );
};

export default SourceMediumModal;
