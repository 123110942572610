import { NavLink as RouterNavLink, Route, Routes } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import ProductActionProbability from 'platform/productActionProbability/ProductActionProbability';
import ProductOverlap from 'platform/productActionProbability/ProductOverlap';
import {
    PRODUCT_ACTION_PROBABILITY_CATEGORY,
    PRODUCT_ACTION_PROBABILITY_PRODUCT,
    PRODUCT_OVERLAP_PRODUCTS,
} from 'platform/productActionProbability/productActionProbability.navigation';

const ProductActionProbabilityNavigation = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Products" />
            </HeaderContainer>
            <BodyContainer>
                <div className="custom-tabs">
                    <Nav tabs>
                        <NavItem key={PRODUCT_ACTION_PROBABILITY_CATEGORY.path}>
                            <NavLink
                                to={PRODUCT_ACTION_PROBABILITY_CATEGORY.path}
                                activeClassName="active"
                                tag={RouterNavLink}
                            >
                                Product category
                            </NavLink>
                        </NavItem>
                        <NavItem key={PRODUCT_ACTION_PROBABILITY_PRODUCT.path}>
                            <NavLink
                                to={PRODUCT_ACTION_PROBABILITY_PRODUCT.path}
                                activeClassName="active"
                                tag={RouterNavLink}
                            >
                                Product
                            </NavLink>
                        </NavItem>
                        <NavItem key={PRODUCT_OVERLAP_PRODUCTS.path}>
                            <NavLink
                                to={PRODUCT_OVERLAP_PRODUCTS.path}
                                activeClassName="active"
                                tag={RouterNavLink}
                            >
                                Product Overlap
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </BodyContainer>
            <Routes>
                <Route
                    path="category"
                    element={
                        <ProductActionProbability
                            helpKey="product_actionProbability_category"
                            advertiserId={advertiserId}
                            dimension="segment_product_category"
                            dimensionLabel="Product category"
                            title="Analysis of action probability for product categories"
                        />
                    }
                />
                <Route
                    path="product"
                    element={
                        <ProductActionProbability
                            helpKey="product_actionProbability_product"
                            advertiserId={advertiserId}
                            dimension="segment_product"
                            dimensionLabel="Product"
                            title="Analysis of action probability for products"
                        />
                    }
                />
                <Route path="productOverlap" element={<ProductOverlap advertiserId={advertiserId} />} />
            </Routes>
        </>
    );
};

export default ProductActionProbabilityNavigation;
