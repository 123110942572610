import classNames from 'classnames';
import Select from 'platform/common/components/Select/Select';
import ReportInteractionContainer from './ReportInteractionContainer';

interface Props {
    value?: string[];
    onChange: (value?: number) => void;
    selectOptions: {
        label: string;
        value?: number | undefined;
    }[];
    required: boolean;
}

const ReportInteractionSelect = ({ value, selectOptions, onChange, required }: Props) => (
    <ReportInteractionContainer required={required} isValueEmpty={!value?.length}>
        {(setTouched, invalid) => (
            <Select
                className={classNames({ 'form-control is-invalid p-0': invalid })}
                collapseMoreValues
                value={value}
                options={selectOptions}
                onChange={onChange}
                onBlur={() => setTouched(true)}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        position: 'static',
                    }),
                }}
            />
        )}
    </ReportInteractionContainer>
);

export default ReportInteractionSelect;
