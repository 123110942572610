/* eslint-disable react/no-danger */
import { sanitize } from 'dompurify';

/**
 * Outputs unescaped HTML content after sanitizing it to prevent XSS attacks
 */
const RawHtml = (props: { className?: string; children: string | undefined }) => (
    <div className={props.className} dangerouslySetInnerHTML={{ __html: sanitize(props.children ?? '') }} />
);

export default RawHtml;
