import axiosBff from 'platform/axios/axiosBff';
import { LabelOverride } from './labelOverrides.types';

export const fetchLabelOverrides = (advertiserId: number) =>
    axiosBff
        .get<LabelOverride[]>('/admin/api/label-overrides', { params: { advertiserId } })
        .then((resp) => resp.data);

export const fetchLabelOverridesBySystemName = (systemName: string) =>
    axiosBff
        .get<LabelOverride[]>(`/admin/api/label-overrides/by-system-name/${systemName}`)
        .then((resp) => resp.data);

export const fetchLabelOverride = (id: number) =>
    axiosBff.get<LabelOverride>(`/admin/api/label-overrides/${id}`).then((resp) => resp.data);

export const createLabelOverride = (labelOverride: LabelOverride) =>
    axiosBff.post('/admin/api/label-overrides', labelOverride);

export const updateLabelOverride = (id: number, labelOverride: LabelOverride) =>
    axiosBff.put(`/admin/api/label-overrides/${id}`, labelOverride);

export const deleteLabelOverride = (id: number) => axiosBff.delete(`/admin/api/label-overrides/${id}`);
