import { groupBy, mapValues } from 'lodash-es';
import { CampaignClassifications } from 'platform/campaign/campaign/campaignClassification.types';
import { assertIsDefined } from 'platform/common/common.assert';
import { isDefined } from 'platform/common/common.types';
import { randomHash } from 'platform/common/utils/number.util';
import {
    MediaGroupFormModel,
    MediaInsertion,
    MediaInsertionFormModel,
    MediaInsertionLink,
    MediaNode,
    MediaNodeFormModel,
    Mediaplan,
    MediaplanChange,
    MediaplanCreatePayload,
    MediaplanFormModel,
    MediaplanTree,
    MediaplanTreeFormModel,
    MediaplanUpdatePayload,
} from 'platform/mediaplan/mediaplan.types';

export const toMediaplanCreatePayload = (mediaplanForm: MediaplanFormModel): MediaplanCreatePayload => ({
    name: mediaplanForm.name.trim(),
    dateFrom: mediaplanForm.runtime.from,
    dateTo: mediaplanForm.runtime.to,
    adservingFee: Number(mediaplanForm.adservingFee),
    agencyFee: Number(mediaplanForm.agencyFee),
    advertiserId: mediaplanForm.advertiserId,
    status: mediaplanForm.status,
    budget: mediaplanForm.budget!,
    projectNumber: mediaplanForm.projectNumber ?? '',
    poNumber: mediaplanForm.poNumber ?? '',
    notes: mediaplanForm.notes,
    classification: mediaplanForm.classification,
});

export const toMediaplanUpdatePayload = (mediaplanForm: MediaplanFormModel): MediaplanUpdatePayload => ({
    name: mediaplanForm.name.trim(),
    dateFrom: mediaplanForm.runtime.from,
    dateTo: mediaplanForm.runtime.to,
    adservingFee: Number(mediaplanForm.adservingFee),
    agencyFee: Number(mediaplanForm.agencyFee),
    advertiserId: mediaplanForm.advertiserId,
    status: mediaplanForm.status,
    budget: mediaplanForm.budget!,
    projectNumber: mediaplanForm.projectNumber ?? '',
    poNumber: mediaplanForm.poNumber ?? '',
    changeTracking: mediaplanForm.newChange,
    version: mediaplanForm.version ?? 0,
    notes: mediaplanForm.notes,
    classification: mediaplanForm.classification,
});

export const toMediaplanForm = (mediaplan: Mediaplan, allChanges: MediaplanChange[]): MediaplanFormModel => ({
    name: mediaplan.name,
    id: mediaplan.id,
    version: mediaplan.version,
    advertiserId: mediaplan.advertiserId,
    status: mediaplan.status,
    budget: mediaplan.budget,
    projectNumber: mediaplan.projectNumber,
    poNumber: mediaplan.poNumber,
    adservingFee: mediaplan.adservingFee,
    agencyFee: mediaplan.agencyFee,
    runtime: {
        from: mediaplan.dateFrom,
        to: mediaplan.dateTo,
    },
    notes: mediaplan.notes,
    previousChanges: allChanges,
    classification: mediaplan.classification,
    newChange: {
        reason: undefined,
        comment: undefined,
        classification: {
            productLabel:
                mediaplan.classification?.productLabel?.length === 1
                    ? mediaplan.classification?.productLabel[0]
                    : undefined,
            productSubLabel:
                mediaplan.classification?.productSubLabel?.length === 1
                    ? mediaplan.classification?.productSubLabel[0]
                    : undefined,
            flightLabel:
                mediaplan.classification?.flightLabel?.length === 1
                    ? mediaplan.classification?.flightLabel[0]
                    : undefined,
            countryLabels: mediaplan.classification?.countryLabels,
        },
    },
});

const toMediaNodesApi = (
    nodes: MediaNodeFormModel[],
    groups: { [key: string]: MediaGroupFormModel },
    insertions: { [key: string]: MediaInsertionFormModel }
): MediaNode[] =>
    nodes.map(({ key, type, children }) => {
        const name = type === 'GROUP' ? groups[key]?.name : insertions[key]?.name;
        assertIsDefined(name, `name of ${type} form element with key "${key}"`);
        return type === 'GROUP'
            ? { type, name: name.trim(), children: toMediaNodesApi(children ?? [], groups, insertions) }
            : { type, name: name.trim() };
    });

export const toMediaNodesFormModel = (nodes: MediaNode[]): MediaNodeFormModel[] =>
    nodes.map(({ type, name, children }) => ({
        key: `nodeId_${randomHash()}`,
        type,
        expanded: true,
        name,
        children: type === 'GROUP' ? toMediaNodesFormModel(children ?? []) : undefined,
    }));

const toMediaInsertionApi = (insertion: MediaInsertionFormModel): MediaInsertion => ({
    id: insertion.id,
    name: insertion.name?.trim() || '',
    vendorSystems: insertion.vendorSystems,
    workflowType: insertion.workflowType,
    dateFrom: insertion.runtime?.from || '',
    dateTo: insertion.runtime?.to || '',
    budget: Number(insertion.budget) || 0,
    notes: insertion.notes,
    changeTracking: insertion.newChange,
    targetKpiMetric: insertion.targetKpiMetric ?? undefined,
    targetKpiValue: insertion.targetKpiValue ?? undefined,
    classification: fromClassificationsEntries(insertion.classificationEntries ?? []),
    campaignIds: insertion.campaignLinks
        .filter((link) => link.matchType === 'EXPLICIT')
        .map((link) => link.campaignId)
        .filter(isDefined),
    volume: Number(insertion.volume) || undefined,
    volumeType: insertion.volumeType || undefined,
    pricing: Number(insertion.pricing) || undefined,
    pricingType: insertion.pricingType || undefined,
});

export const toMediaplanTreeApi = (form: MediaplanTreeFormModel): MediaplanTree => ({
    nodes: toMediaNodesApi(form.treeData, form.groups, form.insertions),
    insertions: Object.values(form.insertions).map(toMediaInsertionApi),
});

export const toMediaInsertionFormModel = (
    key: string,
    insertion: MediaInsertion,
    allLinks: MediaInsertionLink[],
    allChanges: MediaplanChange[],
    mediaplan: Mediaplan
): MediaInsertionFormModel => ({
    key,
    id: insertion.id,
    name: insertion.name,
    vendorSystems: insertion.vendorSystems ?? [],
    workflowType: insertion.workflowType,
    budget: insertion.budget,
    runtime: { to: insertion.dateTo, from: insertion.dateFrom },
    classificationEntries: toClassificationEntries(insertion.classification ?? {}),
    campaignLinks: allLinks.filter((link) => link.mediaInsertionId === insertion.id),
    notes: insertion.notes,
    previousChanges: allChanges.filter((c) => c.mediaInsertionId === insertion.id),
    targetKpiValue: insertion.targetKpiValue,
    targetKpiMetric: insertion.targetKpiMetric,
    volume: insertion.volume,
    volumeType: insertion.volumeType,
    pricing: insertion.pricing,
    pricingType: insertion.pricingType,
    newChange: {
        classification: {
            productLabel: getLabel(
                insertion.classification.productLabel,
                mediaplan.classification?.productLabel
            ),
            productSubLabel: getLabel(
                insertion.classification.productSubLabel,
                mediaplan.classification?.productSubLabel
            ),
            flightLabel: getLabel(
                insertion.classification.flightLabel,
                mediaplan.classification?.flightLabel
            ),
            countryLabels: mediaplan.classification?.countryLabels,
        },
    },
});

function getLabel(insertionLabels?: string[], mediaplanLabels?: string[]) {
    if (insertionLabels?.length === 1) {
        return insertionLabels[0];
    }
    if (!insertionLabels?.length && mediaplanLabels?.length === 1) {
        return mediaplanLabels[0];
    }
    return undefined;
}

export const flattenMediaNodes = (nodes: MediaNodeFormModel[]): MediaNodeFormModel[] =>
    nodes.flatMap((node) => (node.children?.length ? [node, ...flattenMediaNodes(node.children)] : node));

const fromClassificationsEntries = (entries: string[]): CampaignClassifications => {
    const pairs = entries.map((e) => e.split('#', 2) as [string, string]);
    const groupedPairs = groupBy(pairs, (p) => p[0]);
    return mapValues(groupedPairs, (ps) => ps.map((p) => p[1]));
};

const toClassificationEntries = (classification: CampaignClassifications): string[] => {
    const keyValuesPairs: [string, string[] | undefined][] = Object.entries(classification);
    return keyValuesPairs.flatMap(([key, values]) => (values ?? []).map((value) => `${key}#${value}`));
};
