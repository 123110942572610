import { MultiValueProps } from 'react-select';
import StatisticTooltip, { Tooltip } from 'platform/common/components/StatisticTooltip/StatisticTooltip';
import { SelectTreeProps } from '../SelectTree/SelectTree';
import SelectSortableMultiValue from './SelectSortableMultiValue';

const SelectSortableMultiValueWithTooltip = <T extends Tooltip>({
    children,
    ...props
}: MultiValueProps<T>) => {
    const { optionIdSuffix } = props.selectProps as SelectTreeProps<T>;
    const id = optionIdSuffix ? `${props.data.key}-${optionIdSuffix}` : props.data.key;

    return (
        <SelectSortableMultiValue<T>
            {...props}
            id={id}
            endAddon={<StatisticTooltip target={id} tooltip={props.data} />}
        >
            {children}
        </SelectSortableMultiValue>
    );
};

export default SelectSortableMultiValueWithTooltip;
