import { Selection, ScaleBand, axisLeft, axisTop, ScaleTime, select } from 'd3';
import { inRange } from 'lodash-es';
import moment from 'moment';
import { DateType } from './Timeline';
import { TimelineBar } from './useTimelineChart';

export const BAR_HEIGHT = 24;
export const MARGIN = { TOP: 5, RIGHT: 30, BOTTOM: 30, LEFT: 0 };
export const BAR_SPACING = 10;
export const TICK_WIDTH = 50;
const BAR_LABEL_PADDING = 5;
const minBarWidth = 250;

interface AppendBarsParams {
    g: Selection<SVGGElement, unknown, null, undefined>;
    bars: TimelineBar[];
    xScale: ScaleTime<number, number, never>;
    yScale: ScaleBand<string>;
    totalWidth: number;
    minDate: Date;
    onMouseover: (event: any, bar: TimelineBar) => void;
    onMouseout: () => void;
    onMouseMove: (event: any, bar: TimelineBar) => void;
    onClick: (event: any, bar: TimelineBar) => void;
}

const appendMainGroup = (svg: Selection<SVGSVGElement, unknown, null, undefined>) =>
    svg.append('g').attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`);

const appendYAxis = (g: Selection<SVGGElement, unknown, null, undefined>, yScale: ScaleBand<string>) => {
    const yAxis = axisLeft(yScale);
    g.append('g').attr('class', 'Timeline-yAxis').call(yAxis).select('.domain').remove();
};

const appendGridlines = (
    g: Selection<SVGGElement, unknown, null, undefined>,
    xScale: ScaleTime<number, number, never>,
    totalHeight: number,
    ticks: number
) => {
    const gridlines = axisTop(xScale)
        .tickSize(totalHeight)
        .ticks(ticks)
        .tickFormat(() => '');

    g.append('g')
        .attr('class', 'Timeline-gridlines')
        .attr('transform', `translate(0,${totalHeight})`)
        .call(gridlines)
        .selectAll('.tick line')
        .attr('stroke', '#ddd')
        .attr('stroke-dasharray', '2,2');
    g.select('.Timeline-gridlines .domain').remove();
};

const appendPrimaryXAxis = (
    g: Selection<SVGGElement, unknown, null, undefined>,
    primaryXAxis: ReturnType<typeof axisTop>
) => {
    g.append('g').attr('class', 'Timeline-primaryXAxis').call(primaryXAxis);
};

const appendSecondaryXAxis = (
    g: Selection<SVGGElement, unknown, null, undefined>,
    secondaryXAxis: ReturnType<typeof axisTop>
) => {
    g.append('g').attr('class', 'Timeline-secondaryXAxis').call(secondaryXAxis).select('.domain').remove();
};

const calculateBarWidth = (
    bar: TimelineBar,
    xScale: ScaleTime<number, number, never>,
    totalWidth: number,
    minDate: Date
) => {
    if (bar.startDate && bar.dueDate) {
        if (moment(bar.startDate).isSame(bar.dueDate, 'day')) {
            // If the start and due dates are the same, return the width of one day
            const oneDayWidth = xScale(moment(minDate).add(1, 'd').toDate()) - xScale(minDate);
            return oneDayWidth;
        }
        return xScale(bar.dueDate) - xScale(bar.startDate);
    }
    if (bar.startDate) {
        return totalWidth - xScale(bar.startDate);
    }
    if (bar.dueDate) {
        return xScale(bar.dueDate);
    }
    return totalWidth;
};

const getBarClassName = (bar: TimelineBar) => {
    const colorClass = bar.labelKeys?.length
        ? `Task-label-color-${bar.labelKeys[0]}`
        : 'Timeline-bar-fallbackColor';

    return `Timeline-bar ${colorClass}`;
};

const appendBars = ({
    g,
    bars,
    xScale,
    yScale,
    totalWidth,
    minDate,
    onMouseMove,
    onMouseover,
    onClick,
    onMouseout,
}: AppendBarsParams) => {
    g.selectAll()
        .data(bars)
        .enter()
        .append('rect')
        .attr('class', getBarClassName)
        .attr('x', (d) => (d.startDate ? xScale(d.startDate) : 0) - MARGIN.LEFT)
        .attr('y', (d) => yScale(d.id) as number)
        .attr('width', (bar) => calculateBarWidth(bar, xScale, totalWidth, minDate))
        .attr('height', BAR_HEIGHT)
        .attr('rx', 10)
        .attr('ry', 10)
        .style('opacity', (d) => (!d.startDate && !d.dueDate ? 0.5 : 1))
        .on('mouseover', onMouseover)
        .on('mouseout', onMouseout)
        .on('mousemove', onMouseMove)
        .on('click', onClick);
};

const appendBarLabels = (
    g: Selection<SVGGElement, unknown, null, undefined>,
    bars: TimelineBar[],
    xScale: ScaleTime<number, number, never>,
    yScale: ScaleBand<string>,
    totalWidth: number,
    minDate: Date,
    scrollLeft: number,
    selectedDateType: DateType
) => {
    function calculateOffset(inDays: number) {
        if (selectedDateType === 'WEEK') {
            return xScale(
                moment(minDate)
                    .add((inDays / 7) * 24, 'h')
                    .toDate()
            );
        }
        return xScale(
            moment(minDate)
                .add(inDays * 24, 'h')
                .toDate()
        );
    }

    function calculateEllipsis(
        d: TimelineBar,
        textLength: number,
        addBuffer: boolean,
        availableSpace: number
    ) {
        const self = select(this);
        let textLengh = textLength;
        let text = self.text();
        const buffer = addBuffer ? 10 : 0;

        while (textLengh > availableSpace - buffer && text.length > 0) {
            text = text.slice(0, -1);
            self.text(`${text}...`);
            textLengh = self.node().getComputedTextLength();
        }
    }

    g.selectAll()
        .data(bars)
        .enter()
        .append('text')
        .attr('class', 'Timeline-barLabel')
        .text((d) => d.name)
        .attr('x', function calculateXPosition(d) {
            const self = select(this);
            let position: number = 0;
            let positionDefault: number = 0;

            const barWidth = calculateBarWidth(d, xScale, totalWidth, minDate);

            const textLength = self?.node()?.getComputedTextLength() || 0;
            const xPosStart = d.startDate ? xScale(d.startDate) + BAR_LABEL_PADDING : 0 + BAR_LABEL_PADDING;
            const xPosEnd = d.dueDate
                ? xScale(d.dueDate) - BAR_LABEL_PADDING
                : totalWidth - BAR_LABEL_PADDING;

            if (barWidth < minBarWidth) {
                if (scrollLeft >= xPosStart - (textLength > minBarWidth ? minBarWidth : textLength)) {
                    position =
                        selectedDateType === 'MONTH'
                            ? xPosEnd + calculateOffset(3)
                            : xPosEnd + calculateOffset(8);
                } else {
                    position =
                        xPosStart -
                        (textLength > minBarWidth ? minBarWidth : textLength) -
                        BAR_LABEL_PADDING -
                        10;
                }
            } else {
                positionDefault =
                    inRange(scrollLeft + 8 + textLength, xPosStart, xPosEnd) &&
                    inRange(scrollLeft - textLength, xPosStart, xPosEnd)
                        ? scrollLeft + 8
                        : xPosStart;
            }

            const requiredBuffer =
                position && xPosStart - position - 10 > 0 && xPosStart - position - textLength < 50;

            self.each(function setEllipsisText(dt) {
                let availableSpace;
                if (position && xPosStart - position - 10 > 0) {
                    availableSpace = xPosStart - position;
                } else if (barWidth > minBarWidth) {
                    availableSpace = barWidth;
                } else {
                    availableSpace = totalWidth - scrollLeft;
                }
                calculateEllipsis.call(this, dt, textLength, requiredBuffer, availableSpace);
            });

            return position || positionDefault;
        })
        .attr('y', (d) => (yScale(d.id) as number) + BAR_HEIGHT / 2)
        .attr('style', (d) => {
            const barWidth = calculateBarWidth(d, xScale, totalWidth, minDate);
            return barWidth < minBarWidth ? 'fill: #000;' : '';
        });
};

const removeBarLabels = (g: Selection<SVGGElement, unknown, null, undefined>) => {
    g.selectAll('.Timeline-barLabel').remove();
};

const appendBarStripes = (
    g: Selection<SVGGElement, unknown, null, undefined>,
    bars: TimelineBar[],
    xScale: ScaleTime<number, number, never>,
    yScale: ScaleBand<string>,
    totalWidth: number,
    minDate: Date
) => {
    g.selectAll()
        .data(bars)
        .enter()
        .append('path')
        .attr('d', (d) => {
            if (d.tentativeDates) {
                const x = xScale(d.startDate as Date);
                const y = yScale(d.id) as number;
                const barWidth = calculateBarWidth(d, xScale, totalWidth, minDate);
                let path = '';
                for (let i = 20; i < barWidth; i += 20) {
                    path += `M${x + i},${y} l-4,${BAR_HEIGHT} `;
                }
                return path;
            }
            return '';
        })
        .attr('stroke', 'grey')
        .attr('stroke-width', '2');
};

const appendTodayLine = (
    g: Selection<SVGGElement, unknown, null, undefined>,
    xScale: ScaleTime<number, number, never>,
    totalHeight: number
) => {
    const today = new Date();
    const todayX = xScale(today);
    g.append('line')
        .attr('class', 'Timeline-todayLine')
        .attr('x1', todayX)
        .attr('y1', 0)
        .attr('x2', todayX)
        .attr('y2', totalHeight);
};

export const chartBuilder = {
    appendMainGroup,
    appendYAxis,
    appendGridlines,
    appendPrimaryXAxis,
    appendSecondaryXAxis,
    appendBars,
    appendBarLabels,
    appendBarStripes,
    appendTodayLine,
    removeBarLabels,
};
