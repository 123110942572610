export const wikiSpaces = ['ACADEMY', 'MEDIA', 'PLATFORM'] as const;
export type WikiSpace = typeof wikiSpaces[number];

export interface WikiPageData {
    id: number;
    parentId?: number;
    space: WikiSpace;
    title: string;
    order: number;
    updatedBy: string;
    updatedOn: string;
    createdBy: string;
}

export interface WikiFile {
    id: number;
    pageId: number;
    name: string;
    size: number;
    uploadedBy: string;
    uploadedOn: string;
}

export type WikiPageForm = Omit<WikiPageData, 'id' | 'updatedBy' | 'updatedOn' | 'createdBy' | 'order'>;
