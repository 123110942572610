import { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import SortButton from './SortButton';
import { TableSort } from './useTableSort';

interface Props<T> {
    columnKey?: keyof T;
    style?: CSSProperties;
    className?: string;
    children?: ReactNode;
    sort?: TableSort<T>;
    onClick?: (key: keyof T) => void;
}

const HeadCell = <T,>({ columnKey, children, style, className, sort, onClick }: Props<T>) => {
    const isSorted = sort?.column && sort.column === columnKey;

    return (
        <th style={style} onClick={() => onClick && columnKey && onClick(columnKey)}>
            <div
                className={classNames('LogBookListTable-headCell', { highlightedCell: isSorted }, className)}
            >
                <div className="d-flex align-items-center">
                    {children}
                    {isSorted && <SortButton direction={sort.direction} />}
                </div>
            </div>
        </th>
    );
};

export default HeadCell;
