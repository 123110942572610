import { push } from 'redux-first-history';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { SAGA_ACTION } from '@redux-saga/symbols';
import { get } from 'lodash-es';
import { Action } from '../../common/common.types';
import { fulfilled, pending, rejected } from '../../common/utils/actionSuffixes.util';
import { LOGIN as LOGIN_NAV } from '../app.navigation';
import { Account, Profile } from '../app.types';
import { stripBasenameFromPath } from '../components/Sidebar/navigation.util';
import {
    authActions,
    authSelectors,
    AuthState,
    FETCH_CURRENT_USER_ACCOUNT,
    FETCH_CURRENT_USER_PROFILE,
    FETCH_TOKEN,
    IMPERSONATE,
    LOGIN,
    LOGOUT,
    STOP_IMPERSONATION,
    tokenExpired,
    USER_UNAUTHORIZED,
    VERIFY_2FA_CODE,
} from '../ducks/auth.duck';
import * as authService from '../services/auth.service';

// These paths are same for both ROOT and DAP
const isPathSecured = (pathname: string) => {
    // need to do this because redux-first-history does not strip basename from pathname
    // which breaks implementation in preview branch where we set the basename e.g. basename: '/bpw/branch-name'
    const strippedPath = stripBasenameFromPath(pathname);
    if (strippedPath.startsWith(LOGIN_NAV.path)) return false;
    return true;
};

function* guardAuthorisedPaths() {
    // @ts-ignore
    const currentLocation = yield select((state) => state.router.location);
    if (!isPathSecured(currentLocation.pathname)) return;
    // @ts-ignore
    const isLoggedIn = yield select(authSelectors.isLoggedIn);

    if (!isLoggedIn) {
        // @ts-ignore
        const returnLocation = yield select((state) => state.session.returnLocation);
        if (returnLocation !== currentLocation && currentLocation.pathname !== '/') {
            yield put(authActions.saveReturnLocation(currentLocation));
        }
        // If we push new route in the same event loop tick hashed history calls its listeners in wrong
        // order, thus making Router to get wrong state and render wrong component. We work around it by
        // postponing action to next tick
        yield delay(0);
        yield put(push(LOGIN_NAV.path));
    }
}

function* onLogin({
    payload: { username, password },
}: {
    type: typeof LOGIN;
    payload: { username: string; password: string };
}) {
    yield put({ type: pending(FETCH_TOKEN) });
    try {
        // @ts-ignore
        const session = yield call(authService.signIn, username, password);
        yield put({ type: fulfilled(FETCH_TOKEN), payload: session });
    } catch (error) {
        yield put({ type: rejected(FETCH_TOKEN), payload: error });
    }
}

function* onVerify2faCode({ payload }: { type: typeof VERIFY_2FA_CODE; payload: string }) {
    yield put({ type: pending(VERIFY_2FA_CODE) });
    try {
        yield call(authService.verify2FaCode, payload);
        yield put({ type: fulfilled(VERIFY_2FA_CODE) });
    } catch (error) {
        yield put({ type: rejected(VERIFY_2FA_CODE), payload: error });
    }
}

function* onImpersonate({ payload: userToImpersonate }: { type: typeof IMPERSONATE; payload: string }) {
    try {
        yield call(authService.impersonate, { disable: false, login: userToImpersonate });
        yield put({ type: fulfilled(IMPERSONATE) });
        yield put(push('/'));
        // TODO: invalidate advertiser options
    } catch (error) {
        yield put({ type: rejected(IMPERSONATE), payload: error });
    }
}

function* onStopImpersonate() {
    try {
        yield call(authService.impersonate, { disable: true });
        yield put({ type: fulfilled(STOP_IMPERSONATION) });
    } catch (error) {
        yield put({ type: rejected(STOP_IMPERSONATION), payload: error });
    }
}

function* monitorTransitiveStates() {
    const authState: AuthState = yield select(authSelectors.root);
    switch (authState.type) {
        case 'TOKEN_READY': {
            yield put({ type: pending(FETCH_CURRENT_USER_ACCOUNT) });
            try {
                const account: Account = yield call(authService.fetchCurrentUser);
                yield put({ type: fulfilled(FETCH_CURRENT_USER_ACCOUNT), payload: account });
            } catch (error) {
                yield put({ type: rejected(FETCH_CURRENT_USER_ACCOUNT), payload: error });
            }
            return;
        }
        case 'ACCOUNT_READY': {
            yield put({ type: pending(FETCH_CURRENT_USER_PROFILE) });
            try {
                const account: Profile = yield call(authService.fetchCurrentUserProfile);
                yield put({ type: fulfilled(FETCH_CURRENT_USER_PROFILE), payload: account });
            } catch (error) {
                yield put({ type: rejected(FETCH_CURRENT_USER_PROFILE), payload: error });
            }
            return;
        }
        case 'READY': {
            // @ts-ignore
            const strippedPath = stripBasenameFromPath(
                yield select((state) => state.router.location.pathname)
            );
            if (strippedPath !== LOGIN_NAV.path) return;
            yield put(push('/'));
            // reload page after login to get latest code version
            yield location.reload();
            // eslint-disable-next-line no-useless-return
            return;
        }
        default:
    }
}
function* checkIfTokenExpired(action: Action) {
    // We might know about token expiration from 3 sources:
    // 1. If we get rejected action with 401
    const res = get(action, 'payload.response', {});
    if (res.status === 401 && res.data && res.data.error === 'invalid_token') {
        yield put(authActions.userUnauthorized(action));
        return;
    }

    // 2. If we get error form graphql with 401 (this case handled in apollo client config)

    // 3. If user token expiration time is reached (check only if in secured path)
    // @ts-ignore
    const currentLocation = yield select((state) => state.router.location);
    if (isPathSecured(currentLocation.pathname)) {
        // @ts-ignore
        const authState = yield select(authSelectors.root);
        if (authState.type !== 'READY') return;
        if (tokenExpired(authState.tokenExpires)) {
            yield put(authActions.userUnauthorized('Token expired'));
        }
    }
}

// We don't want LOGOUT action performed when we are already in /login, because LOGOUT wipes out the state
// so we do authorization check before dispatching it
function* logoutIfLoggedIn() {
    // @ts-ignore
    const isLoggedIn = yield select(authSelectors.isLoggedIn);
    if (isLoggedIn) {
        yield put(authActions.logout());
    }
}

export default [
    takeEvery((action: any) => !action[SAGA_ACTION] || action.type === LOGOUT, guardAuthorisedPaths),
    takeLatest(LOGIN, onLogin),
    takeLatest(IMPERSONATE, onImpersonate),
    takeLatest(STOP_IMPERSONATION, onStopImpersonate),
    takeLatest(VERIFY_2FA_CODE, onVerify2faCode),
    takeEvery((action: any) => !action[SAGA_ACTION], checkIfTokenExpired),
    takeEvery('*', monitorTransitiveStates),

    takeEvery(USER_UNAUTHORIZED, logoutIfLoggedIn),
];
