import { KeyboardEvent, ReactNode, useEffect } from 'react';
import Dock from 'react-dock';
import classNames from 'classnames';
import ErrorBoundary from 'platform/common/containers/ErrorBoundary/ErrorBoundary';
import SIDE_PANEL_SIZES from '../../constants/sidePanelSizes.constant';
import './SidePanel.scss';

const dimStyle = {
    background: 'rgba(25, 45, 80, 0.55)',
};
const positions = ['left', 'top', 'right', 'bottom'] as const;
const dimModes = ['transparent', 'none', 'opaque'] as const;

type Props = {
    onEscClick?: () => any;
    position?: typeof positions[number];
    isVisible?: boolean;
    dimMode?: typeof dimModes[number];
    children: ReactNode;
    size?: number;
    dockStyle?: any;
    className?: string;
};

const SidePanel = ({
    position = positions[2],
    isVisible = true,
    dimMode = dimModes[2],
    children,
    size = SIDE_PANEL_SIZES.NARROW,
    dockStyle,
    className,
    onEscClick,
}: Props) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.removeProperty('overflow');
        };
    }, []);

    const { fluid, dockSize } = size === 0 ? { fluid: true, dockSize: 1 } : { fluid: false, dockSize: size };

    const keyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Escape' && onEscClick) {
            onEscClick();
        }
    };

    return (
        <div className={classNames('SidePanel', className)}>
            <Dock
                position={position}
                fluid={fluid}
                size={dockSize}
                dimMode={dimMode}
                isVisible={isVisible}
                dimStyle={dimStyle}
                zIndex={1040}
                dockStyle={dockStyle}
            >
                <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                    onKeyDown={keyDown}
                    style={{ outline: 'none' }}
                    className="h-100"
                >
                    <ErrorBoundary>{children}</ErrorBoundary>
                </div>
            </Dock>
        </div>
    );
};

export default SidePanel;
