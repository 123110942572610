import { useDispatch } from 'react-redux';
import { Collapse } from 'reactstrap';
import { push } from 'redux-first-history';
import { LOG_CATEGORY_LABELS } from 'platform/campaign/logBook/logBook.constant';
import { getCategoryLabel } from 'platform/campaign/logBook/logBook.utils';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import { EMPTY_SYMBOL } from 'platform/common/constants/common.constant';
import useToggle from 'platform/common/hooks/useToggle';
import { formatDateTime } from 'platform/common/utils/date.util';
import { LogEntryTableView } from '../../logBook.types';
import { createNote, getLog } from '../../logbook.service';
import NumberShift from '../BudgetShiftingDetails/NumberShift';
import BodyCell from './BodyCell';
import LogBookEntryDivider from './LogBookEntryDivider';
import LogEntryClassification from './LogEntryClassification';
import LogEntryInfo from './LogEntryInfo';
import LogEntryShortNote from './LogEntryShortNote';
import ViewNoteButton from './ViewNoteButton';

interface Props {
    log: LogEntryTableView;
    canEdit: boolean;
    refetchLogs?: () => void;
}

const LogEntryCard = ({ log, canEdit, refetchLogs }: Props) => {
    const dispatch = useDispatch();
    const [showNote, toggleNote] = useToggle(false);
    const hasData = log.eventDetails?.oldData && log.eventDetails?.newData;

    return (
        <>
            <tr className="LogBookListTable-upperRow">
                <BodyCell className="LogBookListTable-upperRowCell fw-bold">
                    {formatDateTime(log.timestamp)}
                </BodyCell>
                <BodyCell className="LogBookListTable-upperRowCell">
                    {LOG_CATEGORY_LABELS[log.category]}
                </BodyCell>
                <BodyCell className="LogBookListTable-upperRowCell">
                    {log.mediaplans?.map((m) => m.name).join(', ')}
                </BodyCell>
                <BodyCell className="LogBookListTable-upperRowCell">
                    {log.mediaInsertions?.map((m) => m.name).join(', ')}
                </BodyCell>
                <BodyCell className="LogBookListTable-upperRowCell">{log.channel}</BodyCell>
                <BodyCell className="LogBookListTable-upperRowCell">
                    {log.periodFrom && log.periodTo ? (
                        <>
                            {log.periodFrom} <span className="text-light-slate-gray">to</span> {log.periodTo}
                        </>
                    ) : (
                        EMPTY_SYMBOL
                    )}
                </BodyCell>
                <BodyCell showEmptyPlaceholder={false} className="LogBookListTable-upperRowCell text-end">
                    {canEdit && (
                        <InlineDropdown
                            items={[
                                {
                                    label: 'Edit',
                                    action: () => {
                                        dispatch(push(`/logs/logbook/${log.id}`));
                                    },
                                },
                                ...(log.category !== 'BUDGET_SHIFTING'
                                    ? [
                                          {
                                              label: 'Duplicate',
                                              action: () =>
                                                  log.id &&
                                                  getLog(log.id)
                                                      .then(({ id, subject, ...rest }) =>
                                                          createNote({
                                                              subject: `${subject} duplicate`,
                                                              ...rest,
                                                          })
                                                      )
                                                      .then(refetchLogs),
                                          },
                                      ]
                                    : []),
                            ]}
                        />
                    )}
                </BodyCell>
            </tr>
            <tr className="LogBookListTable-bottomRow">
                <BodyCell colSpan={7} className="LogBookListTable-bottomRowCell">
                    <div className="d-flex align-items-center">
                        <span className="text-light-slate-gray text-nowrap">{getCategoryLabel(log)}</span>
                        <NumberShift
                            className="ms-2"
                            newNumber={log.eventDetails?.newData?.budget}
                            oldNumber={log.eventDetails?.oldData?.budget}
                        />
                        {hasData && (
                            <>
                                <LogBookEntryDivider />
                                <LogEntryInfo
                                    oldData={log.eventDetails?.oldData}
                                    newData={log.eventDetails?.newData}
                                />
                            </>
                        )}
                        {log.subject && (
                            <>
                                {!hasData && <LogBookEntryDivider />}
                                <LogEntryShortNote text={log.subject ?? ''} />
                            </>
                        )}
                        <LogEntryClassification classification={log.classification} />
                        {log.changedBy && (
                            <span className="text-light-slate-gray ms-auto text-nowrap">
                                Edited by {log.changedBy}
                            </span>
                        )}
                    </div>
                    <Collapse isOpen={!!log.text && showNote}>
                        <RawHtml className="LogBookListTable-note">{log.text}</RawHtml>
                    </Collapse>
                    {!!log.text && <ViewNoteButton expanded={showNote} onClick={toggleNote} />}
                </BodyCell>
            </tr>
        </>
    );
};

export default LogEntryCard;
