import { RootState } from '../../rootState.type';
import { Action } from '../common.types';

const SET_IS_MOBILE = 'common/layout/SET_IS_MOBILE';

export type Layout = {
    isMobile: boolean;
};

export const getIsMobile = () => window.matchMedia('(max-width: 992px)').matches;

const defaultState: Layout = {
    isMobile: getIsMobile(),
};

const reducer = (state: Layout = defaultState, action: Action): Layout => {
    switch (action.type) {
        case SET_IS_MOBILE: {
            return { ...state, isMobile: action.payload };
        }
        default:
            return state;
    }
};

export default reducer;

export const layoutActions = {
    setIsMobile: (isMobile: boolean) => ({
        type: SET_IS_MOBILE,
        payload: isMobile,
    }),
};

export const layoutSelectors = {
    isMobile: (state: RootState) => state.common.layout.isMobile,
};
