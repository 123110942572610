import { ChangeEvent } from 'react';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';

interface Props {
    entriesCount: number;
    onChange: (entriesCount: number) => void;
}

const ReportChartEntriesCount = ({ entriesCount, onChange }: Props) => (
    <InputWithAddOn
        value={entriesCount}
        type="text"
        leftAddOn={{ title: 'Entries count' }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (isNaN(Number(e.target.value))) {
                return;
            }
            onChange(e.target.value ? Number(e.target.value) : 0);
        }}
    />
);

export default ReportChartEntriesCount;
