export enum SpendCalculationType {
    MANUAL = 'MANUAL',
    AUTOMATED = 'AUTOMATED',
}

export enum PricingType {
    CPM = 'CPM',
    CPC = 'CPC',
}

type UnitDatedSpend = {
    dateFrom: string;
    dateTo: string;
    spend: number;
    impressions: number;
    clicks: number;
};

export type Pricing = {
    multiplier?: number;
    type?: PricingType;
};

export type UnitIOSpends = {
    calculationType?: SpendCalculationType;
    vendorExternalId?: string;
    defaultPricing?: Pricing;
    datedSpends?: UnitDatedSpend[];
    bookedPricing?: Pricing;
    notes?: string;
};

export type UnitIOScript = {
    unitId: number;
    campaignId: number;
    creativeName: string;
    creativeSize: string;
    javascript: string;
    iframe: string;
    clickTracker: string;
    viewTracker: string;
    vast2: string;
    vast3: string;
    vast4: string;
};
