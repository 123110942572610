import { FormatOptionLabelMeta } from 'react-select';
import { SelectItem } from 'platform/common/common.types';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import { OptionType } from './select.types';

const SelectOptionWithStatus = (
    { label, status }: SelectItem<string>,
    { context }: FormatOptionLabelMeta<OptionType>
) => {
    if (!status || context === 'value') {
        return label;
    }
    return (
        <div className="d-flex align-items-center">
            <StatusBadge className="me-2" status={status} style={{ minWidth: 60 }} />
            {label}
        </div>
    );
};

export default SelectOptionWithStatus;
