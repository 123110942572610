import { useParams } from 'react-router-dom';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { createChatAgent, getChatAgent, updateChatAgent } from '../../chat.service';
import { ChatAgentFormModel } from '../../chat.types';
import ChatAgentForm from './ChatAgentForm';

interface Props {
    canEdit: boolean;
    redirectTo: string;
    afterSubmit?: () => void;
}

const initialAgent: ChatAgentFormModel = {
    name: '',
    status: 'ACTIVE',
    order: 0,
    modelId: null,
    iconUrl: '',
    settings: '',
    systemMessage: '',
    adminOnly: false,
    systemOnly: false,
    defaultAgent: false,
    personalPrompts: false,
    betaTesting: false,
    requiresAdvertiser: false,
    showPromptsInWelcomeMessage: false,
    description: '',
    message: '',
    blacklistedWords: '',
};

const ChatAgentFormContainer = ({ canEdit, afterSubmit, redirectTo }: Props) => {
    const params = useParams<{ id: string }>();
    const id = !!params.id && Number(params.id);

    return (
        <FormContainer<ChatAgentFormModel>
            helpKey="chat_agent_form"
            showEditWarning={false}
            isEdit={!!id}
            sidePanel
            onOpen={async () => (id ? getChatAgent(id) : initialAgent)}
            onSubmit={(model) => (id ? updateChatAgent(id, model) : createChatAgent(model))}
            onSubmitFinish={afterSubmit}
            canEdit={canEdit}
            redirectTo={redirectTo}
        >
            {(props) => <ChatAgentForm {...props} />}
        </FormContainer>
    );
};

export default ChatAgentFormContainer;
