import { ReactNode } from 'react';
import './LabelLink.scss';

type Props = {
    onClick: () => any;
    className?: string;
    children: ReactNode;
    title?: string;
    style?: React.CSSProperties;
};

const LabelLink = ({ onClick, className = '', children, title, style }: Props) => (
    <span
        role="button"
        tabIndex={0}
        className={`LabelLink ${className}`}
        style={style}
        title={title}
        onClick={onClick}
    >
        {children}
    </span>
);

export default LabelLink;
