import { useState, Fragment } from 'react';
import { last, first } from 'lodash-es';
import { ItemGroupCM360 } from '../../CustomerJourneyCM360';
import { getDurationText, getDurationInterval } from '../../customerJourney.utils';
import JourneyEvent from './JourneyEvent';
import JourneyEventSeparator from './JourneyEventSeparator';
import JourneyToggleableColumn from './JourneyTogleableColumn';

interface Props {
    groups: ItemGroupCM360[];
    startGroups: ItemGroupCM360[];
    endGroups: ItemGroupCM360[];
}

const JourneyTimelineGroup = ({ groups, startGroups, endGroups }: Props) => {
    const [expanded, setExpanded] = useState(false);

    const lastStartGroup = last(startGroups);
    const firstEndGroup = first(endGroups);

    if (!lastStartGroup || !firstEndGroup) {
        return null;
    }

    if (expanded) {
        const firstGroup = first(groups);
        const lastGroup = last(groups);

        if (!firstGroup || !lastGroup) {
            return null;
        }

        return (
            <div className="d-flex flex-grow-1">
                <JourneyToggleableColumn
                    durationText={getDurationText(lastStartGroup.endTime, firstGroup.startTime)}
                    durationInterval={getDurationInterval(lastStartGroup.endTime, firstGroup.startTime)}
                    buttonText="> <"
                    onClick={() => setExpanded(false)}
                />
                {groups.map((group, index) => {
                    const nextGroup = groups[index + 1];

                    return (
                        <Fragment key={group.startTime}>
                            <JourneyEvent
                                group={group}
                                groupIndex={startGroups.length + index}
                                leftDashVisible
                                rightDashVisible
                                circleEmpty
                            />
                            {nextGroup && <JourneyEventSeparator group={group} nextGroup={nextGroup} />}
                        </Fragment>
                    );
                })}
                <JourneyToggleableColumn
                    durationText={getDurationText(lastGroup.endTime, firstEndGroup.startTime)}
                    durationInterval={getDurationInterval(lastGroup.endTime, firstEndGroup.startTime)}
                    buttonText="> <"
                    onClick={() => setExpanded(false)}
                />
            </div>
        );
    }

    return (
        <JourneyToggleableColumn
            durationText={getDurationText(lastStartGroup.endTime, firstEndGroup.startTime)}
            durationInterval={getDurationInterval(lastStartGroup.endTime, firstEndGroup.startTime)}
            buttonText={`< ${groups.length} >`}
            onClick={() => setExpanded(true)}
        />
    );
};

export default JourneyTimelineGroup;
