import { AxiosRequestConfig } from 'axios';
import axios from 'platform/axios/axiosBff';
import { IdResponse } from 'platform/common/common.types';
import { Status } from 'platform/common/constants/status.constant';
import { saveDownloadedFile } from 'platform/common/utils/file.util';
import {
    MediaInsertion,
    MediaInsertionLink,
    MediaInsertionSearchResult,
    MediaInsertionStep,
    Mediaplan,
    MediaplanChange,
    MediaplanCopy,
    MediaplanCreatePayload,
    MediaplanFileMetadata,
    MediaplanTree,
    MediaplanUpdatePayload,
    WorkflowModel,
    WorkflowStep,
} from 'platform/mediaplan/mediaplan.types';

export const getMediaplan = (id: number) =>
    axios.get<Mediaplan>(`/campaign/api/mediaplans/${id}`).then((response) => response.data);

export const listMediaplans = (params: { advertiserId: number; status: Status[] }) =>
    axios.get<Mediaplan[]>(`/campaign/api/mediaplans`, { params }).then((response) => response.data);

export const createMediaplan = (model: MediaplanCreatePayload) =>
    axios.post<IdResponse>(`/campaign/api/mediaplans`, model).then((response) => response.data);

export const updateMediaplan = (id: number, model: MediaplanUpdatePayload) =>
    axios.put(`/campaign/api/mediaplans/${id}`, model);

export const copyMediaplan = (id: number, copy: MediaplanCopy) =>
    axios.post(`/campaign/api/mediaplans/${id}/copy`, copy);

export const storeMediaInsertions = (id: number, model: MediaplanTree, mediaplanVersion: number) =>
    axios.put(`/campaign/api/mediaplans/${id}/insertions`, model, { params: { mediaplanVersion } });

export const getMediaInsertions = (id: number) =>
    axios.get<MediaplanTree>(`/campaign/api/mediaplans/${id}/insertions`).then((response) => response.data);

export const searchMediaInsertions = (params: {
    advertiserId: number;
    mediaplanId?: number[];
    mediaInsertionId?: number[];
}) =>
    axios
        .get<MediaInsertionSearchResult[]>(`/campaign/api/mediaplans/insertions/search`, { params })
        .then((response) => response.data);

export const getMediaInsertionLinks = (params: {
    campaignId?: number | string;
    mediaplanId?: number | string;
}) =>
    axios
        .get<MediaInsertionLink[]>(`/campaign/api/mediaplans/insertions/links`, { params })
        .then((response) => response.data);

export const getMediaplanChanges = (id: number) =>
    axios
        .get<{ insertionChanges: MediaplanChange[]; mediaplanChanges: MediaplanChange[] }>(
            `/campaign/api/mediaplans/${id}/changes`
        )
        .then((response) => response.data);

export const getMediaplanWorkflow = (id: number) =>
    axios.get<WorkflowModel>(`/campaign/api/mediaplans/${id}/workflow`).then((response) => response.data);

export const storeMediaplanStep = (
    mediaplanId: number,
    { type, completed, notes, classification }: WorkflowStep
) =>
    axios
        .put<MediaInsertionStep>(`/campaign/api/mediaplans/${mediaplanId}/workflow/mediaplan-steps/${type}`, {
            completed,
            notes,
            classification,
        })
        .then((response) => response.data);

export const storeMediaInsertionStep = (
    mediaplanId: number,
    { mediaInsertionId, vendorSystem, type, completed, notes, classification }: MediaInsertionStep
) =>
    axios
        .put<MediaInsertionStep>(
            `/campaign/api/mediaplans/${mediaplanId}/workflow/media-insertion-steps/${type}`,
            { completed, notes, classification },
            { params: { mediaInsertionId, vendorSystem } }
        )
        .then((response) => response.data);

export const storeMediaplanFile = (
    id: number,
    fileName: string,
    contentBase64: string,
    config: AxiosRequestConfig
) =>
    axios
        .post(
            `/campaign/api/mediaplans/${id}/files`,
            {
                name: fileName,
                content: contentBase64,
            },
            config
        )
        .then((response) => response.data);

export const deleteMediaplanFile = (id: number, fileId: number) =>
    axios.delete(`/campaign/api/mediaplans/${id}/files/${fileId}`);

export const listMediaplanFiles = (mediaplanId: number) =>
    axios
        .get<MediaplanFileMetadata[]>(`/campaign/api/mediaplans/${mediaplanId}/files`)
        .then((response) => response.data);

export const downloadMediaplanFile = (mediaplanId: number, fileId: number) =>
    axios
        .get<Blob>(`/campaign/api/mediaplans/${mediaplanId}/files/${fileId}`, { responseType: 'blob' })
        .then(saveDownloadedFile);

export const getInsertion = (mediaplanId: number, mediaInsertionId: number) =>
    axios
        .get<MediaInsertion>(`/campaign/api/mediaplans/${mediaplanId}/insertions/${mediaInsertionId}`)
        .then((response) => response.data);

export const updateWorkflowLaneAssignee = (
    mediaplanId: number,
    mediaInsertionId: number,
    vendorSystem: string,
    assignee?: string
) =>
    axios.put(
        `/campaign/api/mediaplans/${mediaplanId}/workflow/media-insertion-lanes`,
        { assignee },
        { params: { mediaInsertionId, vendorSystem } }
    );
