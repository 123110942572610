import { useEffect, useState } from 'react';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';

const LoadingReportIndicator = () => {
    const [dots, setDots] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => setDots((prev) => (prev + 1) % 4), 750);

        return () => clearInterval(timer);
    }, []);

    return (
        <HeaderContainer>
            <PageHeader
                title={`Loading Report${Array.from(Array(dots))
                    .map(() => '.')
                    .join('')}`}
            />
        </HeaderContainer>
    );
};

export default LoadingReportIndicator;
