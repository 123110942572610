import { gql, useQuery } from '@apollo/client';
import Placeholder from '../../../common/components/Placeholder/Placeholder';
import { classificationCodesToFunnelSteps } from '../../constants/segmentFunnel.constant';
import SegmentFunnel from './SegmentFunnel';

interface Props {
    segmentId: number;
}

interface Segment {
    id: number;
    classificationCodes: string[];
}

const SegmentDetailsFunnel = ({ segmentId }: Props) => {
    const { loading, data } = useQuery<{
        segment: Segment;
    }>(
        gql`
            query SegmentFunnelQuery($id: Int!) {
                segment(id: $id) {
                    id
                    classificationCodes
                }
            }
        `,
        {
            variables: {
                id: segmentId,
            },
            fetchPolicy: 'network-only',
        }
    );
    return (
        <>
            {loading && <Placeholder height="55px" />}
            {!loading && data && (
                <SegmentFunnel
                    activeSteps={classificationCodesToFunnelSteps(data.segment.classificationCodes)}
                />
            )}
        </>
    );
};

export default SegmentDetailsFunnel;
