import { ReactNode } from 'react';
import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import ButtonArray from 'platform/common/components/ButtonArray/ButtonArray';
import AdvertiserSelect from 'platform/common/containers/AdvertiserSelect/AdvertiserSelect';
import { Validator, validator } from 'platform/common/utils/validators.util';
import { ACCESS_OPTIONS, AccessType } from '../../constants/access.constant';

export const validateCustomAccessLevel = (value: { mode: string; entityIds: number[] }) =>
    value && value.mode === 'CUSTOM' && !value.entityIds.length ? 'required' : undefined;

type Props = {
    name: string;
    buttonAllText: string;
    disabled?: boolean;
    validate?: Validator | Validator[];
};

const CustomAccess = ({ name, buttonAllText, disabled = false, validate }: Props) => {
    const [{ value }, { touched, error }, { setValue }] = useField<{
        mode: AccessType;
        entityIds: number[];
    }>({
        name,
        validate: validate && validator(validate),
    });

    const invalid = Boolean(touched && error);

    const onButtonClick = (mode: AccessType) => {
        setValue({ ...value, mode });
    };

    const onTreeSelect = (entityIds: number[]) => {
        setValue({ ...value, entityIds });
    };

    const lowerElements: {
        [key in AccessType]: ReactNode;
    } = {
        ALL: buttonAllText,
        CUSTOM: (
            <AdvertiserSelect
                value={value.entityIds}
                onChange={onTreeSelect}
                isMulti
                isClearable
                disabled={disabled}
            />
        ),
    };

    return (
        <div className="w-100">
            <ButtonArray
                buttons={ACCESS_OPTIONS}
                selected={value.mode}
                onClick={onButtonClick}
                disabled={disabled}
            />
            <div className="pt-3">{lowerElements[value.mode]}</div>
            {invalid && <FormFeedback className="d-block">{error}</FormFeedback>}
        </div>
    );
};

export default CustomAccess;
