import { sortBy } from 'lodash-es';
import {
    DimensionFilterOption,
    ReportFilterOperator,
    ReportFilterValue,
} from 'platform/analytics/analytics.types';
import { resolveFilterValues } from 'platform/analytics/reportComponents/ReportFilters/useReportFilterOptions';
import Popover from '../Popover/Popover';
import Tooltip from '../Tooltip/Tooltip';
import './FilterValuesPopover.scss';

interface Props {
    values: ReportFilterValue[];
    options: DimensionFilterOption[];
    operator: ReportFilterOperator;
}

const FilterValuesPopover = ({ values, options, operator }: Props) => {
    const resolvedValues = sortBy(
        options
            .filter((option) =>
                resolveFilterValues(values, options, operator).some(
                    (value) => String(value) === String(option.value)
                )
            )
            .map((option) => ({
                value: option.value,
                label: option.label,
            })),
        (v) => v.label
    );

    return (
        <Popover
            popoverClassName="FilterValuesPopover"
            placement="left"
            preventOverflow
            renderPopover={() => (
                <>
                    {!resolvedValues.length && <div>No values found</div>}
                    {resolvedValues.map(({ value, label }) => (
                        <div className="mb-1" key={value}>
                            {label}
                        </div>
                    ))}
                </>
            )}
        >
            <Tooltip renderTooltip={() => 'Show values'}>
                <div className="FilterValuesPopover-icon">
                    <i className="far fa-list" />
                </div>
            </Tooltip>
        </Popover>
    );
};

export default FilterValuesPopover;
