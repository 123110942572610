import { Col, Input, Row } from 'reactstrap';
import classNames from 'classnames';
import Select from 'platform/common/components/Select/Select';
import { AdvertiserTagFormEvent } from '../../advertiserTag.types';

interface Props {
    event: AdvertiserTagFormEvent;
    onSelectionChange: (advertiserTagVarName: string, property: string) => void;
    setNumberOfCustomVars: (advertiserTagVarName: string, customVars: number) => void;
}

const EventsDownloadSelectionListRow = ({ event, onSelectionChange, setNumberOfCustomVars }: Props) => (
    <Row
        className={classNames('EventsDownloadSelectionList-row g-0', {
            'text-muted': event.disabled,
        })}
    >
        <Col md={5} className="d-flex align-items-center px-1">
            <Input
                type="checkbox"
                className="position-static m-0"
                checked={event.selected}
                disabled={event.disabled}
                onChange={() => onSelectionChange(event.advertiserTagVarName, 'selected')}
            />
            <div className="ms-2">
                <span>{event.name}</span>{' '}
                {event.type === 'CUSTOM_EVENT' && <span>({event.advertiserTagVarName})</span>}
            </div>
        </Col>
        <Col md={2} className="text-center px-1">
            {event.editable.productData && (
                <Input
                    type="checkbox"
                    className="position-static m-0"
                    checked={event.productData}
                    disabled={!event.selected}
                    onChange={() => onSelectionChange(event.advertiserTagVarName, 'productData')}
                />
            )}
        </Col>
        <Col md={2} className="text-center px-1">
            {event.editable.purchaseData && (
                <Input
                    type="checkbox"
                    className="position-static m-0"
                    checked={event.purchaseData}
                    disabled={!event.selected}
                    onChange={() => onSelectionChange(event.advertiserTagVarName, 'purchaseData')}
                />
            )}
        </Col>

        <Col md={3} className="text-center px-1">
            <Select
                value={event.customVarsCount || 0}
                options={Array.from(Array(21), (_, i) => ({
                    label: i === 0 ? 'Not used' : `${i}`,
                    value: i,
                }))}
                isDisabled={!event.selected}
                onChange={(value: number) => setNumberOfCustomVars(event.advertiserTagVarName, value)}
            />
        </Col>
    </Row>
);

export default EventsDownloadSelectionListRow;
