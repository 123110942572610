import { toMediaplanCreatePayload } from 'platform/campaign/campaign/mappers/mediaplan.mapper';
import { createMediaplan } from 'platform/campaign/campaign/services/mediaplan.service';
import { IdResponse } from 'platform/common/common.types';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { MediaplanFormModel } from 'platform/mediaplan/mediaplan.types';
import MediaplanForm from './MediaplanForm';

interface Props {
    redirectTo: string;
    advertiserId: number;
    onSubmitFinish: (data: IdResponse) => void;
    canEdit: boolean;
}

const MediaPlanCreateFormContainer = ({ redirectTo, advertiserId, onSubmitFinish, canEdit }: Props) => {
    const handleSubmit = (model: MediaplanFormModel) => createMediaplan(toMediaplanCreatePayload(model));
    const handleOpen = async (): Promise<MediaplanFormModel> => ({
        status: 'ACTIVE',
        advertiserId,
        runtime: { from: '', to: '' },
        name: '',
        projectNumber: '',
        poNumber: '',
        budget: undefined,
        classification: {
            productLabel: [],
            productSubLabel: [],
            flightLabel: [],
            countryLabels: [],
        },
    });

    return (
        <FormContainer
            helpKey="mediaplan_form"
            isEdit={false}
            redirectAfterSubmit={false}
            canEdit={canEdit}
            redirectTo={redirectTo}
            onSubmitFinish={onSubmitFinish}
            onSubmit={handleSubmit}
            onOpen={handleOpen}
        >
            {(props) => <MediaplanForm {...props} />}
        </FormContainer>
    );
};

export default MediaPlanCreateFormContainer;
