import axiosBff from 'platform/axios/axiosBff';
import { IdResponse } from 'platform/common/common.types';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { saveDownloadedFile } from 'platform/common/utils/file.util';
import {
    CreateUpdateObserverReportPayload,
    GetObserverReportsParams,
    ObserverExecution,
    ObserverReportBase,
    ObserverReportRow,
} from './observer.types';

export const getObserverReport = (id: number): Promise<ObserverReportBase> =>
    axiosBff.get<ObserverReportBase>(`/observer/api/reports/${id}`).then((response) => response.data);

export const getObserverReports = (params: GetObserverReportsParams) =>
    axiosBff.get<ObserverReportRow[]>('/observer/api/reports', { params }).then((response) => response.data);

export const createObserverReport = (payload: CreateUpdateObserverReportPayload) =>
    axiosBff.post<IdResponse>('/observer/api/reports', payload).then((response) => response.data);

export const updateObserverReport = (id: number, payload: CreateUpdateObserverReportPayload) =>
    axiosBff.put(`/observer/api/reports/${id}`, payload);

export const executeObserverReport = (id: number) => axiosBff.post(`/observer/api/reports/${id}/executions`);

export const executeObserverReportForMe = (id: number) =>
    axiosBff.post(`/observer/api/reports/${id}/executions/me`);

export const getObserverExecutions = (reportId: number, params: { offset?: number; limit?: number }) =>
    axiosBff
        .get<ObserverExecution[]>(`/observer/api/reports/${reportId}/executions`, { params })
        .then((response) =>
            response.data.map((execution) => ({
                ...execution,
                details: execution.details.map((d) => ({ ...d, reportId })),
            }))
        );

export const downloadObserverExecutionAttachment = async (
    reportId: number,
    executionId: number,
    detailId: number
) =>
    axiosBff
        .get<Blob>(
            `/observer/api/reports/${reportId}/executions/${executionId}/details/${detailId}/attachment`,
            { responseType: 'blob' }
        )
        .then(saveDownloadedFile);

export const changeObserverReportStatus = (id: number, status: ActiveOrArchived) =>
    axiosBff.put(`/observer/api/reports/${id}/status`, { status });

export const downloadObserverReport = (reportId: number, accessToken: string, params: any) =>
    axiosBff
        .get<Blob>(`/transfer/${reportId}?accessToken=${accessToken}`, {
            baseURL: '',
            params,
            headers: {
                'Content-type': 'application/json',
            },
            responseType: 'blob',
        })
        .then((response) => {
            if (params && params.email) {
                return 'EMAIL';
            }
            saveDownloadedFile(response);
            return 'DOWNLOAD';
        });
