import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import FormRow from 'platform/common/components/FormRow/FormRow';
import SupportedMacrosPopover from 'platform/common/components/SupportedMacrosPopover/SupportedMacrosPopover';
import { LANDING_PAGE_MACROS } from 'platform/creatives/constants/creativeTypes';
import { ConceptModel } from 'platform/creatives/types/creative.types';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormInputWithUrlPreview from 'platform/formik/FormInputWithUrlPreview/FormInputWithUrlPreview';
import { CREATIVE_FORM_HELP_KEY } from '../CreativeForm/CreativeFormContainer';

const LandingPageFields = ({ field }: { field: string }) => {
    const { values } = useFormikContext<ConceptModel>();
    const clickThroughUrl = get(values, `${field}.clickThroughUrl`);

    return (
        <>
            <FormRow label="Landing page" helpKey={`${CREATIVE_FORM_HELP_KEY}_hosted_clickThroughUrl`}>
                <FormInputWithUrlPreview name={`${field}.clickThroughUrl`} value={clickThroughUrl} />
            </FormRow>
            <FormRow
                label="Landing page suffix"
                helpKey={`${CREATIVE_FORM_HELP_KEY}_hosted_clickThroughUrlSuffix`}
            >
                <FormInput
                    name={`${field}.clickThroughUrlSuffix`}
                    type="text"
                    placeholder="Example: utm_source=Source&utm_medium=Medium"
                />
            </FormRow>
            <FormRow>
                <SupportedMacrosPopover list={LANDING_PAGE_MACROS} />
            </FormRow>
        </>
    );
};

export default LandingPageFields;
