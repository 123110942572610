import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { ACTIVE_OR_INACTIVE, Status } from 'platform/common/constants/status.constant';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import AdvancedSettingFormContainer from './AdvancedSettingFormContainer';
import { fetchAdvancedSettings } from './advanced.setting.service';
import {
    ADVANCED_SETTING_GROUP_LABELS,
    AdvancedSetting,
    AdvancedSettingGroup,
} from './advanced.setting.types';

const PATH = '/admin-panel/advanced-settings';
type QueryParams = {
    status: Status[];
};

const AdvancedSettingList = () => {
    const dispatch = useDispatch();
    const {
        queryParams: { status },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>(
        {
            status: ['ACTIVE'],
        },
        PATH
    );
    const [{ data, loading }, refetch] = usePromise([], () => fetchAdvancedSettings({ status }), [status]);
    const canEdit = useFeature('ADMIN_EDIT');

    const columns: TableColumn<AdvancedSetting>[] = [
        {
            Header: 'Status',
            accessor: 'status',
            width: 80,
            Cell: ({ value }) => <StatusBadge status={value} />,
        },
        {
            Header: 'Group',
            accessor: 'group',
            width: 140,
            Cell: ({ value }) => ADVANCED_SETTING_GROUP_LABELS[value as AdvancedSettingGroup],
        },
        {
            Header: 'Key',
            accessor: 'key',
            width: 300,
        },
        {
            Header: 'Name',
            accessor: 'name',
            width: 300,
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: CellWithEllipsis,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            width: 150,
            Cell: ({ original: setting }) => (
                <UpdatedOn date={setting.updatedOn} updatedBy={setting.updatedBy} />
            ),
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original: setting }) => (
                <InlineDropdown
                    items={[
                        {
                            label: canEdit ? 'Edit' : 'View',
                            action: () => dispatch(push(`${PATH}/${setting.key}`)),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="advanced_setting_list">
                {!canEdit && <CanNotEditWarning />}
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={ACTIVE_OR_INACTIVE}
                        onChange={(value) => setQueryParams({ status: value })}
                        isMulti
                    />
                </ListFilters>
                <FormattedTable
                    topToolbar={
                        <ListToolbar onCreate={() => (canEdit ? dispatch(push(`${PATH}/new`)) : undefined)} />
                    }
                    columns={columns}
                    loading={loading}
                    data={data}
                    defaultSorted={[
                        { orderBy: 'group', direction: 'ASC' },
                        { orderBy: 'name', direction: 'ASC' },
                    ]}
                    showPagination={false}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <AdvancedSettingFormContainer
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":key"
                    element={
                        <AdvancedSettingFormContainer
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default AdvancedSettingList;
