import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { Campaign } from 'platform/campaign/campaign/campaign.types';
import CampaignFormContainer from 'platform/campaign/campaign/components/CampaignForm/CampaignFormContainer';
import { createAndEditStrategy } from 'platform/campaign/strategy/utils/strategy.util';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { useFeature } from 'platform/common/hooks/useFeature';

const CampaignRoutes = () => {
    const showConfirmationModal = useConfirmationModal();
    const dispatch = useDispatch();
    const returnLocationQuery = useSelector(authSelectors.returnLocationQuery);
    const canEdit = useFeature('CAMPAIGNS_EDIT');

    const suggestNewStrategy = (campaign: Campaign) =>
        showConfirmationModal(() => createAndEditStrategy(dispatch, campaign.id, campaign.type), {
            title: 'Create strategy',
            text: `Would you like to create a strategy for campaign "${campaign.name}"?`,
            okLabel: 'Yes',
            cancelLabel: 'No',
            onCancel: () => {
                if (returnLocationQuery) {
                    dispatch(push(returnLocationQuery));
                }
            },
        });

    return (
        <Routes>
            <Route
                path="new"
                element={
                    <CampaignFormContainer
                        canEdit={canEdit}
                        redirectTo={returnLocationQuery}
                        redirectAfterSubmit={false}
                        afterSubmit={suggestNewStrategy}
                    />
                }
            />
            <Route
                path=":id"
                element={<CampaignFormContainer redirectTo={returnLocationQuery} canEdit={canEdit} />}
            />
        </Routes>
    );
};

export default CampaignRoutes;
