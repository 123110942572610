import { useSelector } from 'react-redux';
import { ColumnDefinition, ColumnGroup, Template } from 'platform/analytics/analytics.types';
import { getColumnDefinitionOptions, isOlapReport } from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { usePromise } from 'platform/common/hooks/usePromise';
import { getCompatibleColumns } from '../analytics.service';

export const getCompatibleOptions = (
    columnDefinitions: ColumnDefinition[],
    columnGroups: ColumnGroup[],
    compatibleColumns: string[],
    selectedColumns: string[],
    useCompatibleColumns: boolean
) => {
    const columns = useCompatibleColumns
        ? columnDefinitions.filter(({ key }) =>
              compatibleColumns.length ? compatibleColumns.includes(key) : selectedColumns.includes(key)
          )
        : columnDefinitions;

    return getColumnDefinitionOptions(columns, columnGroups);
};

interface Props {
    template: Template;
    columns: string[];
}

export const useCompatibleColumns = ({ template, columns }: Props) => {
    const filters = useSelector(analyticsSelectors.filtersWithAdvertiser);
    const { columnGroups } = useAnalyticsMetadata();

    const [{ data: compatibleColumns, loading }] = usePromise(
        [],
        () =>
            isOlapReport(template)
                ? getCompatibleColumns(template.id, [...columns].sort(), filters)
                : Promise.resolve([]),
        [columns.length]
    );

    return {
        compatibleColumns,
        dimensionOptions: getCompatibleOptions(
            template.dimensions,
            columnGroups,
            compatibleColumns,
            columns,
            isOlapReport(template)
        ),
        metricOptions: getCompatibleOptions(
            template.metrics,
            columnGroups,
            compatibleColumns,
            columns,
            isOlapReport(template)
        ),
        compatibleColumnsLoading: loading,
    };
};
