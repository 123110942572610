import { Badge } from 'reactstrap';
import { uniq } from 'lodash-es';
import { Vendor } from 'platform/vendors/vendors.types';

interface Props {
    label: string;
    color: string;
    snippets: { vendorId?: number }[];
    vendors: Vendor[];
}

const VendorBadges = ({ label, color, snippets, vendors }: Props) => {
    if (!snippets.length) {
        return null;
    }

    return (
        <div className="mt-1">
            <small>{label}:</small>
            {uniq(snippets.map((s) => s.vendorId))
                .map((vendorId) => vendors.find((v) => v.id === vendorId))
                .map((vendor) => (
                    <Badge color={color} className="ms-1">
                        {vendor?.name}
                    </Badge>
                ))}
        </div>
    );
};

export default VendorBadges;
