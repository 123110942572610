import { CSSProperties } from 'react';
import './FilterRemoveIcon.scss';

interface Props {
    onClick: () => void;
    style?: CSSProperties;
}

const FilterRemoveIcon = ({ style, onClick }: Props) => (
    <div className="FilterRemoveIcon" style={style}>
        <i className="fas fa-xmark" role="button" tabIndex={0} onClick={onClick} />
    </div>
);

export default FilterRemoveIcon;
