import { useLocation } from 'react-router-dom';
import { DefaultMetrics } from 'platform/campaign/advertiserManagement/DefaultMetrics/defaultMetrics.types';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { parseQuery } from 'platform/common/utils/url.util';
import DefaultMetricsForm from './DefaultMetricsForm';
import { getDefaultMetrics, storeDefaultMetrics } from './defaultMetrics.service';

type Props = {
    redirectTo: string;
    afterSubmit: () => void;
};

const NO_DEFAULT_METRICS: DefaultMetrics = {
    tableMetrics: [],
    summaryBarFirstRowMetrics: [],
    summaryBarSecondRowMetrics: [],
};

const DefaultMetricsFormContainer = ({ redirectTo, afterSubmit }: Props) => {
    const location = useLocation();
    const query = parseQuery(location.search);

    return (
        <FormContainer
            helpKey="default_metrics_form"
            onOpen={async () => (await getDefaultMetrics(query.advertiserId)) || NO_DEFAULT_METRICS}
            onSubmit={(data) => storeDefaultMetrics(query.advertiserId, data)}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            sidePanel
            isEdit
        >
            {(props) => <DefaultMetricsForm {...props} />}
        </FormContainer>
    );
};

export default DefaultMetricsFormContainer;
