import { ReactNode } from 'react';
import { Badge, ListGroupItem as ReactListGroupItem } from 'reactstrap';
import classNames from 'classnames';
import './ListGroupItem.scss';

interface Props {
    id: string;
    active: boolean;
    onClick: () => void;
    label: string;
    index: number;
    children?: ReactNode;
    disabled?: boolean;
    className?: string;
}

const ListGroupItem = ({ id, active, onClick, label, index, children, disabled, className }: Props) => {
    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <ReactListGroupItem
            className={classNames(
                'ListGroupItem',
                {
                    'ListGroupItem--disabled': disabled,
                },
                className
            )}
            onClick={disabled || active ? undefined : handleOnClick}
            active={active}
            tag="li"
        >
            <Badge color="secondary" pill>
                {index !== undefined && index + 1}
            </Badge>
            <span className="ListGroupItem-label">{label}</span>
            {children}
        </ReactListGroupItem>
    );
};

export default ListGroupItem;
