import { Badge } from 'reactstrap';
import { isEmpty } from 'lodash-es';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { ExternalSystem, getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import CreativeLabelSelect from 'platform/creatives/components/CreativeBlocks/CreativeLabelSelect';
import { getCreativeExternalTypeName } from 'platform/creatives/constants/creativeTypes';
import { ImportedCreativeModel } from 'platform/creatives/mappers/creative/commonCreative.mapper';
import { CREATIVE_FORM_HELP_KEY } from '../CreativeForm/CreativeFormContainer';

type Props = {
    creative: ImportedCreativeModel;
    externalSystem: ExternalSystem;
    field: string;
};

const ImportedCreativeFields = ({ creative, externalSystem, field }: Props) => (
    <>
        {creative.id && (
            <FormRow helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_id`} label="Internal ID">
                {creative.id}
            </FormRow>
        )}
        <FormRow label="Ad format" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_id`}>
            {getCreativeExternalTypeName(creative.externalType)}
        </FormRow>
        {!isEmpty(creative.externalLabels) && (
            <FormRow label={`${getIntegrationLabel(externalSystem)} label`}>
                {creative.externalLabels?.sort().map((label) => (
                    <Badge color="primary" className="badge-outline me-2">
                        {label}
                    </Badge>
                ))}
            </FormRow>
        )}
        <FormRow label="Creative label" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_labelId`}>
            <CreativeLabelSelect name={`${field}.creativeLabelId`} advertiserId={creative.advertiserId} />
        </FormRow>
    </>
);

export default ImportedCreativeFields;
