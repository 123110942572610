import { ADVERTISER_TAG } from './advertiserTag.navigation';
import AdvertiserTagList from './components/AdvertiserTagList/AdvertiserTagList';

const advertiserTagRoutes = [
    {
        ...ADVERTISER_TAG,
        component: AdvertiserTagList,
    },
];

export default advertiserTagRoutes;
