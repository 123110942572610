import { ReactNode } from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';
import classNames from 'classnames';
import { EditorConfiguration } from 'codemirror';
import { isString, useField } from 'formik';
import CodeMirrorSrc from 'platform/common/components/CodeMirror/CodeMirrorSource';
import { Validator, validator } from 'platform/common/utils/validators.util';

interface Props {
    name: string;
    caption?: ReactNode;
    options: EditorConfiguration;
    validate?: Validator | Validator[];
}

const FormCodeEditor = ({ validate, name, options, caption }: Props) => {
    const [{ value }, { touched, error }, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const invalid = Boolean(touched && error);

    return (
        <>
            <FormGroup>
                <CodeMirrorSrc
                    key={name}
                    value={value}
                    className={classNames('form-control', { 'is-invalid': invalid })}
                    options={options}
                    onChange={(codeVal: string) => helpers.setValue(codeVal)}
                />
                {caption && <small className="text-muted">{caption}</small>}
            </FormGroup>
            {Boolean(touched && invalid) && isString(error) ? (
                <FormFeedback className="d-block">{error}</FormFeedback>
            ) : null}
        </>
    );
};

export default FormCodeEditor;
