import { ReactNode } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { arrayItemRemove } from 'platform/common/utils/array.util';

type OldOrNew<T> = T & { __isNew__?: boolean };

type Props<T> = {
    items: OldOrNew<T>[];
    newItem: OldOrNew<T>;
    onChange: (items: OldOrNew<T>[]) => void;
    className?: string;
    disabled?: boolean;
    children: (item: OldOrNew<T>, index: number) => ReactNode;
};

const DynamicList = <T,>({ items, newItem, onChange, className, disabled, children }: Props<T>) => (
    <div className={className}>
        {items.map((item, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
                <div className="flex-grow-1">{children(item, index)}</div>
                <IconButton
                    title="Remove"
                    onClick={() => onChange(arrayItemRemove(items, index))}
                    className={classNames('ms-3', { 'opacity-50': disabled })}
                    disabled={disabled}
                >
                    <i className="far fa-trash-can text-muted" />
                </IconButton>
            </div>
        ))}
        <Button
            className="mt-2"
            color="secondary"
            onClick={() => onChange([...items, { ...newItem, __isNew__: true }])}
            disabled={disabled}
        >
            Add new
        </Button>
    </div>
);

export default DynamicList;
