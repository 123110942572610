import { useSelector } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { OPT_IN_EFFECT_OPTIONS } from 'platform/analytics/analytics.constants';
import { MetricDefinition } from 'platform/analytics/analytics.types';
import { getColumnDefinitionOptions } from 'platform/analytics/analytics.util';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { getCampaignClassificationOptions } from 'platform/campaign/campaign/services/campaignClassification.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SortableList from 'platform/common/components/SortableList/SortableList';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { arrayItemMove } from 'platform/common/utils/array.util';
import { randomHash } from 'platform/common/utils/number.util';
import { makeOptions } from 'platform/common/utils/option.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import CustomMetricRuleEditor from './CustomMetricRuleEditor';
import {
    CUSTOM_METRIC_FORMAT_LABELS,
    CustomMetricFormModel,
    CustomMetricFormRule,
} from './customMetric.types';
import { tokenizeFormula } from './customMetric.util';

const newRule = (): CustomMetricFormRule => ({
    key: randomHash(),
    conditions: [],
    expression: '',
});

const hasOptInEffect = (rule: CustomMetricFormRule, metrics: MetricDefinition[]) =>
    tokenizeFormula(rule.expression, { metrics }).some((token) => token.metric?.optInEffect);

const CustomMetricForm = ({
    advertiserId,
    formikProps: {
        values: { key, optInEffect, rules },
        setFieldValue,
        submitForm,
    },
    canEdit,
    onCancel,
}: FormProps<CustomMetricFormModel> & {
    advertiserId: number;
}) => {
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);
    const [{ data: conditionOptions, loading }] = usePromise(
        [],
        () => getCampaignClassificationOptions(advertiserId, commonClassifiers),
        [advertiserId, commonClassifiers]
    );

    const { definitions, columnGroups } = useAnalyticsMetadata();
    const metricOptions = getColumnDefinitionOptions(definitions.metrics, columnGroups);
    const hasActionRules = rules.some((rule) => hasOptInEffect(rule, definitions.metrics));

    const handleRuleChange = (rule: CustomMetricFormRule) =>
        setFieldValue(
            'rules',
            rules.map((r) => (r.key === rule.key ? rule : r))
        );

    const handleDrop = (moveFrom: CustomMetricFormRule, moveTo: CustomMetricFormRule) => {
        const indexFrom = rules.findIndex((r) => r.key === moveFrom.key);
        const indexTo = rules.findIndex((r) => r.key === moveTo.key);
        setFieldValue('rules', arrayItemMove(rules, indexFrom, indexTo));
    };

    const handleRemove = (rule: CustomMetricFormRule) => {
        setFieldValue(
            'rules',
            rules.filter((r) => r.key !== rule.key)
        );
    };

    return (
        <CardForm
            title="Custom metric"
            subtitle={`Key: ${key}`}
            submitLabel="Save"
            disabled={!canEdit}
            onCancel={onCancel}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Description">
                    <FormInput name="description" type="textarea" rows={2} />
                </FormRow>
                <FormRow label="Data format">
                    <FormSelect
                        name="format"
                        options={makeOptions(CUSTOM_METRIC_FORMAT_LABELS)}
                        isSearchable={false}
                        isClearable={false}
                    />
                </FormRow>
                {(hasActionRules || optInEffect) && (
                    <>
                        <FormRow label="Opt-In Modelling">
                            <FormSelect
                                name="optInEffect"
                                options={OPT_IN_EFFECT_OPTIONS}
                                getOptionLabel={(o) => (
                                    <div className="d-flex align-items-center">
                                        <i className={`me-2 font-lg ${o.iconClass}`} /> {o.label}
                                    </div>
                                )}
                                isSearchable={false}
                                isClearable={false}
                            />
                            {hasActionRules && !optInEffect && (
                                <div className="text-bg-warning rounded mt-2 p-2">
                                    <i className="fal fa-info-circle me-2" />
                                    The formula contains action-based metrics which may need Opt-In Modelling.
                                </div>
                            )}
                        </FormRow>
                    </>
                )}
            </ControlledCard>
            <ControlledCard title="Formula" subtitle="required">
                <Alert color="dark">
                    The <strong>first</strong> matching condition will be used
                </Alert>
                {loading ? (
                    <OverlayLoader />
                ) : (
                    <>
                        <SortableList
                            items={rules}
                            getKey={(rule) => rule.key}
                            dragKey="rules"
                            className="mt-3"
                            itemClassName="ps-3 mb-3 border bg-secondary"
                            onDrop={handleDrop}
                            onRemove={handleRemove}
                        >
                            {(rule, index) => (
                                <CustomMetricRuleEditor
                                    field={`rules[${index}]`}
                                    rule={rule}
                                    conditionOptions={conditionOptions}
                                    metrics={definitions.metrics}
                                    metricOptions={metricOptions}
                                    onChange={handleRuleChange}
                                />
                            )}
                        </SortableList>
                        <Button
                            color="secondary"
                            onClick={() => setFieldValue('rules', [...rules, newRule()])}
                        >
                            Add case
                        </Button>
                    </>
                )}
            </ControlledCard>
        </CardForm>
    );
};

export default CustomMetricForm;
