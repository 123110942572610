import classNames from 'classnames';
import { ReportFilter, ReportFilterValue } from 'platform/analytics/analytics.types';
import Select from 'platform/common/components/Select/Select';
import { useReportFilterOptions } from '../../ReportFilters/useReportFilterOptions';
import ReportInteractionContainer from './ReportInteractionContainer';

interface Props {
    value?: string[];
    onChange: (value?: ReportFilterValue[]) => void;
    filter: string;
    required: boolean;
}

const ReportInteractionFilter = ({ filter, value, onChange, required }: Props) => {
    const repportFilter: ReportFilter = { key: filter, values: value ?? [], operator: 'IS' };
    const { filterOptionsMap, filtersLoading, updateFilters } = useReportFilterOptions({
        reportFilters: [repportFilter],
        onFiltersChange: (filters) => onChange(filters[0].values),
    });

    return (
        <ReportInteractionContainer required={required} isValueEmpty={!value?.length}>
            {(setTouched, invalid) => (
                <Select
                    className={classNames({ 'form-control is-invalid p-0': invalid })}
                    collapseMoreValues
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    value={value}
                    isLoading={filtersLoading}
                    options={filterOptionsMap?.[filter]?.options ?? []}
                    onChange={(newValue) => updateFilters([{ ...repportFilter, values: newValue }])}
                    onBlur={() => setTouched(true)}
                    isMulti
                />
            )}
        </ReportInteractionContainer>
    );
};

export default ReportInteractionFilter;
