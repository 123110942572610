import { gql } from '@apollo/client';
import moment from 'moment';
import { Segment } from 'platform/advertiserTag/advertiserTag.types';
import { fetchAdvertiserTags } from 'platform/advertiserTag/services/advertiserTag.service';
import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import axiosBff from 'platform/axios/axiosBff';
import { IdResponse } from 'platform/common/common.types';
import client from 'platform/common/utils/createApolloClient.util';
import SegmentChartQuery from 'platform/segments/components/SegmentCharts/SegmentChartQuery.graphql';
import { SegmentFormAdvertiser } from 'platform/segments/components/SegmentForm/SegmentForm.types';
import { ChartScale } from 'platform/segments/constants/chart.constants';
import { maskUrls } from 'platform/segments/mappers/segment.mapper';
import segmentSimilarityQuery from 'platform/segments/queries/segmentSimilarityChartQuery.graphql';
import { SegmentSimilarity, StandardEvent } from 'platform/segments/segments.types';

const maskSegmentName = (segment: Segment) =>
    ['CUSTOM_EVENT', 'URL_VISIT', 'TRIGGER'].includes(segment.type) ? `Segment ${segment.id}` : segment.name;

export const fetchSegmentList = (params: { advertiserId: number }, isDemoMode: boolean) =>
    axiosBff.get<Segment[]>('/segmentation/api/segments', { params }).then((res) =>
        res.data.map((segment) => {
            if (!isDemoMode) {
                return segment;
            }

            return {
                ...segment,
                name: maskSegmentName(segment),
                urls: maskUrls(segment.urls),
            };
        })
    );

const fetchTimeToConversions = (params: { advertiserId: number; segmentId?: number }) =>
    client
        .query<{
            conversionAtTimePoints: {
                convertedUsers: number;
                percentFromAllConversions: number;
                timeScale: ChartScale;
                timeValue: number;
            }[];
        }>({
            query: gql`
                query ConversionAtTimePoints($advertiserId: Int!, $segmentId: Int) {
                    conversionAtTimePoints(advertiserId: $advertiserId, segmentId: $segmentId) {
                        convertedUsers
                        percentFromAllConversions
                        timeScale
                        timeValue
                    }
                }
            `,
            variables: params,
            fetchPolicy: 'network-only',
        })
        .then((res) => res.data.conversionAtTimePoints);

export const getTimeToConversion = (advertiserId: number, segmentId?: number) =>
    fetchTimeToConversions({
        advertiserId,
        segmentId,
    }).then((data) => [{ key: 'ALL', data }]);

export const getSegment = (id: number, isDemoMode: boolean): Promise<Segment> =>
    axiosBff.get<Segment>(`/segmentation/api/segments/${id}`).then(({ data }) => ({
        ...data,
        urls: isDemoMode ? maskUrls(data.urls) : data.urls,
        name:
            isDemoMode && ['CUSTOM_EVENT', 'URL_VISIT', 'TRIGGER'].includes(data.type)
                ? `Segment ${data.id}`
                : data.name,
    }));

export const getStandardEvents = (advertiserTagId: number) =>
    axiosBff
        .get<StandardEvent[]>('/segmentation/api/standard-events', { params: { advertiserTagId } })
        .then((res) => res.data);

export const updateSegment = (segment: Partial<Segment>) =>
    axiosBff.put<IdResponse>('/segmentation/api/segments', segment).then((res) => res.data);

export const createSegment = (segment: Partial<Segment>) =>
    axiosBff.post<IdResponse>('/segmentation/api/segments', segment).then((res) => res.data);

export const changeSegmentState = (segmentId: number, archived: any) =>
    axiosBff.patch(`/segmentation/api/segments/${segmentId}/archive`, {
        archived,
    });

export const resyncIntegration = (segmentId: number, externalSystem: any) =>
    axiosBff.patch(`/segmentation/api/segments/${segmentId}/external-system-integrations`, {
        externalSystem,
    });

export const fetchSegmentAdvertiser = async (advertiserId: number): Promise<SegmentFormAdvertiser> => {
    const advertiser = await fetchAdvertiser(advertiserId);
    const advertiserTag = await fetchAdvertiserTags({ advertiserId, state: 'ACTIVE' });
    return {
        ...advertiser,
        advertiserTagId: advertiserTag[0].id,
    };
};

export const fetchSegmentChartData = (advertiserId: number) =>
    client
        .query({
            query: SegmentChartQuery,
            variables: {
                advertiserId,
                period: {
                    from: moment()
                        .subtract(30 - 1, 'd')
                        .format('YYYY-MM-DD'),
                },
                visitFrequency: true,
                visitPages: true,
                visitTime: true,
            },
            fetchPolicy: 'network-only',
        })
        .then((response) => ({
            ...response.data,
            allUniqueUsers: response.data.allUniqueUsers.stats.advertiser_unique_users_30d.value,
        }));

export const fetchSimilarity = (
    id: number,
    advertiserIds: number[],
    advertiserParentId?: number
): Promise<SegmentSimilarity[]> =>
    client
        .query({
            query: segmentSimilarityQuery,
            variables: {
                id,
                advertiserIds,
                advertiserParentId,
            },
            fetchPolicy: 'network-only',
        })
        .then((response) => response.data.segmentSimilarities);
