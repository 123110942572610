import { gql } from '@apollo/client';
import {
    ChatOlapColumn,
    ChatOlapColumnAllowedValues,
    ChatOlapColumns,
} from 'platform/chat/olap/chat.olap.types';
import client, { throwOnError } from 'platform/common/utils/createApolloClient.util';

export const fetchChatOlapMetadata = async (advertiserId: number): Promise<ChatOlapColumns> =>
    client
        .query<{
            chatOlapColumns: ChatOlapColumns;
        }>({
            query: gql`
                query ChatOlapMetadataQuery($advertiserId: Int) {
                    chatOlapColumns(advertiserId: $advertiserId) {
                        dimensions {
                            key
                            name
                            dataType
                            format
                            allowedOperators
                        }
                        metrics {
                            key
                            name
                            dataType
                            format
                            allowedOperators
                        }
                    }
                }
            `,
            fetchPolicy: 'no-cache',
            context: { errorHandledByComponent: true },
            variables: { advertiserId },
        })
        .then(throwOnError)
        .then(({ data: { chatOlapColumns } }) => chatOlapColumns);

export const rowsToString = (columns: ChatOlapColumn[] | ChatOlapColumnAllowedValues[]) => {
    const rows = columns.map((d) => JSON.stringify(d));
    return `${rows.join(',\n')}\n`;
};

export const fetchChatOlapColumns = async (
    advertiserId: number,
    extractData: (meta: ChatOlapColumns) => ChatOlapColumn[]
) => {
    const meta = await fetchChatOlapMetadata(advertiserId);
    return Promise.resolve(rowsToString(extractData(meta)));
};
