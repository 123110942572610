import { useContext, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { ClassificationOptionsContext } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableForm/ClassificationOptionsContext';
import { OptionType } from 'platform/common/components/Select/select.types';
import FormCreatableSelect, {
    FormCreatableSelectProps,
} from 'platform/formik/FormCreatableSelect/FormCreatableSelect';

type Props = Omit<FormCreatableSelectProps, 'options' | 'onNewOption'>;

const FormFlightLabelSelect = (props: Props) => {
    const [{ flightLabels }, loading] = useContext(ClassificationOptionsContext);
    const [newLabel, setNewLabel] = useState<OptionType>({});

    return (
        <FormCreatableSelect
            {...props}
            returnOnlyValues={false}
            options={!isEmpty(newLabel) ? [...flightLabels, newLabel] : flightLabels}
            isLoading={loading}
            onNewOption={setNewLabel}
            formatCreateLabel={(value) => (
                <span>
                    <b>Create label</b>
                    {` "${value}"`}
                </span>
            )}
            placeholder="Select or enter..."
        />
    );
};

export default FormFlightLabelSelect;
