interface Props {
    width?: number;
    height?: number;
}

const WordcloudSvg = ({ width = 24, height = 24 }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 29" fill="none">
        <rect x="2.17578" y="3.5" width="21" height="8" rx="2.5" strokeWidth="1" />
        <rect x="12.1758" y="16.5" width="11" height="8" rx="2.5" strokeWidth="1" />
        <rect x="2.17578" y="16.5" width="5" height="8" rx="2.5" fill="#1083D6" strokeWidth="1" />
    </svg>
);

export default WordcloudSvg;
