import { sortByName } from 'platform/common/utils/array.util';
import axiosBff from '../axios/axiosBff';
import { Vendor, VendorCommission } from './vendors.types';

export const fetchVendor = (id: number) =>
    axiosBff.get<Vendor>(`/admin/api/vendors/${id}`).then((res) => res.data);

export const updateVendor = (id: number, model: Vendor) => axiosBff.put(`/admin/api/vendors/${id}`, model);

export const createVendor = (model: Vendor) =>
    axiosBff.post<Vendor>('/admin/api/vendors', model).then((res) => res.data);

export const fetchVendors = (params?: { clickIdEnabled?: boolean }) =>
    axiosBff.get<Vendor[]>('/admin/api/vendors', { params }).then((res) => sortByName(res.data));

export const fetchVendorCommissions = (id: number) =>
    axiosBff.get<VendorCommission[]>(`/admin/api/vendors/${id}/commissions`).then((res) => res.data);

export const createVendorCommissions = (id: number, model: VendorCommission[]) =>
    axiosBff.put(`/admin/api/vendors/${id}/commissions`, model).then((res) => res.data);
