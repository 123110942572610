import { arrayItemInsert, moveById, removeById, updateById } from 'platform/common/utils/array.util';
import { Task, TaskBoardChange } from '../task.types';

export const subtaskReducer = (subtasks: Task[], c: TaskBoardChange): Task[] => {
    switch (c.type) {
        case 'TASK_ADDED':
            return arrayItemInsert(subtasks, { id: c.taskId, ...c.details.data }, c.details.position);
        case 'TASK_UPDATED':
            return updateById(subtasks, c.taskId, (subtask) => ({ ...subtask, ...c.details?.data }));
        case 'TASK_REORDERED':
            return moveById(subtasks, c.taskId, c.details.position);
        case 'TASK_DELETED':
            return removeById(subtasks, c.taskId);
        default:
            return subtasks;
    }
};
