import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { SYSTEM_PROMPT_KEYS } from './chat.constants';

export interface ChatMessagePayload {
    agentId?: number;
    messages: ChatMessage[];
    placeholders?: Record<string, string>;
}

export interface ChatMessage {
    role: ChatRole;
    contentParts: ChatContentParts[];
    error?: DeeplyChatError;
    prompt?: MessagePrompt;
    fileName?: string;
}

export interface ChatContentParts {
    type: 'TEXT' | 'FILE';
    mimeType?: string;
    content: string;
    fileName?: string;
}

export interface MessagePrompt {
    id: number;
    name: string;
    type: PromptType;
    splitFilePrompt?: boolean;
    forceFileUpload?: boolean;
}

export type ChatAssistantMessageType = 'DEFAULT' | 'QUESTIONS' | 'OLAP_REQUEST' | 'DISPLAY_ISSUE';

type ChatRole = 'user' | 'assistant' | 'system';

export interface DeeplyChatResponse {
    message: string;
    latestDeletedMessageIdx?: number;
}

export interface DeeplyChatAsyncResponse {
    done: boolean;
    message: string;
    latestDeletedMessageIdx?: number;
}

export interface DeeplyChatAsyncRequest {
    id: number;
}

export interface DeeplyChatError {
    code?: string;
    message: string;
    param?: string;
    status?: number;
    type?: string;
}

type ModelEndpointType =
    | 'GPT_35'
    | 'GPT_35_16K'
    | 'GPT_4'
    | 'GPT_4_32K'
    | 'GPT_4_TURBO'
    | 'GPT_4_OMNI'
    | 'GEMINI_V1';

export const MODEL_ENDPOINT_TYPE_LABELS: Record<ModelEndpointType, string> = {
    GPT_35: 'GPT 3.5',
    GPT_35_16K: 'GPT 3.5 - 16k',
    GPT_4: 'GPT 4',
    GPT_4_32K: 'GPT 4 - 32k',
    GPT_4_TURBO: 'GPT 4 Turbo',
    GPT_4_OMNI: 'GPT 4o',
    GEMINI_V1: 'Gemini',
};

export interface ModelEndpoint {
    id: number;
    status: ActiveOrArchived;
    name: string;
    description: string;
    endpoint?: string;
    apiKey?: string;
    modelType: ModelEndpointType;
    maxInputTokens: number;
    settings?: string;
    updatedBy: string;
    createdOn: string;
    updatedOn: string;
}

export type ModelEndpointFormModel = Omit<
    ModelEndpoint,
    'id' | 'createdOn' | 'updatedOn' | 'updatedBy' | 'maxInputTokens'
>;

export interface ChatAgent {
    id: number;
    order: number;
    name: string;
    status: ActiveOrArchived;
    settings: string;
    systemMessage?: string;
    modelId: number | null;
    advertiserIds?: number[];
    iconUrl?: string;
    message?: string;
    blacklistedWords?: string;
    description: string;
    defaultAgent: boolean;
    adminOnly: boolean;
    systemOnly: boolean;
    betaTesting: boolean;
    requiresAdvertiser: boolean;
    personalPrompts: boolean;
    showPromptsInWelcomeMessage: boolean;
    updatedBy: string;
    createdOn: string;
    updatedOn: string;
    supportedFileTypes?: string[];
}

export type ChatAgentFormModel = Omit<ChatAgent, 'id' | 'createdOn' | 'updatedOn' | 'updatedBy'>;

export interface PredefinedPrompt {
    id: number;
    order: number;
    name: string;
    description?: string;
    prompt: string;
    agentId: number | null;
    status: ActiveOrArchived;
    updatedBy: string;
    createdOn: string;
    updatedOn: string;
    adminOnly: boolean;
    forceLanguage: boolean;
    forceFileUpload: boolean;
    splitFilePrompt: boolean;
    blacklistedWords?: string;
}

export interface PersonalPrompt {
    id?: number;
    name: string;
    prompt: string;
    agentId: number;
}

export type PredefinedPromptFormModel = Omit<
    PredefinedPrompt,
    'id' | 'createdOn' | 'updatedOn' | 'updatedBy'
>;

export interface GetPredefinedPromptsParams {
    status: ActiveOrArchived[];
}

export interface ContextPrompt {
    id: number;
    name: string;
    order: number;
    prompt: string;
    status: ActiveOrArchived;
    updatedBy: string;
    createdOn: string;
    updatedOn: string;
}

export type ContextPromptFormModel = Omit<ContextPrompt, 'id' | 'createdOn' | 'updatedOn' | 'updatedBy'>;

export interface SystemPrompt {
    id: number;
    name: string;
    key: typeof SYSTEM_PROMPT_KEYS[number] | string;
    prompt: string;
    status: ActiveOrArchived;
    updatedBy: string;
    createdOn: string;
    updatedOn: string;
}

export type SystemPromptFormModel = Omit<SystemPrompt, 'id' | 'createdOn' | 'updatedOn' | 'updatedBy'>;

export type ChatLanguage = 'language_auto' | 'language_en' | 'language_de';

export type SubmitFunc = (
    submittedMessages: {
        text: string;
        prompt?: MessagePrompt;
        fileName?: string;
        languageKey?: ChatLanguage;
        fileType?: string;
    }[]
) => void;

type PromptType = 'PREDEFINED' | 'CONTEXT' | 'PERSONAL' | 'SYSTEM';

export interface MessageFeedbackPayload {
    promptId: number;
    type: FeedbackType;
    note?: string;
}

export type FeedbackType = 'GOOD' | 'BAD';
