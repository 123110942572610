import { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import classNames from 'classnames';
import { uniqueId } from 'lodash-es';
import './RadioList.scss';

type ValueType = string | number | boolean;

interface Props {
    options: {
        label?: string;
        value: ValueType;
        children?: Function;
        icon?: string;
        disabled?: boolean;
    }[];
    value: ValueType;
    name: string;
    tag?: string;
    radioListGroupClassName?: string;
    className?: string;
    disabled?: boolean;
    onRadioChange: (value: ValueType) => void;
    label?: string;
    optionsClassName?: string;
}

const RadioList = ({
    value,
    onRadioChange,
    options,
    name,
    className,
    disabled = false,
    radioListGroupClassName,
    label,
    optionsClassName,
}: Props) => {
    const [stateValue, setStateValue] = useState<ValueType>(value);

    useEffect(() => {
        setStateValue(value);
    }, [value]);

    const onChange = (newValue: ValueType) => () => {
        setStateValue(newValue);
        onRadioChange(newValue);
    };

    const isChecked = (newValue: ValueType) => stateValue === newValue;
    return (
        <div className={classNames('RadioList', className)}>
            {label && (
                <div>
                    <Label className="m-0 mb-1">{label}</Label>
                </div>
            )}
            <div className={optionsClassName}>
                {options.map((option) => {
                    const key = uniqueId(option.label);
                    const checked = isChecked(option.value);
                    return (
                        <FormGroup
                            key={key}
                            check
                            className={classNames(
                                'RadioList-group',
                                { 'RadioList-group--active': checked },
                                radioListGroupClassName
                            )}
                        >
                            <Label className="d-flex align-items-center me-4" check for={key}>
                                <Input
                                    onChange={onChange(option.value)}
                                    type="radio"
                                    id={key}
                                    name={name}
                                    value={option.value as any}
                                    className="me-2"
                                    checked={checked}
                                    disabled={disabled || option?.disabled}
                                />
                                {option.icon && <i className={`RadioList-icon ${option.icon}`} />}
                                {option.label && (
                                    <span
                                        className={classNames(
                                            'RadioList-label',
                                            option?.disabled ? 'text-muted' : ''
                                        )}
                                    >
                                        {option.label}
                                    </span>
                                )}
                            </Label>
                            {stateValue === option.value && option.children && <option.children />}
                        </FormGroup>
                    );
                })}
            </div>
        </div>
    );
};

export default RadioList;
