import { useParams } from 'react-router-dom';
import { getContextPrompt, updateContextPrompt, createContextPrompt } from 'platform/chat/chat.service';
import { ContextPromptFormModel } from 'platform/chat/chat.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import ContextPromptForm from './ContextPromptForm';

const ContextPromptFormContainer = ({ canEdit, afterSubmit, redirectTo }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = !!params.id && Number(params.id);

    const handleOpen = (): Promise<ContextPromptFormModel> => {
        if (id) {
            return getContextPrompt(id);
        }

        return Promise.resolve({
            name: '',
            order: 0,
            prompt: '',
            status: 'ACTIVE',
        });
    };

    const handleSubmit = (formValues: ContextPromptFormModel) => {
        if (id) {
            return updateContextPrompt(id, formValues);
        }

        return createContextPrompt(formValues);
    };

    return (
        <FormContainer
            helpKey="context_prompt_form"
            showEditWarning={false}
            isEdit={!!id}
            sidePanel
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            canEdit={canEdit}
            redirectTo={redirectTo}
            onOpen={handleOpen}
        >
            {(props) => <ContextPromptForm {...props} />}
        </FormContainer>
    );
};

export default ContextPromptFormContainer;
