import { Fireworks as FireworksJs } from '@fireworks-js/react';

const Fireworks = () => (
    <FireworksJs
        options={{
            acceleration: 1.01,
            gravity: 0.65,
            intensity: 5,
            particles: 100,
            flickering: 100,
            traceSpeed: 5,
            lineWidth: {
                explosion: { min: 2, max: 5 },
                trace: { min: 1, max: 2 },
            },
            rocketsPoint: { min: 30, max: 40 },
            decay: { min: 0.015, max: 0.03 },
            delay: { min: 20, max: 60 },
            mouse: { click: true },
        }}
        className="position-fixed w-100 h-100"
    />
);

export default Fireworks;
