import { syncRequestTypeLabel } from 'platform/adminPanel/components/SyncJournal/syncJournal.constant';
import unitService from 'platform/campaign/unit/services/units.service';
import { isDefined } from 'platform/common/common.types';
import DspIntegrationIcon from 'platform/common/components/DspIntegrationIcon/DspIntegrationIcon';
import { IntegrationSummary } from 'platform/common/components/IntegrationDetailsIcon/IntegrationDetailsIcon';
import { formatDateTime } from 'platform/common/utils/date.util';

type UnitIntegrationSummary = IntegrationSummary & {
    unitId: number;
};

export const UnitIntegrationIcon = ({ integration }: { integration: UnitIntegrationSummary }) => {
    const { unitId, externalSystem, externalId, synchronizationState } = integration;
    const { message, status, warnings, lastSucceeded, lastFailed, failedRequestTypes } = synchronizationState;
    const detailedStats = [
        lastSucceeded ? `Last successful sync: ${formatDateTime(lastSucceeded)}` : undefined,
        lastFailed ? `Last failed sync: ${formatDateTime(lastFailed)}` : undefined,
        failedRequestTypes?.length
            ? `What failed: ${failedRequestTypes.map(syncRequestTypeLabel).join(', ')}`
            : undefined,
    ];
    return (
        <DspIntegrationIcon
            status={status}
            enabled={status !== 'NEVER' || !!externalId}
            integrationMessage={message}
            systemName={externalSystem}
            subLabel={detailedStats.filter(isDefined).join('\n')}
            internalId={unitId}
            externalId={externalId}
            syncEntityType={'UNIT'}
            onResync={() => unitService.syncUnit(unitId)}
            warning={warnings?.length ? warnings.join('\n\n') : undefined}
        />
    );
};
