import { Category } from 'platform/category/category.types';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import SegmentDetailsFunnel from 'platform/segments/components/SegmentFunnel/SegmentDetailsFunnel';
import SegmentOverviewChart from 'platform/segments/components/SegmentOverviewChart/SegmentOverviewChart';
import SegmentOverviewDeviceChart from 'platform/segments/components/SegmentOverviewDeviceChart/SegmentOverviewDeviceChart';
import SegmentSummary from 'platform/segments/components/SegmentSummary/SegmentSummary';
import SegmentSyncPanel from 'platform/segments/components/SegmentSyncPanel/SegmentSyncPanel';

type Props = {
    segmentId: number;
    categories: Category[];
};

const SegmentOverview = ({ segmentId, categories }: Props) => (
    <>
        <InsetBox className="mb-4">
            <SegmentSummary id={segmentId} categories={categories} />
        </InsetBox>
        <InsetBox className="mb-4 p-3">
            <SegmentDetailsFunnel segmentId={segmentId} />
        </InsetBox>
        <InsetBox className="mb-4 pt-3 pe-3 pb-1 ps-3">
            <SegmentOverviewChart segmentId={segmentId} />
        </InsetBox>
        <InsetBox className="mb-4 pt-3 pe-3 pb-1 ps-3">
            <SegmentOverviewDeviceChart segmentId={segmentId} />
        </InsetBox>
        <InsetBox className="mb-4">
            <SegmentSyncPanel segmentId={segmentId} />
        </InsetBox>
    </>
);

export default SegmentOverview;
