import { capitalize } from 'lodash-es';

export const precisionRound = (number: number, precision: number): number => {
    const factor = 10 ** precision;
    return Math.round(number * factor) / factor;
};

/**
 * Example output:
 * - 'A'
 * - 'A and B'
 * - 'A, B and C'
 */
export const joinByCommaAndConjunction = (items: string[] = []): string => {
    switch (items.length) {
        case 0:
            return '';
        case 1:
            return items[0];
        case 2:
            return items.join(' and ');
        default:
            return `${items.slice(0, -1).join(', ')} and ${items[items.length - 1]}`;
    }
};

export const getUserName = (name: string) =>
    (name.toLowerCase().startsWith('dr.') ? name.substring(3) : name).trim();

export const getInitials = (name: string) => {
    const allNames = name.trim().split(' ');
    return allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
            const position = curr.search(/[a-zA-Z]/);
            const letter = curr.charAt(position);

            return `${acc}${letter.toUpperCase()}`;
        }
        return acc;
    }, '');
};

export const toSystemCode = (name: string | null | undefined) => {
    if (!name) {
        return '';
    }
    return name
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/gm, '') // diacritics
        .replace(/[\s.,:;-]+/gm, '_') // separators
        .replace(/[^\w_]+/gm, '') // separators except underscores
        .replace(/_+/gm, '_') // multiple underscores
        .toUpperCase();
};

export const fromSystemCode = (code: string | null | undefined) =>
    code ? capitalize(code).replaceAll('_', ' ') : '';
