import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { LogData } from '../../logBook.types';
import BudgetShiftTooltip from '../BudgetShiftingDetails/BudgetShiftTooltip';

interface Props {
    oldData?: LogData;
    newData?: LogData;
}

const LogEntryInfo = ({ oldData, newData }: Props) => (
    <Tooltip renderTooltip={() => <BudgetShiftTooltip oldData={oldData} newData={newData} />}>
        <i className="fa fa-info-circle text-info me-2" />
    </Tooltip>
);

export default LogEntryInfo;
