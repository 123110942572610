import {
    MediaInsertionStepType,
    MEDIA_INSERTION_STEPS,
    WorkflowLaneModel,
} from 'platform/mediaplan/mediaplan.types';
import { getInsertionStepCompleted, getInsertionStepTotal } from 'platform/mediaplan/mediaplan.utils';
import { SummaryStepProps } from './SummaryStep';

const useSummarySteps = (stepTypes: MediaInsertionStepType[], lanes: WorkflowLaneModel[]) => {
    const stepPropsByType = stepTypes.reduce(
        (acc, type) => ({
            ...acc,
            [type]: {
                total: getInsertionStepTotal(type, lanes),
                completed: getInsertionStepCompleted(type, lanes),
                label: MEDIA_INSERTION_STEPS[type].label,
            },
        }),
        {} as Record<MediaInsertionStepType, SummaryStepProps>
    );

    return { stepPropsByType };
};

export default useSummarySteps;
