import { useState } from 'react';
import { Button, Input } from 'reactstrap';
import classNames from 'classnames';
import { differenceWith, isEqual, sortBy, uniqueId } from 'lodash-es';
import BulkCampaignStrategyUnitModal from 'platform/analytics/modes/CampaignAnalytics/BulkCampaignStrategyUnitModal';
import { StrategyModel } from 'platform/campaign/strategy/strategy.types';
import { UNIT_STATE_OPTIONS, UnitState } from 'platform/campaign/unit/constants/state.constant';
import { Unit, UnitPatch } from 'platform/campaign/unit/unit.types';
import { TableCell, TableRow } from 'platform/common/common.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import IconButton from 'platform/common/components/IconButton/IconButton';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import InlineEditInput from 'platform/common/components/InlineEditInput/InlineEditInput';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import { useModal } from 'platform/common/components/Modal/Modal';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import './UnitEditorTable.scss';

export type Patch = UnitPatch & { key: string };

type Props = {
    strategy: StrategyModel;
    units: Unit[];
    patches: Patch[];
    onChange: (patches: Patch[]) => any;
};

type UnitRow = { state?: UnitState } & Patch;

const toRow = ({ id, state, creativePresetId, targetingName }: Unit): UnitRow => ({
    key: `OldUnit${id}`,
    state,
    id,
    creativePresetId,
    targetingName,
});

export const UnitEditorTable = ({ strategy, units, patches, onChange }: Props) => {
    const { showModal } = useModal();
    const [stateFilter, setStateFilter] = useState<UnitState[]>([UnitState.ACTIVE, UnitState.INACTIVE]);
    const [editRowList, setEditRowList] = useState<UnitRow[]>([]);
    const creativePresets = strategy.creatives?.presets ?? [];

    const originalRows = units.map(toRow);
    const newUnits = patches.filter((p) => !p.id);
    const editableRows: UnitRow[] = [
        ...units.map((unit) => {
            const patch = patches.find((p) => p.id === unit.id);
            return toRow(patch ? { ...unit, ...patch } : unit);
        }),
        ...newUnits.map((p) => ({ ...p, state: UnitState.ACTIVE })),
    ];

    const isFieldChanged = (row: UnitRow, field: keyof UnitRow) =>
        originalRows.find((r) => r.key === row.key)?.[field] !== row[field];

    const handleSelectAll = () => setEditRowList(editRowList.length ? [] : editableRows);

    const isRowSelected = (rowId: string) => !!editRowList.find((item) => item.key === rowId);

    const handleClick = (row: UnitRow) => {
        setEditRowList(
            isRowSelected(row.key)
                ? editRowList.filter((item) => item.key !== row.key)
                : [...editRowList, row]
        );
    };

    const columns: TableColumn<UnitRow>[] = [
        {
            Header: () => (
                <Input
                    type="checkbox"
                    className="position-static m-0"
                    checked={editRowList.length > 0}
                    onChange={handleSelectAll}
                />
            ),
            width: 70,
            headerClassName: 'cell-align-center',
            className: 'cell-align-center',
            Cell: (props: TableCell) => (
                <Input
                    type="checkbox"
                    className="position-static m-0"
                    checked={isRowSelected(props.original.key)}
                    readOnly
                    onChange={() => handleClick(props.original)}
                />
            ),
            sortable: false,
        },
        {
            Header: 'Status',
            accessor: 'state',
            width: 100,
            Cell: ({ original: { state } }) => <StatusBadge status={state} />,
        },
        {
            Header: 'ID',
            accessor: 'id',
            width: 100,
            Cell: ({ original: { id, key } }) =>
                id || (
                    <IconButton title="Remove" onClick={() => removeRow(key)}>
                        <i className="fa fa-times text-primary px-1" />
                    </IconButton>
                ),
        },
        {
            Header: 'Creative preset',
            accessor: 'creativePresetId',
            show: strategy.type !== 'TRADITIONAL',
            getProps: (_, { original: row }: TableRow<UnitRow>) => ({
                className: classNames({
                    'UnitEditorTable-changed': isFieldChanged(row, 'creativePresetId'),
                }),
            }),
            Cell: ({ original: row }) => (
                <>
                    {creativePresets.find((p) => p.id === row.creativePresetId)?.name}
                    <InlineDropdown
                        items={creativePresets.map((p) => ({
                            label: p.name,
                            action: () => modifyRow({ ...row, creativePresetId: p.id }),
                        }))}
                        iconClassName="fa fa-pen pull-right"
                        togglerClassName="edit-toggler ms-1"
                    />
                </>
            ),
        },
        {
            Header: 'Virtual targeting',
            accessor: 'targetingName',
            getProps: (_, { original: row }: TableRow<UnitRow>) => ({
                className: classNames({
                    'UnitEditorTable-changed': isFieldChanged(row, 'targetingName'),
                }),
            }),
            Cell: ({ original: row }) => (
                <InlineEditInput
                    value={row.targetingName?.trim()}
                    onChange={(value) => modifyRow({ ...row, targetingName: value || ' ' })}
                    inputType="text"
                    iconClassName="fa fa-pen pull-right text-primary edit-toggler"
                    style={{ justifyContent: 'left' }}
                />
            ),
        },
    ];
    const diffChanges = (rows: UnitRow[]) => onChange(differenceWith(rows, originalRows, isEqual));

    const modifyRow = (row: UnitRow) => diffChanges([...editableRows.filter((u) => u.key !== row.key), row]);

    const addRow = () =>
        diffChanges([
            ...editableRows,
            {
                key: uniqueId('NewUnit'),
                creativePresetId: creativePresets.length === 1 ? creativePresets[0].id : undefined,
            },
        ]);

    const removeRow = (key: string) => {
        if (isRowSelected(key)) {
            setEditRowList(editRowList.filter((item) => item.key !== key));
        }
        diffChanges(editableRows.filter((u) => u.key !== key));
    };

    const onUpdateBulk = (updatedUnits: UnitRow[]) => {
        diffChanges(
            editableRows
                .filter((editableRow) => !updatedUnits.some((item) => editableRow.key === item.key))
                .concat(updatedUnits)
        );

        setEditRowList([]);
    };

    return (
        <>
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    options={UNIT_STATE_OPTIONS}
                    onChange={setStateFilter}
                    value={stateFilter}
                    isMulti
                />
            </ListFilters>
            <FormattedTable
                topToolbar={
                    <>
                        <Button className="ms-2" onClick={addRow}>
                            <i className="fa fa-plus me-1" /> Add unit
                        </Button>
                        <Button
                            className="ms-2"
                            onClick={() =>
                                showModal((toggle) => (
                                    <BulkCampaignStrategyUnitModal
                                        toggle={toggle}
                                        options={creativePresets}
                                        data={editRowList}
                                        onUpdate={onUpdateBulk}
                                    />
                                ))
                            }
                            disabled={editRowList?.length <= 0}
                        >
                            <i className="fa fa-pencil me-1" /> Bulk edit
                        </Button>
                    </>
                }
                columns={columns}
                className="UnitEditorTable"
                data={sortBy(editableRows, 'key').filter((r) => !r.id || stateFilter.includes(r.state!))}
            />
        </>
    );
};
