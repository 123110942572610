import {
    PAGE_VIEW_EVENTS,
    transformEventsToSegments,
} from 'platform/advertiserTag/mappers/advertiserTag.mapper';
import {
    AdvertiserTag,
    AdvertiserTagDownloadFormModel,
    AdvertiserTagFormEvent,
    AdvertiserTagLite,
    AdvertiserTagLiteIncludes,
    AdvertiserTagLoaderType,
    Event,
    Segment,
} from '../advertiserTag.types';
import { EventName, EventParam } from '../constants/advertiserTag.constant';

const beautify = (json: string) => {
    try {
        return JSON.stringify(JSON.parse(json), null, 4);
    } catch (e) {
        return json;
    }
};

const calculateEditableFlags = (advertiserTagVarName: string) => {
    switch (advertiserTagVarName) {
        case EventName.PAGE_VIEW:
        case EventName.PAGE_VIEW_NO_SCRIPT: {
            return {
                productData: false,
                purchaseData: false,
                convertible: false,
            };
        }
        default: {
            return {
                productData: true,
                purchaseData: true,
                convertible: true,
            };
        }
    }
};

export const toFormModel = (data: {
    advertiserTag: Partial<AdvertiserTag>;
    advertiserTagLoaderType: AdvertiserTagLoaderType;
    advertiserTagId: number;
    segments: Segment[];
    standardEvents: Event[];
    advertiserTagLite: AdvertiserTagLite;
    includes: AdvertiserTagLiteIncludes;
}): AdvertiserTagDownloadFormModel => {
    const {
        advertiserTag,
        advertiserTagId,
        segments,
        standardEvents: standardEventsList,
        advertiserTagLite,
        advertiserTagLoaderType,
        includes,
    } = data;

    const standardEventsSegments = segments.filter((item) => item.type === 'STANDARD_EVENT');
    const customEventsSegments = segments.filter((item) => item.type === 'CUSTOM_EVENT');

    const customEvents: AdvertiserTagFormEvent[] = customEventsSegments.map((segment) => ({
        id: segment.id,
        type: 'CUSTOM_EVENT',
        advertiserTagVarName: segment.advertiserTagVarName,
        name: segment.name,
        selected: true,
        disabled: false,
        editable: {
            productData: true,
            purchaseData: true,
            convertible: segment.convertible,
        },
        availability: segment.availability,
        convertible: segment.convertible,
        productData: segment.eventParams.includes(EventParam.PRODUCT_DATA),
        purchaseData: segment.eventParams.includes(EventParam.PURCHASE_DATA),
        customVarsCount: segment.customVarsCount,
    }));

    const standardEvents: AdvertiserTagFormEvent[] = standardEventsList.map((item) => {
        const eventSegment = standardEventsSegments.find(
            (segment) => segment.advertiserTagVarName === item.advertiserTagVarName
        );

        const pageViewEvent = [EventName.PAGE_VIEW, EventName.PAGE_VIEW_NO_SCRIPT].includes(
            item.advertiserTagVarName as typeof PAGE_VIEW_EVENTS[number]
        );

        return {
            id: eventSegment ? eventSegment.id : undefined,
            type: 'STANDARD_EVENT',
            advertiserTagVarName: item.advertiserTagVarName,
            name: item.name,
            selected: pageViewEvent || !!eventSegment,
            disabled: pageViewEvent || !eventSegment,
            editable: calculateEditableFlags(item.advertiserTagVarName),
            availability: eventSegment?.availability ?? 'PRIVATE',
            convertible: eventSegment?.convertible ?? false,
            productData: eventSegment ? eventSegment.eventParams.includes(EventParam.PRODUCT_DATA) : false,
            purchaseData: eventSegment ? eventSegment.eventParams.includes(EventParam.PURCHASE_DATA) : false,
            customVarsCount: eventSegment?.customVarsCount,
        };
    });

    return {
        advertiserTag,
        advertiserTagLoaderType,
        advertiserTagId,
        events: [...customEvents, ...standardEvents],
        advertiserTagLite: {
            actionMap: beautify(advertiserTagLite.actionMap),
            integrationMap: beautify(advertiserTagLite.integrationMap),
            customVarConfig: beautify(advertiserTagLite.customVarConfig),
            urlSegmentList: beautify(advertiserTagLite.urlSegmentList),
        },
        includes,
    };
};

export const toApi = (data: AdvertiserTagDownloadFormModel) => ({
    advertiserTag: data.advertiserTag,
    advertiserTagId: data.advertiserTagId,
    segmentIds: data.events.filter((event) => event.selected).map((event) => event.id!),
    segments: transformEventsToSegments(data.events),
    advertiserTagLite: data.advertiserTagLite,
});
