import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'platform/app/ducks/auth.duck';

type ShowModalType = (modalFn: (toggle: () => void) => ReactNode) => void;

type ModalContextType = {
    showModal: ShowModalType;
};

const ModalContext = createContext<ModalContextType>({ showModal: () => null });

type ModalContextProviderProps = {
    children: ReactNode;
};

export const ModalProvider = (props: ModalContextProviderProps) => {
    const isLoggedIn = useSelector(authSelectors.isLoggedIn);
    const [modal, setModal] = useState<ReactNode | undefined>(undefined);

    const toggle = () => setModal(undefined);

    const showModal: ModalContextType = useMemo(
        () => ({
            showModal: (modalFn) => setModal(modalFn(toggle)),
        }),
        []
    );

    return (
        <ModalContext.Provider value={showModal}>
            {props.children}
            {isLoggedIn && modal}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);
