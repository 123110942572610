import { ReactNode, useState } from 'react';
import { FormFeedback } from 'reactstrap';
import classNames from 'classnames';

interface Props {
    children: (setTouched: (touched: boolean) => void, invalid: boolean) => ReactNode;
    isValueEmpty: boolean;
    required?: boolean;
}

const ReportInteractionContainer = ({ children, isValueEmpty, required }: Props) => {
    const [touched, setTouched] = useState(false);
    const invalid = !!required && touched && isValueEmpty;

    return (
        <div className={classNames('mt-3', invalid ? 'mb-1' : 'mb-3')}>
            {children(setTouched, invalid)}
            {invalid && <FormFeedback>required</FormFeedback>}
        </div>
    );
};

export default ReportInteractionContainer;
