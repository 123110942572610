import { Button } from 'reactstrap';
import { FieldArrayRenderProps, useField } from 'formik';
import { isEmpty } from 'lodash-es';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import { Campaign } from 'platform/campaign/campaign/campaign.types';
import { CreativePreset } from 'platform/campaign/strategy/strategy.types';
import { OptionType } from 'platform/common/components/CheckboxTree/CheckboxTree.type';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { usePromise } from 'platform/common/hooks/usePromise';
import { namesToOptions } from 'platform/common/utils/option.util';
import { validator } from 'platform/common/utils/validators.util';
import { fetchConcepts } from 'platform/creatives/services/creative.service';
import { Concept } from 'platform/creatives/types/creative.types';
import FieldError from 'platform/formik/FieldError/FieldError';
import CreativePresetControls from './CreativePresetControls';

type Props = {
    fields: FieldArrayRenderProps;
    presets: CreativePreset[];
    campaign: Campaign;
};

type State = {
    concepts: Concept[];
    productLabels: OptionType[];
    flightLabels: OptionType[];
};

const CreativePresetCards = ({ fields, presets, campaign }: Props) => {
    useField({
        name: fields.name,
        validate: validator(() => (isEmpty(presets) ? 'Add at least one preset' : undefined)),
    });

    const { advertiserId } = campaign;
    const [{ data: state, loading }] = usePromise<State>(
        {
            concepts: [],
            productLabels: [],
            flightLabels: [],
        },
        async () => {
            const [concepts, productOptions, flightLabelOptions] = await Promise.all([
                fetchConcepts({ advertiserId, view: 'without-creatives' }),
                fetchProducts(advertiserId),
                fetchFlightLabels(advertiserId),
            ]);
            return {
                concepts,
                productLabels: namesToOptions(productOptions),
                flightLabels: namesToOptions(flightLabelOptions),
            };
        },
        [advertiserId]
    );

    const { productLabels, flightLabels, concepts } = state;
    return (
        <>
            {presets.map((preset, index) => (
                <ControlledCard
                    title={preset.name || '(New preset)'}
                    key={preset.id || index}
                    isExpanded
                    toggleable={false}
                    onRemove={campaign.type !== 'RTB' ? () => fields.remove(index) : undefined}
                >
                    {loading ? (
                        <OverlayLoader />
                    ) : (
                        <CreativePresetControls
                            fieldName={`${fields.name}.${index}`}
                            creativeIds={preset.creatives ?? []}
                            concepts={concepts}
                            onCreativesChange={(creatives) => fields.replace(index, { ...preset, creatives })}
                            campaign={campaign}
                            productLabels={productLabels}
                            flightLabels={flightLabels}
                        />
                    )}
                </ControlledCard>
            ))}
            <FieldError name={fields.name} className="mb-3" />
            {campaign.type !== 'RTB' && (
                <Button onClick={() => fields.push({ creatives: [], name: '' })}>
                    <i className="fa fa-plus me-1" /> Add preset
                </Button>
            )}
        </>
    );
};

export default CreativePresetCards;
