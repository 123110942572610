import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { useFeature } from 'platform/common/hooks/useFeature';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { ReportSaveOperationType } from './ReportSaveForm';

interface Props {
    operationType: ReportSaveOperationType;
    report: AnalyticsReport;
    usedAsSystemReport: boolean;
    isLinked: boolean;
    onClick: () => Promise<void>;
}

const ReportSaveButton = ({ operationType, report, isLinked, usedAsSystemReport, onClick }: Props) => {
    const isSuperuser = useFeature('SUPERUSER');
    const isAdmin = useTypedSelector(authSelectors.isAdmin);
    const canEditCustomFolders = useFeature('CUSTOM_FOLDER_EDIT');

    if (operationType === 'CREATE') {
        const disabled =
            (report.section !== 'PERSONAL' && !isAdmin && !canEditCustomFolders) ||
            (report.section === 'CENTRAL_ANALYTICS' && !!report.advertiserIds?.length);

        return (
            <AnimatedButton disabled={disabled} onClick={onClick}>
                Create
            </AnimatedButton>
        );
    }

    const disabled =
        (report.section !== 'PERSONAL' && usedAsSystemReport && !isSuperuser && !canEditCustomFolders) ||
        isLinked ||
        (report.section === 'CENTRAL_ANALYTICS' && !!report.advertiserIds?.length);

    return (
        <AnimatedButton disabled={disabled} onClick={onClick}>
            Update
        </AnimatedButton>
    );
};

export default ReportSaveButton;
