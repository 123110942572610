import { FieldArray } from 'formik';
import { saveStrategyCreatives } from 'platform/campaign/strategy/services/strategy.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import FormContainer, { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { StrategyCreatives, StrategyModel } from '../../strategy.types';
import CreativePresetCards from './CreativePresetCards';

type Props = {
    strategy: StrategyModel;
    afterSubmit: () => void;
    onCancel: () => void;
};

const StrategyCreativesForm = ({ strategy, afterSubmit, onCancel }: Props) => (
    <FormContainer
        helpKey="strategy_creatives_form"
        sidePanel={false}
        onOpen={async () => strategy.creatives}
        onSubmit={(creatives: StrategyCreatives) => saveStrategyCreatives(strategy.id, creatives)}
        redirectAfterSubmit={false}
        onSubmitFinish={afterSubmit}
        onCancel={onCancel}
        canEdit
        isEdit
    >
        {({ formikProps: { values, submitForm } }: FormProps<StrategyCreatives>) => (
            <CardForm
                title="Creative presets"
                subtitle={`Strategy ID: ${strategy.id}`}
                submitLabel="Save & continue"
                cancelLabel={strategy.type === 'RTB' ? 'Close' : 'Cancel'}
                disabled={strategy.type === 'RTB'}
                onSubmit={submitForm}
                onCancel={onCancel}
            >
                <FieldArray name="presets">
                    {(fields) => (
                        <CreativePresetCards
                            fields={fields}
                            presets={values.presets}
                            campaign={strategy.campaign}
                        />
                    )}
                </FieldArray>
            </CardForm>
        )}
    </FormContainer>
);

export default StrategyCreativesForm;
