import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsMobile, layoutActions, layoutSelectors } from 'platform/common/ducks/layout.duck';

export const useIsMobile = () => {
    const dispatch = useDispatch();
    const isMobile = useSelector(layoutSelectors.isMobile);
    useEffect(() => {
        const handleResize = () => {
            if (isMobile === getIsMobile()) return;
            dispatch(layoutActions.setIsMobile(getIsMobile()));
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch, isMobile]);

    return isMobile;
};
