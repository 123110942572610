import { useDispatch, useSelector } from 'react-redux';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsReportsActions } from 'platform/analytics/ducks/analyticsReports.duck';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { fetchAccessibleReports } from 'platform/app/components/Sidebar/navigation.util';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { useFeature } from 'platform/common/hooks/useFeature';

type AnalyticsReportsState = {
    accessibleReports: AnalyticsReport[];
    reloadAnalyticsReports: (advertiser?: AdvertiserOption) => Promise<AnalyticsReport[]>;
};

export const useAnalyticsReports = (): AnalyticsReportsState => {
    const accessibleReports = useSelector(analyticsSelectors.analyticsReports);
    const profile = useSelector(authSelectors.ready.profile);
    const canViewAnalytics = useFeature('ANALYTICS_VIEW');
    const dispatch = useDispatch();

    return {
        accessibleReports,
        reloadAnalyticsReports: async (advertiser?: AdvertiserOption) =>
            canViewAnalytics
                ? fetchAccessibleReports(profile, advertiser)
                      .then((reports) => dispatch(analyticsReportsActions.setAnalyticsReports(reports)))
                      .then((action) => action.payload)
                : [],
    };
};
