import { ColumnColouringType } from 'platform/analytics/analytics.types';
import ColumnColouring from 'platform/analytics/reportComponents/ColumnColouring/ColumnColouring';
import LabelRenamePopover from 'platform/analytics/reportComponents/LabelRenamePopover';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import PopoverDropdown from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useFeature } from 'platform/common/hooks/useFeature';
import useToggle from 'platform/common/hooks/useToggle';

interface Props {
    column: TableColumn;
    colouring?: ColumnColouringType;
    onColumnRemove: (column: TableColumn) => void;
    onColumnColouringChange: (columnId: string, colouring: ColumnColouringType | undefined) => void;
}

const canColourColumn = (columnTypeId?: string) => {
    if (!columnTypeId) {
        return false;
    }
    const availableTypes = [
        DATA_TYPES.FLOAT.typeId,
        DATA_TYPES.INT.typeId,
        DATA_TYPES.EUR.typeId,
        DATA_TYPES.RATIO.typeId,
        DATA_TYPES.P100.typeId,
    ];
    return availableTypes.includes(columnTypeId);
};

const ColumnActions = ({ column, colouring, onColumnRemove, onColumnColouringChange }: Props) => {
    const [colouringOpen, toggleColouring] = useToggle(false);
    const [labelRenameOpen, toggleLabelRename] = useToggle(false);
    const canRename = useFeature('ADVERTISER_SETTINGS_EDIT');

    const handleColouringChange = (value: ColumnColouringType) => {
        if (column.id) {
            onColumnColouringChange(column.id, value);
        }
    };

    return (
        <>
            <PopoverDropdown
                togglerClassName="pe-0"
                toggler={<i className="fa fa-ellipsis-h" />}
                className="ms-auto"
            >
                {({ toggle }) => (
                    <>
                        <button
                            className="dropdown-item w-100"
                            type="button"
                            onClick={() => {
                                toggle();
                                onColumnRemove(column);
                            }}
                        >
                            Remove column
                        </button>
                        {canColourColumn(column.type?.typeId) && (
                            <button
                                className="dropdown-item w-100"
                                type="button"
                                onClick={() => {
                                    toggle();
                                    toggleColouring();
                                }}
                            >
                                Color column
                            </button>
                        )}
                        {canRename && (
                            <button
                                className="dropdown-item w-100"
                                type="button"
                                onClick={() => {
                                    toggle();
                                    toggleLabelRename();
                                }}
                            >
                                Rename label
                            </button>
                        )}
                    </>
                )}
            </PopoverDropdown>
            {colouringOpen && (
                <ColumnColouring
                    colouring={colouring}
                    onColouringChange={handleColouringChange}
                    onToggle={toggleColouring}
                />
            )}
            {labelRenameOpen && <LabelRenamePopover columnKey={column.id!} onToggle={toggleLabelRename} />}
        </>
    );
};

export default ColumnActions;
