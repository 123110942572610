import { AdvertiserAccountKey } from 'platform/advertisers/advertiser.types';
import axios from 'platform/axios/axiosBff';
import { CampaignClassification } from 'platform/campaign/campaign/campaignClassification.types';
import { ImportedCampaign, ImportedUnit } from 'platform/campaign/campaign/campaignImport.types';
import { SelectItem } from 'platform/common/common.types';
import { ExternalSystem, IntegrationStatus } from 'platform/common/constants/externalIntegration.constant';
import { Status } from 'platform/common/constants/status.constant';
import { makeOptions } from 'platform/common/utils/option.util';

export type CampaignImportKey = AdvertiserAccountKey & {
    externalId: string;
};

type CampaignBatchImportParams = AdvertiserAccountKey & {
    externalIds: string[];
};

export interface CampaignImportStatus {
    externalCampaignId: string;
    status: IntegrationStatus;
    error: string;
    internalCampaignId: number;
    importedOn: string;
}

export enum MappingStatus {
    MAPPED = 'MAPPED',
    NOT_MAPPED = 'NOT_MAPPED',
}

export type CampaignImportFilter = {
    advertiserId: number;
    externalAdvertiserId: string;
    internalIds?: number[];
    externalIds?: string[];
    name?: string;
    channels?: string[];
    status?: Status;
    importStatus?: IntegrationStatus;
    mappingStatus?: MappingStatus;
};

export type CampaignImportDefaultFilters = {
    channelFilter?: string[];
    statusFilter?: Status;
    mappingStatusFilter?: MappingStatus;
    importStatusFilter?: IntegrationStatus;
};

export type ImportMode = 'FULL' | 'SKIP_CREATIVES' | 'SKIP_UNITS';

export const IMPORT_MODE_OPTIONS: SelectItem<ImportMode>[] = makeOptions({
    FULL: 'Import campaign + units + creatives',
    SKIP_CREATIVES: 'Import campaign + units',
    SKIP_UNITS: 'Import campaign only',
});

type ImportProcess = AdvertiserAccountKey & {
    status: IntegrationStatus;
    errorMessage?: string;
    warningMessage?: string;
    startedBy?: string;
    startedOn?: string;
    endedOn?: string;
    retry?: {
        required: boolean;
        count: number;
    };
};

type ImportProgress = {
    pendingCampaigns: number[];
    finishedCampaigns: number[];
};

export type BatchImportSummary = {
    process: ImportProcess;
    progress: ImportProgress;
};

export const fetchExternalCampaignSummaries = (
    externalSystem: ExternalSystem,
    params: CampaignImportFilter
) =>
    axios
        .get<ImportedCampaign[]>(`/campaign/api/import/${externalSystem}/campaigns`, { params })
        .then((response) => response.data);

export const fetchExternalCampaign = ({
    externalSystem,
    externalId,
    advertiserId,
    externalAdvertiserId,
}: CampaignImportKey) =>
    axios
        .get<ImportedCampaign>(`/campaign/api/import/${externalSystem}/campaign/${externalId}`, {
            params: { advertiserId, externalAdvertiserId },
        })
        .then((response) => response.data);

export const importExternalCampaign = (
    { externalSystem, externalId, advertiserId, externalAdvertiserId }: CampaignImportKey,
    settings: {
        classification?: CampaignClassification;
        importMode?: ImportMode;
    }
) =>
    axios
        .put<{ campaignId: number; importStatus: IntegrationStatus }>(
            `/campaign/api/import/${externalSystem}/campaign/${externalId}`,
            settings,
            { params: { advertiserId, externalAdvertiserId } }
        )
        .then((response) => response.data);

export const getCampaignImportStatus = (campaignId: number) =>
    axios
        .get<{ importStatus: IntegrationStatus }>(`/campaign/api/import/campaign-status/${campaignId}`)
        .then((response) => response.data);

export const importExternalUnit = (
    { externalSystem, externalId, advertiserId, externalAdvertiserId }: CampaignImportKey,
    externalUnitId: string,
    importMode: ImportMode
) =>
    axios
        .put<ImportedUnit>(
            `/campaign/api/import/${externalSystem}/campaign/${externalId}/unit/${externalUnitId}`,
            undefined,
            {
                params: { advertiserId, externalAdvertiserId, importMode },
            }
        )
        .then((response) => response.data);

export const getBatchImportSummary = (params: AdvertiserAccountKey) =>
    axios
        .get<BatchImportSummary>(`/campaign/api/import/processes/summary`, { params })
        .then((response) => response.data);

export const startBatchImport = (params: AdvertiserAccountKey) =>
    axios
        .put<BatchImportSummary>(`/campaign/api/import/processes/start`, undefined, { params })
        .then((response) => response.data);

export const cancelBatchImport = (params: AdvertiserAccountKey) =>
    axios
        .put<BatchImportSummary>(`/campaign/api/import/processes/cancel`, undefined, { params })
        .then((response) => response.data);

export const importExternalCampaigns = (
    { externalSystem, externalIds, advertiserId, externalAdvertiserId }: CampaignBatchImportParams,
    settings: {
        classification?: CampaignClassification;
        importMode?: ImportMode;
    }
) =>
    axios
        .put<{ campaignId: number; importStatus: IntegrationStatus }>(
            `/campaign/api/import/${externalSystem}/campaigns`,
            settings,
            { params: { advertiserId, externalAdvertiserId, externalIds } }
        )
        .then((response) => response.data);

export const fetchCampaignImportStatus = ({
    externalSystem,
    advertiserId,
    externalAdvertiserId,
}: AdvertiserAccountKey) =>
    axios
        .get<CampaignImportStatus[]>(`/campaign/api/import/${externalSystem}/campaigns/status`, {
            params: { advertiserId, externalAdvertiserId },
        })
        .then((response) => response.data);
