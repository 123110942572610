import classNames from 'classnames';
import { Task, TaskLane } from '../task.types';
import TaskStatusIcon from './TaskStatusIcon';
import TaskStuckIcon from './TaskStuckIcon';

interface Props {
    task: Task;
    lane?: TaskLane;
    className?: string;
}

const TaskTitle = ({ task, lane, className }: Props) => (
    <div className={classNames('d-flex align-items-center text-break', className)}>
        {task.status !== 'ACTIVE' && <TaskStatusIcon status={task.status} />}
        <TaskStuckIcon task={task} lane={lane} />
        {task.name}
    </div>
);

export default TaskTitle;
