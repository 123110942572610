import { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { NavigationItem } from 'platform/app/app.types';
import NavigationIcon from 'platform/app/components/Sidebar/NavigationIcon';
import ExpandCollapseButton from 'platform/common/components/ExpandCollapseButton/ExpandCollapseButton';
import useToggle from 'platform/common/hooks/useToggle';
import { getNavigationDropdownRedirectLink } from './navigation.util';

interface Props {
    item: NavigationItem;
    children: ReactNode;
    isActive: boolean;
    isOpen: boolean;
    isNested?: boolean;
}

export const hideMobileSidebar = () => document.body.classList.remove('sidebar-mobile-hide');

const NavigationDropdown = ({ item, children, isActive, isOpen, isNested }: Props) => {
    const [open, toggle] = useToggle(isOpen);

    const handleOpen: MouseEventHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        toggle();
    };

    const { icon, name } = item;

    const redirectLink = getNavigationDropdownRedirectLink(item);

    return (
        <li className={classNames('nav-item nav-dropdown', { open })}>
            {redirectLink ? (
                <Link
                    to={redirectLink}
                    className={classNames('nav-link nav-dropdown-toggle', {
                        active: isActive,
                        'py-2 px-3': !item.icon,
                    })}
                    role="button"
                    tabIndex={-1}
                    onClick={hideMobileSidebar}
                >
                    {isNested && item.disabled && (
                        <i className="me-2 opacity-75 text-muted font-xs fal fa-eye-slash" />
                    )}
                    <NavigationIcon Icon={icon} />
                    {name}
                    <ExpandCollapseButton
                        expanded={open}
                        onClick={handleOpen}
                        className="nav-arrow ms-auto"
                    />
                </Link>
            ) : (
                <a
                    className={classNames('nav-link nav-dropdown-toggle', {
                        active: isActive,
                        nested: isNested,
                    })}
                    role="button"
                    tabIndex={-1}
                    onClick={handleOpen}
                >
                    <NavigationIcon Icon={icon} />
                    {name}
                    <ExpandCollapseButton
                        expanded={open}
                        onClick={handleOpen}
                        className="nav-arrow ms-auto"
                    />
                </a>
            )}
            {open && <ul className="nav-dropdown-items">{children}</ul>}
        </li>
    );
};

export default NavigationDropdown;
