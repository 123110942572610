const defaultResolvePaging = (result: { paging: any }) => result.paging;
const defaultResolveEntries = (result: { data: any[] }) => result.data;

const paginate = async (
    fetch: (pageNumber: number, pageSize: number) => any,
    resolveEntries: (result: any) => any[] = defaultResolveEntries,
    resolvePaging: (result: any) => any = defaultResolvePaging
) => {
    const result: any[] = [];
    const paging = { pageSize: 4000, pageNumber: 0, totalPages: 1 };

    do {
        // eslint-disable-next-line no-await-in-loop
        const pageResult = await fetch(paging.pageNumber, paging.pageSize);
        Object.assign(paging, resolvePaging(pageResult));
        const entries = resolveEntries(pageResult);
        entries.forEach((v) => result.push(v));
        paging.pageNumber += 1;
    } while (paging.pageNumber < paging.totalPages);

    return result;
};

export default paginate;
