import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { push } from 'redux-first-history';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { wikiSelectors } from '../wiki.duck';
import { WIKI_PAGE } from '../wiki.navigation';
import { createPage } from '../wiki.service';
import WikiPageCreateModalBody from './WikiPageCreateModalBody';

interface Props {
    parentId?: number;
    toggle: () => void;
    refetch: () => void;
}

const WikiPageCreateModal = ({ parentId, toggle, refetch }: Props) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const wikiSpace = useSelector(wikiSelectors.activeWikiSpace);

    const handleSubmit = async () => {
        if (!title) return;

        const { id } = await createPage({ title, space: wikiSpace, parentId });
        refetch();
        toggle();
        dispatch(push(generatePath(WIKI_PAGE.path as string, { id })));
    };

    return (
        <Modal toggle={toggle} isOpen onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}>
            <ModalHeader onClose={toggle}>Enter page title</ModalHeader>
            <WikiPageCreateModalBody value={title} onChange={setTitle} />
            <ModalFooter>
                <Button color="secondary" className="px-3" onClick={toggle}>
                    Cancel
                </Button>
                <AnimatedButton onClick={handleSubmit} className="px-3">
                    Save
                </AnimatedButton>
            </ModalFooter>
        </Modal>
    );
};

export default WikiPageCreateModal;
