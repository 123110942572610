import { ChangeEvent, useState } from 'react';
import moment from 'moment/moment';
import {
    DataIngestionJobParams,
    fetchDataIngestionJobAccounts,
} from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import { SelectItem } from 'platform/common/common.types';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import FormRow from 'platform/common/components/FormRow/FormRow';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import CreatableSelect from 'platform/common/components/Select/CreatableSelect';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByName } from 'platform/common/utils/array.util';
import Switch from 'platform/formik/Switch/Switch';

type Props = {
    vendor: string;
    jobName: string;
    value: DataIngestionJobParams;
    onChange: (params: DataIngestionJobParams) => void;
};

const DataIngestionJobParamsForm = ({ vendor, jobName, value, onChange }: Props) => {
    const [{ data: accountOptions, loading }] = usePromise<SelectItem<number>[]>(
        [],
        async () => {
            if (vendor === 'mail') {
                return [];
            }
            const accounts = await fetchDataIngestionJobAccounts(jobName);
            return sortByName(accounts).map((a) => ({ value: a.id, label: a.name || String(a.id) }));
        },
        [jobName]
    );

    const [newAccountOptions, setNewAccountOptions] = useState<SelectItem<number>[]>([]);

    const today = moment().format(ISO_DATE_FORMAT);

    const handleChange = (params: Partial<DataIngestionJobParams>) => onChange({ ...value, ...params });

    return (
        <>
            <FormRow label="Period">
                <DateRangePicker
                    from={value.periodFrom}
                    to={value.periodTo}
                    maxDate={today}
                    onChange={(periodFrom, periodTo) => handleChange({ periodFrom, periodTo })}
                />
            </FormRow>
            <FormRow label="Chunk size">
                <InputWithAddOn
                    value={value.periodChunkSize}
                    type="number"
                    rightAddOn={{ title: 'days' }}
                    onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                        handleChange({ periodChunkSize: Number(target.value) })
                    }
                />
            </FormRow>
            {vendor !== 'mail' && (
                <FormRow label="Accounts">
                    <CreatableSelect
                        options={[...accountOptions, ...newAccountOptions]}
                        isLoading={loading}
                        value={value.accounts}
                        placeholder="Select or enter IDs"
                        formatCreateLabel={(input: string) => (
                            <span>
                                <b>Add</b>
                                {` "${input}"`}
                            </span>
                        )}
                        isMulti
                        onChange={(options: SelectItem<number>[]) =>
                            handleChange({ accounts: options.map((o) => o.value) })
                        }
                        onNewOption={(options: SelectItem<number>[]) => {
                            setNewAccountOptions(options.filter((o) => o.__isNew__));
                        }}
                    />
                </FormRow>
            )}
            {vendor === 'mail' && (
                <FormRow label="Re-fetch files" helpKey="data_ingestion_force">
                    <Switch
                        value={value.forceFetchFiles}
                        onChange={({ target }) => handleChange({ forceFetchFiles: target.checked })}
                    />
                </FormRow>
            )}
        </>
    );
};

export default DataIngestionJobParamsForm;
