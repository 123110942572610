import { ComponentType, ReactNode } from 'react';

type Props<T> = {
    Wrapper: ComponentType<T>;
    shouldRenderWrapper: boolean;
    children: ReactNode;
};

const ConditionalWrapper = <T extends {}>({
    Wrapper,
    shouldRenderWrapper,
    children,
    ...rest
}: Props<T> & T) => {
    if (!shouldRenderWrapper) return <>{children}</>;

    const wrapperProps = rest as unknown as T;

    return <Wrapper {...wrapperProps}>{children}</Wrapper>;
};

export default ConditionalWrapper;
