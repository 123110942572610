import { WorkflowEvent, WorkflowLaneModel, WorkflowStep } from 'platform/mediaplan/mediaplan.types';
import AccountingStage from './AccountingStage';
import ApprovalStage from './ApprovalStage';
import LiveStage from './LiveStage';
import PreparationStage from './PreparationStage';
import TechnicalSetupStage from './TechnicalSetupStage';
import '../MediaplanWorkflow.scss';

export interface WorkflowStagesProps {
    mediaplanSteps: Record<string, WorkflowStep>;
    lanes: WorkflowLaneModel[];
    workflowEvents: WorkflowEvent[];
    canEdit: boolean;
    onChange: (step: WorkflowStep, event?: WorkflowEvent) => void;
}

const WorkflowStages = ({ lanes, ...rest }: WorkflowStagesProps) => (
    <ol className="MediaplanWorkflow-stages">
        <li>
            <ApprovalStage {...rest} />
        </li>
        <li>
            <PreparationStage lanes={lanes} />
        </li>
        <li>
            <TechnicalSetupStage lanes={lanes} {...rest} />
        </li>
        <li>
            <LiveStage lanes={lanes} />
        </li>
        <li>
            <AccountingStage lanes={lanes} />
        </li>
    </ol>
);

export default WorkflowStages;
