import { Alert } from 'reactstrap';
import { FormikProps } from 'formik';
import { CampaignModel } from 'platform/campaign/campaign/campaign.types';
import CampaignClassifications from 'platform/campaign/campaign/components/CampaignClassifications';
import { MEDIA_TYPE_OPTIONS } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import FormRadioList from 'platform/formik/FormRadioList/FormRadioList';

type Props = {
    campaign: CampaignModel;
    setFieldValue: FormikProps<CampaignModel>['setFieldValue'];
    missingCMIntegration: boolean;
};

const CampaignClassificationSettings = ({ campaign, setFieldValue, missingCMIntegration }: Props) => (
    <ControlledCard title="Classification" subtitle="required" bodyClassName="form-content">
        {missingCMIntegration && (
            <Alert color={'warning'}>
                Digital campaigns will not work on this advertiser because it&apos;s missing CM360 integration
                setup.
            </Alert>
        )}
        <FormHelp>
            <FormRadioList
                label="Media type"
                options={MEDIA_TYPE_OPTIONS}
                optionsClassName="d-flex"
                name={'mediaType'}
                disabled={!!campaign.id}
                onChange={(newValue) => {
                    setFieldValue('classification.channel', null);
                    setFieldValue('type', newValue === 'TRADITIONAL' ? 'TRADITIONAL' : 'REDIRECT');
                }}
            />
        </FormHelp>
        <CampaignClassifications
            mediaType={campaign.mediaType}
            classification={campaign.classification}
            setFieldValue={setFieldValue}
        />
    </ControlledCard>
);

export default CampaignClassificationSettings;
