const fuzzyMatchImpl = (remainingInputTokens: string[], remainingOptionTokens: string[]): boolean => {
    if (remainingInputTokens.length === 0) {
        return true;
    }
    if (remainingOptionTokens.length === 0) {
        return false;
    }
    const currentInputToken = remainingInputTokens[0];
    const newRemainingInput = remainingInputTokens.slice(1);

    return remainingOptionTokens
        .filter((option) => option.includes(currentInputToken))
        .some((option) =>
            fuzzyMatchImpl(
                newRemainingInput,
                remainingOptionTokens.filter((o) => o !== option)
            )
        );
};

const fuzzyMatch = (option: string, input: string) => {
    const optionTokens = option.split(/\s|[_]/).filter((v) => v.length > 0);
    // The assumption is that users won't normally enter _ when filtering
    // unless they copy-paste metric name from OLAP in which case
    // fuzzy match is not needed
    const inputTokens = input.split(/\s/).filter((v) => v.length > 0);

    return fuzzyMatchImpl(inputTokens, optionTokens);
};

const filterOption = (option: string, input: string) => {
    const _option = option.trim().toLowerCase();
    return _option.includes(input) || fuzzyMatch(_option, input);
};

export const filterOptionAdvanced = (optionKey: string, optionName: string, input: string) => {
    const trimmedInput = input.trim();
    const isAdvancedSearch = trimmedInput.startsWith('/');
    const _input = (isAdvancedSearch ? trimmedInput.slice(1) : trimmedInput).toLowerCase();

    return filterOption(optionName, _input) || (isAdvancedSearch ? filterOption(optionKey, _input) : false);
};
