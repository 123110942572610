import AnalyticsReportFormRoutes from 'platform/analyticsReports/ReportManagement/AnalyticsReportFormRoutes';
import { useRefetchAnalyticsReports } from 'platform/analyticsReports/ReportManagement/useRefetchAnalyticsReports';
import ReportsTable from 'platform/analyticsReports/ReportsTable';
import { fetchAnalyticsReports } from 'platform/analyticsReports/analyticsReport.service';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { useReportTableColumns } from 'platform/analyticsReports/useReportTableColumns';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByName } from 'platform/common/utils/array.util';

interface Props {
    status: ActiveOrArchived[];
    canEdit: boolean;
    onReportChange: (report: AnalyticsReport) => Promise<void>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const SystemReports = ({ status, canEdit, onReportChange, onStatusChange }: Props) => {
    const columns = useReportTableColumns({ canEdit, onReportChange });

    const [{ data: systemReports, loading }, refetchReports] = usePromise<AnalyticsReport[]>(
        [],
        async () => sortByName(await fetchAnalyticsReports({ status, hasSystemKey: true })),
        [status]
    );

    const { refetch } = useRefetchAnalyticsReports(refetchReports);

    const handleReportChange = async (report: AnalyticsReport) => {
        await onReportChange(report);
        refetch();
    };

    return (
        <BodyContainer helpKey="system_reports">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
            </ListFilters>
            <ReportsTable
                columns={columns}
                reports={systemReports}
                loading={loading}
                canEdit={canEdit}
                onReportChange={handleReportChange}
            />
            <AnalyticsReportFormRoutes canEdit={canEdit} afterSubmit={refetch} />
        </BodyContainer>
    );
};

export default SystemReports;
