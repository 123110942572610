import { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Badge, Button, Card, CardBody, CardHeader, Input } from 'reactstrap';
import classNames from 'classnames';
import { maxBy, minBy } from 'lodash-es';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { OlapReport } from 'platform/analytics/analytics.types';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { useLoading } from 'platform/common/hooks/useLoading';
import {
    LANGUAGE_CODES,
    LanguageCode,
    PART_OF_SPEECH_LABELS,
    PartOfSpeech,
} from 'platform/naturalLanguageSentiments/naturalLanguageSentiments.constant';
import './NaturalLanguageSentiments.scss';

enum SentimentTypes {
    NEGATIVE = 'NEGATIVE',
    NEUTRAL = 'NEUTRAL',
    POSITIVE = 'POSITIVE',
    CRITICAL = 'CRITICAL',
}

type Sentiment = { type?: SentimentTypes; value: number };

const isBetween = (value: number, min: number, max: number) => value >= min && value <= max;

const SEGMENT_SCORE = {
    negative: {
        min: -1,
        max: -0.15,
    },
    neutral: {
        min: -0.15,
        max: 0.15,
    },
    positive: {
        min: 0.15,
        max: 1,
    },
};

type TableDataType = {
    google_blacklist_type: PartOfSpeech;
    google_blacklist_keyword: string;
    google_blacklist_critical: boolean | null;
    google_blacklist_sentiment: number;
    google_blacklist_language: LanguageCode;
};

const getSentimentType = (row: TableDataType) => {
    if (row.google_blacklist_critical) {
        return SentimentTypes.CRITICAL;
    }
    if (isBetween(row.google_blacklist_sentiment, SEGMENT_SCORE.negative.min, SEGMENT_SCORE.negative.max)) {
        return SentimentTypes.NEGATIVE;
    }
    if (isBetween(row.google_blacklist_sentiment, SEGMENT_SCORE.neutral.min, SEGMENT_SCORE.neutral.max)) {
        return SentimentTypes.NEUTRAL;
    }
    if (isBetween(row.google_blacklist_sentiment, SEGMENT_SCORE.positive.min, SEGMENT_SCORE.positive.max)) {
        return SentimentTypes.POSITIVE;
    }
    return undefined;
};

const getSentiment = (rows?: TableDataType[]): Sentiment | undefined => {
    const criticalRow = rows?.find((row) => row.google_blacklist_critical);
    const topRow = rows?.every((row) => row.google_blacklist_sentiment > 0)
        ? maxBy(rows, (row) => row.google_blacklist_sentiment)
        : minBy(rows, (row) => row.google_blacklist_sentiment);
    const sentimentRow = criticalRow || topRow;
    if (!sentimentRow) return undefined;
    return {
        type: getSentimentType(sentimentRow),
        value: sentimentRow.google_blacklist_sentiment,
    };
};

// Temporary mapping until backend is corrected
const getCountryCode = (code: string) => {
    if (code === 'en') return 'gb';
    if (code === 'ja') return 'jp';
    return code;
};
const NaturalLanguageSentiments = () => {
    const [phrase, setPhrase] = useState<string>('');
    const [tableData, setTableData] = useState<OlapReport<TableDataType> | undefined>(undefined);
    const [loading, withLoading] = useLoading();
    const [error, setError] = useState();
    const columns = [
        {
            Header: 'Content',
            accessor: (row: TableDataType) => row.google_blacklist_keyword,
            autoWidth: true,
        },
        {
            Header: 'Part of Speech',
            maxWidth: 200,
            Cell: ({ original }: TableCell<TableDataType>) => (
                <Badge color="secondary">{PART_OF_SPEECH_LABELS[original.google_blacklist_type]}</Badge>
            ),
        },
        {
            Header: 'Language',
            maxWidth: 200,
            Cell: ({ original }: TableCell<TableDataType>) => (
                <div className="d-flex gap-2 align-items-center">
                    <ReactCountryFlag
                        className="sentimet-flag"
                        countryCode={getCountryCode(original?.google_blacklist_language)}
                        svg
                    />
                    <span>{LANGUAGE_CODES[original?.google_blacklist_language]}</span>
                </div>
            ),
        },
        {
            Header: 'Sentiment Score',
            type: DATA_TYPES.ID,
            autoWidth: true,
            Cell: ({ original }: TableCell<TableDataType>) =>
                DATA_TYPES.RATIO.parse(original.google_blacklist_sentiment, { precise4: true }),
        },
        {
            Header: 'Critical',
            autoWidth: true,
            Cell: ({ original }: TableCell<TableDataType>) =>
                original.google_blacklist_critical ? (
                    <Badge color="danger" pill>
                        Critical
                    </Badge>
                ) : (
                    '-'
                ),
        },
    ];

    const sentiment = getSentiment(tableData?.rows);

    const performAnalyze = async () =>
        withLoading(async () => {
            const compareValue = phrase
                .trim()
                .toLowerCase()
                .replace(/[\n.,/#!$%^&*;:{}=-_`~()]/g, ' ')
                .split(' ');
            await generateOlapReport<TableDataType>({
                templateId: 'all_columns',
                dimensions: [
                    'google_blacklist_keyword',
                    'google_blacklist_type',
                    'google_blacklist_sentiment',
                    'google_blacklist_critical',
                    'google_blacklist_language',
                ],
                dimensionFilters: [],
                olapFilters: [
                    {
                        columnId: 'google_blacklist_lowercase_keyword',
                        compareOperator: 'IN',
                        compareValue,
                    },
                ],
            })
                .then((r) => {
                    setError(undefined);
                    setTableData(r);
                })
                .catch((e) => {
                    setError(e);
                });
        });

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Natural language sentiments" />
            </HeaderContainer>
            <BodyContainer helpKey="natural_language_sentiments">
                <div className="container NaturalLanguageSentiments px-0">
                    <div className="row" style={{ gridAutoRows: '.5fr !important', rowGap: '1rem' }}>
                        <div className="col-lg-7">
                            <Card className="h-100 mb-0">
                                <CardHeader>Enter text below</CardHeader>
                                <CardBody>
                                    <Input
                                        name="natural-language-sentiment"
                                        type="textarea"
                                        placeholder="Type yout text here"
                                        rows={6}
                                        value={phrase}
                                        onChange={(e) => setPhrase(e.currentTarget.value)}
                                    />
                                    <div className="d-flex align-items-center gap-2 justify-content-between mt-2">
                                        <Button
                                            color="primary"
                                            type="submit"
                                            className="float-right"
                                            onClick={() => performAnalyze()}
                                            disabled={!phrase.length}
                                        >
                                            Analyse
                                        </Button>

                                        <div className="d-flex align-items-center gap-2">
                                            <span>Supported languages:</span>
                                            <div>
                                                {Object.keys(LANGUAGE_CODES)
                                                    .slice(0, 6)
                                                    ?.map((code) => (
                                                        <ReactCountryFlag
                                                            countryCode={code}
                                                            svg
                                                            className="sentimet-flag me-1"
                                                            style={{ height: 16 }}
                                                        />
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-lg-5">
                            <Card className="h-100 mb-0">
                                <CardHeader>Result: Sentiment</CardHeader>
                                <CardBody>
                                    <div className="d-flex flex-column">
                                        <div className="text-muted">Score</div>
                                        <div className="d-flex flex-row mt-1 align-items-center">
                                            <div
                                                className={classNames(
                                                    'sentiment-score flex-fit font-lg d-flex align-items-center justify-content-center',
                                                    {
                                                        negative:
                                                            sentiment?.type === SentimentTypes.CRITICAL ||
                                                            sentiment?.type === SentimentTypes.NEGATIVE,
                                                        neutral: sentiment?.type === SentimentTypes.NEUTRAL,
                                                        positive: sentiment?.type === SentimentTypes.POSITIVE,
                                                    }
                                                )}
                                            >
                                                <h5 className="my-0">
                                                    {DATA_TYPES.FLOAT.format(sentiment?.value) || '-'}
                                                </h5>
                                            </div>
                                            <div className="flex-fill d-flex align-items-center justify-content-center">
                                                {sentiment ? <h3 className="mb-0">{sentiment.type}</h3> : '-'}
                                            </div>
                                            <div className="sentiment-icon flex-fit font-lg d-flex align-items-center justify-content-center">
                                                {(sentiment?.type === SentimentTypes.CRITICAL ||
                                                    sentiment?.type === SentimentTypes.NEGATIVE) && (
                                                    <span className="fa fa-exclamation-circle fa-lg cursor-pointer text-danger" />
                                                )}
                                                {sentiment?.type === SentimentTypes.POSITIVE && (
                                                    <span className="fa fa-check-circle fa-lg cursor-pointer text-success" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-1 mb-3">
                                        The score of the sentiment ranges between{' '}
                                        <span className="text-danger">-1.0</span> and{' '}
                                        <span className="text-success">1.0</span> and corresponds to the
                                        overall emotional learning of the text
                                    </div>
                                    <div className="score-range d-flex align-items-center">
                                        <h6 className="me-3 mb-0">Score range</h6>
                                        <div className="d-inline-flex ms-2 flex-fit align-items-center">
                                            <div className="negative p-2">-1 &ndash; -0.15</div>
                                            <div className="neutral p-2"> -0.15 &ndash; 0.15</div>
                                            <div className="positive p-2"> 0.15 &ndash; 1</div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <Card className="mt-3">
                        <CardHeader>Result: Syntax</CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormattedTable
                                        data={tableData?.rows || []}
                                        loading={loading}
                                        NoDataComponent={
                                            error
                                                ? () => <ErrorMessage error={error} hideIndicator />
                                                : undefined
                                        }
                                        columns={columns}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </BodyContainer>
        </>
    );
};

export default NaturalLanguageSentiments;
