import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { isEqual } from 'lodash-es';
import { isDefined } from 'platform/common/common.types';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { stringifyParams } from 'platform/common/utils/url.util';

type QueryParamValue =
    | string
    | number
    | boolean
    | undefined
    | (string | number)[]
    | { operator: 'OR' | 'AND'; values: (string | number)[] };
type QueryParams = Record<string, QueryParamValue>;

type Props<T> = {
    queryParams: T;
    setQueryParams: (queryParams: Partial<T>) => void;
    returnUrl: string;
};

const hasOperator = (value: QueryParamValue) => typeof value === 'object' && 'operator' in value;

const isNotEmpty = (value: QueryParamValue) => {
    if (hasOperator(value)) {
        return true;
    }

    if (Array.isArray(value)) {
        return value.length > 0;
    }

    return isDefined(value) && value !== '';
};

const formatValue = (value: QueryParamValue) => {
    if (hasOperator(value)) {
        return encodeURIComponent(
            // we always need to have sign in formatted value to correctly parse the operator
            (value.values.length < 2
                ? [...value.values, ...new Array(2 - value.values.length).fill('')]
                : value.values
            ).join(value.operator === 'AND' ? '+' : ',')
        );
    }

    return Array.isArray(value) ? encodeURIComponent(value.join(',')) : encodeURIComponent(value ?? '');
};

const parseNumber = (value: string) => {
    const decoded = decodeURIComponent(value);
    return isNaN(Number(decoded)) ? decoded : Number(decoded);
};

export const formatParams = (params: QueryParams): QueryParams =>
    Object.fromEntries(
        Object.entries(params)
            .filter(([, value]) => isNotEmpty(value))
            .map(([key, value]) => [key, formatValue(value)])
    );

const parseValue = (initialValue: QueryParamValue, searchValue: string | null): QueryParamValue => {
    if (searchValue === null) {
        return initialValue;
    }

    if (typeof initialValue === 'string') {
        return searchValue;
    }

    if (typeof initialValue === 'boolean') {
        return searchValue === 'true';
    }

    const operator = searchValue.includes('+') ? '+' : ',';
    const value = searchValue
        .split(operator)
        .filter((v) => v !== '')
        .map(parseNumber);

    if (hasOperator(initialValue)) {
        return {
            operator: operator === '+' ? 'AND' : 'OR',
            values: value,
        };
    }

    return Array.isArray(initialValue) ? value : value[0];
};

export const parseParams = (searchParams: URLSearchParams, currentParams: QueryParams): QueryParams => {
    searchParams.delete('advertiserId');
    return Object.fromEntries(
        Object.entries(currentParams).map(([key, value]) => [key, parseValue(value, searchParams.get(key))])
    );
};

const resolveValue = (value: QueryParamValue, queryParamValue?: QueryParamValue) => {
    if (!queryParamValue) {
        // we want to keep same reference to avoid rerenders
        if (!isNotEmpty(value)) {
            return value;
        }

        if (hasOperator(value)) {
            return {
                ...value,
                values: [],
            };
        }

        return Array.isArray(value) ? [] : '';
    }

    return isEqual(queryParamValue, value) ? value : queryParamValue;
};

const updateParamValues = (currentParams: QueryParams, queryParams: QueryParams): QueryParams =>
    Object.fromEntries(
        Object.entries(currentParams).map(([key, value]) => [key, resolveValue(value, queryParams[key])])
    );

const formatUrl = (url: string, advertiserId: number, currentParams: QueryParams) =>
    `${url}?${stringifyParams({
        advertiserId,
        ...formatParams({
            ...currentParams,
            ...parseParams(new URLSearchParams(location.search), currentParams),
        }),
    })}`;

const resolveParams = (currentParams: QueryParams) => {
    const queryParams = parseParams(new URLSearchParams(location.search), currentParams);
    if (isEqual(currentParams, queryParams)) {
        return undefined;
    }

    return updateParamValues(currentParams, queryParams);
};

export const useUrlSync = <T extends QueryParams>(
    initialParams: T,
    defaultReturnUrl = location.pathname
): Props<T> => {
    const dispatch = useDispatch();
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    // callback signature used to call function only on first render
    const [returnUrl, setReturnUrl] = useState(() =>
        formatUrl(defaultReturnUrl, advertiserId, initialParams)
    );
    const [currentParams, setCurrentParams] = useState<QueryParams>(
        () => resolveParams(initialParams) ?? initialParams
    );

    useEffect(() => {
        const params = resolveParams(currentParams);
        if (params) {
            setCurrentParams(params);
        }
    }, [location.search]);

    const handleQueryParamsChange = (newParams: Partial<T>) => {
        if (Object.entries(newParams).every(([key, value]) => isEqual(value, currentParams[key]))) {
            return;
        }
        const url = `${defaultReturnUrl}?${stringifyParams({
            advertiserId,
            ...formatParams({
                ...currentParams,
                ...newParams,
            }),
        })}`;

        setCurrentParams({
            ...currentParams,
            ...newParams,
        });

        if (url !== returnUrl) {
            setReturnUrl(url);
            dispatch(push(url));
        }
    };

    return {
        queryParams: currentParams as T,
        setQueryParams: handleQueryParamsChange,
        returnUrl,
    };
};
