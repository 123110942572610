import { NavigationItem } from 'platform/app/app.types';

export const DASHBOARD_FOLDER_ACCESS: NavigationItem = {
    name: '... manage shared folders',
    path: '/dashboard-folder-access',
    requiresFeature: 'CUSTOM_FOLDER_EDIT',
    display: { labelStyles: ['SMALL_TEXT'] },
    isLast: true,
};

export const CUSTOM_FOLDER_ACCESS: NavigationItem = {
    name: '... manage shared folders',
    path: '/custom-folder-access',
    requiresFeature: 'CUSTOM_FOLDER_EDIT',
    display: { labelStyles: ['SMALL_TEXT'] },
    isLast: true,
};
