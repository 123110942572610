import { ChangeEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Input } from 'reactstrap';
import moment from 'moment';

interface Props {
    value?: string;
    className?: string;
    onBlur: (value?: string) => any;
    onFocus?: () => any;
    disabled?: boolean;
    style?: React.CSSProperties;
}

const DateInput = ({ value, className, onBlur, onFocus, disabled, style }: Props) => {
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => setInternalValue(value), [value]);

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInternalValue(event.target.value);
    };

    const onInputBlur = () => {
        const momentValue = moment(internalValue);

        if (!momentValue.isValid()) {
            setInternalValue(value);
            return;
        }

        onBlur(internalValue);
    };

    return (
        <Input
            type="text"
            className={className}
            value={internalValue}
            onChange={onInputChange}
            onBlur={onInputBlur}
            onFocus={onFocus}
            disabled={disabled}
            mask="9999-99-99"
            tag={InputMask}
            style={style}
        />
    );
};

export default DateInput;
