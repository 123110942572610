import classNames from 'classnames';
import { chat } from 'platform/app/app.routes';
import IconButton from 'platform/common/components/IconButton/IconButton';

interface Props {
    className?: string;
    style?: React.CSSProperties;
    iconClassName?: string;
}

const FullScreenChatLink = ({ className, style, iconClassName }: Props) => (
    <a
        style={style}
        className={className}
        href={chat.path}
        rel="noopener noreferrer"
        target="_blank"
        onClick={(e) => {
            e.preventDefault();
            window.open(chat.path, '_blank', `width=600, height=800`);
            return false;
        }}
    >
        <IconButton>
            <i
                className={classNames(
                    'fa-solid fa-arrow-up-right-from-square text-white fs-4',
                    iconClassName
                )}
            />
        </IconButton>
    </a>
);

export default FullScreenChatLink;
