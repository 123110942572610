import { Badge } from 'reactstrap';
import { isEmpty } from 'lodash-es';
import { MetricDefinition } from 'platform/analytics/analytics.types';
import { campaignClassificationsToForm } from 'platform/campaign/campaign/campaignClassification.mapper';
import { CampaignClassifications } from 'platform/campaign/campaign/campaignClassification.types';
import { SelectItem } from 'platform/common/common.types';
import Formula from './Formula';
import { CustomMetricRule } from './customMetric.types';

const conditionLabels = (condition: CampaignClassifications, options: SelectItem<string>[]): string[] => {
    const classificationValues = campaignClassificationsToForm(condition);
    return options.flatMap(
        (o) => o.nodes?.filter((n) => classificationValues.includes(n.value)).map((n) => n.label) ?? []
    );
};

const ConditionalFormula = ({
    rules,
    metrics,
    conditionOptions,
}: {
    rules: CustomMetricRule[];
    metrics: MetricDefinition[];
    conditionOptions: SelectItem<string>[];
}) => (
    <div className="d-flex flex-column gap-2">
        {rules.map(({ condition, expression }, index) => (
            <div key={index} className="d-flex align-items-center gap-2">
                {!isEmpty(condition) && (
                    <>
                        <div className="d-flex gap-1">
                            {conditionLabels(condition, conditionOptions).map((label) => (
                                <Badge key={label} color="warning" className="badge-outline font-xs">
                                    {label}
                                </Badge>
                            ))}
                        </div>
                        <i className="fa-solid fa-arrow-right font-lg" />
                    </>
                )}
                {isEmpty(condition) && rules.length > 1 && (
                    <Badge color="secondary" className="font-xs">
                        DEFAULT:
                    </Badge>
                )}
                <Formula expression={expression} metrics={metrics} />
            </div>
        ))}
    </div>
);

export default ConditionalFormula;
