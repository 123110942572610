import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { orderBy } from 'lodash-es';
import moment from 'moment';
import { listMediaplans, updateMediaplan } from 'platform/campaign/campaign/services/mediaplan.service';
import { useLabelClassification } from 'platform/campaign/logBook/useLabelClassification';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { useModal } from 'platform/common/components/Modal/Modal';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { Status, STATUS_OPTIONS } from 'platform/common/constants/status.constant';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { formatDate } from 'platform/common/utils/date.util';
import { Mediaplan } from 'platform/mediaplan/mediaplan.types';
import { MEDIAPLAN } from '../mediaplan.navigation';
import MediaplanCopyModal from './MediaplanCopyModal';
import MediaplanWizard from './MediaplanWizard';

const nameGetter = (item: { name: string }) => item.name;

type QueryParams = {
    status: Status[];
    searchQuery: string;
    productLabel: string[];
    productSubLabel: string[];
    flightLabel: string[];
    countryLabel: string[];
};

const MediaplanList = () => {
    const {
        queryParams: { status, searchQuery, productLabel, productSubLabel, flightLabel, countryLabel },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>(
        {
            status: ['ACTIVE'],
            searchQuery: '',
            productLabel: [],
            productSubLabel: [],
            flightLabel: [],
            countryLabel: [],
        },
        MEDIAPLAN.path
    );

    const { showModal } = useModal();

    const { id: advertiserId } = useActiveAdvertiserComponent();
    const canEdit = useFeature('MEDIAPLAN_EDIT');
    const dispatch = useDispatch();
    const showConfirmationModal = useConfirmationModal();

    const { products, subLabels, flightLabels, countryOptions, labelsLoading } = useLabelClassification({
        selectedProductLabels: productLabel,
    });

    const [{ data, loading }, refetch] = usePromise<Mediaplan[]>(
        [],
        () =>
            listMediaplans({ advertiserId, status }).then((mediaplans) =>
                orderBy(mediaplans, [(m) => m.dateFrom], ['desc'])
            ),
        [advertiserId, status]
    );

    const showCopyModal = (originalId: number, originalName: string) => {
        showModal((toggle) => (
            <MediaplanCopyModal
                initialValues={{
                    originalId,
                    name: `Copy of ${originalName}`,
                    runtime: {
                        from: moment().format(ISO_DATE_FORMAT),
                        to: moment().add(1, 'month').format(ISO_DATE_FORMAT),
                    },
                }}
                toggle={toggle}
                afterSubmit={refetch}
            />
        ));
    };

    const renderTopToolbar = () => (
        <ListToolbar
            value={searchQuery}
            onSearch={(value) => setQueryParams({ searchQuery: value })}
            onCreate={canEdit ? () => dispatch(push('/mediaplan/wizard/new')) : undefined}
        />
    );

    const statusChangeAction = (mediaPlan: Mediaplan) => {
        const active = mediaPlan.status === 'ACTIVE';
        return {
            label: active ? 'Archive' : 'Activate',
            action: () => {
                showConfirmationModal(
                    async () => {
                        await updateMediaplan(mediaPlan.id, {
                            ...mediaPlan,
                            status: active ? 'ARCHIVED' : 'ACTIVE',
                        });
                        refetch();
                    },
                    {
                        title: 'Change media plan status',
                        text: (
                            <>
                                Media plan <span className="fw-bold">{mediaPlan.name}</span> will be{' '}
                                {active ? 'archived' : 'activated'}. Do you want to continue?
                            </>
                        ),
                    }
                );
            },
        };
    };

    const columns: TableColumn<Mediaplan>[] = [
        {
            Header: 'ID',
            accessor: 'id',
            type: DATA_TYPES.ID,
            maxWidth: 80,
        },
        {
            Header: 'Status',
            maxWidth: 80,
            Cell: ({ original }) => <StatusBadge status={original.status} />,
        },
        {
            Header: 'Name',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            Cell: CellWithEllipsis,
        },

        {
            Header: 'Budget',
            accessor: 'budget',
            maxWidth: 100,
            type: DATA_TYPES.EUR,
        },
        {
            Header: 'Allocated Budget',
            accessor: 'allocatedBudget',
            maxWidth: 100,
            type: DATA_TYPES.EUR,
        },
        {
            Header: 'Period',
            accessor: (mediaplan: Mediaplan) => mediaplan.dateFrom,
            Cell: ({ original }) => `${formatDate(original.dateFrom)} - ${formatDate(original.dateTo)}`,
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            Cell: ({ original: { updatedBy, updatedOn, createdOn } }) => (
                <div className="w-100">
                    <div>{formatDate(updatedOn || createdOn)}</div>
                    <div className="text-muted font-xs">{updatedBy}</div>
                </div>
            ),
        },

        {
            className: 'pull-right cell-align-right',
            maxWidth: 50,
            sortable: false,
            Cell: ({ original }) => {
                const { id, name } = original;
                return canEdit ? (
                    <InlineDropdown
                        items={[
                            {
                                label: 'Edit',
                                action: () => dispatch(push(`/mediaplan/wizard/${id}`)),
                            },
                            statusChangeAction(original),
                            {
                                label: 'Media insertions',
                                action: () => dispatch(push(`/mediaplan/wizard/${id}/media-insertions`)),
                            },
                            {
                                label: 'Workflow',
                                action: () => dispatch(push(`/mediaplan/wizard/${id}/workflow`)),
                            },
                            {
                                label: 'Uploaded files',
                                action: () => dispatch(push(`/mediaplan/wizard/${id}/files`)),
                            },
                            {
                                label: 'Duplicate',
                                action: () => showCopyModal(id, name),
                            },
                        ]}
                    />
                ) : null;
            },
        },
    ];

    const filteredData = data.filter(
        (d) =>
            (!searchQuery ||
                d.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                d.id.toString().includes(searchQuery)) &&
            (!productLabel.length ||
                productLabel.find((label) => d.classification?.productLabel?.includes(label))) &&
            (!productSubLabel.length ||
                productSubLabel.find((label) => d.classification?.productSubLabel?.includes(label))) &&
            (!flightLabel.length ||
                flightLabel.find((label) => d.classification?.flightLabel?.includes(label))) &&
            (!countryLabel.length ||
                countryLabel.find((label) => d.classification?.countryLabels?.includes(label)))
    );

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Mediaplan" />
            </HeaderContainer>
            <BodyContainer helpKey="mediaplan_list">
                {!canEdit && <CanNotEditWarning className="mb-3" />}
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        name="Status"
                        value={status}
                        options={STATUS_OPTIONS}
                        isMulti
                        onChange={(value) => setQueryParams({ status: value })}
                    />
                    <SelectWithAddon
                        getOptionLabel={nameGetter}
                        getOptionValue={nameGetter}
                        isMulti
                        name="Product"
                        value={productLabel}
                        onChange={(value) => setQueryParams({ productLabel: value, productSubLabel: [] })}
                        options={products}
                        isLoading={labelsLoading}
                    />
                    {!!subLabels.length && (
                        <SelectWithAddon
                            getOptionLabel={nameGetter}
                            getOptionValue={nameGetter}
                            isMulti
                            name="Sub-product"
                            value={productSubLabel}
                            onChange={(value) => setQueryParams({ productSubLabel: value })}
                            options={subLabels}
                            isLoading={labelsLoading}
                        />
                    )}
                    <SelectWithAddon
                        getOptionLabel={nameGetter}
                        getOptionValue={nameGetter}
                        isMulti
                        name="Flight"
                        onChange={(value) => setQueryParams({ flightLabel: value })}
                        value={flightLabel}
                        options={flightLabels}
                        isLoading={labelsLoading}
                    />
                    <SelectWithAddon
                        name="Country"
                        isMulti
                        value={countryLabel}
                        onChange={(value) => setQueryParams({ countryLabel: value })}
                        options={countryOptions}
                        isLoading={labelsLoading}
                    />
                </ListFilters>
                {advertiserId && (
                    <FormattedTable
                        columns={columns}
                        data={filteredData}
                        loading={loading}
                        topToolbar={renderTopToolbar()}
                    />
                )}
            </BodyContainer>
            <Routes>
                <Route
                    path="wizard/*"
                    element={
                        <MediaplanWizard
                            advertiserId={advertiserId}
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default MediaplanList;
