import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import { useAvailableFilters } from 'platform/analytics/hooks/useAvailableFilters';
import { useCompatibleColumns } from 'platform/analytics/hooks/useCompatibleColumns';
import { isDefined } from 'platform/common/common.types';

interface Props {
    templateId: string;
    componentId?: number;
    columns: string[];
}

/**
 * Adjusts component's `compatibleFilters` each time a column is added or removed.
 */
export const useCompatibleFilters = ({ templateId, componentId, columns }: Props) => {
    const dispatch = useDispatch();
    const template = useAnalyticsTemplate(templateId);

    const { compatibleColumns, metricOptions, dimensionOptions, compatibleColumnsLoading } =
        useCompatibleColumns({
            template,
            columns: columns.filter(isDefined),
        });

    const filters = useAvailableFilters({ compatibleColumns, template });

    useEffect(() => {
        if (compatibleColumnsLoading || !componentId) {
            return;
        }

        dispatch(
            analyticsSettingsActions.changeCompatibleFilters({ componentId, compatibleFilters: filters })
        );
    }, [compatibleColumnsLoading]);

    return {
        compatibleMetrics: metricOptions.flatMap((m) => m.nodes).map((m) => m.key),
        metricOptions,
        dimensionOptions,
        compatibleColumnsLoading,
    };
};
