import { ApolloError } from '@apollo/client';
import useMediaplanFilter, { MEDIA_PLAN_ID } from 'platform/analytics/hooks/useMediaplanFilter';
import { FilterInputProps } from 'platform/analytics/modes/Analytics';
import { PopoverContainerType } from 'platform/common/components/Popover/Popover';
import {
    DimensionFilterOption,
    FilterDefinition,
    ReportFilterOperator,
    ReportFilter,
} from '../../analytics.types';
import AnalyticsContainFilter from './AnalyticsContainFilter';
import AnalyticsInFilter from './AnalyticsInFilter';

interface Props {
    filter: ReportFilter;
    filterDefinition: FilterDefinition;
    options: DimensionFilterOption[];
    error?: ApolloError;
    filterInputProps?: FilterInputProps;
    supportedOperators: ReportFilterOperator[];
    disabled?: boolean;
    loading: boolean;
    refetch: () => void;
    onChange: (filter: ReportFilter) => void;
    onRemove: () => void;
    container?: PopoverContainerType;
}

const AnalyticsOperatorFilter = ({
    filter,
    filterDefinition,
    options,
    error,
    filterInputProps,
    supportedOperators,
    disabled,
    loading,
    refetch,
    onChange,
    onRemove,
    container,
}: Props) => {
    const { onMediaPlanFilterChange } = useMediaplanFilter({ filter });
    const multi = filterInputProps?.isMulti ?? filterDefinition.isMulti ?? true;

    const handleChange = (newFilter: ReportFilter) => {
        if (newFilter.key === MEDIA_PLAN_ID && newFilter.values.length) {
            onMediaPlanFilterChange(newFilter.values);
        }
        onChange(newFilter);
    };

    switch (filter.operator) {
        case 'CONTAINS':
        case 'CONTAINS_NOT':
            return (
                <AnalyticsContainFilter
                    name={filterDefinition.name}
                    filter={filter}
                    operators={supportedOperators}
                    options={options}
                    error={error}
                    multi={multi}
                    loading={loading}
                    disabled={disabled}
                    refetch={refetch}
                    onChange={handleChange}
                    onRemove={onRemove}
                    container={container}
                    operator={filter.operator}
                />
            );
        default:
            return (
                <AnalyticsInFilter
                    name={filterDefinition.name}
                    filter={filter}
                    operators={supportedOperators}
                    options={options}
                    error={error}
                    multi={multi}
                    loading={loading}
                    disabled={disabled}
                    refetch={refetch}
                    onChange={handleChange}
                    onRemove={onRemove}
                    container={container}
                />
            );
    }
};

export default AnalyticsOperatorFilter;
