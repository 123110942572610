import {
    fetchAdvertiser,
    updateAdvertiserAttributes,
} from 'platform/advertisers/services/advertiser.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { useLoading } from 'platform/common/hooks/useLoading';
import { toastSuccess } from 'platform/common/utils/toast.util';
import AdvertiserAdvancedSettingsForm from './AdvertiserAdvancedSettingsForm';

export const PATH = '/advertiser-settings/advanced';

const AdvertiserAdvancedSettingsFormContainer = () => {
    const canEdit = useFeature('ADVERTISER_SETTINGS_EDIT');
    const { id } = useActiveAdvertiserComponent();
    const [loading, withLoading] = useLoading();

    const handleOpen = async () => {
        const { additionalAttributes } = await fetchAdvertiser(id);
        return { additionalAttributes };
    };

    const handleSubmit = async (data: { additionalAttributes: Record<string, string> }) => {
        await withLoading(() => updateAdvertiserAttributes(id, data.additionalAttributes));
        toastSuccess('Advanced settings updated');
    };

    return (
        <BodyContainer helpKey="advertiser_advanced_settings">
            <FormContainer
                helpKey="advertiser_advanced_settings_form"
                canEdit={canEdit}
                onOpen={handleOpen}
                onSubmit={handleSubmit}
                isEdit
            >
                {(props) => <AdvertiserAdvancedSettingsForm loading={loading} {...props} />}
            </FormContainer>
        </BodyContainer>
    );
};

export default AdvertiserAdvancedSettingsFormContainer;
