import { ReactNode } from 'react';
import Placeholder from '../Placeholder/Placeholder';
import './Statistic.scss';

interface Props {
    name?: string;
    value?: string | number;
    children?: ReactNode;
    isLoading?: boolean;
}

const Statistic = ({ name, value, children, isLoading }: Props) => (
    <div className="Statistic">
        <div className="mb-2 Statistic-name">
            <p className="text-muted">{name}</p>
        </div>

        {isLoading && <Placeholder minHeight="16px" className="pt-1" />}

        {!isLoading && (
            <div className="Statistic-value">{children || <h5 className="fw-bold mb-0">{value}</h5>}</div>
        )}
    </div>
);

export default Statistic;
