import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { orderBy } from 'lodash-es';
import { fetchCategoryTrees } from 'platform/category/category.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import BubbleGroupChart from 'platform/common/components/BubbleGroupChart/BubbleGroupChart';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import Search from 'platform/common/components/Search/Search';
import Select from 'platform/common/components/Select/Select';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import UserFlow from 'platform/segments/components/UserFlow/UserFlow';

const formatUplift = (uplift: number) => `${DATA_TYPES.FLOAT.format(uplift)}x`;

interface ProductActionProbabilityType {
    segment_product_category?: string;
    segment_product?: string;
    adh_segment_unique_users_30d: number;
    adh_segment_unique_users_yesterday: number;
    adh_segment_unique_purchases_30d: number;
    adh_advertiser_purchase_probability_30d: number;
    uplift_30d: number;
}

interface Props {
    helpKey: string;
    advertiserId: number;
    dimension: 'segment_product_category' | 'segment_product';
    dimensionLabel: string;
    title: string;
}

const ProductActionProbability = ({ helpKey, advertiserId, dimension, dimensionLabel, title }: Props) => {
    const [classificationCode, setClassificationCode] = useState('SS_FUNNEL_1');
    const [searchQuery, setSearchQuery] = useState('');

    const { data, loading } = useQuery<{
        productActionProbability: ProductActionProbabilityType[];
    }>(
        gql`
            query ProductActionProbability(
                $advertiserId: Int!
                $classificationCode: String!
                $dimension: String!
            ) {
                productActionProbability(
                    advertiserId: $advertiserId
                    classificationCode: $classificationCode
                    dimension: $dimension
                ) {
                    segment_product_category
                    segment_product
                    adh_segment_unique_users_30d
                    adh_segment_unique_users_yesterday
                    adh_segment_unique_purchases_30d
                    adh_advertiser_purchase_probability_30d
                    uplift_30d
                }
            }
        `,
        {
            variables: {
                advertiserId,
                classificationCode,
                dimension,
            },
            fetchPolicy: 'network-only',
        }
    );

    const [{ loading: loadingFunnelOptions, data: funnelOptions }] = usePromise(
        [],
        async () => {
            const segmentSections = await fetchCategoryTrees({ rootCode: ['SS'], status: ['ACTIVE'] });
            const funnelCategories =
                segmentSections[0]?.children?.find((s) => s.code === 'SS_FUNNEL')?.children ?? [];
            return orderBy(
                funnelCategories.map((f) => ({
                    label: f.name,
                    value: f.code,
                })),
                (f) => Number(f.value.replace('SS_FUNNEL_', ''))
            );
        },
        []
    );

    const columns: TableColumn<ProductActionProbabilityType>[] = [
        {
            Header: dimensionLabel,
            type: DATA_TYPES.TEXT,
            accessor: dimension,
        },
        {
            Header: 'Funnel Users, 30d',
            accessor: 'adh_segment_unique_users_30d',
            type: DATA_TYPES.INT,
            maxWidth: 140,
        },
        {
            Header: 'Funnel Users, ystd',
            accessor: 'adh_segment_unique_users_yesterday',
            type: DATA_TYPES.INT,
            maxWidth: 140,
        },
        {
            Header: 'Actions, 30d',
            accessor: 'adh_segment_unique_purchases_30d',
            type: DATA_TYPES.INT,
            maxWidth: 140,
        },
        {
            Header: 'Action prob.',
            accessor: 'adh_advertiser_purchase_probability_30d',
            type: DATA_TYPES.P100,
            maxWidth: 140,
        },
        {
            Header: 'Uplift',
            type: DATA_TYPES.FLOAT,
            accessor: 'uplift_30d',
            maxWidth: 140,
            Cell: ({ original: { uplift_30d } }) => `${DATA_TYPES.FLOAT.format(uplift_30d)}x`,
        },
    ];

    const productActionProbability = (data?.productActionProbability ?? []).filter((item) =>
        item[dimension]?.includes(searchQuery)
    );

    return (
        <BodyContainer helpKey={helpKey}>
            <InsetBox className="p-3 mb-3">
                <UserFlow advertiserId={advertiserId} chartHeight={200} />
            </InsetBox>
            <div className="fw-bold">{title}</div>
            <div>Select funnel set to compare with</div>
            <div className="mt-3">
                <Select
                    value={classificationCode}
                    options={funnelOptions}
                    isClearable={false}
                    isLoading={loadingFunnelOptions}
                    onChange={setClassificationCode}
                />
            </div>
            <hr />
            <InsetBox className="p-3 mb-3">
                {loading && <Placeholder height="300px" />}
                {!loading && (
                    <BubbleGroupChart
                        data={productActionProbability}
                        xKey="adh_advertiser_purchase_probability_30d"
                        yKey="adh_segment_unique_users_30d"
                        xLabel="Conversion probability"
                        yLabel="Users, 30d."
                        getTooltipLabel={(item) => [
                            `${dimensionLabel}: ${item[dimension]}`,
                            `Uplift: ${formatUplift(item.uplift_30d)}`,
                            `Actions, 30d: ${item.adh_segment_unique_purchases_30d}`,
                            `Users, 30d: ${item.adh_segment_unique_users_30d}`,
                            `Users, ystd: ${item.adh_segment_unique_users_yesterday}`,
                        ]}
                    />
                )}
            </InsetBox>
            <FormattedTable
                exportData={{ filename: 'productActionProbability' }}
                columns={columns}
                data={productActionProbability}
                loading={loading}
                topToolbar={
                    <div>
                        <Search minLength={3} value={searchQuery} onSearch={setSearchQuery} />
                    </div>
                }
            />
        </BodyContainer>
    );
};

export default ProductActionProbability;
