import { useField } from 'formik';
import { OptionType } from 'platform/common/components/Select/select.types';
import SelectTree, { SelectTreeProps } from 'platform/common/components/SelectTree/SelectTree';
import { Validator, validator } from 'platform/common/utils/validators.util';

interface Props {
    name: string;
    validate?: Validator | Validator[];
    fetchError?: string;
    isValidationMessageVisible?: boolean;
    disabled?: boolean;
}

const FormSelectTree = <T extends OptionType>({
    id,
    name,
    value,
    validate,
    fetchError,
    options,
    isValidationMessageVisible = true,
    disabled,
    onChange,
    ...rest
}: SelectTreeProps<T> & Props) => {
    const [field, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });

    const { touched, error } = meta;

    const invalid = Boolean(touched && error);

    return (
        <>
            <SelectTree<T>
                {...rest}
                inputId={id || name}
                value={field.value || options.find((option) => !!option.default)}
                onChange={(val, action) => {
                    helpers.setValue(val);
                    if (onChange) onChange(val, action);
                }}
                onBlur={() => helpers.setTouched(true)}
                options={options}
                isDisabled={disabled}
            />
            {isValidationMessageVisible && (fetchError || invalid) ? (
                <div className="invalid-feedback pl-invalid-feedback">{error || fetchError}</div>
            ) : null}
        </>
    );
};

export default FormSelectTree;
