import { CSSProperties, memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { flow, identity, isEqual, isFunction } from 'lodash-es';
import { ANALYTICS_TABLE_PAGE_SIZE_OPTIONS } from 'platform/analytics/analytics.constants';
import {
    AnalyticsCompareType,
    AnalyticsMetadataDefinitions,
    ColumnColouringType,
    ColumnsColouring,
    EditableDimension,
    OlapReport,
    ReportFilter,
    ReportHeaderColumn,
    Template,
} from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import {
    addColumnIcons,
    addCustomerJourneyLinks,
    addDrills,
    addFirstDependantIntoSubtext,
    addHeaderTooltips,
    addInlineActions,
    getDimensionsWithDependants,
    handleCompare,
    handleSpecialColumns,
    hideDependantColumns,
    makeCellsEditable,
    makeDimensionColsSticky,
    processEmptyColumns,
} from 'platform/analytics/reportComponents/ReportTableContainer/columnBuilders';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { Sort } from 'platform/common/common.types';
import { ExportAction } from 'platform/common/components/DataExportButton/DataExportButton';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { typeOf } from 'platform/common/dataTypes';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';

interface Props {
    template: Template;
    dimensionFilters: ReportFilter[];
    dimensions: string[];
    metrics: string[];
    editableDimensions: EditableDimension[];
    report: OlapReport;
    compareReport?: OlapReport;
    compareWith?: AnalyticsCompareType;
    TopToolbar?: ReactNode;
    hasInlineActions?: boolean;
    hideEmptyColumns: boolean;
    id: number;
    sort?: Sort[];
    pageSize?: number;
    pageNumber?: number;
    summaryTable?: boolean;
    style?: CSSProperties;
    containerStyle?: CSSProperties;
    definitions: AnalyticsMetadataDefinitions;
    exportActions?: ExportAction[];
    columnsColouring?: ColumnsColouring;
    editMode: boolean;
    exportName: string;
    manual?: boolean;
    defaultPageSize?: number;
    topRightToolbar?: ReactNode;
    visible?: boolean;
    onDataExport: () => void;
    refetchReport: () => void;
    onColumnRemove?: (column: TableColumn) => void;
    onDataExplorerClick?: () => void;
    onPageChange?: (pageNumber: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    onSortChange?: (sort: Sort[]) => void;
    onColumnColouringChange?: (columnId: string, columnColouring: ColumnColouringType) => void;
}

const arePropsEqual = (prevProps: Props, nextProps: Props) =>
    Object.keys(prevProps).every(
        (key: keyof Props) =>
            key === 'exportActions' || isFunction(prevProps[key]) || isEqual(prevProps[key], nextProps[key])
    );

const createColumnDefinitions =
    (dimensions: string[]) =>
    (columns: ReportHeaderColumn[]): TableColumn[] =>
        columns.map((column) => ({
            Header: column.name,
            HeaderText: column.name,
            accessor: column.id,
            id: column.id,
            className: dimensions.includes(column.key) ? 'dimensionColumn' : undefined,
            autoWidth: true,
            type: typeOf(column),
            isDerivative: column.isDerivative,
        }));

const ReportTable = memo(
    ({
        visible,
        dimensions,
        template,
        dimensionFilters,
        report,
        metrics,
        editableDimensions,
        compareReport,
        TopToolbar,
        compareWith,
        sort,
        pageSize,
        pageNumber,
        summaryTable = false,
        id,
        hasInlineActions = false,
        hideEmptyColumns,
        style,
        containerStyle,
        definitions,
        exportActions,
        columnsColouring,
        editMode,
        exportName,
        manual = true,
        defaultPageSize,
        topRightToolbar,
        onDataExport,
        refetchReport,
        onColumnRemove,
        onDataExplorerClick,
        onPageChange,
        onPageSizeChange,
        onSortChange,
        onColumnColouringChange,
    }: Props) => {
        const extendedDimensions = getDimensionsWithDependants(dimensions, template.dimensions);
        const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);
        const { inlineEditing } = useTypedSelector(analyticsSelectors.settings);
        const advertiserIds =
            (dimensionFilters.find((f) => f.key === 'advertiser_id')?.values as number[]) || [];

        const buildColumns = flow(
            createColumnDefinitions(extendedDimensions),
            addHeaderTooltips(report),
            addColumnIcons(definitions.metrics, columnsColouring, onColumnRemove, onColumnColouringChange),
            addDrills(report, dimensionFilters, id),
            addFirstDependantIntoSubtext(template.dimensions),
            hideDependantColumns(template.dimensions, dimensions),
            handleSpecialColumns(commonClassifiers, definitions),
            handleCompare(compareWith, report, compareReport, metrics, extendedDimensions),
            addCustomerJourneyLinks(advertiserIds),
            hasInlineActions
                ? addInlineActions(
                      report.header,
                      dimensionFilters.filter((dimensionFilter) => dimensionFilter.values.length),
                      refetchReport
                  )
                : identity,
            processEmptyColumns(report, hideEmptyColumns),
            makeCellsEditable(editableDimensions, !!inlineEditing, editMode),
            makeDimensionColsSticky(extendedDimensions)
        );

        if (summaryTable) {
            const buildSummaryTableColumns = flow(
                createColumnDefinitions(extendedDimensions),
                addHeaderTooltips(report),
                addColumnIcons(
                    definitions.metrics,
                    columnsColouring,
                    onColumnRemove,
                    onColumnColouringChange
                ),
                addFirstDependantIntoSubtext(template.dimensions),
                hideDependantColumns(template.dimensions, dimensions),
                handleSpecialColumns(commonClassifiers, definitions),
                handleCompare(compareWith, report, compareReport, metrics, extendedDimensions),
                processEmptyColumns(report, hideEmptyColumns),
                makeDimensionColsSticky(extendedDimensions)
            );

            return (
                <>
                    <FormattedTable
                        visible={visible}
                        className="SummaryTable"
                        style={style}
                        containerStyle={containerStyle}
                        columns={buildSummaryTableColumns(report.header)}
                        data={report.rows}
                        sorted={sort}
                        containerClass="shadow-none"
                        showPagination={false}
                        multiSort
                        manual
                        onSortedChange={onSortChange}
                        pageSizeOptions={ANALYTICS_TABLE_PAGE_SIZE_OPTIONS}
                        topRightToolbar={topRightToolbar}
                    />
                    <ReportTracingTips report={report} compareReport={compareReport} />
                </>
            );
        }

        return (
            <>
                <FormattedTable
                    visible={visible}
                    topRightToolbar={topRightToolbar}
                    defaultPageSize={defaultPageSize}
                    topToolbar={TopToolbar}
                    exportData={{
                        loadData: onDataExport,
                        filename: exportName,
                        additionalActions: exportActions,
                    }}
                    style={style}
                    containerStyle={containerStyle}
                    columns={buildColumns(report.header)}
                    data={report.rows}
                    pages={manual ? report.paging.totalPages : undefined}
                    page={pageNumber}
                    sorted={sort}
                    pageSize={pageSize}
                    columnsColouring={columnsColouring}
                    containerClass="shadow-none"
                    resizable
                    multiSort
                    manual={manual}
                    onDataExplorerClick={onDataExplorerClick}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    onSortedChange={onSortChange}
                    pageSizeOptions={ANALYTICS_TABLE_PAGE_SIZE_OPTIONS}
                />
                <ReportTracingTips report={report} compareReport={compareReport} />
            </>
        );
    },
    arePropsEqual
);

export default ReportTable;
