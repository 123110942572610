import { useParams } from 'react-router-dom';
import { toAlgorithmApi, toAlgorithmForm } from 'platform/algorithms/algorithm.mapper';
import {
    fetchAlgorithm,
    fetchAlgorithmTemplate,
    updateAlgorithm,
} from 'platform/algorithms/algorithms.service';
import { AlgorithmFormModel } from 'platform/algorithms/algorithms.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import AlgorithmForm from './AlgorithmForm';

const AlgorithmEdit = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const handleOpen = async (): Promise<AlgorithmFormModel> => {
        const algorithm = await fetchAlgorithm(id);
        const template = await fetchAlgorithmTemplate(algorithm.algorithmTemplateId);

        return toAlgorithmForm(algorithm, template);
    };

    const handleSubmit = (data: AlgorithmFormModel) => updateAlgorithm(id, toAlgorithmApi(data));

    return (
        <FormContainer
            helpKey="algorithm_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            sidePanel
            isEdit
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <AlgorithmForm {...props} />}
        </FormContainer>
    );
};

export default AlgorithmEdit;
