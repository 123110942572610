import { orderBy } from 'lodash-es';
import { getMediaInsertionLinks } from 'platform/campaign/campaign/services/mediaplan.service';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { MATCH_TYPE_LABELS, MediaInsertionLink } from 'platform/mediaplan/mediaplan.types';

const columns: TableColumn<MediaInsertionLink>[] = [
    {
        Header: 'Mediaplan',
        accessor: 'mediaplanName',
        minWidth: 175,
        Cell: ({ value }) => (
            <span className="text-truncate" title={value}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Media insertion',
        accessor: 'mediaInsertionName',
        minWidth: 375,
        Cell: ({ value }) => (
            <span className="text-truncate" title={value}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Date from',
        accessor: 'mediaInsertionDateFrom',
        type: DATA_TYPES.DATE,
        minWidth: 100,
    },
    {
        Header: 'Date to',
        accessor: 'mediaInsertionDateTo',
        type: DATA_TYPES.DATE,
        minWidth: 100,
    },
    {
        Header: 'Match',
        accessor: 'matchType',
        minWidth: 75,
        Cell: ({ original: { matchType } }) => MATCH_TYPE_LABELS[matchType],
    },
];

const CampaignImportMediaInsertions = ({ campaignId }: { campaignId: number | null }) => {
    const [{ data, loading }] = usePromise<MediaInsertionLink[]>(
        [],
        () => (campaignId ? getMediaInsertionLinks({ campaignId }) : Promise.resolve([])),
        [campaignId]
    );

    return (
        <FormattedTable
            columns={columns}
            data={orderBy(data, ['mediaInsertionDateFrom', 'mediaInsertionDateTo'])}
            loading={loading}
            containerClass="mb-0"
            stickyHeader={false}
            showPagination={false}
            columnsAlignedFromIndex={0}
            NoDataComponent={() => null}
        />
    );
};

export default CampaignImportMediaInsertions;
