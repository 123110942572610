import { useQuery } from '@apollo/client';
import { ChartOptions } from 'chart.js';
import { cloneDeep, round } from 'lodash-es';
import moment from 'moment';
import { StatisticWithSeries } from 'platform/common/common.types';
import { KpiOverviewChartType } from '../../constants/chart.constants';
import AdvertiserSegmentStatisticsQuery from '../../queries/advertiserSegmentStatistics.graphql';
import KPIOverviewChart from './KPIOverviewChart';
import KPIOverviewNavigation from './KPIOverviewNavigation';

type Props = {
    advertiserId: number;
};

type Query = {
    advertiser: {
        stats: {
            advertiser_unique_users_30d: StatisticWithSeries;
            advertiser_unique_users_yesterday: StatisticWithSeries;
            advertiser_all_actions_30d: StatisticWithSeries;
            advertiser_all_actions_yesterday: StatisticWithSeries;
            advertiser_action_probability_30d: StatisticWithSeries;
            advertiser_action_probability_yesterday: StatisticWithSeries;
            advertiser_attributed_actions_30d: StatisticWithSeries;
            advertiser_attributed_actions_yesterday: StatisticWithSeries;
        };
    };
};

const DATE_FORMAT = 'YYYY-MM-DD';

const defaultChartConfiguration: ChartOptions = {
    animation: {
        duration: 0,
    },
    maintainAspectRatio: false,
    elements: {
        line: { borderWidth: 1.8 },
        point: { radius: 0, hitRadius: 10, hoverRadius: 4 },
    },
    plugins: {
        legend: {
            display: true,
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                tooltipFormat: 'yyyy-MM-DD', // Format for tooltip display
                displayFormats: {
                    hour: 'MMM D', // Display format on the X-axis ticks
                },
            },
            ticks: {
                maxRotation: 0, // Sets the angle of the label text to 0 degrees
                autoSkip: true, // Automatically skips labels to avoid overlapping
                autoSkipPadding: 15,
            },
        },

        y: {
            suggestedMin: 0,
            min: 0,
            ticks: {
                autoSkip: true,
                autoSkipPadding: 15,
            },
        },
    },
};

const defaultDataSetConfiguration = {
    label: 'All devices',
};

const probabilityChartConfiguration: ChartOptions<'line'> = {
    ...defaultChartConfiguration,
    scales: {
        ...defaultChartConfiguration.scales,
        y: {
            min: 0,
            ticks: {
                callback: (value: any) => `${value}%`,
            },
        },
    },
};

const roundDecimal = (value: number) => round(value, 2);

const prepareProbabilityData = (data: StatisticWithSeries) => ({
    ...data,
    series: {
        ...data.series,
        values: data.series.values.map((value) => roundDecimal(value)),
    },
});

const SegmentSparklets = ({ advertiserId }: Props) => {
    const { loading, data: fetchedData } = useQuery<Query>(AdvertiserSegmentStatisticsQuery, {
        variables: {
            advertiserId,
            period: {
                from: moment()
                    .subtract(30 - 1, 'd')
                    .format(DATE_FORMAT),
            },
        },
        fetchPolicy: 'no-cache' as const,
    });

    const data = !loading && fetchedData ? cloneDeep(fetchedData.advertiser) : undefined;

    const options = [
        {
            tab: KpiOverviewChartType.USERS,
            label: 'Users, 30d',
            value: data && String(data.stats.advertiser_unique_users_30d.value),
            Component: () => (
                <KPIOverviewChart
                    data={data && data.stats.advertiser_unique_users_yesterday}
                    chartOptions={defaultChartConfiguration}
                    datasetOptions={defaultDataSetConfiguration}
                    loading={loading}
                />
            ),
        },
        {
            tab: KpiOverviewChartType.ALL_ACTIONS,
            label: 'All actions, 30d',
            value: data && String(data.stats.advertiser_all_actions_30d.value),
            Component: () => (
                <KPIOverviewChart
                    data={data && data.stats.advertiser_all_actions_yesterday}
                    chartOptions={defaultChartConfiguration}
                    datasetOptions={defaultDataSetConfiguration}
                    loading={loading}
                />
            ),
        },
        {
            tab: KpiOverviewChartType.ATTRIBUTED_ACTIONS,
            label: 'Attributed actions, 30d',
            value: data && String(data.stats.advertiser_attributed_actions_30d.value),
            Component: () => (
                <KPIOverviewChart
                    data={data && data.stats.advertiser_attributed_actions_yesterday}
                    chartOptions={defaultChartConfiguration}
                    datasetOptions={defaultDataSetConfiguration}
                    loading={loading}
                />
            ),
        },
        {
            tab: KpiOverviewChartType.ACTION_PROBABILITY,
            label: 'Action probability, 30d',
            value: data && `${roundDecimal(data.stats.advertiser_action_probability_30d.value)}%`,
            Component: () => (
                <KPIOverviewChart
                    data={data && prepareProbabilityData(data.stats.advertiser_action_probability_yesterday)}
                    chartOptions={probabilityChartConfiguration}
                    datasetOptions={defaultDataSetConfiguration}
                    loading={loading}
                />
            ),
        },
    ];

    return <KPIOverviewNavigation options={options} loading={loading} />;
};

export default SegmentSparklets;
