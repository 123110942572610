import { AnyAction } from 'redux';
import { mapValues } from 'lodash-es';
import { RootState } from 'platform/rootState.type';

type ReducerWithRootArg<S, R> = (state: S | undefined, action: AnyAction, rootState?: R) => S;

type ReducerMap<S, R> = { [K in keyof S]: ReducerWithRootArg<S[K], R> };

// This reducer combinator mimics combineReducers function from redux package with
// one addition - it passes root state to every sub-reducers thus allowing it
// to make decision depending on external state to reducer. Important point - it
// still does allow changing only the specific slice of state.
export const combineReducersWithRoot =
    <S extends Record<string, any>>(reducerMap: ReducerMap<S, RootState | S>) =>
    (state: S = {} as any, action: AnyAction, rootState?: RootState): S =>
        mapValues(reducerMap, (reducer, key) => reducer(state[key], action, rootState ?? state)) as S;
