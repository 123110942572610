import { groupBy, orderBy } from 'lodash-es';
import { searchMediaInsertions } from 'platform/campaign/campaign/services/mediaplan.service';
import { assertIsDefined } from 'platform/common/common.assert';
import { isNotEmpty, TableCell } from 'platform/common/common.types';
import DynamicRowsTable from 'platform/common/components/DynamicRowsTable/DynamicRowsTable';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditContainer from 'platform/common/components/InlineEditContainer/InlineEditContainer';
import SelectTree from 'platform/common/components/SelectTree/SelectTree';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import {
    MATCH_TYPE_LABELS,
    MediaInsertionLink,
    MediaInsertionSearchResult,
} from 'platform/mediaplan/mediaplan.types';

type Props = {
    advertiserId: number;
    mediaInsertionLinks: MediaInsertionLink[];
    onChange: (newLinks: Partial<MediaInsertionLink>[]) => void;
};

type MediaplanOption = {
    mediaplanName: string;
    nodes?: MediaInsertionSearchResult[];
};

const columnDefinitions: TableColumn<MediaInsertionLink>[] = [
    {
        Header: 'Mediaplan',
        accessor: 'mediaplanName',
        minWidth: 175,
        Cell: ({ value }) => (
            <span className="text-truncate" title={value}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Media insertion',
        accessor: 'mediaInsertionName',
        minWidth: 375,
        Cell: ({ value }) => (
            <span className="text-truncate" title={value}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Date from',
        accessor: 'mediaInsertionDateFrom',
        type: DATA_TYPES.DATE,
        minWidth: 100,
    },
    {
        Header: 'Date to',
        accessor: 'mediaInsertionDateTo',
        type: DATA_TYPES.DATE,
        minWidth: 100,
    },
    {
        Header: 'Match',
        accessor: 'matchType',
        minWidth: 75,
        Cell: ({ original: { matchType } }: TableCell<MediaInsertionLink>) => MATCH_TYPE_LABELS[matchType],
    },
];

const MediaInsertionLinksTable = ({ advertiserId, mediaInsertionLinks, onChange }: Props) => {
    const [{ data: advertiserInsertions, loading: loadingAdvertiserInsertions }] = usePromise<
        MediaInsertionSearchResult[]
    >([], () => searchMediaInsertions({ advertiserId }), [advertiserId]);

    const explicitLinks = mediaInsertionLinks.filter((link) => link.matchType === 'EXPLICIT');
    const availableInsertions = advertiserInsertions.filter(
        (i) => !explicitLinks.some((link) => link.mediaInsertionId === i.id)
    );
    const options: MediaplanOption[] = orderBy(
        Object.values(groupBy(availableInsertions, (i) => i.mediaplanId)).map(
            (insertions): MediaplanOption => ({
                mediaplanName: insertions[0].mediaplanName,
                nodes: orderBy(insertions, (i) => i.name),
            })
        ),
        (option) => option.mediaplanName
    );

    const toExplicitLink = (insertionId: number): Partial<MediaInsertionLink> => {
        const insertion = advertiserInsertions.find((i) => i.id === insertionId);
        assertIsDefined(insertion, `insertion with id ${insertionId}`);
        return {
            mediaplanId: insertion.mediaplanId,
            mediaplanName: insertion.mediaplanName,
            mediaInsertionId: insertion.id,
            mediaInsertionName: insertion.name,
            mediaInsertionDateFrom: insertion.dateFrom,
            mediaInsertionDateTo: insertion.dateTo,
            matchType: 'EXPLICIT',
        };
    };

    return (
        <DynamicRowsTable
            columns={columnDefinitions}
            data={orderBy(mediaInsertionLinks, ['mediaInsertionDateFrom', 'mediaInsertionDateTo'])}
            isRemovable={(link: MediaInsertionLink) => link.matchType === 'EXPLICIT'}
            onRemove={(removed: MediaInsertionLink) =>
                onChange(
                    mediaInsertionLinks.filter((link) => link.mediaInsertionId !== removed.mediaInsertionId)
                )
            }
            NewRowComponent={() => (
                <div className="flex-grow-1">
                    <InlineEditContainer placeholder="assign manually">
                        {({ toggleEditMode }) => (
                            <SelectTree
                                className="my-1 me-1"
                                options={options}
                                isLoading={loadingAdvertiserInsertions}
                                value={undefined}
                                onChange={(insertionId) => {
                                    if (isNotEmpty(insertionId)) {
                                        onChange([...mediaInsertionLinks, toExplicitLink(insertionId)]);
                                    }
                                }}
                                menuIsOpen
                                closeMenuOnSelect
                                autoFocus
                                onBlur={toggleEditMode}
                                getOptionValue={(i: MediaInsertionSearchResult) => i.id}
                                getOptionLabel={(i: MediaInsertionSearchResult) =>
                                    `${i.name} (${i.dateFrom} - ${i.dateTo})`
                                }
                                getOptionParentLabel={(o: MediaplanOption) => o.mediaplanName}
                            />
                        )}
                    </InlineEditContainer>
                </div>
            )}
        />
    );
};

export default MediaInsertionLinksTable;
