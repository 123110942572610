import { Action } from 'platform/common/common.types';
import {
    SecondaryPreset,
    DateRangePreset,
    getCompareRange,
    getFormattedRangeDates,
} from 'platform/common/utils/date.util';
import { RootState } from 'platform/rootState.type';

const CHANGE_PERIODS = 'common/dateFilter/CHANGE_PERIODS';
const CHANGE_MONTH = 'common/dateFilter/CHANGE_MONTH';
export interface Periods {
    primaryFrom?: string;
    primaryTo?: string;
    secondaryFrom?: string;
    secondaryTo?: string;
    preset?: DateRangePreset;
    secondaryPreset?: SecondaryPreset;
    month?: number;
}

const defaultState: Periods = {
    preset: 'LAST_30_DAYS',
};

const reducer = (state: Periods = defaultState, action: Action): Periods => {
    switch (action.type) {
        case CHANGE_PERIODS: {
            return action.payload;
        }
        case CHANGE_MONTH: {
            return {
                ...state,
                month: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;

const rootSelector = (state: RootState) => state.common.dateFilter;
const periodsSelector = (state: RootState): Periods => {
    const periods = rootSelector(state);
    const { from: primaryFrom, to: primaryTo } = getFormattedRangeDates(
        periods.preset,
        periods.primaryFrom,
        periods.primaryTo
    );

    const { from: secondaryFrom, to: secondaryTo } = getCompareRange({
        primaryFrom,
        primaryTo,
        secondaryPreset: periods.secondaryPreset,
        secondaryFrom: periods.secondaryFrom,
        secondaryTo: periods.secondaryTo,
    });

    return {
        preset: periods.preset,
        primaryFrom,
        primaryTo,
        secondaryPreset: periods.secondaryPreset,
        secondaryFrom,
        secondaryTo,
    };
};

export const dateFilterSelectors = {
    month: (state: RootState) => rootSelector(state).month,
    date: (state: RootState) => {
        const periods = periodsSelector(state);
        return {
            from: periods.primaryFrom,
            to: periods.primaryTo,
        };
    },
    periods: periodsSelector,
};

export const dateFilterActions = {
    changePeriods: (periods: Periods) => ({
        type: CHANGE_PERIODS,
        payload: periods,
    }),
    changeMonth: (month: number | undefined) => ({
        type: CHANGE_MONTH,
        payload: month,
    }),
};
