import { ColumnDefinitionOption } from 'platform/analytics/analytics.types';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import { useCompatibleColumns } from 'platform/analytics/hooks/useCompatibleColumns';
import { useMetricsWithData } from 'platform/analytics/hooks/useMetricsWithData';
import { filterOptionAdvanced } from 'platform/analytics/reportComponents/listFilter';
import SelectSortableMultiValueWithTooltip from 'platform/common/components/Select/SelectSortableMultiValueWithTooltip';
import SelectTreeOptionWithTooltip from 'platform/common/components/SelectTree/SelectTreeOptionWithTooltip';
import { sortByName } from 'platform/common/utils/array.util';
import { Validator } from 'platform/common/utils/validators.util';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';

interface Props {
    name: string;
    values: string[];
    validate?: Validator | Validator[];
}

const DefaultMetricsSelect = ({ name, values, validate }: Props) => {
    const { showMetricsWithData, setShowOnlyMetricsWithData } = useMetricsWithData();
    const template = useAnalyticsTemplate('all_columns');
    const { metricOptions, compatibleColumnsLoading } = useCompatibleColumns({ template, columns: values });

    return (
        <FormSelectTree<ColumnDefinitionOption>
            name={name}
            className="mb-3"
            options={metricOptions.map((option) => ({
                ...option,
                nodes: sortByName(option.nodes ?? []).map((o) => ({
                    ...o,
                    disabled: compatibleColumnsLoading,
                })),
            }))}
            filterOption={(option, input) =>
                filterOptionAdvanced(option.key, option.name, input) && showMetricsWithData(option.key)
            }
            onHasDataFilterChange={setShowOnlyMetricsWithData}
            components={{
                Option: SelectTreeOptionWithTooltip,
                MultiValue: SelectSortableMultiValueWithTooltip,
            }}
            getOptionParentLabel={(option) => option.name}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.key}
            isMulti
            isBreadcrumbsShown={false}
            isLoading={compatibleColumnsLoading}
            validate={validate}
        />
    );
};

export default DefaultMetricsSelect;
