import { ComponentProps, ComponentType, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash-es';
import { Modify, TableCell } from 'platform/common/common.types';
import { ChildrenLoader, TableColumnProcessed } from './FormattedTable';
import { ReactTableProps } from './ReactTableWithStickyColumns';
import ReactTableWithStickyHeader from './ReactTableWithStickyHeader';
import TablePlaceholder from './TablePlaceholder';
import './NestedTable.scss';

type Props = Modify<
    ReactTableProps,
    {
        columns: TableColumnProcessed[];
        elements: Record<string, any>[];
        childrenLoaders?: ChildrenLoader[];
        columnsAlignedFromIndex: number;
        offset: number;
        nodesExpanded: boolean;
        depth: number;
        childrenCountField?: string;
        stickyHeader?: boolean;
        withLoadMore?: boolean;
    }
>;

const getExpandedNodes = (rowData: Record<string, any>[], childrenField: string, expanded = false) =>
    rowData.reduce((acc, elem, index) => {
        if (!elem[childrenField]?.length) {
            return acc;
        }
        return { ...acc, [index]: expanded };
    }, {});

// TODO: use ExpanderComponent prop
// TODO: do not await fetch fn on toggle

const NestedTable = (props: Props) => {
    const {
        elements,
        columns,
        childrenCountField,
        offset,
        nodesExpanded,
        className,
        columnsAlignedFromIndex,
        NoDataComponent,
        defaultSorted,
        loading,
        depth = 0,
        stickyHeader = true,
        childrenLoaders = [],
        withLoadMore = false,
    } = props;
    const [rowData, setRowData] = useState<Record<string, any>[]>(elements);
    const [expandedNodes, setExpandedNodes] = useState<Record<number, boolean>>({});
    const childrenLoader = childrenLoaders[depth];
    const childrenField = childrenLoader?.field;

    useEffect(() => {
        if (isEqual(elements, rowData)) return;
        setExpandedNodes({});
        setRowData(elements);
    }, [elements]);

    useEffect(() => {
        if (!isEqual(elements, rowData) && nodesExpanded !== undefined) {
            setExpandedNodes(getExpandedNodes(elements, childrenField, nodesExpanded));
        }
    }, [elements, nodesExpanded]);

    const expanderColumn: TableColumnProcessed = {
        id: 'expander',
        className: 'cell-align-right NestedTable-expander',
        headerClassName: 'cell-align-right',
        width: 40 + depth * offset,
        Cell: ({ viewIndex, original }: TableCell<any>) => {
            if (!childrenLoader) {
                return null;
            }
            if (childrenCountField && !original[childrenCountField]) {
                return null;
            }
            if (!childrenLoader.lazyLoad && !original[childrenField]?.length) {
                return null;
            }
            return (
                <i
                    tabIndex={-1}
                    role="button"
                    onClick={() => toggleExpanded(original, viewIndex)}
                    className={classNames('fa', 'fa-angle-right', 'NestedTable-expandBtn', {
                        'NestedTable-expandBtn--expanded': expandedNodes[viewIndex],
                    })}
                />
            );
        },
    };

    const loadData = async (original: any) => {
        const currentChildren = (original?.[childrenField] ?? []) as any[];
        const newChildren = childrenLoader?.lazyLoad
            ? await childrenLoader.lazyLoad(original, currentChildren.length)
            : (original[childrenLoader?.field] as any[]);

        setRowData(
            rowData.map((parent) =>
                parent === original
                    ? { ...parent, [childrenField]: [...currentChildren, ...(newChildren ?? [])] }
                    : parent
            )
        );
    };

    const toggleExpanded = async (original: any, index: number) => {
        if (isEmpty(original?.[childrenField])) {
            await loadData(original);
        }
        setExpandedNodes((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const isRootLevel = depth === 0;
    const columnsDifferInTree = columns.some((column) => column.depth);

    const innerColumns = columns
        .map((column, index) => {
            const newColumn = { ...column };

            if (index === columnsAlignedFromIndex) {
                const width = column.width;
                const minWidth = column.minWidth;
                if (width) {
                    newColumn.width = width - offset;
                }
                if (minWidth) {
                    newColumn.minWidth = minWidth - offset;
                }
            }

            return newColumn;
        })
        .filter((column) => (columnsDifferInTree ? (column.depth ?? 0) >= depth + 1 : true));

    const TableComponent: ComponentType<
        ComponentProps<typeof ReactTableWithStickyHeader> | ComponentProps<typeof ReactTable>
    > = isRootLevel && stickyHeader ? ReactTableWithStickyHeader : ReactTable;

    return (
        <TableComponent
            className={classNames(
                '-highlight NestedTable overflow-auto',
                isRootLevel && `-root`,
                columnsDifferInTree && 'columnsDifferInTree',
                className
            )}
            TheadComponent={
                isRootLevel || columnsDifferInTree ? undefined : () => <div className="NestedTable-header" />
            }
            columns={[
                {
                    id: 'fake-expander',
                    expander: true,
                    show: false,
                },
                expanderColumn,
                ...columns.filter((column) => (columnsDifferInTree ? (column.depth ?? 0) === depth : true)),
            ]}
            data={rowData}
            showPagination={false}
            pageSize={rowData.length}
            expanded={expandedNodes}
            onSortedChange={() => setExpandedNodes({})}
            SubComponent={(params: any) => (
                <>
                    <NestedTable
                        {...props}
                        columns={innerColumns}
                        elements={params.original[childrenField] || []}
                        depth={depth + 1}
                    />
                    {childrenLoader?.lazyLoad && withLoadMore && (
                        <div className="NestedTable-loadMore">
                            <Button onClick={() => loadData(params.original)} className="mb-2">
                                Load More
                            </Button>
                        </div>
                    )}
                </>
            )}
            loadingText={isRootLevel ? 'Loading...' : ''}
            noDataText={isRootLevel ? 'No rows found' : ''}
            loading={isRootLevel ? loading : false}
            LoadingComponent={TablePlaceholder}
            NoDataComponent={NoDataComponent}
            defaultSorted={depth === 0 ? defaultSorted : undefined}
            resizable={false}
        />
    );
};

export default NestedTable;
