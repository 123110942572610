import { ReactNode, useState } from 'react';
import { Badge, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import classNames from 'classnames';
import { CREATIVE_TYPES_LABELS, getCreativeTypeName } from 'platform/creatives/constants/creativeTypes';
import { CreativeModel, DeliveryStatus } from '../../types/creative.types';
import CreativeIntegrationIcon from '../CreativeIntegrations/CreativeIntegrationIcon';
import './CreativeCard.scss';

interface Props {
    creative: CreativeModel;
    isExpanded?: boolean;
    isDemoMode?: boolean;
    hasErrors?: boolean;
    children: ReactNode;
    onRemove: () => void;
}

const CreativeCard = ({
    creative,
    isExpanded = false,
    isDemoMode = false,
    hasErrors,
    children,
    onRemove,
}: Props) => {
    const [expanded, setExpanded] = useState(isExpanded);
    const expandArrowName = expanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

    const creativeName = isDemoMode ? 'Creative Name' : creative.name;

    return (
        <Card className={classNames('CreativeCard', { hasErrors: hasErrors && !expanded })}>
            <CardHeader className="d-flex align-items-center">
                {creative.integration && (
                    <span className="me-2">
                        <CreativeIntegrationIcon id={creative.id} integration={creative.integration} />
                    </span>
                )}
                {creative.type && (
                    <Badge className="CreativeCard-badge me-2">{getCreativeTypeName(creative)}</Badge>
                )}
                <span
                    className={classNames('CreativeCard-title', {
                        isInactive:
                            creative.deliveryStatus === DeliveryStatus.PAUSED ||
                            creative.deliveryStatus === DeliveryStatus.REMOVED,
                    })}
                >
                    {creative.isNew ? `New ${CREATIVE_TYPES_LABELS[creative.type]} creative` : creativeName}
                </span>
                <div className="card-actions">
                    {creative.isNew && (
                        <button type="button" onClick={onRemove}>
                            <i className="fa fa-times" />
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn-minimize"
                        onClick={() => setExpanded((prev) => !prev)}
                    >
                        <i className={expandArrowName} />
                    </button>
                </div>
            </CardHeader>
            <Collapse isOpen={expanded}>
                <CardBody>{children}</CardBody>
            </Collapse>
        </Card>
    );
};

export default CreativeCard;
