import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { PersonalPrompt } from 'platform/chat/chat.types';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormContainer, { getLabels } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

interface Props {
    isCreate: boolean;
    toggle: () => void;
    onOpen: () => Promise<PersonalPrompt>;
    onSubmit: (formValues: PersonalPrompt) => Promise<void>;
}

const ChatPersonalPromptModal = ({ toggle, onSubmit, onOpen, isCreate }: Props) => {
    const labels = getLabels(!isCreate, true);

    return (
        <Modal isOpen toggle={toggle}>
            <ModalHeader onClose={toggle}>{`${labels.prefix} personal prompt`}</ModalHeader>
            <ModalBody>
                <FormContainer
                    helpKey="chat_personal_prompt_form"
                    onOpen={onOpen}
                    onSubmit={async (formValues) => {
                        onSubmit(formValues);
                        toggle();
                    }}
                    isEdit={!isCreate}
                >
                    {({ formikProps }) => (
                        <>
                            <FormRow label="Name">
                                <FormInput validate={required} name="name" />
                            </FormRow>
                            <FormRow label="Prompt">
                                <FormInput
                                    inputStyle={{ minHeight: 200 }}
                                    type="textarea"
                                    validate={required}
                                    name="prompt"
                                />
                            </FormRow>
                            <div className="d-flex justify-content-end">
                                <Button className="me-1" outline onClick={toggle}>
                                    Cancel
                                </Button>
                                <Button onClick={formikProps.submitForm} disabled={!formikProps.isValid}>
                                    {labels.submit}
                                </Button>
                            </div>
                        </>
                    )}
                </FormContainer>
            </ModalBody>
        </Modal>
    );
};

export default ChatPersonalPromptModal;
