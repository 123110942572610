import { useSelector } from 'react-redux';
import { RootState } from 'platform/rootState.type';

/* Redux sector with dap state binded */
const useTypedSelector = <TState>(
    selector: (state: RootState) => TState,
    equalityFn?: (left: any, right: any) => boolean
): TState => useSelector(selector, equalityFn);

export default useTypedSelector;
