import { useState } from 'react';
import { keyBy } from 'lodash-es';
import { OlapReport } from 'platform/analytics/analytics.types';
import ChatAssistantMessage from 'platform/chat/ChatMessage/ChatAssistantMessage/ChatAssistantMessage';
import { CHAT_SUMMARY_AGENT_ID } from 'platform/chat/chat.constants';
import { sendChatMessages } from 'platform/chat/chat.service';
import { ChatMessagePayload } from 'platform/chat/chat.types';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import Spinner from 'platform/common/components/Spinner/Spinner';
import { typeOf } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';

const produceRowsForSummary = (data: OlapReport) => {
    const headerMap = keyBy(data.header, (h) => h.key);
    return data.rows.map((row) =>
        Object.entries(row)
            .map(([key, originalValue]) => {
                const col = headerMap[key];
                return col ? { [col.name]: typeOf(col).format(originalValue) } : {};
            })
            .reduce((acc, v) => ({ ...acc, ...v }))
    );
};

interface Props {
    data: OlapReport;
}

const ChatAssistantSummary = ({ data }: Props) => {
    const [loadSummary, setLoadSummary] = useState(false);

    const [{ loading, data: summaryData, error }] = usePromise<string | null>(
        null,
        async () => {
            if (data) {
                const rows = produceRowsForSummary(data);
                const payload: ChatMessagePayload = {
                    agentId: CHAT_SUMMARY_AGENT_ID,
                    messages: [
                        {
                            role: 'user',
                            contentParts: [{ type: 'TEXT', content: JSON.stringify(rows) }],
                        },
                    ],
                    placeholders: {},
                };

                return (await sendChatMessages(payload)).message;
            }
            return null;
        },
        []
    );

    if (loadSummary) {
        if (error) {
            const errorText = "I'm sorry, but I as unable to load table summary at this time";
            return <ChatAssistantMessage content={errorText} />;
        }
        if (loading) {
            return <Spinner className="ms-1" size="sm" />;
        }
        if (summaryData) {
            return <ChatAssistantMessage content={summaryData} />;
        }
    }
    return (
        <div style={{ display: 'flex' }}>
            <LabelLink onClick={() => setLoadSummary(true)} style={{ marginLeft: 'auto', fontWeight: 600 }}>
                <i className="fa-solid fa-wand-magic-sparkles text-primary me-1" />
                Summarize table
            </LabelLink>
        </div>
    );
};

export default ChatAssistantSummary;
