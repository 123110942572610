import { Badge } from 'reactstrap';
import { SEGMENT_TYPE_LABELS, SegmentType } from '../../constants/segmentTypes.constant';

type Props = {
    type: SegmentType;
};

const SegmentTypeBadge = ({ type }: Props) => (
    <Badge color="secondary" className="badge-outline-secondary">
        {SEGMENT_TYPE_LABELS[type]}
    </Badge>
);

export default SegmentTypeBadge;
