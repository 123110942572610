import CreativeLabelList from './components/CreativeLabelList/CreativeLabelList';
import CreativeList from './components/CreativeList/CreativeList';
import { CREATIVE_LABELS, CREATIVES } from './creatives.navigation';

const creativesRoutes = [
    {
        ...CREATIVES,
        component: CreativeList,
    },
    {
        ...CREATIVE_LABELS,
        component: CreativeLabelList,
    },
];

export default creativesRoutes;
