import {
    ALGORITHM_AREA_LABELS,
    ALGORITHM_SECTION_LABELS,
} from 'platform/algorithms/algorithmManager.constant';
import { SECTION_LABELS } from 'platform/app/app.types';
import {
    MARKETING_GOAL_LABELS,
    SEARCH_TERM_STRATEGY_LABELS,
    TARGET_AUDIENCE_LABELS,
} from 'platform/campaign/campaign/campaign.types';
import {
    INTEGRATION_TYPE_LABELS,
    MEDIA_TYPE_LABELS,
} from 'platform/campaign/strategy/constants/integrationTypes.constant';
import { UnitState, UnitStateLabel } from 'platform/campaign/unit/constants/state.constant';
import { getChannelName } from 'platform/channels/channel.util';
import { TypeDefinition } from 'platform/common/common.types';
import { labelByKey } from 'platform/common/constants/optimization.constant';
import { DATA_TYPES, typeOf } from 'platform/common/dataTypes';
import { CommonClassifiers } from 'platform/common/ducks/commonClassifiers.duck';
import { getVendorName } from 'platform/vendors/vendors.util';
import { Report, Template } from '../analytics.types';

const getDeviceType = (char: string) => {
    switch (char) {
        case 'D':
        case 'desktop':
            return 'Desktop';
        case 'T':
        case 'tablet':
            return 'Tablet';
        case 'M':
        case 'mobile':
            return 'Mobile';
        default:
            return '';
    }
};

export const formatColumnValue = (
    key: string,
    value: any,
    type: TypeDefinition | undefined,
    row: Record<string, any>,
    { channels, vendors }: CommonClassifiers
): string => {
    switch (key) {
        case 'vendor_code':
            return getVendorName(value, vendors);
        case 'unit_state':
            return UnitStateLabel[value as UnitState] || value;
        case 'media_type':
            return labelByKey(MEDIA_TYPE_LABELS, value);
        case 'integration_type':
            return labelByKey(INTEGRATION_TYPE_LABELS, value);
        case 'channel':
            return getChannelName(value, channels);
        case 'target_audience':
            return labelByKey(TARGET_AUDIENCE_LABELS, value);
        case 'search_term_strategy':
            return labelByKey(SEARCH_TERM_STRATEGY_LABELS, value);
        case 'marketing_goal':
            return labelByKey(MARKETING_GOAL_LABELS, value);
        case 'device_type':
            return getDeviceType(value);
        case 'cost_per_spend':
            return DATA_TYPES.P100.format(value * 100);
        case 'pagespeed_desktop_tti':
        case 'pagespeed_desktop_lcp':
        case 'pagespeed_desktop_lcp_p75':
        case 'pagespeed_desktop_fcp':
        case 'pagespeed_desktop_fcp_p75':
        case 'pagespeed_desktop_si':
        case 'pagespeed_mobile_tti':
        case 'pagespeed_mobile_lcp':
        case 'pagespeed_mobile_lcp_p75':
        case 'pagespeed_mobile_fcp':
        case 'pagespeed_mobile_fcp_p75':
        case 'pagespeed_mobile_tfb_p75':
        case 'pagespeed_desktop_tfb_p75':
        case 'pagespeed_mobile_si':
            return value ? `${DATA_TYPES.FLOAT.parse(value / 1000, { precise3: true })} s` : '-';
        case 'pagespeed_desktop_fid':
        case 'pagespeed_desktop_fid_p75':
        case 'pagespeed_mobile_fid':
        case 'pagespeed_mobile_fid_p75':
        case 'pagespeed_desktop_tbt':
        case 'pagespeed_mobile_tbt':
        case 'pagespeed_mobile_inp_p75':
        case 'pagespeed_desktop_inp_p75':
            return value ? `${parseInt(String(value), 10)} ms` : '-';
        case 'pagespeed_desktop_cls':
        case 'pagespeed_desktop_cls_p75':
        case 'pagespeed_mobile_cls':
        case 'pagespeed_mobile_cls_p75':
            return value ? `${DATA_TYPES.FLOAT.parse(value, { precise3: true })}` : '-';
        case 'pagespeed_desktop_score':
        case 'pagespeed_mobile_score':
            return value ? `${parseInt(String(value), 10)}` : '-';
        case 'media_insertion_budget_goal_match':
            if (row.media_insertion_target_kpi_value === 0) {
                return '-';
            }
            return type ? type.format(value) : value;
        case 'algorithm_section':
            return labelByKey(ALGORITHM_SECTION_LABELS, value);
        case 'algorithm_area':
            return labelByKey(ALGORITHM_AREA_LABELS, value);
        case 'algorithm_execution_status':
            return value === 'SUCCESS' ? 'Successful' : 'Failed';
        case 'analytics_report_section':
            return labelByKey(SECTION_LABELS, value);
        default:
            return type ? type.format(value) : value;
    }
};

export const formatDimensions = (
    report: Report,
    template: Template,
    commonClassifiers: CommonClassifiers
): Report => {
    const columnTypes = Object.fromEntries(report.header.map((col) => [col.key, typeOf(col)]));
    const mapRow = (row: Record<string, any>) =>
        Object.entries(row).reduce(
            (newRow, [key, value]) => ({
                ...newRow,
                [key]: template.dimensions.some((d) => d.key === key)
                    ? formatColumnValue(key, value, columnTypes[key], row, commonClassifiers)
                    : value,
            }),
            row
        );

    return {
        ...report,
        rows: report.rows.map(mapRow),
    };
};
