import axiosBff from 'platform/axios/axiosBff';
import { SystemSetting, SystemSettingKey } from './systemSetting.types';

export const getSystemSettings = () =>
    axiosBff.get<SystemSetting[]>(`/admin/api/system-settings`).then((res) => res.data);

export const getSystemSetting = (key: SystemSettingKey) =>
    axiosBff.get<SystemSetting>(`/admin/api/system-settings/${key}`).then((res) => res.data);

export const saveSystemSetting = (key: SystemSettingKey, value: { [key: string]: any }) =>
    axiosBff.put<SystemSetting>(`/admin/api/system-settings/${key}`, { value }).then((res) => res.data);
