import { ReactNode } from 'react';
import ModalHeaderCloseButton from 'platform/common/components/Modal/ModalHeaderCloseButton';
import './Modal.scss';

interface Props {
    children: ReactNode | string;
    onClose?: () => void;
}

const ModalHeader = ({ onClose, children }: Props) => (
    <div className="ModalHeader">
        <div className={typeof children === 'string' ? 'ModalHeader-title' : 'flex-fill'}>{children}</div>
        {onClose && <ModalHeaderCloseButton onClick={onClose} />}
    </div>
);

export default ModalHeader;
