import AnalyticsReportFormRoutes from 'platform/analyticsReports/ReportManagement/AnalyticsReportFormRoutes';
import { useRefetchAnalyticsReports } from 'platform/analyticsReports/ReportManagement/useRefetchAnalyticsReports';
import { useReportHandlers } from 'platform/analyticsReports/ReportManagement/useReportHandlers';
import SectionsCard from 'platform/analyticsReports/SectionsCard';
import { fetchAnalyticsReports } from 'platform/analyticsReports/analyticsReport.service';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { ADMIN_REPORT_SECTIONS } from 'platform/app/components/Sidebar/navigation.util';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';

interface Props {
    status: ActiveOrArchived[];
    templates: AnalyticsReport[];
    canEdit: boolean;
    onReportMove: (
        oldReport: AnalyticsReport,
        newReport: Partial<AnalyticsReport>,
        reports: AnalyticsReport[],
        seatId: number
    ) => Promise<void>;
    onReportChange: (report: AnalyticsReport) => Promise<void>;
    onReportsCreate: (reports: AnalyticsReport[]) => Promise<number[]>;
    onStatusChange: (status: ActiveOrArchived[]) => void;
}

const AdminReports = ({
    status,
    templates,
    canEdit,
    onReportChange,
    onReportMove,
    onReportsCreate,
    onStatusChange,
}: Props) => {
    const [{ data: adminSeatReports, loading }, refetchReports] = usePromise<AnalyticsReport[]>(
        [],
        () => fetchAnalyticsReports({ seatId: ADMIN_SEAT_ID }),
        []
    );

    const { refetch } = useRefetchAnalyticsReports(refetchReports);

    const { handleReportChange, handleReportsCreate, handleReportMove } = useReportHandlers({
        seatId: ADMIN_SEAT_ID,
        refetchReports,
        onReportChange,
        onReportMove,
        onReportsCreate,
    });

    return (
        <BodyContainer helpKey="admin_reports">
            <ListFilters className="mb-3">
                <SelectWithAddon
                    name="Status"
                    value={status}
                    options={[
                        { value: 'ACTIVE', label: 'Active ' },
                        { value: 'ARCHIVED', label: 'Archived ' },
                    ]}
                    onChange={onStatusChange}
                    isMulti
                />
            </ListFilters>
            {loading && <OverlayLoader />}
            <InsetBox className="mb-3">
                {ADMIN_REPORT_SECTIONS.map((section) => (
                    <SectionsCard
                        key={section}
                        seatId={ADMIN_SEAT_ID}
                        section={section}
                        reports={adminSeatReports.filter(
                            (r) => (status.length ? status.includes(r.status) : true) && r.section === section
                        )}
                        templates={templates.filter((t) => t.section === section)}
                        loading={loading}
                        canEdit={canEdit}
                        refetchReports={refetchReports}
                        onReportMove={(oldReport, newReport) =>
                            handleReportMove(oldReport, newReport, adminSeatReports)
                        }
                        onReportChange={handleReportChange}
                        onReportsCreate={handleReportsCreate}
                    />
                ))}
            </InsetBox>
            <AnalyticsReportFormRoutes canEdit={canEdit} afterSubmit={refetch} />
        </BodyContainer>
    );
};

export default AdminReports;
