import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { ClassificationOptionsContext } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableForm/ClassificationOptionsContext';
import DateRangePickerPopover from 'platform/common/components/DateRangePicker/DateRangePickerPopover';
import SelectOptionWithStatus from 'platform/common/components/Select/SelectOptionWithStatus';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useLoading } from 'platform/common/hooks/useLoading';
import FormCreatableSelect, {
    FormCreatableSelectProps,
} from 'platform/formik/FormCreatableSelect/FormCreatableSelect';
import { createTimeOffer } from '../advertiserManagement/TimeOffers/timeOffer.service';

type Props = Omit<FormCreatableSelectProps, 'options' | 'onNewOption'>;

const FormTimeOffersSelect = (props: Props) => {
    const { setFieldValue, values } = useFormikContext();
    const ref = useRef<HTMLDivElement>(null);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const [newTimeOfferName, setNewTimeOfferName] = useState<string>('');
    const [datePickerShown, setDatePickerShown] = useState(false);
    const [{ timeOffers }, loading, refetch] = useContext(ClassificationOptionsContext);
    const [createLabelLoading, withLoading] = useLoading();

    const returnOnlyValues = props.returnOnlyValues ?? true;

    const closeDatePicker = () => {
        setDatePickerShown(false);
        props.onBlur?.({} as any);
    };

    const createAndSelectNewTimeOffer = async (dateFrom: string, dateTo: string) => {
        const { id } = await withLoading(() =>
            createTimeOffer({ advertiserId, name: newTimeOfferName, dateFrom, dateTo, status: 'ACTIVE' })
        );
        await refetch();
        const newValue = returnOnlyValues ? id : { value: id, label: newTimeOfferName };
        setFieldValue(props.name, [...get(values, props.name), newValue]);
        closeDatePicker();
    };

    return (
        <div className="flex-grow-1" ref={ref}>
            <FormCreatableSelect
                {...props}
                isMulti
                menuStyle={{ minWidth: 400 }}
                options={timeOffers}
                isLoading={loading || createLabelLoading}
                onNewOption={(options: any[]) => {
                    const newOption = options.pop(); // remove the newly entered option (it has no period yet)
                    setNewTimeOfferName(typeof newOption === 'object' ? newOption.value : newOption);
                    setFieldValue(props.name, options);
                    setDatePickerShown(true);
                }}
                onBlur={(e) => {
                    if (!newTimeOfferName) {
                        props.onBlur?.(e);
                    }
                }}
                formatOptionLabel={SelectOptionWithStatus}
                formatCreateLabel={(value) => (
                    <span>
                        <b>Create label</b>
                        {` "${value}"`}
                    </span>
                )}
                placeholder="Select or enter..."
            />
            {ref.current && datePickerShown && (
                <DateRangePickerPopover
                    target={ref.current}
                    placement={undefined}
                    toggle={closeDatePicker}
                    onChange={createAndSelectNewTimeOffer}
                    onCancel={() => {
                        closeDatePicker();
                        setNewTimeOfferName('');
                    }}
                />
            )}
        </div>
    );
};

export default FormTimeOffersSelect;
