import { CHAT_ADMIN_PANEL } from 'platform/chat/chat.navigations';
import ChatAdminPanelPage from './admin/ChatAdminPanelPage/ChatAdminPanelPage';

const routes = [
    {
        ...CHAT_ADMIN_PANEL,
        component: ChatAdminPanelPage,
    },
];

export default routes;
