import RadioList from 'platform/common/components/RadioList/RadioList';
import TextEditor from 'platform/common/components/TextEditor/TextEditor';

type NoteDisplay = 'AlWAYS' | 'MOUSE_OVER' | 'NONE';

export interface NoteType {
    text: string;
    display: NoteDisplay;
}

interface Props {
    note?: NoteType;
    className?: string;
    onChange: (note?: NoteType) => void;
}

const EMPTY_NOTE: NoteType = {
    text: '',
    display: 'NONE',
};

const ReportNote = ({ className, note = EMPTY_NOTE, onChange }: Props) => (
    <div className={className}>
        <RadioList
            name="Display"
            className="d-flex"
            options={[
                { label: 'No note', value: 'NONE' },
                { label: 'Show on mouse over', value: 'MOUSE_OVER' },
                { label: 'Show always', value: 'AlWAYS' },
            ]}
            value={note?.display ?? 'NONE'}
            onRadioChange={(display: NoteDisplay) =>
                onChange(display === 'NONE' ? undefined : { ...note, display })
            }
        />
        {note?.display !== 'NONE' && (
            <TextEditor className="mb-3" value={note.text} onChange={(text) => onChange({ ...note, text })} />
        )}
    </div>
);

export default ReportNote;
