import IconButton from 'platform/common/components/IconButton/IconButton';
import { TableSortDirection } from './useTableSort';

interface Props {
    direction: TableSortDirection;
}

const SortButton = ({ direction }: Props) => (
    <IconButton className="ms-1 text-primary">
        {direction === 'asc' ? (
            <i className="fa-solid fa-sort-up" />
        ) : (
            <i className="fa-solid fa-sort-down" />
        )}
    </IconButton>
);

export default SortButton;
