import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import { FormTextEditor } from 'platform/formik/FormTextEditor/FormTextEditor';

const NoteCard = () => (
    <ControlledCard bodyClassName="p-0" title="Note">
        <FormTextEditor name="text" />
    </ControlledCard>
);

export default NoteCard;
