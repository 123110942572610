import { useContext, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { ClassificationOptionsContext } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableForm/ClassificationOptionsContext';
import SelectOptionWithStatus from 'platform/common/components/Select/SelectOptionWithStatus';
import { OptionType } from 'platform/common/components/Select/select.types';
import FormCreatableSelect, {
    FormCreatableSelectProps,
} from 'platform/formik/FormCreatableSelect/FormCreatableSelect';

type Props = Omit<FormCreatableSelectProps, 'options' | 'onNewOption'>;

const FormProductLabelSelect = (props: Props) => {
    const [{ productLabels }, loading] = useContext(ClassificationOptionsContext);
    const [newLabel, setNewLabel] = useState<OptionType>({});

    return (
        <FormCreatableSelect
            {...props}
            menuStyle={{ minWidth: 400 }}
            returnOnlyValues={false}
            options={!isEmpty(newLabel) ? [...productLabels, newLabel] : productLabels}
            isLoading={loading}
            onNewOption={(newOption) => {
                setNewLabel(newOption);
                props.onChange?.(newOption.value);
            }}
            formatOptionLabel={SelectOptionWithStatus}
            formatCreateLabel={(value) => (
                <span>
                    <b>Create label</b>
                    {` "${value}"`}
                </span>
            )}
            placeholder="Select or enter..."
        />
    );
};

export default FormProductLabelSelect;
