import { CSSProperties } from 'react';
import './MultiSwitch.scss';

export interface ButtonParams<T> {
    text: string;
    iconClassName: string;
    value: T;
}

interface Props<T> {
    className?: string;
    style?: CSSProperties;
    options: ButtonParams<T>[];
    value: T;
    onChange: (val: T) => void;
}

const MultiSwitch = <T extends string | number>({ className, style, onChange, options, value }: Props<T>) => (
    <div className={className}>
        <div style={style} className="MultiSwitch" aria-label="Switch">
            {options.map((option) => (
                <button
                    key={option.value}
                    type="button"
                    className={option.value === value ? 'MultiSwitch-checked' : 'MultiSwitch-unchecked'}
                    onClick={() => onChange(option.value)}
                >
                    <div className="d-flex align-items-center h-100 px-1">
                        <i className={option.iconClassName} />
                        <span className="ms-2 fw-bold">{option.text}</span>
                    </div>
                </button>
            ))}
        </div>
    </div>
);

export default MultiSwitch;
