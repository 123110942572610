import classNames from 'classnames';
import { SpacerType } from './ReportSpacer';
import './ReportComponentSpacer.scss';

interface Props {
    spacer?: SpacerType;
}

const ReportComponentSpacer = ({ spacer }: Props) => (
    <div className={classNames('ReportComponentSpacer-headline', !spacer?.isContainer && 'sub-headline')}>
        {spacer?.text?.toUpperCase()}
    </div>
);

export default ReportComponentSpacer;
