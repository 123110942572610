import FormProductLabelSelect from 'platform/campaign/common/FormProductLabelSelect';
import { GlobalCostSourcePreset } from 'platform/campaign/globalPreset/globalPreset.types';
import { Channel } from 'platform/channels/channel.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Separator from 'platform/common/components/Separator/Separator';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { limitFloatTo, percentage, required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

interface Props {
    channels: Channel[];
}

const GlobalCostSourceForm = ({
    labels,
    canEdit,
    onCancel,
    formikProps: { initialValues, submitForm },
    channels,
}: Props & FormProps<GlobalCostSourcePreset>) => (
    <CardForm
        title={`${labels.prefix} cost source`}
        subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
        submitLabel={labels.submit}
        onCancel={onCancel}
        disabled={!canEdit}
        onSubmit={submitForm}
    >
        <ControlledCard title="General info" subtitle="required">
            <FormRow label="Name">
                <FormInput name="name" type="text" validate={required} />
            </FormRow>
            <FormRow label="Agency fee">
                <FormNumberInput
                    name="monetization.costPerSpendPercentage"
                    label="%"
                    validate={[required, limitFloatTo(2), percentage]}
                />
            </FormRow>
            <Separator label="Optional" />
            <FormRow label="Channel">
                <FormSelect
                    name="channel"
                    options={channels}
                    getOptionValue={(c) => c.code}
                    getOptionLabel={(c) => c.name}
                />
            </FormRow>
            <FormRow label="Product">
                <FormProductLabelSelect name="productLabel" />
            </FormRow>
        </ControlledCard>
    </CardForm>
);

export default GlobalCostSourceForm;
