import { useEffect, useState } from 'react';
import classNames from 'classnames';
import RoundIconButton from 'platform/common/components/RoundIconButton/RoundIconButton';
import UserSelect from 'platform/common/components/UserSelect/UserSelect';
import { User } from 'platform/userManagement/types/user.type';
import './BorderlessUserSelect.scss';

interface Props {
    userIds: number[];
    options: User[];
    closeMenuOnSelect?: boolean;
    onChange: (userIds: number[]) => void;
    className?: string;
}

const BorderlessUserSelect = ({
    userIds,
    options,
    closeMenuOnSelect = false,
    onChange,
    className,
}: Props) => {
    const [selectedValues, setSelectedValues] = useState(userIds);
    const [visible, setVisible] = useState(!!userIds.length);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setSelectedValues(userIds);
        setVisible(!!userIds.length);
    }, [userIds.length]);

    const handleChange = (ids: number[]) => {
        onChange(ids);
        setVisible(ids.length > 0);
        setOpen(false);
    };

    if (!visible) {
        return (
            <RoundIconButton
                className="ms-1 me-2"
                icon="fa fa-user-plus"
                onClick={() => {
                    setVisible(true);
                    setOpen(true);
                }}
            />
        );
    }

    return (
        <UserSelect
            className={classNames('BorderlessUserSelect', className)}
            userIds={selectedValues}
            options={options}
            menuIsOpen={open}
            onFocus={() => setOpen(true)}
            closeMenuOnSelect={closeMenuOnSelect}
            onChange={(ids) => {
                setSelectedValues(ids);
                if (closeMenuOnSelect) {
                    handleChange(ids);
                }
            }}
            onBlur={() => handleChange(selectedValues)}
        />
    );
};

export default BorderlessUserSelect;
