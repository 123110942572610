import SkeletonCard from './SkeletonCard';

const LogBookListTableSkeleton = () => (
    <>
        {Array.from(Array(10)).map((_, i) => (
            <SkeletonCard key={i} />
        ))}
    </>
);

export default LogBookListTableSkeleton;
