import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { Formik, FormikErrors, FormikProps, FormikSharedConfig } from 'formik';
import CanNotCreateWarning from 'platform/common/components/CanNotCreateWarning/CanNotCreateWarning';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import CardFormPlaceholder from 'platform/common/components/CardForm/CardFormPlaceholder';
import SidePanel from 'platform/common/components/SidePanel/SidePanel';
import ErrorBoundary from 'platform/common/containers/ErrorBoundary/ErrorBoundary';
import { usePromise } from 'platform/common/hooks/usePromise';

export interface FormContainerProps {
    canEdit: boolean;
    canCreate?: boolean;
    redirectAfterSubmit?: boolean;
    redirectTo: string;
    isEdit?: boolean;
    afterSubmit?: (data: any) => void;
}

export interface FormProps<T> {
    isEdit: boolean;
    canEdit: boolean;
    canCreate?: boolean;
    labels: { submit: string; prefix: string };
    formikProps: FormikProps<T>;
    onCancel: () => void;
}

interface Props<T> {
    redirectTo?: string;
    size?: number;
    canEdit?: boolean;
    showEditWarning?: boolean;
    canCreate?: boolean;
    showCreateWarning?: boolean;
    isEdit?: boolean;
    sidePanel?: boolean;
    redirectAfterSubmit?: boolean;
    validate?: (values: T, initialValues: T) => FormikErrors<T> | Record<string, string | undefined>;
    children: (props: FormProps<T>) => ReactNode;
    onOpen: () => Promise<T>;
    onSubmit?: (values: T) => Promise<any>;
    onCancel?: () => void;
    onSubmitFinish?: (data: any) => void;
    formikConfig?: FormikSharedConfig;
    helpKey: string;
}

const FormContainer = <T extends object>({
    redirectTo,
    size,
    canEdit = true,
    showEditWarning = true,
    canCreate = true,
    showCreateWarning = true,
    isEdit = true,
    sidePanel = false,
    redirectAfterSubmit = true,
    validate,
    children,
    onOpen,
    onSubmit,
    onCancel,
    onSubmitFinish,
    formikConfig,
    helpKey,
}: Props<T>) => {
    const dispatch = useDispatch();
    const [{ data }] = usePromise(undefined, onOpen, []);

    const cancel = onCancel ?? (() => redirectTo && dispatch(push(redirectTo)));

    const submit = async (formModel: T) => {
        const response = onSubmit ? await onSubmit(formModel) : undefined;

        if (onSubmitFinish) {
            onSubmitFinish(response);
        }

        if (redirectAfterSubmit && redirectTo) {
            dispatch(push(redirectTo));
        }
    };

    if (!canCreate && showCreateWarning) {
        return <CanNotCreateWarning className="mb-0" />;
    }

    if (!canEdit && showEditWarning && !canCreate) {
        return <CanNotEditWarning className="mb-0" />;
    }

    if (!data)
        return sidePanel ? (
            <SidePanel size={size}>
                <CardFormPlaceholder />
            </SidePanel>
        ) : (
            <CardFormPlaceholder />
        );

    const labels = getLabels(isEdit, canEdit);

    return (
        <ErrorBoundary>
            <Formik
                initialValues={{
                    ...data,
                    helpKey,
                }}
                validate={validate ? (values) => validate(values, data) : undefined}
                onSubmit={submit}
                {...formikConfig}
            >
                {(formikProps) => {
                    const formProps: FormProps<T> = {
                        labels,
                        formikProps,
                        isEdit,
                        canEdit,
                        canCreate,
                        onCancel: cancel,
                    };
                    return sidePanel ? (
                        <SidePanel size={size}>{children(formProps)}</SidePanel>
                    ) : (
                        children(formProps)
                    );
                }}
            </Formik>
        </ErrorBoundary>
    );
};

export const getLabels = (isEdit: boolean, canEdit: boolean) => {
    if (isEdit) {
        return { submit: 'Update', prefix: canEdit ? 'Edit' : 'View' };
    }

    return { submit: 'Save', prefix: 'New' };
};

export default FormContainer;
