import { components, MultiValueRemoveProps } from 'react-select';
import { OptionType } from 'platform/common/components/Select/select.types';

const KanbanLabelRemove = <T extends OptionType>(props: MultiValueRemoveProps<T>) => (
    <>
        <components.MultiValueRemove {...props}>
            <div className={`Kanban-label-remove Task-label-color-${props.data.value}`}>
                <i className="far fa-times" />
            </div>
        </components.MultiValueRemove>
    </>
);

export default KanbanLabelRemove;
