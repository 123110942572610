import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { SelectOption } from 'platform/common/common.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import ControlledCardBorderless from 'platform/common/components/ControlledCard/ControlledCardBorderless';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { Task, TaskCreationData, TaskData, TaskLocation } from '../task.types';

type Props = {
    toggle: () => void;
    task: Task;
    lanes: SelectOption[];
    onTaskAdd: (location: TaskLocation, data: TaskCreationData) => Promise<any>;
    onTaskUpdate: (taskId: number, data: Partial<TaskData>) => void;
};

type TaskCopyOptions = {
    name: string;
    lane: string;
    labels: boolean;
    descriptions: boolean;
    dates: boolean;
    subtasks: boolean;
    assignees: boolean;
};

const KanbanTaskCopyModal = ({ toggle, task, lanes = [], onTaskAdd, onTaskUpdate }: Props) => {
    const handleOpen = async (): Promise<TaskCopyOptions> => ({
        name: '',
        lane: '',
        assignees: false,
        labels: true,
        descriptions: true,
        dates: true,
        subtasks: true,
    });

    const transferTaskData = async (newTaskId: number, transferList: TaskCopyOptions, oldData: Task) => {
        await onTaskUpdate(newTaskId, {
            description: transferList.descriptions ? oldData.description : undefined,
            dueDate: transferList.dates ? oldData.dueDate : undefined,
            startDate: transferList.dates ? oldData.startDate : undefined,
            labelKeys: transferList.labels ? oldData.labelKeys : undefined,
            assigneeIds: transferList.assignees ? oldData.assigneeIds : undefined,
        });
    };

    const handleSubmit = async (copyOptions: TaskCopyOptions) => {
        const taskResponse = await onTaskAdd(
            { laneId: Number(copyOptions.lane), position: 0 },
            { name: copyOptions.name, status: 'ACTIVE' }
        );

        if (taskResponse[0] && copyOptions.subtasks && task.subtasks?.length) {
            task.subtasks.map((it, index) =>
                onTaskAdd(
                    { parentTaskId: taskResponse[0].taskId, position: index },
                    {
                        name: it.name,
                        status: 'ACTIVE',
                    }
                ).then(async (subTaskResponse) => {
                    await transferTaskData(subTaskResponse[0].taskId, copyOptions, it);
                })
            );
        }

        await transferTaskData(taskResponse[0].taskId, copyOptions, task);

        return copyOptions;
    };

    return (
        <Modal isOpen toggle={toggle}>
            <ModalHeader onClose={toggle}>
                <span className="ModalHeader-title">{`Copy task: ${task.name}`}</span>
            </ModalHeader>
            <FormContainer
                helpKey="task_copy_form"
                onOpen={handleOpen}
                onSubmit={handleSubmit}
                onSubmitFinish={toggle}
            >
                {(props) => (
                    <>
                        <ModalBody>
                            <FormRow label="New task name">
                                <FormInput name="name" validate={required} />
                            </FormRow>

                            <FormRow label="Column">
                                <FormSelect options={lanes} name="lane" validate={required} />
                            </FormRow>

                            <ControlledCardBorderless bodyClassName="mt-3" isExpanded title={''} hideControl>
                                <FormRow label="Assignees">
                                    <FormSwitch name="assignees" />
                                </FormRow>

                                <FormRow label="Labels">
                                    <FormSwitch name="labels" />
                                </FormRow>

                                <FormRow label="Descriptions">
                                    <FormSwitch name="descriptions" />
                                </FormRow>

                                <FormRow label="Subtasks">
                                    <FormSwitch name="subtasks" />
                                </FormRow>

                                <FormRow label="Dates">
                                    <FormSwitch name="dates" />
                                </FormRow>
                            </ControlledCardBorderless>
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={toggle}>Cancel</Button>
                            <AnimatedButton onClick={() => props.formikProps.submitForm()}>
                                Submit
                            </AnimatedButton>
                        </ModalFooter>
                    </>
                )}
            </FormContainer>
        </Modal>
    );
};

export default KanbanTaskCopyModal;
