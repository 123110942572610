import { useState } from 'react';
import { ChartData, ChartOptions } from 'chart.js';
import { map, orderBy, round } from 'lodash-es';
import ButtonArray from 'platform/common/components/ButtonArray/ButtonArray';
import Bar from 'platform/common/components/Charts/Bar';
import Select from 'platform/common/components/Select/Select';
import { usePromise } from 'platform/common/hooks/usePromise';
import { CHART_COLORS } from 'platform/common/utils/color.util';
import {
    ChartScale,
    SCALE_TEXT,
    TTA_BUTTON_OPTIONS,
    TtaButton,
} from 'platform/segments/constants/chart.constants';
import { getTimeToConversion } from 'platform/segments/services/segments.service';

const prepareChart = (
    allDeviceData: DataSet[] = [],
    scale: ChartScale
): { data: ChartData<'bar'>; options: ChartOptions<'bar'> } => {
    const filteredData = allDeviceData.map((deviceData) => ({
        key: deviceData.key,
        data: orderBy(
            deviceData.data.filter((item) => item.timeScale === scale),
            'timeValue'
        ),
    }));

    const labels = filteredData.length ? filteredData[0].data.map((line) => String(line.timeValue)) : [];

    return {
        data: {
            labels,
            datasets: filteredData.map(({ data }, index) => ({
                label: 'Users',
                backgroundColor: CHART_COLORS[index],
                data: map(data, 'percentFromAllConversions'),
            })),
        },
        options: {
            plugins: {
                title: {
                    display: true,
                    text: SCALE_TEXT[scale],
                    position: 'bottom' as const,
                    padding: 0,
                },
                tooltip: {
                    callbacks: {
                        label: (tooltipItem: any) => {
                            const usersConverted =
                                filteredData[tooltipItem.datasetIndex].data[tooltipItem.dataIndex]
                                    .convertedUsers;
                            return `${round(tooltipItem.raw, 2)} % | ${usersConverted}`;
                        },
                    },
                },
            },
            animation: false,
            maintainAspectRatio: false,
            scales: {
                y: {
                    min: 0,
                    beginAtZero: true,
                    ticks: {
                        callback: (value: any) => `${value}%`,
                    },
                },
            },
        },
    };
};

type DataSet = {
    key: string;
    data: {
        timeScale: ChartScale;
        timeValue: number;
        convertedUsers: number;
        percentFromAllConversions: number;
    }[];
};

type Props = {
    segments: { value: number; label: string }[];
    advertiserId: number;
    segmentId: number | undefined;
};

const TimeToActionChart = ({ segments, advertiserId, segmentId }: Props) => {
    const [activeButton, setActiveButton] = useState(TtaButton.HOURS);
    const [activeSegmentId, setSegmentId] = useState(segmentId);

    const [{ data, loading }] = usePromise([], () => getTimeToConversion(advertiserId, activeSegmentId), [
        activeSegmentId,
        advertiserId,
    ]);

    const chartData = prepareChart(
        data,
        activeButton === TtaButton.HOURS ? ChartScale.HOURS : ChartScale.DAYS
    );

    return (
        <>
            <div className="SegmentCharts-navigation mb-1 d-flex">
                <ButtonArray
                    className="SegmentCharts-buttons"
                    buttons={TTA_BUTTON_OPTIONS}
                    selected={activeButton}
                    onClick={setActiveButton}
                />
                <Select
                    value={activeSegmentId}
                    options={segments}
                    onChange={setSegmentId}
                    className="w-100"
                />
            </div>
            <div className="SegmentCharts-charts">
                <Bar data={chartData.data} options={chartData.options} loading={loading} height={266} />
            </div>
        </>
    );
};

export default TimeToActionChart;
