import { isEmpty, isEqual, uniq, uniqBy } from 'lodash-es';
import { FlightLabel } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.types';
import { Product } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.types';
import { CAMPAIGN_CLASSIFICATION_STATIC_GROUPS } from 'platform/campaign/campaign/constants/campaignClassification.constant';
import { isNotEmpty, SelectItem } from 'platform/common/common.types';
import { CommonClassifiers } from 'platform/common/ducks/commonClassifiers.duck';
import { sortByName } from 'platform/common/utils/array.util';
import { isActiveRuntime } from 'platform/common/utils/date.util';
import {
    ChangeTrackingModel,
    MEDIA_INSERTION_STEPS,
    MediaInsertionStepType,
    WorkflowLaneModel,
} from 'platform/mediaplan/mediaplan.types';

type FormModel = {
    name?: string;
    budget?: number;
    runtime?: { from: string; to: string };
    newChange?: ChangeTrackingModel;
};

export const getTrackedChanges = (values: FormModel, initialValues?: FormModel) => {
    const budgetChanged = Number(values.budget || 0) !== Number(initialValues?.budget || 0);
    const runtimeChanged = !isEqual(values.runtime, initialValues?.runtime);
    return {
        budgetChanged,
        runtimeChanged,
        needsLogging: (budgetChanged || runtimeChanged) && isActiveRuntime(initialValues?.runtime),
    };
};

export const validateChangeTracking = (values: FormModel, initialValues?: FormModel) => {
    if (!initialValues) {
        return undefined;
    }
    const { budgetChanged, needsLogging } = getTrackedChanges(values, initialValues);
    if (needsLogging) {
        const missingReason = !values.newChange?.reason;
        const missingComment = requiresComment(values.newChange) && !values.newChange?.comment;
        if (missingReason || missingComment) {
            return `Please provide a Log Book ${missingComment ? 'comment' : 'reason'} for changing the ${
                budgetChanged ? 'budget' : 'runtime'
            } of "${values.name || initialValues?.name}"`;
        }
    }
    return undefined;
};

export const requiresComment = (change?: ChangeTrackingModel) =>
    !!change?.reason && (change.reason === 'OVERALL_UPSELL' || change.reason === 'OVERALL_DOWNSELL');

export const getInsertionStepTotal = (type: MediaInsertionStepType, lanes: WorkflowLaneModel[]) => {
    const { simplified } = MEDIA_INSERTION_STEPS[type] ?? {};
    const total = simplified
        ? lanes.length
        : lanes.filter((lane) => lane.mediaInsertion.workflowType === 'IO').length;

    return total;
};

export const getInsertionStepCompleted = (type: MediaInsertionStepType, lanes: WorkflowLaneModel[]) =>
    lanes.filter((lane) => Object.values(lane.steps).some((s) => s.type === type && s.completed)).length;

export const toClassificationOptions = (
    { channels, vendors }: CommonClassifiers,
    products: Product[],
    flightLabels: FlightLabel[],
    selectedProductLabels: string[],
    selectedProductCategories: string[]
) => {
    const productCategoryGroup: SelectItem = {
        label: 'Product category',
        value: 'productCategory',
        nodes: uniq(products.map(({ category }) => category))
            .filter(isNotEmpty)
            .map((category) => ({ label: category, value: `productCategory#${category}` })),
    };
    const productSubLabelGroup: SelectItem = {
        label: 'Sub-product',
        value: 'productSubLabel',
        nodes: uniqBy(
            products
                .filter((x) => selectedProductLabels.includes(x.name))
                .flatMap(({ subLabels }) =>
                    subLabels.map((subLabel) => ({
                        label: subLabel.name,
                        value: `productSubLabel#${subLabel.name}`,
                    }))
                ),
            'value'
        ),
    };
    const productGroup: SelectItem = {
        label: 'Product',
        value: 'productLabel',
        nodes: products
            .filter(
                (l) =>
                    !selectedProductCategories.length || selectedProductCategories.includes(l.category ?? '')
            )
            .map(({ name }) => ({
                label: name,
                value: `productLabel#${name}`,
            })),
    };
    const flightLabelGroup: SelectItem = {
        label: 'Flight',
        value: 'flightLabel',
        nodes: flightLabels.map(({ name }) => ({ label: name, value: `flightLabel#${name}` })),
    };
    const channelGroup: SelectItem = {
        label: 'Channel',
        value: 'channel',
        nodes: channels.map((c) => ({ label: c.name, value: `channel#${c.code}` })),
    };
    const vendorGroup: SelectItem = {
        label: 'Vendor',
        value: 'vendorSystem',
        nodes: sortByName(vendors).map((v) => ({ label: v.name, value: `vendorSystem#${v.externalSystem}` })),
    };

    return [
        ...CAMPAIGN_CLASSIFICATION_STATIC_GROUPS,
        productCategoryGroup,
        productGroup,
        productSubLabelGroup,
        flightLabelGroup,
        channelGroup,
        vendorGroup,
    ].filter((group) => !isEmpty(group.nodes));
};
