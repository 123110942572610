import { ComponentType } from 'react';
import { useDispatch } from 'react-redux';
import { InteractionComponentState, InteractionType } from 'platform/analytics/analytics.types';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import Slider from 'platform/common/components/Slider/Slider';
import ReportInteractionDatePicker from './ReportInteractionComponents/ReportInteractionDatePicker';
import ReportInteractionFilter from './ReportInteractionComponents/ReportInteractionFilter';
import ReportInteractionInput from './ReportInteractionComponents/ReportInteractionInput';
import ReportInteractionSelect from './ReportInteractionComponents/ReportInteractionSelect';

const INTERACTION_COMPONENTS: Record<InteractionType, ComponentType<any>> = {
    INPUT: ReportInteractionInput,
    SLIDER: Slider,
    SELECT: ReportInteractionSelect,
    FILTER: ReportInteractionFilter,
    DATE_PICKER: ReportInteractionDatePicker,
};

interface Props {
    componentState: InteractionComponentState;
}

const ReportInteractionComponent = ({ componentState }: Props) => {
    const dispatch = useDispatch();
    const value = componentState.value ?? componentState.defaultValue;

    const handleChange = (newValue: any) => {
        dispatch(
            analyticsSettingsActions.changeComponentState({
                ...componentState,
                value: newValue,
            })
        );
    };

    const InteractionComponent = INTERACTION_COMPONENTS[componentState.interactionType];

    return (
        <div className="d-flex align-items-center flex-fill">
            <div className="mx-3">{componentState.title}</div>
            <div className="flex-fill me-3">
                <InteractionComponent value={value} onChange={handleChange} {...componentState} />
            </div>
        </div>
    );
};

export default ReportInteractionComponent;
