import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { makeOptions } from 'platform/common/utils/option.util';
import { required, urlHttps } from 'platform/common/utils/validators.util';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { FormTextEditor } from 'platform/formik/FormTextEditor/FormTextEditor';
import { MODEL_ENDPOINT_TYPE_LABELS, ModelEndpointFormModel } from '../../chat.types';

const MODEL_ENDPOINT_TYPE_OPTIONS = makeOptions(MODEL_ENDPOINT_TYPE_LABELS);

type Props = FormProps<ModelEndpointFormModel> & { id?: number };

const ModelEndpointForm = ({ id, labels, onCancel, formikProps: { submitForm }, canEdit }: Props) => (
    <CardForm
        title={`${labels.prefix} DeeplyAI Model`}
        subtitle={id ? `ID: ${id}` : null}
        submitLabel={labels.submit}
        onCancel={onCancel}
        disabled={!canEdit}
        onSubmit={submitForm}
    >
        <ControlledCard title="General">
            <FormRow label="Name">
                <FormInput name="name" validate={required} />
            </FormRow>
            <FormRow label="Status">
                <FormSelect name="status" options={ACTIVE_OR_ARCHIVED} validate={required} />
            </FormRow>
            <FormRow label="Endpoint URL">
                <FormInput name="endpoint" validate={urlHttps} />
            </FormRow>
            <FormRow label="API Key">
                <FormInput name="apiKey" />
            </FormRow>
            <FormRow label="Model Type">
                <FormSelect name="modelType" options={MODEL_ENDPOINT_TYPE_OPTIONS} validate={required} />
            </FormRow>
            <FormRow label="Settings">
                <FormCodeEditor name="settings" options={{ mode: 'javascript', lineWrapping: true }} />
            </FormRow>
        </ControlledCard>

        <ControlledCard bodyClassName="p-0" title="Description">
            <FormTextEditor name="description" />
        </ControlledCard>
    </CardForm>
);

export default ModelEndpointForm;
