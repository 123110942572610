import { Button, List } from 'reactstrap';
import { useFormikContext } from 'formik';
import ChatAssistantQuestionField from './ChatAssistantQuestionField';
import { Question } from './ChatAssistantQuestionsMessage';

interface Props {
    questions: Question[];
}

const ChatAssistantQuestionList = ({ questions }: Props) => {
    const { submitForm } = useFormikContext();

    return (
        <div className="mb-2 bg-light rounded-2 border border-1 border-light p-2">
            <List type="unstyled">
                {questions.map((q, i) => (
                    <li key={i}>
                        <ChatAssistantQuestionField index={i} {...q} />
                    </li>
                ))}
            </List>
            <div className="w-100 d-flex">
                <Button color="primary" className="ms-auto" onClick={submitForm}>
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default ChatAssistantQuestionList;
