import classNames from 'classnames';
import './ColourBox.scss';

interface Props {
    colour?: string;
    className?: string;
}

const ColourBox = ({ colour, className }: Props) => (
    <div
        className={classNames('ColourBox', className)}
        style={{ backgroundColor: colour, border: colour ? undefined : '1px solid #cbd0d9' }}
    />
);

export default ColourBox;
