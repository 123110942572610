import { toastError } from './toast.util';

export const isTransientError = (statusCode: number) => [502, 503, 504].includes(statusCode);

const isForbiddenError = (statusCode: number) => statusCode === 403 || statusCode === 401;

const shouldShowErrorToast = (statusCode: number) =>
    !isForbiddenError(statusCode) && !isTransientError(statusCode);

export const catchNonTransientErrors = (error: any) => {
    if (shouldShowErrorToast(error.response?.status)) {
        toastError(error);
    }

    return Promise.reject(error);
};

// implements exponential backoff with some jitter: 1-1.5s, then 2-3s, then 4-6s, then 8-12s
export const retryDelay = (retryCount: number) => 2 ** (retryCount - 1) * (1 + Math.random() * 0.5) * 1000;
