import { useParams } from 'react-router-dom';
import { keyBy } from 'lodash-es';
import {
    flattenMediaNodes,
    toMediaInsertionFormModel,
    toMediaNodesFormModel,
    toMediaplanTreeApi,
} from 'platform/campaign/campaign/mappers/mediaplan.mapper';
import {
    getMediaInsertionLinks,
    getMediaInsertions,
    getMediaplan,
    getMediaplanChanges,
    storeMediaInsertions,
} from 'platform/campaign/campaign/services/mediaplan.service';
import { assertIsDefined } from 'platform/common/common.assert';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { MediaplanTreeFormModel } from 'platform/mediaplan/mediaplan.types';
import { validateChangeTracking } from 'platform/mediaplan/mediaplan.utils';
import MediaplanTreeForm, { isGroupNode } from './MediaplanTreeForm';

interface Props {
    canEdit: boolean;
    onInitialValuesChange: (isDirty: boolean) => void;
    redirectTo: string;
    onSubmitFinish: (data: any) => void;
}

const MediaplanTreeFormContainer = ({
    canEdit,
    redirectTo,
    onInitialValuesChange,
    onSubmitFinish,
}: Props) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);
    const handleOpen = async (): Promise<MediaplanTreeFormModel> => {
        const [mediaplan, { nodes, insertions }, links, { insertionChanges }] = await Promise.all([
            getMediaplan(id),
            getMediaInsertions(id),
            getMediaInsertionLinks({ mediaplanId: id }),
            getMediaplanChanges(id),
        ]);
        const treeData = toMediaNodesFormModel(nodes);
        const flatNodes = flattenMediaNodes(treeData);
        const insertionFormModels = insertions.map((i) => {
            const node = flatNodes.find((n) => n.type === 'INSERTION' && n.name === i.name);
            assertIsDefined(node, `node corresponding to insertion "${i.name}"`);
            return toMediaInsertionFormModel(node.key, i, links, insertionChanges, mediaplan);
        });
        return {
            mediaplan,
            treeData,
            groups: indexByKey(flatNodes.filter(isGroupNode)),
            insertions: indexByKey(insertionFormModels),
        };
    };

    const validateForm = (values: MediaplanTreeFormModel, initialValues: MediaplanTreeFormModel) => {
        const initialInsertionsById = keyBy(Object.values(initialValues.insertions ?? {}), 'id');
        const changeTrackingError = Object.values(values.insertions ?? {})
            .map((insertion) => {
                const initialInsertion = insertion.id ? initialInsertionsById[insertion.id] : undefined;
                return validateChangeTracking(insertion, initialInsertion);
            })
            .find(Boolean);

        if (changeTrackingError) {
            return { changeTrackingError };
        }

        return {};
    };

    const handleSubmit = async (values: MediaplanTreeFormModel) =>
        storeMediaInsertions(id, toMediaplanTreeApi(values), values.mediaplan.version);

    return (
        <FormContainer
            helpKey="mediaplan_tree_form"
            formikConfig={{ enableReinitialize: true }}
            canEdit={canEdit}
            redirectTo={redirectTo}
            redirectAfterSubmit={false}
            onOpen={handleOpen}
            validate={validateForm}
            onSubmitFinish={onSubmitFinish}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <MediaplanTreeForm id={id} onInitialValuesChange={onInitialValuesChange} {...props} />
            )}
        </FormContainer>
    );
};

const indexByKey = <T extends { key: string }>(items: T[]): { [key: string]: T } =>
    keyBy(items, (i) => i.key);

export default MediaplanTreeFormContainer;
