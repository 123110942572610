import { range } from 'lodash-es';
import { MediaNodeType } from 'platform/mediaplan/mediaplan.types';

const generateMetrics = (nameWithPlaceholder: string, count: number, numberLength: number = 1) =>
    range(1, count).map((n) =>
        nameWithPlaceholder.replace(`$placeholder$`, String(n).padStart(numberLength, '0'))
    );

export const MEDIAPLAN_NODE_OPTIONS: { label: string; type: MediaNodeType }[] = [
    {
        label: 'Group',
        type: 'GROUP',
    },
    {
        label: 'Media insertion',
        type: 'INSERTION',
    },
];

export const MEDIA_INSERTION_KPI_METRICS = [
    'cpa_cd_pc',
    ...generateMetrics('cpa_$placeholder$_cd_pc', 20, 2),

    'roas_cd_pc',
    ...generateMetrics('roas_$placeholder$_cd_pc', 20, 2),

    'cpa_cm_pc',
    ...generateMetrics('cpa_$placeholder$_cm_pc', 20, 2),

    'roas_cm_pc',
    ...generateMetrics('roas_$placeholder$_cm_pc', 20, 2),

    'cpma_cm_pc',
    ...generateMetrics('cpma_$placeholder$_cm_pc', 20, 2),

    'cpa_ua',

    // keep
    'buyside_fallback_clickthrough_rate',
    'buyside_fallback_effective_cpc',
    'buyside_effective_cpc',
    'buyside_fallback_effective_cpm',

    // probably keep these, since gcp_##_ua metrics are under question
    ...generateMetrics('google_goal$placeholder$_cpa', 20),

    // old,
    'buyside_effective_cpa',
];
