import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import ShowSettingsButton from 'platform/common/components/ShowSettingsButton/ShowSettingsButton';
import Spinner from 'platform/common/components/Spinner/Spinner';
import ContactForm from './components/ContactForm';
import ContactList from './components/ContactList';
import { useContacts } from './useContacts';

const Contacts = () => {
    const { adminUser } = useSelector(authSelectors.ready.profile);

    const {
        clientService,
        media,
        internalEmail,
        contactInformation,
        users,
        isEdit,
        loading,
        toggleEdit,
        cancel,
        submit,
    } = useContacts();

    return (
        <>
            <HeaderContainer>
                <div className="d-flex justify-content-between align-items-center">
                    <PageHeader title="Contacts" />

                    {adminUser && <ShowSettingsButton toggled={isEdit} onClick={toggleEdit} />}
                </div>
            </HeaderContainer>

            <BodyContainer helpKey="contacts">
                {isEdit && !loading && (
                    <div className="mb-3">
                        <ContactForm
                            initialValues={contactInformation}
                            users={users}
                            onCancel={cancel}
                            onSubmit={submit}
                        />
                    </div>
                )}

                {loading && <Spinner className="d-block my-4 mx-auto" />}

                {!loading && (
                    <>
                        {!!clientService.length && (
                            <Card className="mb-3">
                                <CardHeader>
                                    <b>Client Service</b>
                                </CardHeader>
                                <CardBody className="d-flex flex-wrap mt-2">
                                    <ContactList userContacts={clientService} />
                                </CardBody>
                            </Card>
                        )}

                        {!!media.length && (
                            <Card className="mb-3">
                                <CardHeader className="d-flex d-inline-flex">
                                    <b>Media</b>
                                    {internalEmail && (
                                        <div className="ms-5">
                                            Internal Advertiser Mailing list: <b>{internalEmail}</b>
                                        </div>
                                    )}
                                </CardHeader>
                                <CardBody className="d-flex flex-wrap mt-2">
                                    <ContactList userContacts={media} />
                                </CardBody>
                            </Card>
                        )}
                    </>
                )}

                {!loading && !clientService.length && !media.length && (
                    <Card>
                        <CardBody>No contact information was added</CardBody>
                    </Card>
                )}
            </BodyContainer>
        </>
    );
};

export default Contacts;
