import { assertIsDefined } from 'platform/common/common.assert';
import { TwoFactorAuthentication } from 'platform/common/constants/twoFactorAuthentication.constant';
import { Role } from 'platform/userManagement/types/role.type';
import { Seat } from '../types/seat.type';
import { User, UserFormModel } from '../types/user.type';

export const ADMIN_SEAT_ID = 100;
export const SUPERUSER_ROLE_ID = 100;

const getSeat = (seats: Seat[], seatId: number): Seat => {
    const seat = seats.find((s) => s.id === seatId);
    assertIsDefined(seat, `seat with id ${seatId}`);
    return seat;
};

export const toFormModel = (
    user: User,
    roles: Role[],
    seats: Seat[],
    userReportIds: number[]
): UserFormModel => {
    const activeSeat = getSeat(seats, user.seatId);
    return {
        id: user.id,
        name: user.name,
        status: user.status,
        login: user.login,
        phone: user.phone,
        roleId: user.roleId,
        seatId: user.seatId,
        externalSystemIntegrations: user.externalSystemIntegrations,
        googleAccessRole: user.googleAccessRole,
        bingAccessRole: user.bingAccessRole,
        advertiserAccess: {
            mode: user.advertiserAccessLevel || activeSeat.advertiserAccess,
            entityIds: user.advertiserAccessLevel ? user.advertiserIds : activeSeat.advertiserIds,
        },
        platformAccessUrl: user.platformAccessUrl || activeSeat.platformAccessUrl,
        companyLogoUrl: user.companyLogoUrl || activeSeat.companyLogoUrl,
        platformLogoUrl: user.platformLogoUrl || activeSeat.platformLogoUrl,
        twoFactorAuthentication: user.twoFactorAuthentication || TwoFactorAuthentication.NONE,
        demoModeEnabled: user.demoModeEnabled,
        useSeatAdvertiser: !user.advertiserAccessLevel,
        useSeatPlatformAccessUrl: !user.platformAccessUrl,
        useSeatCompanyLogoUrl: !user.companyLogoUrl,
        useSeatPlatformLogoUrl: !user.platformLogoUrl,
        useSeatReports: !userReportIds.length,
        userReportIds,
        imageUrl: user.imageUrl,
        title: user.title,
        demoModeMultiplier: user.demoModeMultiplier,
        roles,
        seats,
    };
};

export const getUserDefaults = (roles: Role[], seats: Seat[]): UserFormModel => {
    const activeSeat = getSeat(seats, ADMIN_SEAT_ID);

    return {
        login: '',
        phone: '',
        name: '',
        roleId: undefined,
        status: 'ACTIVE',
        seatId: activeSeat.id,
        advertiserAccess: {
            mode: activeSeat.advertiserAccess,
            entityIds: activeSeat.advertiserIds,
        },
        platformAccessUrl: activeSeat.platformAccessUrl,
        companyLogoUrl: activeSeat.companyLogoUrl,
        platformLogoUrl: activeSeat.platformLogoUrl,
        twoFactorAuthentication: TwoFactorAuthentication.EMAIL,
        demoModeEnabled: false,
        useSeatAdvertiser: true,
        useSeatPlatformAccessUrl: true,
        useSeatCompanyLogoUrl: true,
        useSeatPlatformLogoUrl: true,
        useSeatReports: true,
        userReportIds: [],
        roles,
        seats,
    };
};

export const toApi = (formUser: UserFormModel): User => {
    const {
        id,
        login,
        phone,
        platformAccessUrl,
        companyLogoUrl,
        platformLogoUrl,
        name,
        status,
        twoFactorAuthentication,
        demoModeEnabled,
        roleId,
        seatId,
        googleAccessRole,
        bingAccessRole,
        advertiserAccess,
        useSeatAdvertiser,
        useSeatPlatformAccessUrl,
        useSeatCompanyLogoUrl,
        useSeatPlatformLogoUrl,
        imageUrl,
        title,
        demoModeMultiplier,
    } = formUser;

    return {
        id: id!,
        name,
        status,
        login,
        phone,
        roleId: roleId!,
        seatId,
        googleAccessRole,
        bingAccessRole,
        twoFactorAuthentication,
        demoModeEnabled,
        imageUrl,
        title,
        demoModeMultiplier,
        platformAccessUrl: useSeatPlatformAccessUrl ? undefined : platformAccessUrl,
        companyLogoUrl: useSeatCompanyLogoUrl ? undefined : companyLogoUrl,
        platformLogoUrl: useSeatPlatformLogoUrl ? undefined : platformLogoUrl,
        ...(!useSeatAdvertiser && {
            advertiserIds: advertiserAccess.mode !== 'CUSTOM' ? [] : advertiserAccess.entityIds,
            advertiserAccessLevel: advertiserAccess.mode,
        }),
    };
};
