import { Col, Row } from 'reactstrap';
import { Segment } from 'platform/advertiserTag/advertiserTag.types';
import { Category } from 'platform/category/category.types';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import SegmentDetailsFunnel from 'platform/segments/components//SegmentFunnel/SegmentDetailsFunnel';
import SegmentAnalysisChartContainer from 'platform/segments/components/SegmentAnalysisChart/SegmentAnalysisChartContainer';
import SegmentAnalysisTimeToAction from 'platform/segments/components/SegmentAnalysisTimeToConversion/SegmentAnalysisTimeToAction';
import SegmentAnalysisVisitTime from 'platform/segments/components/SegmentAnalysisVisitTime/SegmentAnalysisVisitTime';
import SegmentSummary from 'platform/segments/components/SegmentSummary/SegmentSummary';
import './SegmentAnalysis.scss';

type Props = {
    segment: Segment | undefined;
    categories: Category[];
};

const SegmentAnalysis = ({ segment, categories }: Props) => {
    if (!segment) {
        return null;
    }

    const { id, advertiserId, name } = segment;

    return (
        <>
            <InsetBox className="mb-4">
                <SegmentSummary id={id} categories={categories} />
            </InsetBox>
            <InsetBox className="mb-4 p-3">
                <SegmentDetailsFunnel segmentId={id} />
            </InsetBox>
            <InsetBox className="p-3 mb-4">
                <SegmentAnalysisChartContainer
                    segmentId={id}
                    advertiserId={advertiserId}
                    segmentName={name}
                />
            </InsetBox>
            <Row className="SegmentAnalysis-charts">
                <Col md="6">
                    <InsetBox className="p-3 SegmentAnalysis-chartBox">
                        <SegmentAnalysisTimeToAction advertiserId={advertiserId} segmentId={id} />
                    </InsetBox>
                </Col>
                <Col md="6">
                    <InsetBox className="p-3 SegmentAnalysis-chartBox">
                        <SegmentAnalysisVisitTime advertiserId={advertiserId} />
                    </InsetBox>
                </Col>
            </Row>
        </>
    );
};

export default SegmentAnalysis;
