import { useState } from 'react';
import { isEmpty } from 'lodash-es';
import { SelectItem } from 'platform/common/common.types';
import SelectOptionWithStatus from 'platform/common/components/Select/SelectOptionWithStatus';
import { OptionType } from 'platform/common/components/Select/select.types';
import FormCreatableSelect, {
    FormCreatableSelectProps,
} from 'platform/formik/FormCreatableSelect/FormCreatableSelect';

type Props = { parentProductLabel: SelectItem<string> } & Omit<
    FormCreatableSelectProps,
    'options' | 'onNewOption'
>;

const FormProductSubLabelSelect = ({ parentProductLabel, ...rest }: Props) => {
    const [newSubLabel, setNewSubLabel] = useState<OptionType>({});
    const subLabels = parentProductLabel.nodes ?? [];

    return (
        <FormCreatableSelect
            {...rest}
            returnOnlyValues={false}
            options={!isEmpty(newSubLabel) ? [...subLabels, newSubLabel] : subLabels}
            onNewOption={setNewSubLabel}
            menuStyle={{ minWidth: 400 }}
            formatOptionLabel={SelectOptionWithStatus}
            formatCreateLabel={(value) => (
                <span>
                    <b>Create sub-product</b>
                    {` "${value}"`}
                </span>
            )}
            placeholder="Select or enter..."
        />
    );
};

export default FormProductSubLabelSelect;
