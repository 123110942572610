import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import { IntegrationType } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import { createStrategy } from 'platform/campaign/strategy/services/strategy.service';

export const createAndEditStrategy = async (
    dispatch: Dispatch,
    campaignId: number,
    type: IntegrationType
) => {
    const { id } = await createStrategy({ campaignId, name: 'All' });
    dispatch(push(type === 'TRADITIONAL' ? `/strategies/${id}/units` : `/strategies/${id}/creatives`));
};
