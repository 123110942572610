import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { uniqueId } from 'lodash-es';
import { fulfilled, rejected } from 'platform/common/utils/actionSuffixes.util';
import { Action } from './common/common.types';

// Function from promise middleware source
const isPromise = (value: any) => {
    if (value !== null && typeof value === 'object') {
        return value && typeof value.then === 'function';
    }
    return false;
};

// This function uses same checks as does promise middleware
const isActionMiddlewareSource = (action: { type: string; payload?: any }): boolean => {
    const { payload } = action;
    if (!payload) return false;
    if (isPromise(payload) || isPromise(payload.promise)) return true;
    if (typeof payload === 'function' || typeof payload.promise === 'function') return true;
    return false;
};

// Canceling action can be done in 2 ways - 1. stopping its propagation 2. changing its name.
// Here we choose second path, cause at least for now, it might be usefull to see cancel actions
// in logs
const cancelAction = (action: Action): Action => ({
    ...action,
    type: `RACE_CANCELED/${action.type}`,
});

export const withTakeLatest = (middleware: Middleware): Middleware => {
    // here we track source action request ids
    const requestIdCache: { [key: string]: string } = {};

    // And here is wrapper middleware
    return (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: Action) => {
        // Infecting source promise action with request id and storing value to cache
        // New actions of the same type will override these value thus disabling before started actions
        if (action.takeLatest && isActionMiddlewareSource(action)) {
            const requestId = uniqueId(action.type);

            requestIdCache[fulfilled(action.type)] = requestId;
            requestIdCache[rejected(action.type)] = requestId;

            const actionWithRequestId = {
                ...action,
                meta: {
                    ...action.meta,
                    // we store requestId in meta, because its only field wich is passed to promise middleware
                    // result actions
                    requestId,
                },
            };

            return middleware(store)(next)(actionWithRequestId);
        }

        // Disabling late action results
        if (action.meta && action.meta.requestId && requestIdCache[action.type] !== action.meta.requestId) {
            return middleware(store)(next)(cancelAction(action));
        }

        return middleware(store)(next)(action);
    };
};
