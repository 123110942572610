import classNames from 'classnames';
import DeeplyAiLogo from 'platform/assets/images/deeply-ai-logo.svg';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import FullScreenChatLink from '../MinimizedChat/FullScreenChatLink';
import useChat from '../useChat';
import ChatAgentsSelect from './ChatAgentsSelect';
import ChatIntroVideo from './ChatIntroVideo';
import './ChatLanding.scss';

interface Props {
    onAgentSelect: () => void;
    className?: string;
    showLink?: boolean;
    prevStep?: () => void;
}

const ChatLanding = ({ onAgentSelect, className, showLink = false, prevStep }: Props) => {
    const { agentsLoading, setSelectedAgent } = useChat();
    const handleAdvertiserReset = () => prevStep && (prevStep(), setSelectedAgent(undefined));

    return (
        <div className={classNames('ChatLanding', className)}>
            {agentsLoading && <OverlayLoader />}
            <div className="ChatLanding-backgroundImg">
                <div className="ChatLanding-header">
                    <img src={DeeplyAiLogo} className="mx-auto" alt="DeeplyAI Logo" />
                    {showLink && (
                        <FullScreenChatLink style={{ right: 32, top: 32 }} className="position-absolute" />
                    )}
                </div>
                <div className="d-flex mx-auto w-100">
                    <ChatAgentsSelect
                        onAgentSelect={onAgentSelect}
                        handleAdvertiserReset={handleAdvertiserReset}
                    />
                </div>
            </div>
            <div className="flex-grow-1 d-flex flex-column justify-content-center">
                <div className="fs-2 fw-bold p-4 w-100 text-center">Improving your digital marketing</div>
                <ChatIntroVideo />
            </div>
        </div>
    );
};

export default ChatLanding;
