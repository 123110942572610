import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import Spinner from 'platform/common/components/Spinner/Spinner';
import { useLoading } from 'platform/common/hooks/useLoading';
import { downloadObserverExecutionAttachment } from 'platform/observer/observer.service';

interface Props {
    reportId: number;
    executionId: number;
    detailId: number;
}

const ObserverExecutionAttachmentLink = ({ reportId, executionId, detailId }: Props) => {
    const [downloading, whileDownloading] = useLoading();

    const handleClick = () => {
        whileDownloading(() => downloadObserverExecutionAttachment(reportId, executionId, detailId));
    };

    return (
        <LabelLink onClick={handleClick}>
            {downloading ? <Spinner size="sm" /> : <i className="fa fa-paperclip" />}
        </LabelLink>
    );
};

export default ObserverExecutionAttachmentLink;
