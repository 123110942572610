import { ReactNode } from 'react';
import { NavLink as RouterNavLink, Route, Routes } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import DefaultMetricsList, {
    PATH as DEFAULT_METRICS,
} from 'platform/campaign/advertiserManagement/DefaultMetrics/DefaultMetricsList';
import AnalyticsNaming, {
    PATH as ANALYTICS_NAMING,
} from 'platform/campaign/advertiserManagement/LabelOverrides/AnalyticsNaming';
import ProductFeedList, {
    PATH as PRODUCT_FEEDS,
} from 'platform/campaign/advertiserManagement/ProductFeeds/ProductFeedList';
import ProductLabelList, {
    PATH as PRODUCT_LABELS,
} from 'platform/campaign/advertiserManagement/ProductLabels/ProductLabelList';
import CustomActionRuleList, {
    PATH as CUSTOM_ACTION_RULES,
} from 'platform/campaign/globalPreset/components/CustomActionRule/CustomActionRuleList';
import GlobalCostSourceList, {
    PATH as GLOBAL_COST_SOURCES,
} from 'platform/campaign/globalPreset/components/GlobalCostSources/GlobalCostSourceList';
import ScoringList, { PATH as SCORING } from 'platform/campaign/globalPreset/components/Scoring/ScoringList';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import AdvertiserAdvancedSettingsFormContainer, {
    PATH as ADVANCED_SETTINGS,
} from '../AdvertiserAdvanced/AdvertiserAdvancedSettingsFormContainer';
import AdvertiserIntegrationsFormContainer, {
    PATH as ADVERTISER_INTEGRATIONS,
} from '../AdvertiserIntegrations/AdvertiserIntegrationsFormContainer';
import CustomMetricList, { PATH as CUSTOM_METRICS } from '../CustomMetrics/CustomMetricList';
import OptInRateList, { PATH as OPT_IN_RATES } from '../OptInRates/OptInRateList';
import TimeOfferList, { PATH as TIME_OFFERS } from '../TimeOffers/TimeOfferList';
import { ADVERTISER_SETTINGS } from '../advertiserSettings.navigation';

const NavItemLink = (props: { children: ReactNode; to: string }) => (
    <NavItem>
        <NavLink tag={RouterNavLink} {...props} />
    </NavItem>
);

const AdvertiserSettings = () => (
    <>
        <HeaderContainer>
            <PageHeader title={ADVERTISER_SETTINGS.name} />
        </HeaderContainer>
        <BodyContainer>
            <div className="custom-tabs">
                <Nav tabs>
                    <NavItemLink to={ADVERTISER_INTEGRATIONS}>Integrations</NavItemLink>
                    <NavItemLink to={GLOBAL_COST_SOURCES}>Cost sources</NavItemLink>
                    <NavItemLink to={PRODUCT_LABELS}>Product labels</NavItemLink>
                    <NavItemLink to={PRODUCT_FEEDS}>Product feeds</NavItemLink>
                    <NavItemLink to={TIME_OFFERS}>Time offers</NavItemLink>
                    <NavItemLink to={CUSTOM_METRICS}>Custom metrics</NavItemLink>
                    <NavItemLink to={DEFAULT_METRICS}>Default metrics</NavItemLink>
                    <NavItemLink to={ANALYTICS_NAMING}>Analytics naming</NavItemLink>
                    <NavItemLink to={ADVANCED_SETTINGS}>Advanced</NavItemLink>
                    <NavItemLink to={CUSTOM_ACTION_RULES}>Custom action rules</NavItemLink>
                    <NavItemLink to={SCORING}>Scoring rules</NavItemLink>
                    <NavItemLink to={OPT_IN_RATES}>Opt-In Rates</NavItemLink>
                </Nav>
            </div>
        </BodyContainer>
        <Routes>
            <Route path="integrations" element={<AdvertiserIntegrationsFormContainer />} />
            <Route path="global-cost-sources/*" element={<GlobalCostSourceList />} />
            <Route path="product-labels/*" element={<ProductLabelList />} />
            <Route path="product-feeds/*" element={<ProductFeedList />} />
            <Route path="time-offers/*" element={<TimeOfferList />} />
            <Route path="opt-in-rates/*" element={<OptInRateList />} />
            <Route path="custom-metrics/*" element={<CustomMetricList />} />
            <Route path="default-metrics/*" element={<DefaultMetricsList />} />
            <Route path="analytics-naming/*" element={<AnalyticsNaming />} />
            <Route path="advanced" element={<AdvertiserAdvancedSettingsFormContainer />} />
            <Route path="custom-action-rules/*" element={<CustomActionRuleList />} />
            <Route path="scoring/*" element={<ScoringList />} />
        </Routes>
    </>
);

export default AdvertiserSettings;
