import { Key, MouseEvent, ReactNode } from 'react';
import { Button } from 'reactstrap';
import { Modifier } from '@popperjs/core';
import classNames from 'classnames';
import Popover, {
    PopoverBoundaries,
    PopoverContainerType,
    PopoverPlacement,
} from 'platform/common/components/Popover/Popover';
import PopoverDropdownOption from './PopoverDropdownOption';
import './PopoverDropdown.scss';

export interface PopoverDropdownItem {
    action: () => void;
    label: ReactNode;
    confirmation?: ReactNode;
    icon?: string;
    key?: Key;
    disabled?: boolean;
}

interface Props {
    toggler: ReactNode;
    items?: PopoverDropdownItem[];
    className?: string;
    togglerClassName?: string;
    boundariesElement?: PopoverBoundaries;
    popoverClassName?: string;
    placement?: PopoverPlacement;
    buttonColorProp?: string;
    preventOverflow?: boolean;
    modifiers?: Modifier<string, any>[];
    children?: ({ toggle }: { toggle: (event?: MouseEvent<HTMLButtonElement>) => void }) => ReactNode;
    container?: PopoverContainerType;
}

const PopoverDropdown = ({
    toggler,
    items,
    className,
    togglerClassName,
    popoverClassName,
    boundariesElement,
    children,
    modifiers,
    placement = 'bottom-end',
    buttonColorProp = 'link',
    preventOverflow = true,
    container,
}: Props) => (
    <Popover
        popoverClassName={classNames('PopoverDropdown-popover', popoverClassName)}
        modifiers={modifiers}
        className={className}
        placement={placement}
        preventOverflow={preventOverflow}
        boundariesElement={boundariesElement}
        container={container}
        renderPopover={({ toggle }) => (
            <>
                {children
                    ? children({ toggle })
                    : (items || []).map((item, index) => (
                          <PopoverDropdownOption key={index} item={item} toggle={toggle} />
                      ))}
            </>
        )}
    >
        <Button
            type="button"
            className={classNames('PopoverDropdown-toggler', togglerClassName)}
            color={buttonColorProp}
        >
            {toggler}
        </Button>
    </Popover>
);
export default PopoverDropdown;
