import { useDispatch, useSelector } from 'react-redux';
import { ReportFilter } from 'platform/analytics/analytics.types';
import { getFilterNumberValue } from 'platform/analytics/analytics.util';
import {
    findRegisteredActionById,
    isRedirectAction,
} from 'platform/analytics/reportComponents/ReportTableContainer/InlineActions/inlineActionConnect';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { isDefined } from 'platform/common/common.types';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';

type Props = {
    filters: ReportFilter[];
};

const CampaignActions = ({ filters }: Props) => {
    const dispatch = useDispatch();
    const canAccess = useSelector(authSelectors.canAccess);

    const context = {
        advertiser_id: getFilterNumberValue(filters, 'advertiser_id'),
        campaign_id: getFilterNumberValue(filters, 'campaign_id'),
    };

    const items = [findRegisteredActionById('add_campaign')]
        .filter(isDefined)
        .filter(isRedirectAction)
        .filter(canAccess)
        .map((action) => ({
            label: action.label,
            action: () => action.operation(context, dispatch),
        }));

    if (!items.length) {
        return null;
    }

    return <ButtonDropdown items={items}>Add new</ButtonDropdown>;
};

export default CampaignActions;
