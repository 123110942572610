import { useEffect, useRef, useState } from 'react';
import { InputGroup, InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { AnalyticsCompareType, CompareOption, DatePickerMode } from 'platform/analytics/analytics.types';
import { PopoverPlacement } from 'platform/common/components/Popover/Popover';
import { Periods } from 'platform/common/ducks/dateFilter.duck';
import { DATE_MOMENT_FORMAT } from '../../constants/dateConfiguration.constant';
import { DateRangePreset, DateRanges, DEFAULT_DATE_RANGES } from '../../utils/date.util';
import DoubleDateRangePickerPopover from './DoubleDateRangePickerPopover';
import './DoubleDateRangePicker.scss';

type Props = Periods & {
    id?: number | string;
    minDate?: string;
    maxDate?: string;
    className?: string;
    placement?: PopoverPlacement;
    mode?: DatePickerMode;
    displayFormat?: string;
    compareType?: AnalyticsCompareType;
    disabled?: boolean;
    ranges?: DateRanges;
    compareOptions?: CompareOption[];
    isPopoverOpen?: boolean;
    showCompareValues: boolean;
    fromDate?: Moment;
    toDate?: Moment;
    onChange: (periods: Periods) => any;
    onShowCompareValuesChange: (showCompareValues: boolean) => void;
};

const displayPeriod = (
    from: string | undefined,
    to: string | undefined,
    preset: DateRangePreset | undefined,
    format: string,
    ranges: DateRanges | undefined
) => {
    if (!from && !to) {
        return '';
    }

    const formattedFrom = from ? moment(from).format(format) : '';
    const formattedTo = to ? moment(to).format(format) : '';

    if (preset) {
        const presetLabel = (ranges && ranges[preset]?.label) || preset;
        return `${presetLabel} (${formattedFrom} - ${formattedTo})`;
    }

    return `${formattedFrom} - ${formattedTo}`;
};

const DoubleDateRangePicker = ({
    primaryFrom,
    primaryTo,
    secondaryFrom,
    secondaryTo,
    minDate,
    maxDate,
    className,
    compareType,
    mode = 'DEFAULT',
    ranges,
    placement = 'bottom-start',
    compareOptions,
    disabled = false,
    preset,
    displayFormat = DATE_MOMENT_FORMAT,
    isPopoverOpen = false,
    showCompareValues,
    fromDate,
    toDate,
    onShowCompareValuesChange,
    onChange,
}: Props) => {
    const [popoverOpen, togglePopoverOpen] = useState(false);
    const iconContainerRef = useRef<HTMLDivElement | null>(null);
    const resolvedRanges = ranges ?? DEFAULT_DATE_RANGES[mode];

    const togglePopover = () => {
        if (disabled) return;
        togglePopoverOpen((open) => !open);
    };

    const onPeriodsChange = (periods: Periods) => {
        onChange(periods);
        togglePopover();
    };

    useEffect(() => {
        togglePopoverOpen(isPopoverOpen);
    }, [isPopoverOpen]);
    return (
        <>
            <div
                ref={iconContainerRef}
                className={classNames('DoubleDateRangePicker', className)}
                onClick={togglePopover}
                role="button"
                tabIndex={0}
            >
                <InputGroup
                    className={classNames('DoubleDateRangePicker--inputGroup align-items-center', {
                        disabled,
                    })}
                >
                    <InputGroupText>
                        <i className="far fa-calendar-alt" />
                    </InputGroupText>
                    <div
                        className={classNames('DoubleDateRangePicker--inputImitator', {
                            active: popoverOpen,
                        })}
                        style={{ height: 'auto' }}
                    >
                        <div className="DoubleDateRangePicker-period">
                            {displayPeriod(primaryFrom, primaryTo, preset, displayFormat, resolvedRanges)}
                        </div>
                        {!!secondaryFrom && !!secondaryTo && (
                            <div className="DoubleDateRangePicker-comparePeriod">
                                vs.{' '}
                                {displayPeriod(
                                    secondaryFrom,
                                    secondaryTo,
                                    undefined,
                                    displayFormat,
                                    resolvedRanges
                                )}
                            </div>
                        )}
                    </div>
                </InputGroup>
            </div>
            {iconContainerRef.current && popoverOpen && (
                <DoubleDateRangePickerPopover
                    target={iconContainerRef.current}
                    placement={placement}
                    mode={mode}
                    toggle={togglePopover}
                    primaryFrom={primaryFrom}
                    primaryTo={primaryTo}
                    preset={preset}
                    secondaryFrom={secondaryFrom}
                    secondaryTo={secondaryTo}
                    compareType={compareType}
                    minDate={minDate}
                    maxDate={maxDate}
                    ranges={resolvedRanges}
                    compareOptions={compareOptions}
                    showCompareValues={showCompareValues}
                    onChange={onPeriodsChange}
                    onCancel={togglePopover}
                    fromDate={fromDate}
                    toDate={toDate}
                    onShowCompareValuesChange={onShowCompareValuesChange}
                />
            )}
        </>
    );
};

export default DoubleDateRangePicker;
