import { isNil } from 'lodash-es';
import { StatisticWithSeries, TypeDefinition } from 'platform/common/common.types';
import StatisticChart from 'platform/common/components/StatisticChart/StatisticChart';

const defaultToEmptySeries = (series: string[]): string[] =>
    series && series.length && !isNil(series[0]) ? series : Array(7).fill('0');

interface Props {
    label: string;
    type: TypeDefinition;
    stats: StatisticWithSeries;
}

const ChartWithSeries = ({ label, type, stats }: Props) => (
    <StatisticChart
        name={label}
        totalValue={type.format(stats.value)}
        labels={defaultToEmptySeries(stats.series.labels)}
        values={defaultToEmptySeries(stats.series.values.map(type.parse)).map(Number)}
    />
);

export default ChartWithSeries;
