import { useEffect, useState } from 'react';

const getPageRange = (pageSize: number, pageNumber: number): { from: number; to: number } => {
    if (pageNumber === 0) {
        return {
            from: 0,
            to: pageSize,
        };
    }

    return {
        from: pageNumber * pageSize,
        to: pageNumber * pageSize + 1,
    };
};

const getPageCount = <T extends {}>(data: T[], pageSize: number) => Math.ceil(data.length / pageSize);

const getPageData = <T extends {}>(data: T[], pageSize: number, pageNumber: number) => {
    const { from, to } = getPageRange(pageSize, pageNumber);

    return data.slice(from, to);
};

const getState = <T extends {}>(pageSize: number, pageNumber: number, data: T[]): State<T> => ({
    pageSize,
    pageNumber,
    pageCount: getPageCount(data, pageSize),
    pageData: getPageData(data, pageSize, pageNumber),
});

const getPageNumber = (pageNumber: number, pageCount: number) => {
    if (pageNumber <= 0) {
        return 0;
    }

    if (pageNumber > pageCount) {
        return pageCount;
    }

    return pageNumber;
};

interface State<T> {
    pageData: T[];
    pageNumber: number;
    pageSize: number;
    pageCount: number;
}

interface Props<T> {
    data: T[];
    pageSizeOptions?: number[];
}

export const usePagination = <T extends {}>({ data, pageSizeOptions = [10, 20, 50, 100, 200] }: Props<T>) => {
    const [{ pageCount, pageSize, pageNumber, pageData }, setState] = useState<State<T>>(
        getState(pageSizeOptions[0], 0, data)
    );

    const handleStateChange = (newPageNumber: number, newPageSize: number) => {
        setState(getState(newPageSize, getPageNumber(newPageNumber, pageCount), data));
    };

    useEffect(() => {
        handleStateChange(pageNumber, pageSize);
    }, [data]);

    return {
        pageCount,
        pageSize,
        pageNumber,
        pageData,
        pageSizeOptions,
        onPageChange: (value: number) => handleStateChange(value, pageSize),
        onPageSizeChange: (value: number) => handleStateChange(pageNumber, value),
    };
};
