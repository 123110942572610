import { ChangeEvent } from 'react';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import Switch from 'platform/common/components/Switch/Switch';

type SpacerDisplay = 'HEADING' | 'NONE';

export interface SpacerType {
    text: string;
    display: SpacerDisplay;
    isContainer: boolean;
}

interface Props {
    spacer?: SpacerType;
    className?: string;
    onChange: (spacer?: SpacerType) => void;
}

const EMPTY_SPACER: SpacerType = {
    text: '',
    display: 'NONE',
    isContainer: false,
};

const ReportSpacer = ({ className, spacer = EMPTY_SPACER, onChange }: Props) => (
    <div className={className}>
        <div className="d-flex align-items-center mb-3 ms-auto">
            <span className="me-3">Section Headline</span>
            <Switch
                className="me-2"
                input={{
                    value: spacer?.display === 'HEADING',
                    onChange: (e) =>
                        onChange({ ...spacer, display: e.target.checked ? 'HEADING' : 'NONE', text: '' }),
                }}
            />
        </div>

        {spacer?.display === 'HEADING' && (
            <InputWithAddOn
                className="mb-3"
                leftAddOn={{ title: 'Section Headline' }}
                placeholder="Type section headline..."
                value={spacer.text}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({ ...spacer, text: e.target.value })}
            />
        )}

        <div className="d-flex align-items-center ms-auto">
            <span className="me-3">Section Container</span>
            <Switch
                className="me-2"
                input={{
                    value: spacer?.isContainer,
                    onChange: (e) => onChange({ ...spacer, isContainer: e.target.checked }),
                }}
            />
        </div>
    </div>
);

export default ReportSpacer;
