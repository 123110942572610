import { useState } from 'react';
import UserSelect from 'platform/common/components/UserSelect/UserSelect';
import { updateObserverMessage } from 'platform/observerMessageCenter/observerMessageCenter.service';
import { ObserverMessage } from 'platform/observerMessageCenter/observerMessageCenter.types';
import { User } from 'platform/userManagement/types/user.type';

interface Props {
    messageData: ObserverMessage;
    assigneeOptions: User[];
    className?: string;
}

const MessageCenterItemAssignees = ({ messageData, assigneeOptions, className }: Props) => {
    const [assignees, setAssignees] = useState(messageData.assignees);

    const handleBlur = async () => {
        await updateObserverMessage(messageData.id, { assignees });
    };

    return (
        <UserSelect
            className={className}
            placeholder="Select assignees"
            userIds={assignees}
            onBlur={handleBlur}
            onChange={setAssignees}
            options={assigneeOptions}
        />
    );
};

export default MessageCenterItemAssignees;
