import { SelectItem } from 'platform/common/common.types';

export type AccessType = 'ALL' | 'CUSTOM';

export const ACCESS_OPTIONS: SelectItem<AccessType>[] = [
    { value: 'ALL', label: 'All' },
    { value: 'CUSTOM', label: 'Custom' },
];

export type GoogleAccessRole = 'READ_ONLY' | 'STANDARD' | 'ADMIN';

export const GOOGLE_ACCESS_OPTIONS: SelectItem<GoogleAccessRole | undefined>[] = [
    { value: undefined, label: 'None' },
    { value: 'READ_ONLY', label: 'Read-only' },
    { value: 'STANDARD', label: 'Standard' },
    { value: 'ADMIN', label: 'Administrative' },
];

export type BingAccessRole =
    | 'VIEWER'
    | 'STANDARD'
    | 'SUPER_ADMIN'
    | 'AGGREGATOR'
    | 'ADVERTISER_CAMPAIGN_MANAGER';

export const BING_ACCESS_OPTIONS: SelectItem<BingAccessRole | undefined>[] = [
    { value: undefined, label: 'None' },
    { value: 'VIEWER', label: 'Viewer' },
    { value: 'STANDARD', label: 'Standard' },
    { value: 'SUPER_ADMIN', label: 'Super admin' },
    { value: 'AGGREGATOR', label: 'Aggregator' },
    { value: 'ADVERTISER_CAMPAIGN_MANAGER', label: 'Advertiser campaign manager' },
];
