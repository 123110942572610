import { useSelector } from 'react-redux';
import { INTEGRATIONS_INFO } from 'platform/common/constants/externalIntegration.constant';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { publicImageUrl } from 'platform/common/utils/environment.util';

interface Props {
    vendorCode: string;
    only?: 'NAME' | 'ICON';
}

const VendorLabel = ({ vendorCode, only }: Props) => {
    const vendor = useSelector(classifierSelectors.vendors).find((v) => v.externalSystem === vendorCode);
    const name = vendor?.name ?? INTEGRATIONS_INFO[vendorCode]?.label ?? vendorCode;
    const iconSrc = vendor?.iconUrl ? publicImageUrl(vendor.iconUrl) : INTEGRATIONS_INFO[vendorCode]?.icon;

    if (only === 'NAME') {
        return <>{name}</>;
    }

    const icon = iconSrc ? (
        <img
            src={iconSrc}
            alt={name}
            className="me-1"
            width={24}
            height={20}
            style={{ objectFit: 'contain' }}
        />
    ) : null;

    if (only === 'ICON') {
        return icon;
    }

    return (
        <span className="d-flex align-items-center text-nowrap text-truncate">
            {icon}
            {name}
        </span>
    );
};

export default VendorLabel;
