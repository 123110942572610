import { FormikProps } from 'formik';
import { DataSharingTemplate } from '../../../adminPanel/adminPanel.types';
import { Advertiser } from '../../../advertisers/advertiser.types';
import { Vendor } from '../../../vendors/vendors.types';
import { Segment, ThirdPartyCodesFormValues, ThirdPartyTrackingRuleModel } from '../../advertiserTag.types';
import { RuleSource } from '../../constants/ruleSource.constant';
import DataSharingRule from './DataSharingRule';
import RuleBuilderRule from './RuleBuilderRule';

interface Props {
    segments: Segment[];
    vendors: Vendor[];
    templates: DataSharingTemplate[];
    advertiser: Advertiser;
    rules: ThirdPartyTrackingRuleModel[];
    setFieldValue: FormikProps<ThirdPartyCodesFormValues>['setFieldValue'];
    onRuleRemove?: (index: number) => void;
}

const ThirdPartyRules = ({
    rules,
    segments,
    onRuleRemove,
    vendors = [],
    templates = [],
    advertiser,
    setFieldValue,
}: Props) => (
    <>
        {rules.map((rule, index) =>
            rule.ruleSource === RuleSource.DATA_SHARING_TEMPLATE ? (
                <DataSharingRule
                    key={rule.tempId}
                    index={index}
                    rule={rule}
                    templates={templates}
                    vendors={vendors}
                    advertiser={advertiser}
                    onRemove={onRuleRemove}
                    setFieldValue={setFieldValue}
                    segments={segments}
                />
            ) : (
                <RuleBuilderRule
                    key={rule.tempId}
                    index={index}
                    rule={rule}
                    setFieldValue={setFieldValue}
                    onRemove={onRuleRemove}
                    segments={segments}
                    vendors={vendors}
                />
            )
        )}
    </>
);

export default ThirdPartyRules;
