import { Button } from 'reactstrap';
import classNames from 'classnames';
import { ReportComponentState } from 'platform/analytics/analytics.types';
import './ReportComponentItem.scss';

interface Props {
    component: Omit<ReportComponentState, 'id'>;
    active?: boolean;
    onClick: (component: Omit<ReportComponentState, 'id'>) => void;
}

const ReportComponentItem = ({ component, active, onClick }: Props) => (
    <Button className={classNames('ReportComponentItem', { active })} onClick={() => onClick(component)}>
        {typeof component.icon === 'string' ? (
            <i className={classNames('text-primary font-2xl', component.icon)} />
        ) : (
            <component.icon />
        )}
        <div className="font-lg">{component.name}</div>
    </Button>
);

export default ReportComponentItem;
