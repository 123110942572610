import { FormGroup, Input, Label } from 'reactstrap';
import { BarState, COLUMN_TYPE_OPTIONS, ColumnDefinitionOption } from 'platform/analytics/analytics.types';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import ReportChartEntriesCount from '../ReportChartEntriesCount';

interface Props {
    componentState: BarState;
    metrics: ColumnDefinitionOption[];
    dimensions: ColumnDefinitionOption[];
    isLoading: boolean;
    onChange: (state: BarState) => void;
}

const ReportBarChartSettings = ({ componentState, metrics, dimensions, isLoading, onChange }: Props) => (
    <>
        <ReportSelectTree
            className="mb-3"
            name="Dimension"
            options={dimensions}
            value={componentState.dimensions[0]}
            isLoading={isLoading}
            isMulti={false}
            onChange={(dimension) =>
                onChange({ ...componentState, dimensions: dimension ? [dimension] : [] })
            }
        />

        <ReportSelectTree
            className="mb-3"
            name="Metric"
            options={metrics}
            value={componentState.metrics}
            isLoading={isLoading}
            isMulti
            onChange={(values) => onChange({ ...componentState, metrics: values })}
        />
        <SelectWithAddon
            selectClassname="flex-fill"
            className="mb-3"
            value={componentState.sortBy}
            name="Sort By"
            options={COLUMN_TYPE_OPTIONS}
            isClearable={false}
            onChange={(sortBy) => onChange({ ...componentState, sortBy })}
        />
        <ReportChartEntriesCount
            entriesCount={componentState.entriesCount}
            onChange={(entriesCount) => onChange({ ...componentState, entriesCount })}
        />
        <FormGroup check className="mt-2">
            <Label check>
                <Input
                    type="checkbox"
                    checked={componentState.showOtherValues ?? true}
                    onChange={(e) => onChange({ ...componentState, showOtherValues: e.target.checked })}
                />
                Show other values
            </Label>
        </FormGroup>
    </>
);

export default ReportBarChartSettings;
