import { ReactNode } from 'react';
import { EMPTY_SYMBOL } from 'platform/common/constants/common.constant';

interface Props {
    children?: ReactNode;
    colSpan?: number;
    cellClassName?: string;
    className?: string;
    showEmptyPlaceholder?: boolean;
}

const BodyCell = ({ cellClassName, children, colSpan, className, showEmptyPlaceholder = true }: Props) => (
    <td className={cellClassName} colSpan={colSpan}>
        <div className={className}>{children || (showEmptyPlaceholder && EMPTY_SYMBOL)}</div>
    </td>
);

export default BodyCell;
