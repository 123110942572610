import { usePromise } from 'platform/common/hooks/usePromise';
import { fetchCreativeLabels } from 'platform/creatives/services/creativeLabel.service';
import { CreativeLabelGroup } from 'platform/creatives/types/creativeLabel.types';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';

interface Props {
    advertiserId: number;
    name: string;
}

const CreativeLabelSelect = ({ advertiserId, name }: Props) => {
    const [{ data }] = usePromise([], () => fetchCreativeLabels({ advertiserId }));

    return (
        <FormSelectTree<CreativeLabelGroup>
            name={name}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            getOptionParentLabel={(option) => option.name}
            childrenKey="labels"
            options={data}
        />
    );
};

export default CreativeLabelSelect;
