import { sortBy } from 'lodash-es';
import { SyncEntityType } from 'platform/adminPanel/components/SyncJournal/syncJournal.types';
import { IntegrationDetailsIcon } from 'platform/common/components/IntegrationDetailsIcon/IntegrationDetailsIcon';
import { ExternalSystem, IntegrationStatus } from '../../constants/externalIntegration.constant';

type Integration = {
    externalId?: string;
    status?: IntegrationStatus;
};

type Props = {
    id?: number;
    syncEntityType?: SyncEntityType;
    integrations?: { [key in ExternalSystem]?: Integration };
    onResync?: (internalId: number, system: ExternalSystem) => void;
};

const DspIntegrationIconList = ({ id, syncEntityType, integrations, onResync }: Props) => {
    if (!integrations) {
        return null;
    }
    const sortedEntries = sortBy(
        Object.entries(integrations).filter(([, i]) => i),
        ([system]) => system
    );
    return (
        <>
            {sortedEntries.map(([externalSystem, { externalId, status }]: [ExternalSystem, Integration]) => (
                <IntegrationDetailsIcon
                    key={externalSystem}
                    syncEntityType={syncEntityType}
                    internalId={id}
                    integration={{
                        externalSystem,
                        externalId,
                        synchronizationState: { status },
                    }}
                    onResync={onResync}
                />
            ))}
        </>
    );
};

export default DspIntegrationIconList;
