import { Modal, ModalBody } from 'reactstrap';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { usePromise } from 'platform/common/hooks/usePromise';
import { User } from 'platform/userManagement/types/user.type';
import { getLaneChanges } from '../task.service';
import { TaskBoardChange, TaskLane } from '../task.types';
import BoardChangesTable from './BoardChangesTable';

interface Props {
    boardId: number;
    lane: TaskLane;
    userOptions: User[];
    onClose: () => void;
}

const LaneChangesModal = ({ boardId, lane, userOptions, onClose }: Props) => {
    const [{ data: changes, loading }] = usePromise<TaskBoardChange[]>(
        [],
        () => getLaneChanges(boardId, lane.id),
        [boardId, lane.id]
    );

    return (
        <Modal isOpen toggle={onClose} style={{ width: 700, maxWidth: 700 }}>
            <ModalHeader onClose={onClose}>Column change history</ModalHeader>
            <ModalBody className="p-4">
                <BoardChangesTable
                    board={{ lanes: [lane] }}
                    userOptions={userOptions}
                    changes={changes}
                    loading={loading}
                />
            </ModalBody>
        </Modal>
    );
};

export default LaneChangesModal;
