import { Alert } from 'reactstrap';
import { DeeplyChatError } from 'platform/chat/chat.types';
import ErrorReporting from 'platform/common/components/Errors/ErrorReporting';

interface Props {
    error: DeeplyChatError;
}

const ChatAssistantError = ({ error }: Props) => (
    <Alert color="danger">
        <p className="mb-0">{error.message}</p>
        <ErrorReporting
            autoSend
            error={{
                message: error.message,
                response: error,
            }}
        />
    </Alert>
);

export default ChatAssistantError;
