import { RootState } from 'platform/rootState.type';
import { WikiPageData, WikiSpace } from './wiki.types';

export interface WikiReducerState {
    wikiSpace: WikiSpace;
    wikiPages: WikiPageData[];
}

const initialState: WikiReducerState = {
    wikiSpace: 'ACADEMY',
    wikiPages: [],
};

const SET_ACTIVE_WIKI_SPACE = 'wiki-actions/SET_ACTIVE_WIKI_SPACE';
const SET_WIKI_PAGES = 'wiki-actions/SET_WIKI_PAGES';

type SetWikiSpaceAction = {
    type: typeof SET_ACTIVE_WIKI_SPACE;
    payload: WikiSpace;
};

type SetWikiPages = {
    type: typeof SET_WIKI_PAGES;
    payload: WikiPageData[];
};

type Action = SetWikiSpaceAction | SetWikiPages;

export const wikiReducer = (state: WikiReducerState = initialState, action: Action): WikiReducerState => {
    switch (action.type) {
        case SET_ACTIVE_WIKI_SPACE:
            return { ...state, wikiSpace: action.payload };
        case SET_WIKI_PAGES:
            return { ...state, wikiPages: action.payload };
        default:
            return state;
    }
};

export const wikiActions = {
    setActiveWikiSpace: (payload: WikiSpace): Action => ({ type: SET_ACTIVE_WIKI_SPACE, payload }),
    setWikiPages: (payload: WikiPageData[]): Action => ({ type: SET_WIKI_PAGES, payload }),
};

export const wikiSelectors = {
    activeWikiSpace: (state: RootState): WikiSpace => state.wikiReducer.wikiSpace,
    wikiPages: (state: RootState): WikiPageData[] => state.wikiReducer.wikiPages,
};
