import axios from 'platform/axios/axiosBff';
import { Scoring } from 'platform/campaign/globalPreset/globalPreset.types';

export const getScoring = (advertiserId: number) =>
    axios
        .get<Scoring>('/campaign/api/scoring/config', { params: { advertiserId } })
        .then((response) => response.data);

export const createOrUpdateScoring = (advertiserId: number, scoring: Scoring) =>
    axios
        .put<Scoring>('/campaign/api/scoring/config', scoring, { params: { advertiserId } })
        .then((response) => response.data);
