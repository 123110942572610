import { Input, InputGroup, InputGroupText } from 'reactstrap';

interface Props {
    pageSize?: number;
    onChange: (pageSize?: number) => void;
}

const ReportPaging = ({ pageSize, onChange }: Props) => (
    <InputGroup>
        <InputGroupText>Limit to</InputGroupText>
        <Input
            type="number"
            value={pageSize}
            onChange={(e) => onChange(e.target.value ? Number(e.target.value) : undefined)}
        />
        <InputGroupText>rows</InputGroupText>
    </InputGroup>
);

export default ReportPaging;
