import axios from 'platform/axios/axiosBff';
import { DefaultMetrics } from './defaultMetrics.types';

export const getDefaultMetrics = (advertiserId: number) =>
    axios
        .get<DefaultMetrics | null>('/campaign/api/default-metrics', { params: { advertiserId } })
        .then((response) => response.data || null);

export const storeDefaultMetrics = (advertiserId: number, data: DefaultMetrics) =>
    axios
        .put<void>(`/campaign/api/default-metrics`, data, { params: { advertiserId } })
        .then((response) => response.data);
