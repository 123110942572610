import { CSSProperties, ReactNode } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';

type Props = {
    id: number | string;
    children: ReactNode;
    onRemove: () => void;
    className?: string;
    style?: CSSProperties;
    visibleOverflow?: boolean;
    childrenClassName?: string;
    canRemove?: boolean;
};

const RemovableLineItem = ({
    id,
    children,
    onRemove,
    className,
    style,
    visibleOverflow,
    childrenClassName,
    canRemove = true,
}: Props) => (
    <div
        key={id}
        style={{
            border: '1px solid #cbd0d9',
            borderRadius: '3px',
            backgroundColor: '#f5f6f9',
            ...style,
        }}
        className={classNames('d-flex p-2 align-items-center', className)}
    >
        <div className={classNames(childrenClassName, { 'overflow-hidden': !visibleOverflow })}>
            {children}
        </div>
        <Button className="ms-auto text-muted p-0" color="link" disabled={!canRemove} onClick={onRemove}>
            <i className="fal fa-times font-xs" />
        </Button>
    </div>
);

export default RemovableLineItem;
