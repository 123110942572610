import { Input } from 'reactstrap';
import classNames from 'classnames';
import PopoverButton from 'platform/common/components/PopoverButton/PopoverButton';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import WorkflowNotesPopover from 'platform/mediaplan/components/MediaplanWorkflow/common/WorkflowStepControls/WorkflowNotesPopover';
import { WorkflowEvent, WorkflowStep } from 'platform/mediaplan/mediaplan.types';
import '../../MediaplanWorkflow.scss';

interface Props {
    step: WorkflowStep;
    stepEvents: WorkflowEvent[];
    canEdit: boolean;
    onChange: (step: WorkflowStep, event?: WorkflowEvent) => void;
}

const WorkflowStepControls = ({ step, stepEvents, canEdit, onChange }: Props) => (
    <div className="MediaplanWorkflow-stepControls">
        <PopoverButton
            placement="top"
            icon={
                <i
                    className={classNames(
                        'fa-message ms-1',
                        step.notes || stepEvents.length
                            ? 'fa-solid text-success'
                            : 'far text-light-slate-gray'
                    )}
                />
            }
        >
            {({ togglePopover }) => (
                <WorkflowNotesPopover
                    step={step}
                    stepEvents={stepEvents}
                    canEdit={canEdit}
                    onChange={onChange}
                    togglePopover={togglePopover}
                />
            )}
        </PopoverButton>
        {step.updatedOn && (
            <Tooltip
                renderTooltip={() => (
                    <>
                        <div className="mb-1 fw-bold">{step.completed ? 'Checked' : 'Unchecked'} on:</div>
                        <UpdatedOn date={step.updatedOn} updatedBy={step.updatedBy} />
                    </>
                )}
            >
                <i
                    className={classNames(
                        'fa-user ms-1',
                        step.completed ? 'fa-solid text-success' : 'far text-light-slate-gray'
                    )}
                />
            </Tooltip>
        )}
        <Input
            type="checkbox"
            className="position-static ms-auto mt-0"
            checked={step.completed}
            disabled={!canEdit}
            onChange={({ target }) => onChange({ ...step, completed: target.checked })}
        />
    </div>
);

export default WorkflowStepControls;
