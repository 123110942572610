import { isNil, mapValues } from 'lodash-es';
import qs from 'qs';

export const stringifyParams = (params: any): string => {
    const paramsOrder = Object.keys(params);
    return qs.stringify(params, {
        arrayFormat: 'comma',
        strictNullHandling: true,
        encode: false,
        sort: (a, b) => paramsOrder.indexOf(a) - paramsOrder.indexOf(b),
    });
};

export const parseQuery = (url: string, params = { parseNumbers: true }): any => {
    const parsedQuery = qs.parse(url, { strictNullHandling: true, comma: true, ignoreQueryPrefix: true });
    return params.parseNumbers
        ? mapValues(parsedQuery, (value) =>
              Array.isArray(value) ? value.map((v) => Number(v) || v) : Number(value) || value
          )
        : parsedQuery;
};

export const toQueryValue = (value: any, params?: { strictEmptyArrayHandling: boolean }) => {
    if (Array.isArray(value) && value.length === 0)
        return params && params.strictEmptyArrayHandling ? undefined : null;
    if (value === '' || isNil(value)) return undefined;
    return value;
};

export const dataUrlToBase64 = (dataUrl?: string) => {
    if (!dataUrl) {
        throw new Error('Invalid data url');
    }
    return dataUrl.split(';base64,')[1];
};

export const inTextLinkFormat = (text: string, preset?: RegExp) => {
    const regex = preset || /https?:\/\/\S+/g;

    return text.replace(regex, (match) => `<a href="${match}" target="_blank">${match}</a>`);
};
