import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { push } from 'redux-first-history';
import qs from 'qs';
import Search from 'platform/common/components/Search/Search';
import { Advertiser } from 'platform/segments/segments.types';

interface Props {
    canEdit: boolean;
    advertiser?: Advertiser;
    searchQuery: string;
    onSearchQueryChange: (query: string) => void;
}

const SegmentToolbar = ({ canEdit, advertiser, searchQuery, onSearchQueryChange }: Props) => {
    const dispatch = useDispatch();
    return (
        <>
            {canEdit && advertiser && (
                <div
                    title={
                        advertiser.activeAdvertiserTag
                            ? undefined
                            : 'No active AdvertiserTag found. Please create new or activate existing AdvertiserTag before creating segments'
                    }
                >
                    <Button
                        size="md"
                        color="primary"
                        className="mb-2 me-2"
                        onClick={() => {
                            dispatch(
                                push({
                                    pathname: '/segments/new',
                                    search: qs.stringify({ advertiserId: advertiser?.id }),
                                })
                            );
                        }}
                        disabled={!advertiser?.activeAdvertiserTag}
                    >
                        Add new
                    </Button>
                </div>
            )}
            <div className="mb-2 me-2">
                <Search onSearch={onSearchQueryChange} value={searchQuery} />
            </div>
        </>
    );
};

export default SegmentToolbar;
