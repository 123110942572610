import { toastError } from 'platform/common/utils/toast.util';
import { receiveAsyncChatMessages, sendAsyncChatMessages } from './chat.service';
import { DeeplyChatResponse, DeeplyChatAsyncResponse, ChatMessagePayload } from './chat.types';

export const chatMessagePollAsyncResult = async (
    payload: ChatMessagePayload,
    withErrorToast = false
): Promise<DeeplyChatResponse> => {
    let isDone = false;
    let asyncResponse: DeeplyChatAsyncResponse = { done: false, message: '' };

    try {
        const res = await sendAsyncChatMessages(payload, withErrorToast);

        while (!isDone) {
            // eslint-disable-next-line no-await-in-loop
            asyncResponse = await receiveAsyncChatMessages(res.id, withErrorToast);

            if (asyncResponse.done) {
                isDone = true;
            } else {
                // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
                await new Promise((resolve) => setTimeout(resolve, 1500));
            }
        }
    } catch (e) {
        isDone = true;

        if (withErrorToast) {
            toastError({ ...e, request: { ...e.request, body: { ...e.request.body, messages: '***' } } });
        }

        return Promise.reject(e);
    }

    return asyncResponse;
};
