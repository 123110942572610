import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ChatAssistantTable, { ReactNodeProps } from './ChatAssistantTable';

interface Props {
    content: string;
    components?: Components;
}

const ChatAssistantDefaultMessage = ({ content, components }: Props) => (
    <ReactMarkdown
        components={{
            table: ({ children }) => <ChatAssistantTable>{children as ReactNodeProps[]}</ChatAssistantTable>,
            ...components,
        }}
        remarkPlugins={[remarkGfm]}
    >
        {content}
    </ReactMarkdown>
);

export default ChatAssistantDefaultMessage;
