import { connect } from 'react-redux';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { get } from 'lodash-es';
import { compile } from 'path-to-regexp';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import { RootState } from '../../../rootState.type';
import { SEGMENT_ANALYSIS, SEGMENT_OVERVIEW, SEGMENT_SIMILARITY, SEGMENTS } from '../../segments.navigation';

type Props = {
    segmentId: number;
    segmentName: string;
    isDemoMode: boolean;
};

type StateProps = {
    location: string;
};

const defaultProps = {
    segmentName: '',
};

const overviewPath = compile(SEGMENT_OVERVIEW.path!);
const analysisPath = compile(SEGMENT_ANALYSIS.path!);
const similarityPath = compile(SEGMENT_SIMILARITY.path!);

const SegmentNavigation = ({ segmentId, segmentName, isDemoMode }: Props & StateProps) => {
    const overviewLink = overviewPath({ segmentId });
    const analysisLink = analysisPath({ segmentId });
    const similarityLink = similarityPath({ segmentId });

    return (
        <>
            <HeaderContainer>
                <PageHeader title={segmentName} parentTitle="Segments" parentLink={SEGMENTS.path} />
            </HeaderContainer>
            <BodyContainer>
                <div className="custom-tabs">
                    <Nav tabs>
                        <NavItem key={overviewLink}>
                            <NavLink to={overviewLink} activeClassName="active" tag={RouterNavLink}>
                                Overview
                            </NavLink>
                        </NavItem>
                        <NavItem key={analysisLink}>
                            <NavLink to={analysisLink} activeClassName="active" tag={RouterNavLink}>
                                Analysis
                            </NavLink>
                        </NavItem>
                        {!isDemoMode && (
                            <NavItem key={similarityLink}>
                                <NavLink to={similarityLink} activeClassName="active" tag={RouterNavLink}>
                                    Similarity
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </div>
            </BodyContainer>
        </>
    );
};

SegmentNavigation.defaultProps = defaultProps;

const mapStateToProps = (state: RootState): StateProps => {
    const location = get(state, 'router.location.pathname', '');
    return {
        location,
    };
};

export default connect(mapStateToProps)(SegmentNavigation);
