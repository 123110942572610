import { OptionType } from '../CheckboxTree/CheckboxTree.type';
import './SelectedValuesBox.scss';

export type Box<T> = { header: string; node?: OptionType; nodes: T[]; key?: string };

type Props = {
    boxes: Box<OptionType>[];
    labelKey: string;
    onChange?: (box: Box<OptionType>[]) => void;
    maxHeight: number;
};

const defaultProps = {
    maxHeight: 50,
    labelKey: 'label',
};

const SelectedValuesBox = ({ boxes, onChange, maxHeight, labelKey }: Props) => {
    if (!boxes || !boxes.length) {
        return null;
    }
    return (
        <div className="SelectedValueBox" style={{ maxHeight: `${maxHeight}rem` }}>
            {boxes.map((box) => (
                <div key={box.key || box.header}>
                    <div>
                        <span className="SelectedValueBox-header">{box.header}</span>
                        {!!onChange && (
                            <span
                                role="button"
                                tabIndex={0}
                                className="SelectedValueBox-groupClose"
                                onClick={() => onChange(boxes.filter((b) => b !== box))}
                            >
                                x
                            </span>
                        )}
                    </div>
                    {box.nodes.map((node) => (
                        <div
                            key={node.key || `${node[labelKey]}-${box.key}`}
                            className="SelectedValueBox-value"
                        >
                            {!!onChange && (
                                <span
                                    role="button"
                                    tabIndex={0}
                                    className="SelectedValueBox-valueClose"
                                    onClick={() => {
                                        const newGroups = boxes
                                            .map((group) => ({
                                                ...group,
                                                nodes: group.nodes.filter((n) => n !== node),
                                            }))
                                            .filter((group) => group.nodes.length);
                                        onChange(newGroups);
                                    }}
                                >
                                    ×
                                </span>
                            )}
                            <div className="SelectedValueBox-valueText">{node[labelKey]}</div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

SelectedValuesBox.defaultProps = defaultProps;

export default SelectedValuesBox;
