import { DATA_TYPES } from 'platform/common/dataTypes';
import { FunnelDataProps } from 'platform/dashboard/dashboard.types';

interface Props {
    data: FunnelDataProps[];
}

const FunnelActionProbability = ({ data }: Props) => (
    <div className="FunnelActionProbability d-flex flex-wrap border-top">
        {data.map(({ title, actionProbability }) => (
            <div key={title} className="p-3" style={{ width: `calc(100% / ${data.length})` }}>
                <div className="gray-400">Action Probability</div>
                <div className="font-lg">{DATA_TYPES.P100.format(actionProbability)}</div>
            </div>
        ))}
    </div>
);

export default FunnelActionProbability;
