import { downloadAttachment } from 'platform/observerMessageCenter/observerMessageCenter.service';
import { ObserverMessage } from 'platform/observerMessageCenter/observerMessageCenter.types';

interface Props {
    messageData: ObserverMessage;
}

const MessageCenterAttachment = ({ messageData: { id, fileName } }: Props) => {
    if (!fileName) return null;

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                downloadAttachment(id);
            }}
            role="button"
            tabIndex={0}
            className="d-flex border rounded-pill p-1 align-items-center"
        >
            <i className="fa-solid fa-paperclip text-primary me-1" />
            <div>{fileName}</div>
        </div>
    );
};

export default MessageCenterAttachment;
