import momentTz from 'moment-timezone';
import { useImageUpload } from 'platform/adminPanel/hooks/useImageUpload';
import { AdvertiserFormModel } from 'platform/advertisers/advertiser.types';
import { Optional } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormInputWithUrlPreview from 'platform/formik/FormInputWithUrlPreview/FormInputWithUrlPreview';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

const TIME_ZONE_OPTIONS = momentTz.tz.names().map((n) => ({ label: n, value: n }));

const AdvertiserWizardForm = ({
    onCancel,
    canEdit,
    formikProps: { setFieldValue, submitForm, values },
}: FormProps<Optional<AdvertiserFormModel, 'id'>>) => {
    const { uploadingImage, handleImageUpload } = useImageUpload();
    const { iconUrl } = values;

    return (
        <CardForm
            title="New Advertiser"
            submitLabel="Proceed"
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
            animationDelay={0}
        >
            <ControlledCard title="General info" subtitle="required" id="generalInfo">
                <FormRow label="Name">
                    <FormInput name="name" validate={required} />
                </FormRow>
                <FormRow label="Domain">
                    <FormInput name="domain" validate={required} />
                </FormRow>
                <FormRow label="Timezone">
                    <FormSelect
                        options={TIME_ZONE_OPTIONS}
                        name="regionalSettings.timeZoneId"
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Icon url">
                    <FormInputWithUrlPreview name="iconUrl" value={iconUrl} validate={required} />
                </FormRow>
                <FormRow>
                    <UploadInput
                        acceptableMimeTypes="image/jpeg, image/png, image/svg+xml"
                        onFileUpload={(file, uploadProgressProps) =>
                            handleImageUpload({
                                file,
                                uploadProgressProps,
                                onUpload: (fileName) => setFieldValue('iconUrl', fileName),
                            })
                        }
                        loading={uploadingImage}
                        subtitle="Upload jpeg/png/svg"
                    />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default AdvertiserWizardForm;
