export type Platform = 'mac' | 'windows';

export const detectPlatform = (): Platform | undefined => {
    const isMac = window.navigator.userAgent.includes('Macintosh');
    if (isMac) {
        return 'mac';
    }

    const isWindows = window.navigator.userAgent.includes('Windows');
    if (isWindows) {
        return 'windows';
    }

    return undefined;
};
