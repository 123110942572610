import { LabelOverride } from 'platform/campaign/advertiserManagement/LabelOverrides/labelOverrides.types';
import RemovableLineItem from 'platform/common/components/RemovableLineItem/RemovableLineItem';
import { CURRENCY_SYMBOL } from 'platform/common/constants/common.constant';
import { required } from 'platform/common/utils/validators.util';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

type Props = {
    field: string;
    ruleDimensions: number[];
    labelOverrides: LabelOverride[];
    onRemove: () => void;
};

const applyLabelOverride = (dimensionId: number, labelOverrides: LabelOverride[]) =>
    labelOverrides.find(
        (labelOverride) => labelOverride.systemName === `action_custom_dimension_${dimensionId}_count`
    )?.alias ?? `Custom dimension ${dimensionId}`;

export const CommissionRuleControls = ({ field, ruleDimensions, labelOverrides, onRemove }: Props) => (
    <RemovableLineItem
        id={field}
        className="border-0 px-3 py-2"
        onRemove={onRemove}
        childrenClassName="flex-fit"
        visibleOverflow
    >
        <div className="d-flex">
            <FormDateRangePicker
                name={`${field}.period`}
                className="me-2"
                validate={required}
                placeholder="Select dates"
            />
            <div className="me-2" style={{ flexBasis: 100 }}>
                <FormInput
                    name={`${field}.value`}
                    validate={required}
                    placeholder="Value"
                    rightAddOn={{ title: CURRENCY_SYMBOL }}
                />
            </div>
            <FormSelect
                name={`${field}.product`}
                className="me-3 flex-grow-1"
                placeholder="Select product"
                options={ruleDimensions.map((r) => ({
                    label: applyLabelOverride(r, labelOverrides),
                    value: r,
                }))}
                validate={required}
                isClearable={false}
            />
        </div>
    </RemovableLineItem>
);
