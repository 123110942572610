import classNames from 'classnames';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { ObserverMessageType } from 'platform/observer/observer.types';
import { MESSAGE_CENTER_TYPE_LABELS } from 'platform/observerMessageCenter/observerMessageCenter.constants';

const TYPE_ICONS: Record<ObserverMessageType, { icon: string; background: string; tooltip: string }> = {
    WARNING: {
        icon: 'fa-solid fa-triangle-exclamation',
        background: 'bg-warning',
        tooltip: MESSAGE_CENTER_TYPE_LABELS.WARNING,
    },
    INFO: {
        icon: 'fa-solid fa-info-circle',
        background: 'bg-info',
        tooltip: MESSAGE_CENTER_TYPE_LABELS.INFO,
    },
    SUGGESTION: {
        icon: 'fa-solid fa-hand-holding-heart',
        background: 'bg-success',
        tooltip: MESSAGE_CENTER_TYPE_LABELS.SUGGESTION,
    },
};

interface Props {
    type: ObserverMessageType;
}

const MessageCenterItemHighlight = ({ type }: Props) => (
    <Tooltip renderTooltip={() => TYPE_ICONS[type].tooltip}>
        <div className={classNames('ObserverMessageCenterList-rowHighlight', TYPE_ICONS[type].background)}>
            <i className={classNames(TYPE_ICONS[type].icon, 'fs-5')} />
        </div>
    </Tooltip>
);

export default MessageCenterItemHighlight;
