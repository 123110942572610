import { ChatAgentProvider } from './ChatAgentProvider';
import { ChatLanguageProvider } from './ChatLanguageProvider';
import { ChatMessageProvider } from './ChatMessageProvider';
import { ChatPromptProvider } from './ChatPromptProvider';

export const ChatProviders = ({ children }: { children: React.ReactNode }) => (
    <ChatAgentProvider>
        <ChatPromptProvider>
            <ChatMessageProvider>
                <ChatLanguageProvider>{children}</ChatLanguageProvider>
            </ChatMessageProvider>
        </ChatPromptProvider>
    </ChatAgentProvider>
);
