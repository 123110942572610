import * as d3 from 'd3';

export const useChartTooltip = () => {
    const showTooltip = ({ left, top, text }: { left: number; top: number; text: string }) => {
        d3.select('.ChartTooltip')
            .classed('ChartTooltip--hidden', false)
            .style('left', `${left}px`)
            .style('top', `${top}px`)
            .select('.ChartTooltip-text')
            .text(text);
    };

    const hideTooltip = () => {
        d3.select('.ChartTooltip').classed('ChartTooltip--hidden', true);
    };

    return {
        showTooltip,
        hideTooltip,
    };
};
