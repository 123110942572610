import { ReactNode } from 'react';
import { AnalyticsProps } from 'platform/analytics/modes/Analytics';
import useActiveAdvertisersComponent from 'platform/common/hooks/useActiveAdvertisers';

interface Props {
    children: (props: AnalyticsProps) => ReactNode;
}

const InteractiveAnalytics = ({ children }: Props) => {
    useActiveAdvertisersComponent();

    return (
        <>
            {children({
                datePickerVisible: false,
                monthPickerVisible: false,
                filtersVisible: false,
                interactionPresetsVisible: true,
            })}
        </>
    );
};

export default InteractiveAnalytics;
