import { useParams } from 'react-router';
import AdvertiserTagDownloadForm from 'platform/advertiserTag/components/AdvertiserTagDownloadForm/AdvertiserTagDownloadForm';
import { toApi, toFormModel } from 'platform/advertiserTag/mappers/advertiserTagDownload.mapper';
import {
    downloadIframeLoader,
    downloadJavascriptLoader,
    downloadJsonLoader,
    fetchAdvertiserTag,
    fetchSegments,
    fetchStandardEvents,
    updateAdvertiserTag,
    updateBatchedSegments,
    fetchAdvertiserTagLite,
} from 'platform/advertiserTag/services/advertiserTag.service';
import { assertIsDefined } from 'platform/common/common.assert';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';

const AdvertiserTagDownloadFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const handleOpen = async () => {
        const [advertiserTag, segments, standardEvents, advertiserTagLite] = await Promise.all([
            fetchAdvertiserTag(id),
            fetchSegments({ advertiserTagId: id }),
            fetchStandardEvents(),
            fetchAdvertiserTagLite(id),
        ]);

        return toFormModel({
            advertiserTag,
            advertiserTagLoaderType: 'javascript',
            advertiserTagId: id,
            segments,
            standardEvents,
            advertiserTagLite,
            includes: { includeLoaderHtml: true, includeLoaderJs: true },
        });
    };

    const handleSubmit = async (model: any) => {
        const { advertiserTag, segments, advertiserTagId, segmentIds } = toApi(model);
        if (model.advertiserTagLoaderType === 'javascript') {
            const advertiserId = advertiserTag.advertiserId;

            assertIsDefined(advertiserId, 'advertiserId');
            assertIsDefined(advertiserTagId, 'advertiserTagId');

            await Promise.all([
                updateAdvertiserTag(advertiserTag),
                updateBatchedSegments(advertiserId, segments),
            ]);

            return downloadJavascriptLoader({
                advertiserTagId,
                segmentIds,
            });
        }

        if (model.advertiserTagLoaderType === 'iframe') {
            const advertiserId = advertiserTag.advertiserId;

            assertIsDefined(advertiserId, 'advertiserId');
            assertIsDefined(advertiserTagId, 'advertiserTagId');

            // cookieSwitch is excluded, since its not being used in iframe loader.
            const { cookieSwitch, ...rest } = advertiserTag;

            await Promise.all([updateAdvertiserTag(rest), updateBatchedSegments(advertiserId, segments)]);

            return downloadIframeLoader({
                advertiserTagId,
                segmentIds,
            });
        }

        if (model.advertiserTagLoaderType === 'json') {
            return downloadJsonLoader(model.advertiserTagId, {
                urlSegmentList: model.advertiserTagLite.urlSegmentList,
                includeLoaderHtml: model.includes.includeLoaderHtml,
                includeLoaderJs: model.includes.includeLoaderJs,
            });
        }
        throw new Error(`Invalid type: ${model.advertiserTagLoaderType}`);
    };

    return (
        <FormContainer
            helpKey="advertiser_tag_download_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            isEdit={!!id}
            sidePanel
            showEditWarning={false}
            size={SIDE_PANEL_SIZES.NARROW}
        >
            {(props) => <AdvertiserTagDownloadForm {...props} />}
        </FormContainer>
    );
};

export default AdvertiserTagDownloadFormContainer;
