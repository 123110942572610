import { fetchCategoryTrees } from 'platform/category/category.service';
import { Category, CategoryRootCode } from 'platform/category/category.types';
import { usePromise } from 'platform/common/hooks/usePromise';
import { Validator } from 'platform/common/utils/validators.util';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';

type Props = {
    name: string;
    roots: CategoryRootCode[];
    validate?: Validator;
};

const FormCategorySelectTree = ({ name, roots, validate }: Props) => {
    const [{ data, loading }] = usePromise([], () => fetchCategoryTrees({ rootCode: roots }), []);

    return (
        <FormSelectTree<Category>
            name={name}
            options={data}
            isLoading={loading}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.code}
            getOptionParentLabel={(option) => option.name}
            childrenKey="children"
            isMulti
            validate={validate}
        />
    );
};

export default FormCategorySelectTree;
