import RoleList from '../components/Roles/RoleList';
import SeatList from '../components/SeatList/SeatList';
import UserList from '../components/UserList/UserList';

export const USER_MANAGEMENT_TAB_LIST = [
    {
        url: 'users',
        name: 'users',
        label: 'Users',
        component: UserList,
    },
    {
        url: 'roles',
        name: 'roles',
        label: 'Roles',
        component: RoleList,
    },
    {
        url: 'seats',
        name: 'seats',
        label: 'Seats',
        component: SeatList,
    },
];
