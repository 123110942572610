import bubble from 'platform/assets/icons/journey/bubble.svg';
import tripleChevrons from 'platform/assets/icons/journey/chevrons3.svg';
import chevrons from 'platform/assets/icons/journey/chevrons.svg';
import { getDuration } from 'platform/common/utils/date.util';
import { DurationInterval, getDurationInterval } from '../customerJourney.utils';

const INTERVAL_ICONS: Record<DurationInterval, string> = {
    SECONDS: tripleChevrons,
    MINUTES: chevrons,
    HOURS: bubble,
    DAYS: bubble,
};

interface Props {
    startTime: string;
    endTime: string;
}

const JourneyCardSeparator = ({ startTime, endTime }: Props) => {
    const intervalType = getDurationInterval(startTime, endTime);

    return (
        <div className="d-flex align-items-center">
            <img
                style={{ paddingLeft: 28, paddingRight: 8 }}
                alt={intervalType}
                src={INTERVAL_ICONS[intervalType]}
            />
            <span className="text-muted">{getDuration(startTime, endTime).humanize()}</span>
        </div>
    );
};

export default JourneyCardSeparator;
