import axiosBff from 'platform/axios/axiosBff';
import { OptInRate } from './optInRates.types';

export const fetchOptInRates = (advertiserId: number) =>
    axiosBff
        .get<OptInRate[]>('/advertiser/api/opt-in-rates', { params: { advertiserId } })
        .then((r) => r.data);

export const fetchOptInRate = (id: number) =>
    axiosBff.get<OptInRate>(`/advertiser/api/opt-in-rates/${id}`).then((r) => r.data);

export const createOptInRate = (optInRate: Omit<OptInRate, 'id'>) =>
    axiosBff.post<OptInRate>('/advertiser/api/opt-in-rates', optInRate).then((r) => r.data);

export const updateOptInRate = (optInRate: OptInRate) =>
    axiosBff.put<OptInRate>(`/advertiser/api/opt-in-rates/${optInRate.id}`, optInRate).then((r) => r.data);

export const deleteOptInRate = (id: number) => axiosBff.delete(`/advertiser/api/opt-in-rates/${id}`);
