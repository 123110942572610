import classNames from 'classnames';
import WordsFadeInAnimation from 'platform/app/components/IntroMessage/WordsFadeInAnimation/WordsFadeInAnimation';
import './IntroMessage.scss';

interface Props {
    className?: string;
}

const IntroMessage = ({ className }: Props) => (
    <div className={classNames('IntroMessage', className)}>
        <h2 className="IntroMessage-text">
            <WordsFadeInAnimation words={['Improving', 'your', 'Digital', 'Marketing']} />
        </h2>
        <span className="IntroMessage-subText">
            As an independent digital media service provider of the next generation, we combine media and
            machine optimization through artificial intelligence and deep learning.
        </span>
    </div>
);

export default IntroMessage;
