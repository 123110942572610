import { sortBy, uniq } from 'lodash-es';
import axiosBff from 'platform/axios/axiosBff';
import { isNotEmpty } from 'platform/common/common.types';
import { Product, ProductCategory } from './productLabel.types';

const withSortedSubLabels = (product: Product): Product => ({
    ...product,
    subLabels: sortBy(product.subLabels ?? [], [(sl) => sl.status, (sl) => sl.name]),
});

const normalizeLabel = (label: string) => label.trim().replaceAll('>', ' > ').replaceAll(/\s+/g, ' ');

export const fetchProducts = (advertiserId: number) =>
    axiosBff
        .get<Product[]>('/campaign/api/products', { params: { advertiserId } })
        .then((response) => sortBy(response.data, [(p) => p.status, (p) => p.name]).map(withSortedSubLabels));

export const fetchProduct = (advertiserId: number, name: string) =>
    axiosBff
        .get<Product>(`/campaign/api/products/${encodeURIComponent(name)}`, { params: { advertiserId } })
        .then((response) => withSortedSubLabels(response.data));

export const storeProduct = ({ advertiserId, name, status, category, subLabels }: Product) =>
    axiosBff
        .put<void>(
            `/campaign/api/products/${encodeURIComponent(normalizeLabel(name))}`,
            {
                status,
                category,
                subLabels: subLabels.map((s) => ({ ...s, name: normalizeLabel(s.name) })),
            },
            { params: { advertiserId } }
        )
        .then((response) => response.data);

export const renameProduct = ({
    advertiserId,
    oldName,
    newName,
    subLabelChanges,
}: {
    advertiserId: number;
    oldName: string;
    newName: string;
    subLabelChanges: { oldName: string; newName: string }[];
}) =>
    axiosBff
        .put<void>(
            `/campaign/api/products/${encodeURIComponent(oldName)}/rename`,
            {
                newName: normalizeLabel(newName),
                subLabelChanges: subLabelChanges
                    .map((c) => ({ oldName: c.oldName, newName: normalizeLabel(c.newName) }))
                    .filter((c) => c.oldName !== c.newName),
            },
            { params: { advertiserId } }
        )
        .then((response) => response.data);

export const getProductCategories = (products: Product[]): ProductCategory[] =>
    uniq(products.map((p) => p.category).filter(isNotEmpty))
        .sort()
        .map((name) => ({ name }));
