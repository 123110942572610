import { JobStatus } from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import FailedIcon from 'platform/assets/icons/FAILED.svg';
import SuccessIcon from 'platform/assets/icons/SUCCESS.svg';
import SyncIcon from 'platform/assets/icons/SYNC.svg';
import LabelIcon from 'platform/common/components/LabelIcon/LabelIcon';

const getIcon = (status: JobStatus) => {
    switch (status) {
        case 'COMPLETED':
            return SuccessIcon;
        case 'FAILED':
            return FailedIcon;
        default:
            return SyncIcon;
    }
};

const DataIngestionJobStatusIcon = ({ status }: { status: JobStatus }) => (
    <LabelIcon
        className={`DataIngestionJobs-status JobStatus-${status}`}
        icon={getIcon(status)}
        label={status}
        labelPosition="right"
    />
);

export default DataIngestionJobStatusIcon;
