type Props = {
    toggle: () => void;
    isExpandedInternal: boolean;
    toggleable: boolean;
};

export const ControlledCardChevron = ({ toggleable, toggle, isExpandedInternal }: Props) => (
    <>
        {toggleable && (
            <button type="button" onClick={toggle} className="btn-minimize">
                <i className={isExpandedInternal ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
            </button>
        )}
    </>
);
