import { isDefined } from 'platform/common/common.types';
import { getClassificationCodesBySegmentType } from 'platform/segments/mappers/segment.mapper';
import { Vendor } from 'platform/vendors/vendors.types';
import { Advertiser } from '../../advertisers/advertiser.types';
import {
    AdvertiserTag,
    AdvertiserTagFormEvent,
    AdvertiserTagFormModel,
    AdvertiserTagTrackersInfo,
    Event,
    Segment,
} from '../advertiserTag.types';
import {
    EventName,
    EventParam,
    UrlDetectionType,
    UrlSegmentTracking,
} from '../constants/advertiserTag.constant';

export const PAGE_VIEW_EVENTS = [EventName.PAGE_VIEW, EventName.PAGE_VIEW_NO_SCRIPT];

const calculateEditableFlags = (advertiserTagVarName: string) => {
    switch (advertiserTagVarName) {
        case EventName.PAGE_VIEW:
        case EventName.PAGE_VIEW_NO_SCRIPT: {
            return {
                convertible: false,
                productData: false,
                purchaseData: false,
            };
        }
        default: {
            return {
                convertible: true,
                productData: true,
                purchaseData: true,
            };
        }
    }
};

export const toFormModel = (data: {
    advertiser: Advertiser;
    advertiserTag: Partial<AdvertiserTag>;
    segments: Segment[];
    standardEvents: Event[];
    trackersInfo: AdvertiserTagTrackersInfo;
    vendors: Vendor[];
}): AdvertiserTagFormModel => {
    const { advertiser, advertiserTag, segments, standardEvents, trackersInfo, vendors } = data;
    const standardEventsSegments = segments.filter((item) => item.type === 'STANDARD_EVENT');
    const customEventsSegments = segments.filter((item) => item.type === 'CUSTOM_EVENT');
    const customEvents: AdvertiserTagFormEvent[] = customEventsSegments.map((segment) => ({
        id: segment.id,
        type: 'CUSTOM_EVENT',
        availability: segment.availability,
        advertiserTagVarName: segment.advertiserTagVarName,
        name: segment.name,
        selected: true,
        disabled: true,
        editable: {
            convertible: true,
            productData: true,
            purchaseData: true,
        },
        convertible: segment.convertible,
        productData: segment.eventParams.includes(EventParam.PRODUCT_DATA),
        purchaseData: segment.eventParams.includes(EventParam.PURCHASE_DATA),
        customVarsCount: segment.customVarsCount,
    }));

    const standardFormEvents: AdvertiserTagFormEvent[] = standardEvents.map((item) => {
        const eventSegment = standardEventsSegments.find(
            (segment) => segment.advertiserTagVarName === item.advertiserTagVarName
        );

        const pageViewEvent = [EventName.PAGE_VIEW, EventName.PAGE_VIEW_NO_SCRIPT].includes(
            item.advertiserTagVarName as typeof PAGE_VIEW_EVENTS[number]
        );

        return {
            id: eventSegment ? eventSegment.id : undefined,
            type: 'STANDARD_EVENT',
            availability: eventSegment ? eventSegment.availability : 'PRIVATE',
            advertiserTagVarName: item.advertiserTagVarName,
            name: item.name,
            selected: pageViewEvent || !!eventSegment,
            disabled: pageViewEvent || !!eventSegment,
            editable: calculateEditableFlags(item.advertiserTagVarName),
            convertible: eventSegment ? eventSegment.convertible : false,
            productData: eventSegment ? eventSegment.eventParams.includes(EventParam.PRODUCT_DATA) : false,
            purchaseData: eventSegment ? eventSegment.eventParams.includes(EventParam.PURCHASE_DATA) : false,
            customVarsCount: eventSegment?.customVarsCount,
        };
    });

    return {
        advertiserTag: {
            id: advertiserTag.id,
            advertiserId: advertiserTag.advertiserId || advertiser.id,
            domain: advertiserTag.domain || advertiser.domain,
            description: advertiserTag.description,
            debugPanelEnabled: Boolean(advertiserTag.debugPanelEnabled),
            internalLogDataEnabled:
                advertiserTag.internalLogDataEnabled !== undefined
                    ? advertiserTag.internalLogDataEnabled
                    : true,
            internalLogDataIdTypes:
                advertiserTag.internalLogDataIdTypes !== undefined
                    ? advertiserTag.internalLogDataIdTypes
                    : [],
            fireLoggingPixel: advertiserTag.fireLoggingPixel,
            oneActionPerOrderIdEnabled:
                advertiserTag.oneActionPerOrderIdEnabled !== undefined
                    ? advertiserTag.oneActionPerOrderIdEnabled
                    : true,
            oneActionPerOrderIdExpirationDays: advertiserTag.oneActionPerOrderIdExpirationDays || 30,
            urlDetection: advertiserTag.externalLocationUrlEnabled
                ? UrlDetectionType.EXTERNAL
                : UrlDetectionType.DATAG,
            urlSegmentTracking: advertiserTag.urlSegmentTracking || UrlSegmentTracking.ON_PAGE_LOAD,
            trackers: advertiserTag.trackers || {
                toggles: {
                    VISIT_TIME: true,
                    VISIT_FREQUENCY: true,
                    VISITED_PAGES: true,
                    ABC: true,
                    PRODUCT: true,
                },
                visitTimeIntervals: Object.keys(trackersInfo.VISIT_TIME),
            },
            clickIdSystems: advertiserTag.clickIdSystems ?? [],
            clickIdsToggle: !!advertiserTag.clickIdSystems?.length,
        },
        events: [...customEvents, ...standardFormEvents],
        advertiser,
        trackersInfo,
        clickIdVendors: vendors.filter((v) => v.clickIdEnabled),
    };
};

export const transformEventsToSegments = (events: AdvertiserTagFormEvent[]): Partial<Segment>[] =>
    events
        .filter((event) => event.selected)
        .map((event) => ({
            id: event.id,
            type: event.type,
            convertible: event.convertible,
            availability: event.availability,
            name: event.name,
            advertiserTagVarName: event.advertiserTagVarName,
            eventParams: [
                event.productData ? EventParam.PRODUCT_DATA : undefined,
                event.purchaseData ? EventParam.PURCHASE_DATA : undefined,
            ].filter(isDefined),
            customVarsCount: event.customVarsCount,
            classificationCodes: getClassificationCodesBySegmentType(event.type, event.advertiserTagVarName),
        }));

export const toApi = (
    data: AdvertiserTagFormModel
): {
    advertiserTag: Partial<AdvertiserTag>;
    segments: Partial<Segment>[];
} => ({
    advertiserTag: {
        id: data.advertiserTag.id,
        advertiserId: data.advertiserTag.advertiserId,
        description: data.advertiserTag.description,
        domain: data.advertiserTag.domain,
        debugPanelEnabled: data.advertiserTag.debugPanelEnabled,
        internalLogDataEnabled: data.advertiserTag.internalLogDataEnabled,
        internalLogDataIdTypes: data.advertiserTag.internalLogDataIdTypes,
        fireLoggingPixel: data.advertiserTag.fireLoggingPixel || undefined,
        oneActionPerOrderIdEnabled: data.advertiserTag.oneActionPerOrderIdEnabled,
        oneActionPerOrderIdExpirationDays: data.advertiserTag.oneActionPerOrderIdExpirationDays,
        externalLocationUrlEnabled: data.advertiserTag.urlDetection === UrlDetectionType.EXTERNAL,
        urlSegmentTracking: data.advertiserTag.urlSegmentTracking,
        trackers: data.advertiserTag.trackers,
        clickIdSystems: data.advertiserTag.clickIdSystems,
    },
    segments: transformEventsToSegments(data.events),
});
