import { Link, Route, Routes } from 'react-router-dom';
import { sortBy } from 'lodash-es';
import { getSystemSettings } from 'platform/adminPanel/components/SystemSettings/systemSetting.service';
import {
    SYSTEM_SETTING_TYPES,
    SystemSetting,
} from 'platform/adminPanel/components/SystemSettings/systemSetting.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { usePromise } from 'platform/common/hooks/usePromise';
import SystemSettingFormContainer from './SystemSettingFormContainer';

const PATH = '/admin-panel/system-settings';

const SystemSettingList = () => {
    const [{ data: settings, loading }, refetch] = usePromise([], getSystemSettings, []);

    const columns: TableColumn<SystemSetting>[] = [
        {
            Header: 'Name',
            width: 200,
            sortable: false,
            Cell: ({ original: { key } }) => (
                <Link to={`${PATH}/${key}`}>{SYSTEM_SETTING_TYPES[key]?.name ?? key}</Link>
            ),
        },
        {
            Header: 'Value',
            sortable: false,
            Cell: ({ original: { value } }) => <div className="text-truncate">{JSON.stringify(value)}</div>,
        },
        {
            Header: 'Edited',
            sortable: false,
            width: 200,
            Cell: ({ original: { updatedBy, updatedOn } }) => (
                <UpdatedOn date={updatedOn} updatedBy={updatedBy} />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="system_settings_list">
                <FormattedTable
                    columns={columns}
                    loading={loading}
                    data={sortBy(settings, (s) => s.key)}
                    showPagination={false}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path=":key"
                    element={<SystemSettingFormContainer redirectTo={PATH} afterSubmit={refetch} />}
                />
            </Routes>
        </>
    );
};

export default SystemSettingList;
