import { Col, Row } from 'reactstrap';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import ProfileChangePasswordForm from './ProfileChangePasswordForm';
import ProfileGeneralInfoForm from './ProfileGeneralInfoForm';

const Profile = () => (
    <>
        <HeaderContainer>
            <PageHeader title="Profile" />
        </HeaderContainer>
        <BodyContainer helpKey="profile">
            <Row>
                <Col xs={6}>
                    <ProfileGeneralInfoForm />
                    <ProfileChangePasswordForm />
                </Col>
            </Row>
        </BodyContainer>
    </>
);

export default Profile;
