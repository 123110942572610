import classNames from 'classnames';
import FormInput from 'platform/formik/FormInput/FormInput';
import MediaInsertionPopoverButton from 'platform/mediaplan/components/MediaplanTreeForm/MediaInsertionPopoverButton';

const MediaInsertionNotes = ({ field, notes }: { field: string; notes: string | undefined }) => (
    <MediaInsertionPopoverButton
        style={{ width: 400 }}
        iconClassName={classNames('fa-comment-alt ms-3', { 'text-success': !!notes })}
    >
        {() => (
            <>
                <div className="MediaNode-popoverTitle">Notes</div>
                <FormInput name={`${field}.notes`} type="textarea" rows={10} />
            </>
        )}
    </MediaInsertionPopoverButton>
);

export default MediaInsertionNotes;
