import { ReactNode } from 'react';
import { isDefined } from 'platform/common/common.types';
import Avatar from 'platform/common/components/Avatar/Avatar';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { User } from 'platform/userManagement/types/user.type';
import './UserIcons.scss';

interface Props {
    userIds: number[];
    options: User[];
    tooltip?: (user: User) => ReactNode;
}

const UserIcons = ({ userIds, options, tooltip = (user) => user.name }: Props) => (
    <div className="d-flex">
        {userIds
            .map((id) => options.find((u) => u.id === id))
            .filter(isDefined)
            .map((user) => (
                <div key={user.id} className="UserIcon">
                    <Tooltip renderTooltip={() => tooltip?.(user)}>
                        <Avatar size="tiny" name={user.name} imageUrl={user.imageUrl} />
                    </Tooltip>
                </div>
            ))}
    </div>
);

export default UserIcons;
