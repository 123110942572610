import { TRACKER_CREATIVE_FORMAT } from '../constants/creativeTypes';
import { Concept, Creative, CreativeModel } from '../types/creative.types';

export const isTrackerCreative = ({ type }: Creative | CreativeModel) => type === 'GCM_TRACKER';

export const getCreativeFormat = (creative: Creative | CreativeModel) => {
    if (isTrackerCreative(creative)) {
        return TRACKER_CREATIVE_FORMAT;
    }
    if ('width' in creative && 'height' in creative && creative.width && creative.height) {
        return `${creative.width}x${creative.height}`;
    }
    return '';
};

export const isCreativeRow = (row: Creative | Concept | CreativeModel): row is Creative =>
    'conceptId' in row && !!row.conceptId;
