import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CAMPAIGN_STATE_OPTIONS, CampaignState } from 'platform/campaign/campaign/constants/state.constant';
import { CampaignSearch, searchCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { UNIT_STATE_OPTIONS, UnitState } from 'platform/campaign/unit/constants/state.constant';
import { ACTIVE_OR_INACTIVE } from 'platform/common/constants/status.constant';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import useToggle from 'platform/common/hooks/useToggle';
import { fetchVendors } from 'platform/vendors/vendors.service';
import { Vendor } from 'platform/vendors/vendors.types';
import { GlobalSearchFilter } from './GlobalSearchFilters';

interface State {
    results: CampaignSearch[] | undefined;
    loading: boolean;
    query: string;
}

const initialState: State = {
    query: '',
    results: undefined,
    loading: false,
};

const initialFilters: GlobalSearchFilter[] = [
    {
        key: 'mediaplanStatuses',
        name: 'Mediaplan status',
        type: 'MEDIAPLAN',
        values: ['ACTIVE'],
        options: ACTIVE_OR_INACTIVE,
    },
    {
        key: 'campaignStatuses',
        name: 'Campaign status',
        type: 'CAMPAIGN',
        values: [CampaignState.NEW, CampaignState.RUNNING],
        options: CAMPAIGN_STATE_OPTIONS,
    },
    {
        key: 'unitStatuses',
        name: 'Unit status',
        type: 'UNIT',
        values: [UnitState.ACTIVE],
        options: UNIT_STATE_OPTIONS,
    },
];

const getVendorFilter = (vendors: Vendor[]): GlobalSearchFilter => ({
    key: 'vendorSystems',
    name: 'Vendor',
    type: 'VENDOR',
    values: [],
    options: vendors.map((vendor) => ({
        value: vendor.externalSystem,
        label: vendor.name,
    })),
});

export const useGlobalSearch = (input: HTMLInputElement | null, disabled: boolean) => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    const advertisers = useSelector(activeAdvertiserSelectors.activeAdvertisers);

    const [{ data: vendors }] = usePromise([], fetchVendors, []);

    const [{ results, loading, query }, setState] = useState<State>(initialState);
    const [filters, setFilters] = useState<GlobalSearchFilter[]>([
        ...initialFilters,
        getVendorFilter(vendors),
    ]);

    useEffect(() => {
        setFilters((prevFilters) =>
            prevFilters.map((filter) => (filter.key === 'vendorSystems' ? getVendorFilter(vendors) : filter))
        );
    }, [vendors]);

    const fetchResults = useCallback(async () => {
        if (!query.trim().length) {
            setState((prev) => ({ ...prev, results: initialState.results }));
            return;
        }

        setState((prev) => ({ ...prev, loading: true }));
        const response = await searchCampaigns({
            query,
            advertiserIds: advertisers.map((a) => a.id),
            types: filters.filter((f) => f.type !== 'VENDOR').map((filter) => filter.type),
            ...filters.reduce((acc, filter) => ({ ...acc, [filter.key]: filter.values }), {}),
        });
        setState((prev) => ({ ...prev, results: response, loading: false }));
    }, [query, filters]);

    useEffect(() => {
        fetchResults();
    }, [fetchResults]);

    useEffect(() => {
        if (isOpen) {
            input?.click(); // closes other open dropdowns
            input?.focus();
        } else {
            input?.blur();
            setState(initialState);
            setFilters([...initialFilters, getVendorFilter(vendors)]);
        }
    }, [isOpen, input]);

    const handleKeyPress = useCallback(
        (e: KeyboardEvent) => {
            if (disabled) {
                return;
            }

            if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
                toggleIsOpen();
                e.preventDefault();
            }
        },
        [disabled]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress, true);

        return () => {
            window.removeEventListener('keydown', handleKeyPress, true);
        };
    }, [handleKeyPress]);

    const changeQuery = (value: string) => {
        setState((prev) => ({ ...prev, query: value }));
    };

    const changeFilters = (values: GlobalSearchFilter[]) => setFilters(values);

    return {
        query,
        filters,
        results,
        vendors,
        loading,
        isOpen,
        changeQuery,
        changeFilters,
        toggle: toggleIsOpen,
    };
};
