import { sortBy } from 'lodash-es';
import { getAdvertiserSummaries } from 'platform/advertisers/services/advertiser.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

const AdvertiserMoveForm = ({
    onCancel,
    formikProps: { values, submitForm },
}: FormProps<{ id: number; name: string; parentId?: number }>) => {
    const [{ data: agents, loading }] = usePromise([], () => getAdvertiserSummaries({ type: 'AGENT' }), []);

    return (
        <CardForm
            title="Move advertiser"
            subtitle={values.name}
            submitLabel="Move"
            onSubmit={submitForm}
            onCancel={onCancel}
        >
            <ControlledCard title="Choose new parent" subtitle="Or leave blank to move to top level">
                <FormRow label="Parent agent">
                    <FormSelect
                        name="parentId"
                        options={sortBy(agents, (a) => a.name)}
                        getOptionValue={(a) => a.id}
                        getOptionLabel={(a) => a.name}
                        isLoading={loading}
                    />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default AdvertiserMoveForm;
