import { useState } from 'react';
import { Alert, Input } from 'reactstrap';
import qs from 'qs';
import {
    initAdvertiserTokenCreation,
    fetchAdvertiserToken,
    resetAdvertiserToken,
} from 'platform/advertisers/services/advertiser.service';
import { SET_GOOGLE_CREDENTIALS_PATH } from 'platform/app/app.navigation';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { ExternalSystem, getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';

const GoogleExternalCredentials = ({
    advertiserId,
    advertiserName,
    system,
}: {
    advertiserId?: number;
    advertiserName?: string;
    system: string;
}) => {
    const [authUrl, setAuthURL] = useState('');
    const [{ data: token }] = usePromise(
        null,
        async () => (advertiserId ? fetchAdvertiserToken(system, advertiserId) : null),
        [advertiserId]
    );
    const isAuthenticated = token?.status === 'COMPLETED';
    const integrationLabel = getIntegrationLabel(system as ExternalSystem);
    return !advertiserId ? (
        <Alert color="warning">
            Please save Advertiser first to enable {{ integrationLabel }} credential setup
        </Alert>
    ) : (
        <>
            {isAuthenticated && (
                <div className="mb-4">
                    <Alert color="success">
                        Advertiser is authenticated. To re-authenticate please click button below
                    </Alert>
                    <div className="d-flex flex-column">
                        <div>Current key:</div>
                        <Input type="text" value={token.refreshToken} disabled />
                    </div>
                </div>
            )}
            {authUrl && (
                <div>
                    <Alert color="primary">
                        Please open the key in a new browser window and follow instructions there. This key
                        will disappear after window close
                    </Alert>
                    <Input type="textarea" value={authUrl} disabled rows={8} />
                </div>
            )}
            <div className="mt-1">
                <AnimatedButton
                    onClick={async () => {
                        const oneTimeHash = (
                            await (token
                                ? resetAdvertiserToken(system, advertiserId)
                                : initAdvertiserTokenCreation(system, advertiserId))
                        ).oneTimeHash;
                        setAuthURL(
                            `${location.origin}${SET_GOOGLE_CREDENTIALS_PATH}?${qs.stringify({
                                system,
                                advertiserId,
                                advertiserName,
                                oneTimeHash,
                            })}`
                        );
                    }}
                >
                    {isAuthenticated ? 'Regenerate authentication link' : 'Generate authentication link'}
                </AnimatedButton>
            </div>
        </>
    );
};

export default GoogleExternalCredentials;
