import { CSSProperties } from 'react';
import { Badge } from 'reactstrap';
import classNames from 'classnames';

const STATUS_DISPLAY: { [key: string]: { label: string; color: string } } = Object.freeze({
    ACTIVE: {
        label: 'Active',
        color: 'success',
    },
    INACTIVE: {
        label: 'Inactive',
        color: 'light',
    },
    ARCHIVED: {
        label: 'Archived',
        color: 'dark',
    },
    PENDING: {
        label: 'Pending',
        color: 'warning',
    },
    PENDING_INACTIVE: {
        label: 'Pending Inactive',
        color: 'secondary',
    },
    INACTIVATED_BY_PARENT: {
        label: 'Inactivated by parent',
        color: 'light',
    },
    RUNNING: {
        label: 'Running',
        color: 'success',
    },
    PAUSED: {
        label: 'Paused',
        color: 'light',
    },
    REMOVED: {
        label: 'Removed',
        color: 'light',
    },
});

type Props = {
    status: string | undefined;
    className?: string;
    style?: CSSProperties;
};

const StatusBadge = ({ status, className, style }: Props) => {
    if (!status) {
        return null;
    }
    const { color, label } = STATUS_DISPLAY[status] ?? { color: 'warning', label: status };
    return (
        <Badge className={classNames('badge-outline', className)} color={color} style={style}>
            {label}
        </Badge>
    );
};

export default StatusBadge;
