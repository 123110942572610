import { ReactNode } from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import classnames from 'classnames';

type Option = { key?: string; label: string; action?: () => any; disabled?: boolean };

type Props = {
    items: Option[];
    children: ReactNode;
    className?: string;
    color?: string;
    dropdownMenuRight?: boolean;
    disabled?: boolean;
    onItemClick?: (option: Option) => void;
    caret?: boolean;
    isOpen: boolean;
    toggle: () => void;
};

const ControlledButtonDropdown = ({
    children,
    items,
    className = '',
    color = 'primary',
    dropdownMenuRight,
    disabled,
    onItemClick,
    caret = true,
    isOpen,
    toggle,
}: Props) => {
    const onDropdownItemClick = (option: Option) => {
        if (option.action) option.action();
        if (onItemClick) onItemClick(option);
        toggle();
    };
    return (
        <ButtonDropdown isOpen={isOpen} toggle={toggle} className={className} disabled={disabled}>
            <DropdownToggle caret={caret} color={color} disabled={disabled}>
                {children}
            </DropdownToggle>
            <DropdownMenu end={Boolean(dropdownMenuRight)}>
                {items.map((option) => (
                    <DropdownItem
                        className={classnames({ disabled: option.disabled })}
                        onClick={option.disabled ? undefined : () => onDropdownItemClick(option)}
                        key={option.key || option.label}
                    >
                        {option.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </ButtonDropdown>
    );
};

export default ControlledButtonDropdown;
