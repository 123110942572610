import { ReactNode, useMemo } from 'react';
import { CellWithEllipsis } from 'platform/common/components/FormattedTable/CellWithEllipsis';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { User } from 'platform/userManagement/types/user.type';
import { IdToName, LabelNames, TaskBoardChange, TaskLane } from '../task.types';
import BoardChangeContext from './BoardChangeContext';
import BoardChangeDetails from './BoardChangeDetails';

interface Props {
    className?: string;
    board: { lanes: TaskLane[]; labelNames?: LabelNames };
    userOptions: User[];
    changes: TaskBoardChange[];
    loading: boolean;
    topToolbar?: ReactNode;
    showContext?: boolean;
}

const BoardChangesTable = ({
    className,
    board: { lanes, labelNames },
    userOptions,
    changes,
    loading,
    topToolbar,
    showContext,
}: Props) => {
    const [laneNames, taskNames] = useMemo<[IdToName, IdToName]>(() => {
        if (!showContext) return [{}, {}];
        return [
            Object.fromEntries(lanes.map((l) => [l.id, l.name])),
            Object.fromEntries(
                lanes.flatMap(({ tasks }) =>
                    [...tasks, ...tasks.flatMap((t) => t.subtasks ?? [])].map((t) => [t.id, t.name])
                )
            ),
        ];
    }, [lanes, showContext]);

    const timeColumn: TableColumn<TaskBoardChange> = {
        Header: 'Time and user',
        sortable: false,
        width: 180,
        Cell: ({ original: change }) => <UpdatedOn date={change.updatedOn} updatedBy={change.updatedBy} />,
    };

    const contextColumn: TableColumn<TaskBoardChange> = {
        Header: 'Context',
        sortable: false,
        Cell: ({ original: change }) => (
            <CellWithEllipsis
                value={<BoardChangeContext change={change} laneNames={laneNames} taskNames={taskNames} />}
            />
        ),
    };

    const actionColumn: TableColumn<TaskBoardChange> = {
        Header: 'Action',
        sortable: false,
        Cell: ({ original: change }) => (
            <CellWithEllipsis
                value={
                    <BoardChangeDetails
                        change={change}
                        userOptions={userOptions}
                        labelNames={labelNames ?? {}}
                    />
                }
            />
        ),
    };

    return (
        <FormattedTable
            containerClass={className}
            topToolbar={topToolbar}
            data={changes}
            loading={loading}
            columns={showContext ? [timeColumn, contextColumn, actionColumn] : [timeColumn, actionColumn]}
            defaultPageSize={50}
        />
    );
};

export default BoardChangesTable;
