import classNames from 'classnames';
import './SectionSeparator.scss';

interface Props {
    iconClassName: string;
}

const SectionSeparator = ({ iconClassName }: Props) => (
    <div className="SectionSeparator">
        <i className={classNames('text-light-slate-gray mb-2', iconClassName)} />
        <div className="SectionSeparator-separator" />
    </div>
);

export default SectionSeparator;
