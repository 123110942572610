import { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { DataIngestionJobParams } from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import Switch from 'platform/formik/Switch/Switch';
import DataIngestionJobParamsForm from './DataIngestionJobParamsForm';

type Props = {
    jobName: string;
    vendor: string;
    toggle: () => any;
    onSubmit: (params?: DataIngestionJobParams) => Promise<void>;
};

const DataIngestionJobModal = ({ jobName, vendor, toggle, onSubmit }: Props) => {
    const today = moment().format(ISO_DATE_FORMAT);
    const monthAgo = moment().subtract(1, 'month').format(ISO_DATE_FORMAT);
    const [custom, setCustom] = useState(false);
    const [params, setParams] = useState<DataIngestionJobParams>({
        periodFrom: monthAgo,
        periodTo: today,
        periodChunkSize: 14,
        accounts: [],
        forceFetchFiles: false,
    });

    return (
        <Modal isOpen toggle={toggle} style={{ width: 450 }}>
            <ModalHeader onClose={toggle}>
                Run <b>{jobName}</b> job
            </ModalHeader>
            <ModalBody>
                <FormRow label="With custom params">
                    <Switch value={custom} onChange={({ target }) => setCustom(target.checked)} />
                </FormRow>
                {custom && (
                    <DataIngestionJobParamsForm
                        vendor={vendor}
                        jobName={jobName}
                        value={params}
                        onChange={setParams}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <AnimatedButton disabled={false} onClick={() => onSubmit(custom ? params : undefined)}>
                    Run
                </AnimatedButton>
            </ModalFooter>
        </Modal>
    );
};

export default DataIngestionJobModal;
