import { Input, Label } from 'reactstrap';
import {
    COLUMN_TYPE_OPTIONS,
    ColumnDefinitionOption,
    LineState,
    PeriodDimension,
} from 'platform/analytics/analytics.types';
import ReportSelectTree from 'platform/analytics/reportComponents/ReportSelectTree';
import { SelectItem } from 'platform/common/common.types';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import ReportChartEntriesCount from '../ReportChartEntriesCount';

const MAX_METRICS_COUNT = 2;
const getMetricOptions = (metrics: string[], metricOptions: ColumnDefinitionOption[]) =>
    metrics.length === MAX_METRICS_COUNT
        ? metricOptions
              .filter((m) => m.nodes.find((n) => metrics.includes(n.key)))
              .map((o) => ({
                  ...o,
                  nodes: o.nodes.filter((n) => metrics.includes(n.key)),
              }))
        : metricOptions;

const pivotOptions: SelectItem<PeriodDimension>[] = [
    { value: 'date', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
];

interface Props {
    componentState: LineState;
    metrics: ColumnDefinitionOption[];
    dimensions: ColumnDefinitionOption[];
    isLoading: boolean;
    onChange: (state: LineState) => void;
}

const ReportLineChartSettings = ({ componentState, metrics, dimensions, isLoading, onChange }: Props) => (
    <>
        <SelectWithAddon
            name="Pivot by"
            className="mb-3"
            selectClassname="flex-fill"
            options={pivotOptions}
            value={componentState.periodDimension}
            isClearable={false}
            onChange={(periodDimension) => onChange({ ...componentState, periodDimension })}
        />
        <ReportSelectTree
            name="Metric"
            className="mb-3"
            options={getMetricOptions(componentState.metrics, metrics)}
            value={componentState.metrics}
            isLoading={isLoading}
            isMulti
            onChange={(value) =>
                onChange({
                    ...componentState,
                    metrics: value,
                })
            }
        />
        <ReportSelectTree
            className="mb-3"
            name="Dimension"
            options={dimensions}
            value={componentState.dimensions[0]}
            isLoading={isLoading}
            isMulti={false}
            disabled={componentState.metrics.length === MAX_METRICS_COUNT}
            onChange={(value) => onChange({ ...componentState, dimensions: value ? [value] : [] })}
        />
        {componentState.dimensions[0] && (
            <SelectWithAddon
                selectClassname="flex-fill"
                className="mb-3"
                value={componentState.sortBy}
                name="Sort By"
                options={COLUMN_TYPE_OPTIONS}
                isClearable={false}
                onChange={(sortBy) => onChange({ ...componentState, sortBy })}
            />
        )}
        <ReportChartEntriesCount
            entriesCount={componentState.entriesCount}
            onChange={(entriesCount) => onChange({ ...componentState, entriesCount })}
        />
        <Label check className="mt-2">
            <Input
                type="checkbox"
                checked={componentState.showValues}
                style={{ marginTop: '0.2rem' }}
                onChange={(e) =>
                    onChange({
                        ...componentState,
                        showValues: e.target.checked,
                    })
                }
            />
            Show values
        </Label>
    </>
);

export default ReportLineChartSettings;
