// List of metrics for which deviation can not be calculated
export const NON_SUMMABLE_METRICS = [
    'buyside_cost',
    'buyside_effective_cpa',
    'buyside_effective_cpa_pc',
    'buyside_effective_cpa_pv',
    'buyside_effective_cpc',
    'buyside_effective_cpm',
    'buyside_effective_cpma_funnel1',
    'buyside_effective_cpma_funnel2',
    'buyside_effective_cpma_funnel3',
    'buyside_non_brand_effective_cpa_pc',
    'buyside_vendor_effective_cpc',
    'buyside_vendor_effective_cpm',
    'criteo_cost',
    'criteo_effective_cpaa_pc',
    'criteo_effective_cpc',
    'effective_cpa_pc_confirmed',
    'effective_cpa_pv_confirmed',
    'effective_cpca',
    'facebook_cost',
    'facebook_effective_cpc',
    'facebook_effective_cpm',
    'google_cost',
    'google_effective_cpa_pc',
    'google_effective_cpa_pv',
    'google_effective_cpc',
    'google_effective_cpm',
    'google_effective_cpma_funnel1',
    'google_effective_cpma_funnel2',
    'google_effective_cpma_funnel3',
];
