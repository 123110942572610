import TaskLabels from '../Common/TaskLabels';
import { LabelNames } from '../task.types';
import { TimelineTask } from './Timeline';

interface Props {
    task: TimelineTask;
    labelNames: LabelNames;
}

const TimelineBarTooltip = ({ task, labelNames }: Props) => (
    <div>
        <strong className="text-light-slate-gray mb-2 d-block">{task.laneName}</strong>
        <strong className="mb-2 d-block">{task.name}</strong>
        <div className="mb-2">
            {task.startDate && (
                <span className="me-2">
                    <i className="fa fa-circle Kanban-green font-xs me-1" />
                    {task.startDate}
                </span>
            )}
            {task.dueDate && (
                <span>
                    <i className="fa fa-circle Kanban-red font-xs me-1" />
                    {task.dueDate}
                </span>
            )}
        </div>
        {!!task.labelKeys?.length && (
            <div className="Timeline-taskLabels">
                <TaskLabels keys={task.labelKeys} names={labelNames} />
            </div>
        )}
    </div>
);

export default TimelineBarTooltip;
