import { useSelector } from 'react-redux';
import CodeMirrorSrc from 'platform/common/components/CodeMirror/CodeMirrorSource';
import InformationModal from 'platform/common/components/InformationModal/InformationModal';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { DataSharingTemplate } from 'platform/segments/segments.types';
import './DataSharingRulesModal.scss';

type Props = {
    toggle: () => void;
    title: string;
    rules: {
        codeSnippets: {
            dataSharingTemplateId?: number;
            dataSharingTemplate?: string;
            codeIntegralPart?: string;
        }[];
        ruleName: string;
    }[];
    dataSharingTemplates: DataSharingTemplate[];
};

const DataSharingRulesModal = ({ toggle, title, rules, dataSharingTemplates }: Props) => {
    const vendors = useSelector(classifierSelectors.vendors).filter((v) => v.useInCDP);

    const templateName = (template: DataSharingTemplate) =>
        vendors.find((v) => v.id === template.vendorId)?.name ?? `Vendor ${template.vendorId}`;

    return (
        <InformationModal isOpen toggle={toggle} title={title} okLabel="Close" style={{ maxWidth: '50vw' }}>
            <div className="DataSharingRulesModal overflow-y-auto" style={{ maxHeight: '70vh' }}>
                {rules.map((rule) => (
                    <div key={rule.ruleName} className="p-2">
                        <span className="fw-bold">Rule: {rule.ruleName}</span>
                        <div>
                            {rule.codeSnippets.map((codeSnippet, codeSnippetIndex) => {
                                const dataSharingTemplate = dataSharingTemplates.find(
                                    (t) => t.id === codeSnippet.dataSharingTemplateId
                                );
                                return (
                                    <div key={codeSnippetIndex} className="my-2">
                                        {dataSharingTemplate?.vendorId && (
                                            <span>Template: {templateName(dataSharingTemplate)}</span>
                                        )}
                                        <CodeMirrorSrc
                                            className="form-control my-2"
                                            value={
                                                codeSnippet.dataSharingTemplate ||
                                                codeSnippet.codeIntegralPart
                                            }
                                            options={{
                                                mode: 'htmlmixed',
                                                lineWrapping: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </InformationModal>
    );
};

export default DataSharingRulesModal;
