import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isDefined } from 'platform/common/common.types';
import './Slider.scss';

const calculateTickMarks = (min: number, max: number, step: number = 0): number[] => {
    if (!step) {
        return [];
    }
    const difference = max - min - step;
    const tickMarkAmount = difference / step;
    const valuePerTick = difference / tickMarkAmount;
    const tickMarks = Array(Math.ceil(tickMarkAmount))
        .fill(0)
        .map((_, i) => min + (i + 1) * valuePerTick);

    return tickMarks;
};

const calculateTooltipPosition = (value: number, min: number, max: number): string => {
    const newValue = Number(((value - min) * 100) / (max - min));
    const newPosition = 10 - newValue * 0.2;

    return `calc(${newValue}% + (${newPosition}px))`;
};

interface Props {
    minValue: number;
    maxValue: number;
    value?: number;
    step?: number;
    onChange: (value?: number) => void;
}

const Slider = ({ minValue, maxValue, value, step, onChange }: Props) => {
    const [innerValue, setInnerValue] = useState(value);
    const ticks = calculateTickMarks(minValue, maxValue, step).filter((tick) => tick !== innerValue);

    useEffect(() => {
        if (isDefined(value) && value !== innerValue) {
            setInnerValue(value);
        }
    }, [value]);

    return (
        <div className="Slider">
            <div className="me-1">{minValue}</div>
            <div className="Slider-inner">
                {isDefined(innerValue) && (
                    <div
                        className="Slider-tooltip"
                        style={{
                            left: calculateTooltipPosition(innerValue, minValue, maxValue),
                        }}
                    >
                        <span>{innerValue}</span>
                    </div>
                )}
                <input
                    type="range"
                    onChange={(e) => setInnerValue(e.target.valueAsNumber)}
                    onMouseUp={() => onChange(innerValue)}
                    min={minValue}
                    max={maxValue}
                    value={innerValue}
                />
                <div className="w-100">
                    {ticks.map((tick, index) => (
                        <div
                            key={index}
                            className={classNames(
                                'Slider-tick',
                                tick < (innerValue ?? 0) ? 'Slider-tick-light' : 'Slider-tick-dark'
                            )}
                            style={{
                                left: calculateTooltipPosition(tick, minValue, maxValue),
                            }}
                        />
                    ))}
                </div>
            </div>
            <div className="ms-1">{maxValue}</div>
        </div>
    );
};

export default Slider;
