import { ReactNode } from 'react';
import { AnalyticsSettings, Template } from 'platform/analytics/analytics.types';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { AnalyticsProps } from 'platform/analytics/modes/Analytics';
import useActiveAdvertisersComponent from 'platform/common/hooks/useActiveAdvertisers';

interface Props {
    children: (props: AnalyticsProps) => ReactNode;
    settings: AnalyticsSettings;
}

export const hasFilters = (includedKeys: string[]) => (templateId: string, templates: Template[]) => {
    const template = templates.find((t) => t.id === templateId);
    if (template?.type !== 'INSIGHT') {
        return true;
    }

    const filterKeys = template.filters.map((f) => f.target);
    return includedKeys.every((key) => filterKeys.includes(key));
};

const InsightAnalytics = ({ children, settings }: Props) => {
    const { templates } = useAnalyticsMetadata();
    useActiveAdvertisersComponent();
    const isPickerVisible = (predicate: (templateId: string, templates: Template[]) => boolean) =>
        settings.components.some((component) => {
            switch (component.type) {
                case 'TABLE':
                    return predicate(component.presets[component.activePresetIndex].templateId, templates);
                case 'CHART':
                case 'SUMMARY_BAR':
                    return predicate(component.templateId, templates);
                default:
                    return false;
            }
        });

    const datePickerVisible = isPickerVisible(hasFilters(['date_from', 'date_to']));
    const monthPickerVisible = isPickerVisible(hasFilters(['month']));

    return (
        <>
            {children({
                componentsProps: {
                    SUMMARY_BAR: {
                        isChartEnabled: false,
                    },
                },
                datePickerVisible,
                monthPickerVisible,
            })}
        </>
    );
};

export default InsightAnalytics;
