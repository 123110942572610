import { CreativeIntegration, DeliveryStatus } from '../../types/creative.types';

export type CommonCreativeFields = {
    id?: number;
    conceptId?: number;
    name: string;
    advertiserId: number;
    integration?: CreativeIntegration;
    deliveryStatus?: DeliveryStatus;
    updatedOn?: string;
    updatedBy?: string;
    creativeLabelId?: number;
};

export type CommonCreativeModelFields = {
    id?: number;
    tempId?: string;
    isNew?: boolean;
    conceptId?: number;
    name: string;
    advertiserId: number;
    integration?: CreativeIntegration;
    deliveryStatus?: DeliveryStatus;
    creativeLabelId?: number;
};

type ImportedCreativeType = 'FACEBOOK' | 'GOOGLE' | 'BING';

export type ImportedCreative = CommonCreativeFields & {
    type: ImportedCreativeType;
    externalType?: string;
    externalLabels?: string[];
};

export type ImportedCreativeModel = CommonCreativeModelFields & {
    type: ImportedCreativeType;
    externalType?: string;
    externalLabels?: string[];
};

export const toApiCommonFields = (creative: CommonCreativeModelFields): CommonCreativeFields => ({
    id: creative.id,
    conceptId: creative.conceptId,
    advertiserId: creative.advertiserId,
    integration: creative.integration,
    name: creative.name,
    creativeLabelId: creative.creativeLabelId,
});

export const toFormModelCommonFields = (creative: CommonCreativeFields): CommonCreativeModelFields => ({
    id: creative.id,
    conceptId: creative.conceptId,
    advertiserId: creative.advertiserId,
    integration: creative.integration,
    deliveryStatus: creative.deliveryStatus,
    name: creative.name,
    creativeLabelId: creative.creativeLabelId,
});

export const toFormModelImportedCreative = (creative: ImportedCreative): ImportedCreativeModel => ({
    ...toFormModelCommonFields(creative),
    type: creative.type,
    externalType: creative.externalType,
    externalLabels: creative.externalLabels,
});

export const toApiImportedCreative = (creative: ImportedCreativeModel): ImportedCreative => ({
    ...toApiCommonFields(creative),
    type: creative.type,
});
