import { useSelector } from 'react-redux';
import { Template } from 'platform/analytics/analytics.types';
import { getTemplate, isOlapReport } from 'platform/analytics/analytics.util';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useLoading } from 'platform/common/hooks/useLoading';
import { INSIGHTS } from 'platform/insight/insight.navigation';
import { fetchInsightQuerySummary } from 'platform/insight/insight.service';

type Props = {
    templateId: string;
    canEdit: boolean;
    onChange: (template: Template) => void;
};

const TemplateSelect = ({ templateId, canEdit, onChange }: Props) => {
    const { templates, reloadAnalyticsMetadata } = useAnalyticsMetadata();
    const template = useAnalyticsTemplate(templateId);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const [loading, withLoading] = useLoading();

    const handleEditClick = async () => {
        if (template.type === 'INSIGHT') {
            const querySummary = await fetchInsightQuerySummary(Number(template.id));
            window.open(`${INSIGHTS.path}/${querySummary.reportId}`, '_blank');
        }
    };

    const handleReloadClick = () => withLoading(() => reloadAnalyticsMetadata(advertiserId));

    return (
        <div className="d-flex align-items-center">
            {loading && <OverlayLoader />}
            <SelectWithAddon
                className="mb-3"
                name="Report template"
                selectClassname="flex-fill"
                value={template.id}
                options={templates.map((t) => ({
                    value: t.id,
                    label: t.name,
                    disabled: t.hideTemplateFromSelector,
                }))}
                isClearable={false}
                isDisabled={!canEdit}
                onChange={(id) => onChange(getTemplate(templates, id))}
            />
            {canEdit && !isOlapReport(template) && (
                <div role="button" tabIndex={-1} className="ms-2 mb-3" onClick={handleEditClick}>
                    <Tooltip renderTooltip={() => 'Edit selected template in new tab'}>
                        <i className="fa fa-edit text-info" />
                    </Tooltip>
                </div>
            )}
            <div role="button" tabIndex={-1} className="ms-2 mb-3" onClick={handleReloadClick}>
                <Tooltip renderTooltip={() => 'Reload changes made to templates, dimensions or metrics'}>
                    <i className="fal fa-refresh" />
                </Tooltip>
            </div>
        </div>
    );
};

export default TemplateSelect;
