import { useState } from 'react';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { orderBy } from 'lodash-es';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useAdvertiserUsers } from 'platform/common/hooks/useAdvertiserUsers';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { makeOptions } from 'platform/common/utils/option.util';
import { OBSERVER_REPORT_MESSAGE_PRIORITIES } from 'platform/observer/observer.constants';
import {
    ObserverMessageCategory,
    ObserverMessagePriority,
    ObserverMessageType,
} from 'platform/observer/observer.types';
import MessageHistory from './MessageHistory';
import ObserverMessageCenterList from './ObserverMessageCenterList/ObserverMessageCenterList';
import {
    MESSAGE_CENTER_CATEGORY_LABELS,
    MESSAGE_CENTER_STATUS_LABELS,
    MESSAGE_CENTER_TYPE_LABELS,
} from './observerMessageCenter.constants';
import { getObserverMessages } from './observerMessageCenter.service';
import { ObserverMessage, ObserverMessageStatus } from './observerMessageCenter.types';

const sortByNew = (msgs: ObserverMessage[]) => orderBy(msgs, [(m) => new Date(m.createdOn)], 'desc');

type QueryParams = {
    status: ObserverMessageStatus[];
    category: ObserverMessageCategory[];
    type: ObserverMessageType[];
    priority: ObserverMessagePriority[];
};

const ObserverMessageCenter = () => {
    const {
        queryParams: { status, category, type, priority },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        status: ['NEW', 'IN_PROGRESS'],
        category: [],
        type: [],
        priority: [],
    });

    const { id: advertiserId } = useActiveAdvertiserComponent();
    const [historyId, setHistoryId] = useState<number | undefined>();

    const [{ data: messages, loading }, refetch] = usePromise(
        [],
        () =>
            getObserverMessages({
                advertiserId,
                status,
                category,
                type,
                priority,
            }).then(sortByNew),
        [status, category, advertiserId, type, priority]
    );
    const assigneeOptions = useAdvertiserUsers(advertiserId);

    return (
        <>
            <HeaderContainer>
                <PageHeader title="DeeplyAI Cockpit" />
            </HeaderContainer>
            <BodyContainer helpKey="observer_message_center">
                <ListFilters className="mb-3">
                    <SelectWithAddon
                        isMulti
                        name="Status"
                        value={status}
                        options={makeOptions(MESSAGE_CENTER_STATUS_LABELS)}
                        onChange={(value) => setQueryParams({ status: value })}
                    />
                    <SelectWithAddon
                        isMulti
                        name="Type"
                        value={type}
                        options={makeOptions(MESSAGE_CENTER_TYPE_LABELS)}
                        onChange={(value) => setQueryParams({ type: value })}
                    />
                    <SelectWithAddon
                        isMulti
                        name="Category"
                        value={category}
                        options={makeOptions(MESSAGE_CENTER_CATEGORY_LABELS)}
                        onChange={(value) => setQueryParams({ category: value })}
                    />
                    <SelectWithAddon
                        isMulti
                        name="Priority"
                        value={priority}
                        options={makeOptions(OBSERVER_REPORT_MESSAGE_PRIORITIES)}
                        onChange={(value) => setQueryParams({ priority: value })}
                    />
                </ListFilters>
                <div className="position-relative">
                    {loading && <OverlayLoader />}
                    <ObserverMessageCenterList
                        rows={messages}
                        assigneeOptions={assigneeOptions}
                        onHistoryClick={setHistoryId}
                        onChange={refetch}
                    />
                </div>
            </BodyContainer>
            <Offcanvas
                backdrop={false}
                direction="end"
                isOpen={!!historyId}
                toggle={() => setHistoryId(undefined)}
            >
                <OffcanvasHeader className="fw-bold" toggle={() => setHistoryId(undefined)}>
                    History of changes
                </OffcanvasHeader>
                <OffcanvasBody>
                    <MessageHistory assigneeOptions={assigneeOptions} id={historyId!} />
                </OffcanvasBody>
            </Offcanvas>
        </>
    );
};

export default ObserverMessageCenter;
