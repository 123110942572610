import { ADVERTISER_SETTINGS } from './advertiserSettings.navigation';
import AdvertiserSettings from './containers/AdvertiserSettings';

const advertiserSettingsRoutes = [
    {
        ...ADVERTISER_SETTINGS,
        component: AdvertiserSettings,
    },
];

export default advertiserSettingsRoutes;
