import { useDispatch } from 'react-redux';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import IconButton from 'platform/common/components/IconButton/IconButton';

interface Props {
    id: number;
    toggleHighlight: () => void;
}

const ReportComponentRemove = ({ id, toggleHighlight }: Props) => {
    const dispatch = useDispatch();
    const showConfirmationModal = useConfirmationModal({ backdrop: false });

    return (
        <IconButton
            title="Remove"
            className="p-1 pe-2"
            onClick={() => {
                toggleHighlight();
                showConfirmationModal(
                    () => {
                        dispatch(analyticsSettingsActions.removeComponent(id));
                        toggleHighlight();
                    },
                    {
                        title: 'Remove component',
                        text: 'Do you want to continue?',
                        onCancel: toggleHighlight,
                    }
                );
            }}
        >
            <i className="fa fa-times" />
        </IconButton>
    );
};

export default ReportComponentRemove;
