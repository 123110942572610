import classNames from 'classnames';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';

interface Props {
    value: any;
    className?: string;
}

export const CellWithEllipsis = ({ value, className }: Props) => (
    <Tooltip
        className={classNames('text-truncate', className)}
        renderTooltip={() => <div className="mx-2 p-2">{value}</div>}
    >
        {value}
    </Tooltip>
);
