import { NodeRendererProps } from 'react-arborist';
import { useDispatch } from 'react-redux';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import { push } from 'redux-first-history';
import classNames from 'classnames';
import { flattenDeep } from 'lodash-es';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import ExpandCollapseButton from 'platform/common/components/ExpandCollapseButton/ExpandCollapseButton';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { useModal } from 'platform/common/components/Modal/Modal';
import PopoverDropdown, {
    PopoverDropdownItem,
} from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import WikiPageCreateModal from '../WikiPageCreateModal/WikiPageCreateModal';
import useWikiPermissions from '../useWikiPermissions';
import { WIKI_PAGE } from '../wiki.navigation';
import { deletePage } from '../wiki.service';
import { TreePage } from './WikiNavigation';
import './WikiNavItem.scss';

const flattenChildren = (children: TreePage[]): TreePage[] =>
    flattenDeep(children.map((child) => [child, ...(child.children || [])]));

type Props = NodeRendererProps<TreePage> & {
    refetch: () => void;
};

const WikiNavItem = ({ dragHandle, node, style, refetch }: Props) => {
    const dispatch = useDispatch();
    const page = node.data;
    const { canEdit } = useWikiPermissions();
    const { showModal } = useModal();
    const showConfirmationModal = useConfirmationModal();
    const location = useLocation();
    const isCurrentPageActive = location.pathname.includes(`${page.id}`);
    const isChildPageActive = flattenChildren(node.data.children || []).some((child) =>
        location.pathname.includes(`${child.id}`)
    );
    const isRoot = node.level === 0;

    const actions: PopoverDropdownItem[] = [
        {
            label: 'Edit',
            action: () => dispatch(push(`${generatePath(WIKI_PAGE.path!, { id: page.id })}?editMode=true`)),
        },
        ...(!page.children?.length
            ? [
                  {
                      label: 'Delete',
                      action: () => {
                          showConfirmationModal(() => deletePage(page.id).then(refetch), {
                              title: 'Delete Page',
                              text: 'Are you sure you want to delete this page?',
                          });
                      },
                  },
              ]
            : []),
    ];

    return (
        <div ref={dragHandle} style={style}>
            <NavItem
                className={classNames('WikiNavItem', {
                    active: !isRoot && isCurrentPageActive,
                    rootActive: isRoot && (isCurrentPageActive || isChildPageActive),
                    ...node.state,
                })}
            >
                <Link
                    title={page.name}
                    key={page.id}
                    className={classNames('py-2 ps-3 nav-link text-truncate d-block')}
                    to={generatePath(WIKI_PAGE.path as string, { id: page.id })}
                >
                    {page.name}
                </Link>
                <div className={classNames('d-flex align-items-center gap-2')}>
                    <IconButton
                        className="action-button"
                        onClick={() =>
                            showModal((toggleModal) => (
                                <WikiPageCreateModal
                                    parentId={Number(page.id)}
                                    toggle={toggleModal}
                                    refetch={refetch}
                                />
                            ))
                        }
                    >
                        <i className="WikiNavItem-addIcon fa-regular fa-circle-plus" />
                    </IconButton>
                    {canEdit && (
                        <PopoverDropdown
                            toggler={<i className="fa fa-ellipsis-h" />}
                            togglerClassName="action-button p-0"
                            items={actions}
                        />
                    )}
                </div>

                {!!node.children?.length && (
                    <ExpandCollapseButton
                        expanded={node.isOpen}
                        onClick={() => (node.isOpen ? node.close() : node.open())}
                    />
                )}
            </NavItem>
        </div>
    );
};

export default WikiNavItem;
