import { CSSProperties } from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';

type Props = {
    isExpanded: boolean;
    onClick: () => void;
    className?: string;
    style?: CSSProperties;
};

const BUTTON_STYLE: CSSProperties = {
    position: 'absolute',
    display: 'inline-block',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: '1px solid #cbd0d9',
    textAlign: 'center',
    backgroundColor: 'white',
    zIndex: 2,
    top: 'calc(50% - 25px)',
};

const SidePanelExpandButton = ({ isExpanded, onClick, className, style }: Props) => (
    <Button
        color="link"
        onClick={onClick}
        style={{ ...BUTTON_STYLE, ...{ [isExpanded ? 'right' : 'left']: '-25px' }, ...style }}
        className={classNames('text-muted p-2 text-decoration-none', className)}
    >
        <i className={isExpanded ? 'fa fa-angle-double-right' : 'fa fa-angle-double-left'} />
    </Button>
);

export default SidePanelExpandButton;
