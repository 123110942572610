import { useEffect, useRef, useState } from 'react';
import { Collapse } from 'reactstrap';
import ChatAssistantMessage from 'platform/chat/ChatMessage/ChatAssistantMessage/ChatAssistantMessage';
import IconButton from 'platform/common/components/IconButton/IconButton';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import { useLoading, withLoadingType } from 'platform/common/hooks/useLoading';
import { SummaryAiSkeleton } from './SummaryAiSkeleton';
import './SummaryAiSkeleton.scss';

export type ReportAiSummaryContainerProps = {
    loading: boolean;
    withLoading: withLoadingType;
    setTextSummary: (val: string) => void | undefined;
    setSummaryOpen: (val: boolean) => void;
};

type ChildrenFunction = (props: ReportAiSummaryContainerProps) => JSX.Element;

const ReportAiSummaryContainer = ({
    showContainer,
    containerClassName,
    children,
}: {
    showContainer?: boolean;
    containerClassName?: string;
    children: ChildrenFunction;
}) => {
    const [textSummary, setTextSummary] = useState<string | undefined>();
    const [collapseOpen, setSummaryOpen] = useState(false);
    const [loading, withLoading] = useLoading();
    const summaryRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (collapseOpen) {
            summaryRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [collapseOpen]);

    return (
        <>
            {children({ loading, withLoading, setTextSummary, setSummaryOpen })}
            {showContainer && (
                <div ref={summaryRef} className={containerClassName}>
                    <Collapse isOpen={collapseOpen}>
                        <InsetBox
                            className="min-content p-3 overflow-hidden p-4"
                            style={{ marginTop: '0.75rem' }}
                        >
                            {loading ? (
                                <SummaryAiSkeleton />
                            ) : (
                                <>
                                    <div className="Summary-header">
                                        <div className="Summary-content-title">
                                            <>
                                                <i className="fa-solid fa-wand-magic-sparkles text-primary" />
                                                <h3 className="m-0">DeeplyAI</h3>
                                            </>
                                        </div>
                                        <div>
                                            <IconButton onClick={() => setSummaryOpen(false)}>
                                                <i
                                                    className="far fa-circle-xmark text-danger"
                                                    style={{ fontSize: 16 }}
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className="Summary-content">
                                        <div>
                                            <ChatAssistantMessage content={textSummary!} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </InsetBox>
                    </Collapse>
                </div>
            )}
        </>
    );
};

export default ReportAiSummaryContainer;
