import { ClassificationOptionsProvider } from 'platform/analytics/reportComponents/ReportTableContainer/ReportTableForm/ClassificationOptionsContext';
import { toApiModel } from 'platform/campaign/strategy/mappers/strategy.mapper';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { updateStrategy } from '../services/strategy.service';
import { StrategyModel } from '../strategy.types';
import StrategyForm from './StrategyForm';

type Props = {
    strategy: StrategyModel;
    afterSubmit: () => void;
    onCancel: () => void;
};

const StrategyFormContainer = ({ strategy, afterSubmit, onCancel }: Props) => (
    <FormContainer
        helpKey="strategy_form"
        sidePanel={false}
        onOpen={async () => strategy}
        onSubmit={(model) => updateStrategy(strategy.id, toApiModel(model))}
        redirectAfterSubmit={false}
        onSubmitFinish={afterSubmit}
        onCancel={onCancel}
        canEdit
        isEdit
    >
        {(props) => (
            <ClassificationOptionsProvider>
                <StrategyForm {...props} />
            </ClassificationOptionsProvider>
        )}
    </FormContainer>
);

export default StrategyFormContainer;
