import { useEffect } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { sortBy } from 'lodash-es';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';

const ChartTabs = ({
    tabs,
    loading,
    currentTab,
    setTab,
}: {
    tabs: {
        chartType: string;
        label: string;
        value: any;
    }[];
    loading: boolean;
    currentTab: string;
    setTab: (chartType: string) => void;
}) => {
    const sortedTabs = loading ? tabs : sortBy(tabs, (tab) => !tab.value);

    useEffect(() => {
        if (loading) return;
        setTab(sortedTabs[0].chartType);
    }, [loading]);

    return (
        <Nav tabs>
            {sortedTabs.map((tab) => (
                <NavItem key={tab.label} className="ChartNavbar-item col-sm-3 p-0">
                    <NavLink active={currentTab === tab.chartType} onClick={() => setTab(tab.chartType)}>
                        {loading ? (
                            <Placeholder className="pb-1" height="20px" />
                        ) : (
                            <Tooltip renderTooltip={() => tab.label}>
                                <div className="ChartNavbar-label text-truncate">{tab.label}</div>
                            </Tooltip>
                        )}
                        <h5 className="ChartNavbar-value text-truncate mb-0">
                            {loading ? <Placeholder className="pt-1 pe-5" height="20px" /> : tab.value || '-'}
                        </h5>
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    );
};

export default ChartTabs;
