import { toTemplatesApi } from 'platform/algorithms/algorithm.mapper';
import { createAlgorithmTemplate } from 'platform/algorithms/algorithms.service';
import { AlgorithmTemplateFormModel } from 'platform/algorithms/algorithms.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import AlgorithmTemplateForm from './AlgorithmTemplateForm';

const AlgorithmTemplateCreate = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const onOpen = (): Promise<AlgorithmTemplateFormModel> => {
        const form: AlgorithmTemplateFormModel = {
            name: '',
            status: 'ACTIVE',
            hasSettings: false,
            hasCode: false,
            hasMultipleSettings: false,
            code: '',
            settings: '',
            version: undefined,
            adminNotes: '',
            display: {
                section: 'ALL',
                area: 'BUDGET',
                htmlDescription: '',
                shortDescription: '',
                onlyDemoMode: false,
                onlyAdvertiserIds: [],
                codeLanguage: 'JAVASCRIPT',
                settingsLanguage: 'JSON',
                order: undefined,
            },
            googleAdScript: false,
            externallyAvailable: false,
            format: 'CSV',
        };

        return Promise.resolve(form);
    };

    const onSubmit = (data: AlgorithmTemplateFormModel) => createAlgorithmTemplate(toTemplatesApi(data));

    return (
        <FormContainer
            helpKey="algorithm_template_form"
            onSubmit={onSubmit}
            onOpen={onOpen}
            canEdit={canEdit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            isEdit={false}
            sidePanel
        >
            {(props) => <AlgorithmTemplateForm {...props} />}
        </FormContainer>
    );
};

export default AlgorithmTemplateCreate;
