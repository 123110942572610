import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { offset } from '@popperjs/core';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash-es';
import moment from 'moment';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import SyncJournalIcon from 'platform/assets/icons/SYNC_JOURNAL.svg';
import { subscribe } from 'platform/common/utils/serverSentEvents.util';
import SyncTable from './SyncTable';
import { syncKey } from './syncJournal.constant';
import { fetchAggregatedSyncs, fetchSyncs, getSyncEventStreamUrl } from './syncJournal.service';
import { AggregatedSyncEntities, Sync } from './syncJournal.types';
import './RealTimeSyncPanel.scss';

const startOfToday = `${moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')}Z`;
const endOfDay = `${moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')}Z`;

const addOrReplaceSync = (currentSyncs: Sync[], sync: Sync): Sync[] => {
    const index = currentSyncs.findIndex((current) => syncKey(current) === syncKey(sync));
    if (index >= 0) {
        return Object.assign([...currentSyncs], { [index]: sync });
    }
    const newSyncs = [sync, ...currentSyncs];
    newSyncs.pop(); // sliding window

    return newSyncs;
};

const RealTimeSyncPanel = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [childPopoversOpen, setChildPopoversOpen] = useState(false);
    const [aggregatedSyncs, setAggregatedSyncs] = useState<AggregatedSyncEntities[]>([]);
    const [syncs, setSyncs] = useState<Sync[]>([]);

    const accessToken = useSelector(authSelectors.ready.token);

    useEffect(() => {
        const fetchAggEntities = async () => {
            setAggregatedSyncs(
                await fetchAggregatedSyncs({
                    dateFrom: startOfToday,
                    dateTo: endOfDay,
                    status: ['IN_PROGRESS', 'ERROR'],
                    limit: 1000,
                })
            );
        };
        if (dropdownOpen) fetchAggEntities();
    }, [dropdownOpen]);

    useEffect(() => {
        const eventStream = dropdownOpen
            ? subscribe(getSyncEventStreamUrl(accessToken), {
                  onConnect: () => fetchSyncs({ limit: 25 }).then((s) => setSyncs(s)),
                  onReceive: (sync: Sync) => {
                      setSyncs((allSyncs: Sync[]) => addOrReplaceSync(allSyncs, sync));
                  },
              })
            : undefined;
        return eventStream ? () => eventStream.unsubscribe() : noop;
    }, [accessToken, dropdownOpen]);

    const pendingEntities = aggregatedSyncs.filter((sync) => sync.status === 'IN_PROGRESS');
    const failingEntities = aggregatedSyncs.filter((sync) => sync.status === 'ERROR');

    return (
        <Dropdown
            className="RealTimeSyncPanel"
            isOpen={dropdownOpen}
            toggle={() => (!childPopoversOpen ? setDropdownOpen(!dropdownOpen) : setChildPopoversOpen(false))}
        >
            <DropdownToggle className="RealTimeSyncPanel-button nav-link dropdown-toggle">
                <img src={SyncJournalIcon} alt="Sync journal icon" />
            </DropdownToggle>
            <DropdownMenu
                className={classNames('pb-0', dropdownOpen && 'show')}
                modifiers={[
                    {
                        ...offset,
                        options: { offset: [0, 20] },
                    } as OffsetModifier,
                ]}
                end
            >
                <div className="RealTimeSyncPanel-content d-flex flex-column p-3">
                    <h5 className="px-2 fw-bold">Latest sync status</h5>
                    {!isEmpty(aggregatedSyncs) && (
                        <>
                            {!isEmpty(failingEntities) && (
                                <div className="mb-2 p-2 d-flex flex-column">
                                    <div className="mb-2">
                                        <div className="text-muted">
                                            Failing entities{' '}
                                            <span className="text-danger">({failingEntities.length})</span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-wrap">
                                        {failingEntities.map((sync) => (
                                            <div
                                                key={sync.name}
                                                className="bg-light-red rounded-pill text-danger font-xs me-2 px-2 py-1"
                                            >{`${sync.name} (${sync.count})`}</div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {!isEmpty(pendingEntities) && (
                                <div className="mb-2 px-3 py-2 d-flex flex-column">
                                    <div className="mb-2">
                                        <b>Pending entities ({pendingEntities.length})</b>
                                    </div>
                                    <div className="flex-wrap">
                                        {pendingEntities.map((sync) => (
                                            <span
                                                key={sync.name}
                                                className="bg-light-red rounded-pill text-danger font-xs me-2 px-2 py-1"
                                            >{`${sync.name} (${sync.count})`}</span>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <hr className="mx-2 my-3" />
                        </>
                    )}

                    <div className="overflow-auto">
                        <SyncTable syncs={syncs} onOpenDetails={() => setChildPopoversOpen(true)} />
                    </div>

                    <hr className="mx-2 my-2" />

                    <div className="mt-3 text-center">
                        <Link
                            to="/admin-panel/sync-journal"
                            className="font-lg fw-bold"
                            onClick={() => setDropdownOpen(false)}
                        >
                            Search in Sync Journal <i className="fa fa-arrow-right ms-1" />
                        </Link>
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default RealTimeSyncPanel;
