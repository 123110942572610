import { useEffect, useState } from 'react';
import { Badge, Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { getCreativeTypeName } from 'platform/creatives/constants/creativeTypes';
import { fetchConcepts } from 'platform/creatives/services/creative.service';
import OverlayLoader from '../../../common/components/OverlayLoader/OverlayLoader';
import { Concept, Creative } from '../../types/creative.types';
import { getCreativeFormat } from '../../utils/creativeList.utils';
import GcmHostedPreviewIframe from '../GcmHostedPreviewIframe/GcmHostedPreviewIframe';
import './CreativesPreview.scss';

interface Props {
    creativeId: number;
    toggle: () => void;
}

interface State {
    concept?: Concept;
    loading: boolean;
    error?: string;
}

const renderSidebarLink = (concept: Concept, creative: Creative) => (
    <span>{concept.type === 'STANDARD' ? getCreativeFormat(creative) : creative.name}</span>
);

const CreativesPreview = ({ creativeId, toggle }: Props) => {
    const [selectedCreativeId, setSelectedCreativeId] = useState<number>(creativeId);
    const [{ concept, loading, error }, setState] = useState<State>({
        concept: undefined,
        loading: true,
        error: undefined,
    });

    useEffect(() => {
        if (concept && concept.creatives.find((c) => c.id === creativeId)) return;

        setState({ concept: undefined, loading: true, error: undefined });

        fetchConcepts({ creativeId: [creativeId], view: 'full' })
            .then((concepts) => {
                if (!concepts) {
                    setState({ concept: undefined, loading: false, error: 'Creative not found' });
                    return;
                }
                setState({
                    concept: concepts[0],
                    loading: false,
                    error: undefined,
                });
            })
            .catch(() => setState({ concept: undefined, loading: false, error: 'Network error happened' }));
    }, []);

    const creative = concept?.creatives.find((item) => item.id === selectedCreativeId);

    return (
        <Modal
            className="CreativesPreview d-flex flex-column"
            contentClassName="flex-fill"
            toggle={toggle}
            isOpen
        >
            {loading && (
                <ModalBody>
                    <OverlayLoader />
                </ModalBody>
            )}
            {error}
            {concept && (
                <>
                    <div className="modal-header flex-column">
                        <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                            <h4 className="m-0">{concept.name}</h4>
                            <Button color="primary" onClick={toggle}>
                                Close
                            </Button>
                        </div>
                        <div className="text-break">
                            URL:{' '}
                            {concept.clickThroughUrl ||
                                (creative && 'clickThroughUrl' in creative && creative.clickThroughUrl) ||
                                '-'}
                        </div>
                    </div>
                    <div>
                        <Row>
                            <Col xs={3} className="border-right">
                                <div className="py-3 ps-3 d-flex flex-column align-items-start overflow-y-auto">
                                    {concept.creatives.map((item) => (
                                        <Button
                                            key={item.id}
                                            color="link"
                                            className="py-1"
                                            onClick={() => setSelectedCreativeId(item.id!)}
                                        >
                                            {renderSidebarLink(concept, item)}
                                        </Button>
                                    ))}
                                </div>
                            </Col>
                            <Col xs={9}>
                                {creative && (
                                    <div className="py-3 pe-3">
                                        <div className="d-flex align-items-center">
                                            <Badge className="CreativeCard-badge me-3">
                                                {getCreativeTypeName(creative)}
                                            </Badge>
                                            <div className="me-3">{creative.name}</div>
                                            {concept.type === 'STANDARD' && (
                                                <div>{getCreativeFormat(creative)}</div>
                                            )}
                                        </div>
                                        <div className="my-4">
                                            <div className="position-relative w-100 overflow-auto text-center">
                                                {creative.type === 'GCM_HOSTED' && (
                                                    <GcmHostedPreviewIframe creativeId={creative.id} />
                                                )}
                                                {creative.type !== 'GCM_HOSTED' && (
                                                    <div>Preview not available</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default CreativesPreview;
