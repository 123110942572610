import { DetailedHTMLProps, TableHTMLAttributes } from 'react';
import { stringify } from 'csv-string';
import { saveAs } from 'file-saver';
import IconButton from 'platform/common/components/IconButton/IconButton';

export interface ReactNodeProps {
    type: 'thead' | 'tbody' | 'tr' | 'th' | 'td' | 'a';
    props: { children: ReactNodeProps[] | string[]; href?: string };
}

const getNodeValue = (node: ReactNodeProps | string): string => {
    if (typeof node === 'string') return node;

    const childNode = node.props.children[0];

    if (typeof childNode !== 'string') {
        return getNodeValue(childNode);
    }

    return childNode;
};

type Props = Omit<DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>, 'children'> & {
    children: ReactNodeProps[];
};

const ChatAssistantTable = (props: Props) => {
    const { children: data } = props;

    const handleExportClick = () => {
        // HTML table adheres to same structure always
        // therefore we are just using indexes to get the data
        const headerRow = (data[0].props.children[0] as ReactNodeProps).props.children;
        const headerCells = headerRow.map(getNodeValue);

        const tableBodyRows = data[1].props.children;
        const cells = tableBodyRows.map((row) => (row as ReactNodeProps).props.children.map(getNodeValue));

        const csv = stringify([headerCells, ...cells] as unknown as string[][], ';');
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'deeply-table-export.csv');
    };

    return (
        <div className="d-flex flex-column">
            <div className="overflow-auto">
                <table {...props} />
            </div>
            <div>
                <IconButton onClick={handleExportClick} className="ms-auto">
                    <i className="fa fa-download ms-auto p-1" title="Export" />
                </IconButton>
            </div>
        </div>
    );
};

export default ChatAssistantTable;
