import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';
import StageCardHeader from './StageCardHeader';
import SummaryStep from './SummaryStep';
import { WorkflowStagesProps } from './WorkflowStages';
import useSummarySteps from './useSummarySteps';

interface Props {
    lanes: WorkflowStagesProps['lanes'];
}

const LiveStage = ({ lanes }: Props) => {
    const { stepPropsByType } = useSummarySteps(['LIVE'], lanes);

    return (
        <SimpleCard
            headerContent={
                <StageCardHeader
                    icon={<i className="fa-solid fa-bullhorn text-white font-3xl" />}
                    text="Live"
                />
            }
        >
            <SummaryStep {...stepPropsByType.LIVE} />
        </SimpleCard>
    );
};

export default LiveStage;
