import { ChangeEvent } from 'react';
import { InteractionComponentState, SliderInteractionState } from 'platform/analytics/analytics.types';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';

interface Props {
    componentState: SliderInteractionState;
    onChange: (state: InteractionComponentState) => void;
}

const ReportInteractionSliderFields = ({ componentState, onChange }: Props) => (
    <>
        <InputWithAddOn
            className="mb-3"
            value={componentState.key}
            type="text"
            leftAddOn={{ title: 'Macro' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange({ ...componentState, key: e.target.value })
            }
        />
        <InputWithAddOn
            value={componentState.defaultValue}
            type="number"
            leftAddOn={{ title: 'Default value' }}
            className="mb-3"
            inputClassName="border-right-0"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                    ...componentState,
                    defaultValue: e.target.value ? Number(e.target.value) : undefined,
                });
            }}
        />
        <InputWithAddOn
            className="mb-3"
            inputClassName="border-right-0"
            value={componentState.minValue}
            type="number"
            leftAddOn={{ title: 'Min' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange({
                    ...componentState,
                    minValue: e.target.value ? Number(e.target.value) : undefined,
                })
            }
        />
        <InputWithAddOn
            className="mb-3"
            inputClassName="border-right-0"
            value={componentState.maxValue}
            type="number"
            leftAddOn={{ title: 'Max' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange({
                    ...componentState,
                    maxValue: e.target.value ? Number(e.target.value) : undefined,
                })
            }
        />
        <InputWithAddOn
            className="mb-3"
            inputClassName="border-right-0"
            value={componentState.step}
            type="number"
            leftAddOn={{ title: 'Step' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange({
                    ...componentState,
                    step: e.target.value ? Number(e.target.value) : undefined,
                })
            }
        />
    </>
);

export default ReportInteractionSliderFields;
