import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash-es';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { FlightLabel } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.types';
import {
    fetchProducts,
    getProductCategories,
} from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import {
    Product,
    ProductCategory,
    ProductSubLabel,
} from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.types';
import { getCountryOptions } from 'platform/category/category.service';
import { SelectItem } from 'platform/common/common.types';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByName } from 'platform/common/utils/array.util';

export const useLabelClassification = ({
    selectedProductCategories,
    selectedProductLabels,
}: {
    selectedProductCategories?: string[];
    selectedProductLabels?: string[];
}): {
    productCategories: ProductCategory[];
    products: Product[];
    subLabels: ProductSubLabel[];
    flightLabels: FlightLabel[];
    countryOptions: SelectItem<string>[];
    labelsLoading: boolean;
} => {
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const [
        {
            data: [allProducts, flightLabels, countryOptions],
            loading: labelsLoading,
        },
    ] = usePromise(
        [[], [], []],
        () =>
            Promise.all([fetchProducts(advertiserId), fetchFlightLabels(advertiserId), getCountryOptions()]),
        [advertiserId]
    );

    const products = selectedProductCategories?.length
        ? allProducts.filter((p) => p.category && selectedProductCategories.includes(p.category))
        : allProducts;

    const productCategories = getProductCategories(products);

    const subLabels = selectedProductLabels?.length
        ? uniqBy(
              sortByName(
                  products.filter((p) => selectedProductLabels.includes(p.name)).flatMap((p) => p.subLabels)
              ),
              (l) => l.name
          )
        : [];

    return { productCategories, products, subLabels, flightLabels, countryOptions, labelsLoading };
};
