import axiosBff from 'platform/axios/axiosBff';
import { LogCategory, LogDateFilterStrategy } from 'platform/campaign/logBook/logBook.constant';
import {
    CreateLogBookEntryPayload,
    LogBookEntry,
    LogBookNoteModel,
} from 'platform/campaign/logBook/logBook.types';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';

export type LogbookFilter = {
    status?: ActiveOrArchived[];
    category?: LogCategory;
    mediaplanId?: number[];
    mediaInsertionId?: number[];
    dateFrom?: string;
    dateTo?: string;
    dateFilterStrategy?: LogDateFilterStrategy;
    advertiserId: number;
    productCategory?: string[];
    productLabel?: string[];
    productSubLabel?: string[];
    flightLabel?: string[];
    countryLabel?: string[];
};

export const searchLogBook = (params: LogbookFilter) =>
    axiosBff.get<LogBookEntry[]>(`/campaign/api/logbook`, { params }).then((response) => response.data);

export const createNote = (payload: CreateLogBookEntryPayload) =>
    axiosBff.post('/campaign/api/logbook/notes', payload);

export const updateNote = (id: number, payload: CreateLogBookEntryPayload) =>
    axiosBff.put(`/campaign/api/logbook/notes/${id}`, payload);

export const getLog = (id: number) =>
    axiosBff.get<LogBookNoteModel>(`/campaign/api/logbook/notes/${id}`).then((response) => response.data);
