import AdminChatAgentList from 'platform/chat/admin/AdminChatAgentList/AdminChatAgentList';
import ModelEndpointList from 'platform/chat/admin/ModelEndpointList/ModelEndpointList';
import SystemPromptList from 'platform/chat/admin/SystemPromptList/SystemPromptList';
import { SelectItem } from 'platform/common/common.types';
import { isProduction } from 'platform/common/utils/environment.util';
import ContextPromptList from './admin/ContextPromptList/ContextPromptList';
import PredefinedPromptList from './admin/PredefinedPromptList/PredefinedPromptList';
import { ChatLanguage } from './chat.types';

// A hack, but we do it instead of introducing additional flags in Deeply service
export const CHAT_SUMMARY_AGENT_ID = isProduction() ? 15 : 11;

export const FOOTER_TEXT =
    'DeeplyAI does not store any of your input and output data, and is hosted on Deep Media’s Cloud in Europe.';

export const MAX_IMAGE_FILE_SIZE = 10 * 1024 * 1024; // 10mb
export const MAX_CSV_FILE_SIZE = 200 * 1024; // 200kb

export const CHAT_DEFAULT_FILE_ACCEPT_TYPES = ['text/csv'];

export const CHAT_EXCLUDED_FILE_TYPES = ['text/csv'];

export const FILE_UPLOAD_ERROR_MSG =
    'File upload failed. Please make sure only one supported file is selected.';

export const MAX_FILE_SIZE_ERROR = 'File size is too large. Please upload a file smaller than 16kb.';

export const CHAT_ADMIN_TABS = [
    {
        url: 'models',
        label: 'Models',
        component: ModelEndpointList,
    },
    {
        url: 'agents',
        label: 'Agents',
        component: AdminChatAgentList,
    },
    {
        url: 'prompts',
        label: 'Prompt Manager',
        component: PredefinedPromptList,
    },
    {
        url: 'context-prompts',
        label: 'Context Prompts',
        component: ContextPromptList,
    },
    {
        url: 'system-prompts',
        label: 'System Prompts',
        component: SystemPromptList,
    },
];

export const CHAT_LANGUAGE_OPTIONS: SelectItem<ChatLanguage>[] = [
    { value: 'language_auto', label: 'Auto' },
    { value: 'language_en', label: 'EN' },
    { value: 'language_de', label: 'DE' },
];

export const SYSTEM_PROMPT_KEYS = [
    'file_prompt',
    'force_language_prompt',
    'language_de',
    'language_en',
] as const;
