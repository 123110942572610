import { useDispatch, useSelector } from 'react-redux';
import { fetchAnalyticsMetadata } from 'platform/analytics/analytics.service';
import { AnalyticsMetadata } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { analyticsMetadataActions } from 'platform/analytics/ducks/analyticsMeta.duck';

type Operations = {
    reloadAnalyticsMetadata: (advertiserId: number) => Promise<AnalyticsMetadata>;
};

export const useAnalyticsMetadata = (): AnalyticsMetadata & Operations => {
    const metadata = useSelector(analyticsSelectors.meta);
    const dispatch = useDispatch();

    return {
        ...metadata,

        reloadAnalyticsMetadata: (advertiserId: number) =>
            fetchAnalyticsMetadata(advertiserId)
                .then((newMetadata) => dispatch(analyticsMetadataActions.setMeta(newMetadata)))
                .then((action) => action.payload),
    };
};
