import { useEffect, useMemo, useRef } from 'react';
import { usePageVisibility } from 'platform/common/hooks/usePageVisibility';

interface Options {
    interval: number;
    backgroundPolling?: boolean;
    immediate?: boolean;
}

// modify original interval by ± 5% to evenly distribute server load across multiple polling clients
const randomJitter = (interval: number) => interval * (0.95 + 0.1 * Math.random());

export const usePolling = (
    loader: () => Promise<any>,
    { interval, immediate, backgroundPolling }: Options
) => {
    const loaderRef = useRef<() => Promise<any>>();
    const running = useRef(false);
    const pageInView = usePageVisibility();
    const isPageVisible = backgroundPolling || pageInView;
    const timeout = useMemo(() => randomJitter(interval), [interval]);

    useEffect(() => {
        // read this: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
        loaderRef.current = loader;
    }, [loader]);

    useEffect(() => {
        // in order not to waste backend resources, poll data only when the tab is active/visible
        if (!isPageVisible) return () => {};

        const poll = async () => {
            if (running.current) {
                return; // in case of slow data loading, skip when previous iteration hasn't completed yet
            }
            try {
                running.current = true;
                await loaderRef.current?.();
            } finally {
                running.current = false;
            }
        };

        if (immediate) {
            poll();
        }

        const timer = setInterval(poll, timeout);

        return () => clearInterval(timer);
    }, [isPageVisible]);
};
