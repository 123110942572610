import FormRow from 'platform/common/components/FormRow/FormRow';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';

const MaintenanceModeForm = () => (
    <>
        <FormRow label="Message">
            <FormInput name="value.message" type="textarea" rows={5} />
        </FormRow>
        <FormRow label="Enabled">
            <FormSwitch name="value.enabled" />
        </FormRow>
    </>
);

export default MaintenanceModeForm;
