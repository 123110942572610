import { Button } from 'reactstrap';
import { FieldArray } from 'formik';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import LabelFields from 'platform/creatives/components/CreativeLabelForm/LabelFields';
import FormInput from 'platform/formik/FormInput/FormInput';
import { CreativeLabelGroup } from '../../types/creativeLabel.types';

const CreativeLabelForm = ({
    labels,
    formikProps: { initialValues, submitForm, values },
    canEdit,
    onCancel,
    isEdit,
}: FormProps<CreativeLabelGroup>) => (
    <CardForm
        title={`${isEdit ? 'Edit' : 'New'} Creative Settings Group`}
        subtitle={isEdit && `ID: ${initialValues.id}`}
        submitLabel={labels.submit}
        onCancel={onCancel}
        disabled={!canEdit}
        onSubmit={submitForm}
    >
        <ControlledCard title="Group info" subtitle="required">
            <FormRow label="Name">
                <FormInput name="name" type="text" validate={required} />
            </FormRow>
        </ControlledCard>
        <ControlledCard title="Labels" subtitle="required">
            <FieldArray name="labels">
                {({ unshift, remove }) => (
                    <>
                        <Button
                            size="md"
                            color="primary"
                            className="mb-3"
                            onClick={() => {
                                unshift({ name: '', groupId: initialValues.id });
                            }}
                        >
                            Add new
                        </Button>
                        {values.labels.map((label, index) => (
                            <LabelFields
                                field={`labels[${index}]`}
                                label={label}
                                onRemove={() => remove(index)}
                            />
                        ))}
                    </>
                )}
            </FieldArray>
        </ControlledCard>
    </CardForm>
);

export default CreativeLabelForm;
