export enum UnitState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INACTIVATED_BY_PARENT = 'INACTIVATED_BY_PARENT',
    ARCHIVED = 'ARCHIVED',
}

export enum UnitStateLabel {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    INACTIVATED_BY_PARENT = 'Inactivated by parent',
    ARCHIVED = 'Archived',
}

export const UNIT_STATE_OPTIONS = [
    { label: UnitStateLabel.ACTIVE, value: UnitState.ACTIVE },
    { label: UnitStateLabel.INACTIVE, value: UnitState.INACTIVE },
    {
        label: UnitStateLabel.INACTIVATED_BY_PARENT,
        value: UnitState.INACTIVATED_BY_PARENT,
        disabled: true,
    },
    { label: UnitStateLabel.ARCHIVED, value: UnitState.ARCHIVED, disabled: true },
];
