import { useRef } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { offset } from '@popperjs/core';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import GlobalSearchFilters from 'platform/app/components/GlobalSearch/GlobalSearchFilters';
import SearchResults from 'platform/app/components/GlobalSearch/SearchResults';
import Overlay from 'platform/common/components/Overlay/Overlay';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Search from 'platform/common/components/Search/Search';
import SearchNotification from 'platform/common/components/SearchNotification/SearchNotification';
import { useIsMobile } from 'platform/common/hooks/useIsMobile';
import { useGlobalSearch } from './useGlobalSearch';
import './GlobalSearch.scss';

const isMac = navigator.userAgent.toLowerCase().includes('mac');

interface Props {
    disabled: boolean;
}

const GlobalSearch = ({ disabled }: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const isMobile = useIsMobile();

    const { query, filters, results, vendors, loading, isOpen, changeQuery, changeFilters, toggle } =
        useGlobalSearch(inputRef.current, disabled);

    return (
        <div ref={ref} className={classNames('GlobalSearch', { mobile: isMobile })}>
            {isOpen && <Overlay style={{ zIndex: 1 }} onClick={toggle} />}

            <Dropdown isOpen={isOpen} toggle={noop}>
                <DropdownToggle className="dropdown-toggle">
                    <Search
                        ref={inputRef}
                        value={query}
                        placeholder="Global search"
                        keyboardShortcut={isMac ? '⌘ K' : 'Ctrl K'}
                        className={classNames('rounded', isOpen ? 'border-primary' : 'border')}
                        onSearch={changeQuery}
                        onClick={() => toggle(true)}
                    />
                </DropdownToggle>
                {/* without stopPropagation DropdownMenu prevents text copying */}
                <DropdownMenu
                    className="GlobalSearch-dropdown"
                    modifiers={[
                        {
                            ...offset,
                            options: { offset: [0, 8] },
                        } as OffsetModifier,
                    ]}
                    end
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <div
                        className={classNames('GlobalSearch-dropdown-content p-3', isMobile && 'flex-column')}
                    >
                        <div className="position-relative d-flex flex-column flex-fill overflow-auto order-2">
                            {loading && <OverlayLoader />}

                            {!results && (
                                <SearchNotification header="Global search" text="Start typing to search" />
                            )}
                            {!loading && results?.length === 0 && (
                                <SearchNotification
                                    header={
                                        <span className="text-break">
                                            No results for &quot;<strong>{query}</strong>&quot;
                                        </span>
                                    }
                                    text="Try refining the query or adjusting filters"
                                />
                            )}
                            {!!results?.length && (
                                <SearchResults results={results} vendors={vendors} onResultClick={toggle} />
                            )}
                        </div>

                        {!isMobile && <div className="border-right order-1" />}

                        <div className="d-flex flex-column order-0">
                            <GlobalSearchFilters
                                filters={filters}
                                className={classNames('GlobalSearch-filters', isMobile && 'mobile')}
                                onFiltersChange={changeFilters}
                            />
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default GlobalSearch;
