import { ComponentType } from 'react';
import classNames from 'classnames';
import { TableCell } from 'platform/common/common.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import TableFooterRow from 'platform/common/components/TableFooterRow/TableFooterRow';

type Props = {
    columns: TableColumn[];
    data: any[];
    loading?: boolean;
    isRemovable?: (row: any) => boolean;
    onRemove: (row: any) => void;
    NewRowComponent: ComponentType<any>;
    newRowAsHeader?: boolean;
    [key: string]: any; // rest props
};

const STYLE = {
    HEADER_ROW: {
        style: {
            order: -1,
        },
    },
};

const DynamicRowsTable = ({
    columns,
    data,
    loading,
    isRemovable = () => true,
    onRemove,
    NewRowComponent,
    newRowAsHeader,
    ...rest
}: Props) => (
    <FormattedTable
        columns={[
            {
                ...columns[0],
                Footer: 'FooterPlaceholder',
            },
            ...columns.slice(1),
            {
                Header: '',
                width: 30,
                sortable: false,
                Cell: ({ original }: TableCell<any>) =>
                    isRemovable(original) ? (
                        <button
                            onClick={() => onRemove(original)}
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                        />
                    ) : null,
            },
        ]}
        data={data}
        loading={loading}
        containerClass="mb-0"
        style={{ overflow: 'visible' }}
        getTableProps={() => ({ style: { overflow: 'visible' } })}
        stickyHeader={false}
        showPagination={false}
        columnsAlignedFromIndex={0}
        NoDataComponent={() => null}
        getTheadProps={() => STYLE.HEADER_ROW}
        TfootComponent={() => (
            <TableFooterRow className={classNames({ 'TableFooterRow-on-top': newRowAsHeader })}>
                <NewRowComponent />
            </TableFooterRow>
        )}
        {...rest}
    />
);

export default DynamicRowsTable;
