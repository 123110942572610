import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import qs from 'qs';
import { ADVERTISER_TAG } from 'platform/advertiserTag/advertiserTag.navigation';
import AdvertiserTagFormContainer from 'platform/advertiserTag/components/AdvertiserTagForm/AdvertiserTagFormContainer';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { TableCell } from 'platform/common/common.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatDateTime } from 'platform/common/utils/date.util';
import { AdvertiserTag } from '../../advertiserTag.types';
import { fetchAdvertiserTags, updateAdvertiserTagState } from '../../services/advertiserTag.service';
import AdvertiserTagDownloadForm from '../AdvertiserTagDownloadForm/AdvertiserTagDownloadFormContainer';
import ThirdPartyCodesForm from '../ThirdPartyCodesForm/ThirdPartyCodesForm';

const AdvertiserTagList = () => {
    const dispatch = useDispatch();

    const canEdit = useFeature('ADVERTISER_TAG_EDIT');
    const demoModeEnabled = useSelector(authSelectors.isDemoModeEnabled);
    const advertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const { id: advertiserId } = useActiveAdvertiserComponent();

    const [{ data, loading }, refetch] = usePromise([], () => fetchAdvertiserTags({ advertiserId }), [
        advertiserId,
    ]);

    const columnDefinitions = useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'state',
                maxWidth: 80,
                Cell: (props: TableCell<AdvertiserTag>) => <StatusBadge status={props.value} />,
            },
            {
                Header: 'ID',
                accessor: 'id',
                type: DATA_TYPES.TEXT,
                maxWidth: 80,
            },
            {
                Header: 'Owner',
                Cell: () => (advertiser ? advertiser.name : null),
                minWidth: 200,
            },
            {
                Header: 'Domain',
                accessor: (tag: AdvertiserTag) => (demoModeEnabled ? 'example.com' : tag.domain),
                minWidth: 200,
            },
            {
                Header: 'Last uploaded',
                accessor: 'lastUploadOn',
                maxWidth: 160,
                Cell: (props: TableCell<AdvertiserTag>) => formatDateTime(props.value) || null,
            },
            {
                Header: 'Edited',
                accessor: 'updatedOn',
                width: 150,
                Cell: (row: TableCell<AdvertiserTag>) => (
                    <UpdatedOn date={row.original.updatedOn} updatedBy={row.original.updatedUserName} />
                ),
            },
            {
                className: 'pull-right cell-align-right',
                width: 50,
                sortable: false,
                Cell: ({ original }: TableCell<AdvertiserTag>) => {
                    const items: PopoverDropdownItem[] = [];
                    const advertiserTagId = original.id;

                    if (original.state === 'ACTIVE') {
                        items.push({
                            label: canEdit ? 'Edit' : 'View',
                            action: () => {
                                dispatch(push(`/advertiser-tags/${advertiserTagId}`));
                            },
                        });

                        items.push({
                            label: canEdit ? 'Edit Data sharing' : 'View Data sharing',
                            action: () => {
                                dispatch(push(`/advertiser-tags/${advertiserTagId}/data-sharing/`));
                            },
                        });

                        items.push({
                            label: 'Download',
                            action: () => {
                                dispatch(push(`/advertiser-tags/${advertiserTagId}/download/`));
                            },
                        });

                        if (canEdit) {
                            items.push({
                                label: 'Deactivate',
                                action: async () => {
                                    await updateAdvertiserTagState(advertiserTagId, {
                                        state: 'INACTIVE',
                                    });
                                    refetch();
                                },
                            });
                        }
                    } else if (canEdit) {
                        items.push({
                            label: 'Activate',
                            action: async () => {
                                await updateAdvertiserTagState(advertiserTagId, {
                                    state: 'ACTIVE',
                                });
                                refetch();
                            },
                        });
                    }

                    return items.length > 0 ? <InlineDropdown items={items} /> : null;
                },
            },
        ],
        [advertiser, canEdit, demoModeEnabled, dispatch, refetch]
    );

    const renderTopToolbar = () => {
        const activeAdvertiserTagExists = data.some((item) => item.state === 'ACTIVE');
        return (
            <ListToolbar
                onCreate={
                    canEdit && !activeAdvertiserTagExists
                        ? () => {
                              dispatch(
                                  push({
                                      pathname: '/advertiser-tags/new',
                                      search: qs.stringify({ advertiserId }),
                                  })
                              );
                          }
                        : undefined
                }
                createInactiveReason={(() => {
                    if (!canEdit) {
                        return undefined;
                    }

                    if (activeAdvertiserTagExists) {
                        return 'Existing AdvertiserTag must be deactivated before creating new';
                    }

                    return undefined;
                })()}
            />
        );
    };

    return (
        <>
            <HeaderContainer>
                <PageHeader title="AdvertiserTag" />
            </HeaderContainer>
            <BodyContainer helpKey="advertiser_tag_list">
                {!canEdit && <CanNotEditWarning />}
                <FormattedTable
                    columns={columnDefinitions}
                    topToolbar={renderTopToolbar()}
                    data={data}
                    loading={loading}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path=":id/data-sharing/"
                    element={<ThirdPartyCodesForm canEdit={canEdit} redirectTo={ADVERTISER_TAG.path!} />}
                />
                <Route
                    path=":id/download/"
                    element={
                        <AdvertiserTagDownloadForm canEdit={canEdit} redirectTo={ADVERTISER_TAG.path!} />
                    }
                />
                <Route
                    path="new"
                    element={
                        <AdvertiserTagFormContainer
                            redirectTo={ADVERTISER_TAG.path!}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <AdvertiserTagFormContainer
                            redirectTo={ADVERTISER_TAG.path!}
                            canEdit={canEdit}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default AdvertiserTagList;
