import { ReportSettings } from 'platform/analytics/analytics.service';
import { DATE_COLUMNS } from 'platform/chat/olap/chat.olap.util';

interface Props {
    olapSettings: ReportSettings;
}

const combineDateFilters = (dateFilters: { [p: string]: any }[]) =>
    dateFilters
        .map((filter) => {
            const firstValue = filter.compareValue[0];
            if (filter.compareOperator === 'BETWEEN') {
                return { from: firstValue, to: filter.compareValue[1] };
            }
            if (['GTE', 'BTE'].includes(filter.compareOperator)) {
                return { from: firstValue };
            }
            if (['STE', 'LTE'].includes(filter.compareOperator)) {
                return { to: firstValue };
            }
            if (['GT', 'BT'].includes(filter.compareOperator)) {
                return { after: firstValue };
            }
            if (['ST', 'LT'].includes(filter.compareOperator)) {
                return { before: firstValue };
            }
            return { values: filter.compareValue };
        })
        .reduce((acc, f) => ({ ...acc, ...f, values: [...(acc.values || []), ...(f.values || [])] }));

const ChatOlapTableToolbar = ({ olapSettings }: Props) => {
    const dateFilters =
        olapSettings.olapFilters?.filter((filter) => DATE_COLUMNS.includes(filter.columnId)) ?? [];

    const date = combineDateFilters(dateFilters);
    const filtersStr = [
        date.from ? `From ${date.from}` : '',
        date.to ? `to ${date.to}` : '',
        date.after ? `After ${date.after}` : '',
        date.before ? `before ${date.before}` : '',
        date.values && date.values.length > 0 ? date.values.join(', ') : '',
    ].join(' ');

    return (
        <div className="fw-semibold">
            <i className="far fa-calendar-alt me-1 text-primary" />
            {filtersStr}
        </div>
    );
};

export default ChatOlapTableToolbar;
