import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import { useAdvancedSetting } from 'platform/common/hooks/useAdvancedSetting';
import { usePromise } from './usePromise';

const useMasterAdvertiser = (advertiserId: number) => {
    const masterAdvertiserId = useAdvancedSetting('master-advertiser-id');
    const [{ data: masterAdvertiser }] = usePromise(
        undefined,
        async () => (masterAdvertiserId ? fetchAdvertiser(Number(masterAdvertiserId)) : undefined),
        [masterAdvertiserId]
    );
    return {
        masterAdvertiser,
        resolvedAdvertiserId: masterAdvertiserId ? Number(masterAdvertiserId) : advertiserId,
    };
};

export default useMasterAdvertiser;
