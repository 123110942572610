import { Card, CardBody } from 'reactstrap';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import { fill, range, sumBy } from 'lodash-es';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import { ReportSettings, generateOlapReport } from 'platform/analytics/analytics.service';
import LinePure from 'platform/common/components/LinePure/LinePure';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatIsoDate } from 'platform/common/utils/date.util';
import { precisionRound } from 'platform/common/utils/formatters.util';
import { DEVICE_COLORS, filterDevices } from 'platform/dashboard/dashboard.constant';

interface Props {
    advertiserId: number;
}

interface ChartProps {
    data: ChartData<'line'>;
    options: ChartOptions<'line'>;
}

const UsersByHourCard = ({ advertiserId }: Props) => {
    const metric = 'adh_segment_unique_users_30d';
    const dimensions = ['hour_of_day', 'device_type'];
    const request: ReportSettings = {
        metrics: [metric],
        dimensions,
        olapFilters: [
            {
                columnId: 'advertiser_id',
                compareOperator: 'EQUALS',
                compareValue: advertiserId,
            },
            {
                columnId: 'date',
                compareOperator: 'EQUALS',
                compareValue: formatIsoDate(moment()),
            },
        ],
    };
    const [{ data: report, loading }] = usePromise(undefined, () => generateOlapReport(request), []);

    const buildChartData = memoizeOne((): ChartProps | null => {
        if (!report) {
            return null;
        }

        const hoursOfDay = range(24).map((value) => moment(value, 'hA').format('hA'));

        const totalUsersCount = sumBy(report.rows, (row) => row[metric]);

        const devices = filterDevices(report.rows);

        const parsedReport = devices.reduce<Record<string, number[]>>(
            (acc, val) => ({
                ...acc,
                [val]: fill(Array(hoursOfDay.length), 0).map((v, i) => {
                    if (totalUsersCount === 0) {
                        return 0;
                    }

                    const value =
                        report.rows.find(
                            (r) => r.hour_of_day === i && r.device_type?.toLowerCase() === val.toLowerCase()
                        )?.[metric] || 0;

                    return precisionRound((value * 100) / totalUsersCount, 2);
                }),
            }),
            {}
        );

        const datasets = devices.map((key) => ({
            label: key,
            data: parsedReport[key],
            fill: false,
            borderWidth: 2,
            borderColor: DEVICE_COLORS[key],
            backgroundColor: DEVICE_COLORS[key],
        }));

        return {
            data: {
                labels: hoursOfDay,
                datasets,
            },
            options: {
                animation: false,
                hover: { intersect: false },
                maintainAspectRatio: false,
                elements: {
                    point: { radius: 0, hoverRadius: 4 },
                },
                scales: {
                    x: {
                        time: {
                            parser: (value: string) => moment(value, 'hA').valueOf(),
                        },
                        ticks: {
                            maxRotation: 0,
                            maxTicksLimit: 4,
                        },
                    },

                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value: number) => DATA_TYPES.P100.format(value),
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        intersect: true,
                        mode: datasets.length > 1 ? 'point' : 'index',
                        callbacks: {
                            label: (tooltip: TooltipItem<'line'>) => DATA_TYPES.P100.format(tooltip.raw),
                        },
                    },
                },
            },
        };
    });

    const data = buildChartData();

    return (
        <Card className="mb-0 h-100">
            <CardBody className="p-3">
                <h5 className="mb-4">Users by Hour</h5>
                <div className="position-relative" style={{ height: '250px' }}>
                    {loading || !data ? (
                        <Placeholder height="250px" />
                    ) : (
                        <LinePure data={data.data} options={data.options} height={250} />
                    )}
                </div>
            </CardBody>
        </Card>
    );
};

export default UsersByHourCard;
