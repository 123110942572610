import { CSSProperties, ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';

interface Props {
    title?: string;
    okLabel?: string;
    style?: CSSProperties;
    children?: ReactNode;
    isOpen: boolean;
    renderFooter?: () => ReactNode;
    toggle: () => void;
}

const InformationModal = ({
    title = '',
    okLabel = 'OK',
    style,
    children,
    isOpen,
    renderFooter,
    toggle,
}: Props) => (
    <Modal style={style} zIndex={1100} isOpen={isOpen} toggle={toggle}>
        <ModalHeader onClose={toggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
            {renderFooter ? (
                renderFooter()
            ) : (
                <Button color="primary" onClick={toggle}>
                    {okLabel}
                </Button>
            )}
        </ModalFooter>
    </Modal>
);

export default InformationModal;
