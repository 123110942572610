import { ChartProps } from 'react-chartjs-2';
import { Card, CardBody } from 'reactstrap';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { sumBy } from 'lodash-es';
import moment from 'moment';
import { ReportSettings, generateOlapReport } from 'platform/analytics/analytics.service';
import Doughnut from 'platform/common/components/Charts/Doughnut';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatIsoDate } from 'platform/common/utils/date.util';
import { precisionRound } from 'platform/common/utils/formatters.util';
import { DEVICE_COLORS, filterDevices } from 'platform/dashboard/dashboard.constant';

type Props = {
    advertiserId: number;
};

const UsersByDevicesCard = ({ advertiserId }: Props) => {
    const metric = 'adh_segment_unique_users_30d';
    const dimensions = ['device_type'];
    const request: ReportSettings = {
        metrics: [metric],
        dimensions,
        olapFilters: [
            {
                columnId: 'date',
                compareOperator: 'EQUALS',
                compareValue: formatIsoDate(moment()),
            },
            {
                columnId: 'advertiser_id',
                compareOperator: 'EQUALS',
                compareValue: advertiserId,
            },
        ],
    };
    const [{ data: report, loading }] = usePromise(undefined, () => generateOlapReport(request), []);

    const buildChartData = () => {
        const totalUsersCount = report ? sumBy(report.rows, (row) => row[metric]) : 0;

        const devices = report ? filterDevices(report.rows) : [];

        const chartData: ChartProps<any, number[], unknown> = {
            type: 'doughnut',
            data: {
                labels: devices,
                datasets: [
                    {
                        data: devices.map((device) => {
                            if (totalUsersCount === 0) {
                                return 0;
                            }
                            const value = report
                                ? report.rows.find(
                                      (row) => row.device_type?.toLowerCase() === device.toLowerCase()
                                  )?.[metric]
                                : 0;
                            return precisionRound((value * 100) / totalUsersCount, 2);
                        }),
                        backgroundColor: devices.map((device) => DEVICE_COLORS[device]),
                        datalabels: {
                            formatter: (value: number) =>
                                totalUsersCount === 0 ? '' : DATA_TYPES.P100.format(value),
                            color: '#000',
                            anchor: 'end' as const,
                            align: 'end' as const,
                            clip: false,
                        },
                    },
                ],
            },
            plugins: [ChartDataLabels],
            options: {
                animation: undefined,
                maintainAspectRatio: false,
                cutout: 60,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom' as const,
                        labels: {
                            boxWidth: 9,
                        },
                    },
                    tooltip: {
                        callbacks: {
                            title(tooltip: any) {
                                return tooltip?.[0]?.label;
                            },
                            label(tooltipItem: any) {
                                return DATA_TYPES.P100.format(tooltipItem.raw);
                            },
                        },
                    },
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 25,
                        bottom: 0,
                    },
                },
            },
        };

        return chartData;
    };

    return (
        <Card className="mb-0 h-100">
            <CardBody className="p-3">
                <h5 className="mb-4">Users by Devices</h5>
                <div className="position-relative" style={{ height: '250px' }}>
                    {loading ? <Placeholder height="250px" /> : <Doughnut {...buildChartData()} />}
                </div>
            </CardBody>
        </Card>
    );
};

export default UsersByDevicesCard;
