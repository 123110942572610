export const IOScriptExportHeader = {
    vendorName: 'Vendor',
    campaignName: 'Campaign name',
    strategyName: 'Strategy name',
    unitName: 'Unit name',
    creativeName: 'Creative name',
    creativeSize: 'Creative size',
    javascript: 'Javascript',
    iframe: 'iFrame',
    clickTracker: 'Click tracker',
    viewTracker: 'View tracker',
    vast2: 'VAST 2.0',
    vast3: 'VAST 3.0',
    vast4: 'VAST 4.0',
};
