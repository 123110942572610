import { Alert } from 'reactstrap';

const AdBlockerWarning = () => (
    <Alert color="danger" className="d-flex align-items-center text-lg mt-2">
        <i className="fa fa-2xl fa-exclamation-circle me-3" />
        <div className="font-lg">
            <div className="mb-2">
                An <b>ad-blocker</b> or <b>anti-tracking</b> browser plugin (e.g. AdBlock, uBlock, Ghostery)
                may be interrupting the normal functioning of the Deep Advertising Platform.
            </div>
            <div>
                To ensure proper functionality, please disable this feature. Thank you for your cooperation.
            </div>
        </div>
    </Alert>
);

export default AdBlockerWarning;
