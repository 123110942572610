import { useSelector } from 'react-redux';
import { makeGridColumnOptions } from 'platform/analytics/analytics.util';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { ReportComponentState } from '../analytics.types';
import { analyticsSelectors } from '../ducks/analytics.duck';
import './reportComponents.scss';

const CHART_HEIGHT = 374;

interface Props<T> {
    componentState: T;
    onChange: (state: T) => void;
}

const ReportPlacementSettings = <T extends ReportComponentState>({ componentState, onChange }: Props<T>) => {
    const gridColumns = useSelector(analyticsSelectors.gridColumns);

    return (
        <div className="d-flex mb-3">
            <SelectWithAddon
                selectClassname="flex-fill"
                name="Width"
                options={makeGridColumnOptions(gridColumns)}
                value={componentState.placement.width}
                isClearable={false}
                onChange={(width) =>
                    onChange({ ...componentState, placement: { ...componentState.placement, width } })
                }
            />
            <SelectWithAddon
                name="Height"
                className="ms-3"
                value={componentState.height}
                options={[
                    { label: '100%', value: undefined },
                    { label: 'Use chart height', value: CHART_HEIGHT },
                ]}
                selectClassname="flex-fill"
                isDisabled={componentState.type !== 'TABLE'}
                onChange={(height) => onChange({ ...componentState, height })}
            />
        </div>
    );
};

export default ReportPlacementSettings;
