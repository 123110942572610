import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import './OverlayLoader.scss';

const OverlayLoader = () => (
    <div className="OverlayLoader">
        <Placeholder height="100%" />
    </div>
);

export default OverlayLoader;
