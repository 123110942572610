import { FC } from 'react';
import './NavigationIcon.scss';

interface Props {
    Icon?: FC | string;
}

const NavigationIcon = ({ Icon }: Props) => {
    if (!Icon) {
        return null;
    }

    const iconEl = typeof Icon === 'string' ? <i className={Icon} /> : <Icon />;

    return <div className="NavigationIcon">{iconEl}</div>;
};

export default NavigationIcon;
