import { Route, Routes } from 'react-router-dom';
import AnalyticsReportFormContainer from 'platform/analyticsReports/AnalyticsReportFormContainer';

interface Props {
    canEdit: boolean;
    afterSubmit?: (data: any) => void;
}

const AnalyticsReportFormRoutes = ({ canEdit, afterSubmit }: Props) => (
    <Routes>
        <Route
            path="new"
            element={
                <AnalyticsReportFormContainer
                    redirectTo="/report-management"
                    canEdit={canEdit}
                    afterSubmit={afterSubmit}
                />
            }
        />
        <Route
            path=":id"
            element={
                <AnalyticsReportFormContainer
                    redirectTo="/report-management"
                    canEdit={canEdit}
                    afterSubmit={afterSubmit}
                />
            }
        />
    </Routes>
);

export default AnalyticsReportFormRoutes;
