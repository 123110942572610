import { ChatContentParts } from 'platform/chat/chat.types';

const ChatUserMessageImage = ({ contentPart }: { contentPart: ChatContentParts }) => (
    <img
        style={{ width: 169, maxHeight: 169, objectFit: 'scale-down' }}
        src={`data:${contentPart?.mimeType};base64,${contentPart?.content || ''}`}
        alt={contentPart?.fileName || ' '}
    />
);

export default ChatUserMessageImage;
