import { NavigationItem, RoutingItem } from 'platform/app/app.types';
import EmbeddedReport from 'platform/embeddedRerport/embeddedReport';

export const EMBEDDED_REPORT: NavigationItem = {
    name: 'Embedded Report',
    path: '/embedded-report',
    requiresFeature: 'CUSTOM_DASHBOARDS',
};

const embeddedReportRoute: RoutingItem = {
    ...EMBEDDED_REPORT,
    component: EmbeddedReport,
};

export default embeddedReportRoute;
