import { ACTION_STATS_REPORTS } from './actionStatsReport.navigation';
import ActionStatsReportList from './components/ActionStatsReportList';

const actionStatsReportRoutes = [
    {
        ...ACTION_STATS_REPORTS,
        component: ActionStatsReportList,
    },
];

export default actionStatsReportRoutes;
