import { dataUrlToBase64 } from 'platform/common/utils/url.util';
import { base64ToDataUrl } from 'platform/creatives/mappers/creative.mapper';
import {
    RuntimeFields,
    RuntimeFormFields,
    toRuntimeApiFields,
    toRuntimeFormFields,
} from 'platform/creatives/mappers/creative/runtimesCreative.mapper';
import { isImage } from 'platform/creatives/utils/asset.utils';
import { HOSTED_TYPES } from '../../constants/creativeTypes';
import {
    CommonCreativeFields,
    CommonCreativeModelFields,
    toApiCommonFields,
    toFormModelCommonFields,
} from './commonCreative.mapper';

export type GcmHostedCreative = CommonCreativeFields & {
    type: 'GCM_HOSTED';
    name: string;
    width: number;
    height: number;
    weight?: number;
    content: string;
    fileType?: string;
    mimeType?: string;
    backupMimeType?: string;
    backupContent?: string;
    clickThroughUrl: string;
    clickThroughUrlSuffix?: string;
} & RuntimeFields;

export type GcmHostedCreativeModel = CommonCreativeModelFields & {
    type: 'GCM_HOSTED';
    name: string;
    width: number;
    height: number;
    isNew: boolean;
    tempId?: string;
    weight?: number;
    clickThroughUrl: string;
    clickThroughUrlSuffix?: string;
    file: {
        content: string;
        type?: string;
        name?: string;
        size?: string;
    };
    backupFile?: {
        content: string;
        type?: string;
        name?: string;
        size?: string;
    };
} & RuntimeFormFields;

export const toFormModelGcmHostedCreative = (
    creative: GcmHostedCreative,
    timezone: string
): GcmHostedCreativeModel => ({
    ...toFormModelCommonFields(creative),
    type: creative.type,
    width: creative.width,
    height: creative.height,
    isNew: false,
    id: creative.id,
    clickThroughUrl: creative.clickThroughUrl,
    clickThroughUrlSuffix: creative.clickThroughUrlSuffix,
    name: creative.name,
    weight: creative.weight,
    file: {
        type: creative.mimeType,
        content: base64ToDataUrl(creative.content),
    },
    backupFile: creative.backupContent
        ? {
              type: creative.backupMimeType,
              content: base64ToDataUrl(creative.backupContent),
          }
        : undefined,
    ...toRuntimeFormFields(creative, timezone),
});

export const toApiGcmHostedCreative = (
    creative: GcmHostedCreativeModel,
    timezone: string
): GcmHostedCreative => ({
    ...toApiCommonFields(creative),
    type: creative.type,
    width: creative.width,
    height: creative.height,
    id: creative.id,
    name: creative.name,
    weight: creative.weight,
    clickThroughUrl: creative.clickThroughUrl,
    clickThroughUrlSuffix: creative.clickThroughUrlSuffix || undefined,
    content: dataUrlToBase64(creative.file.content),
    fileType: isImage(creative.file) ? HOSTED_TYPES.IMAGE : HOSTED_TYPES.HTML5,
    backupContent: creative.backupFile?.content ? dataUrlToBase64(creative.backupFile.content) : undefined,
    ...toRuntimeApiFields(creative, timezone),
});
