import { ReactNode, useState } from 'react';
import { components } from 'react-select';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { sortBy } from 'lodash-es';
import { ColumnDefinitionOption } from 'platform/analytics/analytics.types';
import { useMetricsWithData } from 'platform/analytics/hooks/useMetricsWithData';
import { filterOptionAdvanced } from 'platform/analytics/reportComponents/listFilter';
import SelectTree from 'platform/common/components/SelectTree/SelectTree';
import SelectTreeOptionWithTooltip from 'platform/common/components/SelectTree/SelectTreeOptionWithTooltip';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { OptionType } from '../Select/select.types';

interface Props {
    value: string;
    options: ColumnDefinitionOption[];
    renderTooltip?: () => ReactNode;
    onChange: (value: string) => void;
    loading?: boolean;
    onClick?: () => void;
}

const InlineSelectTree = ({ value, options, loading, renderTooltip, onChange, onClick }: Props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { showMetricsWithData, setShowOnlyMetricsWithData } = useMetricsWithData();

    const handleChange = (val: string) => {
        setIsDropdownOpen(false);
        onChange(val);
    };

    return (
        <Dropdown
            isOpen={isDropdownOpen}
            toggle={() => setIsDropdownOpen(!isDropdownOpen)}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick?.();
            }}
        >
            <Tooltip tooltipVisible={!!renderTooltip} renderTooltip={renderTooltip ?? (() => null)}>
                <DropdownToggle tag="span" className="cursor-pointer">
                    <components.DownChevron />
                </DropdownToggle>
            </Tooltip>
            <DropdownMenu container={document.body}>
                <SelectTree
                    value={value}
                    options={options.map((option) => ({
                        ...option,
                        nodes: sortBy<OptionType>(option.nodes, (node) => node.name.toLowerCase()),
                    }))}
                    placeholder="Search in options"
                    components={{
                        Option: SelectTreeOptionWithTooltip,
                        IndicatorSeparator: null,
                        DropdownIndicator: null,
                    }}
                    isRootNodeSelectable={!options.some((option) => option.nodes?.length)}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    canSelectAll={false}
                    isMulti={false}
                    isClearable={false}
                    isLoading={loading}
                    menuIsOpen
                    autoFocus
                    filterOption={(option: ColumnDefinitionOption, input) =>
                        filterOptionAdvanced(option.key, option.name, input) &&
                        showMetricsWithData(option.key)
                    }
                    onHasDataFilterChange={setShowOnlyMetricsWithData}
                    getOptionParentLabel={(option: ColumnDefinitionOption) => option.name}
                    getOptionLabel={(option: ColumnDefinitionOption) => option.name}
                    getOptionValue={(option: ColumnDefinitionOption) => option.key}
                    onChange={handleChange}
                />
            </DropdownMenu>
        </Dropdown>
    );
};

export default InlineSelectTree;
