import classNames from 'classnames';
import { publicImageUrl } from 'platform/common/utils/environment.util';

interface Props {
    iconUrl?: string;
    className?: string;
    placeholderIcon?: string;
}

const ChatAgentIcon = ({ iconUrl, className, placeholderIcon }: Props) => {
    if (iconUrl) {
        return (
            <img
                width={36}
                height={36}
                className={className}
                src={publicImageUrl(iconUrl)}
                alt="Agent icon"
            />
        );
    }
    if (placeholderIcon) return <i className={classNames('Placeholder-icon fa-solid', placeholderIcon)} />;
    return <i className={classNames('fa-solid fa-circle-question', className)} />;
};

export default ChatAgentIcon;
