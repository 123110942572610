import { useSelector } from 'react-redux';
import { useAdvertiserContext } from 'platform/app/hooks/useAdvertiserContext';
import { useChatAgentContext } from 'platform/chat/ChatProviders/ChatAgentProvider';
import ActiveAdvertiserAppHeader from 'platform/common/containers/ActiveAdvertiser/ActiveAdvertiserAppHeader';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import ChatMessage from '../ChatMessage';
import './ChatAdvertiserSelectMessage.scss';

interface Props {
    containerClassName?: string;
}

const ChatAdvertiserSelectMessage = ({ containerClassName }: Props) => {
    const { selectedAgent } = useChatAgentContext();
    const { changeAdvertiser } = useAdvertiserContext();

    const advertiserOptions = useSelector(activeAdvertiserSelectors.options);

    const filteredAdvertiserOptions = selectedAgent?.advertiserIds?.length
        ? advertiserOptions.filter((op) => selectedAgent?.advertiserIds?.includes(op.id))
        : undefined;

    return (
        <div className="ChatAdvertiserSelectMessage">
            <ChatMessage
                msgContainerClassName="flex-grow-0 mx-2"
                containerClassName={containerClassName}
                iconUrl={selectedAgent?.iconUrl}
                contextPrompts={[]}
                messageData={{
                    role: 'assistant',
                    contentParts: [{ content: 'Please select advertiser:', type: 'TEXT' }],
                }}
            >
                <div className="ChatAdvertiserSelectMessage-activeAdvertiser">
                    <ActiveAdvertiserAppHeader
                        menuProps={{
                            container: 'body',
                            className: 'ChatAdvertiserSelectMessage-activeAdvertiserMenu',
                        }}
                        optionsOverride={filteredAdvertiserOptions}
                        dropdownProps={{ direction: 'up' }}
                        onAdvertisersChange={changeAdvertiser}
                    />
                </div>
            </ChatMessage>
        </div>
    );
};

export default ChatAdvertiserSelectMessage;
