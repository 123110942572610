import { useLocation, useParams } from 'react-router';
import {
    campaignClassificationsToApi,
    campaignClassificationsToForm,
} from 'platform/campaign/campaign/campaignClassification.mapper';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { randomHash } from 'platform/common/utils/number.util';
import { parseQuery } from 'platform/common/utils/url.util';
import CustomMetricForm from './CustomMetricForm';
import { getCustomMetric, storeCustomMetric } from './customMetric.service';
import { CUSTOM_METRIC_OPTIONS, CustomMetric, CustomMetricFormModel } from './customMetric.types';

type Props = {
    canEdit: boolean;
    redirectTo: string;
    afterSubmit: () => void;
};

const initialData = (key: string): CustomMetric => ({
    key,
    name: CUSTOM_METRIC_OPTIONS.find((o) => o.value === key)?.label ?? '',
    format: 'FLOAT',
    rules: [{ expression: '' }],
});

const CustomMetricFormContainer = ({ canEdit, redirectTo, afterSubmit }: Props) => {
    const location = useLocation();
    const { isNew, advertiserId } = parseQuery(location.search);
    const { key } = useParams<{ key: string }>();
    const handleOpen = async (): Promise<CustomMetricFormModel> => {
        const data = await (isNew ? initialData(key!) : getCustomMetric(advertiserId, key!));
        return {
            ...data,
            rules: data.rules.map(({ condition, expression }) => ({
                key: randomHash(),
                conditions: campaignClassificationsToForm(condition),
                expression,
            })),
        };
    };

    const handleSubmit = (data: CustomMetricFormModel) =>
        storeCustomMetric(advertiserId, {
            ...data,
            rules: data.rules.map(({ conditions, expression }) => ({
                condition: campaignClassificationsToApi(conditions),
                expression,
            })),
        });

    return (
        <FormContainer
            helpKey="custom_metric_form"
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            canEdit={canEdit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            sidePanel
            size={SIDE_PANEL_SIZES.WIDE}
            isEdit
            formikConfig={{
                validateOnChange: false,
                validateOnBlur: true,
            }}
        >
            {(props) => <CustomMetricForm {...props} advertiserId={advertiserId} />}
        </FormContainer>
    );
};

export default CustomMetricFormContainer;
