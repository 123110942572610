import FormRow from 'platform/common/components/FormRow/FormRow';
import { makeOptions } from 'platform/common/utils/option.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { OBSERVER_REPORT_FORMAT_NAMES } from '../observer.constants';

const ObserverFormFtpFields = () => (
    <>
        <FormRow label="Port">
            <FormInput name="ftpPort" type="number" validate={required} />
        </FormRow>
        <FormRow label="Hostname">
            <FormInput name="ftpHostname" type="text" validate={required} />
        </FormRow>
        <FormRow label="Username">
            <FormInput name="ftpUsername" type="text" validate={required} />
        </FormRow>
        <FormRow label="Password">
            <FormInput name="ftpPassword" type="password" validate={required} />
        </FormRow>
        <FormRow label="Path">
            <FormInput name="ftpPath" type="text" />
        </FormRow>
        <FormRow label="Enable ssl">
            <FormSwitch name="ftpEnableSsl" />
        </FormRow>
        <FormRow label="File output format">
            <FormSelect
                name="fileFormat"
                options={makeOptions(OBSERVER_REPORT_FORMAT_NAMES)}
                validate={required}
                isClearable={false}
            />
        </FormRow>
    </>
);

export default ObserverFormFtpFields;
