import { MouseEvent } from 'react';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { TablePreset, TableState } from 'platform/analytics/analytics.types';
import CopyButton from 'platform/common/components/CopyButton/CopyButton';
import SortableItem from 'platform/common/components/SortableItem/SortableItem';
import { arrayItemMove } from 'platform/common/utils/array.util';

interface Props {
    componentState: TableState;
    onChange: (state: TableState) => void;
}

const ReportTableTabs = ({ componentState, onChange }: Props) => {
    const { presets, activePresetIndex } = componentState;

    const handleRemove = (preset: TablePreset, index: number, event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onChange({
            ...componentState,
            presets: presets.filter((p) => p !== preset),
            activePresetIndex: presets[index + 1] ? index : index - 1,
        });
    };

    const handleCopy = (preset: TablePreset) => {
        onChange({
            ...componentState,
            presets: [
                ...presets,
                {
                    ...preset,
                    name: `${preset.name} (copy)`,
                },
            ],
        });
    };

    const handleAdd = () => {
        onChange({
            ...componentState,
            presets: [
                ...presets,
                {
                    name: 'New',
                    dimensions: [],
                    editableDimensions: [],
                    metrics: [],
                    hideEmptyColumns: false,
                    templateId: 'all_columns',
                },
            ],
            activePresetIndex: presets.length,
        });
    };

    const handleMove = (from: TablePreset, to: TablePreset) => {
        onChange({
            ...componentState,
            presets: arrayItemMove(presets, presets.indexOf(from), presets.indexOf(to)),
        });
    };

    return (
        <div className="custom-tabs">
            <Nav tabs>
                {presets.map((preset, index) => (
                    <SortableItem key={index} item={preset} sortId="tableTabs" onDrop={handleMove}>
                        <NavItem>
                            <NavLink
                                className={classNames('ps-2', {
                                    active: activePresetIndex === index,
                                })}
                                onClick={() => onChange({ ...componentState, activePresetIndex: index })}
                            >
                                <div className="d-flex align-items-center">
                                    <i className="fal fa-grip-vertical me-1 SortableItem-dragIcon" />
                                    {preset.name || `Preset ${index}`}
                                    <CopyButton
                                        className="ms-5 me-1"
                                        title="Copy preset"
                                        modalProps={{ zIndex: 1070 }}
                                        onCopy={() => handleCopy(preset)}
                                    />
                                    {presets.length > 1 && (
                                        <Button
                                            color="link"
                                            className="text-muted py-0 px-0 border-0"
                                            onClick={(e) => handleRemove(preset, index, e)}
                                        >
                                            <i className="fa fa-times" />
                                        </Button>
                                    )}
                                </div>
                            </NavLink>
                        </NavItem>
                    </SortableItem>
                ))}
                <NavItem key="Add">
                    <NavLink className="px-2" onClick={handleAdd}>
                        <i className="fal fa-plus" />
                    </NavLink>
                </NavItem>
            </Nav>
        </div>
    );
};

export default ReportTableTabs;
