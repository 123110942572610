import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import IconButton from 'platform/common/components/IconButton/IconButton';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';

interface Props {
    className?: string;
    notifiedUserIds: number[] | undefined;
    onChange: (watch: boolean) => void;
}

const KanbanNotificationIconButton = ({ className, notifiedUserIds, onChange }: Props) => {
    const profile = useSelector(authSelectors.ready.profile);
    const isCurrentUserNotified = notifiedUserIds?.includes(profile.id);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        onChange(!isCurrentUserNotified);
    };

    return (
        <div
            className={classNames('d-flex align-items-center justify-content-center', className, {
                disabled: !isCurrentUserNotified,
            })}
        >
            <Tooltip
                renderTooltip={() => (
                    <div className="text-muted">
                        Notifications <b>{isCurrentUserNotified ? 'enabled' : 'muted'}</b> - you
                        {isCurrentUserNotified ? ' may' : ' will not'} receive emails for this task
                    </div>
                )}
            >
                <IconButton onClick={handleClick}>
                    <i className={`fa ${isCurrentUserNotified ? 'fa-bell' : 'fa-bell-slash'}`} />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default KanbanNotificationIconButton;
