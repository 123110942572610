import axiosBff from 'platform/axios/axiosBff';
import { IdResponse, Optional } from 'platform/common/common.types';
import { Status } from 'platform/common/constants/status.constant';
import { namesToOptions } from 'platform/common/utils/option.util';
import { Category, CategoryRootCode } from './category.types';

export const fetchCategoryTrees = (params: { rootCode?: CategoryRootCode[]; status?: Status[] }) =>
    axiosBff.get<Category[]>('/admin/api/categories/trees', { params }).then((response) => response.data);

export const fetchCategory = (id: number) =>
    axiosBff.get<Category>(`/admin/api/categories/${id}`).then((response) => response.data);

export const flattenCategories = (categories: Category[]): Category[] => [
    ...categories,
    ...categories.flatMap((n) => (n.children?.length ? flattenCategories(n.children) : [])),
];

export const getCountryOptions = async () => {
    const tree = await fetchCategoryTrees({ rootCode: ['CTR'] });
    const countries = flattenCategories(tree[0]?.children ?? []);
    return namesToOptions(countries);
};

export const createCategory = (model: Optional<Category, 'id'>) =>
    axiosBff.post<IdResponse>('/admin/api/categories', model).then((response) => response.data);

export const updateCategory = (model: Category) =>
    axiosBff.put<void>(`/admin/api/categories/${model.id}`, model).then((response) => response.data);
