import { Button } from 'reactstrap';
import { OPT_IN_EFFECT_OPTIONS, VAT_OPTIONS } from 'platform/analytics/analytics.constants';
import { COLUMN_TYPE_LABELS, COLUMN_TYPE_OPTIONS } from 'platform/analytics/analytics.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditInput from 'platform/common/components/InlineEditInput/InlineEditInput';
import InlineEditSelect from 'platform/common/components/InlineEditSelect/InlineEditSelect';
import { INSIGHT_COLUMN_FORMATS, InsightColumnFormModel } from 'platform/insight/insight.types';
import './InsightColumns.scss';

const columnFormatOptions = INSIGHT_COLUMN_FORMATS.map((format) => ({ label: format, value: format }));

interface Props {
    columns: InsightColumnFormModel[];
    onChange: (columns: InsightColumnFormModel[]) => void;
}

const groupDerivedColumns = (columns: InsightColumnFormModel[]) =>
    columns
        .filter((c) => !c.derivedFrom)
        .map((c) => ({ ...c, derivedChildren: columns.filter((d) => d.derivedFrom === c.key) }));

const InsightColumns = ({ columns, onChange }: Props) => {
    const addColumn = () => {
        onChange([
            ...columns,
            {
                key: '',
                name: '',
                type: 'DIMENSION',
                format: 'TEXT',
            },
        ]);
    };

    const handleChange = (key: string, value: Partial<InsightColumnFormModel>) => {
        onChange(columns.map((c) => (c.key === key ? { ...c, ...value } : c)));
    };

    const handleRemove = (key: string) => {
        onChange(columns.filter((c) => c.key !== key && c.derivedFrom !== key));
    };

    const originalColumns: TableColumn<InsightColumnFormModel>[] = [
        {
            Header: 'Key',
            accessor: 'key',
            width: 180,
            Cell: ({ original: column }) => (
                <InlineEditInput
                    inputType="text"
                    value={column.key}
                    placeholder="Key"
                    onChange={(key) => handleChange(column.key, { key })}
                />
            ),
        },
        {
            Header: 'Label',
            accessor: 'name',
            width: 180,
            Cell: ({ original: column }) => (
                <InlineEditInput
                    inputType="text"
                    value={column.name}
                    placeholder="Label"
                    onChange={(name) => handleChange(column.key, { name })}
                />
            ),
        },
        {
            Header: 'Object',
            accessor: 'type',
            width: 90,
            Cell: ({ original: column }) => (
                <InlineEditSelect
                    selectStyle={{ minWidth: 90, margin: '-3px' }}
                    options={COLUMN_TYPE_OPTIONS}
                    value={column.type}
                    placeholder="Object"
                    isClearable={false}
                    onChange={(type) =>
                        handleChange(column.key, {
                            type,
                            vat: undefined,
                            optInEffect: undefined,
                            aggregateFunction:
                                type === 'METRIC'
                                    ? column.aggregateFunction || `sum(${column.key})`
                                    : undefined,
                        })
                    }
                />
            ),
        },
        {
            Header: 'Format',
            accessor: 'format',
            width: 70,
            Cell: ({ original: column }) => (
                <InlineEditSelect
                    selectStyle={{ minWidth: 70, margin: '-3px' }}
                    options={columnFormatOptions}
                    value={column.format}
                    placeholder="Format"
                    isClearable={false}
                    onChange={(format) => handleChange(column.key, { format })}
                />
            ),
        },
        {
            Header: 'VAT',
            accessor: 'vat',
            width: 60,
            Cell: ({ original: column }) =>
                column.type === 'METRIC' && (
                    <InlineEditSelect
                        selectStyle={{ minWidth: 60, margin: '-3px' }}
                        options={VAT_OPTIONS}
                        value={column.vat}
                        getLabel={(data) => <i className={`font-lg ${data.iconClass}`} />}
                        isClearable={false}
                        onChange={(vat) => handleChange(column.key, { vat })}
                    />
                ),
        },
        {
            Header: 'Opt-in',
            accessor: 'optInEffect',
            width: 60,
            Cell: ({ original: column }) =>
                column.type === 'METRIC' && (
                    <InlineEditSelect
                        selectStyle={{ minWidth: 60, margin: '-3px' }}
                        options={OPT_IN_EFFECT_OPTIONS}
                        value={column.optInEffect}
                        getLabel={(data) => <i className={`font-lg ${data.iconClass}`} />}
                        isClearable={false}
                        onChange={(optInEffect) => handleChange(column.key, { optInEffect })}
                    />
                ),
        },
        {
            Header: 'Aggregation function',
            accessor: 'aggregateFunction',
            Cell: ({ original: column }) =>
                column.type === 'METRIC' && (
                    <InlineEditInput
                        inputType="text"
                        value={column.aggregateFunction}
                        placeholder="SQL"
                        onChange={(aggregateFunction) => handleChange(column.key, { aggregateFunction })}
                    />
                ),
        },
        {
            Header: '',
            width: 50,
            sortable: false,
            className: 'cell-align-center',
            Cell: ({ original: column }) => (
                <button onClick={() => handleRemove(column.key)} type="button" className="btn-close" />
            ),
        },
    ];

    const derivedColumns: TableColumn<InsightColumnFormModel>[] = [
        {
            Header: 'Derived column',
            accessor: 'key',
            width: 180,
            depth: 1,
        },
        {
            Header: 'Label',
            accessor: 'name',
            width: 180,
            depth: 1,
        },
        {
            Header: 'Object',
            accessor: 'type',
            width: 90,
            depth: 1,
            Cell: ({ original: column }) => COLUMN_TYPE_LABELS[column.type],
        },
        {
            Header: 'Format',
            accessor: 'format',
            depth: 1,
        },
    ];

    return (
        <div className="InsightColumns">
            <FormattedTable
                containerClass="overFlowVisible"
                data={groupDerivedColumns(columns)}
                columns={[...originalColumns, ...derivedColumns]}
                stickyHeader={false}
                sortable={false}
                isTree
                nodesExpanded
                childrenLoaders={[{ field: 'derivedChildren' }]}
                childrenOffset={0}
                NoDataComponent={() => (
                    <div className="p-3 d-flex align-items-center justify-content-center">No columns</div>
                )}
            />
            <Button className="ms-auto mt-3" onClick={addColumn}>
                Add column
            </Button>
        </div>
    );
};

export default InsightColumns;
