import Popover from 'platform/common/components/Popover/Popover';
import './SupportedMacrosPopover.scss';

type Props = {
    list: { key: string; label: string }[];
};

const SupportedMacrosPopover = ({ list }: Props) => (
    <Popover
        className="SupportedMacrosPopover"
        placement="left"
        preventOverflow
        renderPopover={() => (
            <div className="py-2 px-3 font-md">
                {list.map(({ key, label }) => (
                    <div key={key}>
                        <code>{key}</code> - {label}
                    </div>
                ))}
            </div>
        )}
    >
        Supported macros
    </Popover>
);

export default SupportedMacrosPopover;
