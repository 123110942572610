import IconButton from 'platform/common/components/IconButton/IconButton';

interface Props {
    onClick?: () => void;
}

const KanbanToggleButton = ({ onClick }: Props) => (
    <IconButton onClick={onClick}>
        <i className="Kanban-add-icon fal fa-plus" />
    </IconButton>
);

export default KanbanToggleButton;
