import { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    icon: ReactNode;
    children?: ReactNode;
    className?: string;
}

const ChatTitleMessage = ({ icon, children, className }: Props) => (
    <div
        style={{ width: 'fit-content' }}
        className={classNames('d-flex align-items-center p-2 rounded', className)}
    >
        {icon}
        <strong>{children}</strong>
    </div>
);

export default ChatTitleMessage;
