import { Store } from 'redux';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { mapValues } from 'lodash-es';
import { AuthState, authActions, tokenExpired } from 'platform/app/ducks/auth.duck';
import { Action } from 'platform/common/common.types';
import { ErrorDetails } from 'platform/common/error.types';
import { parseErrorMessages } from 'platform/common/utils/errorMessages';
import { retryDelay, isTransientError } from 'platform/common/utils/http.util';
import { toastError } from 'platform/common/utils/toast.util';
import { generateTraceHeaders } from 'platform/common/utils/trace.util';
import { stringifyParams, toQueryValue } from 'platform/common/utils/url.util';

export const API_PATH = '/bff';

const axiosBff = axios.create({
    paramsSerializer: (params) =>
        stringifyParams(
            mapValues(params, (values) => toQueryValue(values, { strictEmptyArrayHandling: true }))
        ),
    baseURL: API_PATH,
});

axiosRetry(axiosBff, {
    retryCondition: ({ config, response, code, message }) => {
        const willRetry = code !== 'ECONNABORTED' && (!response || isTransientError(response.status));
        if (willRetry) {
            // eslint-disable-next-line no-console
            console.warn(`Will retry request to ${config?.url} because "${message}"...`);
        }
        return willRetry;
    },
    retries: 4, // give up after fifth failed request
    retryDelay,
});

let previousAccessToken: string | undefined;

const apolloErrorToErrorDetails = ({ config, response }: AxiosError): ErrorDetails => ({
    message: parseErrorMessages(response?.data)
        .map((e) => (e.field ? `${e.field} ${e.message}` : e.message))
        .join('\n'),
    traceId: config?.headers?.['X-B3-TraceId'] as string | undefined,
    request: {
        url: config?.url ?? '',
        method: config?.method ?? '',
        body: config?.data,
    },
    response: {
        status: response?.status,
        body: String(response?.data),
    },
});

export const setAxiosAccessToken = <T extends { session: AuthState }>(store: Store<T, Action>) => {
    const setAxiosAuthorizationToken = () => {
        const authState = store.getState().session;
        const accessToken = 'token' in authState ? authState.token : undefined;
        if (accessToken && previousAccessToken !== accessToken) {
            previousAccessToken = accessToken;
            axiosBff.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        }
    };

    setAxiosAuthorizationToken();
    store.subscribe(setAxiosAuthorizationToken);

    axiosBff.interceptors.response.use(
        (response) => response,
        (error: AxiosError) => {
            const { config } = error;
            const errorDetails = apolloErrorToErrorDetails(error);
            const authState = store.getState().session;
            const tokenExpires = 'tokenExpires' in authState ? authState.tokenExpires : undefined;
            const expired = tokenExpired(tokenExpires);
            const logout = authState.type === 'READY' && expired;
            if (logout) {
                store.dispatch(authActions.logout());
                return Promise.reject(errorDetails);
            }

            if (!expired && !config?.errorHandledByComponent) {
                toastError(errorDetails, config?.pageUrl);
            }

            return Promise.reject(errorDetails);
        }
    );
};

axiosBff.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.headers) {
        Object.assign(config.headers, generateTraceHeaders());
    }

    return {
        ...config,
        pageUrl: window.location.href,
    };
});

export default axiosBff;
