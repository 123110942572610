const WikiNoContent = () => (
    <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center gap-2">
            <i className="fa-regular fa-empty-set fs-2 text-light-slate-gray" />
            <h2 className="m-0">This wiki page is currently empty</h2>
        </div>
    </div>
);

export default WikiNoContent;
