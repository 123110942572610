import {
    ObserverExecutionStatus,
    ObserverMessageCategory,
    ObserverMessagePriority,
    ObserverMessageType,
    ObserverReportDestination,
    ObserverReportFileFormat,
    ObserverReportSource,
    ObserverReportType,
} from './observer.types';

export const OBSERVER_REPORT_TYPE_NAMES: Record<ObserverReportType, string> = {
    ALERT: 'Alert',
    SCHEDULED: 'Scheduled',
    ON_DEMAND: 'On-demand',
};

export const OBSERVER_REPORT_FORMAT_NAMES: Record<ObserverReportFileFormat, string> = {
    CSV: 'CSV',
    EXCEL: 'Excel',
    JSON: 'JSON',
};

export const OBSERVER_REPORT_QUERY_SOURCE_NAMES: Record<ObserverReportSource, string> = {
    BIG_QUERY: 'BigQuery',
    POSTGRES: 'PostgreSQL',
};

export const OBSERVER_EXECUTION_STATUS_NAMES: Record<ObserverExecutionStatus, string> = {
    SUCCESS: 'Success',
    IN_PROGRESS: 'In Progress',
    FAILED: 'Failed',
};

export const OBSERVER_EXECUTION_STATUS_COLORS: Record<ObserverExecutionStatus, string> = {
    SUCCESS: 'success',
    IN_PROGRESS: 'secondary',
    FAILED: 'danger',
};

export const OBSERVER_REPORT_DESTINATION_NAMES: Record<ObserverReportDestination, string> = {
    EMAIL: 'Email',
    FTP: 'Ftp',
    MESSAGE_CENTER: 'DeeplyAI Cockpit',
};

export const OBSERVER_REPORT_MESSAGE_PRIORITIES: Record<ObserverMessagePriority, string> = {
    LOW: 'Low',
    MID: 'Mid',
    HIGH: 'High',
};

export const OBSERVER_REPORT_MESSAGE_CATEGORIES: Record<ObserverMessageCategory, string> = {
    BIDDING: 'Bidding',
    BUDGET: 'Budget',
    TRACKING: 'Tracking',
};

export const OBSERVER_REPORT_MESSAGE_TYPES: Record<ObserverMessageType, string> = {
    WARNING: 'Warning',
    INFO: 'Info',
    SUGGESTION: 'Suggestions',
};

export const INITIAL_CRON_EXPRESSION = '0 0/1 * 1/1 * ? *';
