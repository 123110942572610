import { Input } from 'reactstrap';
import IconButton from '../IconButton/IconButton';
import Select from '../Select/Select';
import './Pagination.scss';

const getPageOptions = (pageSizeOptions: number[]) =>
    pageSizeOptions.map((size) => ({
        value: size,
        label: `${size} ${size === 1 ? 'row' : 'rows'}`,
    }));

interface Props {
    page: number;
    pages: number;
    pageSize: number;
    pageSizeOptions: number[];
    onPageChange: (value: number) => void;
    onPageSizeChange: (value: number) => void;
}

const Pagination = ({ page, pages, pageSize, pageSizeOptions, onPageChange, onPageSizeChange }: Props) => {
    if (page === undefined) return null;

    return (
        <div className="Pagination">
            <IconButton
                className="Pagination-button me-2"
                disabled={page === 0}
                onClick={() => onPageChange(page - 1)}
            >
                <i className="fa fa-chevron-left" />
            </IconButton>

            <div className="d-flex align-items-center me-2">
                <span className="me-2">Page</span>
                <Input
                    className="ps-3 pe-0"
                    type="number"
                    value={page + 1}
                    max={pages}
                    min={1}
                    onChange={(e) => onPageChange(Number(e.target.value) - 1)}
                />
                <span className="ms-2">of</span>
                <span className="ms-2">{pages}</span>
            </div>

            {pageSizeOptions?.length > 1 && (
                <Select
                    menuPosition="fixed"
                    isSearchable={false}
                    isClearable={false}
                    value={pageSize}
                    onChange={(value) => onPageSizeChange(value)}
                    options={getPageOptions(pageSizeOptions)}
                />
            )}

            <IconButton
                disabled={page + 1 === pages}
                className="Pagination-button ms-2"
                onClick={() => onPageChange(page + 1)}
            >
                <i className="fa fa-chevron-right" />
            </IconButton>
        </div>
    );
};

export default Pagination;
