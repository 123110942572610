import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { uniq } from 'lodash-es';
import { fetchContactInformation } from 'platform/advertisers/services/advertiser.service';
import { isDefined } from 'platform/common/common.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import ListFilters from 'platform/common/components/ListFilters/ListFilters';
import SearchNotification from 'platform/common/components/SearchNotification/SearchNotification';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByName } from 'platform/common/utils/array.util';
import { entitiesToOptions } from 'platform/common/utils/option.util';
import WorkflowStepColumnCheckbox from 'platform/mediaplan/components/MediaplanWorkflow/WorkflowTable/WorkflowStepColumnCheckbox';
import {
    MEDIA_INSERTION_STEPS,
    MediaInsertion,
    MediaInsertionStep,
    MediaInsertionStepType,
    WorkflowEvent,
    WorkflowLaneModel,
} from 'platform/mediaplan/mediaplan.types';
import { fetchUsers } from 'platform/userManagement/services/userManagement.service';
import VendorLabel from 'platform/vendors/components/VendorLabel';
import WorkflowStepControls from '../common/WorkflowStepControls/WorkflowStepControls';
import LaneAssignee from './LaneAssignee';
import '../MediaplanWorkflow.scss';

interface Props {
    lanes: WorkflowLaneModel[];
    workflowEvents: WorkflowEvent[];
    canEdit: boolean;
    onAssigneeChange: (mediaInsertionId: number, vendor: string, assignee?: string) => void;
    onBatchChange: (steps: MediaInsertionStep[]) => void;
    onChange: (step: MediaInsertionStep, event?: WorkflowEvent) => void;
}

const isVisible = ({ workflowType }: MediaInsertion, simplified?: boolean) =>
    simplified || workflowType === 'IO';

const inProgress = (lane: WorkflowLaneModel) =>
    Object.keys(MEDIA_INSERTION_STEPS).some((type) => lane.steps[type]?.completed) && !completed(lane);

const completed = ({ mediaInsertion, steps }: WorkflowLaneModel) =>
    Object.entries(MEDIA_INSERTION_STEPS).every(
        ([type, { simplified }]) => steps[type]?.completed || !isVisible(mediaInsertion, simplified)
    );

const getMediaUsers = async (advertiserId: number) => {
    const [users, contactInfo] = await Promise.all([
        fetchUsers({ states: ['ACTIVE'], seatId: 100 }),
        fetchContactInformation(advertiserId),
    ]);

    if (!contactInfo?.media.length) {
        return [];
    }

    return users.filter((user) => contactInfo.media.includes(user.id));
};

const WorkflowTable = ({
    lanes,
    workflowEvents,
    canEdit,
    onAssigneeChange,
    onBatchChange,
    onChange,
}: Props) => {
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const vendors = useSelector(classifierSelectors.vendors);
    const [selectedMediaInsertions, setSelectedMediaInsertions] = useState<number[]>([]);
    const [selectedVendors, setSelectedVendors] = useState<string[]>([]);

    const uniqueMediaInsertions = sortByName(uniq(lanes.map((l) => l.mediaInsertion)));
    const uniqueVendors = sortByName(
        uniq(lanes.map((l) => vendors.find((v) => v.externalSystem === l.vendorSystem)).filter(isDefined))
    );

    const [{ data: users, loading: usersLoading }] = usePromise([], () => getMediaUsers(advertiserId), [
        advertiserId,
    ]);

    const filteredLanes = lanes.filter(
        (l) =>
            (!selectedMediaInsertions.length || selectedMediaInsertions.includes(l.mediaInsertion.id!)) &&
            (!selectedVendors.length || selectedVendors.includes(l.vendorSystem))
    );

    const columns: TableColumn<WorkflowLaneModel>[] = [
        {
            Header: 'Media insertion',
            minWidth: 250,
            sticky: true,
            accessor: (lane) => lane.mediaInsertion.name,
            Cell: ({ original: lane }) => (
                <div
                    className={classNames('MediaplanWorkflow-insertionCell text-truncate', {
                        'MediaplanWorkflow-inProgress': inProgress(lane),
                        'MediaplanWorkflow-completed': completed(lane),
                    })}
                    title={lane.mediaInsertion.name}
                >
                    {lane.mediaInsertion.name}
                </div>
            ),
        },
        {
            Header: 'Vendor',
            minWidth: 120,
            sticky: true,
            accessor: (lane) => lane.vendorSystem,
            Cell: ({ original: lane }) => (
                <div className="MediaplanWorkflow-vendorCell text-truncate">
                    <VendorLabel vendorCode={lane.vendorSystem} />
                </div>
            ),
        },
        {
            Header: 'Media assignee',
            minWidth: 160,
            accessor: (lane) => lane.assignee,
            Cell: ({ original: { assignee, mediaInsertion, vendorSystem } }) => (
                <LaneAssignee
                    assignee={assignee}
                    users={users}
                    loading={usersLoading}
                    onChange={(value) => onAssigneeChange(mediaInsertion.id!, vendorSystem, value)}
                />
            ),
        },
        ...Object.entries(MEDIA_INSERTION_STEPS).map<TableColumn<WorkflowLaneModel>>(
            ([type, { label, simplified }]) => ({
                Header: () => {
                    const affectedLanes = filteredLanes.filter((l) =>
                        isVisible(l.mediaInsertion, simplified)
                    );
                    return (
                        <>
                            <span>{label}</span>
                            {(selectedMediaInsertions.length > 0 || selectedVendors.length > 0) &&
                                affectedLanes.length > 0 && (
                                    <span className="WorkflowTable-markAll">
                                        <WorkflowStepColumnCheckbox
                                            stepType={type as MediaInsertionStepType}
                                            affectedLanes={affectedLanes}
                                            onBatchChange={onBatchChange}
                                        />
                                        Mark all
                                    </span>
                                )}
                        </>
                    );
                },
                headerClassName: 'WorkflowTable-stepHeader',
                width: 90,
                sortable: false,
                Cell: ({ original: { mediaInsertion, vendorSystem, steps } }) => {
                    if (!isVisible(mediaInsertion, simplified)) {
                        return null;
                    }

                    return (
                        <WorkflowStepControls
                            step={steps[type] ?? { type, completed: false }}
                            stepEvents={workflowEvents.filter(
                                (e) =>
                                    e.mediaInsertionId === mediaInsertion.id &&
                                    e.vendorSystem === vendorSystem &&
                                    e.stepType === type
                            )}
                            canEdit={canEdit}
                            onChange={(step, event) =>
                                onChange(
                                    { ...step, mediaInsertionId: mediaInsertion.id!, vendorSystem },
                                    event
                                        ? { ...event, mediaInsertionId: mediaInsertion.id!, vendorSystem }
                                        : undefined
                                )
                            }
                        />
                    );
                },
            })
        ),
    ];

    const topToolbar = (
        <ListFilters>
            <SelectWithAddon
                name="Media insertion"
                value={selectedMediaInsertions}
                options={entitiesToOptions(uniqueMediaInsertions)}
                isMulti
                onChange={setSelectedMediaInsertions}
            />
            <SelectWithAddon
                name="Vendor"
                value={selectedVendors}
                options={uniqueVendors.map((v) => ({ value: v.externalSystem, label: v.name }))}
                isMulti
                onChange={setSelectedVendors}
            />
        </ListFilters>
    );

    return (
        <FormattedTable
            className="WorkflowTable"
            columns={columns}
            data={filteredLanes}
            topToolbar={topToolbar}
            NoDataComponent={() =>
                !lanes.length ? (
                    <SearchNotification header={null} text="No media insertions or they have no vendors." />
                ) : null
            }
            defaultSorted={[
                { orderBy: 'Media insertion', direction: 'ASC' },
                { orderBy: 'Vendor', direction: 'ASC' },
            ]}
        />
    );
};

export default WorkflowTable;
