import FormRow from 'platform/common/components/FormRow/FormRow';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import { FieldType } from './ChatAssistantQuestionsMessage';

interface Props {
    index: number;
    label: string;
    fieldType: FieldType;
}

const ChatAssistantQuestionField = ({ index, label, fieldType }: Props) => {
    const fieldName = `questions.${index}`;

    return (
        <FormRow
            label={label}
            contentWidth={12}
            labelWidth={12}
            labelClassName="text-start"
            rowClassName="mx-0"
        >
            <FormInput
                validate={required}
                name={fieldName}
                type={fieldType === 'TEXTAREA' ? 'textarea' : undefined}
            />
        </FormRow>
    );
};

export default ChatAssistantQuestionField;
