import {
    AnalyticsCompareType,
    AnalyticsMode,
    analyticsModes,
    DatePickerMode,
    datePickerModes,
    OptInEffect,
    VatSetting,
} from 'platform/analytics/analytics.types';
import { SelectItem } from 'platform/common/common.types';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'platform/common/components/FormattedTable/FormattedTable';

export const TIME_DIMENSIONS = ['week', 'date', 'month', 'year', 'weekday'];

export const COMPARE_OPTIONS: SelectItem<AnalyticsCompareType>[] = [
    { value: 'PREVIOUS_PERIOD', label: 'Previous period' },
    { value: 'PREVIOUS_MONTH', label: 'Previous month' },
    { value: 'PREVIOUS_YEAR', label: 'Previous year' },
    { value: 'PREVIOUS_ROW', label: 'Previous row (tables)' },
    { value: 'CUSTOM_PERIOD', label: 'Custom period' },
    { value: 'AVERAGE', label: 'Average' },
];

const datePickerModeLabels: Record<DatePickerMode, string> = {
    DEFAULT: 'Default (YYYY-MM-DD to YYYY-MM-DD)',
    CALENDAR_WEEK: 'Calendar week (YYYY-CW)',
    CALENDAR_WEEK_RANGE: 'Calendar week (YYYY-CW to YYYY-CW)',
    MONTH: 'Month (YYYY-MM)',
    MONTH_RANGE: 'Month (YYYY-MM to YYYY-MM)',
    YEAR: 'Year (YYYY)',
};

const modeLabels: Record<AnalyticsMode, string> = {
    DEFAULT: 'Default',
    CAMPAIGN: 'Campaign',
    BUDGET: 'Budget',
    INSIGHT: 'Insight',
    INTERACTIVE: 'Interactive',
};

export const CHART_PREVIEW_HEIGHT = window.innerHeight - 300;
export const ANALYTICS_TABLE_PAGE_SIZE_OPTIONS = [5, ...DEFAULT_PAGE_SIZE_OPTIONS];

export const datePickerModeOptions = datePickerModes.map((value) => ({
    value,
    label: datePickerModeLabels[value],
}));

export const analyticsModeOptions = analyticsModes.map((value) => ({
    value,
    label: modeLabels[value],
}));

export const EDITABLE_DIMENSIONS = [
    'campaign_product_label',
    'strategy_product_label',
    'campaign_product_sub_label',
    'strategy_product_sub_label',
    'campaign_flight_label',
    'strategy_flight_label',
    'campaign_time_offer_names',
    'strategy_time_offer_names',
    'country_labels',
] as const;

export const VAT_OPTIONS: (SelectItem<VatSetting | undefined> & { iconClass: string })[] = [
    { value: undefined, label: 'None', iconClass: 'far fa-square' },
    { value: 'INCLUDE', label: 'Include', iconClass: 'fa-solid fa-arrow-trend-up' },
    { value: 'EXCLUDE', label: 'Exclude', iconClass: 'fa-solid fa-arrow-trend-down' },
];

export const OPT_IN_EFFECT_OPTIONS: (SelectItem<OptInEffect | undefined> & { iconClass: string })[] = [
    { value: undefined, label: 'None', iconClass: 'far fa-square' },
    { value: 'INCREASE', label: 'Increase', iconClass: 'fa-solid fa-arrow-trend-up' },
    { value: 'DECREASE', label: 'Decrease', iconClass: 'fa-solid fa-arrow-trend-down' },
];
