import { Modal } from 'reactstrap';
import { User } from 'platform/userManagement/types/user.type';
import { LabelNames, TaskBoardChange, TaskCreationData, TaskLane, TaskLocation } from '../task.types';
import DeeplyTaskCreateInput from './DeeplyTaskCreateInput';

interface Props {
    labelNames: LabelNames;
    lanes: TaskLane[];
    toggle: () => void;
    onTaskOpen: (id: number) => void;
    onTaskAdd: (location: TaskLocation, data: TaskCreationData) => Promise<TaskBoardChange[]>;
    userOptions: User[];
}

const DeeplyTaskCreator = ({ labelNames, lanes, userOptions, toggle, onTaskOpen, onTaskAdd }: Props) => (
    <Modal isOpen toggle={toggle}>
        <DeeplyTaskCreateInput
            onTaskAdd={onTaskAdd}
            onTaskOpen={onTaskOpen}
            labelNames={labelNames}
            userOptions={userOptions}
            lanes={lanes}
            toggle={toggle}
        />
    </Modal>
);

export default DeeplyTaskCreator;
