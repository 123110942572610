import { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { useSortable } from 'platform/common/hooks/useSortable';
import './SortableItem.scss';

interface Props<T> {
    id?: string;
    sortId: string;
    className?: string;
    item: T;
    style?: React.CSSProperties;
    children: ReactNode;
    useHandle?: boolean;
    canDrag?: boolean;
    canDrop?: boolean;
    onMouseDown?: (e: MouseEvent) => void;
    onDrop: (moveFrom: T, moveTo: T) => void;
    hasAddon?: boolean;
}

const SortableItem = <T extends any>({
    id,
    sortId,
    className,
    item,
    style,
    children,
    useHandle,
    canDrag = true,
    canDrop = true,
    onMouseDown,
    onDrop,
    hasAddon,
}: Props<T>) => {
    const { sortableRef, sortableDragRef, sortableDropRef, isOver, isDragging } = useSortable({
        id: sortId,
        canDrag,
        canDrop,
        item,
        onDrop,
    });

    const classes = classNames('SortableItem', className, {
        'SortableItem-hover': isOver && canDrop,
        'SortableItem-dragging': isDragging,
    });

    const removeTooltip = () => {
        if (hasAddon) {
            const tooltipElement = document.querySelector('.StatisticTooltip') as HTMLElement;
            if (tooltipElement) tooltipElement.remove();
        }
    };
    if (!useHandle) {
        return (
            <div
                id={id}
                style={style}
                role="presentation"
                ref={sortableRef}
                className={classes}
                onMouseDown={onMouseDown}
                onDragEnter={removeTooltip}
            >
                {children}
            </div>
        );
    }

    return (
        <div
            id={id}
            style={style}
            role="presentation"
            ref={sortableDropRef}
            className={classes}
            onMouseDown={onMouseDown}
            onDragEnter={removeTooltip}
        >
            <div className="d-flex w-100 align-items-center">
                {canDrag && (
                    <i ref={sortableDragRef} className="fal fa-grip-vertical me-3 SortableItem-dragIcon" />
                )}
                {children}
            </div>
        </div>
    );
};

export default SortableItem;
