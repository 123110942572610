import { useState } from 'react';
import { FormFeedback } from 'reactstrap';
import { useField } from 'formik';
import DynamicOptionList from 'platform/common/components/DynamicOptionList/DynamicOptionList';
import { Validator, validator } from 'platform/common/utils/validators.util';

interface Props {
    name: string;
    addOnTitle?: string;
    buttonTitle: string;
    placeholder?: string;
    validateInput?: Validator | Validator[];
    valueDecorator?: (value: string) => string;
    canRemoveOptions?: boolean;
    id?: string;
    validate?: Validator | Validator[];
    className?: string;
}

const FormDynamicOptionList = ({
    addOnTitle,
    buttonTitle,
    placeholder,
    validateInput,
    valueDecorator,
    canRemoveOptions,
    validate,
    name,
    id,
    className = '',
}: Props) => {
    const [inputError, setInputError] = useState<string | undefined>();
    const [field, meta, helpers] = useField({
        name,
        validate: validate && validator(validate),
    });
    const invalid = meta.touched && (inputError || meta.error);
    const onValuesChange = (values: string[], newValue?: string) => {
        const error =
            newValue &&
            validateInput &&
            (Array.isArray(validateInput)
                ? validateInput.map((f) => f(newValue)).find((e) => e !== undefined)
                : validateInput(newValue));
        setInputError(error);
        if (error) {
            return error;
        }
        helpers.setValue(values);
        return '';
    };

    return (
        <DynamicOptionList
            id={id}
            key="DynamicOptionList"
            inputProps={{
                onBlur: () => field.onBlur(name),
                onFocus: () => helpers.setTouched(true),
            }}
            className={invalid ? 'form-control is-invalid m-0 p-0' : className}
            values={field.value}
            addOnTitle={addOnTitle}
            buttonTitle={buttonTitle}
            placeholder={placeholder}
            onAdd={onValuesChange}
            onRemove={onValuesChange}
            valueDecorator={valueDecorator}
            canRemove={canRemoveOptions}
        >
            {invalid && <FormFeedback>{inputError || meta.error}</FormFeedback>}
        </DynamicOptionList>
    );
};

export default FormDynamicOptionList;
