import { makeOptions } from 'platform/common/utils/option.util';

export type Status = 'ACTIVE' | 'INACTIVE' | 'ARCHIVED';

export type ActiveOrInactive = 'ACTIVE' | 'INACTIVE';

export type ActiveOrArchived = 'ACTIVE' | 'ARCHIVED';

export const STATUS_LABELS: Record<Status, string> = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    ARCHIVED: 'Archived',
};

const ACTIVE_OR_INACTIVE_LABELS: Record<ActiveOrInactive, string> = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
};

export const ACTIVE_OR_ARCHIVED_LABELS: Record<ActiveOrArchived, string> = {
    ACTIVE: 'Active',
    ARCHIVED: 'Archived',
};

export const STATUS_OPTIONS = makeOptions(STATUS_LABELS);
export const ACTIVE_OR_INACTIVE = makeOptions(ACTIVE_OR_INACTIVE_LABELS);
export const ACTIVE_OR_ARCHIVED = makeOptions(ACTIVE_OR_ARCHIVED_LABELS);

export const STATUS_TRANSITIONS = Object.freeze<Record<Status, Status[]>>({
    ACTIVE: ['INACTIVE'],
    INACTIVE: ['ARCHIVED', 'ACTIVE'],
    ARCHIVED: ['INACTIVE'],
});
