import { useEffect, useState } from 'react';
import MinimizedChatPopover from 'platform/chat/MinimizedChat/MinimizedChatPopover';
import Popover from 'platform/common/components/Popover/Popover';
import { useFeature } from 'platform/common/hooks/useFeature';
import useToggle from 'platform/common/hooks/useToggle';
import { history } from 'platform/store';
import GlobalChatBtn, { CHAT_BTN_STYLE } from './GlobalChatBtn';
import './ChatSwitch.scss';

const DEFAULT_POSITION = { x: 0, y: 0 };

const ChatSwitch = () => {
    const canSeeChat = useFeature('CHATBOT_DEFAULT_VIEW');
    const [isChatOpen, toggleChat] = useToggle(false);
    const [position, setPosition] = useState(DEFAULT_POSITION);

    useEffect(() => {
        history.listen(() => setPosition(DEFAULT_POSITION));
    }, []);

    if (!canSeeChat) return null;

    return (
        <div>
            <Popover
                trigger="click"
                isOpen={isChatOpen}
                style={{
                    ...CHAT_BTN_STYLE,
                    bottom: 70,
                    left: position.x || undefined,
                    right: position.x === 0 ? CHAT_BTN_STYLE.right : undefined,
                }}
                placement="top-end"
                popoverClassName="ChatSwitch"
                renderPopover={() => <MinimizedChatPopover />}
            >
                <div
                    draggable
                    onDragEnd={(e) => {
                        setPosition({ ...position, x: e.pageX - Number(CHAT_BTN_STYLE.width ?? 0) / 2 });
                    }}
                >
                    <GlobalChatBtn onClick={toggleChat} isChatOpen={isChatOpen} positionX={position.x} />
                </div>
            </Popover>
        </div>
    );
};

export default ChatSwitch;
