import { ReactNode } from 'react';
import SortableItem from 'platform/common/components/SortableItem/SortableItem';

export type DraggableTableRowProps = {
    index: number;
    move: (from: number, to: number) => void;
    staticRow?: number;
};

export const DraggableTableRow = ({
    index,
    move,
    staticRow,
    children,
}: DraggableTableRowProps & { children: ReactNode }) => {
    if (staticRow === index) {
        return <div className="rt-tr">{children}</div>;
    }

    return (
        <SortableItem
            sortId="draggableTableRow"
            item={{ index }}
            className="rt-tr"
            useHandle
            onDrop={(moveFrom, moveTo) => move(moveFrom.index, moveTo.index)}
        >
            {children}
        </SortableItem>
    );
};
