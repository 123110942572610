import { useParams } from 'react-router-dom';
import DataIngestionConfigurationForm from 'platform/adminPanel/components/DataIngestion/DataIngestionConfigurationForm';
import {
    fetchMailJobConfiguration,
    MailJobConfiguration,
    updateMailJobConfiguration,
} from 'platform/adminPanel/components/DataIngestion/dataIngestion.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';

const DataIngestionConfigurationFormContainer = ({
    redirectTo,
    canEdit,
    afterSubmit,
}: FormContainerProps) => {
    const params = useParams<{ jobName: string }>();
    const jobName = params.jobName;

    const handleOpen = async (): Promise<MailJobConfiguration> =>
        jobName
            ? fetchMailJobConfiguration(String(jobName))
            : {
                  jobName: '',
                  dateColumn: '',
                  datePartitionToColumn: '',
                  partitionColumn: '',
                  headerLinesToSkip: 0,
                  footerLinesToSkip: 0,
                  leadingColumnsToSkip: 0,
                  trailingColumnsToSkip: 0,
                  dateFormat: '',
                  columnDelimiter: '',
                  urlRegex: '',
              };

    const handleSubmit = (model: MailJobConfiguration) =>
        updateMailJobConfiguration({
            ...model,
            jobName: jobName ? String(jobName) : model.jobName,
            dateColumn: model.dateColumn || undefined,
            datePartitionToColumn: model.datePartitionToColumn || undefined,
            partitionColumn: model.partitionColumn || undefined,
            urlRegex: model.urlRegex || undefined,
        });

    return (
        <FormContainer
            redirectTo={redirectTo}
            isEdit
            sidePanel
            helpKey="data_ingestion_configuration_form"
            onOpen={() => handleOpen()}
            canEdit={canEdit}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <DataIngestionConfigurationForm {...props} />}
        </FormContainer>
    );
};

export default DataIngestionConfigurationFormContainer;
