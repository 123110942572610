import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash-es';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { OlapReport } from 'platform/analytics/analytics.types';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import ReportTable from 'platform/analytics/reportComponents/ReportTableContainer/ReportTable';
import ChatAssistantSummary from 'platform/chat/ChatMessage/ChatAssistantMessage/ChatAssistantOlapMessage/ChatAssistantSummary';
import ChatAssistantUserFriendlyErrorMessage from 'platform/chat/ChatMessage/ChatAssistantMessage/ChatAssistantUserFriendlyErrorMessage/ChatAssistantUserFriendlyErrorMessage';
import { ChatOlapRequest } from 'platform/chat/olap/chat.olap.types';
import { mapChatOlapRequestToReportSettings } from 'platform/chat/olap/chat.olap.util';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import { typeOf } from 'platform/common/dataTypes';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import ChatOlapTableToolbar from './ChatOlapTableToolbar';
import './ChatAssistantOlapMessage.scss';

export const produceRowsForSummary = (data: OlapReport) => {
    const headerMap = keyBy(data.header, (h) => h.key);
    return data.rows.map((row) =>
        Object.entries(row)
            .map(([key, originalValue]) => {
                const col = headerMap[key];
                return col ? { [col.name]: typeOf(col).format(originalValue) } : {};
            })
            .reduce((acc, v) => ({ ...acc, ...v }))
    );
};

interface Props {
    content: ChatOlapRequest;
}

const ChatAssistantOlapMessage = ({ content }: Props) => {
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);
    const template = useAnalyticsTemplate('all_columns');
    const { definitions } = useAnalyticsMetadata();
    const olapSettings = useMemo(
        () => mapChatOlapRequestToReportSettings(content, commonClassifiers),
        [content, commonClassifiers]
    );

    const [{ loading, data, error }, refetch] = usePromise<OlapReport | undefined>(
        undefined,
        () =>
            generateOlapReport(
                {
                    ...olapSettings,
                    olapFilters: [
                        ...(olapSettings.olapFilters ?? []),
                        { columnId: 'advertiser_id', compareValue: advertiserId, compareOperator: 'EQUALS' },
                    ],
                    includeVatRate: true,
                },
                'cache-first'
            ),
        [olapSettings]
    );

    if (error) {
        return <ChatAssistantUserFriendlyErrorMessage />;
    }

    if (loading || !data) {
        return <FormattedTable containerClass="shadow-none" manual columns={[]} data={[]} loading />;
    }

    return (
        <div className="ChatAssistant-Report-Table-Container">
            <ReportTable
                TopToolbar={<ChatOlapTableToolbar olapSettings={olapSettings} />}
                defaultPageSize={10}
                manual={false}
                template={template}
                dimensionFilters={[]}
                dimensions={olapSettings?.dimensions ?? []}
                metrics={olapSettings?.metrics ?? []}
                editableDimensions={[]}
                report={data}
                compareWith={undefined}
                hideEmptyColumns={false}
                id={0}
                definitions={definitions}
                editMode={false}
                exportName="deeply-analytics-export"
                onDataExport={() => data.rows}
                refetchReport={refetch}
                containerStyle={{ margin: 0 }}
            />

            {data && (
                <div className="ChatAssistantSummary-wrapper w-100">
                    <ChatAssistantSummary data={data} />
                </div>
            )}
        </div>
    );
};

export default ChatAssistantOlapMessage;
