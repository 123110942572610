import FormRow from 'platform/common/components/FormRow/FormRow';
import { required } from 'platform/common/utils/validators.util';
import FormDynamicOptionList from 'platform/formik/FormDynamicOptionList/FormDynamicOptionList';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

const TRIGGER_TYPE_OPTIONS = [
    {
        value: 'INPUT_NAME',
        label: 'Input name',
    },
    {
        value: 'DATA_ATTRIBUTE_NAME',
        label: 'Data attribute name',
    },
    {
        value: 'ELEMENT_ID',
        label: 'Element ID',
    },
    {
        value: 'ELEMENT_CLASS',
        label: 'Element class',
    },
];

const TRIGGER_EVENT_TYPE_OPTIONS = [
    {
        value: 'CLICK',
        label: 'On Click',
    },
    {
        value: 'SUBMIT',
        label: 'On Submit',
    },
];

const TriggerSegment = () => (
    <>
        <FormRow label="Event type">
            <FormSelect
                name="triggerEventType"
                options={TRIGGER_EVENT_TYPE_OPTIONS}
                validate={required}
                isSearchable={false}
                isClearable={false}
            />
        </FormRow>
        <FormRow label="Trigger type">
            <FormSelect
                name="triggerType"
                options={TRIGGER_TYPE_OPTIONS}
                validate={required}
                isSearchable={false}
                isClearable={false}
            />
        </FormRow>
        <FormRow label="Trigger values">
            <FormDynamicOptionList
                name="triggerValues"
                buttonTitle="Add"
                validate={required}
                validateInput={required}
            />
        </FormRow>
    </>
);

export default TriggerSegment;
