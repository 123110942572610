import { useRef } from 'react';
import { InputGroup, Input, CloseButton } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';
import DatePickerPopover from './DatePickerPopover';

interface Props {
    date: string | undefined;
    placeholder?: string;
    className?: string;
    format?: string;
    isOpen?: boolean;
    children?: React.ReactNode;
    onToggle: (isOpen?: boolean) => void;
    onDateChange: (value: Date) => void;
    onDateClear?: () => void;
}

const UncontrolledDatePicker = ({
    date,
    className,
    format = 'll',
    placeholder,
    isOpen,
    children,
    onToggle,
    onDateChange,
    onDateClear,
}: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const momentDate = moment(date);

    const handleChange = (value: Date) => {
        onDateChange(value);
        onToggle();
    };

    return (
        <>
            <div ref={ref} onClick={() => onToggle()} role="button" tabIndex={0}>
                <InputGroup className={classNames('position-relative', className)}>
                    {children}
                    <Input
                        value={date ? moment(date).format(format) : ''}
                        placeholder={placeholder}
                        className="cursor-pointer"
                    />
                    {date && onDateClear && (
                        <CloseButton
                            style={{ position: 'absolute', right: '5px', bottom: '20%', borderRadius: '50%' }}
                            onClick={(e) => {
                                onDateClear();
                                e.stopPropagation();
                            }}
                        />
                    )}
                </InputGroup>
            </div>
            {ref.current && isOpen && (
                <DatePickerPopover
                    target={ref.current}
                    toggle={onToggle}
                    value={momentDate.toDate()}
                    onChange={handleChange}
                />
            )}
        </>
    );
};

export default UncontrolledDatePicker;
