import moment from 'moment';
import { getDefaultMetrics } from 'platform/campaign/advertiserManagement/DefaultMetrics/defaultMetrics.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import { fetchChannels } from 'platform/channels/channel.service';
import { getChatAgentPlaceholders } from 'platform/chat/chat.service';
import {
    fetchChatOlapColumns,
    fetchChatOlapMetadata,
    rowsToString,
} from 'platform/chat/olap/chat.olap.service';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';

const getResolver = (placeholder: string, advertiserId: number | undefined): (() => Promise<string>) => {
    if (advertiserId) {
        const advertiserResolver = ADVERTISER_PLACEHOLDER_RESOLVERS[placeholder];
        if (advertiserResolver) return () => advertiserResolver(advertiserId);
    }
    return PLACEHOLDER_RESOLVERS[placeholder];
};

const loadPlaceholders = async (
    agentId: number | undefined,
    advertiserId: number | undefined
): Promise<Record<string, string>> => {
    if (!agentId) return Promise.resolve({});
    const placeholders = await getChatAgentPlaceholders(agentId);
    const promises = placeholders
        .map((p) => ({ key: p, value: getResolver(p, advertiserId) }))
        .filter((p) => !!p.value)
        .map((p) => p.value().then((v) => ({ key: p.key, value: v })));
    return Promise.all(promises).then((p) => p.reduce((acc, v) => ({ ...acc, [v.key]: v.value }), {}));
};

const todayResolver = () => Promise.resolve(moment().format(ISO_DATE_FORMAT));

const olapDimensionsResolver = (advertiserId: number) =>
    fetchChatOlapColumns(advertiserId, (meta) => meta.dimensions);

const olapMetricsResolver = (advertiserId: number) =>
    fetchChatOlapColumns(advertiserId, (meta) => meta.metrics);

const olapDefaultMetricsResolver = async (advertiserId: number) => {
    const [olapMetrics, defaultMetrics] = await Promise.all([
        fetchChatOlapMetadata(advertiserId),
        getDefaultMetrics(advertiserId).then((result) => (result ? result.tableMetrics : [])),
    ]);
    const metrics = olapMetrics.metrics.filter((m) => defaultMetrics.includes(m.key));
    return rowsToString(metrics);
};

const olapAllowedValuesResolver = async (advertiserId: number) => {
    const [channels, products] = await Promise.all([fetchChannels(), fetchProducts(advertiserId)]);
    return rowsToString([
        { key: 'channel', allowedValues: channels.map((c) => c.name.toLowerCase()) },
        { key: 'product_label', allowedValues: products.map((p) => p.name.toLowerCase()) },
    ]);
};

const PLACEHOLDER_RESOLVERS: Record<string, () => Promise<string>> = {
    today: todayResolver,
};

const ADVERTISER_PLACEHOLDER_RESOLVERS: Record<string, (advertiserId: number) => Promise<string>> = {
    olap_dimensions_json: olapDimensionsResolver,
    olap_metrics_json: olapMetricsResolver,
    olap_default_metrics_json: olapDefaultMetricsResolver,
    olap_allowed_values_json: olapAllowedValuesResolver,
    advertiser_id: (advertiserId) => Promise.resolve(String(advertiserId)),
};

export const useAgentPlaceholders = (
    agentId: number | undefined,
    advertiserId: number | undefined
): [Record<string, string>, boolean] => {
    const [{ data: placeholders, loading: placeholdersLoading }] = usePromise(
        {},
        async (): Promise<Record<string, string>> => loadPlaceholders(agentId, advertiserId),
        [agentId, advertiserId]
    );
    return [placeholders, placeholdersLoading];
};

export const allowedPlaceholders = (advertiserIdEnabled: boolean): string[] => {
    const resolverKeys = Object.keys(PLACEHOLDER_RESOLVERS);
    if (advertiserIdEnabled) {
        const advertiserResolverKeys = Object.keys(ADVERTISER_PLACEHOLDER_RESOLVERS);
        return [...resolverKeys, ...advertiserResolverKeys];
    }
    return resolverKeys;
};
