import { useParams } from 'react-router';
import { assertIsDefined } from 'platform/common/common.assert';
import CardForm from 'platform/common/components/CardForm/CardForm';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { getSystemSetting, saveSystemSetting } from './systemSetting.service';
import { SYSTEM_SETTING_TYPES, SystemSetting, SystemSettingKey } from './systemSetting.types';

interface Props {
    redirectTo: string;
    afterSubmit: () => void;
}

const SystemSettingForm = ({ formikProps: { values, submitForm }, onCancel }: FormProps<SystemSetting>) => {
    const settingType = SYSTEM_SETTING_TYPES[values.key];
    assertIsDefined(settingType, `${values.key} setting type`);

    return (
        <CardForm title={settingType.name} submitLabel="Save" onSubmit={submitForm} onCancel={onCancel}>
            <settingType.FormComponent />
        </CardForm>
    );
};

const SystemSettingFormContainer = ({ redirectTo, afterSubmit }: Props) => {
    const { key } = useParams<{ key: SystemSettingKey }>();

    if (!key) return null;

    return (
        <FormContainer
            canEdit
            isEdit
            sidePanel
            size={SIDE_PANEL_SIZES.WIDE}
            helpKey="system_setting_form"
            onOpen={() => getSystemSetting(key)}
            onSubmit={(setting) => saveSystemSetting(key, setting.value)}
            redirectTo={redirectTo}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <SystemSettingForm {...props} />}
        </FormContainer>
    );
};

export default SystemSettingFormContainer;
