import { useSelector } from 'react-redux';
import {
    fetchAdvertiser,
    updateAdvertiserAttributes,
} from 'platform/advertisers/services/advertiser.service';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import InlineEditInput from 'platform/common/components/InlineEditInput/InlineEditInput';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { FunnelDataProps } from 'platform/dashboard/dashboard.types';

interface Props {
    data: FunnelDataProps[];
    onAudienceChange: () => void;
}

const FunnelOverviewStats = ({ data, onAudienceChange }: Props) => {
    const isAdmin = useSelector(authSelectors.isAdmin);
    const advertiserId = useSelector(activeAdvertiserSelectors.id);

    const [{ data: advertiser }] = usePromise(undefined, () => fetchAdvertiser(advertiserId), [data]);

    const handleAudienceNameChange = async (attributeKey: string, newValue?: string) => {
        if (!attributeKey || !newValue) {
            return;
        }

        if (advertiser) {
            await updateAdvertiserAttributes(advertiser.id, {
                ...advertiser.additionalAttributes,
                [attributeKey]: newValue,
            });
        }

        onAudienceChange();
    };

    return (
        <div className="FunnelOverviewStats d-flex flex-wrap border-bottom">
            {data.map(({ title, uniqueUsers30d, userDropOffPercentage, label, labelKey }) => {
                const value = label || title;
                return (
                    <div key={title} className="p-3" style={{ width: `calc(100% / ${data.length})` }}>
                        <InlineEditInput
                            onChange={(newValue) => handleAudienceNameChange(labelKey, newValue)}
                            inputType="text"
                            value={value}
                            style={{ height: 'auto' }}
                            iconClassName="fa fa-pen text-primary"
                            renderValue={() => <div className="fw-bold">{value}</div>}
                            disabled={!isAdmin}
                        />
                        <div className="font-xl">{DATA_TYPES.INT.format(uniqueUsers30d)}</div>
                        <div className="gray-400">{DATA_TYPES.P100.format(userDropOffPercentage)}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default FunnelOverviewStats;
