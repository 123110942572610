import { ReactNode } from 'react';
import { Button, Modal, ModalProps } from 'reactstrap';
import ModalHeaderCloseButton from 'platform/common/components/Modal/ModalHeaderCloseButton';
import AnimatedButton from '../AnimatedButton/AnimatedButton';
import './ConfirmationModal.scss';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onOk: () => Promise<void>;
    title: string;
    children?: ReactNode;
    okLabel?: string;
    cancelLabel?: string;
    onCancel?: () => void;
    className?: string;
} & ModalProps;

const ConfirmationModal = ({
    isOpen,
    toggle,
    onOk,
    title,
    children,
    okLabel,
    cancelLabel,
    onCancel,
    ...rest
}: Props) => {
    const handleClose = () => {
        onCancel?.();
        toggle();
    };

    return (
        <Modal isOpen={isOpen} className="ConfirmationModal" toggle={handleClose} {...rest}>
            <ModalHeaderCloseButton className="ms-auto me-3 mt-3" onClick={handleClose} />
            <div className="ConfirmationModal-title">{title}</div>
            {children && <div className="ConfirmationModal-body">{children}</div>}
            <div className="ConfirmationModal-footer">
                <AnimatedButton
                    className="modal-button modal-button--blue me-4"
                    disabled={false}
                    onClick={onOk}
                >
                    {okLabel || 'Yes'}
                </AnimatedButton>{' '}
                <Button className="modal-button" onClick={handleClose}>
                    {cancelLabel || 'Cancel'}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
