import { useParams } from 'react-router-dom';
import SystemPromptForm from 'platform/chat/admin/SystemPromptList/SystemPromptForm';
import { getSystemPrompt, updateSystemPrompt, createSystemPrompt } from 'platform/chat/chat.service';
import { SystemPromptFormModel } from 'platform/chat/chat.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';

const SystemPromptFormContainer = ({ canEdit, afterSubmit, redirectTo }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = !!params.id && Number(params.id);

    const handleOpen = (): Promise<SystemPromptFormModel> => {
        if (id) {
            return getSystemPrompt(id);
        }

        return Promise.resolve({
            name: '',
            key: '',
            prompt: '',
            status: 'ACTIVE',
        });
    };

    const handleSubmit = (formValues: SystemPromptFormModel) => {
        if (id) {
            return updateSystemPrompt(id, formValues);
        }

        return createSystemPrompt(formValues);
    };

    return (
        <FormContainer
            helpKey="system_prompt_form"
            showEditWarning={false}
            isEdit={!!id}
            sidePanel
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            canEdit={canEdit}
            redirectTo={redirectTo}
            onOpen={handleOpen}
        >
            {(props) => <SystemPromptForm {...props} />}
        </FormContainer>
    );
};

export default SystemPromptFormContainer;
