import UnderConstructionImage from '../../../assets/images/under-construction.png';
import './UnderConstruction.scss';

const UnderConstruction = () => (
    <div>
        <img className="UnderConstruction-image" alt="UnderConstruction" src={UnderConstructionImage} />
    </div>
);

export default UnderConstruction;
