import { Pie } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { defaultTo } from 'lodash-es';
import Placeholder from '../Placeholder/Placeholder';

type Props = {
    loading?: boolean;
    data: ChartData<'pie'>;
    options?: ChartOptions<'pie'>;
    height?: number;
    width?: number;
};

const PieWrapper = (props: Props) => {
    if (props.loading) {
        // No idea, but when specifying height={100}, it renders 97px tall.
        // Match placeholder height to actual Pie's height.
        const heightAdjustment = -3;
        const placeholderHeight = `${defaultTo(props.height, 256) + heightAdjustment}px`;
        return <Placeholder height={placeholderHeight} />;
    }

    return (
        <div style={{ height: props?.height }} className="d-flex justify-content-center">
            <Pie {...props} height={props.height} />
        </div>
    );
};

export default PieWrapper;
