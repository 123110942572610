import jsep, { BinaryExpression, Expression, Identifier, UnaryExpression } from 'jsep';
import { MetricDefinition } from 'platform/analytics/analytics.types';

type Config = {
    metrics: string[];
    conditional?: boolean;
};

const validateIdentifier = ({ name }: Identifier, config: Config) => {
    if (!config.metrics.includes(name)) {
        throw Error(`${name} is not a valid metric`);
    }
};

const isVar = (node: Expression): boolean => {
    switch (node.type) {
        case 'Identifier':
            return true;
        case 'BinaryExpression':
            return isVar((node as BinaryExpression).left) || isVar((node as BinaryExpression).right);
        case 'UnaryExpression':
            return isVar((node as UnaryExpression).argument);
        default:
            return false;
    }
};

const validateOperation = ({ left, operator, right }: BinaryExpression, config: Config) => {
    validateNode(left, config);
    validateNode(right, config);
    if (config.conditional && ['/', '*'].includes(operator) && isVar(left) && isVar(right)) {
        throw Error(
            'division or multiplication of two metrics is not supported in conditional formulas,' +
                ' because it can lead to unexpected results caused by data aggregation'
        );
    }
};

const validateNode = (node: Expression, config: Config) => {
    switch (node.type) {
        case 'Literal':
            return;
        case 'Identifier':
            validateIdentifier(node as Identifier, config);
            return;
        case 'BinaryExpression':
            validateOperation(node as BinaryExpression, config);
            return;
        case 'UnaryExpression':
            validateNode((node as UnaryExpression).argument, config);
            return;
        default:
            throw Error(`${node.type} is not allowed`);
    }
};

const LEGAL_CHARS = /[\w ()+\-*/.]/g;

export const validateFormula = (formula: string, config: Config): string | undefined => {
    if (!formula) {
        return undefined;
    }
    const illegalChars = formula.replace(LEGAL_CHARS, '');
    if (illegalChars) {
        return `Formula contains illegal symbols: ${illegalChars}`;
    }
    try {
        validateNode(jsep(formula), config);
        return undefined;
    } catch (e) {
        return `Invalid formula: ${e.message}`;
    }
};

type FormulaToken = {
    text: string;
    type: 'METRIC' | 'NUMBER' | 'SYMBOL';
    metric?: MetricDefinition;
};

export const tokenizeFormula = (
    expression: string,
    { metrics }: { metrics: MetricDefinition[] }
): FormulaToken[] =>
    expression
        .replace(/\s/g, '')
        .split(/\b/)
        .flatMap((text): FormulaToken[] => {
            const firstChar = text[0];
            if ((firstChar >= 'a' && firstChar <= 'z') || (firstChar >= 'A' && firstChar <= 'Z')) {
                return [{ text, type: 'METRIC', metric: metrics.find((m) => m.key === text) }];
            }
            if (firstChar >= '0' && firstChar <= '9') {
                return [{ text, type: 'NUMBER' }];
            }
            return Array.from(text).map((char) => ({ text: char, type: 'SYMBOL' }));
        });
