import { Formik } from 'formik';
import { ContactInformation } from 'platform/advertisers/advertiser.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { User } from 'platform/userManagement/types/user.type';
import '../Contacts.scss';

interface Props {
    initialValues: ContactInformation;
    users: User[];
    onCancel: () => void;
    onSubmit: (data: ContactInformation) => void;
}

const ContactForm = ({ initialValues, users, onCancel, onSubmit }: Props) => {
    const userOptions = users.map((user) => ({
        value: user.id,
        label: user.name,
    }));

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps) => (
                <CardForm
                    title="Contact Information"
                    subtitle={null}
                    submitLabel="Save"
                    formClassname="ContactForm"
                    cardBodyClassname="ContactForm-body"
                    disabled={false}
                    onCancel={onCancel}
                    onSubmit={formProps.submitForm}
                >
                    <>
                        <FormRow label="Client Service">
                            <FormSelect name="clientService" options={userOptions} isMulti />
                        </FormRow>
                        <FormRow label="Media">
                            <FormSelect name="media" options={userOptions} isMulti />
                        </FormRow>
                        <FormRow label="Internal Advertiser Mailing list">
                            <FormInput name="internalEmail" type="text" />
                        </FormRow>
                    </>
                </CardForm>
            )}
        </Formik>
    );
};

export default ContactForm;
