import {
    ExternalSystem,
    getIntegrationIcon,
    getIntegrationLabel,
} from 'platform/common/constants/externalIntegration.constant';

const SyncSystemIcon = ({ system }: { system: ExternalSystem }) => (
    <img src={getIntegrationIcon(system)} alt={system} title={getIntegrationLabel(system)} />
);

export default SyncSystemIcon;
