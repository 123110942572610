import { NavigationItem } from 'platform/app/app.types';

export const ALGORITHMS: NavigationItem = {
    name: 'Algorithms',
    path: '/algorithm-list',
    requiresFeature: 'ALGORITHM_VIEW',
};

export const ALGORITHM_TEMPLATES: NavigationItem = {
    name: 'Algorithm Templates',
    path: '/algorithm-templates',
    requiresFeature: 'ADMIN_VIEW',
};

export const ALGORITHM_LOG_DETAILS: NavigationItem = {
    name: 'Algorithm - Log Details',
    path: '/algorithm-log-details/:id',
    requiresFeature: 'ALGORITHM_HISTORY',
};
