import { useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { FormikErrors } from 'formik';
import {
    toMediaplanForm,
    toMediaplanUpdatePayload,
} from 'platform/campaign/campaign/mappers/mediaplan.mapper';
import {
    getMediaplan,
    getMediaplanChanges,
    updateMediaplan,
} from 'platform/campaign/campaign/services/mediaplan.service';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { MediaplanFormModel } from 'platform/mediaplan/mediaplan.types';
import { validateChangeTracking } from 'platform/mediaplan/mediaplan.utils';
import MediaplanForm from './MediaplanForm';

interface Props {
    redirectTo: string;
    onSubmitFinish: (data: any) => void;
    canEdit: boolean;
}

const MediaplanEditFormContainer = ({ redirectTo, onSubmitFinish, canEdit }: Props) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const handleSubmit = (model: MediaplanFormModel) => {
        const payload = toMediaplanUpdatePayload(model);
        return updateMediaplan(id, payload);
    };
    const handleOpen = async (): Promise<MediaplanFormModel> =>
        Promise.all([getMediaplan(id), getMediaplanChanges(id)]).then(([mediaplan, { mediaplanChanges }]) =>
            toMediaplanForm(mediaplan, mediaplanChanges)
        );
    const validateForm = (values: MediaplanFormModel, initialValues: MediaplanFormModel) => {
        const changeTrackingError = validateChangeTracking(values, initialValues);

        if (changeTrackingError) {
            return { changeTrackingError };
        }

        return {};
    };

    return (
        <FormContainer
            helpKey="mediaplan_form"
            isEdit
            canEdit={canEdit}
            redirectAfterSubmit={false}
            redirectTo={redirectTo}
            onSubmit={handleSubmit}
            onOpen={handleOpen}
            onSubmitFinish={onSubmitFinish}
            validate={validateForm}
        >
            {(props) => {
                const formErrors = props.formikProps.errors as FormikErrors<
                    MediaplanFormModel & { changeTrackingError?: string }
                >;
                const { changeTrackingError } = formErrors;
                const alert = changeTrackingError && !!props.formikProps.submitCount && (
                    <Alert color="danger" className="p-2 me-2 mb-0">
                        {changeTrackingError}
                    </Alert>
                );
                return <MediaplanForm {...props} headerContent={alert} />;
            }}
        </FormContainer>
    );
};

export default MediaplanEditFormContainer;
