// In codebase these statements are used for testing
// However, they are not allowed in google ads
const removeExportStatements = (code: string) => code.replace('export ', '');

const generateGAS = ({
    algorithmId,
    code,
    accessToken,
}: {
    algorithmId: number;
    code: string;
    accessToken: string;
}) => {
    const codeWithoutExportStatements = removeExportStatements(code);
    const containerCode = `
var accessToken = '${accessToken}';
var algorithmId = ${algorithmId};

function getSettings() {
    var url = 'https://app.deepmedia.de/algorithms/' + algorithmId + '/details?accessToken=' + accessToken;
    var details = JSON.parse(UrlFetchApp.fetch(url).getContentText());
    var accounts = new Function(details.settings)();
    return accounts.map(function (account) {
        account.Algorithm_Id = algorithmId;
        account.Access_Token = accessToken;
        account.Version = details.version;
        return account;
    });
}
 
${codeWithoutExportStatements}

function executeInParallel(paramString) {
  var params = JSON.parse(paramString);
  var externalLog = new Function('params', params.logScript)
  var result = JSON.parse(executeScript(params.accounts));
  if (!result.algorithmId) return;
  externalLog(result);
}

function dummyFn() {}

function main() {
    Logger.log('---');
    Logger.log('fetching settings..');
    var logScriptUrl = 'https://s3-eu-west-1.amazonaws.com/dap-prod-dcq3/google-ad-scripts/logger.js';
    var logScript = UrlFetchApp.fetch(logScriptUrl).getContentText();
    try {
      var accounts = getSettings();
      var accountSelector = AdsManagerApp.accounts().withIds(accounts.map(function (account) { return account.Account_Id }));
      var params = JSON.stringify({
          logScript: logScript,
          accounts: accounts,
      });
      accountSelector.executeInParallel("executeInParallel", "dummyFn", params);
    } catch (e) {
      var externalLog = new Function('params', logScript);
      var output = ['---', 'Error occurred while fetching settings:', e].join('\\n');
      Logger.log(output);
      externalLog({
        output: output,
        processedEntries: 0,
        foundEntries: 0,
        changedEntries: 0,
        algorithmId: algorithmId,
        status: 'ERROR',
        accessToken: accessToken,
      });
    }
}
`;
    return containerCode;
};

export default generateGAS;
