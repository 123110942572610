import classNames from 'classnames';
import { TypeDefinition } from 'platform/common/common.types';
import { formatDelta } from 'platform/common/components/DeltaText/deltas.util';
import StatisticWithDelta from '../StatisticWithDelta/StatisticWithDelta';
import './HeaderCell.scss';

interface Props {
    name: string;
    metric?: string;
    type: TypeDefinition;
    value?: number;
    compareValue?: number;
    isSelected: boolean;
    onSelect?: () => void;
}

const HeaderCell = ({ name, type, metric, value, compareValue, isSelected, onSelect }: Props) => (
    <div
        className={classNames('HeaderCell flex-fit p-2', {
            'cursor-pointer': !!onSelect,
            selected: isSelected,
        })}
        role="button"
        tabIndex={0}
        onClick={onSelect}
    >
        <StatisticWithDelta
            name={name}
            value={type.format(value)}
            delta={formatDelta({ value, compareValue, inverted: false })}
            tooltip={{ key: metric ?? '', name }}
            valueClassName="fw-bold"
        />
    </div>
);

export default HeaderCell;
