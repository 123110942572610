import { Nav, NavItem, NavLink } from 'reactstrap';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';

const TABS = [
    'Advertiser reports',
    'Central reports',
    'Admin reports',
    'Templates',
    'Shared links',
    'Personal reports',
    'System reports',
] as const;

export type ReportManagementTab = typeof TABS[number];

interface Props {
    tab: ReportManagementTab;
    status: ActiveOrArchived[];
    canEdit: boolean;
    onTabChange: (tab: ReportManagementTab) => void;
}

const ReportManagementHeader = ({ tab, canEdit, onTabChange }: Props) => (
    <>
        <HeaderContainer>
            <PageHeader title="Report Management" />
        </HeaderContainer>
        <BodyContainer>
            <div className="custom-tabs">
                <Nav tabs>
                    {TABS.map((t) => (
                        <NavItem key={t} onClick={() => onTabChange(t)}>
                            <NavLink active={t === tab}>{t}</NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
            {!canEdit && <CanNotEditWarning />}
        </BodyContainer>
    </>
);

export default ReportManagementHeader;
