import classNames from 'classnames';
import { MediaplanStepType, MEDIAPLAN_STEPS } from 'platform/mediaplan/mediaplan.types';
import WorkflowStepControls from '../common/WorkflowStepControls/WorkflowStepControls';
import { WorkflowStagesProps } from './WorkflowStages';

type Props = Omit<WorkflowStagesProps, 'lanes'> & { type: MediaplanStepType };

const ControlledStep = ({ type, workflowEvents, canEdit, mediaplanSteps, onChange }: Props) => {
    const step = mediaplanSteps[type] ?? { type, completed: false };

    return (
        <div
            className={classNames('MediaplanWorkflow-stageItem', {
                'MediaplanWorkflow-completed': step.completed,
            })}
        >
            <span>{MEDIAPLAN_STEPS[type]?.label}</span>
            <WorkflowStepControls
                step={step}
                stepEvents={workflowEvents.filter((e) => !e.mediaInsertionId && e.stepType === type)}
                canEdit={canEdit}
                onChange={onChange}
            />
        </div>
    );
};

export default ControlledStep;
