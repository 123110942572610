import { Button, ButtonProps } from 'reactstrap';
import classNames from 'classnames';
import './IconButton.scss';

const IconButton = ({ type, className, style, children, ...rest }: ButtonProps) => (
    <Button {...rest} type={type ?? 'button'} className={classNames('IconButton', className)} style={style}>
        {children}
    </Button>
);

export default IconButton;
