import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import cloud from 'd3-cloud';

const MAX_FONT_SIZE = 40;

export interface Word {
    text: string;
    value: number;
    color: string | undefined;
    colorValue: string | undefined;
}

type CloudWord = Required<cloud.Word> & Word;

const getChartData = (words: Word[], maxFontSize: number) => {
    const values = words.map((word) => word.value);
    const fontScale = d3
        .scaleLinear()
        .domain([Math.min(...values), Math.max(...values)])
        .range([8, maxFontSize]);

    return words.map((word) => ({
        ...word,
        size: fontScale(word.value),
    }));
};

interface Props {
    words: Word[];
    width: number;
    height: number;
    onMouseOver: (event: MouseEvent, word: CloudWord) => void;
    onMouseOut: () => void;
    colorDimension: string | undefined;
}

interface WordCloudGroupLabel {
    color: string | undefined;
    colorValue: string | undefined;
}
const getUniqueLabels = (words: Word[]) =>
    words.reduce((uniqueLabels: WordCloudGroupLabel[], word: Word) => {
        const { color, colorValue } = word;

        if (
            color &&
            colorValue &&
            !uniqueLabels.some((item: Word) => item.color === color && item.colorValue === colorValue)
        ) {
            uniqueLabels.push({ color, colorValue });
        }

        return uniqueLabels;
    }, []);

export const useReportWordCloudChart = ({
    words,
    width,
    height,
    onMouseOver,
    onMouseOut,
    colorDimension,
}: Props) => {
    const chartLabels: WordCloudGroupLabel[] = colorDimension ? getUniqueLabels(words) : [];
    const svgRef = useRef<SVGSVGElement>(null);

    const svg = d3.select(svgRef.current);

    const drawWordCloud = () => {
        svg.attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`);

        if (chartLabels.length > 0) {
            chartLabels.forEach(({ color, colorValue }: WordCloudGroupLabel, index) => {
                svg.append('circle')
                    .attr('cx', -Math.abs(width) / 2 + 5)
                    .attr('cy', height / 2 + 5 - 15 * (index + 1) - 1.25)
                    .attr('r', 3.5)
                    .style('fill', `${color}`);
                svg.append('text')
                    .attr('x', -Math.abs(width) / 2 + 15)
                    .attr('y', height / 2 + 5 - 15 * (index + 1))
                    .text(`${colorValue}`)
                    .style('fill', `#666666`)
                    .style('font-size', '12px')
                    .attr('alignment-baseline', 'middle');
            });
        }

        const render = (chartData: CloudWord[]) => {
            svg.append('g')
                .selectAll('text')
                .data(chartData)
                .enter()
                .append('text')
                .style('font-size', (word) => word.size)
                .style('fill', (word) => word.color ?? '#2c90d4')
                .style('cursor', 'pointer')
                .style('font-weight', 600)
                .attr('text-anchor', 'middle')
                .style('font-family', 'sans-serif')
                .attr('transform', (word) => `translate(${[word.x, word.y]})rotate(${word.rotate})`)
                .text((word) => word.text)
                .on('mouseover', onMouseOver)
                .on('mouseout', onMouseOut);
        };

        const draw = (fontSize: number) => {
            const data = getChartData(words, fontSize);
            cloud()
                .size([width, height])
                .words(data)
                .padding(2)
                .rotate(0)
                .font('sans-serif')
                .fontSize((d) => Number(d.size))
                .on('end', (output: CloudWord[]) => {
                    if (data.length !== output.length) {
                        draw(fontSize - 4);
                        return;
                    }
                    render(output);
                })
                .start();
        };

        const adjustSize = (base: number) => Math.ceil(base * (height / 300));
        draw(adjustSize(MAX_FONT_SIZE));
    };

    useEffect(() => {
        if (width > 0 && height > 0) {
            drawWordCloud();
        }

        return () => {
            svg.selectChildren().remove();
        };
    }, [width, height]);

    return {
        svgRef,
    };
};
