import { useSelector } from 'react-redux';
import { COLUMN_TYPE_LABELS, ColumnType } from 'platform/analytics/analytics.types';
import { fetchLabelOverridesBySystemName } from 'platform/campaign/advertiserManagement/LabelOverrides/labelOverrides.service';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';

interface Props {
    columnKey: string;
    columnType: ColumnType;
}

const ColumnDefinitionLabelOverrides = ({ columnKey, columnType }: Props) => {
    const advertisers = useSelector(activeAdvertiserSelectors.options);
    const [{ data: labelOverrides, loading }] = usePromise(
        [],
        () => fetchLabelOverridesBySystemName(columnKey),
        [columnKey]
    );

    return (
        <ControlledCard title="Label overrides per advertiser">
            {loading && <OverlayLoader />}
            {labelOverrides.map(({ advertiserId, alias }) => (
                <div key={advertiserId} className="d-flex mt-3">
                    <div>{alias}</div>
                    <div className="ms-auto text-muted">
                        in {advertisers.find((a) => a.id === advertiserId)?.name ?? advertiserId}
                    </div>
                </div>
            ))}
            {!labelOverrides.length && (
                <div className="ms-auto text-muted">
                    This {COLUMN_TYPE_LABELS[columnType]} has the same name across all advertisers
                </div>
            )}
        </ControlledCard>
    );
};

export default ColumnDefinitionLabelOverrides;
