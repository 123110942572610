import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { updateObserverMessage } from 'platform/observerMessageCenter/observerMessageCenter.service';
import {
    ObserverMessage,
    ObserverMessageStatus,
} from 'platform/observerMessageCenter/observerMessageCenter.types';

interface Props {
    messageData: ObserverMessage;
    newStatus: ObserverMessageStatus;
    onChange: () => void;
    toggle: () => void;
}

const MessageCenterNoteModal = ({ messageData, newStatus, toggle, onChange }: Props) => {
    const [note, setNote] = useState('');

    const handleOk = async () => {
        if (!note) return;

        await updateObserverMessage(messageData.id, { note, status: newStatus });
        toggle();
        onChange();
    };

    const handleCancel = async () => {
        await updateObserverMessage(messageData.id, { note, status: newStatus });
        toggle();
        onChange();
    };

    return (
        <Modal toggle={toggle} isOpen>
            <ModalHeader onClose={toggle}>Enter a note</ModalHeader>
            <ModalBody>
                <Input type="textarea" value={note} onChange={(e) => setNote(e.target.value)} />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" className="px-3" onClick={handleCancel}>
                    Skip
                </Button>
                <AnimatedButton className="px-3" onClick={handleOk}>
                    Save
                </AnimatedButton>
            </ModalFooter>
        </Modal>
    );
};

export default MessageCenterNoteModal;
