import { useState } from 'react';
import { useSelector } from 'react-redux';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';

export const useMetricsWithData = () => {
    const [showOnlyMetricsWithData, setShowOnlyMetricsWithData] = useState(false);
    const metadata = useSelector(analyticsSelectors.meta);

    return {
        showMetricsWithData: (key: string): boolean =>
            metadata.definitions.columnsWithData.includes(key) || !showOnlyMetricsWithData,
        setShowOnlyMetricsWithData,
    };
};
