import { Input, ModalBody } from 'reactstrap';
import useAutoFocus from 'platform/common/hooks/useAutoFocus';

interface Props {
    value: string;
    onChange: (value: string) => void;
}

// Separate component needed here for auto-focus to work correctly
// Due to how reactstrap modal works
const WikiPageCreateModalBody = ({ value, onChange }: Props) => {
    const ref = useAutoFocus<HTMLInputElement>();

    return (
        <ModalBody>
            <Input innerRef={ref} value={value} onChange={(e) => onChange(e.target.value)} required />
        </ModalBody>
    );
};

export default WikiPageCreateModalBody;
