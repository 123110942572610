import Contacts from 'platform/contacts/Contacts';
import { CONTACTS } from 'platform/contacts/contacts.navigation';

const contactPageRoutes = [
    {
        ...CONTACTS,
        component: Contacts,
    },
];

export default contactPageRoutes;
