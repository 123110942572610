import classNames from 'classnames';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import CreativeImportModal from 'platform/creatives/components/CreativeImportExport/CreativeImportModal';
import { useModal } from '../../../common/components/Modal/Modal';
import { GcmCreativeModel } from '../../types/creative.types';

type Props = {
    creatives?: GcmCreativeModel[];
    updateCreatives: (creatives: GcmCreativeModel[]) => void;
    className?: string;
};

const CreativeImportButton = ({ creatives = [], className, updateCreatives }: Props) => {
    const { showModal } = useModal();
    const items = [
        {
            label: 'Landing pages',
            action: () =>
                showModal((toggle) => (
                    <CreativeImportModal
                        toggle={toggle}
                        creatives={creatives}
                        updateCreatives={updateCreatives}
                    />
                )),
        },
    ];
    return (
        <ButtonDropdown color="secondary" className={classNames(className)} items={items} dropdownMenuRight>
            <i className="fa fa-upload" /> Import
        </ButtonDropdown>
    );
};

export default CreativeImportButton;
