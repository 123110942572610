import axiosBff from '../../axios/axiosBff';

const TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION';

const is2FAEnabled = () =>
    axiosBff
        .get('/users/api/settings')
        .then((response) => response.data.TWO_FACTOR_AUTHENTICATION === 'true');

const set2FAEnabled = (enabled: boolean) => {
    const request = {
        [TWO_FACTOR_AUTHENTICATION]: String(enabled),
    };
    return axiosBff.patch('/users/api/settings', request).then(() => enabled);
};

export default {
    is2FAEnabled,
    set2FAEnabled,
};
