import ObserverMessageCenter from './ObserverMessageCenter';
import { OBSERVER_MESSAGE_CENTER } from './observerMessageCenter.navigation';

const observerMessageCenterRoutes = [
    {
        ...OBSERVER_MESSAGE_CENTER,
        component: ObserverMessageCenter,
    },
];

export default observerMessageCenterRoutes;
