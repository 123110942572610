import { useState } from 'react';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import Search from 'platform/common/components/Search/Search';
import { AnalyticsReport } from './analyticsReport.types';

interface Props {
    columns: TableColumn<AnalyticsReport>[];
    reports: AnalyticsReport[];
    loading: boolean;
    canEdit: boolean;
    onReportChange: (report: AnalyticsReport) => void;
}

const ReportsTable = ({ columns, reports, loading }: Props) => {
    const [query, setQuery] = useState('');

    const filteredReports = query
        ? reports.filter(
              (r) =>
                  r.id?.toString().includes(query) || r.name.toLowerCase().includes(query.toLocaleLowerCase())
          )
        : reports;

    return (
        <FormattedTable
            topToolbar={
                <div>
                    <Search onSearch={setQuery} value={query} />
                </div>
            }
            columns={columns}
            data={filteredReports}
            loading={loading}
        />
    );
};

export default ReportsTable;
