import { Task } from 'platform/task/task.types';
import { User } from 'platform/userManagement/types/user.type';

type Props = {
    task: Task;
    userOptions: User[];
};

const KanbanWatchers = ({ task, userOptions }: Props) => {
    const watcherIds = (task.notifiedUserIds ?? []).filter((id) => !task.assigneeIds?.includes(id));
    if (!watcherIds.length) {
        return null;
    }
    return (
        <div className="text-muted">
            <b className="me-1">Watchers:</b>
            {watcherIds.map((id) => userOptions.find((u) => u.id === id)?.name).join(', ')}
        </div>
    );
};

export default KanbanWatchers;
