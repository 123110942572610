import { getChatAgents } from 'platform/chat/chat.service';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

const ObserverAiFormFields = () => {
    const [{ data: agents, loading }] = usePromise([], () => getChatAgents(), []);
    const systemAgents = agents.filter((a) => a.systemOnly);

    if (loading) return <OverlayLoader />;

    return (
        <div>
            <FormRow label="Prompt for query results">
                <FormInput name="deeplyPrompt" type="textarea" rows={10} validate={required} />
            </FormRow>
            <FormRow label="Agent">
                <FormSelect
                    name="deeplyAgentId"
                    options={systemAgents}
                    valueKey="id"
                    labelKey="name"
                    validate={required}
                />
            </FormRow>
            <FormRow label="Rows limit">
                <FormInput name="deeplyRowsLimit" type="number" validate={required} />
            </FormRow>
        </div>
    );
};

export default ObserverAiFormFields;
