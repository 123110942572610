import { CSSProperties, ReactNode } from 'react';
import './Overlay.scss';

interface Props {
    children?: ReactNode;
    style?: CSSProperties;
    onClick?: () => void;
}

const Overlay = ({ children, style, onClick }: Props) => (
    <div role="button" tabIndex={0} className="Overlay" style={style} onClick={onClick}>
        {children}
    </div>
);

export default Overlay;
