import { useLocation, useParams } from 'react-router-dom';
import momentTz from 'moment-timezone';
import qs from 'qs';
import { Advertiser, AdvertiserFormModel, AdvertiserType } from 'platform/advertisers/advertiser.types';
import AdvertiserForm from 'platform/advertisers/components/AdvertiserForm/AdvertiserForm';
import {
    createAdvertiser,
    fetchAdvertiser,
    updateAdvertiser,
} from 'platform/advertisers/services/advertiser.service';
import { Optional } from 'platform/common/common.types';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { ADMIN_SEAT_ID } from 'platform/userManagement/mappers/user.mapper';

interface Props {
    type: AdvertiserType;
    canEdit: boolean;
    redirectTo: string;
    afterSubmit?: () => void;
}

export const getParentId = (queryString: string) => {
    const { parentId } = qs.parse(queryString, { ignoreQueryPrefix: true });
    return parentId && !Array.isArray(parentId) ? Number(parentId) : undefined;
};

const toFormModel = ({
    externalSystemIntegrations,
    additionalAttributes,
    contactInformation,
    createdOn,
    updatedOn,
    updatedBy,
    ...rest
}: Advertiser): AdvertiserFormModel => rest;

export const newAdvertiser = (
    type: AdvertiserType,
    parentId?: number
): Optional<AdvertiserFormModel, 'id'> => ({
    type,
    parentId,
    name: '',
    iconUrl: '',
    domain: '',
    state: 'ACTIVE',
    seatId: ADMIN_SEAT_ID,
    regionalSettings: {
        timeFormat: 'FORMAT_24HOUR',
        currencyId: 'EUR',
        timeZoneId: momentTz.tz.guess(),
    },
});

const AdvertiserFormContainer = ({ type, canEdit, redirectTo, afterSubmit }: Props) => {
    const location = useLocation();
    const params = useParams<{ id: string }>();
    const id = params.id ? Number(params.id) : undefined;
    const parentId = getParentId(location.search);

    return (
        <FormContainer
            redirectTo={redirectTo}
            canEdit={canEdit}
            helpKey="advertiser_form"
            onOpen={async () => (id ? fetchAdvertiser(id).then(toFormModel) : newAdvertiser(type, parentId))}
            onSubmit={id ? updateAdvertiser : createAdvertiser}
            onSubmitFinish={afterSubmit}
            isEdit={!!id}
            sidePanel
        >
            {(props) => <AdvertiserForm {...props} />}
        </FormContainer>
    );
};

export default AdvertiserFormContainer;
