import { Card, CardBody } from 'reactstrap';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import { fill, sumBy } from 'lodash-es';
import memoizeOne from 'memoize-one';
import moment from 'moment/moment';
import { ReportSettings, generateOlapReport } from 'platform/analytics/analytics.service';
import Bar from 'platform/common/components/Charts/Bar';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatIsoDate } from 'platform/common/utils/date.util';
import { DEVICE_COLORS, filterDevices } from 'platform/dashboard/dashboard.constant';
import { precisionRound } from '../../../common/utils/formatters.util';

type Props = {
    advertiserId: number;
};
interface ChartProps {
    data: ChartData<'bar'>;
    options: ChartOptions<'bar'>;
}

const UsersByWeekdaysCard = ({ advertiserId }: Props) => {
    const metric = 'adh_segment_unique_users_30d';
    const dimensions = ['segment_weekday', 'device_type'];
    const request: ReportSettings = {
        metrics: [metric],
        dimensions,
        olapFilters: [
            {
                columnId: 'advertiser_id',
                compareOperator: 'EQUALS',
                compareValue: advertiserId,
            },
            {
                columnId: 'date',
                compareOperator: 'EQUALS',
                compareValue: formatIsoDate(moment()),
            },
        ],
    };
    const [{ data: report, loading }] = usePromise(undefined, () => generateOlapReport(request), []);
    const buildChartData = memoizeOne((): ChartProps => {
        if (!report) {
            return {
                data: {
                    datasets: [],
                    labels: [],
                },
                options: {},
            };
        }

        const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        const totalUsersCount = sumBy(report.rows, (row) => row[metric]);

        const devices = filterDevices(report.rows);

        const parsedReport = devices.reduce<Record<string, number[]>>(
            (acc, device) => ({
                ...acc,
                [device]: fill(Array(weekdays.length), 0).map((v, i) => {
                    if (totalUsersCount === 0) {
                        return 0;
                    }

                    const value =
                        report.rows.find(
                            (r) =>
                                r.segment_weekday === i + 1 &&
                                r.device_type?.toLowerCase() === device.toLowerCase()
                        )?.[metric] || 0;

                    return precisionRound((value * 100) / totalUsersCount, 2);
                }),
            }),
            {}
        );

        const datasets = devices.map((key) => ({
            label: key,
            data: parsedReport[key],
            backgroundColor: DEVICE_COLORS[key],
            borderWidth: 0,
        }));

        return {
            data: {
                labels: weekdays,
                datasets,
            },
            options: {
                animation: false,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            callback: (tick: number) => weekdays[tick].substring(0, 3),
                        },
                    },

                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value: number) => DATA_TYPES.P100.format(value),
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (tooltip: TooltipItem<'bar'>[]) => tooltip?.[0].label,
                            label: (tooltip: TooltipItem<'bar'>) => DATA_TYPES.P100.format(tooltip.raw),
                        },
                    },
                },
            },
        };
    });

    return (
        <Card className="mb-0 h-100">
            <CardBody className="p-3">
                <h5 className="mb-4">Users by Weekdays</h5>
                <div className="position-relative" style={{ height: '250px' }}>
                    {loading ? <Placeholder height="250px" /> : <Bar {...buildChartData()} />}
                </div>
            </CardBody>
        </Card>
    );
};

export default UsersByWeekdaysCard;
