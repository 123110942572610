import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DefaultLogo from 'assets/images/deepmedia.svg';
import classNames from 'classnames';
import { authSelectors } from 'platform/app/ducks/auth.duck';

interface Props {
    className?: string;
}

const CompanyLogo = ({ className }: Props) => {
    const [logoUrl, setLogoUrl] = useState(DefaultLogo);
    const { companyLogoUrl } = useSelector(authSelectors.ready.account);

    useEffect(() => {
        if (companyLogoUrl) {
            fetch(companyLogoUrl).then(() => setLogoUrl(companyLogoUrl));
        }
    }, []);

    return (
        <img
            className={classNames('CompanyLogo', className)}
            src={logoUrl}
            alt="Deep Media"
            style={{ maxWidth: 200, maxHeight: 32 }}
        />
    );
};

export default CompanyLogo;
