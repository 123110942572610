import { Badge } from 'reactstrap';
import { isEmpty } from 'lodash-es';
import { LogEntryTableView } from '../../logBook.types';
import LogBookEntryDivider from './LogBookEntryDivider';

interface Props {
    classification: LogEntryTableView['classification'];
}

const LogEntryClassification = ({ classification }: Props) => {
    if (!classification || isEmpty(classification)) return null;

    const { productCategory, productLabel, productSubLabel, flightLabel, countryLabels } = classification;

    return (
        <>
            <LogBookEntryDivider />
            <div className="d-flex me-2">
                <Badge>{productLabel || productCategory}</Badge>
                <Badge className="ms-1">{productSubLabel}</Badge>
                <Badge className="ms-1">{flightLabel}</Badge>
                {countryLabels &&
                    countryLabels.map((c, i) => (
                        <Badge key={i} className="ms-1">
                            {c}
                        </Badge>
                    ))}
            </div>
        </>
    );
};

export default LogEntryClassification;
