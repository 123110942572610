import { taskReducer, TaskTransition } from 'platform/task/reducers/taskReducer';
import { TaskBoardChange, TaskBoardState } from '../task.types';
import { laneReducer, LaneTransition } from './laneReducer';

type StateReset = { type: 'RESET'; state: TaskBoardState };

export const boardReducer = (
    state: TaskBoardState,
    e: StateReset | LaneTransition | TaskTransition | TaskBoardChange
): TaskBoardState => {
    if (e.type === 'RESET') {
        return e.state;
    }
    const version = Math.max(state.version, 'version' in e ? e.version : 0);
    if (e.type === 'BOARD_UPDATED') {
        return { ...state, version, ...e.details.data };
    }
    return {
        ...state,
        version,
        lanes: 'taskId' in e ? taskReducer(state.lanes, e) : laneReducer(state.lanes, e),
    };
};
