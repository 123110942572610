import { CSSProperties } from 'react';

interface Props {
    className?: string;
    style?: CSSProperties;
}

const TripleChevronIcon = ({ className, style }: Props) => (
    <svg viewBox="0 0 20 40" width="20" height="40" className={className} style={style}>
        <defs>
            <clipPath id="_clipPath_Xtk1U28kzytTt9iOZqkTkkrKDu4Dw77t">
                <rect width="20" height="40" />
            </clipPath>
        </defs>
        <g clipPath="url(#_clipPath_Xtk1U28kzytTt9iOZqkTkkrKDu4Dw77t)">
            <path
                d=" M 9.571 23.172 L 4.658 18.259 C 4.421 18.022 4.421 17.638 4.658 17.401 L 5.231 16.828 C 5.467 16.591 5.851 16.591 6.088 16.827 L 10 20.721 L 13.912 16.827 C 14.149 16.591 14.533 16.591 14.769 16.828 L 15.342 17.401 C 15.579 17.638 15.579 18.022 15.342 18.259 L 10.429 23.172 C 10.192 23.409 9.808 23.409 9.571 23.172 L 9.571 23.172 Z  M 9.573 31.16 L 4.677 26.266 C 4.441 26.03 4.441 25.647 4.677 25.411 L 5.248 24.84 C 5.484 24.604 5.866 24.604 6.102 24.839 L 10 28.718 L 13.898 24.839 C 14.134 24.604 14.516 24.604 14.752 24.84 L 15.323 25.411 C 15.559 25.647 15.559 26.03 15.323 26.266 L 10.427 31.16 C 10.191 31.396 9.809 31.396 9.573 31.16 L 9.573 31.16 Z  M 9.573 15.16 L 4.677 10.266 C 4.441 10.03 4.441 9.647 4.677 9.411 L 5.248 8.84 C 5.484 8.604 5.866 8.604 6.102 8.839 L 10 12.718 L 13.898 8.839 C 14.134 8.604 14.516 8.604 14.752 8.84 L 15.323 9.411 C 15.559 9.647 15.559 10.03 15.323 10.266 L 10.427 15.16 C 10.191 15.396 9.809 15.396 9.573 15.16 Z "
                fillRule="evenodd"
                fill="currentColor"
            />
        </g>
    </svg>
);

export default TripleChevronIcon;
