import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { ColumnDefinition, CustomFilter } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import FilterIcon from 'platform/assets/icons/FILTER.svg';
import { isNotEmpty } from 'platform/common/common.types';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';

interface Props {
    filters: CustomFilter[];
    style?: CSSProperties;
    className?: string;
}

const getTooltipText = (filters: CustomFilter[], columns: ColumnDefinition[]) => {
    const filterList = filters.map((f) => columns.find((c) => c.key === f.column)?.name).filter(isNotEmpty);
    const filtersText = `${filterList.join(', ')} ${filterList.length === 1 ? 'filter has' : 'filters have'}`;
    return `${filtersText} been applied to this section`;
};

const CustomFilterIndicator = ({ filters, style, className }: Props) => {
    const {
        definitions: { metrics, dimensions },
    } = useSelector(analyticsSelectors.meta);

    return (
        <Tooltip
            style={style}
            className={className}
            renderTooltip={() => getTooltipText(filters, [...metrics, ...dimensions])}
        >
            <img src={FilterIcon} alt="Additional filter" className="ms-2" />
        </Tooltip>
    );
};

export default CustomFilterIndicator;
