import LegacyNotification from 'platform/analytics/LegacyNotification';
import AnalyticsContainer from './AnalyticsContainer';
import { ANALYTICS, ANALYTICS_CREATE, LEGACY_ANALYTICS } from './analytics.navigation';

const analyticsRoutes = [
    {
        ...ANALYTICS,
        component: AnalyticsContainer,
    },
    {
        ...ANALYTICS_CREATE,
        component: AnalyticsContainer,
    },
    {
        ...LEGACY_ANALYTICS,
        component: LegacyNotification,
    },
];

export default analyticsRoutes;
