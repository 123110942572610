import { ReactNode } from 'react';
import classNames from 'classnames';
import './HeaderContainer.scss';

interface Props {
    children: ReactNode;
    className?: string;
}

const HeaderContainer = ({ children, className }: Props) => (
    <div className={classNames('HeaderContainer p-3 mb-3', className)}>{children}</div>
);

export default HeaderContainer;
