import axiosBff from '../../axios/axiosBff';
import { Account, Profile } from '../app.types';

export type SignInResult = {
    accessToken: string;
    expiresInMillis: number;
};

export interface PasswordChange {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

export const signIn = (login: string, password: string) =>
    axiosBff.post<SignInResult>(
        '/users/sign-in',
        { login, password },
        {
            headers: {
                Authorization: '',
            },
        }
    );

export const fetchCurrentUser = () => axiosBff.get<Account>('/users/api/account');

export const fetchCurrentUserProfile = () => axiosBff.get<Profile>('/users/api/users/profile');

export const updateCurrentUserProfile = (params: Profile) => axiosBff.put('/users/api/users/profile', params);

export const updateCurrentUserPassword = (params: PasswordChange) =>
    axiosBff.patch('/users/api/users/profile/password', params);

export const impersonate = (params: { login?: string; disable: boolean }) =>
    axiosBff.post('/users/api/impersonation', params);

export const sendPasswordResetEmail = (userName: string) =>
    axiosBff.patch('/users/api/users/password-reset', { login: userName });

export const updatePassword = (activationKey: string, password: string, reset: boolean = false) => {
    const url = reset
        ? `/users/api/users/password-reset/${activationKey}`
        : `/users/api/users/password/${activationKey}`;
    return axiosBff.patch(url, { password });
};

export const send2FaCode = () => axiosBff.post('/users/api/2fa/code');

export const verify2FaCode = (code: string) => axiosBff.post('/users/api/2fa', null, { params: { code } });

export const resendInvitation = (id: number) => axiosBff.patch(`/users/api/users/password-resend/${id}`);
