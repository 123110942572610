import { useRefetchAnalyticsReports } from 'platform/analyticsReports/ReportManagement/useRefetchAnalyticsReports';
import {
    AnalyticsReport,
    AnalyticsReportWithSettings,
} from 'platform/analyticsReports/analyticsReport.types';
import { fetchAnalyticsReport } from '../analyticsReport.service';

interface Props {
    seatId: number;
    refetchReports: () => void;
    onReportMove: (
        oldReport: AnalyticsReportWithSettings,
        newReport: Partial<AnalyticsReportWithSettings>,
        reports: AnalyticsReport[],
        seatId: number
    ) => Promise<void>;
    onReportChange: (report: AnalyticsReportWithSettings) => Promise<void>;
    onReportsCreate: ((reports: AnalyticsReport[]) => void) | undefined;
}

export const useReportHandlers = ({
    seatId,
    refetchReports,
    onReportMove,
    onReportChange,
    onReportsCreate,
}: Props) => {
    const { refetch } = useRefetchAnalyticsReports(refetchReports);

    const handleReportMove = async (
        oldReport: AnalyticsReport,
        newReport: Partial<AnalyticsReport>,
        reports: AnalyticsReport[]
    ) => {
        const [oldReportWithSettings, newReportWithSettings] = await Promise.all([
            fetchAnalyticsReport(oldReport.id!),
            fetchAnalyticsReport(newReport.id!),
        ]);
        await onReportMove(oldReportWithSettings, newReportWithSettings, reports, seatId);
        refetch();
    };

    const handleReportChange = async (report: AnalyticsReport) => {
        const { analyticsSettings } = await fetchAnalyticsReport(report.id!);
        await onReportChange({
            ...report,
            analyticsSettings,
        });
        refetch();
    };

    const handleReportsCreate = async (newReports: AnalyticsReport[]) => {
        if (!onReportsCreate) {
            return;
        }
        await onReportsCreate(newReports);
        refetch();
    };

    return {
        handleReportChange,
        handleReportMove,
        handleReportsCreate,
    };
};
