import classNames from 'classnames';
import { truncate } from 'lodash-es';
import { formatDateTime } from 'platform/common/utils/date.util';
import SyncStatusIcon from './SyncStatusIcon';
import SyncSystemIcon from './SyncSystemIcon';
import { syncEntityName, syncKey } from './syncJournal.constant';
import { Sync } from './syncJournal.types';
import './SyncTable.scss';

interface Props {
    syncs: Sync[];
    onOpenDetails?: () => void;
}

const SyncTable = ({ syncs, onOpenDetails }: Props) => (
    <table className="SyncTable w-100">
        <tbody>
            {syncs.map((sync) => {
                const targetId = `SyncStatus-${syncKey(sync)}`;

                return (
                    <tr key={targetId} id={targetId} onClick={onOpenDetails}>
                        <td className="d-flex">
                            <SyncStatusIcon sync={sync} targetId={targetId} />
                            <span className="SyncTable-entity fw-bold ms-2">{syncEntityName(sync)}</span>
                        </td>
                        <td
                            className={classNames(
                                'SyncTable-message',
                                sync.errorMessage ? 'text-danger' : 'text-muted'
                            )}
                        >
                            {truncate(sync.errorMessage || sync.requestReason || '', { length: 100 })}
                        </td>
                        <td>
                            <i
                                className={classNames(
                                    'fas',
                                    sync.direction === 'IMPORT'
                                        ? 'fa-arrow-left text-info'
                                        : 'fa-arrow-right text-warning'
                                )}
                            />
                        </td>
                        <td>
                            <SyncSystemIcon system={sync.externalSystem} />
                        </td>
                        <td>
                            <span className="text-muted">{formatDateTime(sync.lastUpdateTimestampUtc)}</span>
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </table>
);

export default SyncTable;
