import { useState } from 'react';
import { Popover, UncontrolledTooltip } from 'reactstrap';
import { preventOverflow } from '@popperjs/core';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import SyncTable from 'platform/adminPanel/components/SyncJournal/SyncTable';
import { fetchSyncs, SyncFilter } from 'platform/adminPanel/components/SyncJournal/syncJournal.service';
import { SyncEntityType } from 'platform/adminPanel/components/SyncJournal/syncJournal.types';
import ClockIcon from 'platform/assets/icons/CLOCK.svg';
import SyncIcon from 'platform/assets/icons/SYNC.svg';
import IconButton from 'platform/common/components/IconButton/IconButton';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import {
    ExternalSystem,
    getIntegrationLabel,
    IntegrationStatus,
} from 'platform/common/constants/externalIntegration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import IconAction from '../IconAction/IconAction';
import IntegrationIcon from '../IntegrationIcon/IntegrationIcon';
import TooltipInnerBody from '../TooltipInnerBody/TooltipInnerBody';
import { IntegrationStatusLabel } from './IntegrationStatusLabel';
import './DspIntegrationIcon.scss';

interface Props {
    systemName: ExternalSystem;
    customLabel?: string;
    customIcon?: string;
    status?: IntegrationStatus;
    internalId?: number;
    externalId?: string;
    syncEntityType?: SyncEntityType;
    className?: string;
    integrationMessage?: string;
    subLabel?: string;
    warning?: string;
    enabled: boolean;
    onImport?: () => void;
    onResync?: (internalId: number, system: ExternalSystem) => void;
}

interface PopupProps {
    filter: SyncFilter;
    onClose: () => void;
}

const SyncHistoryPopup = ({ filter, onClose }: PopupProps) => {
    const limit = 25;
    const [{ loading, data: syncs }] = usePromise([], () => fetchSyncs({ ...filter, limit }));
    return (
        <div className="DspIntegrationIcon-syncHistoryPopoverBody">
            <div className="DspIntegrationIcon-syncHistoryHeader">
                <IconButton className="ms-auto font-lg" title="Close" onClick={onClose}>
                    <i className="fa fa-times" />
                </IconButton>
            </div>
            {loading ? (
                <div className="position-relative py-4">
                    <OverlayLoader />
                </div>
            ) : (
                <>
                    <SyncTable syncs={syncs} />
                    {syncs.length === 0 && <div>(No recorded syncs)</div>}
                    {syncs.length >= limit && <div>And older syncs...</div>}
                </>
            )}
        </div>
    );
};

const DspIntegrationIcon = ({
    systemName,
    status,
    internalId,
    externalId,
    syncEntityType,
    onImport,
    onResync,
    enabled,
    className,
    customLabel,
    customIcon,
    integrationMessage,
    subLabel,
    warning,
}: Props) => {
    const [iconContainerRef, setIconContainerRef] = useState<HTMLDivElement | null>(null);
    const [showSyncHistory, setShowSyncHistory] = useState(false);
    const [effectiveStatus, setEffectiveStatus] = useState(
        status === 'NEVER' && externalId ? 'SUCCESS' : status ?? 'NEVER'
    );

    const body = (
        <>
            {!!externalId && <div>{`ID: ${externalId}`}</div>}
            {!!subLabel && <div style={{ whiteSpace: 'pre-wrap' }}>{subLabel}</div>}
        </>
    );
    const systemLabel = customLabel || getIntegrationLabel(systemName);

    return (
        <>
            <div
                className={`DspIntegrationIcon d-inline ${className ?? ''}`}
                ref={(ref) => {
                    if (!iconContainerRef && ref) {
                        setIconContainerRef(ref);
                    }
                }}
            >
                <IntegrationIcon
                    system={systemName}
                    status={effectiveStatus}
                    integrationMessage={integrationMessage}
                    enabled={enabled}
                    customLabel={customLabel}
                    customIcon={customIcon}
                    hasImgTitle={false}
                />
            </div>
            {iconContainerRef && (
                <>
                    <UncontrolledTooltip
                        placement="right"
                        target={iconContainerRef}
                        className="pl-tooltip DspIntegrationIcon-tooltip"
                        autohide={false}
                        delay={{
                            show: 0,
                            hide: 200,
                        }}
                    >
                        <div className="tooltip-inner-body">
                            <TooltipInnerBody
                                className="mx-2"
                                header={systemLabel}
                                body={body}
                                footer={<IntegrationStatusLabel status={effectiveStatus} />}
                            />
                            {integrationMessage && (
                                <div className="mx-2 text-danger" style={{ whiteSpace: 'pre-wrap' }}>
                                    {integrationMessage}
                                </div>
                            )}
                        </div>
                        <div className="tooltip-inner-footer">
                            {internalId && syncEntityType && (
                                <IconAction
                                    icon={ClockIcon}
                                    label="Show sync log"
                                    onClick={() => {
                                        setShowSyncHistory(true);
                                    }}
                                />
                            )}
                            {onImport && externalId && (
                                <IconAction
                                    icon={SyncIcon}
                                    label={`Import from ${systemLabel}`}
                                    onClick={onImport}
                                />
                            )}
                            {onResync && internalId && (
                                <IconAction
                                    icon={SyncIcon}
                                    label={`Resync to ${systemLabel}`}
                                    onClick={() => {
                                        if (effectiveStatus !== 'IN_PROGRESS') {
                                            setEffectiveStatus('IN_PROGRESS');
                                            setShowSyncHistory(false);
                                            onResync(internalId, systemName);
                                        }
                                    }}
                                />
                            )}
                        </div>
                        {warning && (
                            <div className="tooltip-inner-footer">
                                <div className="ms-2 text-danger" style={{ whiteSpace: 'pre-wrap' }}>
                                    {warning}
                                </div>
                            </div>
                        )}
                    </UncontrolledTooltip>

                    {showSyncHistory && internalId && syncEntityType && (
                        <Popover
                            className="DspIntegrationIcon-syncHistoryPopoverContainer"
                            target={iconContainerRef}
                            isOpen
                            placement="bottom"
                            flip={false}
                            modifiers={[
                                {
                                    ...preventOverflow,
                                    options: {
                                        altAxis: true,
                                    },
                                } as PreventOverflowModifier,
                            ]}
                        >
                            <SyncHistoryPopup
                                filter={{
                                    internalId: [internalId],
                                    entityType: syncEntityType,
                                    externalSystem: systemName,
                                }}
                                onClose={() => setShowSyncHistory(false)}
                            />
                        </Popover>
                    )}
                </>
            )}
        </>
    );
};

export default DspIntegrationIcon;
