import { ReactNode, useState } from 'react';
import { groupBy, map } from 'lodash-es';
import Select from 'platform/common/components/Select/Select';
import { isDefined } from '../../common.types';
import ButtonArray from '../ButtonArray/ButtonArray';
import Search from '../Search/Search';
import SelectedValuesBox, { Box } from '../SelectedValuesBox/SelectedValuesBox';
import { OptionFilter, OptionType } from './CheckboxTree.type';
import { filterOptions, flattenNodesWithParents } from './CheckboxTree.utils';
import CheckboxTreeOption from './CheckboxTreeOption';
import './CheckboxTree.scss';

const OPTION_FILTERS = [
    { label: 'Show all', value: OptionFilter.SHOW_ALL },
    { label: 'Show selected', value: OptionFilter.SHOW_SELECTED },
];

type Props = {
    options: OptionType[];
    value: number[];
    valueKey: string;
    labelKey: string;
    nodesKey: string;
    onChange?: (newValue: number[]) => void;
    optionDisplay?: (val: OptionType) => ReactNode;
    onChildLoad?: (params: Object) => Promise<any>;
    updateNode?: (node: OptionType) => any;
    additionalFilters: { key: string; placeholder: string; options: OptionType[]; defaultValue?: string }[];
};

const CheckboxTree = ({
    labelKey = 'label',
    valueKey = 'value',
    nodesKey = 'nodes',
    additionalFilters = [],
    value = [],
    options = [],
    optionDisplay,
    onChange,
    onChildLoad,
    updateNode,
}: Props) => {
    const [state, setState] = useState({
        searchQuery: '',
        optionFilter: OptionFilter.SHOW_ALL,
    });

    const initialState = additionalFilters.reduce(
        (acc, filter) => ({ ...acc, [filter.key]: filter.defaultValue }),
        {}
    );

    const [filterState, setFilterState] = useState(initialState);

    const getValues = (box: Box<OptionType>[]) => box.flatMap((b) => b.nodes).map((item) => item[valueKey]);

    const getValueBoxes = (): Box<OptionType>[] => {
        const flattenedNodesWithParents = flattenNodesWithParents(options, nodesKey);
        const values = value
            .map((v) => flattenedNodesWithParents.find((item) => item[valueKey] === v))
            .filter(isDefined);
        if (!values) {
            return [];
        }

        const valuesWithParents = values.filter((v) => v.parents && v.parents.length);
        const groupedByLastParent = groupBy(
            valuesWithParents,
            (v) => v.parents[v.parents.length - 1][valueKey] as number
        );

        const mappedWithParents = map(groupedByLastParent, (nodes) => ({
            header: nodes[0].parents.map((parent: OptionType) => parent[labelKey]).join('>'),
            nodes,
        }));

        const mappedValuesWithoutParents = values
            .filter((v) => !v.parents || !v.parents.length)
            .map((node) => ({
                node,
                header: node[labelKey],
                nodes: [],
            }));
        return [...mappedWithParents, ...mappedValuesWithoutParents];
    };

    const filteredOptions = filterOptions(
        options,
        value,
        state.searchQuery,
        state.optionFilter,
        valueKey,
        labelKey,
        nodesKey,
        false,
        additionalFilters
            .map((f) => ({ attribute: f.key, value: filterState[f.key as keyof {}] }))
            .filter(isDefined)
    );

    return (
        <div className="CheckboxTree">
            <div className="d-flex">
                <Search
                    className="CheckboxTree-search"
                    onSearch={(searchQuery) => setState({ ...state, searchQuery })}
                    value={state.searchQuery}
                />
                {!!additionalFilters.length &&
                    additionalFilters.map((f) => (
                        <Select
                            key={f.key}
                            className="ps-3 col-sm-3"
                            value={filterState[f.key as keyof {}]}
                            options={f.options}
                            placeholder={f.placeholder}
                            onChange={(val) => setFilterState((oldState) => ({ ...oldState, [f.key]: val }))}
                        />
                    ))}
                <ButtonArray
                    className="ms-auto"
                    buttons={OPTION_FILTERS}
                    selected={state.optionFilter}
                    onClick={(optionFilter) => setState({ ...state, optionFilter })}
                />
            </div>
            <div className="CheckboxTreeMenu">
                {filteredOptions.map((option) => (
                    <CheckboxTreeOption
                        key={option[valueKey]}
                        option={option}
                        depth={0}
                        value={value}
                        labelKey={labelKey}
                        valueKey={valueKey}
                        nodesKey={nodesKey}
                        optionDisplay={optionDisplay}
                        onChange={onChange}
                        onChildLoad={onChildLoad}
                        updateNode={updateNode}
                    />
                ))}
            </div>

            <SelectedValuesBox
                labelKey={labelKey}
                boxes={getValueBoxes()}
                onChange={onChange && ((box) => onChange(getValues(box)))}
            />
        </div>
    );
};

export default CheckboxTree;
