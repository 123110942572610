import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import classNames from 'classnames';
import { useDebounce } from 'platform/common/hooks/useDebounce';
import ReportInteractionContainer from './ReportInteractionContainer';

interface Props {
    value?: number;
    onChange: (value?: number) => void;
    required: boolean;
}

const ReportInteractionInput = ({ value, onChange, required }: Props) => {
    const [innerValue, setInnerValue] = useState<number | undefined>(value);

    const debouncedValue = useDebounce(innerValue, 500);

    useEffect(() => {
        onChange(debouncedValue);
    }, [debouncedValue]);

    return (
        <ReportInteractionContainer required={required} isValueEmpty={!value}>
            {(setTouched, invalid) => (
                <Input
                    className={classNames('border-right-0 my-3', { 'form-control is-invalid p-0': invalid })}
                    type="number"
                    value={innerValue}
                    onChange={(e) => setInnerValue(e.target.value ? Number(e.target.value) : undefined)}
                    // disable input change on scroll
                    onWheel={(e) => e.currentTarget.blur()}
                    onBlur={() => setTouched(true)}
                />
            )}
        </ReportInteractionContainer>
    );
};

export default ReportInteractionInput;
