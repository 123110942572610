import { ChatContentParts } from 'platform/chat/chat.types';
import FileIcon from 'platform/common/components/FileLink/FileIcon';
import ChatTitleMessage from './ChatTitleMessage';

const ChatUserMessageFile = ({
    contentPart,
    className,
}: {
    contentPart: ChatContentParts;
    className?: string;
}) => (
    <ChatTitleMessage
        icon={
            contentPart?.fileName ? (
                <div style={{ width: 15, height: 15 }} className="d-flex me-1">
                    <FileIcon filename={contentPart?.fileName} className="w-100 h-100 object-fit-contain" />
                </div>
            ) : (
                <i className="far fa-files text-primary me-1" />
            )
        }
        className={className}
    >
        {contentPart?.fileName}
    </ChatTitleMessage>
);

export default ChatUserMessageFile;
