import { useLocation } from 'react-router';
import { Optional } from 'platform/common/common.types';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { parseQuery } from 'platform/common/utils/url.util';
import CategoryForm from './CategoryForm';
import { createCategory } from './category.service';
import { Category } from './category.types';

const CategoryCreate = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const location = useLocation();
    const handleOpen = async (): Promise<Optional<Category, 'id'>> => ({
        name: '',
        code: '',
        description: '',
        status: 'ACTIVE',
        parentId: parseQuery(location.search).parentId,
    });

    return (
        <FormContainer
            helpKey="category_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={false}
            sidePanel
            onOpen={handleOpen}
            onSubmit={createCategory}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <CategoryForm {...props} />}
        </FormContainer>
    );
};

export default CategoryCreate;
