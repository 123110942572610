export type StrategyStates = 'DRAFT' | 'CREATED' | 'ARCHIVED';

const StrategyStateOptions: {
    [key: string]: { label: string; value: string; transitions: string[] };
} = {
    DRAFT: {
        label: 'Draft',
        value: 'DRAFT',
        transitions: ['DRAFT', 'CREATED'],
    },
    CREATED: {
        label: 'Created',
        value: 'CREATED',
        transitions: ['CREATED', 'ARCHIVED'],
    },
    ARCHIVED: {
        label: 'Archived',
        value: 'ARCHIVED',
        transitions: ['ARCHIVED', 'CREATED'],
    },
};

export const availableStrategyStateOptions = (current: string) => {
    const currentState = StrategyStateOptions[current];
    return currentState
        ? currentState.transitions.map((to) => StrategyStateOptions[to])
        : Object.values(StrategyStateOptions);
};
