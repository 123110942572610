import { isNil } from 'lodash-es';
import { TypeDefinition } from 'platform/common/common.types';
import { DATA_TYPES } from 'platform/common/dataTypes';

export type Direction = 'NONE' | 'UP' | 'DOWN';
export type Perception = 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL' | 'NONE';

export const PERCEPTION_COLOR_MAP: Record<Perception, string> = Object.freeze({
    NEGATIVE: 'text-danger',
    POSITIVE: 'text-success',
    NEUTRAL: 'text-warning',
    NONE: 'text-dark',
});

interface RawDelta {
    value?: number;
    compareValue?: number;
    inverted?: boolean;
    isDerivative?: boolean;
    type?: TypeDefinition;
}

export interface Delta {
    value: string;
    compareValue: string;
    direction: Direction;
    perception: Perception;
}

export const formatDelta = ({
    value,
    compareValue,
    inverted,
    isDerivative,
    type,
}: RawDelta): Delta | undefined => {
    const delta =
        !isNil(value) && compareValue ? ((value - compareValue) / Math.abs(compareValue)) * 100 : undefined;

    if (isNil(delta) || isNil(compareValue)) {
        return undefined;
    }

    const getDirection = (): Direction => {
        if (!delta) {
            return 'NONE';
        }

        if (delta > 0) {
            return 'UP';
        }

        return 'DOWN';
    };

    const getPerception = (): Perception => {
        if (!isDerivative) {
            return 'NONE';
        }

        if (!delta || Math.abs(delta) <= 10) {
            return 'NEUTRAL';
        }

        if (delta > 0) {
            return inverted ? 'NEGATIVE' : 'POSITIVE';
        }

        return inverted ? 'POSITIVE' : 'NEGATIVE';
    };

    const getComparisonValue = () => {
        if (!type) {
            return compareValue.toString();
        }

        if (type.typeId === 'INT' && !Number.isInteger(compareValue)) {
            return DATA_TYPES.FLOAT.format(compareValue);
        }

        return type.format(compareValue);
    };

    return {
        value: DATA_TYPES.P100.format(delta && Math.abs(delta)),
        compareValue: getComparisonValue(),
        direction: getDirection(),
        perception: getPerception(),
    };
};
