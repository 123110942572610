import { arrayItemInsert, moveById, removeById, updateById } from 'platform/common/utils/array.util';
import { LaneChange, TaskLane } from '../task.types';

export type LaneTransition =
    | { type: 'LANE_MOVE_PENDING'; laneId: number; position: number }
    | { type: 'LANE_SYNC_PENDING'; laneId: number }
    | { type: 'LANE_SYNC_COMPLETED'; laneId: number };

export const laneReducer = (lanes: TaskLane[], e: LaneTransition | LaneChange): TaskLane[] => {
    switch (e.type) {
        case 'LANE_MOVE_PENDING':
            return moveLane(lanes, e.laneId, e.position, { syncing: true });
        case 'LANE_SYNC_PENDING':
            return updateById(lanes, e.laneId, (lane) => ({ ...lane, syncing: true }));
        case 'LANE_SYNC_COMPLETED':
            return updateById(lanes, e.laneId, (lane) => ({ ...lane, syncing: false }));
        case 'LANE_ADDED':
            return arrayItemInsert(lanes, { id: e.laneId, ...e.details.data, tasks: [] }, e.details.position);
        case 'LANE_UPDATED':
            return updateById(lanes, e.laneId, ({ id, tasks, syncing }) => ({
                id,
                tasks,
                syncing,
                ...e.details.data,
                updatedOn: e.updatedOn,
                updatedBy: e.updatedBy,
            }));
        case 'LANE_MOVED':
            return moveLane(lanes, e.laneId, e.details.position, {
                updatedOn: e.updatedOn,
                updatedBy: e.updatedBy,
            });
        case 'LANE_DELETED':
            return removeById(lanes, e.laneId);
        default:
            return lanes;
    }
};

const moveLane = (lanes: TaskLane[], laneId: number, position: number, changes: Partial<TaskLane>) => {
    const movedLanes = moveById(lanes, laneId, position);
    return updateById(movedLanes, laneId, (lane) => ({ ...lane, ...changes }));
};
