import IconButton from 'platform/common/components/IconButton/IconButton';

interface Props {
    onClick: () => void;
    expanded: boolean;
}

const ViewNoteButton = ({ expanded, onClick }: Props) => (
    <IconButton onClick={onClick} className="LogBookListTable-viewNoteButton">
        {expanded ? 'Hide note' : 'View note'} <i className="ms-1 fa-solid fa-arrows-up-down" />
    </IconButton>
);

export default ViewNoteButton;
