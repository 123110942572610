import { useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { ALGORITHM_LANGUAGE_VALUE } from 'platform/algorithms/algorithmManager.constant';
import { AlgorithmSettingsProps } from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettings';
import AlgorithmSettingsEdit from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettingsEdit';
import FormCodeEditor from 'platform/formik/FormCodeEditor/FormCodeEditor';

const TABS = ['Edit', 'JSON'];

const AlgorithmSettingTabs = ({
    settingsLanguage,
    settings,
    hasMultipleSettings,
    onChange,
}: AlgorithmSettingsProps) => {
    const [activeTab, setActiveTab] = useState('Edit');

    return (
        <>
            <div className="custom-tabs">
                <Nav tabs className="mb-3">
                    {TABS.map((tab) => (
                        <NavItem key={tab} onClick={() => setActiveTab(tab)}>
                            <NavLink active={tab === activeTab}>{tab}</NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
            {activeTab === 'Edit' && (
                <AlgorithmSettingsEdit
                    settings={settings}
                    settingsLanguage={settingsLanguage}
                    hasMultipleSettings={hasMultipleSettings}
                    onChange={onChange}
                />
            )}
            {activeTab === 'JSON' && (
                <FormCodeEditor
                    name="settings"
                    options={{
                        mode: ALGORITHM_LANGUAGE_VALUE[settingsLanguage],
                        lineWrapping: true,
                        readOnly: false,
                        lineNumbers: true,
                    }}
                />
            )}
        </>
    );
};

export default AlgorithmSettingTabs;
