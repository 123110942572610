import { useState } from 'react';
import { useModal } from 'platform/common/components/Modal/Modal';
import Select from 'platform/common/components/Select/Select';
import { makeOptions } from 'platform/common/utils/option.util';
import {
    MESSAGE_CENTER_STATUS_ICONS,
    MESSAGE_CENTER_STATUS_LABELS,
} from '../../../observerMessageCenter.constants';
import { ObserverMessage, ObserverMessageStatus } from '../../../observerMessageCenter.types';
import MessageCenterNoteModal from './MessageCenterNoteModal';

const formatOptionLabel = ({ value, label }: { value: ObserverMessageStatus; label: string }) => (
    <div className="d-flex align-items-center">
        <i className={MESSAGE_CENTER_STATUS_ICONS[value]} />
        <span className="ms-1">{label}</span>
    </div>
);
interface Props {
    messageData: ObserverMessage;
    onChange: () => void;
}

const MessageCenterStatusSelect = ({ messageData, onChange }: Props) => {
    const [status, setStatus] = useState<ObserverMessageStatus>(messageData.status);
    const { showModal } = useModal();

    const handleChange = (newVal: ObserverMessageStatus) => {
        showModal((toggle) => (
            <MessageCenterNoteModal
                onChange={() => {
                    setStatus(newVal);
                    onChange();
                }}
                toggle={toggle}
                newStatus={newVal}
                messageData={messageData}
            />
        ));
    };

    return (
        <div tabIndex={-1} onClick={(e) => e.stopPropagation()} role="button">
            <Select
                isSearchable={false}
                className="ObserverMessageCenterList-statusSelect"
                isClearable={false}
                value={status}
                onChange={handleChange}
                options={makeOptions(MESSAGE_CENTER_STATUS_LABELS)}
                formatOptionLabel={formatOptionLabel}
            />
        </div>
    );
};

export default MessageCenterStatusSelect;
