import { RefObject } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { Popover } from 'reactstrap';
import { preventOverflow } from '@popperjs/core';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import './DatePickerPopover.scss';

interface Props {
    target: string | HTMLElement | RefObject<HTMLElement>;
    value: Date | undefined;
    toggle: () => void;
    onChange: (newValue: Date) => void;
}

const DatePickerPopover = ({ value, target, toggle, onChange }: Props) => (
    <Popover
        popperClassName="DatePickerPopover"
        placement="bottom-start"
        target={target}
        isOpen
        toggle={toggle}
        modifiers={[
            {
                ...preventOverflow,
                options: {
                    altAxis: true,
                },
            } as PreventOverflowModifier,
        ]}
        boundariesElement={document.querySelector('.main-container') ?? undefined}
        trigger="legacy"
        flip={false}
        hideArrow
    >
        <DayPicker
            showWeekNumber
            ISOWeek
            weekStartsOn={1}
            mode="single"
            showOutsideDays
            selected={value}
            onDayClick={onChange}
        />
    </Popover>
);

export default DatePickerPopover;
