import { useFeature } from 'platform/common/hooks/useFeature';
import { WikiSpace, wikiSpaces } from './wiki.types';

const useWikiPermissions = () => {
    const canEdit = useFeature('WIKI_EDIT');
    const canView = useFeature('WIKI_VIEW');
    const academyAccess = useFeature('WIKI_SPACE_ACADEMY');
    const mediaAccess = useFeature('WIKI_SPACE_MEDIA_MANAGEMENT');
    const platformAccess = useFeature('WIKI_SPACE_PLATFORM');

    return {
        canEdit,
        canView: canEdit || (canView && (academyAccess || mediaAccess || platformAccess)),
        allowedSpaces: canEdit
            ? wikiSpaces
            : ([academyAccess && 'ACADEMY', mediaAccess && 'MEDIA', platformAccess && 'PLATFORM'].filter(
                  Boolean
              ) as WikiSpace[]),
    };
};

export default useWikiPermissions;
