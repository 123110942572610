import { Input, Label } from 'reactstrap';
import {
    updateActionMap,
    updateCustomVar,
    updateIntegrationMap,
} from 'platform/advertiserTag/services/advertiserTag.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormInput from 'platform/formik/FormInput/FormInput';
import { AdvertiserTagDownloadFormModel, AdvertiserTagFormEvent } from '../../advertiserTag.types';
import EditableSnippet from '../EditableSnippet/EditableSnippet';
import EventsDownloadSelectionList from '../EventsDownloadSelectionList/EventsDownloadSelectionList';

const TAG_TYPE_BUTTONS = [
    {
        value: 'javascript',
        label: 'Javascript',
    },
    {
        value: 'iframe',
        label: 'Iframe',
    },
    {
        value: 'json',
        label: 'JSON',
    },
];

const isAllEnabledEventsSelected = (events: AdvertiserTagFormEvent[]) =>
    events.filter((event) => !event.disabled).every((event) => event.selected);

const AdvertiserTagDownloadForm = ({
    onCancel,
    canEdit,
    isEdit,
    formikProps: {
        initialValues,
        submitForm,
        setFieldValue,
        values: { events, advertiserTagLoaderType, advertiserTagLite },
    },
}: FormProps<AdvertiserTagDownloadFormModel>) => {
    const toggleAllEvents = () => {
        const allSelected = isAllEnabledEventsSelected(events);
        const newEvents = events.map((event) => ({
            ...event,
            selected: event.disabled ? event.selected : !allSelected,
        }));

        setFieldValue('events', newEvents);
    };

    return (
        <CardForm
            title={`${isEdit ? 'Edit' : 'New'} AdvertiserTag`}
            subtitle={isEdit && `ID: ${initialValues.advertiserTagId}`}
            submitLabel="Download"
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <FormButtonArray
                name="advertiserTagLoaderType"
                className="mb-3 bg-white"
                buttons={TAG_TYPE_BUTTONS}
            />
            {(advertiserTagLoaderType === 'javascript' || advertiserTagLoaderType === 'iframe') && (
                <div>
                    <h5 className="mb-2">Download</h5>
                    <Label check className="d-flex align-items-center mb-2">
                        <Input
                            className="position-static my-0 ms-0 me-2"
                            type="checkbox"
                            checked={isAllEnabledEventsSelected(events)}
                            onChange={toggleAllEvents}
                        />
                        <span>Select all available events</span>
                    </Label>
                    <EventsDownloadSelectionList
                        events={events}
                        onChange={setFieldValue}
                        loaderType={advertiserTagLoaderType}
                    />
                </div>
            )}
            {advertiserTagLoaderType === 'json' && (
                <span>
                    <EditableSnippet
                        title="URL segment map"
                        snippet={advertiserTagLite.urlSegmentList}
                        path="advertiserTagLite.urlSegmentList"
                        onChange={setFieldValue}
                    />
                    <EditableSnippet
                        title="Action map"
                        snippet={advertiserTagLite.actionMap}
                        onSave={() =>
                            updateActionMap(initialValues.advertiserTagId, advertiserTagLite.actionMap)
                        }
                        path="advertiserTagLite.actionMap"
                        onChange={setFieldValue}
                    />
                    <EditableSnippet
                        title="Integration map"
                        snippet={advertiserTagLite.integrationMap}
                        onSave={() =>
                            updateIntegrationMap(
                                initialValues.advertiserTagId,
                                advertiserTagLite.integrationMap
                            )
                        }
                        path="advertiserTagLite.integrationMap"
                        onChange={setFieldValue}
                    />
                    <EditableSnippet
                        title="Custom var config"
                        snippet={advertiserTagLite.customVarConfig}
                        onSave={() =>
                            updateCustomVar(initialValues.advertiserTagId, advertiserTagLite.customVarConfig)
                        }
                        path="advertiserTagLite.customVarConfig"
                        onChange={setFieldValue}
                    />
                    <ControlledCard title="Include">
                        <Label check className="d-flex align-items-center">
                            <FormInput type="checkbox" name="includes.includeLoaderJs" className="m-0" />
                            <span className="ms-4">loader.js</span>
                        </Label>
                        <Label check className="d-flex align-items-center">
                            <FormInput type="checkbox" name="includes.includeLoaderHtml" className="m-0" />
                            <span className="ms-4">loader.html</span>
                        </Label>
                    </ControlledCard>
                </span>
            )}
        </CardForm>
    );
};

export default AdvertiserTagDownloadForm;
