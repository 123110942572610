import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { push } from 'redux-first-history';
import axios from 'axios';
import { isString } from 'lodash-es';
import qs from 'qs';
import { fetchSecrets, completeAdvertiserToken } from 'platform/advertisers/services/advertiser.service';
import { GOOGLE_TOKEN_RETRIEVAL_PATH, SET_GOOGLE_CREDENTIALS_PATH } from 'platform/app/app.navigation';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { RootState } from 'platform/rootState.type';

const GoogleTokenRetrieval = () => {
    const dispatch = useDispatch();
    const location = useSelector((state: RootState) => state.router.location);
    const [message, setMessage] = useState<{ type: 'success' | 'danger'; text: string } | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const queryParams = qs.parse(location?.search ?? '', { ignoreQueryPrefix: true });
    if (!isString(queryParams.state)) {
        throw new Error('State parameter not received or is invalid');
    }

    const { code, error, state } = queryParams;
    const { system, advertiserId, oneTimeHash, advertiserName } = JSON.parse(state);

    useEffect(() => {
        const handleQueryParams = async () => {
            if (error && error === 'access_denied') {
                setMessage({
                    type: 'danger',
                    text: 'Permissions were not granted. Please try again.',
                });
                return;
            }

            if (code) {
                const { clientId, clientSecret } = await fetchSecrets(system);

                try {
                    setIsLoading(true);
                    const { data } = await axios.post('https://oauth2.googleapis.com/token', {
                        code,
                        client_id: clientId,
                        client_secret: clientSecret,
                        redirect_uri: `${window.location.origin}${GOOGLE_TOKEN_RETRIEVAL_PATH}`,
                        grant_type: 'authorization_code',
                    });

                    try {
                        await completeAdvertiserToken({
                            system,
                            advertiserId,
                            oneTimeHash,
                            refreshToken: data.refresh_token,
                        });
                        setMessage({
                            type: 'success',
                            text: 'Authentication with Google Ad Manager was successful',
                        });
                    } catch {
                        setMessage({
                            type: 'danger',
                            text: 'Credentials were already saved while using this link. In order to edit or add new credentials please ask your system administrator to generate new link',
                        });
                        setIsLoading(false);
                    }
                    setIsLoading(false);
                } catch ({ response }) {
                    setMessage({
                        type: 'danger',
                        text: `Google token request error: ${response.data.error}`,
                    });
                    setIsLoading(false);
                }
            }
        };

        handleQueryParams();
    }, [code, error, oneTimeHash]);

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="8">
                        <Card className="p-4">
                            <CardBody>
                                {isLoading && <OverlayLoader />}
                                <h2 className="mb-4">Authentication page</h2>

                                <p>Advertiser name: {advertiserName}</p>

                                {message && (
                                    <Alert className="mb-0" color={message.type}>
                                        {message.text}
                                    </Alert>
                                )}

                                {message && message.type === 'danger' && (
                                    <Button
                                        color="primary"
                                        className="mt-3"
                                        onClick={() => {
                                            dispatch(
                                                push(
                                                    `${
                                                        window.location.origin
                                                    }${SET_GOOGLE_CREDENTIALS_PATH}?${qs.stringify({
                                                        system,
                                                        advertiserId,
                                                        advertiserName,
                                                        oneTimeHash,
                                                    })}`
                                                )
                                            );
                                        }}
                                    >
                                        Try again
                                    </Button>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default GoogleTokenRetrieval;
