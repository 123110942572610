import { copyMediaplan } from 'platform/campaign/campaign/services/mediaplan.service';
import { RangeObj } from 'platform/common/common.types';
import ConfirmationModal from 'platform/common/components/ConfirmationModal/ConfirmationModal';
import FormRow from 'platform/common/components/FormRow/FormRow';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import { required } from 'platform/common/utils/validators.util';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormInput from 'platform/formik/FormInput/FormInput';

interface Props {
    initialValues: { originalId: number; name: string; runtime: RangeObj<string> };
    toggle: () => void;
    afterSubmit: () => any;
}

const MediaplanCopyModal = ({ initialValues, toggle, afterSubmit }: Props) => (
    <FormContainer
        helpKey="mediaplan_copy_form"
        onSubmitFinish={() => {
            afterSubmit();
            toggle();
        }}
        onOpen={async () => initialValues}
        onSubmit={(data) =>
            copyMediaplan(data.originalId, {
                name: data.name,
                dateFrom: data.runtime.from,
                dateTo: data.runtime.to,
            })
        }
    >
        {({ formikProps }) => (
            <ConfirmationModal
                isOpen
                toggle={toggle}
                onOk={formikProps.submitForm}
                title="Duplicate mediaplan"
                okLabel="Create copy"
            >
                <div className="mb-3">
                    Please enter name and select dates for the new mediaplan and media insertions.
                </div>
                <FormRow label="New name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="New period">
                    <FormDateRangePicker name="runtime" validate={required} />
                </FormRow>
            </ConfirmationModal>
        )}
    </FormContainer>
);

export default MediaplanCopyModal;
