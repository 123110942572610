import { AdvancedSettingValueType } from 'platform/adminPanel/components/AdvancedSettings/advanced.setting.types';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { required } from '../../../common/utils/validators.util';

type Props = {
    field: string;
    valueType: AdvancedSettingValueType;
};

const AdditionalAttributeInput = ({ field, valueType }: Props) => {
    switch (valueType) {
        case 'SWITCH':
            return <FormSwitch name={field} disabled />;
        case 'NUMBER':
            return <FormNumberInput name={field} validate={required} />;
        default:
            return <FormInput name={field} validate={required} />;
    }
};

export default AdditionalAttributeInput;
