import { Fragment } from 'react';
import classNames from 'classnames';
import { Report } from 'platform/analytics/analytics.types';
import { findColumn } from 'platform/analytics/analytics.util';
import ChartEmptyView from 'platform/common/components/ChartEmptyView/ChartEmptyView';
import { DATA_TYPES, typeOf } from 'platform/common/dataTypes';
import IconButton from '../../../../common/components/IconButton/IconButton';
import { useReportSunburstChart } from './useReportSunburstChart';

interface Props {
    report: Report;
    dimensions: string[];
    metric: string;
    alignmentClassName: string;
    maxHeight?: number;
}

const ReportSunburstChart = ({ report, dimensions, metric, maxHeight = 400, alignmentClassName }: Props) => {
    const { svgRef, breadcrumbs, toggledBreadcrumbs, resetToggledBreadcrumbs } = useReportSunburstChart({
        report,
        dimensions,
        metric,
        maxHeight,
    });

    const displayedBreadcrumbs = breadcrumbs.path.length ? breadcrumbs : toggledBreadcrumbs;

    const sumOfValues = report.rows.reduce((sum, row) => sum + row[metric], 0);
    const percentage = ((displayedBreadcrumbs.selectedValue * 100) / sumOfValues).toPrecision(3);

    const column = findColumn(report, metric);
    const type = typeOf(column);

    const handleFormatting = (value: number) =>
        type.typeId === 'INT' && !Number.isInteger(value)
            ? DATA_TYPES.FLOAT.format(value)
            : type.format(value);

    return (
        <div className="d-flex flex-grow-1 flex-column justify-content-between p-3">
            {!report.rows.length && <ChartEmptyView />}

            {report.rows.length > 0 && (
                <>
                    <div className="position-relative d-flex flex-grow-1 align-items-center">
                        <div
                            className="position-absolute text-center"
                            style={{
                                maxWidth: maxHeight / 3,
                                transform: 'translate(-50%, -50%)',
                                top: '50%',
                                left: '50%',
                            }}
                        >
                            <div className="text-muted">{column?.name}</div>
                            {!!displayedBreadcrumbs.selectedValue && (
                                <div className="font-lg mb-1 text-nowrap fw-bold">
                                    {handleFormatting(displayedBreadcrumbs.selectedValue)}{' '}
                                </div>
                            )}
                            {!!displayedBreadcrumbs.selectedValue && <b>{percentage}% of</b>}

                            <div className="text-nowrap text-muted">{handleFormatting(sumOfValues)}</div>
                        </div>

                        <svg
                            ref={svgRef}
                            className="d-block mx-auto h-100"
                            style={{ maxHeight }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        />
                    </div>

                    <div className="d-flex text-muted" style={{ minHeight: '2rem' }}>
                        {toggledBreadcrumbs.path.length > 0 && displayedBreadcrumbs === toggledBreadcrumbs && (
                            <IconButton size="sm" className="me-2" onClick={resetToggledBreadcrumbs}>
                                <i className="far fa-trash-can" />
                            </IconButton>
                        )}

                        <div
                            className={classNames('d-flex overflow-hidden h-100 p-3', alignmentClassName)}
                            style={{ paddingTop: 2 }}
                        >
                            {displayedBreadcrumbs.path.map((x, i) => (
                                <Fragment key={`${x}_${i}`}>
                                    <span className="font-md">{x}</span>
                                    <i
                                        className={classNames(
                                            'fa fa-angle-right mx-2',
                                            i === displayedBreadcrumbs.path.length - 1 && 'd-none'
                                        )}
                                        style={{ paddingTop: 2 }}
                                    />
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ReportSunburstChart;
