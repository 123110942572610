import { gql, useQuery } from '@apollo/client';
import FunnelActionProbability from 'platform/dashboard/components/CdpDashboard/FunnelActionProbability';
import FunnelOverviewStats from 'platform/dashboard/components/CdpDashboard/FunnelOverviewStats';
import LoadingState from 'platform/dashboard/components/CdpDashboard/LoadingState';
import { FunnelDataProps } from 'platform/dashboard/dashboard.types';
import { FUNNEL_SETTINGS } from '../../constants/segmentFunnel.constant';
import FunnelChart from './FunnelChart';
import './UserFlow.scss';

type SegmentFunnelStatistics = {
    groupId: number;
    uniqueUsers30d: number;
};

const ADVERTISER_QUERY = gql`
    query SegmentFunnelStatisticsQuery($advertiserId: Int!) {
        advertiser(id: $advertiserId) {
            id
            segmentFunnelStatistics {
                groupId
                uniqueUsers30d
            }
            additionalAttributes
        }
    }
`;

type AdvertiserQueryResult = {
    advertiser: {
        segmentFunnelStatistics: SegmentFunnelStatistics[];
        additionalAttributes?: {
            [key: string]: string;
        };
    };
};

type Props = {
    advertiserId: number;
    chartHeight: number;
};

const UserFlow = ({ advertiserId, chartHeight }: Props) => {
    const { data, loading, refetch } = useQuery<AdvertiserQueryResult>(ADVERTISER_QUERY, {
        variables: { advertiserId },
        notifyOnNetworkStatusChange: true,
    });

    if (loading) {
        return <LoadingState chartHeight={chartHeight} />;
    }

    if (!data) {
        return null;
    }

    const { segmentFunnelStatistics, additionalAttributes } = data.advertiser;

    const pageViewsStats = segmentFunnelStatistics.find((stat) => stat.groupId === 0);
    const allActionsStats = segmentFunnelStatistics.find((stat) => stat.groupId === 4);

    const funnelData: FunnelDataProps[] = FUNNEL_SETTINGS.map((settings) => {
        const statistics = segmentFunnelStatistics.find((stat) => stat.groupId === settings.groupId);

        return {
            title: settings.title,
            uniqueUsers30d: statistics && statistics.uniqueUsers30d,
            userDropOffPercentage:
                pageViewsStats && pageViewsStats.uniqueUsers30d && statistics && statistics.uniqueUsers30d
                    ? (statistics.uniqueUsers30d / pageViewsStats.uniqueUsers30d) * 100
                    : null,
            actionProbability:
                allActionsStats && allActionsStats.uniqueUsers30d && statistics && statistics.uniqueUsers30d
                    ? (allActionsStats.uniqueUsers30d / statistics.uniqueUsers30d) * 100
                    : null,
            label: additionalAttributes && additionalAttributes[settings.labelKey],
            labelKey: settings.labelKey,
            groupId: settings.groupId,
        };
    }).filter(
        (funnel) =>
            !(
                (funnel.groupId === 3 || funnel.groupId === 1) &&
                (!funnel.uniqueUsers30d || funnel.uniqueUsers30d === 0)
            )
    );

    return (
        <>
            <FunnelOverviewStats data={funnelData} onAudienceChange={refetch} />
            <FunnelChart data={funnelData.map((d) => d.uniqueUsers30d || 0)} chartHeight={chartHeight} />
            <FunnelActionProbability data={funnelData} />
        </>
    );
};

export default UserFlow;
