import classNames from 'classnames';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { FOOTER_TEXT } from '../chat.constants';
import './ChatFooter.scss';

interface Props {
    className?: string;
}

const ChatFooter = ({ className }: Props) => (
    <div className={classNames('ChatFooter', className)}>
        <div>Privacy first</div>
        <Tooltip renderTooltip={() => <div>{FOOTER_TEXT}</div>}>
            <i className="fal fa-info-circle text-info ms-1" />
        </Tooltip>
    </div>
);

export default ChatFooter;
