import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { makeOptions } from 'platform/common/utils/option.util';

export type ConceptType = 'STANDARD' | 'GOOGLE' | 'BING' | 'FACEBOOK';

export const conceptExternalSystem = (conceptType: ConceptType): ExternalSystem => {
    switch (conceptType) {
        case 'GOOGLE':
        case 'BING':
        case 'FACEBOOK':
            return conceptType;
        default:
            return 'GCM';
    }
};

const CONCEPT_TYPE_LABELS = {
    STANDARD: 'Standard',
    GOOGLE: 'Google',
    BING: 'Bing',
    FACEBOOK: 'Facebook',
};

export const CONCEPT_TYPE_OPTIONS = makeOptions(CONCEPT_TYPE_LABELS);
