import { ReportComponentState } from 'platform/analytics/analytics.types';
import { ComponentsProps } from 'platform/analytics/modes/Analytics';
import CopyButton from 'platform/common/components/CopyButton/CopyButton';
import ReportComponentEdit from './ReportComponentEdit';
import ReportComponentRemove from './ReportComponentRemove';

interface Props {
    canEdit: boolean;
    isHighlighted: boolean;
    ANALYTICS_OVERLAY_Z_INDEX: number;
    toggleHighlight: (newState?: boolean | undefined) => void;
    handleComponentCopy: () => void;
    component: ReportComponentState;
    componentsProps: ComponentsProps | undefined;
    dragRef: (instance: HTMLDivElement | null) => void;
}

const ReportComponentActions = ({
    isHighlighted,
    ANALYTICS_OVERLAY_Z_INDEX,
    toggleHighlight,
    handleComponentCopy,
    component,
    componentsProps,
    canEdit,
    dragRef,
}: Props) => (
    <>
        <i ref={dragRef} className="fal fa-grip-vertical SortableItem-dragIcon flex-grow-1 ms-2" />
        {!isHighlighted && (
            <CopyButton
                title="Copy component"
                className="p-1"
                modalProps={{
                    zIndex: ANALYTICS_OVERLAY_Z_INDEX,
                    onCancel: toggleHighlight,
                }}
                onCopy={handleComponentCopy}
                onClick={toggleHighlight}
            />
        )}
        <ReportComponentEdit
            initialState={component}
            componentsProps={componentsProps}
            canEdit={canEdit}
            toggleHighlight={toggleHighlight}
        />
        {!isHighlighted && <ReportComponentRemove id={component.id} toggleHighlight={toggleHighlight} />}
    </>
);

export default ReportComponentActions;
