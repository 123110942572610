import { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
import { ItemGroupCM360 } from '../../CustomerJourneyCM360';
import JourneyEvent from './JourneyEvent';
import JourneyEventSeparator from './JourneyEventSeparator';
import JourneyTimelineGroup from './JourneyTimelineGroup';

export const SPACE_BETWEEN_EVENTS_MIN_WIDTH = 50;
export const JOURNEY_EVENT_WIDTH = 130;
export const TOGGLEABLE_COLUMN_WIDTH = 80;

const getFittingItemsCount = (componentWidth: number, totalCount: number) => {
    const totalWidth = totalCount * JOURNEY_EVENT_WIDTH + (totalCount - 1) * SPACE_BETWEEN_EVENTS_MIN_WIDTH;

    if (totalWidth <= componentWidth) {
        return totalCount;
    }

    const fittingItemCount = Math.floor(
        (componentWidth - TOGGLEABLE_COLUMN_WIDTH + 2 * SPACE_BETWEEN_EVENTS_MIN_WIDTH) /
            (JOURNEY_EVENT_WIDTH + SPACE_BETWEEN_EVENTS_MIN_WIDTH)
    );

    return fittingItemCount > 2 ? fittingItemCount : 2;
};

interface Props {
    groups: ItemGroupCM360[];
}

const JourneyTimeline = ({ groups }: Props) => {
    const [componentWidth, setComponentWidth] = useState(0);
    const ref = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        const measureElement = () => {
            if (ref.current) {
                setComponentWidth(ref.current.offsetWidth);
            }
        };

        const debouncedOnResize = debounce(measureElement, 250);

        window.addEventListener('resize', debouncedOnResize);
        setTimeout(measureElement, 0);

        return () => window.removeEventListener('resize', debouncedOnResize);
    }, []);

    return (
        <div
            ref={ref}
            className="d-flex justify-content-center align-items-end pt-3 mx-3 mb-3 overflow-auto border-top"
        >
            {(() => {
                if (!componentWidth) {
                    return null;
                }

                const totalCount = groups.length;
                const fittingItemsCount = getFittingItemsCount(componentWidth, totalCount);

                if (totalCount === fittingItemsCount) {
                    return (
                        <>
                            {groups.map((group, index) => {
                                const nextGroup = groups[index + 1];

                                return (
                                    <Fragment key={index}>
                                        <JourneyEvent
                                            group={group}
                                            groupIndex={index}
                                            leftDashVisible={index !== 0}
                                            rightDashVisible={!!nextGroup}
                                            circleEmpty={index !== 0 && !!nextGroup}
                                        />
                                        {nextGroup && (
                                            <JourneyEventSeparator group={group} nextGroup={nextGroup} />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </>
                    );
                }

                const visibleItemsCountOnOneSide = Math.floor(fittingItemsCount / 2);
                const startGroups = groups.slice(0, visibleItemsCountOnOneSide);
                const collapsedGroups = groups.slice(
                    visibleItemsCountOnOneSide,
                    totalCount - visibleItemsCountOnOneSide
                );
                const endGroups = groups.slice(totalCount - visibleItemsCountOnOneSide);

                return (
                    <>
                        {startGroups.map((group, index) => {
                            const nextGroup = startGroups[index + 1];

                            return (
                                <Fragment key={index}>
                                    <JourneyEvent
                                        group={group}
                                        groupIndex={index}
                                        leftDashVisible={index !== 0}
                                        rightDashVisible
                                        circleEmpty={index !== 0}
                                    />
                                    {nextGroup && (
                                        <JourneyEventSeparator group={group} nextGroup={nextGroup} />
                                    )}
                                </Fragment>
                            );
                        })}
                        <JourneyTimelineGroup
                            groups={collapsedGroups}
                            startGroups={startGroups}
                            endGroups={endGroups}
                        />
                        {endGroups.map((group, index) => {
                            const nextGroup = endGroups[index + 1];

                            return (
                                <Fragment key={index}>
                                    <JourneyEvent
                                        group={group}
                                        groupIndex={startGroups.length + collapsedGroups.length + index}
                                        leftDashVisible
                                        rightDashVisible={!!nextGroup}
                                        circleEmpty={!!nextGroup}
                                    />
                                    {nextGroup && (
                                        <JourneyEventSeparator group={group} nextGroup={nextGroup} />
                                    )}
                                </Fragment>
                            );
                        })}
                    </>
                );
            })()}
        </div>
    );
};

export default JourneyTimeline;
