import { FileInfo } from 'platform/common/utils/file.util';
import {
    RuntimeFields,
    RuntimeFormFields,
    toRuntimeApiFields,
    toRuntimeFormFields,
} from 'platform/creatives/mappers/creative/runtimesCreative.mapper';
import {
    CommonCreativeFields,
    CommonCreativeModelFields,
    toApiCommonFields,
    toFormModelCommonFields,
} from './commonCreative.mapper';

export type GcmVideoCreative = CommonCreativeFields & {
    type: 'GCM_VIDEO';
    name: string;
    clickThroughUrl: string;
    clickThroughUrlSuffix?: string;
    externalAssetId?: string;
    weight?: number;
} & RuntimeFields;

export type GcmVideoCreativeModel = CommonCreativeModelFields & {
    type: 'GCM_VIDEO';
    name: string;
    clickThroughUrl: string;
    clickThroughUrlSuffix?: string;
    externalAssetId?: string;
    externalVideoAsset?: FileInfo;
    weight?: number;
} & RuntimeFormFields;

export const toFormModelGcmVideoCreative = (
    creative: GcmVideoCreative,
    timezone: string
): GcmVideoCreativeModel => ({
    ...toFormModelCommonFields(creative),
    type: creative.type,
    id: creative.id,
    name: creative.name,
    clickThroughUrl: creative.clickThroughUrl,
    clickThroughUrlSuffix: creative.clickThroughUrlSuffix,
    externalAssetId: creative.externalAssetId,
    weight: creative.weight,
    ...toRuntimeFormFields(creative, timezone),
});

export const toApiGcmVideoCreative = (
    creative: GcmVideoCreativeModel,
    timezone: string
): GcmVideoCreative => ({
    ...toApiCommonFields(creative),
    type: creative.type,
    id: creative.id,
    name: creative.name,
    clickThroughUrl: creative.clickThroughUrl,
    clickThroughUrlSuffix: creative.clickThroughUrlSuffix || undefined,
    externalAssetId: creative.externalAssetId,
    weight: creative.weight,
    ...toRuntimeApiFields(creative, timezone),
});
