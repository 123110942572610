import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Badge, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { push } from 'redux-first-history';
import classNames from 'classnames';
import {
    ALGORITHM_AREA_LABELS,
    ALGORITHM_BADGE_TYPE,
    AlgorithmArea,
} from 'platform/algorithms/algorithmManager.constant';
import { ALGORITHMS } from 'platform/algorithms/algorithms.navigation';
import { executeAlgorithm } from 'platform/algorithms/algorithms.service';
import { AlgorithmDisplay } from 'platform/algorithms/algorithms.types';
import { useFeature } from 'platform/common/hooks/useFeature';
import useToggle from 'platform/common/hooks/useToggle';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { executeObserverReportForMe } from 'platform/observer/observer.service';
import { ObserverReportDestination } from 'platform/observer/observer.types';
import { ALGORITHM_URL_MACROS } from '../AlgorithmTemplateForm/AlgorithmTemplateForm';
import AlgorithmActions from './AlgorithmActions';
import './AlgorithmCard.scss';

const replaceMacros = (advertiserId: number, algorithmId?: number, url?: string) => {
    if (!url) {
        return null;
    }

    return url
        .replace(ALGORITHM_URL_MACROS[0].key, String(advertiserId))
        .replace(ALGORITHM_URL_MACROS[1].key, String(algorithmId ?? ''));
};

interface Props {
    advertiserId: number;
    area: AlgorithmArea;
    name: string;
    children: ReactNode;
    color: string;
    algorithmId?: number;
    observerReportId?: number;
    observerReportDestinations?: ObserverReportDestination[];
    templateId?: number;
    shortDescription?: string;
    algorithmDisplay?: AlgorithmDisplay;
}

const AlgorithmCard = ({
    name,
    area,
    templateId,
    shortDescription,
    algorithmDisplay,
    advertiserId,
    color,
    children,
    algorithmId,
    observerReportId,
    observerReportDestinations,
}: Props) => {
    const dispatch = useDispatch();
    const [expanded, toggleExpanded] = useToggle(false);

    const canEdit = useFeature('ALGORITHM_EDIT');
    const canViewHistory = useFeature('ALGORITHM_HISTORY');
    const canPreview = useFeature('ALGORITHM_PREVIEW');

    const setupUrl = replaceMacros(advertiserId, algorithmId, algorithmDisplay?.setupUrl);
    const logUrl = replaceMacros(advertiserId, algorithmId, algorithmDisplay?.logUrl);
    const previewUrl = replaceMacros(advertiserId, algorithmId, algorithmDisplay?.previewUrl);
    const runUrl = replaceMacros(advertiserId, algorithmId, algorithmDisplay?.runUrl);
    const historyUrl = replaceMacros(advertiserId, algorithmId, algorithmDisplay?.historyUrl);

    const handleSetup = () => {
        if (setupUrl) {
            window.open(setupUrl, '_blank');
            return;
        }

        dispatch(push(`${ALGORITHMS.path}/${templateId}/${algorithmId ?? 'new'}`));
    };

    const handleRun = () => {
        if (templateId === 80030 && algorithmId) {
            executeAlgorithm(algorithmId);
        } else if (runUrl && !observerReportId) {
            dispatch(push(runUrl));
        } else if (observerReportId && observerReportDestinations?.includes('EMAIL')) {
            executeObserverReportForMe(observerReportId).then(() =>
                toastSuccess('Your request is being processed and the result will be sent via emai')
            );
        }
    };

    const actionBtns = [
        ...(!observerReportId && canEdit
            ? [
                  {
                      title: 'Setup',
                      onClick: handleSetup,
                  },
              ]
            : []),
        ...(!observerReportId && canEdit && logUrl
            ? [
                  {
                      title: 'Log',
                      onClick: () => dispatch(push(logUrl)),
                  },
              ]
            : []),
        ...(!observerReportId && canPreview && previewUrl
            ? [
                  {
                      title: 'Preview',
                      onClick: () => dispatch(push(previewUrl)),
                  },
              ]
            : []),
        ...((canEdit && runUrl) || (observerReportId && observerReportDestinations?.includes('EMAIL'))
            ? [
                  {
                      title: 'Run',
                      onClick: handleRun,
                  },
              ]
            : []),
        ...(!observerReportId && canViewHistory && historyUrl
            ? [
                  {
                      title: 'History',
                      onClick: () => dispatch(push(historyUrl)),
                  },
              ]
            : []),
    ];

    return (
        <Card className="AlgorithmCard" color={color}>
            <CardHeader
                onClick={() => toggleExpanded()}
                className={classNames('AlgorithmCard-header button-transition', { 'text-white': expanded })}
                style={{
                    backgroundColor: expanded ? color : undefined,
                }}
            >
                <div className="AlgorithmCard-headerHighlight" style={{ backgroundColor: color }} />
                <div className="AlgorithmCard-badge">
                    <Badge
                        className="badge-outline py-2"
                        color={expanded ? ALGORITHM_BADGE_TYPE[area] : 'dark'}
                    >
                        {ALGORITHM_AREA_LABELS[area]}
                    </Badge>
                </div>
                <div className="d-flex flex-column col-7" role="button" tabIndex={-1}>
                    <div className="AlgorithmCard-title">{name}</div>
                    {shortDescription && <div className="AlgorithmCard-description">{shortDescription}</div>}
                </div>

                <div className="card-actions d-flex">
                    <AlgorithmActions actionBtns={actionBtns} expanded={expanded} color={color} />

                    <button type="button" className="btn-minimize border-left-0">
                        <i className={expanded ? 'fa fa-chevron-up text-white' : 'fa fa-chevron-down'} />
                    </button>
                </div>
            </CardHeader>
            <Collapse isOpen={expanded && !!children}>
                <CardBody className="d-flex">
                    <div className="AlgorithmCard-badge-spacer" />
                    <div className="ms-2 ps-1">{children}</div>
                </CardBody>
            </Collapse>
        </Card>
    );
};

export default AlgorithmCard;
