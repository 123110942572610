import { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ApolloError } from '@apollo/client';
import { isArray, isEqual } from 'lodash-es';
import moment from 'moment';
import {
    generateInsightReport,
    generateOlapReport,
    generatePivot,
    PivotSettings,
    ReportSettings,
} from 'platform/analytics/analytics.service';
import {
    InteractionComponentState,
    InteractionFilter,
    Report,
    ReportComponentState,
    ReportFilterOperator,
    ReportType,
    ReportFilter,
} from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { hasFilters } from 'platform/analytics/modes/InsightAnalytics';
import { ReportDefaultMetrics } from 'platform/campaign/advertiserManagement/DefaultMetrics/defaultMetrics.types';
import { isDefined } from 'platform/common/common.types';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { dateFilterSelectors, Periods } from 'platform/common/ducks/dateFilter.duck';
import { useCancelableEffect } from 'platform/common/hooks/useCancelableEffect';
import { resolveCustomDateRange } from 'platform/common/utils/date.util';
import { customFiltersToReportFilters, getTemplate } from '../analytics.util';
import { formatDimensions } from './formatReport.util';

interface Props {
    settings: ReportSettings | PivotSettings;
    type: ReportType;
    componentState: ReportComponentState;
    shouldFormatDimensions?: boolean;
    supportsComparison: boolean;
}

const adjustDateFrom = (date?: string, dimenions: string[] = []) => {
    if (dimenions.includes('week') && moment(date).month() === 0) {
        const LAST_WEEK_IN_YEAR = [52, 53];
        const week = Number(moment(date).format('W'));
        // where are cases when last week in previous year includes days from next year
        return LAST_WEEK_IN_YEAR.includes(week)
            ? // to avoid such cases we need to set date to first day of next week
              moment(date).add(1, 'week').startOf('isoWeek').format(ISO_DATE_FORMAT)
            : date;
    }

    return date;
};

interface ReportStateType {
    loading: boolean;
    error?: ApolloError;
    report?: Report;
    compareReport?: Report;
}

const optionalReportFilter = (
    key: string,
    operator: ReportFilterOperator,
    value: any
): ReportFilter | undefined => {
    if (!value) return undefined;
    if (isArray(value)) return value.length ? { key, operator, values: value } : undefined;
    return { key, operator, values: [value] };
};

const generateReport = async ({
    type,
    analyticsReportId,
    settings,
    filters,
    periods,
    month,
    usePeriods,
    useCompare,
    interactions,
}: {
    type: ReportType;
    analyticsReportId?: number;
    settings: ReportSettings | PivotSettings;
    filters: ReportFilter[];
    periods: Periods;
    month?: number;
    usePeriods: boolean;
    useCompare: boolean;
    interactions: InteractionFilter[];
}): Promise<{ report: Report; compareReport?: Report }> => {
    const { primaryFrom, primaryTo, secondaryFrom, secondaryTo } = resolveCustomDateRange(
        periods,
        settings.customDateRange
    );

    const fetchCompareReport = useCompare && !!secondaryFrom && !!secondaryTo;

    const fetchInsightReport = async (period: { from?: string; to?: string }) => {
        const { templateId, dimensions, metrics, sort, customFilters, paging, includeVatRate, modelOptIn } =
            settings as ReportSettings;

        const resolvedCustomFilters: ReportFilter[] = customFiltersToReportFilters(customFilters);

        return generateInsightReport({
            templateId: templateId!,
            columns: [...(dimensions ?? []), ...(metrics ?? [])],
            filters: [
                ...filters.filter((f) => !resolvedCustomFilters.find((lf) => lf.key === f.key)),
                ...resolvedCustomFilters.map((f) =>
                    optionalReportFilter(f.key, f.operator ?? 'IS', f.values)
                ),
                ...interactions.map((f) => optionalReportFilter(f.key, 'IS', f.value)),
                interactions.some((i) => i.key === 'date_from')
                    ? undefined
                    : optionalReportFilter('date_from', 'IS', period.from),
                interactions.some((i) => i.key === 'date_to')
                    ? undefined
                    : optionalReportFilter('date_to', 'IS', period.to),
                optionalReportFilter('month', 'IS', month),
            ].filter(isDefined),
            sort,
            paging,
            analyticsReportId,
            includeVatRate,
            modelOptIn,
        });
    };

    switch (type) {
        case 'PIVOT': {
            const report = await generatePivot({
                ...(settings as PivotSettings),
                filter: {
                    dimensionFilters: filters,
                    period: {
                        from: primaryFrom,
                        to: primaryTo,
                    },
                },
            });
            return { report: { ...report, type: 'PIVOT' } };
        }
        case 'INSIGHT': {
            const [report, compareReport] = await Promise.all([
                fetchInsightReport({
                    from: usePeriods ? primaryFrom : undefined,
                    to: usePeriods ? primaryTo : undefined,
                }),
                usePeriods && fetchCompareReport
                    ? fetchInsightReport({
                          from: secondaryFrom,
                          to: secondaryTo,
                      })
                    : Promise.resolve(undefined),
            ]);
            return {
                report: { ...report, type: 'INSIGHT' },
                compareReport,
            };
        }

        default: {
            const reportSettings = settings as ReportSettings;
            const dimensionFilters = [
                ...filters,
                ...(reportSettings.customFilters ?? []).map((f) => ({
                    key: f.column,
                    values: f.value,
                    operator: f.operator,
                })),
            ];
            const [report, compareReport] = await Promise.all([
                generateOlapReport({
                    ...(reportSettings as ReportSettings),
                    from: adjustDateFrom(primaryFrom, reportSettings.dimensions),
                    to: primaryTo,
                    dimensionFilters,
                    interactions,
                }),
                fetchCompareReport
                    ? generateOlapReport({
                          ...(reportSettings as ReportSettings),
                          from: adjustDateFrom(secondaryFrom, reportSettings.dimensions),
                          to: secondaryTo,
                          dimensionFilters,
                          interactions,
                      })
                    : Promise.resolve(undefined),
            ]);
            return {
                report: { ...report, type: 'DEFAULT' },
                compareReport,
            };
        }
    }
};

export const getInteractionValues = (
    components: ReportComponentState[]
): (InteractionFilter & { required: boolean })[] =>
    components
        ?.filter((c) => c.type === 'INTERACTION')
        .filter((i: InteractionComponentState) => (Array.isArray(i.value) ? !!i.value.length : !!i.value))
        .reduce((acc: (InteractionFilter & { required: boolean })[], c: InteractionComponentState) => {
            if (c.interactionType === 'DATE_PICKER') {
                return [
                    ...acc,
                    { key: c.fromKey, value: c.value?.from ?? '', required: c.required },
                    { key: c.toKey, value: c.value?.to ?? '', required: c.required },
                ];
            }

            return [
                ...acc,
                {
                    key: c.key,
                    value: c.value ?? c.defaultValue ?? 1,
                    required: c.required,
                },
            ];
        }, []);

const findValue = (values: InteractionFilter[], key: string) => values.find((v) => v.key === key)?.value;

export const applyInteractionValues = (
    components: ReportComponentState[],
    values: InteractionFilter[]
): InteractionComponentState[] =>
    components
        .filter((c) => c.type === 'INTERACTION')
        .map((state: InteractionComponentState): InteractionComponentState => {
            switch (state.interactionType) {
                case 'DATE_PICKER':
                    return {
                        ...state,
                        value: {
                            from: (findValue(values, state.fromKey) as string) ?? null,
                            to: (findValue(values, state.toKey) as string) ?? null,
                        },
                    };
                case 'FILTER':
                    return {
                        ...state,
                        value: (findValue(values, state.key) as string[]) ?? [],
                    };
                default:
                    return {
                        ...state,
                        value: (findValue(values, state.key) as number) ?? null,
                    };
            }
        });

const areDefaultMetricsResolved = (
    component: ReportComponentState,
    defaultMetrics: ReportDefaultMetrics | null,
    includeDefaultMetrics?: boolean
) => {
    if (
        !includeDefaultMetrics ||
        !defaultMetrics ||
        (component.type !== 'TABLE' && component.type !== 'SUMMARY_BAR')
    ) {
        return true;
    }

    return !!component.defaultMetrics;
};

export const useReport = ({
    settings,
    type,
    supportsComparison,
    componentState,
    shouldFormatDimensions = true,
}: Props) => {
    const { templates, defaultMetrics } = useAnalyticsMetadata();
    const { mode, analyticsReportId, components, includeDefaultMetrics, filtersLoading } = useSelector(
        analyticsSelectors.settings
    );
    const templateId =
        'templateId' in settings ? settings.templateId : (settings as PivotSettings).definition.templateId;
    const template = getTemplate(templates, templateId!);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const month = useSelector(dateFilterSelectors.month);
    const filters = useSelector(analyticsSelectors.filtersWithAdvertiser);
    const compareType = useSelector(analyticsSelectors.compareType);
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);
    const [state, setState] = useState<ReportStateType>({ loading: false });
    const [previousInteractions, setPreviousInteractions] = useState<InteractionFilter[]>(
        getInteractionValues(components)
    );
    const interactions = useMemo(() => getInteractionValues(components), [components]);
    const hasRequiredInteractionsWithNoValues = interactions.some((i) => i.required && !i.value);
    const filtersLoaded = filters.filter((f) => f.key !== 'advertiser_id').length ? !filtersLoading : true;

    const tryFetch = async (isCanceledFn?: () => boolean) => {
        try {
            if (hasRequiredInteractionsWithNoValues) {
                setState({ ...state, loading: false, report: undefined });
                return;
            }
            if (isCanceledFn && isCanceledFn()) return;

            const { report, compareReport } = await generateReport({
                settings,
                type,
                analyticsReportId,
                filters,
                periods,
                month,
                usePeriods:
                    mode !== 'INSIGHT' ||
                    hasFilters(['date_from', 'date_to'])((settings as ReportSettings).templateId!, templates),
                useCompare: supportsComparison && mode !== 'INTERACTIVE',
                interactions: interactions.map(({ required, ...interaction }) => interaction),
            });
            if (isCanceledFn && isCanceledFn()) return;

            setState({
                ...state,
                report: shouldFormatDimensions
                    ? formatDimensions(report, template, commonClassifiers)
                    : report,
                compareReport:
                    shouldFormatDimensions && compareReport
                        ? formatDimensions(compareReport, template, commonClassifiers)
                        : compareReport,
                loading: false,
                error: undefined,
            });
        } catch (error) {
            if (isCanceledFn && isCanceledFn()) return;
            setState({ ...state, error, loading: false, report: undefined });
        }
    };

    const updateInteractionReport = async (isCanceledFn?: () => boolean) => {
        if (!isEqual(interactions, previousInteractions)) {
            setState({ ...state, loading: true });
            setPreviousInteractions(interactions);
            tryFetch(isCanceledFn);
        }
    };

    const fetchReport = async (isCanceledFn?: () => boolean) => {
        setState({ ...state, loading: true });
        // wait for filters and default metrics to be resolved before fetching report
        // removing this results in multiple report requests
        if (
            !filtersLoaded ||
            !areDefaultMetricsResolved(componentState, defaultMetrics, includeDefaultMetrics)
        ) {
            return;
        }
        tryFetch(isCanceledFn);
    };

    useCancelableEffect(fetchReport, [
        settings,
        filters,
        periods.primaryFrom,
        periods.primaryTo,
        periods.secondaryFrom,
        periods.secondaryTo,
        month,
        compareType,
        componentState.defaultMetrics,
        filtersLoaded,
    ]);

    useCancelableEffect(updateInteractionReport, [interactions]);

    return { ...state, refetchReport: fetchReport };
};
