import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'reactstrap';
import ErrorModal, { TraceIdButton } from 'platform/common/components/Errors/ErrorModal';
import ErrorReporting from 'platform/common/components/Errors/ErrorReporting';
import { useModal } from 'platform/common/components/Modal/Modal';
import { ErrorDetails } from 'platform/common/error.types';

interface Props {
    error: ErrorDetails;
    pageUrl?: string;
}

const ToastError = ({ error, pageUrl }: Props) => {
    const { showModal } = useModal();
    const isServerError = (error.response?.status ?? 0) >= 500;

    const handleOpenModal = () => showModal((toggle) => <ErrorModal error={error} toggle={toggle} />);

    return (
        <div>
            <span>{error.message}</span>
            <br />
            {error.traceId && isServerError && <TraceIdButton traceId={error.traceId} />}
            {(error.detailedMessage || error.request) && (
                <>
                    <ErrorReporting error={error} pageUrl={pageUrl} autoSend={isServerError} />
                    <div className="pt-1">
                        <Button color="link" className="p-0 text-white" onClick={handleOpenModal}>
                            Details
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export const toastError = (error: ErrorDetails, pageUrl?: string) => {
    if (!toast.isActive(error.message)) {
        toast(<ToastError error={error} pageUrl={pageUrl} />, {
            toastId: error.message,
            type: toast.TYPE.ERROR,
        });
    }
};

export const toastSuccess = (message: string) => {
    if (!toast.isActive(message)) {
        toast(<div>{message}</div>, {
            toastId: message,
            type: toast.TYPE.SUCCESS,
        });
    }
};

export const toastInfo = (message: string) => {
    if (!toast.isActive(message)) {
        toast(<div>{message}</div>, {
            toastId: message,
            type: toast.TYPE.INFO,
        });
    }
};
