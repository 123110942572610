import { ChangeEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { getSystemPrompts, sendChatMessages } from 'platform/chat/chat.service';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatDate } from 'platform/common/utils/date.util';
import { isProduction } from 'platform/common/utils/environment.util';
import { toastInfo } from 'platform/common/utils/toast.util';
import { User } from 'platform/userManagement/types/user.type';
import { buildLabelOptions } from '../Kanban/KanbanLabelSelect';
import { LabelNames, TaskBoardChange, TaskCreationData, TaskLane, TaskLocation } from '../task.types';

interface DeeplyTaskCreateData {
    labelKeys: string[];
    assigneeIds: number[];
    dueDate: string;
    name: string;
    laneId: number;
    description: string;
}

const mapUsers = (users: User[]) => users.map((user) => ({ userId: user.id, userName: user.name }));
const mapLanes = (lanes: TaskLane[]) => lanes.map((lane) => ({ laneId: lane.id, laneName: lane.name }));
const PROMPT_KEY = 'task_create';
const AGENT_ID = isProduction() ? 24 : 15;

interface Props {
    labelNames: LabelNames;
    lanes: TaskLane[];
    userOptions: User[];
    toggle: () => void;
    onTaskOpen: (id: number) => void;
    onTaskAdd: (location: TaskLocation, data: TaskCreationData) => Promise<TaskBoardChange[]>;
}

const DeeplyTaskCreateInput = ({ labelNames, lanes, userOptions, toggle, onTaskOpen, onTaskAdd }: Props) => {
    const [{ data: prompt }] = usePromise(
        undefined,
        () =>
            getSystemPrompts({ status: ['ACTIVE'] }).then((r) => r.find((p) => p.key === PROMPT_KEY)?.prompt),
        []
    );
    const users = mapUsers(userOptions);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [input, setInput] = useState('');

    useEffect(() => {
        setTimeout(() => inputRef.current?.focus(), 0); // need the timeout here to ensure nothing is entered in the field on initial focus
    }, []);

    const submit = async (withRedirect = false) => {
        if (!input) return;

        const lanesString = JSON.stringify(mapLanes(lanes));
        const usersString = JSON.stringify(users);
        const todayString = formatDate(new Date());
        const labelsString = JSON.stringify(buildLabelOptions(labelNames));
        const initialPrompt = `Given the following information: 
                                \n\nUsers: ${usersString}
                                \n\nToday: ${todayString}
                                \n\nLabels: ${labelsString}
                                \n\nLanes: ${lanesString}
                                ${prompt}`;
        toggle();
        toastInfo('Your task was submitted to deeply and should appear on the board shortly');

        const deeplyTaskRes = await sendChatMessages({
            agentId: AGENT_ID,
            messages: [
                {
                    role: 'user',
                    contentParts: [
                        { type: 'TEXT', content: initialPrompt },
                        { type: 'TEXT', content: input },
                    ],
                },
            ],
        });

        const { laneId, ...taskData }: DeeplyTaskCreateData = JSON.parse(deeplyTaskRes.message);

        const taskChanges = await onTaskAdd({ laneId, position: 0 }, { ...taskData, status: 'ACTIVE' });

        if (withRedirect && taskChanges[0] && 'taskId' in taskChanges[0]) {
            onTaskOpen(taskChanges[0]?.taskId as number);
        }
    };

    return (
        <InputWithAddOn
            ref={inputRef}
            placeholder="Enter task name, labels, assignees, due date, column and description"
            type="textarea"
            value={input}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
            onKeyDown={(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    submit(e.shiftKey);
                }
            }}
            rightAddOn={{
                onClick: submit,
                className: classNames(
                    'fa-solid fa-paper-plane-top',
                    input ? 'text-primary' : 'text-light-slate-gray'
                ),
            }}
        />
    );
};

export default DeeplyTaskCreateInput;
