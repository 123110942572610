import { Button } from 'reactstrap';
import { SourceMediumMapping } from 'platform/advertisers/advertiser.types';
import { TableCell } from 'platform/common/common.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditInput from 'platform/common/components/InlineEditInput/InlineEditInput';
import { arrayItemUpdate } from 'platform/common/utils/array.util';

type SourceMediumTableModel = SourceMediumMapping & { error?: string };

type Props = {
    onChange: (value: SourceMediumTableModel[]) => void;
    sourceMedium: SourceMediumMapping[];
};

const SourceMediumMappingsTable = ({ onChange, sourceMedium }: Props) => {
    const getColumns = (): TableColumn<SourceMediumMapping>[] => {
        const patternColumn: TableColumn<SourceMediumMapping> = {
            Header: 'Source-medium',
            accessor: 'pattern',
            Cell: (row) => (
                <InlineEditInput
                    onChange={(pattern) =>
                        onChange(
                            arrayItemUpdate(sourceMedium, row.index, { ...sourceMedium[row.index], pattern })
                        )
                    }
                    inputType="text"
                    value={row.original.pattern}
                    placeholder="Source-medium"
                />
            ),
            Footer: () => (
                <InlineEditInput
                    onChange={(pattern) => {
                        onChange([...sourceMedium, { pattern }]);
                    }}
                    inputType="text"
                    placeholder="Enter Source-medium"
                />
            ),
        };
        const productColumn = {
            Header: 'Product',
            accessor: (medium: SourceMediumTableModel) => medium.product,
            Cell: (row: TableCell<SourceMediumTableModel>) => (
                <InlineEditInput
                    onChange={(product) =>
                        onChange(
                            arrayItemUpdate(sourceMedium, row.index, { ...sourceMedium[row.index], product })
                        )
                    }
                    inputType="text"
                    value={row.original.product}
                    placeholder="Product"
                />
            ),
        };
        const unitIdColumn = {
            Header: 'Unit ID',
            accessor: (medium: SourceMediumTableModel) => String(medium.unitId),
            Cell: (row: TableCell<SourceMediumTableModel>) => (
                <InlineEditInput
                    onChange={(unitId) =>
                        onChange(
                            arrayItemUpdate(sourceMedium, row.index, { ...sourceMedium[row.index], unitId })
                        )
                    }
                    inputType="text"
                    value={row.original.unitId}
                    placeholder="Unit ID"
                />
            ),
        };
        const removeColumn = {
            maxWidth: 50,
            Cell: (row: TableCell<SourceMediumTableModel>) => (
                <Button
                    color="link"
                    className="text-muted"
                    onClick={() => onChange(sourceMedium.filter((m, index) => index !== row.index))}
                >
                    <i className="fa fa-times" />
                </Button>
            ),
        };

        return [patternColumn, productColumn, unitIdColumn, removeColumn];
    };
    return (
        <FormattedTable
            stickyHeader={false}
            data={sourceMedium}
            columns={getColumns()}
            NoDataComponent={() => null}
        />
    );
};

export default SourceMediumMappingsTable;
