import { CSSProperties, ReactNode } from 'react';
import { preventOverflow } from '@popperjs/core';
import classNames from 'classnames';
import PopoverButton from 'platform/common/components/PopoverButton/PopoverButton';

interface Props {
    style?: CSSProperties;
    children: ({ togglePopover }: { togglePopover: () => void }) => ReactNode;
    iconClassName: string;
}

const MediaInsertionPopoverButton = ({ children, iconClassName, style }: Props) => (
    <PopoverButton
        style={{
            ...style,
            width: style?.width ?? 560,
        }}
        modifiers={[{ ...preventOverflow, options: { altAxis: true } }]}
        className="MediaInsertionPopoverButton"
        icon={<i className={classNames('fal fa-lg', iconClassName)} />}
    >
        {children}
    </PopoverButton>
);

export default MediaInsertionPopoverButton;
