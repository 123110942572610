import { Button } from 'reactstrap';
import { Section, SECTION_LABELS } from 'platform/app/app.types';
import ButtonDropdown, {
    ButtonDropdownOption,
} from 'platform/common/components/ButtonDropdown/ButtonDropdown';

interface Props {
    actions: ButtonDropdownOption[];
    section: Section;
    canEdit: boolean;
}

const SectionHeader = ({ actions, section, canEdit }: Props) => (
    <div className="SectionsCard-header">
        <div className="SectionsCard-title">{SECTION_LABELS[section]}</div>
        {canEdit && (
            <>
                {actions.length === 1 && (
                    <Button color="primary" onClick={actions[0].action}>
                        {actions[0].label}
                    </Button>
                )}
                {actions.length > 1 && (
                    <ButtonDropdown className="me-2" items={actions} container={document.body}>
                        Add new
                    </ButtonDropdown>
                )}
            </>
        )}
    </div>
);

export default SectionHeader;
