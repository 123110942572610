import { NavLink as RouterNavLink, Route, Routes } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import { USER_MANAGEMENT_TAB_LIST } from '../../constants/tabs.constant';

const tabs = USER_MANAGEMENT_TAB_LIST.map((tab) => (
    <NavItem key={tab.url}>
        <NavLink to={tab.url} tag={RouterNavLink}>
            {tab.label}
        </NavLink>
    </NavItem>
));

const routes = USER_MANAGEMENT_TAB_LIST.map((tab) => (
    <Route key={tab.url} path={`${tab.url}/*`} element={<tab.component />} />
));

const UserManagementPage = () => (
    <div>
        <HeaderContainer>
            <PageHeader title="User management" />
        </HeaderContainer>
        <BodyContainer>
            <div className="custom-tabs">
                <Nav tabs>{tabs}</Nav>
            </div>
        </BodyContainer>
        <Routes>{routes}</Routes>
    </div>
);

export default UserManagementPage;
