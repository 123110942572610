import { Card, CardBody } from 'reactstrap';
import UserFlow from '../../../segments/components/UserFlow/UserFlow';

type Props = {
    advertiserId: number;
};

const UserFlowFunnelCard = ({ advertiserId }: Props) => (
    <Card>
        <CardBody className="p-3">
            <h5 className="mb-3">User Flow Analysis</h5>
            <div className="border-top" />
            <UserFlow advertiserId={advertiserId} chartHeight={300} />
        </CardBody>
    </Card>
);

export default UserFlowFunnelCard;
