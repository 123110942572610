import { AdvertiserAccount } from 'platform/advertisers/advertiser.types';
import ExternalAccountsModal from 'platform/advertisers/components/AdvertiserFormIntegrations/ExternalAccountsModal/ExternalAccountsModal';
import FormRow from 'platform/common/components/FormRow/FormRow';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import { useModal } from 'platform/common/components/Modal/Modal';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { ADVERTISER_INTEGRATION_HELP_KEY } from './AdvertiserIntegration';

interface Props {
    accounts: AdvertiserAccount[];
    externalSystem: ExternalSystem;
    onChange: (field: string, value: any) => void;
    field: string;
}

const MultipleExternalAccounts = ({ accounts = [], externalSystem, onChange, field }: Props) => {
    const { showModal } = useModal();
    return (
        <FormRow
            rowClassName="my-2"
            helpKey={`${field}.${ADVERTISER_INTEGRATION_HELP_KEY}_account`}
            label="External accounts"
        >
            <LabelLink
                onClick={() =>
                    showModal((toggle) => (
                        <ExternalAccountsModal
                            toggle={toggle}
                            externalAccounts={accounts}
                            onChange={(value) => onChange(`${field}.accounts`, value)}
                            externalSystem={externalSystem}
                        />
                    ))
                }
            >
                {accounts.length} account(s)
            </LabelLink>
        </FormRow>
    );
};

export default MultipleExternalAccounts;
