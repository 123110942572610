import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import { helpSelectors } from 'platform/common/ducks/help.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import useToggle from 'platform/common/hooks/useToggle';
import HelpEdit from '../HelpEdit/HelpEdit';
import IconButton from '../IconButton/IconButton';
import LabelLink from '../LabelLink/LabelLink';
import { useModal } from '../Modal/Modal';

interface Props {
    helpKey: string;
}

const PageHelp = ({ helpKey }: Props) => {
    const helpValue = useSelector(helpSelectors.getHelpValue(helpKey));
    const [showFullText, toggleFullText] = useToggle(false);
    const [textOverflow, setTextOverflow] = useToggle(false);
    const { showModal } = useModal();
    const canEdit = useFeature('HELP_EDIT');
    const canView = useFeature('HELP_VIEW') || canEdit;
    const showHelp = !!helpValue || canEdit;
    const ref = useRef<HTMLDivElement>(null);

    const handleEdit = () => {
        showModal((toggle) => <HelpEdit help={{ key: helpKey, value: helpValue }} toggle={toggle} />);
    };

    const handleResize = debounce(() => {
        if (!ref.current) {
            return;
        }
        setTextOverflow(ref.current.scrollWidth > ref.current.offsetWidth);
    }, 50);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!canView || !showHelp) {
        return null;
    }

    return (
        <div className="d-flex w-100 border rounded p-3 mb-3">
            {!!helpValue && (
                <>
                    <i style={{ lineHeight: 1.5 }} className="fal fa-info-circle text-muted me-2" />
                    <div
                        ref={ref}
                        className={classNames('text-muted overflow-hidden', {
                            'text-truncate': textOverflow && !showFullText,
                            'text-nowrap': !showFullText,
                            'display-linebreak': showFullText,
                        })}
                    >
                        {helpValue}
                        {showFullText && (
                            <LabelLink
                                className="fw-bold text-nowrap text-decoration-underline ms-1"
                                onClick={() => toggleFullText()}
                            >
                                See less
                            </LabelLink>
                        )}
                        {canEdit && (
                            <IconButton className="d-inline" onClick={handleEdit}>
                                <i className="far fa-pen-line text-primary ms-2" />
                            </IconButton>
                        )}
                    </div>
                    {textOverflow && !showFullText && (
                        <LabelLink
                            className="fw-bold text-nowrap text-decoration-underline ms-1"
                            onClick={() => toggleFullText()}
                        >
                            See more
                        </LabelLink>
                    )}
                </>
            )}
            {!helpValue && canEdit && (
                <>
                    <IconButton onClick={handleEdit}>
                        <i className="fa fa-plus text-primary me-2" />
                        <div className="text-primary">Add explanation </div>
                    </IconButton>
                    &nbsp;for the chosen page. Explanation will help new users understand how things work. You
                    can always edit it later.
                </>
            )}
        </div>
    );
};

export default PageHelp;
