import { useParams } from 'react-router-dom';
import { AdvertiserTagFormModel } from 'platform/advertiserTag/advertiserTag.types';
import AdvertiserTagForm, {
    ADSERVER_CLICK_ID,
} from 'platform/advertiserTag/components/AdvertiserTagForm/AdvertiserTagForm';
import { toFormModel, toApi } from 'platform/advertiserTag/mappers/advertiserTag.mapper';
import {
    updateAdvertiserTag,
    createAdvertiserTag,
    updateBatchedSegments,
    fetchStandardEvents,
    fetchAdvertiserTagTrackersInfo,
    fetchAdvertiserTag,
    fetchSegments,
} from 'platform/advertiserTag/services/advertiserTag.service';
import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { getActiveAdvertiserId } from 'platform/common/utils/advertiser.util';
import { fetchVendors } from 'platform/vendors/vendors.service';

const AdvertiserTagFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const handleSubmit = async (model: AdvertiserTagFormModel) => {
        const { advertiserTag, segments } = toApi(model);
        await (advertiserTag.id ? updateAdvertiserTag(advertiserTag) : createAdvertiserTag(advertiserTag));

        return updateBatchedSegments(advertiserTag.advertiserId!, segments);
    };

    const handleCreateOpen = async () => {
        const advertiserId = getActiveAdvertiserId();
        const [standardEvents, advertiser, trackersInfo, vendors] = await Promise.all([
            fetchStandardEvents(),
            fetchAdvertiser(advertiserId),
            fetchAdvertiserTagTrackersInfo(),
            fetchVendors({ clickIdEnabled: true }),
        ]);

        return toFormModel({
            advertiser,
            advertiserTag: {
                internalLogDataIdTypes: [],
                fireLoggingPixel: 'ALWAYS',
                clickIdSystems: [ADSERVER_CLICK_ID.value, ...vendors.map((v) => v.externalSystem)],
            },
            segments: [],
            standardEvents,
            trackersInfo,
            vendors,
        });
    };

    const handleEditOpen = async () => {
        const [advertiserTag, segments, trackersInfo, standardEvents, vendors] = await Promise.all([
            fetchAdvertiserTag(id),
            fetchSegments({ advertiserTagId: id }),
            fetchAdvertiserTagTrackersInfo(),
            fetchStandardEvents(),
            fetchVendors({ clickIdEnabled: true }),
        ]);
        const advertiser = await fetchAdvertiser(advertiserTag.advertiserId);

        return toFormModel({
            advertiser,
            advertiserTag,
            segments,
            standardEvents,
            trackersInfo,
            vendors,
        });
    };

    return (
        <FormContainer
            helpKey="advertiser_tag_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            onOpen={() => (id ? handleEditOpen() : handleCreateOpen())}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            isEdit={!!id}
            sidePanel
            showEditWarning={false}
            size={SIDE_PANEL_SIZES.NARROW}
        >
            {(props) => <AdvertiserTagForm {...props} />}
        </FormContainer>
    );
};

export default AdvertiserTagFormContainer;
