import axios from 'platform/axios/axiosBff';
import { CustomMetric } from './customMetric.types';

export const getCustomMetrics = (advertiserId: number) =>
    axios
        .get<CustomMetric[]>('/campaign/api/custom-metrics', { params: { advertiserId } })
        .then((response) => response.data);

export const getCustomMetric = (advertiserId: number, key: string) =>
    axios
        .get<CustomMetric>(`/campaign/api/custom-metrics/${key}`, { params: { advertiserId } })
        .then((response) => response.data);

export const storeCustomMetric = (advertiserId: number, metric: CustomMetric) =>
    axios
        .put<void>(`/campaign/api/custom-metrics/${metric.key}`, metric, { params: { advertiserId } })
        .then((response) => response.data);

export const deleteCustomMetric = (advertiserId: number, key: string) =>
    axios
        .delete<void>(`/campaign/api/custom-metrics/${key}`, { params: { advertiserId } })
        .then((response) => response.data);
