import { Category, CategoryRootCode } from './category.types';

export const getCategoryNames = (
    codes: string[] | undefined,
    allCategories: Category[],
    rootCode: CategoryRootCode
): string =>
    allCategories
        .filter((c) => c.rootCode === rootCode && codes?.includes(c.code))
        .map((c) => c.name)
        .join(', ');
