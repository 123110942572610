import { useQuery } from '@apollo/client';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { AdvertiserTag } from 'platform/dashboard/dashboard.service';
import SegmentVendorList from 'platform/segments/components/SegmentSyncPanel/SegmentVendorList';
import segmentSyncPanelQuery from 'platform/segments/queries/segmentSyncPanelQuery.graphql';

type Props = {
    segmentId: number;
};

type GraphqlResponse = {
    segment: {
        advertiser: {
            activeAdvertiserTag?: AdvertiserTag;
        };
    };
};

const SegmentSyncPanel = ({ segmentId }: Props) => {
    const { data, loading } = useQuery<GraphqlResponse>(segmentSyncPanelQuery, {
        variables: {
            id: segmentId,
        },
        fetchPolicy: 'network-only',
    });

    return (
        <div className="SegmentSyncPanel p-3">
            <div className="mb-3 fw-bold">Segment sharing</div>
            {loading ? (
                <Placeholder height="80px" />
            ) : (
                <SegmentVendorList
                    advertiserTag={data?.segment.advertiser.activeAdvertiserTag}
                    style={{ maxHeight: 800 }}
                />
            )}
        </div>
    );
};

export default SegmentSyncPanel;
