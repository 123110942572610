import { createSelector } from 'reselect';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { authSelectors } from './auth.duck';

export type AppState =
    | { type: 'READY' }
    | { type: 'PENDING' }
    | { type: 'ERROR'; message: string; originalError?: any };

export const appStateSelectors = {
    getState: createSelector(
        authSelectors.isLoggedIn,
        activeAdvertiserSelectors.root,
        (isLoggedIn, activeAdvertiserState): AppState => {
            // App login part is responsible for its error state
            if (!isLoggedIn) return { type: 'PENDING' };

            switch (activeAdvertiserState.type) {
                case 'IDLE':
                case 'PENDING':
                    return { type: 'PENDING' };
                case 'READY':
                    return { type: 'READY' };
                case 'ERROR':
                    return {
                        type: 'ERROR',
                        message: 'Failed to load advertisers',
                        originalError: activeAdvertiserState.error,
                    };
                default:
                    return {
                        type: 'ERROR',
                        message: 'Invalid state',
                    };
            }
        }
    ),
};
