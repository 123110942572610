import { useCallback, useEffect, useState } from 'react';
import { Button, Progress } from 'reactstrap';
import { AdvertiserAccountKey } from 'platform/advertisers/advertiser.types';
import {
    BatchImportSummary,
    cancelBatchImport,
    getBatchImportSummary,
    startBatchImport,
} from 'platform/campaign/campaign/services/campaignImport.service';
import { IntegrationStatusLabel } from 'platform/common/components/DspIntegrationIcon/IntegrationStatusLabel';
import InfoIconTooltip from 'platform/common/components/InfoIconTooltip/InfoIconTooltip';
import Popover from 'platform/common/components/Popover/Popover';
import Spinner from 'platform/common/components/Spinner/Spinner';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { IntegrationStatus } from 'platform/common/constants/externalIntegration.constant';
import { formatDateTime } from 'platform/common/utils/date.util';

type Props = {
    accountKey: AdvertiserAccountKey;
    onFilterCampaigns: (status: IntegrationStatus) => any;
};

export const CampaignBatchImportPanel = ({ accountKey, onFilterCampaigns }: Props) => {
    const [summary, setSummary] = useState<BatchImportSummary>();
    const loadSummary = useCallback(() => {
        getBatchImportSummary(accountKey).then(setSummary);
    }, [accountKey]);

    useEffect(loadSummary, [loadSummary]);

    useEffect(() => {
        if (summary?.process?.status === 'IN_PROGRESS') {
            const timeoutId = setTimeout(loadSummary, 10000);
            return () => clearTimeout(timeoutId);
        }
        return () => {};
    }, [summary, loadSummary]);

    if (!summary) {
        return <Spinner />;
    }

    const { process, progress } = summary;
    const finished = progress.finishedCampaigns?.length ?? 0;
    const pending = progress.pendingCampaigns?.length ?? 0;
    const total = finished + pending;

    return (
        <div className="d-flex gap-1 align-items-center">
            <Tooltip
                autoHide={false}
                renderTooltip={() => (
                    <>
                        {(process.retry?.required || !!process.retry?.count) && (
                            <div className="text-warning">
                                <div className="my-2">
                                    Some of the campaigns failed due to temporary issues. Their import
                                    {process.retry.required ? ' will be' : ' has been'} retried.
                                </div>
                                <div className="my-2">Attempt #{process.retry.count + 1}</div>
                            </div>
                        )}
                        <div className="my-2 d-flex align-items-center">
                            {process.status && process.status !== 'NEVER' ? (
                                <IntegrationStatusLabel status={process.status} />
                            ) : (
                                <span className="text-muted">Never launched</span>
                            )}
                            {process.status === 'IN_PROGRESS' && (
                                <div className="ms-3" style={{ width: 250 }}>
                                    <Progress animated color="info" value={finished} max={total} />
                                </div>
                            )}
                            {(process.errorMessage || process.warningMessage) && (
                                <div
                                    className={`ms-3 ${
                                        process.errorMessage ? 'text-danger' : 'text-warning'
                                    }`}
                                >
                                    <Popover
                                        preventOverflow
                                        renderPopover={() => (
                                            <div className="p-2">
                                                {process.errorMessage || process.warningMessage}
                                            </div>
                                        )}
                                    >
                                        <i
                                            role="button"
                                            tabIndex={-1}
                                            className="fa-lg fa fa-exclamation-circle"
                                        />
                                    </Popover>
                                </div>
                            )}
                            {process.status === 'ERROR' && (
                                <Button className="ms-3" onClick={() => onFilterCampaigns('ERROR')}>
                                    Show failed campaigns
                                </Button>
                            )}
                        </div>

                        {process.status === 'IN_PROGRESS' && (
                            <div className="my-2">
                                So far re-imported {finished} out of {total} eligible campaigns
                            </div>
                        )}

                        {process.startedOn && (
                            <div className="my-2">
                                Started {formatDateTime(process.startedOn)} by {process.startedBy}
                            </div>
                        )}

                        {process.endedOn && (
                            <div className="my-2">Ended {formatDateTime(process.endedOn)}</div>
                        )}
                    </>
                )}
            >
                {process.status !== 'IN_PROGRESS' ? (
                    <>
                        <Button
                            color="secondary"
                            className="text-nowrap"
                            onClick={() => startBatchImport(accountKey).then(setSummary)}
                        >
                            Re-import all eligible campaigns
                        </Button>
                        <InfoIconTooltip className="ms-2">
                            Eligible campaigns satisfy all of the following criteria:
                            <ul className="mt-1 mb-0">
                                <li>programmatic</li>
                                <li>active (not paused or stopped)</li>
                                <li>runtime has not ended</li>
                                <li>associated with a DAP campaign</li>
                            </ul>
                        </InfoIconTooltip>
                    </>
                ) : (
                    <Button color="secondary" onClick={() => cancelBatchImport(accountKey).then(setSummary)}>
                        Cancel batch import
                    </Button>
                )}
            </Tooltip>
        </div>
    );
};
