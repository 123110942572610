import { Button } from 'reactstrap';

const KanbanColumnFormButtons = ({ onCancel }: { onCancel: () => void }) => (
    <div className="Kanban-column-form-buttons">
        <Button color="primary" type="submit">
            Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
    </div>
);

export default KanbanColumnFormButtons;
