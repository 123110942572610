import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { activeAdvertiserSelectors } from '../ducks/activeAdvertiser.duck';

type AdditionalAttributeKey =
    | 'master-advertiser-id'
    | 'facebook.pixel-id'
    | 'facebook.page-id'
    | 'analytics.vat-rate'
    | 'analytics.opt-in-rate'
    | 'campaign-importer.do-not-import-ads';

export const useAdvancedSetting = (key: AdditionalAttributeKey) => {
    const advertiser = useTypedSelector(activeAdvertiserSelectors.activeAdvertiser);
    return advertiser.additionalAttributes?.[key];
};
