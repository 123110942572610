import { UncontrolledTooltip } from 'reactstrap';
import './StatisticTooltip.scss';

export interface Tooltip {
    key: string;
    name: string;
    altName?: string;
    description?: string;
}

interface Props {
    target?: any;
    tooltip: Tooltip;
}

const StatisticTooltip = ({ target, tooltip: { altName, name, description, key } }: Props) => {
    if (!target) {
        return null;
    }

    return (
        <UncontrolledTooltip
            delay={50}
            placement="top-start"
            target={target}
            className="StatisticTooltip"
            boundariesElement="viewport"
            hideArrow
        >
            {(altName || name) && <div className="StatisticTooltip-header">{altName || name}</div>}
            {description && <div className="StatisticTooltip-description">{description}</div>}
            <div className="StatisticTooltip-key">[{key}]</div>
        </UncontrolledTooltip>
    );
};

export default StatisticTooltip;
