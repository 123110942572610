import axiosBff from 'platform/axios/axiosBff';
import { IdResponse } from 'platform/common/common.types';
import { Status } from 'platform/common/constants/status.constant';
import {
    Advertiser,
    AdvertiserAccountKey,
    AdvertiserSummary,
    AdvertiserType,
    GcmParentAccount,
    GcmAdvertiser,
    ContactInformation,
    ExternalAdvertiserAccount,
    AdvertiserToken,
    FloodlightActivity,
    AdvertiserExternalIntegration,
    AdvertiserFormModel,
    CompleteAdvertiserTokenParams,
} from '../advertiser.types';

export const fetchAdvertiser = (advertiserId: number) =>
    axiosBff.get<Advertiser>(`/advertiser/api/v2/advertisers/${advertiserId}`).then((res) => res.data);

export const getAdvertiserSummaries = (params: { ids?: number[]; state?: Status[]; type?: AdvertiserType }) =>
    axiosBff
        .get<AdvertiserSummary[]>(`/advertiser/api/v2/advertisers/summaries`, { params })
        .then((res) => res.data);

export const getAdvertiserSummary = (id: number) =>
    getAdvertiserSummaries({ ids: [id] }).then((result) => {
        if (!result?.length) throw new Error(`Can't find advertiser by id ${id}`);
        return result[0];
    });

export const createAdvertiser = (advertiser: Omit<AdvertiserFormModel, 'id'>) =>
    axiosBff.post<IdResponse>('/advertiser/api/v2/advertisers', advertiser).then((res) => res.data);

export const updateAdvertiser = (advertiser: AdvertiserFormModel) =>
    axiosBff.put(`/advertiser/api/v2/advertisers/${advertiser.id}`, advertiser);

export const updateAdvertiserIntegrations = (
    id: number,
    externalSystemIntegrations: AdvertiserExternalIntegration[]
) => axiosBff.patch(`/advertiser/api/v2/advertisers/${id}/integrations`, { externalSystemIntegrations });

export const updateAdvertiserAttributes = (id: number, additionalAttributes: Record<string, string> | null) =>
    axiosBff.put(`/advertiser/api/v2/advertisers/${id}/additional-attributes`, { additionalAttributes });

export const fetchContactInformation = (advertiserId: number) =>
    axiosBff
        .get<ContactInformation>(`/advertiser/api/v2/advertisers/${advertiserId}/contact-information`)
        .then((res) => res.data);

export const updateContactInformation = (advertiserId: number, contactInfo: ContactInformation) =>
    axiosBff.put(`/advertiser/api/v2/advertisers/${advertiserId}/contact-information`, contactInfo);

export const moveAdvertiser = (id: number, parentId: number | undefined) =>
    axiosBff.put(`/advertiser/api/v2/advertisers/${id}/parent`, { parentId });

export const fetchExternalAdvertiserAccount = (key: AdvertiserAccountKey) =>
    axiosBff
        .get<ExternalAdvertiserAccount>(`/advertiser/api/external-account`, {
            params: {
                advertiserId: key.advertiserId,
                system: key.externalSystem,
                externalId: key.externalAdvertiserId,
            },
        })
        .then((res) => res.data);

export const fetchSecrets = (system: String) =>
    axiosBff
        .get<{ clientId: string; clientSecret: string }>(`/advertiser/api/secrets/${system}`)
        .then((res) => res.data);

export const initAdvertiserTokenCreation = (system: string, advertiserId: number) =>
    axiosBff
        .post<AdvertiserToken>(`/advertiser/api/secrets/${system}/tokens`, { advertiserId })
        .then((res) => res.data);

export const resetAdvertiserToken = (system: string, advertiserId: number) =>
    axiosBff
        .put<AdvertiserToken>(
            `/advertiser/api/secrets/${system}/tokens/reset`,
            {},
            { params: { advertiserId } }
        )
        .then((res) => res.data);

export const completeAdvertiserToken = (params: CompleteAdvertiserTokenParams) =>
    axiosBff
        .put<AdvertiserToken>(
            `/advertiser/api/secrets/${params.system}/tokens/complete`,
            { oneTimeHash: params.oneTimeHash, refreshToken: params.refreshToken },
            { params: { advertiserId: params.advertiserId } }
        )
        .then((res) => res.data);

export const fetchAdvertiserToken = (system: string, advertiserId: number) =>
    axiosBff
        .get<AdvertiserToken>(`/advertiser/api/secrets/${system}/tokens`, {
            params: { advertiserId },
        })
        .then((res) => res.data);

export const fetchGcmParentAccounts = () =>
    axiosBff.get<GcmParentAccount[]>(`/advertiser/api/gcm/parent-accounts`).then((r) => r.data);

export const fetchGcmAdvertisers = () =>
    axiosBff.get<GcmAdvertiser[]>(`/advertiser/api/gcm/advertisers`).then((r) => r.data);

export const fetchFloodlightActivities = (externalAdvertiserId: string) =>
    axiosBff
        .get<FloodlightActivity[]>(`/advertiser/api/gcm/floodlight-activities`, {
            params: { externalAdvertiserId },
        })
        .then((r) => r.data);
