import { Component, ReactNode } from 'react';
import { Button } from 'reactstrap';
import ErrorReporting from 'platform/common/components/Errors/ErrorReporting';

interface Props {
    children: ReactNode;
    onClose?: () => void;
}

interface State {
    error: string | null;
    pageUrl: string;
}

class ErrorBoundary extends Component<Props, State> {
    state: State = { error: null, pageUrl: '' };

    componentDidCatch(error: Error) {
        const pageUrl = window.location.href;
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({ error: error.message, pageUrl });
    }

    render() {
        const { children, onClose } = this.props;
        const { error, pageUrl } = this.state;

        if (error) {
            return (
                <>
                    <div className="p-3">
                        <h1>Something went wrong.</h1>
                        <p>{error}</p>
                        <ErrorReporting error={{ message: error }} pageUrl={pageUrl} />
                        <Button className="me-1" onClick={() => this.setState({ error: null })}>
                            Refresh
                        </Button>
                        {onClose && (
                            <Button className="ms-1" onClick={onClose}>
                                Close
                            </Button>
                        )}
                    </div>
                </>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
