import moment from 'moment';
import { arrayItemInsert, removeById, updateById } from 'platform/common/utils/array.util';
import { attachmentReducer } from 'platform/task/reducers/attachmentReducer';
import { Task, TaskChange, TaskLane, TaskLocation } from '../task.types';
import { findTask, isParentTask } from '../task.util';

export type TaskTransition =
    | { type: 'TASK_MOVE_PENDING'; taskId: number; location: TaskLocation }
    | { type: 'TASK_SYNC_PENDING'; taskId: number }
    | { type: 'TASK_SYNC_COMPLETED'; taskId: number };

export const taskReducer = (lanes: TaskLane[], e: TaskTransition | TaskChange): TaskLane[] => {
    switch (e.type) {
        case 'TASK_MOVE_PENDING':
            return moveTask(lanes, e.taskId, e.location, { syncing: true });
        case 'TASK_SYNC_PENDING':
            return modifyTask(lanes, e.taskId, (task) => ({ ...task, syncing: true }));
        case 'TASK_SYNC_COMPLETED':
            return modifyTask(lanes, e.taskId, (task) => ({ ...task, syncing: false }));
        case 'TASK_ADDED':
            return addTask(lanes, getTaskLocation(e), {
                ...e.details.data,
                id: e.taskId,
                createdOn: e.updatedOn,
                createdBy: e.updatedBy,
                updatedOn: e.updatedOn,
                updatedBy: e.updatedBy,
            });
        case 'TASK_UPDATED':
            return modifyTask(lanes, e.taskId, (task) => ({
                ...task,
                ...e.details.data,
                updatedOn: e.updatedOn,
                updatedBy: e.updatedBy,
            }));
        case 'TASK_MOVED':
        case 'TASK_REORDERED':
            return moveTask(lanes, e.taskId, getTaskLocation(e), {
                lastMovedToLane: e.laneId ? moment().toISOString() : undefined,
                updatedOn: e.updatedOn,
                updatedBy: e.updatedBy,
            });
        case 'TASK_DELETED':
            return deleteTask(lanes, e.taskId);
        case 'ATTACHMENT_ADDED':
        case 'ATTACHMENT_DELETED':
            return modifyTask(lanes, e.taskId, (task) => ({
                ...task,
                attachments: attachmentReducer(task.attachments ?? [], e),
            }));
        default:
            return lanes;
    }
};

const getTaskLocation = (e: {
    laneId?: number;
    parentTaskId?: number;
    details: { position: number };
}): TaskLocation => {
    const { position } = e.details;
    return e.parentTaskId ? { parentTaskId: e.parentTaskId, position } : { laneId: e.laneId!, position };
};

const alterChildTasks = (
    lanes: TaskLane[],
    laneId: number,
    parentTaskId: number | undefined,
    transform: (tasks: Task[]) => Task[]
): TaskLane[] =>
    updateById(lanes, laneId, (lane) => ({
        ...lane,
        tasks: parentTaskId
            ? updateById(lane.tasks, parentTaskId, (task) => ({
                  ...task,
                  subtasks: transform(task.subtasks ?? []),
              }))
            : transform(lane.tasks),
    }));

const addTask = (lanes: TaskLane[], location: TaskLocation, task: Task) => {
    const laneId = isParentTask(location) ? findTask(lanes, location.parentTaskId)?.lane.id : location.laneId;
    if (!laneId) return lanes;
    const parentTaskId = isParentTask(location) ? location.parentTaskId : undefined;
    return alterChildTasks(lanes, laneId, parentTaskId, (tasks) =>
        arrayItemInsert(tasks, task, location.position)
    );
};

const modifyTask = (lanes: TaskLane[], taskId: number, update: (task: Task) => Task) => {
    const found = findTask(lanes, taskId);
    if (!found) return lanes;
    return alterChildTasks(lanes, found.lane.id, found.parentTaskId, (tasks) =>
        updateById(tasks, taskId, update)
    );
};

const moveTask = (lanes: TaskLane[], taskId: number, location: TaskLocation, changes: Partial<Task>) => {
    const found = findTask(lanes, taskId);
    if (!found) return lanes;
    const tmpLanes = alterChildTasks(lanes, found.lane.id, found.parentTaskId, (tasks) =>
        removeById(tasks, taskId)
    );
    return addTask(tmpLanes, location, { ...found.task, ...changes });
};

const deleteTask = (lanes: TaskLane[], taskId: number) => {
    const found = findTask(lanes, taskId);
    if (!found) return lanes;
    return alterChildTasks(lanes, found.lane.id, found.parentTaskId, (tasks) => removeById(tasks, taskId));
};
