import { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import './TableFooterRow.scss';

type Props = {
    className?: string;
    children: ReactNode;
    style?: CSSProperties;
};

const TableFooterRow = ({ className, children, style }: Props) => (
    <div className={classNames('TableFooterRow', className)} style={style}>
        {children}
    </div>
);

export default TableFooterRow;
