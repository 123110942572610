import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash-es';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { AnalyticsSettings, AnalyticsUrlSettings, ReportFilter } from 'platform/analytics/analytics.types';
import { updateTableComponent } from 'platform/analytics/analytics.util';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import { dateFilterActions } from 'platform/common/ducks/dateFilter.duck';
import { analyticsSelectors } from '../ducks/analytics.duck';

interface Props {
    isReportLoading: boolean;
    urlSettings: AnalyticsUrlSettings;
    settings: AnalyticsSettings;
    reportId?: number;
}

export const addUrlFilters = (
    reportFilters: ReportFilter[],
    urlFilters: ReportFilter[] = []
): ReportFilter[] => [
    ...reportFilters.map(
        (reportFilter) => urlFilters.find((urlFilter) => urlFilter.key === reportFilter.key) ?? reportFilter
    ),
    ...urlFilters.filter(
        (urlFilter) => !reportFilters.find((reportFilter) => reportFilter.key === urlFilter.key)
    ),
];

export const useApplyUrlSettings = ({ isReportLoading, urlSettings, settings, reportId }: Props) => {
    const dispatch = useDispatch();
    const reportFilters = useSelector(analyticsSelectors.filters);
    const filters = reportFilters.filter((f) => !!f.values.length);

    useDeepCompareEffect(() => {
        if (isReportLoading || isEmpty(urlSettings) || reportId !== settings.analyticsReportId) return;
        const components = settings.components.map((component) =>
            updateTableComponent(component, urlSettings)
        );

        if (isEqual(components, settings.components) && isEqual(filters, urlSettings.filters)) return;

        dispatch(
            analyticsSettingsActions.changeSettings({
                ...settings,
                filters: addUrlFilters(settings.filters, urlSettings.filters),
                components,
            })
        );

        if (urlSettings?.periods) {
            dispatch(dateFilterActions.changePeriods(urlSettings.periods));
        }
    }, [urlSettings, isReportLoading]);
};
