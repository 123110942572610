import { Button, Card, CardBody, CardHeader, FormFeedback } from 'reactstrap';
import { FieldArray } from 'formik';
import { isEmpty, uniqBy } from 'lodash-es';
import RemovableActionRule from 'platform/campaign/globalPreset/components/CustomActionRule/RemovableActionRule';
import { CustomActionRule } from 'platform/campaign/globalPreset/globalPreset.types';

interface Props {
    field: string;
    rules: CustomActionRule[];
}

const validateRules = (rules?: CustomActionRule[]) => {
    if (uniqBy(rules, 'dimension').length !== rules?.length) {
        return 'Dimensions have to be unique';
    }
    return isEmpty(rules) ? 'At least one rule must be selected' : undefined;
};

const RemovableActionRules = ({ field, rules }: Props) => {
    const error = validateRules(rules);

    return (
        <FieldArray name={field}>
            {({ push, remove }) => (
                <div className="row d-flex">
                    <Card className="flex-column d-flex flex-fit bg-light">
                        <CardHeader>
                            <b>First</b> matching rule will be used
                        </CardHeader>
                        <CardBody>
                            {rules.map((rule, index) => (
                                <RemovableActionRule
                                    rule={rule}
                                    field={`${field}[${index}]`}
                                    onRuleConditionRemove={(parentIndex) => remove(parentIndex)}
                                    parentIndex={index}
                                />
                            ))}
                            {error && <FormFeedback className="d-block">{error}</FormFeedback>}
                            <Button
                                onClick={() =>
                                    push({
                                        expression: {
                                            and: [
                                                {
                                                    condition: {
                                                        column: undefined,
                                                        operator: undefined,
                                                        value: undefined,
                                                    },
                                                },
                                            ],
                                        },
                                    })
                                }
                                color="primary"
                                className="mt-2"
                            >
                                Add rule
                            </Button>
                        </CardBody>
                    </Card>
                </div>
            )}
        </FieldArray>
    );
};

export default RemovableActionRules;
