import { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { get, isArray, isEqual } from 'lodash-es';
import { EditableDimension } from 'platform/analytics/analytics.types';
import FormCountryLabelsSelect from 'platform/campaign/common/FormCountryLabelsSelect';
import FormFlightLabelSelect from 'platform/campaign/common/FormFlightLabelSelect';
import FormProductLabelSelect from 'platform/campaign/common/FormProductLabelSelect';
import FormProductSubLabelSelect from 'platform/campaign/common/FormProductSubLabelSelect';
import FormTimeOffersSelect from 'platform/campaign/common/FormTimeOffersSelect';
import { SelectItem } from 'platform/common/common.types';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditContainer from 'platform/common/components/InlineEditContainer/InlineEditContainer';
import { EMPTY_SYMBOL } from 'platform/common/constants/common.constant';
import { FormCreatableSelectProps } from 'platform/formik/FormCreatableSelect/FormCreatableSelect';
import { ReportTableFormModel } from './ReportTableFormContainer';
import './EditableReportTableCell.scss';

const isElementXPixelsAwayFromBottom = (element: HTMLElement | null, x: number) => {
    if (!element) {
        return false;
    }
    const windowHeight = window.innerHeight;
    const scrollTop = window.scrollY || window.pageYOffset;

    const rect = element.getBoundingClientRect();
    const elementTop = rect.top + scrollTop;
    const elementHeight = rect.height;

    return windowHeight + scrollTop - (elementTop + elementHeight) <= x;
};

const EMPTY_OPTION = {
    label: EMPTY_SYMBOL,
    value: '',
};

export const isValueChanged = (
    formValue: SelectItem | SelectItem[] | number | undefined,
    defaultValue: string | number | string[]
): boolean => {
    if (!formValue) return false;

    if (isArray(formValue)) {
        return !isEqual(
            formValue.map(({ label }) => label),
            defaultValue
        );
    }
    if (typeof formValue === 'number') {
        return formValue !== defaultValue;
    }

    return (formValue.value || null) !== defaultValue;
};

interface Props {
    cellNode: ReactNode;
    column: TableColumn;
    original: any;
    rowIndex: number;
    defaultValue: string | number | string[];
}

const EditableReportTableCell = ({ cellNode, column, original, rowIndex, defaultValue }: Props) => {
    const cellRef = useRef(null);
    const isCellCloseToPageBottom = isElementXPixelsAwayFromBottom(cellRef.current, 400);
    const baseFieldPath = `rowChanges.${rowIndex}`;
    const path = `rowChanges.${rowIndex}.${column.id}`;
    const { setFieldValue, values } = useFormikContext<ReportTableFormModel>();
    const formValue = get(values, path) as SelectItem<string> | SelectItem<string>[] | undefined;
    const isChanged = isValueChanged(formValue, defaultValue);

    const setIds = () => {
        setFieldValue(`${baseFieldPath}.strategy_id`, original?.strategy_id);
        setFieldValue(`${baseFieldPath}.campaign_id`, original?.campaign_id);
    };

    const getCommonProps = (
        toggleEditMode: () => void
    ): Omit<FormCreatableSelectProps, 'options' | 'onNewOption'> => ({
        isClearable: false,
        autoFocus: true,
        defaultMenuIsOpen: true,
        menuPlacement: isCellCloseToPageBottom ? 'top' : 'bottom', // workaround: Flipping menu manually as react-select 'auto' option doesn't work in this case
        menuPosition: 'fixed',
        className: 'w-100',
        name: path,
        onBlur: toggleEditMode,
        onChange: () => {
            toggleEditMode();
            setIds();
        },
    });

    const getSelectItem = (key: string): SelectItem<string> =>
        (get(values, `${baseFieldPath}.${key}`) as SelectItem<string> | undefined) ??
        ({ value: original[key], label: original[key], nodes: [] } as SelectItem<string>);

    const nodesByColumn: Record<EditableDimension, (props: { toggleEditMode: () => void }) => ReactNode> = {
        campaign_product_label: ({ toggleEditMode }) => (
            <FormProductLabelSelect
                {...getCommonProps(toggleEditMode)}
                onChange={() => {
                    setIds();
                    setFieldValue(`${baseFieldPath}.campaign_product_sub_label`, EMPTY_OPTION);
                }}
            />
        ),
        strategy_product_label: ({ toggleEditMode }) => (
            <FormProductLabelSelect
                {...getCommonProps(toggleEditMode)}
                onChange={() => {
                    setIds();
                    setFieldValue(`${baseFieldPath}.strategy_product_sub_label`, EMPTY_OPTION);
                }}
            />
        ),
        campaign_product_sub_label: ({ toggleEditMode }) => (
            <FormProductSubLabelSelect
                {...getCommonProps(toggleEditMode)}
                parentProductLabel={getSelectItem('campaign_product_label')}
            />
        ),
        strategy_product_sub_label: ({ toggleEditMode }) => (
            <FormProductSubLabelSelect
                {...getCommonProps(toggleEditMode)}
                parentProductLabel={getSelectItem('strategy_product_label')}
            />
        ),
        campaign_flight_label: ({ toggleEditMode }) => (
            <FormFlightLabelSelect {...getCommonProps(toggleEditMode)} />
        ),
        strategy_flight_label: ({ toggleEditMode }) => (
            <FormFlightLabelSelect {...getCommonProps(toggleEditMode)} />
        ),
        campaign_time_offer_names: ({ toggleEditMode }) => (
            <FormTimeOffersSelect
                {...getCommonProps(toggleEditMode)}
                returnOnlyValues={false}
                closeMenuOnSelect={false}
                onChange={setIds}
            />
        ),
        strategy_time_offer_names: ({ toggleEditMode }) => (
            <FormTimeOffersSelect
                {...getCommonProps(toggleEditMode)}
                returnOnlyValues={false}
                closeMenuOnSelect={false}
                onChange={setIds}
            />
        ),
        country_labels: ({ toggleEditMode }) => (
            <FormCountryLabelsSelect {...getCommonProps(toggleEditMode)} />
        ),
    };

    const DefaultValueContainer = ({ children }: { children: ReactNode }) => (
        <div className="d-flex align-items-center">
            <span className="flex-grow-1 text-truncate">{children}</span>
            {formValue && isChanged && (
                <>
                    <i
                        role="button"
                        tabIndex={0}
                        className="EditableReportTableCell-icon fal fa-arrows-rotate ms-1 me-2"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (Object.keys(get(values, `rowChanges.${rowIndex}`)).length === 2) {
                                // if two fields left clear whole object, resetting changes
                                setFieldValue(`rowChanges.${rowIndex}`, undefined);
                            } else {
                                setFieldValue(path, undefined);
                            }
                        }}
                    />
                    |
                </>
            )}
            <i className="EditableReportTableCell-icon fal fa-chevron-down ms-2" />
        </div>
    );

    const renderCellText = () => {
        if (!formValue) return <DefaultValueContainer>{cellNode}</DefaultValueContainer>;

        const text = Array.isArray(formValue)
            ? formValue.map(({ label }) => label).join(', ')
            : formValue?.label;

        return <DefaultValueContainer>{text}</DefaultValueContainer>;
    };

    return (
        <div
            className={classNames('EditableReportTableCell', isChanged && 'EditableReportTableCell-changed')}
        >
            <InlineEditContainer ref={cellRef} style={{ color: undefined }} renderValue={renderCellText}>
                {nodesByColumn[column.id as EditableDimension]}
            </InlineEditContainer>
        </div>
    );
};

export default EditableReportTableCell;
