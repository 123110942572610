import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { contactSelectors } from 'platform/common/ducks/contacts.duck';
import { User } from 'platform/userManagement/types/user.type';

export const useUserOptions = (userOptions: User[], selectedUsers?: number[]) => {
    const profile = useSelector(authSelectors.ready.profile);
    const contactInformation = useSelector(contactSelectors.getContacts());

    return useMemo(() => {
        if (profile.adminUser) {
            return userOptions;
        }

        const allowedUsers = new Set([
            ...(contactInformation?.clientService ?? []),
            ...(contactInformation?.media ?? []),
            ...(selectedUsers ?? []),
        ]);

        return userOptions.filter((o) => !o.adminUser || allowedUsers.has(o.id));
    }, [profile, userOptions, contactInformation]);
};
