import { ItemGroupCM360 } from '../CustomerJourneyCM360';
import JourneyCardSeparator from './JourneyCardSeparator';
import JourneyEventListGroup from './JourneyEventListGroup';

interface Props {
    groups: ItemGroupCM360[];
}

const JourneyList = ({ groups }: Props) => (
    <>
        {groups.map((group, index) => (
            <div key={index} id={`JourneyListGroup-${index}`}>
                {index !== 0 && (
                    <JourneyCardSeparator startTime={groups[index - 1].endTime} endTime={group.startTime} />
                )}
                <JourneyEventListGroup rows={group.rows} />
            </div>
        ))}
    </>
);

export default JourneyList;
