import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { replace } from 'redux-first-history';
import { authActions, authSelectors } from 'platform/app/ducks/auth.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { history } from 'platform/store';
import { stripBasenameFromPath } from './navigation.util';

export const useDefaultRedirect = (availablePaths: string[]) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const returnLocation = useSelector(authSelectors.returnLocation);
    const shouldRedirect = pathname === '/';
    const canViewChatbot = useFeature('CHATBOT_DEFAULT_VIEW');
    const canViewAnalytics = useFeature('ANALYTICS_VIEW');
    const defaultReturnPath = canViewChatbot && !canViewAnalytics ? '/deeply' : '/';

    const returnTo = useMemo(() => {
        if (returnLocation) {
            return stripBasenameFromPath(history.createHref(returnLocation));
        }
        return availablePaths[0] ?? defaultReturnPath;
    }, [availablePaths, returnLocation?.pathname]);

    useEffect(() => {
        if (!shouldRedirect) {
            return;
        }

        dispatch(replace(returnTo));
        dispatch(authActions.clearReturnLocation());
    }, [returnTo, shouldRedirect, dispatch]);
};
