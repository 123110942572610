import { CSSProperties } from 'react';
import { FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import { useField } from 'formik';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import { AddOn } from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { DateRanges } from 'platform/common/utils/date.util';
import { validator, Validator } from 'platform/common/utils/validators.util';

interface Props {
    name: string;
    minDate?: string;
    placeholder?: string;
    optionalEndDate?: boolean;
    ranges?: DateRanges;
    disabled?: boolean;
    inputGroupStyle?: CSSProperties;
    className?: string;
    inputClassName?: string;
    validate?: Validator | Validator[];
    isValidationMessageVisible?: boolean;
    leftAddOn?: AddOn;
}

const FormDateRangePicker = ({
    minDate,
    ranges,
    placeholder,
    disabled,
    inputGroupStyle,
    inputClassName,
    className,
    validate,
    optionalEndDate,
    name,
    isValidationMessageVisible = true,
    leftAddOn,
}: Props) => {
    const [field, meta, helpers] = useField<{
        from: string | undefined;
        to: string | undefined;
    }>({ name, validate: validate && validator(validate) });
    const invalid = !!(meta.touched && meta.error);

    return (
        <>
            <DateRangePicker
                from={field.value.from}
                to={field.value.to}
                placeholder={placeholder}
                minDate={minDate}
                className={classNames({ 'form-control is-invalid p-0': invalid }, className)}
                ranges={ranges}
                disabled={disabled}
                inputGroupStyle={inputGroupStyle}
                inputClassName={inputClassName}
                optionalEndDate={optionalEndDate}
                onChange={(from, to) => {
                    helpers.setValue({ from, to });
                }}
                onBlur={() => {
                    field.onBlur(name);
                }}
                leftAddOn={leftAddOn}
            />
            {invalid && isValidationMessageVisible && <FormFeedback>{meta.error}</FormFeedback>}
        </>
    );
};

export default FormDateRangePicker;
