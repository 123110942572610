import { ChangeEvent } from 'react';
import { Col, Input, Tooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useImageUpload } from 'platform/adminPanel/hooks/useImageUpload';
import { toClickIdMacro } from 'platform/advertiserTag/constants/advertiserTag.constant';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormInputButton from 'platform/common/components/FormInputButton/FormInputButton';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Popover from 'platform/common/components/Popover/Popover';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { useFeature } from 'platform/common/hooks/useFeature';
import useToggle from 'platform/common/hooks/useToggle';
import { toSystemCode } from 'platform/common/utils/formatters.util';
import { positiveWholeNumber, required } from 'platform/common/utils/validators.util';
import FormDynamicOptionList from 'platform/formik/FormDynamicOptionList/FormDynamicOptionList';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormInputWithUrlPreview from 'platform/formik/FormInputWithUrlPreview/FormInputWithUrlPreview';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormStateSelect from 'platform/formik/FormStateSelect/FormStateSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { VendorFormModel } from 'platform/vendors/vendors.types';
import './VendorForm.scss';

const copyToClipboard = (text: string) => navigator && navigator.clipboard.writeText(text);

const VendorForm = ({
    labels,
    formikProps: {
        submitForm,
        values: {
            adservers,
            advertiserLevelTracking,
            id,
            accessToken,
            privateNetwork,
            clickIdEnabled,
            clickIdParameter,
            iconUrl,
        },
        setFieldValue,
    },
    canEdit,
    onCancel,
}: FormProps<VendorFormModel>) => {
    const [areSettingsOpen, toggleSettings] = useToggle(false);
    const isSuperuser = useFeature('SUPERUSER');
    const { uploadingImage, handleImageUpload } = useImageUpload();

    const isEditMode = !!id;

    return (
        <CardForm
            title={`${labels.prefix} Vendor`}
            subtitle={id ? `ID: ${id}` : null}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Name">
                    <FormInput
                        name="name"
                        type="text"
                        validate={required}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('name', e.target.value);
                            if (isEditMode || !e.target.value) return;
                            setFieldValue('externalSystem', toSystemCode(e.target.value));
                        }}
                    />
                </FormRow>
                <FormRow label="System Code">
                    <FormInput name="externalSystem" type="text" disabled />
                </FormRow>
                <FormRow label="Status">
                    <FormStateSelect name="status" validate={required} />
                </FormRow>
                <FormRow label="Icon url">
                    <FormInputWithUrlPreview name="iconUrl" value={iconUrl} validate={required} />
                </FormRow>
                <FormRow>
                    <UploadInput
                        acceptableMimeTypes="image/jpeg, image/png, image/svg+xml"
                        onFileUpload={(file, uploadProgressProps) =>
                            handleImageUpload({
                                file,
                                uploadProgressProps,
                                onUpload: (fileName) => setFieldValue('iconUrl', fileName),
                            })
                        }
                        loading={uploadingImage}
                        subtitle="Upload jpeg/png/svg"
                    />
                </FormRow>
                <FormRow label="Advertiser-level tracking">
                    <FormSwitch name="advertiserLevelTracking" />
                </FormRow>
                {advertiserLevelTracking && (
                    <>
                        <FormRow label="Click Tracking Params">
                            <FormInput name="clickTrackingParams" type="text" />
                        </FormRow>
                        <FormRow label="Action Tracking Params">
                            <FormInput name="actionTrackingParams" type="text" />
                        </FormRow>
                    </>
                )}
                <FormRow label="Use in CDP">
                    <FormSwitch name="useInCDP" />
                </FormRow>
                <FormRow label="Automated IO spend">
                    <FormSwitch name="costIntegrationEnabled" disabled={!isSuperuser} />
                </FormRow>
                <FormRow label="Reporting API for vendor">
                    <FormSwitch name="privateNetwork" />
                </FormRow>
                {privateNetwork && (
                    <>
                        <FormRow label="Token" childrenColClassName="d-flex align-items-center">
                            <FormInputButton
                                name="accessToken"
                                buttonTitle="Generate"
                                onClick={() => setFieldValue('accessToken', uuidv4())}
                                isButtonDisabled={!!accessToken}
                                inputProps={{ disabled: true }}
                                validate={required}
                            />
                            <Col md="1" className="d-flex align-items-center justify-content-center">
                                <i
                                    tabIndex={0}
                                    id={`Tooltip-accessToken`}
                                    role="button"
                                    onClick={() => accessToken && copyToClipboard(accessToken)}
                                    className="fa fa-copy ms-2"
                                />
                                <Tooltip
                                    placement="bottom-start"
                                    className="d-flex justify-content-center"
                                    isOpen={areSettingsOpen}
                                    target={`Tooltip-accessToken`}
                                    toggle={toggleSettings}
                                >
                                    Copy URL to Clipboard
                                </Tooltip>
                            </Col>
                        </FormRow>
                        <FormRow label="Parameters">
                            <FormDynamicOptionList name="tokenParameters" buttonTitle="Add" />
                        </FormRow>
                        <FormRow>
                            <Popover
                                className="d-inline-block outline-0 text-primary"
                                renderPopover={() => (
                                    <div className="py-3 px-4 font-md">
                                        <h5 className="mb-3">Supported parameters</h5>
                                        <code>vendor_external_system</code>
                                        <br />
                                        <code>advertiser_id</code>
                                        <br />
                                        <code>campaign_id</code>
                                        <br />
                                        <code>channel</code>
                                    </div>
                                )}
                            >
                                Supported parameters
                            </Popover>
                        </FormRow>
                    </>
                )}
                <FormRow label="AdvertiserTag Click ID">
                    <FormSwitch name="clickIdEnabled" />
                </FormRow>
                {clickIdEnabled && (
                    <>
                        <FormRow label="URL parameter">
                            <FormInput name="clickIdParameter" validate={required} />
                        </FormRow>
                        <FormRow label="Data sharing macro">
                            <Input value={toClickIdMacro(clickIdParameter)} disabled />
                        </FormRow>
                    </>
                )}
            </ControlledCard>
            <ControlledCard title="Adservers">
                <FormRow label="CM360">
                    <FormSwitch
                        name="adservers.GCM.enabled"
                        onChange={(e) => {
                            if (!e.target.value) {
                                setFieldValue('integrations.GCM.externalId', '');
                            }
                        }}
                    />
                </FormRow>
                {adservers.GCM.enabled && (
                    <FormRow label="Directory site ID">
                        <FormNumberInput
                            name="integrations.GCM.externalId"
                            type="text"
                            validate={[required, positiveWholeNumber]}
                        />
                    </FormRow>
                )}
            </ControlledCard>
        </CardForm>
    );
};

export default VendorForm;
