import { ReactNode, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './DateButton.scss';

interface Props {
    selected?: boolean;
    betweenSelected?: boolean;
    secondarySelected?: boolean;
    secondaryBetweenSelected?: boolean;
    style?: React.CSSProperties;
    children: ReactNode;
    onClick?: () => void;
}

const DateButton = ({
    selected,
    betweenSelected,
    secondarySelected,
    secondaryBetweenSelected,
    children,
    style,
    onClick,
}: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (selected && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, []);

    return (
        <div
            style={style}
            ref={ref}
            tabIndex={0}
            role="button"
            className={classNames('DateButton', {
                'DateButton-selected': selected,
                'DateButton-betweenSelected': betweenSelected,
                'DateButton-secondarySelected': secondarySelected,
                'DateButton-secondaryBetweenSelected': secondaryBetweenSelected,
            })}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default DateButton;
