import AdvertiserAccountFormLabel from 'platform/advertisers/components/AdvertiserAccountLabel/AdvertiserAccountFormLabel';
import { fetchFlightLabels } from 'platform/campaign/advertiserManagement/FlightLabels/flightLabel.service';
import { fetchProducts } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.service';
import { usePromise } from 'platform/common/hooks/usePromise';
import { namesToOptions } from 'platform/common/utils/option.util';
import { conceptExternalSystem } from 'platform/creatives/constants/conceptTypes';
import { ConceptModel } from 'platform/creatives/types/creative.types';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import ControlledCard from '../../../common/components/ControlledCard/ControlledCard';
import FormRow from '../../../common/components/FormRow/FormRow';
import { required } from '../../../common/utils/validators.util';

type Props = {
    concept: ConceptModel;
};

const ConceptGeneralTab = ({ concept }: Props) => {
    const [
        {
            data: [productOptions, flightLabelOptions],
            loading: labelLoading,
        },
    ] = usePromise(
        [],
        async () => {
            const labels = await Promise.all([
                fetchProducts(concept.advertiserId),
                fetchFlightLabels(concept.advertiserId),
            ]);
            return labels.map(namesToOptions);
        },
        [concept.advertiserId]
    );

    const externalSystem = conceptExternalSystem(concept.type);

    return (
        <ControlledCard title="General info" subtitle="required">
            <FormRow label="Name">
                <FormInput name="name" validate={required} />
            </FormRow>
            <FormRow label="Product">
                <FormSelect
                    name="productLabel"
                    options={productOptions}
                    returnOnlyValues={false}
                    isLoading={labelLoading}
                />
            </FormRow>
            <FormRow label="Flight">
                <FormSelect
                    name="flightLabel"
                    options={flightLabelOptions}
                    isLoading={labelLoading}
                    returnOnlyValues={false}
                />
            </FormRow>

            {concept.externalAdvertiserId && (
                <AdvertiserAccountFormLabel
                    advertiserId={concept.advertiserId}
                    externalSystem={externalSystem}
                    externalAdvertiserId={concept.externalAdvertiserId}
                />
            )}
        </ControlledCard>
    );
};

export default ConceptGeneralTab;
