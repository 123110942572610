import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';
import { MediaInsertionStepType } from 'platform/mediaplan/mediaplan.types';
import StageCardHeader from './StageCardHeader';
import SummaryStep from './SummaryStep';
import { WorkflowStagesProps } from './WorkflowStages';
import useSummarySteps from './useSummarySteps';

interface Props {
    lanes: WorkflowStagesProps['lanes'];
}

const steps: MediaInsertionStepType[] = [
    'CREATIVES_CHECKED',
    'IO_REQUESTED',
    'IO_CHECKED',
    'IO_SIGNED',
    'IO_BOOKED',
];

const PreparationStage = ({ lanes }: Props) => {
    const { stepPropsByType } = useSummarySteps(steps, lanes);

    return (
        <SimpleCard
            headerContent={
                <StageCardHeader
                    icon={<i className="fa-solid fa-circle-check text-white font-3xl" />}
                    text="Preparation"
                />
            }
        >
            {steps.map((type, i) => (
                <SummaryStep key={i} {...stepPropsByType[type]} />
            ))}
        </SimpleCard>
    );
};

export default PreparationStage;
