import { ReactNode } from 'react';
import { components, MultiValueProps } from 'react-select';
import SortableItem from 'platform/common/components/SortableItem/SortableItem';
import { arrayItemMove } from 'platform/common/utils/array.util';
import { OptionType } from './select.types';

type Props = {
    id?: string;
    endAddon?: ReactNode;
};

const SelectSortableMultiValue = <T extends OptionType>({
    id,
    endAddon,
    ...props
}: MultiValueProps<T> & Props) => {
    const { inputId, value } = props.selectProps;

    return (
        <SortableItem<{ index: number }>
            id={id}
            sortId={inputId ?? 'sortable'}
            item={{ index: value?.indexOf(props.data) ?? 0 }}
            onDrop={(moveFrom, moveTo) => {
                if (value && value.length > 1) {
                    props.setValue(
                        arrayItemMove(value as T[], moveFrom.index, moveTo.index),
                        'select-option'
                    );
                }
            }}
            onMouseDown={(e) => e.stopPropagation()}
            hasAddon={!!endAddon}
        >
            <components.MultiValue {...props} className="cursor-pointer" />
            {endAddon}
        </SortableItem>
    );
};

export default SelectSortableMultiValue;
