import { useLocation, useParams } from 'react-router';
import { Location } from 'history';
import { stringify } from 'qs';
import { ANALYTICS } from 'platform/analytics/analytics.navigation';
import AnalyticsReportForm, {
    AnalyticsReportFormModel,
} from 'platform/analyticsReports/AnalyticsReportForm/AnalyticsReportForm';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { parseQuery } from 'platform/common/utils/url.util';
import { fetchSeats } from 'platform/userManagement/services/userManagement.service';
import {
    createAnalyticsReport,
    fetchAnalyticsReport,
    updateAnalyticsReport,
} from './analyticsReport.service';
import { AnalyticsReportWithSettings } from './analyticsReport.types';

const newLinkReport = ({ search }: Location): AnalyticsReportWithSettings => {
    const { type, section, seatId } = parseQuery(search);

    return {
        name: '',
        type,
        section,
        display: type === 'EXTERNAL' ? { openIn: 'NEW_TAB' } : undefined,
        status: 'ACTIVE',
        systemReportKey: undefined,
        url: '',
        seatIds: seatId ? [seatId] : [],
        usedAsTemplate: false,
        analyticsSettings: undefined,
    };
};

const adjustInternalLinkUrl = (report: AnalyticsReportWithSettings): AnalyticsReportWithSettings => {
    if (report.url?.startsWith(`${ANALYTICS.path}?`) && !parseQuery(report.url).internalLinkId) {
        return { ...report, url: `${report.url}&${stringify({ internalLinkId: report.id })}` };
    }
    return report;
};

const AnalyticsReportFormContainer = ({ redirectTo, canEdit, afterSubmit }: FormContainerProps) => {
    const location = useLocation();
    const params = useParams<{ id: string }>();
    const handleOpen = async (): Promise<AnalyticsReportFormModel> => {
        const [availableSeats, report] = await Promise.all([
            fetchSeats({}),
            params.id ? fetchAnalyticsReport(Number(params.id)) : newLinkReport(location),
        ]);
        return { availableSeats, report };
    };

    const handleSubmit = async ({ report }: AnalyticsReportFormModel) => {
        const rep = report.systemReportKey ? report : { ...report, systemReportKey: undefined };
        if (rep.type === 'INTERNAL_LINK') {
            const reportWithId = rep.id ? rep : { ...rep, id: await createAnalyticsReport(rep) };
            return updateAnalyticsReport(adjustInternalLinkUrl(reportWithId));
        }
        return rep.id ? updateAnalyticsReport(rep) : createAnalyticsReport(rep);
    };

    return (
        <FormContainer
            helpKey="custom_report_form"
            sidePanel
            canEdit={canEdit}
            isEdit={!!params.id}
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
        >
            {(props) => <AnalyticsReportForm {...props} />}
        </FormContainer>
    );
};

export default AnalyticsReportFormContainer;
