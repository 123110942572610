import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import DspIntegrationIcon from 'platform/common/components/DspIntegrationIcon/DspIntegrationIcon';
import FormRow from 'platform/common/components/FormRow/FormRow';
import {
    ExternalSystem,
    getIntegrationLabel,
    IntegrationStatus,
} from 'platform/common/constants/externalIntegration.constant';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { Integration } from 'platform/segments/components/SegmentForm/SegmentForm.types';
import { SegmentType } from 'platform/segments/constants/segmentTypes.constant';
import { isGoogleOrFacebook } from 'platform/segments/mappers/segment.mapper';
import { resyncIntegration } from 'platform/segments/services/segments.service';

const recencyOptions = [
    {
        label: '< 1 day',
        value: 1,
    },
    {
        label: '< 3 days',
        value: 3,
    },
    {
        label: '< 7 days',
        value: 7,
    },
    {
        label: '< 14 day',
        value: 14,
    },
    {
        label: '< 30 days',
        value: 30,
    },
    {
        label: '< 60 days',
        value: 60,
    },
    {
        label: '< 90 days',
        value: 90,
    },
];

interface Props {
    fieldName: string;
    segmentId: number;
    segmentType: SegmentType;
    integrations: { [key in ExternalSystem]?: Integration };
    onStatusResync: (fieldName: string, status: IntegrationStatus) => void;
}

const Integrations = ({ integrations, fieldName, segmentId, onStatusResync }: Props) => {
    const handleResync = (externalSystem: ExternalSystem) => {
        resyncIntegration(segmentId, externalSystem);
        onStatusResync(`${fieldName}.${externalSystem}.status`, 'IN_PROGRESS');
    };

    return (
        <ControlledCard title="Integrations" subtitle="required">
            {Object.keys(integrations).map((integrationKey: ExternalSystem) => {
                const { externalSystem, status, isToggleEnabled, externalId, enabled } =
                    integrations[integrationKey] ?? {};
                if (!externalSystem) return null;
                return (
                    <div key={externalSystem}>
                        <div className="d-flex align-items-center py-2">
                            <FormSwitch
                                name={`${fieldName}.${externalSystem}.enabled`}
                                disabled={!isToggleEnabled}
                            />
                            <div className="ps-1">
                                <DspIntegrationIcon
                                    systemName={externalSystem}
                                    status={status}
                                    enabled={status !== 'NEVER'}
                                    internalId={segmentId}
                                    externalId={externalId}
                                    syncEntityType={
                                        isGoogleOrFacebook(externalSystem) ? 'SEGMENT' : undefined
                                    }
                                    onResync={() => handleResync(externalSystem)}
                                />
                            </div>
                            <div className="ps-1 ms-1">{getIntegrationLabel(externalSystem)}</div>
                        </div>
                        {enabled && isGoogleOrFacebook(externalSystem) && (
                            <div
                                style={{
                                    backgroundColor: '#f5f6f9',
                                }}
                                className="py-3 pe-3"
                            >
                                <FormRow label="Recency" rowClassName="mb-0">
                                    <FormSelect
                                        name={`${fieldName}.${externalSystem}.recencies`}
                                        options={recencyOptions}
                                        isMulti
                                        isClearable
                                        canSelectAll
                                    />
                                </FormRow>
                            </div>
                        )}
                        <hr className="my-2" />
                    </div>
                );
            })}
        </ControlledCard>
    );
};

export default Integrations;
