import axiosBff from 'platform/axios/axiosBff';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';
import { toastError } from 'platform/common/utils/toast.util';
import {
    ChatAgent,
    ChatAgentFormModel,
    ChatMessagePayload,
    ContextPrompt,
    ContextPromptFormModel,
    GetPredefinedPromptsParams,
    MessageFeedbackPayload,
    ModelEndpoint,
    ModelEndpointFormModel,
    DeeplyChatResponse,
    PersonalPrompt,
    PredefinedPrompt,
    PredefinedPromptFormModel,
    SystemPrompt,
    SystemPromptFormModel,
    DeeplyChatAsyncResponse,
    DeeplyChatAsyncRequest,
} from './chat.types';

const handleDeeplyError = (error: any, withErrorToast: boolean) => {
    if (withErrorToast) {
        toastError({
            ...error,
            request: { ...error.request, body: { ...error.request.body, messages: '***' } },
        });
    }

    return Promise.reject(error);
};

export const sendChatMessages = (payload: ChatMessagePayload): Promise<DeeplyChatResponse> =>
    axiosBff.post<DeeplyChatResponse>(`/deeply/api/chat/prompt`, payload).then((r) => r.data);

export const sendAsyncChatMessages = (
    payload: ChatMessagePayload,
    withErrorToast = false
): Promise<DeeplyChatAsyncRequest> =>
    axiosBff
        .post<DeeplyChatAsyncRequest>(`/deeply/api/chat/async/prompt`, payload, {
            errorHandledByComponent: true,
        })
        .then((r) => r.data)
        .catch((e) => handleDeeplyError(e, withErrorToast));

export const receiveAsyncChatMessages = (
    promptId: number,
    withErrorToast = false
): Promise<DeeplyChatAsyncResponse> =>
    axiosBff
        .get<DeeplyChatAsyncResponse>(`/deeply/api/chat/async/prompt/${promptId}/result`, {
            errorHandledByComponent: true,
        })
        .then((r) => r.data)
        .catch((e) => handleDeeplyError(e, withErrorToast));

export const getModelEndpoints = (params?: { status: ActiveOrArchived[] }): Promise<ModelEndpoint[]> =>
    axiosBff.get(`/deeply/api/models`, { params }).then((r) => r.data);
export const getModelEndpoint = (id: number): Promise<ModelEndpoint> =>
    axiosBff.get(`/deeply/api/models/${id}`).then((r) => r.data);
export const createModelEndpoint = (payload: ModelEndpointFormModel): Promise<{ id: number }> =>
    axiosBff.post(`/deeply/api/models`, payload).then((r) => r.data);
export const updateModelEndpoint = (id: number, payload: ModelEndpointFormModel): Promise<void> =>
    axiosBff.put(`/deeply/api/models/${id}`, payload).then((r) => r.data);
export const getChatAgents = (params?: { status: ActiveOrArchived[] }): Promise<ChatAgent[]> =>
    axiosBff.get(`/deeply/api/agents`, { params }).then((r) => r.data);
export const getChatAgent = (id: number): Promise<ChatAgent> =>
    axiosBff.get(`/deeply/api/agents/${id}`).then((r) => r.data);
export const getChatAgentPlaceholders = (id: number): Promise<string[]> =>
    axiosBff.get(`/deeply/api/agents/${id}/message-placeholders`).then((r) => r.data);
export const getDefaultChatAgent = (): Promise<ChatAgent> =>
    axiosBff.get(`/deeply/api/agents/default`).then((r) => r.data);
export const createChatAgent = (payload: ChatAgentFormModel): Promise<{ id: number }> =>
    axiosBff.post(`/deeply/api/agents`, payload).then((r) => r.data);
export const updateChatAgent = (id: number, payload: ChatAgentFormModel): Promise<void> =>
    axiosBff.put(`/deeply/api/agents/${id}`, payload).then((r) => r.data);

export const getPredefinedPrompts = (params: GetPredefinedPromptsParams): Promise<PredefinedPrompt[]> =>
    axiosBff.get(`/deeply/api/predefined-prompts`, { params }).then((r) => r.data);
export const getPredefinedPrompt = (id: number): Promise<PredefinedPrompt> =>
    axiosBff.get(`/deeply/api/predefined-prompts/${id}`).then((r) => r.data);
export const createPredefinedPrompt = (payload: PredefinedPromptFormModel): Promise<{ id: number }> =>
    axiosBff.post(`/deeply/api/predefined-prompts`, payload).then((r) => r.data);
export const updatePredefinedPrompt = (id: number, payload: PredefinedPromptFormModel): Promise<void> =>
    axiosBff.put(`/deeply/api/predefined-prompts/${id}`, payload).then((r) => r.data);
export const updatePredefinedPromptStatus = (id: number, status: ActiveOrArchived): Promise<void> =>
    axiosBff.put(`/deeply/api/predefined-prompts/${id}/status`, { status }).then((r) => r.data);

export const getPersonalPrompts = (): Promise<PersonalPrompt[]> =>
    axiosBff.get(`/deeply/api/personal-prompts`).then((r) => r.data);
export const createPersonalPrompt = (payload: PersonalPrompt): Promise<{ id: number }> =>
    axiosBff.post(`/deeply/api/personal-prompts`, payload).then((r) => r.data);
export const updatePersonalPrompt = (id: number, payload: PersonalPrompt): Promise<void> =>
    axiosBff.put(`/deeply/api/personal-prompts/${id}`, payload).then((r) => r.data);
export const deletePersonalPrompt = (id: number): Promise<void> =>
    axiosBff.delete(`/deeply/api/personal-prompts/${id}`).then((r) => r.data);

export const getContextPrompts = (params: { status: ActiveOrArchived[] }): Promise<ContextPrompt[]> =>
    axiosBff.get(`/deeply/api/context-prompts`, { params }).then((r) => r.data);
export const getContextPrompt = (id: number): Promise<ContextPrompt> =>
    axiosBff.get(`/deeply/api/context-prompts/${id}`).then((r) => r.data);
export const createContextPrompt = (payload: ContextPromptFormModel): Promise<{ id: number }> =>
    axiosBff.post(`/deeply/api/context-prompts`, payload).then((r) => r.data);
export const updateContextPrompt = (id: number, payload: ContextPromptFormModel): Promise<void> =>
    axiosBff.put(`/deeply/api/context-prompts/${id}`, payload).then((r) => r.data);
export const updateContextPromptStatus = (id: number, status: ActiveOrArchived): Promise<void> =>
    axiosBff.put(`/deeply/api/context-prompts/${id}/status`, { status }).then((r) => r.data);

export const getSystemPrompts = (params: { status: ActiveOrArchived[] }): Promise<SystemPrompt[]> =>
    axiosBff.get(`/deeply/api/system-prompts`, { params }).then((r) => r.data);
export const getSystemPrompt = (id: number): Promise<SystemPrompt> =>
    axiosBff.get(`/deeply/api/system-prompts/${id}`).then((r) => r.data);
export const createSystemPrompt = (payload: SystemPromptFormModel): Promise<{ id: number }> =>
    axiosBff.post(`/deeply/api/system-prompts`, payload).then((r) => r.data);
export const updateSystemPrompt = (id: number, payload: SystemPromptFormModel): Promise<void> =>
    axiosBff.put(`/deeply/api/system-prompts/${id}`, payload).then((r) => r.data);
export const updateSystemPromptStatus = (id: number, status: ActiveOrArchived): Promise<void> =>
    axiosBff.put(`/deeply/api/system-prompts/${id}/status`, { status }).then((r) => r.data);

export const submitMessageFeedback = (payload: MessageFeedbackPayload): Promise<void> =>
    axiosBff.post(`/deeply/api/feedback`, payload).then((r) => r.data);
