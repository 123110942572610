import classNames from 'classnames';
import { DateRangePreset, DateRanges } from 'platform/common/utils/date.util';

interface Props {
    ranges: Partial<DateRanges>;
    range?: DateRangePreset | null;
    onRangeClick: (range: DateRangePreset) => void;
}

const DateRangePickerSidebar = ({ ranges, range, onRangeClick }: Props) => (
    <div className="DateRangePickerPopover--sidebar">
        <ul className="DateRangePickerPopover--presets">
            {Object.keys(ranges).map((rangeKey: DateRangePreset) => (
                <li
                    key={rangeKey}
                    role="presentation"
                    className={classNames({ active: range === rangeKey })}
                    onClick={() => onRangeClick(rangeKey)}
                >
                    {ranges[rangeKey]?.label || rangeKey}
                </li>
            ))}
        </ul>
    </div>
);

export default DateRangePickerSidebar;
