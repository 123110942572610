import { useEffect } from 'react';

type Effect = (canceled: () => boolean) => void;

export const useCancelableEffect = (effect: Effect, dependencies: any[]) => {
    useEffect(() => {
        let canceled = false;
        const cancel = () => {
            canceled = true;
        };
        const isCanceledFn = () => canceled;
        effect(isCanceledFn);
        return cancel;
    }, dependencies);
};
