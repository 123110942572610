import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Button, Form, Label } from 'reactstrap';
import { Formik } from 'formik';
import { authActions } from 'platform/app/ducks/auth.duck';
import ConditionalAdBlockerWarning from 'platform/common/components/Errors/ConditionalAdBlockerWarning';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

interface FormModel {
    username: string;
    password: string;
}

interface Props {
    errorMessage?: string;
}

const LoginForm = ({ errorMessage }: Props) => {
    const dispatch = useDispatch();

    const handleLogin = (formData: FormModel) => {
        dispatch(authActions.login(formData));
    };

    return (
        <Formik<FormModel>
            data-testid="loginForm"
            initialValues={{ username: '', password: '' }}
            onSubmit={handleLogin}
        >
            {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <h4 className="mb-4 fw-bold">Sign in</h4>
                    {errorMessage && <Alert color="danger text-break">{errorMessage}</Alert>}
                    <div className="mb-3">
                        <Label for="username" className="fw-bold">
                            Email address
                        </Label>
                        <FormInput
                            data-testid="userName"
                            type="text"
                            name="username"
                            label="Email"
                            validate={required}
                        />
                    </div>
                    <div className="mb-2">
                        <Label for="password" className="fw-bold">
                            Password
                        </Label>
                        <FormInput
                            data-testid="password"
                            name="password"
                            type="password"
                            label="Password"
                            validate={required}
                        />
                    </div>
                    <Link className="d-block text-end mb-2" to="/login/forgot-password">
                        Forgot password?
                    </Link>
                    <ConditionalAdBlockerWarning />
                    <Button
                        color="primary"
                        data-testid="loginButton"
                        className="mt-3 px-3 py-2 w-100"
                        type="submit"
                    >
                        Sign in
                    </Button>
                    <div className="mt-2 text-center">
                        Don&apos;t have an account?&nbsp;
                        <a href="https://www.deepmedia.de/kontakt/">Contact us</a> to find out more about
                        business opportunities.
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
