import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { TagWithAdvertiser } from 'platform/adminPanel/components/AdvertiserTagUpdateList/AdvertiserTagUpdateList';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { DATA_TYPES } from 'platform/common/dataTypes';

interface Props {
    toggle: () => void;
    onOk: () => Promise<void>;
    tags: TagWithAdvertiser[];
}

const AdvertiserTagUpdateConfirmationModal = ({ toggle, onOk, tags }: Props) => (
    <Modal isOpen toggle={toggle} style={{ maxWidth: '700px' }}>
        <ModalHeader onClose={toggle}>Update AdvertiserTags</ModalHeader>
        <ModalBody style={{ maxHeight: '80vh', overflow: 'scroll' }}>
            <FormattedTable
                data={tags}
                columns={[
                    {
                        Header: 'Advertiser',
                        type: DATA_TYPES.TEXT,
                        width: 200,
                        accessor: (tag: TagWithAdvertiser) => tag.advertiser.name,
                    },
                    {
                        Header: 'Tag ID',
                        accessor: (tag: TagWithAdvertiser) => tag.id,
                        type: DATA_TYPES.ID,
                        width: 75,
                    },
                    {
                        Header: 'AdvertiserTag domain',
                        accessor: (tag: TagWithAdvertiser) => tag.domain,
                        minWidth: 200,
                        type: DATA_TYPES.TEXT,
                    },
                ]}
                stickyHeader={false}
                defaultPageSize={20}
            />
        </ModalBody>
        <ModalFooter>
            <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                Cancel
            </Button>
            <AnimatedButton onClick={() => onOk().then(toggle)}>Update</AnimatedButton>
        </ModalFooter>
    </Modal>
);

export default AdvertiserTagUpdateConfirmationModal;
