import { useSelector } from 'react-redux';
import classNames from 'classnames';
import UserButton from 'platform/app/components/UserButton/UserButton';
import { appStateSelectors } from 'platform/app/ducks/appStateSelectors';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import ChatLanding from '../ChatLanding/ChatLanding';
import { ChatProviders } from '../ChatProviders/ChatProviders';
import useSteps from '../MinimizedChat/useSteps';
import FullScreenChat from './FullScreenChat';
import '../ChatAnimations.scss';
import './FullScreenChatContainer.scss';

const FullScreenChatContainer = () => {
    const impersonating = useSelector(authSelectors.impersonating);
    const appState = useSelector(appStateSelectors.getState);
    const { step, nextStep, prevStep } = useSteps();

    if (appState.type !== 'READY') {
        return <OverlayLoader />;
    }

    return (
        <ChatProviders>
            {impersonating && <UserButton className="FullScreenChatUserBtn" />}
            <ChatLanding
                onAgentSelect={nextStep}
                className={classNames(
                    step === 0 ? 'ChatAnimations-visible' : 'ChatAnimations-hidden',
                    'ChatAnimations-transition',
                    'FullScreenChatLanding'
                )}
            />
            <FullScreenChat
                onBackClick={prevStep}
                className={classNames(
                    step === 1 ? 'ChatAnimations-visible' : 'ChatAnimations-hidden',
                    'ChatAnimations-transition'
                )}
            />
        </ChatProviders>
    );
};

export default FullScreenChatContainer;
