import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLoading } from 'platform/common/hooks/useLoading';
import useWikiPermissions from './useWikiPermissions';
import { wikiActions, wikiSelectors } from './wiki.duck';
import { fetchPages } from './wiki.service';

const useWikiPages = () => {
    const dispatch = useDispatch();
    const { canView } = useWikiPermissions();
    const activeSpace = useSelector(wikiSelectors.activeWikiSpace);
    const wikiPages = useSelector(wikiSelectors.wikiPages);
    const [loading, withLoading] = useLoading();

    useEffect(() => {
        if (canView) {
            reloadPages();
        }
    }, [activeSpace]);

    const reloadPages = () => {
        if (!activeSpace || !canView) {
            return;
        }

        withLoading(() => fetchPages(activeSpace).then((pages) => dispatch(wikiActions.setWikiPages(pages))));
    };

    return {
        wikiPages,
        loading,
        reloadPages,
    };
};

export default useWikiPages;
