import { useField } from 'formik';
import { isEmpty } from 'lodash-es';
import { Campaign } from 'platform/campaign/campaign/campaign.types';
import CheckboxTree from 'platform/common/components/CheckboxTree/CheckboxTree';
import { OptionType } from 'platform/common/components/CheckboxTree/CheckboxTree.type';
import ConceptCreativeStatus from 'platform/common/components/ConceptCreativeStatus/ConceptCreativeStatus';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required, validator } from 'platform/common/utils/validators.util';
import { fetchConcepts, fetchCreatives } from 'platform/creatives/services/creative.service';
import { Concept, Creative } from 'platform/creatives/types/creative.types';
import { isCreativeRow } from 'platform/creatives/utils/creativeList.utils';
import FieldError from 'platform/formik/FieldError/FieldError';
import FormInput from 'platform/formik/FormInput/FormInput';
import CreativeDisplayOption from './CreativeDisplayOption';

type Props = {
    concepts: Concept[];
    fieldName: string;
    creativeIds: number[];
    onCreativesChange: (creatives: number[]) => void;
    campaign: Campaign;
    productLabels: OptionType[];
    flightLabels: OptionType[];
};

const filterCreativeIds = (creativeIds: number[], concepts: Concept[]): number[] =>
    creativeIds.filter((creativeId) =>
        concepts.some((concept) => concept.creatives.map((creative) => creative.id).includes(creativeId))
    );

const CreativePresetControls = ({
    concepts,
    fieldName,
    creativeIds,
    onCreativesChange,
    campaign,
    productLabels,
    flightLabels,
}: Props) => {
    const [creativesField, , { setTouched: setCreativesTouched }] = useField({
        name: `${fieldName}.creatives`,
        validate: validator((ids) => (isEmpty(ids) ? 'Select at least one creative' : undefined)),
    });

    const filterConcepts = (allConcepts: Concept[]): Concept[] =>
        allConcepts.filter(
            (concept) =>
                concept.creatives.some(({ id }) => id && creativeIds.includes(id)) ||
                (concept.type === 'STANDARD' &&
                    concept.adserver === campaign.adserver &&
                    concept.state === 'ACTIVE')
        );

    const [{ data: filteredConcepts }, , setFilteredConcepts] = usePromise<Concept[]>(
        [],
        async () => {
            const selectedConcepts = creativeIds.length
                ? await fetchConcepts({ creativeId: creativeIds })
                : [];
            const merged = concepts.map((c) => selectedConcepts.find((sc) => sc.id === c.id) || c);
            return filterConcepts(merged).sort((a, b) => b.creatives.length - a.creatives.length);
        },
        []
    );

    return (
        <>
            {campaign.type === 'REDIRECT' && (
                <FormRow
                    label="Name"
                    rowHtmlId={`${fieldName}.name`}
                    helpKey="strategy_creatives_form_preset_name"
                >
                    <FormInput name={`${fieldName}.name`} validate={required} maxLength={250} />
                </FormRow>
            )}
            {!isEmpty(filteredConcepts) && (
                <div className="mb-3">
                    <CheckboxTree
                        valueKey="id"
                        labelKey="name"
                        nodesKey="creatives"
                        additionalFilters={[
                            {
                                key: 'productLabel',
                                placeholder: 'Product',
                                options: productLabels,
                                defaultValue: campaign.productLabel,
                            },
                            {
                                key: 'flightLabel',
                                placeholder: 'Flight',
                                options: flightLabels,
                                defaultValue: campaign.flightLabel,
                            },
                        ]}
                        options={filteredConcepts}
                        value={filterCreativeIds(creativeIds, filteredConcepts)}
                        onChange={
                            campaign.type === 'REDIRECT'
                                ? (ids: number[]) => {
                                      setCreativesTouched(true);
                                      onCreativesChange(ids);
                                  }
                                : undefined
                        }
                        onChildLoad={({ id, advertiserId }: Creative) =>
                            fetchCreatives({ advertiserId, conceptId: id })
                        }
                        updateNode={(updated: Concept) => {
                            setFilteredConcepts(
                                filterConcepts(
                                    filteredConcepts.map((c) => (c.id === updated.id ? updated : c))
                                )
                            );
                        }}
                        optionDisplay={(option: Concept | Creative) =>
                            isCreativeRow(option) ? (
                                <CreativeDisplayOption
                                    creative={option}
                                    concept={filteredConcepts.find((c) => c.id === option.conceptId)}
                                />
                            ) : (
                                <div className="d-flex w-100">
                                    <div>{option.name}</div>
                                    {option.creativeCount && (
                                        <ConceptCreativeStatus
                                            creativeCount={option.creativeCount}
                                            conceptCreativeStatus={option.creativeSyncSummaryStatus}
                                        />
                                    )}
                                </div>
                            )
                        }
                    />
                    <FieldError name={creativesField.name} className="mt-2" />
                </div>
            )}
        </>
    );
};

export default CreativePresetControls;
