import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { usePromise } from 'platform/common/hooks/usePromise';
import { fetchGcmHostedCreativePreview } from 'platform/creatives/services/creative.service';

interface Props {
    creativeId: number | undefined;
}

const GcmHostedPreviewIframe = ({ creativeId }: Props) => {
    const [{ data, loading }] = usePromise(
        null,
        async () => {
            if (!creativeId) {
                return null;
            }

            return fetchGcmHostedCreativePreview(creativeId);
        },
        [creativeId]
    );

    return (
        <>
            {loading && <OverlayLoader />}

            {!loading && !data && <div>Preview not available</div>}

            {data && (
                <a
                    href={data.computedClickThroughUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="d-inline-block position-relative"
                    style={{ height: data.height }}
                >
                    <div className="w-100 h-100 position-absolute" style={{ zIndex: 1 }} />
                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                    <iframe src={data.previewUrl} width={data.width} height={data.height} frameBorder={0} />
                </a>
            )}
        </>
    );
};

export default GcmHostedPreviewIframe;
