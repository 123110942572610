import { Campaign } from 'platform/campaign/campaign/campaign.types';
import { FormStep } from 'platform/common/components/FormNavigation/FormNavigation';

const STRATEGY_STEP: FormStep = {
    label: 'Strategy',
    id: 'strategy',
    getUrl: (id) => `/strategies/${id}`,
};

const CREATIVES_STEP: FormStep = {
    label: 'Creatives',
    id: 'creatives',
    getUrl: (id) => `/strategies/${id}/creatives`,
};

const UNITS_STEP: FormStep = {
    label: 'Units',
    id: 'units',
    getUrl: (id) => `/strategies/${id}/units`,
};

export const getWizardSteps = ({ type }: Campaign): FormStep[] => {
    switch (type) {
        case 'RTB':
            return [STRATEGY_STEP, CREATIVES_STEP];
        case 'TRADITIONAL':
            return [STRATEGY_STEP, UNITS_STEP];
        default:
            return [STRATEGY_STEP, CREATIVES_STEP, UNITS_STEP];
    }
};
