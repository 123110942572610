import { Optional } from 'platform/common/common.types';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import InsightForm from 'platform/insight/InsightForm/InsightForm';
import { createInsightReport } from 'platform/insight/insight.service';
import { InsightReport, NEW_INSIGHT_QUERY } from 'platform/insight/insight.types';

type Props = {
    advertiserOptions: { value: number; label: string }[];
} & FormContainerProps;

const InsightCreate = ({ canEdit, redirectTo, afterSubmit, advertiserOptions }: Props) => {
    const advertiserId = useTypedSelector(activeAdvertiserSelectors.id);
    const handleOpen = (): Promise<Optional<InsightReport, 'id'>> =>
        Promise.resolve({
            name: '',
            status: 'ACTIVE',
            advertiserId,
            longDescription: '',
            shortDescription: '',
            queries: [NEW_INSIGHT_QUERY],
        });

    const handleSubmit = (data: InsightReport) => createInsightReport(data);

    return (
        <FormContainer
            helpKey="insight_form"
            redirectTo={redirectTo}
            size={SIDE_PANEL_SIZES.WIDE}
            canEdit={canEdit}
            isEdit={false}
            sidePanel
            onSubmitFinish={afterSubmit}
            onSubmit={handleSubmit}
            onOpen={handleOpen}
        >
            {(props) => <InsightForm {...props} advertiserOptions={advertiserOptions} />}
        </FormContainer>
    );
};

export default InsightCreate;
