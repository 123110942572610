import { FormikProps } from 'formik';
import { groupBy } from 'lodash-es';
import { listMediaplans, searchMediaInsertions } from 'platform/campaign/campaign/services/mediaplan.service';
import ButtonArray from 'platform/common/components/ButtonArray/ButtonArray';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import { makeOptions } from 'platform/common/utils/option.util';
import { required } from 'platform/common/utils/validators.util';
import FormAdvertiserSelect from 'platform/formik/FormAdvertiserSelect/FormAdvertiserSelect';
import FormDateRangePicker from 'platform/formik/FormDateRangePicker/FormDateRangePicker';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSelectTree from 'platform/formik/FormSelectTree/FormSelectTree';
import { MediaInsertionSearchResult, Mediaplan } from 'platform/mediaplan/mediaplan.types';
import {
    LOG_BOOK_ASSIGNMENT_TYPES,
    LOG_CATEGORY_OPTIONS,
    LogBookAssignmentType,
} from '../../logBook.constant';
import { LogBookNoteFormModel } from '../../logBook.types';

interface Props {
    formikProps: FormikProps<LogBookNoteFormModel>;
}

const GeneralInfoCard = ({ formikProps: { setFieldValue, values } }: Props) => {
    const { id, advertiserId } = values;
    const [{ data: insertions, loading: insertionsLoading, error: insertionsError }] = usePromise<
        MediaInsertionSearchResult[]
    >([], () => searchMediaInsertions({ advertiserId }), [advertiserId]);

    const [{ data: loadedMediaplans, loading: mediaplansLoading, error: mediaplanError }] = usePromise<
        Mediaplan[]
    >([], () => listMediaplans({ advertiserId, status: ['ACTIVE'] }), [advertiserId]);

    return (
        <ControlledCard title="General info" subtitle="required">
            <FormRow label="Advertiser">
                <FormAdvertiserSelect
                    name="advertiserId"
                    validate={required}
                    isClearable={false}
                    disabled={!!id}
                />
            </FormRow>
            <FormRow label="Status">
                <FormSelect name="status" validate={required} options={ACTIVE_OR_ARCHIVED} />
            </FormRow>
            <FormRow label="Subject">
                <FormInput name="subject" validate={required} />
            </FormRow>
            <FormRow label="Category">
                <FormSelect name="category" validate={required} options={LOG_CATEGORY_OPTIONS} />
            </FormRow>
            <FormRow label="Period">
                <FormDateRangePicker name="period" />
            </FormRow>
            <FormRow label="Assign to">
                <ButtonArray
                    buttons={makeOptions(LOG_BOOK_ASSIGNMENT_TYPES)}
                    onClick={(value: LogBookAssignmentType) => {
                        setFieldValue('assignmentType', value);
                        setFieldValue('mediaplanIds', []);
                        setFieldValue('mediaInsertionIds', []);
                    }}
                    selected={values.assignmentType}
                />
            </FormRow>

            {values.assignmentType === 'MEDIAPLANS' && (
                <FormRow>
                    <FormSelect
                        name="mediaplanIds"
                        isLoading={mediaplansLoading}
                        options={loadedMediaplans.map((i) => ({
                            label: `${i.name} (${i.dateFrom} - ${i.dateTo})`,
                            value: i.id,
                        }))}
                        closeMenuOnSelect={false}
                        isMulti
                        fetchError={mediaplanError && `Mediaplans failed to load. ${mediaplanError?.message}`}
                    />
                </FormRow>
            )}
            {values.assignmentType === 'MEDIA_INSERTIONS' && (
                <FormRow>
                    <FormSelectTree<MediaInsertionTreeOption>
                        name="mediaInsertionIds"
                        options={groupByMediaplan(insertions)}
                        fetchError={
                            insertionsError && `Insertions failed to load. ${insertionsError?.message}`
                        }
                        closeMenuOnSelect={false}
                        isMulti
                        isLoading={insertionsLoading}
                        childrenKey="children"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        getOptionParentLabel={(option) => option.name}
                        optionDisplay={(option: MediaInsertionSearchResult) => (
                            <div className="d-flex w-100">
                                <div className="me-1 pe-1">{option.name}</div>
                                <div className="d-flex">
                                    <div>
                                        ({option.dateFrom} - {option.dateTo})
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                </FormRow>
            )}
        </ControlledCard>
    );
};

interface MediaInsertionTreeOption {
    id: number;
    name: string;
    children?: MediaInsertionSearchResult[];
}

const groupByMediaplan = (loadedInsertions: MediaInsertionSearchResult[]): MediaInsertionTreeOption[] =>
    Object.values(groupBy(loadedInsertions, (i) => i.mediaplanId)).map((mediaInsertions) => ({
        id: mediaInsertions[0].mediaplanId,
        name: mediaInsertions[0].mediaplanName || '',
        children: mediaInsertions,
    }));

export default GeneralInfoCard;
