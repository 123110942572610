import axiosBff from 'platform/axios/axiosBff';
import { Status } from 'platform/common/constants/status.constant';
import { AdvancedSetting } from './advanced.setting.types';

export const fetchAdvancedSetting = (key: string) =>
    axiosBff.get<AdvancedSetting>(`/admin/api/advanced-settings/${key}`).then((r) => r.data);

export const fetchAdvancedSettings = (params?: { status?: Status[] }) =>
    axiosBff.get<AdvancedSetting[]>('/admin/api/advanced-settings', { params }).then((r) => r.data);

export const saveAdvancedSetting = (key: string, data: AdvancedSetting) =>
    axiosBff.put(`/admin/api/advanced-settings/${key}`, data);

export const fetchDeeplyOlapColumns = () =>
    axiosBff.get<string[]>('/admin/api/deeply-columns').then((r) => r.data || []);
