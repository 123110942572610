import { InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import Avatar from 'platform/common/components/Avatar/Avatar';
import Select from 'platform/common/components/Select/Select';
import { getUserName } from 'platform/common/utils/formatters.util';
import { User } from 'platform/userManagement/types/user.type';
import FilterOperatorDropdown from '../FilterOperatorDropdown/FilterOperatorDropdown';
import { useUserOptions } from './useUserOptions';
import './UserSelect.scss';

interface Props {
    placeholder?: string;
    userIds: number[];
    options: User[];
    className?: string;
    operator?: 'OR' | 'AND';
    menuIsOpen?: boolean;
    closeMenuOnSelect?: boolean;
    onChange: (userIds: number[], operator: 'OR' | 'AND') => void;
    onBlur?: () => void;
    onFocus?: () => void;
}

const formatOption = (option: User | { label: string; value: string }) => {
    if ('label' in option) {
        return option.label;
    }

    return (
        <div className="d-flex align-items-center">
            <Avatar size="tiny" name={option.name} imageUrl={option.imageUrl} />{' '}
            <div className="ms-2 flex-grow-1">{getUserName(option.name)}</div>
        </div>
    );
};

const UserSelect = ({
    placeholder,
    userIds,
    options,
    className,
    operator,
    menuIsOpen,
    closeMenuOnSelect = false,
    onChange,
    onBlur,
    onFocus,
}: Props) => {
    const optionsList = useUserOptions(options, userIds);

    return (
        <>
            {operator && (
                <InputGroupText className="rounded-0">
                    <FilterOperatorDropdown
                        operator={operator}
                        supportedOperators={['OR', 'AND']}
                        onChange={(value) => onChange(userIds, value)}
                    />
                </InputGroupText>
            )}
            <Select<User>
                className={classNames('UserSelect', className)}
                value={userIds}
                options={optionsList}
                getOptionValue={(user) => user.id}
                getOptionLabel={(user) => getUserName(user.name)}
                formatOptionLabel={formatOption}
                components={{ IndicatorSeparator: null, DropdownIndicator: null }}
                placeholder={placeholder}
                isMulti
                menuIsOpen={menuIsOpen}
                closeMenuOnSelect={closeMenuOnSelect}
                canSelectAll={false}
                isClearable={false}
                onChange={(value) => onChange(value, operator ?? 'OR')}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        </>
    );
};

export default UserSelect;
