import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { useModal } from 'platform/common/components/Modal/Modal';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import CreativeRuntimeFields from 'platform/creatives/components/CreativeBlocks/CreativeRuntimeFields';
import { RuntimeFormModel } from 'platform/creatives/mappers/creative/runtimesCreative.mapper';

type Props = {
    onApply: (runtime: RuntimeFormModel) => void;
};

type ModalFormProps = Props & {
    toggle: () => void;
};

const ModalForm = ({ onApply, toggle }: ModalFormProps) => {
    const handleOpen = async (): Promise<{ runtime: RuntimeFormModel }> => ({
        runtime: {
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
        },
    });
    const handleSubmit = async (formValues: { runtime: RuntimeFormModel }) => {
        onApply(formValues.runtime);
        toggle();
    };

    return (
        <FormContainer helpKey="creative_bulk_scheduling_form" onOpen={handleOpen} onSubmit={handleSubmit}>
            {(props) => (
                <Modal isOpen toggle={toggle} style={{ maxWidth: '600px' }}>
                    <ModalHeader onClose={toggle}>Bulk scheduling</ModalHeader>
                    <ModalBody>
                        <CreativeRuntimeFields field="runtime" />
                    </ModalBody>
                    <ModalFooter>
                        <AnimatedButton onClick={props.formikProps.submitForm}>
                            Apply to all creatives
                        </AnimatedButton>
                    </ModalFooter>
                </Modal>
            )}
        </FormContainer>
    );
};

const CreativeBulkSchedulingButton = ({ onApply }: Props) => {
    const { showModal } = useModal();
    return (
        <Button
            className="ms-auto"
            onClick={() => showModal((toggle) => <ModalForm onApply={onApply} toggle={toggle} />)}
        >
            Bulk scheduling
        </Button>
    );
};

export default CreativeBulkSchedulingButton;
