import { FormEvent } from 'react';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import './MediaNode.scss';

interface Props {
    field: string;
    groupBudget: number;
}

const MediaGroupNode = ({ field, groupBudget }: Props) => (
    <div className="MediaNode">
        <div className="MediaNode-name">
            <FormInput
                name={`${field}.name`}
                id={field}
                type="text"
                placeholder="Name"
                validate={required}
                isValidationMessageVisible={false}
                onDrop={(e: FormEvent<HTMLInputElement>) => e.preventDefault()}
            />
        </div>
        {groupBudget > 0 && (
            <div className="d-flex flex-column font-xs ms-3">
                <div className="text-muted">Total Budget (net/net)</div>
                <div>{DATA_TYPES.EUR.format(groupBudget)}</div>
            </div>
        )}
    </div>
);

export default MediaGroupNode;
