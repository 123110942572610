import { Link } from 'react-router-dom';
import { groupBy, orderBy } from 'lodash-es';
import { Campaign } from 'platform/campaign/campaign/campaign.types';
import { assertIsDefined } from 'platform/common/common.assert';
import { isNotEmpty, TableCell } from 'platform/common/common.types';
import DynamicRowsTable from 'platform/common/components/DynamicRowsTable/DynamicRowsTable';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditContainer from 'platform/common/components/InlineEditContainer/InlineEditContainer';
import SelectTree from 'platform/common/components/SelectTree/SelectTree';
import { sortByName } from 'platform/common/utils/array.util';
import { MATCH_TYPE_LABELS, MediaInsertionLink } from 'platform/mediaplan/mediaplan.types';
import { Vendor } from 'platform/vendors/vendors.types';

interface Props {
    links: Partial<MediaInsertionLink>[];
    campaigns: Campaign[];
    vendors: Vendor[];
    onChange: (newLinks: Partial<MediaInsertionLink>[]) => void;
}

interface CampaignTreeOption {
    id?: number;
    name: string;
    dateFrom?: string;
    dateTo?: string;
    nodes: Campaign[];
}

const columnDefinitions: TableColumn<MediaInsertionLink>[] = [
    {
        Header: 'Linked campaign',
        accessor: 'campaignName',
        minWidth: 500,
        Cell: ({ original: { campaignId, campaignName } }: TableCell<MediaInsertionLink>) => (
            <Link
                target="_blank"
                to={`/campaigns/${campaignId}`}
                className="text-truncate me-2"
                title={`${campaignName} (ID: ${campaignId})`}
                rel="noopener noreferrer"
            >
                {campaignName}
            </Link>
        ),
    },
    {
        Header: 'Match',
        accessor: 'matchType',
        minWidth: 75,
        Cell: ({ original: { matchType } }) => MATCH_TYPE_LABELS[matchType],
    },
];

const groupByVendor = (allCampaigns: Campaign[], vendors: Vendor[]): CampaignTreeOption[] => {
    const campaignsByVendor = groupBy(allCampaigns, (c) => c.vendorSystem);
    return sortByName(
        Object.entries(campaignsByVendor).map(([vendorSystem, vendorCampaigns]) => {
            const vendor = vendors.find((v) => v.externalSystem === vendorSystem);
            return {
                id: vendorCampaigns[0].id,
                name: vendor?.name || vendorSystem,
                nodes: vendorCampaigns,
            };
        })
    );
};

const CampaignLinksTable = ({ links, campaigns, vendors, onChange }: Props) => {
    const explicitLinks = links.filter((link) => link.matchType === 'EXPLICIT');
    const availableCampaigns = campaigns.filter(
        (c) => !explicitLinks.some((link) => link.campaignId === c.id)
    );

    const toExplicitLink = (campaignId: number): Partial<MediaInsertionLink> => {
        const campaign = campaigns.find((c) => c.id === campaignId);
        assertIsDefined(campaign, `campaign with id ${campaignId}`);
        return {
            campaignId: campaign.id,
            campaignName: campaign.name,
            matchType: 'EXPLICIT',
        };
    };

    return (
        <DynamicRowsTable
            columns={columnDefinitions}
            data={orderBy(links, 'campaignName')}
            isRemovable={(link: MediaInsertionLink) => link.matchType === 'EXPLICIT'}
            onRemove={(removed: MediaInsertionLink) =>
                onChange(links.filter((link) => link.campaignId !== removed.campaignId))
            }
            newRowAsHeader
            NewRowComponent={() => (
                <div className="flex-grow-1">
                    <InlineEditContainer placeholder="assign manually">
                        {({ toggleEditMode }) => (
                            <SelectTree<CampaignTreeOption>
                                className="my-1 me-1"
                                options={groupByVendor(availableCampaigns, vendors)}
                                value={undefined}
                                menuIsOpen
                                closeMenuOnSelect
                                autoFocus
                                filterOption={(c, input) =>
                                    c.id?.toString().toLowerCase().includes(input.toLowerCase()) ||
                                    c.name.toLowerCase().includes(input.toLowerCase())
                                }
                                getOptionLabel={(c) => `${c.name} (${c.dateFrom} - ${c.dateTo})`}
                                getOptionValue={(c) => c.id}
                                getOptionParentLabel={(c) => c.name}
                                onBlur={toggleEditMode}
                                onChange={(campaignId) => {
                                    if (isNotEmpty(campaignId)) {
                                        onChange([...links, toExplicitLink(campaignId)]);
                                    }
                                }}
                            />
                        )}
                    </InlineEditContainer>
                </div>
            )}
        />
    );
};

export default CampaignLinksTable;
