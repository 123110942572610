import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment/moment';
import { ReportAiSummaryContainerProps } from 'platform/analytics/reportComponents/ReportTableContainer/ReportAiSummaryContainer/ReportAiSummaryContainer';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import Switch from 'platform/common/components/Switch/Switch';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import { DATE_RANGES, DateRanges } from 'platform/common/utils/date.util';
import { matchesTaskBoardView, TASK_BOARDS_PATH } from 'platform/task/taskRoutes';
import { User } from 'platform/userManagement/types/user.type';
import { TaskBoardState, TaskLane } from '../task.types';
import KanbanAiHistoryPeriodPopover from './KanbanAiHistoryPeriodPopover';
import KanbanBoardActionsDropdown from './KanbanBoardActionsDropdown';
import { KanbanFilterValues } from './KanbanFilters';

const dateRanges: Partial<DateRanges> = {
    LAST_WEEK: DATE_RANGES.LAST_WEEK,
    THIS_WEEK: {
        label: 'This Week',
        getRange: () => ({
            from: moment().startOf('isoWeek'),
            to: moment().endOf('isoWeek'),
        }),
    },
    NEXT_WEEK: DATE_RANGES.NEXT_WEEK,
};

interface Props {
    board: TaskBoardState;
    canEdit: boolean;
    filters: KanbanFilterValues;
    onChangeFilters: (filters: KanbanFilterValues) => void;
    summaryProps: ReportAiSummaryContainerProps;
    filteredLanes: TaskLane[];
    userOptions: User[];
}

const KanbanPageHeader = ({
    board,
    canEdit,
    filters,
    onChangeFilters,
    summaryProps,
    filteredLanes,
    userOptions,
}: Props) => {
    const matchingView = board.views.find((view) => matchesTaskBoardView(filters, view));
    const canViewDeeply = useFeature('CHATBOT_DEFAULT_VIEW');
    const isMobile = useSelector(layoutSelectors.isMobile);

    return (
        <div className="d-flex align-items-center">
            <PageHeader
                className="text-nowrap"
                title={matchingView?.name ?? board.name}
                parentTitle={matchingView ? board.name : undefined}
                parentLink={matchingView ? `${TASK_BOARDS_PATH}/${board.id}` : undefined}
            />
            <KanbanBoardActionsDropdown className="ms-2" boardId={board.id} canEdit={canEdit} />

            {canViewDeeply && (
                <KanbanAiHistoryPeriodPopover
                    board={board}
                    summaryProps={summaryProps}
                    filteredLanes={filteredLanes}
                    userOptions={userOptions}
                />
            )}

            <DateRangePicker
                className={classNames(!isMobile && 'ms-auto', isMobile && 'borderless ms-2')}
                ranges={dateRanges}
                from={filters.date[0]}
                to={filters.date[1]}
                allowApplyEmptyValues
                additionalContent={
                    <div className="d-flex align-items-center">
                        <Switch
                            input={{
                                value: filters.includeTasksWithoutDates,
                                onChange: (e) =>
                                    onChangeFilters({
                                        ...filters,
                                        includeTasksWithoutDates: e.target.checked,
                                    }),
                            }}
                        />
                        <div className="ms-3">Include tasks without dates</div>
                    </div>
                }
                onChange={(from, to) => onChangeFilters({ ...filters, date: [from ?? '', to ?? ''] })}
                onClear={() => onChangeFilters({ ...filters, date: ['', ''] })}
            />
        </div>
    );
};

export default KanbanPageHeader;
