// Non-mutating array utils
import { sortBy } from 'lodash-es';

export const arrayItemMove = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
    const arrayWithout = arrayItemRemove(array, fromIndex);
    return arrayItemInsert(arrayWithout, array[fromIndex], toIndex);
};

export const arrayItemRemove = <T>(array: T[], index: number): T[] => [
    ...array.slice(0, index),
    ...array.slice(index + 1),
];

export const arrayItemUpdate = <T>(array: T[], index: number, value: T): T[] =>
    array.map((original, i) => (i === index ? value : original));

export const arrayItemInsert = <T>(array: T[], value: T, index: number): T[] => [
    ...array.slice(0, index),
    value,
    ...array.slice(index),
];

export const findById = <ID, T extends { id: ID }>(array: T[] | undefined, id: ID | undefined) =>
    array ? array?.find((item) => item.id === id) : undefined;

export const moveById = <ID, T extends { id: ID }>(array: T[], itemId: ID, toIndex: number): T[] => {
    const fromIndex = array.findIndex((item) => item.id === itemId);
    return fromIndex >= 0 ? arrayItemMove(array, fromIndex, toIndex) : array;
};

export const removeById = <ID, T extends { id: ID }>(array: T[], itemId: ID): T[] =>
    array.filter((item) => item.id !== itemId);

export const updateById = <ID, T extends { id: ID }>(array: T[], itemId: ID, update: (t: T) => T): T[] =>
    array.map((item) => (item.id === itemId ? update(item) : item));

export const sortByName = <T extends { name: string | undefined }>(array: T[]): T[] =>
    sortBy(array, (item) => item.name?.toLocaleLowerCase());
