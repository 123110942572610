import { memo, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import { TableState } from 'platform/analytics/analytics.types';
import { analyticsSettingsActions } from 'platform/analytics/ducks/analyticsSettings.duck';
import CustomFilterIndicator from 'platform/analytics/reportComponents/CustomFilterIndicator';
import IconButton from 'platform/common/components/IconButton/IconButton';

interface Props {
    Toolbar: ReactNode;
    componentState: TableState;
    isTableCollapsed: boolean;
    setIsTableCollapsed: (val: boolean) => void;
}

const ReportTableTopToolbar = memo(
    ({ Toolbar, componentState, isTableCollapsed, setIsTableCollapsed }: Props) => {
        const dispatch = useDispatch();
        const handleTablePresetChange = (index: number) => {
            if (componentState.activePresetIndex !== index) {
                dispatch(
                    analyticsSettingsActions.changeComponentState({
                        ...componentState,
                        activePresetIndex: index,
                    })
                );
            }

            if (componentState.collapseView) {
                setIsTableCollapsed(componentState.activePresetIndex !== index ? false : !isTableCollapsed);
            }
        };

        return (
            <div className="custom-tabs">
                {Toolbar && <div className="mb-3">{Toolbar}</div>}

                <div className="d-flex justify-content-center align-items-center">
                    {componentState.collapseView && (
                        <IconButton
                            className={classNames('pb-1 ps-1 pe-1 fs-6 text-muted')}
                            style={{ minWidth: 21 }}
                            onClick={() => setIsTableCollapsed(!isTableCollapsed)}
                        >
                            <i
                                className={classNames(
                                    'fs-7 text-muted',
                                    !isTableCollapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
                                )}
                            />
                        </IconButton>
                    )}
                    <Nav tabs className="border-bottom-0">
                        {componentState.presets.map((preset, index) =>
                            preset.name ? (
                                <NavItem key={preset.name}>
                                    <NavLink
                                        className="p-0"
                                        active={
                                            isTableCollapsed
                                                ? false
                                                : componentState.activePresetIndex === index
                                        }
                                        onClick={() => handleTablePresetChange(index)}
                                    >
                                        <div
                                            className={classNames('pb-2 pe-3', {
                                                'ps-3': !preset.customFilters?.length,
                                            })}
                                        >
                                            {!!preset.customFilters?.length && (
                                                <CustomFilterIndicator
                                                    className="me-2"
                                                    filters={preset.customFilters}
                                                />
                                            )}
                                            {preset.name}
                                        </div>
                                    </NavLink>
                                </NavItem>
                            ) : null
                        )}
                    </Nav>
                </div>
            </div>
        );
    },
    (prevProps, nextProps) =>
        isEqual(prevProps.componentState, nextProps.componentState) &&
        prevProps.isTableCollapsed === nextProps.isTableCollapsed
);

export default ReportTableTopToolbar;
