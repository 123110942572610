import { ReactNode } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

type Props = {
    headerContent: ReactNode;
    children: ReactNode;
};

/**
 * Simple styled card without collapse action
 */
const SimpleCard = ({ headerContent, children }: Props) => (
    <Card className="SimpleCard">
        <CardHeader>{headerContent}</CardHeader>
        <CardBody>{children}</CardBody>
    </Card>
);

export default SimpleCard;
