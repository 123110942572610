import ChannelLabel from 'platform/channels/components/ChannelLabel';
import { CustomerJourneyReportCM360 } from 'platform/dashboard/dashboard.types';
import VendorLabel from 'platform/vendors/components/VendorLabel';

interface Props {
    row: CustomerJourneyReportCM360;
}

const JourneyRowChannelVendorBlock = ({ row }: Props) => (
    <div className="d-flex align-items-center font-lg">
        <div className="me-3">
            <ChannelLabel withIcon channel={row.channel} searchTermStrategy={row.search_term_strategy} />
        </div>
        {row.vendor_code && <VendorLabel vendorCode={row.vendor_code} />}
    </div>
);

export default JourneyRowChannelVendorBlock;
