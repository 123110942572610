import { FormEvent, useState } from 'react';
import { Form, Input } from 'reactstrap';
import classNames from 'classnames';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { useLoading } from 'platform/common/hooks/useLoading';
import { TaskCreationData } from '../task.types';
import KanbanColumnFormButtons from './KanbanColumnFormButtons';

type Props = {
    className?: string;
    onSubmit: (task: TaskCreationData) => Promise<void>;
    onCancel: () => void;
};

const KanbanCardAdder = ({ className, onSubmit, onCancel }: Props) => {
    const [name, setName] = useState('');
    const [saving, withSaving] = useLoading();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (name) {
            await withSaving(() => onSubmit({ name, status: 'ACTIVE' }));
        }
    };

    return (
        <div className={classNames('card', className)}>
            <Form className="position-relative p-2" onSubmit={handleSubmit}>
                {saving && <OverlayLoader />}
                <Input
                    value={name}
                    placeholder="Name"
                    autoFocus
                    onChange={({ target }) => setName(target.value)}
                />
                <KanbanColumnFormButtons onCancel={onCancel} />
            </Form>
        </div>
    );
};

export default KanbanCardAdder;
