import { ChartState } from 'platform/analytics/analytics.types';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import { useCompatibleFilters } from 'platform/analytics/hooks/useCompatibleFilters';
import ReportBarChart from 'platform/analytics/reportComponents/ReportChartContainer/ReportBarChart/ReportBarChart';
import ReportDoughnutChart from 'platform/analytics/reportComponents/ReportChartContainer/ReportDoughnutChart/ReportDoughnutChart';
import ReportLineChart from 'platform/analytics/reportComponents/ReportChartContainer/ReportLineChart/ReportLineChart';
import ReportWordCloudChartContainer from 'platform/analytics/reportComponents/ReportChartContainer/ReportWordCloudChart/ReportWordCloudChartContainer';
import ReportStackedBarChart from './ReportStackBarChart.tsx/ReportStackBarChart';
import ReportSunburstChartContainer from './ReportSunburstChart/ReportSunburstChartContainer';

type Props = {
    reportName: string;
    componentState: ChartState;
};

const ReportChartContainer = ({ reportName, componentState }: Props) => {
    const { dimensions, metrics, templateId, chartType } = componentState;

    const template = useAnalyticsTemplate(componentState.templateId);

    useCompatibleFilters({
        templateId,
        componentId: componentState.id,
        columns: [...dimensions, ...metrics],
    });

    return (
        <div className="d-flex flex-column flex-grow-1 position-relative">
            {chartType === 'LINE' && (
                <ReportLineChart
                    reportName={reportName}
                    template={template}
                    componentState={componentState}
                />
            )}
            {chartType === 'STACK_LINE' && (
                <ReportLineChart
                    reportName={reportName}
                    template={template}
                    componentState={componentState}
                    isStacked
                />
            )}
            {chartType === 'DOUGHNUT' && (
                <ReportDoughnutChart
                    template={template}
                    reportName={reportName}
                    componentState={componentState}
                />
            )}
            {chartType === 'BAR' && (
                <ReportBarChart template={template} reportName={reportName} componentState={componentState} />
            )}
            {chartType === 'STACK_BAR' && (
                <ReportStackedBarChart
                    template={template}
                    reportName={reportName}
                    componentState={componentState}
                />
            )}
            {chartType === 'WORD_CLOUD' && (
                <ReportWordCloudChartContainer
                    reportName={reportName}
                    template={template}
                    componentState={componentState}
                />
            )}
            {chartType === 'SUNBURST' && (
                <ReportSunburstChartContainer
                    reportName={reportName}
                    template={template}
                    componentState={componentState}
                />
            )}
        </div>
    );
};

export default ReportChartContainer;
