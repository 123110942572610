import { useSelector } from 'react-redux';
import { useAnalyticsReports } from 'platform/analytics/hooks/useAnalyticsReports';
import {
    fetchAnalyticsReport,
    updateAnalyticsReport,
} from 'platform/analyticsReports/analyticsReport.service';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';

interface Props {
    report: AnalyticsReport;
    onDelete: () => void;
}

const ReportDeleteButton = ({ report, onDelete }: Props) => {
    const { reloadAnalyticsReports } = useAnalyticsReports();
    const advertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);

    const handleDelete = async () => {
        const reportWithSettings = await fetchAnalyticsReport(report.id!);
        await updateAnalyticsReport({
            ...reportWithSettings,
            status: 'ARCHIVED',
        });
        await reloadAnalyticsReports(advertiser);
        onDelete();
    };

    if (!report.id || report.section !== 'PERSONAL') {
        return null;
    }

    return (
        <div className="d-flex justify-content-end">
            <AnimatedButton className="btn-secondary mt-3" spinnerColor="#1083d6" onClick={handleDelete}>
                Delete report
            </AnimatedButton>
        </div>
    );
};

export default ReportDeleteButton;
