import { useState } from 'react';
import { Col, Collapse, Container, Input, Row } from 'reactstrap';
import classNames from 'classnames';
import { Feature } from 'platform/app/app.types';
import { isDefined } from 'platform/common/common.types';
import FieldError from 'platform/formik/FieldError/FieldError';
import { Module, MODULE_CONFIG, MODULE_GROUPS } from '../../constants/roles.constant';
import RoleInfoPopover from './RoleInfoPopover';
import './RoleEditList.scss';

type Props = {
    grantedFeatures: Feature[];
    onChange: (features: Feature[]) => void;
};

const RoleEditList = ({ grantedFeatures, onChange }: Props) => {
    const [cards, setCards] = useState(
        Object.entries(MODULE_GROUPS).map(([group, modules]) => ({ group, modules, isExpanded: true }))
    );

    const renderModuleRow = (module: Module) => {
        const { title, features } = MODULE_CONFIG[module];
        const grantedView = !!features.view && grantedFeatures.includes(features.view);
        const grantedEdit = !!features.edit && grantedFeatures.includes(features.edit);
        return (
            <Row key={module} className="RoleEditList-row">
                <Col md={{ size: 5, offset: 1 }}>
                    {title}
                    <RoleInfoPopover module={module} />
                </Col>
                <Col md={2} className="text-center">
                    {!!features.edit && (
                        <Input
                            type="radio"
                            checked={grantedEdit}
                            onChange={() => grant(module, [features.view, features.edit])}
                        />
                    )}
                </Col>
                <Col md={2} className="text-center">
                    {!!features.view && (
                        <Input
                            type="radio"
                            checked={grantedView && !grantedEdit}
                            onChange={() => grant(module, [features.view])}
                        />
                    )}
                </Col>
                <Col md={2} className="text-center">
                    <Input
                        type="radio"
                        checked={!grantedView && !grantedEdit}
                        onChange={() => grant(module, [])}
                    />
                </Col>
            </Row>
        );
    };

    const grant = (module: Module, addedFeatures: (Feature | undefined)[]) => {
        const { view, edit } = MODULE_CONFIG[module].features;
        onChange([
            ...grantedFeatures.filter((f) => f !== view && f !== edit),
            ...addedFeatures.filter(isDefined),
        ]);
    };

    const toggleCard = (group: string) =>
        setCards(cards.map((c) => (c.group === group ? { ...c, isExpanded: !c.isExpanded } : c)));

    return (
        <Container>
            <Row className="RoleEditList-headerRow">
                <FieldError alert name="features" />
                <Col md={{ offset: 6, size: 2 }}>View & Edit</Col>
                <Col md={2}>View</Col>
                <Col md={2}>No Access</Col>
            </Row>
            {cards.map(({ group, modules, isExpanded }) => (
                <div key={group}>
                    <Row className="RoleEditList-groupRow">
                        <Col md={1}>
                            <i
                                tabIndex={0}
                                role="button"
                                onClick={() => toggleCard(group)}
                                className={classNames(
                                    'RoleEditList-expandArrow',
                                    isExpanded ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
                                )}
                            />
                        </Col>
                        <Col md={11} className="RoleEditList-headerTitle">
                            {group}
                        </Col>
                    </Row>
                    <Collapse isOpen={isExpanded}>{modules.map(renderModuleRow)}</Collapse>
                </div>
            ))}
        </Container>
    );
};

export default RoleEditList;
