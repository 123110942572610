import { campaignClassificationsToApi } from 'platform/campaign/campaign/campaignClassification.mapper';
import ScoringForm from 'platform/campaign/globalPreset/components/Scoring/ScoringForm';
import { ScoringFormModel } from 'platform/campaign/globalPreset/globalPreset.types';
import { createOrUpdateScoring } from 'platform/campaign/globalPreset/services/scoring.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';

const ScoringCreate = ({
    canEdit,
    redirectTo,
    afterSubmit,
    advertiserId,
}: FormContainerProps & { advertiserId: number }) => {
    const onOpen = async (): Promise<ScoringFormModel> => ({
        name: '',
        rules: [{ metrics: [] }],
    });

    const onSubmit = (data: ScoringFormModel) =>
        createOrUpdateScoring(advertiserId, {
            name: data.name,
            rules: data.rules.map((rule) => ({
                ...rule,
                classification: campaignClassificationsToApi(rule.classification),
            })),
        });

    return (
        <FormContainer
            helpKey="scoring_form"
            onOpen={onOpen}
            onSubmit={onSubmit}
            canEdit={canEdit}
            onSubmitFinish={afterSubmit}
            redirectTo={redirectTo}
            sidePanel={false}
            isEdit={false}
        >
            {(props) => <ScoringForm {...props} advertiserId={advertiserId} />}
        </FormContainer>
    );
};

export default ScoringCreate;
