import { useMemo } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { Campaign } from 'platform/campaign/campaign/campaign.types';
import { datesOverlap } from 'platform/common/utils/date.util';
import MediaInsertionPopoverButton from 'platform/mediaplan/components/MediaplanTreeForm/MediaInsertionPopoverButton';
import { MediaInsertionLink } from 'platform/mediaplan/mediaplan.types';
import { Vendor } from 'platform/vendors/vendors.types';
import CampaignLinksTable from '../CampaignLinksTable';
import './MediaNode.scss';

interface Props {
    title: string;
    links: MediaInsertionLink[];
    campaigns: Campaign[];
    vendors: Vendor[];
    dateFrom?: string;
    dateTo?: string;
    onChange: (links: MediaInsertionLink[]) => void;
}

const MediaInsertionCampaignLinks = ({
    title,
    links,
    campaigns,
    vendors,
    dateFrom,
    dateTo,
    onChange,
}: Props) => {
    const suitableCampaigns = useMemo(
        () =>
            dateFrom && dateTo
                ? campaigns.filter(
                      (c) => c.dateFrom && c.dateTo && datesOverlap(c.dateFrom, c.dateTo, dateFrom, dateTo)
                  )
                : campaigns,
        [campaigns, dateFrom, dateTo]
    );

    return (
        <>
            <MediaInsertionPopoverButton
                iconClassName={classNames('fa-link mx-3', { 'text-primary': !isEmpty(links) })}
                style={{ width: 800 }}
            >
                {() => (
                    <>
                        <div className="MediaNode-popoverTitle">{title}</div>
                        <div>
                            Campaigns are automatically linked by matching runtime and Classification{' '}
                            <i>after</i> saving the insertion.
                        </div>
                        <div className="mb-3">
                            Additionally, you can manually assign campaigns that do not match the
                            Classification.
                        </div>
                        <CampaignLinksTable
                            links={links}
                            campaigns={suitableCampaigns}
                            vendors={vendors}
                            onChange={onChange}
                        />
                    </>
                )}
            </MediaInsertionPopoverButton>
        </>
    );
};

export default MediaInsertionCampaignLinks;
