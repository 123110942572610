import { useCallback, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import moment from 'moment';
import { generateOlapReport } from 'platform/analytics/analytics.service';
import { ReportFilter, OlapReport } from 'platform/analytics/analytics.types';
import CampaignStateIndicator from 'platform/campaign/campaign/components/CampaignStateIndicator';
import { CampaignState } from 'platform/campaign/campaign/constants/state.constant';
import { bulkUpdateCampaigns } from 'platform/campaign/campaign/services/campaign.service';
import { Period, TableCell } from 'platform/common/common.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import DatePicker from 'platform/common/components/DatePicker/DatePicker';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import FormattedTable from 'platform/common/components/FormattedTable/FormattedTable';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { usePromise } from 'platform/common/hooks/usePromise';
import { formatDate } from 'platform/common/utils/date.util';

interface Props {
    toggle: () => void;
    afterUpdate: () => void;
    filters: ReportFilter[];
    date: Period;
}

interface DataRow {
    advertiser_id: number;
    advertiser_name: string;
    campaign_id: number;
    campaign_name: string;
    campaign_state: CampaignState;
    campaign_runtime_from: string;
    campaign_runtime_to: string;
}

const BulkCampaignRuntimeModal = ({ toggle, afterUpdate, filters, date }: Props) => {
    const [selectedCampaigns, selectCampaigns] = useState<number[]>([]);
    const [runtimeTo, setRuntimeTo] = useState<string | undefined>(formatDate(moment()));

    const [{ loading, data, error }] = usePromise<OlapReport<DataRow> | undefined>(
        undefined,
        () =>
            generateOlapReport({
                templateId: 'all_columns',
                dimensions: [
                    'advertiser_id',
                    'advertiser_name',
                    'campaign_id',
                    'campaign_name',
                    'campaign_state',
                    'campaign_runtime_from',
                    'campaign_runtime_to',
                ],
                metrics: [],
                from: date.from,
                to: date.to,
                dimensionFilters: [...filters, { key: 'full_set', values: [true] }],
            }),
        [filters, date]
    );

    const calcDaysAdded = useCallback(
        (row: DataRow) =>
            row.campaign_runtime_to
                ? moment(runtimeTo).diff(moment(row.campaign_runtime_to), 'days')
                : undefined,
        [runtimeTo]
    );
    const isCampaignSelected = (campaignId: number) => selectedCampaigns.includes(campaignId);

    const onRowClick = (campaignId: number) => {
        selectCampaigns(
            selectedCampaigns.includes(campaignId)
                ? selectedCampaigns.filter((id) => id !== campaignId)
                : [...selectedCampaigns, campaignId]
        );
    };

    const onAllCampaignsClick = () => {
        selectCampaigns(selectedCampaigns.length ? [] : data?.rows.map((row) => row.campaign_id) || []);
    };

    const ready = Boolean(!loading && selectedCampaigns.length);

    const onApply = async () => {
        await bulkUpdateCampaigns({
            campaigns: selectedCampaigns.map((id) => ({ id, dateTo: runtimeTo })),
        });
        afterUpdate();
        toggle();
    };

    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '95vw' }}>
            <ModalHeader onClose={toggle}>
                <div className="d-flex align-items-center">
                    <span className="ModalHeader-title">Select new date for campaign runtime to</span>
                    <div className="d-flex align-items-center ms-2">
                        <DatePicker
                            onDateChange={(d) => setRuntimeTo(formatDate(d.toDateString()))}
                            date={runtimeTo}
                        />
                    </div>
                </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                <FormattedTable
                    NoDataComponent={error ? () => <ErrorMessage error={error} /> : undefined}
                    data={data?.rows || []}
                    loading={loading}
                    stickyHeader={false}
                    getTrProps={(_: any, rowInfo?: TableCell<DataRow>) => ({
                        className: classNames('cursor-pointer', {
                            selected: rowInfo && isCampaignSelected(rowInfo.original.campaign_id),
                        }),
                        onClick: rowInfo ? () => onRowClick(rowInfo.original.campaign_id) : noop,
                    })}
                    columns={[
                        {
                            Header: () => (
                                <Input
                                    type="checkbox"
                                    className="position-static m-0"
                                    checked={!!selectedCampaigns.length}
                                    onChange={onAllCampaignsClick}
                                />
                            ),
                            width: 70,
                            headerClassName: 'cell-align-center',
                            className: 'cell-align-center',
                            Cell: (props: TableCell<DataRow>) => (
                                <Input
                                    type="checkbox"
                                    className="position-static m-0"
                                    checked={isCampaignSelected(props.original.campaign_id)}
                                    readOnly
                                />
                            ),
                            sortable: false,
                        },
                        {
                            Header: 'Advertiser name',
                            autoWidth: true,
                            Cell: (props: TableCell<DataRow>) => (
                                <LabelWithSubtext
                                    label={props.original.advertiser_name}
                                    subtext={DATA_TYPES.ID.format(props.original.advertiser_id)}
                                />
                            ),
                        },
                        {
                            Header: 'Campaign name',
                            accessor: (row: DataRow) => row.campaign_name,
                            Cell: (props: TableCell<DataRow>) => (
                                <>
                                    <CampaignStateIndicator
                                        className="me-2"
                                        state={props.original.campaign_state}
                                    />
                                    <LabelWithSubtext
                                        label={props.original.campaign_name}
                                        subtext={DATA_TYPES.ID.format(props.original.campaign_id)}
                                    />
                                </>
                            ),
                            id: 'name',
                            autoWidth: true,
                        },
                        {
                            Header: 'Runtime from',
                            accessor: (row: DataRow) => row.campaign_runtime_from,
                            type: DATA_TYPES.TEXT,
                            autoWidth: true,
                            id: 'runtimeFrom',
                        },
                        {
                            Header: 'Current runtime to',
                            accessor: (row: DataRow) => row.campaign_runtime_to,
                            type: DATA_TYPES.TEXT,
                            autoWidth: true,
                            id: 'runtimeTo',
                        },
                        {
                            Header: 'Extended by (in days)',
                            accessor: (row: DataRow) => calcDaysAdded(row),
                            type: DATA_TYPES.TEXT,
                            autoWidth: true,
                            id: 'extended',
                        },
                    ]}
                />
            </ModalBody>
            <ModalFooter>
                <div>
                    {ready &&
                        `Update will affect ${selectedCampaigns.length} campain(s) with their strategies having the same end date`}
                </div>
                <div>
                    <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    <AnimatedButton disabled={!ready} onClick={onApply}>
                        Update
                    </AnimatedButton>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default BulkCampaignRuntimeModal;
