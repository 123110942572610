import { FieldArray, useFormikContext } from 'formik';
import { uniqueId } from 'lodash-es';
import CreativeBulkUploadButton from 'platform/creatives/components/CreativeBulkUpload/CreativeBulkUploadButton';
import CreativeExportButton from 'platform/creatives/components/CreativeImportExport/CreativeExportButton';
import CreativeImportButton from 'platform/creatives/components/CreativeImportExport/CreativeImportButton';
import creativeImportExport from 'platform/creatives/components/CreativeImportExport/creativeImportExport';
import ButtonDropdown from '../../../common/components/ButtonDropdown/ButtonDropdown';
import { CREATIVE_TYPES_LABELS, CreativeType } from '../../constants/creativeTypes';
import { ConceptModel } from '../../types/creative.types';
import CreativeBlocks from '../CreativeBlocks/CreativeBlocks';
import CreativeBulkSchedulingButton from '../CreativeBulkSheduling/CreativeBulkSchedulingButton';

const getDefaultCreativeFields = (type: CreativeType) => ({
    tempId: uniqueId('creative'),
    isNew: true,
    type,
    name: '',
    clickThroughUrl: '',
    width: '',
    height: '',
    externalVideoAsset: '',
    file: '',
});

const GCM_CREATIVES: CreativeType[] = ['GCM_TRACKER', 'GCM_HOSTED', 'GCM_VIDEO'];

const ConceptCreativesTab = () => {
    const {
        setFieldValue,
        values: { creatives, adserver, advertiserId, type: conceptType, id },
    } = useFormikContext<ConceptModel>();

    const exportableCreatives = creatives.filter(creativeImportExport.isSupportedType);

    return (
        <FieldArray name="creatives">
            {({ remove, unshift }) => (
                <div>
                    <div className="mb-3">
                        {conceptType === 'STANDARD' && adserver && (
                            <div className="d-flex">
                                <ButtonDropdown
                                    items={GCM_CREATIVES.map((creativeType) => ({
                                        label: CREATIVE_TYPES_LABELS[creativeType],
                                        action: () => unshift(getDefaultCreativeFields(creativeType)),
                                    }))}
                                >
                                    Add creative
                                </ButtonDropdown>
                                {adserver === 'GCM' && (
                                    <>
                                        <CreativeBulkUploadButton
                                            className="ms-2"
                                            advertiserId={advertiserId}
                                            onUpload={(uploaded) =>
                                                setFieldValue('creatives', [...creatives, ...uploaded])
                                            }
                                        />
                                        <CreativeBulkSchedulingButton
                                            onApply={(runtime) => {
                                                creatives.forEach((_, index) => {
                                                    setFieldValue(`creatives[${index}].runtime`, runtime);
                                                    setFieldValue(`creatives[${index}].scheduling`, true);
                                                });
                                            }}
                                        />
                                    </>
                                )}
                                {id && !!exportableCreatives.length && (
                                    <>
                                        <CreativeExportButton
                                            className="ms-2"
                                            creatives={exportableCreatives}
                                        />
                                        <CreativeImportButton
                                            className="ms-2"
                                            creatives={exportableCreatives}
                                            updateCreatives={(updated) => setFieldValue('creatives', updated)}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </div>

                    <CreativeBlocks onCreativeRemove={remove} />
                </div>
            )}
        </FieldArray>
    );
};

export default ConceptCreativesTab;
