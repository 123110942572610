import { Button, Form, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Formik } from 'formik';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import FormRow from 'platform/common/components/FormRow/FormRow';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import { getPlaceholders } from './ObserverForm/ObserverForm';
import { downloadObserverReport, getObserverReport } from './observer.service';

const getInitialValues = (placeholders: string[]) =>
    placeholders.reduce((acc, placeholder) => ({ ...acc, [placeholder]: '' }), {});

interface Props {
    toggle: () => void;
    reportId: number;
}

const ObserverDownloadModal = ({ toggle, reportId }: Props) => {
    const [{ data: observerReport, loading }] = usePromise(undefined, () => getObserverReport(reportId));

    if (loading) {
        return <OverlayLoader />;
    }

    const queryPlaceholders = getPlaceholders(observerReport?.query);

    const onSubmit = async (model: { [key: string]: string }) => {
        if (!observerReport || !observerReport.accessToken) return;

        await downloadObserverReport(observerReport.id, observerReport?.accessToken, model);
        toggle();
    };

    return (
        <Formik initialValues={getInitialValues(queryPlaceholders)} onSubmit={onSubmit}>
            {({ submitForm }) => (
                <Modal isOpen toggle={toggle} style={{ maxWidth: '30vw' }}>
                    <ModalHeader onClose={toggle}>Fill in placeholder values</ModalHeader>
                    <ModalBody style={{ maxHeight: '80vh', overflow: 'scroll', paddingTop: '2rem' }}>
                        <Form>
                            {queryPlaceholders.map((placeholder) => (
                                <FormRow label={placeholder} key={placeholder}>
                                    <FormInput
                                        name={placeholder}
                                        validate={required}
                                        type="text"
                                        id={placeholder}
                                    />
                                </FormRow>
                            ))}
                        </Form>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-between align-items-center">
                        <div>{`Download ${observerReport?.name}`}</div>
                        <div>
                            <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                            <AnimatedButton onClick={submitForm}>Download</AnimatedButton>
                        </div>
                    </ModalFooter>
                </Modal>
            )}
        </Formik>
    );
};

export default ObserverDownloadModal;
