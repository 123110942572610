import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import moment from 'moment';
import { ReportAiSummaryContainerProps } from 'platform/analytics/reportComponents/ReportTableContainer/ReportAiSummaryContainer/ReportAiSummaryContainer';
import { sendChatMessages } from 'platform/chat/chat.service';
import { ChatMessage } from 'platform/chat/chat.types';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import IconButton from 'platform/common/components/IconButton/IconButton';
import Popover from 'platform/common/components/Popover/Popover';
import { ISO_DATE_FORMAT } from 'platform/common/constants/dateConfiguration.constant';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import useToggle from 'platform/common/hooks/useToggle';
import { User } from 'platform/userManagement/types/user.type';
import { getTaskBoardChangesByPeriod } from '../task.service';
import { TaskLane, TaskChange, TaskBoardChange, TaskBoardState } from '../task.types';
import { formatTaskBoardHistoryData } from '../task.util';

const defaultDateRange: (string | undefined)[] = [
    moment().subtract(7, 'days').format(ISO_DATE_FORMAT),
    moment().format(ISO_DATE_FORMAT),
];

type HistoryPeriod = {
    dateFrom?: string;
    dateTo?: string;
};

interface Props {
    board: TaskBoardState;
    filteredLanes: TaskLane[];
    userOptions: User[];
    summaryProps: ReportAiSummaryContainerProps;
}
const KanbanAiHistoryPeriodPopover = ({ filteredLanes, userOptions, summaryProps, board }: Props) => {
    const isMobile = useSelector(layoutSelectors.isMobile);
    const [historyOpen, toggleHistory] = useToggle(false);
    const [[dateFrom, dateTo], setDateFilter] = useState(defaultDateRange);
    const popoverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (!e.target) {
                return;
            }
            const targetElement = e.target as Element;
            const clickedOutsidePopover = popoverRef?.current && !popoverRef.current?.contains(targetElement);
            const clickedOutsideDateRangePickerPopover =
                targetElement.closest('.DateRangePickerPopover') === null;

            if (clickedOutsidePopover && clickedOutsideDateRangePickerPopover) {
                toggleHistory();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [toggleHistory]);

    const generateHistorySummary = async (historyPeriod: HistoryPeriod) => {
        summaryProps.setSummaryOpen(true);
        const lanesData = filteredLanes.map((lane) => ({ id: lane.id, name: lane.name }));
        const tasksData = filteredLanes
            .flatMap((fl) => fl.tasks)
            .map((tsk) => ({ id: tsk.id, name: tsk.name }));

        const allChanges: TaskBoardChange[] = await getTaskBoardChangesByPeriod(board.id, {
            dateFrom: historyPeriod?.dateFrom,
            dateTo: historyPeriod?.dateTo,
            include: 'ALL',
        });
        const finalArray = [];

        if (allChanges.length) {
            const filteredChanges = formatTaskBoardHistoryData(
                board,
                userOptions,
                allChanges.filter((ch: TaskChange) => tasksData.map((tsk) => tsk.id).includes(ch?.taskId)),
                lanesData,
                tasksData
            ).reverse();

            const batches = [];
            for (let i = 0; i < filteredChanges.length; i += 40) {
                batches.push(filteredChanges.slice(i, i + 40));
            }
            let lastResponse: string = '';
            // eslint-disable-next-line no-restricted-syntax
            for (const batch of batches) {
                const call: ChatMessage = {
                    role: 'user',
                    contentParts: [
                        {
                            type: 'TEXT',
                            content: JSON.stringify({
                                data: batch,
                                lastResponse,
                            }),
                        },
                    ],
                };
                // eslint-disable-next-line no-await-in-loop
                const res = await sendChatMessages({
                    agentId: 20,
                    messages: [call],
                    placeholders: {},
                });

                finalArray.push(res?.message);
                lastResponse = res?.message;
            }

            if (finalArray.length < 1) {
                summaryProps.setSummaryOpen(false);
                return;
            }
            summaryProps.setTextSummary(lastResponse);
        }
    };

    return (
        <>
            <Popover
                placement="bottom"
                preventOverflow
                isOpen={historyOpen}
                preventToggle
                renderPopover={() => (
                    <div ref={popoverRef} className="d-flex gap-2">
                        <DateRangePicker
                            label="Date range"
                            from={dateFrom}
                            to={dateTo}
                            ranges={{}}
                            onChange={(from, to) => setDateFilter([from, to])}
                        />
                        <Button
                            onClick={() => {
                                summaryProps.withLoading(() => generateHistorySummary({ dateFrom, dateTo }));
                                toggleHistory();
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                )}
            >
                {isMobile ? (
                    <IconButton className="ms-2" onClick={() => toggleHistory()}>
                        <i className="text-primary fa-solid fa-wand-magic-sparkles me-1" />
                    </IconButton>
                ) : (
                    <Button className="ms-2" onClick={() => toggleHistory()}>
                        <i className="fa-solid fa-wand-magic-sparkles me-1" /> AI summarized history
                    </Button>
                )}
            </Popover>
        </>
    );
};

export default KanbanAiHistoryPeriodPopover;
