import * as CSV from 'csv-string';
import FileSaver from 'file-saver';
import { get, identity, isFunction } from 'lodash-es';
import XLSX from 'xlsx';
import { TableColumn } from '../FormattedTable/FormattedTable';

// https://docs.microsoft.com/en-us/scripting/javascript/advanced/special-characters-javascript#escape-sequences
// Add Byte Order Mark to indicate Unicode (recognized by MS Excel)
const BOM = '\uFEFF';

const determineColumnValue = (col: TableColumn, row: any) => {
    const formatValue = col.type ? col.type.parseForExport : identity;

    if (col.exportCell) {
        return formatValue(col.exportCell(row));
    }

    if (col.accessor) {
        return formatValue(isFunction(col.accessor) ? col.accessor(row) : get(row, col.accessor));
    }

    return '';
};

export const toExportedRows = (columns: TableColumn[], data: any[], headers: string[][] = []): string[][] => {
    const filteredColumns = columns.filter((col) => !!col.accessor || !!col.exportCell);
    const tableBody = data.map((row) => filteredColumns.map((col) => determineColumnValue(col, row)));
    const tableHeader = headers.length ? headers : [filteredColumns.map((col) => col.Header)];
    return [...tableHeader, ...tableBody];
};

export const toCsvFile = (rows: string[][]) =>
    new Blob([BOM, CSV.stringify(rows, ';')], { type: 'application/octet-stream' });

export const downloadCsv = (
    columns: TableColumn[],
    data: any[],
    fileName: string,
    headers: string[][] = []
) => {
    const rows = toExportedRows(columns, data, headers);
    FileSaver.saveAs(toCsvFile(rows), `${fileName}.csv`);
};

export const downloadXlsx = (
    columns: TableColumn[],
    data: any[],
    fileName: string,
    headers: string[][] = []
) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(toExportedRows(columns, data, headers));
    // Max sheet name length is 31 chars
    const sheetName = fileName.substring(0, 30);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    FileSaver.saveAs(new Blob([workbookOut], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
};
