import CategoryList from './CategoryList';
import { CATEGORIZATION } from './category.navigation';

const categoryRoutes = [
    {
        ...CATEGORIZATION,
        component: CategoryList,
    },
];

export default categoryRoutes;
