import { useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import qs from 'qs';
import { fetchSecrets } from 'platform/advertisers/services/advertiser.service';
import { GOOGLE_TOKEN_RETRIEVAL_PATH } from 'platform/app/app.navigation';
import { parseQuery } from 'platform/common/utils/url.util';

const SCOPES = {
    GOOGLE_ANALYTICS: 'https://www.googleapis.com/auth/analytics',
    GOOGLE_SEARCH_CONSOLE: 'https://www.googleapis.com/auth/webmasters.readonly',
};

const GoogleCredentials = () => {
    const location = useLocation();
    const { oneTimeHash, advertiserId, advertiserName, system } = parseQuery(location.search);
    const onClick = async () => {
        const { clientId } = await fetchSecrets(system);
        window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${qs.stringify({
            scope: SCOPES[system as keyof typeof SCOPES] ?? '',
            client_id: clientId,
            redirect_uri: `${window.location.origin}${GOOGLE_TOKEN_RETRIEVAL_PATH}`,
            access_type: 'offline',
            response_type: 'code',
            prompt: 'consent',
            state: JSON.stringify({ system, advertiserId, advertiserName, oneTimeHash }),
        })}`;
    };

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="8">
                        <Card className="p-4">
                            <CardBody>
                                <h2 className="mb-4">Authentication page</h2>

                                <p>Advertiser name: {advertiserName}</p>

                                <Button color="primary" className="px-4" onClick={onClick}>
                                    Authenticate with Google
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default GoogleCredentials;
