import {
    ComponentProps,
    ComponentType,
    CSSProperties,
    ElementType,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Column, ComponentPropsGetterRC, RowInfo, SortingRule } from 'react-table';
import { Card } from 'reactstrap';
import classNames from 'classnames';
import { isNil } from 'lodash-es';
import memoizeOne from 'memoize-one';
import { ColumnColouringType, ColumnsColouring, ReportTableCell } from 'platform/analytics/analytics.types';
import { Modify, Paths, Sort, TypeDefinition } from 'platform/common/common.types';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import { precisionRound } from 'platform/common/utils/formatters.util';
import DataExportButton, { ExportOptions } from '../DataExportButton/DataExportButton';
import Pagination from '../Pagination/Pagination';
import SearchNotification from '../SearchNotification/SearchNotification';
import NestedTable from './NestedTable';
import ReactTableWithStickyColumns, { ReactTableProps } from './ReactTableWithStickyColumns';
import ReactTableWithStickyHeader from './ReactTableWithStickyHeader';
import TablePlaceholder from './TablePlaceholder';
import { formatColumns } from './formatColumns';
import './FormattedTable.scss';

const PAGINATION_THRESHOLD = 5;

export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100, 200];

const toTableSort = (sort: Sort): SortingRule => ({
    id: sort.orderBy,
    desc: sort.direction === 'DESC',
});

const toAppSort = (sort: SortingRule): Sort => ({
    orderBy: sort.id,
    direction: sort.desc ? 'DESC' : 'ASC',
});

export type Accessor<T extends Record<string, any> = Record<string, any>> =
    | ((data: T) => string | number | undefined)
    | Paths<T>
    | undefined;

export interface TableColumn<T extends Record<string, any> = any> extends Column {
    Header?: string | ComponentType<{ formattedValue: string; data: Record<string, any> }>;
    HeaderText?: string;
    accessor?: Accessor<T>;
    type?: TypeDefinition;
    exportOnly?: boolean;
    screenOnly?: boolean;
    Cell?: (cellInfo: ReportTableCell<T>) => ReactNode;
    exportCell?: (row: T) => any;
    getProps?: ComponentPropsGetterRC;
    columns?: TableColumn[];
    sticky?: boolean;
    headerStyle?: CSSProperties;
    style?: CSSProperties;
    autoWidth?: boolean;
    autoWidthAdditionalWidth?: number;
    collapsable?: boolean;
    expandableHeader?: boolean;
    isDerivative?: boolean;
    depth?: number;
}

export type TableColumnProcessed = Modify<
    TableColumn,
    {
        id: string;
        columns?: TableColumnProcessed[];
    }
>;

export type ChildrenLoader = {
    field: string;
    lazyLoad?: (parent: Record<string, any>, offset?: number) => Promise<Record<string, any>[]>;
};

type Props = Modify<
    ReactTableProps,
    {
        topToolbar?: ReactNode;
        topRightToolbar?: ReactNode;
        columns: TableColumn[];
        data: Object[];
        exportData?: Modify<ExportOptions, { loadData?: () => any }>;
        childrenOffset: number;
        columnsAlignedFromIndex: number;
        defaultPageSize: number;
        containerClass?: string;
        defaultSorted?: Sort[];
        sorted?: Sort[];
        childrenCountField?: string;
        style?: CSSProperties;
        containerStyle?: CSSProperties;
        columnsColouring?: ColumnsColouring;
        visible: boolean;
        isTree: boolean;
        stickyHeader: boolean;
        nodesExpanded: boolean;
        childrenLoaders?: ChildrenLoader[];
        onPageSizeChange?: (pageSize: number) => void;
        onPageChange?: (pageNumber: number) => void;
        onSortedChange?: (sort: Sort[]) => void;
        onDataExplorerClick?: () => void;
        withLoadMore?: boolean;
    }
>;

interface State {
    pageSize: number;
    collapsed: string[];
    expanded: string[];
}

const filterColumnsAndChildren = (
    columns: TableColumnProcessed[],
    predicate: (tc: TableColumnProcessed) => boolean
) =>
    columns.filter(predicate).map((column) =>
        column.columns
            ? {
                  ...column,
                  columns: column.columns.filter(predicate),
              }
            : column
    );

const flattenGroups = (columns: TableColumnProcessed[]) =>
    columns.reduce((acc, col) => {
        if (col.columns?.length) {
            return [...acc, ...col.columns];
        }

        return [...acc, col];
    }, []);

const defaultProps = {
    visible: true,
    columns: [],
    data: [],
    isTree: false,
    childrenLoaders: [{ field: 'children' }],
    childrenOffset: 20,
    columnsAlignedFromIndex: 4,
    nodesExpanded: false,
    minRows: 0,
    resizable: false,
    showPagination: true,
    defaultPageSize: 100,
    pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
    stickyHeader: true,
};

const getNoDataComponent = (NoDataComponent?: ElementType) =>
    NoDataComponent ??
    (() => (
        <SearchNotification
            header="No results found"
            text="Nothing matches your filters or search query"
            style={{ padding: '4rem 0' }}
        />
    ));

const getCellStyle = (cellValue?: unknown, columnColouring?: ColumnColouringType) => {
    if (!(typeof cellValue === 'number') || isNil(cellValue) || !columnColouring) {
        return {};
    }
    const value = precisionRound(cellValue, 2);
    const white = '#fff';
    const maxColour =
        (value > columnColouring.max || value === columnColouring.max) && columnColouring.maxColour;
    const minColour =
        (value < columnColouring.min || value === columnColouring.min) && columnColouring.minColour;
    const color = maxColour || minColour || undefined;
    if (!color) {
        return {};
    }
    return columnColouring.type === 'CELL' ? { backgroundColor: color, color: white } : { color };
};

const getTdProps = (tdProps?: any, cellValue?: unknown, columnColouring?: ColumnColouringType) => {
    const cellStyle = getCellStyle(cellValue, columnColouring);
    if (tdProps) {
        return {
            ...tdProps,
            style: { ...(tdProps.style ?? {}), ...cellStyle },
        };
    }

    return {
        style: {
            ...cellStyle,
            position: 'relative',
        },
    };
};

const getSort = (sort: SortingRule[], previousSort: Sort[] | undefined, column: TableColumnProcessed) =>
    sort
        .filter(
            (s) =>
                !previousSort?.find(
                    (i) => s.id === column.id && i.orderBy === column.id && i.direction === 'DESC'
                )
        )
        .map(toAppSort);

/**
 * React-table wrapper which formats columns/values based on column.type
 * See `dataTypes.ts` for available formats.
 */

const FormattedTable = (props: Props) => {
    const [state, setState] = useState<State>({
        pageSize: props.defaultPageSize,
        collapsed: [],
        expanded: [],
    });

    const [localSort, setLocalSort] = useState<Sort[] | undefined>(props.sorted);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLocalSort(props.sorted);
    }, [props.sorted]);

    const isMobile = useSelector(layoutSelectors.isMobile);
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);

    const handleSortChange = (sort: SortingRule[], column: TableColumnProcessed) => {
        const value = getSort(sort, localSort, column);
        props.onSortedChange?.(value);
        setLocalSort(value);
    };

    const handleCollapse = (id: string) => () => {
        setState((s) => {
            if (s.collapsed.includes(id)) {
                return { ...s, collapsed: s.collapsed.filter((c) => c !== id) };
            }

            return { ...s, collapsed: [...s.collapsed, id] };
        });
    };

    const handleExpand = (id: string) => () => {
        setState((s) => {
            if (s.expanded.includes(id)) {
                return { ...s, expanded: s.expanded.filter((c) => c !== id) };
            }

            return { ...s, expanded: [...s.expanded, id] };
        });
    };

    const getExportOptions = (): ExportOptions => {
        if (!props.exportData) {
            throw new Error('No export options passed');
        }

        return {
            ...props.exportData,
            loadData: props.exportData.loadData || (() => props.data),
        };
    };

    const getScreenColumns = memoizeOne((formattedColumns: TableColumnProcessed[]) =>
        filterColumnsAndChildren(formattedColumns, (col) => !col.exportOnly)
    );

    const getExportColumns = memoizeOne((formattedColumns: TableColumnProcessed[]) =>
        flattenGroups(filterColumnsAndChildren(formattedColumns, (col) => !col.screenOnly)).map((col) => ({
            ...col,
            Header: col.HeaderText,
        }))
    );

    const handlePageSizeChange = (pageSize: number) => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollIntoView();
        }
        if (!isNil(props.pageSize) && props.onPageSizeChange) {
            props.onPageSizeChange(pageSize);
            return;
        }

        setState({ ...state, pageSize });
    };

    const renderTableComponent = (screenColumns: TableColumnProcessed[], hasToolbar: boolean) => {
        const {
            visible,
            isTree,
            childrenOffset,
            data,
            minRows,
            className,
            showPagination,
            pages,
            pageSizeOptions,
            stickyHeader,
            NoDataComponent,
            sorted,
            defaultSorted,
            containerStyle,
            columnsColouring,
            onSortedChange,
            ...rest
        } = props;

        if (!visible) {
            return null;
        }

        if (isTree) {
            return (
                <NestedTable
                    {...rest}
                    depth={0}
                    stickyHeader={stickyHeader}
                    columns={screenColumns}
                    elements={data}
                    offset={childrenOffset}
                    NoDataComponent={getNoDataComponent(NoDataComponent)}
                    className={className}
                    sorted={localSort?.map(toTableSort)}
                    defaultSorted={defaultSorted?.map(toTableSort)}
                    onSortedChange={handleSortChange}
                />
            );
        }

        const TableComponent: ComponentType<ComponentProps<typeof ReactTableWithStickyColumns>> = stickyHeader
            ? ReactTableWithStickyHeader
            : ReactTableWithStickyColumns;

        const paginationVisible = showPagination && (data.length > PAGINATION_THRESHOLD || (pages || 0) > 1);

        return (
            <TableComponent
                {...rest}
                columns={screenColumns}
                data={data}
                minRows={minRows}
                className={classNames('-highlight mx-3 mb-3', { 'mt-3': !hasToolbar }, className)}
                pageSize={props.pageSize || state.pageSize}
                pages={pages}
                showPagination={paginationVisible}
                onPageSizeChange={handlePageSizeChange}
                NoDataComponent={getNoDataComponent(NoDataComponent)}
                LoadingComponent={TablePlaceholder}
                PaginationComponent={Pagination}
                pageSizeOptions={pageSizeOptions}
                sorted={localSort?.map(toTableSort)}
                defaultSorted={defaultSorted?.map(toTableSort)}
                onSortedChange={handleSortChange}
                getTdProps={(s: any, rowInfo: RowInfo, column: TableColumnProcessed) =>
                    getTdProps(rest.getTdProps, rowInfo.original[column.id], columnsColouring?.[column.id])
                }
            />
        );
    };

    const formattedColumns = formatColumns(
        props.columns,
        props.data,
        commonClassifiers,
        state.collapsed,
        state.expanded,
        handleCollapse,
        handleExpand
    );
    const screenColumns = getScreenColumns(formattedColumns);
    const showExportData = props.exportData && props.visible && !!props.data.length;
    const showExplorerButton = props.onDataExplorerClick && props.visible && !!props.data.length;
    const hasToolbar = props.topToolbar || props.topRightToolbar || showExportData || showExplorerButton;

    return (
        <Card
            style={props.containerStyle || props.style}
            className={classNames('FormattedTable mb-0', props.containerClass)}
        >
            <div ref={tableContainerRef} className="d-flex flex-column h-100">
                {hasToolbar && (
                    <div className="FormattedTable-toolbarContainer">
                        <div className="FormattedTable-leftToolbar">{props.topToolbar}</div>
                        <div className="FormattedTable-rightToolbarContainer">
                            <div
                                className={classNames({
                                    'FormattedTable-rightToolbar': showExportData || showExplorerButton,
                                })}
                            >
                                {props.topRightToolbar}
                            </div>
                            {!isMobile && showExplorerButton && !!props.onDataExplorerClick && (
                                <IconButton
                                    title="Data Explorer"
                                    disabled={props.loading}
                                    onClick={props.onDataExplorerClick}
                                >
                                    <i className="fal fa-chart-bar" />
                                </IconButton>
                            )}
                            {!isMobile && showExportData && (
                                <DataExportButton
                                    disabled={props.loading}
                                    columns={getExportColumns(formattedColumns)}
                                    exportOptions={getExportOptions()}
                                />
                            )}
                        </div>
                    </div>
                )}
                {renderTableComponent(screenColumns, !!hasToolbar)}
            </div>
        </Card>
    );
};

FormattedTable.defaultProps = defaultProps;

export default FormattedTable;
