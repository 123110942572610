import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { isString } from 'lodash-es';
import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import useErrorHandler from 'platform/common/hooks/useErrorHandler';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';
import { getActiveAdvertiserId } from 'platform/common/utils/advertiser.util';
import { parseQuery } from 'platform/common/utils/url.util';
import { ConceptType } from 'platform/creatives/constants/conceptTypes';
import CreativeMapper from 'platform/creatives/mappers/creative.mapper';
import { createConcept, fetchConcept, updateConcept } from 'platform/creatives/services/creative.service';
import { ConceptModel, GcmCreativeModel } from 'platform/creatives/types/creative.types';
import { conceptValidator, withValidatorResolve } from 'platform/creatives/utils/creativeValidate.utils';
import { fetchSeat } from 'platform/userManagement/services/userManagement.service';
import CreativeForm from './CreativeForm';

export const CREATIVE_FORM_HELP_KEY = 'creative_form';

const validate = (concept: ConceptModel) => {
    const validationResult = withValidatorResolve(conceptValidator)(concept as any);

    return {
        ...(validationResult as {}),
    };
};

const getSeatName = async (seatId?: number) => {
    if (!seatId) return undefined;
    const seat = seatId && (await fetchSeat(seatId));
    if (!seat) return `Seat_${seatId}`;
    return seat.name;
};

type Props = FormContainerProps & {
    isDemoMode: boolean;
};

const CreativeFormContainer = ({ isDemoMode, isEdit, afterSubmit, ...formContainerProps }: Props) => {
    const location = useLocation();
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const [{ errors, requestPayload, traceId }, handleErrors] = useErrorHandler();

    const advertiser = useTypedSelector(activeAdvertiserSelectors.activeAdvertiser);
    const [missingCMIntegration, setMissingCMIntegration] = useState(false);

    const {
        regionalSettings: { timeZoneId },
    } = useTypedSelector(activeAdvertiserSelectors.activeAdvertiser);

    const handleCreateOpen = async (): Promise<ConceptModel> => {
        const { type, externalAdvertiserId }: { type: ConceptType; externalAdvertiserId: string } =
            parseQuery(location.search, {
                parseNumbers: false,
            });
        if (!isString(type)) {
            throw new Error('Missing mandatory search params');
        }

        const fetchedAdvertiser = await fetchAdvertiser(getActiveAdvertiserId());
        setMissingCMIntegration(
            !fetchedAdvertiser?.externalSystemIntegrations?.some((item) => item.externalSystem === 'GCM')
        );
        return CreativeMapper.toFormModel({
            concept: {
                type,
                advertiserId: fetchedAdvertiser.id,
                externalAdvertiserId,
                adserver: fetchedAdvertiser.activeAdserver,
                name: '',
                state: 'ACTIVE',
                creatives: [],
            },
            seatName: await getSeatName(fetchedAdvertiser.seatId),
            timezone: fetchedAdvertiser.regionalSettings.timeZoneId,
        });
    };

    const handleCreateSubmit = (formValues: ConceptModel) =>
        createConcept(
            CreativeMapper.toApi(trimUrlData(formValues), advertiser.regionalSettings.timeZoneId)
        ).catch(handleErrors);

    const handleEditOpen = async (): Promise<ConceptModel> =>
        fetchConcept(id).then((concept) =>
            CreativeMapper.toFormModel({
                concept,
                isDemoMode,
                timezone: timeZoneId,
            })
        );

    const handleEditSubmit = (formValues: ConceptModel) =>
        updateConcept(
            CreativeMapper.toApi(trimUrlData(formValues), advertiser.regionalSettings.timeZoneId)
        ).catch(handleErrors);

    const trimUrlData = (formValues: ConceptModel) => ({
        ...formValues,
        creatives: formValues?.creatives?.map((item: GcmCreativeModel) => ({
            ...item,
            clickThroughUrl: item?.clickThroughUrl?.trim(),
            clickThroughUrlSuffix: item?.clickThroughUrlSuffix?.trim(),
        })),
    });

    return (
        <FormContainer
            helpKey={CREATIVE_FORM_HELP_KEY}
            validate={(values) => validate(values)}
            sidePanel
            onOpen={isEdit ? handleEditOpen : handleCreateOpen}
            onSubmit={isEdit ? handleEditSubmit : handleCreateSubmit}
            onSubmitFinish={afterSubmit}
            {...formContainerProps}
        >
            {(formProps) => (
                <CreativeForm
                    backendErrors={errors}
                    requestPayload={requestPayload}
                    traceId={traceId}
                    missingCMIntegration={missingCMIntegration}
                    {...formProps}
                />
            )}
        </FormContainer>
    );
};

export default CreativeFormContainer;
