import { useState } from 'react';
import { useSelector } from 'react-redux';
import DemoVideos from 'platform/app/components/Intro/DemoVideos/DemoVideos';
import WelcomeScreen from 'platform/app/components/Intro/WelcomeScreen/WelcomeScreen';
import Title from 'platform/app/components/Intro/images/Title.png';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import Overlay from 'platform/common/components/Overlay/Overlay';
import './Intro.scss';

const modalZIndex = 1050;

const Intro = () => {
    const [showIntro, setShowIntro] = useState(true);
    const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined);
    const [showDemoVideos, setShowDemoVideos] = useState(false);
    const { demoModeEnabled, login } = useSelector(authSelectors.ready.profile);
    const showConfirmationModal = useConfirmationModal();

    if (!showIntro || !demoModeEnabled || login !== 'telekom_demo@deepmedia.de') {
        return null;
    }

    const handelClose = () => {
        showConfirmationModal(
            () => {
                if (videoSrc) {
                    setVideoSrc(undefined);
                    setShowDemoVideos(true);
                } else {
                    setShowIntro(false);
                }
            },
            {
                title: 'Do you really want to leave?',
                text: 'Are you sure you want to leave this video? You can always come back.',
            }
        );
    };

    return (
        <Overlay style={{ zIndex: modalZIndex - 1 }} onClick={handelClose}>
            <div className="Intro">
                <div
                    role="button"
                    tabIndex={0}
                    className="Intro-container"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className="Intro-header">
                        <div className="Intro-title">Welcome to the Deep Media Platform</div>
                        <button type="button" className="Intro-button ms-auto me-1" onClick={handelClose}>
                            <i className="far fa-circle-xmark" />
                        </button>
                    </div>
                    <div className="Intro-body">
                        {videoSrc && (
                            <video
                                controls
                                autoPlay
                                disablePictureInPicture
                                height="100%"
                                controlsList={'nofullscreen'}
                                playsInline
                                onEnded={() => {
                                    setVideoSrc(undefined);
                                    setShowDemoVideos(true);
                                }}
                            >
                                <source src={videoSrc} type="video/mp4" />
                            </video>
                        )}
                        {!videoSrc && (
                            <>
                                <div className="Intro-body-left">
                                    <img src={Title} alt="Title" height={450} />
                                </div>
                                <div className="Intro-body-right">
                                    {showDemoVideos && (
                                        <DemoVideos
                                            onButtonClick={() => setShowIntro(false)}
                                            onVideoClick={(src) => {
                                                setVideoSrc(src);
                                            }}
                                        />
                                    )}
                                    {!showDemoVideos && (
                                        <WelcomeScreen
                                            onVideoButtonClick={setVideoSrc}
                                            onPlatformButtonClick={() => setShowIntro(false)}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Overlay>
    );
};

export default Intro;
