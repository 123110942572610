import classNames from 'classnames';
import ChatLanding from '../ChatLanding/ChatLanding';
import { ChatProviders } from '../ChatProviders/ChatProviders';
import MinimizedChat from './MinimizedChat';
import useSteps from './useSteps';
import '../ChatAnimations.scss';
import './MinimizedChatPopover.scss';

const MinimizedChatPopover = () => {
    const { step, nextStep, prevStep } = useSteps();

    return (
        <ChatProviders>
            <div className="MinimizedChatPopover">
                <ChatLanding
                    showLink
                    onAgentSelect={nextStep}
                    prevStep={prevStep}
                    className={classNames(
                        step === 0 ? 'ChatAnimations-visible' : 'ChatAnimations-hidden',
                        'ChatAnimations-transition'
                    )}
                />
                <MinimizedChat
                    onBackClick={prevStep}
                    className={classNames(
                        step === 1 ? 'ChatAnimations-visible' : 'ChatAnimations-hidden',
                        'ChatAnimations-transition'
                    )}
                />
            </div>
        </ChatProviders>
    );
};
export default MinimizedChatPopover;
