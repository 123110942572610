import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import BodyCell from './BodyCell';

const SkeletonCard = () => (
    <tr>
        <BodyCell showEmptyPlaceholder={false} cellClassName="pb-3" colSpan={7}>
            <Placeholder height="125.5px" />
        </BodyCell>
    </tr>
);

export default SkeletonCard;
