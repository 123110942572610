import { KeyboardEvent, useState } from 'react';
import { Input } from 'reactstrap';
import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';

type Props = {
    onSubmit: (text: string) => Promise<void>;
    onCancel: () => void;
};

const KanbanCommentForm = ({ onSubmit, onCancel }: Props) => {
    const [text, setText] = useState('');

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape') {
            onCancel();
        }
    };

    return (
        <div className="position-relative mb-3">
            <Input
                value={text}
                type="textarea"
                rows={3}
                autoFocus
                className="pe-5"
                placeholder="Type your comment here..."
                onChange={({ target }) => setText(target.value)}
                onKeyDown={handleKeyDown}
            />
            <IconButton className="Kanban-comment-send-icon" disabled={!text} onClick={() => onSubmit(text)}>
                <i className={classNames('far fa-paper-plane-top', text ? 'text-primary' : 'text-muted')} />
            </IconButton>
        </div>
    );
};

export default KanbanCommentForm;
