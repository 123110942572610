import { useState } from 'react';
import classNames from 'classnames';
import { SelectItem } from 'platform/common/common.types';
import InlineEditContainer from 'platform/common/components/InlineEditContainer/InlineEditContainer';
import SelectTree from 'platform/common/components/SelectTree/SelectTree';

interface Props<T> {
    values: T[];
    options: SelectItem<T>[];
    getDisplayName: (selectValues: T[], options: SelectItem<T>[]) => string;
    isLoading: boolean;
    isMulti?: boolean;
    closeMenuOnSelect?: boolean;
    placeholder: string;
    className?: string;
    onChange: (values: T[]) => void;
}

const InlineEditSelectTree = <T,>({
    values,
    options,
    getDisplayName,
    isLoading,
    isMulti = true,
    closeMenuOnSelect = false,
    placeholder,
    className,
    onChange,
}: Props<T>) => {
    const [selectValues, setSelectValues] = useState(values);
    return (
        <InlineEditContainer
            placeholder={placeholder}
            value={selectValues.length ? getDisplayName(selectValues, options) : undefined}
        >
            {(props) => (
                <SelectTree
                    value={selectValues}
                    className={classNames('me-1', className)}
                    options={options}
                    isLoading={isLoading}
                    isMulti={isMulti}
                    isRootNodeSelectable={false}
                    closeMenuOnSelect={closeMenuOnSelect}
                    isBreadcrumbsShown={false}
                    menuIsOpen
                    autoFocus
                    onBlur={() => {
                        onChange(selectValues);
                        props.toggleEditMode();
                    }}
                    onChange={setSelectValues}
                />
            )}
        </InlineEditContainer>
    );
};

export default InlineEditSelectTree;
