import { useEffect, useRef, useState } from 'react';
import ReactTable, { Column, TableProps } from 'react-table';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import { Modify } from 'platform/common/common.types';
import { TableColumnProcessed } from './FormattedTable';
import './ReactTableWithStickyColumns.scss';

export type ReactTableProps = Modify<
    Partial<TableProps>,
    {
        columns?: TableColumnProcessed[];
    }
>;

interface State {
    columnWidths: { [key: string]: number };
    tableWidth: number;
}

const colIdToHtmlId = (id?: string) => (id ? `th-${id}` : '');

const STICK_FREE_WIDTH_RATIO = 0.5;
const DISABLE_STICKINESS_BREAKPOINT = 768;

const initialState: State = {
    columnWidths: {},
    tableWidth: 1000,
};

const ReactTableWithStickyColumns = (props: ReactTableProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const columnWidthsStored = useRef(false);

    const [state, setState] = useState<State>(initialState);

    useEffect(() => {
        window.addEventListener('resize', storeColumnWidths);

        return () => {
            window.removeEventListener('resize', storeColumnWidths);
            storeColumnWidths.cancel();
        };
    }, []);

    useEffect(() => {
        storeColumnWidths();
    }, [props.data, props.columns]);

    const storeColumnWidths = debounce(() => {
        columnWidthsStored.current = true;
        const trackedColumns = Array.from(
            ref.current?.querySelectorAll<HTMLElement>('.track-column-width') || []
        );
        const table = ref.current?.querySelector<HTMLElement>('.rt-table');

        setState({
            columnWidths: trackedColumns.reduce((acc, node) => ({ ...acc, [node.id]: node.offsetWidth }), {}),
            tableWidth: table ? table.offsetWidth : state.tableWidth,
        });
    }, 1);

    const getTheadThProps = (
        _: any,
        __: any,
        column: TableColumnProcessed & { parentColumn?: TableColumnProcessed }
    ) => {
        // we trigger storing here as the columns are not yet rendered in componentDidMount
        if (!columnWidthsStored) {
            storeColumnWidths();
        }

        if (!column.sticky && !(column.parentColumn && column.parentColumn.sticky)) {
            return {};
        }

        return {
            id: colIdToHtmlId(column.id),
            className: classNames(column.className, 'track-column-width'),
        };
    };

    const sumColumnWidth = (columns: TableColumnProcessed[] = []): number =>
        columns
            .map((column) => state.columnWidths[colIdToHtmlId(column.id)] || 0)
            .reduce((acc, val) => acc + val, 0);

    const addStickyClasses = (
        columns: TableColumnProcessed[] = [],
        parentSticky: boolean = false,
        nextParentSticky: boolean = false,
        parentLeft: number = 0
    ): Column[] => {
        if (window.innerWidth < DISABLE_STICKINESS_BREAKPOINT) {
            return columns;
        }

        let haltSticking = false;

        return columns.map((column, index) => {
            const left = parentLeft + sumColumnWidth(columns.slice(0, index));
            if (left + sumColumnWidth([column]) > state.tableWidth * STICK_FREE_WIDTH_RATIO) {
                haltSticking = true;
            }

            if (haltSticking || !(column.sticky || parentSticky)) {
                return {
                    ...column,
                    columns: column.columns && addStickyClasses(column.columns),
                };
            }

            const nextSiblingSticky = columns[index + 1] && (columns[index + 1].sticky || parentSticky);
            const nextColSticky = !haltSticking && (nextSiblingSticky || nextParentSticky);

            return {
                ...column,
                className: classNames(column.className, 'sticky-column', {
                    'last-sticky-column': !nextColSticky,
                }),
                headerClassName: classNames(column.headerClassName, 'sticky-column', {
                    'last-sticky-column': !nextColSticky,
                }),
                columns: column.columns && addStickyClasses(column.columns, true, nextColSticky, left),
                style: { ...column.style, left },
                headerStyle: { ...column.headerStyle, left },
            };
        });
    };

    return (
        <div ref={ref} style={{ display: 'contents' }}>
            <ReactTable
                {...props}
                columns={addStickyClasses(props.columns)}
                onResizedChange={storeColumnWidths}
                getTheadThProps={getTheadThProps}
                getTheadGroupThProps={getTheadThProps}
            />
        </div>
    );
};

export default ReactTableWithStickyColumns;
