import axiosBff from '../../axios/axiosBff';

export const uploadImage = (
    file: {
        content: string;
        fileName: string;
        contentType: string;
        rescaleToSize?: {
            width: number;
            height: number;
        };
    },
    onUploadProgress?: (progressEvent: ProgressEvent<EventTarget>) => void
) => axiosBff.post<string>('/admin/api/images', file, { onUploadProgress }).then((response) => response.data);
