import classNames from 'classnames';
import { SegmentAlertingRules } from 'platform/advertiserTag/advertiserTag.types';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { getSegmentAlertingRuleLabels } from 'platform/segments/mappers/segment.mapper';

const SegmentAlertingIndicator = ({ rules }: { rules: Partial<SegmentAlertingRules> }) => {
    const ruleLabels = getSegmentAlertingRuleLabels(rules);
    return (
        <Tooltip
            renderTooltip={() =>
                ruleLabels.length
                    ? ruleLabels.map((label, index) => (
                          <div key={index} className="my-1">
                              {label}
                          </div>
                      ))
                    : 'Alerting is off'
            }
        >
            <i
                className={classNames('fa fa-triangle-exclamation', {
                    'text-success': ruleLabels.length > 0,
                    'text-muted': !ruleLabels.length,
                })}
            />
        </Tooltip>
    );
};

export default SegmentAlertingIndicator;
