import { Col, Row } from 'reactstrap';
import './Separator.scss';

type Props = {
    label: string;
    className?: string;
    labelWidth?: number;
};

const defaultProps = {
    label: '',
};

const Separator = ({ label, className, labelWidth = 4 }: Props) => (
    <Row className={`pl-separator font-xs ${className || ''}`}>
        <Col md={labelWidth} className="text-end">
            <div className="col-form-label">{label}</div>
        </Col>
        <Col md={12 - labelWidth}>
            <hr />
        </Col>
    </Row>
);

Separator.defaultProps = defaultProps;

export default Separator;
