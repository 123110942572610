import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import html5Icon from 'platform/assets/images/html5.png';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Separator from 'platform/common/components/Separator/Separator';
import CreativeRuntimeFields from 'platform/creatives/components/CreativeBlocks/CreativeRuntimeFields';
import LandingPageFields from 'platform/creatives/components/CreativeBlocks/LandingPageFields';
import {
    ACCEPTABLE_HTML5_IMAGE_LABEL,
    ACCEPTABLE_HTML5_IMAGE_TYPES,
    ACCEPTABLE_IMAGE_LABEL,
    ACCEPTABLE_IMAGE_TYPES,
} from 'platform/creatives/constants/asset.constant';
import { GcmHostedCreativeModel } from 'platform/creatives/mappers/creative/gcmHostedCreative.mapper';
import { getAssetMetadata, isImage } from 'platform/creatives/utils/asset.utils';
import { ValidatorResult } from 'platform/creatives/utils/creativeValidate.utils';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import FormUploadInput from 'platform/formik/FormUploadInput/FormUploadInput';
import { maxFileSize, required, url } from '../../../common/utils/validators.util';
import { CREATIVE_FORM_HELP_KEY } from '../CreativeForm/CreativeFormContainer';
import CreativeLabelSelect from './CreativeLabelSelect';
import './CreativeFields.scss';

type Props = {
    field: string;
    advertiserId: number;
};

export const gcmHostedCreativeValidator = (): ValidatorResult<GcmHostedCreativeModel> => ({
    name: required,
    clickThroughUrl: [required, url],
    width: required,
    height: required,
    file: [required, maxFileSize(3)],
    backupFile: [maxFileSize(3)],
});

const GcmHostedCreativeFields = ({ field, advertiserId }: Props) => {
    const { values, setFieldValue } = useFormikContext();
    const creative = get(values, field);
    const { id, tempId, file, backupFile, scheduling } = creative;

    const uniqueContentHtmlId = (forField: string) => `creative${id || tempId || ''}${forField}`;

    const [assetLoading, setAssetLoading] = useState(false);
    useEffect(() => {
        if (file) {
            setAssetLoading(true);
            // Once we finish loading the preview image, we can determine its original width and height.
            // However, there's a race condition when uploading a new image: sometimes DOM is updated
            // but the new image is not yet loaded when this effect is called, which means we get the
            // naturalWidth & naturalHeight of the old image. Adding a small timeout solves the problem.
            const timeout = setTimeout(() => {
                getAssetMetadata(file, uniqueContentHtmlId('fileAsset')).then((metadata) => {
                    setFieldValue(`${field}.width`, metadata.width);
                    setFieldValue(`${field}.height`, metadata.height);
                    setAssetLoading(false);
                });
            }, 500);
            return () => {
                setAssetLoading(false);
                clearTimeout(timeout);
            };
        }
        return () => {};
    }, [file]);

    const onRemoveBackup = () => {
        setFieldValue(`${field}.backupFile`, undefined);
    };

    return (
        <>
            <FormRow
                label="Name"
                labelClassName="ps-0 pe-3 text-end"
                childrenColClassName="ps-0"
                helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_name`}
            >
                <FormInput name={`${field}.name`} type="text" />
            </FormRow>
            {id && (
                <FormRow label="Internal ID" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_id`}>
                    <FormInput name={`${field}.id`} type="text" disabled />
                </FormRow>
            )}
            <LandingPageFields field={field} />
            <FormRow
                label="File"
                rowHtmlId={uniqueContentHtmlId('file')}
                helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_file`}
            >
                <div className="CreativeFields-uploadWithPreview">
                    <FormUploadInput
                        name={`${field}.file`}
                        acceptableMimeTypes={ACCEPTABLE_HTML5_IMAGE_TYPES}
                        subtitle={ACCEPTABLE_HTML5_IMAGE_LABEL}
                    />
                    {file?.content && (
                        <img
                            src={isImage(file) ? file?.content : html5Icon}
                            alt="Preview"
                            className="CreativeFields-assetPreview"
                            id={uniqueContentHtmlId('fileAsset')}
                        />
                    )}
                </div>
            </FormRow>
            <FormRow
                label="Backup file"
                rowHtmlId={uniqueContentHtmlId('backupFile')}
                childrenColClassName="CreativeFields-uploadWithPreview"
                helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_backupFile`}
            >
                <FormUploadInput
                    name={`${field}.backupFile`}
                    acceptableMimeTypes={ACCEPTABLE_IMAGE_TYPES}
                    subtitle={ACCEPTABLE_IMAGE_LABEL}
                    onRemoveItem={onRemoveBackup}
                />
                {backupFile?.content && (
                    <img src={backupFile?.content} alt="Preview" className="CreativeFields-assetPreview" />
                )}
            </FormRow>
            <FormRow label="Creative size" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_size`}>
                <div className="w-50 position-relative">
                    {assetLoading && <OverlayLoader />}
                    <div className="CreativeFields-assetSize">
                        <FormInput
                            id={`${field}.width`}
                            name={`${field}.width`}
                            type="text"
                            validate={required}
                        />
                        <span className="CreativeFields-assetSize-multiplier">x</span>
                        <FormInput
                            id={`${field}.height`}
                            name={`${field}.height`}
                            type="text"
                            validate={required}
                        />
                    </div>
                </div>
            </FormRow>

            <Separator label="Optional" />

            <FormRow
                label="Weight (0 - 10 000)"
                rowHtmlId={uniqueContentHtmlId('weight')}
                helpKey={`${CREATIVE_FORM_HELP_KEY}_hosted_weight`}
            >
                <FormNumberInput name={`${field}.weight`} />
            </FormRow>
            <FormRow
                label="Creative label"
                rowHtmlId={uniqueContentHtmlId('creativeLabel')}
                helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_labelId`}
            >
                <CreativeLabelSelect name={`${field}.creativeLabelId`} advertiserId={advertiserId} />
            </FormRow>
            <FormRow label="Scheduling" helpKey={`${CREATIVE_FORM_HELP_KEY}_scheduling`}>
                <FormSwitch name={`${field}.scheduling`} />
            </FormRow>
            {scheduling && <CreativeRuntimeFields field={`${field}.runtime`} />}
        </>
    );
};

export default GcmHostedCreativeFields;
