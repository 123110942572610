import { ChangeEvent, CSSProperties } from 'react';
import { FormFeedback, Input, InputGroup, InputGroupText, InputProps } from 'reactstrap';
import classNames from 'classnames';
import { useField } from 'formik';
import InputWithAddOn, { AddOn } from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { Validator, validator } from 'platform/common/utils/validators.util';

interface Props {
    label?: string;
    className?: string;
    containerClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    name: string;
    validate?: Validator | Validator[];
    isValidationMessageVisible?: boolean;
    leftAddOn?: AddOn;
    rightAddOn?: AddOn;
    style?: CSSProperties;
}

const FormNumberInput = ({
    label,
    className = '',
    containerClassName,
    inputClassName,
    labelClassName,
    name,
    validate,
    isValidationMessageVisible = true,
    leftAddOn,
    rightAddOn,
    style,
    ...rest
}: Props & InputProps) => {
    const [field, { error, touched }] = useField({ name, validate: validate && validator(validate) });
    const invalid = Boolean(touched && error);
    const showMsg = invalid && isValidationMessageVisible && error;
    const hasAddOn = !!leftAddOn || !!rightAddOn;

    const componentProps = {
        className: inputClassName,
        invalid,
        ...field,
        ...rest,
        checked: rest.type === 'checkbox' ? !!field.value : undefined,
    };

    if (hasAddOn) {
        return (
            <div className={className} style={style}>
                <InputWithAddOn
                    leftAddOn={leftAddOn}
                    rightAddOn={rightAddOn}
                    {...rest}
                    type="number"
                    id={name}
                    {...field}
                    // disable input change on scroll
                    onWheel={(e: ChangeEvent<HTMLInputElement>) => e.currentTarget.blur()}
                    {...componentProps}
                />
                {showMsg && <FormFeedback className="w-100">{error}</FormFeedback>}
            </div>
        );
    }

    return (
        <div className={classNames('flex-grow-1', containerClassName)}>
            <InputGroup className={classNames('flex-wrap', className)}>
                <Input
                    {...rest}
                    type="number"
                    invalid={invalid}
                    id={name}
                    {...field}
                    value={field.value ?? ''}
                    className={classNames(inputClassName, { 'border-right-0': !!label })}
                    // disable input change on scroll
                    onWheel={(e) => e.currentTarget.blur()}
                />
                {!!label && (
                    <InputGroupText className={classNames('bg-white', labelClassName)}>
                        {label}
                    </InputGroupText>
                )}
                {showMsg && <FormFeedback className="w-100">{error}</FormFeedback>}
            </InputGroup>
        </div>
    );
};

export default FormNumberInput;
