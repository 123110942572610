import { useEffect } from 'react';
import { Collapse, Row } from 'reactstrap';
import classNames from 'classnames';
import {
    SEARCH_TERM_STRATEGY_LABELS,
    TARGET_AUDIENCE_LABELS,
} from 'platform/campaign/campaign/campaign.types';
import useToggle from 'platform/common/hooks/useToggle';
import { CustomerJourneyReportCM360 } from 'platform/dashboard/dashboard.types';
import JourneyRowBlockDivider from '../../JourneyRowBlockDivider';
import { JOURNEY_EVENT_TYPE_TEXT } from '../../customerJourney.constants';
import JourneyRowBlock from './JourneyRowBlock';
import JourneyRowChannelVendorBlock from './JourneyRowChannelVendorBlock';
import JourneyRowClassification from './JourneyRowClassification';
import JourneyRowMainBlock from './JourneyRowMainBlock';

interface Props {
    row: CustomerJourneyReportCM360;
    last?: boolean;
    defaultExpanded?: boolean;
    isExpanded?: boolean;
}

const MediaJourneyRow = ({ row, last, defaultExpanded = true, isExpanded }: Props) => {
    const [expanded, toggleExpanded] = useToggle(defaultExpanded);

    useEffect(() => {
        if (isExpanded !== undefined) {
            toggleExpanded(isExpanded);
        }
    }, [isExpanded]);

    return (
        <>
            <Row className={classNames('align-items-center px-3 pt-3', !expanded && 'pb-3')}>
                <JourneyRowMainBlock
                    text={JOURNEY_EVENT_TYPE_TEXT[row.journey_event_type]}
                    subtitle={row.journey_event_date_time}
                    expanded={expanded}
                    eventType={row.journey_event_type}
                    toggleExpanded={toggleExpanded}
                />
                <JourneyRowBlockDivider />
                <JourneyRowBlock label="Classification" value={<JourneyRowChannelVendorBlock row={row} />} />
                <JourneyRowBlockDivider />
                <JourneyRowBlock
                    label="Strategy"
                    value={
                        row.search_term_strategy && row.search_term_strategy !== 'ALL'
                            ? SEARCH_TERM_STRATEGY_LABELS[row.search_term_strategy]
                            : undefined
                    }
                />
                <JourneyRowBlockDivider />
                <JourneyRowBlock label="Virtual Targeting" value={row.unit_marker_targeting} />
            </Row>
            <Collapse isOpen={expanded}>
                <div className={classNames(!last && 'px-3 pb-3')}>
                    <div className={classNames('d-flex p-2 bg-light mt-3 font-lg', last && 'rounded-bottom')}>
                        {row.campaign_product_label && (
                            <JourneyRowClassification
                                className="me-4"
                                iconClassName={'fal fa-bullhorn'}
                                label="Product"
                                value={row.campaign_product_label}
                            />
                        )}
                        {row.campaign_flight_label && (
                            <JourneyRowClassification
                                className="me-4"
                                iconClassName={'fal fa-plane-circle-check'}
                                label="Flight"
                                value={row.campaign_flight_label}
                            />
                        )}
                        {row.target_audience && (
                            <JourneyRowClassification
                                iconClassName={'fal fa-user-group'}
                                label={'Audience'}
                                value={TARGET_AUDIENCE_LABELS[row.target_audience]}
                            />
                        )}
                    </div>
                </div>
            </Collapse>
        </>
    );
};

export default MediaJourneyRow;
