import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import { push } from 'redux-first-history';
import { Segment } from 'platform/advertiserTag/advertiserTag.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { fetchCategoryTrees, flattenCategories } from 'platform/category/category.service';
import { Category } from 'platform/category/category.types';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import SegmentAnalysis from 'platform/segments/components/SegmentAnalysis/SegmentAnalysis';
import SegmentNavigation from 'platform/segments/components/SegmentNavigation/SegmentNavigation';
import SegmentOverview from 'platform/segments/components/SegmentOverview/SegmentOverview';
import SegmentSimilarity from 'platform/segments/components/SegmentSimilarity/SegmentSimilarity';
import { SEGMENTS } from 'platform/segments/segments.navigation';
import { getSegment } from 'platform/segments/services/segments.service';

const SegmentDetails = () => {
    const dispatch = useDispatch();
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const params = useParams<{ segmentId: string }>();
    const segmentId = Number(params.segmentId);
    const isDemoMode = useSelector(authSelectors.isDemoModeEnabled);
    const [
        {
            data: [segment, categories],
        },
    ] = usePromise<[Segment | undefined, Category[]]>(
        [undefined, []],
        () =>
            Promise.all([
                getSegment(segmentId, isDemoMode),
                fetchCategoryTrees({ rootCode: ['SS', 'SSO'] }).then(flattenCategories),
            ]),
        [segmentId]
    );

    useEffect(() => {
        if (advertiserId && segment && segment.advertiserId !== advertiserId) {
            dispatch(push(SEGMENTS.path!));
        }
    }, [advertiserId, segment]);

    return (
        <div>
            <SegmentNavigation segmentId={segmentId} segmentName={segment?.name} isDemoMode={isDemoMode} />
            <BodyContainer helpKey="segment_details">
                <Routes>
                    <Route
                        path="overview"
                        element={<SegmentOverview segmentId={segmentId} categories={categories} />}
                    />
                    <Route
                        path="analysis"
                        element={<SegmentAnalysis segment={segment} categories={categories} />}
                    />
                    {!isDemoMode && (
                        <Route
                            path="similarity"
                            element={<SegmentSimilarity segmentId={segmentId} categories={categories} />}
                        />
                    )}
                </Routes>
            </BodyContainer>
        </div>
    );
};

export default SegmentDetails;
