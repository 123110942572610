import { ChangeEventHandler } from 'react';
import { Input, Label } from 'reactstrap';
import classNames from 'classnames';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';

interface Props {
    value: boolean;
    name?: string;
    className?: string;
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Switch = ({ value, name, className, disabled = false, onChange }: Props) => (
    <Tooltip tooltipVisible={disabled} renderTooltip={() => 'Read-Only'}>
        <Label className={classNames('Switch', className, { checked: value, disabled })}>
            <Input
                name={name}
                type="switch"
                className="Switch-input"
                checked={value ?? false}
                disabled={disabled}
                onChange={onChange}
            />
            <span className="Switch-label" />
            <span className="Switch-handle" />
        </Label>
    </Tooltip>
);

export default Switch;
