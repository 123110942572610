import { Button } from 'reactstrap';
import { Formik } from 'formik';
import { PasswordChange, updateCurrentUserPassword } from 'platform/app/services/auth.service';
import FormRow from 'platform/common/components/FormRow/FormRow';
import SimpleCard from 'platform/common/components/SimpleCard/SimpleCard';
import SimpleCardTitle from 'platform/common/components/SimpleCard/SimpleCardTitle';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { minLength, required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

const matchPassword = (password: string, confirmPassword: string) => {
    if (!password || !confirmPassword) return undefined;
    return password !== confirmPassword ? 'passwords do not match' : undefined;
};

const initialValues: PasswordChange = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
};

const ProfileChangePasswordForm = () => {
    const handleSubmit = (data: PasswordChange) =>
        updateCurrentUserPassword(data).then(() => toastSuccess('Password updated successfully'));

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, submitForm }) => (
                <SimpleCard headerContent={<SimpleCardTitle text="Change password" />}>
                    <FormRow label="Old Password">
                        <FormInput name="oldPassword" type="password" validate={required} />
                    </FormRow>

                    <FormRow label="New Password">
                        <FormInput name="newPassword" type="password" validate={[required, minLength(8)]} />
                    </FormRow>

                    <FormRow label="Confirm New Password">
                        <FormInput
                            name="confirmNewPassword"
                            type="password"
                            validate={[
                                required,
                                (confirmPassword: string) =>
                                    matchPassword(values.newPassword, confirmPassword),
                            ]}
                        />
                    </FormRow>

                    <div className="d-flex justify-content-end">
                        <Button color="primary" onClick={submitForm}>
                            Save
                        </Button>
                    </div>
                </SimpleCard>
            )}
        </Formik>
    );
};

export default ProfileChangePasswordForm;
