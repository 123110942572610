import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { UnitIOSpends } from 'platform/units/units.type';
import axios from '../../../axios/axiosBff';
import { UnitState } from '../constants/state.constant';
import { Unit, UnitPatch, UnitSummary } from '../unit.types';

const getUnit = (id: number) =>
    axios.get<Unit>(`/campaign/api/units/${id}`).then((response) => response.data);

export const getUnits = (params: { strategyIds: number[] }) =>
    axios.post<Unit[]>('/campaign/api/units/search', params).then((response) => response.data);

export const getUnitSummaries = (params: {
    advertiserIds?: number[];
    campaignIds?: number[];
    strategyIds?: number[];
    unitIds?: number[];
    unitStates?: UnitState[];
    externalSystems?: ExternalSystem[];
}) =>
    axios
        .post<UnitSummary[]>('/campaign/api/units/search/summaries', params)
        .then((response) => response.data);

export const getUnitSummary = (id: number): Promise<UnitSummary> =>
    getUnitSummaries({ unitIds: [id] }).then((result) => {
        if (!result?.length) throw new Error(`Can't find unit by id ${id}`);
        return result[0];
    });

const changeUnitStates = (unitIds: number[], state: UnitState) =>
    axios.put('/campaign/api/units/change-states', { state, unitIds });

const bulkChangeUnits = (units: UnitPatch[]) => axios.put('/campaign/api/units/bulk-change', { units });

const storeStrategyUnits = (strategyId: number, units: UnitPatch[]) =>
    axios.patch<{}>(`/campaign/api/strategies/${strategyId}/units`, { units });

const syncUnit = (unitId: number) => axios.post(`/campaign/api/sync/units/${unitId}`);

const syncStrategyUnits = (strategyId: number) =>
    axios.post('/campaign/api/sync/units', null, { params: { strategyId } });

const syncCampaignUnits = (campaignId: number) =>
    axios.post('/campaign/api/sync/units', null, { params: { campaignId } });

const getUnitIOSpends = (id: number) =>
    axios.get<UnitIOSpends>(`/campaign/api/units/${id}/io-spends`).then((response) => response.data);

const updateUnitIOSpends = (id: number, ioSpends: UnitIOSpends) =>
    axios.put(`/campaign/api/units/${id}/io-spends`, ioSpends);

export default {
    getUnit,
    getUnitIOSpends,
    updateUnitIOSpends,
    changeUnitStates,
    bulkChangeUnits,
    storeStrategyUnits,
    syncUnit,
    syncStrategyUnits,
    syncCampaignUnits,
};
