import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import memoizeOne from 'memoize-one';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { MetricDefinition, Report, SummaryBarState } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import { useCompatibleFilters } from 'platform/analytics/hooks/useCompatibleFilters';
import { formatColumnValue } from 'platform/analytics/reportComponents/formatReport.util';
import { Delta, formatDelta } from 'platform/common/components/DeltaText/deltas.util';
import { Tooltip } from 'platform/common/components/StatisticTooltip/StatisticTooltip';
import { typeOf } from 'platform/common/dataTypes';
import { classifierSelectors, CommonClassifiers } from 'platform/common/ducks/commonClassifiers.duck';
import { useReport } from '../useReport';

interface ReportSummaryCell {
    key: string;
    value: string;
    name: string;
    delta?: Delta;
    tooltip?: Tooltip;
}

const formatColumn =
    (
        metrics: { [key: string]: MetricDefinition },
        values: { [key: string]: number },
        compareValues: { [key: string]: number } | undefined,
        commonClassifiers: CommonClassifiers
    ) =>
    (key?: string | undefined): ReportSummaryCell => {
        if (!key || !metrics[key] || !values) {
            return {
                key: key ?? '-',
                name: key ?? '-',
                value: key && values ? `${values[key]}` : '-',
            };
        }
        const metric = {
            ...metrics[key],
            name: metrics[key].name,
        };
        const type = typeOf(metric);

        return {
            key,
            name: type.formatTitle(metric.name),
            value: formatColumnValue(key, values[key], type, values, commonClassifiers),
            delta:
                compareValues &&
                formatDelta({
                    value: values[key],
                    compareValue: compareValues[key],
                    inverted: metric.invertedPerception,
                    isDerivative: metric.isDerivative,
                    type,
                }),
            tooltip: {
                key,
                name: metric.name,
                altName: metric.altName,
                description: metric.description,
            },
        };
    };

const buildStats = memoizeOne(
    ({
        report,
        rows,
        compareReport,
        commonClassifiers,
    }: {
        report?: Report;
        rows: string[][];
        compareReport?: Report;
        commonClassifiers: CommonClassifiers;
    }) => {
        if (!report || !rows.length) {
            return [];
        }

        const columns = report.header.reduce((acc, col) => ({ ...acc, [col.key]: col }), {});
        const values = report.rows[0];
        const compareValues = compareReport?.rows[0];
        return rows.map((row) => row.map(formatColumn(columns, values, compareValues, commonClassifiers)));
    }
);

const TOO_MANY_ROWS_WARNING =
    'Summary bar information might be incorrect. Component received more than a single data row and is unable to display it properly.';

const resolveWarning = (rows: string[][] = []) => (rows.length > 1 ? TOO_MANY_ROWS_WARNING : undefined);

export const useReportSummaryBar = (componentState: SummaryBarState) => {
    const { includeVatRate, modelOptIn } = useSelector(analyticsSelectors.settings);
    const template = useAnalyticsTemplate(componentState.templateId);
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);

    const isMetric = useCallback(
        (key: string) => template?.metrics.find((metric) => metric.key === key),
        [template]
    );
    const isDimension = useCallback(
        (key: string) => template?.dimensions.find((metric) => metric.key === key),
        [template]
    );

    const settings = useMemo<ReportSettings>(() => {
        const columns = componentState.rows.flat();
        return {
            metrics: columns.filter(isMetric),
            dimensions: columns.filter(isDimension),
            templateId: componentState.templateId,
            customFilters: componentState.customFilters,
            customDateRange: componentState.customDateRange,
            includeVatRate,
            modelOptIn,
        };
    }, [
        componentState.templateId,
        componentState.customFilters,
        componentState.customDateRange,
        componentState.rows,
        isMetric,
        isDimension,
        includeVatRate,
        modelOptIn,
    ]);

    const { loading, report, error, compareReport, refetchReport } = useReport({
        settings,
        type: template.type,
        supportsComparison: true,
        componentState,
    });

    const { dimensionOptions, metricOptions, compatibleMetrics, compatibleColumnsLoading } =
        useCompatibleFilters({
            templateId: template.id,
            componentId: componentState.id,
            columns: componentState.rows.flat(),
        });

    const getOptions = (key: string) => (isMetric(key) ? metricOptions : dimensionOptions);

    const warning = resolveWarning(report?.rows);

    const rows = buildStats({
        report,
        rows: componentState.rows,
        compareReport,
        commonClassifiers,
    });

    return {
        settings,
        template,
        report,
        loading,
        error,
        warning,
        rows,
        refetchReport,
        getOptions,
        compatibleMetrics,
        compareReport,
        compatibleColumnsLoading,
    };
};
