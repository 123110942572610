import classNames from 'classnames';

const AVAILABILITY_ICON_CLASSES: { [key: string]: string } = {
    PRIVATE: 'fa fa-lock',
    CUSTOM: 'fa fa-unlock',
    PUBLIC: 'fa fa-globe',
};

interface Props {
    availability: string;
    className?: string;
}

const SegmentAvailabilityIndicator = ({ availability, className }: Props) => {
    if (AVAILABILITY_ICON_CLASSES[availability]) {
        return <span className={classNames(AVAILABILITY_ICON_CLASSES[availability], className)} />;
    }
    return null;
};

export default SegmentAvailabilityIndicator;
