import { ComponentType, ReactNode, useState } from 'react';
import { OptionProps } from 'react-select';
import { Button, Input } from 'reactstrap';
import { TableCell } from 'platform/common/common.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineEditInput from 'platform/common/components/InlineEditInput/InlineEditInput';
import { useModal } from 'platform/common/components/Modal/Modal';
import OptionSelectModal from 'platform/common/components/OptionSelectModal/OptionSelectModal';
import PopoverButton from 'platform/common/components/PopoverButton/PopoverButton';
import { OptionType } from 'platform/common/components/Select/select.types';
import SelectTreeOption from 'platform/common/components/SelectTree/SelectTreeOption';
import StatisticTooltip from 'platform/common/components/StatisticTooltip/StatisticTooltip';
import TableFooterRow from 'platform/common/components/TableFooterRow/TableFooterRow';

interface Column {
    id: string;
    label: string;
    description?: string;
    altName?: string;
}

interface Props {
    columns: Column[];
    columnOptions: Column[];
    data: Object[];
    firstColumn: TableColumn;
    cellPlaceholder: string;
    cellType: 'text' | 'number';
    columnsSelectHeader: string;
    className: string;
    tableWrapperClassName: string;
    onColumnsChange: ({ columnIds }: { columnIds: string[] }) => void;
    onColumnValueChange: ({ columnId, value }: { columnId: string; value: string | number }) => void;
    onCellValueChange: ({
        columnId,
        value,
        row,
    }: {
        columnId: string;
        value: string | number;
        row: TableCell<any>;
    }) => void;
    onRowRemove: ({ row }: { row: TableCell<any> }) => void;
    getRowValue: ({
        row,
        columnId,
    }: {
        row: TableCell<any>;
        columnId: string;
    }) => string | number | undefined;
    getNewRowComponent: ({ columnIds }: { columnIds: string[] }) => ReactNode;
    getTrProps?: (instance: any, row: any) => any;
    getTrComponent?: () => ComponentType;
}

const DynamicTable = ({
    columns,
    columnOptions,
    data,
    firstColumn,
    cellPlaceholder,
    cellType,
    columnsSelectHeader,
    className,
    tableWrapperClassName,
    onColumnsChange,
    onColumnValueChange,
    onCellValueChange,
    onRowRemove,
    getNewRowComponent,
    getRowValue,
    getTrProps,
    getTrComponent,
}: Props) => {
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const { showModal } = useModal();

    const tableColumns = [
        {
            ...firstColumn,
            Footer: 'FooterPlaceholder',
        },
        ...selectedColumns.map((column) => ({
            id: column.id,
            accessor: () => column.label,
            Header: () => (
                <div id={column.id} className="d-flex align-items-center">
                    <div>{column.label}</div>
                    <PopoverButton
                        buttonClassName="p-0 bg-transparent"
                        icon={<span data-icon="t" className="d-flex icon" />}
                    >
                        {() => (
                            <Input
                                type={cellType}
                                onBlur={(event) => {
                                    onColumnValueChange({
                                        columnId: column.id,
                                        value: event.target.value,
                                    });
                                }}
                                placeholder={cellPlaceholder}
                            />
                        )}
                    </PopoverButton>
                    <StatisticTooltip
                        target={column.id}
                        tooltip={{
                            key: column.id,
                            name: column.label,
                            altName: column.altName,
                            description: column.description,
                        }}
                    />
                </div>
            ),
            autoWidth: true,
            sortable: false,
            autoWidthAdditionalWidth: 10,
            Cell: (row: TableCell<any>) => (
                <InlineEditInput
                    inputType={cellType}
                    value={getRowValue({ row, columnId: column.id })}
                    placeholder={cellPlaceholder}
                    onChange={(value) => {
                        onCellValueChange({ row, columnId: column.id, value });
                    }}
                />
            ),
        })),
        {
            Header: '',
            width: 30,
            sortable: false,
            Cell: (row: TableCell<any>) =>
                row.original.classification ? (
                    <button
                        onClick={() => onRowRemove({ row })}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    />
                ) : null,
        },
    ];

    const toolbar = (
        <Button
            onClick={() =>
                showModal((toggle) => (
                    <OptionSelectModal
                        options={columnOptions}
                        header={columnsSelectHeader}
                        toggle={toggle}
                        values={selectedColumns}
                        onApply={(newColumns: Column[]) => {
                            setSelectedColumns(newColumns);
                            onColumnsChange({
                                columnIds: newColumns.map((column) => column.id),
                            });
                        }}
                        getOptionComponent={({ children, ...props }: OptionProps<OptionType, true>) => (
                            <div id={props.data.id}>
                                <SelectTreeOption {...props} />
                                <StatisticTooltip
                                    target={props.data.id}
                                    tooltip={{
                                        key: props.data.id,
                                        name: props.data.label,
                                        altName: props.data.altName,
                                        description: props.data.description,
                                    }}
                                />
                            </div>
                        )}
                    />
                ))
            }
        >
            <span>
                <i className="fa fa-columns pe-1" />
                Metrics
            </span>
        </Button>
    );

    return (
        <FormattedTable
            className={className}
            stickyHeader={false}
            resizable
            topRightToolbar={toolbar}
            columns={tableColumns}
            data={data}
            columnsAlignedFromIndex={0}
            showPagination={false}
            getTableProps={() => ({ className: tableWrapperClassName })}
            getTrProps={getTrProps}
            getTdProps={() => ({})}
            NoDataComponent={() => null}
            TrComponent={getTrComponent && getTrComponent()}
            TfootComponent={() => (
                <TableFooterRow>
                    {getNewRowComponent({ columnIds: selectedColumns.map((column) => column.id) })}
                </TableFooterRow>
            )}
        />
    );
};

export default DynamicTable;
