import { CSSProperties } from 'react';
import { FormFeedback, Input, InputProps } from 'reactstrap';
import { useField } from 'formik';
import InputWithAddOn, { AddOn } from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { validator, Validator } from 'platform/common/utils/validators.util';

interface Props {
    name: string;
    leftAddOn?: AddOn;
    rightAddOn?: AddOn;
    validate?: Validator | Validator[];
    isValidationMessageVisible?: boolean;
    inputClassName?: string;
    style?: CSSProperties;
    inputStyle?: CSSProperties;
    hideInput?: boolean;
}

const FormInput = ({
    leftAddOn,
    rightAddOn,
    className,
    name,
    validate,
    isValidationMessageVisible = true,
    inputClassName,
    style,
    inputStyle,
    ...rest
}: Props & InputProps) => {
    const [field, meta] = useField({ name, validate: validate && validator(validate) });
    const invalid = Boolean(meta.touched && meta.error);
    const hasAddOn = !!leftAddOn || !!rightAddOn;
    const componentProps = {
        className: inputClassName,
        style: inputStyle,
        invalid,
        ...field,
        ...rest,
        checked: rest.type === 'checkbox' ? !!field.value : undefined,
    };

    const message =
        invalid && isValidationMessageVisible ? <FormFeedback>{meta.error}</FormFeedback> : undefined;

    if (hasAddOn) {
        return (
            <div className={className || 'flex-grow-1'} style={style}>
                <InputWithAddOn leftAddOn={leftAddOn} rightAddOn={rightAddOn} {...componentProps} />
                {message}
            </div>
        );
    }
    return (
        <div className={className || 'flex-grow-1'} style={style}>
            <Input {...componentProps} />
            {message}
        </div>
    );
};

export default FormInput;
