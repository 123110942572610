import { MouseEvent, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { intersection } from 'lodash-es';
import { OptionType } from './CheckboxTree.type';
import { flattenNodes, isAnyChildNodeSelected } from './CheckboxTree.utils';

type Props = {
    option: OptionType;
    depth: number;
    valueKey: string;
    labelKey: string;
    nodesKey: string;
    value: number[];
    optionDisplay?: (option: OptionType) => ReactNode;
    onChange?: (newValue: number[]) => void;
    onChildLoad?: (option: OptionType) => Promise<any>;
    updateNode?: (node: OptionType) => Object;
};

const CheckboxTreeOption = ({
    option,
    depth,
    valueKey,
    labelKey,
    nodesKey,
    value,
    optionDisplay,
    onChange,
    onChildLoad,
    updateNode,
}: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const optionValue = option[valueKey];
    const isSelected = value.includes(optionValue);
    const nodes: OptionType[] = option[nodesKey] || [];

    const onNodeClick = () => {
        if (!onChange) {
            return;
        }
        if (!nodes.length) {
            onChange(isSelected ? value.filter((v) => v !== optionValue) : [...value, optionValue]);
            return;
        }

        const flattenedOptions = flattenNodes([option], nodesKey);
        const flattenedValues = flattenedOptions.map((o) => o[valueKey]);

        const valueWithoutSubtree = value.filter((v) => !flattenedValues.includes(v));

        const isPartOfTreeSelected = intersection(flattenedValues, value).length > 0;

        if (isPartOfTreeSelected) {
            onChange(valueWithoutSubtree);
        } else {
            onChange([...valueWithoutSubtree, ...flattenedValues]);
        }
    };

    const onExpandClick = async (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (onChildLoad && updateNode && !isExpanded && !option[nodesKey].length) {
            const children = await onChildLoad(option);
            updateNode({ ...option, [nodesKey]: children });
        }
        setIsExpanded((v) => !v);
    };

    return (
        <div>
            <div
                role="button"
                tabIndex={0}
                style={{ paddingLeft: `${depth * 1.25}rem` }}
                className="CheckboxTreeOption py-2 pe-2 outline-0"
                onClick={onNodeClick}
            >
                {nodes.length > 0 || onChildLoad ? (
                    <>
                        <div onClick={onExpandClick} role="button" tabIndex={0} className="px-2 outline-0">
                            <span
                                className={classNames('CheckboxTreeOption-arrow', {
                                    'CheckboxTreeOption-arrow--expanded': isExpanded,
                                })}
                            />
                        </div>
                        {!!nodes.length && (
                            <input
                                type="checkbox"
                                readOnly
                                className="me-2"
                                ref={(ref) => {
                                    if (ref) {
                                        // eslint-disable-next-line no-param-reassign
                                        ref.indeterminate = isAnyChildNodeSelected(
                                            option,
                                            value,
                                            valueKey,
                                            nodesKey
                                        );
                                    }
                                }}
                                checked={isSelected}
                            />
                        )}
                        {optionDisplay ? optionDisplay(option) : option[labelKey]}
                    </>
                ) : (
                    <>
                        <div style={{ height: '24px', minWidth: '24px' }} />
                        <input type="checkbox" readOnly className="me-2" checked={isSelected} />
                        {optionDisplay ? optionDisplay(option) : option[labelKey]}
                    </>
                )}
            </div>
            {isExpanded && (
                <div>
                    {nodes.map((node) => (
                        <CheckboxTreeOption
                            key={node.id}
                            option={node}
                            depth={depth + 1}
                            value={value}
                            labelKey={labelKey}
                            valueKey={valueKey}
                            nodesKey={nodesKey}
                            optionDisplay={optionDisplay}
                            onChange={onChange}
                            updateNode={updateNode}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default CheckboxTreeOption;
