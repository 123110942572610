import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ChartData, ChartEvent, ChartOptions, LegendItem, TooltipItem } from 'chart.js';
import 'chartjs-adapter-moment';
import { flow, groupBy, isNil, mapValues, orderBy, uniq } from 'lodash-es';
import moment from 'moment';
import { ColumnType, PeriodDimension, Report, ReportHeaderColumn } from 'platform/analytics/analytics.types';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import LinePure from 'platform/common/components/LinePure/LinePure';
import { typeOf } from 'platform/common/dataTypes';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { showLineDataLabels } from 'platform/common/utils/chart.utils';
import { CHART_COLORS } from 'platform/common/utils/color.util';
import { useChartColor } from '../../ChartColorProvider';

const PERIOD_DIMENSIONS_TO_IGNORE = ['week', 'month'];

const generateChartOptions = (
    metrics: ReportHeaderColumn[],
    hasMultipleDatasets: boolean,
    stacked?: boolean
): ChartOptions<'line'> => {
    const yScales: { [key: string]: any } = {};
    metrics.forEach((metric, index) => {
        yScales[`y${index === 0 ? '' : index}`] = {
            type: 'linear',
            display: true,
            position: index === 0 ? 'left' : 'right',
            beginAtZero: true,
            min: 0,
            suggestedMax: 1,
            stacked,
            ticks: {
                maxTicksLimit: 6,
                stepSize: 1,
                autoSkip: true,
                autoSkipPadding: 10,
                callback: (label: string) =>
                    typeOf(metric).format(label, { useMetricPrefix: true, showFraction: false }),
            },
        };
    });

    return {
        animation: false,
        hover: { intersect: false },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: hasMultipleDatasets,
                position: 'bottom',
            },
            tooltip: {
                cornerRadius: 3,
                caretSize: 6,
                intersect: hasMultipleDatasets,
                mode: hasMultipleDatasets ? 'point' : 'index',
                padding: 10,
                callbacks: {
                    label: (tooltipItem: TooltipItem<'line'>) =>
                        isNil(tooltipItem.datasetIndex)
                            ? String(tooltipItem.raw)
                            : typeOf(
                                  metrics.length <= 1 ? metrics[0] : metrics[tooltipItem.datasetIndex]
                              ).format(tooltipItem.raw),
                },
            },
            datalabels: {
                align(context) {
                    if (context.dataIndex === 0) {
                        return 315;
                    }
                    if (context.dataIndex === context.dataset.data.length - 1) {
                        return 225;
                    }

                    return 'top';
                },
                formatter: (value, context: any) =>
                    isNil(context.datasetIndex)
                        ? String(value)
                        : typeOf(metrics.length <= 1 ? metrics[0] : metrics[context.datasetIndex]).format(
                              value
                          ),
            },
        },
        layout: {
            padding: {
                left: 16,
                right: 24,
                top: 24,
                bottom: 16,
            },
        },
        elements: {
            point: { radius: 0, hoverRadius: 4 },
        },
        scales: {
            x: {
                time: {
                    tooltipFormat: 'yyyy-MM-DD', // Format for tooltip display
                },
                ticks: {
                    autoSkip: true,
                },
            },
            ...yScales,
        },
    };
};

const getReportRowsWithFilledGaps = (report: Report, periodDimension: PeriodDimension) => {
    if (PERIOD_DIMENSIONS_TO_IGNORE.includes(periodDimension)) return report.rows;

    const reportRowsWithFilledGaps: any[] = [];

    report.rows.forEach((row, index, rows) => {
        reportRowsWithFilledGaps.push(row);

        const date = moment(row[periodDimension]);
        const tomorrow = date.add(1, 'day');
        const nextDateInArr = moment(rows[index + 1]?.[periodDimension]);
        const isGap = rows[index + 1] && !nextDateInArr.isSame(tomorrow);

        if (isGap) {
            const rowKeys = Object.keys(row).filter((key) => key !== periodDimension);
            const rowWithFilledGaps = rowKeys.reduce((acc, key) => ({ ...acc, [key]: 0 }), {
                [periodDimension]: tomorrow.format('YYYY-MM-DD'),
            } as any);

            reportRowsWithFilledGaps.push(rowWithFilledGaps);
        }
    });

    return reportRowsWithFilledGaps;
};

interface Props {
    dimension?: string;
    metricCells: ReportHeaderColumn[];
    periodDimension: PeriodDimension;
    sortBy: ColumnType;
    report: Report;
    height: number;
    stacked?: boolean;
    showValues?: boolean;
    entriesCount: number;
}

const getSortedRows = (
    reportRowsWithFilledGaps: any[],
    dimension: string,
    periodDimension: PeriodDimension,
    metric: string,
    sortBy: ColumnType,
    entriesCount: number
) =>
    flow(
        (rows: any[]) => rows.filter((row) => row[dimension]),
        (rows) => groupBy(rows, (row) => row[dimension]),
        (rowsByDimension) =>
            mapValues(rowsByDimension, (series, key) => ({
                series: orderBy(series, periodDimension, 'asc'),
                dimension: key,
                total: series.reduce((acc, val) => acc + val[metric], 0),
            })),
        (rowsByDimension) =>
            orderBy(
                rowsByDimension,
                (row) => (sortBy === 'METRIC' ? row.total : row.dimension.toLowerCase()),
                sortBy === 'METRIC' ? 'desc' : 'asc'
            ),
        (rows) => rows.slice(0, entriesCount)
    )(reportRowsWithFilledGaps);

const getChartData = ({
    dimension,
    metricCells,
    periodDimension,
    sortBy,
    report,
    stacked,
    getChartColors,
    entriesCount,
}: Props & {
    getChartColors: (value: (string | number)[], dimensions: string[]) => string[];
}): ChartData<'line'> => {
    const reportRowsWithFilledGaps = getReportRowsWithFilledGaps(report, periodDimension);

    if (!dimension || metricCells.length === 2) {
        return {
            labels: reportRowsWithFilledGaps.map((row) => row[periodDimension]),
            datasets: metricCells.map((cell, index) => {
                const color = dimension ? getChartColors([cell.name], [dimension])[0] : CHART_COLORS[index];

                return {
                    data: reportRowsWithFilledGaps.map((row) => {
                        const value = row[cell.key];
                        return isNil(value) ? 0 : value;
                    }),
                    label: cell.name,
                    fill: false,
                    borderColor: color,
                    borderWidth: 2,
                    lineTension: 0,
                    pointHoverBackgroundColor: color,
                    yAxisID: `y${index === 0 ? '' : index}`,
                };
            }),
        };
    }

    const metric = metricCells[0].key;

    const sortedRows = getSortedRows(
        reportRowsWithFilledGaps,
        dimension,
        periodDimension,
        metric,
        sortBy,
        entriesCount
    );

    const labels = uniq(sortedRows.flatMap((row) => row.series.map((s) => s[periodDimension]))).sort();

    return {
        labels,
        datasets: sortedRows.map((item) => {
            const color = getChartColors([item.dimension], [dimension])[0];

            return {
                data: labels.map((label) => {
                    const labelData = item.series.find((s) => s[periodDimension] === label);
                    return labelData ? labelData[metric] : null;
                }),
                label: item.dimension,
                backgroundColor: color,
                fill: !!stacked,
                borderColor: color,
                borderWidth: 2,
                lineTension: 0,
                spanGaps: true,
                pointHoverBackgroundColor: color,
            };
        }),
    };
};

const getLabels = ({ dimension, metricCells, periodDimension, report, sortBy, entriesCount }: Props) => {
    const reportRowsWithFilledGaps = getReportRowsWithFilledGaps(report, periodDimension);

    if (!dimension || metricCells.length === 2) {
        return reportRowsWithFilledGaps.map((row) => row[periodDimension]);
    }

    const sortedRows = getSortedRows(
        reportRowsWithFilledGaps,
        dimension,
        periodDimension,
        metricCells[0].key,
        sortBy,
        entriesCount
    );

    return sortedRows.map((row) => row.dimension);
};

const LineChart = (props: Props) => {
    const { metricCells, height, entriesCount } = props;
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);
    const { analyticsReportId } = useSelector(analyticsSelectors.settings);

    const { getChartColors } = useChartColor(analyticsReportId!, getLabels({ ...props, entriesCount }), [
        props.dimension ?? '',
    ]);

    const [data, setChartData] = useState(getChartData({ ...props, entriesCount, getChartColors }));

    useEffect(() => {
        setChartData(getChartData({ ...props, entriesCount, getChartColors }));
    }, [props]);
    const [chartOptions, setChartOptions] = useState(
        generateChartOptions(metricCells, data.datasets!.length > 1, props.stacked)
    );
    const handleLegendClick = (e: ChartEvent, legendItem: LegendItem) => {
        const dataSet: any = data.datasets?.[legendItem.datasetIndex!];

        if (dataSet.yAxisID) {
            setChartOptions((state) => ({
                ...state,
                scales: {
                    ...state.scales,
                    [dataSet.yAxisID]: {
                        ...state.scales?.[dataSet.yAxisID],
                        display: !state.scales?.[dataSet.yAxisID]?.display,
                    },
                },
            }));
        }

        setChartData((state) => ({
            ...state,
            datasets: state.datasets?.map((set, index) =>
                index === legendItem.datasetIndex
                    ? {
                          ...set,
                          hidden: !set.hidden,
                      }
                    : set
            ),
        }));
    };

    const options: ChartOptions<'line'> = {
        ...chartOptions,
        plugins: {
            ...chartOptions.plugins,
            datalabels: {
                ...chartOptions.plugins?.datalabels,
                display: showLineDataLabels(props.showValues, periods),
            },
            legend: {
                ...chartOptions?.plugins?.legend,
                onClick(e: ChartEvent, legendItem: LegendItem) {
                    handleLegendClick(e, legendItem);
                },
            },
        },
    };

    return <LinePure data={data} options={options} height={height} />;
};

export default LineChart;
