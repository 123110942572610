import { ChangeEvent, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { CreativePreset } from 'platform/campaign/strategy/strategy.types';
import { Unit } from 'platform/campaign/unit/unit.types';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import { entitiesToOptions } from 'platform/common/utils/option.util';

interface Props {
    toggle: () => void;
    options: CreativePreset[];
    data: Unit[];
    onUpdate: (data: Unit[]) => void;
}

const BulkCampaignStrategyUnitModal = ({ toggle, options, data, onUpdate }: Props) => {
    const [creativeValue, setCreativeValue] = useState();
    const [targetingValue, setTargetingValue] = useState('');

    const onApply = async () => {
        onUpdate(
            data.map((item: Unit & { key: string }) => ({
                ...item,
                creativePresetId: creativeValue || item?.creativePresetId,
                targetingName: targetingValue || item?.targetingName,
            }))
        );
        toggle();
    };

    const getCreativePresetName = (id?: number) =>
        (id && options?.find((item) => item.id === id)?.name) || '';

    const columns: TableColumn<Unit>[] = [
        {
            Header: 'Id',
            width: 100,
            accessor: 'id',
        },
        {
            Header: 'Status',
            accessor: 'state',
            width: 100,
            Cell: ({ original: { state } }) => <StatusBadge status={state} />,
        },
        {
            Header: 'Creative preset',
            minWidth: 250,
            Cell: ({ original: { creativePresetId } }) =>
                getCreativePresetName(creativeValue || creativePresetId),
        },
        {
            Header: 'Virtual targeting',
            Cell: ({ original: { targetingName } }) => targetingValue || targetingName,
            minWidth: 200,
        },
    ];

    return (
        <Modal isOpen toggle={toggle} style={{ maxWidth: '1000px' }}>
            <ModalHeader onClose={toggle}>
                <span className="ModalHeader-title">Edit strategy units</span>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                <div className="d-flex flex-row align-items-center flex-wrap mt-1 mb-2 gap-2">
                    <div>
                        <SelectWithAddon
                            name={'Creative preset'}
                            options={entitiesToOptions(options)}
                            value={creativeValue}
                            onChange={setCreativeValue}
                            placeholder="Select"
                        />
                    </div>
                    <div>
                        <InputWithAddOn
                            leftAddOn={{ title: 'Virtual targeting' }}
                            value={targetingValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setTargetingValue(e.target.value)}
                        />
                    </div>
                </div>
                <FormattedTable data={data} columns={columns} stickyHeader={false} defaultPageSize={20} />
            </ModalBody>
            <ModalFooter>
                <Button className="ms-4 me-2" color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <AnimatedButton disabled={!creativeValue && !targetingValue} onClick={onApply}>
                    Update
                </AnimatedButton>
            </ModalFooter>
        </Modal>
    );
};

export default BulkCampaignStrategyUnitModal;
