import { sortBy, trim } from 'lodash-es';
import { Advertiser } from 'platform/advertisers/advertiser.types';
import { Campaign } from 'platform/campaign/campaign/campaign.types';
import {
    labelClassificationToApi,
    labelClassificationToForm,
} from 'platform/campaign/campaign/campaignClassification.mapper';
import { Strategy, StrategyModel } from '../strategy.types';

export const toFormModel = (
    advertiser: Advertiser,
    campaign: Campaign,
    strategy: Strategy
): StrategyModel => ({
    id: strategy.id,
    advertiser,
    campaign,
    name: strategy.name,
    state: strategy.state,
    type: strategy.type,
    creatives: {
        presets: sortBy(strategy.creatives.presets, 'id') ?? [],
    },
    ...labelClassificationToForm(strategy),
});

export const toApiModel = (data: StrategyModel): Partial<Strategy> => ({
    name: trim(data.name),
    campaignId: data.campaign.id,
    ...labelClassificationToApi(data),
});
