import {
    MARKETING_GOAL_LABELS,
    SEARCH_TERM_STRATEGY_LABELS,
    TARGET_AUDIENCE_LABELS,
} from 'platform/campaign/campaign/campaign.types';
import { CampaignClassifications } from 'platform/campaign/campaign/campaignClassification.types';
import { SelectItem } from 'platform/common/common.types';

const classificationGroup = (
    groupKey: keyof CampaignClassifications,
    groupLabel: string,
    optionLabels: { [key: string]: string }
): SelectItem => ({
    value: groupKey,
    label: groupLabel,
    nodes: Object.entries(optionLabels).map(([key, label]) => ({
        value: `${groupKey}#${key}`,
        label,
    })),
});

export const CAMPAIGN_CLASSIFICATION_STATIC_GROUPS: SelectItem[] = [
    classificationGroup('targetAudience', 'Target Audience', TARGET_AUDIENCE_LABELS),
    classificationGroup('marketingGoal', 'Marketing Goal', MARKETING_GOAL_LABELS),
    classificationGroup('searchTermStrategy', 'Search Term Strategy', SEARCH_TERM_STRATEGY_LABELS),
];

export const CAMPAIGN_CLASSIFICATION_ORDER: (keyof CampaignClassifications)[] = [
    'channel',
    'targetAudience',
    'marketingGoal',
    'searchTermStrategy',
    'productCategory',
    'productLabel',
    'productSubLabel',
    'flightLabel',
    'vendorSystem',
];
