import { KeyboardEvent, useState } from 'react';
import { ActionMeta, InputActionMeta } from 'react-select';
import classNames from 'classnames';
import { isObject, uniq } from 'lodash-es';
import { isDefined } from 'platform/common/common.types';
import { CreatableSelectProps } from 'platform/common/components/Select/CreatableSelect';
import MenuWithSelectAll from 'platform/common/components/SelectTree/MenuWithSelectAll';
import { toastSuccess } from 'platform/common/utils/toast.util';
import ReactCreatableSelectWrapper from '../Select/ReactCreatableSelectWrapper';
import { OptionType } from '../Select/select.types';
import { getSelectStyles } from '../Select/select.utils';
import { SelectTreeProps } from './SelectTree';
import { filterSelectOptions, flattenNodes } from './SelectTree.utils';
import SelectTreeOption from './SelectTreeOption';
import SelectTreeValue from './SelectTreeValue';
import './SelectTree.scss';

const isClear = (key: string) => key === 'Escape';
const isSelectAll = (key: string, metaKey: boolean) => key === 'a' && metaKey;
const isCopy = (key: string, metaKey: boolean) => key === 'c' && metaKey;
const isPaste = (key: string, metaKey: boolean) => key === 'v' && metaKey;

export type CreatibleSelectTreeProps<T> = Omit<CreatableSelectProps<T>, 'filterOption'> & SelectTreeProps<T>;

const CreatableSelectTree = <T extends OptionType>({
    className,
    selectStyle,
    backspaceRemovesValue,
    controlShouldRenderValue,
    placeholder,
    autoFocus,
    components,
    childrenKey = 'nodes',
    optionDisplay,
    getOptionParentLabel = (option: T) => option.label,
    sortValue,
    filterOption,
    options,
    value,
    returnOnlyValues = true,
    closeMenuOnSelect = true,
    isClearable = true,
    isSearchable = true,
    isBreadcrumbsShown = true,
    isRootNodeSelectable = true,
    isLoading = false,
    isDisabled = false,
    invalid = false,
    isMulti = false,
    hideSelectedOptions = false,
    canSelectAll = true,
    getOptionLabel = (option: T) => option.label,
    getOptionValue = (option: T) => option.value,
    onChange,
    onNewOption,
    ...rest
}: CreatibleSelectTreeProps<T>) => {
    const [inputValue, setInputValue] = useState('');
    const [highlightedValues, setHighlightedValues] = useState<string[]>([]);

    const getOptionNodes = (option: T) => option[childrenKey];

    const flattenedOptions: T[] = flattenNodes(options as T[], getOptionNodes);

    const resolveValue = () => {
        if (Array.isArray(value)) {
            return value
                .map((v) => flattenedOptions.find((option) => getOptionValue(option) === v))
                .filter(isDefined);
        }

        const found = flattenedOptions.find(
            (option) => (isObject(value) ? getOptionValue(value as T) : value) === getOptionValue(option)
        );

        return found ? [found] : [];
    };

    const resolvedValue = resolveValue();

    const filteredOptions = filterSelectOptions(
        options,
        inputValue,
        childrenKey,
        undefined,
        getOptionNodes,
        getOptionLabel,
        filterOption
    );

    const getValue = () => {
        if (resolvedValue === undefined) {
            return null;
        }

        return sortValue && Array.isArray(resolvedValue) ? sortValue(resolvedValue) : resolvedValue;
    };

    const handleChange = (valueOptions: any, action: ActionMeta<OptionType>) => {
        if (!valueOptions) {
            onChange?.(isMulti ? [] : undefined, action);
            return;
        }

        let values = valueOptions;
        if (returnOnlyValues) {
            values = Array.isArray(valueOptions)
                ? valueOptions.map(getOptionValue)
                : getOptionValue(valueOptions || {});
        }

        if (action.action === 'create-option') {
            onNewOption(valueOptions.filter((o: T) => o.__isNew__).pop());
        }

        onChange?.(values, action);
    };

    const handleInputChange = (newValue: string, { action }: InputActionMeta) => {
        if (isMulti && !['input-change', 'menu-close'].includes(action)) return inputValue;
        setInputValue(newValue);
        return newValue;
    };

    const handleKeyDown = ({ key, metaKey }: KeyboardEvent) => {
        if (!isMulti) return;

        if (isClear(key)) {
            setHighlightedValues([]);
        } else if (isSelectAll(key, metaKey)) {
            setHighlightedValues(resolvedValue.map(getOptionValue));
        } else if (isCopy(key, metaKey)) {
            navigator.clipboard.writeText(highlightedValues.join(', '));
            toastSuccess('Copied to clipboard');
        } else if (isPaste(key, metaKey)) {
            navigator.clipboard.readText().then((text) => {
                const pastedValues = text.split(', ');
                const currentValues = resolvedValue.map(getOptionValue);
                const newValues = uniq([...currentValues, ...pastedValues]);

                onChange?.(newValues);
                setInputValue('');
            });
        }
    };

    return (
        <ReactCreatableSelectWrapper
            {...rest}
            // https://github.com/JedWatson/react-select/issues/3066
            value={getValue()}
            inputValue={inputValue}
            styles={getSelectStyles({ invalid, selectStyle })}
            options={filteredOptions}
            className={classNames('SelectTree', className)}
            classNamePrefix="react-select"
            components={{
                ...{
                    Option: SelectTreeOption,
                    MultiValueLabel: SelectTreeValue,
                    Menu: MenuWithSelectAll,
                },
                ...components,
            }}
            selectAllEnabled={isMulti && canSelectAll}
            hideSelectedOptions={hideSelectedOptions}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti={isMulti}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            isRootNodeSelectable={isRootNodeSelectable}
            isBreadcrumbsShown={isBreadcrumbsShown}
            filterOption={null}
            onKeyDown={handleKeyDown}
            getOptionNodes={getOptionNodes}
            getOptionParentLabel={getOptionParentLabel}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            optionDisplay={optionDisplay}
            onChange={handleChange}
            onInputChange={handleInputChange}
        />
    );
};

export default CreatableSelectTree;
