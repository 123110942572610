import { components, ControlProps } from 'react-select';
import { OptionType } from 'platform/common/components/Select/select.types';
import './ActiveAdvertiserSelectContainer.scss';

const ActiveAdvertiserSelectContainer = <T extends OptionType>({ children, ...props }: ControlProps<T>) => (
    <div className="ActiveAdvertiserSelectContainer">
        <i className="fa fa-search text-muted ActiveAdvertiserSelectContainer-icon" />
        <components.Control {...props}>{children}</components.Control>
    </div>
);

export default ActiveAdvertiserSelectContainer;
