import { useState } from 'react';
import { isEmpty } from 'lodash-es';
import { StrategyModel } from 'platform/campaign/strategy/strategy.types';
import { Patch, UnitEditorTable } from 'platform/campaign/unit/components/UnitEditorTable/UnitEditorTable';
import unitService, { getUnits } from 'platform/campaign/unit/services/units.service';
import { Unit } from 'platform/campaign/unit/unit.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import FormContainer, { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import FieldError from 'platform/formik/FieldError/FieldError';

type Props = {
    strategy: StrategyModel;
    submitRedirectTo: string;
    onCancel: () => void;
};

const UnitEditorForm = ({ strategy, submitRedirectTo, onCancel }: Props) => {
    const [patches, setPatches] = useState<Patch[]>([]);
    const onOpen = async () => ({ units: await getUnits({ strategyIds: [strategy.id] }) });
    const onSubmit = () => unitService.storeStrategyUnits(strategy.id, patches);

    const validate = () =>
        strategy.type === 'REDIRECT' && patches.some((v) => !v.creativePresetId)
            ? { units: 'All IO units must have creatives assigned' }
            : {};

    return (
        <FormContainer
            helpKey="unit_editor_form"
            sidePanel={false}
            onOpen={onOpen}
            onSubmit={onSubmit}
            redirectTo={submitRedirectTo}
            onCancel={onCancel}
            canEdit
            isEdit
            validate={validate}
        >
            {({ formikProps: { initialValues, submitForm } }: FormProps<{ units: Unit[] }>) => (
                <CardForm
                    title="Units"
                    subtitle={`Strategy ID: ${strategy.id}`}
                    submitLabel="Save"
                    disabled={isEmpty(patches)}
                    onSubmit={submitForm}
                    onCancel={onCancel}
                >
                    <UnitEditorTable
                        strategy={strategy}
                        units={initialValues.units}
                        patches={patches}
                        onChange={setPatches}
                    />
                    <FieldError name="units" className="mt-3" />
                </CardForm>
            )}
        </FormContainer>
    );
};

export default UnitEditorForm;
