import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'reactstrap';
import { preventOverflow } from '@popperjs/core';
import { PreventOverflowModifier } from '@popperjs/core/lib/modifiers/preventOverflow';
import classNames from 'classnames';
import { getReportInlineActions } from 'platform/analytics/analytics.service';
import { ReportFilter } from 'platform/analytics/analytics.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { isDefined } from 'platform/common/common.types';
import CellInlineActionsPopoverBody from './CellInlinActionsPopoverBody';
import { ActionConnect, Context } from './InlineActions.types';
import { findRegisteredActionById } from './inlineActionConnect';
import './CellInlineActions.scss';

type Props = {
    columnId: string;
    row: { [key: string]: any };
    filters: ReportFilter[];
    refetchReport: () => void;
};

type State =
    | {
          type: 'IDLE';
      }
    | {
          type: 'LOADING';
      }
    | {
          type: 'OPEN';
          actions: ActionConnect[];
          context: Context;
      };

const CellInlineActions = ({ columnId, row, filters, refetchReport }: Props) => {
    const canAccess = useSelector(authSelectors.canAccess);
    const [state, setState] = useState<State>({ type: 'IDLE' });

    const loadInlineActions = async () => {
        setState({ type: 'LOADING' });
        const inlineActions = await getReportInlineActions(columnId, row, filters).catch(() =>
            setState({
                type: 'IDLE',
            })
        );

        if (inlineActions && inlineActions?.actions.length) {
            const availableActions = inlineActions.actions
                .map(findRegisteredActionById)
                .filter(isDefined)
                .filter(canAccess);

            if (availableActions.length) {
                setState({
                    type: 'OPEN',
                    actions: availableActions,
                    context: inlineActions.context,
                });
            }
            return;
        }
        setState({
            type: 'IDLE',
        });
    };

    const containerRef = useRef<HTMLDivElement | null>(null);

    const closePopover = useCallback(() => {
        setState((s) => (s.type === 'OPEN' ? { type: 'IDLE' } : s));
    }, []);

    return (
        <div className="CellInlineActions">
            <div
                className={classNames('CellInlineActions-button py-1 cursor-pointer', {
                    animate: state.type === 'LOADING',
                })}
                role="button"
                tabIndex={0}
                onClick={loadInlineActions}
            >
                <span data-icon="t" className="d-flex icon" />
            </div>
            <div ref={containerRef} />
            {containerRef.current && (
                <Popover
                    className="CellInlineActions-popover"
                    target={containerRef.current}
                    placement="bottom-start"
                    trigger="legacy"
                    style={{ width: 600 }}
                    isOpen={state.type === 'OPEN'}
                    flip={false}
                    hideArrow
                    modifiers={[
                        { ...preventOverflow, options: { altAxis: true } } as PreventOverflowModifier,
                    ]}
                    toggle={closePopover}
                >
                    {state.type === 'OPEN' && (
                        <CellInlineActionsPopoverBody
                            actions={state.actions}
                            context={state.context}
                            columnId={columnId}
                            row={row}
                            closePopover={closePopover}
                            onSubmitFinished={refetchReport}
                        />
                    )}
                </Popover>
            )}
        </div>
    );
};

export default CellInlineActions;
