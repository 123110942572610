import { ReactNode } from 'react';
import { NavLink as RouterNavLink, Route, Routes } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import { ADMIN_PANEL_TAB_LIST } from '../../constants/tabs.constant';

const AdminPanelPage = () => {
    const tabs = ADMIN_PANEL_TAB_LIST.map((tab) => (
        <NavItem key={tab.url}>
            <NavLink to={tab.url} tag={RouterNavLink}>
                {tab.label}
            </NavLink>
        </NavItem>
    ));

    const routes = ADMIN_PANEL_TAB_LIST.reduce<ReactNode[]>((acc, tab) => {
        if (tab.component) {
            acc.push(<Route key={tab.url} path={`${tab.url}/*`} element={<tab.component />} />);
        }
        return acc;
    }, []);

    return (
        <div>
            <HeaderContainer>
                <PageHeader title="Admin panel" />
            </HeaderContainer>
            <BodyContainer>
                <div className="custom-tabs">
                    <Nav tabs>{tabs}</Nav>
                </div>
            </BodyContainer>
            <Routes>{routes}</Routes>
        </div>
    );
};

export default AdminPanelPage;
