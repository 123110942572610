import { makeOptions } from 'platform/common/utils/option.util';

export type LogCategory =
    | 'TRACKING'
    | 'CAMPAIGN_SUMMARY'
    | 'OPTIMIZATION'
    | 'GENERAL'
    | 'BUDGET_SHIFTING'
    | 'WORKFLOW';

export enum LogDateFilterStrategy {
    NOTE_PERIOD = 'NOTE_PERIOD',
    CREATE_DATE_OR_NOTE_PERIOD = 'CREATE_DATE_OR_NOTE_PERIOD',
}

export enum FilterBy {
    NOTE_PERIOD = 'NOTE_PERIOD',
    MEDIAPLAN_OR_INSERTION = 'MEDIAPLAN_OR_INSERTION',
}

export const LOG_CATEGORY_LABELS: Record<LogCategory, string> = {
    TRACKING: 'Tracking',
    CAMPAIGN_SUMMARY: 'Campaign Summary',
    OPTIMIZATION: 'Optimization',
    GENERAL: 'General',
    BUDGET_SHIFTING: 'Budget Shifting',
    WORKFLOW: 'Workflow',
};

export const LOG_CATEGORY_OPTIONS = makeOptions(LOG_CATEGORY_LABELS);

export type LogBookAssignmentType = 'NONE' | 'MEDIAPLANS' | 'MEDIA_INSERTIONS';

export const LOG_BOOK_ASSIGNMENT_TYPES: Record<LogBookAssignmentType, string> = {
    NONE: 'None',
    MEDIAPLANS: 'Mediaplans',
    MEDIA_INSERTIONS: 'Media Insertions',
};
