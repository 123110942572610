import { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AxiosResponse } from 'axios';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { useLoading } from 'platform/common/hooks/useLoading';
import { blobToImage, saveDownloadedFile } from 'platform/common/utils/file.util';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import Spinner from '../Spinner/Spinner';
import './ImagePreviewModal.scss';

interface Props {
    title: string;
    load: () => Promise<AxiosResponse<Blob>>;
    toggle: () => void;
}

const ImagePreviewModal = ({ title, load, toggle }: Props) => {
    const imageContainerRef = useRef<HTMLDivElement | null>(null);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [downloading, whileDownloading] = useLoading();

    useEffect(() => {
        if (image) {
            imageContainerRef.current?.appendChild(image);
        } else {
            load().then((r) => setImage(blobToImage(r.data)));
        }
    }, [image]);

    return (
        <Modal centered className="ImagePreviewModal" isOpen toggle={toggle}>
            {!image && <OverlayLoader />}
            <ModalHeader onClose={toggle}>
                <div className="d-flex justify-content-between align-items-center me-2">
                    <span className="font-lg me-3">{title}</span>
                    <LabelLink
                        className="text-nowrap"
                        onClick={() => whileDownloading(() => load().then(saveDownloadedFile))}
                    >
                        {downloading ? <Spinner size="sm" /> : <i className="fa-lg far fa-download" />}
                        <span className="ms-2">Download</span>
                    </LabelLink>
                </div>
            </ModalHeader>
            <ModalBody>
                <div ref={imageContainerRef} />
            </ModalBody>
        </Modal>
    );
};

export default ImagePreviewModal;
