import axiosBff from 'platform/axios/axiosBff';
import { IdResponse } from '../../../common/common.types';
import { Strategy, StrategyCreatives, StrategyPatch } from '../strategy.types';

export const getStrategy = (id: string | number) =>
    axiosBff.get<Strategy>(`/campaign/api/strategies/${id}`).then((response) => response.data);

export const updateStrategy = (id: string | number, model: Partial<Strategy>) =>
    axiosBff.put(`/campaign/api/strategies/${id}`, model).then((response) => response.data);

export const changeStrategyState = (strategyId: number, state: string) =>
    axiosBff.put(`/campaign/api/strategies/${strategyId}/state`, JSON.stringify(state), {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const createStrategy = (strategy: { campaignId: number; name: string }) =>
    axiosBff.post<IdResponse>('/campaign/api/strategies', strategy).then((response) => response.data);

export const saveStrategyCreatives = (strategyId: number, creatives: StrategyCreatives) =>
    axiosBff
        .put(`/campaign/api/strategies/${strategyId}/creatives`, creatives)
        .then((response) => response.data);

export const deleteStrategy = (id: string | number) => axiosBff.delete<any>(`/campaign/api/strategies/${id}`);

export const bulkUpdateStrategies = (params: { strategies: StrategyPatch[] }) =>
    axiosBff.patch<void>('/campaign/api/strategies', params);
