import { Component } from 'react';
import className from 'classnames';
import codeMirrorInstance, { EditorConfiguration } from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/r/r';
import 'codemirror/mode/sas/sas';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/yaml/yaml';
import { isEqual } from 'lodash-es';

type Props = {
    className?: string;
    defaultValue: string;
    name: string;
    onChange?: Function;
    onCursorActivity?: Function;
    onFocusChange?: Function;
    onScroll?: Function;
    options?: EditorConfiguration;
    path?: string;
    value?: string;
    preserveScrollPosition: boolean;
};

type State = {
    isFocused: boolean;
};

const defaultProps = {
    defaultValue: '',
    name: '',
    preserveScrollPosition: false,
};

function normalizeLineEndings(str?: string) {
    if (!str) return str;
    return str.replace(/\r\n|\r/g, '\n');
}

export default class CodeMirrorSrc extends Component<Props, State> {
    static defaultProps = defaultProps;

    state: State = {
        isFocused: false,
    };

    codeMirror: any;
    textareaNode: any;

    componentDidMount() {
        this.codeMirror = codeMirrorInstance.fromTextArea(this.textareaNode, this.props.options);
        this.codeMirror.on('change', this.codemirrorValueChanged);
        this.codeMirror.on('cursorActivity', this.cursorActivity);
        this.codeMirror.on('focus', this.focusChanged.bind(this, true));
        this.codeMirror.on('blur', this.focusChanged.bind(this, false));
        this.codeMirror.on('scroll', this.scrollChanged);
        this.codeMirror.setValue(this.props.defaultValue || this.props.value || '');
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.codeMirror &&
            this.props.value !== undefined &&
            this.props.value !== prevProps.value &&
            normalizeLineEndings(this.codeMirror.getValue()) !== normalizeLineEndings(this.props.value)
        ) {
            if (prevProps.preserveScrollPosition) {
                const prevScrollPosition = this.codeMirror.getScrollInfo();
                this.codeMirror.setValue(this.props.value);
                this.codeMirror.scrollTo(prevScrollPosition.left, prevScrollPosition.top);
            } else {
                this.codeMirror.setValue(this.props.value);
            }
        }
        if (typeof this.props.options === 'object') {
            Object.keys(this.props.options).forEach((optionName: 'readOnly' | 'mode' | 'lineWrapping') => {
                this.setOptionIfChanged(optionName, this.props.options && this.props.options[optionName]);
            });
        }
    }

    componentWillUnmount() {
        // is there a lighter-weight way to remove the cm instance?
        if (this.codeMirror) {
            this.codeMirror.toTextArea();
        }
    }

    setOptionIfChanged = (optionName: string, newValue: any) => {
        const oldValue = this.codeMirror.getOption(optionName);
        if (!isEqual(oldValue, newValue)) {
            this.codeMirror.setOption(optionName, newValue);
        }
    };

    focusChanged = (focused: boolean) => {
        this.setState({
            isFocused: focused,
        });
        const { onFocusChange } = this.props;
        return onFocusChange && onFocusChange(focused);
    };

    cursorActivity = (cm: any) => {
        const { onCursorActivity } = this.props;
        return onCursorActivity && onCursorActivity(cm);
    };

    scrollChanged = (cm: any) => {
        const { onScroll } = this.props;
        return onScroll && onScroll(cm.getScrollInfo());
    };

    codemirrorValueChanged = (doc: any, change: any) => {
        const { onChange } = this.props;
        if (onChange && change.origin !== 'setValue') {
            onChange(doc.getValue(), change);
        }
    };
    render() {
        const editorClassName = className(
            'ReactCodeMirror',
            { 'ReactCodeMirror--focused': this.state.isFocused },
            { 'ReactCodeMirror--disabled': this.props.options?.readOnly },
            this.props.className
        );
        return (
            <div className={editorClassName}>
                <textarea
                    ref={(ref) => {
                        this.textareaNode = ref;
                    }}
                    name={this.props.name || this.props.path}
                    defaultValue={this.props.value}
                    autoComplete="off"
                />
            </div>
        );
    }
}
