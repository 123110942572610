import { ReactNode } from 'react';
import './FormHeader.scss';

interface Props {
    title: string;
    subtitle?: ReactNode;
    content?: ReactNode;
    children: ReactNode;
}

const FormHeader = ({ title, subtitle, content, children }: Props) => (
    <div className="FormHeader d-flex justify-content-end align-items-center">
        <div className="me-auto">
            <span className="FormHeader-title">{title}</span>
            {subtitle && (
                <span className="FormHeader-subtitle">
                    <br />
                    {subtitle}
                </span>
            )}
        </div>
        {content}
        <div className="d-flex mt-1">{children}</div>
    </div>
);

export default FormHeader;
