import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Product } from 'platform/campaign/advertiserManagement/ProductLabels/productLabel.types';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import ProductLabelForm, { ProductLabelFormModel } from './ProductLabelForm';
import {
    fetchProduct,
    fetchProducts,
    getProductCategories,
    renameProduct,
    storeProduct,
} from './productLabel.service';

const newProduct = (advertiserId: number): Product => ({
    advertiserId,
    name: '',
    status: 'ACTIVE',
    subLabels: [],
});

const ProductLabelFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ name: string }>();
    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const name = params.name ? decodeURIComponent(params.name) : undefined;

    const handleOpen = async (): Promise<ProductLabelFormModel> => {
        const [product, categories] = await Promise.all([
            name ? fetchProduct(advertiserId, name) : newProduct(advertiserId),
            fetchProducts(advertiserId).then(getProductCategories),
        ]);
        return { original: product, product, categories };
    };

    const handleSubmit = ({ original, product, isRenaming }: ProductLabelFormModel) =>
        isRenaming
            ? renameProduct({
                  advertiserId,
                  oldName: original.name,
                  newName: product.name,
                  subLabelChanges: product.subLabels.map((subLabel, index) => ({
                      oldName: original.subLabels[index].name,
                      newName: subLabel.name,
                  })),
              })
            : storeProduct(product);

    return (
        <FormContainer
            helpKey="product_label_form"
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={!!name}
            sidePanel
            size={SIDE_PANEL_SIZES.MEDIUM}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <ProductLabelForm {...props} />}
        </FormContainer>
    );
};

export default ProductLabelFormContainer;
