import axiosBff from 'platform/axios/axiosBff';
import { IdResponse } from 'platform/common/common.types';
import { FILE_UPLOAD_HEADERS, fileUploadData } from 'platform/common/utils/file.util';
import { WikiFile, WikiPageData, WikiPageForm, WikiSpace } from './wiki.types';

export const fetchPages = (space: WikiSpace) =>
    axiosBff.get<WikiPageData[]>(`/wiki/api/pages`, { params: { space } }).then((r) => r.data);

export const fetchPage = (id: number | string) =>
    axiosBff.get<WikiPageData>(`/wiki/api/pages/${id}`).then((r) => r.data);

export const createPage = (pageData: WikiPageForm) =>
    axiosBff.post<WikiPageData>(`/wiki/api/pages`, pageData).then((r) => r.data);

export const fetchPageContent = (id: number | string): Promise<string> =>
    axiosBff.get<string>(`/wiki/api/pages/${id}/content`).then((r) => r.data);

export const deletePage = (id: number | string) =>
    axiosBff.delete<void>(`/wiki/api/pages/${id}`).then((r) => r.data);

export const updatePageTitle = (id: number | string, title: string) =>
    axiosBff.put<WikiPageData>(`/wiki/api/pages/${id}/title`, { title }).then((r) => r.data);

export const updatePageContent = (id: number | string, html: string) =>
    axiosBff
        .put<WikiPageData>(`/wiki/api/pages/${id}/content`, html, {
            headers: { 'Content-Type': 'text/html' },
        })
        .then((r) => r.data);

export const updatePagePosition = (
    id: number | string,
    order: number | string,
    parentId?: number | string | null
) => axiosBff.put<void>(`/wiki/api/pages/${id}/position`, { order, parentId }).then((r) => r.data);

export const fetchFiles = (pageId: number | string) =>
    axiosBff.get<WikiFile[]>(`/wiki/api/files`, { params: { pageId } }).then((r) => r.data);

export const uploadFile = (pageId: number | string, name: string, content: Blob) =>
    axiosBff
        .post<IdResponse>(`/wiki/api/files`, fileUploadData(content, name), {
            params: { pageId },
            ...FILE_UPLOAD_HEADERS,
        })
        .then((r) => r.data);

export const deleteFile = (id: number | string) =>
    axiosBff.delete<void>(`/wiki/api/files/${id}`).then((r) => r.data);

export const downloadFile = (id: number | string) =>
    axiosBff.get<Blob>(`/wiki/api/files/${id}/content`, {
        responseType: 'blob',
    });
