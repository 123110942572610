import AuditLog from 'platform/audit/AuditLog';
import CampaignImportList from 'platform/campaign/campaign/components/CampaignImport/CampaignImportList';
import { CAMPAIGN_IMPORT, CAMPAIGNS, CHANGE_LOG } from './campaign.navigation';
import CampaignRoutes from './campaign/components/CampaignRoutes';

const campaignRoutes = [
    {
        ...CAMPAIGNS,
        component: CampaignRoutes,
    },
    {
        ...CAMPAIGN_IMPORT,
        component: CampaignImportList,
    },
    {
        ...CHANGE_LOG,
        component: AuditLog,
    },
];

export default campaignRoutes;
