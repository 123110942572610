import { InputGroup, InputGroupText } from 'reactstrap';
import classNames from 'classnames';
import { useMetricsWithData } from 'platform/analytics/hooks/useMetricsWithData';
import { filterOptionAdvanced } from 'platform/analytics/reportComponents/listFilter';
import SelectSortableMultiValueWithTooltip from 'platform/common/components/Select/SelectSortableMultiValueWithTooltip';
import SelectTree from 'platform/common/components/SelectTree/SelectTree';
import SelectTreeOptionWithTooltip from 'platform/common/components/SelectTree/SelectTreeOptionWithTooltip';
import { useFeature } from 'platform/common/hooks/useFeature';
import { sortByName } from 'platform/common/utils/array.util';
import { ColumnDefinitionOption } from '../analytics.types';

type ValueType<IsMulti extends boolean> = IsMulti extends true ? string[] : string;

interface Props<IsMulti extends boolean> {
    name: string;
    options: ColumnDefinitionOption[];
    value: ValueType<IsMulti> | undefined;
    className?: string;
    placeholder?: string;
    isRootNodeSelectable?: boolean;
    isLoading?: boolean;
    isMulti: IsMulti;
    disabled?: boolean;
    onChange: (value: ValueType<IsMulti>) => void;
    menuIsOpen?: boolean;
    optionIdSuffix?: number;
}

const hasChildNodes = (options: ColumnDefinitionOption[]) => options.some((option) => option.nodes?.length);

const ReportSelectTree = <IsMulti extends boolean>({
    value,
    options,
    name,
    className,
    placeholder,
    isMulti,
    disabled = false,
    isLoading = false,
    onChange,
    menuIsOpen,
    optionIdSuffix,
}: Props<IsMulti>) => {
    const isSuperuser = useFeature('SUPERUSER');
    const { showMetricsWithData, setShowOnlyMetricsWithData } = useMetricsWithData();

    return (
        <InputGroup className={classNames('flex-nowrap', className)}>
            <InputGroupText className="rounded-start">{name}</InputGroupText>
            <SelectTree<ColumnDefinitionOption>
                inputId={name}
                className="flex-grow-1"
                value={value}
                selectStyle={{
                    borderBottomLeftRadius: '0px !important',
                    borderTopLeftRadius: '0px !important',
                }}
                options={options.map((option) => ({
                    ...option,
                    nodes: sortByName(option.nodes ?? []).map((o) => ({ ...o, disabled: isLoading })),
                }))}
                components={{
                    Option: SelectTreeOptionWithTooltip,
                    MultiValue: SelectSortableMultiValueWithTooltip,
                }}
                placeholder={placeholder}
                closeMenuOnSelect={!isMulti}
                menuIsOpen={menuIsOpen}
                isBreadcrumbsShown={false}
                isLoading={isLoading}
                isMulti={isMulti}
                isDisabled={disabled}
                optionIdSuffix={optionIdSuffix}
                isRootNodeSelectable={!hasChildNodes(options)}
                filterOption={(option: ColumnDefinitionOption, input) =>
                    filterOptionAdvanced(option.key, option.name, input) &&
                    (!option.isSystem || isSuperuser) &&
                    showMetricsWithData(option.key)
                }
                onHasDataFilterChange={setShowOnlyMetricsWithData}
                getOptionParentLabel={(option: ColumnDefinitionOption) => option.name}
                getOptionLabel={(option: ColumnDefinitionOption) => option.name}
                getOptionValue={(option: ColumnDefinitionOption) => option.key}
                onChange={onChange}
            />
        </InputGroup>
    );
};

export default ReportSelectTree;
