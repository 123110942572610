import classNames from 'classnames';
import InfoIconAlert from 'platform/common/components/InfoIconAlert/InfoIconAlert';
import Popover from 'platform/common/components/Popover/Popover';
import RawHtml from 'platform/common/components/RawHtml/RawHtml';
import { ReportComponentType } from '../analytics.types';
import { NoteType } from './ReportNote';
import './ReportComponentNote.scss';

interface Props {
    note: NoteType;
    componentType: ReportComponentType;
}

const ReportComponentNote = ({ note, componentType }: Props) => {
    if (note.display === 'AlWAYS') {
        return (
            <InfoIconAlert
                className={classNames(
                    'ReportComponentNote-alert ',
                    componentType === 'SPACER' || componentType === 'NOTE'
                        ? 'border-primary rounded'
                        : 'bg-secondary mb-3 mx-3'
                )}
            >
                <RawHtml className="ReportComponentNote-alert--text">{note.text}</RawHtml>
            </InfoIconAlert>
        );
    }

    return (
        <Popover
            trigger="hover"
            placement="left"
            boundariesElement={document.querySelector('.main') || undefined}
            className="position-absolute px-1"
            popoverClassName="ReportComponentNote-popover"
            style={{ top: -8, right: -10 }}
            hideArrow={false}
            preventOverflow
            renderPopover={() => <RawHtml className="ReportComponentNote-popover--text">{note.text}</RawHtml>}
        >
            <i className="text-primary fal fa-info-circle" />
        </Popover>
    );
};

export default ReportComponentNote;
