import { flow, isString, uniq } from 'lodash-es';
import { AnalyticsCompareType, Report, ReportTableCell } from 'platform/analytics/analytics.types';
import ReportDeltaText from 'platform/analytics/reportComponents/ReportDeltaText';
import { assertIsDefined } from 'platform/common/common.assert';
import { formatDelta } from 'platform/common/components/DeltaText/deltas.util';
import { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import { typeOf } from 'platform/common/dataTypes';
import { beautifyInsightColumnName, findColumn } from '../analytics.util';

interface Props {
    pivotColumn: string | undefined;
    dimensions: string[];
    metrics: string[];
    report: Report;
    compareReport?: Report;
    compareWith?: AnalyticsCompareType;
}

interface FindMetricArgs {
    metric: string;
    dimensionValue: string;
    dimensionIndex: number;
    pivotValue: string;
    pivotIndex: number;
}

const DIMENSION_ACCESSOR = 'dimension';
const MONTH_COLUMN_KEY = 'month';

const rowKey = (value: string, metric: string) => `${value}-${metric}`;
const getMetricFromRowKey = (key: string) => key.split('-').pop();

const isDateType = (report: Report, columnKey: string) => {
    if (columnKey.toLowerCase() === MONTH_COLUMN_KEY) {
        return true;
    }
    const { typeId } = typeOf(findColumn(report, columnKey));
    return typeId === 'DATE' || typeId === 'DATE_TIME';
};

const mapMetricsToColumns = (report: Report, pivotValue: string) => (metrics: string[]) =>
    metrics.map<TableColumn>((metric) => ({
        Header: beautifyInsightColumnName(metric),
        accessor: rowKey(pivotValue, metric),
        id: rowKey(pivotValue, metric),
        type: typeOf(findColumn(report, metric)),
        Cell: ({ formattedValue }) => (
            <div className="w-100 text-truncate" title={formattedValue}>
                {formattedValue}
            </div>
        ),
    }));

const handleComparison =
    (report: Report, comparisonRows: Record<string, any>[], compareWith?: AnalyticsCompareType) =>
    (columns: TableColumn[]) =>
        columns.map<TableColumn>((col) => {
            if (!compareWith) {
                return col;
            }

            return {
                ...col,
                Cell: ({ original, formattedValue, index }: ReportTableCell) => {
                    assertIsDefined(col.id, 'col.id');
                    const metric = getMetricFromRowKey(col.id);
                    const headerCol = findColumn(report, metric);

                    const delta = formatDelta({
                        value: original[col.id],
                        compareValue: comparisonRows[index]?.[col.id],
                        inverted: !!headerCol?.invertedPerception,
                        isDerivative: !!headerCol?.isDerivative,
                        type: col.type,
                    });

                    return (
                        <div>
                            <div className="w-100 text-truncate" title={formattedValue}>
                                {formattedValue}
                            </div>
                            <ReportDeltaText delta={delta} className="font-xs" />
                        </div>
                    );
                },
            };
        });

const buildRows = (
    dimensionValues: string[],
    pivotValues: string[],
    metrics: string[],
    findMetricValue: (search: FindMetricArgs) => any
) =>
    dimensionValues.map((dimensionValue, dimensionIndex) => {
        const row: Record<string, any> = { [DIMENSION_ACCESSOR]: dimensionValue };

        pivotValues.forEach((pivotValue, pivotIndex) => {
            metrics.forEach((metric) => {
                const metricValue = findMetricValue({
                    metric,
                    dimensionValue,
                    dimensionIndex,
                    pivotValue,
                    pivotIndex,
                });

                row[rowKey(pivotValue, metric)] = metricValue;
            });
        });

        return row;
    });

export const useInsightPivotTable = ({
    pivotColumn,
    dimensions,
    metrics,
    report,
    compareReport,
    compareWith,
}: Props) => {
    const firstDimension = dimensions[0];
    if (!pivotColumn || !dimensions.length || pivotColumn === firstDimension) {
        return null;
    }

    const dimensionValues = uniq<string>(report.rows.map((x) => x[firstDimension])).sort();
    const pivotValues = uniq<string>(report.rows.map((row) => row[pivotColumn]))
        .filter(Boolean)
        .sort();

    const findReportValue = ({ metric, dimensionValue, pivotValue }: FindMetricArgs) =>
        report.rows.find(
            (originalRow) =>
                originalRow[firstDimension] === dimensionValue && originalRow[pivotColumn] === pivotValue
        )?.[metric];

    const findComparisonValue =
        (reportRows: Record<string, any>[]) =>
        ({ metric, dimensionValue, dimensionIndex, pivotValue, pivotIndex }: FindMetricArgs) => {
            if (compareWith === 'AVERAGE') {
                const numericValues = reportRows
                    .map((row) => row[rowKey(pivotValue, metric)])
                    .filter((value) => !isNaN(value));

                const avg = numericValues.reduce((sum, x) => sum + x, 0) / numericValues.length;
                return avg;
            }

            const firstDimensionIsDate = isDateType(report, firstDimension);
            const pivotColumnIsDate = isDateType(report, pivotColumn);

            if (!compareReport || (!firstDimensionIsDate && !pivotColumnIsDate)) {
                return null;
            }

            const dateColumnKey = firstDimensionIsDate ? firstDimension : pivotColumn;
            const dateIndex = firstDimensionIsDate ? dimensionIndex : pivotIndex;
            const dateValues = uniq<string>(compareReport.rows.map((x) => x[dateColumnKey])).sort();

            const otherDimensionKey = dateColumnKey === firstDimension ? pivotColumn : firstDimension;
            const otherDimensionValue = dateColumnKey === firstDimension ? pivotValue : dimensionValue;

            return compareReport.rows.find(
                (originalRow) =>
                    originalRow[dateColumnKey] === dateValues[dateIndex] &&
                    originalRow[otherDimensionKey] === otherDimensionValue
            )?.[metric];
        };

    const rows = buildRows(dimensionValues, pivotValues, metrics, findReportValue);
    const comparisonRows = !compareWith
        ? []
        : buildRows(dimensionValues, pivotValues, metrics, findComparisonValue(rows));

    const dimensionsColumn: TableColumn = {
        sticky: true,
        columns: [
            {
                Header: beautifyInsightColumnName(firstDimension),
                accessor: DIMENSION_ACCESSOR,
                className: 'dimensionColumn',
                autoWidth: true,
                type: typeOf(findColumn(report, firstDimension)),
                Cell: ({ formattedValue }) => (
                    <div className="w-100 text-truncate" title={formattedValue}>
                        {formattedValue}
                    </div>
                ),
            },
        ],
    };

    const groupedColumns = pivotValues.map<TableColumn>((pivotValue) => ({
        Header: beautifyInsightColumnName(pivotValue),
        headerClassName: 'cell-align-center',
        accessor: pivotValue,
        id: pivotValue,
        Cell: ({ formattedValue }) => (
            <div className="w-100 text-truncate" title={formattedValue}>
                {formattedValue}
            </div>
        ),
        columns: flow(
            mapMetricsToColumns(report, pivotValue),
            handleComparison(report, comparisonRows, compareWith)
        )(metrics),
    }));

    const columns = groupedColumns.length ? [dimensionsColumn, ...groupedColumns] : [];

    const headers = columns.reduce<string[][]>(
        (acc, x) => {
            if (x.columns?.length) {
                x.columns.forEach((y) => {
                    acc[0].push(isString(x.Header) ? x.Header : '');
                    acc[1].push(isString(y.Header) ? y.Header : '');
                });
            }

            return acc;
        },
        [[], []]
    );

    return {
        rows,
        columns,
        headers,
    };
};
