import { CampaignIntegration } from 'platform/campaign/campaign/campaign.types';
import FormHelp from 'platform/common/components/FormHelp/FormHelp';
import InputWithAddOn from 'platform/common/components/InputWithAddon/InputWithAddOn';
import { OBJECTIVE_LABELS } from 'platform/common/constants/externalIntegration.constant';
import { labelByKey } from 'platform/common/constants/optimization.constant';

const FacebookIntegration = ({ integration }: { integration: CampaignIntegration }) => (
    <>
        {!integration.attributes?.objective && (
            <FormHelp iconClassName="mt-2">
                <InputWithAddOn
                    disabled
                    className="mb-3"
                    leftAddOn={{ title: 'Objective' }}
                    value={labelByKey(OBJECTIVE_LABELS, integration?.attributes?.objective)}
                />
            </FormHelp>
        )}
    </>
);

export default FacebookIntegration;
