import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import { Series, Period } from 'platform/common/common.types';
import DateRangePicker from 'platform/common/components/DateRangePicker/DateRangePicker';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { usePromise } from 'platform/common/hooks/usePromise';
import { getPeriodForLast30Days } from 'platform/common/utils/date.util';
import SegmentAnalysisChart from 'platform/segments/components/SegmentAnalysisChart/SegmentAnalysisChart';
import segmentAnalyticsChartQuery from 'platform/segments/queries/segmentAnalyticsChartQuery.graphql';
import { fetchSegmentList } from 'platform/segments/services/segments.service';

const metricOptions = [
    {
        label: 'Users',
        value: 'adh_segment_unique_users_yesterday',
    },
    {
        label: 'All purchases',
        value: 'adh_segment_unique_purchases_30d',
    },
    {
        label: 'Purchase probability',
        value: 'adh_segment_purchase_probability_30d',
    },
];

type Query = {
    segmentAnalyticsChart: {
        name: string;
        stats: {
            [key: string]: {
                series: Series;
            };
        };
    };
};

type Props = {
    segmentId: number;
    segmentName: string;
    advertiserId: number;
};

const DEFAULT_COMPARE_TO_SEGMENT = 'Purchase Unattributed';

const SegmentAnalysisChartContainer = ({ segmentId, segmentName, advertiserId }: Props) => {
    const [dateRange, setDateRange] = useState<Period>({
        ...getPeriodForLast30Days(),
        preset: 'LAST_30_DAYS',
    });
    const [metric, setMetric] = useState('adh_segment_unique_users_yesterday');
    const [compareSegmentId, setCompareSegmentId] = useState<number | undefined>();

    const isDemoMode = useSelector(authSelectors.isDemoModeEnabled);

    const [{ data: segmentOptions, loading: segmentOptionsLoading }] = usePromise(
        [],
        async () => {
            const list = await fetchSegmentList({ advertiserId }, isDemoMode);
            setCompareSegmentId(list.find((segment) => segment.name === DEFAULT_COMPARE_TO_SEGMENT)?.id);
            return list;
        },
        [advertiserId]
    );

    const { loading, data: primaryStats } = useQuery<Query>(segmentAnalyticsChartQuery, {
        variables: {
            id: segmentId,
            statsPeriod: { from: dateRange.from, to: dateRange.to },
        },
        skip: !segmentId,
    });

    const { data: secondaryStats } = useQuery<Query>(segmentAnalyticsChartQuery, {
        variables: {
            id: compareSegmentId,
            statsPeriod: { from: dateRange.from, to: dateRange.to },
        },
        skip: !compareSegmentId,
    });

    if (!primaryStats?.segmentAnalyticsChart) {
        return null;
    }

    const { labels, values: primaryValues } = primaryStats.segmentAnalyticsChart.stats[metric].series;
    const { name: secondaryLabel, stats } = secondaryStats?.segmentAnalyticsChart ?? {};
    const secondaryValues = stats?.[metric].series.values;

    return (
        <>
            {loading && <OverlayLoader />}
            <div className="d-flex flex-wrap">
                <div className="mb-2 me-2 ">
                    <SelectWithAddon
                        name="Metric"
                        value={metric}
                        options={metricOptions}
                        onChange={setMetric}
                        isClearable={false}
                    />
                </div>
                <div className="mb-2 me-2 ">
                    <SelectWithAddon
                        name="Compare with"
                        value={compareSegmentId}
                        options={segmentOptions}
                        onChange={setCompareSegmentId}
                        isLoading={segmentOptionsLoading}
                        isClearable={false}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                    />
                </div>
                <div className="ms-auto">
                    <DateRangePicker
                        className="borderless"
                        preset={dateRange.preset}
                        from={dateRange.from}
                        to={dateRange.to}
                        onChange={(from, to, preset) => setDateRange({ from, to, preset })}
                    />
                </div>
            </div>
            <div>
                <SegmentAnalysisChart
                    metric={metric}
                    labels={labels}
                    primaryLabel={segmentName}
                    primaryValues={primaryValues}
                    secondaryLabel={secondaryLabel}
                    secondaryValues={secondaryValues}
                />
            </div>
        </>
    );
};

export default SegmentAnalysisChartContainer;
