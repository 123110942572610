import { Button } from 'reactstrap';
import CreativeBulkUploadModal from 'platform/creatives/components/CreativeBulkUpload/CreativeBulkUploadModal';
import { GcmHostedCreativeModel } from 'platform/creatives/mappers/creative/gcmHostedCreative.mapper';
import { useModal } from '../../../common/components/Modal/Modal';

type Props = {
    className?: string;
    advertiserId: number;
    onUpload: (creatives: GcmHostedCreativeModel[]) => void;
};

const CreativeBulkUploadButton = ({ className, advertiserId, onUpload }: Props) => {
    const { showModal } = useModal();
    return (
        <Button
            className={className}
            onClick={() =>
                showModal((toggle) => (
                    <CreativeBulkUploadModal
                        toggle={toggle}
                        advertiserId={advertiserId}
                        onUpload={onUpload}
                    />
                ))
            }
        >
            <i className="fa fa-upload" /> Bulk upload
        </Button>
    );
};

export default CreativeBulkUploadButton;
