import { useSelector } from 'react-redux';
import { useAnalyticsReports } from 'platform/analytics/hooks/useAnalyticsReports';
import { Section } from 'platform/app/app.types';
import { authSelectors } from 'platform/app/ducks/auth.duck';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import { useModal } from 'platform/common/components/Modal/Modal';
import { PopoverDropdownItem } from 'platform/common/components/PopoverDropdown/PopoverDropdown';
import { useFeature } from 'platform/common/hooks/useFeature';
import { arrayItemMove } from 'platform/common/utils/array.util';
import { resolveFolderId } from '../ReportManagement/ReportManagement';
import { useReportHandlers } from '../ReportManagement/useReportHandlers';
import SectionsCard from '../SectionsCard';
import {
    assignAnalyticsReports,
    createAnalyticsReport,
    updateAnalyticsReport,
} from '../analyticsReport.service';
import { AnalyticsReport, AnalyticsReportWithSettings } from '../analyticsReport.types';
import FolderSaveModal from './FolderSaveModal';

interface Props {
    section: Section;
}

const FolderAccess = ({ section }: Props) => {
    const showConfirmationModal = useConfirmationModal();
    const { showModal } = useModal();
    const canEdit = useFeature('CUSTOM_FOLDER_EDIT');
    const profile = useSelector(authSelectors.ready.profile);
    const { seatId } = profile;
    const { accessibleReports, reloadAnalyticsReports } = useAnalyticsReports();
    const seatReports = accessibleReports.filter((r) => r.seatIds.includes(seatId));
    const filteredSeatReports = seatReports.filter((r) => r.section === section && !r.createdByAdmin);

    const onReportMove = async (
        oldReport: AnalyticsReportWithSettings,
        newReport: Partial<AnalyticsReportWithSettings>,
        reports: AnalyticsReport[]
    ) => {
        const oldIndex = reports.findIndex((r) => r.id === oldReport.id);
        const newIndex = reports.findIndex((r) => r.id === newReport.id);

        if (
            oldIndex === -1 ||
            oldIndex === newIndex ||
            (oldReport.type === 'FOLDER' && !!newReport.folderId)
        ) {
            return;
        }

        const promises = [
            updateAnalyticsReport({
                ...oldReport,
                section: newReport.section,
                folderId: resolveFolderId(oldReport, newReport),
            }),
            newIndex === -1
                ? Promise.resolve()
                : assignAnalyticsReports(
                      { seatId },
                      arrayItemMove(reports, oldIndex, newIndex).map((r) => r.id!)
                  ),
        ];

        await Promise.all(promises);
    };

    const { handleReportChange, handleReportMove, handleReportsCreate } = useReportHandlers({
        seatId,
        refetchReports: reloadAnalyticsReports,
        onReportChange: updateAnalyticsReport,
        onReportMove,
        onReportsCreate: (reports) => createAnalyticsReport(reports[0]),
    });

    const getEditActions = (currentReport: AnalyticsReport): PopoverDropdownItem[] => {
        if (profile.adminUser) {
            return []; // admins should not edit shared folders as they're linked to external user's seat
        }
        const active = currentReport.status === 'ACTIVE';

        const actions = [];

        if (currentReport.type === 'FOLDER' && active) {
            actions.push({
                label: 'Assign users',
                action: () =>
                    showModal((toggle) => (
                        <FolderSaveModal
                            reportId={currentReport.id}
                            onCreate={(report) => handleReportsCreate([report])}
                            seatId={seatId!}
                            section={section}
                            toggle={toggle}
                        />
                    )),
            });
        }

        if (
            (currentReport.type === 'FOLDER' &&
                !filteredSeatReports.some((r) => r.folderId === currentReport.id)) ||
            currentReport.type !== 'FOLDER'
        ) {
            actions.push({
                label: active ? 'Archive' : 'Activate',
                action: () =>
                    showConfirmationModal(
                        () =>
                            handleReportChange({ ...currentReport, status: active ? 'ARCHIVED' : 'ACTIVE' }),
                        {
                            title: 'Change report status',
                            text: `The report ${currentReport.name} will be ${
                                active ? 'archived' : 'activated'
                            }. Do you want to continue?`,
                        }
                    ),
            });
        }

        return actions;
    };

    return (
        <>
            <BodyContainer className="mt-3" helpKey="folder_access">
                <InsetBox className="my-3">
                    <SectionsCard
                        seatId={seatId!}
                        section={section}
                        reports={filteredSeatReports}
                        canEdit={canEdit}
                        canEditReportName={false}
                        loading={false}
                        templates={[]}
                        refetchReports={reloadAnalyticsReports}
                        onReportsCreate={handleReportsCreate}
                        onReportChange={handleReportChange}
                        onReportMove={(oldReport, newReport) =>
                            handleReportMove(oldReport, newReport, seatReports)
                        }
                        createActions={
                            profile.adminUser
                                ? [] // admins should not create shared folders as they should be linked to external user's seat
                                : [
                                      {
                                          label: 'New Folder',
                                          action: () =>
                                              showModal((toggle) => (
                                                  <FolderSaveModal
                                                      onCreate={(report) => handleReportsCreate([report])}
                                                      seatId={seatId!}
                                                      section={section}
                                                      toggle={toggle}
                                                  />
                                              )),
                                      },
                                  ]
                        }
                        editActions={getEditActions}
                    />
                </InsetBox>
            </BodyContainer>
        </>
    );
};

export default FolderAccess;
