import { ReactNode, useState } from 'react';
import { components, OptionProps } from 'react-select';
import { Button, Dropdown, DropdownMenu, DropdownMenuProps, DropdownProps, DropdownToggle } from 'reactstrap';
import classNames from 'classnames';
import Select from 'platform/common/components/Select/Select';
import ActiveAdvertiserSelectContainer from 'platform/common/containers/ActiveAdvertiser/ActiveAdvertiserSelectContainer';
import { AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { publicImageUrl } from 'platform/common/utils/environment.util';
import './ActiveAdvertiserSelect.scss';

type Value = number | number[];

interface Props {
    value?: Value;
    options: AdvertiserOption[];
    children: ReactNode;
    isMulti?: boolean;
    isLoading: boolean;
    closeMenuOnSelect?: boolean;
    onChange: (selected: AdvertiserOption[]) => void;
    menuProps?: DropdownMenuProps;
    dropdownProps?: DropdownProps;
}

const DisplayValue = ({ children }: { children: ReactNode }) => (
    <div className="me-2 d-flex flex-column flex-fit" style={{ lineHeight: '1em' }}>
        <small className="text-muted align-top">Advertiser</small>
        <div className="fw-bold text-truncate" title={typeof children === 'string' ? children : undefined}>
            {children}
        </div>
    </div>
);

interface DisplayContainerProps {
    iconUrl?: string;
    label?: string;
    children: ReactNode;
}

const DisplayContainer = ({ iconUrl, label, children }: DisplayContainerProps) => (
    <div className="d-flex align-items-center flex-fit">
        <div className="mx-2" style={{ height: 25, width: 25 }}>
            {iconUrl && (
                <img
                    style={{ borderRadius: 4 }}
                    className="w-100 h-100"
                    role="presentation"
                    src={publicImageUrl(iconUrl)}
                    alt={label}
                />
            )}
        </div>
        {children}
    </div>
);

const Option = (props: OptionProps<AdvertiserOption, boolean>) => (
    <div
        className={classNames('ActiveAdvertiserSelect-option d-flex', {
            'ActiveAdvertiserSelect-option--selected': props.isSelected,
        })}
    >
        <div
            role="button"
            tabIndex={0}
            className="py-1 px-0 flex-grow-1 outline-0"
            onClick={() => props.setValue([props.data], 'select-option')}
        >
            <DisplayContainer label={props.data.name} iconUrl={props.data.iconUrl}>
                <div className="flex-fit">
                    <div className="fw-bold">{props.data.name}</div>
                    <small className="text-muted">{props.data.id}</small>
                </div>
            </DisplayContainer>
        </div>
        {props.isMulti && !props.isSelected && (
            <Button
                color="link"
                onClick={() => props.setValue([...(props.getValue() as any), props.data], 'select-option')}
            >
                <i className="fa fa-plus-circle cursor-pointer text-dark" />
            </Button>
        )}
    </div>
);

const ActiveAdvertiserSelect = ({
    value,
    options,
    children,
    isLoading,
    isMulti,
    menuProps = {},
    dropdownProps = {},
    onChange,
}: Props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const selectedOption =
        Array.isArray(value) && value.length === 1
            ? options.find((o) => o.id === value[0])
            : options.find((o) => o.id === value);

    const handleChange = (newValue: Value) => {
        if (!isMulti) {
            setIsDropdownOpen(false);
        }

        onChange(
            Array.isArray(newValue)
                ? options.filter((o) => newValue.includes(o.id))
                : options.filter((o) => o.id === newValue)
        );
    };

    return options.length === 1 ? (
        <DisplayContainer label={options[0].name} iconUrl={options[0].iconUrl}>
            <DisplayValue>{children}</DisplayValue>
        </DisplayContainer>
    ) : (
        <Dropdown
            isOpen={isDropdownOpen}
            toggle={() => setIsDropdownOpen(!isDropdownOpen)}
            {...dropdownProps}
        >
            <DropdownToggle className="ActiveAdvertiserSelect-dropdownToggle" tag="div">
                <DisplayContainer label={selectedOption?.name} iconUrl={selectedOption?.iconUrl}>
                    <DisplayValue>{children}</DisplayValue>
                </DisplayContainer>
                <div className="ms-auto">
                    <components.DownChevron />
                </div>
            </DropdownToggle>
            <DropdownMenu flip className="ActiveAdvertiserSelect-dropdownMenu" {...menuProps}>
                <Select<AdvertiserOption>
                    menuPlacement="top"
                    value={value}
                    options={options}
                    components={{
                        Option,
                        Control: ActiveAdvertiserSelectContainer,
                        IndicatorSeparator: null,
                        DropdownIndicator: null,
                    }}
                    backspaceRemovesValue={false}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isMulti={isMulti}
                    isClearable={false}
                    isLoading={isLoading}
                    canSelectAll
                    clearValuesOnAddAllFound
                    menuIsOpen
                    autoFocus
                    getOptionLabel={(advertiser) => advertiser.name}
                    getOptionValue={(advertiser) => advertiser.id}
                    onChange={handleChange}
                />
            </DropdownMenu>
        </Dropdown>
    );
};

export default ActiveAdvertiserSelect;
