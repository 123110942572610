import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import DeltaText, { DeltaTextProps } from 'platform/common/components/DeltaText/DeltaText';
import useTypedSelector from 'platform/common/hooks/useTypedSelector';

const ReportDeltaText = (props: DeltaTextProps) => {
    const { showCompareValues } = useTypedSelector(analyticsSelectors.settings);

    return <DeltaText {...props} showCompareValue={showCompareValues} />;
};

export default ReportDeltaText;
