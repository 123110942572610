import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { required } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { ACCESS_OPTIONS } from 'platform/userManagement/constants/access.constant';
import { SUPERUSER_ROLE_ID } from 'platform/userManagement/mappers/user.mapper';
import { RoleFormModel } from 'platform/userManagement/types/role.type';
import RoleEditList from './RoleEditList';

type Props = {
    onDelete?: () => void;
};

const RoleForm = ({
    onCancel,
    labels,
    canEdit,
    isEdit,
    formikProps: { initialValues, values, setFieldValue, submitForm },
    onDelete,
}: FormProps<RoleFormModel> & Props) => {
    const channels = useSelector(classifierSelectors.channels);

    return (
        <CardForm
            title={`${labels.prefix} Role`}
            subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info">
                <FormRow label="Name">
                    <FormInput name="title" type="text" validate={required} />
                </FormRow>

                <FormRow label="Status">
                    <FormSelect
                        name="status"
                        options={ACTIVE_OR_ARCHIVED}
                        isClearable={false}
                        validate={required}
                    />
                </FormRow>

                <RoleEditList
                    grantedFeatures={values.features}
                    onChange={(features) => setFieldValue('features', features)}
                />
            </ControlledCard>

            <ControlledCard title="Analytics">
                <FormRow label="Channel access">
                    <FormButtonArray name="channelAccess" buttons={ACCESS_OPTIONS} />
                    <div className="pt-3">
                        {values.channelAccess === 'ALL' && 'User has access to all channels.'}
                        {values.channelAccess === 'CUSTOM' && (
                            <FormSelect
                                name="accessibleChannels"
                                options={channels}
                                getOptionValue={(c) => c.code}
                                getOptionLabel={(c) => c.name}
                                isMulti
                            />
                        )}
                    </div>
                </FormRow>
            </ControlledCard>

            {isEdit && (
                <ControlledCard title="Delete role">
                    {initialValues.assignedUsers.length > 0 && (
                        <div>
                            <p>This role cannot be deleted because it is assigned to a user:</p>
                            {initialValues.assignedUsers.map((user) => (
                                <p key={user} className="mb-1">
                                    {user}
                                </p>
                            ))}
                        </div>
                    )}
                    <Button
                        disabled={
                            !!initialValues.assignedUsers.length ||
                            !canEdit ||
                            initialValues.id === SUPERUSER_ROLE_ID
                        }
                        color="secondary"
                        onClick={onDelete}
                    >
                        Delete role
                    </Button>
                </ControlledCard>
            )}
        </CardForm>
    );
};

export default RoleForm;
