import classNames from 'classnames';
import { CAMPAIGN_STATE_LABEL, CampaignState } from '../constants/state.constant';

type Props = {
    state?: CampaignState;
    className?: string;
};

const CAMPAIGN_STATE_CLASSNAME: {
    [key in CampaignState]: string;
} = Object.freeze({
    [CampaignState.RUNNING]: 'bg-success',
    [CampaignState.PAUSED]: 'bg-secondary',
    [CampaignState.STOPPED]: 'bg-secondary',
    [CampaignState.NEW]: 'bg-white border-primary',
    [CampaignState.CANCELLED]: 'bg-white border-danger',
    [CampaignState.ARCHIVED]: 'bg-white border-dark',
});

const CampaignStateIndicator = ({ state, className }: Props) =>
    state ? (
        <div className={className}>
            <div
                style={{ height: '10px', width: '10px', border: '2px solid transparent' }}
                className={classNames('rounded-circle', CAMPAIGN_STATE_CLASSNAME[state])}
                title={CAMPAIGN_STATE_LABEL[state]}
            />
        </div>
    ) : null;

export default CampaignStateIndicator;
