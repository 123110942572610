import { useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Modal } from 'reactstrap';
import { uniq } from 'lodash-es';
import { AdvertiserAccount } from 'platform/advertisers/advertiser.types';
import ExternalAccountsTable from 'platform/advertisers/components/AdvertiserFormIntegrations/ExternalAccountsModal/ExternalAccountsTable';
import FormHeader from 'platform/common/components/FormHeader/FormHeader';
import { ExternalSystem, getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import './ExternalAccountsModal.scss';

type Props = {
    toggle: () => any;
    externalSystem: ExternalSystem;
    externalAccounts: AdvertiserAccount[];
    onChange: (value: AdvertiserAccount[]) => void;
};

const getAccountError = (account: AdvertiserAccount, accounts: AdvertiserAccount[]) => {
    if (!account.externalId) {
        return 'ID is required';
    }
    if (accounts.filter((acc) => acc.externalId === account.externalId).length > 1) {
        return 'IDs have to be unique';
    }
    return '';
};

const ExternalAccountsModal = ({ toggle, externalSystem, onChange, externalAccounts }: Props) => {
    const [accounts, setAccounts] = useState(externalAccounts);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    return (
        <Modal isOpen toggle={toggle} className="ExternalAccountsModal">
            <Card className="CardForm h-100">
                <CardHeader>
                    <FormHeader title={`${getIntegrationLabel(externalSystem)} external accounts`}>
                        <Button
                            color="primary"
                            onClick={() => {
                                const accountsWithErrors = accounts.map((account) => ({
                                    ...account,
                                    error: getAccountError(account, accounts),
                                }));

                                const errors = uniq(
                                    accountsWithErrors.map((acc) => acc.error).filter(Boolean)
                                );

                                if (errors.length) {
                                    setAccounts(accountsWithErrors);
                                    setErrorMessages(errors);
                                    return;
                                }
                                onChange(accounts);
                                toggle();
                            }}
                        >
                            Submit
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Dismiss
                        </Button>
                    </FormHeader>
                </CardHeader>
                <CardBody>
                    <Card className="mb-0">
                        <CardBody>
                            {!!errorMessages.length && (
                                <Alert color="danger">
                                    {errorMessages.map((error) => (
                                        <div>{error}</div>
                                    ))}
                                </Alert>
                            )}
                            <ExternalAccountsTable
                                externalSystem={externalSystem}
                                accounts={accounts}
                                onChange={setAccounts}
                            />
                        </CardBody>
                    </Card>
                </CardBody>
            </Card>
        </Modal>
    );
};

export default ExternalAccountsModal;
