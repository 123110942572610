import { useSelector } from 'react-redux';
import { useAnalyticsReports } from 'platform/analytics/hooks/useAnalyticsReports';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';

export const useRefetchAnalyticsReports = (refetchReports: () => void) => {
    const { reloadAnalyticsReports } = useAnalyticsReports();
    const advertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);

    const refetch = async () => {
        refetchReports();
        await reloadAnalyticsReports(advertiser);
    };

    return {
        refetch,
    };
};
