import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContactInformation, EMPTY_CONTACTS } from 'platform/advertisers/advertiser.types';
import { updateContactInformation } from 'platform/advertisers/services/advertiser.service';
import { contactActions, contactSelectors } from 'platform/common/ducks/contacts.duck';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import { fetchUsers } from 'platform/userManagement/services/userManagement.service';
import { User } from 'platform/userManagement/types/user.type';

export interface ContactData {
    name: string;
    login: string;
    phone: string;
    imageUrl?: string;
    title?: string;
}

const mapUserDataByFieldName = (contactFieldUsers: number[], users: User[]) =>
    users
        .filter((user) => contactFieldUsers.includes(user.id))
        .map<ContactData>((u) => ({
            name: u.name,
            login: u.login,
            imageUrl: u.imageUrl,
            phone: u.phone,
            title: u.title,
        }));

const userIdsToContacts = (userData: User[], contactInfo: ContactInformation) => {
    if (!userData.length || !contactInfo) {
        return EMPTY_CONTACTS;
    }

    return {
        clientService: mapUserDataByFieldName(contactInfo.clientService, userData),
        media: mapUserDataByFieldName(contactInfo.media, userData),
        internalEmail: contactInfo.internalEmail,
    };
};
export const useContacts = () => {
    const dispatch = useDispatch();
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const [isEdit, setIsEdit] = useState(false);
    const contactInformation = useSelector(contactSelectors.getContacts());

    const [{ data: users, loading }] = usePromise([], () => fetchUsers({ states: ['ACTIVE'], seatId: 100 }), [
        advertiserId,
    ]);

    const { clientService, media, internalEmail } = userIdsToContacts(users, contactInformation);

    const toggleEdit = () => setIsEdit((prev) => !prev);

    const cancel = () => setIsEdit(false);

    const submit = async (data: ContactInformation) => {
        await updateContactInformation(advertiserId, data);
        dispatch(contactActions.setContacts(data));
        setIsEdit(false);
    };

    return {
        clientService,
        media,
        internalEmail,
        contactInformation,
        users,
        isEdit,
        loading,
        toggleEdit,
        cancel,
        submit,
    };
};
