import { isEmpty } from 'lodash-es';
import { LogBookAssignmentType } from 'platform/campaign/logBook/logBook.constant';
import {
    LogBookEntry,
    LogBookNoteFormModel,
    LogBookNoteModel,
    LogEntryTableView,
} from 'platform/campaign/logBook/logBook.types';
import { getChannelLabels } from 'platform/campaign/logBook/logBook.utils';
import { Channel } from 'platform/channels/channel.types';
import { formatDateTime } from 'platform/common/utils/date.util';

const inferAssigmentType = ({
    mediaInsertionIds,
    mediaplanIds,
}: {
    mediaInsertionIds?: number[];
    mediaplanIds?: number[];
}): LogBookAssignmentType => {
    if (!isEmpty(mediaInsertionIds)) {
        return 'MEDIA_INSERTIONS';
    }
    if (!isEmpty(mediaplanIds)) {
        return 'MEDIAPLANS';
    }
    return 'NONE';
};

export const toNotesApiModel = ({
    id,
    version,
    advertiserId,
    status,
    subject,
    category,
    text,
    mediaInsertionIds,
    mediaplanIds,
    period,
    classification,
}: LogBookNoteFormModel): LogBookNoteModel => ({
    id,
    version,
    advertiserId,
    status,
    subject,
    category: category!,
    text: text ?? '',
    mediaInsertionIds,
    mediaplanIds,
    periodFrom: period?.from,
    periodTo: period?.to,
    classification,
});

export const toNotesFormModel = ({
    id,
    version,
    advertiserId,
    status,
    subject,
    category,
    text,
    mediaInsertionIds,
    mediaplanIds,
    periodFrom,
    periodTo,
    classification,
}: LogBookNoteModel): LogBookNoteFormModel => ({
    id,
    version,
    advertiserId,
    status,
    subject,
    category,
    text,
    mediaInsertionIds,
    mediaplanIds,
    period: { from: periodFrom, to: periodTo },
    classification,
    assignmentType: inferAssigmentType({ mediaInsertionIds, mediaplanIds }),
});

export const toTableView = (log: LogBookEntry, channels: Channel[]): LogEntryTableView => ({
    ...log,
    timestamp: formatDateTime(log.timestamp),
    channel: getChannelLabels(log, channels),
});
