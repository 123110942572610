import { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import { AnyAction } from 'redux';
import { push } from 'redux-first-history';
import classNames from 'classnames';
import { Formik } from 'formik';
import { isEmpty, orderBy } from 'lodash-es';
import moment, { Duration, Moment } from 'moment';
import AdvertiserAccountFormLabel from 'platform/advertisers/components/AdvertiserAccountLabel/AdvertiserAccountFormLabel';
import WarningIcon from 'platform/assets/icons/WARNING.svg';
import { getCampaignImportListUrl } from 'platform/campaign/campaign.navigation';
import { campaignClassificationToApi } from 'platform/campaign/campaign/campaignClassification.mapper';
import { CampaignClassificationModel } from 'platform/campaign/campaign/campaignClassification.types';
import {
    ImportedCampaign,
    ImportedEntity,
    ImportedUnit,
} from 'platform/campaign/campaign/campaignImport.types';
import CampaignClassifications from 'platform/campaign/campaign/components/CampaignClassifications';
import CampaignImportMediaInsertions from 'platform/campaign/campaign/components/CampaignImport/CampaignImportMediaInsertions';
import { assertIsDefined } from 'platform/common/common.assert';
import FormHeader from 'platform/common/components/FormHeader/FormHeader';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import Popover from 'platform/common/components/Popover/Popover';
import Select from 'platform/common/components/Select/Select';
import SidePanel from 'platform/common/components/SidePanel/SidePanel';
import Spinner from 'platform/common/components/Spinner/Spinner';
import { ExternalSystem, getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import SIDE_PANEL_SIZES from 'platform/common/constants/sidePanelSizes.constant';
import { activeAdvertiserSelectors } from 'platform/common/ducks/activeAdvertiser.duck';
import { useAdvancedSetting } from 'platform/common/hooks/useAdvancedSetting';
import { formatDateTime } from 'platform/common/utils/date.util';
import { parseErrorMessages } from 'platform/common/utils/errorMessages';
import { parseQuery } from 'platform/common/utils/url.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import {
    CampaignImportKey,
    fetchExternalCampaign,
    getCampaignImportStatus,
    IMPORT_MODE_OPTIONS,
    importExternalCampaign,
    importExternalUnit,
    ImportMode,
} from '../../services/campaignImport.service';
import './CampaignImport.scss';

interface Summary {
    campaign?: ImportedCampaign;
    units?: ImportedUnit[];
    importStarted?: Moment;
    importDuration?: Duration;
}

const modifyCampaign = (state: Summary, changes: Partial<ImportedCampaign>): Summary => ({
    ...state,
    campaign: state.campaign ? { ...state.campaign, ...changes } : undefined,
});

const modifyUnit = (state: Summary, externalId: string, changes: Partial<ImportedUnit>): Summary => ({
    ...state,
    units: state.units?.map((e) => (e.externalId === externalId ? { ...e, ...changes } : e)),
});

const getImportDuration = (state: Summary) => moment.duration(moment().diff(state.importStarted));

const reducer = (state: Summary, action: AnyAction): Summary => {
    switch (action.type) {
        case 'SUMMARY_LOADED':
            return {
                ...state,
                campaign: action.campaign,
                units: action.units,
            };
        case 'CAMPAIGN_IMPORT_STARTED':
            return {
                ...modifyCampaign(state, { importStatus: 'IN_PROGRESS' }),
                importStarted: moment(),
            };
        case 'CAMPAIGN_IMPORT_RUNNING':
            return {
                ...modifyCampaign(state, { importStatus: 'IN_PROGRESS' }),
                importDuration: getImportDuration(state),
            };
        case 'CAMPAIGN_IMPORT_COMPLETED':
            return {
                ...modifyCampaign(state, {
                    campaignId: action.campaignId,
                    importStatus: action.importStatus,
                }),
                importDuration: getImportDuration(state),
            };
        case 'CAMPAIGN_IMPORT_FAILED':
            return {
                ...modifyCampaign(state, {
                    importStatus: 'ERROR',
                    importError: action.importError,
                    warnings: undefined,
                }),
                importDuration: getImportDuration(state),
            };
        case 'UNIT_IMPORT_STARTED':
            return modifyUnit(state, action.externalId, {
                importStatus: 'IN_PROGRESS',
            });
        case 'UNIT_IMPORT_COMPLETED': {
            return modifyUnit(state, action.externalId, {
                unitId: action.unitId,
                strategyId: action.strategyId,
                importStatus: 'SUCCESS',
                warnings: action.warnings,
            });
        }
        case 'UNIT_IMPORT_FAILED':
            return modifyUnit(state, action.externalId, {
                importStatus: 'ERROR',
                importError: action.importError,
                warnings: undefined,
            });
        default:
            throw new Error(`Unsupported action: ${action.type}`);
    }
};

const ImportStatusIcon = ({
    result: { importStatus, importError, warnings },
}: {
    result: ImportedEntity;
}) => (
    <div className="m-auto">
        {importStatus === 'IN_PROGRESS' && <Spinner />}
        {(importStatus === 'SUCCESS' || importStatus === 'ERROR') && (
            <div className="d-inline-flex">
                <Popover
                    preventOverflow
                    renderPopover={() => (
                        <div className="p-2">
                            <div>{importError || 'No errors'}</div>
                        </div>
                    )}
                >
                    {importStatus === 'SUCCESS' && (
                        <i className="fa-lg text-success fa fa-check-circle me-2" />
                    )}
                    {importStatus === 'ERROR' && (
                        <i
                            role="button"
                            tabIndex={-1}
                            className="fa-lg text-danger fa fa-exclamation-circle"
                        />
                    )}
                </Popover>
                {!!warnings?.length && (
                    <Popover
                        preventOverflow
                        renderPopover={() => (
                            <div className="px-2">
                                {warnings.map((warning) => (
                                    <p className="p-0 my-2">{warning}</p>
                                ))}
                            </div>
                        )}
                    >
                        <i
                            role="button"
                            tabIndex={-1}
                            className="fa-lg text-warning fa fa-exclamation-circle"
                        />
                    </Popover>
                )}
            </div>
        )}
    </div>
);

const CampaignImportForm = () => {
    const location = useLocation();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [{ campaign, units, importStarted, importDuration }, dispatchImportAction] = useReducer(reducer, {
        units: [],
    });

    const advertiserId = useSelector(activeAdvertiserSelectors.id);
    const doNotImportAds = useAdvancedSetting('campaign-importer.do-not-import-ads');
    const [importMode, setImportMode] = useState<ImportMode>(doNotImportAds ? 'SKIP_CREATIVES' : 'FULL');
    const importModeOptions = doNotImportAds
        ? IMPORT_MODE_OPTIONS.filter((o) => o.value !== 'FULL')
        : IMPORT_MODE_OPTIONS;

    const { externalAdvertiserId, externalSystem } = parseQuery(location.search, { parseNumbers: false }) as {
        externalAdvertiserId: string;
        externalSystem: ExternalSystem;
    };

    const campaignId = campaign?.campaignId;
    const externalCampaignId = params.id;
    const systemLabel = getIntegrationLabel(externalSystem);
    const externalUnitLabel = externalSystem === 'FACEBOOK' ? 'Ad set' : 'Ad group';
    const importing = importStarted && campaign?.importStatus === 'IN_PROGRESS';
    const existsRedirectUnits = units?.some((u) => u.type === 'REDIRECT');
    const disabled = campaign?.type === 'REDIRECT' || existsRedirectUnits;
    const dispatch = useDispatch();

    const campaignKey = useCallback((): CampaignImportKey => {
        assertIsDefined(externalAdvertiserId, 'externalAdvertiserId');
        return {
            externalSystem,
            externalId: externalCampaignId!,
            advertiserId,
            externalAdvertiserId,
        };
    }, [advertiserId, externalAdvertiserId, externalCampaignId, externalSystem]);

    const importUnit = async (externalId: string) => {
        dispatchImportAction({ type: 'UNIT_IMPORT_STARTED', externalId });
        try {
            const { unitId, strategyId, warnings } = await importExternalUnit(
                campaignKey(),
                externalId,
                importMode
            );
            dispatchImportAction({ type: 'UNIT_IMPORT_COMPLETED', externalId, unitId, strategyId, warnings });
        } catch (error) {
            const importError = parseErrorMessages(error?.response?.data)[0]?.message;
            dispatchImportAction({ type: 'UNIT_IMPORT_FAILED', externalId, importError });
        }
    };

    const importCampaign = async (classification?: CampaignClassificationModel) => {
        dispatchImportAction({ type: 'CAMPAIGN_IMPORT_STARTED' });
        setLoading(true);
        try {
            const { campaignId: newCampaignId, importStatus } = await importExternalCampaign(campaignKey(), {
                classification: campaignClassificationToApi(classification ?? {}),
                importMode,
            });
            dispatchImportAction({
                type: 'CAMPAIGN_IMPORT_COMPLETED',
                campaignId: newCampaignId,
                importStatus,
            });
        } catch (error) {
            const importError = parseErrorMessages(error?.response?.data)[0]?.message;
            dispatchImportAction({ type: 'CAMPAIGN_IMPORT_FAILED', importError });
        } finally {
            setLoading(false);
        }
    };

    const columnDefinitions: TableColumn<ImportedUnit>[] = [
        {
            Header: `${externalUnitLabel} name`,
            autoWidth: true,
            Cell: ({ original: { name } }) => name,
        },
        {
            Header: `${externalUnitLabel} ID`,
            width: 200,
            Cell: ({ original }) => original.externalId,
        },
        {
            Header: 'DAP unit',
            width: 260,
            Cell: ({ original: { unitId, strategyId } }) =>
                strategyId ? (
                    <>
                        Unit {unitId} in&nbsp;
                        <Link to={`/strategies/${strategyId}`}>Strategy {strategyId}</Link>
                    </>
                ) : null,
        },
        {
            width: 60,
            Cell: ({ original }) => <ImportStatusIcon result={original} />,
        },
        {
            width: 80,
            Cell: ({ original: { externalId, unitId } }) =>
                campaignId && importMode !== 'SKIP_UNITS' ? (
                    <Button
                        className="pt-1 pb-1"
                        disabled={disabled || importing}
                        onClick={() => importUnit(externalId)}
                    >
                        {unitId ? 'Retry' : 'Import'}
                    </Button>
                ) : null,
        },
    ];

    const loadCampaignImportSummary = useCallback((): Promise<ImportedCampaign> => {
        setLoading(true);
        const fetchData = async () => {
            setLoading(true);
            const summary = await fetchExternalCampaign(campaignKey());
            dispatchImportAction({
                type: 'SUMMARY_LOADED',
                campaign: summary,
                units: orderBy(summary.externalUnits, ['unitId', 'name']),
            });
            return summary;
        };
        return fetchData().finally(() => setLoading(false));
    }, [campaignKey]);

    useEffect(() => {
        dispatchImportAction({ type: 'SUMMARY_LOADED', summary: { units: [] } });
        loadCampaignImportSummary();
    }, [loadCampaignImportSummary]);

    useEffect(() => {
        if (importing && campaignId) {
            const timeoutId = setTimeout(async () => {
                const { importStatus } = await getCampaignImportStatus(campaignId);
                if (importStatus === 'IN_PROGRESS') {
                    dispatchImportAction({ type: 'CAMPAIGN_IMPORT_RUNNING' });
                } else {
                    await loadCampaignImportSummary();
                }
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
        return () => {};
    }, [importing, campaignId, importDuration, loadCampaignImportSummary]);

    const handleClose = () => {
        dispatch(push(getCampaignImportListUrl({ advertiserId, externalAdvertiserId, externalSystem })));
    };

    return (
        <SidePanel size={SIDE_PANEL_SIZES.WIDE}>
            <Card className="h-100">
                <CardHeader>
                    <FormHeader
                        title="Campaign import details"
                        subtitle={`${systemLabel} ID: ${externalCampaignId}`}
                    >
                        <div className="py-2 align-items-end">
                            <Button color="secondary" onClick={handleClose}>
                                Dismiss
                            </Button>
                        </div>
                    </FormHeader>
                </CardHeader>
                {loading && <OverlayLoader />}
                {campaign && (
                    <Formik<{ classification: CampaignClassificationModel }>
                        initialValues={{
                            classification: {
                                channel: campaign.channel,
                                marketingGoal: 'PERFORMANCE',
                                targetAudience: 'PROSPECTING',
                            },
                        }}
                        onSubmit={(data) => importCampaign(data.classification)}
                    >
                        {({ values, setFieldValue, submitForm }) => (
                            <Form className="overflow-y-auto CampaignImportFormContainer">
                                <CardBody>
                                    {campaign && (
                                        <Card className="mt-lg-4">
                                            <CardHeader>Campaign info</CardHeader>
                                            <CardBody>
                                                <div className="position-relative d-flex flex-column gap-3">
                                                    <AdvertiserAccountFormLabel
                                                        advertiserId={advertiserId}
                                                        externalSystem={externalSystem}
                                                        externalAdvertiserId={externalAdvertiserId}
                                                    />
                                                    <FormInput
                                                        name={'campaign name'}
                                                        readOnly
                                                        leftAddOn={{ title: `${systemLabel} campaign name` }}
                                                        value={campaign.name}
                                                    />
                                                    <FormInput
                                                        name={'campaign ID'}
                                                        readOnly
                                                        leftAddOn={{ title: `${systemLabel} campaign ID` }}
                                                        value={campaign.externalId}
                                                    />
                                                    <FormInput
                                                        name={'Last imported'}
                                                        readOnly
                                                        leftAddOn={{ title: `Last imported` }}
                                                        value={
                                                            campaign.importedOn
                                                                ? formatDateTime(campaign.importedOn)
                                                                : '(never)'
                                                        }
                                                    />

                                                    {campaign.creativeConceptId && (
                                                        <FormInput
                                                            name={'Imported creative set'}
                                                            readOnly
                                                            hideInput
                                                            className="mb-0"
                                                            leftAddOn={{
                                                                title: `Imported creative set`,
                                                            }}
                                                            rightAddOn={{
                                                                className: 'd-none',
                                                                containerClassName: 'flex-fill text-start',
                                                                title: (
                                                                    <Link
                                                                        to={`/creatives/sets/${campaign.creativeConceptId}`}
                                                                    >
                                                                        {campaign.creativeConceptId}
                                                                    </Link>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                    {importStarted && importDuration && (
                                                        <FormInput
                                                            name={'Import started'}
                                                            readOnly
                                                            leftAddOn={{
                                                                title: `Import started`,
                                                            }}
                                                            hideInput
                                                            rightAddOn={{
                                                                className: 'd-none',
                                                                containerClassName: 'flex-fill text-start',
                                                                title: (
                                                                    <>
                                                                        {formatDateTime(importStarted)}{' '}
                                                                        (duration:
                                                                        {importDuration?.minutes()
                                                                            ? ` ${importDuration?.minutes()}m `
                                                                            : ' '}
                                                                        {importDuration?.seconds()}s)
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                    <div className="ms-auto">
                                                        <ImportStatusIcon result={campaign} />
                                                    </div>
                                                </div>
                                            </CardBody>
                                            {!campaignId && (
                                                <>
                                                    <CardHeader className="mt-4">
                                                        <div>Classifications</div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <CampaignClassifications
                                                            mediaType={'DIGITAL'}
                                                            classification={values.classification}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </CardBody>
                                                </>
                                            )}
                                            <hr />
                                            <CardBody className="d-flex flex-row-reverse">
                                                <div className="ms-4">
                                                    <Button
                                                        color="primary"
                                                        disabled={disabled || importing}
                                                        onClick={submitForm}
                                                    >
                                                        {campaignId ? 'Re-import' : 'Import'}
                                                    </Button>
                                                </div>
                                                <div style={{ flexBasis: '300px' }}>
                                                    <Select
                                                        value={importMode}
                                                        onChange={setImportMode}
                                                        options={importModeOptions}
                                                        isClearable={false}
                                                    />
                                                </div>
                                                {disabled && (
                                                    <Alert color="warning" className="me-4">
                                                        <img
                                                            src={WarningIcon}
                                                            alt="Warning"
                                                            className="me-2"
                                                        />
                                                        Cannot import this campaign because it
                                                        {existsRedirectUnits
                                                            ? ` has an IO unit(s) mapped to ${externalUnitLabel}(s)`
                                                            : ' is already mapped to an IO campaign'}
                                                    </Alert>
                                                )}
                                            </CardBody>
                                        </Card>
                                    )}

                                    {campaignId && (
                                        <Card className="mt-lg-4">
                                            <CardHeader>
                                                <div>Media insertions</div>
                                            </CardHeader>
                                            <CardBody>
                                                <CampaignImportMediaInsertions campaignId={campaignId} />
                                            </CardBody>
                                        </Card>
                                    )}
                                    {!isEmpty(units) && (
                                        <Card className="mt-lg-4">
                                            <CardHeader>
                                                <span>Units</span>
                                            </CardHeader>
                                            <CardBody>
                                                <FormattedTable
                                                    columns={columnDefinitions}
                                                    data={units}
                                                    getTrProps={(_: any, rowInfo: any) => ({
                                                        className: classNames({
                                                            'border border-danger':
                                                                rowInfo?.original?.status === 'ERROR',
                                                            'CampaignImport-pending':
                                                                !rowInfo?.original?.unitId,
                                                        }),
                                                    })}
                                                />
                                            </CardBody>
                                        </Card>
                                    )}
                                </CardBody>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card>
        </SidePanel>
    );
};

export default CampaignImportForm;
