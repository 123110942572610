import { useSelector } from 'react-redux';
import { helpSelectors } from 'platform/common/ducks/help.duck';
import { useFeature } from 'platform/common/hooks/useFeature';
import HelpEdit from '../HelpEdit/HelpEdit';
import IconButton from '../IconButton/IconButton';
import InfoIconTooltip from '../InfoIconTooltip/InfoIconTooltip';
import { useModal } from '../Modal/Modal';

const NO_DOCUMENTATION = 'No help information is available';
interface Props {
    helpKey: string;
    className?: string;
}

const HelpIcon = ({ helpKey, className }: Props) => {
    const helpValue = useSelector(helpSelectors.getHelpValue(helpKey));
    const { showModal } = useModal();
    const canEdit = useFeature('HELP_EDIT');
    const canView = useFeature('HELP_VIEW') || canEdit;
    const showHelp = !!helpValue || canEdit;

    const handleEdit = () => {
        showModal((toggle) => <HelpEdit help={{ key: helpKey, value: helpValue }} toggle={toggle} />);
    };

    if (!canView || !showHelp || !helpKey) {
        return null;
    }

    return (
        <InfoIconTooltip
            className={className}
            iconClassName={helpValue ? undefined : 'fa fa-info-circle text-gray-300'}
            autoHide={false}
        >
            <div className="d-flex align-items-center">
                <div className="InfoIconTooltip-content display-linebreak">
                    {helpValue || NO_DOCUMENTATION}
                </div>
                {canEdit && (
                    <IconButton className="ms-2" onClick={handleEdit}>
                        <i className="fal fa-edit" />
                    </IconButton>
                )}
            </div>
        </InfoIconTooltip>
    );
};

export default HelpIcon;
