import classNames from 'classnames';
import { ChatContentParts, ChatMessage } from 'platform/chat/chat.types';
import ChatTitleMessage from './ChatTitleMessage';
import ChatUserMessageFile from './ChatUserMessageFile';
import ChatUserMessageImage from './ChatUserMessageImage';

interface Props {
    messageData: ChatMessage;
    className?: string;
    titleMessageClassName?: string;
}

const ChatUserMessage = ({
    messageData: { prompt, contentParts },
    className,
    titleMessageClassName,
}: Props) => {
    if (contentParts.length > 1) {
        const sortedContentParts = contentParts.sort((a, b) => {
            const getOrder = (contentPart: ChatContentParts) => {
                if (contentPart.type === 'TEXT' && !contentPart.mimeType?.includes('text/csv')) return 0;
                if (contentPart.mimeType?.includes('image')) return 1;
                return 2;
            };

            return getOrder(a) - getOrder(b);
        });

        return (
            <div className="d-flex flex-direction-row gap-1 flex-wrap w-100">
                {sortedContentParts.map((contentPart, index) => {
                    if (contentPart.type === 'FILE' || contentPart.mimeType?.includes('text/csv')) {
                        return (
                            <div
                                key={index}
                                className={classNames(
                                    'mb-1',
                                    contentPart.mimeType?.includes('image')
                                        ? 'align-content-center'
                                        : 'w-100 align-content-center'
                                )}
                            >
                                {contentPart.mimeType?.includes('image') ? (
                                    <ChatUserMessageImage contentPart={contentPart} />
                                ) : (
                                    <ChatUserMessageFile
                                        contentPart={contentPart}
                                        className={titleMessageClassName}
                                    />
                                )}
                            </div>
                        );
                    }
                    return (
                        <div key={index} className={classNames(className, 'w-100')}>
                            {contentPart.content}
                        </div>
                    );
                })}
            </div>
        );
    }

    if (prompt?.name) {
        return (
            <ChatTitleMessage
                icon={<i className="far fa-book-open-cover text-primary me-1" />}
                className={titleMessageClassName}
            >
                {prompt.name}
            </ChatTitleMessage>
        );
    }

    if (contentParts[0]?.mimeType?.includes('image')) {
        return <ChatUserMessageImage contentPart={contentParts[0]} />;
    }

    if (contentParts[0]?.fileName) {
        return <ChatUserMessageFile contentPart={contentParts[0]} className={titleMessageClassName} />;
    }

    return <div className={className}>{contentParts[0]?.content || ''}</div>;
};

export default ChatUserMessage;
