import { NavigationItem } from 'platform/app/app.types';

export const CREATIVES: NavigationItem = {
    name: 'Creatives',
    path: '/creatives',
    redirectTo: '/creatives',
    requiresFeature: 'CREATIVES_VIEW',
};

export const CREATIVE_LABELS: NavigationItem = {
    name: 'Creative settings',
    path: '/creative-settings',
    requiresFeature: 'CREATIVES_VIEW',
};
