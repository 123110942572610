import classNames from 'classnames';
import { Perception, PERCEPTION_COLOR_MAP } from './deltas.util';

interface Props {
    value: number | undefined;
    limits: {
        min: number;
        max: number;
    };
    suffix: string | undefined;
    className?: string;
    higherValueDesirable?: boolean;
}

const ColoredValue = ({ value, limits, suffix, className, higherValueDesirable = false }: Props) => {
    const numberVal = Number(value);

    if (!numberVal) {
        return <>-</>;
    }

    const getPerception = (): Perception => {
        if (numberVal >= limits.min && numberVal <= limits.max) {
            return 'NEUTRAL';
        }

        if (numberVal > limits.max) {
            return higherValueDesirable ? 'POSITIVE' : 'NEGATIVE';
        }

        if (numberVal < limits.min) {
            return higherValueDesirable ? 'NEGATIVE' : 'POSITIVE';
        }

        return 'NONE';
    };

    return (
        <div className={classNames('text-nowrap', className, PERCEPTION_COLOR_MAP[getPerception()])}>
            {numberVal}
            {suffix && <span className="ps-1">{suffix}</span>}
        </div>
    );
};

export default ColoredValue;
