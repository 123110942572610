import { DEFAULT_ANALYTICS_SETTINGS } from 'platform/analytics/ducks/analyticsSettings.duck';
import {
    AnalyticsReportWithSettings,
    AnalyticsReport,
} from 'platform/analyticsReports/analyticsReport.types';
import { Section } from 'platform/app/app.types';
import axiosBff from 'platform/axios/axiosBff';
import { ActiveOrArchived } from 'platform/common/constants/status.constant';

const setDefaults = (analyticsReport: AnalyticsReportWithSettings): AnalyticsReportWithSettings => ({
    ...analyticsReport,
    analyticsSettings: {
        ...DEFAULT_ANALYTICS_SETTINGS,
        ...(analyticsReport.analyticsSettings ?? {}),
    },
});

export const createAnalyticsReport = (analyticsReport: AnalyticsReportWithSettings) =>
    axiosBff
        .post<{ id: number }>('/admin/api/customizable-reports', analyticsReport)
        .then((res) => res.data.id);

export const updateAnalyticsReport = (analyticsReport: AnalyticsReportWithSettings) =>
    axiosBff
        .put<void>(`/admin/api/customizable-reports/${analyticsReport.id}`, analyticsReport)
        .then((res) => res.data);

export const patchAnalyticsReport = (reportId: number, patch: Pick<AnalyticsReport, 'exclusiveUserIds'>) =>
    axiosBff.patch<void>(`/admin/api/customizable-reports/${reportId}`, patch).then((res) => res.data);

export type AnalyticsReportFilter = {
    status?: ActiveOrArchived[];
    section?: Section[];
    seatId?: number;
    templateId?: string;
    olapColumn?: string;
    usedAsTemplate?: boolean;
    hasSection?: boolean;
    hasSystemKey?: boolean;
    hasSectionOrSystemKey?: boolean;
    ownerUserId?: number[];
};

export const fetchAnalyticsReports = (params: AnalyticsReportFilter) =>
    axiosBff.get<AnalyticsReport[]>('/admin/api/customizable-reports', { params }).then((res) => res.data);

export const fetchUserVisibleAnalyticsReports = () =>
    axiosBff.get<AnalyticsReport[]>('/admin/api/customizable-reports/user-visible').then((res) => res.data);

export const fetchAnalyticsReport = (id: number) =>
    axiosBff
        .get<AnalyticsReportWithSettings>(`/admin/api/customizable-reports/${id}`)
        .then((res) => setDefaults(res.data));

export const assignAnalyticsReports = (
    params: { userId: number } | { seatId: number },
    reportIds: number[]
) =>
    axiosBff
        .put<void>('/admin/api/customizable-reports/assignments', { reportIds }, { params })
        .then((res) => res.data);

export const fetchAssignedAnalyticsReports = (params: { userId: number } | { seatId: number }) =>
    axiosBff
        .get<{ reportIds: number[] }>('/admin/api/customizable-reports/assignments', { params })
        .then((res) => res.data.reportIds);
