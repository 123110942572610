import { Alert, Button } from 'reactstrap';
import { mapValues } from 'lodash-es';
import { AlgorithmSettingsProps } from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettings';
import AlgorithmSettingsTable, {
    AlgorithmSettingsValue,
} from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettingsTable';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import { DataTypes } from 'platform/common/components/InlineEditInput/InlineEditInput';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { getValueType } from './AlgorithmSettingsValueEdit';

type Settings = Record<string, AlgorithmSettingsValue>;

const parseJSON = (settings: string) => {
    try {
        return JSON.parse(settings);
    } catch (e) {
        return undefined;
    }
};

const getDefaultValue = (dataType: DataTypes) => {
    switch (dataType) {
        case DATA_TYPES.FLOAT:
        case DATA_TYPES.INT:
            return 0;
        case DATA_TYPES.BOOLEAN:
            return false;
        case DATA_TYPES.ARRAY_OF_STRINGS:
            return [];
        default:
            return '';
    }
};

const getNewSettings = (settings: Settings[]) =>
    settings.length
        ? mapValues(settings[0], (v) => getDefaultValue(getValueType(v)))
        : {
              Key: '',
          };

const AlgorithmSettingsEdit = ({ settings, hasMultipleSettings, onChange }: AlgorithmSettingsProps) => {
    const settingsJSON = settings ? parseJSON(settings) : [];
    const items: Settings[] = Array.isArray(settingsJSON) ? settingsJSON : [settingsJSON];

    const handleChange = (value: Settings[]) => {
        onChange(JSON.stringify(value, null, 4));
    };

    const handleUpdate = (value: Settings, index: number) => {
        const newSettings = items.map((item, i) => (i === index ? value : item));
        handleChange(newSettings);
    };

    const handleRemove = (index: number) => {
        const newSettings = items.filter((_, i) => i !== index);
        handleChange(newSettings);
    };

    if (!settingsJSON) {
        return <Alert color="danger">Settings are not valid JSON. Please check settings in JSON tab</Alert>;
    }

    return (
        <>
            {items.map((item, index) => (
                <ControlledCard
                    className="squared mb-3"
                    bodyClassName="p-0"
                    key={index}
                    title={`Settings ${index + 1}`}
                    onRemove={() => handleRemove(index)}
                >
                    <AlgorithmSettingsTable
                        settings={item}
                        onChange={(value) => handleUpdate(value, index)}
                    />
                </ControlledCard>
            ))}
            {(hasMultipleSettings || !items.length) && (
                <Button
                    className="ms-auto mb-3"
                    onClick={() => handleChange([...items, getNewSettings(items)])}
                >
                    Add settings
                </Button>
            )}
        </>
    );
};

export default AlgorithmSettingsEdit;
