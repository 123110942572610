import { useImageUpload } from 'platform/adminPanel/hooks/useImageUpload';
import ChatAgentSystemMessageField from 'platform/chat/admin/AdminChatAgentList/ChatAgentSystemMessageField';
import { getModelEndpoints } from 'platform/chat/chat.service';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import UploadInput from 'platform/common/components/UploadInput/UploadInput';
import { ACTIVE_OR_ARCHIVED } from 'platform/common/constants/status.constant';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required } from 'platform/common/utils/validators.util';
import FormAdvertiserSelect from 'platform/formik/FormAdvertiserSelect/FormAdvertiserSelect';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormInputWithUrlPreview from 'platform/formik/FormInputWithUrlPreview/FormInputWithUrlPreview';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { FormTextEditor } from 'platform/formik/FormTextEditor/FormTextEditor';
import { ChatAgentFormModel, ModelEndpoint } from '../../chat.types';
import ChatValueField from './ChatValueField';

type Props = FormProps<ChatAgentFormModel> & { id?: number };

const ChatAgentForm = ({
    id,
    labels,
    onCancel,
    formikProps: { submitForm, values, setFieldValue },
    canEdit,
    isEdit,
}: Props) => {
    const { handleImageUpload, uploadingImage } = useImageUpload();

    const clearSystemOnly = () => {
        setFieldValue('systemOnly', false);
    };

    const [{ loading, data: models }] = usePromise<ModelEndpoint[]>(
        [],
        () => getModelEndpoints({ status: ['ACTIVE'] }),
        []
    );

    const modelOptions = models
        .map((model) => ({ label: `${model.name} (${model.maxInputTokens} tokens)`, value: model.id }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return (
        <CardForm
            title={`${labels.prefix} DeeplyAI Agent`}
            subtitle={id ? `ID: ${id}` : null}
            submitLabel={labels.submit}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General">
                <FormRow label="Name">
                    <FormInput name="name" validate={required} />
                </FormRow>
                <FormRow label="Status">
                    <FormSelect name="status" options={ACTIVE_OR_ARCHIVED} validate={required} />
                </FormRow>
                <FormRow label="Advertiser">
                    <FormAdvertiserSelect
                        name="advertiserIds"
                        isMulti
                        closeMenuOnSelect={false}
                        isClearable
                    />
                </FormRow>
                <FormRow label="Order">
                    <FormNumberInput name="order" validate={required} isClearable={false} />
                </FormRow>
                <FormRow label="Model">
                    <FormSelect
                        name="modelId"
                        options={modelOptions}
                        isLoading={loading}
                        validate={required}
                    />
                </FormRow>
                <FormRow label="Icon Url">
                    <FormInputWithUrlPreview name="iconUrl" value={values.iconUrl} validate={required} />
                </FormRow>
                <FormRow>
                    <UploadInput
                        acceptableMimeTypes="image/jpeg, image/png, image/svg+xml"
                        subtitle="Upload jpeg/png/svg"
                        onFileUpload={(file, uploadProgressProps) =>
                            handleImageUpload({
                                file,
                                uploadProgressProps,
                                onUpload: (fileName) => setFieldValue('iconUrl', fileName),
                            })
                        }
                        loading={uploadingImage}
                    />
                </FormRow>
                <ChatValueField />
                <ChatAgentSystemMessageField showAvailablePlaceholders={!isEdit} />
                <FormRow label="Welcome message">
                    <FormInput name="message" type="textarea" rows={5} />
                </FormRow>
                <FormRow label="Blacklisted words">
                    <FormInput name="blacklistedWords" type="textarea" rows={5} />
                </FormRow>
                {values.message && (
                    <FormRow label="Show prompts in welcome message">
                        <FormSwitch name="showPromptsInWelcomeMessage" onChange={() => clearSystemOnly()} />
                    </FormRow>
                )}
                <FormRow label="Default">
                    <FormSwitch name="defaultAgent" onChange={() => clearSystemOnly()} />
                </FormRow>
                <FormRow label="Internal only">
                    <FormSwitch name="adminOnly" onChange={() => clearSystemOnly()} />
                </FormRow>
                <FormRow label="System only">
                    <FormSwitch
                        name="systemOnly"
                        onChange={() => {
                            setFieldValue('defaultAgent', false);
                            setFieldValue('adminOnly', false);
                            setFieldValue('personalPrompts', false);
                            setFieldValue('showPromptsInWelcomeMessage', false);
                        }}
                    />
                </FormRow>
                <FormRow label="Beta agent">
                    <FormSwitch name="betaTesting" />
                </FormRow>
                <FormRow label="Requires advertiser">
                    <FormSwitch name="requiresAdvertiser" />
                </FormRow>
                <FormRow label="Personal prompts">
                    <FormSwitch name="personalPrompts" onChange={() => clearSystemOnly()} />
                </FormRow>
            </ControlledCard>

            <ControlledCard bodyClassName="p-0" title="Description">
                <FormTextEditor name="description" />
            </ControlledCard>
        </CardForm>
    );
};

export default ChatAgentForm;
