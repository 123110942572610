import { Button, Card, CardBody, Form } from 'reactstrap';
import { Formik } from 'formik';
import FormRow from 'platform/common/components/FormRow/FormRow';
import useToggle from 'platform/common/hooks/useToggle';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

interface Props {
    onCustomEventCreate: (params: { name: string; variable: string }) => any;
}

interface Values {
    name: string;
    variable: string;
}

const NewCustomEventPanel = ({ onCustomEventCreate }: Props) => {
    const [showForm, toggleShowForm] = useToggle(false);

    return (
        <>
            <div className="mb-3">
                <Button color="secondary" onClick={() => toggleShowForm()}>
                    Add custom event
                </Button>
            </div>
            {showForm && (
                <Formik<Values> initialValues={{ name: '', variable: '' }} onSubmit={onCustomEventCreate}>
                    {({ submitForm }) => (
                        <Form>
                            <Card color="secondary">
                                <CardBody>
                                    <div>
                                        <FormRow label="Name">
                                            <FormInput name="name" maxLength={30} validate={required} />
                                        </FormRow>
                                        <FormRow label="Variable">
                                            <FormInput name="variable" maxLength={30} validate={required} />
                                        </FormRow>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button color="secondary" onClick={() => toggleShowForm()}>
                                            Cancel
                                        </Button>
                                        <Button color="primary" className="ms-2" onClick={submitForm}>
                                            Add
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default NewCustomEventPanel;
