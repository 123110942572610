import { useDispatch, useSelector } from 'react-redux';
import { taskActions, taskSelectors } from 'platform/task/reducers/task.duck';
import { getTaskBoards } from 'platform/task/task.service';

export const useActiveTaskBoards = () => {
    const activeTaskBoards = useSelector(taskSelectors.activeBoards);
    const dispatch = useDispatch();

    return {
        activeTaskBoards,
        reloadActiveTaskBoards: (advertiserId: number) =>
            getTaskBoards({ advertiserId, status: ['ACTIVE'] })
                .then((boards) => dispatch(taskActions.setActiveBoards(boards)))
                .then((action) => action.payload),
    };
};
