import { useEffect, useRef, KeyboardEvent, useState } from 'react';
import { Input } from 'reactstrap';
import { Boundary } from '@popperjs/core';
import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';
import { FileInfo } from 'platform/common/utils/file.util';
import { useChatLanguageContext } from '../ChatProviders/ChatLanguageProvider';
import { useChatMessageContext } from '../ChatProviders/ChatMessageProvider';
import { MessagePrompt } from '../chat.types';
import { mapChatValidatedFiles, useChatFileUpload } from '../useChatFileUpload';
import ChatAttachment from './ChatAttachment';
import ChatFileUploadButton from './ChatFileUploadButton';
import ChatLanguageSelect from './ChatLanguageSelect';
import ChatPromptsButton from './ChatPromptsButton/ChatPromptsButton';

interface Props {
    className?: string;
    iconClassName?: string;
    chatBoundary: Boundary | null;
    uploadedFiles: File[];
    setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const ChatInput = ({ className, iconClassName, chatBoundary, uploadedFiles, setUploadedFiles }: Props) => {
    const { language } = useChatLanguageContext();
    const [input, setInput] = useState('');
    const ref = useRef<HTMLInputElement | null>(null);
    const { submit, submitLoading, messages } = useChatMessageContext();

    const { uploadMultipleFiles } = useChatFileUpload();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = '0px';
            const scrollHeight = ref.current.scrollHeight;

            ref.current.style.height = `${scrollHeight}px`;
        }
    }, [ref, input]);

    const handleSubmit = (text: string, prompt?: MessagePrompt, fileName?: string) => {
        if (!text && !uploadedFiles.length) return;

        if (uploadedFiles.length > 0) {
            uploadMultipleFiles(uploadedFiles, (fileInfo: FileInfo[]) => {
                const filesData = fileInfo.map((file: FileInfo) => mapChatValidatedFiles(file));

                const combinedMessages = [
                    { text, prompt, fileName, languageKey: language, withContent: true, content: filesData },
                ];
                submit(combinedMessages);
            });
        } else {
            submit([{ text, prompt, fileName, languageKey: language }]);
        }

        setInput('');
        setUploadedFiles([]);
    };

    const handleInputEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            handleSubmit(input);
        } else if (e.key === 'ArrowUp' && input === '') {
            const latestMessageNotInInput = messages
                .filter((m) => m.role === 'user')
                .find(
                    (_, i, msgs) => i === msgs.length - 1 || input === msgs[i + 1].contentParts?.[0]?.content
                );

            setInput(latestMessageNotInInput?.contentParts?.[0]?.content ?? input);
        }
    };

    const handleFileDelete = (index: number) => {
        const newFiles = [...uploadedFiles];
        newFiles.splice(index, 1);
        setUploadedFiles(newFiles);
    };

    return (
        <div className={className}>
            <Input
                type="textarea"
                innerRef={ref}
                placeholder="Your question here..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleInputEnterPress}
            />

            {uploadedFiles.length > 0 && (
                <div className="p-2 d-flex gap-2 flex-wrap">
                    {uploadedFiles.map((attachment: File, index: number) => (
                        <ChatAttachment
                            key={`chatAttachment-${index}`}
                            attachment={attachment}
                            onDelete={() => handleFileDelete(index)}
                        />
                    ))}
                </div>
            )}
            <div className="px-1 pb-2 d-flex w-100 justify-content-end">
                <ChatLanguageSelect />
                <ChatPromptsButton chatBoundary={chatBoundary} />
                <ChatFileUploadButton setUploadedFiles={setUploadedFiles} />
                <IconButton
                    disabled={submitLoading || (!input.length && !uploadedFiles.length)}
                    className={iconClassName}
                    onClick={() => handleSubmit(input)}
                >
                    <i
                        className={classNames(
                            'far fa-paper-plane-top fs-5',
                            input.length || uploadedFiles.length ? 'text-primary' : 'text-light-slate-gray'
                        )}
                    />
                </IconButton>
            </div>
        </div>
    );
};

export default ChatInput;
