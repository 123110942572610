import { ReactNode } from 'react';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
    children: ReactNode;
}

const TextWithEllipsis = ({ children }: Props) => (
    <Tooltip className="w-100" renderTooltip={() => children}>
        <div
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textAlign: 'left' }}
        >
            {children}
        </div>
    </Tooltip>
);

export default TextWithEllipsis;
