import { usePromise } from 'platform/common/hooks/usePromise';
import { sortByName } from 'platform/common/utils/array.util';
import { fetchUsers } from 'platform/userManagement/services/userManagement.service';
import { User } from 'platform/userManagement/types/user.type';

export const useAdvertiserUsers = (accessibleAdvertiserId: number | undefined): User[] => {
    const [{ data: users }] = usePromise(
        [],
        async () =>
            accessibleAdvertiserId
                ? sortByName(await fetchUsers({ states: ['ACTIVE'], accessibleAdvertiserId }))
                : [],
        [accessibleAdvertiserId]
    );
    return users;
};
