import { sortBy } from 'lodash-es';
import { COLUMN_TYPE_OPTIONS, ColumnDefinition, ColumnType } from 'platform/analytics/analytics.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import SelectOptionWithTooltip from 'platform/common/components/Select/SelectOptionWithTooltip';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { usePromise } from 'platform/common/hooks/usePromise';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { fetchLabelOverrides } from './labelOverrides.service';
import { LabelOverride } from './labelOverrides.types';

export interface LabelOverrideProps {
    advertiserId: number;
    metrics: ColumnDefinition[];
    dimensions: ColumnDefinition[];
    keyTyMetricName: { [key: string]: string };
    keyToDimensionName: { [key: string]: string };
}

export type LabelOverrideModel = LabelOverride & LabelOverrideProps;

const defaultOptions = {
    metricOptions: [],
    dimensionOptions: [],
};

const LabelOverridesForm = ({
    onCancel,
    labels,
    canEdit,
    formikProps: { initialValues, submitForm, values },
}: FormProps<LabelOverrideModel>) => {
    const { metrics, dimensions, advertiserId, systemName, keyTyMetricName, keyToDimensionName } =
        initialValues;
    const isEdit = !!initialValues.id;

    const [{ data: options }] = usePromise(defaultOptions, async () => {
        if (advertiserId) {
            const overrides = await fetchLabelOverrides(advertiserId);
            const keys = overrides.map((o) => o.systemName);

            const predicate = (m: ColumnDefinition) => {
                const keyIsOverridden = keys.find((k) => k === m.key)!!;
                const editableItemKey = isEdit && m.key === systemName;
                return !keyIsOverridden || editableItemKey;
            };
            const availableMetrics = metrics.filter(predicate);
            const availableDimensions = dimensions.filter(predicate);

            return {
                metricOptions: availableMetrics.map(
                    (m): ColumnDefinition => ({ ...m, name: keyTyMetricName[m.key] || '-' })
                ),
                dimensionOptions: availableDimensions.map(
                    (d): ColumnDefinition => ({ ...d, name: keyToDimensionName[d.key] || '-' })
                ),
            };
        }
        return defaultOptions;
    });

    const systemNameOptions = (type?: ColumnType): ColumnDefinition[] =>
        sortBy(type === 'METRIC' ? options.metricOptions : options.dimensionOptions, (o) =>
            o.name.toLowerCase()
        );

    return (
        <CardForm
            title={isEdit ? 'Edit Label' : `New Label`}
            subtitle={isEdit && `ID: ${initialValues.id}`}
            submitLabel={labels.submit}
            animationDelay={0}
            onCancel={onCancel}
            disabled={!canEdit}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info">
                <FormRow label="Type">
                    <FormSelect
                        name="type"
                        options={COLUMN_TYPE_OPTIONS}
                        validate={required}
                        isClearable={false}
                    />
                </FormRow>
                <FormRow label="Default name">
                    <FormSelect<ColumnDefinition>
                        name="systemName"
                        options={systemNameOptions(values.type)}
                        getOptionValue={(o) => o.key}
                        getOptionLabel={(o) => o.name}
                        validate={required}
                        filterOption={(data, search) =>
                            data.label.toLowerCase().includes(search.toLowerCase()) ||
                            data.value.toLowerCase().includes(search.toLowerCase())
                        }
                        components={{ Option: SelectOptionWithTooltip }}
                    />
                </FormRow>
                <FormRow label="Advertiser-specific name">
                    <FormInput name="alias" validate={required} />
                </FormRow>
            </ControlledCard>
        </CardForm>
    );
};

export default LabelOverridesForm;
