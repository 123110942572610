import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import { getCampaignImportDetailsUrl } from 'platform/campaign/campaign.navigation';
import campaignService from 'platform/campaign/campaign/services/campaign.service';
import { IntegrationType } from 'platform/campaign/strategy/constants/integrationTypes.constant';
import { assertIsDefined } from 'platform/common/common.assert';
import {
    IntegrationDetailsIcon,
    IntegrationSummary,
} from 'platform/common/components/IntegrationDetailsIcon/IntegrationDetailsIcon';
import { IMPORTED_INTEGRATIONS } from 'platform/common/constants/externalIntegration.constant';

type CampaignIntegrationSummary = IntegrationSummary & {
    campaignId: number;
    advertiserId: number;
    externalAdvertiserId?: string;
    type: IntegrationType;
};

type Props = {
    integration: CampaignIntegrationSummary;
};

const openImportPanel = async (
    dispatch: Dispatch,
    { externalId, advertiserId, externalSystem, externalAdvertiserId }: CampaignIntegrationSummary
) => {
    assertIsDefined(externalId, 'externalId');
    assertIsDefined(externalAdvertiserId, 'externalAdvertiserId');
    dispatch(
        push(getCampaignImportDetailsUrl(externalId, { advertiserId, externalSystem, externalAdvertiserId }))
    );
};

export const CampaignIntegrationIcon = ({ integration }: Props) => {
    const dispatch = useDispatch();
    return (
        <IntegrationDetailsIcon
            syncEntityType={'CAMPAIGN'}
            internalId={integration.campaignId}
            integration={integration}
            onImport={
                IMPORTED_INTEGRATIONS.includes(integration.externalSystem) &&
                integration.type === 'RTB' &&
                integration.externalId
                    ? () => openImportPanel(dispatch, integration)
                    : undefined
            }
            onResync={() => campaignService.syncCampaign(integration.campaignId, integration.externalSystem)}
        />
    );
};
