import { monetizationToApi, monetizationToForm } from 'platform/campaign/campaign/mappers/campaign.mapper';
import {
    GlobalCostSourceApiModel,
    GlobalCostSourcePreset,
} from 'platform/campaign/globalPreset/globalPreset.types';

export const toApiModel = (preset: GlobalCostSourcePreset): GlobalCostSourceApiModel => ({
    ...preset,
    channelDefault: undefined,
    channel: preset.channel ? preset.channel : undefined,
    productLabel: preset.productLabel ? preset.productLabel.value : undefined,
    monetization: monetizationToApi(preset.monetization),
});

export const toPreset = (apiModel: GlobalCostSourceApiModel): GlobalCostSourcePreset => ({
    ...apiModel,
    monetization: monetizationToForm(apiModel.monetization || {}),
    productLabel: apiModel.productLabel
        ? { label: apiModel.productLabel, value: apiModel.productLabel }
        : undefined,
});
