import { useEffect } from 'react';
import { AppChildren, useAppContext } from 'platform/app/AppContext';

export const useSetAppHeader = (components: AppChildren) => {
    const [, setAppHeader] = useAppContext();

    useEffect(() => {
        setAppHeader(components);
        return () => setAppHeader({ AppHeaderComponent: undefined });
    }, []);
};
