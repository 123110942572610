import { useState } from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import FormRow from 'platform/common/components/FormRow/FormRow';
import Separator from 'platform/common/components/Separator/Separator';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { FileUploadProgressProps } from 'platform/common/hooks/useProgress';
import { FileInfo } from 'platform/common/utils/file.util';
import { maxFileSize, required, url } from 'platform/common/utils/validators.util';
import CreativeRuntimeFields from 'platform/creatives/components/CreativeBlocks/CreativeRuntimeFields';
import LandingPageFields from 'platform/creatives/components/CreativeBlocks/LandingPageFields';
import { GcmVideoCreativeModel } from 'platform/creatives/mappers/creative/gcmVideoCreative.mapper';
import { uploadExternalAsset } from 'platform/creatives/services/asset.service';
import { ConceptModel } from 'platform/creatives/types/creative.types';
import { ValidatorResult } from 'platform/creatives/utils/creativeValidate.utils';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import FormUploadInput from 'platform/formik/FormUploadInput/FormUploadInput';
import { CREATIVE_FORM_HELP_KEY } from '../CreativeForm/CreativeFormContainer';
import CreativeLabelSelect from './CreativeLabelSelect';

type Props = {
    field: string;
    advertiserId: number;
};

export const gcmVideoCreativeValidator = (): ValidatorResult<GcmVideoCreativeModel> => ({
    name: required,
    clickThroughUrl: [required, url],
    externalVideoAsset: [required, maxFileSize(1024)],
});

const GcmVideoCreativeFields = ({ field, advertiserId }: Props) => {
    const { values, setFieldValue } = useFormikContext<ConceptModel>();
    const [assetLoading, setAssetLoading] = useState(false);
    const creative = get(values, field);
    const { id, externalAssetId, scheduling } = creative;

    return (
        <>
            <FormRow label="Name" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_name`}>
                <FormInput name={`${field}.name`} type="text" />
            </FormRow>
            {id && (
                <FormRow label="Internal ID" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_id`}>
                    <FormInput name={`${field}.id`} type="text" disabled />
                </FormRow>
            )}
            <LandingPageFields field={field} />
            <FormRow label="Video file" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_file`}>
                {externalAssetId && (
                    <div className="mt-2 mb-3">
                        {getIntegrationLabel('GCM')} asset: <b>{externalAssetId}</b>
                    </div>
                )}
                <FormUploadInput
                    name={`${field}.externalVideoAsset`}
                    title={externalAssetId ? 'Upload new' : 'Upload'}
                    subtitle="Supported file types: .mp4 and .mov"
                    loading={assetLoading}
                    disabled={assetLoading}
                    acceptableMimeTypes="video/mp4, application/mp4, video/quicktime"
                    readFileAs="blob"
                    onFileUpload={async (file: FileInfo, uploadProgressProps?: FileUploadProgressProps) => {
                        setAssetLoading(true);

                        const response = await uploadExternalAsset(
                            { advertiserId },
                            file.content as Blob,
                            uploadProgressProps?.onUploadProgress
                        )
                            .then(({ externalId }) => {
                                setFieldValue(`${field}.externalAssetId`, externalId);
                            })
                            .catch(() => {
                                setFieldValue(`${field}.externalAssetId`, null);
                                setFieldValue(`${field}.externalVideoAsset`, null);
                                return 'failed';
                            })
                            .finally(() => {
                                uploadProgressProps?.resetProgress();
                                setAssetLoading(false);
                            });

                        return response;
                    }}
                />
            </FormRow>

            <Separator label="Optional" />

            <FormRow label="Weight (0 - 10 000)" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_weight`}>
                <FormNumberInput name={`${field}.weight`} />
            </FormRow>
            <FormRow label="Creative label" helpKey={`${CREATIVE_FORM_HELP_KEY}_creative_labelId`}>
                <CreativeLabelSelect name={`${field}.creativeLabelId`} advertiserId={advertiserId} />
            </FormRow>
            <FormRow label="Scheduling" helpKey={`${CREATIVE_FORM_HELP_KEY}_scheduling`}>
                <FormSwitch name={`${field}.scheduling`} />
            </FormRow>
            {scheduling && <CreativeRuntimeFields field={`${field}.runtime`} />}
        </>
    );
};

export default GcmVideoCreativeFields;
