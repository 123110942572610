import { Button } from 'reactstrap';
import { FieldArray, FormikProps } from 'formik';
import { partition, uniqueId } from 'lodash-es';
import VendorBadges from 'platform/advertiserTag/components/ThirdPartyRules/VendorBadges';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import LabelLink from 'platform/common/components/LabelLink/LabelLink';
import { entitiesToOptions } from 'platform/common/utils/option.util';
import { nonNegativeNumber, required } from 'platform/common/utils/validators.util';
import FormButtonArray from 'platform/formik/FormButtonArray/FormButtonArray';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormNumberInput from 'platform/formik/FormNumberInput/FormNumberInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { Vendor } from 'platform/vendors/vendors.types';
import {
    Segment,
    SnippetType,
    StandardRuleSnippetModel,
    ThirdPartyCodesFormValues,
    ThirdPartyTrackingRuleModel,
} from '../../advertiserTag.types';
import { FIRE_PIXEL_OPTIONS, FirePixelType } from '../../constants/advertiserTag.constant';
import { THIRD_PARTY_CODES_FORM_RULE_HELP_KEY } from '../ThirdPartyCodesForm/ThirdPartyCodesForm';
import ThirdPartyRulesCodeSnippets from './ThirdPartyRulesCodeSnippets';

type Props = {
    rule: ThirdPartyTrackingRuleModel;
    index: number;
    vendors: Vendor[];
    segments: Segment[];
    onRemove?: (index: number) => void;
    setFieldValue: FormikProps<ThirdPartyCodesFormValues>['setFieldValue'];
};

const RuleBuilderRule = (props: Props) => {
    const { rule, onRemove, index, vendors = [], segments = [], setFieldValue } = props;
    const fieldBase = `rules.${index}`;
    const readOnly = rule.ruleSource !== 'RULE_BUILDER';
    const isFiringOnCustomSegments = rule.firePixels === FirePixelType.CUSTOM;
    const segmentOptions = entitiesToOptions(segments);

    const toggleAllIncludedEvents = () => {
        setFieldValue(
            `${fieldBase}.includedSegments`,
            segments.map((item) => item.id)
        );
    };

    const toggleAllExcludedEvents = () => {
        setFieldValue(
            `${fieldBase}.excludedSegments`,
            segments.map((item) => item.id)
        );
    };

    const [activeSnippets, inactiveSnippets] = partition(
        rule.codeSnippets,
        (s) => (s as StandardRuleSnippetModel).active
    );

    const addCodeSnippet = (push: (obj: any) => void) => {
        const codeSnippet: StandardRuleSnippetModel = {
            tempId: uniqueId('ThirdPartyRule-CodeSnippet'),
            type: 'JAVASCRIPT_HTML',
            codeIntegralPart: '',
            snippetType: SnippetType.STANDARD,
            active: true,
        };
        push(codeSnippet);
    };

    return (
        <ControlledCard
            useCollapse={false}
            key={rule.tempId}
            title={
                <>
                    <div>{rule.ruleName}</div>
                    <VendorBadges
                        label="Active"
                        color={rule.active ? 'primary' : 'secondary'}
                        snippets={activeSnippets}
                        vendors={vendors}
                    />
                    <VendorBadges
                        label="Inactive"
                        color="secondary"
                        snippets={inactiveSnippets}
                        vendors={vendors}
                    />
                </>
            }
            isExpanded={rule.isNew}
            onRemove={!readOnly && onRemove ? () => onRemove(index) : undefined}
        >
            <FormRow label="Name" helpKey={`${THIRD_PARTY_CODES_FORM_RULE_HELP_KEY}_name`}>
                <FormInput name={`${fieldBase}.ruleName`} validate={required} disabled={Boolean(readOnly)} />
            </FormRow>
            <FormRow label="Active" helpKey={`${THIRD_PARTY_CODES_FORM_RULE_HELP_KEY}_active`}>
                <FormSwitch name={`${fieldBase}.active`} disabled={readOnly} />
            </FormRow>
            <FormRow label="Priority" helpKey={`${THIRD_PARTY_CODES_FORM_RULE_HELP_KEY}_priority`}>
                <FormNumberInput name={`${fieldBase}.priority`} validate={nonNegativeNumber} />
            </FormRow>
            <FormRow label="Fire" helpKey={`${THIRD_PARTY_CODES_FORM_RULE_HELP_KEY}_firePixels`}>
                <FormButtonArray
                    name={`${fieldBase}.firePixels`}
                    buttons={FIRE_PIXEL_OPTIONS}
                    disabled={readOnly}
                />
            </FormRow>
            <FormRow
                label="Unique per User / Day"
                helpKey={`${THIRD_PARTY_CODES_FORM_RULE_HELP_KEY}_fireOncePerDay`}
            >
                <FormSwitch name={`${fieldBase}.fireOncePerDay`} />
            </FormRow>
            {isFiringOnCustomSegments && (
                <>
                    <FormRow
                        label={vendors ? 'Include' : ''}
                        helpKey={`${THIRD_PARTY_CODES_FORM_RULE_HELP_KEY}_includedSegments`}
                    >
                        <FormSelect
                            name={`${fieldBase}.includedSegments`}
                            options={segmentOptions}
                            validate={required}
                            disabled={readOnly}
                            isMulti
                        />
                        {!readOnly && (
                            <LabelLink onClick={toggleAllIncludedEvents}>
                                Select all available events
                            </LabelLink>
                        )}
                    </FormRow>
                    <FormRow
                        label={vendors ? 'Exclude' : ''}
                        helpKey={`${THIRD_PARTY_CODES_FORM_RULE_HELP_KEY}_excludedSegments`}
                    >
                        <FormSelect
                            name={`${fieldBase}.excludedSegments`}
                            options={segmentOptions}
                            disabled={readOnly}
                            isMulti
                        />
                        {!readOnly && (
                            <LabelLink onClick={toggleAllExcludedEvents}>
                                Select all available events
                            </LabelLink>
                        )}
                    </FormRow>
                </>
            )}
            <FieldArray name={`${fieldBase}.codeSnippets`}>
                {({ push, remove, form, name }) => (
                    <>
                        <ThirdPartyRulesCodeSnippets
                            fieldName={name}
                            codeSnippets={form.values.rules[index].codeSnippets}
                            readOnly={readOnly}
                            onCodeSnippetRemove={(snippetIndex: number) => remove(snippetIndex)}
                            isFiredAlways={rule.firePixels === FirePixelType.ALWAYS}
                            vendors={vendors}
                            ruleIsActive={rule.active}
                        />
                        {!readOnly && (
                            <div>
                                <Button color="primary" onClick={() => addCodeSnippet(push)}>
                                    Add code
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </FieldArray>
        </ControlledCard>
    );
};

export default RuleBuilderRule;
