import { formatNaturalDate } from 'platform/common/utils/date.util';
import { formatBudget } from 'platform/common/utils/number.util';
import { LogData } from '../../logBook.types';

interface Props {
    oldData?: LogData;
    newData?: LogData;
    comment?: string;
}

const BudgetShiftTooltip = ({ oldData, newData, comment }: Props) => {
    const newBudget = newData?.budget ?? 0;
    const oldBudget = oldData?.budget ?? 0;
    const budgetDelta = newBudget - oldBudget;

    return (
        <>
            {budgetDelta !== 0 && (
                <div>
                    Budget changed: <b>{formatBudget(oldBudget)}</b> -&gt; <b>{formatBudget(newBudget)}</b>
                </div>
            )}
            {oldData?.dateFrom !== newData?.dateFrom && (
                <div>
                    Start date moved: <b>{formatNaturalDate(oldData?.dateFrom)}</b> -&gt;{' '}
                    <b>{formatNaturalDate(newData?.dateFrom)}</b>
                </div>
            )}
            {oldData?.dateTo !== newData?.dateTo && (
                <div>
                    End date moved: <b>{formatNaturalDate(oldData?.dateTo)}</b> -&gt;{' '}
                    <b>{formatNaturalDate(newData?.dateTo)}</b>
                </div>
            )}
            {comment && <div>{comment}</div>}
        </>
    );
};

export default BudgetShiftTooltip;
