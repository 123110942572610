import { useState } from 'react';
import { useSelector } from 'react-redux';
import BubbleGroupChart from 'platform/common/components/BubbleGroupChart/BubbleGroupChart';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InsetBox from 'platform/common/components/InsetBox/InsetBox';
import LabelWithSubtext from 'platform/common/components/LabelWithSubtext/LabelWithSubtext';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import Select from 'platform/common/components/Select/Select';
import StatusBadge from 'platform/common/components/StatusBadge/StatusBadge';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { activeAdvertiserSelectors, AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import SegmentTypeBadge from 'platform/segments/components/SegmentTypeBadge/SegmentTypeBadge';
import { SegmentSimilarity } from 'platform/segments/segments.types';
import { fetchSimilarity } from 'platform/segments/services/segments.service';

type Props = {
    segmentId: number;
};

const columns: TableColumn<SegmentSimilarity>[] = [
    {
        Header: 'Status',
        accessor: 'segment.state',
        maxWidth: 80,
        Cell: ({ value }) => <StatusBadge status={value ? 'ARCHIVED' : 'ACTIVE'} />,
    },
    {
        screenOnly: true,
        Header: 'Type',
        accessor: 'segment.type',
        headerClassName: 'SegmentPage-typeHeader',
        maxWidth: 80,
        Cell: ({ value }) => <SegmentTypeBadge type={value} />,
    },
    {
        Header: 'ID',
        accessor: 'segment.id',
        type: DATA_TYPES.ID,
        maxWidth: 80,
    },
    {
        Header: 'Name/Advertiser',
        type: DATA_TYPES.TEXT,
        accessor: 'segment.name',
        Cell: ({ original }) => (
            <LabelWithSubtext label={original.segment.name} subtext={original.segment.advertiser.name} />
        ),
    },
    {
        Header: 'Uplift',
        type: DATA_TYPES.FLOAT,
        accessor: 'uplift_30d',
        maxWidth: 140,
        Cell: ({ original }) => `${DATA_TYPES.FLOAT.format(original.uplift_30d)}x`,
    },
    {
        Header: 'Similar users, 30d',
        accessor: 'overlapping_unique_users_30d',
        type: DATA_TYPES.INT,
        maxWidth: 140,
    },
    {
        Header: 'Users, 30d',
        accessor: 'segment_unique_users_30d',
        type: DATA_TYPES.INT,
        maxWidth: 140,
    },
    {
        Header: 'Similarity',
        accessor: 'similarity_30d',
        type: DATA_TYPES.P100,
        maxWidth: 140,
    },
];

const SegmentSimilarityChart = ({ segmentId }: Props) => {
    const [selectedAdvertisers, setAdvertisers] = useState<AdvertiserOption[]>([]);
    const activeAdvertiser = useSelector(activeAdvertiserSelectors.activeAdvertiser);
    const advertisers = useSelector(activeAdvertiserSelectors.options);
    const advertiserIds = selectedAdvertisers.map((a) => a.id);

    const [{ loading, data }] = usePromise(
        [],
        () =>
            advertiserIds.length
                ? fetchSimilarity(
                      segmentId,
                      advertiserIds,
                      selectedAdvertisers[0].parentId ?? selectedAdvertisers[0].id
                  )
                : Promise.resolve([]),
        [selectedAdvertisers]
    );

    const advertiserOptions = activeAdvertiser.parentId
        ? advertisers.filter((a) => a.parentId === activeAdvertiser.parentId)
        : [activeAdvertiser];

    return (
        <>
            <div className="fw-bold">Analysis of segments similarity</div>
            <div>Select dimensions to compare with</div>
            <div className="mt-3">
                <Select
                    value={advertiserIds}
                    options={advertiserOptions}
                    isMulti
                    isClearable
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    closeMenuOnSelect={false}
                    returnOnlyValues={false}
                    onChange={setAdvertisers}
                />
            </div>
            {!!selectedAdvertisers.length && (
                <>
                    <hr />
                    <InsetBox className="p-3 mb-3">
                        {loading && <Placeholder height="300px" />}
                        {!loading && (
                            <BubbleGroupChart
                                xKey="similarity_30d"
                                xLabel="Similarity"
                                yKey="segment_unique_users_30d"
                                yLabel="Users, 30d."
                                data={data}
                                getTooltipLabel={(item) => [
                                    `Advertiser: ${item.segment.advertiser.name}`,
                                    `Segment: ${item.segment.name}`,
                                    `ID: ${item.segment.id}`,
                                    `Uplift: ${DATA_TYPES.FLOAT.format(item.uplift_30d)}`,
                                    `Similar Users, 30d: ${item.overlapping_unique_users_30d}`,
                                    `Users, 30d: ${item.segment_unique_users_30d}`,
                                    `Similarity ${DATA_TYPES.FLOAT.format(item.similarity_30d)} %`,
                                ]}
                            />
                        )}
                    </InsetBox>
                    <FormattedTable
                        exportData={{ filename: 'similarity' }}
                        columns={columns}
                        data={data}
                        loading={loading}
                    />
                </>
            )}
        </>
    );
};

export default SegmentSimilarityChart;
