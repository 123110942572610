import classNames from 'classnames';
import './LabelWithSubtext.scss';

interface Props {
    label?: string;
    subtext?: string;
    className?: string;
    nativeTooltipText?: string;
}

const LabelWithSubtext = ({ label = '', subtext = '-', className, nativeTooltipText }: Props) => (
    <div className={classNames('LabelWithSubtext', className)}>
        <span title={nativeTooltipText ?? label}>{label}</span>
        <br />
        <span className="LabelWithSubtext-subtext" title={subtext}>
            {subtext}
        </span>
    </div>
);

export default LabelWithSubtext;
