import { useDrag, useDrop } from 'react-dnd';

interface Props<T> {
    id: string;
    item: T;
    canDrag?: boolean;
    canDrop?: boolean;
    onDrop: (moveFrom: T, moveTo: T) => void;
}

export const useSortable = <T>({ id, item, canDrag = true, canDrop = true, onDrop }: Props<T>) => {
    const [{ isOver }, dropRef] = useDrop<T, void, { isOver: boolean }>({
        accept: id,
        collect(monitor) {
            return {
                isOver: monitor.isOver(),
            };
        },
        canDrop: () => canDrop,
        drop(dragItem) {
            onDrop(dragItem, item);
        },
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag<T, void, { isDragging: boolean }>({
        type: id,
        item,
        canDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return {
        sortableDragRef: dragRef,
        sortableDropRef: (instance: HTMLDivElement | null) => previewRef(dropRef(instance)),
        sortableRef: (instance: HTMLDivElement | null) => dragRef(previewRef(dropRef(instance))),
        isOver,
        isDragging,
    };
};
