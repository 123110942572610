import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { authActions, authSelectors } from 'platform/app/ducks/auth.duck';
import { RootState } from 'platform/rootState.type';
import { getSystemSetting } from './systemSetting.service';

const MaintenanceModeNotification = () => {
    const dispatch = useDispatch();

    const profile = useSelector(authSelectors.ready.profile);
    const { maintenanceModeMessageHidden } = useSelector((state: RootState) => state.session);

    const [message, setMessage] = useState('');
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const { value } = await getSystemSetting('MAINTENANCE_MODE');

            if (value) {
                setMessage(value.message);
                setEnabled(value.enabled);
            }
        };

        fetch();
    }, []);

    if (!enabled || maintenanceModeMessageHidden || !profile.adminUser) {
        return null;
    }

    return (
        <div className="position-sticky" style={{ top: 0, zIndex: 1020 }}>
            <Alert
                color="warning"
                className="font-lg mb-0"
                toggle={() => dispatch(authActions.hideMaintenanceModeMessage())}
            >
                <strong>System Notice:</strong> {message}
            </Alert>
        </div>
    );
};

export default MaintenanceModeNotification;
