import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import { isNil, uniq } from 'lodash-es';
import { StatisticWithSeries, TypeDefinition } from 'platform/common/common.types';
import LinePure from 'platform/common/components/LinePure/LinePure';
import { DATA_TYPES } from 'platform/common/dataTypes';
import { shouldShowFraction, shouldShowMetricPrefix } from 'platform/common/utils/chart.utils';

const chartColor = '#1A90D7';

const METRIC_TYPE_MAP = {
    unique_users_30d: DATA_TYPES.INT,
    unique_users_yesterday: DATA_TYPES.INT,
    all_actions_30d: DATA_TYPES.INT,
    action_probability_30d: DATA_TYPES.P100,
    bounce_rate_30d: DATA_TYPES.P100,
};

interface Stats {
    unique_users_30d: StatisticWithSeries;
    all_actions_30d: StatisticWithSeries;
    action_probability_30d: StatisticWithSeries;
    bounce_rate_30d: StatisticWithSeries;
}

interface Props {
    selectedMetric: string;
    data: { primaryStats: Stats; secondaryStats: Stats };
    loading: boolean;
}

const COMPARE_LABEL = 'Compare Total';

const renderTooltipTitle = (compareLabels: string[]) => (items: TooltipItem<any>[]) =>
    items.map(({ dataset, dataIndex, datasetIndex, label }) => {
        if (!compareLabels || isNil(dataIndex) || isNil(datasetIndex) || dataset.label !== COMPARE_LABEL) {
            return String(label);
        }

        return compareLabels[dataIndex];
    });

const generateChartByDayOptions = (
    type: TypeDefinition,
    compareLabels: string[],
    useMetricPrefix: boolean,
    showFraction: boolean
): ChartOptions<'line'> => ({
    animation: false,
    hover: { intersect: false },
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            cornerRadius: 3,
            caretSize: 6,
            intersect: false,
            mode: 'index',
            caretPadding: 10,
            callbacks: {
                label: (tooltipItem) => type.format(tooltipItem.raw),
                title: renderTooltipTitle(compareLabels),
            },
        },
    },
    layout: {
        padding: {
            left: 16,
            right: 24,
            top: 24,
            bottom: 24,
        },
    },
    elements: {
        point: { radius: 0, hoverRadius: 4 },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                tooltipFormat: 'yyyy-MM-DD', // Format for tooltip display
                displayFormats: {
                    hour: 'MMM D', // Display format on the X-axis ticks
                },
            },
            ticks: {
                maxRotation: 0, // Sets the angle of the label text to 0 degrees
                autoSkip: true, // Automatically skips labels to avoid overlapping
                maxTicksLimit: 6, // Maximum number of ticks to show
            },
        },

        y: {
            beginAtZero: true,
            suggestedMin: 0,
            ticks: {
                callback: (label) => type.format(label, { useMetricPrefix, showFraction }),
            },
        },
    },
});

const SegmentChart = ({ data, selectedMetric }: Props) => {
    const type = METRIC_TYPE_MAP[selectedMetric as keyof typeof METRIC_TYPE_MAP];
    const chartMetric = selectedMetric === 'unique_users_30d' ? 'unique_users_yesterday' : selectedMetric;
    const series = data.primaryStats[chartMetric as keyof Stats].series;
    const compareSeries = data.secondaryStats[chartMetric as keyof Stats].series;

    if (!series || !compareSeries) {
        return null;
    }

    const chartData: ChartData<'line'> = {
        labels: uniq(series.labels),
        datasets: [
            {
                data: [...series.values],
                label: 'Total',
                fill: false,
                borderColor: chartColor,
                borderWidth: 2,
                pointHoverBackgroundColor: chartColor,
            },
            {
                data: [...compareSeries.values],
                label: COMPARE_LABEL,
                fill: false,
                borderColor: chartColor,
                borderWidth: 1,
                borderDash: [10, 10],
            },
        ],
    };

    return (
        <div className="px-2 border-top">
            <LinePure
                data={chartData}
                height={300}
                options={generateChartByDayOptions(
                    type,
                    compareSeries.labels,
                    shouldShowMetricPrefix(chartData.datasets as { data: number[] }[]),
                    shouldShowFraction(chartData.datasets as { data: number[] }[])
                )}
            />
        </div>
    );
};

export default SegmentChart;
