import { useSelector } from 'react-redux';
import { ActiveAdvertiserProps } from 'platform/app/AppContext';
import ActiveAdvertiserSelect from 'platform/common/containers/ActiveAdvertiser/ActiveAdvertiserSelect';
import { activeAdvertiserSelectors } from '../../ducks/activeAdvertiser.duck';

const ActiveAdvertisersAppHeader = ({ onAdvertisersChange }: ActiveAdvertiserProps) => {
    const options = useSelector(activeAdvertiserSelectors.options);
    const activeAdvertisers = useSelector(activeAdvertiserSelectors.activeAdvertisers);
    const activeAdvertiserIds = useSelector(activeAdvertiserSelectors.ids);
    const loading = useSelector(activeAdvertiserSelectors.loading);

    return (
        <ActiveAdvertiserSelect
            value={activeAdvertiserIds}
            options={options}
            isLoading={loading}
            isMulti
            onChange={onAdvertisersChange}
        >
            {activeAdvertisers.length === 1 || activeAdvertiserIds.length !== options.length
                ? activeAdvertisers.map((v) => v.name).join(', ')
                : 'All'}
        </ActiveAdvertiserSelect>
    );
};
export default ActiveAdvertisersAppHeader;
