import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { classifierActions } from 'platform/common/ducks/commonClassifiers.duck';
import { fetchChannel, fetchChannels, saveChannel } from '../channel.service';
import { Channel } from '../channel.types';
import ChannelForm from './ChannelForm';

const NEW_CHANNEL: Channel = {
    code: '',
    name: '',
    status: 'ACTIVE',
    mediaType: 'DIGITAL',
};

const ChannelFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const code = useParams().code;
    const dispatch = useDispatch();

    const handleSubmit = async (channel: Channel) => {
        await saveChannel(channel);
        dispatch(classifierActions.setChannels(await fetchChannels()));
    };

    return (
        <FormContainer
            helpKey="channel_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={!!code}
            sidePanel
            onOpen={async () => (code ? fetchChannel(code) : NEW_CHANNEL)}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <ChannelForm {...props} />}
        </FormContainer>
    );
};

export default ChannelFormContainer;
