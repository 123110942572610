import memoizeOne from 'memoize-one';
import { createSelector } from 'reselect';
import { Accessor } from 'platform/common/components/FormattedTable/FormattedTable';
import { filterTreeByQuery } from '../../common/utils/search.util';
import { Advertiser } from '../advertiser.types';

const filterTreeByQueryMemoized = createSelector(
    ({ advertisers }: { advertisers: Advertiser[] }) => advertisers,
    ({ query }: { query: string }) => query,
    ({ accessors }: { accessors: Accessor[] }) => accessors,
    (advertisers, query, accessors) => filterTreeByQuery(advertisers, query, 'children', accessors)
);

export const filterAdvertiserTree = memoizeOne(
    (advertisers: Advertiser[], query: string, accessors: Accessor[]) => {
        if (!advertisers || !advertisers.length) return [];
        return query.length ? filterTreeByQueryMemoized({ advertisers, query, accessors }) : advertisers;
    }
);
