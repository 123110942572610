import { ReactNode } from 'react';
import { Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import classNames from 'classnames';
import ErrorBoundary from 'platform/common/containers/ErrorBoundary/ErrorBoundary';
import AnimatedButton from '../AnimatedButton/AnimatedButton';
import FormHeader from '../FormHeader/FormHeader';
import './CardForm.scss';

interface Props {
    title: string;
    subtitle?: ReactNode;
    headerContent?: ReactNode;
    disabled?: boolean;
    submitLabel?: string;
    cancelLabel?: string;
    animationDelay?: number;
    formClassname?: string;
    cardBodyClassname?: string;
    children: ReactNode;
    onCancel: () => void;
    onSubmit?: (val?: any) => Promise<void>;
}

const CardForm = ({
    title,
    subtitle,
    headerContent,
    disabled,
    submitLabel = 'Update',
    cancelLabel = 'Cancel',
    animationDelay,
    formClassname = '',
    cardBodyClassname = '',
    children,
    onCancel,
    onSubmit,
}: Props) => (
    <Form className={classNames('h-100', formClassname)}>
        <Card className="CardForm h-100">
            <CardHeader>
                <FormHeader title={title} subtitle={subtitle} content={headerContent}>
                    <Button color="secondary" onClick={onCancel}>
                        {cancelLabel}
                    </Button>
                    {onSubmit && (
                        <AnimatedButton
                            disabled={disabled}
                            onClick={onSubmit}
                            animationDelay={animationDelay}
                        >
                            {submitLabel}
                        </AnimatedButton>
                    )}
                </FormHeader>
            </CardHeader>
            <CardBody className={cardBodyClassname}>
                <ErrorBoundary>{children}</ErrorBoundary>
            </CardBody>
        </Card>
    </Form>
);

export default CardForm;
