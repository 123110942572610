import { Question } from 'platform/chat/ChatMessage/ChatAssistantMessage/ChatAssistantQuestionsMessage/ChatAssistantQuestionsMessage';
import { ChatOlapRequest } from 'platform/chat/olap/chat.olap.types';

type ResolvedData =
    | { content: string; type: 'DEFAULT' }
    | { content: string; type: 'DISPLAY_ISSUE' }
    | { content: Question[]; type: 'QUESTIONS' }
    | { content: ChatOlapRequest; type: 'OLAP_REQUEST' };

/* Serves 2 purposes:
 *  - ignore json markdown elements
 *  - ignore meaningless text that chatgpt sometimes generates around desired json
 */
const extractJsonBestEffort = (msg: string): string => {
    const jsonStart = msg.indexOf('{');
    const jsonEnd = msg.lastIndexOf('}');

    if (jsonStart > -1 && jsonEnd > jsonStart) {
        return msg.substring(jsonStart, jsonEnd + 1);
    }
    return '';
};

const tryParseJSON = (msg: string): any | null => {
    try {
        const extractedJson = extractJsonBestEffort(msg);
        return JSON.parse(extractedJson);
    } catch (e) {
        return null;
    }
};

const isAttemptedJson = (msg: string): boolean => {
    const cleaned = msg.replace('```json', '').replace('```', '');

    return cleaned.startsWith('{') || cleaned.startsWith('[');
};

const resolveMessageType = (msg: string): ResolvedData => {
    if (!msg) {
        return { type: 'DEFAULT', content: msg };
    }

    const json = tryParseJSON(msg);

    if (json) {
        if (json.olapRequest) {
            return { type: 'OLAP_REQUEST', content: json.olapRequest };
        }

        if (json.questions) {
            return { type: 'QUESTIONS', content: json.questions };
        }
    } else if (isAttemptedJson(msg)) {
        // here we check if the whole message was intended to be JSON
        return { type: 'DISPLAY_ISSUE', content: msg };
    }

    return { type: 'DEFAULT', content: msg };
};

export default resolveMessageType;
