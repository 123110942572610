import { useState } from 'react';
import { ErrorPayload, LegacyErrorPayload } from 'platform/common/utils/errorMessages';

type State = {
    errors?: ErrorPayload | LegacyErrorPayload | string;
    traceId?: string;
    requestPayload?: any;
};

const useErrorHandler = (): [State, (reason: any) => void] => {
    const [state, setState] = useState<State>({});
    const onRejection = (reason: any) => {
        if (!reason) {
            return;
        }
        if (reason.response && (reason.message || '').includes('Request failed with status code')) {
            setState({
                requestPayload: JSON.parse(reason.response.config.data),
                errors: reason.response.data,
                traceId: reason.response.config.headers['X-B3-TraceId'],
            });
        }
    };
    return [state, onRejection];
};

export default useErrorHandler;
