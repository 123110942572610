import classNames from 'classnames';

export interface SummaryStepProps {
    label: string;
    completed: number;
    total: number;
}

const SummaryStep = ({ label, completed, total }: SummaryStepProps) => {
    if (total === 0) {
        return null;
    }

    const isFullyCompleted = completed === total;
    const isStarted = completed > 0;

    return (
        <div className="MediaplanWorkflow-stageItem">
            <div>{label}</div>
            <div
                className={classNames(
                    'ms-auto',
                    { 'bg-success': isFullyCompleted },
                    { 'bg-yellow': isStarted && !isFullyCompleted },
                    { 'bg-light-slate-gray': !isStarted }
                )}
            >
                {completed}/{total}
            </div>
        </div>
    );
};

export default SummaryStep;
