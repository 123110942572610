import { useEffect, useState } from 'react';

const isPageVisible = () => document.visibilityState && document.visibilityState !== 'hidden';

export const usePageVisibility = () => {
    const [isVisible, setIsVisible] = useState(isPageVisible());

    useEffect(() => {
        const changeVisibility = () => setIsVisible(isPageVisible());
        document.addEventListener('visibilitychange', changeVisibility);
        return () => document.removeEventListener('visibilitychange', changeVisibility);
    }, []);

    return isVisible;
};
