import qs from 'qs';
import IconButton from 'platform/common/components/IconButton/IconButton';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { serverUrl } from 'platform/common/utils/environment.util';
import { copyToClipboard } from 'platform/observer/ObserverList';
import { ObserverFormModel } from 'platform/observer/observer.types';

interface Props {
    report: ObserverFormModel;
    params: Record<string, any>;
}

const ObserverReportUrl = ({ report, params }: Props) => {
    const reportUrl = `${serverUrl()}/transfer/${report.systemKey || report.id}?${qs.stringify({
        accessToken: report.accessToken,
        ...params,
    })}`;

    return (
        <div className="d-flex align-items-center justify-content-between gap-2 w-100">
            <span className="text-truncate">{reportUrl}</span>
            <Tooltip renderTooltip={() => 'Copy URL to clipboard'}>
                <IconButton onClick={() => copyToClipboard(reportUrl)}>
                    <i className="fa fa-copy" />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default ObserverReportUrl;
