import WikiPage from './WikiPage/WikiPage';
import { WIKI_PAGE } from './wiki.navigation';

const wikiRoutes = [
    {
        ...WIKI_PAGE,
        component: WikiPage,
    },
];

export default wikiRoutes;
