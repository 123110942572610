import { ReactNode } from 'react';
import './TooltipInnerBody.scss';

type Props = {
    header: string;
    body: ReactNode;
    footer?: ReactNode;
    className: string;
};

const defaultProps = {
    className: '',
};

const TooltipInnerBody = ({ header, body, footer, className }: Props) => (
    <div className={`TooltipInnerBody ${className}`}>
        <div className="TooltipInnerBody--header fw-bold">{header}</div>
        <div className="TooltipInnerBody--body">{body}</div>
        {footer && <div className="TooltipInnerBody--footer fw-bold">{footer}</div>}
    </div>
);

TooltipInnerBody.defaultProps = defaultProps;

export default TooltipInnerBody;
