import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { isEqual } from 'lodash-es';
import { fetchAnalyticsColumns } from 'platform/analytics/analytics.service';
import { campaignClassificationsToDisplayNames } from 'platform/campaign/campaign/campaignClassification.mapper';
import { getCampaignClassificationOptions } from 'platform/campaign/campaign/services/campaignClassification.service';
import { ScoringFormModel, ScoringFormRule } from 'platform/campaign/globalPreset/globalPreset.types';
import { TableCell } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import {
    DraggableTableRow,
    DraggableTableRowProps,
} from 'platform/common/components/DraggableTableRow/DraggableTableRow';
import DynamicTable from 'platform/common/components/DynamicTable/DynamicTable';
import FormRow from 'platform/common/components/FormRow/FormRow';
import InlineEditSelectTree from 'platform/common/components/InlineEditSelectTree/InlineEditSelectTree';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import { usePromise } from 'platform/common/hooks/usePromise';
import { arrayItemMove } from 'platform/common/utils/array.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';

const ScoringForm = ({
    advertiserId,
    labels,
    formikProps: {
        initialValues,
        submitForm,
        values: { rules },
        setFieldValue,
    },
    canEdit,
    onCancel,
}: FormProps<ScoringFormModel> & {
    advertiserId: number;
}) => {
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);

    const [
        {
            data: [classifications, metrics],
            loading,
        },
    ] = usePromise(
        [[], []],
        () =>
            Promise.all([
                getCampaignClassificationOptions(advertiserId, commonClassifiers),
                fetchAnalyticsColumns(advertiserId).then((m) => m.metrics),
            ]),
        [advertiserId, commonClassifiers]
    );

    return (
        <CardForm
            title={`${labels.prefix} scoring rule`}
            subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
            submitLabel={labels.submit}
            disabled={!canEdit}
            onCancel={onCancel}
            onSubmit={submitForm}
        >
            <ControlledCard title="General info" subtitle="required">
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} className="col-md-6" />
                </FormRow>
            </ControlledCard>

            <ControlledCard title="Rules" subtitle="Calculate score based on following rules">
                <Alert color="dark">
                    <strong>First</strong> matching rule will be used
                </Alert>
                {loading ? (
                    <OverlayLoader />
                ) : (
                    <DynamicTable
                        data={rules}
                        cellPlaceholder="add weight"
                        cellType="number"
                        columnsSelectHeader="Select metrics"
                        className="border-0 overflow-visible"
                        tableWrapperClassName="overflow-visible"
                        columnOptions={metrics.map((metric) => ({
                            id: metric.key,
                            label: metric.name,
                            description: metric.description,
                            altName: metric.altName,
                        }))}
                        columns={initialValues.rules[0].metrics.map((ruleMetric) => {
                            const metric = metrics.find((m) => m.key === ruleMetric.metric);
                            return {
                                id: ruleMetric.metric,
                                label: metric?.name || ruleMetric.metric,
                                description: metric?.description,
                                altName: metric?.altName,
                            };
                        })}
                        getTrComponent={() => DraggableTableRow}
                        onColumnsChange={({ columnIds }) =>
                            setFieldValue(
                                'rules',
                                rules.map((rule) => ({
                                    ...rule,
                                    metrics: columnIds.map(
                                        (columnId) =>
                                            rule.metrics.find((metric) => metric.metric === columnId) ?? {
                                                metric: columnId,
                                                weight: 1,
                                            }
                                    ),
                                }))
                            )
                        }
                        onColumnValueChange={({ value, columnId }) =>
                            setFieldValue(
                                'rules',
                                rules.map((rule) => ({
                                    ...rule,
                                    metrics: rule.metrics.map((metric) =>
                                        metric.metric === columnId
                                            ? {
                                                  ...metric,
                                                  weight: value,
                                              }
                                            : metric
                                    ),
                                }))
                            )
                        }
                        onCellValueChange={({ row, columnId, value }) =>
                            setFieldValue(
                                'rules',
                                rules.map((rule) => {
                                    if (rules.indexOf(rule) !== row.index) return rule;
                                    return {
                                        ...rule,
                                        metrics: [
                                            ...rule.metrics.filter((metric) => metric.metric !== columnId),
                                            { metric: columnId, weight: value },
                                        ],
                                    };
                                })
                            )
                        }
                        onRowRemove={({ row }) => {
                            setFieldValue(
                                'rules',
                                rules.filter((rule) => rules.indexOf(rule) !== row.index)
                            );
                        }}
                        getTrProps={(_, row): DraggableTableRowProps => {
                            const rowData = row.original;
                            return {
                                index: rules.findIndex((r) => isEqual(r, rowData)),
                                move: (from: number, to: number) =>
                                    setFieldValue('rules', arrayItemMove(rules, from, to)),
                                staticRow: rules.length - 1,
                            };
                        }}
                        getNewRowComponent={({ columnIds }) => (
                            <InlineEditSelectTree
                                placeholder="add rule"
                                className="w-100"
                                values={[]}
                                options={classifications}
                                getDisplayName={campaignClassificationsToDisplayNames}
                                isLoading={loading}
                                onChange={(values) => {
                                    if (!values.length) return;
                                    setFieldValue('rules', [
                                        {
                                            classification: values,
                                            metrics: columnIds.map((columnId) => ({
                                                metric: columnId,
                                                weight: 1,
                                            })),
                                        },
                                        ...rules,
                                    ]);
                                }}
                            />
                        )}
                        getRowValue={({
                            row,
                            columnId,
                        }: {
                            row: TableCell<ScoringFormRule>;
                            columnId: string;
                        }) => row.original.metrics.find((metric) => metric.metric === columnId)?.weight}
                        firstColumn={{
                            Header: 'Classification',
                            minWidth: 300,
                            accessor: 'classification',
                            sortable: false,
                            Cell: (row: TableCell<ScoringFormRule>) =>
                                row.original.classification ? (
                                    <InlineEditSelectTree
                                        placeholder="add rule"
                                        values={row.original.classification}
                                        options={classifications}
                                        getDisplayName={campaignClassificationsToDisplayNames}
                                        isLoading={loading}
                                        onChange={(values) => {
                                            setFieldValue(
                                                'rules',
                                                rules.map((rule) => {
                                                    if (rules.indexOf(rule) !== row.index) return rule;
                                                    return {
                                                        ...rule,
                                                        classification: values,
                                                    };
                                                })
                                            );
                                        }}
                                    />
                                ) : (
                                    'Default'
                                ),
                        }}
                    />
                )}
            </ControlledCard>
        </CardForm>
    );
};

export default ScoringForm;
