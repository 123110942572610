import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import './SummaryAiSkeleton.scss';

export const SummaryAiSkeleton = () => (
    <>
        <div className="Summary-header">
            <div className="Summary-content-title">
                <Placeholder height="21px" width="40px" borderRadius="20px" />
                <Placeholder height="21px" width="91px" borderRadius="20px" />
            </div>
            <div>
                <Placeholder height="21px" width="21px" borderRadius="20px" />
            </div>
        </div>
        <div className="Summary-content">
            {Array.from({ length: 10 }).map((_, posIndex) => (
                <Placeholder
                    key={`placeholder-${posIndex}`}
                    height="12px"
                    maxWidth={`${[350, 300, 500, 560, 650, 460, 400, 650, 450, 300][posIndex]}px`}
                    borderRadius="20px"
                />
            ))}
        </div>
    </>
);
