import moment from 'moment/moment';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import { Task, TaskLane } from 'platform/task/task.types';

const TaskStuckIcon = ({ task, lane }: { task: Task; lane?: TaskLane }) => {
    if (task.status !== 'ACTIVE' || !task.lastMovedToLane || !lane?.timeout) {
        return null;
    }

    const movedDaysAgo = moment().diff(moment(task.lastMovedToLane), 'days');
    if (movedDaysAgo > lane.timeout.days) {
        return (
            <Tooltip renderTooltip={() => `Stuck in this column for ${movedDaysAgo} days already`}>
                <i className="fa fa-alarm-clock Kanban-red me-2" />
            </Tooltip>
        );
    }

    return null;
};

export default TaskStuckIcon;
