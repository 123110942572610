import { useParams } from 'react-router';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { getSharedFile, updateSharedFileMetadata } from '../fileSharing.service';
import FileSharingForm from './FileSharingForm';

const FileSharingFormContainer = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const id = Number(useParams().id);

    return (
        <FormContainer
            helpKey="file_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit
            sidePanel
            onOpen={() => getSharedFile(id)}
            onSubmit={(file) => updateSharedFileMetadata(id, file)}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <FileSharingForm {...props} />}
        </FormContainer>
    );
};

export default FileSharingFormContainer;
