import { useFormikContext } from 'formik';
import { sortBy } from 'lodash-es';
import { listAlgorithmTemplates } from 'platform/algorithms/algorithms.service';
import { AlgorithmTemplate } from 'platform/algorithms/algorithms.types';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { usePromise } from 'platform/common/hooks/usePromise';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

const sortByArea = (templates: AlgorithmTemplate[]) => sortBy(templates, (t) => t.display.area);

const ObserverAlgorithmTemplateSelect = () => {
    const { setFieldValue } = useFormikContext();
    const [{ data, loading }] = usePromise([], () => listAlgorithmTemplates().then(sortByArea), []);

    return (
        <FormRow label="Algorithm">
            <FormSelect
                isLoading={loading}
                name="algorithmTemplateId"
                options={data}
                getOptionLabel={(t: AlgorithmTemplate) => `${t.display.area} > ${t.name}`}
                getOptionValue={(t: AlgorithmTemplate) => t.id}
                onChange={(t) => (t ? setFieldValue('showInAlgorithmManager', false) : null)}
            />
        </FormRow>
    );
};

export default ObserverAlgorithmTemplateSelect;
