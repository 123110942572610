import { FormEvent, useState } from 'react';
import { Form, Input, InputGroup, InputGroupText } from 'reactstrap';
import { submitMessageFeedback } from 'platform/chat/chat.service';
import { FeedbackType } from 'platform/chat/chat.types';
import FormRow from 'platform/common/components/FormRow/FormRow';
import IconButton from 'platform/common/components/IconButton/IconButton';
import Popover from 'platform/common/components/Popover/Popover';
import { useLoading } from 'platform/common/hooks/useLoading';
import useToggle from 'platform/common/hooks/useToggle';
import { toastSuccess } from 'platform/common/utils/toast.util';
import './ChatMessageFeedback.scss';

const SUBMIT_TOAST = 'Thank you for your feedback!';

interface Props {
    promptId: number;
}

const ChatMessageFeedback = ({ promptId }: Props) => {
    const [loading, withLoading] = useLoading();
    const [isSubmitted, toggleSubmitted] = useToggle(false);
    const [note, setNote] = useState<string | undefined>();

    const submitFeedback = async (type: FeedbackType, e?: FormEvent) => {
        if (loading) return;

        e?.preventDefault();
        await withLoading(() => submitMessageFeedback({ promptId, type, note }));

        toggleSubmitted(true);
        toastSuccess(SUBMIT_TOAST);
    };

    if (isSubmitted) return null;

    return (
        <div className="d-flex me-2">
            <IconButton onClick={() => submitFeedback('GOOD')} className="me-2">
                <i className="ChatMessageFeedback-good text-light-slate-gray far fa-thumbs-up fs-5" />
            </IconButton>
            <Popover
                placement="left"
                renderPopover={({ toggle }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitFeedback('BAD', e);
                            toggle();
                        }}
                    >
                        <FormRow label="Comment" childrenColClassName="d-flex">
                            <InputGroup>
                                <Input type="textarea" autoFocus onChange={(e) => setNote(e.target.value)} />
                                <InputGroupText
                                    className="cursor-pointer"
                                    onClick={() => {
                                        submitFeedback('BAD');
                                        toggle();
                                    }}
                                >
                                    <IconButton type="submit">
                                        <i className="far fa-paper-plane-top text-primary fs-5" />
                                    </IconButton>
                                </InputGroupText>
                            </InputGroup>
                        </FormRow>
                    </Form>
                )}
            >
                <IconButton className="mt-1">
                    <i className="ChatMessageFeedback-bad text-light-slate-gray far fa-thumbs-down fs-5" />
                </IconButton>
            </Popover>
        </div>
    );
};

export default ChatMessageFeedback;
