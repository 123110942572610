import classNames from 'classnames';
import IconButton from 'platform/common/components/IconButton/IconButton';
import './RoundIconButton.scss';

interface Props {
    className?: string;
    icon: string;
    onClick: () => void;
}

const RoundIconButton = ({ className, icon, onClick }: Props) => (
    <div className={classNames('RoundIconButton', className)}>
        <IconButton onClick={onClick}>
            <i className={icon} />
        </IconButton>
    </div>
);

export default RoundIconButton;
