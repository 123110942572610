import { fetchAdvertiser } from 'platform/advertisers/services/advertiser.service';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { usePromise } from 'platform/common/hooks/usePromise';
import store from 'platform/store';

export const getAdvertiserFromOptions = <T extends { id: number; children?: T[] }>(
    id: number,
    tree: T[] = []
): T | undefined => {
    if (tree.length === 0) return undefined;

    if (tree[0].id === id) return tree[0];

    const result = getAdvertiserFromOptions(id, tree[0].children);
    if (result) return result;

    return getAdvertiserFromOptions(id, tree.slice(1));
};

export const useAdvertiserExternalAccountOptions = (advertiserId: number) => {
    const [{ data }] = usePromise<Partial<Record<ExternalSystem, { label: string; value: string }[]>>>(
        {},
        async () => {
            const advertiser = await fetchAdvertiser(advertiserId);
            return Object.fromEntries(
                advertiser.externalSystemIntegrations.map((i) => [
                    i.externalSystem,
                    i.accounts.map((a) => ({ label: a.name || a.externalId, value: a.externalId })),
                ])
            );
        },
        [advertiserId]
    );
    return data;
};

export const getActiveAdvertiserId = () => {
    const advertiserId = store.getState().common.activeAdvertiser.ids[0];
    if (!advertiserId) {
        throw new Error('Advertiser ID is not set.');
    }

    return advertiserId;
};
