import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { useAnalyticsMetadata } from 'platform/analytics/hooks/useAnalyticsMetadata';
import { getCampaignClassificationOptions } from 'platform/campaign/campaign/services/campaignClassification.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ButtonDropdown from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { classifierSelectors } from 'platform/common/ducks/commonClassifiers.duck';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import ConditionalFormula from './ConditionalFormula';
import CustomMetricFormContainer from './CustomMetricFormContainer';
import { deleteCustomMetric, getCustomMetrics } from './customMetric.service';
import { CUSTOM_METRIC_OPTIONS, CustomMetric } from './customMetric.types';

export const PATH = '/advertiser-settings/custom-metrics';

const CustomMetricList = () => {
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const dispatch = useDispatch();
    const showConfirmationModal = useConfirmationModal();
    const canEdit = useFeature('ADVERTISER_SETTINGS_EDIT');
    const { definitions, reloadAnalyticsMetadata } = useAnalyticsMetadata();
    const commonClassifiers = useSelector(classifierSelectors.commonClassifiers);

    const [
        {
            data: [customMetrics, conditionOptions],
            loading,
        },
        refetch,
    ] = usePromise(
        [[], []],
        () =>
            Promise.all([
                getCustomMetrics(advertiserId),
                getCampaignClassificationOptions(advertiserId, commonClassifiers),
            ]),
        [advertiserId, commonClassifiers]
    );

    const unusedOptions = CUSTOM_METRIC_OPTIONS.filter(
        (o) => !customMetrics.some((cm) => cm.key === o.value)
    );

    const getEditPath = (key: string) => `${PATH}/${key}?advertiserId=${advertiserId}`;

    const columns: TableColumn<CustomMetric>[] = [
        {
            Header: 'Key',
            accessor: 'key',
            width: 130,
        },
        {
            Header: 'Name',
            accessor: 'name',
            width: 250,
        },
        {
            Header: 'Formula',
            minWidth: 250,
            sortable: false,
            Cell: ({ original: { rules } }) => (
                <ConditionalFormula
                    rules={rules}
                    metrics={definitions.metrics}
                    conditionOptions={conditionOptions}
                />
            ),
        },
        {
            Header: 'Edited',
            accessor: 'updatedOn',
            maxWidth: 200,
            Cell: ({ original }) => <UpdatedOn date={original.updatedOn} updatedBy={original.updatedBy} />,
        },
        {
            className: 'pull-right cell-align-right',
            sortable: false,
            maxWidth: 50,
            Cell: ({ original: { key, name } }) => (
                <InlineDropdown
                    items={[
                        {
                            label: canEdit ? 'Edit' : 'View',
                            action: () => dispatch(push(getEditPath(key))),
                        },
                        ...(canEdit
                            ? [
                                  {
                                      label: 'Delete',
                                      action: () =>
                                          showConfirmationModal(
                                              () =>
                                                  deleteCustomMetric(advertiserId, key).then(() => refetch()),
                                              {
                                                  title: 'Delete custom metric',
                                                  text: `Are you sure you want to delete "${name}"?`,
                                                  okLabel: 'Delete',
                                              }
                                          ),
                                  },
                              ]
                            : []),
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            {(!canEdit || advertiserId) && (
                <BodyContainer helpKey="custom_metrics_list">
                    {!canEdit && <CanNotEditWarning />}

                    {advertiserId && (
                        <FormattedTable
                            topToolbar={
                                loading || !canEdit ? undefined : (
                                    <ButtonDropdown
                                        items={unusedOptions.map(({ value, label }) => ({
                                            label,
                                            action: () => dispatch(push(`${getEditPath(value)}&isNew=true`)),
                                        }))}
                                    >
                                        Add new
                                    </ButtonDropdown>
                                )
                            }
                            columns={columns}
                            data={customMetrics}
                            loading={loading}
                            defaultSorted={[{ orderBy: 'Key', direction: 'ASC' }]}
                        />
                    )}
                </BodyContainer>
            )}
            <Routes>
                <Route
                    path=":key"
                    element={
                        <CustomMetricFormContainer
                            redirectTo={PATH}
                            canEdit={canEdit}
                            afterSubmit={() => {
                                refetch();
                                reloadAnalyticsMetadata(advertiserId);
                            }}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default CustomMetricList;
