import {
    Sync,
    SyncEntityType,
    SyncRequestType,
    SyncStatus,
} from 'platform/adminPanel/components/SyncJournal/syncJournal.types';
import { AnalyticsUrlSettings } from 'platform/analytics/analytics.types';
import { getSystemReportUrl } from 'platform/analytics/analytics.util';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { isDefined } from 'platform/common/common.types';
import { getIntegrationLabel } from 'platform/common/constants/externalIntegration.constant';
import { makeOptions } from 'platform/common/utils/option.util';

export const syncKey = (sync: Sync) => `${sync.entityType}-${sync.requestId}`;

const SYNC_ENTITY_LABELS: { [key in SyncEntityType]: string } = {
    CAMPAIGN: 'Campaign',
    UNIT: 'Unit',
    SEGMENT: 'Segment',
    ADVERTISER: 'Advertiser',
    CREATIVE: 'Creative',
    USER: 'User',
};

const SYNC_ENTITY_LABELS_BY_REQUEST_TYPE: { [key in SyncRequestType]: string } = {
    CAMPAIGN_SYNC_REQUEST: 'Campaign',
    CAMPAIGN_CLASSIFICATION_UPDATE: 'Labels of Campaign',
    CAMPAIGN_STATUS_UPDATE: 'Status of Campaign',
    CAMPAIGN_RUNTIME_UPDATE: 'Runtime of Campaign',
    CAMPAIGN_BUDGET_UPDATE: 'Budget of Campaign',
    UNIT_SYNC_REQUEST: 'Unit',
    UNIT_CREATIVE_UPDATE: 'Ad of Unit',
    UNIT_STATUS_UPDATE: 'Status of Unit',
    UNIT_RUNTIME_UPDATE: 'Runtime of Unit',
    UNIT_BUDGET_UPDATE: 'Budget of Unit',
    UNIT_BID_UPDATE: 'Bid of Unit',
};

export type NonSyncEntityType = 'STRATEGY' | 'CONCEPT';

export const syncEntityTypeLabel = (type: SyncEntityType) => SYNC_ENTITY_LABELS[type] ?? type;

export const syncRequestTypeLabel = (type: SyncRequestType) =>
    SYNC_ENTITY_LABELS_BY_REQUEST_TYPE[type] ?? type;

export const syncLabel = ({ entityType, requestType }: Sync): string =>
    (requestType && SYNC_ENTITY_LABELS_BY_REQUEST_TYPE[requestType]) ?? syncEntityTypeLabel(entityType);

export const syncEntityName = (sync: Sync) => {
    if (sync.internalName) {
        return `${syncLabel(sync)} ${sync.internalName}`;
    }
    const id = sync.internalId ?? `[${getIntegrationLabel(sync.externalSystem)} id: ${sync.externalId}]`;
    return `${syncLabel(sync)} ${id}`;
};

export const SYNC_ENTITY_TYPE_OPTIONS = makeOptions(SYNC_ENTITY_LABELS);

export const SYNC_REQUEST_TYPE_OPTIONS = makeOptions(SYNC_ENTITY_LABELS_BY_REQUEST_TYPE);

const SYNC_STATUS_LABELS: { [key in SyncStatus]: string } = {
    IN_PROGRESS: 'In progress',
    SUCCESS: 'Success',
    ERROR: 'Failed',
};

export const syncStatusLabel = ({ status }: { status: SyncStatus }): string =>
    SYNC_STATUS_LABELS[status] ?? status;

export const SYNC_STATUS_OPTIONS = makeOptions(SYNC_STATUS_LABELS);

export const RESYNCED_ENTITY_TYPES: SyncEntityType[] = ['CAMPAIGN', 'UNIT', 'CREATIVE', 'USER'];

export const urlBySyncEntity = (
    reports: AnalyticsReport[],
    type?: SyncEntityType | NonSyncEntityType,
    advertiserId?: number,
    id?: number,
    campaignId?: number
) => {
    const getCampaignReportUrl = (settings?: AnalyticsUrlSettings) =>
        getSystemReportUrl(reports, 'campaign_list', settings, advertiserId);

    switch (type) {
        case 'CAMPAIGN': {
            return getCampaignReportUrl({
                filters: [
                    {
                        key: 'campaign_state',
                        values: [],
                    },
                    {
                        key: 'campaign_id',
                        values: [id].filter(isDefined),
                    },
                ],
            });
        }
        case 'UNIT': {
            return getCampaignReportUrl({
                filters: [
                    {
                        key: 'campaign_id',
                        values: [campaignId].filter(isDefined),
                    },
                    {
                        key: 'unit_id',
                        values: [id].filter(isDefined),
                    },
                ],
                dimensions: [
                    'vendor_code',
                    'campaign_name',
                    'strategy_name',
                    'unit_state',
                    'unit_dsp',
                    'unit_id',
                ],
            });
        }
        case 'STRATEGY': {
            return getCampaignReportUrl({
                filters: [
                    {
                        key: 'campaign_id',
                        values: [campaignId].filter(isDefined),
                    },
                    {
                        key: 'strategy_id',
                        values: [id].filter(isDefined),
                    },
                ],
                dimensions: ['vendor_code', 'campaign_name', 'strategy_name', 'strategy_id'],
            });
        }
        case 'SEGMENT': {
            return `/segments/${id}`;
        }
        case 'ADVERTISER': {
            return `/advertisers/advertiser/${advertiserId}`;
        }
        case 'CREATIVE': {
            return `/creatives/${id}`;
        }
        case 'USER': {
            return `/user-management/users/${id}`;
        }
        default:
            return '#';
    }
};
