import { ReactNode } from 'react';
import classNames from 'classnames';
import { useFormHelp } from 'platform/common/hooks/useFormHelp';
import HelpIcon from '../HelpIcon/HelpIcon';

interface Props {
    children: ReactNode;
    helpKey?: string;
    className?: string;
    iconClassName?: string;
}

const FormHelp = ({ helpKey, children, className = 'mb-3', iconClassName }: Props) => {
    const key = useFormHelp(children, helpKey);
    return (
        <div className={classNames('d-flex', className)}>
            <HelpIcon className={classNames('me-2', iconClassName)} helpKey={helpKey ?? key} />
            {children}
        </div>
    );
};

export default FormHelp;
