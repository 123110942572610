import { formatNaturalDateTime } from 'platform/common/utils/date.util';

interface Props {
    date: string | undefined;
}

const KanbanTimestamp = ({ date }: Props) => (
    <span className="text-muted opacity-50">{formatNaturalDateTime(date)}</span>
);

export default KanbanTimestamp;
