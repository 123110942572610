import { Button } from 'reactstrap';
import { FieldArray } from 'formik';
import { difference, orderBy } from 'lodash-es';
import { isNotEmpty, Optional } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import FormRow from 'platform/common/components/FormRow/FormRow';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import { FormProps } from 'platform/common/containers/FormContainer/FormContainer';
import { useLoading } from 'platform/common/hooks/useLoading';
import { toastSuccess } from 'platform/common/utils/toast.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import { FormTextEditor } from 'platform/formik/FormTextEditor/FormTextEditor';
import InsightQueryCard from 'platform/insight/InsightForm/InsightQueryCard';
import {
    analyseInsightQuery,
    fetchInsightQueryVersion,
    normalizeColumnMetadata,
} from 'platform/insight/insight.service';
import {
    InsightColumn,
    InsightQuery,
    InsightQueryMetadata,
    InsightReport,
    NEW_INSIGHT_QUERY,
} from 'platform/insight/insight.types';

type Props = {
    advertiserOptions: { value: number; label: string }[];
} & FormProps<Optional<InsightReport, 'id'>>;

const mergeColumns = (oldColumns: InsightColumn[], newColumns: InsightColumn[]): InsightColumn[] => {
    const columns = [
        ...oldColumns,
        ...normalizeColumnMetadata(newColumns).filter(
            ({ key }) => !oldColumns.find((c) => c.key.toLowerCase() === key)
        ),
    ];
    return orderBy(columns, (c) => c.type);
};

const InsightForm = ({
    labels,
    formikProps: {
        initialValues,
        submitForm,
        values: { queries = [] },
        setFieldValue,
    },
    canEdit,
    onCancel,
    advertiserOptions,
}: Props) => {
    const [loading, withLoading] = useLoading();
    const showConfirmationModal = useConfirmationModal();

    const analyseQuery = ({ source, code, metadata }: InsightQuery): Promise<InsightQueryMetadata> =>
        withLoading(() => analyseInsightQuery({ source, code, columns: metadata.columns }));

    const confirmSaveWithErrors = () =>
        showConfirmationModal(submitForm, {
            title: 'Save with errors?',
            text: 'There are query errors. Are you sure you want to save?',
            okLabel: 'Save',
        });

    const changeMetadata = (index: number, changes: Partial<InsightQueryMetadata>) =>
        setFieldValue(`queries[${index}].metadata`, { ...queries[index].metadata, ...changes });

    const analyseQueryAndPopulateMetadata = async (index: number, opts: { suggestColumns: boolean }) => {
        const { columns: suggestedColumns, parameters, error } = await analyseQuery(queries[index]);
        if (error) {
            changeMetadata(index, { error });
        } else {
            const oldColumns = queries[index].metadata.columns ?? [];
            const newColumns = opts.suggestColumns
                ? mergeColumns(oldColumns, suggestedColumns ?? [])
                : oldColumns;
            changeMetadata(index, { columns: newColumns, parameters, error: undefined });
            const addedColumns = difference(
                newColumns.map((c) => c.name),
                oldColumns.map((c) => c.name)
            );
            toastSuccess(
                addedColumns.length ? `Added columns: ${addedColumns.join(', ')}` : 'Query is valid'
            );
        }
    };

    const restoreQueryVersion = (index: number, timestamp: string) =>
        withLoading(async () => {
            const { code, metadata } = await fetchInsightQueryVersion(queries[index].id!!, timestamp);
            const { columns, parameters } = metadata;
            setFieldValue(`queries[${index}].code`, code);
            changeMetadata(index, { columns, parameters });
        });

    const submit = async () => {
        const queryErrors = queries.map(async (query, index) => {
            const { error } = await analyseQuery(query);
            changeMetadata(index, { error });
            return error;
        });
        if ((await Promise.all(queryErrors)).some(isNotEmpty)) {
            confirmSaveWithErrors();
        } else {
            submitForm();
        }
    };

    return (
        <CardForm
            title={`${labels.prefix} insights`}
            subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
            submitLabel={labels.submit}
            disabled={!canEdit}
            onCancel={onCancel}
            onSubmit={submit}
        >
            {loading && <OverlayLoader />}
            <ControlledCard title="General info">
                <FormRow label="Name">
                    <FormInput name="name" type="text" validate={required} />
                </FormRow>
                <FormRow label="Advertiser">
                    <FormSelect name="advertiserId" options={advertiserOptions} />
                </FormRow>
                <FormRow label="Description">
                    <FormInput name="shortDescription" type="text" />
                </FormRow>
                <FormRow label="HTML Description">
                    <FormTextEditor name="longDescription" />
                </FormRow>
            </ControlledCard>
            <FieldArray name="queries">
                {({ push, remove }) => (
                    <>
                        {queries.map((query, index) => (
                            <InsightQueryCard
                                key={index}
                                field={`queries[${index}]`}
                                query={query}
                                setFieldValue={setFieldValue}
                                onAnalyse={({ suggestColumns }) =>
                                    analyseQueryAndPopulateMetadata(index, { suggestColumns })
                                }
                                onRemove={() => remove(index)}
                                onRestore={(timestamp) => restoreQueryVersion(index, timestamp)}
                            />
                        ))}
                        <Button className="mb-3" onClick={() => push(NEW_INSIGHT_QUERY)}>
                            Add query
                        </Button>
                    </>
                )}
            </FieldArray>
        </CardForm>
    );
};

export default InsightForm;
