import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdvertiserAccountKey } from 'platform/advertisers/advertiser.types';
import { CAMPAIGN_IMPORT } from 'platform/campaign/campaign.navigation';
import { ImportedCampaign } from 'platform/campaign/campaign/campaignImport.types';
import CampaignImportForm from 'platform/campaign/campaign/components/CampaignImport/CampaignImportForm';
import CampaignImportTable from 'platform/campaign/campaign/components/CampaignImport/CampaignImportTable';
import {
    CampaignImportDefaultFilters,
    CampaignImportFilter,
    CampaignImportStatus,
    fetchCampaignImportStatus,
    fetchExternalCampaignSummaries,
} from 'platform/campaign/campaign/services/campaignImport.service';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ControlledCard from 'platform/common/components/ControlledCard/ControlledCard';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import Spinner from 'platform/common/components/Spinner/Spinner';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useDebounce } from 'platform/common/hooks/useDebounce';
import { useLoading } from 'platform/common/hooks/useLoading';
import { usePolling } from 'platform/common/hooks/usePolling';
import StatisticWithDelta from 'platform/dashboard/components/StatisticWithDelta/StatisticWithDelta';
import AdvertiserAccountDetails from './AdvertiserAccountDetails';
import CampaignImportListFilters from './CampaignImportListFilters';
import './CampaignImport.scss';

const mapCampaignsImportStatus = (
    campaigns: ImportedCampaign[],
    campaignsImportStatus: CampaignImportStatus[]
) =>
    campaigns.map((c) => {
        const importStatus = campaignsImportStatus.find((s) => s.externalCampaignId === c.externalId);
        return importStatus
            ? {
                  ...c,
                  importStatus: importStatus.status,
                  importError: importStatus.error,
                  importedOn: importStatus.importedOn,
                  campaignId: importStatus.internalCampaignId,
              }
            : c;
    });

const changeImportCampaignsToInProgress = (
    campaigns: ImportedCampaign[],
    externalIds: string[]
): ImportedCampaign[] =>
    campaigns.map((c) => (externalIds.includes(c.externalId) ? { ...c, importStatus: 'IN_PROGRESS' } : c));

const CampaignImportList = () => {
    const [loading, withLoading] = useLoading();
    const [expertCardExpanded, setExpertCardExpanded] = useState(false);
    const [campaigns, setCampaigns] = useState<ImportedCampaign[]>();

    const [filters, setFilters] = useState<CampaignImportDefaultFilters>({
        channelFilter: [],
        statusFilter: undefined,
        mappingStatusFilter: undefined,
        importStatusFilter: undefined,
    });
    const debouncedFilters = useDebounce(filters, 500);

    const { id: advertiserId } = useActiveAdvertiserComponent();

    const [accountKey, setAccountKey] = useState<AdvertiserAccountKey | undefined>();

    const updateCampaignsStatus = async () => {
        if (accountKey && campaigns?.length) {
            const campaignImportStatus = await fetchCampaignImportStatus(accountKey);
            if (campaignImportStatus) {
                setCampaigns(mapCampaignsImportStatus(campaigns, campaignImportStatus));
            }
        }
    };

    usePolling(updateCampaignsStatus, { interval: 5000 });

    useEffect(() => {
        if (accountKey) {
            const filter: CampaignImportFilter = {
                advertiserId,
                externalAdvertiserId: accountKey.externalAdvertiserId,
                channels: filters?.channelFilter,
                status: filters?.statusFilter,
                importStatus: filters?.importStatusFilter,
                mappingStatus: filters?.mappingStatusFilter,
            };
            withLoading(async () =>
                setCampaigns(await fetchExternalCampaignSummaries(accountKey.externalSystem, filter))
            );
        } else {
            setCampaigns([]);
        }
    }, [accountKey, debouncedFilters]);

    return (
        <div className="CampaignImportList">
            <HeaderContainer>
                <PageHeader title={CAMPAIGN_IMPORT.name} />
            </HeaderContainer>
            <BodyContainer helpKey="campaign_import_list">
                <CampaignImportListFilters
                    advertiserId={advertiserId}
                    onChange={(newKey) => {
                        setAccountKey(newKey);
                        if (filters.channelFilter?.length) {
                            setFilters({ ...filters, channelFilter: [] });
                        }
                    }}
                />

                {campaigns && accountKey ? (
                    <div>
                        <div className="InsetBox d-flex p-3 mb-4 position-relative">
                            <StatisticWithDelta
                                valueClassName="fw-bold"
                                className="flex-grow-1 border-end p-2"
                                name="Imported campaigns:"
                                value={campaigns.filter((c) => c.campaignId).length || 0}
                            />
                            <StatisticWithDelta
                                valueClassName="fw-bold"
                                className="flex-grow-1 p-2"
                                name="Not imported campaigns:"
                                value={campaigns.filter((c) => !c.campaignId).length || 0}
                            />
                            {loading && <OverlayLoader />}
                        </div>

                        <ControlledCard
                            title="Expert"
                            isExpanded={expertCardExpanded}
                            onToggle={setExpertCardExpanded}
                        >
                            {expertCardExpanded && <AdvertiserAccountDetails accountKey={accountKey} />}
                        </ControlledCard>

                        <CampaignImportTable
                            loading={loading}
                            filters={filters}
                            onFilterChange={({ name, value }) => setFilters({ ...filters, [name]: value })}
                            accountKey={accountKey}
                            campaigns={campaigns}
                            onCampaignsImport={(externalIds) => {
                                setCampaigns(changeImportCampaignsToInProgress(campaigns ?? [], externalIds));
                            }}
                        />
                    </div>
                ) : (
                    <>
                        {loading && (
                            <div className="d-flex justify-content-center pt-2">
                                <Spinner color="primary" />
                            </div>
                        )}
                    </>
                )}
            </BodyContainer>

            <Routes>
                <Route path=":id" element={<CampaignImportForm />} />
            </Routes>
        </div>
    );
};

export default CampaignImportList;
