import { Collapse, Row } from 'reactstrap';
import classNames from 'classnames';
import useToggle from 'platform/common/hooks/useToggle';
import { CustomerJourneyReportCM360 } from 'platform/dashboard/dashboard.types';
import JourneyRowBlockDivider from '../../JourneyRowBlockDivider';
import { JOURNEY_EVENT_TYPE_TEXT } from '../../customerJourney.constants';
import JourneyRowBlock from './JourneyRowBlock';
import JourneyRowChannelVendorBlock from './JourneyRowChannelVendorBlock';
import JourneyRowCustomVariables from './JourneyRowCustomVariables';
import JourneyRowMainBlock from './JourneyRowMainBlock';

interface Props {
    row: CustomerJourneyReportCM360;
    defaultExpanded?: boolean;
}

const ActionJourneyRow = ({ row, defaultExpanded = false }: Props) => {
    const [expanded, toggleExpanded] = useToggle(defaultExpanded);

    return (
        <>
            <Row className={classNames('align-items-center px-3 pt-3', !expanded && 'pb-3')}>
                <JourneyRowMainBlock
                    text={JOURNEY_EVENT_TYPE_TEXT[row.journey_event_type]}
                    subtitle={row.journey_event_date_time}
                    expanded={expanded}
                    eventType={row.journey_event_type}
                    showChevron={!!row.journey_custom_variables}
                    toggleExpanded={toggleExpanded}
                />
                <JourneyRowBlockDivider />
                <JourneyRowBlock label="Classification" value={<JourneyRowChannelVendorBlock row={row} />} />
                <JourneyRowBlockDivider />
                <JourneyRowBlock label="Category" value={row.journey_product_category_ids?.join(', ')} />
                <JourneyRowBlockDivider />
                <JourneyRowBlock label="Products" value={row.journey_product_ids?.join(', ')} />
            </Row>
            <Collapse isOpen={expanded}>
                <JourneyRowCustomVariables
                    className="rounded-bottom"
                    customVariableJson={row.journey_custom_variables}
                />
            </Collapse>
        </>
    );
};

export default ActionJourneyRow;
