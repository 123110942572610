import classNames from 'classnames';
import IconBadge from 'platform/common/components/IconBadge/IconBadge';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';

const TaskBlockedBadge = ({ className }: { className?: string }) => (
    <Tooltip renderTooltip={() => 'This task cannot be completed until all upstream tasks are finished'}>
        <IconBadge
            className={classNames('badge-outline-secondary', className)}
            icon={<i className="fa fa-ban" />}
            text="Blocked"
        />
    </Tooltip>
);

export default TaskBlockedBadge;
