import styles from 'platform/scss/combined-variables.scss';

export const CHART_COLORS = [
    styles.primary,
    styles.lightSlateGray,
    styles.success,
    '#59b2f3',
    '#8be9c7',
    '#6a7589',
    '#20c997',
    '#5f9ea0',
    '#87c1eb',
    '#eaecf0',
    '#c5f4e3',
    '#01375e',
    '#05724B',
    '#444A58',
    '#996500',
    '#3E5820',
    '#5023B0',
    '#025590',
    '#0A9F6A',
    '#667085',
    '#D38D05',
    '#6E9B36',
    '#7744EB',
    '#4ca2e0',
    '#17D390',
    '#98A2B3',
    '#F5B100',
    '#96C954',
    '#9F8BF4',
    '#51DEAC',
    '#C5CAD2',
    '#FFD029',
    '#C6E0A0',
    '#C2B8F9',
    '#afg4ee',
    '#AFEBD5',
    '#DCD7FB',
];
