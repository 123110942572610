import { Period } from 'platform/common/common.types';
import AddItemButton from 'platform/common/components/AddItemButton/AddItemButton';
import { ButtonDropdownOption } from 'platform/common/components/ButtonDropdown/ButtonDropdown';
import FilterRemoveIcon from 'platform/common/components/FilterRemoveIcon/FilterRemoveIcon';
import SelectWithAddon from 'platform/common/components/Select/SelectWithAddon';
import { DATE_RANGES, DateRangePreset } from 'platform/common/utils/date.util';

interface Props {
    customDateRange?: Period;
    onChange: (customDateRange?: Period) => void;
}

const ReportCustomDateRange = ({ customDateRange, onChange }: Props) => {
    const rangeOptions: ButtonDropdownOption[] = Object.keys(DATE_RANGES).map((preset: DateRangePreset) => ({
        label: DATE_RANGES[preset].label,
        value: preset,
        action: () =>
            onChange({
                preset,
                from: '',
                to: '',
            }),
    }));

    return (
        <>
            {customDateRange && (
                <SelectWithAddon
                    name="Custom date range"
                    selectClassname="flex-grow-1"
                    value={customDateRange.preset}
                    options={rangeOptions}
                    isClearable={false}
                    endAddon={<FilterRemoveIcon onClick={() => onChange(undefined)} />}
                    onChange={(preset: DateRangePreset) => onChange({ preset, from: '', to: '' })}
                />
            )}
            {!customDateRange && <AddItemButton label="Add date range" options={rangeOptions} />}
        </>
    );
};

export default ReportCustomDateRange;
