import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FieldArray } from 'formik';
import { groupBy, isEmpty } from 'lodash-es';
import { isDefined } from 'platform/common/common.types';
import CardForm from 'platform/common/components/CardForm/CardForm';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import { precisionRound } from 'platform/common/utils/formatters.util';
import { createVendorCommissions, fetchVendorCommissions } from 'platform/vendors/vendors.service';
import {
    VendorCommission,
    VendorCommissionFormModel,
    VendorCommissionRule,
} from 'platform/vendors/vendors.types';
import AdvertiserCommission, { NEW_COMMISSION } from './AdvertiserCommission';

const NEW_RULE: VendorCommissionRule = {
    commission: [NEW_COMMISSION],
};

const toCommissionApiModel = (model: VendorCommissionFormModel): VendorCommission[] =>
    model.rules.reduce((apiRules, { advertiserId, commission }) => {
        const commissions =
            advertiserId &&
            commission?.map(({ product, period, value }) => ({
                advertiserId,
                productId: product!,
                commission: precisionRound(parseFloat(value), 2),
                dateFrom: period.from,
                dateTo: period.to,
            }));
        return [...apiRules, ...(commissions || [])].filter(isDefined);
    }, []);

const toCommissionFormModel = (
    vendorId: number,
    commissions: VendorCommission[]
): VendorCommissionFormModel => {
    const commissionByAdvertiser = groupBy(commissions, 'advertiserId');
    if (isEmpty(commissionByAdvertiser)) {
        return { id: vendorId, rules: [{}] };
    }
    const rules = Object.keys(commissionByAdvertiser).reduce((acc, advId) => {
        const commission = commissionByAdvertiser[advId].map((rule) => ({
            period: { from: rule.dateFrom, to: rule.dateTo },
            product: rule.productId,
            value: String(rule.commission),
        }));
        return [...acc, { advertiserId: Number(advId), commission }];
    }, []);

    return { id: vendorId, rules };
};

const VendorCommissionForm = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const params = useParams<{ id: string }>();
    const id = Number(params.id);

    const handleOpen = async (): Promise<VendorCommissionFormModel> => {
        const commission = await fetchVendorCommissions(id);
        return toCommissionFormModel(id, commission);
    };

    const handleSubmit = (data: VendorCommissionFormModel) =>
        createVendorCommissions(id, toCommissionApiModel(data));

    return (
        <FormContainer
            helpKey="vendor_commission_form"
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit
            sidePanel
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {({
                labels,
                onCancel,
                formikProps: {
                    submitForm,
                    initialValues,
                    setFieldValue,
                    values: { rules },
                },
            }) => (
                <CardForm
                    title={`${labels.prefix} Vendor Commisions`}
                    subtitle={initialValues.id ? `ID: ${initialValues.id}` : null}
                    submitLabel={labels.submit}
                    onCancel={onCancel}
                    disabled={!canEdit}
                    onSubmit={submitForm}
                >
                    <FieldArray name="rules">
                        {({ push, remove }) => (
                            <>
                                {rules.map((rule, index) => (
                                    <AdvertiserCommission
                                        field={`rules[${index}]`}
                                        rule={rule}
                                        selectedAdvertiserIds={rules
                                            .map((r) => r.advertiserId)
                                            .filter(isDefined)}
                                        onRuleRemove={() => remove(index)}
                                        onChange={setFieldValue}
                                    />
                                ))}
                                <Button color="secondary" className="mt-2" onClick={() => push(NEW_RULE)}>
                                    Add advertiser
                                </Button>
                            </>
                        )}
                    </FieldArray>
                </CardForm>
            )}
        </FormContainer>
    );
};

export default VendorCommissionForm;
