import FullScreenChatContainer from 'platform/chat/FullScreenChat/FullScreenChatContainer';
import UnderConstruction from '../common/components/UnderConstruction/UnderConstruction';
import { CHAT, LOGIN, PROFILE, UNDER_CONSTRUCTION } from './app.navigation';
import LoginPage from './components/Login/LoginPage';
import Profile from './components/Profile/Profile';

export const login = {
    ...LOGIN,
    component: LoginPage,
};

export const profile = {
    ...PROFILE,
    component: Profile,
};

export const underConstruction = {
    ...UNDER_CONSTRUCTION,
    component: UnderConstruction,
};

export const chat = {
    ...CHAT,
    component: FullScreenChatContainer,
};
