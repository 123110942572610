import { detectPlatform, Platform } from 'platform/common/utils/platform.util';

const ZoomText = () => {
    const platform = detectPlatform();

    if (!platform) {
        return (
            <div>
                <p className="mb-1">
                    To zoom in please use <kbd>{zoomInShortcuts.windows}</kbd> (for windows) or{' '}
                    <kbd>{zoomInShortcuts.mac}</kbd> (for mac)
                </p>
                <p className="mb-0">
                    To zoom out please use: <kbd>{zoomOutShortcuts.windows}</kbd> (for windows) or{' '}
                    <kbd>{zoomOutShortcuts.mac}</kbd> (for mac)
                </p>
            </div>
        );
    }

    return (
        <div>
            <p className="mb-1">
                To zoom in please use <kbd>{zoomInShortcuts[platform]}</kbd>
            </p>
            <p className="mb-0">
                To zoom out please use <kbd>{zoomOutShortcuts[platform]}</kbd>
            </p>
        </div>
    );
};

const zoomInShortcuts: Record<Platform, string> = {
    windows: 'Ctrl +',
    mac: '⌘ +',
};

const zoomOutShortcuts: Record<Platform, string> = {
    windows: 'Ctrl -',
    mac: '⌘ -',
};

export default ZoomText;
