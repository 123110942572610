import { useRef, useState, useEffect } from 'react';
import {
    select,
    scaleTime,
    axisTop,
    timeMonday,
    timeFormat,
    timeMonth,
    scaleBand,
    utcParse,
    min,
    max,
    Axis,
    NumberValue,
} from 'd3';
import moment from 'moment';
import { DateType, TimelineTask } from './Timeline';
import { BAR_HEIGHT, BAR_SPACING, MARGIN, TICK_WIDTH, chartBuilder } from './timelineChartBuilder';

export interface TimelineBar {
    id: string;
    startDate: Date | null;
    dueDate: Date | null;
    labelKeys: string[];
    name: string;
    laneName: string;
    tentativeDates: boolean;
}

const parseDate = utcParse('%Y-%m-%d');

const toChartBar = (task: TimelineTask): TimelineBar => ({
    id: task.id.toString(),
    startDate: task.startDate ? parseDate(task.startDate) : null,
    dueDate: task.dueDate ? parseDate(task.dueDate) : null,
    labelKeys: task.labelKeys ?? [],
    name: task.name,
    laneName: task.laneName,
    tentativeDates: task.tentativeDates ?? false,
});

const getChartParams = (tasks: TimelineTask[], selectedDateType: DateType, clientWidth: number) => {
    const bars = tasks.map(toChartBar);
    const width = clientWidth - MARGIN.LEFT - MARGIN.RIGHT;
    const minDate = min(bars, (d: TimelineBar) => d.startDate) as Date;
    const maxDate = max(bars, (d: TimelineBar) => d.dueDate) as Date;
    const numDays = moment(maxDate).diff(moment(minDate), 'days');
    const numWeeks = moment(maxDate).diff(moment(minDate), 'weeks');
    const totalWidth = Math.max(width, (selectedDateType === 'WEEK' ? numDays : numWeeks) * TICK_WIDTH);
    const totalHeight = (BAR_HEIGHT + BAR_SPACING) * bars.length;
    const yScale = scaleBand()
        .domain(bars.map((d: TimelineBar) => d.id.toString()))
        .range([totalHeight, 0]);
    const xScale = scaleTime()
        .domain([minDate ?? maxDate, maxDate ?? minDate])
        .range([0, totalWidth]);

    return { bars, minDate, maxDate, numDays, numWeeks, totalWidth, totalHeight, yScale, xScale };
};

const useTimelineChart = (tasks: TimelineTask[], onTaskOpen: (taskId: number) => void) => {
    const [scrollLeft, setScrollLeft] = useState<number>(0);
    const chartRef = useRef<SVGSVGElement | null>(null);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const topAxisRef = useRef<SVGSVGElement | null>(null);
    const tooltipTargetRef = useRef<HTMLDivElement | null>(null);
    const [selectedDateType, setSelectedDateType] = useState<DateType>('MONTH');
    const [hoveredTaskId, setHoveredTaskId] = useState<number | null>(null);

    useEffect(() => {
        if (!tasks?.length || !chartRef.current || !topAxisRef.current || !scrollRef.current) return;

        const { xScale, yScale, totalWidth, bars, minDate, numDays, numWeeks, totalHeight } = getChartParams(
            tasks,
            selectedDateType,
            scrollRef.current.clientWidth
        );

        const svg = select(chartRef.current)
            .attr('width', totalWidth + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', totalHeight + MARGIN.TOP + MARGIN.BOTTOM);
        const g = chartBuilder.appendMainGroup(svg);
        chartBuilder.appendYAxis(g, yScale);
        chartBuilder.appendGridlines(
            g,
            xScale,
            totalHeight,
            selectedDateType === 'WEEK' ? numDays : numWeeks
        );
        chartBuilder.appendBars({
            g,
            bars,
            xScale,
            yScale,
            totalWidth,
            minDate,
            onMouseover: (event, d) => {
                tooltipTargetRef.current?.setAttribute(
                    'style',
                    `top: ${event.pageY}px; left: ${event.pageX}px`
                );
                const bar = bars.find((t) => t.id === d.id);
                if (bar) setHoveredTaskId(Number(bar.id));
            },
            onMouseout: () => {
                tooltipTargetRef.current?.removeAttribute('style');
                setHoveredTaskId(null);
            },
            onMouseMove: (event) => {
                tooltipTargetRef.current?.setAttribute(
                    'style',
                    `top: ${event.pageY}px; left: ${event.pageX}px`
                );
            },
            onClick: (_, d) => {
                onTaskOpen(Number(d.id));
                setHoveredTaskId(null);
            },
        });

        chartBuilder.appendBarStripes(g, bars, xScale, yScale, totalWidth, minDate);
        chartBuilder.appendTodayLine(g, xScale, totalHeight);

        scrollRef.current.scrollLeft = xScale(new Date());

        const svgTopAxis = select(topAxisRef.current)
            .attr('width', totalWidth + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', totalHeight + MARGIN.TOP + MARGIN.BOTTOM);
        const gTopAxis = chartBuilder.appendMainGroup(svgTopAxis);

        const PRIMARY_AXIS_VARIANTS: Record<DateType, Axis<Date | NumberValue>> = {
            WEEK: axisTop(xScale).ticks(timeMonday.every(1)).tickFormat(timeFormat('Week %V')),
            MONTH: axisTop(xScale).ticks(timeMonth.every(1)).tickFormat(timeFormat('%b %Y')),
        };
        const SECONDARY_AXIS_VARIANTS: Record<DateType, Axis<Date | NumberValue>> = {
            WEEK: axisTop(xScale).ticks(numDays).tickFormat(timeFormat('%b %d')),
            MONTH: axisTop(xScale).ticks(numWeeks).tickFormat(timeFormat('WK%V')),
        };
        const secondaryXAxis = SECONDARY_AXIS_VARIANTS[selectedDateType];
        const primaryXAxis = PRIMARY_AXIS_VARIANTS[selectedDateType];

        chartBuilder.appendPrimaryXAxis(gTopAxis, primaryXAxis);
        chartBuilder.appendSecondaryXAxis(gTopAxis, secondaryXAxis);

        // eslint-disable-next-line consistent-return
        return () => {
            svg.selectChildren().remove();
            svgTopAxis.selectChildren().remove();
        };
    }, [tasks, chartRef.current, scrollRef.current, selectedDateType]);

    useEffect(() => {
        if (!scrollRef.current) return;

        const { xScale, yScale, totalWidth, bars, minDate } = getChartParams(
            tasks,
            selectedDateType,
            scrollRef.current.clientWidth
        );
        const svg = select(chartRef.current);

        chartBuilder.removeBarLabels(svg.select('g'));
        chartBuilder.appendBarLabels(
            svg.select('g'),
            bars,
            xScale,
            yScale,
            totalWidth,
            minDate,
            scrollLeft,
            selectedDateType
        );
    }, [tasks, chartRef.current, scrollRef.current, selectedDateType, scrollLeft]);

    return {
        chartRef,
        topAxisRef,
        scrollRef,
        tooltipTargetRef,
        selectedDateType,
        setSelectedDateType,
        hoveredTaskId,
        setHoveredTaskId,
        onScroll: (e: React.UIEvent<HTMLDivElement>) => setScrollLeft(e.currentTarget.scrollLeft),
    };
};

export default useTimelineChart;
