import { Alert } from 'reactstrap';
import { getAnalyticsUrl } from 'platform/analytics/analytics.util';
import {
    AnalyticsReportFilter,
    fetchAnalyticsReports,
} from 'platform/analyticsReports/analyticsReport.service';
import { AnalyticsReport } from 'platform/analyticsReports/analyticsReport.types';
import { SECTION_LABELS } from 'platform/app/app.types';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import UpdatedOn from 'platform/common/components/UpdatedOn/UpdatedOn';
import { usePromise } from 'platform/common/hooks/usePromise';
import { fetchSeats } from 'platform/userManagement/services/userManagement.service';

interface Props {
    title: string;
    subtitle: string;
    filter: AnalyticsReportFilter;
}

const AnalyticsReportUsageTable = ({ title, subtitle, filter }: Props) => {
    const [
        {
            data: [analyticsReports, seats],
            loading,
        },
    ] = usePromise([[], []], () => Promise.all([fetchAnalyticsReports(filter), fetchSeats({})]), []);

    const columns: TableColumn<AnalyticsReport>[] = [
        {
            Header: 'Seat',
            accessor: 'seatIds',
            width: 200,
            Cell: ({ original: { seatIds } }) => (
                <div className="text-truncate">
                    {seats
                        .filter((s) => seatIds?.includes(s.id))
                        .map((s) => s.name)
                        .join(', ')}
                </div>
            ),
        },
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ original: report }) => (
                <a
                    href={getAnalyticsUrl(report.id)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-truncate"
                    title={report.name}
                >
                    {report.name}
                </a>
            ),
        },
        {
            Header: 'Section',
            accessor: ({ section }) => section && SECTION_LABELS[section],
            width: 140,
        },
        {
            Header: 'Edited',
            accessor: (insights) => insights.updatedOn,
            width: 160,
            Cell: ({ original: report }) => (
                <UpdatedOn date={report.updatedOn} updatedBy={report.updatedBy} />
            ),
        },
    ];

    return (
        <div style={{ minWidth: 800 }}>
            <div className="d-flex justify-content-between align-items-baseline mb-3">
                <span className="font-lg">{title}</span>
                <span className="text-muted">{subtitle}</span>
            </div>
            <div className="position-relative">
                <>
                    <FormattedTable
                        data={analyticsReports}
                        loading={loading}
                        columns={columns}
                        stickyHeader={false}
                        defaultSorted={[{ orderBy: 'name', direction: 'ASC' }]}
                        defaultPageSize={10}
                        NoDataComponent={() => (
                            <Alert color="info" className="m-2 text-center">
                                No usages found
                            </Alert>
                        )}
                    />
                </>
            </div>
        </div>
    );
};

export default AnalyticsReportUsageTable;
