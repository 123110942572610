import {
    ComponentType,
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from 'react';
import { AdvertiserOption } from 'platform/common/ducks/activeAdvertiser.duck';

export interface ActiveAdvertiserProps {
    onAdvertisersChange: (activeAdvertisers: AdvertiserOption[]) => void;
}

export interface AppChildren {
    AppHeaderComponent?: ComponentType<ActiveAdvertiserProps>;
}

type Context = [AppChildren, Dispatch<SetStateAction<AppChildren>>] | null;

const AppContext = createContext<Context>(null);

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const state = useState<AppChildren>({});

    return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('AppContext must be used within an AppProvider');
    }

    return context;
};
