import {
    CommonCreativeFields,
    CommonCreativeModelFields,
    toApiCommonFields,
    toFormModelCommonFields,
} from 'platform/creatives/mappers/creative/commonCreative.mapper';

export type GcmTrackerCreativeModel = CommonCreativeModelFields & {
    type: 'GCM_TRACKER';
    clickThroughUrl: string;
    clickThroughUrlSuffix?: string;
};

export type GcmTrackerCreative = CommonCreativeFields & {
    type: 'GCM_TRACKER';
    clickThroughUrl: string;
    clickThroughUrlSuffix?: string;
};

export const toApiGcmTrackerCreative = (creative: GcmTrackerCreativeModel): GcmTrackerCreative => ({
    ...toApiCommonFields(creative),
    type: creative.type,
    clickThroughUrl: creative.clickThroughUrl,
    clickThroughUrlSuffix: creative.clickThroughUrlSuffix || undefined,
});

export const toFormModelGcmTrackerCreative = (creative: GcmTrackerCreative): GcmTrackerCreativeModel => ({
    ...toFormModelCommonFields(creative),
    type: creative.type,
    clickThroughUrl: creative.clickThroughUrl,
    clickThroughUrlSuffix: creative.clickThroughUrlSuffix,
});
