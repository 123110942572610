import { CSSProperties, ReactNode, forwardRef } from 'react';
import useToggle from 'platform/common/hooks/useToggle';

type Props = {
    disabled?: boolean;
    iconClassName?: string;
    style?: CSSProperties;
    children: ({ toggleEditMode }: { toggleEditMode: () => void }) => ReactNode;
    value?: string;
    renderValue?: () => ReactNode;
    placeholder?: string;
};

const InlineEditContainer = forwardRef<HTMLDivElement, Props>(
    ({ iconClassName, style = {}, children, disabled, value, renderValue, placeholder }, ref) => {
        const [isEditMode, toggleEditMode] = useToggle(false);
        if (isEditMode) return <>{children({ toggleEditMode })}</>;

        return (
            <div
                ref={ref}
                className="d-flex"
                style={{
                    cursor: disabled ? 'default' : 'pointer',
                    color: value ? undefined : '#aaa',
                    outline: 'none',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    ...style,
                }}
                role="button"
                tabIndex={0}
                onClick={disabled ? undefined : () => toggleEditMode()}
            >
                <div className="text-truncate w-100" title={value || ''}>
                    {renderValue?.() || value || placeholder}
                </div>
                {iconClassName && !disabled && <i className={`${iconClassName} ms-2`} />}
            </div>
        );
    }
);

export default InlineEditContainer;
