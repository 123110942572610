import { Alert, Input, InputGroup, InputGroupText } from 'reactstrap';
import { OlapReport } from 'platform/analytics/analytics.types';
import { useAnalyticsTemplate } from 'platform/analytics/hooks/useAnalyticsTemplate';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import HeaderContainer from 'platform/common/components/HeaderContainer/HeaderContainer';
import OverlayLoader from 'platform/common/components/OverlayLoader/OverlayLoader';
import PageHeader from 'platform/common/components/PageHeader/PageHeader';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useDebounce } from 'platform/common/hooks/useDebounce';
import useMasterAdvertiser from 'platform/common/hooks/useMasterAdvertiser';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { fetchCustomerJourneyData, fetchAdditionalSegmentData } from 'platform/dashboard/dashboard.service';
import { CustomerJourneyReportCM360, JourneyEventTypeCM360 } from 'platform/dashboard/dashboard.types';
import JourneyList from './JourneyEventList/JourneyList';
import JourneyEventListHeader from './JourneyEventListHeader/JourneyEventListHeader';

const SEGMENT_MICRO_ACTION_CLASSIFICATION_CODE = 'TS_MA_'; // Tracking -> Micro Action

export interface ItemGroupCM360 {
    eventType: JourneyEventTypeCM360;
    startTime: string;
    endTime: string;
    rows: CustomerJourneyReportCM360[];
}

type QueryParams = {
    orderId?: string;
};

const CustomerJourneyCM360 = () => {
    const {
        queryParams: { orderId },
        setQueryParams,
    } = useUrlSync<QueryParams>({
        orderId: undefined,
    });

    const { id: advertiserId } = useActiveAdvertiserComponent();
    const template = useAnalyticsTemplate('customer_journey_cm360');
    const debouncedOrderId = useDebounce(orderId, 1000);
    const { resolvedAdvertiserId: segmentAdvertiserId } = useMasterAdvertiser(advertiserId);

    const [{ data: report, loading: reportLoading, error: reportError }, refetchReport] = usePromise<
        OlapReport<CustomerJourneyReportCM360> | undefined
    >(
        undefined,
        async () => {
            if (!debouncedOrderId) {
                return undefined;
            }

            const [fetchedReport, additionalSegmentData] = await Promise.all([
                fetchCustomerJourneyData(template, advertiserId, debouncedOrderId),
                fetchAdditionalSegmentData(segmentAdvertiserId),
            ]);

            const consolidatedReport: OlapReport<CustomerJourneyReportCM360> = {
                ...fetchedReport,
                rows: fetchedReport.rows
                    .map((row) => {
                        const segment = additionalSegmentData.find((s) => s.id === row.segment_id);

                        return {
                            ...row,
                            adh_segment_purchase_probability_30d:
                                segment?.stats.adh_segment_purchase_probability_30d.value,
                        };
                    })
                    .filter((row) => {
                        if (row.journey_event_type !== 'pageview') return true;

                        const segment = additionalSegmentData.find((s) => s.id === row.segment_id);

                        return segment?.classificationCodes.some((code) =>
                            code.startsWith(SEGMENT_MICRO_ACTION_CLASSIFICATION_CODE)
                        );
                    }),
            };

            return consolidatedReport;
        },
        [advertiserId, template, debouncedOrderId]
    );

    if (reportLoading) {
        return <OverlayLoader />;
    }

    return (
        <>
            <HeaderContainer>
                <PageHeader title="Customer Journey" />
            </HeaderContainer>
            <BodyContainer helpKey="customer_journey_cm360" className="position-relative">
                <InputGroup className="mb-3 me-2" style={{ maxWidth: 200 }}>
                    <InputGroupText>Order ID</InputGroupText>
                    <Input
                        type="text"
                        value={orderId}
                        onChange={(e) => setQueryParams({ orderId: e.target.value })}
                    />
                </InputGroup>
                {(() => {
                    if (reportError) {
                        return <ErrorMessage error={reportError} onRetry={refetchReport} />;
                    }

                    if (!report || !report.rows.length) {
                        return <Alert color="warning">No information found</Alert>;
                    }

                    // Groups have single or more than 3 rows. If there are 2 items of same event type, they are split into separate groups
                    const groups = report.rows.reduce<ItemGroupCM360[]>((acc, row, index) => {
                        const lastGroup = acc[acc.length - 1];
                        const nextRow = report.rows[index + 1];

                        if (
                            index === 0 ||
                            row.journey_event_type !== lastGroup.eventType ||
                            (lastGroup.rows.length === 1 &&
                                (!nextRow || nextRow.journey_event_type !== lastGroup.eventType))
                        ) {
                            return [
                                ...acc,
                                {
                                    eventType: row.journey_event_type,
                                    startTime: row.journey_event_date_time,
                                    endTime: row.journey_event_date_time,
                                    rows: [row],
                                },
                            ];
                        }

                        return [
                            ...acc.slice(0, acc.length - 1),
                            {
                                ...lastGroup,
                                endTime: row.journey_event_date_time,
                                rows: [...lastGroup.rows, row],
                            },
                        ];
                    }, []);

                    return (
                        <>
                            <JourneyEventListHeader report={report} groups={groups} />
                            <div className="my-5">
                                <JourneyList groups={groups} />
                            </div>
                        </>
                    );
                })()}
            </BodyContainer>
        </>
    );
};

export default CustomerJourneyCM360;
