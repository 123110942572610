import { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { shallowEqual, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ChartOptions, TooltipItem } from 'chart.js';
import classNames from 'classnames';
import { isNil } from 'lodash-es';
import { CHART_PREVIEW_HEIGHT } from 'platform/analytics/analytics.constants';
import { ReportSettings } from 'platform/analytics/analytics.service';
import { DoughnutState, Template } from 'platform/analytics/analytics.types';
import {
    exportChartData,
    exportedFilename,
    findColumn,
    getChartAlignment,
} from 'platform/analytics/analytics.util';
import { analyticsSelectors } from 'platform/analytics/ducks/analytics.duck';
import ReportChartTitle from 'platform/analytics/reportComponents/ReportChartContainer/ReportChartTitle';
import ReportTracingTips from 'platform/analytics/reportComponents/ReportTracingTips/ReportTracingTips';
import { useReport } from 'platform/analytics/reportComponents/useReport';
import { isNotEmpty } from 'platform/common/common.types';
import ChartEmptyView from 'platform/common/components/ChartEmptyView/ChartEmptyView';
import ErrorMessage from 'platform/common/components/Errors/ErrorMessage';
import InformationModal from 'platform/common/components/InformationModal/InformationModal';
import { useModal } from 'platform/common/components/Modal/Modal';
import Placeholder from 'platform/common/components/Placeholder/Placeholder';
import { DATA_TYPES, typeOf } from 'platform/common/dataTypes';
import { dateFilterSelectors } from 'platform/common/ducks/dateFilter.duck';
import { layoutSelectors } from 'platform/common/ducks/layout.duck';
import { useChartColor } from '../../ChartColorProvider';
import '../ReportChart.scss';

const DOUGHNUT_CHART_HEIGHT = 305;

interface Props {
    template: Template;
    reportName: string;
    componentState: DoughnutState;
}

const ReportDoughnutChart = ({ componentState, template, reportName }: Props) => {
    const {
        title,
        dimensions,
        metrics,
        customFilters,
        customDateRange,
        showOtherValues = true,
        entriesCount,
    } = componentState;
    const dimension = dimensions[0];
    const metric = metrics[0];
    const { includeVatRate, modelOptIn, components, analyticsReportId } = useSelector(
        analyticsSelectors.settings
    );
    const settings = useMemo<ReportSettings>(
        () => ({
            dimensions: [dimension],
            metrics: [metric],
            customFilters,
            templateId: template.id,
            sort: [{ orderBy: metric, direction: 'DESC' }],
            includeVatRate,
            customDateRange,
            modelOptIn,
        }),
        [dimension, metric, customFilters, customDateRange, template.id, includeVatRate, modelOptIn]
    );
    const { loading, report, error, refetchReport } = useReport({
        settings,
        type: template.type,
        supportsComparison: false,
        componentState,
    });
    const { showModal } = useModal();
    const isMobile = useSelector(layoutSelectors.isMobile);
    const periods = useSelector(dateFilterSelectors.periods, shallowEqual);

    const rows = report?.rows.filter((row) => row[dimension]) ?? [];
    const topRows = rows.slice(0, entriesCount);
    const otherRows = showOtherValues ? rows.slice(entriesCount, rows.length) : [];
    const total = rows.reduce((acc, row) => acc + row[metric], 0);
    const other = otherRows.length
        ? otherRows.reduce(
              (acc, row) => ({
                  ...acc,
                  value: acc.value + row[metric],
              }),
              { label: 'Other', value: 0 }
          )
        : undefined;
    const labels = [...topRows.map((row) => row[dimension]), other?.label].filter(isNotEmpty);

    const { getChartColors } = useChartColor(analyticsReportId!, labels, dimensions);

    if (loading) return <Placeholder minHeight="290px" />;
    if (error) return <ErrorMessage error={error} onRetry={refetchReport} />;

    const metricCell = findColumn(report, metric);
    const dimensionCell = findColumn(report, dimension);
    if (!report || !metricCell || !dimensionCell) {
        return <ChartEmptyView />;
    }

    const data = {
        labels: labels.map((label) => (label.length > 20 ? `${label.substring(0, 20)}..` : label)),
        datasets: [
            {
                data: [...topRows.map((row) => row[metric]), other?.value],
                backgroundColor: getChartColors(labels, dimensions),
            },
        ],
    };

    const options: ChartOptions<'doughnut'> = {
        animation: false,
        maintainAspectRatio: false,
        cutout: '60%',
        plugins: {
            legend: {
                display: true,
                position: 'right',
            },
            tooltip: {
                callbacks: {
                    label(tooltipItem: TooltipItem<'doughnut'>): string | string[] {
                        if (isNil(tooltipItem.dataIndex)) return '-';
                        const { format } = typeOf(findColumn(report, metric), DATA_TYPES.FLOAT);
                        const value = tooltipItem.dataset?.data?.[tooltipItem.dataIndex];
                        return `${labels[tooltipItem.dataIndex]}: ${format(value)} (${DATA_TYPES.P100.format(
                            typeof value === 'number' && (value / total) * 100
                        )})`;
                    },
                },
            },
        },
    };

    const titleText = title || `${metricCell.name} by ${dimensionCell.name}`;

    return (
        <>
            <ReportChartTitle title={titleText} componentState={componentState} template={template}>
                <div className="ReportChart-buttonContainer ms-1">
                    <Button
                        className="ReportChart-button"
                        title="View in modal"
                        onClick={() =>
                            showModal((toggle) => (
                                <InformationModal
                                    isOpen
                                    toggle={toggle}
                                    title={titleText}
                                    style={{ maxWidth: '80%' }}
                                >
                                    <Doughnut height={CHART_PREVIEW_HEIGHT} data={data} options={options} />
                                </InformationModal>
                            ))
                        }
                    >
                        <i className="fal fa-expand" />
                    </Button>
                    {!isMobile && (
                        <Button
                            className="ReportChart-button"
                            title="Download chart data"
                            onClick={exportChartData(
                                [metric],
                                dimension,
                                report,
                                exportedFilename(reportName, titleText, periods)
                            )}
                        >
                            <i className="fa fa-download" />
                        </Button>
                    )}
                </div>
            </ReportChartTitle>

            <div className={classNames('d-flex  h-100 p-3', getChartAlignment(componentState, components))}>
                <div className="w-100">
                    <Doughnut data={data} options={options} height={DOUGHNUT_CHART_HEIGHT} />
                </div>
            </div>
            <ReportTracingTips report={report} />
        </>
    );
};

export default ReportDoughnutChart;
