import { useFormikContext } from 'formik';
import FormRow from 'platform/common/components/FormRow/FormRow';
import { makeOptions } from 'platform/common/utils/option.util';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';
import FormSwitch from 'platform/formik/FormSwitch/FormSwitch';
import { OBSERVER_REPORT_FORMAT_NAMES } from '../observer.constants';
import { ObserverFormModel } from '../observer.types';

const ObserverFormEmailFields = () => {
    const { values } = useFormikContext<ObserverFormModel>();

    return (
        <div className="ps-5">
            <FormRow label="Subject">
                <FormInput name="mailSubject" type="text" validate={required} />
            </FormRow>
            <FormRow label="Text">
                <FormInput
                    name="mailText"
                    type="textarea"
                    rows={10}
                    options={makeOptions(OBSERVER_REPORT_FORMAT_NAMES)}
                    validate={required}
                />
            </FormRow>
            <FormRow label="Attach Result as File">
                <FormSwitch name="attachFile" />
            </FormRow>
            {values.attachFile && (
                <FormRow label="File output format">
                    <FormSelect
                        name="fileFormat"
                        options={makeOptions(OBSERVER_REPORT_FORMAT_NAMES)}
                        validate={required}
                        isClearable={false}
                    />
                </FormRow>
            )}
            <FormRow label="DeeplyAI">
                <FormSwitch name="deeplyEnabled" />
            </FormRow>
        </div>
    );
};

export default ObserverFormEmailFields;
