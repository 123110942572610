import { DataSharingTemplate } from 'platform/adminPanel/adminPanel.types';
import { Status } from 'platform/common/constants/status.constant';
import axiosBff from '../../axios/axiosBff';

export const fetchDataSharingTemplate = (id: number) =>
    axiosBff.get<DataSharingTemplate>(`/segmentation/api/segment-sharing/${id}`).then((res) => res.data);

export const updateDataSharingTemplate = (id: number, model: DataSharingTemplate) =>
    axiosBff.put<void>(`/segmentation/api/segment-sharing/${id}`, model).then((res) => res.data);

export const createDataSharingTemplate = (model: DataSharingTemplate) =>
    axiosBff.post<void>('/segmentation/api/segment-sharing', model).then((res) => res.data);

export const fetchDataSharingTemplates = (params?: { id?: number[]; states: Status[] }) =>
    axiosBff
        .get<DataSharingTemplate[]>('/segmentation/api/segment-sharing', { params })
        .then((res) => res.data);
