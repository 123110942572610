import ReactTable from 'react-table';
import { TableCell } from '../../../../common/common.types';
import { DATA_TYPES } from '../../../../common/dataTypes';
import { SegmentChartStats } from '../../../segments.types';
import '../VisitTime/VisitTimeTable.scss';

type StateProps = {
    data: SegmentChartStats[];
    loading: boolean;
};

const VisitFrequencyTable = ({ data, loading }: StateProps) => {
    if (!data) {
        return null;
    }

    const columns = [
        {
            Header: 'Visit frequency',
            accessor: 'name',
        },
        {
            Header: 'Users 30 d.',
            accessor: 'uniqueUsers',
        },
        {
            Header: 'All actions',
            accessor: 'allActions',
        },
        {
            Header: 'Change in action prob., %',
            accessor: 'penetrationDelta',
            Cell: (line: TableCell<SegmentChartStats>) => DATA_TYPES.P100.parse(Math.max(0, line.value)),
        },
    ];

    return (
        <div className="VisitTimeTable pt-3">
            <ReactTable
                columns={columns}
                data={data}
                loading={loading}
                minRows={0}
                resizable={false}
                showPagination={false}
            />
        </div>
    );
};

export default VisitFrequencyTable;
