import { UnitState } from 'platform/campaign/unit/constants/state.constant';
import { ExternalSystem } from 'platform/common/constants/externalIntegration.constant';
import { ActiveOrInactive } from 'platform/common/constants/status.constant';
import { UnitIOScript } from 'platform/units/units.type';
import axios from '../../../axios/axiosBff';
import { Campaign, CampaignPatch } from '../campaign.types';
import { CampaignState } from '../constants/state.constant';

export const getCampaign = (id: string | number) =>
    axios.get<Campaign>(`/campaign/api/campaigns/${id}`).then((response) => response.data);

export const getCampaigns = (params: { advertiserIds?: number[] }) =>
    axios.get<Campaign[]>(`/campaign/api/campaigns`, { params }).then((response) => response.data);

const changeCampaignState = (campaignId: string | number, state: CampaignState) =>
    axios.put(`/campaign/api/campaigns/${campaignId}/state`, JSON.stringify(state), {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const createCampaign = (campaign: Omit<Campaign, 'id' | 'adserver'>) =>
    axios.post<Campaign>('/campaign/api/campaigns', campaign).then((response) => response.data);

export const updateCampaign = (id: string | number, campaign: Omit<Campaign, 'id' | 'adserver'>) =>
    axios.put(`/campaign/api/campaigns/${id}`, campaign).then((response) => response.data);

export const deleteCampaign = (id: number) => axios.delete(`/campaign/api/campaigns/${id}`);

const syncCampaign = (id: number, system?: string) =>
    axios.post(`/campaign/api/sync/campaigns/${id}`, null, { params: { system } });

export type CampaignSearchType = 'CAMPAIGN' | 'UNIT' | 'MEDIAPLAN' | 'VENDOR';

export interface CampaignSearch {
    name?: string;
    internalId: number;
    externalId?: number;
    type: CampaignSearchType;
    advertiserId: number;
    parentCampaignId?: number;
    parentCampaignName?: string;
    vendorId?: number;
    vendorSystem?: string;
}

export const searchCampaigns = (params: {
    advertiserIds: number[];
    types: CampaignSearchType[];
    query: string;
    campaignStatuses?: CampaignState[];
    mediaplanStatuses?: ActiveOrInactive[];
    unitStatuses?: UnitState[];
    vendorSystems?: (ExternalSystem | string)[];
}) => axios.post<CampaignSearch[]>(`/campaign/api/search`, params).then((response) => response.data);

export const bulkUpdateCampaigns = (params: { campaigns: CampaignPatch[] }) =>
    axios.patch<void>('/campaign/api/campaigns', params);

export const generateIOScripts = (params: { unitIds: number[]; advertiserId: number }) =>
    axios
        .post<UnitIOScript[]>('/campaign/api/units/io-scripts/generate', params)
        .then((response) => response.data);

export default {
    getCampaign,
    changeCampaignState,
    syncCampaign,
};
