import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { push } from 'redux-first-history';
import { stringify } from 'qs';
import BodyContainer from 'platform/common/components/BodyContainer/BodyContainer';
import CanNotEditWarning from 'platform/common/components/CanNotEditWarning/CanNotEditWarning';
import { useConfirmationModal } from 'platform/common/components/ConfirmationModal/useConfirmationModal';
import FormattedTable, { TableColumn } from 'platform/common/components/FormattedTable/FormattedTable';
import InlineDropdown from 'platform/common/components/InlineDropdown/InlineDropdown';
import ListToolbar from 'platform/common/components/ListToolbar/ListToolbar';
import { DATA_TYPES } from 'platform/common/dataTypes';
import useActiveAdvertiserComponent from 'platform/common/hooks/useActiveAdvertiser';
import { useFeature } from 'platform/common/hooks/useFeature';
import { usePromise } from 'platform/common/hooks/usePromise';
import { useUrlSync } from 'platform/common/hooks/useUrlSync/useUrlSync';
import { filterBySearchQuery } from 'platform/common/utils/search.util';
import OptInRateFormContainer from './OptInRateFormContainer';
import { deleteOptInRate, fetchOptInRates } from './optInRates.service';
import { OptInRate } from './optInRates.types';

export const PATH = '/advertiser-settings/opt-in-rates';

type QueryParams = {
    searchQuery: string;
};

const OptInRateList = () => {
    const {
        queryParams: { searchQuery },
        setQueryParams,
        returnUrl,
    } = useUrlSync<QueryParams>(
        {
            searchQuery: '',
        },
        PATH
    );

    const dispatch = useDispatch();
    const showConfirmModal = useConfirmationModal();
    const canEdit = useFeature('ADVERTISER_SETTINGS_EDIT');
    const { id: advertiserId } = useActiveAdvertiserComponent();
    const [{ data, loading }, refetch] = usePromise([], () => fetchOptInRates(advertiserId), [advertiserId]);

    const columnDefinitions: TableColumn<OptInRate>[] = [
        {
            Header: 'Label',
            accessor: 'name',
            type: DATA_TYPES.TEXT,
            minWidth: 300,
        },
        { Header: 'Opt-in rate', accessor: 'value', type: DATA_TYPES.TEXT },
        {
            Header: 'Date from',
            accessor: 'dateFrom',
            type: DATA_TYPES.TEXT,
        },
        {
            className: 'pull-right cell-align-right',
            width: 50,
            sortable: false,
            Cell: ({ original }) => (
                <InlineDropdown
                    items={[
                        {
                            label: 'Edit',
                            action: () =>
                                dispatch(
                                    push({
                                        pathname: `${PATH}/${original.id}`,
                                        search: stringify({ advertiserId }),
                                    })
                                ),
                        },
                        {
                            label: 'Delete',
                            action: () =>
                                showConfirmModal(() => deleteOptInRate(original.id).then(refetch), {
                                    title: 'Delete Opt-In Rate',
                                    text: 'Are you sure you want to delete this Opt-In Rate?',
                                }),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <>
            <BodyContainer helpKey="time_offers_list">
                {!canEdit && <CanNotEditWarning />}
                <FormattedTable
                    isTree
                    topToolbar={
                        <ListToolbar
                            onCreate={
                                canEdit
                                    ? () =>
                                          dispatch(
                                              push({
                                                  pathname: `${PATH}/new`,
                                                  search: stringify({ advertiserId }),
                                              })
                                          )
                                    : undefined
                            }
                            value={searchQuery}
                            onSearch={(value) => setQueryParams({ searchQuery: value })}
                        />
                    }
                    columns={columnDefinitions}
                    data={filterBySearchQuery(
                        data,
                        columnDefinitions.map((column) => column.accessor),
                        searchQuery
                    )}
                    loading={loading}
                    nodesExpanded={!!searchQuery}
                    defaultSorted={[{ orderBy: 'dateFrom', direction: 'DESC' }]}
                />
            </BodyContainer>
            <Routes>
                <Route
                    path="new"
                    element={
                        <OptInRateFormContainer
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <OptInRateFormContainer
                            canEdit={canEdit}
                            redirectTo={returnUrl}
                            afterSubmit={refetch}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default OptInRateList;
