import FailedIcon from 'platform/assets/icons/FAILED.svg';
import SyncIcon from 'platform/assets/icons/SYNC.svg';
import { IntegrationStatus } from 'platform/common/constants/externalIntegration.constant';

type Properties = {
    conceptCreativeStatus?: IntegrationStatus;
    creativeCount?: number;
};

const ConceptCreativeStatus = ({ conceptCreativeStatus, creativeCount }: Properties) => {
    if (conceptCreativeStatus === 'ERROR') {
        return (
            <img
                className="d-inline ms-1"
                src={FailedIcon}
                alt={'ERROR'}
                title="Some of the creatives have integration errors"
            />
        );
    }

    if (!!creativeCount && !conceptCreativeStatus) {
        return (
            <img
                className="d-inline ms-1"
                src={SyncIcon}
                alt={'IN_PROGRESS'}
                title="Some of the creatives are being synced"
            />
        );
    }

    return null;
};

export default ConceptCreativeStatus;
