import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import AnimatedButton from 'platform/common/components/AnimatedButton/AnimatedButton';
import './FloatingButton.scss';

interface Props {
    children: string;
    icon: string;
    disabled?: boolean;
    onCheckCollapsed?: (scrollingContainer: Element) => boolean;
    onClick: () => Promise<void>;
}

const FloatingButton = ({ children, icon, disabled, onClick }: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const scrollingContainerRef = useRef<Element | null>(null);

    const [collapsed, setCollapsed] = useState(false);

    const isCollapsed = () => {
        const container = scrollingContainerRef.current || document.body;
        return container.scrollHeight - container.scrollTop <= container.clientHeight;
    };

    const handleScroll = (e: Event) => {
        if (!(e.target instanceof Element) || !e.target.contains(ref.current)) {
            return;
        }

        scrollingContainerRef.current = e.target;
        setCollapsed(isCollapsed());
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const debouncedMouseLeave = debounce(() => setCollapsed(isCollapsed()), 500);

    const debouncedMouseEnter = debounce(
        () => {
            debouncedMouseLeave.cancel();
            setCollapsed(false);
        },
        500,
        { leading: true }
    );

    return (
        <div
            className={classNames('FloatingButton', collapsed ? 'collapsed' : 'expanded')}
            ref={ref}
            role="button"
            tabIndex={0}
            onMouseEnter={debouncedMouseEnter}
            onMouseLeave={debouncedMouseLeave}
        >
            <AnimatedButton disabled={disabled} onClick={onClick}>
                <div className="FloatingButton-label">{children}</div>
                {icon && <i className={classNames('text-white', icon, !collapsed && 'ms-2')} />}
            </AnimatedButton>
        </div>
    );
};

export default FloatingButton;
