import { Button } from 'reactstrap';
import classNames from 'classnames';
import { FieldArray } from 'formik';
import {
    customColumnOptions,
    customRuleExpressionOptions,
} from 'platform/campaign/globalPreset/constants/customActionRule.constant';
import { CustomActionRuleExpression } from 'platform/campaign/globalPreset/globalPreset.types';
import RemovableLineItem from 'platform/common/components/RemovableLineItem/RemovableLineItem';
import { required } from 'platform/common/utils/validators.util';
import FormInput from 'platform/formik/FormInput/FormInput';
import FormSelect from 'platform/formik/FormSelect/FormSelect';

interface Props {
    field: string;
    expressions?: CustomActionRuleExpression[];
    parentIndex: number;
    onRuleConditionRemove: (parentIndex: number) => void;
}

const RemovableActionRuleConditions = ({
    field,
    expressions = [],
    onRuleConditionRemove,
    parentIndex,
}: Props) => (
    <FieldArray name={field}>
        {({ push, remove }) =>
            expressions.map((expression, index) => (
                <>
                    {index > 0 && <div className="mb-2 ps-3">AND</div>}
                    <RemovableLineItem
                        id={index}
                        className="border-0"
                        key={index}
                        onRemove={() => {
                            remove(index);
                            if (expressions.length <= 1) {
                                onRuleConditionRemove(parentIndex);
                            }
                        }}
                        visibleOverflow
                    >
                        <div className="row mb-2">
                            <div className="col-md-4">
                                <FormSelect
                                    name={`${field}[${index}].condition.column`}
                                    options={customColumnOptions}
                                    isClearable={false}
                                    validate={required}
                                />
                            </div>
                            <div className="col-md-4">
                                <FormSelect
                                    name={`${field}[${index}].condition.operator`}
                                    options={customRuleExpressionOptions}
                                    isClearable={false}
                                    validate={required}
                                />
                            </div>
                            <div className="col-md-4">
                                <FormInput name={`${field}[${index}].condition.value`} validate={required} />
                            </div>
                        </div>
                    </RemovableLineItem>
                    {expressions.length - 1 === index && (
                        <div className="row ps-3">
                            <Button
                                color="link"
                                className="text-muted"
                                onClick={() => {
                                    push({ and: [] });
                                }}
                            >
                                <i className={classNames('fa', 'fa-lg', 'fa-plus-square')} />
                            </Button>
                        </div>
                    )}
                </>
            ))
        }
    </FieldArray>
);

export default RemovableActionRuleConditions;
