import { DataSharingTemplate } from 'platform/adminPanel/adminPanel.types';
import { createDataSharingTemplate } from 'platform/adminPanel/services/segmentSharingTemplate.service';
import FormContainer, { FormContainerProps } from 'platform/common/containers/FormContainer/FormContainer';
import SegmentSharingTemplateForm from './SegmentSharingTemplateForm';

const SegmentSharingTemplateCreate = ({ canEdit, redirectTo, afterSubmit }: FormContainerProps) => {
    const handleOpen = async (): Promise<DataSharingTemplate> => ({
        systemName: '',
        codeType: 'JAVASCRIPT_HTML',
        codeTemplate: '',
    });

    const handleSubmit = (model: DataSharingTemplate) => createDataSharingTemplate(model);

    return (
        <FormContainer
            redirectTo={redirectTo}
            canEdit={canEdit}
            isEdit={false}
            sidePanel
            helpKey="segment_sharing_template_form"
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            onSubmitFinish={afterSubmit}
        >
            {(props) => <SegmentSharingTemplateForm {...props} />}
        </FormContainer>
    );
};

export default SegmentSharingTemplateCreate;
