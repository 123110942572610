import { ReactNode } from 'react';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import './InfoIconTooltip.scss';

interface Props {
    children: ReactNode;
    className?: string;
    iconClassName?: string;
    autoHide?: boolean;
}

const InfoIconTooltip = ({ children, iconClassName = 'fa fa-info-circle text-info', ...rest }: Props) => (
    <Tooltip
        {...rest}
        style={{ height: 'min-content' }}
        tooltipClassName="InfoIconTooltip"
        renderTooltip={() => children}
    >
        <i className={iconClassName} />
    </Tooltip>
);

export default InfoIconTooltip;
