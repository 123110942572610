import { ChangeEvent } from 'react';
import { Input, Label } from 'reactstrap';
import classNames from 'classnames';
import Tooltip from 'platform/common/components/Tooltip/Tooltip';
import './Switch.scss';

type Props = {
    input: {
        value: boolean;
        onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
    };
    name?: string;
    className?: string;
    disabled?: boolean;
    [key: string]: any;
};

const defaultProps = {
    input: {
        value: false,
        onChange: () => {},
    },
};

const Switch = (props: Props) => {
    const { input, name, className, disabled = false, ...rest } = props;
    return (
        <Tooltip tooltipVisible={disabled} renderTooltip={() => 'Read-Only'}>
            <Label
                className={classNames('Switch', className, {
                    checked: input.value,
                })}
            >
                <Input
                    name={name}
                    type="checkbox"
                    className="Switch-input"
                    checked={input.value}
                    onChange={input.onChange}
                    disabled={disabled}
                    {...rest}
                />
                <span className="Switch-label" />
                <span className="Switch-handle" />
            </Label>
        </Tooltip>
    );
};

Switch.defaultProps = defaultProps;

export default Switch;
