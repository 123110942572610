import { useParams } from 'react-router';
import FormContainer from 'platform/common/containers/FormContainer/FormContainer';
import AdvancedSettingForm from './AdvancedSettingForm';
import { fetchAdvancedSetting, saveAdvancedSetting } from './advanced.setting.service';
import { AdvancedSetting, defaultAdvancedSetting } from './advanced.setting.types';

interface Props {
    canEdit: boolean;
    redirectTo: string;
    afterSubmit: () => void;
}

const AdvancedSettingFormContainer = ({ canEdit, redirectTo, afterSubmit }: Props) => {
    const { key } = useParams<{ key: string | undefined }>();

    const handleOpen = (): Promise<AdvancedSetting> =>
        key ? fetchAdvancedSetting(key) : Promise.resolve(defaultAdvancedSetting(''));

    const handleSubmit = (data: AdvancedSetting) => saveAdvancedSetting(data.key, data);

    return (
        <FormContainer
            canEdit={canEdit}
            isEdit={!!key}
            sidePanel
            onOpen={handleOpen}
            onSubmit={handleSubmit}
            redirectTo={redirectTo}
            helpKey="advanced_setting_form"
            onSubmitFinish={afterSubmit}
        >
            {(props) => <AdvancedSettingForm {...props} />}
        </FormContainer>
    );
};

export default AdvancedSettingFormContainer;
