export type AuditObjectType = 'Advertiser' | 'Campaign' | 'Strategy' | 'Unit';

export const AUDIT_OBJECT_TYPE_LABELS: Record<AuditObjectType, string> = {
    Advertiser: 'Advertiser',
    Campaign: 'Campaign',
    Strategy: 'Strategy',
    Unit: 'Unit',
};

export interface AuditedChange {
    objectType: AuditObjectType;
    objectId: number;
    campaignId?: number;
    campaignName?: string;
    propertyName: string;
    newValue: string;
    author: string;
    timestamp: string;
}
