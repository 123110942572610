import classNames from 'classnames';
import ControlledCard, {
    ControlledCardProps,
} from 'platform/common/components/ControlledCard/ControlledCard';

const ControlledCardBorderless = ({
    className,
    headerClassName,
    bodyClassName,
    ...rest
}: ControlledCardProps) => (
    <ControlledCard
        {...rest}
        className={classNames(className, 'shadow-none mb-0')}
        headerClassName={classNames(headerClassName, 'p-0 border-0')}
        bodyClassName={classNames(bodyClassName, 'p-0 border-0')}
        headerChildren={<div className="ControlledCard-line" />}
    />
);

export default ControlledCardBorderless;
