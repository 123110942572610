import { ItemGroupCM360 } from '../../CustomerJourneyCM360';
import { getDurationInterval, getDurationText } from '../../customerJourney.utils';
import JourneyDurationInfo from './JourneyDurationInfo';
import { SPACE_BETWEEN_EVENTS_MIN_WIDTH } from './JourneyTimeline';

interface Props {
    group: ItemGroupCM360;
    nextGroup: ItemGroupCM360;
}

const JourneyEventSeparator = ({ group, nextGroup }: Props) => {
    const durationInterval = getDurationInterval(group.endTime, nextGroup.startTime);
    const durationText = getDurationText(group.endTime, nextGroup.startTime);

    return (
        <div
            className="d-flex flex-column align-items-center flex-grow-1 justify-content-end"
            style={{ minWidth: SPACE_BETWEEN_EVENTS_MIN_WIDTH }}
        >
            <JourneyDurationInfo
                durationTextStyle={{ marginBottom: 9 }}
                durationText={durationText}
                durationInterval={durationInterval}
            />
            <div
                className="d-flex align-items-center"
                style={{ width: '100%', height: 10, marginBottom: 28 }}
            >
                <div className="JourneyEvent-separator" />
            </div>
        </div>
    );
};

export default JourneyEventSeparator;
