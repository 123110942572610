import classNames from 'classnames';
import Popover from 'platform/common/components/Popover/Popover';
import InsightQueryVersionsTable from './InsightQueryVersionsTable';

interface Props {
    className?: string;
    queryId: number;
    onRestore: (timestamp: string) => void;
}

const InsightQueryVersionsPopover = ({ className, queryId, onRestore }: Props) => (
    <Popover
        placement="bottom"
        preventOverflow
        renderPopover={({ toggle }) => (
            <InsightQueryVersionsTable
                queryId={queryId}
                onRestore={(timestamp) => {
                    toggle();
                    onRestore(timestamp);
                }}
            />
        )}
    >
        <div role="button" tabIndex={-1} className={classNames('text-info', className)}>
            <i className="fa fa-history me-1" /> Version history
        </div>
    </Popover>
);

export default InsightQueryVersionsPopover;
