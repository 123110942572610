import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { dateFilterActions } from 'platform/common/ducks/dateFilter.duck';
import Select from '../Select/Select';

interface Props {
    monthFilter?: number;
}

const AnalyticsMonthPicker = ({ monthFilter }: Props) => {
    const selectRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setMenuIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, []);

    return (
        <div className="d-flex gap-1 ms-2 align-items-center" ref={selectRef}>
            <div
                role="button"
                tabIndex={0}
                className="d-flex gap-2 align-items-center"
                onClick={() => setMenuIsOpen(!menuIsOpen)}
            >
                <i className="far fa-calendar text-primary" />
                <span>Month:</span>
            </div>
            <Select
                menuIsOpen={menuIsOpen || undefined}
                onMenuClose={() => setMenuIsOpen(false)}
                name="Month:"
                value={monthFilter}
                selectStyle={{ border: 'none', background: 'transparent', minWidth: 100 }}
                components={{ IndicatorSeparator: null, DropdownIndicator: null }}
                options={moment.months().map((month, index) => ({ label: month, value: index + 1 }))}
                onChange={(month) => {
                    dispatch(dateFilterActions.changeMonth(month));
                    setMenuIsOpen(false);
                }}
                styles={{
                    valueContainer: (provided) => ({ ...provided, padding: '2px' }),
                }}
            />
        </div>
    );
};

export default AnalyticsMonthPicker;
