import { EventType } from 'platform/advertiserTag/constants/advertiserTag.constant';
import { makeOptions } from 'platform/common/utils/option.util';

export type SegmentType =
    | EventType
    | 'ABC'
    | 'VISIT_TIME'
    | 'VISIT_FREQUENCY'
    | 'VISITED_PAGES'
    | 'PRODUCT'
    | 'TRIGGER';

const SEGMENT_TYPE_NAMES: { [key in SegmentType]: string } = {
    STANDARD_EVENT: 'Standard',
    CUSTOM_EVENT: 'Custom',
    VISIT_TIME: 'Visit Time',
    VISIT_FREQUENCY: 'Visit Frequency',
    VISITED_PAGES: 'Visited pages',
    URL_VISIT: 'URL',
    ABC: 'ABC',
    PRODUCT: 'Product',
    TRIGGER: 'Trigger',
};

export const SEGMENT_TYPE_LABELS: { [key in SegmentType]: string } = {
    STANDARD_EVENT: 'Event',
    CUSTOM_EVENT: 'Event',
    URL_VISIT: 'URL',
    VISIT_TIME: 'Time',
    VISIT_FREQUENCY: 'Frequency',
    VISITED_PAGES: 'Pages',
    ABC: 'ABC',
    PRODUCT: 'Product',
    TRIGGER: 'Trigger',
};

export const SEGMENT_TYPE_OPTIONS = makeOptions(SEGMENT_TYPE_NAMES);

const SEGMENT_TYPES_FOR_NEW_SEGMENT: SegmentType[] = [
    'STANDARD_EVENT',
    'CUSTOM_EVENT',
    'URL_VISIT',
    'TRIGGER',
];

export const SEGMENT_TYPE_OPTIONS_FOR_NEW_SEGMENT = SEGMENT_TYPE_OPTIONS.filter((o) =>
    SEGMENT_TYPES_FOR_NEW_SEGMENT.includes(o.value)
);
