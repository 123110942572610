import { ReactElement, useState } from 'react';
import { OptionProps } from 'react-select';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ModalHeader from 'platform/common/components/Modal/ModalHeader';
import { OptionType } from 'platform/common/components/Select/select.types';
import SelectTree from 'platform/common/components/SelectTree/SelectTree';

interface Props {
    options: OptionType[];
    values: OptionType[];
    header: string;
    getOptionComponent?: (props: OptionProps<any, boolean>) => ReactElement;
    toggle: () => void;
    onApply: (values: OptionType[]) => void;
}

const OptionSelectModal = ({ options, values, header, getOptionComponent, toggle, onApply }: Props) => {
    const [selectedOptions, setSelectedOptions] = useState(values);
    return (
        <Modal isOpen toggle={toggle}>
            <ModalHeader onClose={toggle}>{header}</ModalHeader>
            <ModalBody>
                <SelectTree
                    value={selectedOptions}
                    options={options}
                    returnOnlyValues={false}
                    closeMenuOnSelect={false}
                    canSelectAll={false}
                    isMulti
                    isClearable
                    isSearchable
                    getOptionValue={(option) => option.id}
                    components={getOptionComponent && { Option: getOptionComponent }}
                    onChange={setSelectedOptions}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    disabled={false}
                    onClick={() => {
                        onApply(selectedOptions);
                        toggle();
                    }}
                >
                    Apply
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default OptionSelectModal;
