import { isBoolean, isInteger, isNumber } from 'lodash-es';
import { AlgorithmSettingsValue } from 'platform/algorithms/components/AlgorithmSettings/AlgorithmSettingsTable';
import InlineEditInput, { DataTypes } from 'platform/common/components/InlineEditInput/InlineEditInput';
import { DATA_TYPES } from 'platform/common/dataTypes';
import Switch from 'platform/formik/Switch/Switch';

export const getValueType = (value: AlgorithmSettingsValue) => {
    if (Array.isArray(value)) {
        return DATA_TYPES.ARRAY_OF_STRINGS;
    }

    if (isNumber(value)) {
        return isInteger(value) ? DATA_TYPES.INT : DATA_TYPES.FLOAT;
    }

    if (isBoolean(value)) {
        return DATA_TYPES.BOOLEAN;
    }

    return DATA_TYPES.TEXT;
};

interface Props {
    valueKey: string;
    value: AlgorithmSettingsValue;
    onChange: (key: string, value: string, dataType?: DataTypes) => void;
}

const AlgorithmSettingsValueEdit = ({ valueKey, value, onChange }: Props) => {
    const dataType = getValueType(value);

    if (typeof value === 'boolean') {
        return (
            <Switch value={value} onChange={(e) => onChange(valueKey, String(e.target.checked), dataType)} />
        );
    }

    return (
        <InlineEditInput
            inputType="text"
            value={value}
            placeholder="Value"
            dataType={getValueType(value)}
            onChange={(newValue) => onChange(valueKey, newValue, dataType)}
        />
    );
};

export default AlgorithmSettingsValueEdit;
