import { Button, ButtonGroup } from 'reactstrap';
import classNames from 'classnames';

export type ButtonType<T> = {
    label: string;
    value: T;
    icon?: string;
    disabled?: boolean;
};

type Props<T> = {
    buttons: ButtonType<T>[];
    selected?: T;
    onClick: (value: T) => void;
    disabled?: boolean;
    className?: string;
    id?: string;
};

const ButtonArray = <T extends any>({
    buttons,
    selected,
    disabled = false,
    onClick,
    className,
    id,
}: Props<T>) => (
    <ButtonGroup className={className} id={id}>
        {buttons.map((button) => (
            <Button
                type="button"
                key={String(button.value)}
                onClick={() => onClick(button.value)}
                outline
                className={classNames({ active: String(button.value) === String(selected) })}
                disabled={disabled || button.disabled}
            >
                {button.icon && <i className={classNames('me-2', button.icon)} />}
                {button.label}
            </Button>
        ))}
    </ButtonGroup>
);

export default ButtonArray;
